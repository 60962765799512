export enum AuditActionType {
    CREATED = 'CREATED',
    MODIFIED = 'MODIFIED',
    DELETED = 'DELETED',
    RESTORED = 'RESTORED'
}
export interface AuditEntry {
    userId: string;
    action: AuditActionType;
    time: string;
    version: number;
}