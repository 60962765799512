import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import CounterpartyDetailContainer
  from 'components/Detail/counterparty/CounterpartyContainer'

const CounterpartyDetailDialog = (props) => {
  const {
    show,
    onClose,
    counterparty,
    onSaveSuccess,
    isNew,
    readOnly,
  } = props

  return (
    <Dialog
      onClose={onClose}
      open={show}
    >
      <CounterpartyDetailContainer
        counterparty={counterparty}
        isNew={isNew}
        onClose={onClose}
        onSaveSuccess={onSaveSuccess}
        readOnly={readOnly}
      />
    </Dialog>
  )
}

export default CounterpartyDetailDialog

CounterpartyDetailDialog.defaultProps = {
  onClose: () => null,
  onSaveSuccess: () => null,
  counterparty: {},
}
