import {
  PARTY_SWITCH_CONTEXT_ERROR,
  PARTY_SWITCH_CONTEXT_STARTED,
  PARTY_SWITCH_CONTEXT_SUCCESS,
  RETRIEVE_MY_PARTY_ERROR,
  RETRIEVE_MY_PARTY_STARTED,
  RETRIEVE_MY_PARTY_SUCCESS,
} from './actionTypes'
import {jsonRpcRequest, httpRequest} from './helper/utilities'
import config from 'react-global-configuration'
// import {logout} from './authentication'
import {
  PARTY_TYPE_BROKER,
  PARTY_TYPE_COMPANY,
  PARTY_TYPE_INDIVIDUAL,
  PARTY_TYPE_PROCESSING_BANK,
  PARTY_TYPE_PROCESSING_ORG,
  PARTY_TYPE_SYSTEM,
} from 'constants/partyTypes'


export function partySwitchContextStarted() {
  return {type: PARTY_SWITCH_CONTEXT_STARTED}
}

export function partySwitchContextError(error) {
  return {type: PARTY_SWITCH_CONTEXT_ERROR, error}
}

export function partySwitchContextSuccess(result) {
  return {type: PARTY_SWITCH_CONTEXT_SUCCESS, result}
}

export function partySwitchContext(partyCode) {

  return dispatch => {
    dispatch(partySwitchContextStarted());
    httpRequest({
      url: `${config.get('url')}/switch-context`,
      request: {
        'partyToSwitchTo': partyCode,
      },
    }).then(result => {
      dispatch(partySwitchContextSuccess(result))
    }).catch(error => {
      dispatch(partySwitchContextError(error))
    })
  }
}

export function retrieveMyPartyStarted() {
  return {type: RETRIEVE_MY_PARTY_STARTED}
}

export function retrieveMyPartyError(error) {
  return {type: RETRIEVE_MY_PARTY_ERROR, error}
}

export function retrieveMyPartySuccess(result) {
  return {type: RETRIEVE_MY_PARTY_SUCCESS, result}
}

export function retrieveMyParty(identifier, partyType) {
  let method
  switch (partyType) {
    case PARTY_TYPE_SYSTEM:
      method = 'System-Recordkeeper.Retrieve'
      break
    case PARTY_TYPE_PROCESSING_BANK:
      method = 'ProcessingBank-Recordkeeper.Retrieve'
      break
    case PARTY_TYPE_PROCESSING_ORG:
      method = 'ProcessingOrg-Recordkeeper.Retrieve'
      break
    case PARTY_TYPE_BROKER:
      method = 'Broker-Recordkeeper.Retrieve'
      break
    case PARTY_TYPE_COMPANY:
      method = 'Company-Recordkeeper.Retrieve'
      break
    case PARTY_TYPE_INDIVIDUAL:
      method = 'Individual-Recordkeeper.Retrieve'
      break
    default:
      method = undefined
  }
  if (method) {
    return dispatch => {
      jsonRpcRequest({
        url: config.get('golangUrl'),
        method: method,
        request: {
          serializedIdentifier: identifier,
        },
        onStart: () => dispatch(retrieveMyPartyStarted()),
        onSuccess: result => dispatch(retrieveMyPartySuccess(result)),
        onError: error => dispatch(retrieveMyPartyError(error)),
      })
    }
  } else {
    return dispatch => dispatch(
      retrieveMyPartyError('Invalid Party Type, Unable to Determine Method'))
  }
}
