import React, {ChangeEvent, cloneElement, ReactElement, useCallback, useEffect, useState} from 'react'
import {Card, createStyles, Grid, IconButton, makeStyles, MenuItem, useTheme,} from '@material-ui/core'
import {SwapHoriz} from '@material-ui/icons'
import {Currency} from 'popcorn-js/financial/currency/currencyType'
import {DarkNumberField, DarkSelect, LightCheckbox, LightDatePicker, LightFormControlLabel} from './styledComponents'
import {TradeDirection, TradeType} from 'popcorn-js/trade/tradeTypes'
import {CurrencyPair} from 'popcorn-js/financial/currencyPair/currencyPairTypes'
import Big from 'big.js'
import {isBefore, isEqual, isWeekend, isValid, parseISO} from 'date-fns'
import {debounce} from 'lodash'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import {getMidDayISODateString, getMidDay} from './util'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) =>
  createStyles({
    buySellRoot: {
      margin: '20px',
      height: '250px',
      width: '360px',
    },
    container: {},
    buying: {
      height: '100%',
      color: 'black',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
    },
    selling: {
      height: '100%',
      color: 'black',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
    },
    details: {
      backgroundColor: theme.palette.primary.main,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: '8px',
      borderBottomLeftRadius: '8px',
      height: '100%',
    },
    switchButton: {
      alignSelf: 'center',
      justifySelf: 'center',
      padding: 0,
      marginLeft: '-7px',
      width: '30px',
      height: '30px',
      backgroundColor: 'white',
      color: 'black',
      '&:hover': {
        backgroundColor: 'white',
        color: 'black',
      },
      '&:active': {
        backgroundColor: 'white',
        color: 'black',
      },
    },
  }),
);

interface BuySellProps {
  tradeDate: string | null | undefined;
  tradeType: TradeType;
  maturityDate: string | null | undefined;
  spotDate: string | null | undefined;
  maturityDateChange: (date: string | null | undefined) => void;
  acmTrade: boolean;
  acmTradeChange: (value: boolean) => void;
  tradeDateChange: (date: string | null | undefined) => void;
  currencies: Currency[] | undefined;
  currencyPairs: CurrencyPair[] | undefined;
  direction: TradeDirection | undefined;
  directionChange: (date: TradeDirection | undefined) => void;
  buyAmount: Big | undefined;
  buyAmountChange: (value: Big | undefined) => void;
  buyCurrency: Currency | undefined;
  buyCurrencyChange: (value: Currency | undefined) => void;
  sellAmount: Big | undefined;
  sellAmountChange: (value: Big | undefined) => void;
  sellCurrency: Currency | undefined;
  sellCurrencyChange: (value: Currency | undefined) => void;
  allInRate: Big | undefined;
  tradeParentsTotalAllocatedAmount: Big | undefined;
  nonTradingDays: Date[];
}

/**
 * The BuySell component is the main input and focus
 * @param props
 * @constructor
 */
export const BuySell = (props: BuySellProps): ReactElement => {
  const {
    tradeDate, tradeDateChange, maturityDate, maturityDateChange, acmTrade, acmTradeChange, direction, directionChange, currencies, currencyPairs,
    buyAmountChange, buyCurrency, buyCurrencyChange, sellAmountChange, sellCurrency, sellCurrencyChange, allInRate, tradeType, spotDate, tradeParentsTotalAllocatedAmount,
    nonTradingDays,
  } = props

  const theme = useTheme()
  const classes = useStyles(theme)

  const [buyAmount, setBuyAmount] = useState<Big | undefined>()
  const [sellAmount, setSellAmount] = useState<Big | undefined>()

  const sellAmountChangeHandler = useCallback(debounce((value: Big | undefined) => sellAmountChange(value), 500), [sellAmountChange])
  const buyAmountChangeHandler = useCallback(debounce((value: Big | undefined) => buyAmountChange(value), 500), [buyAmountChange])

  const isHoliday = (d: Date): boolean => {
    for (const ntd of nonTradingDays) {
      if (
        ntd.getDate() === d.getDate() &&
            ntd.getMonth() === d.getMonth() &&
            ntd.getFullYear() === d.getFullYear()
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    buyAmountChangeHandler(buyAmount)
  }, [buyAmount, buyAmountChangeHandler])
  useEffect(() => {
    sellAmountChangeHandler(sellAmount)
  }, [sellAmount, sellAmountChangeHandler])

  const updateBuyAmount = useCallback((sellAmount: Big | undefined) => {
    Big.DP = 2
    if (sellAmount && allInRate && allInRate.gt(0)) {
      if (direction === TradeDirection.SELL) {
        setBuyAmount(sellAmount.mul(allInRate))
      } else {
        setBuyAmount(sellAmount.div(allInRate))
      }
    } else {
      setBuyAmount(Big(0))
    }
  }, [direction, allInRate])

  const updateSellAmount = useCallback((buyAmount: Big | undefined) => {
    Big.DP = 2
    if (buyAmount && allInRate && allInRate.gt(0)) {
      if (direction === TradeDirection.SELL) {
        setSellAmount(buyAmount.div(allInRate))
      } else {
        setSellAmount(buyAmount.mul(allInRate))
      }
    } else {
      setSellAmount(Big(0))
    }
  }, [direction, allInRate])

  const handleBuyAmountChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (direction === TradeDirection.BUY) {
      const newBuyAmount = event.target.value ? Big(event.target.value) : undefined
      if (!(newBuyAmount && buyAmount && newBuyAmount?.eq(buyAmount))) {
        setBuyAmount(newBuyAmount)
      }
      updateSellAmount(newBuyAmount)
    }
  }, [direction, buyAmount, updateSellAmount])

  const handleSellAmountChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (direction === TradeDirection.SELL) {
      const newSellAmount = event.target.value ? Big(event.target.value) : undefined
      if (!(newSellAmount && sellAmount && newSellAmount?.eq(sellAmount))) {
        setSellAmount(newSellAmount)
      }
      updateBuyAmount(newSellAmount)
    }
  }, [direction, sellAmount, updateBuyAmount])

  useEffect(() => {
    if (direction === TradeDirection.SELL) {
      if (tradeType === TradeType.CANCELLATION) {
        setSellAmount(tradeParentsTotalAllocatedAmount)
      }
      updateBuyAmount(sellAmount)
    }
  }, [sellAmount, direction, updateBuyAmount, tradeParentsTotalAllocatedAmount, tradeType])

  useEffect(() => {
    if (direction === TradeDirection.BUY) {
      if (tradeType === TradeType.CANCELLATION) {
        setBuyAmount(tradeParentsTotalAllocatedAmount)
      }
      updateSellAmount(buyAmount)
    }
  }, [buyAmount, direction, updateSellAmount, tradeParentsTotalAllocatedAmount, tradeType])

  const currencyMap: Map<string, Currency> = new Map();
  currencies?.forEach((c: Currency) => currencyMap.set(c.isoCode, c))

  // find all currencies which are counter currencies to the buy currency (to be included in the available sell currencies)
  const buyCounterCurrencyIDs: Set<string> = new Set();
  currencyPairs?.forEach((currencyPair: CurrencyPair) => {
    if (currencyPair.baseCurrencyId === buyCurrency?.id) {
      buyCounterCurrencyIDs.add(currencyPair.quoteCurrencyId)
    }
    if (currencyPair.quoteCurrencyId === buyCurrency?.id) {
      buyCounterCurrencyIDs.add(currencyPair.baseCurrencyId)
    }
  })

  // find all currencies which are counter currencies to the sell currency (to be included in the available buy currencies)
  const sellCounterCurrencyIDs: Set<string> = new Set();
  currencyPairs?.forEach((currencyPair: CurrencyPair) => {
    if (currencyPair.baseCurrencyId === sellCurrency?.id) {
      sellCounterCurrencyIDs.add(currencyPair.quoteCurrencyId)
    }
    if (currencyPair.quoteCurrencyId === sellCurrency?.id) {
      sellCounterCurrencyIDs.add(currencyPair.baseCurrencyId)
    }
  })

  const buyCurrencies = currencies?.filter((currency: Currency) => currency.id !== sellCurrency?.id && sellCounterCurrencyIDs.has(currency.id))
  const sellCurrencies = currencies?.filter((currency: Currency) => currency.id !== buyCurrency?.id && buyCounterCurrencyIDs.has(currency.id))

  return (
    <div
      className={classes.buySellRoot}
    >
      <div
        style={{height: '50%', width: '100%'}}
      >
        <div
          style={{width: 'calc(50% - 8px)', height: '100%', float: 'left'}}
        >
          <Card
            className={classes.buying}
            elevation={0}
            style={{
              display: 'flex',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              backgroundColor: (tradeType === TradeType.CANCELLATION ? (direction === TradeDirection.BUY ? (theme as any).palette.export.main : (theme as any).palette.local) : (direction === TradeDirection.BUY ? theme.palette.import.main : (theme as any).palette.local)),
              paddingLeft: '24px',
              paddingRight: '24px',
              paddingTop: '12px',
            }}
          >
            <Grid
              alignContent={'flex-start'}
              alignItems={'center'}
              container
              direction={'row'}
              justify={'center'}
              spacing={2}
              style={{justifySelf: 'center', alignSelf: 'top'}}
            >
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-start'}}
                xs={6}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                >
                  Buying
                </span>
              </Grid>
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-end', width: '60px'}}
                xs={6}
              >
                <DarkSelect
                  disabled={(buyCurrencies ? buyCurrencies.length < 2 : false) || tradeType === TradeType.CANCELLATION}
                  id="spotForwardSelectCurrency"
                  onChange={(event: ChangeEvent<{ name?: string | undefined, value: unknown }>) =>
                    buyCurrencyChange(currencyMap.get(event.target.value as string))}
                  value={buyCurrency ? buyCurrency.isoCode : ''}
                >
                  {buyCurrencies?.map((currency: Currency) =>
                    <MenuItem
                      key={currency.isoCode}
                      value={currency.isoCode}>{currency.isoCode}</MenuItem>
                  )}
                </DarkSelect>
              </Grid>
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-end'}}
                xs={12}
              >
                <DarkNumberField
                  disabled={direction !== TradeDirection.BUY || tradeType === TradeType.CANCELLATION}
                  id="buyAmount"
                  label="Amount"
                  onChange={handleBuyAmountChange}
                  prefix={buyCurrency?.symbol}
                  style={{width: '124px'}}
                  value={buyAmount ? buyAmount.toFixed(2) : ''}
                />
              </Grid>
            </Grid>
          </Card>
        </div>

        <div
          style={{width: '16px', height: '100%', float: 'left', display: 'flex'}}
        >
          {tradeType !== TradeType.CANCELLATION && <IconButton
            className={classes.switchButton}
            onClick={() => {
              if (direction === TradeDirection.SELL) {
                directionChange(TradeDirection.BUY)
                const newSellAmount = buyAmount
                setBuyAmount(sellAmount)
                setSellAmount(newSellAmount)
              } else {
                directionChange(TradeDirection.SELL)
                const newBuyAmount = sellAmount
                setSellAmount(buyAmount)
                setBuyAmount(newBuyAmount)
              }
            }}
          >
            <SwapHoriz style={{width: '30px', height: '30px'}}/>
          </IconButton>}
        </div>
        <div
          style={{width: 'calc(50% - 8px)', height: '100%', float: 'right'}}
        >
          <Card
            className={classes.selling}
            elevation={0}
            style={{
              display: 'flex',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              backgroundColor: (tradeType === TradeType.CANCELLATION ? (direction === TradeDirection.SELL ? theme.palette.import.main : (theme as any).palette.local) : (direction === TradeDirection.SELL ? (theme as any).palette.export.main : (theme as any).palette.local)),
              paddingLeft: '24px',
              paddingRight: '24px',
              paddingTop: '12px',
            }}
          >
            <Grid
              alignContent={'flex-start'}
              alignItems={'center'}
              container
              direction={'row'}
              justify={'center'}
              spacing={2}
              style={{justifySelf: 'center', alignSelf: 'top'}}
            >
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-start'}}
                xs={6}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                >
                    Selling
                </span>
              </Grid>
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-end', width: '60px'}}
                xs={6}
              >
                <DarkSelect
                  disabled={(sellCurrencies ? sellCurrencies?.length < 2 : false) || tradeType === TradeType.CANCELLATION}
                  id="spotForwardSelectSellCurrency"
                  onChange={(event: ChangeEvent<{ name?: string | undefined, value: unknown }>) =>
                    sellCurrencyChange(currencyMap.get(event.target.value as string))}
                  value={sellCurrency ? sellCurrency.isoCode : ''}
                >
                  {sellCurrencies?.map((currency: Currency) =>
                    <MenuItem
                      key={currency.isoCode}
                      value={currency.isoCode}>{currency.isoCode}</MenuItem>
                  )}
                </DarkSelect>
              </Grid>
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-end'}}
                xs={12}
              >
                <DarkNumberField
                  disabled={direction !== TradeDirection.SELL || tradeType === TradeType.CANCELLATION}
                  id="sellAmount"
                  label="Amount"
                  onChange={handleSellAmountChange}
                  prefix={sellCurrency?.symbol}
                  style={{width: '124px'}}
                  value={sellAmount ? sellAmount.toFixed(2) : ''}
                />
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
      <div
        style={{height: '50%'}}
      >
        <Card
          className={classes.details}
          elevation={0}
        >
          <Grid
            container
            style={{paddingTop: '24px'}}>
            <Grid
              item
              style={{display: 'flex', justifyContent: 'center'}}
              xs={6}
            >
              <LightDatePicker
                format={'yyyy-MM-dd'}
                id={'tradeDate'}
                label={'Trade Date'}
                onChange={(day: MaterialUiPickersDate, value: string | null | undefined) => {
                  if (value && isValid(parseISO(value))) {
                    const date = getMidDay(day as Date);
                    if (isWeekend(date) || isHoliday(date)) {
                      tradeDateChange(undefined);
                      return;
                    }
                    tradeDateChange(getMidDayISODateString(date as Date));
                  } else {
                    tradeDateChange(undefined);
                  }
                }}
                renderDay={(
                  day: MaterialUiPickersDate,
                  selectedDate: MaterialUiPickersDate,
                  isInCurrentMonth: boolean,
                  dayComponent: ReactElement,
                ) => {
                  const date = getMidDay(day as Date);
                  if (isWeekend(date) || isHoliday(date)) {
                    return cloneElement(dayComponent, { disabled: true });
                  }
                  return dayComponent;
                }}
                style={{ width: '135px' }}
                value={tradeDate ? tradeDate : ''}
              />
            </Grid>
            <Grid
              item
              style={{display: 'flex', justifyContent: 'center'}}
              xs={6}
            >
              
              <LightDatePicker
                disabled={tradeType === TradeType.SPOT || tradeType === TradeType.CANCELLATION}
                format={'yyyy-MM-dd'}
                id="maturityDatePicker"
                label="Maturity Date"
                onChange={(currentDate: MaterialUiPickersDate, value: string | null | undefined) => {
                  if (
                    tradeType === TradeType.FORWARD &&
                                        value &&
                                        spotDate &&
                                        tradeDate &&
                                        isValid(parseISO(value))
                  ) {
                    const spotDateStart = parseISO(spotDate);
                    const tradeDateStart = parseISO(tradeDate);
                    const newMaturityDate = getMidDay(currentDate as Date);
                    if (
                      !isEqual(newMaturityDate, spotDateStart) &&
                                            !isBefore(newMaturityDate, tradeDateStart) &&
                                            !isWeekend(newMaturityDate) &&
                                            !isHoliday(newMaturityDate)
                    ) {
                      maturityDateChange(newMaturityDate.toISOString());
                      return;
                    }
                  }
                  maturityDateChange(undefined);
                }}
                renderDay={(
                  day: MaterialUiPickersDate,
                  selectedDate: MaterialUiPickersDate,
                  isInCurrentMonth: boolean,
                  dayComponent: ReactElement,
                ) => {
                  const date = getMidDay(day as Date);
                  if (isWeekend(date) || isHoliday(date)) {
                    return cloneElement(dayComponent, { disabled: true });
                  }
                  if (tradeType === TradeType.FORWARD && spotDate && tradeDate) {
                    const spotDateStart = parseISO(spotDate);
                    const tradeDateStart = parseISO(tradeDate);
                    if (
                      isEqual(date, spotDateStart) ||
                                            isBefore(date, tradeDateStart)
                    ) {
                      return cloneElement(dayComponent, { disabled: true });
                    }
                  }
                  return dayComponent;
                }}
                style={{ width: '135px' }}
                value={maturityDate ? maturityDate : ''}
              />
            </Grid>
            <Grid
              item
              style={{display: 'flex', justifyContent: 'center'}}
              xs={6}
            >
              <LightFormControlLabel
                control={
                  <LightCheckbox
                    checked={acmTrade}
                    name="acmTrade"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => acmTradeChange(event.target.checked)}
                  />
                }
                label="ACM Trade"
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  )
}
