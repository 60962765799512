import {isArray} from 'utils/Utils'

/**
 * Check if given object is identified by given
 * identifier
 * @param {object} objectToCheck - object to check
 * @param {object} identifier
 * @returns {boolean}
 */
export function objectIdentifiedBy(objectToCheck, identifier) {
  if (!(objectToCheck && typeof objectToCheck === 'object')) {
    throw new TypeError('object arg to isIdentifiedBy function is not an object')
  }
  if (!(identifier && typeof identifier === 'object')) {
    throw new TypeError('identifier arg to isIdentifiedBy function is not an object')
  }

  try {
    for (const field in identifier.value) {
      if (identifier.value[field] !== objectToCheck[field]) {
        return false
      }
    }
  } catch (e) {
    console.error(`error trying to check if object is identified by given identifier: ${e}`)
    return false
  }

  return true
}

/**
 * Check if given object is identified by given
 * identifier
 * @param {object} objectToCheck - object to check
 * @param {object[]} identifierList
 * @returns {boolean}
 */
export function objectIdentifiedByOneOf(objectToCheck, identifierList) {
  if (!(objectToCheck && typeof objectToCheck === 'object')) {
    throw new TypeError(`object arg to objectIdentifiedByOneOf function is not an object: ${objectToCheck.toString()}`)
  }
  if (!isArray(identifierList)) {
    throw new TypeError('identifierList arg to objectIdentifiedByOneOf function is not an object')
  }

  try {
    for (let idx = 0; idx < identifierList.length; idx++) {
      if (objectIdentifiedBy(objectToCheck, identifierList[idx])) {
        return true
      }
    }
  } catch (e) {
    console.error(`error trying to check if object is identified by an identifier in list: ${e}`)
  }
  return false
}

/**
 * Retrieve object from list with given identifier
 * @param {object} identifier
 * @param {object[]} list
 * @returns {*|undefined}
 */
export function retrieveFromList(identifier, list) {
  if (!isArray(list)) {
    throw new TypeError('list arg to RetrieveFromList function is not an array')
  }
  if (!(identifier && typeof identifier === 'object')) {
    throw new TypeError(`identifier arg to retrieveFromList function is not an object: ${identifier.toString()}`)
  }
  for (let idx = 0; idx < list.length; idx++) {
    if (objectIdentifiedBy(list[idx], identifier)) {
      return list[idx]
    }
  }
}
