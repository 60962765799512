import {jsonRpcRequest} from 'jsonRpc/jsonRpcReqest'
import config from 'react-global-configuration'
import {GenerateDollarPortfolioReportResponse} from '.'

/**
 * Generator is a convenience client for invoking the backend services
 */
const Generator = {
  ServiceProviderName: 'Report-Generator',
  /**
   * @param {GeneratePositionReportForCurrencyRequest} request
   * @constructor
   */
  GeneratePositionReportForCurrency: async function (request) {
    const response = await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GeneratePositionReportForCurrency`,
      request,
    })
    return response
  },

  /**
   * @param {GenerateDollarPortfolioReportRequest} request
   * @constructor
   */
  GenerateDollarPortfolioReport: async function (request) {
    return new GenerateDollarPortfolioReportResponse(await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GenerateDollarPortfolioReport`,
      request,
    }))
  },

  /**
   * @constructor
   */
  GenerateMaturityLadderReport: async function() {
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GenerateMaturityLadderReport`,
    })
  },

  /**
   * @constructor
   */
  GenerateCoverageRatioReport: async function() {
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GenerateCoverageRatioReport`
    })
  },
}


export default Generator
