import React, {useContext, useEffect, useState, ReactElement} from 'react';
import {
  FiX as DeselectIcon,
  FiAlertTriangle as PricesUnavaliableIcon,
} from 'react-icons/fi';
import {
  IconButton,
  Typography,
  Tooltip,
  Icon,
  useTheme, AppBar, Toolbar
} from '@material-ui/core';
import { processUnixDateForViewing } from 'utils/Utils';
import InvoiceDetail from 'components/Detail/invoice/InvoiceDetailDialog';
import PrepaymentDetailDialog from 'components/Detail/prepayment/PrepaymentDetailDialogContainer';
import { ComponentLevelLoader } from 'components/Loader/Loader';
import { ComponentLevelError } from 'components/Error/Error';
import { CustomTheme } from 'theme/custom';
import { InvoiceAmountRequested, InvoiceTradeLink, PrepaymentAmountRequested, 
  PrepaymentTradeLink, SettlementInstruction, Status } from 'popcorn-js/financial/settlementInstruction'
import { getSignBasedOnType, Invoice } from 'popcorn-js/financial/invoice/Invoice';
import { Prepayment } from 'popcorn-js/financial/prepayment';
import { AppContextType, AppContext } from 'appContext';
import { styled, useStyletron } from 'styletron-react';
import moment from 'moment';

import Handler from 'popcorn-js/rick/handler'

import { Counterparty } from 'popcorn-js/counterparty/types';
import { InvoiceRecordkeeper } from 'popcorn-js/financial/invoice';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import { Recordkeeper as PrepaymentRecordkeeper } from 'popcorn-js/financial/accounting/prepayment/recordkeeper';
import { EXACT_CRITERION } from 'popcorn-js/search/criteria/types';
import { ActionButton } from './ActionButton';
import { GetApp } from '@material-ui/icons';
import { displayAmount } from 'views/Tickets/util';
import Big from 'big.js'
import {usePerfectScrollbar} from 'hooks'

type InvoiceAndInvoiceAmountsRequested = {
  invoice: Invoice,
  amountRequested: InvoiceAmountRequested,
};

type PrepaymentAndPrepaymentAmountsRequested = {
  prepayment: Prepayment,
  amountRequested: PrepaymentAmountRequested,
};

type InvoiceRequestedAmountCounterpartySummary = {
  counterparty: Counterparty;
  total: number;
  localTotal: number;
  invoicesAndInvoiceAmountsRequested: InvoiceAndInvoiceAmountsRequested[];
}
type InvoiceRequestedAmountCounterpartySummaryMap = {
  [key: string]: InvoiceRequestedAmountCounterpartySummary;
}

type PrepaymentRequestedAmountCounterpartySummary = {
  counterparty: Counterparty;
  total: number;
  localTotal: number;
  prepaymentsAndPrepaymentAmountsRequested: PrepaymentAndPrepaymentAmountsRequested[];
}
type PrepaymentRequestedAmountCounterpartySummaryMap = {
  [key: string]: PrepaymentRequestedAmountCounterpartySummary;
}

const DividingLine = (props: {theme: CustomTheme}) => {
  const StyledComponent = styled('div', {
    margin: '10px 0',
    borderWidth: '1px 0 0 0',
    borderStyle: 'solid',
    borderColor: props.theme.palette.grey[300],
  })
  return <StyledComponent />
}

const calculateLocalTotal = (sign: number, links: (InvoiceTradeLink|PrepaymentTradeLink)[]) => {
  let totalRateAmount = 0.0;
  for (const tradeLink of links) {
    // correct for negative link amounts
    const linkAmount = tradeLink.amount < 0 ? -1 * tradeLink.amount : tradeLink.amount
    totalRateAmount += linkAmount * tradeLink.rate;
  }
  return sign * totalRateAmount;
}

const displayCurrencyAmount = (amount: number|undefined): string => {
  if (amount) {
    let sign = ''
    if (amount < 0) {
      sign = '-'
    }
    return `${sign} ${displayAmount(Big(Math.abs(amount)))}`
  }
  return '--'
}

const displayRate = (value: number|undefined): string => {
  if (value) {
    return value.toFixed(4)
  }
  return '-'
}

export const SettlementInstructionSummary = (props: {
  SI: SettlementInstruction,
  readOnly?: boolean,
  onDownload?: () => void;
  onInvoiceRemove?: (id: string) => void;
  onPrepaymentRemove?: (id: string) => void;
}): ReactElement => {
  const {SI: initialSI, readOnly, onInvoiceRemove, onPrepaymentRemove, onDownload} = props

  const setScrollbarRef = usePerfectScrollbar()

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice|undefined>()
  const [viewInvoice, setViewInvoice] = useState(false)
  const [itemListHeight, setItemListHeight] = useState<number>(0)
  const [selectedPrepayment, setSelectedPrepayment] = useState({})
  const [viewPrepaymentDialog, setViewPrepaymentDialog] = useState(false)
  const [invoiceRequestedAmountCounterpartySummaryMap, setInvoiceRequestedAmountCounterpartySummaryMap] = 
    useState<InvoiceRequestedAmountCounterpartySummaryMap>({} as InvoiceRequestedAmountCounterpartySummaryMap)
  const [prepaymentRequestedAmountCounterpartySummaryMap, setPrepaymentRequestedAmountCounterpartySummaryMap] = 
    useState<PrepaymentRequestedAmountCounterpartySummaryMap>({} as PrepaymentRequestedAmountCounterpartySummaryMap)
  const [error, setError] = useState<string|undefined>(undefined)
  const [currentSI, setCurrentSI] = useState(initialSI)
  const [localToSIMTMRate, setLocalToSIMTMRate] = useState<number|undefined>()
  const [loading, setLoading] = useState(true)

  const [css] = useStyletron()
  const appContext = useContext<AppContextType>(AppContext);
  const theme = useTheme<CustomTheme>()

  useEffect(() => {
    const findMTMRate = async (SI: SettlementInstruction): Promise<number> => {
    // then there is no need to retrieve spot rate
      if (SI.currencyId === appContext.localCurrency.id) {
        return 1;
      }
      const currencyPair = appContext.currencyPairs.find(
        (ccyPair) =>
          ccyPair.baseCurrencyId === SI.currencyId &&
        ccyPair.quoteCurrencyId === appContext.localCurrency.id
      );
      if (currencyPair) {
        try {
          let rateRetrieveResult;
          if (SI.date && SI.date <= moment().unix()) {
            rateRetrieveResult = await Handler.RetrieveRate({
              rateSubscription: {
                currencyPairName: currencyPair.name,
                date: 0,
              },
            });
          } else {
            rateRetrieveResult = await Handler.RetrieveRate({
              rateSubscription: {
                currencyPairName: currencyPair.name,
                date: SI.date,
              },
            });
          }
          return (rateRetrieveResult.priceSubscriptionSucceeded.askRate + rateRetrieveResult.priceSubscriptionSucceeded.bidRate) / 2
        } catch (e) {
          console.error('error retrieving spot rate for currency pair', e);
          throw new Error('error retrieving spot rate for currency pair')
        }
      }
      throw new Error('currency pair not found')
    };
    const findSiInvoices = async (SI: SettlementInstruction): Promise<Invoice[]> => {
      const invoiceIds = (SI.invoiceAmountsRequested || []).map(
        (entry: InvoiceAmountRequested) => entry.invoiceId
      );
      if (invoiceIds.length > 0) {
        const criteria = invoiceIds.map((id) => ({
          type: EXACT_CRITERION,
          value: {
            field: 'id',
            text: id,
          },
        }));

        try {
          const findResponse = await InvoiceRecordkeeper.find({ criteria });
          const newInvoices = [...(findResponse as {records: Invoice[], total: number}).records]
          return newInvoices
        } catch (e) {
          console.error('Could not find invoices: ', e.message || e);
          throw e;
        }
      }
      return [] as Invoice[]
    };
    const findSiPrepayments = async (SI: SettlementInstruction): Promise<Prepayment[]> => {
    // all prepayment ids
      const prepaymentIds = SI.prepaymentAmountsRequested?.map(
      (entry: PrepaymentAmountRequested) => entry.prepaymentId
    );
      if (prepaymentIds && prepaymentIds.length > 0) {
        const criteria = prepaymentIds.map((id) => ({
          type: EXACT_CRITERION,
          value: {
            field: 'id',
            text: id,
          },
        }));
        try {
          const prepaymentFindResponse = await PrepaymentRecordkeeper.find({criteria, query: {}, deleted: false});
          const newPrepayments = [...prepaymentFindResponse.records]
          return newPrepayments
        } catch (e) {
          console.error('error finding prepayments: ', e.message || e);
          throw e;
        }
      }
      return [] as Prepayment[]
    };
    const findSiCounterparties = async (): Promise<Counterparty[]> => {
      try {
        const counterpartyFindResponse = await CounterpartyRecordkeeper.find({
          criteria: [],
        });
        return counterpartyFindResponse.records || [] as Counterparty[]
      } catch (e) {
        console.error(
          'Could not find request for fund counterparties: ' + e.message || e
        );
        throw e;
      }
    };
    const buildSummaries = async (SI: SettlementInstruction, siInvoices: Invoice[], siPrepayments: Prepayment[], siCounterparties: Counterparty[]) => {
      const invoiceRequestedAmountCounterpartySummary: InvoiceRequestedAmountCounterpartySummaryMap = 
    {} as InvoiceRequestedAmountCounterpartySummaryMap;
      if (SI.invoiceAmountsRequested) {
        for (const invoiceRequestedAmount of SI.invoiceAmountsRequested) {
          const entryInvoice = siInvoices.find(
            (inv: Invoice) => inv.id === invoiceRequestedAmount.invoiceId
          );
          if (!entryInvoice) {
            console.error('Could not find invoice in invoice cache.');
            throw new Error('Could not find invoice in invoice cache.');
          }
          const sign = getSignBasedOnType(entryInvoice)

          const invoiceCounterparty = siCounterparties.find(
            (c: Counterparty) => c.id === entryInvoice.counterpartyId
          );
          if (!invoiceCounterparty) {
            console.error(
              'Could not find invoice counterparty in counterparty cache.'
            );
            throw new Error(
              'Could not find invoice counterparty in counterparty cache.'
            );
          }
          if (invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id] === undefined) {
            invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id] = {
              counterparty: invoiceCounterparty,
              total: 0,
              localTotal: 0,
              invoicesAndInvoiceAmountsRequested: [] as InvoiceAndInvoiceAmountsRequested[],
            };
          }

          // correct for previously mis-captured amounts
          const amountRequested = invoiceRequestedAmount.amount < 0 ? -1 * invoiceRequestedAmount.amount : invoiceRequestedAmount.amount

          switch (SI.status) {
            case Status.Draft:
            case Status.Submitted:
            case Status.Processing:
              invoiceRequestedAmountCounterpartySummary[
                invoiceCounterparty.id
              ].localTotal += sign * amountRequested * (localToSIMTMRate !== undefined ? localToSIMTMRate : 0.0);
              break
            case Status.Complete: {
              const invoiceTradeLinks = SI.invoiceTradeLinks?.filter(
                (invoiceTradeLink: InvoiceTradeLink) =>
                  invoiceTradeLink.invoiceId === invoiceRequestedAmount.invoiceId
              );
              if (invoiceTradeLinks !== undefined) {
                invoiceRequestedAmountCounterpartySummary[
                  invoiceCounterparty.id
                ].localTotal += calculateLocalTotal(sign, invoiceTradeLinks)
              }
            }
              break
            default:
            // ??
          }
  
          invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id].total += sign * amountRequested;
  
        invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id].invoicesAndInvoiceAmountsRequested?.push({
          invoice: entryInvoice,
          amountRequested: {...invoiceRequestedAmount, amount: amountRequested},
        });
        }
      }

      const prepaymentRequestedAmountCounterpartySummary: PrepaymentRequestedAmountCounterpartySummaryMap = {} as PrepaymentRequestedAmountCounterpartySummaryMap;
      if (SI.prepaymentAmountsRequested) {
        for (const prepaymentAmountRequested of SI.prepaymentAmountsRequested) {
          const prepaymentAmountRequestedPrepayment = siPrepayments.find(
            (pp) => pp.id === prepaymentAmountRequested.prepaymentId
          );
          if (!prepaymentAmountRequestedPrepayment) {
            console.error('could not find prepayment in prepayment cache');
            throw new Error('could not find prepayment in prepayment cache');
          }
  
          const prepaymentCounterparty = siCounterparties.find(
            (b) => b.id === prepaymentAmountRequestedPrepayment.counterpartyId
          );
          if (!prepaymentCounterparty) {
            console.error(
              'could not find prepayment counterparty in counterparty cache'
            );
            throw new Error(
              'could not find prepayment counterparty in counterparty cache'
            );
          }
  
          if (!prepaymentRequestedAmountCounterpartySummary[prepaymentCounterparty.id]) {
            prepaymentRequestedAmountCounterpartySummary[
              prepaymentCounterparty.id
            ] = {
              counterparty: prepaymentCounterparty,
              total: 0,
              localTotal: 0,
              prepaymentsAndPrepaymentAmountsRequested: [],
            };
          }

          switch (SI.status) {
            case Status.Draft:
            case Status.Submitted:
            case Status.Processing:
              prepaymentRequestedAmountCounterpartySummary[
                prepaymentCounterparty.id
              ].localTotal += prepaymentAmountRequested.amount * (localToSIMTMRate !== undefined ? localToSIMTMRate : 0.0);
              break
            case Status.Complete: {
              const prepaymentTradeLinks = SI.prepaymentTradeLinks?.filter(
                (prepaymentTradeLink: PrepaymentTradeLink) =>
                  prepaymentTradeLink.prepaymentId === prepaymentAmountRequested.prepaymentId
              );
              if (prepaymentTradeLinks !== undefined) {
                prepaymentRequestedAmountCounterpartySummary[
                  prepaymentCounterparty.id
                ].localTotal += calculateLocalTotal(1, prepaymentTradeLinks);
              }
            }
              break
            default:
                // ??
          }

          prepaymentRequestedAmountCounterpartySummary[prepaymentCounterparty.id].prepaymentsAndPrepaymentAmountsRequested.push({
            prepayment: prepaymentAmountRequestedPrepayment,
            amountRequested: prepaymentAmountRequested,
          });
        }
      }

      setInvoiceRequestedAmountCounterpartySummaryMap(invoiceRequestedAmountCounterpartySummary)
      setPrepaymentRequestedAmountCounterpartySummaryMap(prepaymentRequestedAmountCounterpartySummary)
    };
    const processNewSI = async (SI: SettlementInstruction) => {
      setLoading(true)
      try {
        setLocalToSIMTMRate(await findMTMRate(SI))
        const siInvoices = await findSiInvoices(SI)
        const siPrepayments = await findSiPrepayments(SI)
        const siCounterparties = await findSiCounterparties()
        buildSummaries(SI, siInvoices, siPrepayments, siCounterparties).finally()
        setCurrentSI(SI)
      } catch (e) {
        setError(e.message || e)
      }
      setLoading(false)
    };
    processNewSI(initialSI).finally()
  }, [initialSI, appContext, localToSIMTMRate])

  const handleHideInvoiceDetail = () => {
    setViewInvoice(false)
  };

  const handleInvoiceSelect = (invoice: Invoice) => {
    setSelectedInvoice(invoice)
    setViewInvoice(true)
  };

  const handlePrepaymentSelect = (prepayment: Prepayment) => {
    setSelectedPrepayment(prepayment)
    setViewPrepaymentDialog(true)
  };

  const renderContent = () => {
    if (error) {
      return (
        <div
          style={{
            height: '100%',
            display: 'grid',
            alignContent: 'center',
            paddingBottom: '30px',
          }}
        >
          <ComponentLevelError errorMessage={error} />
        </div>
      );
    }

    if (loading) {
      return renderLoading();
    }

    return renderMain();
  };

  const getItemListHeight = (element: string | Node | null) => {
    if (element === null) {
      return
    }
    if (typeof(element) === 'string') {
      return
    }
    if (element.parentElement === null) {
      return
    }
    if (itemListHeight > 0) {
      // already set
      return
    }
    try {
      if (element && element.parentElement) {
        setItemListHeight(element.parentElement.clientHeight - 200)
      }
    } catch (e) {
      console.error('error setting item list height');
    }
  };

  const renderMain = () => {
    let total = 0;
    try {
      total += Object.values(invoiceRequestedAmountCounterpartySummaryMap).reduce((total, summary) => {return total + summary.total}, 0);
      total += Object.values(prepaymentRequestedAmountCounterpartySummaryMap).reduce((total, summary) => total + summary.total, 0);
    } catch (e) {
      console.error('error calculating total', e);
    }
    let localTotal = 0;
    try {
      localTotal += Object.values(
        invoiceRequestedAmountCounterpartySummaryMap
      ).reduce((localTotal, summary) => localTotal + summary.localTotal, 0);
      localTotal += Object.values(
        prepaymentRequestedAmountCounterpartySummaryMap
      ).reduce((localTotal, summary) => localTotal + summary.localTotal, 0);
    } catch (e) {
      console.error('error calculating total', e);
    }
    const currencyIsoCode =
      (appContext.currencies.find((c) => c.id === currentSI.currencyId) || {})
        .isoCode || '';
    const localCurrencyIsoCode =
      (appContext.currencies.find((c) => c.id === appContext.localCurrency.id) || {}).isoCode || '';

    const displayLocalCurrency = currentSI.currencyId !== appContext.localCurrency.id;

    return (
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',})}
        ref={(element: string | Node | null) => {
          getItemListHeight(element)
          if (element) {
            setScrollbarRef(element as any)
          }
        }}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',})}>
          <Typography variant={'subtitle1'}>
            {processUnixDateForViewing(currentSI.date)}
          </Typography>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between',
            })}>
            <Typography variant={'subtitle1'}>
              {`${currencyIsoCode} Total: ${displayCurrencyAmount(total)}`}
            </Typography>
            {displayLocalCurrency && (
              <div>
                {localTotal !== 0 ? (
                  <Typography variant={'subtitle1'}>
                    {`ZAR Total: ${displayCurrencyAmount(localTotal)}`}
                  </Typography>
                ) : localToSIMTMRate ? (
                  <Typography variant={'subtitle1'}>
                    {`ZAR Total: ${displayCurrencyAmount(total * localToSIMTMRate)}`}
                  </Typography>
                ) : (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto 20px',
                    }}
                  >
                    <div>
                      <Typography variant={'subtitle1'}>
                        {'ZAR Total:'}
                      </Typography>
                    </div>
                    <div
                      className={css({
                        textAlign: 'right',
                        minWidth: 120,})}
                    >
                      <Tooltip title="Prices Unavailable">
                        <Icon
                          className={css({
                            padding: '0',
                            color: theme.palette.error.main,})
                          }>
                          <PricesUnavaliableIcon fontSize={'medium'} />
                        </Icon>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <DividingLine theme={theme} />
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
          })}
          style={{ height: itemListHeight }}>
          {(() => {
            if (
              Object.keys(invoiceRequestedAmountCounterpartySummaryMap).length ===
                0 &&
              Object.keys(prepaymentRequestedAmountCounterpartySummaryMap)
                .length === 0
            ) {
              return (
                <Typography
                  key={'empty'}
                  style={{ textAlign: 'center' }}
                  variant={'overline'}
                >
                  {'No entries'}
                </Typography>
              );
            }
            return (
              <React.Fragment>
                <div
                  className={css({
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '5px',
                  })}
                >
                  <Typography variant={'subtitle1'}>Invoices</Typography>
                </div>
                {Object.keys(invoiceRequestedAmountCounterpartySummaryMap).map(
                  (counterpartyId, idx) => (
                    <InvoiceRequiredAmountCounterpartySummaryItem
                      SI={initialSI}
                      counterpartySummary={
                        invoiceRequestedAmountCounterpartySummaryMap[
                          counterpartyId
                        ]
                      }
                      displayLocalCurrency={displayLocalCurrency}
                      invoiceTradeLinks={
                        currentSI.invoiceTradeLinks
                      }
                      key={idx}
                      localCurrencyISO={localCurrencyIsoCode}
                      onInvoiceRemove={(id: string) => onInvoiceRemove ? onInvoiceRemove(id) : undefined}
                      onInvoiceSelect={handleInvoiceSelect}
                      rate={localToSIMTMRate}
                      readOnly={readOnly}
                      siCurrencyISO={currencyIsoCode}
                    />
                  )
                )}
                <DividingLine theme={theme} />
                <div
                  className={css({
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '5px',})}>
                  <Typography variant={'subtitle1'}>Prepayments</Typography>
                </div>
                {Object.keys(prepaymentRequestedAmountCounterpartySummaryMap).map(
                  (counterpartyId, idx) => (
                    <PrepaymentRequiredAmountCounterpartySummaryItem
                      counterpartySummary={
                        prepaymentRequestedAmountCounterpartySummaryMap[
                          counterpartyId
                        ]
                      }
                      displayLocalCurrency={displayLocalCurrency}
                      key={idx}
                      localCurrencyISO={localCurrencyIsoCode}
                      onPrepaymentRemove={(id: string) => onPrepaymentRemove ? onPrepaymentRemove(id) : undefined}
                      onPrepaymentSelect={handlePrepaymentSelect}
                      prepaymentTradeLinks={
                        currentSI.prepaymentTradeLinks
                      }
                      rate={localToSIMTMRate}
                      readOnly={readOnly}
                      siCurrencyISO={currencyIsoCode}
                    />
                  )
                )}
                <DividingLine theme={theme} />
              </React.Fragment>
            );
          })()}
        </div>
      </div>
    );
  };

  const renderDialogs = () => {
    return (
      <React.Fragment>
        {viewInvoice && selectedInvoice && (
          <InvoiceDetail
            invoice={selectedInvoice}
            onClose={handleHideInvoiceDetail}
            readOnly
            show={viewInvoice}
          />
        )}
        {viewPrepaymentDialog && (
          <PrepaymentDetailDialog
            closeDialog={() => setViewPrepaymentDialog(false)}
            prepayment={selectedPrepayment}
            readOnly
            show={viewPrepaymentDialog}
          />
        )}
      </React.Fragment>
    );
  };

  const renderLoading = () => {
    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          alignContent: 'center',
          paddingBottom: '30px',
        }}
      >
        <ComponentLevelLoader
          color={'black'}
          isLoading />
      </div>
    );
  };

  return (
    <div ref={getItemListHeight}>
      <AppBar position="static">
        <Toolbar className={css({display: 'flex', justifyContent: 'space-between'})}>
          <div><Typography variant={'h5'}>Summary</Typography></div>
          {readOnly && currentSI.status === Status.Complete && 
            <div>
              <ActionButton 
                disabled={false}
                helpText={'Download Confirmation'}
                icon={<GetApp />}
                id={'download-details'}
                onClick={() => onDownload ? onDownload() : undefined}
              />
            </div>}
        </Toolbar>
      </AppBar>
      {renderContent()}
      {renderDialogs()}
    </div>
  )
}

export default SettlementInstructionSummary;

const InvoiceRequiredAmountCounterpartySummaryItem = (props: {
  counterpartySummary: InvoiceRequestedAmountCounterpartySummary;
  displayLocalCurrency: boolean;
  readOnly?: boolean;
  onInvoiceRemove: (invoiceId: string) => void;
  onInvoiceSelect: (invoice: Invoice) => void;
  siCurrencyISO: string;
  localCurrencyISO: string;
  invoiceTradeLinks: InvoiceTradeLink[]|undefined;
  rate: number|undefined;
  SI: SettlementInstruction;
}) => {
  const {
    counterpartySummary,
    displayLocalCurrency,
    readOnly,
    onInvoiceRemove: onInvoiceDeselect,
    onInvoiceSelect,
    siCurrencyISO,
    localCurrencyISO,
    invoiceTradeLinks,
    SI
  } = props;

  const [css] = useStyletron()
  const theme = useTheme<CustomTheme>()

  return (
    <div 
      className={css({
        display: 'grid',
        gridTemplateRows: '30px auto auto 30px',
        paddingBottom: '20px',
      })}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `1fr 1fr 1fr 1fr ${
            displayLocalCurrency ? '1fr' : ''
          }`,
          padding: '0 10px',
        }}
      >
        <Typography
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {counterpartySummary.counterparty.name}
        </Typography>
        <Typography>{'Due Date'}</Typography>
        <Typography>{'Effective Rate'}</Typography>
        <Typography style={{ textAlign: 'right' }}>
          {`Amount ${siCurrencyISO}`}
        </Typography>
        {displayLocalCurrency && (
          <Typography style={{ textAlign: 'right' }}>
            {`Amount ${localCurrencyISO}`}
          </Typography>
        )}
      </div>
      <div>
        {counterpartySummary.invoicesAndInvoiceAmountsRequested.map(
          (li, idx) => {
            const sign = getSignBasedOnType(li.invoice)
            const fxAmount = sign * li.amountRequested.amount
            // determine local amount
            let localAmount = 0.0
            switch (SI.status) {
              case Status.Draft:
              case Status.Submitted:
              case Status.Processing:
                localAmount = props.rate ? sign * li.amountRequested.amount * props.rate : 0.0
                break
              case Status.Complete: {
                const links = invoiceTradeLinks?.filter(
                  (invoiceTradLink: InvoiceTradeLink) => invoiceTradLink.invoiceId === li.amountRequested.invoiceId)
                if (links !== undefined && links.length > 0) {
                  localAmount = calculateLocalTotal(sign, links)
                }
              }
                break
            }
            return (
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  margin: '0',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: theme.palette.background.default,
                  },})}
                key={idx}
                onClick={() => onInvoiceSelect(li.invoice)}
                style={{
                  display: 'grid',
                  gridTemplateColumns: `1fr 1fr 1fr 1fr ${
                    displayLocalCurrency ? '1fr' : ''
                  }`,
                  padding: '0 10px',
                }}
              >
                <div>{li.invoice.number}</div>
                <div>{processUnixDateForViewing(li.invoice.dueDate)}</div>
                <div>{displayRate(li.invoice.effectiveRate)}</div>
                <div style={{ textAlign: 'right' }}>
                  {displayAmount(Big(fxAmount))}
                </div>
                {displayLocalCurrency && (
                  <div
                    className={css({
                      textAlign: 'right',
                      minWidth: 120,})}>
                    {displayAmount(Big(localAmount))}
                  </div>
                )}
                {!readOnly && (
                  <IconButton
                    className={css({
                      padding: '0',
                      color: theme.palette.error.main,
                    })}
                    onClick={(e) => {
                      onInvoiceDeselect(li.invoice.id);
                      e.stopPropagation();
                    }}
                  >
                    <DeselectIcon fontSize={'small'} />
                  </IconButton>
                )}
              </div>
            );
          }
        )}
      </div>
      <DividingLine theme={theme} />
      <div
        className={css({
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '3px 10px',
          fontWeight: 'bold',})}>
        <div style={{ paddingRight: '10px' }}>
          {displayCurrencyAmount(counterpartySummary.total)}
        </div>
        {displayLocalCurrency && (
          <div
            className={css({
              textAlign: 'right',
              minWidth: 120,})}>
            {counterpartySummary.localTotal !== 0 ? (
              displayCurrencyAmount(counterpartySummary.localTotal)
            ) : props.rate ? displayCurrencyAmount(counterpartySummary.total * props.rate)
              : (
                <Tooltip title="Prices Unavailable">
                  <Icon 
                    className={css({
                      padding: '0',
                      color: theme.palette.error.main,})}>
                    <PricesUnavaliableIcon fontSize={'medium'} />
                  </Icon>
                </Tooltip>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

const PrepaymentRequiredAmountCounterpartySummaryItem = (props: {
  counterpartySummary: PrepaymentRequestedAmountCounterpartySummary;
  displayLocalCurrency: boolean;
  readOnly?: boolean;
  onPrepaymentSelect: (p: Prepayment) => void;
  onPrepaymentRemove: (id: string) => void;
  siCurrencyISO: string;
  localCurrencyISO: string;
  prepaymentTradeLinks: PrepaymentTradeLink[]|undefined;
  rate: number|undefined;
}) => {
  const {
    counterpartySummary,
    displayLocalCurrency,
    readOnly,
    onPrepaymentSelect,
    onPrepaymentRemove,
    siCurrencyISO,
    localCurrencyISO,
    prepaymentTradeLinks,
  } = props;

  const [css] = useStyletron()
  const theme = useTheme<CustomTheme>()

  return (
    <div
      className={css({
        display: 'grid',
        gridTemplateRows: '30px auto auto 30px',
        paddingBottom: '20px',})}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `1fr 1fr 1fr ${
            displayLocalCurrency ? '1fr' : ''
          }`,
          padding: '0 10px',
        }}
      >
        <Typography
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {counterpartySummary.counterparty.name}
        </Typography>
        <Typography style={{ textAlign: 'right' }}>{'Due Date'}</Typography>
        <Typography style={{ textAlign: 'right' }}>
          {`Amount ${siCurrencyISO}`}
        </Typography>
        {displayLocalCurrency && (
          <Typography style={{ textAlign: 'right' }}>
            {`Amount ${localCurrencyISO}`}
          </Typography>
        )}
      </div>
      <div>
        {counterpartySummary.prepaymentsAndPrepaymentAmountsRequested.map(
          (li, idx) => {
            return (
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  padding: '3px 10px',
                  margin: '0',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: theme.palette.background.default,
                  },})}
                key={idx}
                onClick={() => onPrepaymentSelect(li.prepayment)}
              >
                {!readOnly && (
                  <IconButton
                    className={css({
                      padding: '0',
                      color: theme.palette.error.main,})}
                    onClick={(e) => {
                      onPrepaymentRemove(li.prepayment.id);
                      e.stopPropagation();
                    }}
                  >
                    <DeselectIcon fontSize={'small'} />
                  </IconButton>
                )}
                <div>{li.prepayment.number}</div>
                <div style={{ textAlign: 'right' }}>
                  {processUnixDateForViewing(li.prepayment.dueDate)}
                </div>
                <div style={{ textAlign: 'right' }}>
                  {displayCurrencyAmount(li.amountRequested.amount)}
                </div>
                {displayLocalCurrency && (
                  <div 
                    className={css({
                      textAlign: 'right',
                      minWidth: 120,})}>
                    {prepaymentTradeLinks?.find(
                      (prepaymentTradeLink) =>
                        prepaymentTradeLink.prepaymentId ===
                        li.amountRequested.prepaymentId
                    ) !== undefined ? (
                        displayCurrencyAmount(
                          calculateLocalTotal(1, 
                            prepaymentTradeLinks?.filter(
                              (prepaymentTradeLink) =>
                                prepaymentTradeLink.prepaymentId ===
                              li.amountRequested.prepaymentId
                            )
                          ))
                      ) : props.rate ? displayCurrencyAmount(counterpartySummary.localTotal) : (
                        <Tooltip title="Prices Unavailable">
                          <Icon 
                            className={css({
                              padding: '0',
                              color: theme.palette.error.main,})}>
                            <PricesUnavaliableIcon fontSize={'medium'} />
                          </Icon>
                        </Tooltip>
                      )}
                  </div>
                )}
              </div>
            );
          }
        )}
      </div>
      <DividingLine theme={theme} />
      <div 
        className={css({
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '3px 10px',
          fontWeight: 'bold',})}>
        <div style={{ paddingRight: '10px' }}>
          {displayCurrencyAmount(counterpartySummary.total)}
        </div>
        {displayLocalCurrency && (
          <div 
            className={css({
              textAlign: 'right',
              minWidth: 120,
            })}>
            {counterpartySummary.localTotal !== 0 ? displayCurrencyAmount(counterpartySummary.localTotal)
              : props.rate ? displayCurrencyAmount(counterpartySummary.total * props.rate) : (
                <Tooltip title="Prices Unavailable">
                  <Icon 
                    className={css({
                      padding: '0',
                      color: theme.palette.error.main,
                    })}>
                    <PricesUnavaliableIcon fontSize={'medium'} />
                  </Icon>
                </Tooltip>
              )}
          </div>
        )}
      </div>
    </div>
  );
};