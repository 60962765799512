// PARTY TYPES
export const PARTY_TYPE_COMPANY = 'COMPANY'
export const PARTY_TYPE_PROCESSING_ORG = 'PROCESSING_ORG'
export const PARTY_TYPE_SYSTEM = 'SYSTEM'
export const PARTY_TYPE_INDIVIDUAL = 'INDIVIDUAL'

export const SystemDateFormat = 'YYYY-MM-DD'
export const SystemDateTimeFormat = 'YYYY-MM-DD HH:mm:ss'

export const ORDER = 'ORDER'
export const TRADE = 'TRADE'
export const BUY = 'BUY'
export const SELL = 'SELL'
export const SWAP = 'SWAP'
