import React from 'react'
import PropTypes from 'prop-types'
import {Card, withStyles,} from '@material-ui/core'

const styles = (theme) => ({
  root: {
    padding: '8px',
    transition: 'content 2s',
    borderWidth: '6px 0 0 0',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
})

// ReportCard is a wrapper component card for reports
const ReportCard = props => {
  const {
    classes,
    ...rest
  } = props

  return (
    <Card
      className={classes.root}
      {...rest}
    >
      {props.children}
    </Card>
  )
}

ReportCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  classes: PropTypes.object,
  options: PropTypes.array,
  title: PropTypes.string,
}

ReportCard.defaultProps = {
  options: [],
}

const StyledReportCard = withStyles(styles)(ReportCard)
export default StyledReportCard
