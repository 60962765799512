import React from 'react'
import moment from 'moment'
import {
  Button,
  CardHeader,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  withTheme,
} from '@material-ui/core'
import {MdClear as CloseIcon,} from 'react-icons/md'

import ReportCard from 'components/ReportCard/ReportCard'
// flow types
import {processUnixDateForViewing} from '../../../utils/Utils'


function NewSettlementInstructionCreateInfo() {
  return {
    date: 0,
    currencyId: '',
  }
}

class AddNewSiDialog extends React.Component {
  state = {
    settlementInstructionCreateInfo: NewSettlementInstructionCreateInfo(),
    invalidFields: {},
  }

  handleSubmit = async () => {
    const {
      settlementInstructionCreateInfo,
    } = this.state
    try {
      this.props.onSubmit(settlementInstructionCreateInfo)
    } catch (invalidFields) {
      this.setState({invalidFields})
    }
  }

  handleChange = (field, value) => {
    const {
      settlementInstructionCreateInfo,
      invalidFields,
    } = this.state

    this.setState({
      settlementInstructionCreateInfo: {
        ...settlementInstructionCreateInfo,
        [field]: value,
      },
      invalidFields: {
        ...invalidFields,
        [field]: undefined,
      },
    })
  }

  render() {
    const {
      settlementInstructionCreateInfo,
      invalidFields,
    } = this.state

    const {
      currencies,
      show,
      importExport,
    } = this.props

    const currencyOptions = currencies.map(
      c => ({value: c.id, label: c.isoCode}))

    return (
      <Dialog
        open={show}
      >
        <ReportCard>
          <div>
            <CardHeader
              action={this.renderIcons()}
              title={`Create New ${importExport ? importExport : ''} Settlement Instruction`}
            />
            <div
              style={{
                margin: '30px',
              }}
            >
              <ItemWrapper
                error={invalidFields.currencyId}
                label={'Currency'}
              >
                <Select
                  fullWidth
                  onChange={event => this.handleChange('currencyId',
                    event.target.value)}
                  options={currencyOptions}
                  value={settlementInstructionCreateInfo.currencyId || ''}
                >
                  {currencies.map((c, i) => (
                    <MenuItem
                      key={i}
                      value={c.id}>{c.isoCode}</MenuItem>))}
                </Select>
              </ItemWrapper>
              <ItemWrapper
                error={invalidFields.date}
                label={'Delivery Date'}
              >
                <Input
                  fullWidth
                  inputProps={{
                    max: '9999-12-31'
                  }}
                  onChange={event => this.handleChange('date',
                    moment.utc(event.target.value).unix())}
                  type={'date'}
                  value={processUnixDateForViewing(
                    settlementInstructionCreateInfo.date)}
                />
              </ItemWrapper>
              <div
                style={{
                  textAlign: 'center',
                  margin: '10px',
                }}>
                <Button
                  onClick={this.handleSubmit}
                  style={{width: '100%'}}>{'Create'}</Button>
              </div>
            </div>
          </div>
        </ReportCard>
      </Dialog>
    )
  }

  renderIcons = () => {
    const items = []
    const {closeDialog} = this.props

    items.push(
      <Tooltip
        aria-label="Close"
        enterDelay={500}
        key={'Close'}
        title="Close">
        <IconButton onClick={closeDialog}>
          <CloseIcon/>
        </IconButton>
      </Tooltip>,
    )

    return items
  }

  validate = () => {
    return new Promise((resolve, reject) => {
      const {
        settlementInstructionCreateInfo,
      } = this.state

      const invalidFields = {}

      if (!settlementInstructionCreateInfo.currencyId ||
        settlementInstructionCreateInfo.currencyId === '') {
        invalidFields.currencyId = 'must be set'
      }

      if (!settlementInstructionCreateInfo.date || settlementInstructionCreateInfo.date ===
        0) {
        invalidFields.date = 'invalid'
      }

      if (Object.keys(invalidFields).length !== 0) {
        reject(invalidFields)
      } else {
        resolve(undefined)
      }
    })
  }
}

AddNewSiDialog.defaultProps = {
  currencies: [],
  closeDialog: () => null,
}

export default withTheme(AddNewSiDialog)

const ItemWrapper = (props) => {
  return (
    <Grid item>
      <div
        style={{
          height: props.error ? '45px' : '31px',
          display: 'grid',
          gridGap: '20px',
          gridTemplateColumns: '1fr 1fr',
          marginLeft: '40px',
          marginRight: '40px',
        }}
      >
        <Typography variant={'subtitle1'}>
          <div>{props.label}</div>
        </Typography>
        <div>
          {props.children}
          {props.error &&
          <FormHelperText
            error
            id="helperText">
            {props.error}
          </FormHelperText>}
        </div>
      </div>
    </Grid>
  )
}
