import React from 'react'
import {
  Chip,
  withStyles,
  Button,
} from '@material-ui/core'
import AsyncSelect from 'react-select/async'
import Select, {components} from 'react-select'
import {MdMoreVert as MoreVert} from 'react-icons/md'
import {EXACT_CRITERION, TEXT_CRITERION} from 'popcorn-js/search/criteria/types'
const styles = (theme) => ({
  blankFieldsButton: {
    color: theme.palette.text.primary,
    background: theme.palette.primary.main,
    width: '100%',
  },
})

const selectStyles = (theme) => ({
  valueContainer: styles => ({
    ...styles,
    border: 'none',
    padding: '0 4px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '6px',
  }),
  option: (styles, {isSelected, isFocused}) => ({
    ...styles,
    border: 'none',
    color: isSelected ? 'black' : theme.palette.primary.contrastText,
    backgroundColor: isSelected ? 'white' : isFocused ? theme.palette.primary.light :  theme.palette.primary.main,
  }),
  control: styles => ({
    ...styles,
    minHeight: '1px',
    border: 'none',
    padding: '0',
    borderRadius: '0',
    backgroundColor: 'none',
  }),
  input: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    color: theme.palette.text.primary,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
  }),
  singleValue: (styles, {data}) => {
    return ({
      ...styles,
      color: data.label === 'Blank Fields' || data.label === 'start typing...' ? theme.palette.text.secondary : theme.palette.text.primary,
      fontStyle: data.label === 'Blank Fields' || data.label === 'start typing...' ? 'italic' : ''
    })
  },
})

const BlankFieldsValue = 'Blank Fields'

class Filter extends React.Component  {
  state = {
    filter: [],
  }

  componentDidMount() {
    this.handleChange()
  }

  render() {
    const {
      filters,
      show,
    } = this.props

    const {
      filter,
    } = this.state

    if (show === false) {
      return <div/>
    }

    return (
      <div>
        {filters.map((f, i) => {
          return (
            <LineItem
              asyncOptionsFetcher={f.asyncOptionsFetcher}
              classes={this.props.classes}
              criterionType={f.criterionType}
              field={f.field}
              filterType={f.filterType}
              items={filter.filter(fi => fi.field === f.field)}
              key={i}
              label={f.label}
              onRemove={this.removeFilterValue}
              onSubmit={this.addFilterValue}
              selectOptions={f.selectOptions || []}
              theme={this.props.theme}
            />
          )
        })}
      </div>
    )
  }

  addFilterValue = (criterionType, field, value, chipLabel) => {
    const {filter} = this.state
    if (!filter.find(f => f.field === field && f.value === value)) {
      if (!chipLabel) {
        chipLabel = value
      }
      filter.push({criterionType, field, value, chipLabel})
      this.setState({
        filter,
      }, () => this.handleChange(filter))
    }
  }

  removeFilterValue = (field, value) => {
    const {
      filter: oldFilter,
    } = this.state

    const filter = oldFilter.filter(
      f => !(f.field === field && f.value === value))

    this.setState({
      filter,
    }, () => this.handleChange(filter))
  }

  handleChange = (filter = []) => {
    // Building the criteria
    const criteria = []
    const defaultCriteria = this.props.defaultFilter || []
    criteria.push(...defaultCriteria)

    if (filter.length === 0) {
      this.props.onChange({criteria, filter})
    }

    for (const f of filter) {
      switch (f.criterionType) {
        case TEXT_CRITERION:
          criteria.push({
            type: TEXT_CRITERION,
            value: {field: f.field, text: f.value},
          })
          break
        case EXACT_CRITERION:
          criteria.push({
            type: EXACT_CRITERION,
            value: {field: f.field, text: f.value},
          })
          break
        default:
      }
    }
    this.props.onChange({criteria, filter})
  }
}

const StyledFilter = withStyles(styles, {withTheme: true})(Filter)
export default StyledFilter

class LineItem extends React.Component {
  state = {
    value: '',
    menuIsOpen: false,
  }

  handleUpdate(value) {
    this.setState({
      menuIsOpen: false,
      value,
    })
  }

  handleSubmit = (value, chipLabel) => {
    const {
      field,
      criterionType,
    } = this.props

    if (chipLabel === BlankFieldsValue) {
      this.props.onSubmit(EXACT_CRITERION, field, value, chipLabel)
    }

    this.props.onSubmit(criterionType, field, value, chipLabel)
    this.setState({value: ''})
  }

  handleDelete = (item) => {
    this.props.onRemove(item.field, item.value)
  }

  render() {
    const {
      menuIsOpen,
    } = this.state

    const {
      label,
      items,
      filterType,
      selectOptions,
      theme,
      asyncOptionsFetcher,
      classes
    } = this.props

    const MenuList = (props) => {
      return (
        <components.MenuList {...props}>
          <div
            onClick={() => {
              this.setState({
                menuIsOpen: false,
              })
              this.handleSubmit('', BlankFieldsValue)
            }}
            style={{
              cursor: 'pointer',
            }}
          >
            <Button
              className={classes.blankFieldsButton}
              size="small"
            >
              Search Blank Fields
            </Button>
          </div>
          {props.children}
        </components.MenuList>
      )
    }

    const NoOptionsMessage = () => {
      return (
        <div/>
      )
    }

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <MoreVert
            onClick={() => {
              this.setState({
                menuIsOpen: !this.state.menuIsOpen,
              })
            }}
            style={{
              height: '24px',
            }}
          />
        </components.DropdownIndicator>
      )
    }

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 1fr',
          gridColumnGap: '10px',
        }}
      >
        <div>
          {filterType === 'text' &&
          <Select
            components={{MenuList, NoOptionsMessage, DropdownIndicator}}
            menuIsOpen={menuIsOpen}
            onInputChange={value => {
              this.setState({
                menuIsOpen: false,
              })
              this.handleUpdate(value)
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this.handleSubmit(e.target.value)
              }
            }}
            placeholder={label}
            styles={selectStyles(theme)}
          />
          }
          {filterType === 'select' &&
          <Select
            components={{MenuList}}
            onChange={(selected) => this.handleSubmit(selected.value)}
            onInputChange={value => {
              this.handleUpdate(value)
            }}
            options={selectOptions}
            placeholder={'label'}
            styles={selectStyles(theme)}
            value={{value: '', label: 'Type'}}
          />
          }
          {filterType === 'asyncSelect' &&
          <div>
            <AsyncSelect
              components={{MenuList}}
              defaultOptions={[{value: '', label: 'Start typing...'}]}
              loadOptions={asyncOptionsFetcher}
              menuPosition={'fixed'}
              onChange={selected => this.handleSubmit(selected.value,
                selected.label)}
              styles={selectStyles(theme)}
              value={{value: '', label}}
            />
          </div>
          }
        </div>
        <div
          style={{
            overflowX: 'scroll',
          }}
        >
          {items.map((item, i) => {
            return (
              <Chip
                key={i}
                label={ChipLabel({filterType, item, selectOptions})}
                onDelete={() => this.handleDelete(item)}
                style={{
                  margin: '0 4px',
                  fontStyle: item.chipLabel === BlankFieldsValue ?
                    'italic' :
                    '',
                  color: item.chipLabel === BlankFieldsValue ? '#707070' : '',
                }}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

const ChipLabel = (props) => {
  switch (props.filterType) {
    case 'text': {
      return props.item.chipLabel ? props.item.chipLabel : props.item.value
    }
    case 'select': {
      if (props.item.chipLabel) {
        return props.item.chipLabel
      }
      return ((props.selectOptions || []).find(
        option => option.value === props.item.value) || {}).label || '-'
    }
    case 'asyncSelect': {
      return props.item.chipLabel
    }

    default:
      return '-'
  }
}
