import React from 'react'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './toaster.css'

const Toaster = (props) => {
  return <ToastContainer position={'top-left'}/>
}

export default Toaster
