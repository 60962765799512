import React, {useState} from 'react'
import {GeneratorTS as Generator} from 'popcorn-js/report'
import {IconButton, Typography, Card, CardHeader, Theme} from '@material-ui/core'
import {MdClose as CloseIcon, MdImportExport as ExportIcon,} from 'react-icons/md'
import {RefreshIcon} from 'components/Icons'
import ErrorIcon from 'components/Icons/ErrorIcon/ErrorIcon'
import CardContent from '@material-ui/core/CardContent'
import CashFlowGraph from './CashFlowGraph'
import {ComponentLevelLoader} from 'components/Loader/Loader'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {useSelector} from 'react-redux'
import {
  viewExportMonthViewCashFlowReport,
  viewWeekViewCashFlowReport,
} from 'permissions/permissionTypes'
import CashFlowTotals from 'views/Home/Company/Dashboard/MainCashFlowGraph/CashFlowTotals'
import NetTradeCashFlowsTicket from 'views/Home/Company/Dashboard/MainCashFlowGraph/NetTradeCashFlowsTicket'
import SplitButton from 'views/Home/Company/Dashboard/MainCashFlowGraph/SplitButton'
import {usePerfectScrollbar} from 'hooks'
import {useService} from 'hooks/useService'
import {exportReport} from 'views/Home/Company/Dashboard/exportReport'
import LightTooltip from 'components/LightTooltp/LightTooltip'
import {Currency} from 'popcorn-js/financial/currency/currencyType';
import WeekViewCashFlowGraph from './WeekViewCashFlowGraph';
import {BarChart, IconType, Legend} from 'recharts';
import {GenerateMonthViewCashFlowReportResponse} from 'popcorn-js/report/generatorTS';

const useStyles = makeStyles((theme: Theme) => ({
  progressWrapper: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    height: '480px',
  },
  cardTitle: {
    width: 'max-content',
    display: 'grid',
    gridTemplateColumns: 'auto 130px',
    gridColumnGap: theme.spacing()
  },
  cardContentRoot: {
    width: 'calc(100%)',
    overflow: 'hidden',
  },
  cardRoot: {
    background: theme.palette.primary.main
  },
  cardHeading: {
    background: theme.palette.paper.default
  },
}
))

interface MainCashFlowGraphLayoutProps {
  localCurrency: Currency,
  onClose: () => void,
}

const MainCashFlowGraphLayout: React.FC<MainCashFlowGraphLayoutProps> = (props: MainCashFlowGraphLayoutProps) => {
  const {localCurrency} = props
  const theme = useTheme()

  const canExport = useSelector(state => {
    try {
      // eslint-disable-next-line
      // @ts-ignore
      const permissions = state.security.viewPermissions
      return permissions.includes(viewExportMonthViewCashFlowReport)
    } catch (e) {
      console.error('error getting party\'s local currency', e)
    }
  })

  const canViewWeekViewCashFlowGraph = useSelector(state => {
    try {
      // eslint-disable-next-line
      // @ts-ignore
      const permissions = state.security.viewPermissions
      return permissions.includes(viewWeekViewCashFlowReport)
    } catch (e) {
      console.error('error getting party\'s local currency', e)
    }
  })

  const paymentOptions = ['MONTH']
  if (canViewWeekViewCashFlowGraph) {
    paymentOptions.push('WEEK')
  }

  const classes = useStyles({})
  const setScrollBarElementRef = usePerfectScrollbar(false, true)
  const setScrollBarElementRef2 = usePerfectScrollbar(false, true)

  const [aggregationOption, setAggregationOption] = useState(paymentOptions[0])

  const service = () => {
    switch (aggregationOption) {
      case 'WEEK':
        return Generator.GenerateWeekViewCashFlowReport
      case 'MONTH':
      default:
        return Generator.GenerateMonthViewCashFlowReport
    }
  }

  const [
    {
      response,
      loading,
      error,
    },
    setMonthViewReportRequest,
  ] = useService({}, service())
  const report = response || {}
  const additionalActions = []

  const showViewOptions = canViewWeekViewCashFlowGraph
  const showNetTradeCashFlows = true

  if (canExport) {
    additionalActions.push(
      <LightTooltip
        key={1}
        title={'Export'}
      >
        <span>
          <IconButton
            disabled={loading || error !== ''}
            onClick={(): void => exportReport(response)}
          >
            <ExportIcon/>
          </IconButton>
        </span>
      </LightTooltip>
    )
  }

  additionalActions.push(
    <LightTooltip
      key={3}
      title={'Refresh'}
    >
      <span>
        <IconButton
          disabled={loading}
          onClick={(): void => setMonthViewReportRequest({})}
        >
          <RefreshIcon/>
        </IconButton>
      </span>
    </LightTooltip>,
    <LightTooltip
      key={2}
      title={'Exit'}
    >
      <IconButton
        onClick={() => props.onClose()}
      >
        <CloseIcon/>
      </IconButton>
    </LightTooltip>
  )


  const graphLegend = (width: number, report: GenerateMonthViewCashFlowReportResponse) => {
    const totals = (report || {}).totals || {}

    const salesDue = totals.salesDue?.totalAmountInCostCurrency
    const purchaseDue = totals.purchaseDue?.totalAmountInCostCurrency

    const purchaseOverDue = totals.purchaseOverDue?.totalAmountInCostCurrency
    const salesOverDue = totals.salesOverDue?.totalAmountInCostCurrency

    const purchaseHedge = totals.purchaseHedge?.totalAmountInCostCurrency
    const salesHedge = totals.salesHedge?.totalAmountInCostCurrency

    const showPurchaseTotals = (purchaseDue !== 0 || purchaseOverDue !== 0 || purchaseHedge !==0)
    const showSalesTotals = (salesDue !== 0 || salesOverDue !== 0 || salesHedge !==0)
    const showOverDue = (showPurchaseTotals || showSalesTotals)
    const showHedges = (showPurchaseTotals || showSalesTotals)

    const t: IconType = 'circle'
    const legendPayload = []
    if (showPurchaseTotals) {
      legendPayload.push({id: '3', value: 'Purchase Contracts', type: t, color: theme.palette.import.main})
    }
    if (showSalesTotals) {
      legendPayload.push({id: '2', value: 'Sales Contracts', type: t, color: theme.palette.export.main})
    }
    if (showOverDue) {
      legendPayload.push({id: '1', value: 'OverDue Contracts', type: t, color: theme.palette.data.graphB})
    }
    if (showHedges) {
      legendPayload.push({id: '4', value: 'Future Net Trade Cash Flows', type: t, color: theme.palette.data.graphC})
    }

    return (
      <div>
        <BarChart
          height={50}
          width={width}
        >
          <Legend
            margin={{top: 8, right: 8, bottom: 8, left: 8}}
            payload={legendPayload}
            width={700}
            wrapperStyle={{
              background: theme.palette.paper.default,
              borderRadius: '3px',
              padding: theme.spacing(),
              left: '270px',
            }}/>
        </BarChart>
      </div>
    )
  }

  return (
    <Card
      classes={{root: classes.cardRoot}}
    >
      <CardHeader
        action={
          <React.Fragment>
            {additionalActions}
          </React.Fragment>
        }
        classes={{root: classes.cardHeading}}
        title={
          <div
            className={classes.cardTitle}
          >
            <Typography
              style={{padding: '3px'}}
              variant={'h5'}>{canViewWeekViewCashFlowGraph ? 'Cash Flows per ' : 'Cash Flows per Month'}
            </Typography>
            <div>
              {showViewOptions && <SplitButton
                disableDropdown={!canViewWeekViewCashFlowGraph}
                disabled={!canViewWeekViewCashFlowGraph}
                initialOption={aggregationOption}
                menuOptions={paymentOptions}
                onSelect={(e: string): void => setAggregationOption(e)}
              />}
            </div>
          </div>
        }
      />
      <CardContent>
        {((): React.ReactNode => {
          switch (true) {
            case loading:
              return (
                <div className={classes.progressWrapper}>
                  <ComponentLevelLoader
                    color={'white'}
                    isLoading
                  />
                </div>
              )
            case error !== '':
              return (
                <div>
                  <ErrorIcon/>
                  <div>
                    {error}
                  </div>
                </div>
              )
            default:
              return (
                <div>
                  <div
                    style={{
                      display: 'grid',
                      columnGap: '8px',
                      gridTemplateColumns: `auto ${showNetTradeCashFlows ? '240px' : '0px'}`,
                    }}
                  >
                    <div
                      className={classes.cardContentRoot}
                      // eslint-disable-next-line
                      // @ts-ignore
                      ref={setScrollBarElementRef}
                    >
                      {(() => {
                        switch (aggregationOption) {
                          case 'WEEK':
                            return (
                              <WeekViewCashFlowGraph
                                data={response}
                                localCurrency={localCurrency}
                                width={1000}
                              />
                            )
                          case 'MONTH':
                          default:
                            return (
                              <CashFlowGraph
                                data={response}
                                localCurrency={localCurrency}
                                width={1000}
                              />
                            )
                        }
                      })()}
                    </div>
                    {showNetTradeCashFlows ? <NetTradeCashFlowsTicket
                      currency={localCurrency}
                      report={report}
                    /> : <div/>}
                  </div>
                  <div
                    // eslint-disable-next-line
                    // @ts-ignore
                    ref={setScrollBarElementRef2}
                    style={{
                      overflowX: 'scroll'
                    }}
                  >
                    <CashFlowTotals
                      currency={localCurrency}
                      report={report}
                      width={1100}
                    />
                    {graphLegend(1100, report)}
                  </div>
                </div>
              )
          }
        })()}
      </CardContent>
    </Card>
  )
}

export default MainCashFlowGraphLayout
