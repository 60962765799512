import {jsonRpcRequest} from '../../jsonRpc/jsonRpcReqest';
import {config} from '../../index';
import {jsonRpcRequestRaw} from '../../../actions/helper/utilities'
const ServiceProviderName = 'User-Manager'


export const Manager = {
  /**
   * @param {getUserProfileByIdRequestType} request
   * @returns {getUserProfileByIdResponseType}
   */
  getUserProfileById(request) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${ServiceProviderName}.GetUserProfileById`,
      request
    })
  },

  /**
   * @param {inviteUserRequestType} request
   */
  inviteUser(request){
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${ServiceProviderName}.InviteUser`,
      request,
    })
  },

  registerNewUser(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'User-Manager.RegisterNewUser',
      request,
    })
  },

  sendRegistrationConfirmation(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'User-Manager.SendRegistrationConfirmation',
      request,
    })
  }
}
