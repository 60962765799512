import React from 'react';
import Table from 'components/Table/VirtualizedTable/VirtualizedTable';
import {
  CardHeader,
  Icon,
  IconButton,
  withStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import { FiFilter as FilterIcon } from 'react-icons/fi';

import ReportCard from 'components/ReportCard/ReportCard';
import { InvoiceRecordkeeper } from 'popcorn-js/financial/invoice';
import { Recordkeeper as PrepaymentRecordkeeper } from 'popcorn-js/financial/accounting/prepayment/recordkeeper';
import { processUnixDateForViewing } from 'utils/Utils';
import { FormatNumber } from 'utils/TradeUtilities';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import Filter from 'components/Filter/Filter';
import { TEXT_CRITERION, EXACT_CRITERION } from 'popcorn-js/search/criteria/types';
import {
  INVOICE_TYPE_PURCHASE_CREDIT_NOTE,
  INVOICE_TYPE_PURCHASE_INVOICE, 
  INVOICE_TYPE_SALES_CREDIT_NOTE,
  INVOICE_TYPE_SALES_INVOICE
} from 'constants/invoice';

const filterHeight = 90;

const styles = () => ({
  cardRoot: {
    padding: 0,
  },
  cardHeaderRoot: {
    padding: '6px 10px 5px 10px',
  },
  formField: {
    height: '60px',
    width: '100%',
  },
  asyncSelectLabel: {
    fontSize: 13,
  },
  asyncSelectHelperText: {
    marginTop: '-2px',
  },
});

const selectStyles = (theme) => ({
  valueContainer: (styles) => ({
    ...styles,
    border: 'none',
    padding: '0 4px',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '6px',
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    border: 'none',
    color: isSelected ? 'black' : theme.palette.primary.contrastText,
    backgroundColor: isSelected
      ? 'white'
      : isFocused
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  }),
  control: (styles) => ({
    ...styles,
    minHeight: '1px',
    border: 'none',
    padding: '0',
    borderRadius: '0',
    backgroundColor: 'none',
  }),
  input: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    color: theme.palette.text.primary,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
  }),
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      color:
        data.label === 'Blank Fields' || data.label === 'start typing...'
          ? theme.palette.text.secondary
          : theme.palette.text.primary,
      fontStyle:
        data.label === 'Blank Fields' || data.label === 'start typing...'
          ? 'italic'
          : '',
    };
  },
});

const exposureTabs = {
  invoice: 0,
  prepayments: 1,
};

class ExposureSelection extends React.Component {
  constructor(props) {
    super(props);
    this.asyncSelectStyles = selectStyles(props.theme);
    this.state.prepaymentCriteria = props.defaultPrepaymentsFilter;
  }

  asyncSelectStyles = {};

  state = {
    showFilter: false,
    activeExposureTab: exposureTabs.invoice,
    errorMessage: undefined,
    invoices: [],
    invoiceCounterparties: [],
    total: 30,
    criteria: [],
    query: {},
    clearCacheToggle: false,
    sortBy: '',
    sortDirection: 'ASC',
    invoiceTableWrapperHeight: 0,
    prepayments: [],
    prepaymentCounterparties: [],
    prepaymentTotal: 30,
    prepaymentCriteria: [],
    prepaymentQuery: {},
    prepaymentClearCacheToggle: false,
    prepaymentSortBy: '',
    prepaymentSortDirection: 'ASC',
  };

  findCounterpartiesByIds = async (counterpartyIds) => {
    const { invoiceCounterparties } = this.state;

    // filter to only have unique ids
    let uniqueIds = [];
    for (const b of counterpartyIds) {
      if (!uniqueIds.find((u) => u.id === b)) {
        uniqueIds.push(b);
      }
    }

    // filter already retrieved ids
    uniqueIds = uniqueIds.filter(
      (id) => !invoiceCounterparties.find((b) => b.id === id)
    );

    if (uniqueIds.length <= 0) {
      return;
    }

    const criteria = [];
    for (const id of uniqueIds) {
      criteria.push({ field: 'id', text: id });
    }

    try {
      const counterpartyFindResponse = await CounterpartyRecordkeeper.find({
        criteria: criteria,
        query: undefined,
        Deleted: false,
      });
      invoiceCounterparties.push(...counterpartyFindResponse.records);
      this.setState({ invoiceCounterparties });
    } catch (e) {
      this.setState({ errorMessage: e.message || e });
    }
  };

  loadMoreInvoiceRows = async ({ startIndex, stopIndex }) => {
    const { criteria, sortBy, sortDirection } = this.state;
    const sort = sortBy && sortBy !== '';

    const query = {
      offset: startIndex,
      limit: stopIndex - startIndex + 1,
      sortBy: sort ? [sortBy] : undefined,
      order: sort ? (sortDirection === 'DESC' ? ['desc'] : ['asc']) : undefined,
    };

    try {
      const invoiceFindResponse = await InvoiceRecordkeeper.find({
        criteria,
        query,
      });

      const counterpartyIds = invoiceFindResponse.records
        .filter((inv) => inv.counterpartyId !== '')
        .map((inv) => inv.counterpartyId);
      await this.findCounterpartiesByIds(counterpartyIds);

      this.setState({
        invoices: invoiceFindResponse.records,
        total: invoiceFindResponse.total,
      });
      return { startIndex, stopIndex };
    } catch (e) {
      console.error('error finding invoices', e);
      this.setState({ errorMessage: e.message || e });
      throw e;
    }
  };

  loadMorePrepaymentRows = async ({ startIndex, stopIndex }) => {
    const {
      prepaymentCriteria,
      prepaymentSortBy,
      prepaymentSortDirection,
    } = this.state;
    const sort = prepaymentSortBy && prepaymentSortBy !== '';

    const query = {
      offset: startIndex,
      limit: stopIndex - startIndex + 1,
      sortBy: sort ? [prepaymentSortBy] : undefined,
      order: sort
        ? prepaymentSortDirection === 'DESC'
          ? ['desc']
          : ['asc']
        : undefined,
    };

    try {
      const prepaymentFindResponse = await PrepaymentRecordkeeper.find({
        criteria: prepaymentCriteria,
        query,
      });

      const counterpartyIds = prepaymentFindResponse.records
        .filter((inv) => inv.counterpartyId !== '')
        .map((inv) => inv.counterpartyId);

      await this.findCounterpartiesByIds(counterpartyIds);

      this.setState({
        prepayments: prepaymentFindResponse.records,
        prepaymentTotal: prepaymentFindResponse.total,
      });
      return { startIndex, stopIndex };
    } catch (e) {
      console.error('error finding invoices', e);
      this.setState({ errorMessage: e.message || e });
      throw e;
    }
  };

  handleSelection = (invoice) => {
    if (invoice === undefined || invoice.id === undefined) {
      return;
    }
    const { selectedInvoices } = this.props;
    if (selectedInvoices.find((inv) => inv.id === invoice.id)) {
      this.props.onInvoiceDeselect(invoice);
    } else {
      this.props.onInvoiceSelect(invoice);
    }
  };

  handlePrepaymentSelection = (prepayment) => {
    if (prepayment === undefined || prepayment.id === undefined) {
      return;
    }
    const { selectedPrepayments } = this.props;
    if (selectedPrepayments.find((pp) => pp.id === prepayment.id)) {
      this.props.onPrepaymentDeselect(prepayment);
    } else {
      this.props.onPrepaymentSelect(prepayment);
    }
  };

  handleCriteriaChange = (criteriaFilter) => {
    const { selectedSI } = this.props;

    let total = this.state.total;
    if (total === 0) {
      total = 1;
    }

    let baseCriteria = []
    // only retrieve invoices with types corresponding to the Import/Export indicator of the SI 
    if (selectedSI.importExport === 'Import') {
      baseCriteria = [
        {type: EXACT_CRITERION, text: INVOICE_TYPE_PURCHASE_INVOICE, field: 'type' },
        {type: EXACT_CRITERION, text: INVOICE_TYPE_PURCHASE_CREDIT_NOTE, field: 'type' },
      ]
    } else if (selectedSI.importExport === 'Export') {
      baseCriteria = [
        {type: EXACT_CRITERION, text: INVOICE_TYPE_SALES_INVOICE, field: 'type' },
        {type: EXACT_CRITERION, text: INVOICE_TYPE_SALES_CREDIT_NOTE, field: 'type' },
      ]
    }

    // explicitly only retrieve invoices for the selected SI currency
    baseCriteria.push({type: EXACT_CRITERION, text: selectedSI.currencyId, field: 'currencyId'})

    this.setState(
      {
        total,
        criteria: [...criteriaFilter.criteria, ...baseCriteria],
      },
      () => this.clearCache()
    );
  };

  handleSortChange = ({ sortBy, sortDirection }) => {
    this.setState({ sortBy, sortDirection }, this.clearCache);
  };

  prepaymentHandleSortChange = ({ sortBy, sortDirection }) => {
    this.setState(
      {
        prepaymentSortBy: sortBy,
        prepaymentSortDirection: sortDirection,
      },
      this.prepaymentClearCache
    );
  };
  
  clearCache() {
    this.setState({
      clearCacheToggle: !this.state.clearCacheToggle,
      invoices: [],
    });
  }

  prepaymentClearCache() {
    this.setState({
      prepaymentClearCacheToggle: !this.state.prepaymentClearCacheToggle,
      prepayments: [],
    });
  }

  prepaymentsHandleCriteriaChange = (criteriaFilter) => {
    let prepaymentTotal = this.state.prepaymentTotal;
    if (prepaymentTotal === 0) {
      prepaymentTotal = 1;
    }

    this.setState(
      {
        prepaymentTotal,
        prepaymentCriteria: criteriaFilter.criteria || [],
      },
      this.prepaymentClearCache
    );
  };

  generateCounterpartyOptions = async (inputValue) => {
    const criteria = [
      { text: inputValue, field: 'name' },
      { text: this.props.partyCode, field: 'partyCode' },
    ];
    try {
      const counterpartyFindResult = await CounterpartyRecordkeeper.find({
        criteria: criteria,
        query: { sortBy: ['name'], order: [], limit: 100, offset: 0 },
        Deleted: false,
      });
      return (counterpartyFindResult.records || []).map((b) => ({
        value: b.id,
        label: b.name,
      }));
    } catch (e) {
      console.error('error finding counterparties', e);
      throw e;
    }
  };

  handleExposureTypeTabChange = (muiClass, newTabValue) => {
    this.setState({ activeExposureTab: newTabValue });
  };

  render() {
    const { activeExposureTab } = this.state;
    const { classes } = this.props;

    return (
      <ReportCard classes={{ root: classes.cardRoot }}>
        <CardHeader
          action={this.renderIcons()}
          classes={{ root: classes.cardHeaderRoot }}
          title={
            <Tabs
              onChange={this.handleExposureTypeTabChange}
              value={activeExposureTab}
            >
              <Tab
                label={'Invoices'}
                value={exposureTabs.invoice} />
              <Tab
                label={'Prepayments'}
                value={exposureTabs.prepayments} />
            </Tabs>
          }
        />
        {(() => {
          switch (activeExposureTab) {
            case exposureTabs.invoice:
              return this.renderInvoiceSelection();
            case exposureTabs.prepayments:
              return this.renderPrepaymentsSelection();
            default:
              return null;
          }
        })()}
      </ReportCard>
    );
  }

  renderIcons = () => {
    const { showFilter, activeExposureTab } = this.state;

    switch (activeExposureTab) {
      case exposureTabs.invoice:
      case exposureTabs.prepayments:
        return [
          <IconButton
            key={'filter'}
            onClick={() => this.setState({ showFilter: !showFilter })}
          >
            <Icon>
              <FilterIcon />
            </Icon>
          </IconButton>,
        ];

      default:
        return [];
    }
  };

  setMaxHeights = (element) => {
    const { invoiceTableWrapperHeight } = this.state;
    if (invoiceTableWrapperHeight > 0) {
      // heights are already set
      return;
    }
    try {
      this.setState({
        invoiceTableWrapperHeight: element.parentElement.clientHeight - 60,
      });
    } catch (e) {
      console.error('error setting invoice table height', e);
    }
  };

  renderInvoiceSelection = () => {
    const {
      showFilter,
      total,
      invoiceCounterparties,
      sortBy,
      sortDirection,
      clearCacheToggle,
      invoices,
      invoiceTableWrapperHeight,
    } = this.state;

    const { selectedInvoices, defaultFilter } = this.props;

    return (
      <React.Fragment>
        <div
          style={{
            height: showFilter ? filterHeight : '0px',
            padding: '0 40px',
          }}
        >
          <Filter
            defaultFilter={defaultFilter}
            filters={[
              {
                label: 'Invoice Number',
                field: 'number',
                filterType: 'text',
                criterionType: TEXT_CRITERION,
              },
              {
                label: 'CounterParty',
                field: 'counterpartyId',
                filterType: 'asyncSelect',
                asyncOptionsFetcher: this.generateCounterpartyOptions,
                criterionType: TEXT_CRITERION,
              },
            ]}
            onChange={this.handleCriteriaChange}
            show={showFilter}
          />
        </div>
        <div
          ref={this.setMaxHeights}
          style={{
            height: invoiceTableWrapperHeight - (showFilter ? filterHeight : 0),
            overflow: 'hidden',
          }}
        >
          <Table
            clearCacheToggle={clearCacheToggle}
            columns={[
              {
                width: 140,
                flexGrow: 1.0,
                label: 'Counterparty',
                dataKey: 'counterpartyId',
                cellContentRenderer: (cell) => {
                  return (
                    (
                      invoiceCounterparties.find(
                        (b) => b.id === cell.cellData
                      ) || {}
                    ).name || cell.cellData
                  );
                },
                disableSort: true,
              },
              {
                width: 140,
                label: 'Number',
                dataKey: 'number',
              },
              {
                width: 60,
                label: 'Type',
                dataKey: 'type',
              },
              {
                width: 100,
                label: 'Direction',
                dataKey: 'direction',
              },
              {
                width: 180,
                label: 'Due date',
                dataKey: 'dueDate',
                cellContentRenderer: (cell) => {
                  return processUnixDateForViewing(cell.cellData);
                },
              },
              {
                width: 120,
                label: 'Unpaid Amount',
                dataKey: 'amountDue',
                numeric: true,
                cellContentRenderer: (cell) => {
                  const unpaidAmount =
                    parseFloat(cell.rowData.amountDue || '0') -
                    parseFloat(cell.rowData.paidAmount || '0');
                  return FormatNumber(unpaidAmount, true, true, 2, true);
                },
              },
            ]}
            loadMoreRows={this.loadMoreInvoiceRows}
            minimumBatchSize={50}
            onRowClick={(row) => this.handleSelection(row.rowData)}
            rowCount={total}
            rowGetter={({ index }) => {
              let inv = {};

              if (invoices[index]) {
                inv = invoices[index];
              }

              return inv;
            }}
            selected={selectedInvoices}
            showCheckboxes
            sort={this.handleSortChange}
            sortBy={sortBy}
            sortDirection={sortDirection}
            threshold={30}
          />
        </div>
      </React.Fragment>
    );
  };

  renderPrepaymentsSelection = () => {
    const {
      showFilter,
      prepaymentTotal,
      invoiceCounterparties,
      prepaymentSortBy,
      prepaymentSortDirection,
      prepaymentClearCacheToggle,
      prepayments,
      invoiceTableWrapperHeight,
    } = this.state;

    const { selectedPrepayments, defaultPrepaymentsFilter } = this.props;

    return (
      <React.Fragment>
        <div
          style={{
            height: showFilter ? filterHeight : '0px',
            padding: '0 40px',
          }}
        >
          <Filter
            defaultFilter={defaultPrepaymentsFilter}
            filters={[
              {
                label: 'Number',
                field: 'number',
                filterType: 'text',
                criterionType: TEXT_CRITERION,
              },
              {
                label: 'CounterParty',
                field: 'counterpartyId',
                filterType: 'asyncSelect',
                asyncOptionsFetcher: this.generateCounterpartyOptions,
                criterionType: TEXT_CRITERION,
              },
            ]}
            onChange={this.prepaymentsHandleCriteriaChange}
            show={showFilter}
          />
        </div>
        <div
          ref={this.setMaxHeights}
          style={{
            height: invoiceTableWrapperHeight - (showFilter ? filterHeight : 0),
            overflow: 'hidden',
          }}
        >
          <Table
            clearCacheToggle={prepaymentClearCacheToggle}
            columns={[
              {
                width: 140,
                flexGrow: 1.0,
                label: 'Counterparty',
                dataKey: 'counterpartyId',
                cellContentRenderer: (cell) => {
                  return (
                    (
                      invoiceCounterparties.find(
                        (b) => b.id === cell.cellData
                      ) || {}
                    ).name || cell.cellData
                  );
                },
                disableSort: true,
              },
              {
                width: 140,
                label: 'Number',
                dataKey: 'number',
              },
              {
                width: 140,
                label: 'Reference',
                dataKey: 'externalReference',
              },
              {
                width: 120,
                label: 'Amount',
                dataKey: 'amountDue',
                numeric: true,
                cellContentRenderer: (cell) => {
                  return FormatNumber(
                    cell.rowData.amountDue,
                    true,
                    true,
                    2,
                    true
                  );
                },
              },
              {
                width: 120,
                label: 'Unpaid Amount',
                dataKey: 'amountDue',
                numeric: true,
                cellContentRenderer: (cell) => {
                  return FormatNumber(
                    cell.rowData.amountDue - cell.rowData.paidAmount,
                    true,
                    true,
                    2,
                    true
                  );
                },
              },
            ]}
            loadMoreRows={this.loadMorePrepaymentRows}
            minimumBatchSize={50}
            onRowClick={(row) => this.handlePrepaymentSelection(row.rowData)}
            rowCount={prepaymentTotal}
            rowGetter={({ index }) => {
              let prepayment = {};
              if (prepayments[index]) {
                prepayment = prepayments[index];
              }
              return prepayment;
            }}
            selected={selectedPrepayments}
            showCheckboxes
            sort={this.prepaymentHandleSortChange}
            sortBy={prepaymentSortBy}
            sortDirection={prepaymentSortDirection}
            threshold={30}
          />
        </div>
      </React.Fragment>
    );
  };
}

const StyledExposureSelection = withStyles(styles, { withTheme: true })(
  ExposureSelection
);

export default StyledExposureSelection;

ExposureSelection.defaultProps = {};
