import {
  RATE_SOCKET_AUTHORIZED,
  RATE_SOCKET_AUTHORIZING,
  RATE_SOCKET_CONNECTED,
  RATE_SOCKET_CONNECTING,
  RATE_SOCKET_DISCONNECTED,
  RATE_SOCKET_STALE,
  RATE_SOCKET_UNAUTHORIZED,
} from '../actions/actionTypes'

const CONNECTING = 'CONNECTING'
const CONNECTED = 'CONNECTED'
const AUTHORIZING = 'AUTHORIZING'
const AUTHORIZED = 'AUTHORIZED'
const DISCONNECTED = 'DISCONNECTED'
const STALE = 'STALE'

const initialState = {
  rateSocket: DISCONNECTED,
  eventSocket: DISCONNECTED,
}

export default function socketStatus(state = initialState, action) {
  switch (action.type) {
    // CONNECTING
    case RATE_SOCKET_CONNECTING:
      return {...state, rateSocket: CONNECTING}
    // CONNECTED
    case RATE_SOCKET_CONNECTED:
      return {...state, rateSocket: CONNECTED}
    // AUTHORIZING
    case RATE_SOCKET_AUTHORIZING:
      return {...state, rateSocket: AUTHORIZING}
    // AUTHORIZED
    case RATE_SOCKET_AUTHORIZED:
      return {...state, rateSocket: AUTHORIZED}
    // STALE
    case RATE_SOCKET_STALE:
      return {...state, rateSocket: STALE}
    // DISCONNECT AND UNAUTHORIZED
    case RATE_SOCKET_DISCONNECTED:
    case RATE_SOCKET_UNAUTHORIZED:
      return {...state, rateSocket: DISCONNECTED}
    default:
      return state
  }
}
