import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {ID_IDENTIFIER} from '../../search/identifier/types'
import {Marshal} from '../../search/identifier/Marshaller'

/**
 * ClientTierRecordkeeper is a convenience client for invoking the backend services
 */
const Recordkeeper = {
  /**
   * @param {ClientTier} clientTier
   */
  create(clientTier) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ClientTier-Recordkeeper.Create',
      request: {clientTier},
    })
  },

  /**
   * @param criteria: Filter criteria
   * @param query: Query options
   * @param {boolean} deleted
   */
  find(criteria, query, deleted) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ClientTier-Recordkeeper.Find',
      request: {
        criteria,
        query,
        deleted,
      },
    })
  },

  /**
   * @param {string} id
   */
  delete(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ClientTier-Recordkeeper.Delete',
      request: {
        serializedIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  },

  /**
   *
   * @param {ClientTier} clientTier
   * @param {String} id
   */
  update(clientTier, id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ClientTier-Recordkeeper.Update',
      request: {
        clientTier,
        serializedIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  },

  /**
   * @param {string} id
   */
  retrieveHistory(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ClientTier-Recordkeeper.RetrieveHistory',
      request: {
        serializedIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  },

  /**
   * @param {string} id
   */
  restore(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ClientTier-Recordkeeper.Restore',
      request: {
        serializedIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  },

  /**
   * @param {string} id
   */
  deleteForever(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ClientTier-Recordkeeper.DeleteForever',
      request: {
        serializedIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  },
}

export default Recordkeeper
