import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IntTextField, {parseTypes} from 'components/FormContols/TextField'
import {ContactDetails} from '../../popcorn-js/legalEntity/party/ContactDetails'

const IndividualContactDetails = (props) => {
  return (
    <Card
      style={{
        marginBottom: '10px',
        marginTop: '20px',
      }}>

      <CardHeader title={props.title}/>
      <CardContent>
        <Grid
          container
          direction={'column'}
          style={{paddingLeft: '40px'}}>
          {(!props.fields || props.fields.includes('telephoneNumber')) &&
          <Grid item>
            <IntTextField
              disableThousandsSeparator
              disabled={props.disabled || !props.onChange}
              disallowNegative
              error={!!props.invalidFields.telephoneNumber}
              fullWidth
              helperText={props.invalidFields.telephoneNumber || ' '}
              id="telephoneNumber"
              label="Telephone Number"
              margin={props.margin}
              onChange={(e) => props.onChange('telephoneNumber',
                e.target.value)}
              parseType={parseTypes.int}
              value={(props.contactDetails.telephoneNumber || '')}
            />
          </Grid>
          }
          {(!props.fields || props.fields.includes('cellphoneNumber')) &&
          <Grid item>
            <IntTextField
              disableThousandsSeparator
              disabled={props.disabled || !props.onChange}
              disallowNegative
              error={!!props.invalidFields.cellphoneNumber}
              fullWidth
              helperText={props.invalidFields.cellphoneNumber || ' '}
              id="cellphoneNumber"
              label="Cellphone Number"
              margin={props.margin}
              onChange={(e) => props.onChange('cellphoneNumber',
                e.target.value)}
              parseType={parseTypes.int}
              value={(props.contactDetails.cellphoneNumber || '')}
            />
          </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

IndividualContactDetails.propTypes = {
  contactDetails: PropTypes.instanceOf(ContactDetails).isRequired,
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.string),
  invalidFields: PropTypes.objectOf(PropTypes.string),
  margin: PropTypes.oneOf(['dense', 'normal']),
  onChange: PropTypes.func,
  title: PropTypes.string,
}

IndividualContactDetails.defaultProps = {
  address: {},
  invalidFields: {},
  margin: 'normal',
  disabled: false,
  title: 'Contact Details',
}

export default IndividualContactDetails
