import {IconButton, Tooltip} from '@material-ui/core';
import React from 'react';

import {DeleteForever,} from '@material-ui/icons'

export const deleteForeverAction = onClick => (
  <Tooltip
    key={'delete'}
    title={'Delete Forever'}>
    <IconButton onClick={onClick}>
      <DeleteForever/>
    </IconButton>
  </Tooltip>)
