const identifierTypeSwitch = {}

/**
 * unmarshal given serialised identifier into object
 * @param {string} serializedIdentifier
 * @returns {{type: string, value: *}}
 * @constructor
 */
export default function unmarshal(serializedIdentifier) {
  const indexOfSeparator = serializedIdentifier.indexOf('::')

  if (indexOfSeparator === -1) {
    throw new Error(`unable to find separator :: in given serializedIdentifier: '${serializedIdentifier}'`)
  }

  const identifierType = serializedIdentifier.substring(0, indexOfSeparator)
  if (identifierTypeSwitch[identifierType] === undefined) {
    throw new TypeError(`invalid/unsupported identifier type ${identifierType}`)
  }

  const marshalledIdentifierValue = serializedIdentifier.substring(indexOfSeparator + 2)

  let unmarshalledIdentifierValue
  try {
    unmarshalledIdentifierValue = JSON.parse(marshalledIdentifierValue)
  } catch (e) {
    throw new Error(`error unmarshalling identifier value: ${e}`)
  }

  return new identifierTypeSwitch[identifierType]({
    type: identifierType,
    value: unmarshalledIdentifierValue
  })
}
