import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import SettlementInstructionDetailContainer from 'components/Detail/SettlementInstruction/SettlementInstructionDetailContainer'
import ReportCard from '../../ReportCard/ReportCard'

const SettlementInstructionSummaryDialog = (props) => {
  const {
    onClose,
    show,
    SI,
    height,
    onSubmitSuccess,
    onUpdateSuccess,
    readOnly,
  } = props

  return (
    <Dialog
      maxWidth={'lg'}
      onClose={onClose}
      open={show}
    >
      <ReportCard
        style={{
          padding: '0 20px',
          height: 'calc(100vh - 200px)',
          width: '730px'
        }}
      >
        <SettlementInstructionDetailContainer
          SI={SI}
          height={height}
          onSubmitSuccess={onSubmitSuccess}
          onUpdateSuccess={onUpdateSuccess}
          readOnly={readOnly}
        />
      </ReportCard>
    </Dialog>
  )
}

export default SettlementInstructionSummaryDialog
