import React, {useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import {
  Date as DateFilter,
  Time as TimeFilter,
  Bool as BoolFilter,
  Number as NumberFilter,
  Text as TextFilter,
  TextOptions as TextOptionsFilter
} from '../Table/PGTable/Filters'
import AsyncOptions from '../Table/PGTable/Filters/AsyncOptions'
import _ from 'lodash'
import {
  BOOL_CRITERION,
  DATE_CRITERION,
  NUMBER_CRITERION,
  TEXT_CRITERION,
  TIME_CRITERION
} from 'popcorn-js/search/criteria/types'

const useStyles = makeStyles(theme => ({
  tableCellHead: {
    backgroundColor: '#191c2f',
    color: theme.palette.text.primary,
    fontSize: 16,
    position: 'sticky',
    top: '35px',
    border: '0px',
    zIndex: 1,
  },
}));

export default function FilterRow(props) {
  const {initialCriteria, showCheckboxes} = props
  const classes = useStyles();
  const [columnCrit, setColumnCrit] = React.useState(initialCriteria)
  const [show, setShow] = React.useState(true)

  const showFilter = props.show

  useEffect(() => {
    setShow(showFilter)
    setColumnCrit(_.clone(initialCriteria))
  }, [showFilter, initialCriteria])

  function generateCriteria() {
    const criteria = []
    for (const field in columnCrit) {
      if (columnCrit.hasOwnProperty(field) && columnCrit[field] !== undefined) {
        criteria.push(columnCrit[field])
      }
    }
    return criteria
  }

  function handleFilterChange(val, field) {
    const newColumnCrit = columnCrit
    console.log(val)
    console.log(field)
    if (!val) {
      delete newColumnCrit[field]
    } else {
      newColumnCrit[field] = val
    }
    setColumnCrit(newColumnCrit)
    props.onFilterChange(generateCriteria())
  }

  // eslint-disable-next-line react/no-multi-comp
  function renderDefaultFilter(column) {
    return (
      <TextFilter
        // initValue={columnFilterCriteria[column.field]}
        fieldID={column.field}
        onChange={(val) => handleFilterChange(val, column.field)}
      />
    )
  }

  // eslint-disable-next-line react/no-multi-comp
  function renderTextOptionsFilter(column) {
    return (
      <TextOptionsFilter
        fieldID={column.field}
        filterConfig={column.filter}
        initValue={columnCrit ? columnCrit[column.field] : ''}
        onChange={(val) => handleFilterChange(val, column.field)}
      />
    )
  }

  // eslint-disable-next-line react/no-multi-comp
  function renderAsyncTextOptionsFilter(column) {
    return (
      <AsyncOptions
        fieldID={column.field}
        filterConfig={column.filter}
        // initValue={this.criteria[columnId]}
        onChange={(val) => handleFilterChange(val, column.field)}
      />
    )
  }

  // eslint-disable-next-line react/no-multi-comp
  function renderNumberFilter(column) {
    return (
      <NumberFilter
        fieldID={column.field}
        filterConfig={column.filter}
        // initValue={this.criteria[columnId]}
        onChange={(val) => handleFilterChange(val, column.field)}
      />
    )
  }

  // eslint-disable-next-line react/no-multi-comp
  function renderDateFilter(column) {
    return (
      <DateFilter
        fieldID={column.field}
        filterConfig={column.filter}
        // initValue={this.criteria[columnId]}
        onChange={(val) => handleFilterChange(val, column.field)}
      />
    )
  }

  function renderTimeFilter(column) {
    return (
      <TimeFilter
        fieldID={column.field}
        filterConfig={column.filter}
        // initValue={this.criteria[columnId]}
        onChange={(val) => handleFilterChange(val, column.field)}
      />
    )
  }
  function renderBoolFilter(column) {
    return (
      <BoolFilter
        fieldID={column.field}
        filterConfig={column.filter}
        // initValue={this.criteria[columnId]}
        onChange={(val) => handleFilterChange(val, column.field)}
      />
    )
  }


  function getComponentForColumn(column) {
    if (column.filtering === false) {
      return null
    }

    if (column.filter) {
      switch (column.filter.type) {
        case TEXT_CRITERION:
          if (column.filter.options !== undefined) {
            return renderTextOptionsFilter(column)
          }
          if (column.filter.asyncOptionsFetcher !== undefined) {
            return renderAsyncTextOptionsFilter(column)
          }
          return renderDefaultFilter(column)
        case NUMBER_CRITERION:
          return renderNumberFilter(column)
        case DATE_CRITERION:
          return renderDateFilter(column)
        case TIME_CRITERION:
          return renderTimeFilter(column)
        case BOOL_CRITERION:
          return renderBoolFilter(column)
        default:
          return renderDefaultFilter(column)
      }
    }
  }

  return (
    <TableRow>
      {(showCheckboxes && show) && <TableCell
        classes={{head: classes.tableCellHead}}
        key={'checkbox'}
        padding={'default'}
      />
      }
      {show && props.columns.map((col, idx) => (
        <TableCell
          classes={{head: classes.tableCellHead}}
          key={'filterRow' + idx}
          padding={'default'}
        >
          {getComponentForColumn(col)}
        </TableCell>
      ))}
    </TableRow>
  )
}

FilterRow.propTypes = {
  showCheckboxes: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  initialCriteria: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
};
