import {DateKeeper} from 'popcorn-js/financial/fxCalendar/dateKeeper'
import {
  TRADE_LEG_DIRECTION_BUY,
  TRADE_LEG_DIRECTION_SELL,
  TRADE_TYPE_CANCELLATION,
  TRADE_TYPE_FORWARD,
  TRADE_TYPE_SPOT,
} from 'constants/trade'


const Updater = {
  async UpdateByField(request) {

    const {trade, field, value} = request
    const pipMultiplier = request.currencyPairToTrade ?
      request.currencyPairToTrade.pips ?
        request.currencyPairToTrade.pips : 10000.00 : 10000.00


    //trade.tradeParents[idx].amount
    if (field.includes('trade.tradeParents') && field.includes('amount')) {
      const idx = parseInt(field.charAt(19))

      if (isNaN(idx)) {
        throw new Error(`invalid field in ByFieldRequest: ${request.field}`)
      }

      if (idx > trade.tradeParents.length - 1) {
        throw new Error(`invalid field in ByFieldRequest: ${request.field}`)
      }
      trade.tradeParents[idx].amount = value
      return {trade}
    }

    if (field.includes('trade.acmParents') && field.includes('amount')) {
      const idx = parseInt(field.charAt(17))

      if (isNaN(idx)) {
        throw new Error(`invalid field in ByFieldRequest: ${request.field}`)
      }

      if (idx > trade.acmParents.length - 1) {
        throw new Error(`invalid field in ByFieldRequest: ${request.field}`)
      }
      trade.acmParents[idx].amount = value
      return {trade}
    }

    switch (field) {
      //
      // Near leg Updating
      //
      case 'legs.0.externalReference':
        trade.legs[0].externalReference = value
        break
      case 'legs.0.bank':
        trade.legs[0].bank = value
        break
      case 'legs.0.direction':
        trade.legs[0].direction = value
        switch (trade.tradeType) {
          case TRADE_TYPE_SPOT:
          case TRADE_TYPE_FORWARD:
          case TRADE_TYPE_CANCELLATION:
            break
          default:
            trade.legs[1].direction =
              trade.legs[0].direction === TRADE_LEG_DIRECTION_SELL
                ? TRADE_LEG_DIRECTION_BUY
                : TRADE_LEG_DIRECTION_SELL
        }
        break

      case 'legs.0.tradeDate':
        trade.legs[0].tradeDate = value
        switch (trade.tradeType) {
          case TRADE_TYPE_SPOT:
          case TRADE_TYPE_FORWARD: {
            const spotDateResult = await DateKeeper.GetSpotDateFor({
              currencyPair: {id: trade.currencyPairId},
              date: trade.legs[0].tradeDate,
            })
            if (spotDateResult.spotDate === trade.legs[0].maturityDate) {
              trade.tradeType = TRADE_TYPE_SPOT
            } else {
              trade.tradeType = TRADE_TYPE_FORWARD
            }
            break
          }
          default:
            trade.legs[1].tradeDate = trade.legs[0].tradeDate
        }
        break

      case 'legs.0.maturityDate':
        trade.legs[0].maturityDate = value
        switch (trade.tradeType) {
          case TRADE_TYPE_SPOT:
          case TRADE_TYPE_FORWARD: {
            const spotDateResult = await DateKeeper.GetSpotDateFor({
              currencyPair: {id: trade.currencyPairId},
              date: trade.legs[0].tradeDate,
            })
            if (spotDateResult.spotDate === trade.legs[0].maturityDate) {
              trade.tradeType = TRADE_TYPE_SPOT
            } else {
              trade.tradeType = TRADE_TYPE_FORWARD
            }
          }
            break
          default:
        }
        break
      case 'legs.0.notionalAmount':
        trade.legs[0].notionalAmount = value
        trade.legs[0].quoteAmount = trade.legs[0].notionalAmount * (trade.legs[0].allInRate) * pipMultiplier / pipMultiplier
        if (trade.legs.length > 1) {
          trade.legs[1].notionalAmount = trade.legs[0].notionalAmount
          trade.legs[1].quoteAmount = trade.legs[1].notionalAmount * (trade.legs[1].allInRate) * pipMultiplier / pipMultiplier
        }
        break
      case 'legs.0.spotPrice':
        trade.legs[0].spotPrice = value
        trade.legs[0].allInRate = trade.legs[0].spotPrice + trade.legs[0].forwardPoints
        trade.legs[0].quoteAmount = trade.legs[0].notionalAmount * (trade.legs[0].allInRate) * pipMultiplier / pipMultiplier
        break
      case 'legs.0.forwardPoints':
        trade.legs[0].forwardPoints = value
        trade.legs[0].allInRate = trade.legs[0].spotPrice + trade.legs[0].forwardPoints
        trade.legs[0].quoteAmount = trade.legs[0].notionalAmount * (trade.legs[0].allInRate) * pipMultiplier / pipMultiplier
        break

      case 'legs.0.allInRate':
        trade.legs[0].allInRate = value
        if (trade.legs[0].forwardPoints !== 0) {
          trade.legs[0].forwardPoints = trade.legs[0].allInRate - trade.legs[0].spotPrice
        }
        trade.legs[0].quoteAmount = trade.legs[0].notionalAmount * (trade.legs[0].allInRate) * pipMultiplier / pipMultiplier
        break
      case 'legs.0.effectiveRate':
        break
      //
      // Far leg Updating
      //
      case 'legs.1.externalReference':
        trade.legs[1].externalReference = value
        break

      case 'legs.1.bank':
        trade.legs[1].bank = value
        break

      case 'legs.1.direction':
        trade.legs[1].direction = value
        switch (trade.tradeType) {
          case TRADE_TYPE_SPOT:
          case TRADE_TYPE_FORWARD:
            break
          default:
            trade.legs[0].direction =
              trade.legs[1].direction === TRADE_LEG_DIRECTION_SELL
                ? TRADE_LEG_DIRECTION_BUY
                : TRADE_LEG_DIRECTION_SELL
        }
        break

      case 'legs.1.tradeDate':
        trade.legs[1].tradeDate = value
        switch (trade.tradeType) {
          case TRADE_TYPE_SPOT:
          case TRADE_TYPE_FORWARD: {
            const spotDateResult = await DateKeeper.GetSpotDateFor({
              currencyPair: {id: trade.currencyPairId},
              date: trade.legs[1].tradeDate,
            })
            if (spotDateResult.spotDate === trade.legs[1].maturityDate) {
              trade.tradeType = TRADE_TYPE_SPOT
            } else {
              trade.tradeType = TRADE_TYPE_FORWARD
            }
            break
          }
          default:
            trade.legs[0].tradeDate = trade.legs[1].tradeDate
        }
        break

      case 'legs.1.maturityDate':
        trade.legs[1].maturityDate = value
        switch (trade.tradeType) {
          case TRADE_TYPE_SPOT:
          case TRADE_TYPE_FORWARD: {
            const spotDateResult = await DateKeeper.GetSpotDateFor({
              currencyPair: {id: trade.currencyPairId},
              date: trade.legs[1].tradeDate,
            })
            if (spotDateResult.spotDate === trade.legs[1].maturityDate) {
              trade.tradeType = TRADE_TYPE_SPOT
            } else {
              trade.tradeType = TRADE_TYPE_FORWARD
            }
          }
            break
          default:
        }
        break
      case 'legs.1.notionalAmount':
        trade.legs[1].notionalAmount = value
        trade.legs[1].quoteAmount = trade.legs[1].notionalAmount * (trade.legs[1].allInRate) * pipMultiplier / pipMultiplier
        trade.legs[0].notionalAmount = trade.legs[1].notionalAmount
        trade.legs[0].quoteAmount = trade.legs[0].notionalAmount * (trade.legs[0].allInRate) * pipMultiplier / pipMultiplier
        break
      case 'legs.1.spotPrice':
        trade.legs[1].spotPrice = value
        trade.legs[1].allInRate = trade.legs[1].spotPrice + trade.legs[1].forwardPoints
        trade.legs[1].quoteAmount = trade.legs[1].notionalAmount * (trade.legs[1].allInRate) * pipMultiplier / pipMultiplier
        break
      case 'legs.1.forwardPoints':
        trade.legs[1].forwardPoints = value
        trade.legs[1].allInRate = trade.legs[1].spotPrice + trade.legs[1].forwardPoints
        trade.legs[1].quoteAmount = trade.legs[1].notionalAmount * (trade.legs[1].allInRate) * pipMultiplier / pipMultiplier
        break

      case 'legs.1.allInRate':
        trade.legs[1].allInRate = value
        trade.legs[1].forwardPoints = trade.legs[1].allInRate - trade.legs[1].spotPrice
        trade.legs[1].quoteAmount = trade.legs[1].notionalAmount * (trade.legs[1].allInRate) * pipMultiplier / pipMultiplier
        break
      case 'legs.1.effectiveRate':
        break

      default:
        if (trade.hasOwnProperty(field)) {
          trade[field] = value
        } else {
          throw new Error(`invalid field in ByFieldRequest: ${request.field}`)
        }
    }
    return {trade}
  },
}

export default Updater
