import React from 'react'
import {Header, ItemEntry} from 'views/History/HistoryLayout'
import {Divider} from '@material-ui/core'
import {processUnixDateForViewing} from '../../../utils/Utils'
import {SystemDateTimeFormat} from '../../../constants/formats'

const styles = ({
  root: {
    display: 'grid',
    flexGrow: 1,
    rowGap: '2px',
    boxShadow: '',
    // paddingTop: '25px',
    zIndex: 1,
    gridTemplateRows: (() => {
      let space = ''
      for (let i = 0; i < 20; i++) {
        space = space + '32px '
      }
      return space
    })(),
  },
  divider: {
    height: '2px',
    margin: '20px 20px',
  },
  subDivider: {
    height: '1px',
    margin: '16px 40px',
  },
})

export const ClientTierFields = (clientTier, fieldType, changedFields = []) => {
  return (
    <div>
      <main style={styles.root}>
        <Header
          fieldType={fieldType}
          label={'Client Tier Information'}
        />
        {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
        <ItemEntry
          changed={changedFields.includes('name')}
          fieldType={fieldType}
          id={'name'}
          label={'Name'}
          value={clientTier.name || ''}
        />
        <ItemEntry
          changed={changedFields.includes('currencyPairSpreads')}
          fieldType={fieldType}
          id={'currencyPairSpreads'}
          label={'Currency Pair Spreads'}
          nestedArray
          nestedArrayFieldsToIgnore={['currencyPairId']}
          value={clientTier.currencyPairSpreads || []}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Audit Entry'}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.username')}
          fieldType={fieldType}
          id={'auditEntry.username'}
          label={'Username'}
          value={(clientTier.auditEntry || {}).username || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.action')}
          fieldType={fieldType}
          id={'auditEntry.action'}
          label={'Action'}
          value={(clientTier.auditEntry || {}).action || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.time')}
          fieldType={fieldType}
          id={'auditEntry.time'}
          label={'Time'}
          value={(clientTier.auditEntry || {}).time ? processUnixDateForViewing((clientTier.auditEntry || {}).time, SystemDateTimeFormat) : ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.version')}
          fieldType={fieldType}
          id={'auditEntry.version'}
          label={'Version'}
          value={(clientTier.auditEntry || {}).version || 0}
        />
      </main>
    </div>
  )
}
