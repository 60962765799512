import React from 'react'
import {Grid, IconButton, Tooltip} from '@material-ui/core'
import SettlementInstructionBlotterContainer from 'components/Blotter/SettlementInstruction/BlotterContainer'
import ReportCard from '../../../components/ReportCard/ReportCard'
import {FiLink as LinkIcon,} from 'react-icons/fi'
import Recordkeeper from 'popcorn-js/financial/settlementInstruction/recordkeeper'
import SettlementInstructionSummaryDialog from 'components/Detail/SettlementInstruction/SettlementInstructionSummaryDialog'
import {MdFileDownload as DownloadIcon, MdRemoveRedEye as ViewIcon,} from 'react-icons/md'
import {StatusComplete, StatusSubmitted,} from 'constants/settlementInstruction'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'
import saveAs from 'file-saver'
import Downloader from 'popcorn-js/financial/settlementInstruction/downloader.js'
import {EXACT_CRITERION, ExactCriterion} from 'popcorn-js/search/criteria/types'
import ConnectedSiInvoiceTradeLinkerContainer from './SIExposureTradeLinkerDialog/SIExposureTradeLinkerDialogContainer'
import {InvoiceRecordkeeper} from '../../../popcorn-js/financial/invoice';

class SettlementInstruction extends React.Component {
  state = {
    requestsForFunds: [],
    selected: undefined,

    showLinker: false,
    showSISummaryDialog: false,

    errorMessage: undefined,
    successMessage: undefined,

    invoiceCache: [],
  }

  componentDidMount() {
    this.findSettlementInstruction().finally()
  }

  populateInvoiceCache = async () => {
    const {
      requestsForFunds,
    } = this.state

    let invoiceFindCrit = []
    requestsForFunds.forEach(r => {
      r.invoiceAmountsRequested.forEach(a => {
        invoiceFindCrit.push({
          type: EXACT_CRITERION,
          value: {
            field: 'id',
            text: a.invoiceId,
          },
        })
      })
    })
    const findResponse = await InvoiceRecordkeeper.find({ criteria: invoiceFindCrit });
    this.setState({
      invoiceCache: findResponse.records
    })
  }

  findSettlementInstruction = async () => {
    try {
      const siFindResponseResponse = await Recordkeeper.find(
        [
          new ExactCriterion({field: 'status', text: StatusSubmitted}),
          new ExactCriterion({field: 'financialYear', text: 'CURRENT'}),
        ],
      )
      this.setState({
        requestsForFunds: siFindResponseResponse.records,
        total: siFindResponseResponse.total,
      })
      // retrieve associated invoices
      this.populateInvoiceCache().finally()
    } catch (e) {
      console.error('error finding sis', e)
      this.setState({errorMessage: e.message || e})
    }
  }

  handleSelection = (selected) => {
    this.setState({selected})
  }

  handleSummaryDialog = () => {
    this.setState({showSISummaryDialog: true})
  }

  handleExport = async () => {
    try {
      const settlementInstructionExportResult = await Downloader.downloadConfirmation(
        this.state.selected.id)

      // convert base64 to byte array
      const binData = atob(settlementInstructionExportResult.data)
      const bytes = new Array(binData.length)
      for (let i = 0; i < binData.length; i++) {
        bytes[i] = binData.charCodeAt(i)
      }

      const blob = new Blob([new Uint8Array(bytes)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
      })
      saveAs(blob, 'settlementInstruction_export.xlsx')

    } catch (e) {
      console.error(e)
      this.setState(
        {
          errorMessage: e.message || e,
        },
      )
    }
  }

  render() {
    const {
      requestsForFunds,
      total,
      selected,
      invoiceCache
    } = this.state

    return (
      <div>
        <Grid container>
          <Grid
            item
            xs={12}>
            <ReportCard>
              <SettlementInstructionBlotterContainer
                additionalActions={this.buildActions()}
                invoiceCache={invoiceCache}
                onDoubleClick={si =>
                  this.setState({
                    showSISummaryDialog: true,
                    selected: si,
                  })
                }
                onSelect={this.handleSelection}
                requestsForFunds={requestsForFunds}
                selected={selected}
                total={total}
              />
            </ReportCard>
          </Grid>
        </Grid>
        {this.renderDialogs()}
      </div>
    )
  }

  onUpdate = (settlementInstruction) => {
    this.setState({selected: settlementInstruction})
    this.findSettlementInstruction().finally()
  }

  buildActions() {
    const {
      selected,
    } = this.state

    const blotterActions = []
    if (
      selected &&
        (selected.status === StatusSubmitted)
    ) {
      blotterActions.push(
        <Tooltip
          key={'link'}
          title={'Link Trades'}>
          <IconButton
            key={'link'}
            onClick={() => this.setState({showLinker: true})}
          >
            <LinkIcon/>
          </IconButton>
        </Tooltip>,
      )
    }
    if (selected) {
      blotterActions.push(
        <Tooltip
          key={'view'}
          title={'View Details'}>
          <IconButton
            key={'view'}
            onClick={this.handleSummaryDialog}>
            <ViewIcon/>
          </IconButton>
        </Tooltip>,
      )
      blotterActions.push(
        <Tooltip
          enterDelay={400}
          key={'Import'}
          title={'Import'}>
          <IconButton
            disabled={selected.status !== StatusComplete}
            onClick={this.handleExport}
          >
            <DownloadIcon/>
          </IconButton>
        </Tooltip>)
    }

    return blotterActions
  }

  renderDialogs() {
    const {
      errorMessage,
      successMessage,

      showLinker,
      selected,

      showSISummaryDialog,
    } = this.state

    return (
      <span>
        <NotificationSweetAlert
          errorMessage={errorMessage}
          onClose={() => this.setState({
            errorMessage: undefined,
            successMessage: undefined,
          })}
          successMessage={successMessage}
        />
        {showLinker &&
        <ConnectedSiInvoiceTradeLinkerContainer
          closeDialog={() => {
            this.setState({showLinker: false})
          }}
          onUpdate={this.onUpdate}
          settlementInstruction={selected}
          showDialog={showLinker}
        />}
        {showSISummaryDialog &&
        <SettlementInstructionSummaryDialog
          SI={selected}
          height={600}
          onClose={() => this.setState({showSISummaryDialog: false})}
          onSubmitSuccess={() => null}
          onUpdateSuccess={() => null}
          readOnly
          show={showSISummaryDialog}
        />}
      </span>
    )
  }
}

export default SettlementInstruction
