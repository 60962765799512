import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import {withStyles} from '@material-ui/core'
import NotificationSweetAlert
  from 'components/SweetAlert/NotificationSweetAlert'
//other imports
import Table from '../../../components/Table'
import ProcessingOrgEntity from 'popcorn-js/legalEntity/party/processingOrg/ProcessingOrg'
import BasicInformation from 'components/Cards/BasicInformation'
import Recordkeeper from 'popcorn-js/legalEntity/party/processingOrg/recordkeeper'
import ClientTierRecordkeeper from 'popcorn-js/financial/clientTier/recordkeeper'
import {
  FilterToSortBy, objectCopy, SortByToFilter,
  SortNumberString,
  SortObjects,
} from '../../../utils/Utils'
import Validator from 'popcorn-js/legalEntity/party/processingOrg/validator'
import {FullPageLoader as Loader} from 'components/Loader/Loader'
import HistoryLayout from '../../History/HistoryLayout'
import {ProcessingOrgFields} from '../../History/ProcessingOrgHistory/ProcessingOrgFields'
import {
  PARTY_TYPE_PROCESSING_ORG, PARTY_TYPE_SYSTEM,
} from 'constants/partyTypes'
import Comparator from 'popcorn-js/legalEntity/party/processingOrg/comparator'
import CurrencyPairs from 'components/Cards/CurrencyPairs'
import ClientTierEntity from 'popcorn-js/financial/clientTier/ClientTier'
import Typography from '@material-ui/core/Typography'

const styles = () => ({
  tabContainer: {
    height: 'calc(100vh - 295px)',
    marginTop: '8px',
    overflowY: 'scroll',
  },
})

const ACTIVE_STATE_VIEWING = 'ACTIVE_STATE_VIEWING'
const ACTIVE_STATE_CREATING = 'ACTIVE_STATE_CREATING'
const ACTIVE_STATE_EDITING = 'ACTIVE_STATE_EDITING'
const ACTIVE_STATE_VIEWING_DELETE = 'ACTIVE_STATE_VIEWING_DELETE'

const processingOrgListLength = 10
const processingOrgConfigurationListId = `${ProcessingOrgEntity.camel}ConfigurationList`
const newAutoFocusElementId = 'partyCode'

class ProcessingOrg extends React.Component {
  state = {
    activeState: ACTIVE_STATE_VIEWING,

    processingOrgs: [],
    total: 0,
    selectedRowIndex: -1,
    previouslySelectedRowIndex: -1,
    sortBy: ['name'],

    history: [],
    selected: new ProcessingOrgEntity(),
    original: new ProcessingOrgEntity(),
    previouslySelectedEntity: new ProcessingOrgEntity(),

    isLoading: false,
    showHistory: false,

    successMessage: undefined,
    errorMessage: undefined,
    warningMessage: undefined,
    confirmationMethod: undefined,

    invalidFields: {},

    tabIndex: 0,

    assignedCurrencyPairIds: [],
    availableCurrencyPairIds: [],
  }

  componentDidMount() {
    this.find()
    this.findClientTiers()
    this.processCurrencyPairs()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partyCurrencyPairIds.length !==
      this.props.partyCurrencyPairIds.length) {
      this.processCurrencyPairs()
    }
  }

  processCurrencyPairs = () => {
    const assignedCurrencyPairIds = this.state.selected.currencyPairIds || []
    const availableCurrencyPairIds = this.props.partyCurrencyPairIds.filter(
      c => !assignedCurrencyPairIds.find(assignedC => assignedC === c))
    this.setState({availableCurrencyPairIds, assignedCurrencyPairIds})
  }

  handleTabChange = (event, tabIndex) => {
    this.setState({tabIndex})
  }

  find = async (deleted = false) => {
    const {
      sortBy,
    } = this.state

    this.setState({isLoading: true})
    try {
      const findResponse = await Recordkeeper.find(
        [],
        {sortBy, order: [], limit: 0, offset: 0},
        deleted,
      )
      const processingOrgs = findResponse.records.map(
        b => new ProcessingOrgEntity(b),
      )
      processingOrgs.sort((a, b) => SortObjects(a, b, sortBy))

      const total = findResponse.total
      const selected = total > 0 ? processingOrgs[0] : new ProcessingOrgEntity()
      const selectedRowIndex = total > 0 ? 0 : -1

      this.handleSelection(selected, selectedRowIndex,
        deleted ? ACTIVE_STATE_VIEWING_DELETE : ACTIVE_STATE_VIEWING)
      this.setState({total, processingOrgs})
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false, tabIndex: 0})
  }

  findClientTiers = async () => {
    this.setState({isLoading: true})
    try {
      const findResponse = await ClientTierRecordkeeper.find(
        [],
        {},
        false,
      )
      this.setState({
        clientTiers: findResponse.records.map(c => new ClientTierEntity(c)),
      })
    } catch (e) {
      this.setState({
        errorMessage: 'Could not retrieve client tiers: ' + e.message || e,
      })
    }
    this.setState({isLoading: false})
  }

  handleSelection(selected, index, activeState) {
    if (this.state.activeState === ACTIVE_STATE_EDITING && !activeState) {
      this.showDiscardConfirmation(() => {
        this.handleHideAlert()
        this.handleSelection(selected, index, ACTIVE_STATE_VIEWING)
      })
      return
    }

    let t = activeState
    const {
      activeState: currentState,
    } = this.state
    if (!activeState) {
      switch (currentState) {
        case ACTIVE_STATE_CREATING:
        case ACTIVE_STATE_EDITING:
          t = ACTIVE_STATE_VIEWING
          break
        default:
          t = currentState
      }
    }

    this.setState({
      selected: new ProcessingOrgEntity(selected),
      original: new ProcessingOrgEntity(selected),
      previouslySelected: new ProcessingOrgEntity(selected),

      selectedRowIndex: index,
      previouslySelectedRowIndex: index,

      activeState: t,
    }, () => this.processCurrencyPairs())

  }

  handleNew = () => {
    this.setState({
      selected: new ProcessingOrgEntity(),
      original: new ProcessingOrgEntity(),
      selectedRowIndex: -1,
      activeState: ACTIVE_STATE_CREATING,
      tabIndex: 0,
    }, () => document.getElementById(newAutoFocusElementId).focus())
  }

  handleViewDelete = () => {
    this.find(true)
  }

  handleRetrieveHistory = async () => {
    this.setState({isLoading: true})
    try {
      const retrieveHistoryResponse = await Recordkeeper.retrieveHistory(
        this.state.selected.id,
      )
      this.setState({
        history: retrieveHistoryResponse.history.map(
          processingOrg => new ProcessingOrgEntity(processingOrg),
        ),
        showHistory: true,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleReturn = () => {
    this.find()
  }

  handleRestore = async () => {
    this.setState({isLoading: true})
    try {
      await Recordkeeper.restore(this.state.selected.id)
      const processingOrgs = this.state.processingOrgs.slice()
      const idx = processingOrgs.findIndex(
        c => c.id === this.state.selected.id,
      )
      processingOrgs.splice(idx, 1)
      if (processingOrgs.length > idx) {
        this.handleSelection(processingOrgs[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ? new ProcessingOrgEntity() : processingOrgs[idx - 1],
          idx - 1)
      }
      this.setState({
        successMessage: `${ProcessingOrgEntity.capital} restored`,
        processingOrgs,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    })
  }

  handleUpdate = async () => {
    this.setState({isLoading: true})
    try {
      const updateResponse = await Recordkeeper.update(
        this.state.selected,
        this.state.original.id,
      )
      const processingOrgs = this.state.processingOrgs.slice() || []
      const selected = new ProcessingOrgEntity(updateResponse.processingOrg)
      const processingOrgToReplaceIndex = processingOrgs.findIndex(
        b => b.id === updateResponse.processingOrg.id,
      )
      if (processingOrgToReplaceIndex >= 0) {
        processingOrgs[processingOrgToReplaceIndex] = selected
      }
      processingOrgs.sort(
        (a, b) => SortObjects(a, b, this.state.sortBy),
      )
      const selectedIndex = processingOrgs.findIndex(
        b => b.id === selected.id,
      )

      this.handleSelection(selected, selectedIndex, ACTIVE_STATE_VIEWING)
      this.setState({
        successMessage: `${ProcessingOrgEntity.capital} updated`,
        processingOrgs,
      })
      ProcessingOrg.scrollToPos(
        processingOrgToReplaceIndex / processingOrgs.length
      )
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleCreate = async () => {
    if (await this.isValid()) {
      this.setState({isLoading: true})
      const processingOrg = this.state.selected

      // populate party code in the client tier
      processingOrg.parentPartyCode = this.props.partyCode
      try {
        const createResponse = await Recordkeeper.create(processingOrg)
        const selected = new ProcessingOrgEntity(createResponse.processingOrg)
        const processingOrgs = this.state.processingOrgs.slice() || []
        processingOrgs.push(selected)
        processingOrgs.sort(
          (a, b) => SortObjects(a, b, this.state.sortBy),
        )

        const selectedRowIndex = processingOrgs.findIndex(
          c => c.id === selected.id,
        )

        this.handleSelection(selected, selectedRowIndex,
          ACTIVE_STATE_VIEWING)

        this.setState(
          {
            successMessage: `${ProcessingOrgEntity.capital} created`,
            processingOrgs,
          })
        this.find()
      } catch (e) {
        this.setState({errorMessage: e})
      }
      this.setState({isLoading: false})
    }
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    try {
      await Recordkeeper.delete(this.state.selected.id)
      const processingOrgs = this.state.processingOrgs.slice() || []
      const idx = processingOrgs.findIndex(
        c => c.id === this.state.selected.id)
      processingOrgs.splice(idx, 1)

      if (processingOrgs.length > idx) {
        this.handleSelection(processingOrgs[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ? new ProcessingOrgEntity() : processingOrgs[idx - 1],
          idx - 1)
      }

      this.setState({
        successMessage: `${ProcessingOrgEntity.capital} deleted`,
        processingOrgs,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    this.handleHideAlert()
    try {
      await Recordkeeper.delete(this.state.selected.id)
      const processingOrgs = this.state.processingOrgs.slice()
      const idx = processingOrgs.findIndex(
        c => c.id === this.state.selected.id,
      )
      processingOrgs.splice(idx, 1)

      if (processingOrgs.length > idx) {
        this.handleSelection(processingOrgs[idx], idx)
      } else {
        this.handleSelection(
          idx === 0
            ? new ProcessingOrgEntity()
            : processingOrgs[idx - 1],
          idx - 1,
        )
      }

      this.setState({
        successMessage: `${ProcessingOrgEntity.capital} deleted`,
        processingOrgs,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  showDeleteConfirmation = () => {
    this.setState({
      warningMessage: `You are about to permanently delete ${this.state.selected.partyCode} - '${this.state.selected.name}'. Do you want to continue?`,
      confirmationMethod: this.handleDelete,
    })
  }

  showDiscardConfirmation = (confirm) => {
    this.setState({
      warningMessage: 'You have unsaved changes. Do you want to continue?',
      confirmationMethod: confirm,
    })
  }

  handleCancelUpdate = () => {
    this.setState({
      invalidFields: {},
      selected: new ProcessingOrgEntity(this.state.original),
      activeState: ACTIVE_STATE_VIEWING,
    }, () => this.processCurrencyPairs())
  }

  handleCancelCreate = () => {
    this.setState({
      invalidFields: {},
      selected: new ProcessingOrgEntity(this.state.previouslySelected),
      original: new ProcessingOrgEntity(this.state.previouslySelected),
      activeState: ACTIVE_STATE_VIEWING,
      selectedRowIndex: this.state.previouslySelectedRowIndex,
      tabIndex: 0,
    })
  }

  handleFieldChange = (field, newValue) => {
    const {
      selected,
      invalidFields,
    } = this.state
    selected[field] = newValue
    invalidFields[field] = undefined

    this.setState({selected, invalidFields})
    this.handleChanges(selected)
  }

  handleAddCurrencyPairs = (currencyPairs) => {
    const selected = new ProcessingOrgEntity(objectCopy(this.state.selected))
    for (const ccyPair of currencyPairs) {
      selected.currencyPairIds.push(ccyPair.value)
    }
    this.setState({selected}, () => this.processCurrencyPairs())
    this.handleChanges(selected)
  }

  handleRemoveCurrencyPairs = (currencyPairs) => {
    const selected = new ProcessingOrgEntity(objectCopy(this.state.selected))
    selected.currencyPairIds = (selected.currencyPairIds || []).filter(
      c => !currencyPairs.find(cc => cc.value === c))
    this.setState({selected}, () => this.processCurrencyPairs())
    this.handleChanges(selected)
  }

  handleChanges = (selected) => {
    const {
      activeState: currentState,
    } = this.state
    const {
      selectedRowIndex,
    } = this.state

    let activeState
    switch (currentState) {
      case ACTIVE_STATE_CREATING:
        activeState = ACTIVE_STATE_CREATING
        break

      case ACTIVE_STATE_VIEWING:
        activeState = selectedRowIndex >= 0
          ? !Comparator.CompareAll(this.state.original, selected)
            ? ACTIVE_STATE_EDITING
            : ACTIVE_STATE_VIEWING
          : ACTIVE_STATE_CREATING
        break

      case ACTIVE_STATE_EDITING:
        activeState = !Comparator.CompareAll(this.state.original, selected)
          ? ACTIVE_STATE_EDITING
          : ACTIVE_STATE_VIEWING
        break

      default:
    }
    this.setState({activeState})
  }

  render() {
    const {
      invalidFields,
      activeState,
      selected,
      processingOrgs,
      selectedRowIndex,
    } = this.state

    const showCurrencyPairsTab = activeState === ACTIVE_STATE_EDITING ||
      (activeState === ACTIVE_STATE_VIEWING && selectedRowIndex >= 0)

    const {
      classes,
    } = this.props

    return (
      <div id="legalEntitiesProcessingOrgRoot">
        {this.renderDialogs()}
        <Card
          style={{
            marginBottom: '10px',
            maxHeight: 'calc(100vh - 152px)',
          }}
        >
          <CardHeader
            action={
              <div>
                <Button disabled>Import</Button>
                <Button disabled>Export</Button>
              </div>
            }
            title={
              <Typography
                gutterBottom
                variant="subtitle1">
                {activeState === ACTIVE_STATE_VIEWING_DELETE ?
                  'Processing Organisations - Delete' :
                  'Processing Organisations'}
              </Typography>
            }
          />
          <CardContent
            style={{
              padding: '0',
              margin: '0px 24px 24px 24px',
            }}
          >
            <Grid
              container
              direction={'row'}
              spacing={3}>
              <Grid
                item
                lg={3}
                md={4}
                sm={6}
                xs={12}>
                <Table
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'name',
                    },
                  ]}
                  data={processingOrgs}
                  defaultSortMethod={(a, b) => SortNumberString(a, b)}
                  defaultSorted={SortByToFilter(this.state.sortBy)}
                  getTdProps={(state, rowInfo) => {
                    const rowIndex = rowInfo ? rowInfo.index : undefined
                    return {
                      onClick: (e, handleOriginal) => {
                        if (rowInfo) {
                          this.handleSelection(
                            new ProcessingOrgEntity(rowInfo.original),
                            rowIndex,
                          )
                        }
                        if (handleOriginal) {
                          handleOriginal()
                        }
                      },
                      style: {
                        background: rowIndex ===
                        selectedRowIndex ?
                          this.props.theme.palette.secondary.light :
                          'white',
                        color: rowIndex === selectedRowIndex ?
                          this.props.theme.palette.secondary.contrastText :
                          'black',
                      },
                    }
                  }}


                  id={`${ProcessingOrgEntity.camel}Table`}
                  noDataText={'No Processing Organisations found'}
                  onSortedChange={(filter) => this.setState(
                    {sortBy: FilterToSortBy(filter)})}
                  pageSize={Math.max(processingOrgListLength,
                    (processingOrgs || []).length)}
                  showPagination={false}
                />
              </Grid>
              <Grid
                item
                lg={9}
                md={8}
                sm={6}
                xs={12}>
                <AppBar
                  position={'static'}
                >
                  <Tabs
                    onChange={this.handleTabChange}
                    scrollButtons="auto"
                    value={this.state.tabIndex}
                    variant={'scrollable'}
                  >
                    <Tab
                      id="legalEntityProcessingOrgBasicInfoTab"
                      label="Basic Info"
                    />
                    {showCurrencyPairsTab &&
                    <Tab
                      id="legalEntityProcessingOrgCurrencyPairsTab"
                      label="Currency Pairs"
                    />
                    }

                  </Tabs>
                </AppBar>
                {this.state.tabIndex === 0 &&
                <Grid
                  className={classes.tabContainer}
                  container
                  direction={'row'}
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}>
                    <BasicInformation
                      clientTiers={this.state.clientTiers}
                      disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                      invalidFields={invalidFields}
                      isNew={activeState === ACTIVE_STATE_CREATING ||
                      selectedRowIndex < 0}
                      onChange={(field, value) => this.handleFieldChange(field,
                        value)}
                      party={selected}
                      partyCode={this.props.partyCode}
                      viewOfPartyType={PARTY_TYPE_PROCESSING_ORG}
                      viewingPartyType={this.props.partyType}
                    />
                  </Grid>
                </Grid>
                }
                {this.state.tabIndex === 1 &&
                <Grid
                  className={classes.tabContainer}
                  container
                  direction={'row'}
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}>
                    <CurrencyPairs
                      assignedCurrencyPairOptions={this.state.assignedCurrencyPairIds.map(
                        c => ({
                          value: c,
                          text: (this.props.allCurrencyPairs.find(
                            pair => pair.id === c) || {}).name || c,
                        }))}
                      availableCurrencyPairOptions={this.state.availableCurrencyPairIds.map(
                        c => ({
                          value: c,
                          text: (this.props.allCurrencyPairs.find(
                            pair => pair.id === c) || {}).name || c,
                        }))}

                      onAdd={this.handleAddCurrencyPairs}
                      onRemove={this.handleRemoveCurrencyPairs}

                    />
                  </Grid>
                </Grid>
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {this.renderControls()}
      </div>
    )
  }

  renderControls = () => {
    const {
      selectedRowIndex,
      activeState,
      isLoading,
    } = this.state

    return (
      <CardActions
        style={{
          display: 'block',
        }}
      >
        {activeState === ACTIVE_STATE_VIEWING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${ProcessingOrgEntity.camel}NewButton`}
          onClick={this.handleNew}
          size="medium"
        >
          New
        </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING && selectedRowIndex >= 0 &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${ProcessingOrgEntity.camel}DeleteButton`}
          onClick={this.handleDelete}
          size="medium"
        >
          Delete
        </Button>
        }
        {activeState === ACTIVE_STATE_EDITING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${ProcessingOrgEntity.camel}SaveButton`}
          onClick={this.handleUpdate}
          size="medium"
        >
          Save Changes
        </Button>
        }
        {activeState === ACTIVE_STATE_EDITING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${ProcessingOrgEntity.camel}CancelUpdateButton`}
          onClick={this.handleCancelUpdate}
          size="medium"
        >
          Cancel
        </Button>
        }
        {activeState === ACTIVE_STATE_CREATING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${ProcessingOrgEntity.camel}CreateButton`}
          onClick={this.handleCreate}
          size="medium"
        >
          Save New
        </Button>
        }
        {activeState === ACTIVE_STATE_CREATING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${ProcessingOrgEntity.camel}CancelCreateButton`}
          onClick={this.handleCancelCreate}
          size="medium"
        >
          Cancel
        </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING_DELETE && selectedRowIndex >=
        0 &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${ProcessingOrgEntity.camel}RestoreButton`}
          onClick={this.handleRestore}
          size="medium"
        >
          Restore
        </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING_DELETE && selectedRowIndex >=
        0 &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${ProcessingOrgEntity.camel}DeleteButton`}
          onClick={this.showDeleteConfirmation}
          size="medium"
        >
          Delete
        </Button>
        }
        <span
          style={{
            float: 'right',
          }}
        >
          {activeState === ACTIVE_STATE_VIEWING && selectedRowIndex >= 0 &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ProcessingOrgEntity.camel}RetrieveHistoryButton`}
            onClick={this.handleRetrieveHistory}
            size="medium"
          >
            Show History
          </Button>
          }
          {activeState === ACTIVE_STATE_VIEWING &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ProcessingOrgEntity.camel}ViewDeleteButton`}
            onClick={this.handleViewDelete}
            size="medium"
          >
            View Delete
          </Button>
          }
          {activeState === ACTIVE_STATE_VIEWING_DELETE &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ProcessingOrgEntity.camel}ReturnFromDeleteButton`}
            onClick={this.handleReturn}
            size="medium"
          >
            Return
          </Button>
          }
        </span>
      </CardActions>
    )

  }

  renderDialogs = () => {
    const {
      isLoading,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      selected,
      showHistory,
      history,
    } = this.state

    return (
      <span>
        <Loader
          color={this.props.theme.palette.primary.main}
          isLoading={isLoading}
        />
        {showHistory &&
        <HistoryLayout
          entity={selected}
          entityFields={ProcessingOrgFields}
          entityHistory={history}
          entityName={ProcessingOrgEntity.capital}
          loading={isLoading}
          onHide={() => this.setState({showHistory: false})}
          open
        />
        }
        <NotificationSweetAlert
          customClass={`${ProcessingOrgEntity.camel}ConfigAlert`}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}

          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </span>
    )
  }

  isValid = async () => {
    const {
      selected,
    } = this.state
    try {
      await Validator.ValidateData(selected)
    } catch (invalidFields) {
      if (invalidFields) {
        this.setState({invalidFields})
        return false
      }
    }
    return true
  }

  static scrollToPos(pos) {
    const objDiv = document.getElementById(processingOrgConfigurationListId)
    if (objDiv) {
      objDiv.scrollTop = pos * objDiv.scrollHeight
    }
  }

}

ProcessingOrg.propTypes = {
  allCurrencyPairs: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  partyCode: PropTypes.string,
  partyCurrencyPairIds: PropTypes.arrayOf(PropTypes.string),


  partyType: PropTypes.oneOf([PARTY_TYPE_PROCESSING_ORG, PARTY_TYPE_SYSTEM]),
  theme: PropTypes.object,
}

ProcessingOrg.defaultProps = {
  partyCurrencyPairIds: [],
  clientTiers: [],
}

const StyledProcessingOrg = withStyles(styles, {withTheme: true})(ProcessingOrg)

export default StyledProcessingOrg
