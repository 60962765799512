function roundMillions(value) {
  if (value < 10 && value > 0) {
    return value.toFixed(2).toString()
  } else if (value === 0) {
    return '00.0'
  } else {
    return value.toFixed(1)
  }
}

export function roundScaleAndFormatNumber(amount, currencySymbol, showInMillions = false, decimals = 0, showSuffix = true) {
  try {
    const sign = amount < 0 ? '-' : ''
    const symbol = currencySymbol ? currencySymbol : ''
    const absAmount = Math.abs(amount)
    if (showInMillions) {
      const millions = absAmount / 1000000
      return sign + symbol + `${roundMillions(millions)}${showSuffix ? 'm' : ''}`
    }
    return sign + symbol + numberWithCommas(round(absAmount, decimals))
  } catch (e) {
    console.error('error formatting and rounding number', e)
    return 0
  }
}

export function nanToZeroWrapper(numberFormatter, amount, currencySymbol, showInMillions = false, decimals = 0) {
  const formattedAmount = numberFormatter(amount, currencySymbol, showInMillions, decimals)
  if (formattedAmount === 'NaN' || formattedAmount === '-NaN') {
    return roundScaleAndFormatNumber(0.0, currencySymbol, showInMillions, decimals)
  }
  return formattedAmount
}

export function round(value, decimals) {
  const wasNegative = value < 0
  value = Number(Math.round(Math.abs(value) + 'e' + decimals) + 'e-' + decimals)
  return wasNegative ? -1 * value : value
}

function numberWithCommas(x) {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}
