/* eslint-disable react/display-name */
import React, {forwardRef, useImperativeHandle} from 'react'
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterRow from './FilterRow'
import WrappedTableRow from './WrappedTableRow'
import useColConfig from './AndileTableColumnConfig'
import ColumnConfigDialog from '../Table/Common/ColumnConfigDialog'
import {MdViewColumn} from 'react-icons/md'
import classNames from 'classnames'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover'
import {ComponentLevelError} from '../Error/Error'
import Checkbox from '@material-ui/core/Checkbox'
import { usePerfectScrollbar } from 'hooks'
import { useStyletron } from 'styletron-react';

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    sortBy,
    columns,
    onFilterChange,
    onChangeSorting,
    initialCriteria,
    showCheckboxes,
    onSelectAll,
    selected,
    data,
  } = props;

  const numSelected = selected ? selected.length : 0
  const rowCount = data ? data.length : 0

  const [css] = useStyletron();

  return (
    <TableHead>
      <TableRow>
        {showCheckboxes && <TableCell
          classes={{head: classes.tableCellHead}}
          key={'checkbox'}
          padding={'default'}
        >
          <Checkbox
            checked={numSelected === rowCount}
            className={css({padding: 0, color: '#2BBED9'})}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            inputProps={{'aria-label': 'select all'}}
            onChange={onSelectAll}
            size={'small'}
            style={{padding: '0px'}}
          />
        </TableCell>
        }
        {columns.map((col, index) => (
          <TableCell
            align={col.align || 'left'}
            classes={{head: classes.tableCellHead}}
            key={`head_${col.number}_${index}_${col.field}`}
            style={{
              padding: col.paddingZero ? 0 : undefined
            }}
            sortDirection={sortBy === col.field ? order : false}
          >
            <TableSortLabel
              active={col.field !== undefined && sortBy === col.field && !col.disableSort}
              direction={order}
              hideSortIcon={col.disableSort}
              onClick={() => {
                !col.disableSort && onChangeSorting(col.field, order === 'desc' ? 'asc' : 'desc')
              }}
            >
              {col.title}
              {sortBy === col.field ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      <FilterRow
        columns={columns}
        initialCriteria={initialCriteria}
        onFilterChange={onFilterChange}
        show={props.showFilterRow}
        showCheckboxes={showCheckboxes}
      />
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  selected: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onSelectAll: PropTypes.func,
  showCheckboxes: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  initialCriteria: PropTypes.object,
  onChangeSorting: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']),
  showFilterRow: PropTypes.bool,
  sortBy: PropTypes.string,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.border}`,
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    maxHeight: '330px',
    overflow: 'hidden',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCellHead: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    height: '48px',
    font: 'Bold 14px/20px Roboto',
    position: 'sticky',
    top: 0,
    whiteSpace: 'nowrap',
    zIndex: 1
  },
  smallIconButton: {
    padding: '0px'
  },
  toolBarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  toolBarSpacer: {
    flex: '1 1 100%',
  },
  toolBarActions: {
    color: theme.palette.text.secondary,
    display: 'flex'
  },
  toolBarTitle: {
    flex: '0 0 auto',
  },
  iconActive: {
    color: 'white'
  },
  footerGridColumnLayout: {
    display: 'flex',
    gridTemplateColumns: '1fr 1fr'
  },
  footer: {
    width: '100%',
  },
  helperText: {
    margin: 'auto',
    marginLeft: '16px'
  }
}));

const AndileTable = forwardRef((props, ref) => {
  const classes = useStyles()
  const [showFilterRow, setShowFilterRow] = React.useState(false)
  const [colConfigOpen, setColConfigOpen] = React.useState(false)
  const [menuPosition, setMenuPosition] = React.useState(undefined)
  const setScrollBarElementRef = usePerfectScrollbar(false, false)

  const {
    cellContentStyleCallBack,
    data,
    columns,
    colConfigCloseFromCard,
    title,
    onFilterChange,
    page,
    rowsPerPageOptions,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    count,
    loading,
    rowActions,
    onChangeSorting,
    order,
    sortBy,
    defaultColConfig,
    tableID,
    initialCriteria,
    rowDoubleClickAction,
    maxTableHeight,
    freeActions,
    freeActionsMenu,
    fixedTableHeight,
    showCheckboxes,
    onSelectAll,
    onRowCheck,
    selected,
    rowClickAction,
    disableColConfigButton,
    alternativeColumns,
    showFilterRowFromCard,
    disableHeader,
    disableFooter,
  } = props

  let {colConfigOpenFromCard} = props
  const emptyRows = rowsPerPageOptions - (data.length || 0)
  let [{visibleColumns, colConfig, usedDefaultColConfig, error, loading: colConfigLoading}, updateColConfig] = useColConfig(columns, defaultColConfig, tableID)

  if (alternativeColumns !== undefined) {
    visibleColumns = alternativeColumns
  }

  function handleOnClick(event) {
    const target = event.currentTarget && event.currentTarget.getBoundingClientRect()
    // setMenuPosition(menuPosition ? undefined : {x: target.x, y: target.y})
    setMenuPosition(menuPosition ? undefined : {x: target.x, y: target.y})
  }

  useImperativeHandle(ref, () => ({
    hideFilterRow() {
      onFilterChange([])
      setShowFilterRow(false);
    },
  }));

  function renderError(error) {
    return <ComponentLevelError
      errorMessage={error}
    />
  }

  return (
    <div className={classes.root}>
      {error && renderError(error)}
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorPosition={{top: (menuPosition || {}).y + 35, left: (menuPosition || {}).x + 35}}
        anchorReference="anchorPosition"
        onClose={handleOnClick}
        open={!!menuPosition}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {freeActionsMenu}
      </Popover>
      {colConfigOpen &&
      <ColumnConfigDialog
        closeDialog={() => {
          setColConfigOpen(false)
        }}
        columnConfig={colConfig}
        defaultColumnConfig={usedDefaultColConfig}
        onUpdate={(newColConfig) => {
          setColConfigOpen(false)
          updateColConfig(newColConfig)
        }
        }
        show={colConfigOpen}
      />}
      {colConfigOpenFromCard &&
      <ColumnConfigDialog
        closeDialog={() => {
          colConfigCloseFromCard()
        }}
        columnConfig={colConfig}
        defaultColumnConfig={usedDefaultColConfig}
        onUpdate={(newColConfig) => {
          setColConfigOpen(false)
          updateColConfig(newColConfig)
        }
        }
        show={colConfigOpenFromCard}
      />}
      <Paper className={classes.paper}>
        {!disableHeader && <Toolbar
          className={classes.toolBarRoot}
        >
          <div className={classes.toolBarTitle}>
            <Typography
              id="tableTitle"
              variant="h6">
              {title}
            </Typography>
          </div>
          <div className={classes.toolBarSpacer}/>
          <div className={classes.toolBarActions}>
            {freeActions}
            <Tooltip title="Filter list">
              <IconButton
                aria-label="filter list"
                onClick={() => {
                  onFilterChange(initialCriteria ? Object.keys(initialCriteria).map(field => initialCriteria[field]) : [])
                  setShowFilterRow(!showFilterRow)
                }}
              >
                <FilterListIcon/>
              </IconButton>
            </Tooltip>
            {!disableColConfigButton &&
            <Tooltip title="Open column configuration">
              <IconButton
                aria-label="view column"
                onClick={() => setColConfigOpen(!colConfigOpen)}
              >
                <MdViewColumn/>
              </IconButton>
            </Tooltip>
            }
            {freeActionsMenu &&
            <Tooltip title="More Actions">
              <IconButton
                aria-label="more"
                onClick={handleOnClick}
              >
                <MoreVertIcon
                  className={classNames({[classes.iconActive]: menuPosition})}
                />
              </IconButton>
            </Tooltip>
            }
          </div>
        </Toolbar>}
        <div
          className={classes.tableWrapper}
          ref={setScrollBarElementRef}
          style={{
            maxHeight: maxTableHeight && maxTableHeight,
            height: fixedTableHeight && fixedTableHeight
          }}>
          <Table
            aria-labelledby="tableTitle"
            className={classes.table}
            size={'small'}
          >
            <EnhancedTableHead
              classes={classes}
              columns={visibleColumns}
              data={data}
              disableHeader={disableHeader}
              disableFooter={disableFooter}
              initialCriteria={initialCriteria}
              onChangeSorting={onChangeSorting}
              onFilterChange={onFilterChange}
              onSelectAll={onSelectAll}
              order={order}
              selected={selected}
              showCheckboxes={showCheckboxes}
              showFilterRow={showFilterRow || showFilterRowFromCard}
              sortBy={sortBy}
            />
            <TableBody>
              {data.length === 0 ?
                <TableRow
                  style={{
                    opacity: loading && 0.3,
                  }}>
                  <TableCell
                    colSpan={20}
                  >
                    {loading ? 'Loading' : 'No Records Found'}
                  </TableCell>
                </TableRow> :
                data.map((row, index) => {
                  return (
                    <WrappedTableRow
                      cellContentStyleCallBack={cellContentStyleCallBack}
                      columns={visibleColumns}
                      index={index}
                      key={row.id || index}
                      loading={loading || colConfigLoading}
                      onRowCheck={onRowCheck}
                      page={page}
                      row={row}
                      rowActions={rowActions}
                      rowClickAction={rowClickAction}
                      rowDoubleClickAction={rowDoubleClickAction}
                      selected={selected}
                      showCheckboxes={showCheckboxes}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    background: 'none',
                    height: 30 * emptyRows
                  }}>
                  <TableCell
                    style={{
                      border: '0px',
                      padding: '0px'
                    }}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {!disableFooter && <div className={classes.footer}>
          <div
            className={classes.footerGridColumnLayout}
          >
            {rowsPerPageOptions && <div className={classes.helperText}>
              Scroll to see more rows on this page
            </div>}
            {rowsPerPageOptions &&
            <div>
              <TablePagination
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                component="div"
                count={count}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            </div>}
            {!rowsPerPageOptions && <div className={classes.helperText} />}
            {!rowsPerPageOptions &&
            <div>
              <TablePagination
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                component="div"
                count={count}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
              />
            </div>}
          </div>
        </div>}
      </Paper>
    </div>
  )

})

AndileTable.propTypes = {
  cellContentStyleCallBack: PropTypes.func,
  selected: PropTypes.array.isRequired,
  onSelectAll: PropTypes.func,
  onRowCheck: PropTypes.func,
  showCheckboxes: PropTypes.bool,
  colConfigOpenFromCard: PropTypes.bool,
  showFilterRowFromCard: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  alternativeColumns: PropTypes.array,
  count: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  defaultColConfig: PropTypes.array,
  fixedTableHeight: PropTypes.number,
  freeActions: PropTypes.array,
  freeActionsMenu: PropTypes.object,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  initialCriteria: PropTypes.object,
  loading: PropTypes.bool,
  disableColConfigButton: PropTypes.bool,
  disableHeader: PropTypes.bool,
  disableFooter: PropTypes.bool,
  FECTable: PropTypes.bool,
  maxTableHeight: PropTypes.number,
  onChangeSorting: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']),
  page: PropTypes.number.isRequired,
  rowActions: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  rowDoubleClickAction: PropTypes.func,
  rowClickAction: PropTypes.func,
  colConfigCloseFromCard: PropTypes.func,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  sortBy: PropTypes.string,
  tableID: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
}

AndileTable.defaultProps = {
  initialCriteria: {}
}

export default AndileTable
