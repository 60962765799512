import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {Marshal} from '../../../search/identifier/Marshaller'
import {
  ID_IDENTIFIER,
  PARTY_CODE_IDENTIFIER,
} from '../../../search/identifier/types'
import {MarshalCriteria as marshalCriteria} from 'popcorn-js/search/criteria/Marshaller'

/**
 * CompanyRecordkeeper is a convenience client for invoking the backend services
 */
export default class Recordkeeper {

  /**
   * @param criteria: Filter criteria
   * @param query: Query options
   * @param {boolean} [deleted]
   */
  static find({criteria, query, deleted}) {
    if (criteria != null) {
      criteria = marshalCriteria(criteria)
    }

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Company-Recordkeeper.Find',
      request: {
        criteria,
        query,
        deleted,
      },
    })
  }

  /**
   * @param {Company} company
   */
  static create(company) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Company-Recordkeeper.Create',
      request: {company},
    })
  }

  /**
   * @param {Company} company
   * @param {Identifier} id
   */
  static update(company, id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Company-Recordkeeper.Update',
      request: {
        company,
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  }

  /**
   * @param {Identifier} id
   */
  static delete(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Company-Recordkeeper.Delete',
      request: {serializedIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}})}
    })
  }

  /**
   * @param {Identifier} id
   */
  static restore(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Company-Recordkeeper.Restore',
      request: {serializedIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}})}
    })
  }

  /**
   * @param {Identifier} id
   */
  static retrieveHistory(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Company-Recordkeeper.RetrieveHistory',
      request: {serializedIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}})}
    })
  }

  /**
   * @param {string} id
   */
  static deleteForever(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Company-Recordkeeper.DeleteForever',
      request: {
        serializedIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      }
    })
  }

  /**
   * @param {string} partyCode
   */
  static retrieve(partyCode) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Company-Recordkeeper.Retrieve',
      request: {
        serializedIdentifier: Marshal({type: PARTY_CODE_IDENTIFIER, value: {partyCode}}),
      }
    })
  }

}
