import React from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'


const useStyles = makeStyles(() => ({
  root: {
    height: '32px',
    width: '32px',
    strokeWidth: '2'
  },
})
)


export function SettlementInstructionIcon() {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <SvgIcon
      className={classes.root}
      htmlColor={theme.palette.primary.contrastText}
      viewBox="0 0 32 32"

    >
      <g
        className="cls-1"
        id="Request_for_Funds">
        <g
          id="Group_22"
          transform="translate(-456.806 -503.822)">
          <g
            id="Group_19">
            <path
              d="M466.931,513.07v.525a2.975,2.975,0,0,1,.891.213,1.736,1.736,0,0,1,.646.484,1.96,1.96,0,0,1,.338.511,1.177,1.177,0,0,1,.119.478.574.574,0,0,1-.176.419.59.59,0,0,1-.431.177.6.6,0,0,1-.616-.514,1.09,1.09,0,0,0-.771-.8v2.015q.6.165.959.3a2.033,2.033,0,0,1,.637.393,1.8,1.8,0,0,1,.463.638,1.984,1.984,0,0,1,.162.811,2.135,2.135,0,0,1-.26,1.035,2.048,2.048,0,0,1-.762.788,2.712,2.712,0,0,1-1.2.362v1.209a1.1,1.1,0,0,1-.055.416.237.237,0,0,1-.242.131.276.276,0,0,1-.248-.106.63.63,0,0,1-.068-.33v-1.309a3.044,3.044,0,0,1-.992-.267,2.343,2.343,0,0,1-.708-.5,2.139,2.139,0,0,1-.419-.631,1.65,1.65,0,0,1-.135-.643.574.574,0,0,1,.181-.419.6.6,0,0,1,.452-.188.66.66,0,0,1,.371.1.538.538,0,0,1,.207.287c.088.265.164.467.226.608a1.214,1.214,0,0,0,.295.386,1.3,1.3,0,0,0,.522.266v-2.254a5.874,5.874,0,0,1-1.086-.4,1.811,1.811,0,0,1-.707-.626,2.079,2.079,0,0,1,.258-2.415,2.372,2.372,0,0,1,1.535-.619v-.514c0-.271.1-.407.3-.407S466.931,512.806,466.931,513.07Zm-.613,3.328v-1.859a1.7,1.7,0,0,0-.635.319.9.9,0,0,0-.016,1.182A1.976,1.976,0,0,0,466.318,516.4Zm.613,1.437v2.122a1.3,1.3,0,0,0,.758-.392.988.988,0,0,0,.267-.688.831.831,0,0,0-.259-.651A2.1,2.1,0,0,0,466.931,517.835Z"
              id="Path_13"/>
          </g>
          <g
            id="Group_20">
            <path
              d="M468.734,509.029h-4.258a.5.5,0,0,1-.375-.169l-3.123-3.532a.5.5,0,0,1,.375-.831.788.788,0,0,0,.569-.233,1.8,1.8,0,0,1,2.362,0,.811.811,0,0,0,1.138,0,1.8,1.8,0,0,1,2.361,0,.817.817,0,0,0,1.144,0,1.8,1.8,0,0,1,2.363,0,.8.8,0,0,0,.573.233.5.5,0,0,1,.374.831l-3.129,3.533a.5.5,0,0,1-.374.168Zm-4.033-1h3.808l2.456-2.772a3.12,3.12,0,0,1-.284-.2.817.817,0,0,0-1.145,0,1.8,1.8,0,0,1-2.364,0,.811.811,0,0,0-1.139,0,1.8,1.8,0,0,1-2.361,0,.809.809,0,0,0-1.138,0,3.093,3.093,0,0,1-.285.2l2.452,2.773Z"
              id="Path_14"/>
          </g>
          <g
            id="Group_21">
            <path
              d="M466.609,527.219c-4.9,0-7.873-.949-8.605-1.315-1.757-.878-1.146-2.255-.853-2.917a2.555,2.555,0,0,0,.154-.4c.029-.178.08-1.1.133-2.066a64.382,64.382,0,0,1,.626-7.192c.468-1.985,5.472-4.738,6.042-5.046a.5.5,0,0,1,.237-.059h4.531a.508.508,0,0,1,.238.059c.569.308,5.577,3.062,6.042,5.046a64.821,64.821,0,0,1,.623,7.155c.053.989.1,1.922.135,2.1a2.366,2.366,0,0,0,.155.4c.292.661.9,2.037-.854,2.915a26.274,26.274,0,0,1-8.6,1.315Zm-2.139-17.995c-2.005,1.1-5.163,3.187-5.434,4.334a65.17,65.17,0,0,0-.6,7.017c-.07,1.278-.109,1.954-.146,2.176a2.811,2.811,0,0,1-.226.642c-.339.765-.477,1.185.386,1.617.627.313,3.473,1.209,8.158,1.209a25.62,25.62,0,0,0,8.157-1.209c.86-.431.725-.851.387-1.616a2.736,2.736,0,0,1-.227-.644c-.038-.223-.077-.911-.148-2.211a65.34,65.34,0,0,0-.6-6.981c-.27-1.148-3.428-3.238-5.434-4.334Z"
              id="Path_15"/>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
