/* eslint-disable react/display-name */
import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import Popper from '@material-ui/core/Popper'
import {HexToRGBA} from 'utils/Utils'
import _ from 'lodash'
import Checkbox from '@material-ui/core/Checkbox'
import {StyledTableCellContent} from './StyledTableCellContent';
import { useStyletron } from 'styletron-react';

const useStyles = makeStyles(theme => ({
  paper: {
    background: HexToRGBA(theme.palette.background.paper, 0.8),
    boxShadow: '0px 0px',
    // height: '34px',
    overflowY: 'hidden',
    overflowX: 'hidden',
    marginRight: '-7px'
  },
  tableCellRoot: {
    whiteSpace: 'nowrap'
  },
  speedDialActionButtonSmall: {
    background: 'none',
    boxShadow: 'none',
    height: '34px',
    fontSize: 21,
    '&:hover': {
      background: 'none',
      boxShadow: 'none',
      color: theme.palette.text.primary
    },
    color: HexToRGBA(theme.palette.text.primary, 0.5),
    '&$disabled': {
      color: theme.palette.primary.light
    },
  },
}));

export default function WrappedTableRow(props) {
  const classes = useStyles();
  const theme = useTheme()
  const {
    row,
    page,
    index,
    columns,
    rowActions,
    loading,
    showCheckboxes,
    selected,
    rowClickAction} = props

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [css] = useStyletron();

  function handlePopoverOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const rowCols = _.cloneDeep(columns)

  let actions = []

  if (rowActions && {}.toString.call(rowActions) === '[object Function]') {
    actions = props.rowActions(row)
  } else if (Array.isArray(rowActions)) {
    actions = rowActions
  } else {
    actions = undefined
  }

  if (actions && actions.length > 0) {
    rowCols.push({
      paddingZero: true,
      render: () => (
        <div>
          <Popper
            anchorEl={anchorEl}
            id={'popper'}
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
            open={!!anchorEl}
            placement={'right'}
            transition
          >
            {({TransitionProps}) => (
              <Fade
                {...TransitionProps}
                timeout={{
                  enter: 1000,
                  exit: 50
                }}>
                <Paper
                  className={classes.paper}
                  style={{
                    pointerEvents: 'none',
                    height: anchorEl ? anchorEl.getBoundingClientRect().height : '34px'
                  }}
                >
                  <SpeedDial
                    ariaLabel="SpeedDial"
                    direction={'left'}
                    hidden
                    icon={<div/>}
                    open={!!anchorEl}
                  >
                    {actions.map(action => (
                      <SpeedDialAction
                        FabProps={{
                          style: {height: anchorEl ? anchorEl.getBoundingClientRect().height : '34px'},
                          className: classes.speedDialActionButtonSmall
                        }}
                        icon={action.icon}
                        key={action.tooltip}
                        onClick={() => action.onClick(row)}
                        tooltipPlacement={'bottom'}
                        tooltipTitle={action.tooltip}
                      />
                    ))}
                  </SpeedDial>
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      ),
    }
    )
  }
  
  let rowId = row.id
  if (row.Trade) {
    rowId = row.Trade.id
  }
  const isSelected = selected ? !!selected.find(trd => trd.id === rowId) : false

  return (
    <React.Fragment>
      <TableRow
        hover
        key={`${row.id}_${index}_${page}`}
        onClick={() => {
          rowClickAction && rowClickAction(row)
        }}
        onDoubleClick={() => {
          props.rowDoubleClickAction && props.rowDoubleClickAction(row)
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          opacity: loading && 0.3,
          background: !!anchorEl && `linear-gradient(90deg, ${theme.palette.highActions} 70%, ${theme.palette.secondary.main} 90%)`,
        }}
        tabIndex={-1}
      >
        {showCheckboxes && <TableCell
          classes={{head: classes.tableCellHead}}
          key={'checkbox'}
          padding={'checkbox'}
        >
          <Checkbox
            checked={isSelected}
            className={css({padding: 0, color: '#2BBED9'})}
            inputProps={{'aria-label': 'select all'}}
            size={'small'}
          />
        </TableCell>
        }
        {rowCols.map((col, index2) => {
          return (
            <TableCell
              align={col.align || 'left'}
              classes={{root: classes.tableCellRoot}}
              key={`${row.id}_${index}_${page}_${col.field}_${index2}`}
              style={{
                padding: col.paddingZero ? 0 : undefined
              }}
            >
              <StyledTableCellContent
                $theme={theme}
                col={col}
                row={row}
                // styleCallBack={
                //   cellContentStyleCallBack ? () => cellContentStyleCallBack(col, row) : undefined}
              >
                {col.render ? col.render(row) : row[col.field]}
              </StyledTableCellContent>
            </TableCell>
          )
        })}
      </TableRow>
    </React.Fragment>

  )
}

WrappedTableRow.propTypes = {
  selected: PropTypes.array,
  showCheckboxes: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  page: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  rowActions: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  rowDoubleClickAction: PropTypes.func,
  cellContentStyleCallBack: PropTypes.func,
  rowClickAction: PropTypes.func,
};
