import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
} from '@material-ui/core'
import {TIME_CRITERION} from 'popcorn-js/search/criteria/types'
import {HexToRGBA, isFunction} from 'utils/Utils'
import {TextField, TextFieldParseTypes} from 'components/FormContols'
import fromUnixTime from 'date-fns/fromUnixTime'
import {parseISO} from 'date-fns';

const styles = (theme) => ({
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderWidth: '2px',
      borderColor: `${theme.palette.border}`,
    }
  },

  cssFocused: {
  },

  input: {
    zIndex: 1,
    color: theme.palette.text.primary,
    fontSize: 12,
  },

  notchedOutline: {
    borderWidth: '2px',
    borderColor: `${theme.palette.border} !important`,
    background: theme.palette.background.paper,
    boxShadow:  `0 2px 2px 0 ${HexToRGBA('#000000', 1)}`,
  },
})

class Time extends Component {
  static CriteriaType = TIME_CRITERION

  constructor(props) {
    // current timestamp in milliseconds
    const date_ob = new Date(Date.now());

    super(props)
    this.state = {
      startDateTouched: false,
      endDateTouched: false,
    }
    this.state.value = {
      type: Time.CriteriaType,
      value: {
        field: props.fieldID,
        startDate: {
          ignore: true,
          date: date_ob.toISOString(),
          inclusive: true,
        },
        endDate: {
          ignore: true,
          date: date_ob.toISOString(),
          inclusive: true,
        },
      }
    }
    if (props.initValue === undefined) {
      this.state.value = {
        type: Time.CriteriaType,
        value: {
          field: props.fieldID,
          startDate: {
            ignore: true,
            date: date_ob.toISOString(),
            inclusive: true,
          },
          endDate: {
            ignore: true,
            date: date_ob.toISOString(),
            inclusive: true,
          },
        }
      }
    } else {
      this.state.value = props.initValue
      this.state.startDateTouched = true
      this.state.endDateTouched = true
    }
  }

  onChange = (event, start) => {
    let {
      value,
      startDateTouched,
      endDateTouched,
    } = this.state
    const {
      onChange
    } = this.props

    if (start) {
      if (!startDateTouched) {
        startDateTouched = true
      }
      value.value.startDate.date = fromUnixTime(event.target.value)
      value.value.startDate.ignore = event.target.value === 0
    } else {
      if (!endDateTouched) {
        endDateTouched = true
      }
      if (event.target.value !== 0) {
        let date = fromUnixTime(event.target.value).toISOString()
        let endDate = date.replace('00:00:00.000', '20:59:59.999')
        endDate = parseISO(endDate)

        value.value.endDate.date = endDate
      } else {
        value.value.endDate.date = event.target.value
      }
      value.value.endDate.ignore = event.target.value === 0
    }

    if (isFunction(onChange)) {
      if (
        (value.value.startDate.ignore) &&
        (value.value.endDate.ignore)
      ) {
        onChange(undefined)
      } else {
        onChange(value)
      }
    }

    this.setState({
      value,
      startDateTouched,
      endDateTouched,
    })
  }

  render () {
    const {
      filterConfig,
      showBorder,
      classes,
      theme
    } = this.props
    const {
      value,
      startDateTouched,
      endDateTouched,
    } = this.state

    let addInputProps = {}
    if (filterConfig) {
      if (filterConfig.width) {
        addInputProps.style = {
          width: filterConfig.width,
        }
      } else {
        addInputProps.style = {
          width: 175,
        }
      }
    }

    if (showBorder) {
      if (addInputProps.style) {
        addInputProps = {
          ...addInputProps,
          style: {
            ...addInputProps.style,
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.primary.light}`,
            borderRadius: '2px',
          },
        }
      } else {
        addInputProps = {
          ...addInputProps,
          style: {
            backgroundColor: 'white',
            border: '1px solid #D6D6D6',
            borderRadius: '2px',
          },
        }
      }
    }

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: 'auto',
          gridTemplateRows: 'auto auto',
          minWidth: '175px'
        }}>
        <div>
          <TextField
            {...addInputProps}
            InputProps={{
              classes: {
                input: classes.input,
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
            id="startDate"
            margin="dense"
            onChange={(e) => this.onChange(e, true)}
            parseType={TextFieldParseTypes.date}
            type="date"
            value={startDateTouched ? value.value.startDate.date : ''}
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            {...addInputProps}
            InputProps={{
              classes: {
                input: classes.input,
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
            id="endDate"
            margin="dense"
            onChange={(e) => this.onChange(e)}
            parseType={TextFieldParseTypes.date}
            type="date"
            value={endDateTouched ? value.value.endDate.date : ''}
            variant="outlined"
          />
        </div>
      </div>
    )
  }
}

const styledTime= withStyles(styles)(Time)

styledTime.propTypes = {
  fieldID: PropTypes.string.isRequired,
  filterConfig: PropTypes.object,
  initValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  showBorder: PropTypes.bool,
}

styledTime.defaultProps = {
  showBorder: false,
}

export default styledTime
