const requiredFields = [
  'partyCode',
  'name',
  'clientTierId',
  'adminEmail',
  'customsClientNumber',
]

class Validator {

  /**
   * @Param {Company} company
   */
  static ValidateData(company) {

    return new Promise(function (resolve, reject) {
      const invalidFields = {}
      let valid = true
      requiredFields.forEach(fieldName => {
        if (!company[fieldName] || company[fieldName] === '') {
          invalidFields[fieldName] = 'required'
          valid = false
        }
      })
      if (valid) {
        resolve(undefined)
      } else {
        reject(invalidFields)
      }
    })
  }
}

export default Validator
