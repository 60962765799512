import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  withStyles, Button,
} from '@material-ui/core'
import {EXACT_CRITERION, TEXT_CRITERION} from 'popcorn-js/search/criteria/types'
import {HexToRGBA, isFunction} from 'utils/Utils'
import AsyncSelect from 'react-select/async'
import {TextCriterion} from 'popcorn-js/search/criteria/types'
import {components} from 'react-select'
import {FaTimes} from 'react-icons/fa'

const styles = (theme) => ({
  blankFieldsButton: {
    color: theme.palette.text.primary,
    background: theme.palette.primary.main,
  },
})

const selectStyles = (theme) => ({
  valueContainer: styles => ({
    ...styles,
    border: 'none',
    padding: '0 4px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '6px',
  }),
  option: (styles, {isSelected, isFocused}) => ({
    ...styles,
    border: 'none',
    color: isSelected ? theme.palette.text.primary : theme.palette.primary.contrastText,
    backgroundColor: isSelected ? theme.palette.primary.light : isFocused ? HexToRGBA(theme.palette.primary.light, 0.5) :  theme.palette.primary.main,
  }),
  control: styles => ({
    ...styles,
    minHeight: '1px',
    border: 'none',
    padding: '0',
    borderRadius: '0',
    backgroundColor: 'none',
  }),
  input: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    color: theme.palette.text.primary,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
  }),
  singleValue: (styles, {data}) => {
    return ({
      ...styles,
      color: data.label === 'Blank Fields' || data.label === 'start typing...' ? theme.palette.text.secondary : theme.palette.text.primary,
      fontStyle: data.label === 'Blank Fields' || data.label === 'start typing...' ? 'italic' : ''
    })
  },
})

class AsyncOptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: ''
    }
    if (props.initValue === undefined) {
      this.state.value = {
        type: AsyncOptions.CriteriaType,
        value: {
          field: props.fieldID,
          text: '',
        },
      }
    } else {
      this.state.value = props.initValue
    }
  }

  static CriteriaType = TEXT_CRITERION

  onChange = (selected) => {
    let newTextVal = ''
    if (!selected) {
      newTextVal = ''
    } else {
      newTextVal = selected.value
    }

    let {
      value,
    } = this.state
    const {
      onChange,
    } = this.props

    value = {
      ...value,
      value: {
        ...value.value,
        text: newTextVal === 'No Filter' ? '' : newTextVal,
      },
    }

    if (isFunction(onChange)) {
      if ((newTextVal === '')) {
        onChange(undefined)
      } else {
        onChange(value)
      }
    }

    this.setState({
      selected,
    })
  }

  generateOptions = async inputValue => {
    try {
      const criterion = new TextCriterion({
        text: inputValue,
        field: this.props.filterConfig.displayAccessor,
      })
      const result = await this.props.filterConfig.asyncOptionsFetcher(criterion)
      return (result.records || []).map(b => ({
        value: b[this.props.filterConfig.valueAccessor],
        label: b[this.props.filterConfig.displayAccessor]
      }))
    } catch (e) {
      return e.message || e
    }
  }

  render() {
    const {
      filterConfig,
      theme,
      classes
    } = this.props
    const {
      selected,
      inputValue
    } = this.state

    const NoOptionsMessage = (props) => {
      if (inputValue) {
        return (
          <components.NoOptionsMessage {...props} />
        )
      }
      return (
        <div/>
      )
    }

    const ClearIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <FaTimes
            onClick={() => {
              this.setState({
                inputValue: '',
              },() => this.onChange(null))
            }}
            style={{
              margin: '5px',
              fontSize: 15
            }}
          />
        </components.DropdownIndicator>
      )
    }

    const MenuList = (props) => {
      if (inputValue) {
        return (
          <components.MenuList {...props} />
        )
      }
      return (
        <components.MenuList {...props}>
          <div
            onClick={() => {
              this.setState({
                blankField: true,
                menuIsOpen: false,
                inputValue: '',
                selected:  {label: 'Blank Fields' , value: ''},
                value:{
                  type: EXACT_CRITERION,
                  value: {
                    field: this.props.fieldID,
                    text: '',
                  },
                }
              })
              if (isFunction(this.props.onChange)) {
                if ((this.props.newTextVal === '')) {
                  this.props.onChange(undefined)
                } else {
                  this.props.onChange({
                    type: EXACT_CRITERION,
                    value: {
                      field: this.props.fieldID,
                      text: '',
                    },
                  })
                }
              }
            }}
            style={{
              cursor: 'pointer',
            }}
          >
            <Button
              className={classes.blankFieldsButton}
              size="small">
              Search Blank Fields
            </Button>
          </div>
          {props.children}
        </components.MenuList>
      )
    }

    return <div
      style={{
        height: '100%',
        display: 'grid',
        justifyItems: 'center',
        gridTemplateRows: '1fr',
      }}>
      <div
        style={{
          alignSelf: 'center',
          width: '100%',
          minWidth: '160px'
        }}
      >
        <AsyncSelect
          components={{MenuList, NoOptionsMessage, ClearIndicator}}
          defaultOptions={(filterConfig.defaultOptions || []).map(b => ({value: b[this.props.filterConfig.valueAccessor], label: b[this.props.filterConfig.displayAccessor]}))}
          isClearable
          loadOptions={this.generateOptions}
          menuPosition={'fixed'}
          onChange={selected => this.onChange(selected)}
          onInputChange={(value,  {action}) => {
            if (action === 'input-change') {
              this.setState({
                inputValue: value,
              })
            } else {
              this.setState({
                inputValue: '',
              })
            }
          }}
          styles={selectStyles(theme)}
          value={selected ? selected : {value: '', label: 'start typing...'}}
        />
      </div>
    </div>
  }
}

const styledText = withStyles(styles)(AsyncOptions)

styledText.propTypes = {
  fieldID: PropTypes.string.isRequired,
  filterConfig: PropTypes.object,
  initValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
}

export default withStyles(styles, {withTheme: true})(AsyncOptions)
