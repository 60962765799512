// AUTHENTICATION
export const SET_LOGIN_MESSAGE = 'SET_LOGIN_MESSAGE'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS'
export const LOGOUT = 'LOGOUT'

// Permissions
// myPermissions
export const FIND_ALL_MY_PERMISSIONS_STARTED = 'FIND_ALL_MY_PERMISSIONS_STARTED'
export const FIND_ALL_MY_PERMISSIONS_ERROR = 'FIND_ALL_MY_PERMISSIONS_ERROR'
export const FIND_ALL_MY_PERMISSIONS_SUCCESS = 'FIND_ALL_MY_PERMISSIONS_SUCCESS'

//  find
// CURRENCY
// findAllCurrencies
export const FIND_ALL_CURRENCIES_SUCCESS = 'FIND_ALL_CURRENCIES_SUCCESS'
// createCurrency
export const CREATE_CURRENCY_STARTED = 'CREATE_CURRENCY_STARTED'
export const CREATE_CURRENCY_SUCCESS = 'CREATE_CURRENCY_SUCCESS'
export const CREATE_CURRENCY_ERROR = 'CREATE_CURRENCY_ERROR'
// updateCurrency
export const UPDATE_CURRENCY_STARTED = 'UPDATE_CURRENCY_STARTED'
export const UPDATE_CURRENCY_SUCCESS = 'UPDATE_CURRENCY_SUCCESS'
export const UPDATE_CURRENCY_ERROR = 'UPDATE_CURRENCY_ERROR'

// PROCESSING BANK
// find
export const FIND_ALL_ProcessingBanks_SUCCESS = 'FIND_ALL_ProcessingBanks_SUCCESS'

// CURRENCY PAIR
// findAll
export const FIND_ALL_CURRENCY_PAIRS_STARTED = 'FIND_ALL_CURRENCY_PAIRS_STARTED'
export const FIND_ALL_CURRENCY_PAIRS_SUCCESS = 'FIND_ALL_CURRENCY_PAIRS_SUCCESS'
export const FIND_ALL_CURRENCY_PAIRS_ERROR = 'FIND_ALL_CURRENCY_PAIRS_ERROR'
// create
export const CREATE_CURRENCY_PAIR_STARTED = 'CREATE_CURRENCY_PAIR_STARTED'
export const CREATE_CURRENCY_PAIR_SUCCESS = 'CREATE_CURRENCY_PAIR_SUCCESS'
export const CREATE_CURRENCY_PAIR_ERROR = 'CREATE_CURRENCY_PAIR_ERROR'
// update
export const UPDATE_CURRENCY_PAIR_STARTED = 'UPDATE_CURRENCY_PAIR_STARTED'
export const UPDATE_CURRENCY_PAIR_SUCCESS = 'UPDATE_CURRENCY_PAIR_SUCCESS'
export const UPDATE_CURRENCY_PAIR_ERROR = 'UPDATE_CURRENCY_PAIR_ERROR'

//UI
export const SAVE_TABLE_CONFIG_STARTED = 'SAVE_TABLE_CONFIG_STARTED'
export const SAVE_TABLE_CONFIG_SUCCESS = 'SAVE_TABLE_CONFIG_SUCCESS'
export const SAVE_TABLE_CONFIG_ERROR = 'SAVE_TABLE_CONFIG_ERROR'
export const RETRIEVE_TABLE_CONFIG_STARTED = 'RETRIEVE_TABLE_CONFIG_STARTED'
export const RETRIEVE_TABLE_CONFIG_SUCCESS = 'RETRIEVE_TABLE_CONFIG_SUCCESS'
export const RETRIEVE_TABLE_CONFIG_ERROR = 'RETRIEVE_TABLE_CONFIG_ERROR'

// Roles
// find
export const FIND_ALL_ROLES_SUCCESS = 'FIND_ALL_ROLES_SUCCESS'
// update
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR'

export const CLEAR_PROTECTED_ROLE = 'CLEAR_PROTECTED_ROLE'
// Protected Roles
// retrieve
export const RETRIEVE_PROTECTED_ROLE_STARTED = 'RETRIEVE_PROTECTED_ROLE_STARTED'
export const RETRIEVE_PROTECTED_ROLE_SUCCESS = 'RETRIEVE_PROTECTED_ROLE_SUCCESS'
export const RETRIEVE_PROTECTED_ROLE_ERROR = 'RETRIEVE_PROTECTED_ROLE_ERROR'
// update
export const UPDATE_PROTECTED_ROLE_STARTED = 'UPDATE_PROTECTED_ROLE_STARTED'
export const UPDATE_PROTECTED_ROLE_SUCCESS = 'UPDATE_PROTECTED_ROLE_SUCCESS'
export const UPDATE_PROTECTED_ROLE_ERROR = 'UPDATE_PROTECTED_ROLE_ERROR'
// reset
export const RESET_PROTECTED_ROLE_STARTED = 'RESET_PROTECTED_ROLE_STARTED'
export const RESET_PROTECTED_ROLE_SUCCESS = 'RESET_PROTECTED_ROLE_SUCCESS'
export const RESET_PROTECTED_ROLE_ERROR = 'RESET_PROTECTED_ROLE_ERROR'

// Users
// findAllUsers
export const RETRIEVE_MY_USER_SUCCESS = 'RETRIEVE_MY_USER_SUCCESS'
export const FIND_ALL_PARTY_USERS_SUCCESS = 'FIND_ALL_PARTY_USERS_SUCCESS'
export const RETRIEVE_MY_USER_PROFILE_SUCCESS = 'RETRIEVE_MY_USER_PROFILE_SUCCESS'

// find a page
export const FIND_PAGE_ALL_USERS_STARTED = 'FIND_PAGE_ALL_USERS_STARTED'
export const FIND_PAGE_ALL_USERS_SUCCESS = 'FIND_PAGE_ALL_USERS_SUCCESS'
export const FIND_PAGE_ALL_USERS_ERROR = 'FIND_PAGE_ALL_USERS_ERROR'

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const UPDATE_USER_ROLES_SUCCESS = 'UPDATE_USER_ROLES_SUCCESS'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'
export const EMAIL_VERIFICATION_ERROR = 'EMAIL_VERIFICATION_ERROR'
export const EMAIL_VERIFICATION_SUCCESS  = 'EMAIL_VERIFICATION_SUCCESS'
export const CREATE_PASSWORD_STARTED = 'CREATE_PASSWORD_STARTED'
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS'
export const CREATE_PASSWORD_ERROR = 'CREATE_PASSWORD_ERROR'

//PARTY
// retrieve
export const RETRIEVE_MY_PARTY_STARTED = 'RETRIEVE_MY_PARTY_STARTED'
export const RETRIEVE_MY_PARTY_SUCCESS = 'RETRIEVE_MY_PARTY_SUCCESS'
export const RETRIEVE_MY_PARTY_ERROR = 'RETRIEVE_MY_PARTY_ERROR'
//context
export const PARTY_SWITCH_CONTEXT_STARTED = 'PARTY_SWITCH_CONTEXT_STARTED'
export const PARTY_SWITCH_CONTEXT_ERROR = 'PARTY_SWITCH_CONTEXT_ERROR'
export const PARTY_SWITCH_CONTEXT_SUCCESS = 'PARTY_SWITCH_CONTEXT_SUCCESS'

// DOCUMENTS
//  status
export const STATUS_OF_DOCUMENTS_SUCCESS = 'STATUS_OF_DOCUMENTS_SUCCESS'

// REGISTER
//  user
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'

export const POPCORN_INSTANCE_FIND_STARTED = 'POPCORN_INSTANCE_FIND_STARTED'
export const POPCORN_INSTANCE_FIND_SUCCESS = 'POPCORN_INSTANCE_FIND_SUCCESS'
export const POPCORN_INSTANCE_FIND_ERROR = 'POPCORN_INSTANCE_FIND_ERROR'
export const POPCORN_INSTANCE_DELETE_STARTED = 'POPCORN_INSTANCE_DELETE_STARTED'
export const POPCORN_INSTANCE_DELETE_SUCCESS = 'POPCORN_INSTANCE_DELETE_SUCCESS'
export const POPCORN_INSTANCE_DELETE_ERROR = 'POPCORN_INSTANCE_DELETE_ERROR'

export const RATE_SOCKET_CONNECTED = 'RATE_SOCKET_CONNECTED'
export const RATE_SOCKET_CONNECTING = 'RATE_SOCKET_CONNECTING'
export const RATE_SOCKET_DISCONNECTED = 'RATE_SOCKET_DISCONNECTED'

export const RATE_SOCKET_AUTHORIZED = 'RATE_SOCKET_AUTHORIZED'
export const RATE_SOCKET_AUTHORIZING = 'RATE_SOCKET_AUTHORIZING'
export const RATE_SOCKET_UNAUTHORIZED = 'RATE_SOCKET_UNAUTHORIZED'
export const RATE_SOCKET_STALE = 'RATE_SOCKET_STALE'

export const RICK_INSTANCES_FIND_STARTED = 'RICK_INSTANCES_FIND_STARTED'
export const RICK_INSTANCES_FIND_SUCCESS = 'RICK_INSTANCES_FIND_SUCCESS'
export const RICK_INSTANCES_FIND_ERROR = 'RICK_INSTANCES_FIND_ERROR'
export const RICK_INSTANCE_UPDATE_EVENT = 'RICK_INSTANCE_UPDATE_EVENT'

export const RETRIEVE_BATCH_RATES_SUCCESS = 'RETRIEVE_BATCH_RATES_SUCCESS'
export const RETRIEVE_BATCH_RATES_ERROR = 'RETRIEVE_BATCH_RATES_ERROR'
export const RETRIEVE_BATCH_RATES_STARTED = 'RETRIEVE_BATCH_RATES_STARTED'

// tradehandler methods
export const VALIDATE_TRADE_ERROR = 'VALIDATE_TRADE_ERROR'
export const RECORD_TRADE_SUCCESS = 'RECORD_TRADE_SUCCESS'
export const RECORD_TRADE_ERROR = 'RECORD_TRADE_ERROR'

// Trade Booking Manager
export const CONFIRM_TRADE_BOOKING_SUCCESS = 'CONFIRM_TRADE_BOOKING_SUCCESS'
export const CONFIRM_TRADE_BOOKING_ERROR = 'CONFIRM_TRADE_BOOKING_ERROR'

// trade events
export const TRADE_RECORDED_EVENT = 'TRADE_RECORDED_EVENT'
export const TRADE_BATCH_CREATED_EVENT = 'TRADE_BATCH_CREATED_EVENT'
export const TRADE_BOOKED_EVENT = 'TRADE_BOOKED_EVENT'
export const TRADE_BOOKING_CONFIRMED_EVENT = 'TRADE_BOOKING_CONFIRMED_EVENT'
export const TRADE_AMENDED_EVENT = 'TRADE_AMENDED_EVENT'
