const selectStyles = (theme) => ({
  valueContainer: styles => ({
    ...styles,
    border: 'none',
    padding: '0 4px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '6px',
  }),
  option: (styles, {isSelected}) => ({
    ...styles,
    border: 'none',
    backgroundColor: isSelected ?
      theme.palette.primary.light :
      theme.palette.primary.main,
  }),
  control: styles => ({
    ...styles,
    minHeight: '1px',
    padding: '0',
    borderRadius: '0',
    border: 'none',
    backgroundColor: 'none',
    width: 180,
    borderBottom: '1px solid #4B4D5E',
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
  }),
  singleValue: (styles, {data}) => ({
    ...styles,
    color: data.label === 'start typing...' ? theme.palette.text.secondary : theme.palette.text.primary,
    fontStyle: data.label === 'start typing...' ? 'italic' : '',
  }),
  input: styles => ({
    ...styles,
    padding: '0',
    margin: '0',
    color: theme.palette.text.primary,
  }),
})

export default selectStyles
