import moment from 'moment'
import React from 'react'
import {round} from 'views/Home/Company/Dashboard/Util'

interface XAxisTickFormatterProps {
  dateFormat: string,
  payload?: any,
  x?: any,
  y?: any,
  xDiff?: (value: string) => number,
  theme?: any,
}

export const XAxisTickFormatter: React.FC<XAxisTickFormatterProps> = (props: XAxisTickFormatterProps) => {
  const {
    dateFormat,
    x,
    y,
    payload,
    xDiff,
    theme
  } = props
  const displayMonth = (payload || {}).value || ''
  const currentMonth = moment().format(dateFormat)

  const dx = -20 + (xDiff ? xDiff(displayMonth) : 0)
  if (displayMonth === currentMonth) {
    return (
      <g transform={`translate(${x},${y - 15})`}>
        <text
          dx={dx}
          dy={0}
          fill={theme.palette.customColors.customBlue2}
          fontSize={'12px'}
          fontWeight={'bold'}
          textAnchor="start"
          x={0}
          y={0}
        >{displayMonth}</text>
      </g>
    )
  }
  return (
    <g transform={`translate(${x},${y - 15})`}>
      <text
        dx={dx}
        dy={0}
        fill={theme?.palette.text.body}
        fontSize={'12px'}
        textAnchor="start"
        x={0}
        y={0}
      >
        {displayMonth}</text>
    </g>
  )
}

export const roundMillions = (value: number): string => {
  if (value < 10 && value > 0) {
    return value.toFixed(2).toString()
  } else if (value === 0) {
    return '00.0'
  } else {
    return value.toFixed(2)
  }
}

export const numberWithCommas = (x: number): string =>  {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const roundScaleAndFormatNumber = (
  amount: number,
  currencySymbol: string,
  showInMillions = false,
  showInThousands= false ,
  decimals = 0,
  showSuffix = true): string =>  {
  try {
    const sign = amount < 0 ? '-' : ''
    const symbol = currencySymbol ? currencySymbol : ''
    const absAmount = Math.abs(amount)
    if (showInMillions) {
      const millions = absAmount / 1000000
      return sign + symbol + `${roundMillions(millions)}${showSuffix ? 'm' : ''}`
    }

    if (showInThousands) {
      const millions = absAmount / 1000
      return sign + symbol + `${roundMillions(millions)}${showSuffix ? 'k' : ''}`
    }

    return sign + symbol + numberWithCommas(round(absAmount, decimals))
  } catch (e) {
    console.error('error formatting and rounding number', e)
    return '0'
  }
}

export type dataScale = 'Millions' | 'Thousands'

export const scaleToNumber = (scale: dataScale): number => {
  switch (scale) {
    case 'Millions':
      return Math.pow(10,6)
    case 'Thousands':
    default:
      return Math.pow(10,3)
  }
}

// defaults to a thousand, but if a data point exist that is >= a million then change the scale
// to millions, which means the data will be rounded to the nearest million, this will determine the Y axis and tooltip
// display of data. The function arg is the datapoints as shown on the graph, if stacked data then this function will work
// best if the total of the stacked bar is supplied
export const determineRechartsDataScale = (data: Array<Record<string, any>>, relevantKeys: Array<string>): dataScale =>  {
  const dataPoints: Array<number> = []
  data.forEach((p: Record<string,number>): void => {
    relevantKeys.forEach((k: string) => {
      // eslint-disable-next-line
      if (p.hasOwnProperty(k)) {
        dataPoints.push(Math.abs(p[k]) || 0)
      }
    })
  })

  let scale: dataScale = 'Thousands'
  if (Math.max(...dataPoints) >= Math.pow(10,6)) {
    scale = 'Millions'
  }
  return scale
}

export const categoryNameToMonthNum = (categoryName: string | number, xAxisDateFormat: string): { dateString: string; weekOfMonth: string } => {
  const dateInfo = categoryName.toString().split('_')
  return {
    dateString: moment(`${dateInfo[0]}-${dateInfo[1]}-01`).format(xAxisDateFormat),
    weekOfMonth: dateInfo[2]
  }
}

export const calculateTooltipOffset = (graphWidth: number, numberOfDataPoints: number): number => {
  return  (graphWidth/(numberOfDataPoints|| 1))/2
}