import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, Card, CardContent, CardHeader, Collapse, IconButton, Tooltip, useTheme} from '@material-ui/core';
import {ExpandLess, ExpandMore, RemoveRedEye as ViewDetailsIcon} from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import {useStyletron} from 'styletron-react';
import {CustomTheme} from 'theme/custom';
import AndileTable from 'components/AndileMaterialUITable/AndileTable';
import {useService} from 'hooks/useService';
import Handler from 'popcorn-js/operations/handler'
import Downloader from 'popcorn-js/operations/downloader'
import {Exception, ExceptionStatus, Type} from './opertation';
import {Criteria, CriteriaType, Criterion} from 'popcorn-js/search';
import {debounce} from 'lodash';
import ErrorIcon from 'components/Icons/ErrorIcon/ErrorIcon';
import {TableDateTimeFormat} from 'constants/formats'
import moment from 'moment'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {AppContext, AppContextType} from 'appContext'


import {ActionsMenu} from 'views/SettlementInstruction/ActionsMenu';
import {HexToRGBA} from 'utils/Utils';
import {FormatBoolean} from 'utils/TradeUtilities';
import {BackIcon} from 'components/Icons';
import InvoiceDetail from 'components/Detail/invoice/InvoiceDetailDialog';
import {Invoice} from 'popcorn-js/financial/invoice/Invoice';
import InvoiceRecordkeeper from 'popcorn-js/financial/invoice/recordkeeper';
import TradeRecordKeeper from 'popcorn-js/trade/recordkeeper';
import {Recordkeeper as OrderRecordkeeper} from 'popcorn-js/financial/order/recordkeeper';

import {Trade} from 'popcorn-js/trade';
import DetailDialog from '../TradeStation/DetailDialog';
import {Order, OrderStatus} from 'popcorn-js/financial/order/orderType';
import OrderDetailDialog from 'components/Detail/order/OrderDialog';
import {Recordkeeper as SIRecordkeeper} from 'popcorn-js/financial/settlementInstruction/recordkeeperV2';
import SettlementInstructionSummaryDialog from 'views/SettlementInstruction/SettlementInstructionSummaryDialog';
import {SettlementInstruction} from 'popcorn-js/financial/settlementInstruction';
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert';
import {StatusDraft} from 'constants/settlementInstruction';
import {AuditActionType} from 'popcorn-js/audit/entryTypes';
import {CurrencyPair} from 'popcorn-js/financial';
import {Currency} from 'popcorn-js/financial';
import {format} from 'date-fns';
import {InvoiceStatus} from 'popcorn-js/financial/invoice/types';

const statusOptions = Object.values(InvoiceStatus).map((status: InvoiceStatus) => ({
  label: status as string,
  value: status as string,
}));
statusOptions.push({
  label: OrderStatus.ORDER_STATUS_PROPOSED as string,
  value: OrderStatus.ORDER_STATUS_PROPOSED as string,
})
statusOptions.push({
  label: OrderStatus.ORDER_STATUS_CONFIRMED as string,
  value: OrderStatus.ORDER_STATUS_CONFIRMED as string,
})
statusOptions.push({
  label: StatusDraft as string,
  value: StatusDraft as string,
})
const OperationsManagement = (): React.ReactNode => {
  const appContext = useContext<AppContextType>(AppContext);

  const currencyPairs = appContext.party.currencyPairIds.map((id: string) => {
    const ccyPair = appContext.currencyPairs.find((c: CurrencyPair) => c.id === id)
    return {value: ccyPair?.name}
  })

  const currencies = appContext.party.currencyPairIds.map((id: string) => {
    const ccyPair = appContext.currencyPairs.find((c: CurrencyPair) => c.id === id)
    const foreignCcyID = ccyPair?.baseCurrencyId
    const ccy = appContext.currencies.find((c: Currency) => c.id === foreignCcyID)
    return {value: ccy?.isoCode}
  })
  const currencyOptions = [...currencyPairs, ...currencies]

  const [css] = useStyletron();
  const theme = useTheme<CustomTheme>()

  const [query, setQuery] = useState<any>({
    sortBy: [],
    order: [],
    limit: 17,
    offset: 0,
  })
  const [criteria, setCriteria] = useState<Criterion[]>(
    [
      {
        Type: CriteriaType.TextCriterion,
        type: CriteriaType.TextCriterion,
        field: 'exceptionStatus',
        text: ExceptionStatus.Pending,
      },
    ]
  )
  const [columns, setColumns] = useState<any>([
    {
      title: 'Type',
      field: 'type',
      render: (rowData: Exception) => rowData.type
    },
    {
      title: 'Number',
      field: 'number',
      filter: {
        displayAccessor: 'number',
        valueAccessor: 'number',
        type: CriteriaType.TextCriterion
      },
      render: (rowData: Exception) => rowData.number
    },
    {
      title: 'Status',
      field: 'status',
      filter: {
        options: statusOptions,
        displayAccessor: 'label',
        valueAccessor: 'value',
        type: CriteriaType.TextCriterion
      },
      render: (rowData: Exception) => rowData.status
    },
    {
      title: 'Currency',
      field: 'currency',
      filter: {
        options: currencyOptions,
        displayAccessor: 'value',
        valueAccessor: 'value',
        type: CriteriaType.TextCriterion
      },
      render: (rowData: Exception) => rowData.currency
    },
    {
      title: 'Action',
      field: 'action',
      filter: {
        options: [{v: AuditActionType.DELETED}],
        displayAccessor: 'v',
        valueAccessor: 'v',
        type: CriteriaType.TextCriterion
      },
      render: (rowData: Exception) => rowData.action
    },
    {
      title: 'Action Executed',
      field: 'actionExecuted',
      filter: {
        options: [{v: 'True'}, {v: 'False'}],
        displayAccessor: 'v',
        valueAccessor: 'v',
        type: CriteriaType.BoolCriterion
      },
      render: (rowData: Exception) => FormatBoolean(rowData.actionExecuted)
    },
    {
      title: 'User',
      field: 'user',
      filter: {
        displayAccessor: 'user',
        valueAccessor: 'user',
        type: CriteriaType.TextCriterion
      },
      render: (rowData: Exception) => rowData.user
    },
    {
      title: 'Time',
      field: 'time',
      filter: {
        displayAccessor: 'time',
        valueAccessor: 'time',
        type: CriteriaType.TimeCriterion
      },
      render: (rowData: Exception) => moment(rowData.time).format(TableDateTimeFormat)
    },
  ])

  const [open, setOpen] = useState<boolean>(true)
  const [exceptionsTotal, setExceptionsTotal] = useState<number>(0)
  const [exceptionsResponse, setExceptionsResponse] = useState<Exception[] | undefined>()
  const [loadErrorMessage, setLoadErrorMessage] = useState<string | undefined>()
  const [typeFilter, setTypeFilter] = useState<string>('All')
  const [title, setTitle] = useState<string>('Exceptions')
  const [selected, setSelected] = React.useState<Exception[]>([])
  const [showFilterRow, setShowFilterRow] = useState<boolean>(false)
  const [selectBool, setSelectBool] = useState<boolean>(false)
  const [resolvedView, setResolvedView] = useState<boolean>(false)
  const [viewInvoice, setViewInvoice] = useState<boolean>(false)
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>()
  const [viewTrade, setViewTrade] = useState<boolean>(false)
  const [selectedTrade, setSelectedTrade] = useState<Trade>()
  const [viewOrder, setViewOrder] = useState<boolean>(false)
  const [selectedOrder, setSelectedOrder] = useState<Order>()
  const [viewSI, setViewSI] = useState<boolean>(false)
  const [selectedSI, setSelectedSI] = useState<SettlementInstruction>()
  const [restoreStarted, setRestoreStarted] = useState<boolean>(false)
  const [verifyStarted, setVerifyStarted] = useState<boolean>(false)
  const [deleteStarted, setDeleteStarted] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [warningMessage, setWarningMessage] = useState<string | undefined>()
  const [successMessage, setSuccessMessage] = useState<string | undefined>()
  const [confirmCallback, setConfirmCallback] = useState<() => void>(() => () => undefined)

  const handleHideAlert = () => {
    setErrorMessage(undefined)
    setSuccessMessage(undefined)
    setWarningMessage(undefined)
    setConfirmCallback(() => undefined)
    if (resolvedView) {
      handleViewResolved()
    } else {
      handleViewExceptions()
    }
  }
  const handleConfirm = () => {
    confirmCallback()
    setSuccessMessage(undefined)
    setWarningMessage(undefined)
    setErrorMessage(undefined)
    setConfirmCallback(() => undefined)
    if (resolvedView) {
      handleViewResolved()
    } else {
      handleViewExceptions()
    }
  }


  const generateFindExceptionsRequest = useCallback(() => {

    return {
      criteria: criteria,
      query: query,
    }
  }, [query, criteria])

  /* find Exceptions --------------------------------------------------------------------- */
  const [
    {response: findExceptionsResponse, loading: findExceptionsLoading, error: findExceptionsError},
    setFindExceptionsRequest,
  ] = useService(null, Handler.FindExceptions)

  useEffect(() => {
    if (findExceptionsResponse && findExceptionsResponse.records && !findExceptionsLoading) {
      setExceptionsTotal(findExceptionsResponse.total)
      setExceptionsResponse(findExceptionsResponse.records)
    }
    if (findExceptionsError) {
      setLoadErrorMessage(findExceptionsError)
    }
  }, [findExceptionsResponse, findExceptionsLoading, findExceptionsError])

  useEffect(() => {
    setFindExceptionsRequest(generateFindExceptionsRequest())
  }, [generateFindExceptionsRequest, setFindExceptionsRequest, query, criteria])

  /* verify exceptions --------------------------------------------------------------------- */
  const [
    {response: verifyExceptionResponse, loading: verifyExceptionLoading, error: verifyExceptionError},
    setVerifyExceptionRequest,
  ] = useService(null, Handler.VerifyException)
  const handleVerifyException = (selected: Exception) => {
    setConfirmCallback(() => () => {
      setVerifyStarted(true)
      setVerifyExceptionRequest({
        exception: selected
      })
    })
    setWarningMessage(`Are you sure you want to verify this Exception entry (${selected.number})?`)

  }
  useEffect(() => {
    if (verifyExceptionResponse && !verifyExceptionLoading && verifyStarted) {
      setVerifyStarted(false)
      setSuccessMessage('Successfully verified Exception entry')
    }
  }, [verifyExceptionResponse, verifyExceptionLoading, verifyStarted])
  useEffect(() => {
    if (verifyExceptionError && !verifyExceptionLoading && verifyStarted) {
      setVerifyStarted(false)
      setErrorMessage('Failed to verify Exception: ' + verifyExceptionError)
    }
  }, [verifyExceptionError, verifyExceptionLoading, verifyStarted])

  /* restore exceptions --------------------------------------------------------------------- */
  const [
    {response: restoreResponse, loading: restoreLoading, error: restoreError},
    setRestoreRequest,
  ] = useService(null, Handler.RestoreException)
  const handleRestore = (selected: Exception) => {
    setConfirmCallback(() => () => {
      setRestoreStarted(true)
      setRestoreRequest({
        id: selected.entityId,
        exception: selected
      })
    })
    setWarningMessage(`Are you sure you want to restore this Exception entry (${selected.number})?`)
  }
  // this effect reacts to a successful service response,
  useEffect(() => {
    if (restoreResponse && !restoreLoading && restoreStarted) {
      setRestoreStarted(false)
      setSuccessMessage('Successfully restore Exception entry')
    }
  }, [restoreResponse, restoreLoading, restoreStarted])

  useEffect(() => {
    if (restoreError && !restoreLoading && restoreStarted) {
      setRestoreStarted(false)
      setErrorMessage('Failed to restore Exception: ' + restoreError)
    }
  }, [restoreError, restoreLoading, restoreStarted])

  /* delete exceptions --------------------------------------------------------------------- */
  const [
    {response: deleteResponse, loading: deleteLoading, error: deleteError},
    setDeleteRequest,
  ] = useService(null, Handler.DeleteException)
  const handleDelete = (selected: Exception) => {
    setConfirmCallback(() => () => {
      setDeleteStarted(true)
      setDeleteRequest({
        id: selected.entityId,
        exception: selected
      })
    })
    setWarningMessage(`Are you sure you want to delete this Exception (${selected.number})?`)
  }
  // this effect reacts to a successful service response,
  useEffect(() => {
    if (deleteResponse && !deleteLoading && deleteStarted) {
      setDeleteStarted(false)
      setSuccessMessage('Successfully deleted Exception')
    }
  }, [deleteResponse, deleteLoading, deleteStarted])

  useEffect(() => {
    if (deleteError && !deleteLoading && deleteStarted) {
      setDeleteStarted(false)
      setErrorMessage('Failed to deleted Exception: ' + deleteError)
    }
  }, [deleteError, deleteLoading, deleteStarted])

  const handleChangePage = (event: any, newPage: any) => {
    const offset = query.limit * newPage
    const newQuery = {
      ...query,
      offset
    }
    setQuery(newQuery)
  }
  const handleChangeRowsPerPage = (event: any) => {
    const rowsPerPage = event.target.value
    const newQuery = {
      sortBy: [],
      order: [],
      limit: rowsPerPage,
      offset: 0
    }
    setQuery(newQuery)
  }
  const handleChangeSorting = (sortBy: any, order: any) => {
    const newQuery = {
      ...query,
      sortBy: [sortBy],
      order: [order],
    }
    setQuery(newQuery)
  }
  const handleFilterChange = useCallback(debounce((newCrit: Criteria) => {
    const newQuery = {
      ...query,
      offset: 0
    }
    if (resolvedView) {
      newCrit.push(
        {
          Type: CriteriaType.TextCriterion,
          type: CriteriaType.TextCriterion,
          field: 'exceptionStatus',
          text: ExceptionStatus.Verified,
        },
        {
          Type: CriteriaType.TextCriterion,
          type: CriteriaType.TextCriterion,
          field: 'exceptionStatus',
          text: ExceptionStatus.Restored,
        },
        {
          Type: CriteriaType.TextCriterion,
          type: CriteriaType.TextCriterion,
          field: 'exceptionStatus',
          text: ExceptionStatus.TimedOut,
        },
        {
          Type: CriteriaType.TextCriterion,
          type: CriteriaType.TextCriterion,
          field: 'exceptionStatus',
          text: ExceptionStatus.Deleted,
        }
      )
    } else {
      newCrit.push(
        {
          Type: CriteriaType.TextCriterion,
          type: CriteriaType.TextCriterion,
          field: 'exceptionStatus',
          text: ExceptionStatus.Pending,
        }
      )
    }
    if (newCrit) {
      switch (typeFilter) {
        case Type.Invoice:
          newCrit.push(
            {
              Type: CriteriaType.TextCriterion,
              type: CriteriaType.TextCriterion,
              field: 'type',
              text: Type.Invoice,
            }
          )
          break
        case Type.Trade:
          newCrit.push(
            {
              Type: CriteriaType.TextCriterion,
              type: CriteriaType.TextCriterion,
              field: 'type',
              text: Type.Trade,
            }
          )
          break
        case Type.SI:
          newCrit.push(
            {
              Type: CriteriaType.TextCriterion,
              type: CriteriaType.TextCriterion,
              field: 'type',
              text: Type.SI,
            }
          )
          break
        case Type.Order:
          newCrit.push(
            {
              Type: CriteriaType.TextCriterion,
              type: CriteriaType.TextCriterion,
              field: 'type',
              text: Type.Order,
            }
          )
          break
        default:
          setQuery(newQuery)
          setCriteria(newCrit)
          break
      }
      setQuery(newQuery)
      setCriteria(newCrit)
    }
  }, 300), [query])
  const handleViewResolved = () => {
    setColumns([
      {
        title: 'Type',
        field: 'type',
        render: (rowData: Exception) => rowData.type
      },
      {
        title: 'Number',
        field: 'number',
        filter: {
          displayAccessor: 'number',
          valueAccessor: 'number',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.number
      },
      {
        title: 'Status',
        field: 'status',
        filter: {
          options: statusOptions,
          displayAccessor: 'label',
          valueAccessor: 'value',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.status
      },
      {
        title: 'Currency',
        field: 'currency',
        filter: {
          options: currencyOptions,
          displayAccessor: 'value',
          valueAccessor: 'value',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.currency
      },
      {
        title: 'Action',
        field: 'action',
        filter: {
          options: [{v: AuditActionType.DELETED}],
          displayAccessor: 'v',
          valueAccessor: 'v',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.action
      },
      {
        title: 'Action Executed',
        field: 'actionExecuted',
        filter: {
          options: [{v: 'True'}, {v: 'False'}],
          displayAccessor: 'v',
          valueAccessor: 'v',
          type: CriteriaType.BoolCriterion
        },
        render: (rowData: Exception) => FormatBoolean(rowData.actionExecuted)
      },
      {
        title: 'User',
        field: 'user',
        filter: {
          displayAccessor: 'user',
          valueAccessor: 'user',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.user
      },
      {
        title: 'Time',
        field: 'time',
        filter: {
          displayAccessor: 'time',
          valueAccessor: 'time',
          type: CriteriaType.TimeCriterion
        },
        render: (rowData: Exception) => moment(rowData.time).format(TableDateTimeFormat)
      },
      {
        title: 'Exception Status',
        field: 'exceptionStatus',
        filter: {
          options: [{v: ExceptionStatus.Restored}, {v: ExceptionStatus.Deleted}, {v: ExceptionStatus.Verified}, {v: ExceptionStatus.TimedOut}],
          displayAccessor: 'v',
          valueAccessor: 'v',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.exceptionStatus
      },
    ])
    setResolvedView(true)
    setSelected([])
    setSelectBool(false)
    setShowFilterRow(false)
    setTitle('Resolved Exceptions')
    setCriteria([
      {
        Type: CriteriaType.TextCriterion,
        type: CriteriaType.TextCriterion,
        field: 'exceptionStatus',
        text: ExceptionStatus.Verified,
      },
      {
        Type: CriteriaType.TextCriterion,
        type: CriteriaType.TextCriterion,
        field: 'exceptionStatus',
        text: ExceptionStatus.Restored,
      },
      {
        Type: CriteriaType.TextCriterion,
        type: CriteriaType.TextCriterion,
        field: 'exceptionStatus',
        text: ExceptionStatus.TimedOut,
      },
      {
        Type: CriteriaType.TextCriterion,
        type: CriteriaType.TextCriterion,
        field: 'exceptionStatus',
        text: ExceptionStatus.Deleted,
      }
    ])
  }
  const handleViewExceptions = () => {
    setColumns([
      {
        title: 'Type',
        field: 'type',
        render: (rowData: Exception) => rowData.type
      },
      {
        title: 'Number',
        field: 'number',
        filter: {
          displayAccessor: 'number',
          valueAccessor: 'number',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.number
      },
      {
        title: 'Status',
        field: 'status',
        filter: {
          options: statusOptions,
          displayAccessor: 'label',
          valueAccessor: 'value',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.status
      },
      {
        title: 'Currency',
        field: 'currency',
        filter: {
          options: [currencyOptions],
          displayAccessor: 'value',
          valueAccessor: 'value',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.currency
      },
      {
        title: 'Action',
        field: 'action',
        filter: {
          options: [{v: AuditActionType.DELETED}],
          displayAccessor: 'v',
          valueAccessor: 'v',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.action
      },
      {
        title: 'Action Executed',
        field: 'actionExecuted',
        filter: {
          options: [{v: 'True'}, {v: 'False'}],
          displayAccessor: 'v',
          valueAccessor: 'v',
          type: CriteriaType.BoolCriterion
        },
        render: (rowData: Exception) => FormatBoolean(rowData.actionExecuted)
      },
      {
        title: 'User',
        field: 'user',
        filter: {
          displayAccessor: 'user',
          valueAccessor: 'user',
          type: CriteriaType.TextCriterion
        },
        render: (rowData: Exception) => rowData.user
      },
      {
        title: 'Time',
        field: 'time',
        filter: {
          displayAccessor: 'time',
          valueAccessor: 'time',
          type: CriteriaType.TimeCriterion
        },
        render: (rowData: Exception) => moment(rowData.time).format(TableDateTimeFormat)
      },
    ])
    setResolvedView(false)
    setSelected([])
    setSelectBool(false)
    setShowFilterRow(false)
    setTitle('Exceptions')
    setCriteria([
      {
        Type: CriteriaType.TextCriterion,
        type: CriteriaType.TextCriterion,
        field: 'exceptionStatus',
        text: ExceptionStatus.Pending,
      },
    ])
  }

  const handleTypeFilterChange = (type: any) => {
    const newCrit: Criteria = []
    switch (type) {
      case Type.Invoice:
        setTypeFilter(Type.Invoice)
        newCrit.push(
          {
            Type: CriteriaType.TextCriterion,
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: Type.Invoice as string,
          })
        break
      case Type.Trade:
        setTypeFilter(Type.Trade)
        newCrit.push(
          {
            Type: CriteriaType.TextCriterion,
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: Type.Trade,
          })
        break
      case Type.SI:
        setTypeFilter(Type.SI)
        newCrit.push(
          {
            Type: CriteriaType.TextCriterion,
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: Type.SI,
          })
        break
      case Type.Order:
        setTypeFilter(Type.Order)
        newCrit.push(
          {
            Type: CriteriaType.TextCriterion,
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: Type.Order,
          })

        break
      default:
        break
    }
    if (resolvedView) {
      newCrit.push(
        {
          Type: CriteriaType.TextCriterion,
          type: CriteriaType.TextCriterion,
          field: 'exceptionStatus',
          text: ExceptionStatus.Verified,
        }
      )
    } else {
      newCrit.push(
        {
          Type: CriteriaType.TextCriterion,
          type: CriteriaType.TextCriterion,
          field: 'exceptionStatus',
          text: ExceptionStatus.Pending,
        },
        {
          Type: CriteriaType.TextCriterion,
          type: CriteriaType.TextCriterion,
          field: 'exceptionStatus',
          text: ExceptionStatus.Restored,
        },
        {
          Type: CriteriaType.TextCriterion,
          type: CriteriaType.TextCriterion,
          field: 'exceptionStatus',
          text: ExceptionStatus.TimedOut,
        },
        {
          Type: CriteriaType.TextCriterion,
          type: CriteriaType.TextCriterion,
          field: 'exceptionStatus',
          text: ExceptionStatus.Deleted,
        }
      )
    }
    setCriteria(newCrit)
  }
  const handleSelectRow = (rowData: Exception) => {
    const ind = selected.findIndex((selectedException: Exception) => selectedException.id === rowData.id)
    const newSelected = [...selected]
    if (ind === -1) {
      newSelected.push(rowData)
    } else {
      newSelected.splice(ind, 1)
    }
    setSelected(newSelected)
    let select = false
    if (newSelected.length > 0) {
      select = true
    }
    setSelectBool(select)
  }

  const handleSelectAll = () => {
    const newSelected = [...selected]
    if (newSelected.length !== 0) {
      setSelected([])
    } else if (exceptionsResponse) {
      exceptionsResponse.forEach((exception: Exception) => {
        newSelected.push(exception)
      })
      setSelected(newSelected)
    }
  }
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const [selectedIndex, setSelectedIndex] = React.useState('ALL');

  /* find Invoice --------------------------------------------------------------------- */
  const [
    {response: retrieveInvoiceResponse, loading: retrieveInvoiceLoading, error: retrieveInvoiceError},
    setRetrieveInvoiceRequest,
  ] = useService(null, InvoiceRecordkeeper.retrieve)
  useEffect(() => {
    if (retrieveInvoiceResponse && retrieveInvoiceResponse.invoice && !retrieveInvoiceLoading) {
      setSelectedInvoice(retrieveInvoiceResponse.invoice)
      setViewInvoice(true)
    }
    if (retrieveInvoiceError) {
      setErrorMessage(retrieveInvoiceError)
    }
  }, [retrieveInvoiceResponse, retrieveInvoiceLoading, retrieveInvoiceError])

  /* find Order --------------------------------------------------------------------- */
  const [
    {response: retrieveOrderResponse, loading: retrieveOrderLoading, error: retrieveOrderError},
    setRetrieveOrderRequest,
  ] = useService(null, OrderRecordkeeper.retrieve)
  useEffect(() => {
    if (retrieveOrderResponse && retrieveOrderResponse.order && !retrieveOrderLoading) {
      setSelectedOrder(retrieveOrderResponse.order)
      setViewOrder(true)
    }
    if (retrieveOrderError) {
      setErrorMessage(retrieveOrderError)
    }
  }, [retrieveOrderResponse, retrieveOrderLoading, retrieveOrderError])

  /* find Trade --------------------------------------------------------------------- */
  const [
    {response: retrieveTradeResponse, loading: retrieveTradeLoading, error: retrieveTradeError},
    setRetrieveTradeRequest,
  ] = useService(null, TradeRecordKeeper.retrieve)
  useEffect(() => {
    if (retrieveTradeResponse && retrieveTradeResponse.trade && !retrieveTradeLoading) {
      setSelectedTrade(retrieveTradeResponse.trade)
      setViewTrade(true)
    }
    if (retrieveTradeError) {
      setErrorMessage(retrieveTradeError)
    }
  }, [retrieveTradeResponse, retrieveTradeLoading, retrieveTradeError])

  /* find SI --------------------------------------------------------------------- */
  const [
    {response: retrieveSIResponse, loading: retrieveSILoading, error: retrieveSIError},
    setRetrieveSIRequest,
  ] = useService(null, SIRecordkeeper.retrieve)
  useEffect(() => {
    if (retrieveSIResponse && retrieveSIResponse.settlementInstruction && !retrieveSILoading) {
      setSelectedSI(retrieveSIResponse.settlementInstruction)
      setViewSI(true)
    }
    if (retrieveSIError) {
      setErrorMessage(retrieveSIError)
    }
  }, [retrieveSIResponse, retrieveSILoading, retrieveSIError])


  const handleViewDetails = (selected: Exception) => {
    switch (selected.type) {
      case Type.Invoice:
        if (selected.action === 'DELETED' && selected.exceptionStatus !== ExceptionStatus.Restored && selected.actionExecuted) {
          setRetrieveInvoiceRequest({
            identifier: {id: selected.entityId},
            Deleted: true
          })
        } else {
          setRetrieveInvoiceRequest({
            identifier: {id: selected.entityId},
          })
        }
        break
      case Type.Trade:
        if (selected.action === 'DELETED' && selected.exceptionStatus !== ExceptionStatus.Restored && selected.actionExecuted) {
          setRetrieveTradeRequest({
            identifier: {id: selected.entityId},
            Deleted: true
          })
        } else {
          setRetrieveTradeRequest({
            identifier: {id: selected.entityId},
          })
        }
        break
      case Type.SI:
        if (selected.action === 'DELETED' && selected.exceptionStatus !== ExceptionStatus.Restored && selected.actionExecuted) {
          setRetrieveSIRequest({
            identifier: {id: selected.entityId},
            Deleted: true
          })
        } else {
          setRetrieveSIRequest({
            identifier: {id: selected.entityId},
          })
        }

        break
      case Type.Order:
        if (selected.action === 'DELETED' && selected.exceptionStatus !== ExceptionStatus.Restored && selected.actionExecuted) {
          setRetrieveOrderRequest({
            identifier: {id: selected.entityId},
            Deleted: true
          })
        } else {
          setRetrieveOrderRequest({
            identifier: {id: selected.entityId},
          })
        }

        break
      default:
        break
    }
  }
  /* download list --------------------------------------------------------------------- */
  const [
    {
      response: downloadListResponse,
      loading: downloadListLoading,
      error: downloadListError,
    },
    setDownloadListRequest,
    resetDownloadListResponse,
  ] = useService(null, Downloader.DownloadList)
  const handleDownloadList = () => {
    setDownloadListRequest({
      criteria,
      query: {
        sortBy: query.sortBy,
        order: query.order,
      },
    })
  }
  useEffect(() => {
    if (downloadListResponse && !downloadListLoading) {
      resetDownloadListResponse()
      try {
        const binData = atob(downloadListResponse.data)
        const bytes = new Array(binData.length)
        for (let i = 0; i < binData.length; i++) {
          bytes[i] = binData.charCodeAt(i)
        }
        const blob = new Blob([new Uint8Array(bytes)], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
        })
        let fileName = ''
        const dateStr = format(new Date(), 'yyyy-MM-dd')
        if (resolvedView) {
          fileName = `Resolved Exceptions List ${dateStr}.xlsx`
        } else {
          fileName = `Exceptions List ${dateStr}.xlsx`
        }
        saveAs(blob, fileName)
      } catch (e) {
        console.error('error downloading Exception list', e)
        setErrorMessage(e)
      }
    }
  }, [downloadListResponse, downloadListLoading, resetDownloadListResponse, resolvedView])
  useEffect(() => {
    if (downloadListError && !downloadListLoading) {
      setErrorMessage('Failed to download Exception list: ' + downloadListError)
    }
  }, [downloadListError, downloadListLoading])

  const renderTableActions = () => {
    const actions: any[] = []
    if (selected.length === 1) {
      if (selected[0].exceptionStatus === ExceptionStatus.Pending) {
        actions.push(
          <Button
            className={css({
              width: '84px',
              height: '24px',
              marginRight: '16px',
              color: theme.palette.background.default,
              border: '1.5px solid ' + theme.palette.background.default,
            })}
            onClick={() => handleVerifyException(selected[0])}
          >
            Verify
          </Button>
        )
        if (selected[0].actionExecuted) {
          actions.push(
            <Button
              className={css({
                width: '84px',
                height: '24px',
                marginRight: '16px',
                color: theme.palette.background.default,
                border: '1.5px solid ' + theme.palette.background.default,
              })}
              onClick={() => handleRestore(selected[0])}
            >
              Restore
            </Button>
          )
        } else {
          actions.push(
            <Button
              className={css({
                width: '84px',
                height: '24px',
                color: theme.palette.background.default,
                border: '1.5px solid ' + theme.palette.background.default,
              })}
              onClick={() => handleDelete(selected[0])}
            >
              Delete
            </Button>
          )
        }
      }
      actions.push(
        <Tooltip title="View Details">
          <IconButton
            aria-label="viewDetails"
            className={selectBool ? css({
              color: theme.palette.background.default,
            }) : css({})}
            onClick={
              () => handleViewDetails(selected[0])
            }
          >
            <ViewDetailsIcon/>
          </IconButton>
        </Tooltip>
      )
    }
    return actions
  }

  return <>
    <div
      className={css({
        font: 'Normal 20px/20px Roboto',
        marginBottom: '24px',
        color: theme.palette.text.primary,
        textAlign: 'left',
        letterSpacing: '0px',
        opacity: 1,
      })}
    >
      Operations Management
    </div>
    <Card
      className={css({
        borderRadius: '8px',
        marginBottom: 0,
      })}
      square={false}
    >
      <CardHeader
        action={
          <>
            {
              open && <>
                {resolvedView && <Tooltip title="Go Back to exceptions">
                  <IconButton
                    aria-label="exitResolved"
                    className={selectBool ? css({
                      color: theme.palette.background.default,
                    }) : css({})}
                    onClick={
                      () => handleViewExceptions()
                    }
                  >
                    <BackIcon/>
                  </IconButton>
                </Tooltip>}
                {renderTableActions()}
                <Tooltip
                  key={'filterList'}
                  title="Filter list"
                >
                  <IconButton
                    aria-label="filter list"
                    className={selectBool ? css({
                      color: theme.palette.background.default,
                    }) : css({})}
                    onClick={() => {
                      handleFilterChange([])
                      setShowFilterRow(!showFilterRow)
                    }}
                  >
                    <FilterListIcon/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download File">
                  <IconButton
                    aria-label="downloadFile"
                    onClick={() => {
                      handleDownloadList()
                    }}
                  >
                    <GetAppIcon/>
                  </IconButton>
                </Tooltip>
                <Tooltip title={'Collapse'}>
                  <IconButton
                    className={selectBool ? css({
                      color: theme.palette.background.default,
                    }) : css({})}
                    onClick={() => setOpen(!open)}
                  >
                    <ExpandLess/>
                  </IconButton>
                </Tooltip>
              </>
            }
            {
              !open && <>
                {resolvedView && <Tooltip title="Go Back to exceptions">
                  <IconButton
                    aria-label="exitResolved"
                    onClick={
                      () => handleViewExceptions()
                    }
                  >
                    <BackIcon/>
                  </IconButton>
                </Tooltip>}
                <Tooltip title={'Expand'}>
                  <IconButton
                    onClick={() => setOpen(!open)}
                  >
                    <ExpandMore/>
                  </IconButton>
                </Tooltip>
              </>
            }
          </>
        }
        className={css(selectBool ?
          {
            backgroundColor: theme.palette.text.label,
            padding: theme.spacing(1.5),
            height: '48px',
            color: theme.palette.primary.contrastText,
          } :
          {
            backgroundColor: theme.palette.paper.default,
            padding: theme.spacing(1.5),
            height: '48px',
            color: theme.palette.primary.contrastText,
          })}
        title={
          <>
            {selectBool &&
            <div
              style={{
                color: '#000000',
              }}
            >
              Selected {title}
            </div>}
            {!selectBool &&
            <div
              style={{
                overflow: 'hidden'
              }}>
              <div
                style={{
                  float: 'left'
                }}>
                {title}
              </div>
              <div
                style={{
                  float: 'left',
                  paddingLeft: '16px'
                }}>
                <ButtonGroup
                  aria-label="split button"
                  style={{
                    height: '24px',
                  }}
                  variant="contained"
                >
                  <Button
                    style={{
                      backgroundColor: '#2BBED9',
                      borderRight: `1px solid ${HexToRGBA('#40426C', 0.5)}`
                    }}
                  >
                    {selectedIndex}</Button>
                  <Button
                    aria-haspopup="menu"
                    aria-label="select merge strategy"
                    onClick={(event: any) => setAnchorEl(event.currentTarget ? event.currentTarget : undefined)}
                    size="small"
                    style={{
                      backgroundColor: '#2BBED9',
                    }}
                  >
                    <ArrowDropDownIcon/>
                  </Button>
                </ButtonGroup>
              </div>
              <ActionsMenu
                anchorElement={anchorEl}
                items={[
                  {
                    id: 'invoice',
                    text: Type.Invoice,
                    onClick: () => {
                      setSelectedIndex(Type.Invoice)
                      handleTypeFilterChange(Type.Invoice)
                    },
                  },
                  {
                    id: 'trade',
                    text: Type.Trade,
                    onClick: () => {
                      setSelectedIndex(Type.Trade)
                      handleTypeFilterChange(Type.Trade)
                    },
                  },
                  {
                    id: 'si',
                    text: Type.SI,
                    onClick: () => {
                      setSelectedIndex(Type.SI)
                      handleTypeFilterChange(Type.SI)
                    },
                  },
                  {
                    id: 'order',
                    text: Type.Order,
                    onClick: () => {
                      setSelectedIndex(Type.Order)
                      handleTypeFilterChange(Type.Order)
                    },
                  },
                  {
                    id: 'all',
                    text: 'All',
                    onClick: () => {
                      setSelectedIndex('All')
                      handleTypeFilterChange('All')
                    },
                  }
                ]}
                onClose={() => setAnchorEl(undefined)}
                title={''}
              />
            </div>}
          </>}
        titleTypographyProps={{
          className: css({
            color: theme.palette.primary.contrastText,
          }),
          variant: 'h6',
        }}
      />
      <Collapse in={open}>
        <CardContent
          className={css({
            padding: 0,
            gridTemplateColumns: 'auto auto 1fr auto',
            ':last-child': {
              padding: 0,
            },
          })}
        >
          {((): React.ReactNode => {
            if (loadErrorMessage) {
              return (
                <div>
                  <ErrorIcon/>
                  <div style={{textAlign: 'center'}}>
                    {loadErrorMessage}
                  </div>
                </div>
              )
            } else {
              return (
                <AndileTable
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  alternativeColumns={columns}
                  columns={[]}
                  count={exceptionsTotal}
                  data={exceptionsResponse || []}
                  defaultColConfig={[]}
                  disableHeader
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  loading={findExceptionsLoading}
                  maxTableHeight={600}
                  onChangeSorting={handleChangeSorting}
                  onFilterChange={handleFilterChange}
                  onRowCheck={handleSelectRow}
                  onSelectAll={handleSelectAll}
                  order={query.order.length > 0 ? query.order[0] : undefined}
                  page={Math.ceil(query.offset / query.limit)}
                  rowClickAction={handleSelectRow}
                  rowDoubleClickAction={(rowData: Exception) => {
                    handleViewDetails(rowData)
                  }}
                  rowsPerPage={query.limit}
                  rowsPerPageOptions={[5, 10, 17, 20, 25, 30]}
                  selected={selected}
                  showCheckboxes
                  showFilterRowFromCard={showFilterRow}
                  sortBy={query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                  tableID={'Exceptions'}
                  title={'Exceptions'}
                />
              )
            }
          })()}
          <NotificationSweetAlert
            customClass={'RecordCancellationTicketDetailDialog'}
            errorMessage={errorMessage}
            onClose={handleHideAlert}
            onConfirm={handleConfirm}
            successMessage={successMessage}
            warningMessage={warningMessage}
          />
        </CardContent>
      </Collapse>
    </Card>
    {!resolvedView && <Button
      className={css({
        width: '242px',
        color: theme.palette.text.primary,
        margin: '16px',
        border: '1.5px solid ' + theme.palette.text.primary,
      })}
      onClick={() => handleViewResolved()}
    >
      View resolved exceptions
    </Button>}
    {resolvedView && <Button
      className={css({
        width: '242px',
        color: theme.palette.text.primary,
        margin: '16px',
        border: '1.5px solid ' + theme.palette.text.primary,
      })}
      onClick={() => handleViewExceptions()}
    >
      Go Back to exceptions
    </Button>}
    {viewInvoice && selectedInvoice && (
      <InvoiceDetail
        invoice={selectedInvoice}
        onClose={() => setViewInvoice(false)}
        readOnly
        show={viewInvoice}
      />
    )}
    {viewTrade &&
    <DetailDialog
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      closeDialog={() => setViewTrade(false)}
      currencyPairs={appContext.currencyPairs}
      open={viewTrade}
      party={appContext.party}
      processingBanks={[]}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trade={selectedTrade}
    />}
    {viewOrder && (
      <OrderDetailDialog
        isNew={false}
        onClose={() => setViewOrder(false)}
        order={selectedOrder}
        show={viewOrder}
      />
    )}
    {viewSI && selectedSI &&
    <SettlementInstructionSummaryDialog
      SI={selectedSI}
      onClose={() => setViewSI(false)}
      show={viewSI}
    />
    }
  </>

}

export default OperationsManagement