import React from 'react'

interface ChartSVGProps {
  fill: string,
  background: string,
}

export const ChartSVG: React.FC<ChartSVGProps> = (props: ChartSVGProps) => (
  <svg
    height="105.534"
    viewBox="0 0 105.534 105.534"
    width="105.534"
    xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(105.534 105.534) rotate(180)">
      <rect
        fill={props.fill}
        height="60%"
        width="60%"
        x="20"
        y="20"/>
      <path
        d="M73.356,3H11.794A8.82,8.82,0,0,0,3,11.794V73.356a8.82,8.82,0,0,0,8.794,8.794H73.356a8.82,8.82,0,0,0,8.794-8.794V11.794A8.82,8.82,0,0,0,73.356,3ZM29.383,64.561H20.589V33.781h8.794Zm17.589,0H38.178V20.589h8.794Zm17.589,0H55.767V46.972h8.794Z"
        fill={props.background}
        transform="translate(10.192 10.192)"
      />
      <path
        d="M0,0H105.534V105.534H0Z"
        fill="none"
      />
    </g>
  </svg>
)
