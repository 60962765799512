import React, { ReactElement } from 'react'
import { Popover, Divider, CardHeader, Tooltip, IconButton, Button, 
  Card, makeStyles, CardContent, ButtonProps, withStyles } from '@material-ui/core'
import { MdClear } from 'react-icons/md'
import { HexToRGBA } from 'utils/Utils'
import { useTheme } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    // width: '300px',
  },
  cardHeader: {
    padding: theme.spacing(),
    backgroundColor: theme.palette.primary.dark,
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 17,
    marginRight: '0px',
    marginTop: '0px',
    marginLeft: '10px'
  },
  action: {
    color: 'red',
    marginRight: '0px',
    marginTop: '0px'
  },
  buttonContainedPrimary: {
    color: '#1A1B36',
    fontWeight: 'bold',
    fontSize: '12px',
    backgroundColor: '#45B2F7',
    '&:hover': {
      backgroundColor: HexToRGBA('#45B2F7', 0.6),
    }
  },
  button: {
    width: '50px',
    height: '30px',
  },
}))

const ActionButtonBase = (color: string) => withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    outline: 'none',
    color: '#1E2036',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    backgroundColor: color,
    '&:hover': {
      backgroundColor: color,
      opacity: 0.7,
    },
    '&:active': {
      backgroundColor: color,
      opacity: 0.85,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button)

export const ActionButton = (color: string) => (props: ButtonProps): ReactElement => {
  const{children, ...rest} = props

  const ImportBase = ActionButtonBase(color)

  return <ImportBase
    disableElevation 
    variant="contained"
    {...rest}
  >
    {children}
  </ImportBase>
}

interface InvoiceCategoryPopoverProps {
  popoverAnchorEl: null | Element | ((element: Element) => Element);
  open: boolean;
  onClose: () => void;
  onSelectCatergory: (catergory: string, nextState: string) => void;
  nextState: string;
}
export const InvoiceCategoryPopover = (props: InvoiceCategoryPopoverProps): ReactElement => {
  const {popoverAnchorEl, open, onClose, onSelectCatergory, nextState} = props

  const classes = useStyles()
  const theme = useTheme()

  const ImportButton = ActionButton('#fc9338')
  const ExportButton = ActionButton('#1dcbb0')

  return (
    <Popover
      anchorEl={popoverAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Card
        className={classes.root}
      >
        <CardHeader
          action={(
            <Tooltip
              key={'close'}
              title={'Close'}>
              <IconButton
                onClick={onClose}>
                <MdClear/>
              </IconButton>
            </Tooltip>
          )}
          classes={{
            root: classes.cardHeader,
            action: classes.action,
            title: classes.cardTitle
          }}
          title={'Upload'}
        />
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <ImportButton
            onClick={() => onSelectCatergory('PURCHASE', nextState)}
          >
            {'PURCHASE INVOICES & CREDIT NOTES'}
          </ImportButton>
          <div style={{height: (theme as any).spacing(1)}} />
          <Divider light />
          <div style={{height: (theme as any).spacing(1)}} />
          <ExportButton
            onClick={() => onSelectCatergory('SALES', nextState)}
          >
            {'SALES INVOICES & CREDIT NOTES'}
          </ExportButton>
        </CardContent>
      </Card>
    </Popover>
  )
}