import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from '../../actions/helper/utilities'

/**
 * Trade Calculator is a client side library for invoking the popcorn api
 */
export default class Calculator {
  static calculateEffectiveRate(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Calculator.CalculateEffectiveRate',
      request,
    })
  }

  static calculateBalance(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Calculator.CalculateBalance',
      request,
    })
  }
}
