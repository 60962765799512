export function marshalIdentifier (identifier) {
  return identifier.type + '::' + JSON.stringify(identifier)
}

/**
 * @return {string}
 */
export function Marshal(object) {
  if (object.type && object.value) {
    return object.type + '::' + JSON.stringify(object.value)
  }
}
