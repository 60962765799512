import {HexToRGBA} from 'utils/Utils'

export const paletteFromGuidelines = {
  primary: {
    light: '#74d9ff', // Specified in guidelines but never used
    main: '#2BA8F8', //
    dark: '#0079c5',
    contrastText: '#000000'
  },
  secondary: {
    light: '#FFFF51',
    main: '#ECD400',
    dark: '#b5a300',
    contrastText: '#000000'
  },
  success: {
    light: '#97f19d',
    main: '#65BE6E',
    dark: '#328d41',
    contrastText: '#000000'
  },
  error: {
    light: '#FF8584',
    main: '#FF5057',
    dark: '#c5072e',
    contrastText: '#000000'
  },
  warning: {
    light: '#FFE75F',
    main: '#FFB529',
    dark: '#c78500',
    contrastText: '#000000'
  },
  background: {
    paper: '#27254D',
    default: '#040833'
  },
  text: {
    primary: '#FFFFFF',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(255, 255, 255, 0.5)',
    hint: 'rgba(255, 255, 255, 0.5)',
    icon: 'rgba(255, 255, 255, 0.5)'
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  action: {
    active: '#FF0000',
    hover: 'rgba(255, 255, 255, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(255, 255, 255, 0.16)',
    selectedOpacity: 0.16,
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(255, 255, 255, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.24
  },
  export: {
    light: '#76FFE0',
    main: '#39CBAE',
    dark: '#009A80',
    contrastText: '#000000'
  },
  import: {
    light: '#FFC54E',
    main: '#F89515',
    dark: '#be6600',
    contrastText: '#000000'
  }
};

// TODO implement other module augmentations
declare module '@material-ui/core/styles/createPalette' {
  export interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
    accent: string;
    body: string;
    label: string;
    positive: string;
  }
  export interface Palette {
    data: {
      graphB: string,
      graphC: string,
      data1B: string,
      data3B: string,
    }
    customText: {
      greyGraphLabel: string
      accent: string
      label: string
    },
    paper: {
      default: string
    },
    customColors: {
      customBlue: string
      customBlue2: string
      customBlueContrastText: string
      greyText1: string
    },
    export: {
      light: string,
      main: string,
      dark: string,
      contrastText: string
    },
    import: {
      light: string,
      main: string,
      dark: string,
      contrastText: string
    },
    local: string;
    text: TypeText;
    // extra custom values (not sure if this is part of the official theme, but these are defined below)
    border: string;
  }
}

const customText = {
  greyGraphLabel: '#D5D5D5',
  accent: '#F2C713',
  label: '#00D2FE',
}

const customColors = {
  customBlue: '#45B2F7', // TODO specified in cash flow graph design but not in color guidelines
  customBlue2: '#2BA8F8', // 'default primary main' on guidelines
  customBlueContrastText: '#1A1B36', // TODO  specified in cash flow graph design but not in color guidelines
  greyText1: '#8F909B',
}

const paper = {
  default: '#27254D'
}

const primary = {
  light: '#40426C',
  main: '#2F305D', //  Custom / Paper 2 on guidelines
  dark: '#1E2036', // TODO specified in cash flow graph design but not in color guidelines
  medium: '#27254D', // 'paper' on guidelines
}

const text = {
  graph: '',
  primary: '#FFFFFF',
  secondary: '#787986',
  body: '#8F909B',
  accent: '#F2C713',
  label: '#00D2FE',
  positive: '#33D1B2',
}

const background = {
  default: '#252644',
  paper: '#1E2036',
}

const data = {
  graphB: '#FF5057',// 'error red main' on guidelines
  graphC: '#F1FFA3',// 'net trade cash flow yellow main' on guidelines
  data1B: '#FC930C',
  data3B: '#1DC447',
}

const alertColors = {
  info: {
    a: '#B712F2',
    b: '#7913F3'
  },
  success: {
    a: '#33D1B2',
    b: '#1DC447'
  },
  error: {
    a: '#F21BA3',
    b: '#E6372B'
  },
  warning: {
    a: '#F2C713',
    b: '#FC930C'
  }
}

const alert = {
  background: {
    success: `linear-gradient(${alertColors.success.a}, ${alertColors.success.b})`,
    error: `linear-gradient(${alertColors.error.a}, ${alertColors.error.b})`,
    info: `linear-gradient(${alertColors.info.a}, ${alertColors.info.b})`,
    warning: `linear-gradient(${alertColors.warning.a}, ${alertColors.warning.b})`,
  },
  info: {
    button: {
      color: text.primary,
      backgroundColor: HexToRGBA(text.primary, .2),
      '&:hover': {
        color: alertColors.info.b,
        backgroundColor: text.primary,
      },
    },
  },
  warning: {
    button: {
      color: text.primary,
      backgroundColor: HexToRGBA(text.primary, .2),
      '&:hover': {
        color: alertColors.warning.b,
        backgroundColor: text.primary,
      },
    },
  },
  error: {
    button: {
      color: text.primary,
      backgroundColor: HexToRGBA(text.primary, .2),
      '&:hover': {
        color: alertColors.error.b,
        backgroundColor: text.primary,
      },
    },
  },
}

const calcSecondary = (context: any, originalContext: any) => {
  if (!context || !originalContext) {
    return {
      main: '#BDAE32',
    }
  }
  if (context.partyCode !== originalContext.partyCode) {
    return {
      main: '#3F51B5',
      content: '#FFFFFF',
      updateText: '#F2C713',
      border: '#6779DE',
      button: '#B2B9E1',
      buttonHover: '#FFFFFF',
    }
  } else {
    return {
      main: '#D9C947',
      content: '#27254D',
      updateText: '#27254D',
      border: '#1A1B36',
      button: '#27254D',
      buttonHover: '#27254D',
    }
  }
}

// the default palette if no calculation w.r.t. context is required
export const paletteDefault = {
  primary,
  customColors,
  dialogBackdrop: '#242641',
  sidebar: '#1A1D33',
  border: '#2A2E51',
  text,
  background,
  data,
  customText,
  alert,
  paper,
  highActions: '#08A9D9',
  secondary: {
    main: '#BDAE32'
  },
  export: {
    light: '#76FFE0',
    main: '#39CBAE',
    dark: '#009A80',
    contrastText: '#000000'
  },
  import: {
    light: '#FFC54E',
    main: '#F89515',
    dark: '#be6600',
    contrastText: '#000000'
  },
  local: '#9FA7DF',
}
// the dynamic palette, based on context
export const palette = (context: any, originalContext: any) => {
  const secondary = calcSecondary(context, originalContext)
  return {
    ...paletteDefault,
    secondary,
  }
}

