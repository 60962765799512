import {
  ContactDetailsIndividual,
} from 'popcorn-js/legalEntity/party/individual/ContactDetailsIndividual'
import {isObject} from 'utils/Utils'
import Entry from '../../../audit/Entry';

export default class Individual {

  /**
   * @type {string}
   */
  id = ''

  /**
   * @type {string}
   */
  partyCode = ''

  /**
   * @type {string}
   */
  parentPartyCode = ''

  /**
   * @type {string}
   */
  name = ''

  /**
   * @type {ContactDetailsIndividual}
   */
  contactDetails = new ContactDetailsIndividual()

  /**
   * @type {number}
   */
  incomeTaxNumber = undefined

  /**
   * @type {string}
   */
  southAfricanCitizen = ''

  /**
   * @type {string}
   */
  southAfricanCitizenOnly = ''

  /**
   * @type {string}
   */
  IDNumber = ''

  /**
   * @type {string}
   */
  USCitizen = ''

  /**
   * @type {string}
   */
  USPerson = ''

  /**
   * @type {number}
   */
  citizenshipTotal = undefined

  /**
   * @type {string}
   */
  email = ''

  /**
   * @type {string}
   */
  logo = ''

  /**
   * @type {string}
   */
  clientTierId = ''

  /**
   * @type {string[]}
   */
  currencyPairIds = []

  /**
   * @type {string}
   */
  natureOfBusiness = ''

  /**
   * @type {string}
   */
  sourceOfFunds = ''
  /**
   * @type {string}
   */
  defaultCurrencyId = ''

  /**
   * @type {string}
   */
  localCurrencyId  = ''

  /**
   * @type {string}
   */
  financialYearEnd = ''

  /**
   * @type Entry
   */
  auditEntry = new Entry()

  /**
   * @type {string[]}
   */
  subsidiaries = []

  static camel = 'individual'
  static nice = 'individual'
  static niceP = 'Individuals'
  static capital = 'Individual'
  static capitalP = 'Individuals'

  /**
   * @param {object|Individual} [individual]
   */
  constructor(individual) {
    if (individual !== undefined &&
        (individual instanceof Individual || isObject(individual))) {
      try {
        this.id = individual.id
        this.partyCode = individual.partyCode
        this.parentPartyCode = individual.parentPartyCode
        this.name = individual.name
        this.contactDetails = new ContactDetailsIndividual(individual.contactDetails)
        this.incomeTaxNumber = individual.incomeTaxNumber
        this.southAfricanCitizen = individual.southAfricanCitizen
        this.southAfricanCitizenOnly = individual.southAfricanCitizenOnly
        this.IDNumber = individual.IDNumber
        this.USCitizen = individual.USCitizen
        this.USPerson = individual.USPerson
        this.citizenshipTotal = individual.citizenshipTotal
        this.email = individual.email
        this.logo = individual.logo
        this.clientTierId = individual.clientTierId
        this.currencyPairIds = individual.currencyPairIds
        this.natureOfBusiness = individual.natureOfBusiness
        this.sourceOfFunds = individual.sourceOfFunds
        this.defaultCurrencyId = individual.defaultCurrencyId
        this.localCurrencyId = individual.localCurrencyId
        this.financialYearEnd = individual.financialYearEnd
        this.auditEntry = individual.auditEntry
      } catch (e) {
        console.error(`error constructing individual: ${e}`)
        throw e
      }
    }
  }

}
