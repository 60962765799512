import React from 'react'
import PropTypes from 'prop-types'
// Material UI components
import {Card, CardContent, CardHeader, Grid, InputAdornment, makeStyles, TextField,} from '@material-ui/core'
import {HexToRGBA} from 'utils/Utils'
import SelectList from 'components/SelectList/SelectList'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import {Assignment as CopyIcon} from '@material-ui/icons'


const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '100%',
  },
  cardHeaderRoot: {
    backgroundColor: HexToRGBA(theme.palette.text.primary, .1),
    padding: theme.spacing(1),
  },
  inputLabel: {
    '&$inputLabelFocused': {
      color: theme.palette.text.secondary,
    },
    '&$inputLabelDisabled': {
      color: theme.palette.text.secondary,
    },
  },
  inputLabelFocused: {},
  inputLabelDisabled: {},

  inputUnderline: {
    '&&:hover:before': {
      borderBottomColor: theme.palette.text.primary,
    },
    '&:before': {
      borderBottomColor: theme.palette.text.secondary,
    },
    '&:after': {
      borderBottomColor: theme.palette.highActions,
    },
  },
  input: {
    color: HexToRGBA(theme.palette.text.primary, .8),
  },
}))

const APIUserDetails = props => {
  const classes = useStyles()
  const {
    apiUser,
    disabled,
    handleChange,
    handleChangeCheckedEvent,
    handleChangeEvent,
    roles,
  } = props

  const InputProps = {
    classes: {
      underline: classes.inputUnderline,
      input: classes.input,
    },
  }

  const InputLabelProps = {
    shrink: Boolean(apiUser.description),
    classes: {
      root: classes.inputLabel,
      focused: classes.inputLabelFocused,
      disabled: classes.inputLabelDisabled,
    },
  }

  const onSelectRole = roleName => {
    const role = props.roles.find(role => role.name === roleName) || {}
    handleChange('roleId')(role.id)
  }

  const roleNames = roles.map(role => role.name)
  const selectedRole = roles.find(role => role.id === apiUser.roleId) || {}

  return (
    <Card
      className={classes.root}
    >
      <CardHeader
        classes={{root: classes.cardHeaderRoot}}
        title="Details"
        titleTypographyProps={{
          variant: 'body1',
        }}
      />
      {apiUser &&
      <CardContent
      >
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={6}>
            <TextField
              InputLabelProps={InputLabelProps}
              InputProps={InputProps}
              className={classes.textField}
              disabled={disabled}
              fullWidth
              label="Description"
              margin="dense"
              onChange={handleChangeEvent('description')}
              value={apiUser.description}
            />
          </Grid>
          {apiUser.id &&
          <Grid
            item
            xs={12}>
            <TextField
              InputLabelProps={InputLabelProps}
              InputProps={{
                ...InputProps,
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="copy id"
                    onClick={() =>
                      navigator.clipboard.writeText(apiUser.id).then(() => {
                        alert('API ID copied to clipboard')
                      }).catch((e) => {
                        alert('Can\'t copy to clipboard: ', e)
                      }).finally(() => {
                      })}
                  >
                    <CopyIcon/>
                  </IconButton>
                </InputAdornment>,
              }}
              className={classes.disabledTextfield}
              disabled
              fullWidth
              label="API ID"
              margin="dense"
              value={apiUser.id}
            />
          </Grid>
          }
          <Grid
            item
            xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(apiUser.authenticationAllowed)}
                  inputProps={{'aria-labelledby': 'authenticationAllowed'}}
                  onChange={handleChangeCheckedEvent('authenticationAllowed')}
                  value="authenticationAllowed"/>
              }
              label="Authentication Allowed"
            />
          </Grid>

          <Grid
            item
            style={{maxHeight: '50%'}}
            xs={12}
          >
            <SelectList
              maxRows={3}
              onSelect={onSelectRole}
              options={roleNames}
              selected={selectedRole.name}
              title="Select role"
            />
          </Grid>

        </Grid>
      </CardContent>
      }
    </Card>
  )
}

APIUserDetails.propTypes = {
  apiUser: PropTypes.object,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  handleChangeCheckedEvent: PropTypes.func,
  handleChangeEvent: PropTypes.func,
  roles: PropTypes.array,
}


export default APIUserDetails


