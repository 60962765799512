import {
  CONFIRM_TRADE_BOOKING_SUCCESS,
  RECORD_TRADE_SUCCESS,
  TRADE_AMENDED_EVENT,
  TRADE_BATCH_CREATED_EVENT,
  TRADE_BOOKED_EVENT,
  TRADE_BOOKING_CONFIRMED_EVENT,
  TRADE_RECORDED_EVENT,
} from 'actions/actionTypes'

const initState = {
  records: [],
  total: 0,
  export: {
    records: [],
    retrieveInProgress: false,
    retrieveSuccessful: false,
    error: undefined,
  },
  upload: {
    validationInProgress: false,
    creationInProgress: false,
    error: undefined,
    unique: [],
    duplicates: [],
    invalid: [],
  },
  findPage: {
    inProgress: false,
    success: false,
    error: undefined,
  },
  reFetchToggle: false,
  events: [],
}

export default function trade(state = initState, action) {
  switch (action.type) {

    case RECORD_TRADE_SUCCESS:
    case CONFIRM_TRADE_BOOKING_SUCCESS:
    case TRADE_AMENDED_EVENT:
      return {
        ...state,
        reFetchToggle: !state.reFetchToggle,
      }

    // Event Reducers
    case TRADE_BOOKED_EVENT:
    case TRADE_BOOKING_CONFIRMED_EVENT:
    case TRADE_RECORDED_EVENT:
    case TRADE_BATCH_CREATED_EVENT:
      return {
        ...state,
        reFetchToggle: !state.reFetchToggle,
        events: [
          action.tradeEvent,
          ...state.events,
        ]
      }
    default:
      return state
  }
}
