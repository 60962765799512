import {isObject} from 'utils/Utils'

export const textNeCriterionType = 'TEXT_NE_CRITERION'
export const exactCriterionType = 'EXACT_CRITERION'

export const IDENTIFIER_CRITERION = 'IDENTIFIER_CRITERION'
export const TEXT_CRITERION = 'TEXT_CRITERION'
export const EXACT_CRITERION = 'EXACT_CRITERION'
export const NUMBER_CRITERION = 'NUMBER_CRITERION'
export const BOOL_CRITERION = 'BOOL_CRITERION'
export const TEXT_NE_CRITERION = 'TEXT_NE_CRITERION'
export const DATE_CRITERION = 'DATE_CRITERION'
export const INVOICE_OVERPAID_CRITERION = 'INVOICE_OVERPAID_CRITERION'
export const TIME_CRITERION = 'TIME_CRITERION'

export const ALL_CRITERION_TYPES = [
  IDENTIFIER_CRITERION,
  TEXT_CRITERION,
  NUMBER_CRITERION,
  BOOL_CRITERION,
  TEXT_NE_CRITERION,
  DATE_CRITERION,
  EXACT_CRITERION,
  INVOICE_OVERPAID_CRITERION,
]

export class DateSearch {

  /**
   * @type {number}
   */
  date = 0

  /**
   * @type {boolean}
   */
  inclusive = true

  /**
   * @type {boolean}
   */
  ignore = false

  /**
   *
   * @param dateSearch
   */
  constructor (dateSearch) {
    const {date, inclusive, ignore} = dateSearch
    this.date = date
    this.inclusive = inclusive
    this.ignore = ignore
  }
}

export class BaseCriterion {
  static type = 'BASE_CRITERION_TYPE'

  /**
   * @type {string}
   */
  type = ''

  constructor() {
    this.type = this.constructor.type
  }
}



// ALL


export class AllCriterion extends BaseCriterion {
  static type = 'ALL_CRITERION'
}

// BOOL

export class BoolCriterion extends BaseCriterion {
  static type = BOOL_CRITERION
  /**
   * @type {string}
   */
  field = ''

  /**
   * @type {boolean}
   */
  value = false

  constructor(criterion) {
    super()
    if (criterion !== undefined &&
      (criterion instanceof Criterion || isObject(criterion))) {
      this.field = criterion.field
      this.value = criterion.value
    } else {
      throw new Error('not a bool criterion')
    }
  }
}

export class DateCriterion extends BaseCriterion {
  static type = DATE_CRITERION

  /**
   * @type {string}
   */
  field = ''

  /**
   * @type {DateSearch}
   */
  startDate = new DateSearch({})

  /**
   * @type {DateSearch}
   */
  endDate = new DateSearch({})

  constructor(criterion) {
    super()
    if (
      criterion !== undefined &&
      (criterion instanceof BaseCriterion || isObject(criterion))
    ) {
      this.field = criterion.field
      this.startDate = criterion.startDate
      this.endDate = criterion.endDate
    }
  }
}


export class ExactCriterion extends BaseCriterion {
  static type = EXACT_CRITERION
  /**
   * @type {string}
   */
  field = ''

  /**
   * @type {string}
   */
  text = ''

  constructor(criterion) {
    super()
    if (criterion !== undefined &&
      (criterion instanceof BaseCriterion || isObject(criterion))) {
      this.field = criterion.field
      this.text = criterion.text
    } else {
      throw new Error('not a exact criterion')
    }
  }
}


export class Criterion extends BaseCriterion {
  static type = IDENTIFIER_CRITERION

  /**
   * @type {string}
   */
  field = ''

  /**
   * @type {string}
   */
  identifier = ''

  constructor(criterion) {
    super()
    if (criterion !== undefined &&
      (criterion instanceof Criterion || isObject(criterion))) {
      this.field = criterion.field
      this.identifier = criterion.identifier
    } else {
      throw new Error('not a identifier criterion')
    }
  }
}


export class AmountSearch {
  /**
   * @type {number}
   */
  amount = new Date().getTime()

  /**
   * @type {boolean}
   */
  inclusive = false

  /**
   * @type {boolean}
   */
  ignore = false
}

export class NumberCriterion extends BaseCriterion {
  static type = NUMBER_CRITERION

  /**
   * @type {string}
   */
  field = ''

  /**
   * @type {AmountSearch}
   */
  moreThan = new AmountSearch({})

  /**
   * @type {AmountSearch}
   */
  lessThan = new AmountSearch({})

  constructor(criterion) {
    super()
    if (criterion !== undefined &&
      (criterion instanceof Criterion || isObject(criterion))) {
      this.field = criterion.field
      this.moreThan = criterion.moreThan
      this.lessThan = criterion.lessThan
    } else {
      throw new Error('not a number criterion')
    }
  }
}

export class TextCriterion extends BaseCriterion {
  static type = TEXT_CRITERION
  /**
   * @type {string}
   */
  field = ''

  /**
   * @type {string}
   */
  text = 'false'

  constructor(criterion) {
    super()
    if (criterion !== undefined &&
      (criterion instanceof Criterion || isObject(criterion))) {
      this.field = criterion.field
      this.text = criterion.text
    } else {
      throw new Error('not a text criterion')
    }
  }
}


export class TextNeCriterion extends BaseCriterion {
  static type = TEXT_NE_CRITERION

  /**
   * @type {string}
   */
  field = ''

  /**
   * @type {string}
   */
  text = 'false'

  constructor(criterion) {
    super()
    if (criterion !== undefined &&
      (criterion instanceof Criterion || isObject(criterion))) {
      this.field = criterion.field
      this.text = criterion.text
    } else {
      throw new Error('not a text criterion')
    }
  }
}
