import {useEffect, useState} from 'react'

type ServiceResponse = any
type ServiceRequest = any
type ServiceCallBack = (request: any) => Promise<any>

export const useService = (initialRequest: ServiceRequest, service: ServiceCallBack): [
  {
    request: ServiceRequest;
    response: ServiceResponse;
    loading: boolean;
    error: any
  },
  (value: (((prevState: any) => any) | any)) => void, () => void] =>  {

  const [request, setRequest] = useState<ServiceRequest>(initialRequest);
  const [response, setResponse] = useState<ServiceResponse|undefined>();
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string|undefined>()

  const resetResponse = () => {
    setResponse(undefined)
  }

  useEffect(() => {
    if (!request) {
      return
    }

    setResponse(undefined)

    const fetchData = async (): Promise<ServiceResponse> => {
      setError('')
      setLoading(true)
      setRequest(undefined)
      try {
        const resp = await service(request)
        setResponse(resp)
        setLoading(false)
        return resp
      } catch (e) {
        console.error(e)
        setResponse(undefined)
        setError((e.message ? e.message : e).toString())
        setLoading(false)
      }
    }
    fetchData().finally()
  }, [request, service])

  return [{request, response, loading, error}, setRequest, resetResponse]
}
