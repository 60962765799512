import React from 'react';
import { Header, ItemEntry } from 'views/History/HistoryLayout';
import { Divider } from '@material-ui/core';
import { processUnixDateForViewing } from 'utils/Utils';
import { SystemDateTimeFormat } from 'constants.js';

const styles = {
  root: {
    display: 'grid',
    flexGrow: 1,
    rowGap: '2px',
    boxShadow: '',
    // paddingTop: '25px',
    zIndex: 1,
    gridTemplateRows: (() => {
      let space = '';
      for (let i = 0; i < 22; i++) {
        space = space + '32px ';
      }
      return space;
    })(),
  },
  divider: {
    height: '2px',
    margin: '20px 20px',
  },
  subDivider: {
    height: '1px',
    margin: '16px 40px',
  },
};

const OrderFields = (order, fieldType, changedFields = []) => {
  return (
    <div>
      <main style={styles.root}>
        <Header
          fieldType={fieldType}
          label={'Order Information'} />
        {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
        <ItemEntry
          changed={changedFields.includes('type')}
          fieldType={fieldType}
          id={'type'}
          label={'type'}
          value={order.type || ''}
        />
        <ItemEntry
          changed={changedFields.includes('name')}
          fieldType={fieldType}
          id={'number'}
          label={'Number'}
          value={order.number || ''}
        />
        <ItemEntry
          changed={changedFields.includes('counterpartyId')}
          fieldType={fieldType}
          id={'counterpartyId'}
          label={'Counterparty'}
          value={order.counterpartyName || order.counterpartyId || ''}
        />
        <ItemEntry
          changed={changedFields.includes('amountDue')}
          fieldType={fieldType}
          id={'amountDue'}
          label={'Amount Due'}
          value={order.amountDue || ''}
        />
        <ItemEntry
          changed={changedFields.includes('currencyId')}
          fieldType={fieldType}
          id={'currencyId'}
          label={'Currency'}
          value={order.currencyIsoCode || order.currencyId || ''}
        />
        <ItemEntry
          changed={changedFields.includes('costCurrencyId')}
          fieldType={fieldType}
          id={'costCurrencyId'}
          label={'Cost Currency'}
          value={order.costCurrencyIsoCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('costingRate')}
          fieldType={fieldType}
          id={'costingRate'}
          label={'Costing Rate'}
          value={order.costingRate || ''}
        />
        <ItemEntry
          changed={changedFields.includes('captureRate')}
          fieldType={fieldType}
          id={'captureRate'}
          label={'Capture Rate'}
          value={order.capture || ''}
        />
        <ItemEntry
          changed={changedFields.includes('dueDate')}
          fieldType={fieldType}
          id={'dueDate'}
          label={'Due Date'}
          value={processUnixDateForViewing(order.dueDate, SystemDateTimeFormat)}
        />
        <ItemEntry
          changed={changedFields.includes('shippingDate')}
          fieldType={fieldType}
          id={'shippingDate'}
          label={'Shipping Date'}
          value={processUnixDateForViewing(
            order.shippingDate,
            SystemDateTimeFormat
          )}
        />
        <ItemEntry
          changed={changedFields.includes('shipmentReference')}
          fieldType={fieldType}
          id={'shipmentReference'}
          label={'Shipment Reference'}
          value={order.shipmentReference}
        />
        <ItemEntry
          changed={changedFields.includes('notes')}
          fieldType={fieldType}
          id={'notes'}
          label={'Notes'}
          value={order.notes || ''}
        />
        <ItemEntry
          changed={changedFields.includes('status')}
          fieldType={fieldType}
          id={'status'}
          label={'Status'}
          value={order.status || ''}
        />
        <ItemEntry
          changed={changedFields.includes('partyCode')}
          fieldType={fieldType}
          id={'partyCode'}
          label={'Party Code'}
          value={order.partyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('issueDate')}
          fieldType={fieldType}
          id={'issueDate'}
          label={'Issue Date'}
          value={processUnixDateForViewing(
            order.issueDate,
            SystemDateTimeFormat
          )}
        />
        <ItemEntry
          changed={changedFields.includes('invoiceId')}
          fieldType={fieldType}
          id={'invoiceId'}
          label={'Linked Invoice'}
          value={order.invoiceNumber || order.invoiceId || ''}
        />
        <Divider style={styles.divider} />
        <Header
          fieldType={fieldType}
          label={'Audit Entry'} />
        <ItemEntry
          changed={changedFields.includes('auditEntry.username')}
          fieldType={fieldType}
          id={'auditEntry.username'}
          label={'Username'}
          value={(order.auditEntry || {}).username || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.action')}
          fieldType={fieldType}
          id={'auditEntry.action'}
          label={'Action'}
          value={(order.auditEntry || {}).action || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.time')}
          fieldType={fieldType}
          id={'auditEntry.time'}
          label={'Time'}
          value={processUnixDateForViewing(
            (order.auditEntry || {}).time,
            SystemDateTimeFormat
          )}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.version')}
          fieldType={fieldType}
          id={'auditEntry.version'}
          label={'Version'}
          value={(order.auditEntry || {}).version}
        />
      </main>
    </div>
  );
};

export default OrderFields;
