export const TRADE_STATUS_OPEN = 'OPEN'
export const TRADE_STATUS_MATURED = 'MATURED'
export const ALL_TRADE_STATUSES = [
  TRADE_STATUS_OPEN,
  TRADE_STATUS_MATURED,
]

export const TRADE_RECORD_STATUSES = [
  TRADE_STATUS_OPEN,
  TRADE_STATUS_MATURED,
]

export const TRADE_LEG_DIRECTION_BUY = 'BUY'
export const TRADE_LEG_DIRECTION_SELL = 'SELL'
export const ALL_TRADE_LEG_DIRECTIONS = [
  TRADE_LEG_DIRECTION_BUY,
  TRADE_LEG_DIRECTION_SELL
]

export const TRADE_TYPE_FORWARD = 'FORWARD'
export const TRADE_TYPE_SPOT = 'SPOT'
export const TRADE_TYPE_DRAWDOWN = 'DRAWDOWN'
export const TRADE_TYPE_EXTENSION = 'EXTENSION'
export const TRADE_TYPE_UNKNOWN = 'UNKNOWN'
export const TRADE_TYPE_CANCELLATION = 'CANCELLATION'

export const ALL_TRADE_TYPES = [
  TRADE_TYPE_FORWARD,
  TRADE_TYPE_SPOT,
  TRADE_TYPE_DRAWDOWN,
  TRADE_TYPE_EXTENSION,
  TRADE_TYPE_CANCELLATION,
  TRADE_TYPE_UNKNOWN,
]

