import React from 'react'
import styles from './error.module.scss'

export default function ErrorIcon() {
  return (
    <div className={styles.error_icon}>
      <div className={styles.error_x_mark}>
        <span className={styles.error_line_left}/>
        <span className={styles.error_line_right}/>
      </div>
    </div>
  )
}
