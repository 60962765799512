import {isObject, isString} from 'utils/Utils'
import uuidv4 from 'uuid/v4'
import { NameIdentifier } from 'popcorn-js/search/identifier'
import { retrieveFromList } from 'popcorn-js/search/identifier/utilities'

export class TradeParent {
  /**
   * @type {number}
   */
  amount
  /**
   * @type {number}
   */
  rate
  /**
   * @type {string}
   */
  parentTradeId

  constructor(parentTrade) {
    this.amount = parentTrade.amount
    this.rate = parentTrade.rate
    this.parentTradeId = parentTrade.id
  }
}

export default class Trade {
  /**
   * @type {string}
   */
  id = ''
  /**
   * @type {string}
   */
  number = ''
  /**
   * @type {number}
   */
  date = 0
  /**
   * @type {string}
   */
  status = ''
  /**
   * @type {Leg[]}
   */
  legs = []
  /**
   * @type {string}
   */
  tradeType = ''
  /**
   * @type {string}
   */
  currencyPairId = ''
  /**
   * @type {[]TradeParent}
   */
  tradeParents = []
  /**
   * @type {string[]}
   */
  childTradeIds = []
  /**
   * @type {string}
   */
  tradingPartyCode = ''
  /**
   * @type {string}
   */
  processingOrgPartyCode = ''
  /**
   * @type {number}
   */
  availableBalance = 0.0
  /**
   * @type {boolean}
   */
  ACM = false
  /**
   * @type {number}
   */
  acmBalance = 0.0
  /**
   * @type {Array}
   */
  acmParents = []
  /**
   * @type {string}
   */
  importExport = ''
  /**
   * @type {number}
   */
  capturedSpotRate = 0.0
  /**
   * @type {string}
   */
  trader = ''
  /**
   * @type {string}
   */
  traderOrganisation = ''
  /**
   * @type {string}
   */
  relationshipManager = ''
  /**
   * @type {string}
   */
  portfolioManager = ''
  /**
   * @type {string}
   */
  financialYear = ''
  /**
   * @type {string}
   */
  notes = ''


  static linkedFields = {
    'legs.0.notionalAmount': ['legs.0.quoteAmount'],
    'legs.0.spotPrice': ['legs.0.quoteAmount'],
    'legs.1.notionalAmount': ['legs.1.quoteAmount'],
    'legs.1.spotPrice': ['legs.1.quoteAmount']
  }

  /**
   * @param {object|Trade} [trade]
   */
  constructor(trade) {
    if (trade !== undefined && (trade instanceof Trade || isObject(trade))) {
      try {
        this.id = trade.id
        this.number = trade.number
        this.date = trade.date
        this.status = trade.status
        this.availableBalance = trade.availableBalance
        this.legs = trade.legs.map(leg => new Leg(leg))
        this.tradeType = trade.tradeType
        this.currencyPairId = trade.currencyPairId
        this.childTradeIds = trade.childTradeIds
        this.tradingPartyCode = trade.tradingPartyCode
        this.tradeParents = trade.tradeParents
        this.processingOrgPartyCode = trade.processingOrgPartyCode
        this.linkedExternalReference = trade.linkedExternalReference
        this.ACM = trade.ACM
        this.acmParents = trade.acmParents
        this.acmBalance = trade.acmBalance
        this.importExport = trade.importExport
        this.auditEntry = trade.auditEntry
        this.capturedSpotRate = trade.capturedSpotRate
        this.trader = trade.trader
        this.traderOrganisation = trade.traderOrganisation
        this.relationshipManager = trade.relationshipManager
        this.portfolioManager = trade.portfolioManager
        this.financialYear = trade.financialYear
        this.notes = trade.notes
      } catch (e) {
        console.error(`error constructing trade: ${e}`)
      }
    }
  }

  static importFields() {
    // Information for the processing program to parse import file
    // name     --> Column header processing program expects to find
    //              Both this value and column header will have all
    //              spaces stripped and be converted to lower case before
    //              comparison
    // type     --> To decide how to parse data
    // optional --> if set to true, this column can be excluded
    return [
      {
        name: 'id',
        type: 'string',
        optional: true
      },
      {
        name: 'number',
        type: 'string',
        optional: true
      },
      {
        name: 'linkedTradeNumber',
        type: 'string',
        optional: true
      },
      {
        name: 'externalTradeReference',
        type: 'string',
        objField: 'externalReference'
      },
      {
        name: 'tradeDate',
        type: 'date',
        objField: 'date'
      },
      {
        name: 'status',
        type: 'string'
      },
      {
        name: 'tradeType',
        type: 'string'
      },
      {
        name: 'baseCurrency',
        type: 'string'
      },
      {
        name: 'quoteCurrency',
        type: 'string'
      },
      {
        name: 'tradingPartyCode',
        type: 'string',
        optional: true
      },
      {
        name: 'processingOrgPartyCode',
        type: 'string'
      },

      // Legs
      // Near Leg
      {
        name: 'nearLegExternalReference',
        type: 'string'
      },
      {
        name: 'nearLegMaturityDate',
        type: 'date'
      },
      {
        name: 'nearLegQuoteAmount',
        type: 'float'
      },
      {
        name: 'nearLegBaseAmount',
        type: 'float'
      },
      {
        name: 'nearLegDirection',
        type: 'string'
      },
      {
        name: 'nearLegStartDate',
        type: 'date'
      },
      {
        name: 'nearLegSpotPrice',
        type: 'float',
        optional: true
      },
      {
        name: 'nearLegForwardPoints',
        type: 'float',
        optional: true
      },
      {
        name: 'nearLegCapturePrice',
        type: 'float',
        optional: true
      },
      // Far Leg
      {
        name: 'farLegExternalReference',
        type: 'string',
        optional: true
      },
      {
        name: 'farLegStartDate',
        type: 'date',
        optional: true
      },
      {
        name: 'farLegMaturityDate',
        type: 'date',
        optional: true
      },
      {
        name: 'farLegBaseAmount',
        type: 'float',
        optional: true
      },
      {
        name: 'farLegQuoteAmount',
        type: 'float',
        optional: true
      },
      {
        name: 'farLegDirection',
        type: 'string',
        optional: true
      },
      {
        name: 'farLegSpotPrice',
        type: 'float',
        optional: true
      },
      {
        name: 'farLegForwardPoints',
        type: 'float',
        optional: true
      },
      {
        name: 'farLegCapturePrice',
        type: 'float',
        optional: true
      }
    ]
  }

  static newFromImportFields(importFieldsObj, addNewFromImportProps) {
    const newTrade = new Trade()
    newTrade.id = uuidv4()
    newTrade.externalReference = importFieldsObj.externalTradeReference
    newTrade.date = importFieldsObj.tradeDate
    newTrade.status = importFieldsObj.status
    newTrade.tradeType = importFieldsObj.tradeType
    if (isString(newTrade.tradeType)) {
      newTrade.tradeType = newTrade.tradeType.toUpperCase()
    }
    try {
      const ccypair = retrieveFromList(
        new NameIdentifier(
          `${importFieldsObj.baseCurrency}/${importFieldsObj.quoteCurrency}`
        ),
        addNewFromImportProps.currencyPairs
      )
      if (ccypair !== undefined) {
        newTrade.currencyPairId = ccypair.id
      }
    } catch (e) {
      console.error('error retrieving currency pair from list', e)
    }

    newTrade.tradingPartyCode = addNewFromImportProps.partyCode
    newTrade.processingOrgPartyCode = importFieldsObj.processingOrgPartyCode
    newTrade.linkedExternalReference = importFieldsObj.linkedTradeNumber
    if (importFieldsObj.nearLegBaseAmount) {
      const newNearLeg = new Leg()
      newNearLeg.externalReference = importFieldsObj.nearLegExternalReference
      newNearLeg.bank = importFieldsObj.nearLegBank
      newNearLeg.direction = importFieldsObj.nearLegDirection
      newNearLeg.tradeDate = importFieldsObj.nearLegStartDate
      newNearLeg.maturityDate = importFieldsObj.nearLegMaturityDate
      newNearLeg.notionalAmount = importFieldsObj.nearLegBaseAmount
      newNearLeg.quoteAmount = importFieldsObj.nearLegQuoteAmount
      newNearLeg.spotPrice = importFieldsObj.nearLegSpotPrice
      newNearLeg.forwardPoints = importFieldsObj.nearLegForwardPoints
      newTrade.legs.push(newNearLeg)
    }
    if (importFieldsObj.farLegBaseAmount) {
      const newFarLeg = new Leg()
      newFarLeg.externalReference = importFieldsObj.farLegExternalReference
      newFarLeg.bank = importFieldsObj.farLegBank
      newFarLeg.direction = importFieldsObj.farLegDirection
      newFarLeg.tradeDate = importFieldsObj.farLegStartDate
      newFarLeg.maturityDate = importFieldsObj.farLegMaturityDate
      newFarLeg.notionalAmount = importFieldsObj.farLegBaseAmount
      newFarLeg.quoteAmount = importFieldsObj.farLegQuoteAmount
      newFarLeg.spotPrice = importFieldsObj.farLegSpotPrice
      newFarLeg.forwardPoints = importFieldsObj.farLegForwardPoints
      newTrade.legs.push(newFarLeg)
    }

    return newTrade
  }
}

export class Leg {
  /**
   * @type {string}
   */
  externalReference = ''
  /**
   * @type {string}
   */
  bank = ''
  /**
   * @type {string}
   */
  internalReference = ''
  /**
   * @type {number}
   */
  interbankRate = 0.0
  /**
   * @type {string}
   */
  direction = ''
  /**
   * @type {number}
   */
  tradeDate = 0
  /**
   * @type {number}
   */
  maturityDate = 0
  /**
   * @type {number}
   */
  notionalAmount = 0.0
  /**
   * @type {number}
   */
  quoteAmount = 0.0
  /**
   * @type {number}
   */
  spotPrice = 0.0
  /**
   * @type {number}
   */
  forwardPoints = 0.0
  /**
   * @type {number}
   */
  effectiveRate = 0.0

  /**
   * @param {object|Leg} [leg]
   */
  allInRate = 0.0

  constructor(leg) {
    if (leg !== undefined && (leg instanceof Leg || isObject(leg))) {
      try {
        this.externalReference = leg.externalReference
        this.bank = leg.bank
        this.internalReference = leg.internalReference
        this.interbankRate = leg.interbankRate
        this.maturityDate = leg.maturityDate
        this.quoteAmount = leg.quoteAmount
        this.notionalAmount = leg.notionalAmount
        this.direction = leg.direction
        this.tradeDate = leg.tradeDate
        this.spotPrice = leg.spotPrice
        this.forwardPoints = leg.forwardPoints
        this.effectiveRate = leg.effectiveRate
        this.allInRate = leg.allInRate
      } catch (e) {
        console.error(`error constructing leg ${e}`)
      }
    }
  }
}
