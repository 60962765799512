import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {Marshal} from '../../../search/identifier/Marshaller'
import {ID_IDENTIFIER, PARTY_CODE_IDENTIFIER} from '../../../search/identifier/types'

/**
 * CompanyRecordkeeper is a convenience client for invoking the backend services
 */
export default class Recordkeeper {

  /**
   * @param criteria: Filter criteria
   * @param query: Query options
   * @param {boolean} [deleted]
   */
  static find(criteria, query, deleted) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Individual-Recordkeeper.Find',
      request: {
        criteria,
        query,
        deleted,
      },
    })
  }

  /**
   * @param {Individual} individual
   */
  static create(individual) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Individual-Recordkeeper.Create',
      request: {individual},
    })
  }

  /**
   * @param {Individual} individual
   * @param {Identifier} id
   */
  static update(individual, id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Individual-Recordkeeper.Update',
      request: {
        individual,
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  }

  /**
   * @param {Identifier} id
   */
  static delete(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Individual-Recordkeeper.Delete',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  }

  /**
   * @param {Identifier} id
   */
  static restore(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Individual-Recordkeeper.Restore',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  }

  /**
   * @param {Identifier} id
   */
  static retrieveHistory(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Individual-Recordkeeper.RetrieveHistory',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  }

  /**
   * @param {string} id
   */
  static deleteForever(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Individual-Recordkeeper.DeleteForever',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  }

  /**
   * @param {string} id
   */
  static retrieve(partyCode) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Individual-Recordkeeper.Retrieve',
      request: {
        identifier: Marshal({type: PARTY_CODE_IDENTIFIER, value: {partyCode}}),
      }
    })
  }
}
