import React from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'


const useStyles = makeStyles(() => ({
  root: {
    height: '32px',
    width: '32px',
    strokeWidth: '2'
  },
})
)


export function DashboardIcon() {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <SvgIcon
      className={classes.root}
      htmlColor={theme.palette.primary.contrastText}
      viewBox="0 0 32 32"
    >
      <g
        className="cls-1"
        id="Artboard_10">
        <g
          id="Group_45"
          transform="translate(-455.222 -741.939)">
          <g
            id="Group_39">
            <g
              id="Group_37">
              <path
                d="M477.494,764.709H455.722a.5.5,0,0,1-.5-.5v-21.77a.5.5,0,0,1,.5-.5h21.772a.5.5,0,0,1,.5.5v21.77a.5.5,0,0,1-.5.5Zm-21.272-1h20.772v-20.77H456.222v20.77Z"
                id="Path_26"
              />
            </g>
            <g
              data-name="Group 38"
              id="Group_38">
              <path
                d="M477.136,748.768H456.08a.5.5,0,0,1,0-1h21.056a.5.5,0,0,1,0,1Z"
                id="Path_27"
              />
            </g>
            <path
              d="M458.653,744.249a1.1,1.1,0,1,0,1.1,1.1,1.1,1.1,0,0,0-1.1-1.1Z"
              id="Path_28"
            />
            <path
              d="M461.83,744.249a1.1,1.1,0,1,0,1.1,1.1,1.1,1.1,0,0,0-1.1-1.1Z"
              id="Path_29"
            />
            <path
              d="M465.005,744.249a1.1,1.1,0,1,0,1.106,1.1,1.1,1.1,0,0,0-1.106-1.1Z"
              id="Path_30"/>
          </g>
          <g
            data-name="Group 44"
            id="Group_44">
            <g
              data-name="Group 40"
              id="Group_40">
              <path
                d="M466.609,752.777h-6.832a.5.5,0,0,1,0-1h6.832a.5.5,0,0,1,0,1Z"
                id="Path_31"
              />
            </g>
            <g
              data-name="Group 41"
              id="Group_41">
              <path
                d="M466.609,755.943h-6.832a.5.5,0,0,1,0-1h6.832a.5.5,0,0,1,0,1Z"
                id="Path_32"
              />
            </g>
            <g
              data-name="Group 42"
              id="Group_42">
              <path
                d="M466.609,759.111h-6.832a.5.5,0,0,1,0-1h6.832a.5.5,0,0,1,0,1Z"
                id="Path_33"
              />
            </g>
            <g
              data-name="Group 43"
              id="Group_43">
              <path
                d="M473.443,759.111h-4.334a.5.5,0,0,1-.5-.5v-6.334a.5.5,0,0,1,.5-.5h4.334a.5.5,0,0,1,.5.5v6.334a.5.5,0,0,1-.5.5Zm-3.834-1h3.334v-5.334h-3.334v5.334Z"
                id="Path_34"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>

  )

}
