import React from 'react'
import {CardContent, CardHeader, Grid, makeStyles, TextField} from '@material-ui/core'
import {HexToRGBA} from 'utils/Utils'
import Card from '@material-ui/core/Card'
import SelectList from 'components/SelectList/SelectList'
import User from 'popcorn-js/legalEntities/user/User'

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '100%',
  },
  cardHeaderRoot: {
    backgroundColor: HexToRGBA(theme.palette.text.primary, .1),
    padding: theme.spacing(1),
  },
  inputLabel: {
    '&$inputLabelFocused': {
      color: theme.palette.text.secondary,
    },
    '&$inputLabelDisabled': {
      color: theme.palette.text.secondary,
    },
  },
  inputLabelFocused: {},
  inputLabelDisabled: {},

  inputUnderline: {
    '&&:hover:before': {
      borderBottomColor: theme.palette.text.primary,
    },
    '&:before': {
      borderBottomColor: theme.palette.text.secondary,
    },
    '&:after': {
      // eslint-disable-next-line
      // @ts-ignore
      borderBottomColor: theme.palette.highActions,
    },
  },
  input: {
    color: HexToRGBA(theme.palette.text.primary, .8),
  },
  textField: {},
}))

interface UserDetailsProps {
  handleChange?: (user: User) => void,
  invokedActions?: number,
  roles?: any,
  user?: User,
  disabled?: boolean,
  invokedAction?: any
}

const UserDetails: React.FC<UserDetailsProps> = (props: UserDetailsProps) => {
  const classes = useStyles()
  const {
    user,
    disabled,
    handleChange,
    roles,
    invokedAction,
  } = props

  const InputProps = {
    classes: {
      underline: classes.inputUnderline,
      input: classes.input,
    },
  }

  const InputLabelProps = {
    shrink: Boolean(user && user.loginName),
    classes: {
      root: classes.inputLabel,
      focused: classes.inputLabelFocused,
      disabled: classes.inputLabelDisabled,
    },
  }

  // TODO use ROLE Type
  const onSelectRole = (roleName: any): void => {
    const role = roles.find((role: any) => role.name === roleName) || {}
    handleChange && handleChange(new User({
      ...user,
      roleId: role.id
    }))
  }

  const roleNames = roles.map((role: any) => role.name)
  const selectedRole = roles.find((role: any) => role.id === (user || {}).roleId) || {}

  const disableRoleChange = invokedAction !== 2

  return (
    <Card
      className={classes.root}
    >
      <CardHeader
        classes={{root: classes.cardHeaderRoot}}
        title="Details"
        titleTypographyProps={{
          variant: 'body1'
        }}
      />
      {
        user &&

        <CardContent
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}>
              <TextField
                InputLabelProps={{
                  ...InputLabelProps,
                  shrink: Boolean(user.loginName),
                }}
                InputProps={InputProps}
                className={classes.textField}
                disabled={disabled}
                fullWidth
                label={'User Name'}
                margin={'dense'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  handleChange && handleChange(new User({
                    ...user,
                    loginName: e.target.value
                  }))
                }}
                value={user.loginName}
              />
            </Grid>
            <Grid
              item
              xs={6}>
              <TextField
                InputLabelProps={{
                  ...InputLabelProps,
                  shrink: Boolean(user.firstName),
                }}
                InputProps={InputProps}
                className={classes.textField}
                disabled={disabled}
                fullWidth
                label={'First Name'}
                margin={'dense'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  handleChange && handleChange(new User({
                    ...user,
                    firstName: e.target.value
                  }))
                }}
                value={user.firstName}
              />
            </Grid>
            <Grid
              item
              xs={6}>
              <TextField
                InputLabelProps={{
                  ...InputLabelProps,
                  shrink: Boolean(user.lastName),
                }}
                InputProps={InputProps}
                className={classes.textField}
                disabled={disabled}
                fullWidth
                label={'Last Name'}
                margin={'dense'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  handleChange && handleChange(new User({
                    ...user,
                    lastName: e.target.value
                  }))
                }}
                value={user.lastName}
              />
            </Grid>
            <Grid
              item
              xs={6}>
              <TextField
                InputLabelProps={{
                  ...InputLabelProps,
                  shrink: Boolean(user.emailAddress),
                }}
                InputProps={InputProps}
                className={classes.textField}
                disabled={disabled}
                fullWidth
                label={'Email Address'}
                margin={'dense'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  handleChange && handleChange(new User({
                    ...user,
                    emailAddress: e.target.value
                  }))
                }}
                value={user.emailAddress}
              />
            </Grid>
            <Grid
              item
              xs={6}>
              <TextField
                InputLabelProps={{
                  ...InputLabelProps,
                  shrink: Boolean(user.primaryTelephoneNumber),
                }}
                InputProps={InputProps}
                className={classes.textField}
                disabled={disabled}
                fullWidth
                label={'Telephone Number'}
                margin={'dense'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  handleChange && handleChange(new User({
                    ...user,
                    primaryTelephoneNumber: e.target.value
                  }))
                }}
                value={user.primaryTelephoneNumber}
              />
            </Grid>
            <Grid
              item
              xs={6}>
              <TextField
                InputLabelProps={{
                  ...InputLabelProps,
                  shrink: Boolean(user.mobilePhone),
                }}
                InputProps={InputProps}
                className={classes.textField}
                disabled={disabled}
                fullWidth
                label={'Mobile Phone Number'}
                margin={'dense'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  handleChange && handleChange(new User({
                    ...user,
                    mobilePhone: e.target.value
                  }))
                }}
                value={user.mobilePhone}
              />
            </Grid>
            <Grid
              item
              style={{
                maxHeight: '50%',
                paddingTop: '16px',
              }}
              xs={12}
            >
              <SelectList
                disabled={disableRoleChange}
                maxRows={3}
                onSelect={onSelectRole}
                options={roleNames}
                selected={selectedRole.name}
                title="Select role"
              />
            </Grid>
          </Grid>
        </CardContent>
      }
    </Card>
  )
}

export default UserDetails
