import React from 'react'
import Table from 'components/Table/VirtualizedTable/VirtualizedTable'
import {CardHeader} from '@material-ui/core'

import {processUnixDateForViewing} from '../../../utils/Utils'
import {FormatNumber} from '../../../utils/TradeUtilities'
import {
  INVOICE_TYPE_PURCHASE_CREDIT_NOTE,
  INVOICE_TYPE_PURCHASE_INVOICE, INVOICE_TYPE_SALES_CREDIT_NOTE,
  INVOICE_TYPE_SALES_INVOICE
} from '../../../constants/invoice';

class SettlementInstructionBlotter extends React.Component {
  handleRowClick(settlementInstruction) {
    this.props.onSelect(settlementInstruction)
  }

  handleRowDoubleClick(settlementInstruction) {
    if (this.props.onDoubleClick) {
      this.props.onDoubleClick(settlementInstruction)
    }
  }

  render() {
    const {currencies, requestsForFunds, total, title, selected, invoiceCache} = this.props

    return (
      <div>
        <CardHeader
          action={this.renderIcons()}
          title={title || 'Settlement Instruction'}
        />
        {!this.props.minimize &&
        <div
          style={{
            height: 'calc(100vh - 200px)',
            overflow: 'hidden'
          }}
        >
          <Table
            columns={[
              {
                label: 'Number',
                dataKey: 'number',
                flexGrow: 1.0,
                width: 150,
              },
              {
                label: 'Client',
                dataKey: 'partyCode',
                flexGrow: 1.0,
                width: 150,
              },
              {
                label: 'Requested Amount',
                dataKey: 'invoiceAmountsRequested',
                flexGrow: 1.0,
                width: 150,
                cellContentRenderer: ({rowData}) => {
                  let canShowReqAmount = true
                  try {
                    let total = rowData.invoiceAmountsRequested.reduce((total, current) => {
                      const inv = invoiceCache.find(inv => inv.id === current.invoiceId)
                      if (!inv) {
                        canShowReqAmount = false
                        return 0
                      }
                      switch (inv.type) {
                        case INVOICE_TYPE_PURCHASE_INVOICE:
                        case INVOICE_TYPE_SALES_INVOICE:
                          return total + current.amount
                        case INVOICE_TYPE_PURCHASE_CREDIT_NOTE:
                        case INVOICE_TYPE_SALES_CREDIT_NOTE:
                          return total - current.amount
                        default:
                          canShowReqAmount = false
                          return 0
                      }
                    }, 0)
                    total += rowData.prepaymentAmountsRequested.reduce((total, current) => total + current.amount, 0)
                    total += rowData.customEntries.reduce((total, current) => total + current.amount, 0)
                    if (canShowReqAmount) {
                      return FormatNumber(total, true, true, 2, true)
                    }
                    return '-'
                  } catch (e) {
                    console.error('error getting amount requested', e)
                    return 0
                  }
                },
                numeric: true,
              },
              {
                label: 'Currency',
                dataKey: 'currencyId',
                flexGrow: 1.0,
                width: 150,
                cellContentRenderer: ({cellData}) => (currencies.find(
                  c => c.id === cellData) || {}).isoCode || cellData,
              },
              {
                label: 'Value Date',
                dataKey: 'date',
                flexGrow: 1.0,
                width: 150,
                cellContentRenderer: ({cellData}) => processUnixDateForViewing(
                  cellData),
              },
              {
                label: 'Status',
                dataKey: 'status',
                width: 250,
              },
            ]}
            minimumBatchSize={50}
            onRowClick={({rowData}) => this.handleRowClick(rowData)}
            onRowDoubleClick={({rowData}) => this.handleRowDoubleClick(rowData)}
            rowCount={total}
            rowGetter={({index}) => {
              if (index > requestsForFunds.length - 1) {
                return {}
              }
              return requestsForFunds[index]
            }}
            selected={selected ? [selected] : []}
            threshold={30}
          />
        </div>
        }
      </div>
    )
  }

  renderIcons() {
    const {
      additionalActions,
    } = this.props

    const items = []

    items.push(...additionalActions)

    return items
  }
}

SettlementInstructionBlotter.defaultProps = {
  currencies: [],
  additionalActions: [],
  minimize: false,
  onSelect: () => null,
  selected: [],
  requestsForFunds: [],
  rowGetter: () => null,
  total: 0,
  invoiceCache: [],
}

export default SettlementInstructionBlotter
