import React from 'react'
import PropTypes from 'prop-types'
import {Individual as individualEntity,} from 'popcorn-js/legalEntity/party'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core'
import {TextField as ParsedTextField, TextFieldParseTypes,} from 'components/FormContols'
import ClientTierEntity from 'popcorn-js/financial/clientTier/ClientTier'
import CurrencyEntity from 'popcorn-js/financial/currency/Currency'
import {
  PARTY_TYPE_BROKER,
  PARTY_TYPE_COMPANY,
  PARTY_TYPE_INDIVIDUAL,
  PARTY_TYPE_PROCESSING_BANK,
  PARTY_TYPE_PROCESSING_ORG,
  PARTY_TYPE_SYSTEM,
} from 'constants/partyTypes'
import LogoUpdater from 'views/LegalEntities/GenericDetails/LogoUpdater'

const styles = theme => ({
  root: {
    marginBottom: '10px',
    marginTop: '20px',
  },
})

let BasicInformationIndividual = (props) => {
  const party = props.party || {}

  const {
    classes,
  } = props

  const relativeParty = viewingPartyTranslator(
    props.viewingPartyType,
    props.viewOfPartyType,
    props.partyCode, party,
    props.isNew,
  )
  if (relativeParty === RELATIVE_PARTY_TYPE_INVALID) {
    return ''
  }

  const x = renderTable[relativeParty].viewing[props.viewOfPartyType] || []
  const showLogo = x.includes(FIELD_LOGO) ||
    x.includes(FIELD_LOGO + FIELD_DISABLED)


  return (
    <Card className={classes.root}>
      <CardHeader title={props.title}/>
      <CardContent>
        <Grid container>
          {showLogo &&
          <Grid
            item
            lg={2}
            md={3}
            xs={4}>
            <LogoUpdater
              handleChange={(data) => props.onChange('logo',
                data)}
              logoData={party.logo}/>
          </Grid>
          }
          <Grid
            item
            lg={showLogo ? 10 : 12}
            md={showLogo ? 9 : 12}
            xs={showLogo ? 8 : 12}>
            <Grid
              container
              direction={'column'}
              style={{paddingLeft: '40px'}}>
              {(x.includes(FIELD_PARTY_CODE) ||
                x.includes(FIELD_PARTY_CODE + FIELD_DISABLED)) &&
              <Grid item>
                <ParsedTextField
                  disabled={!props.isNew}
                  error={!!props.invalidFields.partyCode}
                  fullWidth
                  helperText={props.invalidFields.partyCode || ' '}
                  id="partyCode"
                  label="Party Code"
                  margin={props.margin}
                  onChange={(e) => props.onChange('partyCode',
                    e.target.value)}
                  parseType={TextFieldParseTypes.upperCaseString}
                  value={(party.partyCode || '')}
                />
              </Grid>
              }
              {(x.includes(FIELD_ID_NUMBER) ||
                x.includes(FIELD_ID_NUMBER + FIELD_DISABLED)) &&
              <Grid item>
                <TextField
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_ID_NUMBER + FIELD_DISABLED)}
                  error={!!props.invalidFields.line1}
                  fullWidth
                  id="IDNumber"
                  label="Identity number"
                  margin={props.margin}
                  onChange={(e) => props.onChange('IDNumber',
                    e.target.value)}
                  value={(party.IDNumber || '')}
                />
              </Grid>
              }
              {(x.includes(FIELD_NAME) ||
                x.includes(FIELD_NAME + FIELD_DISABLED)) &&
              <Grid item>
                <TextField
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_NAME + FIELD_DISABLED)}
                  error={!!props.invalidFields.name}
                  fullWidth
                  helperText={props.invalidFields.name || ' '}
                  id="name"
                  label="Name"
                  margin={props.margin}
                  onChange={(e) => props.onChange('name',
                    e.target.value)}
                  value={(party.name || '')}
                />
              </Grid>}
              {(x.includes(FIELD_EMAIL) ||
                x.includes(FIELD_EMAIL + FIELD_DISABLED)) &&
              <Grid item>
                <TextField
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_EMAIL + FIELD_DISABLED)}
                  error={!!props.invalidFields.name}
                  fullWidth
                  helperText={props.invalidFields.name || ' '}
                  id="email"
                  label="Email"
                  margin={props.margin}
                  onChange={(e) => props.onChange('email',
                    e.target.value)}
                  value={(party.email || '')}
                />
              </Grid>}
              {(x.includes(FIELD_SOUTH_AFRICAN_CITIZEN) ||
                x.includes(FIELD_SOUTH_AFRICAN_CITIZEN + FIELD_DISABLED)) &&
              <Grid item>
                <FormControl
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_SOUTH_AFRICAN_CITIZEN + FIELD_DISABLED)}
                  error={!!props.invalidFields.southAfricanCitizen}
                  id="southAfricanCitizen"
                  margin={props.margin}
                  style={{width: '100%'}}>
                  <InputLabel
                    htmlFor="southAfricanCitizen-required"
                    shrink={!!party.southAfricanCitizen}>
                    South African citizen
                  </InputLabel>
                  <Select
                    error={!!props.invalidFields.defaultCurrencyId}
                    inputProps={{
                      id: 'southAfricanCitizenselect-required',
                    }}
                    onChange={(e) => props.onChange('southAfricanCitizen',
                      e.target.value)}
                    value={party.southAfricanCitizen}
                  >
                    {['Yes', 'No'].map((c, i) => <MenuItem
                      className={'YesNoItems'}
                      key={i}
                      value={c}> {c} </MenuItem>)}
                  </Select>
                  <FormHelperText>{props.invalidFields.defaultCurrencyId ||
                  ' '}</FormHelperText>
                </FormControl>
              </Grid>
              }
              {(x.includes(FIELD_SOUTH_AFRICAN_CITIZEN_ONLY) ||
                x.includes(FIELD_SOUTH_AFRICAN_CITIZEN_ONLY + FIELD_DISABLED)) &&
              <Grid item>
                <FormControl
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_SOUTH_AFRICAN_CITIZEN_ONLY + FIELD_DISABLED)}
                  error={!!props.invalidFields.southAfricanCitizen}
                  id="southAfricanCitizenOnly"
                  margin={props.margin}
                  style={{width: '100%'}}>
                  <InputLabel
                    htmlFor="southAfricanCitizenOnly-required"
                    shrink={!!party.southAfricanCitizenOnly}>
                    South African citizen only
                  </InputLabel>
                  <Select
                    error={!!props.invalidFields.defaultCurrencyId}
                    inputProps={{
                      id: 'southAfricanCitizenOnlySelect-required',
                    }}
                    onChange={(e) => props.onChange('southAfricanCitizenOnly',
                      e.target.value)}
                    value={party.southAfricanCitizenOnly}
                  >
                    {['Yes', 'No'].map((c, i) => <MenuItem
                      className="YesNoItems"
                      key={i}
                      value={c}> {c} </MenuItem>)}
                  </Select>
                  <FormHelperText>{props.invalidFields.defaultCurrencyId ||
                  ' '}</FormHelperText>
                </FormControl>
              </Grid>
              }
              {(x.includes(FIELD_US_CITIZEN) ||
                x.includes(FIELD_US_CITIZEN + FIELD_DISABLED)) &&
              <Grid item>
                <FormControl
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_US_CITIZEN + FIELD_DISABLED)}
                  error={!!props.invalidFields.USCitizen}
                  id="unitedStatesCitizen"
                  margin={props.margin}
                  style={{width: '100%'}}>
                  <InputLabel
                    htmlFor="unitedStatesCitizen-required"
                    shrink={!!party.USCitizen}>
                    United States citizen
                  </InputLabel>
                  <Select
                    error={!!props.invalidFields.USCitizen}
                    inputProps={{
                      id: 'unitedStatesCitizenSelect-required',
                    }}
                    onChange={(e) => props.onChange('USCitizen',
                      e.target.value)}
                    value={party.USCitizen}
                  >
                    {['Yes', 'No'].map((c, i) => <MenuItem
                      className="YesNoItems"
                      key={i}
                      value={c}> {c} </MenuItem>)}
                  </Select>
                  <FormHelperText>{props.invalidFields.defaultCurrencyId ||
                  ' '}</FormHelperText>
                </FormControl>
              </Grid>
              }
              {(x.includes(FIELD_US_PERSON) ||
                x.includes(FIELD_US_PERSON + FIELD_DISABLED)) &&
              <Grid item>
                <FormControl
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_US_PERSON + FIELD_DISABLED)}
                  error={!!props.invalidFields.US}
                  id="USPerson"
                  margin={props.margin}
                  style={{width: '100%'}}>
                  <InputLabel
                    htmlFor="USPerson-required"
                    shrink={!!party.USPerson}>
                    United States person
                  </InputLabel>
                  <Select
                    error={!!props.invalidFields.defaultCurrencyId}
                    inputProps={{
                      id: 'USPersonSelect-required',
                    }}
                    onChange={(e) => props.onChange('USPerson',
                      e.target.value)}
                    value={party.USPerson}
                  >
                    {['Yes', 'No'].map((c, i) => <MenuItem
                      className="YesNoItems"
                      key={i}
                      value={c}> {c} </MenuItem>)}
                  </Select>
                  <FormHelperText>{props.invalidFields.defaultCurrencyId ||
                  ' '}</FormHelperText>
                </FormControl>
              </Grid>
              }
              {(x.includes(FIELD_CLIENT_TIER_ID) ||
                x.includes(FIELD_CLIENT_TIER_ID + FIELD_DISABLED)) &&
              <Grid item>
                <FormControl
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_CLIENT_TIER_ID + FIELD_DISABLED)}
                  error={!!props.invalidFields.clientTierId}
                  id="clientTierId"
                  margin={props.margin}
                  style={{width: '100%'}}>
                  <InputLabel
                    htmlFor="clientTierIdLabel"
                    shrink={!!props.party.clientTierId}>
                    Client Tier
                  </InputLabel>
                  <Select
                    error={!!props.invalidFields.clientTierId}
                    inputProps={{
                      id: 'clientTierIdLabel',
                    }}
                    onChange={(e) => props.onChange('clientTierId',
                      e.target.value)}
                    value={party.clientTierId}
                  >
                    {props.clientTiers.map((c, i) => <MenuItem
                      className="clientTierItems"
                      key={i}
                      value={c.id}>{c.name}</MenuItem>)}
                  </Select>
                  <FormHelperText>{props.invalidFields.clientTierId ||
                  ' '}</FormHelperText>
                </FormControl>
              </Grid>
              }
              {(x.includes(FIELD_DEFAULT_CURRENCY_ID) ||
                x.includes(FIELD_DEFAULT_CURRENCY_ID + FIELD_DISABLED)) &&
              <Grid item>
                <FormControl
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_DEFAULT_CURRENCY_ID + FIELD_DISABLED)}
                  error={!!props.invalidFields.defaultCurrencyId}
                  id="defaultCurrencyId"
                  margin={props.margin}
                  style={{width: '100%'}}>
                  <InputLabel
                    htmlFor="defaultCurrencyId-required"
                    shrink={!!party.defaultCurrencyId}>
                    Default Currency
                  </InputLabel>
                  <Select
                    error={!!props.invalidFields.defaultCurrencyId}
                    inputProps={{
                      id: 'defaultCurrencyId-required',
                    }}
                    onChange={(e) => props.onChange('defaultCurrencyId',
                      e.target.value)}
                    value={party.defaultCurrencyId}
                  >
                    {props.currencies.map((c, i) => <MenuItem
                      className="currencyItems"
                      key={i}
                      value={c.id}>{c.name}</MenuItem>)}
                  </Select>
                  <FormHelperText>{props.invalidFields.defaultCurrencyId ||
                  ' '}</FormHelperText>
                </FormControl>
              </Grid>
              }
              {(x.includes(FIELD_LOCAL_CURRENCY_ID) ||
                x.includes(FIELD_LOCAL_CURRENCY_ID + FIELD_DISABLED)) &&
              <Grid item>
                <FormControl
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_LOCAL_CURRENCY_ID + FIELD_DISABLED)}
                  error={!!props.invalidFields.localCurrencyId}
                  id="localCurrencyId"
                  margin={props.margin}
                  style={{width: '100%'}}>
                  <InputLabel
                    htmlFor="localCurrencyId-required">
                    Local Currency
                  </InputLabel>
                  <Select
                    error={!!props.invalidFields.localCurrencyId}
                    inputProps={{
                      id: 'localCurrencyId-required',
                    }}
                    onChange={(e) => props.onChange('localCurrencyId',
                      e.target.value)}
                    value={party.localCurrencyId || ''}
                  >
                    {props.currencies.map((c, i) => <MenuItem
                      className="currencyItems"
                      key={i}
                      value={c.id}>{c.name}</MenuItem>)}
                  </Select>
                  <FormHelperText>{props.invalidFields.localCurrencyId ||
                  ' '}</FormHelperText>
                </FormControl>
              </Grid>
              }
              {(x.includes(FIELD_FINANCIAL_YEAR_END) ||
                x.includes(FIELD_FINANCIAL_YEAR_END + FIELD_DISABLED)) &&
              <Grid item>
                <FormControl
                  disabled={props.disabled || !props.onChange ||
                  x.includes(FIELD_FINANCIAL_YEAR_END + FIELD_DISABLED)}
                  error={!!props.invalidFields.financialYearEnd}
                  id="financialYearEnd"
                  margin={props.margin}
                  style={{width: '100%'}}>
                  <InputLabel
                    htmlFor="financialYearEnd-required">
                    Financial Year End
                  </InputLabel>
                  <Select
                    error={!!props.invalidFields.financialYearEnd}
                    inputProps={{
                      id: 'financialYearEnd-required',
                    }}
                    onChange={(e) => props.onChange('financialYearEnd',
                      e.target.value)}
                    value={party.financialYearEnd || ''}
                  >
                    {months.map((m, i) => <MenuItem
                      className="currencyItems"
                      key={i}
                      value={m}>{m}</MenuItem>)}
                  </Select>
                  <FormHelperText>{props.invalidFields.financialYearEnd ||
                  ' '}</FormHelperText>
                </FormControl>
              </Grid>
              }
            </Grid>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  )
}

BasicInformationIndividual = withStyles(styles)(BasicInformationIndividual)

BasicInformationIndividual.propTypes = {
  clientTiers: PropTypes.arrayOf(PropTypes.instanceOf(ClientTierEntity)),

  currencies: PropTypes.arrayOf(PropTypes.instanceOf(CurrencyEntity)),

  disabled: PropTypes.bool,

  invalidFields: PropTypes.objectOf(PropTypes.string),

  isNew: PropTypes.bool,
  margin: PropTypes.oneOf(['dense', 'normal']),
  onChange: PropTypes.func,
  onLogoChange: PropTypes.func,
  party: PropTypes.oneOfType([
    PropTypes.instanceOf(individualEntity),
  ]).isRequired,
  title: PropTypes.string,

  viewOfPartyType: PropTypes.oneOf([
    PARTY_TYPE_INDIVIDUAL,
  ]).isRequired,
  viewingPartyType: PropTypes.oneOf([
    PARTY_TYPE_BROKER,
    PARTY_TYPE_COMPANY,
    PARTY_TYPE_PROCESSING_BANK,
    PARTY_TYPE_PROCESSING_ORG,
    PARTY_TYPE_SYSTEM,
    PARTY_TYPE_INDIVIDUAL,
  ]).isRequired,
}

BasicInformationIndividual.defaultProps = {
  invalidFields: {},
  margin: 'normal',
  disabled: false,
  title: 'Basic Information',

  clientTiers: [],
  currencies: [],
}

export default BasicInformationIndividual

// fields

const FIELD_DISABLED = '.disabled'

const FIELD_PARTY_CODE = 'partyCode'
const FIELD_NAME = 'name'
const FIELD_EMAIL = 'email'
const FIELD_CLIENT_TIER_ID = 'clientTierId'
const FIELD_LOGO = 'logo'
const FIELD_DEFAULT_CURRENCY_ID = 'defaultCurrencyId'
const FIELD_LOCAL_CURRENCY_ID = 'localCurrencyId'
const FIELD_FINANCIAL_YEAR_END = 'financialYearEnd'
const FIELD_ID_NUMBER = 'IDNumber'
const FIELD_SOUTH_AFRICAN_CITIZEN = 'southAfricanCitizen'
const FIELD_SOUTH_AFRICAN_CITIZEN_ONLY = 'southAfricanCitizenOnly'
const FIELD_US_PERSON = 'USCitizen'
const FIELD_US_CITIZEN = 'USPerson'


const RELATIVE_PARTY_TYPE_ROOT = 'root'
const RELATIVE_PARTY_TYPE_PARENTS_PARENT = 'parentsParent'
const RELATIVE_PARTY_TYPE_PARENT = 'parent'
const RELATIVE_PARTY_TYPE_SELF = 'self'
const RELATIVE_PARTY_TYPE_INVALID = 'invalid' // when incorrect parent/child relationship supplied

/**
 * Translate the combination of viewing party and view of party into the relative viewing party
 *
 * @param viewingPartyType  - The type of the party that is viewing this information
 * @param viewOfPartyType   - What is the type of the party that is being viewed
 * @param partyCode         - The partyCody of the user that is logged in (His current context's partyCode
 * @param party             - The actual party who's information is being viewed
 * @param isNew             - Indicates {party} is new (Being created)
 *
 * @returns {RELATIVE_PARTY_TYPE_PARENT} - The direct parent is the party viewing the entity
 * @returns {RELATIVE_PARTY_TYPE_ROOT} - The SYSTEM party is viewing the entity
 * @returns {RELATIVE_PARTY_TYPE_SELF} - Viewing oneself
 * @returns {RELATIVE_PARTY_TYPE_PARENTS_PARENT} - The parent's parent is viewing the entity
 */
const viewingPartyTranslator = (viewingPartyType, viewOfPartyType, partyCode, party, isNew) => {
  if (isNew) {
    return RELATIVE_PARTY_TYPE_PARENT
  }

  if (partyCode && party) {
    if (partyCode === party.parentPartyCode) {
      return RELATIVE_PARTY_TYPE_PARENT
    }
  }

  switch (viewingPartyType) {
    case PARTY_TYPE_SYSTEM:
      return RELATIVE_PARTY_TYPE_ROOT

    case PARTY_TYPE_PROCESSING_ORG:
      switch (viewOfPartyType) {
        case PARTY_TYPE_PROCESSING_ORG:
          return RELATIVE_PARTY_TYPE_SELF
        case PARTY_TYPE_BROKER:
        case PARTY_TYPE_COMPANY:
        case PARTY_TYPE_INDIVIDUAL:
        case PARTY_TYPE_PROCESSING_BANK:
          return RELATIVE_PARTY_TYPE_PARENTS_PARENT
        default:
          console.error('invalid party types supplied \'', viewingPartyType,
            '\' can\'t view basic info of \'', viewOfPartyType, '\'')
          return RELATIVE_PARTY_TYPE_INVALID
      }

    case PARTY_TYPE_BROKER:
      switch (viewOfPartyType) {
        case PARTY_TYPE_BROKER:
          return RELATIVE_PARTY_TYPE_SELF
        case PARTY_TYPE_COMPANY:
          return RELATIVE_PARTY_TYPE_PARENTS_PARENT
        case PARTY_TYPE_INDIVIDUAL:
          return RELATIVE_PARTY_TYPE_PARENTS_PARENT
        default:
          console.error('invalid party types supplied \'', viewingPartyType,
            '\' can\'t view basic info of \'', viewOfPartyType, '\'')
          return RELATIVE_PARTY_TYPE_INVALID
      }

    case PARTY_TYPE_COMPANY:
      switch (viewOfPartyType) {
        case PARTY_TYPE_COMPANY:
          return RELATIVE_PARTY_TYPE_SELF
        default:
          console.error('invalid party types supplied \'', viewingPartyType,
            '\' can\'t view basic info of \'', viewOfPartyType, '\'')
          return RELATIVE_PARTY_TYPE_INVALID
      }
    case PARTY_TYPE_INDIVIDUAL:
      switch (viewOfPartyType) {
        case PARTY_TYPE_INDIVIDUAL:
          return RELATIVE_PARTY_TYPE_SELF
        default:
          console.error('invalid party types supplied \'', viewingPartyType,
            '\' can\'t view basic info of \'', viewOfPartyType, '\'')
          return RELATIVE_PARTY_TYPE_INVALID
      }

    case PARTY_TYPE_PROCESSING_BANK:
      console.error('not defined yet')
      return RELATIVE_PARTY_TYPE_INVALID
    default :
      return RELATIVE_PARTY_TYPE_INVALID
  }
}

// render table
// used to decide what to render based on the relative relationship between viewer and viewee
const renderTable = {
  [RELATIVE_PARTY_TYPE_ROOT]: {
    viewing: {
      [PARTY_TYPE_SYSTEM]: [
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE + FIELD_DISABLED,
      ],
      [PARTY_TYPE_PROCESSING_ORG]: [
        //Note: This case wont occur: Relative party will be parent.
      ],
      [PARTY_TYPE_COMPANY]: [ // In this case SYSTEM is not the direct parent of the entity
        FIELD_PARTY_CODE,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_LOGO,
        FIELD_DEFAULT_CURRENCY_ID,
        FIELD_LOCAL_CURRENCY_ID,
        FIELD_FINANCIAL_YEAR_END,
      ],
      [PARTY_TYPE_INDIVIDUAL]: [ // In this case SYSTEM is not the direct parent of the entity
        FIELD_PARTY_CODE,
        FIELD_ID_NUMBER,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_LOGO,
        FIELD_SOUTH_AFRICAN_CITIZEN,
        FIELD_SOUTH_AFRICAN_CITIZEN_ONLY,
        FIELD_US_CITIZEN,
        FIELD_US_PERSON,
        FIELD_DEFAULT_CURRENCY_ID,
        FIELD_LOCAL_CURRENCY_ID,
        FIELD_FINANCIAL_YEAR_END,
      ],
      [PARTY_TYPE_BROKER]: [ // In this case SYSTEM is not the direct parent of the entity
        FIELD_LOGO,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE,
      ],
    },
  },

  [RELATIVE_PARTY_TYPE_PARENT]: {
    viewing: {
      [PARTY_TYPE_PROCESSING_ORG]: [
        FIELD_CLIENT_TIER_ID,
        FIELD_LOGO,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE,
      ],
      [PARTY_TYPE_COMPANY]: [
        FIELD_CLIENT_TIER_ID,
        FIELD_LOGO,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE,
      ],
      [PARTY_TYPE_INDIVIDUAL]: [
        FIELD_CLIENT_TIER_ID,
        FIELD_LOGO,
        FIELD_ID_NUMBER,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE,
        FIELD_SOUTH_AFRICAN_CITIZEN,
        FIELD_SOUTH_AFRICAN_CITIZEN_ONLY,
        FIELD_US_CITIZEN,
        FIELD_US_PERSON,
        FIELD_DEFAULT_CURRENCY_ID,
        FIELD_LOCAL_CURRENCY_ID,
        FIELD_FINANCIAL_YEAR_END,
      ],
      [PARTY_TYPE_BROKER]: [
        FIELD_CLIENT_TIER_ID,
        FIELD_LOGO,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE,
      ],
    },
  },

  [RELATIVE_PARTY_TYPE_PARENTS_PARENT]: {
    viewing: {
      [PARTY_TYPE_COMPANY]: [
        FIELD_LOGO,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE,
      ],
      [PARTY_TYPE_INDIVIDUAL]: [
        FIELD_LOGO,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE,
      ],
    },
  },

  [RELATIVE_PARTY_TYPE_SELF]: {
    viewing: {
      [PARTY_TYPE_COMPANY]: [
        FIELD_DEFAULT_CURRENCY_ID,
        FIELD_FINANCIAL_YEAR_END,
        FIELD_LOCAL_CURRENCY_ID,
        FIELD_LOGO,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE + FIELD_DISABLED,
      ],
      [PARTY_TYPE_INDIVIDUAL]: [
        FIELD_LOGO,
        FIELD_ID_NUMBER,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE + FIELD_DISABLED,
        FIELD_SOUTH_AFRICAN_CITIZEN,
        FIELD_SOUTH_AFRICAN_CITIZEN_ONLY,
        FIELD_US_CITIZEN,
        FIELD_US_PERSON,
        FIELD_DEFAULT_CURRENCY_ID,
        FIELD_LOCAL_CURRENCY_ID,
        FIELD_FINANCIAL_YEAR_END,
      ],
      [PARTY_TYPE_BROKER]: [
        FIELD_LOGO,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE + FIELD_DISABLED,
      ],
      [PARTY_TYPE_PROCESSING_ORG]: [
        FIELD_LOGO,
        FIELD_NAME,
        FIELD_EMAIL,
        FIELD_PARTY_CODE + FIELD_DISABLED,
      ],
    },
  },
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
