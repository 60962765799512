const requiredFields = ['name']

class Validator {

  /**
   * @Param {ClientTier} clientTier
   */
  static ValidateData(clientTier) {
    return new Promise(
      function (resolve, reject) {
        const invalidFields = {}
        let valid = true
        requiredFields.forEach(fieldName => {
          if (!clientTier[fieldName] || clientTier[fieldName] === '') {
            invalidFields[fieldName] = 'required'
            valid = false
          }
        })
        if (valid) {
          resolve(undefined)
        } else {
          reject(invalidFields)
        }
      })
  }
}

// invalidFields {
//    name: 'required'
// }

export default Validator
