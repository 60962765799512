import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core'
import {TextField, TextFieldParseTypes} from 'components/FormContols'
import {NUMBER_CRITERION} from 'popcorn-js/search/criteria/types'
import {isFunction, isNumber} from 'utils/Utils'

const styles = (theme) => ({})

class Number extends Component {
  static CriteriaType = NUMBER_CRITERION

  constructor(props) {
    super(props)
    this.state = {
      moreThanTouched: false,
      lessThanTouched: false,
    }
    if (props.initValue === undefined) {
      this.state.value = {
        type: Number.CriteriaType,
        value: {
          field: props.fieldID,
          lessThan: {
            ignore: true,
            amount: 0,
            inclusive: true,
          },
          moreThan: {
            ignore: true,
            amount: 0,
            inclusive: true,
          },
        },
      }
    } else {
      this.state.value = props.initValue
      this.state.moreThanTouched = true
      this.state.lessThanTouched = true
    }
  }

  onChange = (event, lessThan) => {
    let {
      value,
      moreThanTouched,
      lessThanTouched,
    } = this.state
    const {
      onChange
    } = this.props

    if (lessThan) {
      value.value.lessThan.amount = event.target.value
      if (!lessThanTouched) {
        lessThanTouched = true
      }

      if (event.original.target.value === '') {
        value.value.lessThan.amount = 0
        value.value.lessThan.ignore = true
      } else {
        value.value.lessThan.ignore = false
      }
    } else {
      value.value.moreThan.amount = event.target.value
      if (!moreThanTouched) {
        moreThanTouched = true
      }

      if (event.original.target.value === '') {
        value.value.moreThan.amount = 0
        value.value.moreThan.ignore = true
      } else {
        value.value.moreThan.ignore = false
      }
    }

    if (isFunction(onChange)) {
      if (
        value.value.lessThan.ignore &&
        value.value.moreThan.ignore
      ) {
        onChange(undefined)
      } else {
        if (
          isNumber(value.value.lessThan.amount) &&
          isNumber(value.value.moreThan.amount)
        ) {
          onChange(value)
        }
      }
    }

    this.setState({
      value,
      moreThanTouched,
      lessThanTouched,
    })
  }

  render() {
    const {
      filterConfig
    } = this.props
    const {
      value,
      moreThanTouched,
      lessThanTouched,
    } = this.state

    const addInputProps = {}
    if (filterConfig) {
      if (filterConfig.width) {
        addInputProps.style = {
          width: filterConfig.width,
        }
      }
    }

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: 'auto',
          gridTemplateRows: 'auto auto',
        }}>
        <div>
          <TextField
            {...addInputProps}
            InputProps={{disableUnderline: true}}
            onChange={e => this.onChange(e, false)}
            parseType={TextFieldParseTypes.float}
            placeholder={'Start'}
            value={moreThanTouched ? value.value.moreThan.amount : ''}
          />
        </div>
        <div>
          <TextField
            {...addInputProps}
            InputProps={{disableUnderline: true}}
            onChange={e => this.onChange(e, true)}
            parseType={TextFieldParseTypes.float}
            placeholder={'End'}
            value={lessThanTouched ? value.value.lessThan.amount : ''}
          />
        </div>
      </div>
    )
  }
}

const styledText = withStyles(styles)(Number)

styledText.propTypes = {
  // Not required, will be used to set value on construction
  fieldID: PropTypes.string.isRequired,
  filterConfig: PropTypes.object,
  initValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
}

export default Number
