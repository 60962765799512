import React from 'react'
import {Grid, makeStyles} from '@material-ui/core'
import {TextField as ParsedTextField, TextFieldParseTypes} from 'components/FormContols'
import PropTypes from 'prop-types'

import {ItemWrapper} from './ItemWrapper'
import {HexToRGBA} from '../../../../utils/Utils'

const useStyles = makeStyles(theme => ({
  title: {
    backgroundColor: HexToRGBA('#ffffff', 0.1),
    color: theme.palette.text.primary,
    padding: theme.spacing(),
    border: '1px solid #26283c',
    marginBottom: theme.spacing(2)
  }
}))


export const AuditEntry = (props) => {
  const classes = useStyles()
  const inputProps = {
    className: classes.formField,
    disableUnderline: true,
    readOnly: true,
  }

  const order = props.order || {}
  return (
    <React.Fragment>
      <div
        className={classes.title}>
        Audit Information
      </div>
      <Grid
        alignContent={'flex-end'}
        container
        direction={'column'}
        spacing={1}>
        <ItemWrapper label={'Updated By'}>
          <ParsedTextField
            InputProps={inputProps}
            id="UserId"
            parseType={TextFieldParseTypes.text}
            value={order?.auditEntry?.username || 'unknown'}
          />
        </ItemWrapper>
        <ItemWrapper
          label={'Previous Action'}
        >
          <ParsedTextField
            InputProps={inputProps}
            id="action"
            parseType={TextFieldParseTypes.text}
            value={order?.auditEntry?.action}
          />
        </ItemWrapper>
        <ItemWrapper
          label={'Date of last edit'}
        >
          <ParsedTextField
            InputProps={inputProps}
            id="time"
            parseType={TextFieldParseTypes.date}
            type={'datetime'}
            value={order?.auditEntry?.time}
          />
        </ItemWrapper>
        <ItemWrapper
          label={'Current Version'}
        >
          <ParsedTextField
            InputProps={inputProps}
            id="action"
            parseType={TextFieldParseTypes.text}
            value={order?.auditEntry?.version}
          />
        </ItemWrapper>
      </Grid>
    </React.Fragment>
  )
}

AuditEntry.propTypes = {
  order: PropTypes.object.isRequired,
}
