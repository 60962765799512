import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types/prop-types';
import HistoryLayout from '../HistoryLayout';
import { InvoiceRecordkeeper } from 'popcorn-js/financial/invoice';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import { ExactCriterion } from 'popcorn-js/search/criteria/types';
import { MarshalCriteria as marshalCriteria } from 'popcorn-js/search/criteria/Marshaller';
import { InvoiceFields } from './InvoiceFields';

const mapStateToProps = (state) => {
  return {
    currencies: state.currency.records || [],
  };
};

class InvoiceDetailHistoryContainer extends Component {
  state = {
    isLoading: true,
    history: [],
    counterparties: [],
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { invoice } = this.props;
    try {
      this.setState({ isLoading: true });
      await this.retrieveHistory(invoice.id);
      await this.findAssociatedCounterparties();
      this.setState({ isLoading: false });
    } catch (e) {
      console.error('error retrieving history', e);
    }
  };

  retrieveHistory = async (id) => {
    const retrieveResponse = await InvoiceRecordkeeper.retrieveHistory({
      identifier: { id: id },
    });
    this.setState({
      history: retrieveResponse.history,
    });
  };

  modifyMultiple = (invoices) => {
    return invoices.map((inv) => this.modifyFields(inv));
  };

  modifyFields = (invoice) => {
    return {
      ...invoice,

      // currency association
      currencyIsoCode: (
        this.props.currencies.find((c) => c.id === invoice.currencyId) || {}
      ).isoCode,

      // cost currency association
      costCurrencyIsoCode: (
        this.props.currencies.find((c) => c.id === invoice.costCurrencyId) || {}
      ).isoCode,

      // counterparty association
      counterpartyName: (
        this.state.counterparties.find(
          (c) => c.id === invoice.counterpartyId
        ) || {}
      ).name,
    };
  };

  findAssociatedCounterparties = async () => {
    const { invoice } = this.props;
    const { history } = this.state;

    const counterpartyIds = [];

    // current order version
    if (invoice.counterpartyId && invoice.counterpartyId !== '') {
      counterpartyIds.push(invoice.counterpartyId);
    }

    // history
    for (const inv of history) {
      if (inv.counterpartyId && inv.counterpartyId !== '') {
        if (!counterpartyIds.includes(inv.counterpartyId)) {
          counterpartyIds.push(inv.counterpartyId);
        }
      }
    }

    if (counterpartyIds.length <= 0) {
      return;
    }

    const criteria = marshalCriteria(
      counterpartyIds.map((id) => new ExactCriterion({ field: 'id', text: id }))
    );

    const findResponse = await CounterpartyRecordkeeper.find({
      criteria: criteria,
      query: undefined,
      Deleted: false,
    });
    this.setState({ counterparties: findResponse.records });
  };

  render() {
    const { history, isLoading } = this.state;

    return (
      <HistoryLayout
        entity={this.modifyFields(this.props.invoice)}
        entityFields={InvoiceFields}
        entityHistory={this.modifyMultiple(history || [])}
        entityName={'Invoice'}
        loading={isLoading}
        onHide={this.props.onHide}
        open={this.props.open}
      />
    );
  }
}

InvoiceDetailHistoryContainer.propTypes = {
  invoice: PropTypes.object,
  invoiceHistory: PropTypes.array,
  onHide: PropTypes.func,
  open: PropTypes.bool,
};

export default connect(mapStateToProps)(InvoiceDetailHistoryContainer);
