import {IconButton, Tooltip} from '@material-ui/core'
import React from 'react'
import {MdAdd, MdArrowBack, MdCancel, MdDelete, MdDeleteForever, MdHistory, MdRestore, MdSave,} from 'react-icons/md'

export const saveAction = onClick => (
  <Tooltip
    key={'save'}
    title={'Save Changes'}>
    <IconButton onClick={onClick}>
      <MdSave/>
    </IconButton>
  </Tooltip>
)

export const createAction = onClick => (
  <Tooltip
    key={'new'}
    title={'Create new'}>
    <IconButton onClick={onClick}>
      <MdAdd/>
    </IconButton>
  </Tooltip>
)

export const viewHistory = onClick => (
  <Tooltip
    key={'history'}
    title={'View history'}>
    <IconButton onClick={onClick}>
      <MdHistory/>
    </IconButton>
  </Tooltip>)

export const viewDelete = onClick => (
  <Tooltip
    key={'viewDelete'}
    title={'View Deletes'}>
    <IconButton onClick={onClick}>
      <MdDelete/>
    </IconButton>
  </Tooltip>)

export const discardAction = onClick => (
  <Tooltip
    key={'discard'}
    title={'Discard changes'}>
    <IconButton onClick={onClick}>
      <MdCancel/>
    </IconButton>
  </Tooltip>)

export const deleteAction = onClick => (
  <Tooltip
    key={'delete'}
    title={'Delete'}>
    <IconButton onClick={onClick}>
      <MdDelete/>
    </IconButton>
  </Tooltip>)


export const returnAction = onClick => (
  <Tooltip
    key={'return'}
    title={'Return'}>
    <IconButton onClick={onClick}>
      <MdArrowBack/>
    </IconButton>
  </Tooltip>)

export const deleteForeverAction = onClick => (
  <Tooltip
    key={'deleteForever'}
    title={'Delete Forever'}>
    <IconButton onClick={onClick}>
      <MdDeleteForever/>
    </IconButton>
  </Tooltip>)

export const restoreAction = onClick => (
  <Tooltip
    key={'restore'}
    title={'Restore'}>
    <IconButton onClick={onClick}>
      <MdRestore/>
    </IconButton>
  </Tooltip>)
