import {jsonRpcRequest} from '../../jsonRpc/jsonRpcReqest';
import config from 'react-global-configuration'
import {MarshalCriteria} from '../search/criteria/Marshaller';

export interface FindResponse {
  wrappedReports: any,
}
const Store = {
  ServiceProviderName: 'Report-Store',
  
  async Find(request: { criteria: any}): Promise<FindResponse> {
    const serializedCriteria = MarshalCriteria(request.criteria)
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Store.ServiceProviderName}.Find`,
      request: {
        criteria: serializedCriteria,
      },
    })
  }
}

export default Store