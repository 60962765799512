import EntryComparator from 'popcorn-js/audit/EntryComparator'
import AccountEntry from 'popcorn-js/financial/account/Account'

class Comparator {

  /**
   * @param {Account} a
   * @param {Account} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareExcluding(a, b, ...args) {
    for (const key in Object.keys(a)) {
      switch (key) {
        case 'auditEntry':
          if (!EntryComparator.CompareExcluding(a.auditEntry, b.auditEntry, ...args.filter(a => a.includes('auditEntry.')).map(a => a.replace('auditEntry.','')))) {
            return false
          }
          break
        case 'entries':
          if (a.entries.length !== b.entries.length) {
          // if no of entries differ then the account entities are different
            return false
          }
          // if no of entries does not differ then each are compared in place
          for (let i=0; i<a.entries.length; i++){
            if (!AccountEntry.CompareAll(a.entries[i], b.entries[i])){
              return false
            }
          }
          break
        default:
          if (a[key] !== b[key] && !args.includes(key)) {
            return false
          }
      }
    }
    return true
  }

  /**
   * @param {Account} a
   * @param {Account} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareOnly(a, b, ...args) {
    for (const field in args) {
      if (args[field].includes('auditEntry.')) {
        if (!EntryComparator.CompareOnly(a.auditEntry, b.auditEntry, args[field].replace('auditEntry.', ''))) {
          return false
        }
      } else if (a[args[field]] !== b[args[field]]) {
        return false
      }
    }
    return true
  }

  /**
   * @param {Account} a
   * @param {Account} b
   * @return {boolean} True if a and b are the same
   */
  static CompareAll(a, b) {
    if (!(a instanceof AccountEntry)) {
      console.error('Account comparator cannot compare objects: invalid type')
      return false
    }
    if (!(b instanceof AccountEntry)) {
      console.error('Account comparator cannot compare objects: invalid type')
      return false
    }

    const keys = Object.keys(a)

    for (const i in keys) {
      switch (keys[i]) {
        case 'auditEntry':
          if (!EntryComparator.CompareAll(a.auditEntry, b.auditEntry)) {
            return false
          }
          break
        case 'entries':
          if (a.entries.length !== b.entries.length) {
          // if no of entries differ then the account entities are different
            return false
          }
          // if no of entries does not differ then each are compared in place
          for (let i=0; i<a.entries.length; i++){
            if (!AccountEntry.CompareAll(a.entries[i], b.entries[i])){
              return false
            }
          }
          break
        default:
          if (a[keys[i]] !== b[keys[i]]) {
            return false
          }
      }
    }
    return true

  }
}

export default Comparator
