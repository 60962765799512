import React from 'react'
import Card from '../../../components/Card/Card'
import CardHeader from '../../../components/Card/CardHeader'
import CardIcon from '../../../components/Card/CardIcon'
import {
  MdExpandMore as Expand,
  MdFileDownload,
  MdFormatListBulleted as List,
} from 'react-icons/md'
import CardBody from '../../../components/Card/CardBody'
import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails'
import {Table, TableBody, TableCell, TableHead, TableRow,} from '@material-ui/core'
import {processUnixDateForViewing} from '../../../utils/Utils'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {cardTitle} from '../../../assets/jss/material-dashboard-pro-react'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {roundScaleAndFormatNumber} from '../Company/Dashboard/Util';

const styles = theme => ({
  root: {
    width: '100%',
  },
  expansionValue: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expansionHeading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#848484',
  },
  subMenuHeading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardTitle: {
    ...cardTitle,
    marginTop: '0px',
    marginBottom: '3px',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
})

const RatesCache = (props) => {
  const {classes, instances, exportInstances} = props
  return (
    <Card>
      <CardHeader
        color="success"
        icon>
        <CardIcon color="success">
          <List/>
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          Rick Instances Details
        </h4>
      </CardHeader>
      <CardBody>
        {instances && <div>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<Expand/>}>
              <Grid
                container
                direction={'row'}
                justify={'space-between'}>
                <Grid item>
                  <Tooltip title="Export File">
                    <IconButton
                      aria-label="exportFile"
                      onClick={() => {
                        exportInstances()
                      }}
                    >
                      <MdFileDownload/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid
                container
                direction={'column'}>
                <Grid item>
                </Grid>
                <Grid item>
                  {/*Forward Cache*/}
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<Expand/>}>
                      <Typography className={classes.subMenuHeading}>Forward
                        Cache</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Currency Pair</TableCell>
                            <TableCell>Tenor</TableCell>
                            <TableCell>Tenor Date</TableCell>
                            <TableCell>Bid</TableCell>
                            <TableCell>Ask</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {instances.forwardCache ?
                            instances.forwardCache.map(
                              (fc, idx) => {

                                return (
                                  <TableRow key={idx}>
                                    <TableCell>
                                      {fc.ccyPair}
                                    </TableCell>
                                    <TableCell>
                                      {fc.tenor}
                                    </TableCell>
                                    <TableCell>
                                      {processUnixDateForViewing(
                                        fc.tenorDate)}
                                    </TableCell>
                                    <TableCell>
                                      {roundScaleAndFormatNumber(fc.bid, '', false, 4)}
                                    </TableCell>
                                    <TableCell>
                                      {roundScaleAndFormatNumber(fc.ask, '', false, 4)}
                                    </TableCell>
                                  </TableRow>
                                )
                              }) :
                            <TableRow key={1}/>}
                        </TableBody>
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                <Grid item>
                  {/*Spot Cache*/}
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<Expand/>}>
                      <Typography className={classes.subMenuHeading}>Spot
                        Cache</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Currency Pair</TableCell>
                            <TableCell>Bid</TableCell>
                            <TableCell>Ask</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {instances.spotCache ?
                            instances.spotCache.map(sc => {
                              return (
                                <TableRow key={sc.ccyPair}>
                                  <TableCell>
                                    {sc.ccyPair}
                                  </TableCell>
                                  <TableCell>
                                    {roundScaleAndFormatNumber(sc.bid, '', false, 4)}
                                  </TableCell>
                                  <TableCell>
                                    {roundScaleAndFormatNumber(sc.ask, '', false, 4)}
                                  </TableCell>
                                </TableRow>
                              )
                            }) :
                            <TableRow key={1}/>}
                        </TableBody>
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>}
      </CardBody>
    </Card>
  )
}

RatesCache.propTypes = {
  classes: PropTypes.object,
  currencyPairs: PropTypes.array,
  exportInstances: PropTypes.func,
  instances: PropTypes.array.isRequired,
}

export default withStyles(styles)(RatesCache)

