/* eslint-disable @typescript-eslint/no-empty-function */
import React, {ChangeEvent, useState, useEffect, useCallback, cloneElement, ReactElement} from 'react'
import {
  Card,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  useTheme,
  FormControl,
} from '@material-ui/core'
import { Currency } from 'popcorn-js/financial/currency/currencyType'
import { LightDatePicker, DarkNumberField, LightTextField, LightNumberField, 
  LightInputLabel, LightSelect, FormComponent, DarkTextField, LightRateField } from './styledComponents'
import Big from 'big.js'
import { isValid, parseISO, isBefore, isWeekend, isAfter } from 'date-fns'
import { debounce } from 'lodash'
import { ProcessingBank } from 'popcorn-js/legalEntity/party'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { getMidDay } from './util'
import { TradeDirection } from 'popcorn-js/trade/tradeTypes'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) =>
  createStyles({
    buying: {
      height: '100%',
      color: 'black',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
    },
    selling: {
      height: '100%',
      color: 'black',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
    },
    details: {
      backgroundColor: theme.palette.primary.main,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: '8px',
      borderBottomLeftRadius: '8px',
      height: '100%',
    },
  }),
);

interface BuySellStaticFullProps {
  tradeDate: string | null | undefined;
  maturityDate: string | null | undefined;
  maturityDateMax?: string | null | undefined;
  maturityDateMin?: string | null | undefined;
  maturityDateEditable: boolean;
  maturityDateChange: (date: string | null | undefined) => void;
  tradeDateChange: (date: string | null | undefined) => void;
  foreignCurrencyAmount: Big|undefined;
  buyAmountChange: (value: Big | undefined) => void;
  buyCurrency: Currency|undefined;
  sellAmountChange: (value: Big | undefined) => void;
  sellCurrency: Currency|undefined;
  allInRate: Big|undefined;
  spotRate: Big|undefined;
  spotRateChange: (rate: Big | undefined) => void;
  allInRateChange: (rate: Big | undefined) => void;
  forwardPointsChange: (points: Big | undefined) => void;
  bank: ProcessingBank|undefined;
  bankEditable: boolean;
  banks: ProcessingBank[] | undefined;
  bankChange?: (bank: ProcessingBank | undefined) => void;
  interbankRateChange: (interbankRate: Big|undefined) => void;
  bankRateChange: (bankRate: Big|undefined) => void;
  externalReferenceChange: (externalReference: string|undefined) => void;
  direction: TradeDirection;
  parentDirection: TradeDirection;
  nonTradingDays: Date[];
}

/**
 * The BuySell component is the main input and focus
 * @param props
 * @constructor
 */
export const BuySellStaticFull: React.FC<BuySellStaticFullProps> = (props: BuySellStaticFullProps) => {
  const {
    direction, tradeDateChange, maturityDateChange, buyAmountChange, buyCurrency, sellAmountChange, sellCurrency, foreignCurrencyAmount,
    spotRateChange, allInRateChange, forwardPointsChange, banks, bankChange, interbankRateChange, externalReferenceChange, tradeDate, 
    maturityDateEditable, parentDirection, bankEditable, bankRateChange, nonTradingDays
  } = props

  const maturityDateMax = props.maturityDateMax ? getMidDay(parseISO(props.maturityDateMax)) : undefined
  const maturityDateMin = props.maturityDateMin ? getMidDay(parseISO(props.maturityDateMin)) : undefined
  
  const theme = useTheme()
  const classes = useStyles(theme)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fxColor = parentDirection === TradeDirection.BUY ? theme.palette.import.main : theme.palette.export.main

  const [maturityDate, setMaturityDate] = useState<string | null | undefined>(props.maturityDate)
  const [buyAmount, setBuyAmount] = useState<Big|undefined>()
  const [sellAmount, setSellAmount] = useState<Big|undefined>()
  const [externalReference, setExternalReference] = useState<string|undefined>();
  const [spotRate, setSpotRate] = useState<Big|undefined>()
  const [allInRate, setAllInRate] = useState<Big|undefined>()
  const [forwardPoints, setForwardPoints] = useState<string|undefined>('')
  const [pointsPrefix, setPointsPrefix] = useState<string|undefined>('')
  const [interbankRate, setInterbankRate] = useState<Big|undefined>()
  const [bankRate, setBankRate] = useState<Big|undefined>()
  const [bank, setBank] = useState<ProcessingBank|undefined>(!bankEditable ? props.bank : undefined)
  const [primary, setPrimary] = useState<boolean>(false)
  
  const bankUpdateHandler = useCallback(debounce((value: ProcessingBank | undefined) => bankChange ? bankChange(value) : undefined, 500), []);
  const maturityDateUpdateHandler = useCallback(debounce((value: string | null | undefined) => maturityDateChange(value), 500), []);
  const tradeDateUpdateHandler = useCallback(debounce((value: string | null | undefined) => tradeDateChange(value), 500), []);
  const allInRateRateUpdateHandler = useCallback(debounce((value: Big|undefined) => allInRateChange(value), 500), []);
  const interbankRateChangeUpdateHandler = useCallback(debounce((value: Big|undefined) => interbankRateChange(value), 500), []);
  const bankRateChangeUpdateHandler = useCallback(debounce((value: Big|undefined) => bankRateChange(value), 500), []);
  const forwardPointsChangeUpdateHandler = useCallback(debounce((value: Big|undefined) => forwardPointsChange(value), 500), []);
  const spotRateUpdateHandler = useCallback(debounce((value: Big|undefined) => spotRateChange(value), 100), []);

  const isHoliday = (d: Date): boolean => {
    for (const ntd of nonTradingDays) {
      if (
        ntd.getDate() === d.getDate() &&
            ntd.getMonth() === d.getMonth() &&
            ntd.getFullYear() === d.getFullYear()
      ) {
        return true;
      }
    }
    return false;
  };
  useEffect(() => {
    bankUpdateHandler(bank)
  }, [bank, bankUpdateHandler])
  useEffect(() => {
    maturityDateUpdateHandler(maturityDate)
  }, [maturityDate, maturityDateUpdateHandler])
  useEffect(() => {
    allInRateRateUpdateHandler(allInRate)
  }, [allInRate, allInRateRateUpdateHandler])
  useEffect(() => {
    spotRateUpdateHandler(spotRate)
  }, [spotRate, spotRateUpdateHandler])
  useEffect(() => {
    forwardPointsChangeUpdateHandler(forwardPoints ? Big(forwardPoints) : undefined)
  }, [forwardPoints, forwardPointsChangeUpdateHandler])
  useEffect(() => {
    interbankRateChangeUpdateHandler(interbankRate)
  }, [interbankRate, interbankRateChangeUpdateHandler])
  useEffect(() => {
    bankRateChangeUpdateHandler(bankRate)
  }, [bankRate, bankRateChangeUpdateHandler])

  useEffect(() => {
    setMaturityDate(props.maturityDate)
  }, [props.maturityDate])

  useEffect(() => {
    if (!primary) {
      setSpotRate(props.spotRate)
    }
  }, [props.spotRate, primary])
  
  useEffect(() => {
    if (direction === TradeDirection.BUY) {
      setBuyAmount(foreignCurrencyAmount)
    } else {
      setSellAmount(foreignCurrencyAmount)
    }
  }, [foreignCurrencyAmount, direction])

  const handleAllInRateChange = useCallback((value: Big|undefined) => {
    setAllInRate(value)
    if (value && forwardPoints) {
      setSpotRate(value.minus(forwardPoints))
    }
  }, [forwardPoints])

  const handleSpotRateChange = useCallback((value: Big|undefined) => {
    setSpotRate(value)
    if (value && forwardPoints) {
      setAllInRate(value.plus(forwardPoints))
    } else if (value) {
      setAllInRate(value)
    } else if (forwardPoints) {
      setAllInRate(forwardPoints ? Big(forwardPoints) : undefined)
    } else {
      setAllInRate(undefined)
    }
  }, [forwardPoints])

  const parseForwardPoints = useCallback((value: string|undefined): Big|undefined => {
    if (value && value.endsWith('.')) {
      value += '0'
    }
    if (value && !value.startsWith('-')){
      value = pointsPrefix + value
    }
    return value ? Big(value) : undefined
  }, [pointsPrefix])

  const handleKeyPress = (event: any) => {
    if(event.key === '-'){
      if (pointsPrefix === '-'){
        setPointsPrefix('')
      } else {
        setPointsPrefix('-')
      }
    }
    if(event.key === '+'){
      setPointsPrefix('')
    }
  }
  
  const handleForwardPointsChange = useCallback((value: string|undefined) => {
    const newValue = value ? value.trim().replace(/-/g, '') : value
    if (pointsPrefix && newValue && (newValue !== '')) {
      setForwardPoints(pointsPrefix + newValue)
    } else {
      setForwardPoints(newValue)
    }
    const newForwardPoints = parseForwardPoints(newValue)
    if (newForwardPoints && spotRate) {
      setAllInRate(newForwardPoints.plus(spotRate))
    } else if (newForwardPoints) {
      setAllInRate(newForwardPoints)
    } else if (spotRate) {
      setAllInRate(spotRate)
    } else {
      setAllInRate(undefined)
    }
  }, [spotRate, parseForwardPoints, pointsPrefix])

  const handleExternalReferenceChange = useCallback(debounce((value: string|undefined) => externalReferenceChange(value), 500), []);

  useEffect(() => {
    handleExternalReferenceChange(externalReference);
  }, [externalReference, handleExternalReferenceChange]);

  const sellAmountChangeHandler = useCallback(debounce((value: Big|undefined) => sellAmountChange(value), 500), [sellAmountChange])
  const buyAmountChangeHandler = useCallback(debounce((value: Big|undefined) => buyAmountChange(value), 500), [buyAmountChange])

  useEffect(() => {
    buyAmountChangeHandler(buyAmount)
  }, [buyAmount, buyAmountChangeHandler])
  useEffect(() => {
    sellAmountChangeHandler(sellAmount)
  }, [sellAmount, sellAmountChangeHandler])

  const updateBuyAmount = useCallback((sellAmount: Big|undefined) => {
    Big.DP = 2
    if (sellAmount && allInRate && allInRate.gt(0)) {
      if (direction === TradeDirection.BUY) {
        setBuyAmount(sellAmount.div(allInRate))
      } else {
        setBuyAmount(sellAmount.mul(allInRate))
      }
    } else {
      setBuyAmount(Big(0))
    }
  }, [direction, allInRate])

  const updateSellAmount = useCallback((buyAmount: Big|undefined) => {
    Big.DP = 2
    if (buyAmount && allInRate && allInRate.gt(0)) {
      if (direction === TradeDirection.SELL) {
        setSellAmount(buyAmount.div(allInRate))
      } else {
        setSellAmount(buyAmount.mul(allInRate))
      }
    } else {
      setSellAmount(Big(0))
    }
  }, [direction, allInRate])

  const handleBuyAmountChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (direction === TradeDirection.BUY) {
      const newBuyAmount = event.target.value ? Big(event.target.value) : undefined
      if (!(newBuyAmount && buyAmount && newBuyAmount?.eq(buyAmount))) {
        setBuyAmount(newBuyAmount)
      }
      updateSellAmount(newBuyAmount)
    }
  }, [direction, buyAmount, updateSellAmount])

  const handleSellAmountChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (direction === TradeDirection.SELL) {
      const newSellAmount = event.target.value ? Big(event.target.value) : undefined
      if (!(newSellAmount && sellAmount && newSellAmount?.eq(sellAmount))) {
        setSellAmount(newSellAmount)
      }
      updateBuyAmount(newSellAmount)
    }
  }, [direction, sellAmount, updateBuyAmount])

  useEffect(() => {
    if (direction === TradeDirection.SELL) {
      updateBuyAmount(sellAmount)
    }
  }, [sellAmount, direction, updateBuyAmount])

  useEffect(() => {
    if (direction === TradeDirection.BUY) {
      updateSellAmount(buyAmount)
    }
  }, [buyAmount, direction, updateSellAmount])

  return (
    <div
      style={{
        width: '412px',
        height: '497px',
        minHeight: '30px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{height: '50%', width: '100%'}}
      >
        <div
          style={{width: 'calc(50% - 8px)', height: '123px', float: 'left'}}
        >
          <Card
            elevation={0}
            style={{
              height: '100%',
              color: 'black',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px',
              display: 'flex', 
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              backgroundColor: (direction === TradeDirection.BUY ? fxColor : (theme as any).palette.local),
              paddingLeft: '24px',
              paddingRight: '24px',
            }}
          >
            <Grid
              alignContent={'flex-start'}
              alignItems={'center'}
              container
              direction={'row'}
              justify={'center'}
              spacing={1}
              style={{justifySelf: 'center', alignSelf: 'top', marginTop: '12px'}}
            >
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-start'}}
                xs={6}
              >
                <span 
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                >
                  Buying
                </span>
              </Grid>
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-end', width: '60px'}}
                xs={6}
              >
                <DarkTextField
                  disabled
                  id={`${direction}-buyCurrency`}
                  value={buyCurrency ? buyCurrency.isoCode : ''}
                />
              </Grid>
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-end'}}
                xs={12}
              >
                <DarkNumberField
                  disabled
                  id={`${direction}-buyAmount`}
                  label={'Amount'}
                  onChange={handleBuyAmountChange}
                  prefix={buyCurrency?.symbol}
                  style={{width: '150px'}}
                  value={buyAmount ? buyAmount.toFixed(2) : ''}
                />
              </Grid>
            </Grid>
          </Card>
        </div>
          
        <div
          style={{width: '16px', height: '100%', float: 'left', display: 'flex'}}
        />
        
        <div
          style={{width: 'calc(50% - 8px)', height: '123px', float: 'right'}}
        >
          <Card
            elevation={0}
            style={{
              height: '100%',
              color: 'black',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px',
              display: 'flex', 
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              backgroundColor: (direction === TradeDirection.SELL ? fxColor : (theme as any).palette.local),
              paddingLeft: '24px',
              paddingRight: '24px',
            }}
          >
            <Grid
              alignContent={'flex-start'}
              alignItems={'center'}
              container
              direction={'row'}
              justify={'center'}
              spacing={1}
              style={{justifySelf: 'center', alignSelf: 'top', marginTop: '12px'}}
            >
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-start'}}
                xs={6}
              >
                <span 
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                >
                    Selling
                </span>
              </Grid>
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-end', width: '60px'}}
                xs={6}
              >
                <DarkTextField
                  disabled
                  id={`${direction}-sellCurrency`}
                  value={sellCurrency ? sellCurrency.isoCode : ''}
                />
              </Grid>
              <Grid
                item
                style={{display: 'flex', justifyContent: 'flex-end'}}
                xs={12}
              >
                <DarkNumberField
                  disabled
                  id={`${direction}-sellAmount`}
                  label={'Amount'}
                  onChange={handleSellAmountChange}
                  prefix={sellCurrency?.symbol}
                  style={{width: '150px'}}                    
                  value={sellAmount ? sellAmount.toFixed(2) : ''}
                />
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
      <div
        style={{width: '100%', height: '374px'}}
      >
        <Card
          className={classes.details}
          elevation={0}
        >
          <Grid
            container
            direction={'row-reverse'}
            // justify={'flex-end'}
          >
            <FormComponent>
              <LightNumberField
                id={`${direction}-spotRate`}
                label="Spot Rate"
                onBlur={() => setPrimary(false)}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleSpotRateChange(event.target.value ? Big(event.target.value) : undefined)}
                onFocus={() => setPrimary(true)}
                value={spotRate ? spotRate.toFixed(4) : ''}
              />
            </FormComponent>
            <FormComponent>
              <LightTextField
                id={`${direction}-externalReference`}
                label="External Reference"
                onChange={(
                  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setExternalReference(event.target.value)}
                value={externalReference}
              />
            </FormComponent>
            <FormComponent>
              <LightRateField
                id={`${direction}-forwardPoints`}
                label="Forward Points"
                onBlur={() => {
                  setPrimary(false)
                  setForwardPoints(parseForwardPoints(forwardPoints)?.toFixed(4))
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleForwardPointsChange(event.target.value)}
                onFocus={() => setPrimary(true)}
                onKeyPress={handleKeyPress}
                placeholder={'-.----'}
                prefix={pointsPrefix}
                value={forwardPoints}
              />
            </FormComponent>
            <FormComponent>
              <LightDatePicker
                format={'yyyy-MM-dd'}
                id={`${direction}-tradeDate`}
                label="Trade Date"
                onChange={(day: MaterialUiPickersDate, value: string | null | undefined) => {
                  if (value && isValid(parseISO(value))) {
                    const date = getMidDay(day as Date)
                    if (isWeekend(date) || isHoliday(date)) {
                      tradeDateUpdateHandler(undefined)
                      return
                    }
                    if (maturityDateMax && isAfter(date, maturityDateMax)) {
                      tradeDateUpdateHandler(undefined)
                      return
                    }
                    if (maturityDateMin && isAfter(date, maturityDateMin)) {
                      tradeDateUpdateHandler(undefined)
                      return
                    }
                    const newTradeDate = getMidDay(date as Date)
                    tradeDateUpdateHandler(newTradeDate.toISOString())
                    if (maturityDate) {
                      const currentMaturityDate = getMidDay(parseISO(maturityDate))
                      if (isBefore(currentMaturityDate, newTradeDate)) {
                        setMaturityDate(newTradeDate.toISOString())
                      }
                    }
                  } else {
                    tradeDateUpdateHandler(undefined)
                  }
                }}
                renderDay={(day: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, isInCurrentMonth: boolean, dayComponent: ReactElement) => {
                  const date = getMidDay(day as Date)
                  if (isWeekend(date) || isHoliday(date)) {
                    return cloneElement(dayComponent, {disabled: true})
                  }
                  if (maturityDateMax && isAfter(date, maturityDateMax)) {
                    return cloneElement(dayComponent, {disabled: true})
                  }
                  if (maturityDateMin && isAfter(date, maturityDateMin)) {
                    return cloneElement(dayComponent, {disabled: true})
                  }
                  return dayComponent
                }}
                value={tradeDate ? tradeDate : ''}
              />
            </FormComponent>
            <FormComponent>
              <LightNumberField
                id={`${direction}-allInRate`}
                label="All-In Rate"
                onBlur={() => setPrimary(false)}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleAllInRateChange(event.target.value ? Big(event.target.value) : undefined)}
                onFocus={() => setPrimary(true)}
                value={allInRate ? allInRate.toFixed(4) : ''}
              />
            </FormComponent>
            <FormComponent>
              <LightDatePicker
                disabled={!maturityDateEditable}
                format={'yyyy-MM-dd'}
                id={`${direction}-maturityDate`}
                label={'Maturity Date'}
                onChange={(date: MaterialUiPickersDate, value: string | null | undefined) => {
                  if (value && tradeDate && isValid(parseISO(value))) {
                    const tradeDateStart = getMidDay(parseISO(tradeDate))
                    const newMaturityDate = getMidDay(date as Date)
                    if (isBefore(newMaturityDate, tradeDateStart) || isWeekend(newMaturityDate) || isHoliday(newMaturityDate) ){
                      setMaturityDate(undefined)
                      return
                    }
                    if (maturityDateMax && isAfter(newMaturityDate, maturityDateMax)) {
                      setMaturityDate(undefined)
                      return
                    }
                    if (maturityDateMin && isBefore(newMaturityDate, maturityDateMin)) {
                      setMaturityDate(undefined)
                      return
                    }
                    setMaturityDate(newMaturityDate.toISOString())
                  } else {
                    setMaturityDate(undefined)
                  }
                }}
                renderDay={(day: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, isInCurrentMonth: boolean, dayComponent: ReactElement) => {
                  const date = getMidDay(day as Date)
                  if (isWeekend(date) || isHoliday(date)) {
                    return cloneElement(dayComponent, { disabled: true });
                  }
                  if (tradeDate) {
                    const tradeDateStart = parseISO(tradeDate)
                    if (isBefore(date, tradeDateStart) || isWeekend(date)) {
                      return cloneElement(dayComponent, {disabled: true})
                    }
                    if (maturityDateMax && isAfter(date, maturityDateMax)) {
                      return cloneElement(dayComponent, {disabled: true})
                    }
                    if (maturityDateMin && isBefore(date, maturityDateMin)) {
                      return cloneElement(dayComponent, {disabled: true})
                    }
                  }
                  return dayComponent
                }}
                value={maturityDate || ''}
              />
            </FormComponent>
            <FormComponent>
              <LightNumberField
                id={`${direction}-bankRate`}
                label="Bank Rate"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setBankRate(event.target.value ? Big(event.target.value) : undefined)}
                value={bankRate ? bankRate.toString() : ''}
              />
            </FormComponent>
            <FormComponent>
              <FormControl
                style={{width: '100%'}}
              >
                <LightInputLabel>Bank</LightInputLabel>
                <LightSelect
                  disabled={!bankEditable}
                  id={`${direction}-bank`}
                  onChange={(event: ChangeEvent<{name?: string|undefined, value: unknown}>) =>
                    setBank(banks?.find((bank: ProcessingBank) => bank.id === event.target.value))}
                  style={{width: '100%'}}
                  value={bank ? bank.id : ''}
                >
                  {banks?.map((bank: ProcessingBank) =>
                    <MenuItem
                      key={bank.partyCode}
                      value={bank.id}>{bank.name}</MenuItem>
                    )}
                </LightSelect>
              </FormControl>
            </FormComponent>
            <FormComponent>
              <LightNumberField
                id={`${direction}-interbankRate`}
                label="Interbank Rate"
                onChange={(event: ChangeEvent<HTMLInputElement>) => setInterbankRate(event.target.value ? Big(event.target.value) : undefined)}
                value={interbankRate ? interbankRate.toString() : ''}
              />
            </FormComponent>
          </Grid>
        </Card>
      </div>
    </div>
  )
}
