import React, { Component } from "react";
import { connect } from "react-redux";
import HistoryLayout from "../HistoryLayout";
import OrderFields from "./OrderFields";
import PropTypes from "prop-types/prop-types";
import { Recordkeeper as OrderRecordkeeper } from "popcorn-js/financial/order/recordkeeper";
import { InvoiceRecordkeeper as InvoiceRecorkeeper } from "popcorn-js/financial/invoice";
import { CounterpartyRecordkeeper } from "popcorn-js/counterparty/index";
import { ExactCriterion } from "popcorn-js/search/criteria/types";

const mapStateToProps = (state) => {
  return {
    currencies: state.currency.records || [],
  };
};

const mapDispatchToProps = () => {
  return {};
};

class OrderContainer extends Component {
  state = {
    isLoading: true,
    history: [],

    invoices: [],
    counterparties: [],
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { order } = this.props;
    await this.retrieveHistory(order.id);
    await this.findAssociatedInvoices();
    await this.findAssociatedCounterparties();
    this.setState({ isLoading: false });
  };

  retrieveHistory = async (id) => {
    const retrieveHistoryResult = await OrderRecordkeeper.retrieveHistory({
      identifier: { id: id },
    });
    this.setState({ history: retrieveHistoryResult.history });
  };

  findAssociatedInvoices = async () => {
    const { order } = this.props;
    const { history } = this.state;

    const invoiceIds = [];

    // current order version
    if (order.invoiceId && order.invoiceId !== "") {
      invoiceIds.push(order.invoiceId);
    }

    // history
    for (const po of history) {
      if (po.invoiceId && po.invoiceId !== "") {
        if (!invoiceIds.includes(po.invoiceId)) {
          invoiceIds.push(po.invoiceId);
        }
      }
    }

    if (invoiceIds.length <= 0) {
      return;
    }

    const criteria = invoiceIds.map(
      (id) => new ExactCriterion({ field: "id", text: id })
    );

    const invoiceFindResult = await InvoiceRecorkeeper.find({ criteria });
    this.setState({ invoices: invoiceFindResult.records });
  };

  findAssociatedCounterparties = async () => {
    const { order } = this.props;
    const { history } = this.state;

    const counterpartyIds = [];

    // current order version
    if (order.counterpartyId && order.counterpartyId !== "") {
      counterpartyIds.push(order.counterpartyId);
    }

    // history
    for (const po of history) {
      if (po.counterpartyId && po.counterpartyId !== "") {
        if (!counterpartyIds.includes(po.counterpartyId)) {
          counterpartyIds.push(po.counterpartyId);
        }
      }
    }

    if (counterpartyIds.length <= 0) {
      return;
    }

    const criteria = counterpartyIds.map(
      (id) => new ExactCriterion({ field: "id", text: id })
    );
    const counterpartyFindResponse = await CounterpartyRecordkeeper.find({
      criteria: criteria,
      query: undefined,
      Deleted: false,
    });
    this.setState({ counterparties: counterpartyFindResponse.records });
  };

  render() {
    const { history, isLoading } = this.state;

    return (
      <HistoryLayout
        entity={this.modifyFields(this.props.order)}
        entityFields={OrderFields}
        entityHistory={this.modifyMultiple(history || [])}
        entityName={"Orders"}
        loading={isLoading}
        onHide={this.props.onHide}
        open={this.props.open}
      />
    );
  }

  modifyMultiple = (order) => {
    return order.map((c) => this.modifyFields(c));
  };

  modifyFields = (order) => {
    return {
      ...order,

      // currency association
      currencyIsoCode: (
        this.props.currencies.find((c) => c.id === order.currencyId) || {}
      ).isoCode,

      // cost currency association
      costCurrencyIsoCode: (
        this.props.currencies.find((c) => c.id === order.costCurrencyId) || {}
      ).isoCode,

      // invoice association
      invoiceNumber: (
        this.state.invoices.find((i) => i.id === order.invoiceId) || {}
      ).number,

      // counterparty association
      counterpartyName: (
        this.state.counterparties.find((c) => c.id === order.counterpartyId) ||
        {}
      ).name,
    };
  };
}

OrderContainer.propTypes = {
  open: PropTypes.bool,
  order: PropTypes.object,
  orderHistory: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);
