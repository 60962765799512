import React, {useCallback, useEffect, useState} from 'react';
import AndileTable from 'components/AndileMaterialUITable/AndileTable';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  MdExpandLess,
  MdExpandMore,
  MdFileDownload,
  MdFolder,
  MdViewColumn,
  MdRemoveRedEye as ViewDetailsIcon, MdRestore,
  MdDelete,
  MdUnarchive,
} from 'react-icons/all';
import {connect} from 'react-redux';
import {CurrencyPair} from 'popcorn-js/financial/currencyPair/currencyPairTypes';
import {Company} from 'popcorn-js/legalEntity/party'

import {useService} from 'hooks/useService';
import Handler from 'popcorn-js/trade/handler';
import TradeRecordkeeper from 'popcorn-js/trade/recordkeeper'
import TradeExporter from 'popcorn-js/financial/fx/trade/exporter'
import saveAs from 'file-saver';


import DetailDialog from 'views/TradeStation/DetailDialog'


import {DATE_CRITERION, NUMBER_CRITERION, TEXT_CRITERION} from 'popcorn-js/search/criteria/types';
import {ImportExport, LegAsTrade, Trade, TradeDirection, TradeStatus, TradeType} from 'popcorn-js/trade/tradeTypes';
import {processUnixDateForViewing} from 'utils/Utils';
import {FormatBoolean, FormatNumber} from 'utils/TradeUtilities';
import FilterListIcon from '@material-ui/icons/FilterList';
import {BackIcon} from 'components/Icons';
import {debounce} from 'lodash';
import TradeHistoryLayoutContainer from '../History/TradeHistory/TradeHistoryLayoutContainer';
import ErrorIcon from 'components/Icons/ErrorIcon/ErrorIcon';
import NotificationSweetAlert from '../../components/SweetAlert/NotificationSweetAlert';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) =>
  createStyles({
    rootTable: {
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '8px',
      marginBottom: 0,
    },
    cardContentRoot: {
      padding: 0,
      gridTemplateColumns: 'auto auto 1fr auto',
      '&:last-child': {
        padding: 0,
      },
    },
    headerRoot: {
      backgroundColor: theme.palette.primary.medium,
      padding: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.75),
      height: '52px',
      color: theme.palette.primary.contrastText,
    },
  }),
);

interface Props {
  myParty: Company,
  currencyPairs: CurrencyPair[],
  extendedContext: any,
  childCompanies: any,
  partyType: any,
}

const mapStateToProps = (state: any) => {
  return {
    currencyPairs: state.currencyPair.records,

  }
}

const tradeStatuses = [
  {
    value: TradeStatus.OPEN,
    label: TradeStatus.OPEN,
  }, {
    value: TradeStatus.MATURED,
    label: TradeStatus.MATURED,
  },
]
const tradeDirections = [
  {
    value: TradeDirection.BUY,
    label: TradeDirection.BUY,
  }, {
    value: TradeDirection.SELL,
    label: TradeDirection.SELL,
  },
]

const tradeTypesForColumn = [
  {value: TradeType.SPOT},
  {value: TradeType.FORWARD},
  {value: TradeType.EXTENSION},
  {value: TradeType.CANCELLATION},
  {value: TradeType.DRAWDOWN},
]
const importExportOptions = [
  {value: ImportExport.Import},
  {value: ImportExport.Export},
]
const finYearOptions = [
  {
    value: 'CURRENT',
    label: 'CURRENT',
  },
  {
    value: '2020',
    label: '2020',
  },
  {
    value: '2021',
    label: '2021',
  },
]

const LegsAsTradesTable: React.FC<Props> = (props: Props) => {
  const {currencyPairs, myParty} = props
  const classes = useStyles(useTheme())

  const [tradesTotal, setTradesTotal] = useState<number>(0)
  const [tradesResponse, setTradesResponse] = useState<LegAsTrade[] | undefined>()
  const [fullTrade, setFullTrade] = useState<Trade>()
  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false)
  const [showTradeHistoryDialog, setShowTradeHistoryDialog] = useState<boolean>(false)
  const [detailOrHistory, setDetailOrHistory] = useState<string>()
  const [cardTitle, setCardTitle] = useState<string>('Full List of Trades')
  const [query, setQuery] = useState<any>({
    sortBy: ['tradeDate', 'currencyPair.name', 'status', 'internalReference'],
    order: ['desc', 'asc', 'desc', 'asc'],
    limit: 17,
    offset: 0,
  })
  const [criteria, setCriteria] = useState<any>(
    // Remove empty legs from result i.e. Cancellation on Extension that has dummy near leg
    [
      {
        type: NUMBER_CRITERION,
        value: {
          field: 'allInRate',
          lessThan: {
            ignore: true
          },
          moreThan: {
            amount: 0,
            inclusive: false,
          }
        },
      },
      {
        type: TEXT_CRITERION,
        value: {
          field: 'financialYear',
          text: 'CURRENT',
        },
      },
    ]
  )
  const [open, setOpen] = useState<boolean>(true)
  const [showFilterRow, setShowFilterRow] = useState<boolean>(false)
  const [colConfigOpen, setColConfigOpen] = useState<boolean>(false)
  const [deleted, setDeleted] = React.useState<boolean>(false)
  const [selected, setSelected] = React.useState<LegAsTrade[]>([])
  const [additionalActions, setAdditionalActions] = React.useState<any>([])

  const [loadErrorMessage, setLoadErrorMessage] = useState<string | undefined>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [warningMessage, setWarningMessage] = useState<string | undefined>()
  const [successMessage, setSuccessMessage] = useState<string | undefined>()
  const [confirmationMethod, setConfirmationMethod] = useState<any>()

  const generateFindTradesRequest = useCallback(() => {

    return {
      criteria: criteria,
      query: query,
      deleted: deleted,
    }
  }, [query, criteria, deleted])
  const [
    {
      response: findTradesResponse,
      loading: findTradesLoading,
      error: findTradesError,
    },
    setFindTradesRequest
  ] = useService(generateFindTradesRequest(), Handler.FindAllLegsAsTrades)

  useEffect(() => {
    setFindTradesRequest(generateFindTradesRequest())
  }, [generateFindTradesRequest, setFindTradesRequest, query, criteria])

  useEffect(() => {
    if (findTradesResponse && findTradesResponse.Records && !findTradesLoading) {
      setTradesTotal(findTradesResponse.Total)
      setTradesResponse(findTradesResponse.Records)
    }
    if (findTradesError) {
      setLoadErrorMessage(findTradesError)
    }
  }, [findTradesResponse, findTradesLoading, findTradesError])


  const handleChangePage = (event: any, newPage: any) => {
    const offset = query.limit * newPage
    const newQuery = {
      ...query,
      offset
    }
    setQuery(newQuery)
  }

  const handleChangeRowsPerPage = (event: any) => {
    const rowsPerPage = event.target.value
    const newQuery = {
      sortBy: [],
      order: [],
      limit: rowsPerPage,
      offset: 0
    }
    setQuery(newQuery)
  }

  const handleHideAlert = () => {
    setErrorMessage(undefined)
    setSuccessMessage(undefined)
    setWarningMessage(undefined)
    setConfirmationMethod(undefined)
  }

  const handleChangeSorting = (sortBy: any, order: any) => {
    const newQuery = {
      ...query,
      sortBy: [sortBy],
      order: [order],
    }
    setQuery(newQuery)
  }

  const handleFilterChange = useCallback(debounce((newCrit: any) => {
    const newQuery = {
      ...query,
      offset: 0
    }
    if (newCrit) {
      const allInRateCrit = newCrit.filter((f: any) => f.value.field === 'allInRate')
      if (allInRateCrit.length > 0) {
        if (allInRateCrit[0].value.moreThan.amount <= 0) {
          allInRateCrit.forEach((f: any) => f.value.moreThan =
            {
              amount: 0,
              inclusive: false,
              ignore: false,
            })
        }
      } else {
        // Remove empty legs from result i.e. Cancellation on Extension that has dummy near leg
        newCrit.push({
          type: NUMBER_CRITERION,
          value: {
            field: 'allInRate',
            lessThan: {
              ignore: true
            },
            moreThan: {
              amount: 0,
              inclusive: false,
            }
          },
        })
      }
      const financialYearCrit = newCrit.filter((f: any) => f.value.field === 'financialYear')
      if (financialYearCrit.length === 0) {
        newCrit.push({
          type: TEXT_CRITERION,
          value: {
            field: 'financialYear',
            text: 'CURRENT',
          }
        })
      }
      setQuery(newQuery)
      setCriteria(newCrit)
    }
  }, 300), [query])

  const [
    {
      response: findFullTradeResponse,
      loading: findFullTradeLoading,
    },
    setFindFullTradeRequest
  ] = useService(null, TradeRecordkeeper.retrieve)


  const handleOpenDetailDialog = (rowData: LegAsTrade) => {
    if (rowData.tradeId) {
      setFindFullTradeRequest({
        identifier: {id: rowData.tradeId},
        deleted: deleted
      })
    } else {
      setFindFullTradeRequest({
        identifier: {id: selected[0].tradeId},
        deleted: deleted
      })
    }
    setDetailOrHistory('Detail')
  }
  const handleOpenTradeHistory = (rowData: LegAsTrade) => {
    if (rowData.tradeId) {
      setFindFullTradeRequest({
        identifier: {id: rowData.tradeId},
        deleted: deleted
      })
    } else {
      setFindFullTradeRequest({
        identifier: {id: selected[0].tradeId},
        deleted: deleted
      })
    }
    setDetailOrHistory('History')
  }

  const handleSelectRow = (rowData: LegAsTrade) => {
    const ind = selected.findIndex((selectedTrd: LegAsTrade) => selectedTrd.id === rowData.id)
    const newSelected = [...selected]
    if (ind === -1) {
      newSelected.push(rowData)
    } else {
      newSelected.splice(ind, 1)
    }
    let newAdditionalActions = [...additionalActions]
    if (newSelected.length === 1) {

    } else {
      newAdditionalActions = []
    }
    setSelected(newSelected)
    setAdditionalActions(newAdditionalActions)
  }

  const handleSelectAll = () => {
    const newSelected = [...selected]
    if (newSelected.length !== 0) {
      setSelected([])
    } else if (tradesResponse) {
      tradesResponse.forEach((trade: LegAsTrade) => {
          newSelected.push(trade)
        }
      )
      setSelected(newSelected)
    }
  }
  const handleCloseDialog = () => {
    setDetailDialogOpen(false)
  }
  const handleCloseColConfig = () => {
    setColConfigOpen(false)
  }

  useEffect(() => {
    if (findFullTradeResponse && findFullTradeResponse.trade && !findFullTradeLoading) {
      setFullTrade(findFullTradeResponse.trade)
      if (detailOrHistory === 'Detail') {
        setDetailDialogOpen(true)
      }
      if (detailOrHistory === 'History') {
        setShowTradeHistoryDialog(true)
      }
    }
  }, [findFullTradeResponse, findFullTradeLoading, detailOrHistory])


  const [
    {
      response: exportTradesResponse,
      loading: exportTradesLoading,
      error: exportTradesError,
    },
    setExportTradesRequest
  ] = useService(null, TradeExporter.exportTrades)

  const handleExportTrades = () => {
    setExportTradesRequest(generateFindTradesRequest())
  }

  useEffect(() => {
    if (exportTradesResponse && exportTradesResponse.data && !exportTradesLoading) {
      // convert base64 to byte array
      const binData = atob(exportTradesResponse.data)
      const bytes = new Array(binData.length)
      for (let i = 0; i < binData.length; i++) {
        bytes[i] = binData.charCodeAt(i)
      }

      const blob = new Blob([new Uint8Array(bytes)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8'
      })
      const date = new Date();
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = date.getFullYear();

      const today = yyyy + '-' + mm + '-' + dd
      // @ts-ignore
      saveAs(blob, 'Trade Export ' + today + '.xlsx')
    }
    if (exportTradesError) {
      setErrorMessage(exportTradesError)
    }
  }, [exportTradesResponse, exportTradesLoading, exportTradesError])

  const [
    {
      response: deleteTradeResponse,
      loading: deleteTradeLoading,
      error: deleteTradeError,
    },
    setDeleteTradesRequest
  ] = useService(null, Handler.Delete)


  useEffect(() => {
    if (deleteTradeResponse && !deleteTradeLoading) {
      setSuccessMessage('Trade deleted')
    }
    if (deleteTradeError) {
      setErrorMessage(deleteTradeError)
    }
  }, [deleteTradeResponse, deleteTradeLoading, deleteTradeError])

  const [
    {
      response: restoreTradeResponse,
      loading: restoreTradeLoading,
      error: restoreTradeError,
    },
    setRestoreTradeRequest
  ] = useService(null, Handler.Restore)

  useEffect(() => {
    if (restoreTradeResponse && !restoreTradeLoading) {
      setSuccessMessage('Trade restored')
    }
    if (restoreTradeError) {
      setErrorMessage(restoreTradeError)
    }
  }, [restoreTradeResponse, restoreTradeLoading, restoreTradeError])

  return <div id="tradeStationRoot">
    {detailDialogOpen &&
    <DetailDialog
      // @ts-ignore
      closeDialog={handleCloseDialog}
      currencyPairs={currencyPairs}
      open={detailDialogOpen}
      party={myParty}
      processingBanks={[]}
      // @ts-ignore
      trade={fullTrade}
    />}
    {showTradeHistoryDialog &&
    <TradeHistoryLayoutContainer
      onHide={() => setShowTradeHistoryDialog(false)}
      open={showTradeHistoryDialog}
      trade={fullTrade}
    />
    }
    <Card
      className={classes.rootTable}
      square={false}
    >
      <CardHeader
        action={
          <React.Fragment>
            {open
              ? (
                <React.Fragment>
                  {deleted && <Tooltip title="Exit Deleted">
                    <IconButton
                      aria-label="exitDeleted"
                      onClick={
                        () => {
                          setQuery({
                            ...query,
                            offset: 0,
                          })
                          setDeleted(false)
                          setCardTitle('Full List of Trades')
                        }
                      }
                    >
                      <BackIcon/>
                    </IconButton>
                  </Tooltip>}
                  {(selected.length === 1) && !deleted &&
                  <Tooltip
                    key={'Delete'}
                    title={'Delete'}
                  >
                    <IconButton
                      aria-label={'Delete'}
                      onClick={() => {
                        setWarningMessage(`You are about to delete '${selected[0].number}'. Do you want to continue?`)
                        setConfirmationMethod(() => {
                          setWarningMessage(undefined)
                          setConfirmationMethod(undefined)
                          setDeleteTradesRequest(selected[0].tradeId)
                        })
                      }}
                    >
                      <MdDelete/>
                    </IconButton>
                  </Tooltip>}
                  {(selected.length === 1) && deleted &&
                  <Tooltip
                    key={'Restore'}
                    title={'Restore'}
                  >
                    <IconButton
                      aria-label={'Restore'}
                      onClick={() => {
                        setWarningMessage(`You are about to restore '${selected[0].number}'. Do you want to continue?`)
                        setConfirmationMethod(() => {
                          setWarningMessage(undefined)
                          setConfirmationMethod(undefined)
                          setRestoreTradeRequest(selected[0].tradeId)
                        })
                      }}
                    >
                      <MdUnarchive/>
                    </IconButton>
                  </Tooltip>}
                  {(selected.length === 1) &&
                  <Tooltip
                    key={'ViewDetails'}
                    title={'View Details'}
                  >
                    <IconButton
                      aria-label={'ViewDetails'}
                      onClick={(selected: any) => handleOpenDetailDialog(selected)}
                    >
                      <ViewDetailsIcon/>
                    </IconButton>
                  </Tooltip>}
                  {(selected.length === 1) && <Tooltip
                    key={'ViewHistory'}
                    title={'View History'}
                  >
                    <IconButton
                      aria-label={'ViewHistory'}
                      onClick={(selected: any) => handleOpenTradeHistory(selected)}
                    >
                      <MdRestore/>
                    </IconButton>
                  </Tooltip>}
                  <Tooltip
                    key={'filterList'}
                    title="Filter list"
                  >
                    <IconButton
                      aria-label="filter list"
                      onClick={() => {
                        handleFilterChange([])
                        setShowFilterRow(!showFilterRow)
                      }}
                    >
                      <FilterListIcon/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Open column configuration">
                    <IconButton
                      aria-label="view column"
                      onClick={() => setColConfigOpen(!colConfigOpen)}
                    >
                      <MdViewColumn/>
                    </IconButton>
                  </Tooltip>
                  {!deleted && <Tooltip title="View Deleted Items">
                    <IconButton
                      aria-label="viewArchive"
                      onClick={() => {
                        setQuery({
                          ...query,
                          offset: 0,
                        })
                        setDeleted(true)
                        setCardTitle('Full List of Deleted Trades')

                      }}
                    >
                      <MdFolder/>
                    </IconButton>
                  </Tooltip>}
                  <Tooltip title="Export File">
                    <IconButton
                      aria-label="exportFile"
                      onClick={() => {
                        handleExportTrades()
                      }}
                    >
                      <MdFileDownload/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Collapse'}>
                    <IconButton
                      onClick={() => setOpen(!open)}
                    >
                      <MdExpandLess/>
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )
              : (
                <Tooltip title={'Expand'}>
                  <IconButton
                    onClick={() => setOpen(!open)}
                  >
                    <MdExpandMore/>
                  </IconButton>
                </Tooltip>
              )
            }
          </React.Fragment>
        }
        classes={{root: classes.headerRoot}}
        title={
          <Typography
            variant={'h5'}
          >
            {cardTitle}
          </Typography>
        }
      />
      <Collapse in={open}>
        <CardContent
          classes={{root: classes.cardContentRoot}}
        >
          {((): React.ReactNode => {
            if (loadErrorMessage) {
              return (
                <div>
                  <ErrorIcon/>
                  <div style={{textAlign: 'center'}}>
                    {loadErrorMessage}
                  </div>
                </div>
              )
            } else {
              return (
                <AndileTable
                  // @ts-ignore
                  colConfigCloseFromCard={handleCloseColConfig}
                  colConfigOpenFromCard={colConfigOpen}
                  columns={[
                    {
                      title: 'Trade Number',
                      field: 'number',
                      filter: {type: TEXT_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.number) {
                          return rowData.number
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Internal Reference',
                      field: 'internalReference',
                      filter: {type: TEXT_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.internalReference) {
                          return rowData.internalReference
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'External Reference',
                      field: 'externalReference',
                      filter: {type: TEXT_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.externalReference) {
                          return rowData.externalReference
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Trade Type', field: 'tradeType',
                      filter:
                        {
                          type: TEXT_CRITERION,
                          options: tradeTypesForColumn,
                          displayAccessor: 'value',
                          valueAccessor: 'value',
                        },
                      render: (rowData: LegAsTrade) => {
                        if (rowData.tradeType) {
                          return rowData.tradeType
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Direction', field: 'direction',
                      filter: {
                        type: TEXT_CRITERION,
                        displayAccessor: 'value',
                        valueAccessor: 'value',
                        options: tradeDirections,
                      },
                      render: (rowData: LegAsTrade) => {
                        if (rowData.direction) {
                          return rowData.direction
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Import/Export', field: 'importExport',
                      filter: {
                        type: TEXT_CRITERION,
                        displayAccessor: 'value',
                        valueAccessor: 'value',
                        options: importExportOptions,
                      },
                      render: (rowData: LegAsTrade) => {
                        if (rowData.importExport) {
                          return rowData.importExport
                        } else {
                          return '-'
                        }
                      },
                    },

                    {
                      title: 'Currency Pair',
                      field: 'currencyPair.name',
                      disableSort: true,
                      filter:
                        {
                          options: currencyPairs,
                          displayAccessor: 'name',
                          valueAccessor: 'name',
                          type: TEXT_CRITERION
                        },
                      render: (rowData: LegAsTrade) => {
                        if (rowData.currencyPair.name) {
                          return rowData.currencyPair.name
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Status', field: 'status',
                      filter:
                        {
                          options: tradeStatuses,
                          displayAccessor: 'value',
                          valueAccessor: 'value',
                          type: TEXT_CRITERION
                        },
                      render: (rowData: LegAsTrade) => {
                        if (rowData.status) {
                          return rowData.status
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Trade Date',
                      field: 'tradeDate',
                      render: (rowData: LegAsTrade) => {
                        if (rowData.tradeDate) {
                          return processUnixDateForViewing(rowData.tradeDate)
                        } else {
                          return '-'
                        }
                      },
                      filter: {type: DATE_CRITERION}
                    },
                    {
                      title: 'Notional Amount', field: 'notionalAmount',
                      filter: {type: NUMBER_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.notionalAmount) {
                          return FormatNumber(rowData.notionalAmount.toString(), true, true, 2, true)
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Maturity Date',
                      field: 'maturityDate',
                      render: (rowData: LegAsTrade) => {
                        if (rowData.maturityDate) {
                          return processUnixDateForViewing(rowData.maturityDate)
                        } else {
                          return '-'
                        }
                      },
                      filter: {type: DATE_CRITERION}
                    },

                    {
                      title: 'All-In Rate', field: 'allInRate', filter: {type: NUMBER_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.allInRate) {
                          return FormatNumber(rowData.allInRate.toString(), true, true, 4, true)
                        } else {
                          return '-'
                        }
                      },
                    },

                    {
                      title: 'Effective Rate', field: 'effectiveRate', filter: {type: NUMBER_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.effectiveRate) {
                          return FormatNumber(rowData.effectiveRate.toString(), true, true, 4, true)
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Bank',
                      field: 'bank',
                      filter: {type: TEXT_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.bank) {
                          return rowData.bank
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Trader',
                      field: 'trader',
                      filter: {type: TEXT_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.trader) {
                          return rowData.trader
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Trader Organisation',
                      field: 'traderOrganisation',
                      filter: {type: TEXT_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.traderOrganisation) {
                          return rowData.traderOrganisation
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Bank Rate', field: 'bankRate', filter: {type: NUMBER_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.bankRate) {
                          return FormatNumber(rowData.bankRate.toString(), true, true, 4, true)
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Interbank Rate', field: 'interbankRate', filter: {type: NUMBER_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.interbankRate) {
                          return FormatNumber(rowData.interbankRate.toString(), true, true, 4, true)
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Quote Amount', field: 'quoteAmount', filter: {type: NUMBER_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.quoteAmount) {
                          return FormatNumber(rowData.quoteAmount.toString(), true, true, 4, true)
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Spot Price', field: 'spotPrice', filter: {type: NUMBER_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.spotPrice) {
                          return FormatNumber(rowData.spotPrice.toString(), true, true, 4, true)
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Forward Points', field: 'forwardPoiYents', filter: {type: NUMBER_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.forwardPoints) {
                          return FormatNumber(rowData.forwardPoints.toString(), true, true, 4, true)
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'ACM Trade', field: 'ACM',
                      render: (rowData: LegAsTrade) => {
                        return FormatBoolean(rowData.ACM)

                      }
                    },
                    {
                      title: 'ACM Balance', field: 'acmBalance', filter: {type: NUMBER_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.acmBalance) {
                          return FormatNumber(rowData.acmBalance.toString(), true, true, 4, true)
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'Captured Spot Rate', field: 'capturedSpotRate', filter: {type: NUMBER_CRITERION},
                      render: (rowData: LegAsTrade) => {
                        if (rowData.bankRate) {
                          return FormatNumber(rowData.bankRate.toString(), true, true, 4, true)
                        } else {
                          return '-'
                        }
                      },
                    },
                    {
                      title: 'FinancialYear',
                      field: 'financialYear',
                      filter: {
                        options: finYearOptions,
                        displayAccessor: 'value',
                        valueAccessor: 'value',
                        type: TEXT_CRITERION
                      },
                      render: (rowData: LegAsTrade) => {
                        if (rowData.financialYear) {
                          return rowData.financialYear
                        } else {
                          return '-'
                        }
                      },
                    },

                  ]}
                  count={tradesTotal}
                  data={tradesResponse || []}
                  defaultColConfig={[
                    {header: 'Trade Number', visible: true},
                    {header: 'Internal Reference', visible: true},
                    {header: 'External Reference', visible: true},
                    {header: 'Trade Type', visible: true},
                    {header: 'Direction', visible: true},
                    {header: 'Import/Export', visible: true},
                    {header: 'Currency Pair', visible: true},
                    {header: 'Status', visible: true},
                    {header: 'Trade Date', visible: true},
                    {header: 'Notional Amount', visible: true},
                    {header: 'Maturity Date', visible: true},
                    {header: 'All-In Rate', visible: true},
                    {header: 'Effective Rate', visible: true},
                    {header: 'Bank', visible: false},
                    {header: 'Bank Rate', visible: false},
                    {header: 'Interbank Rate', visible: false},
                    {header: 'Quote Amount', visible: false},
                    {header: 'Spot Price', visible: false},
                    {header: 'Forward Points', visible: false},
                    {header: 'ACM Trade', visible: false},
                    {header: 'ACM Balance', visible: false},
                    {header: 'Trader', visible: false},
                    {header: 'Trader Organisation', visible: false},
                    {header: 'Captured Spot Rate', visible: false},
                    {header: 'FinancialYear', visible: false},


                  ]}
                  disableHeader
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  loading={findTradesLoading}
                  maxTableHeight={700}
                  onChangeSorting={handleChangeSorting}
                  onFilterChange={handleFilterChange}
                  onRowCheck={handleSelectRow}
                  onSelectAll={handleSelectAll}
                  order={query.order.length > 0 ? query.order[0] : undefined}
                  page={Math.ceil(query.offset / query.limit)}
                  rowClickAction={handleSelectRow}
                  rowDoubleClickAction={handleOpenDetailDialog}
                  rowsPerPage={query.limit}
                  rowsPerPageOptions={[5, 10, 17, 20, 25, 30]}
                  selected={selected}
                  showCheckboxes
                  showFilterRowFromCard={showFilterRow}
                  sortBy={query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                  tableID={'TradesTable'}
                  title={'Trades'}
                />
              )
            }
          })()}
          <NotificationSweetAlert
            customClass={'RecordCancellationTicketDetailDialog'}
            errorMessage={errorMessage}
            onClose={handleHideAlert}
            onConfirm={confirmationMethod}
            successMessage={successMessage}
            warningMessage={warningMessage}
          />
        </CardContent>
      </Collapse>
    </Card>
  </div>

}

export default connect(mapStateToProps)(LegsAsTradesTable)