import React from 'react'
import {
  Button, Tabs, Tab, AppBar, TextField, Grid, Card,
  FormHelperText, CardHeader, CardContent, Typography,
  FormControl, InputLabel, Select, MenuItem,
  withStyles,
} from '@material-ui/core'
import Table from 'components/Table'
import PropTypes from 'prop-types'
import CurrencyPairEntity from 'popcorn-js/financial/currencyPair/CurrencyPair'
import CurrencyEntity from 'popcorn-js/financial/currency/Currency'
import Recordkeeper from 'popcorn-js/financial/currencyPair/recordkeeper'

//popcorn-js
//popcorn-js
import {
  CurrencyRecordkeeper
} from 'popcorn-js/financial/currency'

import {
  FilterToSortBy,
  SortObjects,
  SortByToFilter,
  SortNumberString,
} from 'utils/Utils'
import Validator from 'popcorn-js/financial/currencyPair/validator'
import Comparator from 'popcorn-js/financial/currencyPair/comparator'
import CardActions from '@material-ui/core/CardActions/CardActions'
import {FullPageLoader as Loader} from 'components/Loader/Loader'
import HistoryLayout from 'views/History/HistoryLayout'
import {CurrencyPairFields} from 'views/History/CurrencyPairHistory/CurrencyPairFields'
import NotificationSweetAlert
  from 'components/SweetAlert/NotificationSweetAlert'

const ACTIVE_STATE_VIEWING = 'ACTIVE_STATE_VIEWING'
const ACTIVE_STATE_CREATING = 'ACTIVE_STATE_CREATING'
const ACTIVE_STATE_EDITING = 'ACTIVE_STATE_EDITING'
const ACTIVE_STATE_VIEWING_DELETE = 'ACTIVE_STATE_VIEWING_DELETE'

const currencyPairListLength = 10
const currencyPairConfigurationListId = `${CurrencyEntity.camel}ConfigurationList`

const styles = () => ({
  tabContainer: {
    height: 'calc(100vh - 295px)',
    marginTop: '8px',
    overflowY: 'scroll',
  },
})

class CurrencyPair extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeState: ACTIVE_STATE_VIEWING,

      currencyPairs: [],
      total: 0,
      selectedRowIndex: -1,
      previouslySelectedRowIndex: -1,
      sortBy: ['name'],

      history: [],
      selected: new CurrencyPairEntity(),
      original: new CurrencyPairEntity(),
      previouslySelected: new CurrencyPairEntity(),

      isLoading: true,

      showHistory: false,

      successMessage: undefined,
      errorMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,

      invalidFields: {},

      tabIndex: 0,

      currencies: [],

      pips: [{points: 100}, {points: 1000}, {points: 10000}],
    }
  }

  componentDidMount() {
    this.find()
    this.currencyFind()
  }

  handleTabChange = (event, tabIndex) => {
    this.setState({tabIndex: tabIndex})
  }

  handleSelection = (selected, index, activeState) => {
    if (this.state.activeState === ACTIVE_STATE_EDITING && !activeState) {
      this.showDiscardConfirmation(() => {
        this.handleHideAlert()
        this.handleSelection(selected, index, ACTIVE_STATE_VIEWING)
      })
      return
    }


    let t = activeState
    const {
      activeState: currentState,
    } = this.state
    if (!activeState) {
      switch (currentState) {
        case ACTIVE_STATE_CREATING:
        case ACTIVE_STATE_EDITING:
          t = ACTIVE_STATE_VIEWING
          break
        default:
          t = currentState
      }
    }

    this.setState({
      selected: new CurrencyPairEntity(selected),
      original: new CurrencyPairEntity(selected),
      previouslySelected: new CurrencyPairEntity(selected),

      selectedRowIndex: index,
      previouslySelectedRowIndex: index,

      activeState: t,
    })
  }

  currencyFind = async () => {
    this.setState({isLoading: true})
    try {
      const findResponse = await CurrencyRecordkeeper.find({})
      this.setState({
        currencies: findResponse.records.map(
          ccyPair => new CurrencyEntity(ccyPair)
        )
      })
    } catch (e) {
      console.error('error finding currencies')
    }
    this.setState({isLoading: false})
  }

  find = async (deleted = false) => {
    const {
      sortBy,
    } = this.state

    const query = {sortBy}

    this.setState({isLoading: true})
    try {
      const findResponse = await Recordkeeper.find(
        undefined,
        query,
        deleted,
      )
      // Map to currency pair entities
      const currencyPairs = findResponse.records.map(
        ccyPair => new CurrencyPairEntity(ccyPair)
      )
      currencyPairs.sort((a, b) => SortObjects(a, b, sortBy))

      const total = findResponse.total
      const selected = total > 0 ?
        currencyPairs[0] :
        new CurrencyPairEntity()
      const selectedRowIndex = total > 0 ? 0 : -1

      this.handleSelection(
        selected,
        selectedRowIndex,
        deleted
          ? ACTIVE_STATE_VIEWING_DELETE
          : ACTIVE_STATE_VIEWING
      )
      this.setState({total, currencyPairs})
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleViewDelete = () => {
    this.find(true)
  }

  handleRetrieveHistory = async () => {
    this.setState({isLoading: true})
    try {
      const retrieveHistoryResponse = await Recordkeeper.retrieveHistory(
        this.state.selected.id
      )
      this.setState({
        history: retrieveHistoryResponse.history.map(
          c => new CurrencyPairEntity(c),
        ),
        showHistory: true,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleReturn = () => {
    this.find()
  }

  showDeleteConfirmation = () => {
    this.setState({
      warningMessage: `You are about to permanently delete '${this.state.selected.name}'. Do you want to continue?`,
      confirmationMethod: this.handleDelete,
    })
  }

  showDiscardConfirmation = (confirm) => {
    this.setState({
      warningMessage: 'You have unsaved changes. Do you want to continue?',
      confirmationMethod: confirm,
    })
  }

  handleRestore = async () => {
    this.setState({isLoading: true})
    try {
      await Recordkeeper.restore(this.state.selected.id)
      const currencyPairs = this.state.currencyPairs.slice()
      const idx = currencyPairs.findIndex(
        c => c.id === this.state.selected.id
      )
      currencyPairs.splice(idx, 1)

      this.props.syncSystemReduxState()

      if (currencyPairs.length > idx) {
        this.handleSelection(currencyPairs[idx], idx,this.state.activeState)
      } else {
        this.handleSelection(
          idx === 0 ? new CurrencyPairEntity() : currencyPairs[idx - 1],
          idx - 1,this.state.activeState)
      }

      this.setState({
        successMessage: `${CurrencyPairEntity.capital} restored`,
        currencyPairs,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    this.handleHideAlert()
    try {
      await Recordkeeper.deleteForever(this.state.selected.id)
      const currencyPairs = this.state.currencyPairs.slice()
      const idx = currencyPairs.findIndex(
        c => c.id === this.state.selected.id,
      )
      currencyPairs.splice(idx, 1)

      if (currencyPairs.length > idx) {
        this.handleSelection(currencyPairs[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ? new CurrencyPairEntity() : currencyPairs[idx - 1],
          idx - 1)
      }

      this.setState({
        successMessage: `${CurrencyPairEntity.capital} deleted`,
        currencyPairs,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleNew = () => {
    this.setState({
      selected: new CurrencyPairEntity(),
      original: new CurrencyPairEntity(),
      selectedRowIndex: -1,
      activeState: ACTIVE_STATE_CREATING,
    })
  }

  handleUpdate = async () => {
    if (await this.isValid()) {
      this.setState({isLoading: true})
      try {
        const updateResponse = await Recordkeeper.update(
          this.state.selected,
          {id: this.state.selected.id}
        )
        const currencyPairs = this.state.currencyPairs.slice()
        const currencyPairToReplaceIndex = currencyPairs.findIndex(
          c => c.id === updateResponse.currencyPair.id,
        )
        const selected = new CurrencyPairEntity(updateResponse.currencyPair)
        if (currencyPairToReplaceIndex >= 0) {
          currencyPairs[currencyPairToReplaceIndex] = selected
        }
        currencyPairs.sort((a, b) => SortObjects(a, b, this.state.sortBy))
        const selectedIndex = currencyPairs.findIndex(c => c.id === selected.id)

        this.handleSelection(selected, selectedIndex, ACTIVE_STATE_VIEWING)
        this.setState({
          successMessage: `${CurrencyPairEntity.capital} updated`,
          currencyPairs,
        })
        CurrencyPair.scrollToPos(currencyPairToReplaceIndex / currencyPairs.length)
      } catch (e) {
        this.setState({errorMessage: e.message || e})
      }
      this.setState({isLoading: false})
    }
  }

  handleCreate = async () => {
    if (await this.isValid()) {
      this.setState({isLoading: true})
      try {
        const createResponse = await Recordkeeper.create(this.state.selected)
        const newCurrencyPair = new CurrencyPairEntity(createResponse.currencyPair)
        const currencyPairs = this.state.currencyPairs.slice()
        currencyPairs.push(newCurrencyPair)
        currencyPairs.sort(
          (a, b) => SortObjects(a, b, this.state.sortBy),
        )

        const selected = new CurrencyPairEntity(newCurrencyPair)
        const selectedRowIndex = currencyPairs.findIndex(
          c => c.name === selected.name,
        )

        this.props.syncSystemReduxState()

        this.handleSelection(
          selected,
          selectedRowIndex,
          ACTIVE_STATE_VIEWING,
        )

        this.setState({
          successMessage: `${CurrencyPairEntity.capital} created`,
          currencyPairs,
        })
        CurrencyPair.scrollToPos(selectedRowIndex / currencyPairs.length)
      } catch (e) {
        this.setState({errorMessage: e.message || e})
      }
      this.setState({isLoading: false})
    }
  }

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    })
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    try {
      await Recordkeeper.delete(this.state.selected.id)
      const currencyPairs = this.state.currencyPairs.slice() || []
      const idx = currencyPairs.findIndex(
        c => c.id === this.state.selected.id
      )
      currencyPairs.splice(idx, 1)

      this.props.syncSystemReduxState()

      if (currencyPairs.length > idx) {
        this.handleSelection(currencyPairs[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ? new CurrencyPairEntity() : currencyPairs[idx - 1],
          idx - 1)
      }

      this.setState({
        successMessage: `${CurrencyPairEntity.capital} deleted`,
        currencyPairs,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleCancelUpdate = () => {
    this.setState({
      invalidFields: {},
      selected: new CurrencyPairEntity(this.state.original),
      activeState: ACTIVE_STATE_VIEWING,
    })
  }

  handleCancelCreate = () => {
    this.setState({
      invalidFields: {},
      selected: new CurrencyPairEntity(
        this.state.previouslySelected),
      original: new CurrencyPairEntity(
        this.state.previouslySelected),
      activeState: ACTIVE_STATE_VIEWING,
      selectedRowIndex: this.state.previouslySelectedRowIndex,
    })
    CurrencyPair.scrollToPos(
      this.state.previouslySelectedRowIndex / this.state.currencyPairs.length)
  }

  handleFieldChange = (field, newValue) => {
    const {
      selected,
      invalidFields,
    } = this.state
    selected[field] = newValue
    invalidFields[field] = undefined

    this.setState({selected, invalidFields})
    this.handleChanges(selected)
  }

  handleChanges = (selected) => {
    const {
      activeState: currentState,
    } = this.state
    const {
      selectedRowIndex,
    } = this.state
    let activeState
    switch (currentState) {
      case ACTIVE_STATE_CREATING:
        activeState = ACTIVE_STATE_CREATING
        break

      case ACTIVE_STATE_VIEWING:
        activeState = selectedRowIndex >= 0
          ? !Comparator.CompareAll(this.state.original, selected)
            ? ACTIVE_STATE_EDITING
            : ACTIVE_STATE_VIEWING
          : ACTIVE_STATE_CREATING
        break

      case ACTIVE_STATE_EDITING:
        activeState = !Comparator.CompareAll(this.state.original, selected)
          ? ACTIVE_STATE_EDITING
          : ACTIVE_STATE_VIEWING
        break

      default:
    }
    this.setState({activeState})
  }

  render() {
    const {
      invalidFields,
      activeState,
      selected,
      currencyPairs,
      currencies,
      selectedRowIndex,
    } = this.state

    const {
      classes
    } = this.props

    return (
      <div id={`${CurrencyPairEntity.camel}ConfigurationRoot`}>
        <Card
          style={{
            marginBottom: '10px',
          }}>
          {this.renderDialogs()}
          <CardHeader
            action={
              <div>
                <Button disabled>Import</Button>
                <Button disabled>Export</Button>
              </div>
            }
            title={
              <Typography
                gutterBottom
                variant="subtitle1">
                {activeState === ACTIVE_STATE_VIEWING_DELETE ?
                  `${CurrencyPairEntity.capitalP} - Delete` :
                  `${CurrencyPairEntity.capitalP}`}
              </Typography>
            }
          />
          <CardContent
            style={{
              padding: '0',
              margin: '0px 24px 24px 24px',
            }}
          >
            <Grid
              container
              direction={'row'}
              spacing={3}>
              <Grid
                item
                lg={4}
                md={6}
                sm={12}>
                <Table
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'name',
                    },
                  ]}
                  data={currencyPairs}
                  defaultSortMethod={(a, b) => SortNumberString(a, b)}
                  defaultSorted={SortByToFilter(this.state.sortBy)}
                  getTbodyProps={() => {
                    return {
                      style: {
                        overflowY: 'scroll',
                        height: '368px',
                        scrollBehavior: 'smooth',
                      },
                      id: currencyPairConfigurationListId,
                    }
                  }}
                  getTdProps={(state, rowInfo) => {
                    const rowIndex = rowInfo ? rowInfo.index : undefined

                    return {
                      onClick: (e, handleOriginal) => {
                        if (rowInfo) {
                          this.handleSelection(
                            new CurrencyPairEntity(rowInfo.original),
                            rowIndex)
                        }
                        if (handleOriginal) {
                          handleOriginal()
                        }
                      },
                      style: {
                        background: rowIndex === selectedRowIndex ?
                          this.props.theme.palette.secondary.light :
                          'white',
                        color: rowIndex === selectedRowIndex ?
                          this.props.theme.palette.secondary.contrastText :
                          'black',
                      },
                    }
                  }}
                  id={`${CurrencyPairEntity.camel}Table`}
                  onSortedChange={(filter) => this.setState(
                    {sortBy: FilterToSortBy(filter)})}
                  pageSize={Math.max(currencyPairListLength,
                    (currencyPairs || []).length)}
                  showPagination={false}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={6}
                sm={12}>
                <AppBar
                  position={'static'}
                >
                  <Tabs
                    onChange={this.handleTabChange}
                    value={this.state.tabIndex}>
                    <Tab
                      id="currencyPairMainTab"
                      label="Main"
                    />
                  </Tabs>
                </AppBar>
                {this.state.tabIndex === 0 &&
                <Grid
                  className={classes.tabContainer}
                  container
                  direction={'column'}>
                  <FormControl
                    disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                    error={!!invalidFields.baseCurrencyId}
                    id="currencyPairBaseCurrency"
                    margin="normal"
                  >
                    <InputLabel
                      htmlFor="base-currency-pair-select"
                    >
                      Base Currency
                    </InputLabel>
                    <Select
                      inputProps={{id: 'base-currency-pair-select'}}
                      onChange={(e) => this.handleFieldChange(
                        'baseCurrencyId', e.target.value)}
                      value={selected.baseCurrencyId}
                    >
                      {currencies.map((ccy, idx) =>
                        <MenuItem
                          className="baseCurrenciesToSelect"
                          key={idx}
                          value={ccy.id}
                        >
                          {ccy.isoCode}
                        </MenuItem>,
                      )}
                    </Select>
                    <FormHelperText>
                      {invalidFields.baseCurrencyId ?
                        `*${invalidFields.baseCurrencyId}` :
                        ' '}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                    error={!!invalidFields.quoteCurrencyId}
                    id="quote-currency-pair-main"
                    margin="normal"
                  >
                    <InputLabel
                      htmlFor="quote-currency-pair-select"
                    >
                      Quote Currency
                    </InputLabel>
                    <Select
                      inputProps={{id: 'quote-currency-pair-select'}}
                      onChange={(e) => this.handleFieldChange(
                        'quoteCurrencyId', e.target.value)}
                      value={selected.quoteCurrencyId}

                    >
                      {currencies.map((ccy, idx) =>
                        <MenuItem
                          className="quoteCurrenciesToSelect"
                          key={idx}
                          value={ccy.id}
                        >
                          {ccy.isoCode}
                        </MenuItem>,
                      )}
                    </Select>
                    <FormHelperText>
                      {invalidFields.quoteCurrencyId ?
                        `*${invalidFields.quoteCurrencyId}` :
                        ' '}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                    error={!!invalidFields.pips}
                    id="pips-currency-pair-main"
                    margin="normal"
                  >
                    <InputLabel
                      htmlFor="pips-select"
                    >
                      Pips
                    </InputLabel>
                    <Select
                      inputProps={{id: 'pips-select'}}
                      onChange={(e) => this.handleFieldChange('pips',
                        e.target.value)}
                      value={selected.pips || ''}
                    >
                      {this.state.pips.map((c, idx) => <MenuItem
                        className="pipsOptionsToSelect"
                        key={idx}
                        value={c.points}>{c.points}</MenuItem>)}
                    </Select>
                    <FormHelperText>
                      {invalidFields.pips ? `*${invalidFields.pips}` : ' '}
                    </FormHelperText>
                  </FormControl>
                  <TextField
                    disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                    error={invalidFields.settlementDays}
                    helperText={invalidFields.settlementDays ?
                      `*${invalidFields.settlementDays}` :
                      ' '}
                    inputProps={{
                      id: 'settlement-days-text-field',
                    }}
                    label={'Spot Days'}
                    margin="normal"
                    onChange={(e) => {
                      const settlementDaysInt = parseInt(
                        (e.target.value || '').replace(/\D/g, ''), 10)
                      if (isNaN(settlementDaysInt)) {
                        this.handleFieldChange('settlementDays', 0)
                      } else {
                        this.handleFieldChange('settlementDays',
                          settlementDaysInt)
                      }
                    }}
                    value={selected.settlementDays ?
                      `T+${selected.settlementDays}` :
                      ''}
                  />
                </Grid>
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {this.renderControls()}
      </div>
    )
  }

  renderControls = () => {
    const {
      selectedRowIndex,
      activeState,
      isLoading,
    } = this.state
    return (
      <CardActions
        style={{
          display: 'block',
        }}
      >
        {activeState === ACTIVE_STATE_VIEWING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyPairEntity.camel}NewButton`}
          onClick={this.handleNew}
          size="medium"
        >
          New
        </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING && selectedRowIndex >= 0 &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyPairEntity.camel}DeleteButton`}
          onClick={this.handleDelete}
          size="medium"
        >
          Delete
        </Button>
        }
        {activeState === ACTIVE_STATE_EDITING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyPairEntity.camel}SaveButton`}
          onClick={this.handleUpdate}
          size="medium"
        >
          Save Changes
        </Button>
        }
        {activeState === ACTIVE_STATE_EDITING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyPairEntity.camel}CancelUpdateButton`}
          onClick={this.handleCancelUpdate}
          size="medium"
        >
          Cancel
        </Button>
        }
        {activeState === ACTIVE_STATE_CREATING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyPairEntity.camel}CreateButton`}
          onClick={this.handleCreate}
          size="medium"
        >
          Save New
        </Button>
        }
        {activeState === ACTIVE_STATE_CREATING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyPairEntity.camel}CancelCreateButton`}
          onClick={this.handleCancelCreate}
          size="medium"
        >
          Cancel
        </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING_DELETE && selectedRowIndex >=
        0 &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyPairEntity.camel}RestoreButton`}
          onClick={this.handleRestore}
          size="medium"
        >
          Restore
        </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING_DELETE && selectedRowIndex >=
        0 &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyPairEntity.camel}DeleteButton`}
          onClick={this.showDeleteConfirmation}
          size="medium"
        >
          Delete
        </Button>
        }
        <span
          style={{
            float: 'right',
          }}
        >
          {activeState === ACTIVE_STATE_VIEWING && selectedRowIndex >= 0 &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${CurrencyPairEntity.camel}RetrieveHistoryButton`}
            onClick={this.handleRetrieveHistory}
            size="medium"
          >
            Show History
          </Button>
          }
          {activeState === ACTIVE_STATE_VIEWING &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${CurrencyPairEntity.camel}ViewDeleteButton`}
            onClick={this.handleViewDelete}
            size="medium"
          >
            View Delete
          </Button>
          }
          {activeState === ACTIVE_STATE_VIEWING_DELETE &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${CurrencyPairEntity.camel}ReturnFromDeleteButton`}
            onClick={this.handleReturn}
            size="medium"
          >
            Return
          </Button>
          }
        </span>

      </CardActions>
    )
  }

  renderDialogs() {
    const {
      isLoading,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      selected,
      showHistory,
      history,
    } = this.state

    return (
      <span>
        <Loader
          color={this.props.theme.palette.primary.main}
          isLoading={isLoading}/>
        {showHistory &&
        <HistoryLayout
          entity={selected}
          entityFields={CurrencyPairFields}
          entityHistory={history}
          entityName={CurrencyPairEntity.capital}
          loading={isLoading}
          onHide={() => this.setState({showHistory: false})}
          open
        />
        }
        <NotificationSweetAlert
          customClass={`${CurrencyPairEntity.camel}ConfigAlert`}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}

          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </span>
    )
  }

  isValid = async () => {
    const {
      selected,
    } = this.state

    try {
      await Validator.ValidateData(selected)
    } catch (invalidFields) {
      if (invalidFields) {
        this.setState({invalidFields})
        return false
      }
    }
    return true
  }

  static scrollToPos(pos) {
    const objDiv = document.getElementById(currencyPairConfigurationListId)
    if (objDiv) {
      objDiv.scrollTop = pos * objDiv.scrollHeight
    }
  }

}

CurrencyPair.propTypes = {
  /**
   * This function will retrieve the system after currencyPairs are deleted,
   * restored or created. This is to ensure that the system in the redux state
   * is up to date with the newly automatically assigned or un-assigned
   * currency pairs.
   */
  classes: PropTypes.object,
  syncSystemReduxState: PropTypes.func.isRequired,
  theme: PropTypes.object,
}

const StyledCurrencyPair = withStyles(styles, {withTheme: true})(CurrencyPair)

export default StyledCurrencyPair
