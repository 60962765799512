import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {Marshal} from 'popcorn-js/search/identifier/Marshaller'
import {ID_IDENTIFIER} from 'popcorn-js/search/identifier/types'

const Handler = {
  createDraft({date, currencyId, partyCode, processingOrgPartyCode, importExport}) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Handler.CreateDraft',
      request: {
        date,
        currencyId,
        partyCode,
        processingOrgPartyCode,
        importExport,
      },
    })
  },

  draftUpdate({settlementInstruction}) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Handler.DraftUpdate',
      request: {settlementInstruction},
    })
  },

  linkUpdate({settlementInstruction}) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Handler.LinkUpdate',
      request: {settlementInstruction},
    })
  },

  submit({id}) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Handler.Submit',
      request: {
        settlementInstructionIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  },

  cancel({id}) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Handler.Cancel',
      request: {
        settlementInstructionIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  },

  complete({id}) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Handler.Complete',
      request: {
        settlementInstructionIdentifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  },
  delete({id}) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Handler.Delete',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
      accessToken: ''
    })
  },
}

export default Handler
