import React from 'react'
import {connect} from 'react-redux'
import CurrencyPair from 'views/Configuration/CurrencyPair/CurrencyPair'
import {bindActionCreators} from 'redux'
import {retrieveMyParty} from 'actions/party'
import {PARTY_TYPE_SYSTEM,} from 'constants/partyTypes'
import {Marshal} from 'popcorn-js/search/identifier/Marshaller'
import {PARTY_CODE_IDENTIFIER} from 'popcorn-js/search/identifier/types'
import {findAllCurrencyPairs} from 'actions/currencyPairs'

class CurrencyPairContainer extends React.Component {
  constructor(props) {
    super(props)

    this.handleSyncSystemReduxState = this.handleSyncSystemReduxState.bind(this)
  }

  handleSyncSystemReduxState() {
    const identifier = Marshal({
      type: PARTY_CODE_IDENTIFIER,
      value: {partyCode: this.props.partyCode},
    })
    this.props.retrieveMyParty(identifier, PARTY_TYPE_SYSTEM)
    this.props.findAllCurrencyPairs()
  }

  render() {
    const {currencies} = this.props
    return (
      <CurrencyPair
        currencies={currencies}
        syncSystemReduxState={this.handleSyncSystemReduxState}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    partyCode: state.security.claims.context.partyCode,
    currencies: state.currency.records,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    retrieveMyParty,
    findAllCurrencyPairs
  }, dispatch)
}


CurrencyPairContainer = connect(mapStateToProps, mapDispatchToProps)(
  CurrencyPairContainer)
export default CurrencyPairContainer
