import { addMinutes, isWeekend, addDays, getUnixTime, parseISO } from 'date-fns'
import Big from 'big.js'
import { CurrencyPair } from 'popcorn-js/financial/currencyPair/currencyPairTypes'
import { ProcessingBank, Company } from 'popcorn-js/legalEntity/party'
import { TradeDirection, TradeType, TradeStatus, TradeParent, TradeLeg, Trade } from 'popcorn-js/trade/tradeTypes'

/**
 * getMidDay sets the time to the middle of the day, defined as 11:59:59 GMT, for
 * the given date.
 * @param date 
 */
export const getMidDay = (date: Date): Date => {
  date.setHours(11)
  date.setMinutes(59)
  date.setSeconds(59)
  date.setMilliseconds(0)
  return addMinutes(date, -date.getTimezoneOffset())
}

export const getMidDayISODateString = (date: Date): string => {
  return getMidDay(date).toISOString()
}

export const getNextTradeDate = (date: Date): Date => {
  const current = getMidDay(date)
  const next = addDays(current, 1)
  if (isWeekend(next)) {
    return getNextTradeDate(next)
  }
  return next
}

export const getPreviousTradeDate = (date: Date): Date => {
  const current = getMidDay(date)
  const previous = addDays(current, -1)
  if (isWeekend(previous)) {
    return getNextTradeDate(previous)
  }
  return previous
}

export const displayAmount = (amount: Big|undefined): string => amount ? new Intl.NumberFormat('en-UK', 
  {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(Number(amount)) : '0.00'

export const displayDate = (date: string|null|undefined): string => {
  const dateParts = date?.split('T')
  return dateParts?.length === 2 ? dateParts[0] : ''
}

interface createDrawdownExtensionTradeProps {
  tradeDate: string|null|undefined;
  // leg-specific details
  externalReference: string|undefined;
  buyAmount: Big|undefined;
  sellAmount: Big|undefined;
  maturityDate: string|null|undefined;
  allInRate: Big|undefined;
  spotRate: Big|undefined;
  bank: ProcessingBank|undefined;
  interbankRate: Big|undefined;
  bankRate: Big|undefined;
  forwardPoints: Big|undefined;
  cancellationExternalReference: string|undefined;
  cancellationBuyAmount: Big|undefined;
  cancellationSellAmount: Big|undefined;
  cancellationMaturityDate: string|null|undefined;
  cancellationAllInRate: Big|undefined;
  cancellationSpotRate: Big|undefined;
  cancellationBank: ProcessingBank|undefined;
  cancellationInterbankRate: Big|undefined;
  cancellationBankRate: Big|undefined;
  cancellationForwardPoints: Big|undefined;
  // other trade details required for recording
  currencyPair: CurrencyPair|undefined;
  party: Company;
  parentPartyCode: string;
  capturedSpotRate: Big|undefined;
  trader: string|undefined;
  traderOrganisation: string|undefined;
  notes: string|undefined;
  tradeParents: TradeParent[];
  direction: TradeDirection;
  tradeType: TradeType;
}
export const createDrawdownTrade = (props: createDrawdownExtensionTradeProps): Trade => {
  const {
    tradeDate,
    maturityDate, 
    allInRate, 
    interbankRate, 
    spotRate, 
    forwardPoints, 
    buyAmount, 
    sellAmount, 
    externalReference, 
    bank, 
    cancellationBank, 
    cancellationMaturityDate, 
    cancellationAllInRate, 
    cancellationInterbankRate, 
    currencyPair, 
    cancellationSpotRate, 
    cancellationForwardPoints, 
    party, 
    parentPartyCode, 
    capturedSpotRate, 
    trader, 
    traderOrganisation, 
    notes, 
    tradeParents, 
    cancellationBuyAmount, 
    cancellationSellAmount, 
    cancellationExternalReference, 
    direction,
    tradeType,
    bankRate,
    cancellationBankRate,

  } = props

  const nearLeg: TradeLeg = {
    externalReference: externalReference,
    bank: bank ? bank.partyCode : '',
    direction,
    notionalAmount: direction === TradeDirection.BUY ? Number(buyAmount) : Number(sellAmount),
    quoteAmount: direction === TradeDirection.BUY ?Number(sellAmount) : Number(buyAmount),
    interbankRate: Number(interbankRate),
    bankRate: Number(bankRate),
    maturityDate: maturityDate ? getUnixTime(parseISO(maturityDate)) : 0,
    tradeDate: tradeDate ? getUnixTime(parseISO(tradeDate)) : 0,
    spotPrice: Number(spotRate),
    forwardPoints: Number(forwardPoints),
    allInRate: Number(allInRate),
  }
  const farLeg: TradeLeg = {
    externalReference: cancellationExternalReference,
    bank: cancellationBank ? cancellationBank.partyCode : '',
    notionalAmount: direction === TradeDirection.SELL ? Number(cancellationBuyAmount) : Number(cancellationSellAmount),
    quoteAmount: direction === TradeDirection.SELL ?Number(cancellationSellAmount) : Number(cancellationBuyAmount),
    direction: direction === TradeDirection.SELL ? TradeDirection.BUY : TradeDirection.SELL,
    interbankRate: Number(cancellationInterbankRate),
    bankRate: Number(cancellationBankRate),
    maturityDate: cancellationMaturityDate ? getUnixTime(parseISO(cancellationMaturityDate)) : 0,
    tradeDate: tradeDate ? getUnixTime(parseISO(tradeDate)) : 0,
    spotPrice: Number(cancellationSpotRate),
    forwardPoints: Number(cancellationForwardPoints),
    allInRate: Number(cancellationAllInRate),
  }
  return {
    tradeType,
    legs: [nearLeg, farLeg],
    trader: trader ? trader : '',
    traderOrganisation: traderOrganisation ? traderOrganisation : '',
    notes: notes ? notes : '',
    date: getUnixTime(new Date()),
    status: TradeStatus.OPEN,
    currencyPairId: currencyPair ? currencyPair.id : '',
    tradingPartyCode: party.partyCode,
    processingOrgPartyCode: parentPartyCode,
    acmBalance: 0,    
    importExport: '',
    tradeParents: tradeParents ? tradeParents : ([] as TradeParent[]),
    capturedSpotRate: capturedSpotRate ? Number(capturedSpotRate) : 0,
    childTradeIds: [],
    acmParents: [],
    financialYear: 'CURRENT',
  }
}
export const createExtensionTrade = (props: createDrawdownExtensionTradeProps): Trade => {
  const {
    tradeDate,
    maturityDate, 
    allInRate, 
    interbankRate, 
    spotRate, 
    forwardPoints, 
    buyAmount, 
    sellAmount, 
    externalReference, 
    bank, 
    cancellationBank,
    cancellationMaturityDate, 
    cancellationAllInRate, 
    cancellationInterbankRate, 
    currencyPair, 
    cancellationSpotRate, 
    cancellationForwardPoints, 
    party, 
    parentPartyCode, 
    capturedSpotRate, 
    trader, 
    traderOrganisation, 
    notes, 
    tradeParents, 
    cancellationBuyAmount, 
    cancellationSellAmount, 
    cancellationExternalReference, 
    direction,
    tradeType,
    bankRate,
    cancellationBankRate,
  } = props

  const nearLeg: TradeLeg = {
    externalReference: cancellationExternalReference,
    bank: cancellationBank ? cancellationBank.partyCode : '',
    notionalAmount: direction === TradeDirection.SELL ? Number(cancellationBuyAmount) : Number(cancellationSellAmount),
    quoteAmount: direction === TradeDirection.SELL ?Number(cancellationSellAmount) : Number(cancellationBuyAmount),
    direction: direction === TradeDirection.SELL ? TradeDirection.BUY : TradeDirection.SELL,
    interbankRate: Number(cancellationInterbankRate),
    bankRate: Number(cancellationBankRate),
    maturityDate: cancellationMaturityDate ? getUnixTime(parseISO(cancellationMaturityDate)) : 0,
    tradeDate: tradeDate ? getUnixTime(parseISO(tradeDate)) : 0,
    spotPrice: Number(cancellationSpotRate),
    forwardPoints: Number(cancellationForwardPoints),
    allInRate: Number(cancellationAllInRate),
  }
  const farLeg: TradeLeg = {
    externalReference: externalReference,
    bank: bank ? bank.partyCode : '',
    direction,
    notionalAmount: direction === TradeDirection.BUY ? Number(buyAmount) : Number(sellAmount),
    quoteAmount: direction === TradeDirection.BUY ? Number(sellAmount) : Number(buyAmount),
    interbankRate: Number(interbankRate),
    bankRate: Number(bankRate),
    maturityDate: maturityDate ? getUnixTime(parseISO(maturityDate)) : 0,
    tradeDate: tradeDate ? getUnixTime(parseISO(tradeDate)) : 0,
    spotPrice: Number(spotRate),
    forwardPoints: Number(forwardPoints),
    allInRate: Number(allInRate),
  }

  return {
    tradeType,
    legs: [nearLeg, farLeg],
    trader: trader ? trader : '',
    traderOrganisation: traderOrganisation ? traderOrganisation : '',
    notes: notes ? notes : '',
    date: getUnixTime(new Date()),
    status: TradeStatus.OPEN,
    currencyPairId: currencyPair ? currencyPair.id : '',
    tradingPartyCode: party.partyCode,
    processingOrgPartyCode: parentPartyCode,
    acmBalance: 0,
    importExport: '',
    tradeParents: tradeParents ? tradeParents : ([] as TradeParent[]),
    capturedSpotRate: capturedSpotRate ? Number(capturedSpotRate) : 0,
    childTradeIds: [],
    acmParents: [],
    financialYear: 'CURRENT',
  }
}