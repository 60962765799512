import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  IconButton,
  makeStyles,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import {MdChevronLeft as LeftIcon, MdChevronRight as RightIcon, MdClear} from 'react-icons/md'
import {ScaleLoader as Spinner} from 'react-spinners'
import {UserManager} from 'popcorn-js/legalEntities/user'
import {HexToRGBA} from 'utils/Utils'

const styles = theme => ({
  primaryAppBar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  progressSpinnerDialog: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  button: {
    top: '50%',
    position: 'fixed',
  },
  currentEntity: {
    backgroundColor: HexToRGBA(theme.palette.background.default, .3),
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(),
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 17,
    marginRight: '0px',
    marginTop: '0px',
  },
  action: {
    marginRight: '0px',
    marginTop: '0px',
  },
})

class HistoryLayout extends React.Component {
  state = {
    index: 0,
    processing: true,
  }

  handleLeftClick() {
    this.setState({index: Math.max(this.state.index - 1, 0)})
  }

  handleRightClick() {
    this.setState({
      index: Math.min(this.state.index + 1,
        this.props.entityHistory.length - 1),
    })
  }

  componentDidMount() {
    const {entity, entityHistory} = this.props
    this.processEntityForViewing(entity, entityHistory)
  }

  componentDidUpdate(prevProps) {
    const {entity, entityHistory} = this.props
    if (prevProps.entityHistory.length !== entityHistory.length) {
      this.processEntityForViewing(entity, entityHistory)
    }
  }

  processEntityForViewing = (entity, entityHistory) => {
    try {
      Promise.all([entity, ...entityHistory].map(e => UserManager.getUserProfileById({userId: e.auditEntry.userId}).then(
        result => {
          e.auditEntry.username = result.displayName
        },
      ).catch(() => {
        console.log('could not retrieve user name')
        e.auditEntry.username = '-'
      }),
      )).then(() => this.forceUpdate(),
      ).catch(
        () => console.log('could not retrieve user name'),
      ).finally(() =>
        this.setState({
          processing: false,
        }),
      )
    } catch (e) {
      console.log('could not retrieve user name')
    }
  }

  render() {
    const {classes, entity, entityFields, entityHistory, entityName, loading, addEntityFieldsProps} = this.props

    if (loading || this.state.processing) {
      return (
        <div>
          <Dialog
            PaperProps={{classes: {root: classes.progressSpinnerDialog}}}
            className={classes.progressSpinnerDialog}
            open={this.props.open}
          >
            <div align="center">
              <Spinner
                color={'Black'}
                isLoading
                style={{position: 'relative'}}
              />
            </div>
          </Dialog>
        </div>)
    }
    if (!entityHistory || entityHistory.length === 0) {
      return (
        <div>
          <Dialog
            onClose={this.props.onHide}
            open={this.props.open}
          >
            <div>
              <Toolbar className={classes.primaryAppBar}>
                <Typography
                  color="inherit"
                  variant="h4">
                  {'No history to show'}
                </Typography>
              </Toolbar>
            </div>
          </Dialog>
        </div>
      )
    }
    const entities = [entity, ...entityHistory]
    const changes = Compare(entities[this.state.index],
      entities[this.state.index + 1])
    return (
      <div>
        <Dialog
          maxWidth={'lg'}
          onClose={this.props.onHide}
          open={this.props.open && !this.props.loading}
          scroll="paper"
        >
          <CardHeader
            action={<Tooltip
              title={'Close'}>
              <IconButton
                onClick={this.props.onHide}>
                <MdClear/>
              </IconButton>
            </Tooltip>}
            classes={{
              root: classes.cardHeader,
              action: classes.action,
              title: classes.cardTitle,
            }}
            title={`${entityName} History`}
          />
          <div
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}>
            <GridContainer>
              <GridItem
                lg={4}
                md={4}
                sm={4}
                xs={4}>
                <Card>
                  <CardContent>
                    {entityFields(entity, FIELD_TYPE_NAME, [], addEntityFieldsProps)}
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem
                lg={1}
                md={1}
                sm={1}
                xs={1}>
                <IconButton
                  className={classes.button}
                  disabled={this.state.index === 0}
                  onClick={() => this.handleLeftClick()}
                >
                  {
                    <Icon>
                      <LeftIcon/>
                    </Icon>
                  }
                </IconButton>
              </GridItem>
              <GridItem
                lg={3}
                md={3}
                sm={3}
                xs={3}>
                <Card>
                  <CardContent
                    className={this.state.index === 0 ?
                      classes.currentEntity :
                      ''}>
                    {entityFields(entities[this.state.index],
                      FIELD_TYPE_VALUE, changes, addEntityFieldsProps)}
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem
                lg={3}
                md={3}
                sm={3}
                xs={3}>
                <Card>
                  <CardContent>
                    {entityFields(entities[this.state.index + 1],
                      FIELD_TYPE_VALUE, changes, addEntityFieldsProps)}
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem
                lg={1}
                md={1}
                sm={1}
                xs={1}>
                <IconButton
                  className={classes.button}
                  disabled={this.state.index === entityHistory.length - 1}
                  onClick={() => this.handleRightClick()}
                >
                  {<RightIcon/>}
                </IconButton>
              </GridItem>
            </GridContainer>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(styles)(HistoryLayout)

export const FIELD_TYPE_NAME = 'FIELD_TYPE_NAME'
export const FIELD_TYPE_VALUE = 'FIELD_TYPE_VALUE'

export const Header = (props) => {
  if (props.fieldType === FIELD_TYPE_NAME) {
    return (<div
      style={{marginLeft: props.subheader ? '20px' : ''}}>{props.label}</div>)
  } else {
    return <div/>
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    height: props => props.textArea ? '' : '31px',
    display: 'grid',
    marginLeft: '5px',
    fontWeight: props => props.changed ? 'bold' : 'normal',
    backgroundColor: props => props.changed ? theme.palette.primary.dark : 'transparent',
    zIndex: '1',
  },
  textField: {
    color: props => props.changed ? theme.palette.text.accent : theme.palette.text.body,
  },
}))

export const ItemEntry = (props) => {
  const classes = useStyles(props)
  if (props.fieldType === FIELD_TYPE_VALUE) {
    if (props.nestedArray) {
      return (
        <div className={classes.root}>
          <ExpansionPanel>
            <ExpansionPanelSummary style={{height: '32px', minHeight: '32px'}}>
              {props.label}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{
                maxHeight: '210px',
                overflow: 'scroll',
                paddingBottom: '20px',
              }}>
              {BuildDropDownFromArray(0, props.value, props.nestedArrayFieldsToIgnore)}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )
    }
    return (
      <div className={classes.root}>
        <TextField
          InputProps={{
            classes: {
              disabled: classes.textField,
            },
          }}
          disabled
          error={props.error}
          onChange={props.onChange}
          value={{}.toString.apply(props.value) === '[object Array]' ?
            props.value.join(', ') :
            props.value}
        />
      </div>
    )
  } else {
    return (
      <div
        style={{
          height: props.textArea ? '' : '31px',
          display: 'grid',
          gridTemplateColumns: '250px 288px',
          marginLeft: '40px',
          marginRight: '40px',
        }}
      >
        <Typography variant={'subtitle2'}>
          <div>{props.label}</div>
        </Typography>
      </div>
    )
  }
}

ItemEntry.propTypes = {
  fieldType: PropTypes.string,
}

function BuildDropDownFromArray(idx, array, fieldsToIgnore) {
  let i = 0
  const getKey = () => {
    i++
    return `${idx}.${i}`
  }
  if (isArray(array)) {
    return (
      <ul style={{padding: '0'}}>
        {array.map((item, i) => {
          if (isObject(item)) {
            return BuildDropdownFromObject(getKey(), item, fieldsToIgnore)
          }
          if (isArray(item)) {
            return BuildDropDownFromArray(getKey(), item)
          }
          return (
            <li key={getKey()}>
              {item}
            </li>
          )
        })}
      </ul>
    )
  }
}

function BuildDropdownFromObject(idx, object, fieldsToIgnore) {
  let i = 0
  const getKey = () => {
    i++
    return `${idx}.${i}`
  }
  if (isObject(object)) {
    const items = []
    for (const key in object) {
      if (fieldsToIgnore.includes(key)) {
        continue
      }
      if (isObject(object[key])) {
        items.push(
          <li key={getKey()}>
            {BuildDropdownFromObject(object[key])}
          </li>,
        )
      }
      if (isArray(object[key])) {
        items.push(
          <li key={getKey()}>
            <span>{toWords(key)}</span>
            <span>{BuildDropDownFromArray(getKey(), object[key])}</span>
          </li>,
        )
      }
      if (isValue(object[key])) {
        items.push(
          <li key={getKey()}>
            {`${toWords(key)} - ${object[key]}`}
          </li>,
        )
      }
    }
    return (
      <ul
        key={getKey()}
        style={{
          border: '1px solid #dddddd',
          margin: '5px',
        }}
      >
        {items.map(t => t)}
      </ul>
    )
  }
}

function toWords(text) {
  return text.replace(
    /([A-Z]+)/g, ' $1',
  ).replace(
    /([A-Z][a-z])/g, ' $1',
  ).replace(/^./, (match) => match.toUpperCase())
}

function Compare(obj1, obj2, nestedName) {
  if (!obj1 || !obj2) {
    return []
  }
  if (Object.keys(obj1).length === 0 || Object.keys(obj2).length === 0) {
    return []
  }
  let differences = []

  for (const key in obj1) {
    if (isValue(obj1[key])) {
      if (obj1[key] !== obj2[key]) {
        differences.push(nestedName ? nestedName + '.' + key : key)
      }
    }
    if (isObject(obj1[key])) {
      const response = Compare(obj1[key], obj2[key],
        nestedName ? nestedName + '.' + key : key)
      differences = differences.concat(response)
    }
    if (isArray(obj1[key])) {
      if (!CompareArray(obj1[key], obj2[key])) {
        differences.push(nestedName ? nestedName + '.' + key : key)
      }
    }
  }
  return differences
}

/**
 * @return {boolean}
 */
function CompareArray(array1, array2) {
  if (!array1 || !array2) {
    return false
  }
  if (array1.length !== array2.length) {
    return false
  }
  if (
    (array1.length > 1 && isValue(array1[0])) ||
    (array2.length > 1 && isValue(array2[0]))
  ) {
    return !(
      array1.some(item => !array2.find(item2 => item === item2)) ||
      array2.some(item2 => !array1.find(item1 => item1 === item2))
    )
  }
  // TODO compare nested objects
  return true
}

function isArray(obj) {
  return {}.toString.apply(obj) === '[object Array]'
}

function isObject(obj) {
  return {}.toString.apply(obj) === '[object Object]'
}

function isValue(obj) {
  return !isObject(obj) && !isArray(obj)
}
