import React from 'react'
import {connect} from 'react-redux'

import ProcessingOrg from './ProcessingOrg'

const mapStateToProps = state => {
  return {
    partyCode: state.security.claims.context.partyCode,
    partyType: state.security.claims.context.partyType,
    securityClaims: state.security.claims,
    partyCurrencyPairIds: state.myParty.currencyPairIds || {},
    allCurrencyPairs: state.currencyPair.records || [],
  }
}


const Container = props => {
  return (
    <div>
      <ProcessingOrg {...props}/>
    </div>)
}


const ProcessingOrgContainer = connect(mapStateToProps)(
  Container)
export default ProcessingOrgContainer
