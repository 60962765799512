import React from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'


const useStyles = makeStyles(() => ({
  root: {
    height: '32px',
    width: '32px',
    strokeWidth: '2'
  },
})
)


export function DealingIcon() {
  const classes = useStyles()
  const theme = useTheme()


  return (
    <SvgIcon
      className={classes.root}
      htmlColor={theme.palette.primary.contrastText}
      viewBox="0 0 32 32"
    >
      <g
        id="Dealing">
        <g
          id="Group_57"
          transform="translate(-454.692 -323.697)">
          <g
            id="Group_52">
            <path
              d="M478.024,350.639h-5.332a.5.5,0,0,1-.5-.5V334.114a.5.5,0,0,1,.5-.5h5.332a.5.5,0,0,1,.5.5v16.025a.5.5,0,0,1-.5.5Zm-4.832-1h4.332V334.614h-4.332v15.025Z"
              id="Path_41"/>
          </g>
          <g
            data-name="Group 53"
            id="Group_53">
            <path
              d="M469.274,350.639h-5.332a.5.5,0,0,1-.5-.5v-9.168a.5.5,0,0,1,.5-.5h5.332a.5.5,0,0,1,.5.5v9.168a.5.5,0,0,1-.5.5Zm-4.832-1h4.332v-8.168h-4.332v8.168Z"
              id="Path_42"/>
          </g>
          <g
            data-name="Group 54"
            id="Group_54">
            <path
              d="M460.524,350.639h-5.332a.5.5,0,0,1-.5-.5v-4.192a.5.5,0,0,1,.5-.5h5.332a.5.5,0,0,1,.5.5v4.192a.5.5,0,0,1-.5.5Zm-4.832-1h4.332v-3.192h-4.332v3.192Z"
              id="Path_43"/>
          </g>
          <g
            data-name="Group 55"
            id="Group_55">
            <path
              d="M471.252,332.439a.5.5,0,0,1-.353-.853l3.514-3.518-3.514-3.517a.5.5,0,0,1,.707-.707l3.867,3.871a.5.5,0,0,1,0,.707l-3.867,3.871a.5.5,0,0,1-.354.146Z"
              id="Path_44"/>
          </g>
          <g
            data-name="Group 56"
            id="Group_56">
            <path
              d="M458.1,341.579a.521.521,0,0,1-.1-.009.5.5,0,0,1-.4-.586,16.514,16.514,0,0,1,16.14-13.416.5.5,0,0,1,0,1,15.512,15.512,0,0,0-15.158,12.606.5.5,0,0,1-.49.4Z"
              id="Path_45"/>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
