import {useEffect, useState} from 'react'
import {isArray, isObject, isString} from 'utils/Utils'

// columns, defaultColConfig // FROM PROPS PASSED TO TABLE

// the physical columns that will  show visibleColumns

export default function useColConfig(columns, defaultColConfig, tableID) {
  const [visibleColumns, setVisibleColumns] = useState(columns)
  const [error] = useState(undefined)
  const [loading, setLoading] = useState(undefined)
  const [colConfig, setColConfig] = useState(undefined)
  const [usedDefaultColConfig, setUsedDefaultColConfig] = useState(undefined)
  const [setupCompleted, setSetupCompleted] = useState(false)

  useEffect(() => {
    if (usedDefaultColConfig) {
      return
    }
    const generatedUsedDefaultColConfig = generateDefaultColConfig(columns, defaultColConfig)
    setUsedDefaultColConfig(generatedUsedDefaultColConfig)
  }, [columns, defaultColConfig, usedDefaultColConfig])


  useEffect(() => {
    if (setupCompleted) {
      return
    }
    const setFunc = async () => {
      const conf = await generateUsedColConfig(columns, usedDefaultColConfig, tableID)
      setColConfig(conf)
      if (conf) {
        setSetupCompleted(true)
      }
    }
    setLoading(true)
    setFunc().finally()
    setLoading(false)
  }, [columns, usedDefaultColConfig, tableID, setupCompleted])

  useEffect(() => {
    if (colConfig === undefined) {
      return
    }
    const visibleCols = configureColumns(columns, colConfig)
    setVisibleColumns(visibleCols)
  }, [colConfig, columns])

  function updateColConfig(newColConfig) {
    validateColConfig(newColConfig, columns)
    const saveNewConfig = async () => {
      setLoading(true)
      await saveColConfig(tableID, newColConfig)
      setColConfig(newColConfig)
      setLoading(false)
    }
    saveNewConfig().finally()
  }
  return [{visibleColumns, colConfig, usedDefaultColConfig, error, loading}, updateColConfig]
}

function generateDefaultColConfig(columns, defaultColConfig) {

  // validate defaultColConfig if not defined and set it
  // else use columns and set that as the default col config

  try {
    if (
      // if a default column configuration is passed AND
      (defaultColConfig !== undefined) &&
      // it is valid
      validateColConfig(defaultColConfig, columns)
    ) {
      // then set defaultColConfig to the prop
      return defaultColConfig
    }
  } catch (e) {
    throw new Error(`invalid defaultColConfig: ${e}`)
  }

  // otherwise it was not passed or is invalid, a default one will be generated
  const usedDefaultColConfig = []
  for (const col of columns) {

    // if no header given or given header is a blank string then ignore
    // cannot give column configuration
    if (
      !isString(col.title) ||
      (col.title === '')
    ) {
      continue
    }

    usedDefaultColConfig.push({
      header: col.title,
      visible: true,
    })
  }

  return usedDefaultColConfig
}

async function generateUsedColConfig(columns, defaultColConfig, tableID) {

  // This will try to retrieve the col config from local storage
  // If found it will try to set the used col config to this one
  // Else it will use the default col config

  const retrievedColConfig = await retrieveColConfig(tableID)
  if (retrievedColConfig === undefined) {
    // eslint-disable-next-line no-console
    // if colConfig is undefined, it was never set before
    // so use default
    return defaultColConfig
  } else {
    // otherwise colConfig was retrieved and is not undefined
    try {
      validateColConfig(retrievedColConfig, columns)
      return retrievedColConfig
    } catch (e) {
      // eslint-disable-next-line no-console
      return defaultColConfig
    }
  }
}

function configureColumns(columns, colConfig) {

  //this visible cols are generate using the used colConfig

  const visibleColumns = []
  for (const colConf of colConfig) {
    let column
    for (const colIdx in columns) {
      const col = columns[colIdx]
      if (col.title === colConf.header) {
        column = col
        break
      }
      if (colIdx === (columns.length - 1)) {
        throw new Error(
          `unable to locate column with header ${col.Header} in columns`)
      }
    }
    if (colConf.visible) {
      // this column should be visible
      // add
      visibleColumns.push(column)
    }
  }

  return visibleColumns
}


function validateColConfig(colConfig, columns) {
  if (!isArray(colConfig)) {
    throw new TypeError('not an array')
  }

  const headers = []
  for (const colConf of colConfig) {
    // each item in colConfig must be an object
    if (!isObject(colConf)) {
      throw new TypeError(
        `an item in the array is not an object ${colConf}`)
    }

    // each item in colConfig must have a `header` and `visible property`
    if (!(colConf.hasOwnProperty('header') && isString(colConf.header))) {
      throw new TypeError(
        `an item in the array does not have a 'header' property which is a string: ${colConf.header}`)
    }

    // each header must only feature once
    if (headers.includes(colConf.header)) {
      throw new Error(`duplicate header in colConfig: ${colConf.header}`)
    }
    headers.push(colConf.header)

    // each header must correspond to one of the column objects
    let found = false
    for (const col of columns) {
      if (col.title === colConf.header) {
        found = true
        break
      }
    }
    if (!found) {
      throw new Error(
        `no column object with header ${colConf.header} found among columns`)
    }
  }

  // each valid column object Header must be represented in the colConfig
  for (const col of columns) {
    if (
      !(
        (col.title === undefined) ||
        (col.title === '') ||
        headers.includes(col.title)
      )
    ) {
      throw new Error(`column with Header ${col.title} not in colConfig`)
    }
  }

  return true
}

function retrieveColConfig(tableID) {
  return new Promise((resolve, reject) => {
    try {
      const tableConfigurations = localStorage.getItem('tableConfigurations')
      // if tableConfigurations is null or undefined then this has never been
      // saved in local storage.
      // It must be set now to be available for further use
      if (
        (tableConfigurations === null) ||
        (tableConfigurations === undefined)
      ) {
        localStorage.setItem('tableConfigurations', JSON.stringify({}))
        resolve(undefined)
      }

      // otherwise we try and parse it and return the config for this tableID
      resolve(JSON.parse(tableConfigurations)[tableID])
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`error retrieving column config: ${e}`)
      reject(e)
    }
  })
}

function saveColConfig(tableID, colConfig) {
  return new Promise((resolve, reject) => {
    try {
      let tableConfigurations = localStorage.getItem('tableConfigurations')
      if (
        (tableConfigurations === null) ||
        (tableConfigurations === undefined)
      ) {
        // if tableConfigurations is null or undefined then this has never been
        // saved in local storage.
        // It must be set now to be available for further use
        tableConfigurations = {}
      } else {
        // otherwise we try and parse it
        tableConfigurations = JSON.parse(tableConfigurations)
      }

      // then update the col config
      tableConfigurations[tableID] = colConfig
      // then save it
      localStorage.setItem('tableConfigurations',
        JSON.stringify(tableConfigurations))
      // and resolve promise
      resolve()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`error saving column config: ${e}`)
      reject(e)
    }
  })
}
