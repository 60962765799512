const requiredFields = ['name', 'isoCode', 'symbol']

class Validator {

  /**
   * @Param {Currency} currency
   */
  static ValidateData(currency) {
    return new Promise((resolve, reject) => {
      const invalidFields = {}
      let valid = true
      requiredFields.forEach(fieldName => {
        if (!currency[fieldName] || currency[fieldName] === '') {
          invalidFields[fieldName] = 'required'
          valid = false
        }
      })
      if (valid) {
        resolve(undefined)
      } else {
        reject(invalidFields)
      }
    })
  }
}

// invalidFields {
//    name: 'required'
// }

export default Validator
