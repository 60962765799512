import React from 'react';
import { InvoiceRecordkeeper } from 'popcorn-js/financial/invoice';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import SettlementInstructionComparator from 'popcorn-js/financial/settlementInstruction/comparator';
import SettlementInstructionHandler from 'popcorn-js/financial/settlementInstruction/handler';
import {Status} from 'popcorn-js/financial/settlementInstruction';
import {
  FiX as DeselectIcon,
  FiAlertTriangle as PricesUnavaliableIcon,
} from 'react-icons/fi';
import { MdDone as SubmitIcon } from 'react-icons/md';
import { FormatNumber } from 'utils/TradeUtilities';
import {
  CardHeader,
  IconButton,
  Typography,
  withStyles,
  Tooltip,
  Icon,
} from '@material-ui/core';
import NotificationSweetAlert from '../../SweetAlert/NotificationSweetAlert';
import { objectCopy, processUnixDateForViewing } from 'utils/Utils';
import InvoiceDetail from '../invoice/InvoiceDetailDialog';
import PrepaymentDetailDialog from 'components/Detail/prepayment/PrepaymentDetailDialogContainer';
import Handler from 'popcorn-js/rick/handler'

import { ComponentLevelLoader } from 'components/Loader/Loader';
import { ComponentLevelError } from 'components/Error/Error';
import moment from 'moment';
import { Recordkeeper as PrepaymentRecordkeeper } from 'popcorn-js/financial/accounting/prepayment/recordkeeper';
import { EXACT_CRITERION } from 'popcorn-js/search/criteria/types';
import { getSignBasedOnType } from 'popcorn-js/financial/invoice/Invoice';
import { displayAmount } from 'views/Tickets/util';
import Big from 'big.js'

const styles = (theme) => ({
  contentLayout: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
  },
  headingLayout: {
    display: 'flex',
    flexDirection: 'column',
  },
  headingTotalsLayout: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pricesUnavailableIcon: {
    padding: '0',
    color: theme.palette.error.main,
  },
  pricesUnavailableWrapper: {
    textAlign: 'right',
    minWidth: 120,
  },
  dividingLine: {
    margin: '10px 0',
    borderWidth: '1px 0 0 0',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
  },
  list: {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  invoiceAmountRequestedCounterpartySummaryLayout: {
    display: 'grid',
    gridTemplateRows: '30px auto auto 30px',
    paddingBottom: '20px',
  },
  removeLineItemIconButton: {
    padding: '0',
    color: theme.palette.error.main,
  },
  summaryGroupHeadingLayout: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '5px',
  },
  invoiceLineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    margin: '0',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  counterpartyTotalLine: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '3px 10px',
    fontWeight: '600',
  },
  prepaymentAmountRequestedCounterpartySummaryLayout: {
    display: 'grid',
    gridTemplateRows: '30px auto auto 30px',
    paddingBottom: '20px',
  },
  prepaymentLineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '3px 10px',
    margin: '0',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
});


const calculateLocalTotal = (sign, links) => {
  let totalRateAmount = 0.0;
  for (const tradeLink of links) {
    // correct for negative link amounts
    const linkAmount = tradeLink.amount < 0 ? -1 * tradeLink.amount : tradeLink.amount
    totalRateAmount += linkAmount * tradeLink.rate;
  }
  return sign * totalRateAmount;
}

class SettlementInstruction extends React.Component {
  state = {
    invoiceCache: [],
    counterpartyCache: [],
    localToSIMTMRate: null,
    counterpartyViewObject: [],
    prepaymentCache: [],

    loading: false,

    successMessage: undefined,
    errorMessage: undefined,
    warningMessage: undefined,
    confirmationMethod: undefined,
    selectedInvoiceId: undefined,

    selectedInvoice: null,
    viewInvoice: false,

    itemListHeight: 0,

    selectedPrepayment: {},
    viewPrepaymentDialog: false,

    invoiceRequestedAmountCounterpartySummary: {},
    prepaymentRequestedAmountCounterpartySummary: {},

    error: undefined,
  };

  componentDidMount() {
    this.processNewSI().finally();
  }

  componentDidUpdate(prevProps) {
    if (
      !SettlementInstructionComparator.CompareAll(this.props.SI, prevProps.SI)
    ) {
      this.processNewSI().finally();
    }
  }

  processNewSI = async () => {
    this.setState({ loading: true });
    try {
      await this.findMTMRate();
    } catch (e) {
      console.error('error finding spot rate', e);
      this.setState({
        loading: false,
        error: e.message || e,
      });
      return;
    }

    try {
      await this.findSiInvoices();
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        error: e.message || e,
      });
      return;
    }

    try {
      await this.findSiPrepayments();
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        error: e.message || e,
      });
      return;
    }

    try {
      await this.findSiCounterparties();
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        error: e.message || e,
      });
      return;
    }

    try {
      this.buildSummaries();
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        error: e.message || e,
      });
      return;
    }

    this.setState({ loading: false });
  };

  findSiInvoices = async () => {
    const { SI: settlementInstruction } = this.props;
    const { invoiceCache } = this.state;

    // all invoice ids
    let invoiceIds = (settlementInstruction.invoiceAmountsRequested || []).map(
      (entry) => entry.invoiceId
    );

    // filter to be only unique ids
    invoiceIds = invoiceIds.filter(
      (id, index, list) => list.indexOf(id) === index
    );

    // filter out ids of invoice already in the state
    invoiceIds = invoiceIds.filter(
      (id) => !invoiceCache.find((inv) => inv.id === id)
    );

    if (invoiceIds.length > 0) {
      const criteria = invoiceIds.map((id) => ({
        type: EXACT_CRITERION,
        value: {
          field: 'id',
          text: id,
        },
      }));

      try {
        const findResponse = await InvoiceRecordkeeper.find({ criteria });
        invoiceCache.push(...findResponse.records);
        this.setState({ invoiceCache });
      } catch (e) {
        console.error('Could not find invoices: ', e.message || e);
        throw e;
      }
    }
  };

  findSiPrepayments = async () => {
    const { SI: settlementInstruction } = this.props;
    const { prepaymentCache } = this.state;

    // all prepayment ids
    let prepaymentIds = settlementInstruction.prepaymentAmountsRequested.map(
      (entry) => entry.prepaymentId
    );

    // filter to be only unique ids
    prepaymentIds = prepaymentIds.filter(
      (id, index, list) => list.indexOf(id) === index
    );

    // filter out ids of invoice already in the state
    prepaymentIds = prepaymentIds.filter(
      (id) => !prepaymentCache.find((pp) => pp.id === id)
    );

    if (prepaymentIds.length > 0) {
      const criteria = prepaymentIds.map((id) => ({
        type: EXACT_CRITERION,
        value: {
          field: 'id',
          text: id,
        },
      }));

      try {
        const prepaymentFindResponse = await PrepaymentRecordkeeper.find({
          criteria,
        });
        prepaymentCache.push(...prepaymentFindResponse.records);
        this.setState({ prepaymentCache });
      } catch (e) {
        console.error('error finding prepayments: ', e.message || e);
        throw e;
      }
    }
  };

  findSiCounterparties = async () => {
    const { invoiceCache, prepaymentCache } = this.state;
    const { counterpartyCache } = this.state;
    const { SI: settlementInstruction } = this.props;

    // get associated invoice ids
    const invoiceIds = (
      settlementInstruction.invoiceAmountsRequested || []
    ).map((entry) => entry.invoiceId);

    // get invoices for ids
    let invoices = invoiceIds.map(
      (id) => invoiceCache.find((inv) => inv.id === id) || {}
    );

    // filter undefined invoices - technically should be none
    invoices = invoices.filter((inv) => inv.id);

    // get counterparty ids for all linked invoices
    let counterpartyIds = invoices.map((inv) => inv.counterpartyId);

    // get associated prepayment ids
    const prepaymentIds = (
      settlementInstruction.prepaymentAmountsRequested || []
    ).map((entry) => entry.prepaymentId);

    // get prepayments for ids
    let prepayments = prepaymentIds.map(
      (id) => prepaymentCache.find((pp) => pp.id === id) || {}
    );

    // filter undefined prepayments - technically should be none
    prepayments = prepayments.filter((pp) => pp.id);

    // add prepayment counterparties
    counterpartyIds = [
      ...counterpartyIds,
      ...prepayments.map((pp) => pp.counterpartyId),
    ];

    // filter undefined ids
    counterpartyIds = counterpartyIds.filter((id) => id);

    // get unique ids
    counterpartyIds = counterpartyIds.filter(
      (id, index, list) => list.indexOf(id) === index
    );

    // filter out cached counterparties
    counterpartyIds = counterpartyIds.filter(
      (id) => !counterpartyCache.find((b) => b.id === id)
    );

    if (counterpartyIds.length > 0) {
      const criteria = counterpartyIds.map((id) => ({
        type: EXACT_CRITERION,
        value: {
          field: 'id',
          text: id,
        },
      }));
      try {
        const counterpartyFindResponse = await CounterpartyRecordkeeper.find({
          criteria: criteria,
        });
        counterpartyCache.push(...counterpartyFindResponse.records);
        this.setState({ counterpartyCache });
      } catch (e) {
        console.error(
          'Could not find request for fund counterparties: ' + e.message || e
        );
        throw e;
      }
    }
  };

  findMTMRate = async () => {
    const { currencyPairs, SI, currencies } = this.props;
    let { localCurrencyId } = this.props;

    // TODO: remove this when si has a target currency, this is to deal with when the
    // TODO: processing org is logged in as they have no localCurrencyId
    if (localCurrencyId === undefined) {
      const zarCurrency = currencies.find((ccy) => ccy.isoCode === 'ZAR');
      if (!zarCurrency) {
        console.error('unable to find zar currency among currencies');
        return;
      }
      localCurrencyId = zarCurrency.id;
    }

    // if si and local currency id are the same
    // then there is no need to retrieve spot rate
    if (SI.currencyId === localCurrencyId) {
      return;
    }

    // look for the siCurrency/localCurrency currency pair
    const currencyPair = currencyPairs.find(
      (ccyPair) =>
        ccyPair.baseCurrencyId === SI.currencyId &&
        ccyPair.quoteCurrencyId === localCurrencyId
    );

    if (currencyPair) {
      // if the currency pair was found try and retrieve the current spot rate
      // for this currency pair
      try {
        let rateRetrieveResult;
        if (SI.date <= moment().unix()) {
          rateRetrieveResult = await Handler.RetrieveRate({
            rateSubscription: {
              currencyPairName: currencyPair.name,
              date: 0,
            },
          });
        } else {
          rateRetrieveResult = await Handler.RetrieveRate({
            rateSubscription: {
              currencyPairName: currencyPair.name,
              date: SI.date,
            },
          });
        }

        this.setState({
          localToSIMTMRate:
            (rateRetrieveResult.priceSubscriptionSucceeded.askRate +
              rateRetrieveResult.priceSubscriptionSucceeded.bidRate) /
            2,
        });
      } catch (e) {
        console.error('error retrieving spot rate for currency pair', e);
        this.setState({ localToSIMTMRate: null });
      }
    }
  };

  buildSummaries = () => {
    const { SI: settlementInstruction } = this.props;
    const {
      counterpartyCache,
      invoiceCache,
      prepaymentCache,
      localToSIMTMRate,
    } = this.state;
    const invoiceRequestedAmountCounterpartySummary = {};
    for (const invoiceRequestedAmount of settlementInstruction.invoiceAmountsRequested) {
      const entryInvoice = invoiceCache.find(
        (inv) => inv.id === invoiceRequestedAmount.invoiceId
      );
      if (!entryInvoice) {
        console.error('Could not find invoice in invoice cache.');
        throw new Error('Could not find invoice in invoice cache.');
      }
      const sign = getSignBasedOnType(entryInvoice)

      const invoiceCounterparty = counterpartyCache.find(
        (b) => b.id === entryInvoice.counterpartyId
      );
      if (!invoiceCounterparty) {
        console.error(
          'Could not find invoice counterparty in counterparty cache.'
        );
        throw new Error(
          'Could not find invoice counterparty in counterparty cache.'
        );
      }
      if (
        !Object.keys(invoiceRequestedAmountCounterpartySummary).includes(
          invoiceCounterparty.id
        )
      ) {
        invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id] = {
          counterparty: invoiceCounterparty,
          total: 0,
          localTotal: 0,
          invoicesAndInvoiceAmountsRequested: [],
        };
      }

      // correct for previously mis-captured amounts
      const amountRequested = invoiceRequestedAmount.amount < 0 ? -1 * invoiceRequestedAmount.amount : invoiceRequestedAmount.amount

      switch (settlementInstruction.status) {
        case Status.Draft:
        case Status.Submitted:
        case Status.Processing:
          invoiceRequestedAmountCounterpartySummary[
            invoiceCounterparty.id
          ].localTotal += sign * amountRequested * localToSIMTMRate;
          break
        case Status.Complete: {
          const invoiceTradeLinks = settlementInstruction.invoiceTradeLinks.filter(
            (invoiceTradLink) =>
              invoiceTradLink.invoiceId === invoiceRequestedAmount.invoiceId
          );
          if (invoiceTradeLinks !== undefined) {
            invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id].localTotal += calculateLocalTotal(sign, invoiceTradeLinks)
          }
        }
          break
        default:
          // ??
      }

      invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id].total += sign * amountRequested;

      invoiceRequestedAmountCounterpartySummary[
        invoiceCounterparty.id
      ].invoicesAndInvoiceAmountsRequested.push({
        invoice: entryInvoice,
        amountRequested: {...invoiceRequestedAmount, amount: amountRequested},
      });
    }

    const prepaymentRequestedAmountCounterpartySummary = {};
    for (const prepaymentAmountRequested of settlementInstruction.prepaymentAmountsRequested) {
      const prepaymentAmountRequestedPrepayment = prepaymentCache.find(
        (pp) => pp.id === prepaymentAmountRequested.prepaymentId
      );
      if (!prepaymentAmountRequestedPrepayment) {
        console.error('could not find prepayment in prepayment cache');
        throw new Error('could not find prepayment in prepayment cache');
      }

      const prepaymentCounterparty = counterpartyCache.find(
        (b) => b.id === prepaymentAmountRequestedPrepayment.counterpartyId
      );
      if (!prepaymentCounterparty) {
        console.error(
          'could not find prepayment counterparty in counterparty cache'
        );
        throw new Error(
          'could not find prepayment counterparty in counterparty cache'
        );
      }

      if (
        !Object.keys(prepaymentRequestedAmountCounterpartySummary).includes(
          prepaymentCounterparty.id
        )
      ) {
        prepaymentRequestedAmountCounterpartySummary[
          prepaymentCounterparty.id
        ] = {
          counterparty: prepaymentCounterparty,
          total: 0,
          localTotal: 0,
          prepaymentsAndPrepaymentAmountsRequested: [],
        };
      }

      switch (settlementInstruction.status) {
        case Status.Draft:
        case Status.Submitted:
        case Status.Processing:
          prepaymentRequestedAmountCounterpartySummary[
            prepaymentCounterparty.id
          ].localTotal += prepaymentAmountRequested.amount * localToSIMTMRate;
          break
        case Status.Complete: {
          const prepaymentTradeLinks = settlementInstruction.prepaymentTradeLinks.filter(
            (prepaymentTradeLink) =>
              prepaymentTradeLink.invoiceId === prepaymentAmountRequested.invoiceId
          );
          if (prepaymentTradeLinks !== undefined) {
            prepaymentRequestedAmountCounterpartySummary[prepaymentCounterparty.id].localTotal += calculateLocalTotal(1, prepaymentTradeLinks);
          }
        }
          break
        default:
            // ??
      }

      prepaymentRequestedAmountCounterpartySummary[
        prepaymentCounterparty.id
      ].total += prepaymentAmountRequested.amount;
      prepaymentRequestedAmountCounterpartySummary[
        prepaymentCounterparty.id
      ].prepaymentsAndPrepaymentAmountsRequested.push({
        prepayment: prepaymentAmountRequestedPrepayment,
        amountRequested: prepaymentAmountRequested,
      });
    }

    this.setState({
      invoiceRequestedAmountCounterpartySummary,
      prepaymentRequestedAmountCounterpartySummary,
    });
  };
  handleHideInvoiceDetail = () => {
    this.setState({ viewInvoice: false });
  };

  handleHideAlert = () => {
    this.setState({
      errorMessage: null,
      successMessage: null,
      confirmationMethod: null,
      warningMessage: null,
    });
  };

  handleInvoiceSelect = (invoice) => {
    this.setState({
      selectedInvoice: invoice,
      viewInvoice: true,
    });
  };

  handleInvoiceDeselect = (invoiceId) => {
    const settlementInstruction = objectCopy(this.props.SI);

    const entryIndexToSplice = settlementInstruction.invoiceAmountsRequested.findIndex(
      (entry) => entry.invoiceId === invoiceId
    );
    if (entryIndexToSplice >= 0) {
      settlementInstruction.invoiceAmountsRequested.splice(
        entryIndexToSplice,
        1
      );
      this.updateSi(settlementInstruction).finally();
    } else {
      console.error(
        'Could not find invoice to deselect. Not suppose to happen'
      );
      this.setState({
        errorMessage:
          'Unexpected Error Occured. Please Contact Your Administrator',
      });
    }
  };

  handlePrepaymentSelect = (prepayment) => {
    this.setState({
      selectedPrepayment: prepayment,
      viewPrepaymentDialog: true,
    });
  };

  handlePrepaymentDeselect = (prepaymentId) => {
    const settlementInstruction = objectCopy(this.props.SI);

    const entryIndexToSplice = settlementInstruction.prepaymentAmountsRequested.findIndex(
      (ppar) => ppar.prepaymentId === prepaymentId
    );
    if (entryIndexToSplice >= 0) {
      settlementInstruction.prepaymentAmountsRequested.splice(
        entryIndexToSplice,
        1
      );
      this.updateSi(settlementInstruction).finally();
    } else {
      console.error(
        'Could not find prepayment to deselect. Not suppose to happen'
      );
      this.setState({
        errorMessage:
          'Unexpected Error Occured. Please Contact Your Administrator',
      });
    }
  };

  updateSi = async (settlementInstruction) => {
    try {
      const draftUpdateResponse = await SettlementInstructionHandler.draftUpdate(
        {
          settlementInstruction,
        }
      );
      this.setState({ successMessage: 'Update Settlement Instruction' });
      this.props.onUpdateSuccess(draftUpdateResponse.settlementInstruction);
    } catch (e) {
      console.error('error updating settlement instruction', e);
      this.setState({ errorMessage: e.message || e });
    }
  };

  handleSubmit = async () => {
    const { SI: settlementInstruction } = this.props;
    try {
      const submitResponse = await SettlementInstructionHandler.submit({
        id: settlementInstruction.id,
      });
      this.props.onSubmitSuccess(submitResponse.settlementInstruction);
    } catch (e) {
      console.error('error submitting si', e);
      this.setState({ errorMessage: e.message || e });
    }
  };

  render() {
    const { readOnly } = this.props;
    return (
      <div ref={this.setItemListHeight}>
        <CardHeader
          action={
            readOnly ? null : (
              <IconButton onClick={this.handleSubmit}>
                <Tooltip
                  enterDelay={400}
                  title="Submit">
                  <SubmitIcon />
                </Tooltip>
              </IconButton>
            )
          }
          title={'Summary'}
        />
        {this.renderContent()}
        {this.renderDialogs()}
      </div>
    );
  }

  renderContent = () => {
    const { loading, error } = this.state;

    if (error) {
      return (
        <div
          style={{
            height: '100%',
            display: 'grid',
            alignContent: 'center',
            paddingBottom: '30px',
          }}
        >
          <ComponentLevelError errorMessage={error} />
        </div>
      );
    }

    if (loading) {
      return SettlementInstruction.renderLoading();
    }

    return this.renderMain();
  };

  setItemListHeight = (element) => {
    const { itemListHeight } = this.state;
    if (itemListHeight > 0) {
      // then it was already set
      return;
    }
    try {
      this.setState({
        itemListHeight: element.parentElement.clientHeight - 200,
      });
    } catch (e) {
      console.error('error setting item list height');
    }
  };

  renderMain = () => {
    const {
      localToSIMTMRate,
      itemListHeight,
      invoiceRequestedAmountCounterpartySummary,
      prepaymentRequestedAmountCounterpartySummary,
    } = this.state;

    const {
      classes,
      SI: settlementInstruction,
      currencies,
      readOnly,
      localCurrencyId,
    } = this.props;

    let total = 0;
    try {
      total += Object.values(invoiceRequestedAmountCounterpartySummary).reduce(
        (total, summary) => {
          return total + summary.total},
        0
      );
      total += Object.values(
        prepaymentRequestedAmountCounterpartySummary
      ).reduce((total, summary) => total + summary.total, 0);
    } catch (e) {
      console.error('error calculating total', e);
    }
    let localTotal = 0;
    try {
      localTotal += Object.values(
        invoiceRequestedAmountCounterpartySummary
      ).reduce((localTotal, summary) => localTotal + summary.localTotal, 0);
      localTotal += Object.values(
        prepaymentRequestedAmountCounterpartySummary
      ).reduce((localTotal, summary) => localTotal + summary.localTotal, 0);
    } catch (e) {
      console.error('error calculating total', e);
    }
    const currencyIsoCode =
      (currencies.find((c) => c.id === settlementInstruction.currencyId) || {})
        .isoCode || '';
    const localCurrencyIsoCode =
      (currencies.find((c) => c.id === localCurrencyId) || {}).isoCode || '';

    const displayLocalCurrency =
      settlementInstruction.currencyId !== localCurrencyId;

    return (
      <div
        className={classes.contentLayout}
        ref={this.setItemListHeight}>
        <div className={classes.headingLayout}>
          <Typography variant={'subtitle1'}>
            {processUnixDateForViewing(settlementInstruction.date)}
          </Typography>
          <div className={classes.headingTotalsLayout}>
            <Typography variant={'subtitle1'}>
              {`${currencyIsoCode} Total: ${FormatNumber(
                total,
                true,
                true,
                2,
                true
              )}`}
            </Typography>
            {displayLocalCurrency && (
              <div>
                {localTotal !== 0 ? (
                  <Typography variant={'subtitle1'}>
                    {`ZAR Total: ${FormatNumber(
                      localTotal,
                      true,
                      true,
                      2,
                      true
                    )}`}
                  </Typography>
                ) : localToSIMTMRate ? (
                  <Typography variant={'subtitle1'}>
                    {`ZAR Total: ${FormatNumber(
                      total * localToSIMTMRate,
                      true,
                      true,
                      2,
                      true
                    )}`}
                  </Typography>
                ) : (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto 20px',
                    }}
                  >
                    <div>
                      <Typography variant={'subtitle1'}>
                        {'ZAR Total:'}
                      </Typography>
                    </div>
                    <div className={classes.pricesUnavailableWrapper}>
                      <Tooltip title="Prices Unavailable">
                        <Icon className={classes.pricesUnavailableIcon}>
                          <PricesUnavaliableIcon fontSize={'medium'} />
                        </Icon>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={classes.dividingLine} />
        <div
          className={classes.list}
          style={{ height: itemListHeight }}>
          {(() => {
            if (
              Object.keys(invoiceRequestedAmountCounterpartySummary).length ===
                0 &&
              Object.keys(prepaymentRequestedAmountCounterpartySummary)
                .length === 0
            ) {
              return (
                <Typography
                  key={'empty'}
                  style={{ textAlign: 'center' }}
                  variant={'overline'}
                >
                  {'No entries'}
                </Typography>
              );
            }
            return (
              <React.Fragment>
                <div className={classes.summaryGroupHeadingLayout}>
                  <Typography variant={'subtitle1'}>Invoices</Typography>
                </div>
                {Object.keys(invoiceRequestedAmountCounterpartySummary).map(
                  (counterpartyId, idx) => (
                    <InvoiceRequiredAmountCounterpartySummaryItem
                      SI={settlementInstruction}
                      classes={classes}
                      counterpartySummary={
                        invoiceRequestedAmountCounterpartySummary[
                          counterpartyId
                        ]
                      }
                      displayLocalCurrency={displayLocalCurrency}
                      invoiceTradeLinks={
                        settlementInstruction.invoiceTradeLinks
                      }
                      key={idx}
                      localCurrencyISO={localCurrencyIsoCode}
                      onInvoiceDeselect={this.handleInvoiceDeselect}
                      onInvoiceSelect={this.handleInvoiceSelect}
                      rate={localToSIMTMRate}
                      readOnly={readOnly}
                      siCurrencyISO={currencyIsoCode}
                    />
                  )
                )}
                <div className={classes.dividingLine} />
                <div className={classes.summaryGroupHeadingLayout}>
                  <Typography variant={'subtitle1'}>Prepayments</Typography>
                </div>
                {Object.keys(prepaymentRequestedAmountCounterpartySummary).map(
                  (counterpartyId, idx) => (
                    <PrepaymentRequiredAmountCounterpartySummaryItem
                      classes={classes}
                      counterpartySummary={
                        prepaymentRequestedAmountCounterpartySummary[
                          counterpartyId
                        ]
                      }
                      displayLocalCurrency={displayLocalCurrency}
                      key={idx}
                      localCurrencyISO={localCurrencyIsoCode}
                      onPrepaymentDeselect={this.handlePrepaymentDeselect}
                      onPrepaymentSelect={this.handlePrepaymentSelect}
                      prepaymentTradeLinks={
                        settlementInstruction.prepaymentTradeLinks
                      }
                      rate={localToSIMTMRate}
                      readOnly={readOnly}
                      siCurrencyISO={currencyIsoCode}
                    />
                  )
                )}
                <div className={classes.dividingLine} />
              </React.Fragment>
            );
          })()}
        </div>
      </div>
    );
  };

  renderDialogs = () => {
    const {
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,

      selectedInvoice,
      viewInvoice,

      selectedPrepayment,
      viewPrepaymentDialog,
    } = this.state;

    return (
      <React.Fragment>
        <NotificationSweetAlert
          customClass={'SettlementInstructionDetails'}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}
          successMessage={successMessage}
          warningMessage={warningMessage}
        />
        {viewInvoice && selectedInvoice && (
          <InvoiceDetail
            invoice={selectedInvoice}
            onClose={this.handleHideInvoiceDetail}
            readOnly
            show={viewInvoice}
          />
        )}
        {viewPrepaymentDialog && (
          <PrepaymentDetailDialog
            closeDialog={() => this.setState({ viewPrepaymentDialog: false })}
            prepayment={selectedPrepayment}
            readOnly
            show={viewPrepaymentDialog}
          />
        )}
      </React.Fragment>
    );
  };

  static renderLoading = () => {
    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          alignContent: 'center',
          paddingBottom: '30px',
        }}
      >
        <ComponentLevelLoader
          color={'black'}
          isLoading />
      </div>
    );
  };
}

export default withStyles(styles)(SettlementInstruction);

SettlementInstruction.defaultProps = {
  currencies: [],
  onUpdateSuccess: () => null,
  onSubmitSuccess: () => null,
};

const InvoiceRequiredAmountCounterpartySummaryItem = (props) => {
  const {
    counterpartySummary,
    classes,
    displayLocalCurrency,
    readOnly,
    onInvoiceDeselect,
    onInvoiceSelect,
    siCurrencyISO,
    localCurrencyISO,
    invoiceTradeLinks,
    SI
  } = props;

  return (
    <div className={classes.invoiceAmountRequestedCounterpartySummaryLayout}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `1fr 1fr 1fr 1fr ${
            displayLocalCurrency ? '1fr' : ''
          }`,
          padding: '0 10px',
        }}
      >
        <Typography
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {counterpartySummary.counterparty.name}
        </Typography>
        <Typography>{'Due Date'}</Typography>
        <Typography>{'Effective Rate'}</Typography>
        <Typography style={{ textAlign: 'right' }}>
          {`Amount ${siCurrencyISO}`}
        </Typography>
        {displayLocalCurrency && (
          <Typography style={{ textAlign: 'right' }}>
            {`Amount ${localCurrencyISO}`}
          </Typography>
        )}
      </div>
      <div>
        {counterpartySummary.invoicesAndInvoiceAmountsRequested.map(
          (li, idx) => {
            const sign = getSignBasedOnType(li.invoice)
            const fxAmount = sign * li.amountRequested.amount
            // determine local amount
            let localAmount = 0.0
            switch (SI.status) {
              case Status.Draft:
              case Status.Submitted:
              case Status.Processing:
                localAmount = props.rate ? sign * li.amountRequested.amount * props.rate : 0.0
                break
              case Status.Complete: {
                const links = invoiceTradeLinks?.filter(
                  (invoiceTradLink) => invoiceTradLink.invoiceId === li.amountRequested.invoiceId)
                if (links !== undefined && links.length > 0) {
                  localAmount = calculateLocalTotal(sign, links)
                }
              }
                break
              default:
                // none
            }
            return (
              <div
                className={classes.invoiceLineItem}
                key={idx}
                onClick={() => onInvoiceSelect(li.invoice)}
                style={{
                  display: 'grid',
                  gridTemplateColumns: `1fr 1fr 1fr 1fr ${
                    displayLocalCurrency ? '1fr' : ''
                  }`,
                  padding: '0 10px',
                }}
              >
                <div>{li.invoice.number}</div>
                <div>{processUnixDateForViewing(li.invoice.dueDate)}</div>
                <div>{li.invoice.effectiveRate}</div>
                <div style={{ textAlign: 'right' }}>
                  {displayAmount(Big(fxAmount))}
                </div>
                {displayLocalCurrency && (
                  <div className={classes.pricesUnavailableWrapper}>
                    {displayAmount(Big(localAmount))}
                  </div>
                )}
                {!readOnly && (
                  <IconButton
                    className={classes.removeLineItemIconButton}
                    onClick={(e) => {
                      onInvoiceDeselect(li.invoice.id);
                      e.stopPropagation();
                    }}
                  >
                    <DeselectIcon fontSize={'small'} />
                  </IconButton>
                )}
              </div>
            );
          }
        )}
      </div>
      <div className={classes.dividingLine} />
      <div className={classes.counterpartyTotalLine}>
        <div style={{ paddingRight: '10px' }}>
          {FormatNumber(counterpartySummary.total, true, true, 2, true)}
        </div>
        {displayLocalCurrency && (
          <div className={classes.pricesUnavailableWrapper}>
            {counterpartySummary.localTotal !== 0 ? (
              FormatNumber(counterpartySummary.localTotal, true, true, 2, true)
            ) : props.rate ? (
              FormatNumber(
                counterpartySummary.total * props.rate,
                true,
                true,
                2,
                true
              )
            ) : (
              <Tooltip title="Prices Unavailable">
                <Icon className={classes.pricesUnavailableIcon}>
                  <PricesUnavaliableIcon fontSize={'medium'} />
                </Icon>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const PrepaymentRequiredAmountCounterpartySummaryItem = (props) => {
  const {
    counterpartySummary,
    classes,
    displayLocalCurrency,
    readOnly,
    onPrepaymentSelect,
    onPrepaymentDeselect,
    siCurrencyISO,
    localCurrencyISO,
    prepaymentTradeLinks,
  } = props;
  
  return (
    <div className={classes.prepaymentAmountRequestedCounterpartySummaryLayout}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `1fr 1fr 1fr ${
            displayLocalCurrency ? '1fr' : ''
          }`,
          padding: '0 10px',
        }}
      >
        <Typography
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {counterpartySummary.counterparty.name}
        </Typography>
        <Typography style={{ textAlign: 'right' }}>{'Due Date'}</Typography>
        <Typography style={{ textAlign: 'right' }}>
          {`Amount ${siCurrencyISO}`}
        </Typography>
        {displayLocalCurrency && (
          <Typography style={{ textAlign: 'right' }}>
            {`Amount ${localCurrencyISO}`}
          </Typography>
        )}
      </div>
      <div>
        {counterpartySummary.prepaymentsAndPrepaymentAmountsRequested.map(
          (li, idx) => {
            return (
              <div
                className={classes.prepaymentLineItem}
                key={idx}
                onClick={() => onPrepaymentSelect(li.prepayment)}
              >
                {!readOnly && (
                  <IconButton
                    className={classes.removeLineItemIconButton}
                    onClick={(e) => {
                      onPrepaymentDeselect(li.prepayment.id);
                      e.stopPropagation();
                    }}
                  >
                    <DeselectIcon fontSize={'small'} />
                  </IconButton>
                )}
                <div>{li.prepayment.number}</div>
                <div style={{ textAlign: 'right' }}>
                  {processUnixDateForViewing(li.prepayment.dueDate)}
                </div>
                <div style={{ textAlign: 'right' }}>
                  {FormatNumber(li.amountRequested.amount, true, true, 2, true)}
                </div>
                {displayLocalCurrency && (
                  <div className={classes.pricesUnavailableWrapper}>
                    {prepaymentTradeLinks.find(
                      (prepaymentTradeLink) =>
                        prepaymentTradeLink.invoiceId ===
                        li.amountRequested.invoiceId
                    ) !== undefined ? (
                        FormatNumber(
                          calculateLocalTotal(
                            prepaymentTradeLinks.filter(
                              (prepaymentTradeLink) =>
                                prepaymentTradeLink.invoiceId ===
                              li.amountRequested.invoiceId
                            )
                          ),
                          true,
                          true,
                          2,
                          true
                        )
                      ) : props.rate ? (
                        FormatNumber(
                          counterpartySummary.localTotal,
                          true,
                          true,
                          2,
                          true
                        )
                      ) : (
                        <Tooltip title="Prices Unavailable">
                          <Icon className={classes.pricesUnavailableIcon}>
                            <PricesUnavaliableIcon fontSize={'medium'} />
                          </Icon>
                        </Tooltip>
                      )}
                  </div>
                )}
              </div>
            );
          }
        )}
      </div>
      <div className={classes.dividingLine} />
      <div className={classes.counterpartyTotalLine}>
        <div style={{ paddingRight: '10px' }}>
          {FormatNumber(counterpartySummary.total, true, true, 2, true)}
        </div>
        {displayLocalCurrency && (
          <div className={classes.pricesUnavailableWrapper}>
            {counterpartySummary.localTotal !== 0 ? (
              FormatNumber(counterpartySummary.localTotal, true, true, 2, true)
            ) : props.rate ? (
              FormatNumber(
                counterpartySummary.total * props.rate,
                true,
                true,
                2,
                true
              )
            ) : (
              <Tooltip title="Prices Unavailable">
                <Icon className={classes.pricesUnavailableIcon}>
                  <PricesUnavaliableIcon fontSize={'medium'} />
                </Icon>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};