import {
  FIND_PAGE_ALL_USERS_ERROR,
  FIND_PAGE_ALL_USERS_STARTED,
  FIND_PAGE_ALL_USERS_SUCCESS,
} from '../actions/actionTypes'

export default function user(state = {
  records: [],
  total: 0,
  findPage: {inProgress: false, success: false, error: undefined},
  action: {},
}, action) {
  switch (action.type) {
    case FIND_PAGE_ALL_USERS_STARTED:
      return {
        ...state,
        findPage: {
          inProgress: true,
          success: false,
          error: undefined,
        },
      }
    case FIND_PAGE_ALL_USERS_SUCCESS:
      return {
        ...state,
        records: action.result.records ? action.result.records : [],
        total: action.result.total,
        findPage: {
          inProgress: false,
          success: true,
          error: undefined,
        },
      }
    case FIND_PAGE_ALL_USERS_ERROR:
      return {
        ...state,
        records: [],
        total: 0,
        findPage: {
          inProgress: false,
          success: false,
          error: action.error,
        },
      }
    default:
      return state
  }
}
