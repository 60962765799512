import {jsonRpcRequest} from 'jsonRpc/jsonRpcReqest';
import {config} from '../index';

export interface RetrieveRateResponse {
  bid:   number;
  ask:   number;
}
export interface RetrieveRateRequest {
  currencyPairName:   string;
  tenor:   string;
}

const Source = {
  ServiceProviderName: 'Pricing-Source',

  async RetrieveRate(request: RetrieveRateRequest): Promise<RetrieveRateResponse> {
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('rickUrl'),
      method: `${Source.ServiceProviderName}.RetrieveRate`,
      request,
    })
  },
  async RetrieveAllRate(request: RetrieveRateRequest): Promise<RetrieveRateResponse> {
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('rickUrl'),
      method: `${Source.ServiceProviderName}.RetrieveAllRate`,
      request,
    })
  },
}
export default Source