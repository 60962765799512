export const AUDIT_ENTRY_CREATED = 'CREATED'
export const AUDIT_ENTRY_MODIFIED = 'MODIFIED'
export const AUDIT_ENTRY_DELETED = 'DELETED'
export const AUDIT_ENTRY_RESTORED = 'RESTORED'

export const ALL_AUDIT_ENTRY_ACTIONS = [
  AUDIT_ENTRY_CREATED,
  AUDIT_ENTRY_MODIFIED,
  AUDIT_ENTRY_DELETED,
  AUDIT_ENTRY_RESTORED,
]