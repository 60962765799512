import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import {Address} from '../../popcorn-js/legalEntity/party/ContactDetails'
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@material-ui/core';

const IndividualAddressCard = (props) => {
  const {address, country} = props
  return (
    <div>
      <Card
        style={{
          marginBottom: '10px',
          marginTop: '20px',
        }}>

        <CardHeader title={props.title}/>
        <CardContent>
          <Grid
            container
            direction={'column'}
            style={{paddingLeft: '40px'}}>
            {(!props.fields || props.fields.includes('residentialAddress')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.residentialA}
                fullWidth
                id="residentialAddress"
                label="Residential Address"
                margin={props.margin}
                onChange={(e) => props.onChange('residentialAddress',
                  e.target.value)}
                value={(address.residentialAddress || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('streetNumber')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.streetNumber}
                fullWidth
                id="streetNumber"
                label="streetNumber"
                margin={props.margin}
                onChange={(e) => props.onChange('streetNumber',
                  e.target.value)}
                value={(address.streetNumber || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('complex')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.complex}
                fullWidth
                id="complex"
                label="Complex"
                margin={props.margin}
                onChange={(e) => props.onChange('complex',
                  e.target.value)}
                value={(address.complex || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('unitNumber')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.unitNumber}
                fullWidth
                id="unitNumber"
                label="Unit number"
                margin={props.margin}
                onChange={(e) => props.onChange('unitNumber',
                  e.target.value)}
                value={(address.unitNumber || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('suburb')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.suburb}
                fullWidth
                id="suburb"
                label="Suburb"
                margin={props.margin}
                onChange={(e) => props.onChange('suburb',
                  e.target.value)}
                value={(address.suburb || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('city')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.city}
                fullWidth
                id="city"
                label="City"
                margin={props.margin}
                onChange={(e) => props.onChange('city',
                  e.target.value)}
                value={(address.city || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('residentialPostalCode')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.postalCode}
                fullWidth
                id="residentialPostalCode"
                label="Residential Postal Code"
                margin={props.margin}
                onChange={(e) => props.onChange('residentialPostalCode',
                  e.target.value)}
                value={address.residentialPostalCode ? address.residentialPostalCode.toString() : ''}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('country')) &&
            <Grid item>
              <FormControl
                error={!!props.invalidFields.country}
                id="countryId"
                margin={props.margin}
                style={{width: '100%'}}>
                <InputLabel
                  htmlFor="countryId-required"
                  shrink={!!address.country}>
                  Country
                </InputLabel>
                <Select
                  error={!!props.invalidFields.country}
                  inputProps={{
                    id: 'countryId-required',
                  }}
                  onChange={(e) => props.onChange('country',
                    e.target.value)}
                  value={address.country}>
                  {country.map((c, i) => <MenuItem
                    className="countryItems"
                    key={i}
                    value={c.id}>{c.name}</MenuItem>)}
                </Select>
                <FormHelperText>{props.invalidFields.country ||
                ' '}</FormHelperText>
              </FormControl>
            </Grid>
            }
            {(!props.fields || props.fields.includes('postalAddress')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.postalAddress}
                fullWidth
                id="postalAddress"
                label="Postal Address"
                margin={props.margin}
                onChange={(e) => props.onChange('postalAddress',
                  e.target.value)}
                value={(address.postalAddress || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('POBox')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.POBox}
                fullWidth
                id="POBox"
                label="PO Box"
                margin={props.margin}
                onChange={(e) => props.onChange('POBox',
                  e.target.value)}
                value={(address.POBox || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('postalNumber')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.postalNumber}
                fullWidth
                id="postalNumber"
                label="Postal Number"
                margin={props.margin}
                onChange={(e) => props.onChange('postalNumber',
                  e.target.value)}
                value={(address.postalNumber || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('townName')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.townName}
                fullWidth
                id="townName"
                label="Town Name"
                margin={props.margin}
                onChange={(e) => props.onChange('townName',
                  e.target.value)}
                value={(address.townName || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('postalCode')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.postalCode}
                fullWidth
                id="postalCode"
                label="Postal Code"
                margin={props.margin}
                onChange={(e) => props.onChange('postalCode',
                  e.target.value)}
                value={(address.postalCode || '')}
              />
            </Grid>
            }
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

IndividualAddressCard.propTypes = {
  address: PropTypes.instanceOf(Address).isRequired,
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.string),
  invalidFields: PropTypes.objectOf(PropTypes.string),
  margin: PropTypes.oneOf(['dense', 'normal']),
  onChange: PropTypes.func,
  title: PropTypes.string,
}

IndividualAddressCard.defaultProps = {
  address: {},
  invalidFields: {},
  margin: 'normal',
  disabled: false,
  title: 'Address',
}

export default IndividualAddressCard
