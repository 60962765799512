import AuditEntryComparator from 'popcorn-js/audit/EntryComparator'
import {
  Company,
} from 'popcorn-js/legalEntity/party/index'

class Comparator {

  /**
   * Compare 2 companies, a and b, excluding given fields
   * @param {Company} a - Company a to compare
   * @param {Company} b - Company b to compare
   * @param {string[]} fieldsToExclude - strings of field names to exclude from comparison
   * @return {boolean} - true if the a and b do NOT differ
   */
  static CompareExcluding(a, b, ...fieldsToExclude) {
    for (const field in a) {
      switch (field) {
        case 'auditEntry':
        // if the field is auditEntry we will use the auditEntry Comparator
          if (
          // a and b are the NOT same AND
            !AuditEntryComparator.CompareExcluding(a.auditEntry, b.auditEntry, ...fieldsToExclude) &&
              // fieldsToExclude does NOT include 'audit entry'
              !fieldsToExclude.includes('auditEntry')
          ) {
            return false
          }
          break

        default:
          if (
          // if this field on a and b are NOT the same AND
            a[field] !== b[field] &&
            // fieldsToExclude does NOT include this field
            !fieldsToExclude.includes(field)
          ) {
            return false
          }
      }
    }
    return true
  }

  /**
   * Compare 2 companies, a and b, considering only the given fields
   * @param {Company} a - Company a to compare
   * @param {Company} b - Company b to compare
   * @param {string[]} fieldsToCompare - strings of field names to compare
   * @return {boolean}
   */
  static CompareOnly(a, b, ...fieldsToCompare) {

    for (const field of fieldsToCompare) {
      switch (field) {
        case 'auditEntry':
        // if the field is auditEntry we will use the auditEntry Comparator
          if (!AuditEntryComparator.CompareOnly(a.auditEntry, b.auditEntry, ...fieldsToCompare)) {
          // if a and b are NOT the same
            return false
          }
          break
        default:
        // if this field on a and b are NOT the same
          if (a[field] !== b[field]) {
            return false
          }
      }
    }
    return true
  }

  /**
   * Compare 2 companies, a and b, considering all fields
   * @param {Company} a
   * @param {Company} b
   * @return {boolean} True if a and b are the same
   */
  static CompareAll(a, b) {
    if (!(a instanceof Company)) {
      console.error('Company comparator cannot compare objects: invalid type')
      return false
    }
    if (!(b instanceof Company)) {
      console.error('Company comparator cannot compare objects: invalid type')
      return false
    }

    for (const field in a) {
      switch (field) {
      // if the field is auditEntry then we use the AuditEntryComparator
        case 'auditEntry':
          if (!AuditEntryComparator.CompareAll(a.auditEntry, b.auditEntry)) {
            return false
          }
          break

        default:
          if (a[field] !== b[field]) {
            return false
          }
      }
    }
    return true

  }
}

export default Comparator
