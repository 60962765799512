import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import IntTextField, {parseTypes} from 'components/FormContols/TextField'
import {ContactDetails} from '../../popcorn-js/legalEntity/party/ContactDetails'

const ContactDetailsCard = (props) => {
  return (
    <Card
      style={{
        marginBottom: '10px',
        marginTop: '20px',
      }}>

      <CardHeader title={props.title}/>
      <CardContent>
        <Grid
          container
          direction={'column'}
          style={{paddingLeft: '40px'}}>
          {(!props.fields || props.fields.includes('telephoneNumber')) &&
          <Grid item>
            <IntTextField
              disableThousandsSeparator
              disabled={props.disabled || !props.onChange}
              disallowNegative
              error={!!props.invalidFields.telephoneNumber}
              fullWidth
              helperText={props.invalidFields.telephoneNumber || ' '}
              id="telephoneNumber"
              label="Telephone Number"
              margin={props.margin}
              onChange={(e) => props.onChange('telephoneNumber',
                e.target.value)}
              parseType={parseTypes.int}
              value={(props.contactDetails.telephoneNumber || '')}
            />
          </Grid>
          }
          {(!props.fields || props.fields.includes('faxNumber')) &&
          <Grid item>
            <IntTextField
              disableThousandsSeparator
              disabled={props.disabled || !props.onChange}
              disallowNegative
              error={!!props.invalidFields.faxNumber}
              fullWidth
              helperText={props.invalidFields.faxNumber || ' '}
              id="faxNumber"
              label="Fax Number"
              margin={props.margin}
              onChange={(e) => props.onChange('faxNumber',
                e.target.value)}
              parseType={parseTypes.int}
              value={(props.contactDetails.faxNumber || '')}
            />
          </Grid>
          }
          {(!props.fields || props.fields.includes('emailAddress')) &&

          <Grid item>
            <TextField
              disabled={props.disabled || !props.onChange}
              error={!!props.invalidFields.emailAddress}
              fullWidth
              helperText={props.invalidFields.emailAddress || ' '}
              id="emailAddress"
              label="Email Address"
              margin={props.margin}
              onChange={(e) => props.onChange('emailAddress',
                e.target.value)}
              value={(props.contactDetails.emailAddress || '')}
            />
          </Grid>
          }
          {(!props.fields || props.fields.includes('website')) &&

          <Grid item>
            <TextField
              disabled={props.disabled || !props.onChange}
              error={!!props.invalidFields.website}
              fullWidth
              helperText={props.invalidFields.website || ' '}
              id="website"
              label="Website"
              margin={props.margin}
              onChange={(e) => props.onChange('website',
                e.target.value)}
              value={props.contactDetails.website || ''}
            />
          </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

ContactDetailsCard.propTypes = {
  contactDetails: PropTypes.instanceOf(ContactDetails).isRequired,
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.string),
  invalidFields: PropTypes.objectOf(PropTypes.string),
  margin: PropTypes.oneOf(['dense', 'normal']),
  onChange: PropTypes.func,
  title: PropTypes.string,
}

ContactDetailsCard.defaultProps = {
  address: {},
  invalidFields: {},
  margin: 'normal',
  disabled: false,
  title: 'Contact Details',
}

export default ContactDetailsCard
