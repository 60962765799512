const styles = theme => ({
  dialogTitleWrapper: {
    padding: 5,
    backgroundColor: theme.palette.primary.main,
  },
  dialogTitle: {
    padding: 5,
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',
  },
  dialogTitleText: {
    justifySelf: 'center',
    color: theme.palette.primary.body,
  },
  TBDLogoWrapper: {
    padding: 4,
  },
  TBDLogoImg: {
    width: '30px',
    verticalAlign: 'middle',
    border: '0',
  },
  dialogTitleCloseBtnWrapper: {
    justifySelf: 'end',
    paddingLeft: 4,
  },
  dialogTitleCloseButton: {
    padding: 2,
    minHeight: '20px',
    minWidth: '20px',
    height: '20px',
    width: '20px',
  },
  dialogTitleCloseIcon: {
    fontSize: 15,
  },
  dialogContent: {
    padding: '5 5 10 5',
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: '1fr',
  },
  configSelect: {
    color: theme.palette.text.body,
  },
  configSelectDisabled: {
    color: theme.palette.text.secondary,
  },
  root: {},
  stepContentWrapper: {
    padding: '5 5 10 5',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    justifyItems: 'center',
    alignItems: 'center',
  },
  stepperWrapper: {},
  stepper: {
    padding: '5 5 10 5',
  },
  button: {
    minWidth: '140px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  progress: {
    margin: 2,
  },
  errorIcon: {
    fontSize: 80,
  },
  confTitle: {
    padding: 2,
  },
  confBody: {
    padding: 2,
  },
})

export default styles
