import React from 'react'
import PropTypes from 'prop-types'
import {withStyles,} from '@material-ui/core'
import MatureTradesNewTable from './MatureTradesNewTable'
import OverDueExposuresNewTable from './OverDueExposuresNewTable'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
})

class ProcessingOrgHome extends React.Component {
  state = {
    loading: false,
  }


  render() {
    const {loading} = this.state
    const {
      classes,
    } = this.props

    if (loading) {
      return <div>Loading</div>
    }

    return (
      <div className={classes.root}>
        <OverDueExposuresNewTable/>
        <MatureTradesNewTable/>
      </div>
    )
  }
}

ProcessingOrgHome.propTypes = {
  classes: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  currencyPairs: PropTypes.array.isRequired,
  partyCurrencyPairIds: PropTypes.array.isRequired,
}

ProcessingOrgHome = withStyles(styles)(ProcessingOrgHome)
export default ProcessingOrgHome
