import {
  RETRIEVE_TABLE_CONFIG_ERROR,
  RETRIEVE_TABLE_CONFIG_STARTED,
  RETRIEVE_TABLE_CONFIG_SUCCESS,
  SAVE_TABLE_CONFIG_ERROR,
  SAVE_TABLE_CONFIG_STARTED,
  SAVE_TABLE_CONFIG_SUCCESS,
} from './actionTypes'

export function saveTableConfigStarted() {
  return {type: SAVE_TABLE_CONFIG_STARTED}
}

export function saveTableConfigSuccess(id, config) {
  return {type: SAVE_TABLE_CONFIG_SUCCESS, config: {[id]: config}}
}

export function saveTableConfigError(error) {
  return {type: SAVE_TABLE_CONFIG_ERROR, error}
}

export function saveTableConfig(id, config) {
  return dispatch => {
    dispatch(saveTableConfigStarted())
    try {
      localStorage.setItem(id, JSON.stringify(config))
      dispatch(saveTableConfigSuccess(id, config))
    } catch (e) {
      dispatch(saveTableConfigError(e))
    }
  }
}

export function retrieveTableConfigStarted() {
  return {type: RETRIEVE_TABLE_CONFIG_STARTED}
}

export function retrieveTableConfigSuccess(id, config) {
  return {type: RETRIEVE_TABLE_CONFIG_SUCCESS, config: {[id]: config}}
}

export function retrieveTableConfigError(error) {
  return {type: RETRIEVE_TABLE_CONFIG_ERROR, error}
}

export function retrieveTableConfig(id) {
  return dispatch => {
    dispatch(retrieveTableConfigStarted())
    try {
      const config = JSON.parse(localStorage.getItem(id))
      dispatch(retrieveTableConfigSuccess(id, config))
    } catch (e) {
      dispatch(retrieveTableConfigError(e))
    }
  }
}
