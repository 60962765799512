import React from 'react'
import {toast} from 'react-toastify'
import {
  CONFIRM_TRADE_BOOKING_ERROR,
  CONFIRM_TRADE_BOOKING_SUCCESS,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  EMAIL_VERIFICATION_ERROR,
  EMAIL_VERIFICATION_SUCCESS,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
  RESET_PROTECTED_ROLE_ERROR,
  RESET_PROTECTED_ROLE_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROTECTED_ROLE_ERROR,
  UPDATE_PROTECTED_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  UPDATE_ROLE_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
} from 'actions/actionTypes'

export default function notifications(state = {}, action) {
  switch (action.type) {
    // Register
    case REGISTER_USER_SUCCESS:
      toast.success(<div id="registerUserToastSuccess">{'Registration complete'}</div>)
      //      sessionStorage.removeItem('access_token')
      sessionStorage.setItem('tradebaseUsername',
        action.result.adminUser.loginName,
      )
      setTimeout(() => {
        window.location.href = '/'
      }, 1000)
      return {...state, error: ''}
    case REGISTER_USER_ERROR:
      toast.error(
        'Failed to register your account: ' + parseError(action.error))
      return {...state, error: action.error}

    // Create Trading Day
    case EMAIL_VERIFICATION_ERROR:
      toast.error('Failed to send email verification: ' + parseError(action.error))
      return {...state, error: action.error}
    case EMAIL_VERIFICATION_SUCCESS:
      toast.success(<div id="userInviteToastSuccess">{'Successfully sent email invite'}</div>)
      return state

    case UPDATE_PROTECTED_ROLE_ERROR:
      toast.error('Failed to update admin role: ' + parseError(action.error))
      return {...state, error: action.error}

    case UPDATE_PROTECTED_ROLE_SUCCESS:
      toast.success('Successfully updated ' + action.result.role.name + ' role')
      return state

    case RESET_PROTECTED_ROLE_ERROR:
      toast.error('Failed to reset admin role: ' + parseError(action.error))
      return {...state, error: action.error}

    case RESET_PROTECTED_ROLE_SUCCESS:
      toast.success('Successfully reset ' + action.result.role.name + ' role')
      return state

    case UPDATE_PASSWORD_ERROR:
      toast.error('Failed to update password: ' + parseError(action.error))
      return {...state, error: action.error}

    case UPDATE_PASSWORD_SUCCESS:
      toast.success('Successfully updated password')
      return state

    case UPDATE_USER_ERROR:
      toast.error('Failed to update user details: ' + parseError(action.error))
      return {...state, error: action.error}

    case UPDATE_USER_SUCCESS:
      toast.success('Successfully updated user details')
      return state
    case CREATE_USER_ERROR:
      toast.error('Failed to create user details: ' + parseError(action.error))
      return {...state, error: action.error}

    case CREATE_USER_SUCCESS:
      toast.success(<div id="createUserSuccessToast">{'Successfully created user details'}</div>)
      return state

    case UPDATE_ROLE_ERROR:
      toast.error('Failed to update role: ' + parseError(action.error))
      return {...state, error: action.error}

    case UPDATE_ROLE_SUCCESS:
      toast.success(<div id="roleUpdateToastSuccess">{'Successfully updated role'}</div>)
      return state

    // trade booking manager methods
    case CONFIRM_TRADE_BOOKING_SUCCESS:
      toast.success(<div id="tradeBookingConfirmToastSuccess">Successfully Confirmed trade booking</div>)
      return state

    case CONFIRM_TRADE_BOOKING_ERROR:
      toast.error('Failed to confirm trade booking: ' + parseError(action.error))
      return {...state, error: action.error}

    default:
      return state
  }
}

function parseError(error) {
  // JsonRPC error
  if (error.message && error.code) {
    if (error.code === UnauthorizedForMethod) {
      // TODO: Handle differently?
      return error.message
    } else {
      return error.message
    }
  } else return error
}

const UnauthorizedForMethod = -32000
