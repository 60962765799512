import {createMuiTheme} from '@material-ui/core/styles'
import {palette, paletteDefault} from 'theme/palette'
import overrides from 'theme/overrides'

/* themeOptions generates the theme based on the state of context switching */
export const themeOptions = (context, originalContext) => {
  const themePalette = palette(context, originalContext)

  // this is so that we can use the generated palette colors on lower levels
  const theme = createMuiTheme({palette: themePalette})

  return {
    overrides: overrides(theme),
    palette: themePalette,
    typography: {
      fontFamily: '\'Roboto\', sans-serif',
      useNextVariants: true,
    },
  }
}

/* themeDefault assumes the default palette */
export const themeDefault = {
  overrides: overrides(createMuiTheme({palette: paletteDefault})),
  palette: paletteDefault,
  typography: {
    fontFamily: '\'Roboto\', sans-serif',
    useNextVariants: true,
  }
}

export const getTheme = () => {
  return createMuiTheme(defaultTheme)
}

export const defaultTheme = {
  palette: {
    primary: {main: '#000033'},
    secondary: {main: '#1976d2'},
    fontColor: {main: '#fff'},
    error: {main: '#ff111b'},
  },
  typography: {
    fontFamily: '\'Roboto\', sans-serif',
    useNextVariants: true,
  },
}
