import React, { ReactElement, useState } from 'react'
import moment from 'moment'
import {
  Button,
  CardHeader,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {MdClear as CloseIcon,} from 'react-icons/md'

import ReportCard from 'components/ReportCard/ReportCard'
// flow types
import {processUnixDateForViewing} from 'utils/Utils'
import { Currency } from 'popcorn-js/financial'

type SettlementInstructionCreateInfo = {
    date: number;
    currencyId: string;
}

const ItemWrapper = (props: {
  children: ReactElement;
  error: string|undefined;
  label: string|undefined;
}): ReactElement => {
  return (
    <Grid item>
      <div
        style={{
          height: props.error ? '45px' : '31px',
          display: 'grid',
          gridGap: '20px',
          gridTemplateColumns: '1fr 1fr',
          marginLeft: '40px',
          marginRight: '40px',
        }}
      >
        <Typography variant={'subtitle1'}>
          <div>{props.label}</div>
        </Typography>
        <div>
          {props.children}
          {props.error &&
          <FormHelperText
            error
            id="helperText">
            {props.error}
          </FormHelperText>}
        </div>
      </div>
    </Grid>
  )
}

export const AddNewSI = (props: {
  onSubmit: (i:SettlementInstructionCreateInfo ) => void;
  closeDialog: () => void;
  importExport: 'Import'|'Export';
  currencies: (Currency|undefined)[];
  show: boolean;
}):ReactElement => {
  const {onSubmit, closeDialog, show, importExport, currencies} = props

  const [settlementInstructionCreateInfo, setSettlementInstructionCreateInfo] = useState<SettlementInstructionCreateInfo>({} as SettlementInstructionCreateInfo)
  const [invalidFields, setInvalidFields] = useState<any>()

  const handleSubmit = async () => {
    onSubmit(settlementInstructionCreateInfo)
  }

  const handleChange = (field: string, value: string|number|undefined) => {
    setSettlementInstructionCreateInfo({
      ...settlementInstructionCreateInfo,
      [field]: value,
    })

    setInvalidFields({
      ...invalidFields,
      [field]: undefined,
    })
  }

  return (
    <Dialog
      open={show}
    >
      <ReportCard>
        <div>
          <CardHeader
            action={[

              <Tooltip
                aria-label="Close"
                enterDelay={500}
                key={'Close'}
                title="Close">
                <IconButton onClick={closeDialog}>
                  <CloseIcon/>
                </IconButton>
              </Tooltip>
            ]}
            title={`Create New ${importExport ? importExport : ''} Settlement Instruction`}
          />
          <div
            style={{
              margin: '30px',
            }}
          >
            <ItemWrapper
              error={invalidFields?.currencyId}
              label={'Currency'}
            >
              <Select
                fullWidth
                onChange={event => handleChange('currencyId', (event.target.value as string))}
                value={settlementInstructionCreateInfo.currencyId || ''}
              >
                {currencies.map((c, i) => (
                  c &&
                    <MenuItem
                      key={i}
                      value={c.id}>{c.isoCode}</MenuItem>))}
              </Select>
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields?.date}
              label={'Delivery Date'}
            >
              <Input
                fullWidth
                inputProps={{
                  max: '9999-12-31'
                }}
                onChange={event => handleChange('date', moment.utc(event.target.value).unix())}
                type={'date'}
                value={processUnixDateForViewing(settlementInstructionCreateInfo.date)}
              />
            </ItemWrapper>
            <div
              style={{
                textAlign: 'center',
                margin: '10px',
              }}>
              <Button
                onClick={handleSubmit}
                style={{width: '100%'}}>{'Create'}</Button>
            </div>
          </div>
        </div>
      </ReportCard>
    </Dialog>
  )
}