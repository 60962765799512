import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { HexToRGBA } from 'utils/Utils';
import { ChartSVG } from 'views/Home/Company/Dashboard/ChartSVG';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'grid',
      minWidth: 150,
      width: '100%',
      gridTemplateRows: 'auto auto',
      justifyContent: 'center',
      alignContent: 'space-between',
    },
    image: {
      height: '180px',
      borderRadius: '50%',
    },
    buttonContainedPrimary: {
      color: '#1A1B36',
      fontWeight: 'bold',
      fontSize: '12px',
      backgroundColor: '#45B2F7',
      '&:hover': {
        backgroundColor: HexToRGBA('#45B2F7', 0.6),
      },
      width: '70px',
      height: '28px',
    },
    buttonWrapper: {
      justifySelf: 'center',
      padding: theme.spacing(),
      marginBottom: '35px',
    },
  })
);

interface CashFlowButtonProps {
  disabled: boolean;
  onClick: (e?: string) => void;
}

const CashFlowButton: React.FC<CashFlowButtonProps> = (
  props: CashFlowButtonProps
) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <ChartSVG background={'#211f40'} fill={theme.palette.text.accent} />
      <div className={classes.buttonWrapper}>
        <Button
          classes={{
            containedPrimary: classes.buttonContainedPrimary,
          }}
          color={'primary'}
          disabled={props.disabled}
          onClick={(): void => props.onClick()}
          variant={'contained'}
        >
          View
        </Button>
      </div>
    </div>
  );
};

export default CashFlowButton;
