import React, {Component} from 'react'
import Table from 'components/Table'
import PropTypes from 'prop-types'
import {
  withStyles, Tooltip,
} from '@material-ui/core'
import {
  MdErrorOutline as ErrorIcon,
  MdViewColumn as ViewColumnIcon,
} from 'react-icons/md'
import ColumnConfigDialog from '../Common/ColumnConfigDialog'
import {isArray, isFunction, isObject, isString, objectCopy} from 'utils/Utils'
import {UnmarshalCriteria} from 'popcorn-js/search/criteria/Unmarshaller'
import {DATE_CRITERION, NUMBER_CRITERION, TEXT_CRITERION} from 'popcorn-js/search/criteria/types'
import {
  Text as TextFilter,
  TextOptions as TextOptionsFilter,
  Number as NumberFilter,
  Date as DateFilter
} from './Filters'
import {MarshalCriteria} from 'popcorn-js/search/criteria/Marshaller'
import {
  validateColumnConfig,
  validateColumns,
  validateData,
} from 'components/Table/Common/Functions'
import Fab from '@material-ui/core/Fab'

const supportedCriterionTypes = [
  TEXT_CRITERION,
  NUMBER_CRITERION,
  DATE_CRITERION,
]

const styles = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: 'auto',
  },
  controlsWrapper: {
    padding: 2,
  },
  tableWrapper: {
    overflow: 'auto',
  },
  controlButton: {
    padding: 2,
    minHeight: 25,
    height: 25,
    width: 25,
  },
  controlIcon: {
    fontSize: 20
  },
  errorIcon: {
    fontSize: 80
  },
})

const errorStates = {
  buildingTable: 'Error Building Table',
  configuringColumns: 'Error Configuring Columns',
  renderingTable: 'Error Rendering Table',
  updatingColumns: 'Error Updating Columns',
}

const processingStates = {
}

const states = {
  nop: 0,
  adjustingColumnConfig: 1,
  columnConfigSaved: 2,
  errorUpdatingColumns: errorStates.updatingColumns,
}

const events = {
  init: states.nop,

  colConfigBtnClicked: states.adjustingColumnConfig,
  closeColumnConfig: states.nop,
  rebuiltColumns: states.nop,

  saveColumnConfig: states.columnConfigSaved,
  columnConfigSet: states.nop,

  errorBuildingTable : errorStates.buildingTable,
  errorConfiguringColumns: errorStates.configuringColumns,
  errorRenderingTable: errorStates.renderingTable,
  errorUpdatingColumns: states.errorUpdatingColumns,
}

class BckndPgntdCustColTable extends Component {

  constructor (props) {
    super(props)
    // Initialise the state object
    this.state={
      activeState: events.init,
      errors: {},
    }

    this.UpdateTableTimeout = undefined

    // Perform initial col config fetch
    if (isFunction(props.retrieveTableConfig)) {
      props.retrieveTableConfig(props.tableID)
    }

    // Try and construct the table
    try {
      this.validateProps()
      this.setDefaultColumnConfig()
      this.setColumnConfig()
      this.buildColumns()
      this.configureVisibleColumns()
      if (this.props.initQuery === undefined) {
        this.state.query = this.newQuery()
        this.state.activePage = 0
      } else {
        this.state.query = this.props.initQuery
        this.state.activePage = 0
      }
    } catch (e) {
      // If it fails put component into an error state
      console.error(`error building table:\n${e}`)
      this.state.activeState = events.errorBuildingTable
      this.state.errors[events.errorBuildingTable] = e
    }

    // Perform initial fetch:
    this.fetchData(this.state.query)
  }

  reConstructTable = () => {
    let err = undefined
    try {
      this.validateProps()
    } catch (e) {
      err = `error validating props: ${e}`
    }
    try {
      this.setDefaultColumnConfig()
    } catch (e) {
      err = `error setting default column config: ${e}`
    }
    try {
      this.buildColumns()
    } catch (e) {
      err = `error building columns: ${e}`
    }
    try {
      this.configureVisibleColumns()
    } catch (e) {
      err = `error configuring visible columns: ${e}`
    }
    try {
      const newQuery = this.newQuery()
      this.fetchData(newQuery)
      this.setState({
        query: newQuery,
        activePage: 0,
      })
    } catch (e) {
      err = `error fetching data: ${e}`
    }

    if (err !== undefined) {
      console.error(`error re-constructing table:\n${err}`)
      this.setState({
        activeState: errorStates.buildingTable,
        errors: {
          ...this.state.errors,
          [errorStates.buildingTable]: `error re-constructing table:\n${err}`
        }
      })
    }

  }

  validateProps = () => {
    const {
      data,
      columns,
      defaultColConfig,
      initFindPageCriteria,
      initQuery,
    } = this.props

    // Validate data
    validateData(data)

    // Validate columns passed
    validateColumns(columns)
    // Validate filter config in columns
    BckndPgntdCustColTable.validateColumnFilterConfig(columns)

    // Validate defaultConfig if it is passed
    if (defaultColConfig !== undefined) {
      if (!isArray(defaultColConfig)) {
        throw new TypeError('invalid defaultColConfig prop: it is not an Array')
      }
    }

    // Validate initFindPageCriteria and set if it is passed
    if (initFindPageCriteria !== undefined) {
      this.initFindPageCriteria = BckndPgntdCustColTable.validateInitFindPageCriteria(initFindPageCriteria)
      this.indexInitFindPageCriteria()
    }

    // Validate initQuery and set query if it is passed
    if (initQuery !== undefined) {
      BckndPgntdCustColTable.validateInitQuery(initQuery)
    }
  }

  setDefaultColumnConfig = () => {
    const {
      columns,
      defaultColConfig,
    } = this.props


    // If defaultColConfig was passed
    if (defaultColConfig !== undefined) {
      // Set this default config to it
      this.defaultColConfig = defaultColConfig
      return
    }

    // Otherwise: Given defaultColumnConfig prop was not passed
    // so construct an automatic one.
    this.defaultColConfig = []
    for (let i=0; i<columns.length; i++) {
      const col = columns[i]
      if (!(col.colConfig && col.colConfig.alwaysVisible)) {
        this.defaultColConfig.push({
          header: col.Header,
          visible: true,
        })
      }
    }

  }

  setColumnConfig = () => {
    this.setState({
      columnConfig: this.getColumnConfig().map(colConf => objectCopy(colConf))
    })
  }

  indexInitFindPageCriteria = () => {
    const fields = []
    const indexedInitFindPageCriteria = {}
    this.initFindPageCriteria.forEach(criteria => {
      if (fields.includes(criteria.value.field)) {
        throw new Error(`2 criteria with same field name found in provided initFindPageCriteria. Field Name: ${criteria.value.field}`)
      }
      // Otherwise it is not a duplicate
      fields.push(criteria.value.field)
      indexedInitFindPageCriteria[criteria.value.field] = criteria
    })
    this.initFindPageCriteria = indexedInitFindPageCriteria
  }

  configureVisibleColumns = () => {
    /**
     * Using columnConfig, and all columns in this.columns,
     * an array of columns (this.visibleColumns) which should be visible is created.
     * This is the array passed to data in <Table .../>
     *
     * This function is called in 2 different places
     * [1] - After this.buildColumns(), when this.constructTable is called on
     *       from constructor
     * [2] - After this.buildColumns() when this.constructTable is called on
     *       from this.reConstructTable
     * [3] - When active state has changed to states.columnConfigChanged. This function
     *       is called on it's own to configure visible columns. Avoids rebuild.
     */

    const columnConfig = this.getColumnConfig()

    this.visibleColumns = []

    // Go through column config to place columns appropriately
    for (let i=0; i<columnConfig.length; i++) {
      const colConf = columnConfig[i]

      // Ignore if not set to visible
      if (!colConf.visible) continue

      // Try and find a given column object with col conf header
      for (let j=0; j<this.columns.length; j++) {
        const column = this.columns[j]
        if (column.Header === colConf.header) {
          this.visibleColumns.push(column)
          break
        }
        if (j === (this.columns.length - 1)) {
          console.error(`unable to find column object with given columnConfig header: ${colConf.header}`)
        }
      }
    }

    // Go through columns to find always visible which
    // should not have made it into columnConfig
    for (let i=0; i<this.columns.length; i++) {
      const col = this.columns[i]

      // Check if column is flagged as always visible,
      // i.e. we shouldn't be able to find an entry for it in
      // colConfig
      if (col.colConfig && col.colConfig.alwaysVisible) {
        // If we found it add it to either start or
        // end of columns
        if (col.colConfig.position === 'start') {
          this.visibleColumns = [col, ...this.visibleColumns]
        } else {
          this.visibleColumns = [...this.visibleColumns, col]
        }
      }
    }

    // Done determining which columns are visible.
    // Now remove filters from any which have just become
    // invisible
    let columnFilterFieldsStateChanged = false
    Object.keys(this.columnFilterFieldsState).forEach(columnID => {
      // Look for undefined because if the state associated with this columnID
      // is already undefined we don't care whether or not the column is set
      // to be visible
      if (this.columnFilterFieldsState[columnID] !== undefined) {
        // Look for each piece of filter fields state
        // inside the visible columns
        for (let i=0; i<this.visibleColumns.length; i++) {
          // Get a valid column ID To compare with
          const column = this.visibleColumns[i]
          if (
            (column.id === undefined) &&
              (column.accessor === undefined)
          ) {
            // If we can't get a valid id ignore this visible column
          } else if (column.filterable === false) {
            // Ignore columns that couldn't be filtered anyway
          } else {
            const colId = column.id ? column.id : column.accessor
            // If the col id matches, the column associated with this
            // filter state is still visible
            if (colId === columnID) {
              break
            }
          }

          if (i === (this.visibleColumns.length - 1)) {
            // This column associated with this piece of state
            // was not found as any of the visible columns
            this.columnFilterFieldsState[columnID] = undefined
            columnFilterFieldsStateChanged = true
          }
        }
      }
    })

    if (columnFilterFieldsStateChanged) {
      this.reFilter()
    } else {
      this.setState({
        activeState: events.init,
      })
    }
  }

  buildColumns = (rebuild) => {
    const {
      columns,
    } = this.props

    if (rebuild) {
      this.columnFilterFieldsState = {}
    } else if (!isObject(this.columnFilterFieldsState)) {
      this.columnFilterFieldsState = {}
    }

    this.columns = []

    for (let i=0; i<columns.length; i++) {
      const column = columns[i]

      if (
        (column.id === undefined) &&
          (column.accessor === undefined)
      ) {
        // Don't add filter state or filter object
        // for columns with no id AND no accessor
        column.Filter = () => undefined
        this.columns.push(column)
        continue
      } else if (column.filterable === false) {
        column.Filter = () => undefined
        this.columns.push(column)
        continue
      }
      const columnId = column.id ? column.id : column.accessor

      // If the columnFilterFieldsState is not undefined we are doing a rebuild
      // at run-time. Don't try look at init criteria if it already has a value
      if (this.columnFilterFieldsState[columnId] === undefined) {
        this.columnFilterFieldsState[columnId] = this.initFindPageCriteria[columnId]
      }

      // Note, whether or not filter config is passed for a column, a filter
      // will be created, unless filterable = false is passed for column
      // the default created is textCriteria
      if (column.filterConfig === undefined) {
        column.Filter = () => {
          return <TextFilter
            fieldID={columnId}
            initValue={this.columnFilterFieldsState[columnId]}
            onChange={(newFilterVal) => this.handleFilterChange(newFilterVal, columnId)}
          />
        }
      } else {
        switch (column.filterConfig.type) {
          case TEXT_CRITERION:
            if (column.filterConfig.options === undefined) {
              column.Filter = () => {
                return <TextFilter
                  fieldID={columnId}
                  filterConfig={column.filterConfig}
                  initValue={this.columnFilterFieldsState[columnId]}
                  onChange={(newFilterVal) => this.handleFilterChange(newFilterVal, columnId)}
                />
              }
            } else {
              column.Filter = () => {
                return <TextOptionsFilter
                  accessor={column.filterConfig.accessor}
                  fieldID={columnId}
                  filterConfig={column.filterConfig}
                  initValue={this.columnFilterFieldsState[columnId]}
                  onChange={(newFilterVal) => this.handleFilterChange(newFilterVal, columnId)}
                  options={column.filterConfig.options}
                />
              }
            }
            break

          case NUMBER_CRITERION:
            column.Filter = () => {
              return <NumberFilter
                fieldID={columnId}
                filterConfig={column.filterConfig}
                initValue={this.columnFilterFieldsState[columnId]}
                onChange={(newFilterVal) => this.handleFilterChange(
                  newFilterVal, columnId)}
              />
            }
            break

          case DATE_CRITERION:
            column.Filter = () => {
              return <DateFilter
                fieldID={columnId}
                filterConfig={column.filterConfig}
                initValue={this.columnFilterFieldsState[columnId]}
                onChange={(newFilterVal) => this.handleFilterChange(
                  newFilterVal, columnId)}
              />
            }
            break

          default:
        }
      }
      this.columns.push(column)
    }

    // Set criteria
    this.criteria = []
    try {
      const criteriaToMarshal = []
      Object.keys(this.columnFilterFieldsState).forEach(fieldName => {
        if (this.columnFilterFieldsState[fieldName] !== undefined) {
          criteriaToMarshal.push(this.columnFilterFieldsState[fieldName])
        }
      })

      this.criteria = MarshalCriteria(criteriaToMarshal)
    } catch (e) {
      console.error('Unable to marshall criteria!')
    }
  }

  newQuery = () => {
    const {
      defaultPageSize,
    } = this.props
    return {
      sortBy: [],
      order: [],
      limit: defaultPageSize,
      offset: 0,
    }
  }

  handleFilterChange = (newFilterValue, columnId) => {
    this.columnFilterFieldsState[columnId] = newFilterValue

    try {
      const criteriaToMarshal = []
      Object.keys(this.columnFilterFieldsState).forEach(fieldName => {
        if (this.columnFilterFieldsState[fieldName] !== undefined) {
          criteriaToMarshal.push(this.columnFilterFieldsState[fieldName])
        }
      })

      this.criteria = MarshalCriteria(criteriaToMarshal)
    } catch (e) {
      console.error(`unable to marshall criteria :${e}`)
    }

    const newQuery = this.newQuery()
    this.fetchData(newQuery)
    this.setState({
      query: newQuery,
      activePage: 0,
    })
  }

  reFilter = () => {

    try {
      const criteriaToMarshal = []
      Object.keys(this.columnFilterFieldsState).forEach(fieldName => {
        if (this.columnFilterFieldsState[fieldName] !== undefined) {
          criteriaToMarshal.push(this.columnFilterFieldsState[fieldName])
        }
      })

      this.criteria = MarshalCriteria(criteriaToMarshal)
    } catch (e) {
      console.error(`unable to marshall criteria :${e}`)
    }

    const newQuery = this.newQuery()
    this.fetchData(newQuery)
    this.setState({
      query: newQuery,
      activePage: 0,
      activeState: events.init,
    })
  }

  getColumnConfig = () => {
    const {
      tableConfig,
      columns,
      tableID,
    } = this.props

    try {
      validateColumnConfig(tableConfig.config[tableID], columns)
      return tableConfig.config[tableID]
    } catch (e) {
      return this.defaultColConfig
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const {
      activeState: prevActiveState,
    } = prevState
    const {
      activeState,
    } = this.state
    const {
      rebuildTableToggle: prevRebuildTableToggle,
      tableConfig: prevTableConfig,
      reFetchDataToggle: prevReFetchDataToggle,
    } = prevProps
    const {
      tableConfig,
      tableID,
      rebuildTableToggle,
      reFetchDataToggle,
    } = this.props

    // Check for if column config has changed
    try {
      if (
        (prevTableConfig.config[tableID] === undefined) &&
          (tableConfig.config[tableID] !== undefined)
      ) {
        this.reConstructTable()
        return
      }
    } catch (e) {
      // console.error(`error checking tableConfig prop: ${e}`)
      return
    }

    if (prevRebuildTableToggle !== rebuildTableToggle) {
      this.reConstructTable()
      return
    }

    if (reFetchDataToggle !== prevReFetchDataToggle) {
      const newQuery = this.newQuery()
      this.fetchData(newQuery)
      this.setState({
        query: newQuery,
        activePage: 0,
        activeState: events.init,
      })
      return
    }


    // If state has changed
    if (activeState !== prevActiveState) {
      if (activeState === states.columnConfigSaved) {
        this.configureVisibleColumns()
        return
      }
    }

    try {
      // If a completed retrieval or save is detected
      const retrievalCompleted =
          (tableConfig.retrieve.success !== prevTableConfig.retrieve.success) &&
          tableConfig.retrieve.success
      const savingCompleted =
          (tableConfig.save.success !== prevTableConfig.save.success) &&
          tableConfig.save.success

      if (retrievalCompleted || savingCompleted) {
        this.configureVisibleColumns()

      }
    } catch (e) {
      console.error('error checking tableConfig flags')
    }
  }

  render () {
    const {
      classes,
    } = this.props
    const {
      activeState,
      errors,
    } = this.state

    return (
      <div className={classes.root}>
        {(()=>{
          switch (true) {
            case Object.values(errorStates).includes(activeState):
              return this.renderError()

            case activeState === states.nop:
            case activeState === states.adjustingColumnConfig:
            case activeState === states.columnConfigSaved:
            case Object.values(processingStates).includes(activeState):
              try {
                return this.renderTable()
              } catch (e) {
                this.setState({
                  activeState: events.errorRenderingTable,
                  errors: {
                    ...errors,
                    [events.errorRenderingTable]: `${e}`
                  },
                })
              }
              break

            default:
              return this.renderError('Invalid State')
          }
        })()}
      </div>
    )
  }

  handleQueryChange = (type, newValue) => {
    let {
      query,
    } = this.state

    let setActivePage = false
    switch (type) {
      case 'sort':
        const sortBy = newValue.map(item => {
          return item.id
        })
        const order = newValue.map(item => {
          return item.desc ? 'desc' : 'asc'
        })
        query = {
          ...query,
          sortBy,
          order,
        }
        break
      case 'pageSize':
        query = {
          ...query,
          limit: newValue,
        }
        break
      case 'pageNo':
        query = {
          ...query,
          offset: query.limit * newValue,
        }
        setActivePage = true
        break
      default:
    }

    this.fetchData(query)
    if (setActivePage) {
      this.setState({
        query,
        activePage: newValue,
      })
    } else {
      this.setState({
        query,
      })
    }
  }

  fetchData = (query) => {
    const {findPageRequest} = this.props
    clearTimeout(this.UpdateTableTimeout)
    if (findPageRequest) {
      this.UpdateTableTimeout = setTimeout(() => {
        findPageRequest(this.criteria, query)
      }, 150)
    }
  }

  renderTable = () => {
    const {
      data,
      classes,
      totalNoRecords,
      findPageStatuses,
      tableID,
      saveTableConfig,
      ...rest
    } = this.props
    const {
      activeState,
      query,
      activePage,
    } = this.state

    return (
      <React.Fragment>
        {(activeState === states.adjustingColumnConfig) &&
          <ColumnConfigDialog
            closeDialog={() => {this.setState({activeState: events.closeColumnConfig})}}
            columnConfig={this.getColumnConfig()}
            defaultColumnConfig={this.defaultColConfig}
            onUpdate={newConfig => {
              if (isFunction(saveTableConfig)) {
                saveTableConfig(tableID, newConfig)
                this.setState({
                  activeState: events.saveColumnConfig,
                })
              }
            }}
            show={activeState === states.adjustingColumnConfig}
          />}
        <div className={classes.controlsWrapper}>
          <Tooltip
            placement="top"
            title="Adjust Columns">
            <Fab
              aria-label="Adjust Columns"
              className={classes.controlButton}
              color="primary"
              onClick={()=>{this.setState({activeState: events.colConfigBtnClicked})}}
            >
              <ViewColumnIcon className={classes.controlIcon} />
            </Fab>
          </Tooltip>
        </div>
        <div className={classes.tableWrapper}>
          <Table
            {...rest}
            columns={this.visibleColumns}
            data={BckndPgntdCustColTable.trimData(query.limit ? query.limit : 5, data)}
            defaultPageSize={query.limit ? query.limit : 5}
            filterable
            loading={findPageStatuses.inProgress}
            manual
            onPageChange={(updatedPageNo) => this.handleQueryChange('pageNo',
              updatedPageNo)}
            onPageSizeChange={(updatedPageSize) => this.handleQueryChange(
              'pageSize', updatedPageSize)}
            onSortedChange={(updatedSortObjs) => this.handleQueryChange('sort',
              updatedSortObjs)}
            page={activePage}
            pages={Math.ceil(totalNoRecords/query.limit)}
          />
        </div>
      </React.Fragment>
    )
  }

  renderError = (outOfStateError) => {
    const {activeState, errors} = this.state
    const {classes} = this.props
    const error = errors[activeState]

    let errorMsg = 'Unknown Error'
    if (outOfStateError) {
      errorMsg = outOfStateError
    } else if (isObject(error)) {
      if (error.message) {
        errorMsg = error.message
      }
    } else if (isString(error)) {
      errorMsg = error
    }

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          alignItems: 'center',
          justifyItems: 'center',
          margin: '15px'
        }}
      >
        <div><b>{activeState}</b></div>
        <div>
          <ErrorIcon
            className={classes.errorIcon}
          />
        </div>
        <div>{errorMsg}</div>
      </div>
    )
  }

  static trimData(queryLimit, data) {

    if (data.length <= queryLimit) {
      return data
    }

    const trimmedData = []
    for (let i=0; (i < queryLimit) || (i < data.length); i++) {
      trimmedData.push(data[i])
    }

    return trimmedData
  }

  // Validation Functions specific to BckndPgntdCustColTable
  static validateColumnFilterConfig(columns) {

    columns.forEach(col => {
      // Check if columnConfig was passed
      if (col.filterConfig !== undefined) {
        // it it was, confirm that it is legit
        if (col.filterConfig.type !== undefined) {
          // filterConfig.type was specified

          // confirm the type is supported
          if (!supportedCriterionTypes.includes(col.filterConfig.type)) {
            throw new Error(`unsupported filter criteria type '${col.filterConfig.type}'`)
          }

          // perform validation specific to given criterion type
          switch (col.filterConfig.type) {
            case TEXT_CRITERION:
            // If options were passed
              if (col.filterConfig.options !== undefined) {
              // options must be an array
                if (!isArray(col.filterConfig.options)) {
                  throw new Error('options prop is not an array')
                }

                // an accessor must be passed if options is
                if (!isString(col.filterConfig.accessor)) {
                  throw new Error('accessor not specified for options')
                }
              }
              break
            default:
          }
        } else {
          throw new Error('filter config type must be passed if config is')
        }
      }
    })
  }
  static validateInitFindPageCriteria(initFindPageCriteria) {
    const error = err => `invalid initFindPageCriteria: ${err}`
    let unmarshalledCriteria

    // Initial criteria should be an array
    if (!isArray(initFindPageCriteria)) {
      throw error('not an array')
    }

    initFindPageCriteria.forEach((serialisedCrit, idx) => {
      // Each element of the array should be serialised criteria (i.e. strings)
      if (!isString(serialisedCrit)) {
        throw error(`expected array of serialised criteria. Array element ${idx}: ${serialisedCrit} is not a string`)
      }
    })

    // Unmarshal criteria
    try {
      unmarshalledCriteria = UnmarshalCriteria(initFindPageCriteria)
    } catch (e) {
      throw error(`error when unmarshalling initFindPageCriteria:\n${e}`)
    }

    return unmarshalledCriteria

  }
  static validateInitQuery(initQuery) {
    if (!isArray(initQuery)) {
      throw new Error('initQuery should be an array')
    }
  }
}

const StyledBckndPgntdCustColTable = withStyles(styles)(BckndPgntdCustColTable)

StyledBckndPgntdCustColTable.propTypes = {
  /**
   * Data For Table
   */
  columns: PropTypes.array.isRequired,

  /**
   * Toggle this value to get columns rebuilt
   * The typical use case for this is when the
   * data used in one of the filters is coming from
   * a API Request. E.g. loading currency pairs.
   * When this component constructs the first time that
   * data may not yet have loaded and the filter options
   * will be blank. The parent can toggle this on
   * load of that data to get the columns to rebuild.
   * This is a trade-off to avoid this component doing
   * a very expensive compare of the entire columns
   * prop on each component did update
   */
  data: PropTypes.array.isRequired,

  defaultColConfig: PropTypes.array,

  /**
   * Redux State Object with findPage api information
   * The init state of this object is:
   *     {
   *     inProgress: false,
   *     success: false,
   *     error: undefined,
   *     }
   */
  findPageRequest: PropTypes.func.isRequired,

  /**
   * Additional Configuration objects which can be passed with the
   * column objects.
   *
   * An example of where these are placed:
   *
   * columns={[
   *   {
   *     Header: 'Number',
   *     accessor: 'number',
   *
   *--> filterConfig: {
   *       type: TEXT_CRITERION,
   *       options: this.props.tradingPartyCodes,
   *       accessor: 'partyCode',
   *     },
   *
   *   },
   * ]}
   *
   * Column Config For React Table
   * Optional Arguments which can be passed in
   * individual column objects:
   *
   *  ___colConfig___
   *  alwaysVisible:
   *    -true/false
   *    -if true, the header is unavailable in the column
   *     configuration dialog.
   *     (i.e. it cannot have it's visibility toggled or order changed)
   *     This will typically be set for columns with blank headers
   *      (i.e. action columns)
   *
   *    -false is the same as not passing this argument
   *     the column header will appear in the column configuration
   *     dialog
   *     (provided the column header in the column is unique and not
   *      a blank string or undefined)
   *
   *  position:
   *    -determines where in the column order the always visible
   *     column will be placed
   *
   *  e.g. colConfig: {
   *                   alwaysVisible: true,
   *                   position: 'end'
   *                  },
   *
   *  If colConfig is not passed then the header of the column will
   *  be searched for in the column config when columns and the visible/hidden
   *  lists are built.
   *
   *
   */
  findPageStatuses: PropTypes.object.isRequired,

  /**
   *  Passed to indicate that an externally generated
   *  or stored columnConfig is not provided. A default
   *  one should be generated.
   */
  hideCustColButton: PropTypes.bool,

  /**
   * Hardcoded Default table config,
   * While editing users can either reset to this or
   * the prev columnConfig required prop
   */
  initFindPageCriteria: PropTypes.array,

  // Filter Props

  /**
   * Optional argument which will be used to initialise
   * table filter fields
   */
  initQuery: PropTypes.object,

  /**
   * Function that will be called with new
   * criteria and query each time filter
   * changes
   */
  reFetchDataToggle: PropTypes.bool,

  rebuildTableToggle: PropTypes.bool,

  /**
   * Optional initial query object,
   * will be used to init filters
   */
  retrieveTableConfig: PropTypes.func,

  /**
   * function which table can call to save the table config
   */
  saveTableConfig: PropTypes.func,

  /**
   * function which table can call to retrieve the table config
   */
  tableConfig: PropTypes.object,

  /**
   * ui.tableConfig redux state
   */
  tableID: PropTypes.string.isRequired,

  /**
   * Id which will be used when calling the save table config
   * function
   */
  totalNoRecords: PropTypes.number.isRequired,

  /**
   * True will hide the custom column button
   */

  useDefaultColumnConfig: PropTypes.bool,
}

StyledBckndPgntdCustColTable.defaultProps = {
  initFindPageCriteria: [],
}

export default StyledBckndPgntdCustColTable
