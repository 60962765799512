import Base from './Base'
import {EXTERNAL_REFERENCE_TRADINGPARTY} from 'popcorn-js/search/identifier/types'
import {isObject, isString} from 'utils/Utils'

export default class ExternalReferenceTradingParty extends Base {

  static identifierType = EXTERNAL_REFERENCE_TRADINGPARTY

  /**
   * @type {string}
   * @private
   */
  _type = ExternalReferenceTradingParty.identifierType

  /**
   * @type {{externalReference: string, tradingPartyCode: string}}
   * @private
   */
  _value = {
    externalReference: '',
    tradingPartyCode: '',
  }

  /**
   * Create new ExternalReferenceTradingParty instance with given
   * identifier
   * @param {
   * {value:{
   *    externalReference: string,
   *    tradingPartyCode: string,
   *  }
   *  } |
   *  ExternalReferenceTradingParty |
   *  string |
   * } [identifier]
   */
  constructor(identifier) {
    super()
    switch (true) {
      case isString(identifier):
        throw new Error('implement string support for identifier instantiation')

      case identifier instanceof ExternalReferenceTradingParty:
      case isObject(identifier):
        this._value = {
          externalReference: identifier.value.externalReference,
          tradingPartyCode: identifier.value.tradingPartyCode,
        }
        break

      case identifier === undefined:
        break

      default:
        throw new TypeError('invalid value passed to ExternalReferenceTradingParty constructor')
    }
  }

  get externalReference() {
    return this._value.externalReference
  }

  set externalReference(newVal) {
    this._value.externalReference = newVal
  }

  get tradingPartyCode() {
    return this._value.tradingPartyCode
  }

  set tradingPartyCode(newVal) {
    this._value.tradingPartyCode = newVal
  }

}
