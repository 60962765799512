import {
  PARTY_TYPE_BROKER, PARTY_TYPE_COMPANY, PARTY_TYPE_INDIVIDUAL,
  PARTY_TYPE_PROCESSING_BANK, PARTY_TYPE_PROCESSING_ORG,
  PARTY_TYPE_SYSTEM,
} from 'constants/partyTypes'
import SystemRecordkeeper from 'popcorn-js/legalEntity/party/system/recordkeeper'
import ProcessingBankRecordkeeper
  from 'popcorn-js/legalEntity/party/processingBank/recordkeeper'
import ProcessingOrgRecordkeeper
  from 'popcorn-js/legalEntity/party/processingOrg/recordkeeper'
import BrokerRecordkeeper from 'popcorn-js/legalEntity/party/broker/recordkeeper'
import CompanyRecordkeeper from 'popcorn-js/legalEntity/party/company/recordkeeper'
import IndividualRecordkeeper
  from 'popcorn-js/legalEntity/party/individual/recordkeeper'

export default class Retriever {
  static retrieveMyParty({partyCode, partyType}) {
    switch (partyType) {
      case PARTY_TYPE_SYSTEM:
        return SystemRecordkeeper.retrieve()
      case PARTY_TYPE_PROCESSING_BANK:
        return ProcessingBankRecordkeeper.retrieve(partyCode)
      case PARTY_TYPE_PROCESSING_ORG:
        return ProcessingOrgRecordkeeper.retrieve(partyCode)
      case PARTY_TYPE_BROKER:
        return BrokerRecordkeeper.retrieve(partyCode)
      case PARTY_TYPE_COMPANY:
        return CompanyRecordkeeper.retrieve(partyCode)
      case PARTY_TYPE_INDIVIDUAL:
        return IndividualRecordkeeper.retrieve(partyCode)
      default:
        throw new Error(`invalid party type ${partyType}`)
    }
  }
}
