import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from '../../actions/helper/utilities'
import {Marshal} from '../search/identifier/Marshaller'
import {ID_IDENTIFIER} from '../search/identifier/types'

/**
 * CountryRecordkeeper is a convenience client for invoking the backend services
 */
export default class Recordkeeper {
  /**
   * @param {Country} country
   */
  static create(country) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Country-Recordkeeper.Create',
      request: {country},
    })
  }

  /**
   * @param criteria: Filter criteria
   * @param query: Query options
   * @param deleted: Search in the deletes
   */
  static find(criteria, query, deleted) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Country-Recordkeeper.Find',
      request: {
        criteria,
        query,
        deleted,
      },
    })
  }

  /**
   * @param {country} country
   * @param {string} id
   */
  static update(country, id) {
    // Create an identifier from the id
    const serializedIdentifier = Marshal({
      type: ID_IDENTIFIER,
      value: {id},
    })

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Country-Recordkeeper.Update',
      request: {
        country,
        identifier: serializedIdentifier,
      },
    })
  }

  /**
   * @param {string} id
   */
  static delete(id) {
    // Create an identifier from the id
    const serializedIdentifier = Marshal({
      type: ID_IDENTIFIER,
      value: {id},
    })

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Country-Recordkeeper.Delete',
      request: {
        identifier: serializedIdentifier,
      },
    })
  }

  /**
   * @param {string} id
   */
  static restore(id) {
    // create identifier from id
    const serializedIdentifier = Marshal({
      type: ID_IDENTIFIER,
      value: {id},
    })

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Country-Recordkeeper.Restore',
      request: {
        identifier: serializedIdentifier,
      },
    })
  }

  /**
   * @param {string} id
   */
  static retrieveHistory(id) {
    const serializedIdentifier = Marshal({
      type: ID_IDENTIFIER,
      value: {id},
    })

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Country-Recordkeeper.RetrieveHistory',
      request: {
        identifier: serializedIdentifier,
      },
    })
  }

  /**
   * @param {string} id
   */
  static deleteForever(id) {
    const serializedIdentifier = Marshal({
      type: ID_IDENTIFIER,
      value: {id},
    })

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Country-Recordkeeper.DeleteForever',
      request: {
        identifier: serializedIdentifier,
      },
    })
  }

}
