
import {jsonRpcRequestRaw} from './helper/utilities'
import config from 'react-global-configuration'


export function exportRatesCache() {
  return jsonRpcRequestRaw({
    url: config.get('rickUrl'),
    method: 'Rick-Exporter.ExportRateCache',
    request: {},
  })
}
