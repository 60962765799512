import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import {useTheme} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    height: '32px',
    width: '32px',
    strokeWidth: '2'
  },
}))


export function LegalEntitiesIcon() {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <SvgIcon
      className={classes.root}
      htmlColor={theme.palette.primary.contrastText}
      viewBox="0 0 32 32"
    >
      <g
        id="Legal_Entities">
        <g
          id="Group_72"
          transform="translate(-111.405 -572.556)">
          <g
            id="Group_67">
            <path
              d="M132.555,593.1H113.012a1.609,1.609,0,0,1-1.607-1.607V577.851a1.609,1.609,0,0,1,1.607-1.607h19.543a1.607,1.607,0,0,1,1.6,1.607v13.641a1.607,1.607,0,0,1-1.6,1.607Zm-19.543-15.855a.608.608,0,0,0-.607.607v13.641a.608.608,0,0,0,.607.607h19.543a.606.606,0,0,0,.6-.607V577.851a.606.606,0,0,0-.6-.607Z"
              id="Path_53"/>
          </g>
          <g
            id="Group_68">
            <path
              d="M126.791,577.151a.5.5,0,0,1-.5-.5v-2.49a.606.606,0,0,0-.607-.6h-5.805a.606.606,0,0,0-.607.6v2.49a.5.5,0,0,1-1,0v-2.49a1.608,1.608,0,0,1,1.607-1.6h5.805a1.608,1.608,0,0,1,1.607,1.6v2.49a.5.5,0,0,1-.5.5Z"
              id="Path_54"/>
          </g>
          <g
            id="Group_69">
            <path
              d="M120.617,583.786h-8.3a.5.5,0,0,1,0-1h8.3a.5.5,0,1,1,0,1Z"
              id="Path_55"/>
          </g>
          <g
            id="Group_70">
            <path
              d="M132.9,583.786h-8.3a.5.5,0,1,1,0-1h8.3a.5.5,0,0,1,0,1Z"
              id="Path_56"/>
          </g>
          <g
            data-name="Group 71"
            id="Group_71">
            <path
              d="M124.166,586.552H121.4a.5.5,0,0,1-.5-.5V581.9a.5.5,0,0,1,.5-.5h2.765a.5.5,0,0,1,.5.5v4.148a.5.5,0,0,1-.5.5Zm-2.265-1h1.765V582.4H121.9v3.148Z"
              id="Path_57"/>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
