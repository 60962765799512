import React, {Component} from 'react'
import HistoryLayout from 'views/History/HistoryLayout'
import {connect} from 'react-redux'

const mapStateToProps = state => {
  return {
    currencyPairs: state.currencyPair.records || [],
  }
}

class ClientTierHistoryContainer extends Component {
  constructor(props) {
    super(props)

    this.modifyFields = this.modifyFields.bind(this)
  }

  render() {
    return (
      <HistoryLayout
        entity={this.modifyFields(this.props.entity)}
        entityFields={this.props.entityFields}
        entityHistory={this.modifyMultiple(this.props.entityHistory || [])}
        entityName={this.props.entityName}
        loading={this.props.isLoading}
        onHide={this.props.onHide}
        open={this.props.open}
      />
    )
  }

  modifyMultiple = clientTiers => {
    return clientTiers.map(c => this.modifyFields(c))
  }

  modifyFields = clientTier => {
    if (!clientTier) {
      return
    }
    clientTier.currencyPairSpreads.map(
      cps => cps.currencyPairName =
        (() => {
          const spreadCurrencyPair = this.props.currencyPairs.find(
            pcp => pcp.id === cps.currencyPairId)
          return spreadCurrencyPair ? spreadCurrencyPair.name : 'unknown'
        })())
    return clientTier
  }
}

ClientTierHistoryContainer.propTypes = {}

export default connect(mapStateToProps)(
  ClientTierHistoryContainer)
