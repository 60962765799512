import Base from './Base'
import {isObject, isString} from 'utils/Utils'
import {ID_IDENTIFIER} from 'popcorn-js/search/identifier/types'
import unmarshal from 'popcorn-js/search/identifier/unmarshal'

export default class PartyCode extends Base {

  static identifierType = ID_IDENTIFIER

  /**
   * @type {string}
   * @private
   */
  _type = PartyCode.identifierType

  /**
   * @type {{partyCode: string}}
   * @private
   */
  _value = {
    partyCode: '',
  }

  /**
   * Create new PartyCode instance with given
   * identifier
   * @param {
   * {value:{
   *    partyCode: string,
   *  }
   *  } |
   *  PartyCode |
   *  string |
   * } [identifier]
   */
  constructor(identifier) {
    super()
    switch (true) {
      case isString(identifier):
        // if a string is passed this could either be a serialised identifier
        // or the actual string party code
        if (identifier.indexOf('::') > -1) {
          // if :: exists within string, we return unmarshalled identifier
          return unmarshal(identifier)
        } else {
          // otherwise we assume that the actual string id was passed
          this._value = {
            partyCode: identifier,
          }
        }
        break

      case identifier instanceof PartyCode:
      case isObject(identifier):
        this._value = {
          partyCode: identifier.value.partyCode,
        }
        break

      case identifier === undefined:
        break

      default:
        throw new TypeError('invalid value passed to PartyCode constructor')
    }
  }

  get partyCode() {
    return this._value.partyCode
  }

  set partyCode(newVal) {
    this._value.partyCode = newVal
  }
}
