export const RECORDKEEPER_ADAPTOR_REGISTERED_NAME = 'Invoice-Recordkeeper'
export const LINKER_ADAPTOR_REGISTERED_NAME = 'Invoice-Linker'

//Recordkeeper services
export const INVOICE_CREATE_PERMISSION = RECORDKEEPER_ADAPTOR_REGISTERED_NAME + '.Create'
export const INVOICE_CREATE_BATCH_PERMISSION = RECORDKEEPER_ADAPTOR_REGISTERED_NAME + '.CreateBatch'
export const INVOICE_DELETE_PERMISSION = RECORDKEEPER_ADAPTOR_REGISTERED_NAME + '.Delete'
export const INVOICE_FIND_PERMISSION = RECORDKEEPER_ADAPTOR_REGISTERED_NAME + '.Find'
export const INVOICE_RESTORE_PERMISSION = RECORDKEEPER_ADAPTOR_REGISTERED_NAME + '.Restore'
export const INVOICE_RETRIEVE_PERMISSION = RECORDKEEPER_ADAPTOR_REGISTERED_NAME + '.Retrieve'
export const INVOICE_UPDATE_PERMISSION = RECORDKEEPER_ADAPTOR_REGISTERED_NAME + '.Update'
export const INVOICE_VALIDATE_PERMISSION = RECORDKEEPER_ADAPTOR_REGISTERED_NAME + '.Validate'
export const INVOICE_VALIDATE_BATCH_PERMISSION = RECORDKEEPER_ADAPTOR_REGISTERED_NAME + '.ValidateBatch'
export const INVOICE_RETRIEVE_HISTORY_PERMISSION = RECORDKEEPER_ADAPTOR_REGISTERED_NAME + '.RetrieveHistory'
//Linker services
export const INVOICE_GET_ACCOUNT_INVOICES_PERMISSION = LINKER_ADAPTOR_REGISTERED_NAME + '.GetAccountInvoices'
export const INVOICES_GET_INVOICES_ACCOUNTS_TRADES_PERMISSION = LINKER_ADAPTOR_REGISTERED_NAME + '.GetInvoiceAccountsTrades'
export const INVOICES_GET_LINK_DETAIL_PERMISSION = LINKER_ADAPTOR_REGISTERED_NAME + '.GetLinksDetail'
export const INVOICES_REMOVE_ACCOUNT_LINK_PERMISSION = LINKER_ADAPTOR_REGISTERED_NAME + '.RemoveAccountLink'
export const INVOICES_REMOVE_TRADE_LINK_PERMISSION = LINKER_ADAPTOR_REGISTERED_NAME + '.RemoveTradeLink'
export const INVOICES_SET_ACCOUNT_LINK_PERMISSION = LINKER_ADAPTOR_REGISTERED_NAME + '.SetAccountLink'
export const INVOICES_SET_BATCH_ACCOUNT_LINK_PERMISSION = LINKER_ADAPTOR_REGISTERED_NAME + '.SetBatchAccountLink'
export const INVOICES_SET_BATCH_TRADE_LINK_PERMISSION = LINKER_ADAPTOR_REGISTERED_NAME + '.SetBatchTradeLink'
export const INVOICES_SET_TRADE_LINK_PERMISSION = LINKER_ADAPTOR_REGISTERED_NAME + '.SetTradeLink'

