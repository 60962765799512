import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Overview from './Overview'
import CurrencyEntity from 'popcorn-js/financial/currency/Currency'
import CountryRecordkeeper from 'popcorn-js/country/recordkeeper'
import CountryEntity from '../../../popcorn-js/country/Country'

function mapStateToProps(state) {
  return {
    securityClaims: state.security.claims,
    currencies: state.currency.records,
  }
}

const mapDispatchToProps = () => {
  return {}
}

class OverviewContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      countries: [],
    }

  }

  findSystemCountries() {
    // Send the find request
    CountryRecordkeeper.find()
      .then(result => {
        // Map to country entities
        const countries = result.records.map(country => new CountryEntity(country))
        this.setState({countries: countries})
      })
      .catch(error => this.setState({errorMessage: error.message || error}))
  }

  componentDidMount() {
    this.findSystemCountries()
  }

  render() {
    return (
      <div>
        <Overview
          countries={this.state.countries}
          currencies={this.props.currencies}
          partyCode={this.props.securityClaims.context.partyCode}
          partyType={this.props.securityClaims.context.partyType}
        />
      </div>
    )
  }
}

OverviewContainer.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.instanceOf(CurrencyEntity)),
  securityClaims: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewContainer)
