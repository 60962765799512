import EntryComparator from 'popcorn-js/audit/EntryComparator'
import {Country as CountryEntity} from 'popcorn-js/country'

class Comparator {

  /**
   * @param {Country} a
   * @param {Country} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareExcluding(a, b, ...args) {
    return !(
      (a.id !== b.id && !args.includes('id')) ||
      (a.name !== b.name && !args.includes('name')) ||
      (a.code !== b.code && !args.includes('code')) ||
      !EntryComparator.CompareExcluding(a.auditEntry, b.auditEntry, ...args.filter(a => a.includes('auditEntry.')).map(a => a.replace('auditEntry.', '')))
    )
  }

  /**
   * @param {Country} a
   * @param {Country} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareOnly(a, b, ...args) {
    for (const field in args) {
      if (args[field].includes('auditEntry.')) {
        if (!EntryComparator.CompareOnly(a.auditEntry, b.auditEntry, args[field].replace('auditEntry.', ''))) {
          return false
        }
      } else if (a[args[field]] !== b[args[field]]) {
        return false
      }
    }
    return true
  }

  /**
   * @param {Country} a
   * @param {Country} b
   * @return {boolean}
   */
  static CompareAll(a, b) {
    if (!(a instanceof CountryEntity)) {
      console.error('Country comparator cannot compare objects: invalid type')
      return false
    }
    if (!(b instanceof CountryEntity)) {
      console.error('Country comparator cannot compare objects: invalid type')
      return false
    }

    return !(
      (a.id !== b.id) ||
      (a.name !== b.name) ||
      (a.code !== b.code) ||
      !EntryComparator.CompareAll(a.auditEntry, b.auditEntry)
    )

  }
}

export default Comparator
