import {HexToRGBA} from '../../utils/Utils'

export const MuiTableCell = theme => ({
  body: {
    color: HexToRGBA(theme.palette.text.primary, 0.5),
  },
  root: {
    borderBottomColor: HexToRGBA(theme.palette.text.primary, 0.2),
  },
})
