import {
  FIND_ALL_MY_PERMISSIONS_SUCCESS,
  RETRIEVE_MY_USER_PROFILE_SUCCESS,
  RETRIEVE_MY_USER_SUCCESS,
} from '../actions/actionTypes'
import {UPDATE_USER_SUCCESS} from 'actions/actionTypes'

export default function myUser(state = {}, action) {
  switch (action.type) {
    case RETRIEVE_MY_USER_SUCCESS:
      return {
        ...state,
        user: action.result.user,
      }
    case FIND_ALL_MY_PERMISSIONS_SUCCESS:
      return {...state, permissions: action.result.permissions}
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        user: action.result.user,
        action: {...state.action, updated: action.result.user, error: undefined},
      }
    }
    case RETRIEVE_MY_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        userProfile: {...action.result}
      }
    }
    default:
      return state
  }
}
