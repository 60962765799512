import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  withStyles, Select, MenuItem,
} from '@material-ui/core'
import {TEXT_CRITERION} from 'popcorn-js/search/criteria/types'
import {isFunction} from 'utils/Utils'

const styles = (theme) => ({

})

class TextOptions extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    if (props.initValue === undefined) {
      this.state.value = {
        type: TextOptions.CriteriaType,
        value: {
          field: props.fieldID,
          text: '',
        }
      }
    } else {
      this.state.value = props.initValue
    }
  }

  static CriteriaType = TEXT_CRITERION

  onChange = (newTextVal) => {
    let {
      value,
    } = this.state
    const {
      onChange
    } = this.props

    value = {
      ...value,
      value: {
        ...value.value,
        text: newTextVal
      }
    }

    if (isFunction(onChange)) {
      if ((newTextVal === '')) {
        onChange(undefined)
      } else {
        onChange(value)
      }
    }

    this.setState({
      value
    })
  }

  render () {
    const {
      options,
      accessor,
      filterConfig,
    } = this.props
    const {
      value,
    } = this.state

    const displayAccessor = filterConfig.displayAccessor === undefined
      ? filterConfig.accessor
      : filterConfig.displayAccessor

    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          justifyItems: 'center',
          gridTemplateRows: '1fr',
        }}>
        <div
          style={{
            alignSelf: 'center',
          }}
        >
          <Select
            onChange={(e) => this.onChange(e.target.value)}
            value={value.value.text}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            {options.map((option, idx) => {
              return <MenuItem
                key={idx}
                value={option[accessor]}
              >
                {option[displayAccessor]}
              </MenuItem>
            })}
          </Select>
        </div>
      </div>
    )
  }

}

const styledText = withStyles(styles)(TextOptions)

styledText.propTypes = {
  // Not required, will be used to set value on construction
  accessor: PropTypes.string.isRequired,
  fieldID: PropTypes.string.isRequired,
  filterConfig: PropTypes.object,
  initValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
}

export default TextOptions
