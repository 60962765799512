import React from 'react'
import {IconButton, Tooltip} from '@material-ui/core';

import {Cancel,} from '@material-ui/icons'

export const discardAction = onClick => (
  <Tooltip
    key={'discard'}
    title={'Discard changes'}>
    <IconButton onClick={onClick}>
      <Cancel/>
    </IconButton>
  </Tooltip>
)
