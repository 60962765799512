import React from 'react';

import moment from 'moment';

// Material UI components
import {
  withStyles,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Input,
  Tooltip,
  Dialog,
  Theme,
} from '@material-ui/core';

// Icons
import {
  MdClear as CloseIcon,
  MdModeEdit as EditIcon,
  MdRestore,
} from 'react-icons/md';
import {
  BackIcon,
  CancelIcon,
  DeLinkIcon,
  LinkIcon,
  SaveIcon,
} from 'components/Icons';

// Service providers
import Handler from 'popcorn-js/financial/order/handler';
import OrderLinker from 'popcorn-js/financial/order/linker';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import InvoiceRecordkeeper from 'popcorn-js/financial/invoice/recordkeeper';
import OrderValidator from 'popcorn-js/financial/order/validator';
import Manager from 'popcorn-js/legalEntity/user/manager';
import { financialYears } from 'popcorn-js/financial';

// Custom components
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert';
import OrderHistory from 'views/History/OrderHistory/OrderContainer';
import {
  TextField as ParsedTextField,
  TextFieldParseTypes,
} from 'components/FormContols/index';
import { AuditEntry, ItemWrapper } from './components';
import InvoiceDetailDialog from 'components/Detail/invoice/InvoiceDetailDialog';

// Constants
import {
  ALL_ORDER_STATUSES,
  ORDER_STATUS_CONFIRMED,
  ORDER_STATUS_PAID,
} from 'popcorn-js/financial/order/orderType';
import {
  ExactCriterion,
  TextCriterion,
} from 'popcorn-js/search/criteria/types';
import { Invoice as InvoiceEntity } from 'popcorn-js/financial/invoice/Invoice';

// 3rd Party components
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

// helpers
import { HexToRGBA, objectCopy, processUnixDateForViewing } from 'utils/Utils';
import { EntityList } from '../invoice/components';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Order,
  orderTypeOptions,
} from '../../../popcorn-js/financial/order/orderType';

const styles = (theme: Theme) => ({
  root: {
    border: `solid 2px ${theme.palette.primary.light}`,
  },
  layout: {
    marginBottom: theme.spacing(2),
  },
  formFieldAccent: {
    color: theme.palette.customText.accent,
  },
  formFieldGreen: {
    fontWeight: 'bold',
    color: theme.palette.data.data3B,
  },
  title: {
    backgroundColor: HexToRGBA('#ffffff', 0.1),
    color: theme.palette.text.primary,
    padding: theme.spacing(),
    border: '1px solid #26283c',
    marginBottom: theme.spacing(),
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(),
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 17,
    marginRight: '0px',
    marginTop: '0px',
  },
  action: {
    color: 'red',
    marginRight: '0px',
    marginTop: '0px',
  },
  leftAlign: {
    paddingLeft: theme.spacing(),
  },
  fieldUnderline: {
    '&:before': {
      borderBottom: '1px solid #4B4D5E',
    },
  },
  listHeader: {
    backgroundColor: HexToRGBA('#ffffff', 0.1),
    color: theme.palette.text.primary,
    padding: theme.spacing(),
    border: '1px solid #26283c',
  },
  noRecordsMessageContainer: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  noRecordsMessage: {
    minWidth: '211px',
    padding: theme.spacing(),
    color: HexToRGBA(theme.palette.text.primary, 0.5),
  },
  noRecordsActions: {
    padding: theme.spacing(),
  },
  progress: {
    color: theme.palette.customText.label,
  },
  progressSpinnerDialog: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  progressSpinnerDialogBackdrop: {
    backgroundColor: 'transparent',
  },
});

const selectStyles = (theme: Theme) => ({
  valueContainer: (styles: any) => ({
    ...styles,
    border: 'none',
    padding: '0 4px',
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: '6px',
  }),
  option: (styles: any, { isSelected }: { isSelected: boolean }) => ({
    ...styles,
    border: 'none',
    backgroundColor: isSelected
      ? theme.palette.primary.light
      : theme.palette.primary.main,
  }),
  control: (styles: any) => ({
    ...styles,
    minHeight: '1px',
    padding: '0',
    borderRadius: '0',
    border: 'none',
    backgroundColor: 'none',
    width: 180,
    borderBottom: '1px solid #4B4D5E',
  }),
  menu: (styles: any) => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
  }),
  singleValue: (styles: any, { data }: { data: any }) => ({
    ...styles,
    color:
      data.label === 'start typing...'
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
    fontStyle: data.label === 'start typing...' ? 'italic' : '',
  }),
  input: (styles: any) => ({
    ...styles,
    padding: '0',
    margin: '0',
    color: theme.palette.text.primary,
  }),
});

const activeStates = {
  viewing: 'viewing',
  editing: 'editing',
};

const statusOptions = ALL_ORDER_STATUSES.map((status) => ({
  label: status,
  value: status,
}));

const financialYearOptions = financialYears.map((financialYear) => ({
  value: financialYear,
  label: financialYear,
}));

type OrderDetailState = {
  activeState: string;
  loading: boolean;
  order: Order | any;
  originalOrder: Order | any;
  counterpartyCache: Array<any>;
  invoiceCache: Array<any>;
  invalidFields: Record<any, any>;
  successMessage: string | undefined;
  errorMessage: string | undefined;
  warningMessage: string | undefined;
  confirmationMethod: () => void;
  createLink: boolean;
  invoiceIdToLinkOption: any;
  defaultInvoiceOptions: Array<any>;
  defaultCounterpartyOptions: Array<any>;
  orderInvoice: any;
  orderCounterparty: any;
  showHistory: boolean;
  showInvoiceDetail: boolean;
};

type OrderDetailProps = {
  counterparties: [];
  currencies: [];
  partyCode: '';
  onClose: () => null;
  onSaveSuccess: () => void;
  classes: any;
  isNew: any;
  order: any;
  readOnly: boolean;
  theme: any;
};

class OrderDetail extends React.Component<OrderDetailProps, OrderDetailState> {
  state: Readonly<OrderDetailState> = {
    activeState: activeStates.viewing,
    loading: false,
    successMessage: undefined,
    errorMessage: undefined,
    warningMessage: undefined,
    confirmationMethod: () => null,
    order: {},
    originalOrder: {},
    counterpartyCache: [],
    invoiceCache: [],
    invalidFields: {},
    createLink: false,
    invoiceIdToLinkOption: {},
    defaultInvoiceOptions: [],
    defaultCounterpartyOptions: [],
    orderInvoice: {},
    orderCounterparty: {},
    showHistory: false,
    showInvoiceDetail: false,
  };

  componentDidMount() {
    const { order } = this.props;
    this.setOrder(order);
  }

  setOrder = (order: Order) => {
    const { isNew, partyCode } = this.props;
    this.setState({
      loading: true,
    });

    let selectedOrder: Order;
    if (isNew) {
      selectedOrder = {
        partyCode,
        type: order.type,
      };
    } else {
      selectedOrder = order;
    }

    this.setState(
      {
        order: selectedOrder,
        originalOrder: objectCopy(selectedOrder),
        activeState: isNew ? activeStates.editing : activeStates.viewing,
        loading: false,
      },
      isNew
        ? () => null
        : () => {
          this.setOrderCounterparty();
          this.setOrderInvoice();
          if (!isNew) {
            this.processEntityForViewing();
          }
        }
    );
  };

  processEntityForViewing = async () => {
    const order = objectCopy(this.state.order);
    try {
      const getUsernameResponse = await Manager.getUserProfileById(
        order.auditEntry.userId
      );
      order.auditEntry.username = getUsernameResponse.displayName;
    } catch (e) {
      order.auditEntry.username = 'SYSTEM';
    }
    this.setState({
      order,
      originalOrder: objectCopy(order),
    });
  };

  switchToEditing = async () => {
    this.setState({ activeState: activeStates.editing });
    const counterpartyOptions = await this.generateCounterpartyOptions('');
    this.setState({ defaultCounterpartyOptions: counterpartyOptions });
  };

  setOrderCounterparty = async () => {
    const { order, counterpartyCache, orderCounterparty } = this.state;
    const counterpartyId = order.counterpartyId;

    if (!counterpartyId) {
      // order counterparty not set
      return;
    }
    if (orderCounterparty.id === counterpartyId) {
      // unchanged
      return;
    }

    const counterparty = counterpartyCache.find((b) => b.id === counterpartyId);
    if (counterparty) {
      // counterparty found in counterparty cache
      this.setState({ orderCounterparty: counterparty });
    }
    this.setState({ loading: true });
    try {
      const retrieveResponse = await CounterpartyRecordkeeper.retrieve({
        id: counterpartyId,
      });
      this.setState({
        orderCounterparty: retrieveResponse.counterparty,
      });
    } catch (e) {
      console.error('Could not retrieve counterparty: ', e.message || e);
    }
    this.setState({ loading: false });
  };

  setOrderInvoice = async () => {
    const { order, invoiceCache, orderInvoice } = this.state;
    const invoiceId = order.invoiceId;
    if (!invoiceId) {
      // order invoice not set
      return;
    }

    if (orderInvoice.id === invoiceId) {
      // order invoice hasn't changed
      return;
    }

    const invoice = invoiceCache.find((inv) => inv.id === invoiceId);
    if (invoice) {
      // invoice found in the invoice cache
      this.setState({ orderInvoice: invoice });
      return;
    }
    this.setState({ loading: true });
    try {
      const retrieveResponse = await InvoiceRecordkeeper.retrieve({
        identifier: { id: invoiceId },
      });
      this.setState({ orderInvoice: retrieveResponse.invoice });
    } catch (e) {
      console.error('Could not retrieve invoice: ', e.message || e);
    }
    this.setState({ loading: false });
  };

  validate = async () => {
    const { order } = this.state;

    try {
      const validateResponse = await OrderValidator.ValidateData(order);
      if ((validateResponse.invalidOrder.reasons || []).length !== 0) {
        const invalidFields: Record<any, any> = {};
        for (const reason of validateResponse.invalidOrder.reasons) {
          invalidFields[reason.field] = reason.reason;
        }
        this.setState({ invalidFields });
        return false;
      } else {
        return true;
      }
    } catch (e) {
      this.setState({
        errorMessage: e.message || e,
      });
    }
  };

  onSave = async () => {
    if (await this.validate()) {
      const { order } = this.state;
      const { isNew } = this.props;
      if (isNew) {
        try {
          await Handler.Record({ order });
          this.props.onSaveSuccess
            ? this.props.onSaveSuccess()
            : this.setState({ successMessage: 'Order Created' });
        } catch (e) {
          this.setState({ errorMessage: e.message || e });
        }
      } else {
        try {
          const updateResponse = await Handler.Update({
            order: order,
            identifier: { id: order.id },
          });
          this.setOrder(updateResponse.order);
          this.props.onSaveSuccess
            ? this.props.onSaveSuccess()
            : this.setState({ successMessage: 'Updated' });
        } catch (e) {
          this.setState({ errorMessage: e.message || e });
        }
      }
    } else {
      this.setState({ errorMessage: 'Not all fields are set' });
    }
  };

  showDiscardChangesConfirmation = (functionAfterDiscard: () => void) => {
    this.setState({
      warningMessage: 'Would you like to discard all changes?',
      confirmationMethod: functionAfterDiscard,
    });
  };

  handleDiscardChangesAndView = () => {
    this.setState({
      order: objectCopy(this.state.originalOrder),
      warningMessage: undefined,
      confirmationMethod: () => null,
      activeState: activeStates.viewing,
    });
  };

  renderIcons = () => {
    const { activeState, createLink } = this.state;
    const { isNew, order } = this.props;
    const items = [];
    let deleted;
    try {
      deleted = (order || {}).auditEntry.action === 'DELETED';
    } catch (e) {
      console.error('could not determine audit entry action');
      deleted = true;
    }

    let i = 0;
    const getKey = () => {
      i++;
      return i;
    };

    switch (activeState) {
      case activeStates.editing:
        items.push(
          <Tooltip
            key={getKey()}
            title={'Save Changes'}>
            <IconButton onClick={this.onSave}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        );
        if (!isNew) {
          items.push(
            <Tooltip
              key={getKey()}
              title={'Cancel'}>
              <IconButton
                onClick={() => {
                  this.showDiscardChangesConfirmation(
                    this.handleDiscardChangesAndView
                  );
                }}
              >
                <BackIcon />
              </IconButton>
            </Tooltip>
          );
        }
        break;

      case activeStates.viewing:
        items.push(
          <Tooltip
            key={getKey()}
            title={'View History'}>
            <IconButton onClick={() => this.setState({ showHistory: true })}>
              <MdRestore />
            </IconButton>
          </Tooltip>
        );
        !createLink &&
          items.push(
            <Tooltip
              key={getKey()}
              title={'Edit'}>
              <IconButton
                disabled={deleted || this.props.readOnly}
                onClick={() => this.switchToEditing()}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          );
        break;

      default:
    }
    items.push(
      <Tooltip
        key={getKey()}
        title={'Close'}>
        <IconButton onClick={this.props.onClose}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    );
    return items;
  };

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: () => null,
    });
  };

  initiateLinking = async () => {
    this.setState({ createLink: true });
    const result = await this.generateInvoiceOptions('');
    this.setState({ defaultInvoiceOptions: result });
  };

  delinkInvoice = async () => {
    const { order } = this.state;

    try {
      const unlinkResponse = await OrderLinker.unlinkFromInvoice(order.id);
      this.setState(
        {
          order: unlinkResponse.order,
          originalOrder: objectCopy(order),
          orderInvoice: {},
          successMessage: 'Linked Removed',
          invoiceIdToLinkOption: {},
        },
        () => this.props.onSaveSuccess()
      );
    } catch (e) {
      this.setState({ errorMessage: e.message || e });
    }
  };

  saveLink = async () => {
    const { invoiceIdToLinkOption } = this.state;
    const { order } = this.state;
    try {
      const linkResponse = await OrderLinker.linkToInvoice(
        order.id,
        invoiceIdToLinkOption.value
      );
      order.invoiceId = linkResponse.order.invoiceId;
      this.setState(
        {
          order,
          originalOrder: objectCopy(order),
          successMessage: 'Link Established',
        },
        () => {
          this.cancelLinking();
          this.setOrderInvoice();
          this.props.onSaveSuccess();
        }
      );
    } catch (e) {
      this.setState({ errorMessage: e.message || e });
    }
  };

  cancelLinking = () => {
    this.setState({ createLink: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.root}>
        <CardHeader
          action={this.renderIcons()}
          classes={{
            root: classes.cardHeader,
            action: classes.action,
            title: classes.cardTitle,
          }}
          title={'Order Details'}
        />
        <div>{this.renderLayout()}</div>
        {this.renderDialogs()}
      </Card>
    );
  }

  renderDialogs = () => {
    const {
      showHistory,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      orderInvoice,
      showInvoiceDetail,
      order,
      loading,
    } = this.state;

    const { classes, counterparties } = this.props;

    return (
      <span>
        {showHistory && (
          <OrderHistory
            onHide={() =>
              this.setState({
                showHistory: false,
              })
            }
            open={showHistory}
            order={order}
          />
        )}
        {showInvoiceDetail && (
          <InvoiceDetailDialog
            counterparties={counterparties}
            invoice={new InvoiceEntity(orderInvoice)}
            onClose={() => this.setState({ showInvoiceDetail: false })}
            readOnly
            show
          />
        )}
        {loading && (
          <Dialog
            BackdropProps={{
              classes: { root: classes.progressSpinnerDialogBackdrop },
            }}
            PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
            className={classes.loading}
            open={loading}
          >
            <CircularProgress className={classes.progress} />
          </Dialog>
        )}
        <NotificationSweetAlert
          customClass={'configAlert'}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}
          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </span>
    );
  };

  editOrder = (field: string, newValue: any) => {
    const { order, invalidFields } = this.state;

    let updateAssociation: any = () => null;

    switch (field) {
      case 'counterpartyId': {
        updateAssociation = this.setOrderCounterparty;
        break;
      }
      default:
    }

    order[field] = newValue;
    invalidFields[field] = undefined;

    this.setState({ order, invalidFields }, () => updateAssociation());
  };

  generateCounterpartyOptions = async (inputValue: any) => {
    const criteria = [
      new TextCriterion({ text: inputValue, field: 'name' }),
      new ExactCriterion({ text: this.props.partyCode, field: 'partyCode' }),
    ];
    try {
      const findResult = await CounterpartyRecordkeeper.find({
        criteria: criteria,
        query: undefined,
        Deleted: false,
      });
      this.setState({ counterpartyCache: findResult.records });
      return (findResult.records || []).map((b: any) => ({
        value: b.id,
        label: b.name,
      }));
    } catch (e) {
      throw e.message || e;
    }
  };

  generateInvoiceOptions = async (inputValue: any) => {
    const { order } = this.state;

    const criteria = [
      new TextCriterion({
        text: inputValue,
        field: 'number',
      }),
      new ExactCriterion({
        text: this.props.partyCode,
        field: 'partyCode',
      }),
      new ExactCriterion({
        field: 'currencyId',
        text: order.currencyId,
      }),
      new ExactCriterion({
        field: 'costCurrencyId',
        text: order.costCurrencyId,
      }),
    ];
    const query = {
      limit: 20,
    };

    try {
      const findResponse = await InvoiceRecordkeeper.find({
        criteria: criteria,
        query: query,
      });
      this.setState({ invoiceCache: findResponse.records });
      return (findResponse.records || []).map((b: any) => ({
        value: b.id,
        label: b.number,
      }));
    } catch (e) {
      throw e.message || e;
    }
  };

  renderLayout = () => {
    const { currencies, classes } = this.props;

    const {
      activeState,
      order,
      invalidFields,
      createLink,
      invoiceIdToLinkOption,

      defaultInvoiceOptions,
      defaultCounterpartyOptions,

      orderInvoice,
      orderCounterparty,
    } = this.state;

    const currencyOptions = currencies.map((c: any) => ({
      value: c.id,
      label: c.isoCode,
    }));

    const readOnly = activeState === activeStates.viewing;
    const currencySymbol = (
      currencies.find((c: any) => c.id === order?.currencyId) || { symbol: '' }
    ).symbol;

    let deleted;
    try {
      deleted = (order || {}).auditEntry.action === 'DELETED';
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('could not determine audit entry action');
      deleted = true;
    }

    const inputProps = {
      classes: {
        underline: classes.fieldUnderline,
      },
      disableUnderline: readOnly,
      readOnly: readOnly,
    };

    return (
      <div>
        <Grid
          className={classes.layout}
          container
          direction={'row'}
          spacing={2}
        >
          <Grid
            item
            lg={this.props.isNew ? 12 : 6}
            md={this.props.isNew ? 12 : 6}
            sm={12}
            xs={12}
          >
            <div className={classes.title}>Order Information</div>
            <ItemWrapper
              error={invalidFields.type}
              label={'Type'}>
              {readOnly ? (
                <Input
                  className={classes.formField}
                  disableUnderline
                  id="type"
                  readOnly
                  value={order?.type || ''}
                />
              ) : (
                <Select
                  InputProps={{
                    ...inputProps,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  menuPosition={'fixed'}
                  onChange={(selected: any) => {
                    this.editOrder('type', selected.value);
                  }}
                  options={orderTypeOptions.map((status) => ({
                    label: status,
                    value: status,
                  }))}
                  styles={selectStyles(this.props.theme)}
                  value={{
                    label: order?.type,
                    value: order?.type,
                  }}
                />
              )}
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.number}
              label={'Number'}>
              <ParsedTextField
                InputProps={inputProps}
                id="number"
                onChange={(e: any) => this.editOrder('number', e.target.value)}
                parseType={TextFieldParseTypes.string}
                value={order?.number || ''}
              />
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.counterpartyId}
              label={'Counterparty'}
            >
              {readOnly ? (
                <Input
                  className={classes.formField}
                  disableUnderline
                  id="counterparty"
                  readOnly
                  value={
                    order?.counterpartyId !== ''
                      ? orderCounterparty.name || order?.counterpartyId
                      : ''
                  }
                />
              ) : (
                <AsyncSelect
                  defaultOptions={defaultCounterpartyOptions}
                  disableUnderline={readOnly}
                  id="counterparty"
                  loadOptions={this.generateCounterpartyOptions}
                  menuPosition={'fixed'}
                  onChange={(selected) =>
                    this.editOrder('counterpartyId', selected.value)
                  }
                  styles={selectStyles(this.props.theme)}
                  value={{
                    value: order?.counterpartyId,
                    label: orderCounterparty.name || order?.counterpartyId,
                  }}
                />
              )}
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.amountDue}
              label={'Amount Due'}>
              <ParsedTextField
                InputProps={(() => {
                  return {
                    ...inputProps,
                    startAdornment: currencySymbol,
                    className: classNames(
                      classes.formFieldAccent,
                      classes.leftAlign
                    ),
                  };
                })()}
                id="amountDue"
                onChange={(e: any) =>
                  this.editOrder('amountDue', e.target.value)
                }
                parseType={TextFieldParseTypes.float}
                value={order?.amountDue}
              />
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.status}
              label={'Status'}>
              {readOnly ? (
                <Input
                  className={classNames({
                    [classes.formFieldGreen]:
                      order?.status === ORDER_STATUS_PAID,
                    [classes.formFieldAccent]:
                      order?.status === ORDER_STATUS_CONFIRMED,
                  })}
                  disableUnderline
                  id="status"
                  readOnly
                  value={order?.status || ''}
                />
              ) : (
                <Select
                  InputProps={{
                    ...inputProps,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  menuPosition={'fixed'}
                  onChange={(selected: any) =>
                    this.editOrder('status', selected.value)
                  }
                  options={statusOptions}
                  styles={selectStyles(this.props.theme)}
                  value={{
                    label: order?.status,
                    value: order?.status,
                  }}
                />
              )}
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.financialYear}
              label={'Financial Year'}
            >
              {readOnly ? (
                <Input
                  className={classes.formField}
                  disableUnderline
                  id="financialYear"
                  readOnly
                  value={order?.financialYear || ''}
                />
              ) : (
                <Select
                  InputProps={{
                    ...inputProps,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  menuPosition={'fixed'}
                  onChange={(selected: any) =>
                    this.editOrder('financialYear', selected.value)
                  }
                  options={financialYearOptions}
                  styles={selectStyles(this.props.theme)}
                  value={{
                    label: order?.financialYear,
                    value: order?.financialYear,
                  }}
                />
              )}
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.dueDate}
              label={'Due Date'}>
              <Input
                className={classes.formField}
                disableUnderline={readOnly}
                id="dueDate"
                inputProps={{
                  max: '9999-12-31',
                  style: {
                    paddingLeft: readOnly ? undefined : '21px',
                    position: readOnly ? undefined : 'relative',
                    left: readOnly ? undefined : '-16px',
                  },
                }}
                onChange={(e) =>
                  this.editOrder('dueDate', moment.utc(e.target.value).unix())
                }
                readOnly={readOnly}
                style={{
                  borderBottomColor: readOnly ? undefined : 'gray',
                  borderBottomWidth: readOnly ? undefined : '1px',
                  borderBottomStyle: readOnly ? undefined : 'solid',
                }}
                type={readOnly ? 'text' : 'date'}
                value={processUnixDateForViewing(order?.dueDate)}
              />
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.currencyId}
              label={'Currency'}>
              {readOnly ? (
                <Input
                  className={classes.formField}
                  disableUnderline
                  id="currencyId"
                  readOnly
                  value={
                    (
                      currencies.find(
                        (c: any) => c.id === order?.currencyId
                      ) || { isoCode: '' }
                    ).isoCode || ''
                  }
                />
              ) : (
                <Select
                  InputProps={{
                    ...inputProps,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  menuPosition={'fixed'}
                  onChange={(selected: any) =>
                    this.editOrder('currencyId', selected.value)
                  }
                  options={currencyOptions}
                  styles={selectStyles(this.props.theme)}
                  value={{
                    value: order?.currencyId,
                    label:
                      (
                        currencies.find(
                          (c: any) => c.id === order?.currencyId
                        ) || { isoCode: '' }
                      ).isoCode || '',
                  }}
                />
              )}
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.costCurrencyId}
              label={'Cost Currency'}
            >
              {readOnly ? (
                <Input
                  className={classes.formField}
                  disableUnderline
                  id="costCurrencyId"
                  readOnly
                  value={
                    (
                      currencies.find(
                        (c: any) => c.id === order?.costCurrencyId
                      ) || { isoCode: '' }
                    ).isoCode || ''
                  }
                />
              ) : (
                <Select
                  InputProps={{
                    ...inputProps,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  menuPosition={'fixed'}
                  onChange={(selected: any) =>
                    this.editOrder('costCurrencyId', selected.value)
                  }
                  options={currencyOptions}
                  styles={selectStyles(this.props.theme)}
                  value={{
                    value: order?.costCurrencyId,
                    label:
                      (
                        currencies.find(
                          (c: any) => c.id === order?.costCurrencyId
                        ) || { isoCode: '' }
                      ).isoCode || '',
                  }}
                />
              )}
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.costingRate}
              label={'Costing Rate'}
            >
              <ParsedTextField
                InputProps={inputProps}
                id="costingRate"
                onChange={(e: any) =>
                  this.editOrder('costingRate', e.target.value)
                }
                parseType={TextFieldParseTypes.float}
                value={order?.costingRate || 0.0}
              />
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.captureRate}
              label={'Capture Rate'}
            >
              <ParsedTextField
                InputProps={inputProps}
                id="captureRate"
                onChange={(e: any) =>
                  this.editOrder('captureRate', e.target.value)
                }
                parseType={TextFieldParseTypes.float}
                value={order?.captureRate || 0.0}
              />
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.shippingDate}
              label={'Shipping Date'}
            >
              <Input
                className={classes.formField}
                disableUnderline={readOnly}
                id="shippingDate"
                inputProps={{
                  max: '9999-12-31',
                }}
                onChange={(e) =>
                  this.editOrder(
                    'shippingDate',
                    moment.utc(e.target.value).unix()
                  )
                }
                readOnly={readOnly}
                type={readOnly ? 'text' : 'date'}
                value={processUnixDateForViewing(order?.shippingDate)}
              />
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.issueDate}
              label={'Issue Date'}>
              <Input
                className={classes.formField}
                disableUnderline={readOnly}
                id="issueDate"
                inputProps={{
                  max: '9999-12-31',
                }}
                onChange={(e) =>
                  this.editOrder('issueDate', moment.utc(e.target.value).unix())
                }
                readOnly={readOnly}
                type={readOnly ? 'text' : 'date'}
                value={processUnixDateForViewing(order?.issueDate)}
              />
            </ItemWrapper>
            <ItemWrapper
              error={invalidFields.shipmentReference}
              label={'Shipment Reference'}
            >
              <Input
                disableUnderline={readOnly}
                id="shipmentReference"
                onChange={(e) =>
                  this.editOrder('shipmentReference', e.target.value)
                }
                readOnly={readOnly}
                value={order?.shipmentReference || '#'}
              />
            </ItemWrapper>
          </Grid>
          {!this.props.isNew && (
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}>
              {(() => {
                if (order?.invoiceId === '') {
                  return (
                    <div>
                      <div className={classes.listHeader}>Linked Invoice</div>
                      <div className={classes.noRecordsMessageContainer}>
                        <div className={classes.noRecordsMessage}>
                          {(() => {
                            if (createLink) {
                              return (
                                <div
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns:
                                      'minmax(140px, 1fr) auto auto',
                                    marginRight: '-10px',
                                  }}
                                >
                                  <AsyncSelect
                                    defaultOptions={defaultInvoiceOptions}
                                    id="invoice"
                                    loadOptions={this.generateInvoiceOptions}
                                    menuPosition={'fixed'}
                                    onChange={(selected) =>
                                      this.setState({
                                        invoiceIdToLinkOption: selected,
                                      })
                                    }
                                    styles={selectStyles(this.props.theme)}
                                    value={{
                                      value: invoiceIdToLinkOption.value,
                                      label: invoiceIdToLinkOption.label,
                                    }}
                                  />
                                  <IconButton
                                    onClick={this.saveLink}
                                    style={{
                                      margin: '-10px -5px',
                                    }}
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={this.cancelLinking}
                                    style={{
                                      margin: '-10px -5px',
                                    }}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </div>
                              );
                            }
                            return <div>Linked Invoice</div>;
                          })()}
                        </div>
                        <div className={classes.noRecordsActions}>
                          {!createLink && (
                            <IconButton
                              disabled={deleted || !readOnly}
                              onClick={this.initiateLinking}
                              style={{
                                margin: '-10px -5px',
                              }}
                            >
                              <LinkIcon />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }

                return (
                  <EntityList
                    additionalActions={(() => {
                      if (!readOnly) {
                        return [];
                      }
                      if (order?.invoiceId === '') {
                        return [];
                      }
                      return [
                        <Tooltip
                          key={'view'}
                          title={'Unlink From Invoice'}>
                          <IconButton
                            onClick={this.delinkInvoice}
                            style={{
                              padding: '0px',
                            }}
                          >
                            <DeLinkIcon />
                          </IconButton>
                        </Tooltip>,
                      ];
                    })()}
                    columns={[
                      {
                        Header: 'Number',
                        accessor: 'number',
                      },
                    ]}
                    entities={orderInvoice.id ? [orderInvoice] : []}
                    listHeader={'Linked Invoice'}
                    noRecordsMessage={'No Linked Invoice'}
                    onClick={() =>
                      this.setState({
                        showInvoiceDetail: true,
                      })
                    }
                  />
                );
              })()}
              <AuditEntry order={order} />
            </Grid>
          )}
        </Grid>
      </div>
    );
  };
}

// @ts-ignore
export default withStyles(styles, { withTheme: true })(OrderDetail);
