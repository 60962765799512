import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import {loginSuccess, refreshSuccess} from './actions/authentication'
import AppContainer from './layouts/AppContainer'
import Authenticate from './Authenticate'
import Authenticator from 'popcorn-js/authenticator/authenticator'
import LoadingPage from './views/Pages/LoadingPage/LoadingPage'
import {useAuth0} from './utils/react-auth0-spa'

function mapStateToProps(state: any): Object {
  return {
    securityClaims: state.security.claims,
  }
}

const mapDispatchToProps = (dispatch: any): Object => {
  return {
    onRefreshSuccess: (claims: any): Function => dispatch(refreshSuccess(claims)),
    onLoginSuccess: (claims: any): Function => dispatch(loginSuccess(claims)),
  }
}

interface Props {
    onLoginSuccess: Function;
    onRefreshSuccess: Function;
    securityClaims: Object;
}

const AppWrapper: React.FC<Props> = (props: Props) => {
  const {
    loading: auth0Loading,
    logout: auth0Logout,
  } = useAuth0();

  
  const {onRefreshSuccess, onLoginSuccess, securityClaims} = props
  const [refreshing, setRefreshing] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)

  const doLogout = useCallback(() => {
    setLoggedIn(false)
    Authenticator.logout()
    auth0Logout({returnTo: `${window.location.origin}/logout`})
  }, [auth0Logout])
  // when the component is mounted (i.e., when the app loads/refreshes), it attempts to refresh the token; if this
  // fails, the public routes will be loaded, including authentication
  const doRefresh = useCallback(async () => {
    try {
      const response = await Authenticator.refresh()
      onRefreshSuccess(response.claims)
      setLoggedIn(true)
    } catch (e) {
      setLoggedIn(false)
    }
    setRefreshing(false)
  }, [onRefreshSuccess])
  useEffect(() => {
    if (!auth0Loading) {
      doRefresh().finally()
    }
  }, [doRefresh, auth0Loading])

  // NB: can't load main app without securityClaims being set
  // so whether we're still refreshing, or waiting for the redux state to
  // update, the loading page will appear
  if ((loggedIn && securityClaims) && !(refreshing || auth0Loading)) {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            key={'app'}
            path={'/app/*'}
            render={(): ReactElement => (
              <AppContainer
                onError={(): void => doLogout()}
                onRefreshAccessToken={(): Promise<void> => doRefresh()}
                {...props} />
            )}
          />
          <Route
            render={(): any => <Redirect to={'/app/dashboard'}/>}
          />
        </Switch>
      </BrowserRouter>
    )
  } else if (!(loggedIn || refreshing || auth0Loading)) {
    return (
      <Authenticate
        onLoginSuccess={(resp: any): void => {
          onLoginSuccess(resp.claims)
          setLoggedIn(true)
        }}
      />
    )
  } else {
    return (
      <LoadingPage
        onDismiss={(): void => {
          setLoggedIn(false)
          Authenticator.logout()
          auth0Logout({returnTo: `${window.location.origin}/logout`})
        }}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
