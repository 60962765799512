import React, {Component} from 'react'
import PropTypes from 'prop-types'
import WithDragAndDropContext from 'components/DnD/WithDragDropContext'
import {isFunction} from 'utils/Utils'
import {ListItem, ListItemHolder,} from '../DnDListAssignSort'
import {Card, CardContent, Grid, withStyles,} from '@material-ui/core'

const styles = (theme) => ({
  root: {
    padding: 10,
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
  },
  list: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    justifyItems: 'center',
  },
  listTitle: {
    margin: 5,
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: 20,
  },
  listItemsWrapper: {
    boxShadow: 'inset 0 0 4px #000000',
    backgroundColor: theme.palette.primary.light,
    padding: 10,
  },
  listItemsInnerWrapper: {
    height: 200,
    width: 220,
    overflow: 'auto',
  }
})

class DnDListAssignSort extends Component {

  constructor(props) {
    super(props)
    this.state = {
      unassignedItems: this.props.unassignedItems,
      assignedItems: this.props.assignedItems,
      itemOnTheMove: undefined,
    }
  }

  itemDropped = (droppedItem, toAssigned, itemHolderIdx) => {
    const {
      accessor,
      onChange,
    } = this.props
    let {
      unassignedItems,
      assignedItems
    } = this.state

    let sourceList
    let destList
    if (toAssigned) {
      sourceList = unassignedItems
      destList = assignedItems
    } else {
      sourceList = assignedItems
      destList = unassignedItems
    }

    // Was the item dropped on the end ListItemHolder?
    const droppedAtEnd = itemHolderIdx === destList.length

    // Is item already in the destination list?
    let itemInDestList = false
    let existingItemIdx
    for (existingItemIdx = 0; existingItemIdx < destList.length; existingItemIdx++) {
      if (destList[existingItemIdx][accessor] === droppedItem[accessor]) {
        itemInDestList = true
        break
      }
    }

    // If item is already in destination list and it was not dropped at
    // it's existing position
    // i.e. If (itemHolderIdx === existingItemIdx) then do nothing
    if (itemInDestList && (itemHolderIdx !== existingItemIdx)) {
      // Remove Existing Item
      if (existingItemIdx === (destList.length - 1)) {
        destList = destList.slice(0, existingItemIdx)
      } else {
        destList = [...destList.slice(0, existingItemIdx), ...destList.slice(existingItemIdx + 1)]
      }

      // If Item dropped after existing item
      if (itemHolderIdx > existingItemIdx) {
        // Insert at itemHolderIdx - 1
        destList.splice(itemHolderIdx - 1, 0, droppedItem)
      } else {
        // Else Item dropped before existing item
        // Insert at itemHolderIdx
        destList.splice(itemHolderIdx, 0, droppedItem)
      }
    } else {
      // Item not in destination list

      // Remove item from source list
      sourceList = sourceList.filter(item => item[accessor] !== droppedItem[accessor])

      // Was item dropped at end?
      if (droppedAtEnd) {
        // If it was dropped at the end, push it to the destination list
        // (i.e add at end)
        destList.push(droppedItem)
      } else {
        // Otherwise it was dropped somewhere in the list,
        // add it at that index
        destList.splice(itemHolderIdx, 0, droppedItem)
      }
    }

    if (toAssigned) {
      unassignedItems = sourceList
      assignedItems = destList
    } else {
      assignedItems = sourceList
      unassignedItems = destList
    }

    if (isFunction(onChange)) {
      onChange({
        unassignedItems,
        assignedItems
      })
    } else {
      console.error('onChange prop given to DnDListAssignSort is not a function')
    }

    this.setState({
      unassignedItems,
      assignedItems
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      resetToggle: prevResetToggle,
    } = prevProps
    const {
      resetToggle,
      unassignedItems,
      assignedItems,
    } = this.props

    if (prevResetToggle !== resetToggle) {
      this.setState({
        unassignedItems,
        assignedItems,
      })
    }

  }

  render() {
    const {
      classes,
      accessor,
      sourceListTitle,
      destListTitle,
    } = this.props
    const {
      unassignedItems,
      assignedItems,
    } = this.state

    return (
      <div className={classes.root}>
        <Card>
          <CardContent>
            <Grid
              container
              direction={'row'}
              spacing={8}>
              <Grid item>
                <div className={classes.list}>
                  <div className={classes.listTitle}>
                    {sourceListTitle}
                  </div>
                  <div className={classes.listItemsWrapper}>
                    <div className={classes.listItemsInnerWrapper}>
                      {unassignedItems.map((item, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            <ListItemHolder
                              key={1000 + idx}
                              onItemDrop={(droppedItem) => {
                                this.itemDropped(droppedItem, false, idx)
                              }}
                            />
                            <ListItem
                              accessor={accessor}
                              key={2000 + idx}
                              listObject={item}
                            />
                          </React.Fragment>
                        )
                      })}
                      <ListItemHolder
                        holderHeight={unassignedItems.length ? 100 : 200}
                        onItemDrop={(droppedItem) => {
                          this.itemDropped(droppedItem, false, unassignedItems.length)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.list}>
                  <div className={classes.listTitle}>
                    {destListTitle}
                  </div>
                  <div className={classes.listItemsWrapper}>
                    <div className={classes.listItemsInnerWrapper}>
                      {assignedItems.map((item, idx) => {
                        return (
                          <React.Fragment key={3000 + idx}>
                            <ListItemHolder
                              key={4000 + idx}
                              onItemDrop={(droppedItem) => {
                                this.itemDropped(droppedItem, true, idx)
                              }}
                            />
                            <ListItem
                              accessor={accessor}
                              key={5000 + idx}
                              listObject={item}
                            />
                          </React.Fragment>
                        )
                      })}
                      <ListItemHolder
                        holderHeight={assignedItems.length ? 40 : 150}
                        onItemDrop={(droppedItem) => {
                          this.itemDropped(droppedItem, true, assignedItems.length)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    )
  }

}

DnDListAssignSort.propTypes = {
  /**
   * List Of Unassigned Objects
   */
  accessor: PropTypes.any.isRequired,
  /**
   * List Of Assigned Objects
   */
  assignedItems: PropTypes.array.isRequired,
  /**
   * String or function which will be used to get value
   * for display and filtering
   */
  destListTitle: PropTypes.string,
  /**
   * Title for the source list
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Title for the destination list
   */
  resetToggle: PropTypes.bool,
  /**
   * Function will be called and passed updated
   * unassignedItems and assignedItems lists on
   * each change
   */
  sourceListTitle: PropTypes.string,
  /**
   * Reset Toggle, used to implement a "reset"
   * functionality from parent
   */
  unassignedItems: PropTypes.array.isRequired,
}

DnDListAssignSort.defaultProps = {
  sourceListTitle: 'Available',
  destListTitle: 'Assigned',
}

const StyledDnDListAssignSort = withStyles(styles)(
  WithDragAndDropContext(DnDListAssignSort))

export default StyledDnDListAssignSort
