import {combineReducers} from 'redux'
import {LOGOUT} from 'actions/actionTypes'
import security from 'reducers/security'
import trade from 'reducers/trade'
import currency from 'reducers/currency'
import currencyPair from 'reducers/currencyPair'
import myUser from 'reducers/myUser'
import myParty from 'reducers/myParty'
import ui from './ui'
import notifications from './notifications'
import role from './role'
import popcorn from './popcorn'
import user from './user'
import rick from './rick'
import socketStatus from 'reducers/socketStatus'
import processingBank from 'reducers/processingBank'

const appReducer = combineReducers({
  security,
  ui,
  socketStatus,
  myParty,
  myUser,
  role,
  popcorn,
  currency,
  currencyPair,
  processingBank,
  trade,
  notifications,
  user: user,
  rick,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined
    localStorage.removeItem('claims')
  }

  return appReducer(state, action)
}

export default rootReducer
