export default class Base {
  static identifierType = 'NOT SET'

  constructor() {
    this._type = Base.identifierType
  }

  /**
   * @type {string}
   * @private
   */
  _type

  get type() {
    return this._type
  }

  /**
   * @type {*}
   * @private
   */
  _value

  get value() {
    return this._value
  }

  marshall() {
    return `${this.type}::${JSON.stringify(this.value)}`
  }
}
