import { Criteria } from 'popcorn-js/search';

export function marshalCriteria(criteria: Criteria): string[] | undefined {
  if (criteria == null) {
    return undefined;
  }
  const stringObjects = [];
  for (const criterion of criteria) {
    const cType = criterion.type;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const c: any = { ...criterion };
    delete c.type;
    stringObjects.push(cType + '::' + JSON.stringify(c));
  }
  return stringObjects;
}
