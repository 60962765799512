import React from 'react'
import Grid from '@material-ui/core/Grid'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {HexToRGBA} from 'utils/Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainedPrimary: {
      color: theme.palette.customColors.customBlueContrastText,
      fontWeight: 'bold',
      fontSize: '12px',
      backgroundColor: theme.palette.customColors.customBlue,
      '&:hover': {
        backgroundColor: HexToRGBA(theme.palette.customColors.customBlue, 0.6),
      },
    },
    buttonContainedPrimaryButton: {
      color: theme.palette.customColors.customBlueContrastText,
      fontWeight: 'bold',
      fontSize: '12px',
      width: '80px',
      backgroundColor: theme.palette.customColors.customBlue,
      '&:hover': {
        backgroundColor: HexToRGBA(theme.palette.customColors.customBlue, 0.6),
      },
    },
  }),
)


interface SplitButtonProps {
  disableDropdown: boolean,
  disabled: boolean,
  initialOption: string,
  menuOptions: Array<string>,
  onSelect: (arg: string) => void
}

const SplitButton: React.FC<SplitButtonProps> = (props: SplitButtonProps) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(props.menuOptions.findIndex(option => option === props.initialOption))

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLAnchorElement>, index: number) => {
    setSelectedIndex(index)
    setOpen(false)
    props.onSelect(props.menuOptions[index])
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    // eslint-disable-next-line
    // @ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  return (
    <Grid
      alignItems="center"
      container
      direction="column">
      <Grid
        item
        xs={12}>
        <ButtonGroup
          aria-label="split button"
          color="primary"
          ref={anchorRef}
          variant="contained">
          <Button
            classes={{
              containedPrimary: classes.buttonContainedPrimaryButton
            }}
            color={'primary'}
          >{props.menuOptions[selectedIndex]}</Button>
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            aria-label="select merge strategy"
            classes={{
              containedPrimary: classes.buttonContainedPrimary
            }}
            color={'primary'}
            disabled={props.disableDropdown}
            onClick={handleToggle}
            size="small"
          >
            <ArrowDropDownIcon/>
          </Button>
        </ButtonGroup>
        <Popper
          anchorEl={anchorRef.current}
          disablePortal
          open={open}
          role={undefined}
          style={{
            zIndex: 1,
          }}
          transition>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper
              >
                <ClickAwayListener onClickAway={(e):void => handleClose(e)}>
                  <MenuList id="split-button-menu">
                    {props.menuOptions.map((option, index) => (
                      <MenuItem
                        disabled={props.disabled}
                        key={option}
                        onClick={(event ) => handleMenuItemClick(event, index)}
                        selected={index === selectedIndex}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}

export default SplitButton
