import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import AndileTable from 'components/AndileMaterialUITable/AndileTable';
import {
  DATE_CRITERION,
  NUMBER_CRITERION,
  TEXT_CRITERION,
  TextCriterion,
} from 'popcorn-js/search/criteria/types';
import { processUnixDateForViewing } from 'utils/Utils';
import { ComponentLevelError } from 'components/Error/Error';
import { useInvoiceRecordkeeperFind } from 'hooks/financial/invoice/recordkeeperHooks';
import { MdRemoveRedEye, MdRestore } from 'react-icons/md';
import InvoiceDetailDialog from 'components/Detail/invoice/InvoiceDetailDialog';
import InvoiceDetailHistoryContainer from 'views/History/InvoiceHistory/InvoiceDetailHistoryContainer';
import { useSelector } from 'react-redux';
import {
  ALL_INVOICE_STATUSES,
  INVOICE_DIRECTION_PAYABLE,
  INVOICE_DIRECTION_RECEIVABLE,
} from 'constants/invoice';
import { useCounterpartyRecordkeeperFind } from 'hooks/financial/counterparty/recordkeeperHooks';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index'; 
import { FormatNumber } from 'utils/TradeUtilities';

const useStyles = makeStyles(() => ({
  root: {
    margin: '5px',
  },
}));

const safeRender = (accessor, formatter = (value) => value) => (rowData) => {
  try {
    return formatter(rowData[accessor]);
  } catch (e) {
    return '-';
  }
};

const invoiceStatuses = ALL_INVOICE_STATUSES.map((value) => ({ value }));
const invoiceDirections = [
  {
    value: INVOICE_DIRECTION_PAYABLE,
    label: INVOICE_DIRECTION_PAYABLE,
  },
  {
    value: INVOICE_DIRECTION_RECEIVABLE,
    label: INVOICE_DIRECTION_RECEIVABLE,
  },
];

const initialPageSize = 10;
const rowsPerPageOptions = [5, 10, 25];

//EXPOSURES
function OverDueExposuresNewTable() {
  const classes = useStyles();
  const currencies = useSelector((state) => state.currency.records);
  const [showHistory, setShowHistory] = useState(undefined);
  const [showDetail, setShowDetail] = useState(undefined);
  const [
    {
      request: invoiceFindRequest,
      response: invoiceFindResponse,
      loading: invoiceFindLoading,
      error: invoiceFindError,
    },
    setInvoiceFindRequest,
  ] = useInvoiceRecordkeeperFind([], {
    sortBy: [],
    order: [],
    limit: initialPageSize,
    offset: 0,
  });

  const [
    {
      response: associatedCounterpartiesFindResponse,
      loading: associatedCounterpartyFindLoading,
      error: associatedCounterpartyFindError,
    },
    setAssociatedCounterpartyFindRequest,
  ] = useCounterpartyRecordkeeperFind([], {
    sortBy: [],
    order: [],
    limit: -1,
    offset: 0,
  });

  useEffect(() => {
    if (invoiceFindResponse && invoiceFindResponse.records.length > 0) {
      const crit = invoiceFindResponse.records.map(
        (inv) => new TextCriterion({ field: 'id', text: inv.counterpartyId })
      );
      setAssociatedCounterpartyFindRequest({
        criteria: crit,
        query: {
          sortBy: [],
          order: [],
          limit: 0,
          offset: 0,
        },
      });
    }
  }, [invoiceFindResponse, setAssociatedCounterpartyFindRequest]);

  const rowActions = [
    {
      icon: <MdRemoveRedEye />,
      tooltip: 'View Details',
      onClick: (rowInfo) => {
        setShowDetail(rowInfo);
      },
    },
    {
      icon: <MdRestore />,
      tooltip: 'View History',
      onClick: (rowInfo) => {
        setShowHistory(rowInfo);
      },
    },
  ];

  // Table callbacks
  function handleChangeSorting(sortBy, order) {
    const newRequest = {
      ...invoiceFindRequest,
      query: {
        ...invoiceFindRequest.query,
        sortBy: [sortBy],
        order: [order],
      },
    };

    setInvoiceFindRequest(newRequest);
  }

  function handleChangePage(event, newPage) {
    const offset = invoiceFindRequest.query.limit * newPage;

    const newRequest = {
      ...invoiceFindRequest,
      query: {
        ...invoiceFindRequest.query,
        offset: offset,
      },
    };

    setInvoiceFindRequest(newRequest);
  }

  function handleChangeRowsPerPage(event) {
    const rowsPerPage = event.target.value;

    setInvoiceFindRequest({
      ...invoiceFindRequest,
      query: {
        sortBy: [],
        order: [],
        limit: rowsPerPage,
        offset: 0,
      },
    });
  }

  function handleFilterChange(newCrit) {
    setInvoiceFindRequest({
      query: {
        ...invoiceFindRequest.query,
        offset: 0,
      },
      criteria: newCrit.map((cr) => {
        return {
          type: cr.type,
          ...cr.value,
        };
      }),
    });
  }

  async function generateCounterpartyOptions(criterion) {
    const criteria = [criterion];
    try {
      return await CounterpartyRecordkeeper.find({
        criteria: criteria,
        query: { sortBy: ['name'], order: [], limit: 20, offset: 0 },
        Deleted: false,
      });
    } catch (e) {
      throw e.message || e;
    }
  }

  if (invoiceFindError || associatedCounterpartyFindError) {
    return <ComponentLevelError errorMessage={'Failed to find exposures'} />;
  }

  return (
    <div className={classes.root}>
      {showDetail && (
        <InvoiceDetailDialog
          counterparties={
            associatedCounterpartiesFindResponse &&
            associatedCounterpartiesFindResponse.records
          } //TODO
          invoice={showDetail}
          onClose={() => setShowDetail(undefined)}
          readOnly
          show={!!showDetail}
        />
      )}
      {showHistory && (
        <InvoiceDetailHistoryContainer
          invoice={showHistory}
          onHide={() => {
            setShowHistory(undefined);
          }}
          open={!!showHistory}
        />
      )}
      <AndileTable
        columns={[
          {
            title: 'Number',
            field: 'number',
            filter: { type: TEXT_CRITERION },
          },
          { title: 'Party', field: 'partyCode' },
          {
            title: 'Counterparty',
            filter: {
              asyncOptionsFetcher: generateCounterpartyOptions,
              defaultOptions: associatedCounterpartiesFindResponse
                ? associatedCounterpartiesFindResponse.records
                : [],
              displayAccessor: 'name',
              valueAccessor: 'id',
              type: TEXT_CRITERION,
            },
            render: (rowData) => {
              try {
                if (!rowData.counterpartyId) {
                  return '-';
                }
                const counterPartyName = (
                  associatedCounterpartiesFindResponse.records.find(
                    (b) => b.id === rowData.counterpartyId
                  ) || {}
                ).name;
                return counterPartyName
                  ? counterPartyName
                  : rowData.counterpartyId;
              } catch (e) {
                return rowData.counterpartyId;
              }
            },
            field: 'counterpartyId',
          },
          {
            title: 'Amount Due',
            field: 'amountDue',
            filter: { type: NUMBER_CRITERION },
          },
          {
            title: 'Currency',
            render: safeRender(
              'currencyId',
              (currencyId) =>
                currencies.find((c) => c.id === currencyId).isoCode
            ),
            field: 'currencyId',
            filter: {
              options: currencies,
              displayAccessor: 'isoCode',
              valueAccessor: 'id',
              type: TEXT_CRITERION,
            },
          },
          {
            title: 'Cost Currency',
            render: safeRender(
              'costCurrencyId',
              (costCurrencyId) =>
                currencies.find((c) => c.id === costCurrencyId).isoCode
            ),
            field: 'costCurrencyId',
            filter: {
              options: currencies,
              displayAccessor: 'isoCode',
              valueAccessor: 'id',
              type: TEXT_CRITERION,
            },
          },
          {
            title: 'Costing Rate',
            field: 'costingRate',
            filter: { type: NUMBER_CRITERION },
            render: safeRender('costingRate', (costingRate) =>
              FormatNumber(costingRate, true, true, 4, true)
            ),
          },
          {
            title: 'Due Date',
            field: 'dueDate',
            render: safeRender('dueDate', processUnixDateForViewing),
            filter: { type: DATE_CRITERION },
          },
          {
            title: 'Shipping Date',
            field: 'shippingDate',
            filter: { type: DATE_CRITERION },
            render: safeRender('shippingDate', (shippingDate) =>
              processUnixDateForViewing(shippingDate)
            ),
          },
          {
            title: 'Shipment Reference',
            field: 'shipmentReference',
            filter: { type: TEXT_CRITERION },
          },
          { title: 'Notes', field: 'notes', filter: { type: TEXT_CRITERION } },
          {
            title: 'Status',
            field: 'status',
            filter: {
              options: invoiceStatuses,
              displayAccessor: 'value',
              valueAccessor: 'value',
              type: TEXT_CRITERION,
            },
          },
          {
            title: 'Approved Amount',
            field: 'approvedAmount',
            filter: { type: NUMBER_CRITERION },
            render: safeRender('approvedAmount', (approvedAmount) =>
              FormatNumber(approvedAmount, true, true, 2, true)
            ),
          },
          {
            title: 'Paid Amount',
            field: 'paidAmount',
            filter: { type: NUMBER_CRITERION },
            render: safeRender('paidAmount', (paidAmount) =>
              FormatNumber(paidAmount, true, true, 2, true)
            ),
          },
          {
            title: 'Effective Rate',
            field: 'effectiveRate',
            filter: { type: NUMBER_CRITERION },
            render: safeRender('effectiveRate', (effectiveRate) =>
              FormatNumber(effectiveRate, true, true, 4, true)
            ),
          },
          {
            title: 'Issue Date',
            field: 'issueDate',
            filter: { type: DATE_CRITERION },
            render: safeRender('issueDate', (issueDate) =>
              processUnixDateForViewing(issueDate)
            ),
          },
          {
            title: 'Capture Rate',
            field: 'captureRate',
            filter: { type: NUMBER_CRITERION },
            render: safeRender('captureRate', (captureRate) =>
              FormatNumber(captureRate, true, true, 4, true)
            ),
          },
          {
            title: 'Direction',
            field: 'direction',
            filter: {
              type: TEXT_CRITERION,
              options: invoiceDirections,
              displayAccessor: 'value',
              valueAccessor: 'value',
            },
          },
        ]}
        count={invoiceFindResponse.total}
        data={invoiceFindResponse.records}
        defaultColConfig={[
          { header: 'Number', visible: true },
          { header: 'Party', visible: false },
          { header: 'Counterparty', visible: true },
          { header: 'Amount Due', visible: true },
          { header: 'Currency', visible: true },
          { header: 'Cost Currency', visible: false },
          { header: 'Costing Rate', visible: false },
          { header: 'Due Date', visible: true },
          { header: 'Shipping Date', visible: false },
          { header: 'Shipment Reference', visible: false },
          { header: 'Notes', visible: false },
          { header: 'Status', visible: true },
          { header: 'Approved Amount', visible: false },
          { header: 'Paid Amount', visible: false },
          { header: 'Effective Rate', visible: false },
          { header: 'Issue Date', visible: false },
          { header: 'Capture Rate', visible: false },
          { header: 'Direction', visible: false },
        ]}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={invoiceFindLoading || associatedCounterpartyFindLoading}
        onChangeSorting={handleChangeSorting}
        onFilterChange={handleFilterChange}
        order={
          invoiceFindRequest.query.order.length > 0
            ? invoiceFindRequest.query.order[0]
            : undefined
        }
        page={Math.ceil(
          invoiceFindRequest.query.offset / invoiceFindRequest.query.limit
        )}
        rowActions={rowActions}
        rowDoubleClickAction={(rowInfo) => setShowDetail(rowInfo)}
        rowsPerPage={invoiceFindRequest.query.limit}
        rowsPerPageOptions={rowsPerPageOptions}
        sortBy={
          invoiceFindRequest.query.sortBy.length > 0
            ? invoiceFindRequest.query.sortBy[0]
            : undefined
        }
        tableID={'OverdueExposuresProcessingOrgHome'}
        title={'Overdue Exposures'}
      />
    </div>
  );
}

OverDueExposuresNewTable.propTypes = {};

OverDueExposuresNewTable.defaultProps = {};

export default OverDueExposuresNewTable;
