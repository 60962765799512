import {marshalIdentifier} from '../../marshallers';
import {MarshalCriteria as marshallCriteria} from 'popcorn-js/search/criteria/Marshaller'
import {jsonRpcRequest} from '../../jsonRpc/jsonRpcReqest';
import {config} from '../../index';

/**
 * User is a convenience client for invoking the backend services
 */


const ServiceProviderName = 'User-Recordkeeper'

export const Recordkeeper = {
  create(createRequest){
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${ServiceProviderName}.Create`,
      request: {...createRequest},
    })
  },

  retrieve(retrieveRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${ServiceProviderName}.Retrieve`,
      request: {
        ...retrieveRequest,
        identifier: serializedIdentifier,
      },
    })
  },

  find(findRequest) {
    const serializedCriteria = marshallCriteria(findRequest.criteria)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${ServiceProviderName}.Find`,
      request: {
        ...findRequest,
        criteria: serializedCriteria
      },
    })
  },

  update(updateRequest) {
    const serializedIdentifier = marshalIdentifier(updateRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${ServiceProviderName}.Update`,
      request: {
        ...updateRequest,
        identifier: serializedIdentifier
      },
    })
  },

  /**
   * @param {{identifier }} deleteForeverRequest
   */
  deleteForever(deleteForeverRequest) {
    const serializedIdentifier = marshalIdentifier(deleteForeverRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${ServiceProviderName}.DeleteForever`,
      request: {
        ...deleteForeverRequest,
        identifier: serializedIdentifier
      },
    })
  },
  /**
   * @param {{identifier }} deleteRequest
   */
  delete(deleteRequest) {
    const serializedIdentifier = marshalIdentifier(deleteRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${ServiceProviderName}.Delete`,
      request: {
        ...deleteRequest,
        identifier: serializedIdentifier
      },
    })
  },
  /**
   * @param {{identifier }} restoreRequest
   */
  restore(restoreRequest) {
    const serializedIdentifier = marshalIdentifier(restoreRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${ServiceProviderName}.Restore`,
      request: {
        ...restoreRequest,
        identifier: serializedIdentifier
      },
    })
  },

}
