import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles(() => ({
  root: {
    height: '32px',
    width: '32px',
    strokeWidth: '2',
  },
}));

export function CounterpartiesIcon() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <SvgIcon
      className={classes.root}
      htmlColor={theme.palette.primary.contrastText}
      viewBox="0 0 32 32"
    >
      <g id="Counterparties">
        <g
          id="Group_51"
          transform="translate(-455.843 -387.306)">
          <g id="Group_46">
            <path
              d="M476.873,405.932h-20.53a.5.5,0,0,1-.5-.5V389.1a.5.5,0,0,1,.5-.5H461.7a.5.5,0,0,1,0,1h-4.856v15.332h19.53V389.6h-4.508a.5.5,0,0,1,0-1h5.008a.5.5,0,0,1,.5.5v16.332a.5.5,0,0,1-.5.5Z"
              id="Path_35"
            />
          </g>
          <g id="Group_47">
            <path
              d="M462.554,397.326a2.387,2.387,0,1,1,2.121-2.371,2.262,2.262,0,0,1-2.121,2.371Zm0-3.745a1.4,1.4,0,1,0,1.121,1.374,1.271,1.271,0,0,0-1.121-1.374Z"
              id="Path_36"
            />
          </g>
          <g id="Group_48">
            <path
              d="M465.679,402.868h-6.252a.5.5,0,0,1-.5-.464l-.14-2.02a2.078,2.078,0,0,1,1.306-1.943,7.3,7.3,0,0,1,4.911,0,2.06,2.06,0,0,1,1.308,1.947c-.04.659-.087,1.339-.136,2.017a.5.5,0,0,1-.5.464Zm-5.785-1h5.32c.036-.52.071-1.038.1-1.549a1.1,1.1,0,0,0-.652-.939,6.325,6.325,0,0,0-4.228,0,1.1,1.1,0,0,0-.649.937l.106,1.551Z"
              id="Path_37"
            />
          </g>
          <path
            d="M470.581,388.306a.75.75,0,1,1,0,1.5h-7.5a.75.75,0,0,1,0-1.5h7.5m0-1h-7.5a1.75,1.75,0,0,0,0,3.5h7.5a1.75,1.75,0,1,0,0-3.5Z"
            id="Path_38"
          />
          <g
            data-name="Group 49"
            id="Group_49">
            <path
              d="M472.917,395.89h-4.168a.5.5,0,0,1,0-1h4.168a.5.5,0,0,1,0,1Z"
              id="Path_39"
            />
          </g>
          <g
            data-name="Group 50"
            id="Group_50">
            <path
              d="M472.917,399.056h-4.168a.5.5,0,0,1,0-1h4.168a.5.5,0,0,1,0,1Z"
              id="Path_40"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
