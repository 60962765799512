import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core';
import { ChartSVG } from 'views/Home/Company/Dashboard/ChartSVG';
import { createStyles } from '@material-ui/core/styles';
import { HexToRGBA } from 'utils/Utils';
import Dialog from '@material-ui/core/Dialog';
import { ExposureGraphLayout } from 'views/Home/Company/Dashboard/ExposureGraph/ExposureGraphLayout';
import { Currency } from 'popcorn-js/financial/currency/currencyType';
import { useTheme } from '@material-ui/core/styles';

interface ImageButtonProps {
  disabled: boolean;
  currency: Currency;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      overflow: 'hidden',
    },
    root: {
      display: 'grid',
      minWidth: 150,
      width: '100%',
      gridTemplateRows: 'auto auto',
      justifyContent: 'center',
      alignContent: 'space-between',
    },
    image: {
      height: '180px',
      borderRadius: '50%',
    },
    buttonContainedPrimary: {
      color: theme.palette.customColors.customBlueContrastText,
      fontWeight: 'bold',
      fontSize: '12px',
      backgroundColor: theme.palette.customColors.customBlue,
      '&:hover': {
        backgroundColor: HexToRGBA(theme.palette.customColors.customBlue, 0.6),
      },
    },
    button: {
      width: '70px',
      height: '28px',
    },
    buttonWrapper: {
      justifySelf: 'center',
      padding: theme.spacing(),
      marginBottom: '35px',
    },
  })
);

export const ExposureGraphEntryPoint: React.FC<ImageButtonProps> = (
  props: ImageButtonProps
) => {
  const [showExposureGraph, setShowExposureGraph] = React.useState<boolean>(
    false
  );
  const classes = useStyles();

  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Dialog
        PaperProps={{ classes: { root: classes.dialog } }}
        fullWidth
        maxWidth={'lg'}
        onClose={(): void => setShowExposureGraph(false)}
        open={showExposureGraph}
        scroll={'body'}
        style={{ overflow: 'body' }}
      >
        <ExposureGraphLayout
          currency={props.currency}
          onClose={(): void => setShowExposureGraph(false)}
        />
      </Dialog>
      <ChartSVG background={'#343164'} fill={theme.palette.text.accent} />
      <div className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          classes={{
            containedPrimary: classes.buttonContainedPrimary,
          }}
          color={'primary'}
          disabled={props.disabled}
          onClick={(): void => setShowExposureGraph(true)}
          variant={'contained'}
        >
          View
        </Button>
      </div>
    </div>
  );
};
