import {OrderRecordkeeper} from 'popcorn-js/financial/order/index'

class Validator {

  /**
   * @Param {Order} order
   */
  static ValidateData(order) {
    return new Promise((resolve, reject) => {
      OrderRecordkeeper.validate({order})
        .then(result => resolve(result))
        .catch(error => reject(error))
    })
  }

  /**
   * @Param {Order} order
   */
  static ValidateBatchData(orders) {
    return new Promise((resolve, reject) => {
      OrderRecordkeeper.validateBatch({orders})
        .then(result => resolve(result))
        .catch(error => reject(error))
    })
  }

}

export default Validator
