import {Entry as AuditEntry} from 'popcorn-js/audit'
import {ContactDetails} from '../ContactDetails'
import BankingDetails from 'popcorn-js/legalEntity/party/BankingDetails'
import {isObject} from 'utils/Utils'

class ProcessingBank {
  static camel = 'processingBank'
  static nice = 'processing Bank'
  static niceP = 'processing Banks'
  static capital = 'Processing Bank'
  static capitalP = 'Processing Banks'
  /**
   * @type {string}
   */
  id = ''
  /**
   * @type {string}
   */
  name = ''
  /**
   * @type {string}
   */
  adminEmail = ''
  /**
   * @type {string}
   */
  partyCode = ''
  /**
   * @type {string}
   */
  sarbAuthNumber = ''
  /**
   * @type {number}
   */
  incomeTaxNumber = 0
  /**
   * @type {number}
   */
  valueAddedTaxNumber = 0
  /**
   * @type {string}
   */
  customsClientNumber = ''
  /**
   * @type {string}
   */
  logo = ''
  /**
   * @type {ContactDetails}
   */
  contactDetails = new ContactDetails()
  /**
   * @type {BankingDetails}
   */
  bankingDetails = new BankingDetails()
  /**
   * @type {string}
   */
  authorisedSignatory = ''
  /**
   * @type {AuditEntry}
   */
  auditEntry = new AuditEntry()
  /**
   * @type {string[]}
   */
  currencyPairIds = []

  /**
   * @param {object|ProcessingBank} [processingBank]
   */
  constructor(processingBank) {
    if (processingBank !== undefined &&
      (processingBank instanceof ProcessingBank || isObject(processingBank))) {
      try {
        this.id = processingBank.id
        this.name = processingBank.name
        this.adminEmail = processingBank.adminEmail
        this.partyCode = processingBank.partyCode
        this.sarbAuthNumber = processingBank.sarbAuthNumber
        this.incomeTaxNumber = processingBank.incomeTaxNumber
        this.valueAddedTaxNumber = processingBank.valueAddedTaxNumber
        this.customsClientNumber = processingBank.customsClientNumber
        this.logo = processingBank.logo
        this.contactDetails = new ContactDetails(processingBank.contactDetails)
        this.bankingDetails = new BankingDetails(processingBank.bankingDetails)
        this.authorisedSignatory = processingBank.authorisedSignatory
        this.auditEntry = new AuditEntry(processingBank.auditEntry)
        this.currencyPairIds = processingBank.currencyPairIds
      } catch (e) {
        console.error(`error constructing processing bank: ${e}`)
        throw e
      }
    }
  }

}

export default ProcessingBank
