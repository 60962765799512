import React, { useState, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { HexToRGBA, round } from 'utils/Utils';
import {
  IconButton,
  makeStyles,
  Tooltip as MUITooltip,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import CardTS from 'views/Home/Company/Dashboard/CardTS';
import { ScaleLoader as Spinner } from 'react-spinners';
import {
  MdErrorOutline as ErrorIcon,
  MdHelp as HelpIcon,
} from 'react-icons/md';
import { RefreshIcon } from 'components/Icons';
import cx from 'classnames';
import {
  GeneratorTS
} from 'popcorn-js/report';
import { roundScaleAndFormatNumber } from './Util';
import Dialog from '@material-ui/core/Dialog';
import MainCashFlowGraphLayout from './MainCashFlowGraph/MainCashFlowGraphLayout';
import { useSelector } from 'react-redux';
import CashFlowButton from './MainCashFlowGraph/CashFlowButton';
import { viewViewCashFlowGraph } from 'permissions/permissionTypes';
import { Currency } from 'popcorn-js/financial/currency/currencyType';
import { useService } from 'hooks/useService'

const usePortfolioCardStyles = makeStyles(theme => ({
  dialog: {
    overflow: 'hidden',
  },
  portfolioCardRoot: {
    padding: 0,
    marginBottom: theme.spacing(2),
  },
  portfolioCardContentRoot: {
    height: '305px',
    padding: 0,
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'auto auto 1fr auto',
    '&:last-child': {
      padding: 0,
    },
  },
  cardTitle: {
    fontSize: '17px',
    fontWeight: 600,
  },
  actionIcon: {
    color: HexToRGBA(theme.palette.primary.contrastText, 0.5),
    '&:disabled': {
      color: HexToRGBA(theme.palette.primary.contrastText, 0.2),
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },
  portfolioValuesLoading: {
    gridColumn: '3/3',
    backgroundColor: theme.palette.primary.light,
    padding: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px 0px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorIcon: {
    fontSize: 80,
    color: theme.palette.primary.main,
  },
  errorText: {
    color: theme.palette.text.primary,
  },

  // Pnl Totals sections
  portfolioPnlTotalsWrapper: {
    borderRight: `3px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
    width: '164px',
    backgroundColor: HexToRGBA(theme.palette.primary.light, 0.54),
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px ${theme.spacing(2)}px`,
  },
  portfolioPnlTotalsLayout: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
  },

  portfolioPnlTotalsItemHeader: {
    color: theme.palette.text.label,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
  },

  portfolioPnlTotalsItemValue: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 'bold',
  },

  portfolioPnlTotalsItemValueAccent: {
    color: theme.palette.text.accent,
  },

  portfolioPnlTotalsInnerWrapper: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    height: '100%',
  },

  portfolioPnlTotalsItem: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    marginTop: theme.spacing(5),
  },

  // Net Position Section
  portfolioNetPositionWrapper: {
    width: '223px',
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  portfolioNetPositionLayout: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr 1fr',
  },
  percentageHedgedSectionHeading: {
    color: theme.palette.text.label,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    marginRight: '9px',
    marginBottom: theme.spacing(1),
  },
  netPosition: {
    color: theme.palette.text.accent,
    fontSize: '32px',
    fontWeight: 'bold',
    lineHeight: '32px',
  },
  showMoreLabel: {
    color: theme.palette.text.body,
    fontSize: '12px',
    lineHeight: '12px',
    cursor: 'pointer',
    marginTop: theme.spacing(0.5),
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  portfolioNetPositionSectionHeading: {
    color: theme.palette.text.label,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    marginRight: '9px',
    marginBottom: theme.spacing(1),
  },
  hedgePolicyDescription: {
    color: theme.palette.text.primary,
    fontSize: '12px',
    lineHeight: '15px',
    marginTop: '-3px',
    fontWeight: 200,
  },
  netPositionMTMValue: {
    color: theme.palette.text.accent,
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 'bold',
  },

  //   // PL Section
  portfolioPLWrapper: {
    minWidth: '396px',
    backgroundColor: HexToRGBA(theme.palette.primary.light, 0.54),
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px ${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  portfolioPLLayout: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateRows: 'auto 1fr 0.2fr',
  },
  portfolioPLGridColumnLayout: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr 1fr',
  },
  portfolioPLGridRowLayout: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr 1fr',
  },
  portfolioPLSectionHeading: {
    color: theme.palette.text.label,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    marginRight: '9px',
  },
  portfolioPLGridTopBorder: {
    borderTop: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
  },
  portfolioPLGridTopBorderWhite: {
    borderTop: `1px solid ${HexToRGBA(theme.palette.text.primary, 1)}`,
  },
  portfolioPLGridBottomBorder: {
    borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
  },
  portfolioPLGridLeftBorder: {
    borderLeft: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
  },
  portfolioPLGridCellPadding: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px ${theme.spacing(1)}px`,
  },
  portfolioPLGridCellContentTopLeft: {
    display: 'grid',
    alignItems: 'start',
    justifyItems: 'start',
  },
  portfolioPLGridCellContentTopRight: {
    display: 'grid',
    alignItems: 'start',
    justifyItems: 'end',
  },
  portfolioPLGridCellContentBottomLeft: {
    display: 'grid',
    alignItems: 'end',
    justifyItems: 'start',
  },
  portfolioPLGridCellContentBottomRight: {
    display: 'grid',
    alignItems: 'end',
    justifyItems: 'end',
  },
  portfolioPLGridCellContentCenterLeft: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'start',
  },
  portfolioPLGridCellContentCenterRight: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'end',
  },
  portfolioPLGridHeading: {
    color: theme.palette.text.label,
    fontSize: '11px',
    lineHeight: '11px',
    fontWeight: 'bold',
  },
  portfolioPLGridTextAlignRight: {
    textAlign: 'right',
  },
  portfolioPLGridRealisedUnrealisedHeading: {
    color: theme.palette.text.primary,
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: 'bold',
  },
  portfolioPLGridValue: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    minWidth: '55px',
  },
  portfolioPLGridAccentValue: {
    color: theme.palette.text.accent,
    fontSize: '17px',
    lineHeight: '17px',
    fontWeight: 'bold',
  },
  portfolioPLGridTotalHeading: {
    color: theme.palette.text.label,
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'bold',
  },
  portfolioPLGridCostingRateHeading: {
    color: theme.palette.text.body,
    marginTop: '-3px',
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 200,
  },
  portfolioPLGridCostingRate: {
    color: theme.palette.text.body,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 200,
  },
  columnLayout: {
    display: 'flex',
    flexDirection: 'column',
  },
  acmSection: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr 1fr',
    marginTop: theme.spacing(2),
  },
  acmTitle: {
    color: theme.palette.text.label,
    fontSize: '14px',
    lineHeight: '12px',
    fontWeight: 800,
  },
  acmValue: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '17px',
    fontWeight: 800,
  },

  // Cash Projection
  cashProjectionWrapper: {
    borderLeft: `2px solid ${HexToRGBA(theme.palette.text.primary, 0.1)}`,
    backgroundColor: HexToRGBA(theme.palette.primary.light, 0.54),
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px ${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  cashProjectionLayout: {
    flexGrow: 1,
    padding: `0px ${theme.spacing(1)}px 0px ${theme.spacing(2)}px`,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRow: 'auto 1fr',
  },
  cashProjectionLoadingLayout: {
    flexGrow: 1,
    borderLeft: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.1)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cashProjectionErrorLayout: {
    flexGrow: 1,
    borderLeft: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.1)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cashProjectionLabel: {
    color: theme.palette.text.label,
    fontSize: '16px',
    lineHeight: '12px',
    fontWeight: 800,
  },
  cashProjectionChartWrapper: {
    justifySelf: 'center',
    alignSelf: 'center',
  },
  cashProjectionPlaceholderImg: {
    alignSelf: 'center',
    justifySelf: 'center',
    maxWidth: '430px',
  },

  widthAlign: {
    width: '152px',
  },

  // General
  headingWithHelp: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    // marginBottom: theme.spacing(0.5),
  },
  helpIconWrapper: {
    width: '14px',
    height: '14px',
    color: theme.palette.text.body,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    marginLeft: theme.spacing(1),
  },
  helpIcon: {
    width: '14px',
    height: '14px',
    marginTop: '3px',
  },
  chartColors: {
    one: '#5d24a9',
    two: '#7b65a9',
    three: '#a6a976',
    four: '#a9504c',
    five: '#a90818',
    six: '#a95019',
    seven: '#a9795f',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MUITooltip);

interface NetPositionSectionProps {
  percentageImportsHedged: number;
  percentageExportsHedged: number;
  grossImportExposureAtSpot: number;
  grossExportExposureAtSpot: number;
  localCurrencySymbol: string;
}
const NetPositionSection = (props: NetPositionSectionProps) => {
  const classes = usePortfolioCardStyles();
  const {percentageImportsHedged, percentageExportsHedged, grossImportExposureAtSpot, grossExportExposureAtSpot, localCurrencySymbol} = props

  return <div className={classes.portfolioNetPositionWrapper}>
    <div className={classes.portfolioNetPositionLayout}>
      <div>
        <div className={classes.headingWithHelp}>
          <div className={classes.percentageHedgedSectionHeading}>
              % Hedged Imports
          </div>
        </div>
        <div className={classes.netPosition}>
          {round(percentageImportsHedged, 2)
            .toString()
            .padStart(2, '0')}
            %
        </div>
      </div>
      <div>
        <div className={classes.headingWithHelp}>
          <div className={classes.portfolioNetPositionSectionHeading}>
              Gross Import Exposure at Spot
            <LightTooltip
              placement={'top-start'}
              title={
                'Sum of all outstanding Import Orders & Invoices, converted at Spot rate'
              }
            >
              <span className={classes.helpIconWrapper}>
                <HelpIcon className={classes.helpIcon} />
              </span>
            </LightTooltip>
          </div>
        </div>
        <div className={classes.netPositionMTMValue}>
          {
            //	Gross Import Exposure at Spot
            roundScaleAndFormatNumber(
              grossImportExposureAtSpot,
              (() => {
                try {
                  return localCurrencySymbol;
                } catch (e) {
                  return '-';
                }
              })(),
              true
            )
          }
        </div>
      </div>
      <div>
        <div className={classes.headingWithHelp}>
          <div className={classes.percentageHedgedSectionHeading}>
              % Hedged Exports
          </div>
        </div>
        <div className={classes.netPosition}>
          {
            // % Exports Hedged
            round(percentageExportsHedged, 2)
              .toString()
              .padStart(2, '0')
          }
            %
        </div>
      </div>
      <div>
        <div className={classes.headingWithHelp}>
          <div className={classes.portfolioNetPositionSectionHeading}>
              Gross Export Exposure at Spot
            <LightTooltip
              placement={'top-start'}
              title={
                'Sum of all outstanding Import Orders & Invoices, converted at Spot rate'
              }
            >
              <span className={classes.helpIconWrapper}>
                <HelpIcon className={classes.helpIcon} />
              </span>
            </LightTooltip>
          </div>
        </div>
        <div className={classes.netPositionMTMValue}>
          {
            //	Gross Export Exposure at Spot
            roundScaleAndFormatNumber(
              grossExportExposureAtSpot,
              (() => {
                try {
                  return localCurrencySymbol;
                } catch (e) {
                  return '-';
                }
              })(),
              true
            )
          }
        </div>
      </div>
    </div>
  </div>
};

// const TotalPLSection = () => {};

// const PLDetailSection = () => {};

// const CashProjectionSection = () => {};

interface Props {
  localCurrency: Currency;
}

const PortfolioCard = (props: Props): ReactElement => {
  const classes = usePortfolioCardStyles();
  const theme = useTheme();
  const { localCurrency } = props;
  const [ showCashFlowGraph, setShowCashFlowGraph ] = useState(false);

  const [
    {
      response: dollarPortfolioReport,
      loading: dollarPortfolioReportLoading,
      error: dollarPortfolioReportLoadError,
    },
    setGenerateDollarPortfolioReportRequest,
  ] = useService({}, GeneratorTS.GenerateDollarPortfolioReport);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const canViewCashFlowGraph = useSelector<any>((state) => {
    try {
      const permissions = state.security.viewPermissions;
      return permissions.includes(viewViewCashFlowGraph);
    } catch (e) {
      console.error('error getting party\'s local currency', e);
    }
  });

  const loading = dollarPortfolioReportLoading;

  const additionalActions = [
    <LightTooltip
      key={0}
      title={'Refresh'}>
      <IconButton
        className={classes.actionIcon}
        disabled={loading}
        onClick={() =>
          setGenerateDollarPortfolioReportRequest({})
        }
      >
        <RefreshIcon />
      </IconButton>
    </LightTooltip>,
  ];

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{ classes: { root: classes.dialog } }}
        fullWidth
        maxWidth={'lg'}
        onClose={() => setShowCashFlowGraph(false)}
        open={showCashFlowGraph}
      >
        <MainCashFlowGraphLayout
          localCurrency={localCurrency}
          onClose={() => setShowCashFlowGraph(false)}
        />
      </Dialog>
      <CardTS
        additionalActions={additionalActions}
        cardContentProps={{
          classes: { root: classes.portfolioCardContentRoot },
        }}
        cardProps={{ classes: { root: classes.portfolioCardRoot } }}
        title={
          <Typography
            className={classes.cardTitle}
            variant={'h5'}>
            {'Portfolio'}
          </Typography>
        }
      >
        {(() => {
          switch (true) {
            case loading:
              return (
                <div className={classes.portfolioValuesLoading}>
                  <Spinner
                    color={theme.palette.primary.main}
                    loading={dollarPortfolioReportLoading} />
                </div>
              );
            case dollarPortfolioReportLoadError !== '':
              return (
                <div className={classes.portfolioValuesLoading}>
                  <ErrorIcon className={classes.errorIcon} />
                  <div className={classes.errorText}>
                    {dollarPortfolioReportLoadError}
                  </div>
                </div>
              );
            default:
              return (
                <React.Fragment>
                  <NetPositionSection 
                    grossExportExposureAtSpot={dollarPortfolioReport.grossExportExposureAtSpot}
                    grossImportExposureAtSpot={dollarPortfolioReport.grossImportExposureAtSpot}
                    localCurrencySymbol={localCurrency.symbol}
                    percentageExportsHedged={dollarPortfolioReport.percentageHedgedExports}
                    percentageImportsHedged={dollarPortfolioReport.percentageHedgedImports}
                  />
                  <div className={classes.portfolioPnlTotalsWrapper}>
                    <div className={classes.portfolioPnlTotalsInnerWrapper}>
                      <div className={classes.portfolioPnlTotalsLayout}>
                        <div className={classes.portfolioPnlTotalsItem}>
                          <div className={classes.portfolioPnlTotalsItemHeader}>
                            Total Realised P/L
                          </div>
                          <div className={classes.portfolioPnlTotalsItemValue}>
                            {roundScaleAndFormatNumber(
                              dollarPortfolioReport.totalRealisedPnl,
                              localCurrency.symbol
                            )}
                            {/*{'N.A.'}*/}
                          </div>
                        </div>
                        <div className={classes.portfolioPnlTotalsItem}>
                          <div className={classes.portfolioPnlTotalsItemHeader}>
                            Total Unrealised P/L
                          </div>
                          <div className={classes.portfolioPnlTotalsItemValue}>
                            {roundScaleAndFormatNumber(
                              dollarPortfolioReport.totalUnRealisedPnl,
                              localCurrency.symbol
                            )}
                          </div>
                        </div>
                        <div className={classes.portfolioPnlTotalsItem}>
                          <div className={classes.portfolioPnlTotalsItemHeader}>
                            Total P/L
                          </div>
                          <div
                            className={cx(
                              classes.portfolioPnlTotalsItemValueAccent,
                              classes.portfolioPnlTotalsItemValue
                            )}
                          >
                            {roundScaleAndFormatNumber(
                              dollarPortfolioReport.totalPnlWithACM,
                              localCurrency.symbol
                            )}
                            {/*{'N.A.'}*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.portfolioPLWrapper}>
                    <div className={classes.portfolioPLLayout}>
                      <div className={classes.headingWithHelp}>
                        <div className={classes.portfolioPLSectionHeading}>
                          Profit & Loss (P/L)
                        </div>
                      </div>
                      <div className={classes.portfolioPLGridColumnLayout}>
                        <div
                          // _______Column 1_______
                          className={classes.portfolioPLGridRowLayout}
                        >
                          <div
                            className={cx(
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentBottomLeft,
                              classes.portfolioPLGridHeading,
                              classes.widthAlign
                            )}
                          />
                          <div
                            className={cx(
                              classes.portfolioPLGridTopBorder,
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentCenterLeft,
                              classes.portfolioPLGridRealisedUnrealisedHeading,
                              classes.widthAlign
                            )}
                          >
                            Realised P/L on Payments & Receipts
                          </div>
                          <div
                            className={cx(
                              classes.portfolioPLGridTopBorder,
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentCenterLeft,
                              classes.portfolioPLGridRealisedUnrealisedHeading,
                              classes.widthAlign
                            )}
                          >
                            Unrealised P/L on Exposure
                          </div>
                          <div
                            className={cx(
                              classes.portfolioPLGridTopBorderWhite,
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentCenterLeft,
                              classes.portfolioPLGridTotalHeading,
                              classes.widthAlign
                            )}
                          >
                            Imports & Exports P/L
                          </div>
                        </div>
                        <div
                          // _______Column 2_______
                          className={cx(classes.portfolioPLGridRowLayout)}
                        >
                          <div
                            className={cx(
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentBottomRight,
                              classes.portfolioPLGridHeading,
                              classes.portfolioPLGridTextAlignRight
                            )}
                          >
                            Costing Rate vs
                            <br />
                            Effective Rate
                          </div>
                          <div
                            className={cx(
                              classes.portfolioPLGridTopBorder,
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentCenterRight,
                              classes.portfolioPLGridValue
                            )}
                          >
                            {
                              // 3a
                              roundScaleAndFormatNumber(
                                dollarPortfolioReport.realisedPnL
                                  .withCostingRate,
                                localCurrency.symbol
                              )
                              // 'N.A.'
                            }
                          </div>
                          <div
                            className={cx(
                              classes.portfolioPLGridTopBorder,
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentCenterRight,
                              classes.portfolioPLGridValue
                            )}
                          >
                            {
                              // 4a
                              roundScaleAndFormatNumber(
                                dollarPortfolioReport.unrealisedPnL
                                  .withCostingRate,
                                localCurrency.symbol
                              )
                            }
                          </div>
                          <div
                            className={cx(
                              classes.portfolioPLGridTopBorderWhite,
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentCenterRight,
                              classes.portfolioPLGridValue
                            )}
                          >
                            {
                              // 5a
                              roundScaleAndFormatNumber(
                                dollarPortfolioReport.totalPnL.withCostingRate,
                                localCurrency.symbol
                              )
                              // 'N.A.'
                            }
                          </div>
                        </div>
                        <div
                          // _______Column 3_______
                          className={cx(
                            classes.portfolioPLGridRowLayout,
                            classes.portfolioPLGridLeftBorder
                          )}
                        >
                          <div
                            className={cx(
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentBottomRight,
                              classes.portfolioPLGridHeading,
                              classes.portfolioPLGridTextAlignRight
                            )}
                          >
                            Capture Rate vs
                            <br />
                            Effective Rate
                          </div>
                          <div
                            className={cx(
                              classes.portfolioPLGridTopBorder,
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentCenterRight,
                              classes.portfolioPLGridValue
                            )}
                          >
                            {
                              // 3b
                              roundScaleAndFormatNumber(
                                dollarPortfolioReport.realisedPnL
                                  .withCaptureRate,
                                localCurrency.symbol
                              )
                              // 'N.A.'
                            }
                          </div>
                          <div
                            className={cx(
                              classes.portfolioPLGridTopBorder,
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentCenterRight,
                              classes.portfolioPLGridValue
                            )}
                          >
                            {
                              // 4b
                              roundScaleAndFormatNumber(
                                dollarPortfolioReport.unrealisedPnL
                                  .withCaptureRate,
                                localCurrency.symbol
                              )
                            }
                          </div>
                          <div
                            className={cx(
                              classes.portfolioPLGridTopBorderWhite,
                              classes.portfolioPLGridCellPadding,
                              classes.portfolioPLGridCellContentCenterRight,
                              classes.portfolioPLGridValue
                            )}
                          >
                            {
                              // 5b
                              roundScaleAndFormatNumber(
                                dollarPortfolioReport.totalPnL.withCaptureRate,
                                localCurrency.symbol
                              )
                              // 'N.A.'
                            }
                          </div>
                        </div>
                      </div>
                      <div className={classes.acmSection}>
                        <div
                          className={cx(
                            classes.acmTitle,
                            classes.widthAlign,
                            classes.portfolioPLGridCellPadding
                          )}
                        >
                          ACM Realised P/L
                          <LightTooltip
                            placement={'top-start'}
                            title={
                              'P/L resulting from contracts exited with the aim to re-enter the market at a more preferential rate.  Exchange control ruling and Internal Treasury policy apply.'
                            }
                          >
                            <span className={classes.helpIconWrapper}>
                              <HelpIcon className={classes.helpIcon} />
                            </span>
                          </LightTooltip>
                        </div>
                        <div
                          className={cx(
                            classes.acmValue,
                            classes.portfolioPLGridTextAlignRight,
                            classes.portfolioPLGridCellPadding
                          )}
                        >
                          {roundScaleAndFormatNumber(
                            dollarPortfolioReport.pnlFromACM,
                            localCurrency.symbol
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
          }
        })()}
        <div className={classes.cashProjectionWrapper}>
          <div className={classes.cashProjectionLayout}>
            <div className={classes.cashProjectionLabel}>
              Cash Projection ({localCurrency.isoCode})
            </div>
            <CashFlowButton
              disabled={!canViewCashFlowGraph}
              onClick={() => setShowCashFlowGraph(true)}
            />
          </div>
        </div>
      </CardTS>
    </React.Fragment>
  );
}

PortfolioCard.propTypes = {
  localCurrency: PropTypes.object.isRequired,
};

export default PortfolioCard;
