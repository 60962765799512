export default class Role {
  id = ''
  name = ''
  partyCode = ''
  permissions: Array<any> = []
  auditEntry = {}

  constructor(role?: Partial<Role>) {
    Object.assign(this, role);

    this.permissions = role?.permissions ? role?.permissions?.slice() : []
  }
}
