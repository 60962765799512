import React from 'react'
import {connect} from 'react-redux'

import APIUser from './APIUser'

class APIUserContainer extends React.Component {
  render() {
    const {partyCode} = this.props

    return (
      <APIUser
        partyCode={partyCode}
      />
    )
  }
}

const mapStateToProps = state => ({
  partyCode: state.security.claims.context.partyCode,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(APIUserContainer)

