import {IconButton, Tooltip} from '@material-ui/core';
import React from 'react';

import {Restore,} from '@material-ui/icons'

export const restoreAction = onClick => (
  <Tooltip
    key={'restore'}
    title={'Restore'}>
    <IconButton onClick={onClick}>
      <Restore/>
    </IconButton>
  </Tooltip>)
