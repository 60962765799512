import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  withStyles, Button,
} from '@material-ui/core'
import {EXACT_CRITERION, TEXT_CRITERION} from 'popcorn-js/search/criteria/types'
import {HexToRGBA, isFunction} from 'utils/Utils'
import {MdExpandMore} from 'react-icons/md'
import Select, {components} from 'react-select'
import {FaTimes} from 'react-icons/fa'

const styles = (theme) => ({
  blankFieldsButton: {
    color: theme.palette.text.primary,
    background: theme.palette.primary.main,
  },
})

const selectStyles = (theme) => ({
  valueContainer: styles => ({
    ...styles,
    border: 'none',
    padding: '0 4px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '6px',
  }),
  option: (styles, {isSelected, isFocused}) => ({
    ...styles,
    border: 'none',
    color: isSelected ? theme.palette.text.primary : theme.palette.primary.contrastText,
    backgroundColor: isSelected ? theme.palette.primary.light : isFocused ? HexToRGBA(theme.palette.primary.light, 0.5) :  theme.palette.primary.main,
  }),
  control: styles => ({
    ...styles,
    minHeight: '1px',
    border: 'none',
    padding: '0',
    borderRadius: '0',
    backgroundColor: 'none',
  }),
  input: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    color: theme.palette.text.primary,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
  }),
  singleValue: (styles, {data}) => {
    return ({
      ...styles,
      color: data.label === 'Blank Fields' || data.label === 'start typing...' ? theme.palette.text.secondary : theme.palette.text.primary,
      fontStyle: data.label === 'Blank Fields' || data.label === 'start typing...' ? 'italic' : ''
    })
  },
})

class Text extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuIsOpen: false,
      blankFieldSelected: false,
      inputValue: ''
    }
    if (props.initValue === undefined) {
      this.state.value = {
        type: Text.CriteriaType,
        value: {
          field: props.fieldID,
          text: '',
        },
      }
    } else {
      this.state.value = props.initValue
      this.state.inputValue = props.initValue.value && props.initValue.value.text
    }
  }

  static CriteriaType = TEXT_CRITERION

  onChange = (newTextVal) => {

    const {
      onChange,
      fieldID
    } = this.props

    const value = {
      type: Text.CriteriaType,
      value: {
        field: fieldID,
        text: newTextVal,
      },
    }

    if (isFunction(onChange)) {
      if ((newTextVal === '')) {
        onChange(undefined)
      } else {
        onChange(value)
      }
    }

    this.setState({
      value,
      // selected: new newTextVal //TODO
    })
  }

  render() {
    const {
      filterConfig,
      theme,
      classes
    } = this.props
    const {
      menuIsOpen,
      inputValue,
      blankFieldSelected
    } = this.state

    const addInputProps = {}
    if (filterConfig) {
      if (filterConfig.width) {
        addInputProps.style = {
          width: filterConfig.width,
        }
      }
    }
    const MenuList = (props) => {
      if (blankFieldSelected) {
        return (
          <components.MenuList {...props} />
        )
      }
      return (
        <components.MenuList {...props}>
          <div
            onClick={() => {
              this.setState({
                menuIsOpen: false,
                blankFieldSelected: true,
                inputValue: '',
                value: {
                  type: EXACT_CRITERION,
                  value: {
                    field: props.fieldID,
                    text: '',
                  },
                }
              })
              if (isFunction(this.props.onChange)) {
                if ((this.props.newTextVal === '')) {
                  this.props.onChange(undefined)
                } else {
                  this.props.onChange({
                    type: EXACT_CRITERION,
                    value: {
                      field: this.props.fieldID,
                      text: '',
                    },
                  })
                }
              }
            }}
            style={{
              cursor: 'pointer',
            }}


          >
            <Button
              className={classes.blankFieldsButton}
              size="small">
              Search Blank Fields
            </Button>
          </div>
          {props.children}
        </components.MenuList>
      )
    }

    const NoOptionsMessage = (props) => {
      return (
        <div />
      )
    }

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <MdExpandMore
            onClick={() => {
              this.setState({
                menuIsOpen: !this.state.menuIsOpen
              })
            }}
            style={{
              height: '24px',
              margin: '-11px',
              fontSize: 40
            }}
          />
        </components.DropdownIndicator>
      )
    }

    const ClearIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <FaTimes
            onClick={() => {
              this.setState({
                inputValue: '',
                blankFieldSelected: false,
              }, () => this.onChange(''))
            }}
            style={{
              margin: '5px',
              fontSize: 15
            }}
          />
        </components.DropdownIndicator>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          justifyItems: 'center',
          gridTemplateRows: '1fr',
        }}>
        <div
          style={{
            alignSelf: 'center',
            width: '100%',
            minWidth: '160px'
          }}
        >
          <Select
            components={{MenuList, NoOptionsMessage, DropdownIndicator, ClearIndicator}}
            inputValue={inputValue}
            isClearable
            menuIsOpen={menuIsOpen}
            menuPosition={'fixed'}
            onInputChange={(value, {action}) => {
              if (action === 'input-change') {
                this.setState({
                  menuIsOpen: false,
                  inputValue: value,
                  blankFieldSelected: false
                }, () => this.onChange(value))
              }
            }}
            styles={selectStyles(theme)}
            value={{value: '', label: blankFieldSelected ? 'Blank Fields' : 'start typing...'}}
          />
        </div>
      </div>
    )
  }
}

const styledText = withStyles(styles)(Text)

styledText.propTypes = {
  // Not required, will be used to set value on construction
  fieldID: PropTypes.string.isRequired,
  filterConfig: PropTypes.object,
  initValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  showBorder: PropTypes.bool,
  value: PropTypes.object.isRequired,
}

styledText.defaultProps = {
  showBorder: false,
}

export default withStyles(styles, {withTheme: true})(Text)
