import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {MarshalCriteria} from 'popcorn-js/search/criteria/Marshaller'

export class Exporter {
  static exportInvoices({criteria}) {
    if (criteria != null) {
      criteria = MarshalCriteria(criteria)
    }
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Invoice-Exporter.Export',
      request: {criteria},
    })
  }

}

export default Exporter
