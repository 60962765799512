import React from 'react'
import {withStyles, withTheme} from '@material-ui/core'
import logo from 'assets/img/stellcap_logo_old.png'
import PropTypes from 'prop-types'
import styles from './styles'
import './fonts.css'
import {usePerfectScrollbar} from 'hooks'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'
import spinner from './spinner.png'

const LoadingPage = (props) => {
  const {
    classes,
    errorMessage,
    onDismiss
  } = props

  const setScrollBarElementRef = usePerfectScrollbar()

  return (
    <div
      className={classes.primaryContainer}
      ref={setScrollBarElementRef}
    >
      <div className={classes.logoContainer}>
        <NotificationSweetAlert
          errorMessage={errorMessage}
          onDismiss={() => onDismiss()}
        />
      </div>
      <div className={classes.logoContainer}>
        <img
          alt="STELLCAP"
          className={classes.logo}
          src={logo}/>
      </div>
      <div className={classes.spinnerContainer}>
        <img
          alt={'SPINNER'}
          className={classes.spinner}
          src={spinner}/>
      </div>
    </div>
  )
}

LoadingPage.propTypes = {
  classes: PropTypes.object,
  errorMessage: PropTypes.string,
  onDismiss: PropTypes.func,
  theme: PropTypes.object.isRequired,
}

LoadingPage.defaultProps = {
  onDismiss: () => {},
}

export default withStyles(styles)(withTheme(LoadingPage))
