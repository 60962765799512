/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState, useCallback, Fragment, ReactNode, ReactElement, ChangeEvent } from 'react'
import { TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, useTheme } from '@material-ui/core'
import { LightCheckbox, lightColor, LightNumberField } from './styledComponents'
import { useService } from 'hooks/useService'
import { BoolCriterion, TextCriterion, NumberCriterion } from 'popcorn-js/search/criteria/types'
import Recordkeeper from 'popcorn-js/trade/recordkeeper'
import { TradeDirection, TradeParent, Trade } from 'popcorn-js/trade/tradeTypes'
import {CurrencyPair} from 'popcorn-js/financial/currencyPair/currencyPairTypes'
import Big from 'big.js'
import { debounce } from 'lodash'

export interface ACMParentRow {
  selected: boolean;
  disabled: boolean;
  number: string;
  id: string;
  allocatedAmount: Big|undefined;
  notionalAmount: Big;
  acmBalance: Big;
  rate: Big;
  valid: boolean;
  helperText?: string;
}

interface ACMParentAllocationsProps {
  childTradeBaseAmount: Big|undefined,
  acmParentsChange: (acmParents: TradeParent[]) => void;
  currencyPair: CurrencyPair|undefined;
  direction: TradeDirection|undefined;
  validChange: (valid: boolean) => void;
}

interface CommonProps {
  children: ReactNode;
}
const HeaderText = (props: CommonProps) => 
  <span
    style={{
      fontWeight: 'bold', 
      fontSize: '14px',
      color: lightColor,
      width: 'auto',
    }}
  >
    {props.children}
  </span>

const CellText = (props: CommonProps) => 
  <span
    style={{
      fontSize: '14px',
      fontFamily: 'Roboto',
      color: lightColor,
    }}
  >
    {props.children}
  </span>

const displayAmount = (amount: Big|undefined) => amount ? new Intl.NumberFormat('en-UK', { maximumFractionDigits: 2, minimumFractionDigits: 2}).format(Number(amount)) : ''

export const ACMParentAllocations = (props: ACMParentAllocationsProps): ReactElement => {
  const { direction, currencyPair, acmParentsChange, validChange, childTradeBaseAmount } = props
  const theme = useTheme()

  const [acmParentAllocationsMap, setACMParentAllocationsMap] = useState<Map<string, ACMParentRow>>(new Map())
  const [allocatedAmount, setAllocatedAmount] = useState<Big|undefined>()
  const [totalAmount, setTotalAmount] = useState<Big|undefined>()
  const [allocatedAmountValid, setAllocatedAmountValid] = useState<boolean>(true)
  const [allocatedAmountErrorText, setAllocatedAmountErrorText] = useState<string>('')

  const handleACMParentAllocationsChange = useCallback(debounce((value: TradeParent[]) => acmParentsChange(value), 500), []);

  useEffect(() => {
    handleACMParentAllocationsChange(Array.from(acmParentAllocationsMap.values())?.
    filter((acmParentRow: ACMParentRow) => acmParentRow.selected && acmParentRow.allocatedAmount)?.
    map(
      (acmParentRow: ACMParentRow): TradeParent => {
        return {
          amount: Number(acmParentRow.allocatedAmount),
          rate: Number(acmParentRow.rate),
          parentTradeId: acmParentRow.id,
          parentTradeNumber: acmParentRow.number,
          parentNotionalAmount: Number(acmParentRow.notionalAmount),
          parentACMBalance: Number(acmParentRow.acmBalance)
        }
      }))
  }, [acmParentAllocationsMap, handleACMParentAllocationsChange])

  const generateFindACMTradesRequest = useCallback(() => {
    return {
      criteria: [
        new BoolCriterion({
          field: 'ACM',
          value: true,
        }),
        new TextCriterion({
          field: 'legs.0.direction',
          text: direction === TradeDirection.BUY ? TradeDirection.SELL : TradeDirection.BUY,
        }),
        new TextCriterion({
          field: 'currencyPairId',
          text: currencyPair?.id,
        }),
        new NumberCriterion({
          field: 'acmBalance',
          moreThan: {
            amount: 0.0,
          },
          lessThan: {
            ignore: true,
          }
        })
      ],
    }
  }, [direction, currencyPair])

  const [
    {
      response: findTradesResponse,
      loading: findTradesLoading,
    },
    setFindTradesRequest
  ] = useService(generateFindACMTradesRequest(), Recordkeeper.find)

  useEffect(() => {
    setFindTradesRequest(generateFindACMTradesRequest())
  }, [direction, currencyPair, setFindTradesRequest, generateFindACMTradesRequest])

  useEffect(() => {
    if (findTradesResponse && findTradesResponse.records && !findTradesLoading) {
      const newACMParentAllocationsMap = new Map<string, ACMParentRow>();
      findTradesResponse.records.forEach((trade: Trade) => {
        if (trade && trade.number && trade.id) {
          newACMParentAllocationsMap.set(trade.number, {
            selected: false,
            number: trade.number,
            id: trade.id,
            allocatedAmount: undefined,
            notionalAmount: Big(trade.legs ? trade.legs[0].notionalAmount : Big(0)),
            acmBalance: Big(trade.acmBalance ? trade.acmBalance : 0),
            rate: Big(trade.legs ? trade.legs[0].effectiveRate || 0 : Big(0)),
            valid: true,
            disabled: Big(trade.acmBalance ? trade.acmBalance : 0).lte(0)
          })
        }
      })
      setACMParentAllocationsMap(newACMParentAllocationsMap)
    } else {
      setACMParentAllocationsMap(new Map<string, ACMParentRow>())
    }
  }, [findTradesResponse, setFindTradesRequest, findTradesLoading])

  useEffect(() => {
    const newAllocatedAmount = Array.from(acmParentAllocationsMap.values())?.map(
      (acmParentRow: ACMParentRow) => acmParentRow.allocatedAmount ? acmParentRow.allocatedAmount : Big(0)).reduce(
        (total:  Big, current: Big) => total.add(current), Big(0))
    setAllocatedAmount(newAllocatedAmount)
    const newTotalAmount = Array.from(acmParentAllocationsMap.values())?.map(
      (acmParentRow: ACMParentRow) => acmParentRow.acmBalance ? acmParentRow.acmBalance : Big(0)).reduce(
        (total:  Big, current: Big) => total.add(current.gt(0) ? current : Big(0)), Big(0))
    setTotalAmount(newTotalAmount)
    let isAllocatedAmountValid = true
    if  (newAllocatedAmount && newTotalAmount && newAllocatedAmount.gt(newTotalAmount)) {
      isAllocatedAmountValid = false
      setAllocatedAmountErrorText('exceeds total amount')
    } else if (newAllocatedAmount && childTradeBaseAmount && newAllocatedAmount.gt(childTradeBaseAmount)) {
      isAllocatedAmountValid = false
      setAllocatedAmountErrorText(`exceeds foreign currency base amount ${displayAmount(childTradeBaseAmount)}`)
    } else {
      setAllocatedAmountErrorText('')
    }
    setAllocatedAmountValid(isAllocatedAmountValid)
    validChange(isAllocatedAmountValid)
  }, [acmParentAllocationsMap, childTradeBaseAmount, validChange])

  useEffect(() => {
    const found = Array.from(acmParentAllocationsMap.values()).find((value: ACMParentRow) => !value.valid)
    const areAllocationsValid = found === undefined
    validChange(allocatedAmountValid && areAllocationsValid)
  }, [acmParentAllocationsMap, validChange, allocatedAmountValid])

  const updateACMParentAllocatedAmount = useCallback((entry: ACMParentRow, allocatedAmount: Big|undefined) => {
    const newACMParentAllocationsMap = new Map(acmParentAllocationsMap);
    let valid = true
    let helperText = ''
    if (!entry.disabled) {
      if (allocatedAmount?.gt(entry.acmBalance)) {
        valid = false
        helperText = 'must be <= ACM balance'
      } else if (allocatedAmount?.lte(0)) {
        valid = false
        helperText = 'must be > 0'
      }
    }
    newACMParentAllocationsMap.set(entry.number, {
      ...entry,
      allocatedAmount,
      valid,
      helperText,
    })
    setACMParentAllocationsMap(newACMParentAllocationsMap)
  }, [acmParentAllocationsMap])

  const updateACMParentSelected = useCallback((entry: ACMParentRow, selected: boolean) => {
    const newACMParentAllocationsMap = new Map(acmParentAllocationsMap);
    newACMParentAllocationsMap.set(entry.number, {
      ...entry,
      selected,
      allocatedAmount: undefined,
    })
    setACMParentAllocationsMap(newACMParentAllocationsMap)
  }, [acmParentAllocationsMap])

  return (
    <div
      style={{
        width: '650px',
        display: 'flex',
        flexFlow: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '40px',
        }}
      >
        {acmParentAllocationsMap.size > 0 &&
          <Fragment>
            <span
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Roboto',
                color: '#D2B200',
                marginRight: '15px'
              }}
            >
              Allocated Amount
            </span>
            <span
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Roboto',
                color: '#FFFFFF',
                marginRight: '5px'
              }}
            >
              {displayAmount(allocatedAmount)} /
            </span>
            <span
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Roboto',
                color: '#FFFFFFAA',
                marginRight: '5px'
              }}
            >
              {displayAmount(totalAmount)}
            </span>
            {!allocatedAmountValid &&
              <span
                style={{
                  fontSize: '10px',
                  fontFamily: 'Roboto',
                  color: '#f44336'
                }}
              >
                {allocatedAmountErrorText}
              </span>
            }
          </Fragment>
        }
        {acmParentAllocationsMap.size === 0 &&
          <span
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              color: '#D2B200',
              marginRight: '15px'
            }}
          >
            No eligible ACM parent trades found
          </span>
        }
      </div>
      <div
        style={{
          width: '100%',
          flex: '1 1 auto'
        }}
      >
        {acmParentAllocationsMap.size > 0 &&
          <TableContainer 
            component={Paper}
            style={{
              paddingBottom: '10px', 
              backgroundColor: theme.palette.primary.main,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: '8px',
              borderBottomLeftRadius: '8px',
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    width: '100%',
                    backgroundColor: theme.palette.primary.light, 
                  }}>
                  <TableCell>{/* check box */}</TableCell>
                  <TableCell><HeaderText>Number</HeaderText></TableCell>
                  <TableCell><HeaderText>Allocated amount</HeaderText></TableCell>
                  <TableCell><HeaderText>ACM balance</HeaderText></TableCell>
                  <TableCell><HeaderText>Rate</HeaderText></TableCell>
                </TableRow>
              </TableHead>
              <TableBody 
                style={{
                  backgroundColor: theme.palette.primary.main
                }}
              >
                {Array.from(acmParentAllocationsMap.values())?.map((entry: ACMParentRow) => (
                  <TableRow
                    key={entry.number}
                    style={{backgroundColor: theme.palette.primary.main}}>
                    <TableCell>
                      <LightCheckbox
                        checked={entry.selected}
                        disabled={entry.disabled}
                        id={`${entry.number}-select`}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateACMParentSelected(entry, event.target.checked)}
                      />
                    </TableCell>
                    <TableCell align="left"><CellText>{entry.number}</CellText></TableCell>
                    <TableCell align="left">
                      <LightNumberField
                        disabled={!entry.selected}
                        error={!entry.valid}
                        helperText={entry.helperText}
                        id={'allocatedAmount'}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => 
                          updateACMParentAllocatedAmount(entry, event.target.value ? Big(event.target.value) : undefined)}
                        style={{width: '150px'}}
                        value={displayAmount(entry.allocatedAmount)}
                      />
                    </TableCell>
                    <TableCell align="left"><CellText>{displayAmount(entry.acmBalance)}</CellText></TableCell>
                    <TableCell align="left"><CellText>{entry.rate.toFixed(4)}</CellText></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </div>
    </div>
  )
}
