import React, { useContext } from 'react'
import {UserManager, UserRecordkeeper} from 'popcorn-js/legalEntities/user/index'
import Comparator from 'popcorn-js/user/comparator'
import {Card, CardContent, CardHeader, Dialog, Grid, List, ListItem, makeStyles} from '@material-ui/core'
import {HexToRGBA} from 'utils/Utils'
import UserDetails from './UserDetail'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'
import {RoleRecordkeeper} from 'popcorn-js/security/role'
import {
  createAction,
  deleteAction,
  deleteForeverAction,
  discardAction,
  inviteMailAction,
  restoreAction,
  returnAction,
  saveAction,
  viewDelete
} from 'components/Actions'
import CircularProgress from '@material-ui/core/CircularProgress'
import User from 'popcorn-js/legalEntities/user/User'
import { AppContextType, AppContext } from 'appContext'
import { PartyType } from 'popcorn-js/trade/tradeTypes'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(4),
    height: '100%',
  },
  card: {
    border: `1px solid ${theme.palette.primary.light}`,
  },
  title: {
    fontWeight: 'bold',
  },
  cardContent: {
    padding: 0,
  },
  cardHeaderRoot: {
    backgroundColor: HexToRGBA(theme.palette.text.primary, .1),
    padding: theme.spacing(1),
  },
  list: {
    overflowY: 'scroll',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  listItemRoot: {
    height: '28px',
    '&:hover': {
      background: 'linear-gradient(to right, #229ED9 0%, #229ED9 70%, #3F51B5 100%);',
    },
    '&$listItemSelected': {
      background: `linear-gradient(to right, ${HexToRGBA('#229ED9', .5)} 0%, ${HexToRGBA('#229ED9', .5)} 70%, ${HexToRGBA('#3F51B5', .5)} 100%);`,
    },
  },
  listItemSelected: {},
  progress: {
    // eslint-disable-next-line
    // @ts-ignore
    color: theme.palette.highActions,
    margin: theme.spacing(2),
  },
  progressSpinnerDialog: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  progressSpinnerDialogBackdrop: {
    backgroundColor: 'transparent',
  },
}),
)

const invokedActions = {
  init: -1,
  Selected: 0,
  Save: 1,
  New: 2,
  Delete: 3,
  Discard: 4,
  UserInvite: 5,
}

interface UserProps {
  partyCode: string
}

const UserForm: React.FC<UserProps> = (props: UserProps) => {
  const classes = useStyles()
  const {partyCode} = props
  const [actions, setActions] = React.useState<Array<any>>()
  const [users, setUsers] = React.useState<Array<User>>([])
  const [roles, setRoles] = React.useState([])
  const [selected, setSelected] = React.useState<User>()
  const [original, setOriginal] = React.useState<User>()
  const [message, setMessage] = React.useState<any>({})
  const [invokedAction, setInvokedAction] = React.useState<number>(invokedActions.init)
  const [deleted, setDeleted] = React.useState<boolean>(false)
  const [isLoading, setLoading] = React.useState<boolean>(true)

  const appContext = useContext<AppContextType>(AppContext);
  const partyType = appContext.partyType;

  React.useEffect(() => {
    setLoading(true)
    RoleRecordkeeper.find({sortBy: ['name']}).then(response => {
      let roles = response.records || []
      if (![PartyType.SYSTEM, PartyType.PROCESSING_ORG].includes(partyType)) {
        roles = roles.filter((r: any) => !['admin', 'context'].includes(r.name || ''));
      }
      setRoles(roles)
    })
    UserRecordkeeper.find({criteria: [], query: {}, deleted: deleted}).then(response => {
      const users = response.records || []
      setUsers(users)

      if (users.length > 0) {
        setSelected(users[0])
        setOriginal(users[0])
      }
    })
    setLoading(false)
  }, [partyCode, deleted, partyType])

  React.useEffect(() => {
    const handleDelete = async (): Promise<void> => {
      if (!selected) {
        return
      }
      setLoading(true)
      try {
        await UserRecordkeeper.delete({identifier: {id: selected.id}})
        const list = users.slice()
        const index = users.findIndex(user => user.loginName === selected.loginName)
        list.splice(index, 1)
        setUsers(list)
        if (list.length > 0) {
          setSelected(list[0])
          setOriginal(list[0])
        } else {
          setSelected(new User({partyCode}))
        }
        setMessage({
          success: 'User deleted successfully',
        })
        setInvokedAction(invokedActions.Delete)
      } catch (e) {
        console.error('handleDelete', e)
        setMessage({
          error: e.message || e,
        })
      }
      setLoading(false)
    }
    const handleDeleteForever = async (): Promise<void> => {
      if (!selected) {
        return
      }
      setLoading(true)
      try {
        await UserRecordkeeper.deleteForever({identifier: {id: selected.id}})
        const list = users.slice()
        const index = users.findIndex(u => u.id === selected.id)
        list.splice(index, 1)
        setUsers(list)

        if (list.length > 0) {
          const lastIdx = list.length - 1
          setSelected(list[lastIdx])
        } else {
          setSelected(new User({partyCode}))
        }

        setMessage({
          success: 'User deleted forever successfully',
        })
      } catch (e) {
        setMessage({
          error: e.message || e,
        })
      }
      setLoading(false)
    }
    const handleRestore = async (): Promise<void> => {
      if (!selected) {
        return
      }
      setLoading(true)
      try {
        await UserRecordkeeper.restore({identifier: {id: selected.id}})
        const list = users.slice()
        const index = users.findIndex(role => role.id === selected.id)
        list.splice(index, 1)
        setUsers(list)

        if (list.length > 0) {
          const lastIdx = list.length - 1
          setSelected(list[lastIdx])
        } else {
          setSelected(new User({partyCode}))
        }

        setMessage({
          success: 'User restored successfully',
        })
      } catch (e) {
        setMessage({
          error: e.message || e,
        })
      }
      setLoading(false)
    }
    const handleNew = (): void => {
      setSelected(new User({partyCode}))
      setOriginal(undefined)
      setInvokedAction(invokedActions.New)
    }

    const handleCreate = async (): Promise<void> => {
      if (!selected) {
        return
      }
      setLoading(true)
      try {
        selected.partyCode = partyCode
        const role = roles.find((role: any) => role.id === selected.roleId)
        const registerNewUserResponse = await UserManager.registerNewUser({
          username: selected.loginName,
          firstName: selected.firstName,
          lastName: selected.lastName,
          email: selected.emailAddress,
          telephoneNumber: selected.primaryTelephoneNumber,
          mobileNumber: selected.mobilePhone,
          role: (role as any)?.name,
        })
        const list = users.slice()
        list.push(registerNewUserResponse.user)
        setUsers(list)
        setSelected(registerNewUserResponse.user)
        setOriginal(registerNewUserResponse.user)
        setMessage({
          success: 'User created successfully',
        })
        setInvokedAction(invokedActions.Save)
      } catch (e) {
        console.error('handleCreate', e)

        setMessage({
          error: e.message || e,
        })
      }
      setLoading(false)
    }
    const handleUpdate = async (): Promise<void> => {
      if (!selected) {
        return
      }
      setLoading(true)
      try {
        const updateResponse = await UserRecordkeeper.update({user: selected, identifier: {id: selected.id}})
        const index = users.findIndex(user => user.id === selected.id)
        const list = users.slice()
        list.splice(index, 1, updateResponse.user)
        setUsers(list)
        setSelected(updateResponse.user)
        setOriginal(updateResponse.user)

        setMessage({
          success: 'Saved successfully',
        })
        setInvokedAction(invokedActions.Save)
      } catch (e) {
        console.error('handleUpdate', e)
        setMessage({
          error: e.message || e,
        })
      }
      setLoading(false)
    }
    const handleInviteUser = async (): Promise<void> => {
      if (!selected) {
        return
      }
      setLoading(true)
      try {
        await UserManager.sendRegistrationConfirmation({username: selected.loginName})
        setMessage({
          success: 'Invitation email sent',
        })
        setInvokedAction(invokedActions.UserInvite)

      } catch (e) {
        console.error('email Verification', e)
        setMessage({
          error: e.message || e,
        })
      }
      setLoading(false)
    }
    const handleDiscard = (): void => {
      if (!selected || !selected.id || selected.id === '') {
        if (Comparator.IsDirty({...selected, partyCode: ''})) {
          setMessage({
            warn: 'Discard changes?',
            confirm: () => {
              setSelected(users[0])
              setOriginal(users[0])
              setMessage({})
            },
          })
        } else {
          if (users.length > 0) {
            setSelected(users[0])
            setOriginal(users[0])
          } else {
            setSelected(new User({partyCode}))
          }
          setMessage({})
        }
      } else {
        const original = users.find(user => user.id === selected.id)
        if (!original) {
          console.error('No original found!')
        }
        if (Comparator.IsDirty(selected, original)) {
          setMessage({
            warn: 'Discard changes?',
            confirm: () => {
              setSelected(original)
              setMessage({})
            },
          })
        }
      }
      setInvokedAction(invokedActions.Delete)
    }
    const handleSave = async (): Promise<void> => {
      if (!selected) {
        return
      }
      if (selected.id) {
        await handleUpdate()
      } else {
        await handleCreate()
      }

    }
    const toggleDelete = (): void => {
      setDeleted(!deleted)
    }
    const updateActions = (): void => {
      if (deleted) {
        if (selected && selected.id) {
          setActions([returnAction(toggleDelete), deleteForeverAction(handleDeleteForever), restoreAction(handleRestore)])
        } else {
          setActions([returnAction(toggleDelete)])
        }
      } else {
        if  (!selected && !original) {
          setActions([createAction(handleNew)])
        } else if (selected && !original) {
          setActions([discardAction(handleDiscard), saveAction(handleSave)])
        } else if (!Comparator.IsDirty({...selected, partyCode: ''}) && !original) {
          setActions([discardAction(handleDiscard), saveAction(handleSave)])
        } else if (Comparator.IsDirty(selected, original)) {
          setActions([discardAction(handleDiscard), saveAction(handleSave)])
        } else {
          setActions([createAction(handleNew), deleteAction(handleDelete), inviteMailAction(handleInviteUser), viewDelete(toggleDelete)])
        }
      }
    }
    updateActions()
  }, [selected, deleted, users, partyCode, original, roles])


  const handleSelect = (user: User): void => {
    setInvokedAction(invokedActions.Selected)
    setSelected(user)
    setOriginal(user)
  }
  
  const closeAlert = ():void => {
    setMessage({})
  }

  const list = (): any => {
    if (!selected) {
      return
    }
    return users.map(user => (
      <ListItem
        button
        classes={{
          root: classes.listItemRoot,
          selected: classes.listItemSelected,
        }}
        component="li"
        key={user.id}
        onClick={(): void => handleSelect(user)}
        selected={user.id === selected.id}
      >
        {user.loginName}
      </ListItem>
    ))
  }

  const listHeight = users.length <= 18 ? users.length * 28 : 18 * 28

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          action={actions}
          title="Users"
          titleTypographyProps={{className: classes.title, variant: 'body1'}}
        />
        {isLoading &&
        <Dialog
          BackdropProps={{classes: {root: classes.progressSpinnerDialogBackdrop}}}
          PaperProps={{classes: {root: classes.progressSpinnerDialog}}}
          open={isLoading}
        >
          <CircularProgress
            className={classes.progress}/>
        </Dialog>
        }
        <CardContent className={classes.cardContent}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Card>
                <CardHeader
                  classes={{
                    root: classes.cardHeaderRoot,
                  }}
                  title="Username"
                  titleTypographyProps={{
                    variant: 'body1',
                  }}
                />
                <List
                  className={classes.list}
                  component="ul"
                  style={{height: `${listHeight}px`}}
                >
                  {list()}
                </List>
              </Card>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <UserDetails
                handleChange={(user: User): void => {
                  setSelected(user)
                }}
                invokedAction={invokedAction}
                roles={roles}
                user={selected}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <NotificationSweetAlert
        errorMessage={message.error}
        infoMessage={message.info}
        onClose={closeAlert}
        onConfirm={message.confirm}
        options={message.options}
        successMessage={message.success}
        warningMessage={message.warn}
      />
    </div>
  )
}

export default UserForm
