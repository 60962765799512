import React from 'react'
import {IconButton, Tooltip} from '@material-ui/core';

import {Mail,} from '@material-ui/icons'

export const inviteMailAction = onClick => (
  <Tooltip
    key={'save'}
    title={'Invite User'}>
    <IconButton onClick={onClick}>
      <Mail/>
    </IconButton>
  </Tooltip>
)

