import React from 'react'
import ReactDOM from 'react-dom'
import {createBrowserHistory} from 'history'
import {applyMiddleware, createStore} from 'redux'
import thunk from 'redux-thunk'
import config from 'react-global-configuration'
import uuidv1 from 'uuid/v1'
import 'assets/scss/material-dashboard-pro-react.css?v=1.2.0'
import {Provider} from 'react-redux'
import rootReducer from './reducers'
import {config as libConf} from 'popcorn-js/index'
import './index.css'
import AppWrapper from './AppWrapper'
import {Auth0Provider} from './utils/react-auth0-spa'
import {MuiThemeProvider} from '@material-ui/core/styles'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import {themeOptions} from './theme/options'
import { Provider as StyletronProvider, DebugEngine } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';

const hist = createBrowserHistory()

const initialState = {}

const createStoreWithMiddleware = applyMiddleware(
  thunk,
)(createStore)

const store = createStoreWithMiddleware(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__())

const hostname = window && window.location && window.location.hostname
const environment = hostname.split('.')[0]

if (!sessionStorage.getItem('sessionId')) {
  sessionStorage.setItem('sessionId', uuidv1().slice(-5))
}

const appVersion = '<APP_VERSION>'


const auth0Config = {
  domain: 'tbdealing.eu.auth0.com',
  redirect_uri: `${window.location.origin}/app/dashboard`
}
switch (environment) {
  case 'www':
    auth0Config.clientId = 'C32b4mu7G20X3YTHpk7z8TfsTIFXhvW3'
    break;
  case 'dev':
    auth0Config.clientId = 'aRVbh0keeQ7zpfUQMWSXoLjiSwMQ71gs'
    break;
  case 'staging':
    auth0Config.clientId = 'Yl9ZpPy1wj12dOygUwILoTOT9SuCO807'
    break;
  default:
    auth0Config.clientId = '0puHNXAn9uOp64dhxmVt7AmHLDnQ8za7'
}

let cfg = {}

if (
  environment === 'www' ||
  environment === 'dev' ||
  environment === 'staging'
) {
  const domain = hostname.split('.').slice(1,).join('.')
  const apiHostname = ['api', environment, domain].join('.')
  console.log('base API hostname: ', apiHostname)
  cfg = {
    version: appVersion,
    url: `https://${apiHostname}`,
    golangUrl: `https://${apiHostname}/api`,
    rickUrl: `https://${apiHostname}/rick`,
    priceFeedWsUrl: `wss://${apiHostname}/ws`,
    eventFeedWsUrl: `wss://${apiHostname}/events`,
  }
} else {
  cfg = {
    version: 'local',
    url: `http://${window.location.hostname}:9006`,
    golangUrl: `http://${window.location.hostname}:9006/api`,
    rickUrl: `http://${window.location.hostname}:8500/rick`,
    priceFeedWsUrl: `ws://${window.location.hostname}:8500/ws`,
    eventFeedWsUrl: `ws://${window.location.hostname}:9006/events`,
  }
}

libConf.set(cfg)
config.set(cfg)


// styletron
const debug =  new DebugEngine();
// 1. Create a client engine instance
// TODO add theme
const engine = new Styletron();

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      client_id={auth0Config.clientId}
      domain={auth0Config.domain}
      redirect_uri={window.location.origin}
    >
      <StyletronProvider
        debug={debug}
        debugAfterHydration
        value={engine}>
        <MuiThemeProvider theme={createMuiTheme(themeOptions())}>
          <AppWrapper history={hist}/>
        </MuiThemeProvider>
      </StyletronProvider>
    </Auth0Provider>
  </Provider>,
  document.getElementById('root'),
)
