import {IconButton, Tooltip} from '@material-ui/core';
import React from 'react';

import {Delete,} from '@material-ui/icons'

export const deleteAction = onClick => (
  <Tooltip
    key={'delete'}
    title={'Delete'}>
    <IconButton onClick={onClick}>
      <Delete/>
    </IconButton>
  </Tooltip>)
