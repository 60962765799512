import {HexToRGBA} from 'utils/Utils'

const styles = theme => ({
  root: {
    border: `solid 2px ${theme.palette.primary.light}`,
  },
  layout: {
    marginBottom: theme.spacing(2),
  },
  formFieldAccent: {
    color: theme.palette.text.accent,
  },
  formFieldGreen: {
    fontWeight: 'bold',
    color: theme.palette.data.data3B,
  },
  title: {
    backgroundColor: HexToRGBA('#ffffff', 0.1),
    color: theme.palette.text.primary,
    padding: theme.spacing(),
    border: '1px solid #26283c',
    marginBottom: theme.spacing(),
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(),
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 17,
    marginRight: '0px',
    marginTop: '0px',
  },
  action: {
    color: 'red',
    marginRight: '0px',
    marginTop: '0px',
  },
  leftAlign: {
    paddingLeft: theme.spacing(),
  },
  dateField: {
    paddingLeft: theme.spacing(),
    left: '-16px',
    position: 'relative'
  },
  fieldUnderline: {
    '&:before': {
      borderBottom: '1px solid #4B4D5E',
    },
  },
  progress: {
    color: theme.palette.text.label,
  },
  progressSpinnerDialog: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  progressSpinnerDialogBackdrop: {
    backgroundColor: 'transparent',
  },
})

export default styles
