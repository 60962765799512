import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// Custom components
import InvoiceDetail from 'components/Detail/invoice/InvoiceDetail'

// Constants
import {
  RECORDKEEPER_ADAPTOR_REGISTERED_NAME,
  LINKER_ADAPTOR_REGISTERED_NAME,
} from 'popcorn-js/financial/invoice/permissions.js'

class InvoiceDetailContainer extends React.Component {
  render() {
    const {
      onClose,
      counterparties,
      updateInvoiceError,
      updateInvoiceSuccess,
      readOnly,
      invoice,
      currencies,
      currencyPairs,
      myParty,
    } = this.props

    const apiPermissionSet = this.getInvoiceApiPermission(this.props.permission)

    return (
      <InvoiceDetail
        apiPermissionSet={apiPermissionSet}
        counterparties={counterparties}
        currencies={currencies}
        currencyPairs={currencyPairs}
        invoice={invoice}
        myParty={myParty}
        onClose={onClose}
        readOnly={readOnly}
        updateInvoiceError={updateInvoiceError}
        updateInvoiceSuccess={updateInvoiceSuccess}
      />
    )
  }

  //retrieve invoices permission that you have
  getInvoiceApiPermission(permission) {
    const invoicePermissions = permission.filter(
      (permission) => {
        if (permission.match(RECORDKEEPER_ADAPTOR_REGISTERED_NAME) || permission.match(LINKER_ADAPTOR_REGISTERED_NAME)) {
          return permission
        } else {
          return undefined
        }
      }) || []
    return new Set(invoicePermissions)
  }
}

const mapStateToProps = state => {
  return {
    currencyPairs: state.currencyPair.records || [],
    currencies: state.currency.records || [],
    permission: state.security.permissions || [],
    myParty: state.myParty,
  }
}

const mapDispatchToProps = () => {
  return {}
}

InvoiceDetailContainer.propTypes = {
  counterparties: PropTypes.arrayOf(PropTypes.object),
  currencies: PropTypes.array,
  currencyPairs: PropTypes.array,
  invoice: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  updateInvoiceError: PropTypes.func,
  updateInvoiceSuccess: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetailContainer)
