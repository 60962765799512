import {
  emailIdentifier,
  codeIdentifier,
  adminEmailIdentifier,
  idIdentifier,
  isoCodeIdentifier,
  nameIdentifier,
  hostNameIdentifier,
  partyCodeIdentifier,
  reasonIdentifier,
  numberIdentifier,
  numberPartyBankIdentifier,
  numberPartyIdentifier,
  namePartyIdentifier, loginNameIdentifier
} from './index';

const identifiersMap = new Map([
  ['admin', adminEmailIdentifier],
  ['email',emailIdentifier],
  ['code', codeIdentifier],
  ['id',idIdentifier],
  ['isoCode',isoCodeIdentifier],
  ['name',nameIdentifier],
  ['hostName',hostNameIdentifier],
  ['partyCode',partyCodeIdentifier],
  ['reason',reasonIdentifier],
  ['number',numberIdentifier],
  ['numberpartyCodeprocessingBankPartyCode',numberPartyBankIdentifier],
  ['namepartyCode',namePartyIdentifier],
  ['numberpartyCode',numberPartyIdentifier],
  ['loginName',loginNameIdentifier],
])


export function marshalIdentifier(identifier) {
  let supportedIdentifier = ''
  try {
    supportedIdentifier = mapper(identifier)
  } catch (err) {
    throw err
  }
  return supportedIdentifier + '::' + JSON.stringify(identifier)
}

function mapper(identifier) {
  const identifierKeys = Object.keys(identifier).sort().join()
  console.log(identifier)
  if (identifiersMap.has(identifierKeys)) {
    const supportedString = identifiersMap.get(identifierKeys)

    if (typeof supportedString === 'string') {
      return supportedString
    } else {
      throw new Error('Unidentified identifierType supported')
    }
  } else {
    throw new Error('Identifier not supported')
  }
}


