export const ADMIN_EMAIL_IDENTIFIER = 'ADMIN_EMAIL_IDENTIFIER'
export const CODE_IDENTIFIER = 'CODE_IDENTIFIER'
export const ID_IDENTIFIER = 'ID_IDENTIFIER'
export const ISO_CODE_IDENTIFIER = 'ISO_CODE_IDENTIFIER'
export const LOGIN_NAME_IDENTIFIER = 'LOGIN_NAME_IDENTIFIER'
export const NAME_IDENTIFIER = 'NAME_IDENTIFIER'
export const HOSTNAME_IDENTIFIER = 'HOSTNAME_IDENTIFIER'
export const NAME_PARTY_IDENTIFIER = 'NAME_PARTY_IDENTIFIER'
export const PARTY_CODE_IDENTIFIER = 'PARTY_CODE_IDENTIFIER'
export const REASON_IDENTIFIER = 'REASON_IDENTIFIER'
export const THRESHOLD_CURRENCY_PAIR_IDENTIFIER = 'THRESHOLD_CURRENCY_PAIR_IDENTIFIER'
export const TYPE_PARTY_IDENTIFIER = 'TYPE_PARTY_IDENTIFIER'
export const NUMBER_PARTY_IDENTIFIER = 'NUMBER_PARTY_IDENTIFIER'
export const NUMBER_PARTY_BANK_IDENTIFIER = 'NUMBER_PARTY_BANK_IDENTIFIER'
export const NUMBER_PARTY_COUNTERPARTY_IDENTIFIER = 'NUMBER_PARTY_COUNTERPARTY_IDENTIFIER'
export const NUMBER_PARTY_COUNTERPARTYID_IDENTIFIER = 'NUMBER_PARTY_COUNTERPARTYID_IDENTIFIER'
export const EXTERNAL_REFERENCE_PARTY_COUNTERPARTY = 'EXTERNAL_REFERENCE_PARTY_COUNTERPARTY'
export const NUMBER_IDENTIFIER = 'NUMBER_IDENTIFIER'
export const EXTERNAL_REFERENCE_TRADINGPARTY_COUNTERPARTY = 'EXTERNAL_REFERENCE_TRADINGPARTY_COUNTERPARTY'
export const EXTERNAL_REFERENCE_TRADINGPARTY = 'EXTERNAL_REFERENCE_TRADINGPARTY'

export const codeIdentifier = 'CODE_IDENTIFIER'
export const idIdentifier = 'ID_IDENTIFIER'
export const isoCodeIdentifier = 'ISO_CODE_IDENTIFIER'
export const loginNameIdentifier = 'LOGIN_NAME_IDENTIFIER'
export const nameIdentifier = 'NAME_IDENTIFIER'
export const hostNameIdentifier = 'HOSTNAME_IDENTIFIER'
export const partyCodeIdentifier = 'PARTY_CODE_IDENTIFIER'
export const reasonIdentifier = 'REASON_IDENTIFIER'
export const numberIdentifier = 'NUMBER_IDENTIFIER'
export const numberPartyBankIdentifier = 'NUMBER_PARTY_BANK_IDENTIFIER'
export const namePartyIdentifier = 'NAME_PARTY_IDENTIFIER'
export const numberPartyIdentifier = 'NUMBER_PARTY_IDENTIFIER'
export const emailIdentifier = 'EMAIL_IDENTIFIER'
export const adminEmailIdentifier = 'ADMIN_EMAIL_IDENTIFIER'
