import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {Marshal} from 'popcorn-js/search/identifier/Marshaller';
import {ID_IDENTIFIER} from 'popcorn-js/search/identifier/types';
import {marshalCriteria} from 'popcorn-js/search/marshallerV2'

export default class Downloader {
  static downloadConfirmation(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Downloader.DownloadConfirmation',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}})
      },
    })
  }
  static downloadList(request) {
    const serializedCriteria = marshalCriteria(request.criteria)
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Downloader.DownloadList',
      request: {
        ...request,
        criteria: serializedCriteria
      },
    })
  }

}

