import React from 'react'
import {connect} from 'react-redux'
import SystemHome from 'views/Home/System/SystemHome'
import {bindActionCreators} from 'redux'
import {findPageAllUsers} from 'actions/users'
import {retrieveTableConfig, saveTableConfig} from 'actions/ui'


const mapStateToProps = state => {
  return {
    securityClaims: state.security.claims || {},
    myParty: state.myParty,
    user: state.user || {},
    tableConfig: state.ui.tableConfig,
    currencyPairs: state.currencyPair.records || [],
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    findPageAllUsers,
    retrieveTableConfig,
    saveTableConfig,
  }, dispatch)
}

class SystemHomeContainer extends React.Component {

  componentDidMount() {
  }

  render() {
    const {
      user,
      retrieveTableConfig,
      saveTableConfig,
      tableConfig,
      findPageAllUsers,

      currencyPairs
    } = this.props

    return (
      <SystemHome
        currencyPairs={currencyPairs}
        findPageAllUsers={(criteria, query) => {
          findPageAllUsers(criteria, query)
        }}
        retrieveTableConfig={retrieveTableConfig}
        saveTableConfig={saveTableConfig}
        tableConfig={tableConfig}
        user={user}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemHomeContainer)

