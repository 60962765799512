/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-multi-comp */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {Fragment, ReactElement, ReactNode, useEffect, useState} from 'react'
import {
  Backdrop,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  useTheme
} from '@material-ui/core'
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {CloseIcon} from 'components/Icons'
import {
  ActionButton,
  LightCheckbox,
  lightColor,
  LightFormControlLabel,
  LightTextField,
  SecondaryActionButton
} from './styledComponents'
import {TradeDirection, TradeParent, TradeStatus, TradeType} from 'popcorn-js/trade/tradeTypes'
import {Currency} from 'popcorn-js/financial/currency/currencyType'
import {CurrencyPair} from 'popcorn-js/financial/currencyPair/currencyPairTypes'
import Big from 'big.js'
import {ProcessingBank} from 'popcorn-js/legalEntity/party'
import {useService} from 'hooks/useService'
import Handler from 'popcorn-js/trade/handler'
import {parseISO} from 'date-fns'
import getUnixTime from 'date-fns/getUnixTime'

function Alert(props: AlertProps) {
  return <MuiAlert
    elevation={6}
    variant="filled"
    {...props} />;
}

interface ConfirmTradeDetailsProps {
  open: boolean;
  onClose: () => void;
  onFinished: () => void;
  // trade details (to display)
  tradeType: TradeType;
  parentTradeType?: TradeType;
  externalReference: string | undefined;
  buyCurrency: Currency | undefined;
  sellCurrency: Currency | undefined;
  buyAmount: Big | undefined;
  sellAmount: Big | undefined;
  tradeDate: string | null | undefined;
  maturityDate: string | null | undefined;
  allInRate: Big | undefined;
  acmChildTrade: boolean;
  acmParents: TradeParent[];
  tradeParents: TradeParent[];
  direction: TradeDirection | undefined;
  // other trade details required for recording
  currencyPair: CurrencyPair | undefined;
  bank: ProcessingBank | undefined;
  interbankRate: Big | undefined;
  bankRate: Big | undefined;
  spotRate: Big | undefined;
  forwardPoints: Big | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  party: any;
  parentPartyCode: string;
  capturedSpotRate: Big | undefined;
  trader: string | undefined;
  title: string;
  traderOrganisation: string | undefined;
  notes: string | undefined;
}


interface SplitRowProps {
  leftComponent: ReactNode;
  rightComponent: ReactNode;
}

const SplitRow = (props: SplitRowProps): ReactElement => {
  const {leftComponent, rightComponent} = props
  return (
    <div
      style={{
        width: '100%', height: '40px', display: 'flex', borderBottom: '1px solid #4C497D',
      }}
    >
      <div
        style={{
          width: '30%',
          height: '100%',
          float: 'left',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '25px'
        }}
      >
        {leftComponent}
      </div>
      <div
        style={{
          width: '70%',
          height: '100%',
          float: 'right',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '25px'
        }}
      >
        {rightComponent}
      </div>
    </div>
  )
}

interface CommonProps {
  children: ReactNode;
}

const SplitLabel = (props: CommonProps) => {
  return (
    <span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>{props.children}</span>
  )
}

const SubTitle = (props: CommonProps) => {
  return (
    <div
      style={{
        width: '100%',
        height: '40px',
        paddingLeft: '25px',
        backgroundColor: '#2F305D',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {props.children}
    </div>
  )
}

const HeaderText = (props: CommonProps) =>
  <span
    style={{
      fontWeight: 'bold',
      fontSize: '14px',
      color: lightColor,
      width: 'auto',
    }}
  >
    {props.children}
  </span>

const CellText = (props: CommonProps) =>
  <span
    style={{
      fontSize: '14px',
      fontFamily: 'Roboto',
      color: lightColor,
    }}
  >
    {props.children}
  </span>

export const ConfirmTradeDetails = (props: ConfirmTradeDetailsProps) => {
  const {
    open, onClose, onFinished, tradeType, externalReference, buyCurrency, sellCurrency,
    buyAmount, sellAmount, tradeDate, maturityDate, allInRate, acmChildTrade: acmTrade, acmParents, tradeParents, direction,
    interbankRate, currencyPair, spotRate, forwardPoints, party, bank, parentPartyCode, capturedSpotRate, title, trader, 
    traderOrganisation, notes, parentTradeType, bankRate
  } = props

  const theme = useTheme()

  const [recordDone, setRecordDone] = useState<boolean>(false)
  const [recordStarted, setRecordStarted] = useState<boolean>(false)

  const displayAmount = (amount: Big | undefined) => amount ? new Intl.NumberFormat('en-UK', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(Number(amount)) : '0.00'

  const handleClose = () => {
    if (recordStarted && recordDone) {
      setRecordDone(false)
      setRecordStarted(false)
      onFinished()
    } else {
      setRecordDone(false)
      setRecordStarted(false)
      onClose()
    }
  }

  const [
    {
      loading: recordLoading,
      error: recordError,
    },
    setRecordRequest,
  ] = useService(null, Handler.Record)

  useEffect(() => {
    if (recordStarted && !recordLoading) {
      setRecordDone(true)
    }
  }, [recordStarted, recordLoading])

  const handleConfirm = () => {
    if (
      tradeDate && maturityDate && currencyPair && bank && trader && capturedSpotRate
    ) {
      setRecordStarted(true)
      const notionalAmount = direction === TradeDirection.BUY ? Number(buyAmount) : Number(sellAmount);
      if (parentTradeType === TradeType.EXTENSION && tradeType === TradeType.CANCELLATION) {
        setRecordRequest({
          trade: {
            tradeType,
            trader,
            traderOrganisation,
            notes,
            date: getUnixTime(new Date()),
            status: TradeStatus.OPEN,
            legs: [
              {},
              {
                externalReference,
                bank: bank.partyCode,
                direction,
                notionalAmount,
                quoteAmount: direction === TradeDirection.SELL ? Number(buyAmount) : Number(sellAmount),
                interbankRate: Number(interbankRate),
                bankRate: Number(bankRate),
                maturityDate: getUnixTime(parseISO(maturityDate)),
                tradeDate: getUnixTime(parseISO(tradeDate)),
                spotPrice: Number(spotRate),
                forwardPoints: Number(forwardPoints),
                allInRate: Number(allInRate),
                effectiveRate: Number(allInRate),
              }
            ],
            currencyPairId: currencyPair.id,
            tradingPartyCode: party.partyCode,
            processingOrgPartyCode: parentPartyCode,
            availableBalance: notionalAmount,
            acmBalance: notionalAmount,
            acmParents: acmParents,
            tradeParents: tradeParents,
            capturedSpotRate: Number(capturedSpotRate),
            ACM: acmTrade,
            financialYear: 'CURRENT',
          }
        })
      } else {
        setRecordRequest({
          trade: {
            tradeType,
            trader,
            traderOrganisation,
            notes,
            date: getUnixTime(new Date()),
            status: TradeStatus.OPEN,
            legs: [
              {
                externalReference,
                bank: bank.partyCode,
                direction,
                notionalAmount,
                quoteAmount: direction === TradeDirection.SELL ? Number(buyAmount) : Number(sellAmount),
                interbankRate: Number(interbankRate),
                bankRate: Number(bankRate),
                maturityDate: getUnixTime(parseISO(maturityDate)),
                tradeDate: getUnixTime(parseISO(tradeDate)),
                spotPrice: Number(spotRate),
                forwardPoints: Number(forwardPoints),
                allInRate: Number(allInRate),
                effectiveRate: Number(allInRate),
              }
            ],
            currencyPairId: currencyPair.id,
            tradingPartyCode: party.partyCode,
            processingOrgPartyCode: parentPartyCode,
            availableBalance: notionalAmount,
            importExport: '',
            acmBalance: notionalAmount,
            acmParents: acmParents,
            tradeParents: tradeParents,
            capturedSpotRate: Number(capturedSpotRate),
            ACM: acmTrade,
            financialYear: 'CURRENT',
          }
        })
      }
    }
  }

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      id={'ConfirmTradeDetailsDialog'}
      onClose={() => handleClose()}
      open={open}
    >
      <div
        style={{
          width: '512px',
          backgroundColor: '#383964',
        }}
      >
        <Toolbar
          style={{position: 'relative', paddingLeft: '25px', backgroundColor: '#383964'}}
          variant={'dense'}
        >
          <span
            style={{flex: 1, fontSize: '20px', fontFamily: 'Roboto', fontWeight: 'bold'}}>
            {title}
          </span>
          <IconButton
            aria-label="close"
            color="inherit"
            edge="end"
            onClick={() => handleClose()}>
            <CloseIcon/>
          </IconButton>
        </Toolbar>
        <div
          style={{height: '100%', width: '100%'}}
        >
          <div
            style={{width: '100%', height: '80px', display: 'flex'}}
          >
            <div
              style={{
                width: '30%',
                height: '100%',
                float: 'left',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '25px'
              }}
            >
              <LightTextField
                disabled
                id={'tradeType'}
                label={'Trade Type'}
                style={{width: '120px'}}
                value={tradeType}
              />
            </div>
            <div
              style={{
                width: '70%',
                height: '100%',
                float: 'right',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '25px'
              }}
            >
              <LightTextField
                disabled
                id={'dealReference'}
                label={'Deal Reference'}
                style={{width: '144px'}}
                value={externalReference}
              />
            </div>
            {tradeType === TradeType.CANCELLATION && <div
              style={{
                width: '70%',
                height: '100%',
                float: 'right',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '25px'
              }}
            >
              <LightFormControlLabel
                control={
                  <LightCheckbox
                    checked={acmTrade}
                    name="acmTrade"
                  />
                }
                label="ACM Trade"
              />
            </div>}
          </div>
          <SubTitle>
            <span
              style={{
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                color: (tradeType === TradeType.CANCELLATION ? (direction === TradeDirection.BUY ? theme.palette.export.main : theme.palette.local) : (direction === TradeDirection.BUY ? theme.palette.import.main : theme.palette.local)),
              }}>
                Buying {buyCurrency?.isoCode}
            </span>
            <span style={{fontSize: '16px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>
                    &nbsp;/&nbsp;
            </span>
            <span
              style={{
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                color: (tradeType === TradeType.CANCELLATION ? (direction === TradeDirection.SELL ? theme.palette.import.main : theme.palette.local) : (direction === TradeDirection.SELL ? theme.palette.export.main : theme.palette.local)),
              }}>
                Selling {sellCurrency?.isoCode}
            </span>
          </SubTitle>
          <SplitRow
            leftComponent={<SplitLabel>Buying:</SplitLabel>}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rightComponent={<span
              style={{
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                color: (tradeType === TradeType.CANCELLATION ? (direction === TradeDirection.BUY ? theme.palette.export.main : theme.palette.local) : (direction === TradeDirection.BUY ? theme.palette.import.main : theme.palette.local)),
              }}>{buyCurrency?.isoCode} {displayAmount(buyAmount)}</span>}
          />
          <SplitRow
            leftComponent={<SplitLabel>Trade Date:</SplitLabel>}
            rightComponent={<span
              style={{
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 'normal',
                color: '#FFFFFF99'
              }}>{tradeDate}</span>}
          />
          <SplitRow
            leftComponent={<SplitLabel>Maturity Date:</SplitLabel>}
            rightComponent={<span
              style={{
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 'normal',
                color: '#FFFFFF99'
              }}>{maturityDate}</span>}
          />
          <SplitRow
            leftComponent={<SplitLabel>All-In Rate:</SplitLabel>}
            rightComponent={<span
              style={{
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 'normal',
                color: '#FFFFFF99'
              }}>{allInRate?.toFixed(4)}</span>}
          />
          <SplitRow
            leftComponent={<SplitLabel>Selling:</SplitLabel>}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rightComponent={<span
              style={{
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                color: (tradeType === TradeType.CANCELLATION ? (direction === TradeDirection.SELL ? theme.palette.import.main : theme.palette.local) : (direction === TradeDirection.SELL ? theme.palette.export.main : theme.palette.local)),
              }}>{sellCurrency?.isoCode} {displayAmount(sellAmount)}</span>}
          />
          {acmTrade && acmParents.length > 0 &&
          <Fragment>
            <SubTitle>
              <span style={{fontSize: '16px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>
                            Parent Trades
              </span>
            </SubTitle>
            <div
              style={{width: '100%', height: 'auto'}}
            >
              <TableContainer
                component={Paper}
                style={{
                  backgroundColor: '#383964',
                  borderRadius: 0,
                }}
              >
                <Table size={'small'}>
                  <TableHead>
                    <TableRow
                      style={{
                        width: '100%',
                        backgroundColor: '#414272',
                      }}>
                      <TableCell style={{paddingLeft: '25px'}}><HeaderText>Number</HeaderText></TableCell>
                      <TableCell><HeaderText>Allocated amount</HeaderText></TableCell>
                      <TableCell><HeaderText>Notional amount</HeaderText></TableCell>
                      <TableCell><HeaderText>Rate</HeaderText></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    style={{
                      backgroundColor: theme.palette.primary.main
                    }}
                  >
                    {acmParents.map((acmParent: TradeParent) => (
                      <TableRow
                        key={acmParent.parentTradeNumber}
                        style={{backgroundColor: '#383964'}}>
                        <TableCell
                          align="left"
                          style={{paddingLeft: '25px'}}><CellText>{acmParent.parentTradeNumber}</CellText></TableCell>
                        <TableCell align="left"><CellText>{acmParent.amount?.toFixed(2)}</CellText></TableCell>
                        <TableCell
                          align="left"><CellText>{acmParent.parentNotionalAmount?.toFixed(2)}</CellText></TableCell>
                        <TableCell align="left"><CellText>{acmParent.rate?.toFixed(4)}</CellText></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Fragment>
          }
          <div
            style={{
              width: '100%',
              height: '75px',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <ActionButton
              disabled={recordLoading || recordDone}
              onClick={() => handleConfirm()}
              style={{width: '220px', height: '23px', fontSize: '10px'}}>Confirm Trade Information</ActionButton>
            <SecondaryActionButton
              disabled={recordLoading || recordDone}
              onClick={() => handleClose()}
              style={{width: '220px', height: '23px', fontSize: '10px'}}>Revise Trade
              Information</SecondaryActionButton>
          </div>
        </div>
      </div>
      <Backdrop
        open={recordLoading}
        style={{zIndex: 10}}>
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Snackbar
        autoHideDuration={3000}
        onClose={() => handleClose()}
        open={recordDone && !recordLoading}>
        <Fragment>
          {!recordError &&
          <Alert
            onClose={() => handleClose()}
            severity="success">
            Successfully recorded trade!
          </Alert>
          }
          {!!recordError &&
          <Alert
            onClose={() => handleClose()}
            severity="error">
            Failed to record trade: {recordError}
          </Alert>
          }
        </Fragment>
      </Snackbar>
    </Dialog>
  )
}