import PropTypes from 'prop-types';
import {
  IconButton,
  makeStyles,
  Tooltip as MUITooltip,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { HexToRGBA, round } from 'utils/Utils';
import Card from 'views/Home/Company/Dashboard/Card';
import React, { useEffect, useState } from 'react';
import {
  MdErrorOutline as ErrorIcon,
  MdHelp as HelpIcon,
  MdImportExport as ExportIcon,
} from 'react-icons/md';
import { RefreshIcon } from 'components/Icons';
import { ScaleLoader as Spinner } from 'react-spinners';
import cx from 'classnames';
import {
  GeneratePositionReportForCurrencyResponse,
  Generator,
} from 'popcorn-js/report';
import saveAs from 'file-saver';
import { roundScaleAndFormatNumber } from './Util';
import moment from 'moment';
import { SystemDateFormat } from '../../../../constants';
import { ExposureGraphEntryPoint } from 'views/Home/Company/Dashboard/ExposureGraph/ExposureGraphEntryPoint';
import { useSelector } from 'react-redux';
import { viewExposureReport } from 'permissions/permissionTypes';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MUITooltip);

function useGeneratorGeneratePositionReportForCurrency(initialCurrencyId = '') {
  const [request, setRequest] = useState({ currencyId: initialCurrencyId });
  const [response, setResponse] = useState(
    new GeneratePositionReportForCurrencyResponse()
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const timestamp = new Date();

  useEffect(() => {
    const fetchData = async () => {
      setError('');
      setLoading(true);
      try {
        setResponse(await Generator.GeneratePositionReportForCurrency(request));
      } catch (e) {
        console.error('error generating position report', e);
        setError(
          'error generating position report: ' +
          (e.message ? e.message : e).toString()
        );
      }
      setLoading(false);
    };
    fetchData().finally();
  }, [request]);

  return [{ request, response, loading, error, timestamp }, setRequest];
}

const usePositionCardStyles = makeStyles((theme) => ({
  positionCardRoot: {
    padding: 0,
    marginBottom: theme.spacing(2),
  },
  positionCardContentRoot: {
    padding: 0,
    height: '290px',
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr auto',
    '&:last-child': {
      padding: 0,
    },
  },
  cardTitle: {
    fontSize: '17px',
    fontWeight: '600',
  },
  actionIcon: {
    color: HexToRGBA(theme.palette.primary.contrastText, 0.5),
    '&:disabled': {
      color: HexToRGBA(theme.palette.primary.contrastText, 0.2),
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },
  positionValuesLoading: {
    gridColumn: '1/4',
    padding: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px 0px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorIcon: {
    fontSize: 80,
    color: theme.palette.primary.light,
  },
  errorText: {
    color: theme.palette.text.primary,
  },

  // Net Position Section
  positionNetPositionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '180px',
    height: '100%',
    padding: '16px',
  },
  positionNetPositionLayout: {
    padding: `0px ${theme.spacing(2)}px 0px 0px`,
    flexGrow: 1,
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
  },
  sectionHeading: {
    color: theme.palette.text.label,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    marginRight: '9px',
    marginBottom: theme.spacing(0.5),
    alignSelf: 'center',
  },
  spotRateHeading: {
    color: HexToRGBA(theme.palette.data.data3B, 0.7),
    fontSize: '14px',
    lineHeight: '12px',
    fontWeight: 'bold',
    marginRight: '9px',
    marginBottom: theme.spacing(0.5),
    alignSelf: 'center',
  },
  sectionHeadingExposureGraph: {
    color: theme.palette.text.label,
    fontSize: '16px',
    lineHeight: '12px',
    fontWeight: 'bold',
    marginRight: '9px',
    marginBottom: theme.spacing(0.5),
    alignSelf: 'center',
  },
  sectionHeading_2: {
    color: theme.palette.text.label,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    alignSelf: 'center',
  },
  netPosition: {
    color: theme.palette.text.accent,
    fontSize: '40px',
    fontWeight: 'bold',
    lineHeight: '40px',
  },
  exploreShowMoreLabel: {
    color: theme.palette.text.body,
    fontSize: '12px',
    lineHeight: '12px',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  // Spot and realised/unrealised section
  positionSpotRealisedUnrealisedWrapper: {
    width: '180px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `2px solid ${HexToRGBA(theme.palette.text.primary, 0.1)}`,
    padding: '16px',
  },
  positionSpotRealisedUnrealisedLayout: {
    flexGrow: 1,
    // padding: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2.5)}px`,
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
  },
  // Total exposure section
  totalExposureWrapper: {
    width: '460px',
    borderLeft: `2px solid ${HexToRGBA(theme.palette.text.primary, 0.1)}`,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  totalExposureLayout: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  totalExposureSectionHeading: {
    color: theme.palette.text.label,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 'bold',
    marginBottom: `${theme.spacing(2)}px`,
  },
  totalExposureGridColumnLayout: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1.3fr 1fr 1fr 1fr',
  },
  totalExposureGridRowLayout: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr',
  },
  minWidthTable: {
    minWidth: '65px',
  },
  totalExposureGridTopBorder: {
    borderTop: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
  },
  totalExposureGridLeftBorder: {
    borderLeft: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
  },
  totalExposureGridBackground: {
    backgroundColor: HexToRGBA(theme.palette.primary.light, 0.54),
  },
  totalExposureGridCellContentCenter: {
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
  },
  totalExposureGridCellContentCenterJustifyCenter: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    textAlign: 'center',
  },
  totalExposureGridCellContentLeftCenter: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'start',
  },
  totalExposureGridCellContentRightCenter: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'end',
  },
  totalExposureGridCellContentRightTop: {
    display: 'grid',
    alignItems: 'start',
    justifyItems: 'end',
  },
  totalExposureGridCellPadding: {
    padding: '4px',
  },
  totalExposureGridBodyText: {
    color: theme.palette.text.body,
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'medium',
  },
  totalExposureGridHeaderText: {
    color: theme.palette.text.body,
    fontSize: '11px',
    lineHeight: '11px',
    fontWeight: 'bold',
  },
  totalExposureGridPrimaryText: {
    color: theme.palette.text.primary,
    fontSize: '12px',
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  totalExposureGridLabelText: {
    color: theme.palette.text.label,
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
  },

  totalExposureGridImportText: {
    color: theme.palette.data.data1B,
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
  },
  totalExposureGridCellContentLeft: {
    display: 'grid',
    textAlign: 'left',
    alignItems: 'center',
  },
  totalExposureGridCellContentRight: {
    display: 'grid',
    textAlign: 'right',
    alignItems: 'center',
  },
  totalExposureGridExportText: {
    color: theme.palette.text.positive,
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
  },

  // cash projection wrapper
  positionExposureProjectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `2px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
  },
  positionExposureProjectionLayout: {
    flexGrow: 1,
    padding: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRow: 'auto 1fr',
  },
  exposureProjectionPlaceholderImg: {
    alignSelf: 'center',
    justifySelf: 'center',
    width: '100%',
    maxWidth: '430px',
  },

  // General
  headingWithHelp: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    marginBottom: theme.spacing(0.5),
  },
  columns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    textAlign: 'inherit',
  },
  value: {
    color: theme.palette.text.body,
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 'medium',
  },
  leftValue: {
    color: HexToRGBA(theme.palette.text.primary, 0.5),
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 'bold',
  },
  lightValue: {
    color: HexToRGBA(theme.palette.text.primary, 0.7),
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 'bold',
  },
  valueAccent: {
    color: theme.palette.text.accent,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '20px',
    width: '150px',
  },
  valuePrimary: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  helpIconWrapper: {
    width: '14px',
    height: '14px',
    color: theme.palette.text.body,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    marginLeft: '4px',
    alignSelf: 'center',
  },
  helpIconWrapperColumn: {
    width: '14px',
    height: '14px',
    color: theme.palette.text.body,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    margin: 'auto',
    alignSelf: 'center',
  },
  helpIcon: {
    width: '14px',
    height: '14px',
  },
  spotRateValueWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spotRateValue: {
    color: HexToRGBA(theme.palette.data.data3B, 0.7),
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: '600',
  },
  refinitivLogoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  refinitivLogo: {
    height: '12px',
    width: '60px',
    content:
      'url("https://github.com/TBDealing/logos/raw/master/refinitiv_logo_white.png")',
    alignSelf: 'flex-end',
  },
  spotRateTimestamp: {
    color: HexToRGBA(theme.palette.text.primary, 1.0),
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: '600',
    alignSelf: 'flex-end',
  },
  marginLeft: {
    marginLeft: '11px',
  },
  paddingRight: {
    paddingRight: '8px',
    paddingLeft: '8px',
  },
  valueAtTooltip: {
    width: '35px',
  },
  ACMSection: {
    display: 'grid',
    gridTemplateColumns: '3fr 1.5fr 3fr',
  },
  ACMSubSection: {
    display: 'grid',
    gridTemplateColumns: '1.3fr 1fr',
  },
}));

const exportReport = (response, currency) => {
  // convert base64 to byte array
  const binData = atob(response.excelData);
  const bytes = new Array(binData.length);
  for (let i = 0; i < binData.length; i++) {
    bytes[i] = binData.charCodeAt(i);
  }

  const blob = new Blob([new Uint8Array(bytes)], {
    type:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
  });
  saveAs(
    blob,
    `${currency.isoCode} Dashboard Export ${moment().format(
      SystemDateFormat
    )}.xlsx`
  );
};

function PositionCard(props) {
  const theme = useTheme();
  const classes = usePositionCardStyles();
  const { currency, localCurrency } = props;

  const canViewExposureReport = useSelector((state) => {
    try {
      const permissions = state.security.viewPermissions;
      return permissions.includes(viewExposureReport);
    } catch (e) {
      console.error('error getting party\'s local currency', e);
    }
  });

  const [
    { response, loading, error },
    setRequest,
  ] = useGeneratorGeneratePositionReportForCurrency(currency.id);

  return (
    <Card
      additionalActions={[
        <LightTooltip
          key={0}
          title={'Refresh'}>
          <IconButton
            className={classes.actionIcon}
            disabled={loading}
            onClick={() =>
              setRequest({
                currencyId: currency.id,
              })
            }
          >
            <RefreshIcon />
          </IconButton>
        </LightTooltip>,
        <LightTooltip
          key={1}
          title={'Export'}>
          <IconButton
            className={classes.actionIcon}
            disabled={loading || error !== ''}
            onClick={() => exportReport(response, currency)}
          >
            <ExportIcon />
          </IconButton>
        </LightTooltip>,
      ]}
      cardContentProps={{ classes: { root: classes.positionCardContentRoot } }}
      cardProps={{ classes: { root: classes.positionCardRoot } }}
      title={
        <Typography
          className={classes.cardTitle}
          variant={'h5'}
        >{`${currency.isoCode} Position`}</Typography>
      }
    >
      {(() => {
        switch (true) {
          case loading:
            return (
              <div className={classes.positionValuesLoading}>
                <Spinner
                  color={theme.palette.primary.light}
                  isLoading />
              </div>
            );

          case error !== '':
            return (
              <div className={classes.positionValuesLoading}>
                <ErrorIcon className={classes.errorIcon} />
                <div className={classes.errorText}>{error}</div>
              </div>
            );

          default:
            return (
              <React.Fragment>
                <div className={classes.positionNetPositionWrapper}>
                  <div className={classes.positionNetPositionLayout}>
                    <div className={classes.columns}>
                      <div>
                        <div className={classes.headingWithHelp}>
                          <div className={classes.sectionHeading_2}>
                            Net Exposure
                            <LightTooltip
                              placement={'top-start'}
                              title={
                                'Total Net Exposure across Imports and Exports (incl CFC)'
                              }
                            >
                              <span className={classes.helpIconWrapper}>
                                <HelpIcon className={classes.helpIcon} />
                              </span>
                            </LightTooltip>
                          </div>
                        </div>
                        <div className={classes.valueAccent}>
                          {roundScaleAndFormatNumber(
                            response.currencyPosition,
                            currency.symbol
                          )}
                        </div>
                      </div>
                      <div className={classes.marginLeft} />
                    </div>
                    <div className={classes.columns}>
                      <div>
                        <div className={classes.headingWithHelp}>
                          <div className={classes.sectionHeading_2}>
                            Total P/L
                          </div>
                        </div>
                        <div className={classes.valueAccent}>
                          {roundScaleAndFormatNumber(
                            response.totalPnL.withCostingRate,
                            localCurrency.symbol
                          )}
                          {/*{'N.A.'}*/}
                        </div>
                      </div>
                      <div className={classes.marginLeft} />
                    </div>
                  </div>
                </div>
                <div className={classes.positionSpotRealisedUnrealisedWrapper}>
                  <div className={classes.positionSpotRealisedUnrealisedLayout}>
                    <div>
                      <div className={classes.headingWithHelp}>
                        <div className={classes.spotRateHeading}>Spot Rate</div>
                        <div className={classes.refinitivLogoWrapper}>
                          <div className={classes.refinitivLogo} />
                        </div>
                      </div>
                      <div className={classes.spotRateValueWrapper}>
                        <div className={classes.valuePrimary}>
                          {round(response.spotRate, 4)}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={classes.headingWithHelp}>
                        <div className={classes.sectionHeading}>
                          Total Realised P/L
                          <LightTooltip
                            placement={'top-start'}
                            title={
                              'Realised P/L on Settled Import and Export invoices, excl. ACM'
                            }
                          >
                            <span className={classes.helpIconWrapper}>
                              <HelpIcon className={classes.helpIcon} />
                            </span>
                          </LightTooltip>
                        </div>
                      </div>
                      <div className={classes.valuePrimary}>
                        {roundScaleAndFormatNumber(
                          response.totalRealisedPnL.withCostingRate,
                          localCurrency.symbol
                        )}
                        {/*{'N.A.'}*/}
                      </div>
                    </div>
                    <div>
                      <div className={classes.headingWithHelp}>
                        <div className={classes.sectionHeading}>
                          Total Unrealised P/L
                          <LightTooltip
                            placement={'top-start'}
                            title={
                              'Unrealised P&L on Outstanding Import & Export orders / invoices and FEC Open Balances'
                            }
                          >
                            <span className={classes.helpIconWrapper}>
                              <HelpIcon className={classes.helpIcon} />
                            </span>
                          </LightTooltip>
                        </div>
                      </div>
                      <div className={classes.valuePrimary}>
                        {roundScaleAndFormatNumber(
                          response.totalUnrealisedPnL.withCostingRate,
                          localCurrency.symbol
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.totalExposureWrapper}>
                  <div className={classes.totalExposureLayout}>
                    <div className={classes.totalExposureSectionHeading}>
                      Import/Export Exposure
                    </div>
                    <div className={classes.totalExposureGridColumnLayout}>
                      <div className={classes.totalExposureGridRowLayout}>
                        <div
                          className={cx(
                            classes.totalExposureGridImportText,
                            classes.totalExposureGridCellContentLeftCenter
                          )}
                        >
                          P/L
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentLeftCenter,
                            classes.totalExposureGridPrimaryText
                          )}
                        >
                          {
                            //
                            // 3a.i
                            //
                            roundScaleAndFormatNumber(
                              response.ordersInvoicesUnrealisedProfitAndLoss
                                .withCostingRate.import,
                              localCurrency.symbol,
                              true
                            )
                          }
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentLeftCenter,
                            classes.totalExposureGridPrimaryText
                          )}
                        >
                          {
                            //
                            // 6a
                            //
                            roundScaleAndFormatNumber(
                              response.tradeUnrealisedProfitAndLoss.import,
                              localCurrency.symbol,
                              true
                            )
                          }
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridPrimaryText
                          )}
                        >
                          -
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentLeftCenter,
                            classes.totalExposureGridPrimaryText
                          )}
                        >
                          {
                            //
                            // 9a.i
                            //
                            roundScaleAndFormatNumber(
                              response.netUnrealisedProfitAndLoss
                                .withCostingRate.import,
                              localCurrency.symbol,
                              true
                            )
                          }
                        </div>
                        <div
                          className={cx(classes.totalExposureGridTopBorder)}
                        />
                      </div>
                      <div className={classes.totalExposureGridRowLayout}>
                        <div
                          className={cx(
                            classes.totalExposureGridImportText,
                            classes.totalExposureGridCellContentLeft
                          )}
                        >
                          Avg Rate
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridPrimaryText
                          )}
                        >
                          <div className={classes.columns}>
                            <div className={classes.valueAtTooltip}>
                              {
                                //
                                // 2a.i
                                //
                                round(
                                  response.ordersInvoicesAverageRate
                                    .withCostingRate.import,
                                  2
                                )
                              }
                            </div>
                            <LightTooltip
                              placement={'top-start'}
                              title={'Weighted Ave Costing Rate'}
                            >
                              <span className={classes.helpIconWrapperColumn}>
                                <HelpIcon className={classes.helpIcon} />
                              </span>
                            </LightTooltip>
                          </div>
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridPrimaryText
                          )}
                        >
                          <div className={classes.columns}>
                            <div className={classes.valueAtTooltip}>
                              {
                                //
                                // 5a
                                //
                                round(response.tradeAverageRate.import, 2)
                              }
                            </div>
                            <LightTooltip
                              placement={'top-start'}
                              title={'Weighted Ave Effective Rate'}
                            >
                              <span className={classes.helpIconWrapperColumn}>
                                <HelpIcon className={classes.helpIcon} />
                              </span>
                            </LightTooltip>
                          </div>
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridPrimaryText
                          )}
                        >
                          <div className={classes.columns}>
                            <div className={classes.valueAtTooltip}>-</div>
                            <LightTooltip
                              placement={'top-start'}
                              title={'Weighted Ave Rate of CFC Transactions'}
                            >
                              <span className={classes.helpIconWrapperColumn}>
                                <HelpIcon className={classes.helpIcon} />
                              </span>
                            </LightTooltip>
                          </div>
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridPrimaryText
                          )}
                        >
                          <div />
                        </div>
                        <div
                          className={cx(classes.totalExposureGridTopBorder)}
                        />
                      </div>
                      <div
                        className={cx(
                          classes.totalExposureGridRowLayout,
                          classes.totalExposureGridImportText
                        )}
                      >
                        <div
                          className={cx(
                            classes.totalExposureGridCellContentCenter,
                            classes.paddingRight
                          )}
                        >
                          Imports
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.paddingRight,
                            classes.minWidthTable
                          )}
                        >
                          {
                            //
                            // 1a
                            //
                            roundScaleAndFormatNumber(
                              response.ordersInvoicesUnpaidTotal.import,
                              currency.symbol,
                              true
                            )
                          }
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.paddingRight
                          )}
                        >
                          {
                            //
                            // 4a
                            //
                            roundScaleAndFormatNumber(
                              response.tradeTotal.import,
                              currency.symbol,
                              true
                            )
                          }
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.paddingRight
                          )}
                        >
                          -
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.paddingRight
                          )}
                        >
                          {
                            //
                            // 7a
                            //
                            roundScaleAndFormatNumber(
                              response.netTotal.import,
                              currency.symbol,
                              true
                            )
                          }
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.paddingRight
                          )}
                        >
                          {
                            //
                            // 10a
                            //
                            round(response.netPercentageHedged.import, 2)
                              .toString()
                              .padStart(2, '0')
                          }
                          %
                        </div>
                      </div>
                      <div
                        className={cx(
                          classes.totalExposureGridRowLayout,
                          classes.totalExposureGridPrimaryText
                        )}
                      >
                        <div
                          className={cx(
                            classes.totalExposureGridHeaderText,
                            classes.totalExposureGridLeftBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridCellPadding
                          )}
                        />
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridLeftBorder,
                            classes.totalExposureGridCellContentCenterJustifyCenter,
                            classes.totalExposureGridCellPadding
                          )}
                        >
                          Orders/
                          <br />
                          Invoices
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridLeftBorder,
                            classes.totalExposureGridCellContentCenterJustifyCenter,
                            classes.totalExposureGridCellPadding
                          )}
                        >
                          Hedges
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridLeftBorder,
                            classes.totalExposureGridCellContentCenterJustifyCenter,
                            classes.totalExposureGridCellPadding
                          )}
                        >
                          CFC's
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridLeftBorder,
                            classes.totalExposureGridCellContentCenterJustifyCenter,
                            classes.totalExposureGridCellPadding,
                            classes.totalExposureGridLabelText
                          )}
                        >
                          Net
                          <br />
                          Exposure
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenterJustifyCenter,
                            classes.totalExposureGridCellPadding
                          )}
                        >
                          % Hedged
                        </div>
                      </div>
                      <div
                        className={cx(
                          classes.totalExposureGridRowLayout,
                          classes.totalExposureGridExportText
                        )}
                      >
                        <div
                          className={cx(
                            classes.totalExposureGridLeftBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.minWidthTable
                          )}
                        >
                          Exports
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridLeftBorder,
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter
                          )}
                        >
                          {currency.symbol}
                          {
                            //
                            // 1b
                            //
                            roundScaleAndFormatNumber(
                              response.ordersInvoicesUnpaidTotal.export,
                              '',
                              true
                            )
                          }
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridLeftBorder,
                            classes.totalExposureGridCellContentCenter
                          )}
                        >
                          {
                            //
                            // 4b
                            //
                            response.tradeTotal.export
                              ? roundScaleAndFormatNumber(
                                response.tradeTotal.export,
                                currency.symbol,
                                true
                              )
                              : '-'
                          }
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridLeftBorder
                          )}
                        >
                          -
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridLeftBorder,
                            classes.totalExposureGridCellContentCenter
                          )}
                        >
                          {currency.symbol}
                          {
                            //
                            // 7b
                            //
                            roundScaleAndFormatNumber(
                              response.netTotal.export,
                              '',
                              true
                            )
                          }
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter
                          )}
                        >
                          {
                            //
                            // 10b
                            //
                            round(
                              response.netPercentageHedged.export,
                              2
                            ).toString()
                          }
                          %
                        </div>
                      </div>
                      <div
                        className={cx(
                          classes.totalExposureGridRowLayout,
                          classes.totalExposureGridPrimaryText
                        )}
                      >
                        <div
                          className={cx(
                            classes.totalExposureGridExportText,
                            classes.totalExposureGridCellContentCenter
                          )}
                        >
                          Avg Rate
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridTopBorder
                          )}
                        >
                          <div className={classes.columns}>
                            <LightTooltip
                              placement={'top-start'}
                              title={'Weighted Ave Costing Rate'}
                            >
                              <span className={classes.helpIconWrapperColumn}>
                                <HelpIcon className={classes.helpIcon} />
                              </span>
                            </LightTooltip>
                            <div className={classes.valueAtTooltip}>
                              {
                                //
                                // 2b.i
                                //
                                round(
                                  response.ordersInvoicesAverageRate
                                    .withCostingRate.export,
                                  2
                                )
                              }
                            </div>
                          </div>
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter
                          )}
                        >
                          <div className={classes.columns}>
                            <LightTooltip
                              placement={'top-start'}
                              title={'Weighted Ave Effective Rate'}
                            >
                              <span className={classes.helpIconWrapperColumn}>
                                <HelpIcon className={classes.helpIcon} />
                              </span>
                            </LightTooltip>
                            <div className={classes.valueAtTooltip}>
                              {
                                //
                                // 5b
                                //
                                response.tradeAverageRate.export
                                  ? round(response.tradeAverageRate.export, 2)
                                  : '-'
                              }
                            </div>
                          </div>
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter
                          )}
                        >
                          <div className={classes.columns}>
                            <LightTooltip
                              placement={'top-start'}
                              title={'Weighted Ave Rate of CFC Transactions'}
                            >
                              <span className={classes.helpIconWrapperColumn}>
                                <HelpIcon className={classes.helpIcon} />
                              </span>
                            </LightTooltip>
                            <div className={classes.valueAtTooltip}>-</div>
                          </div>
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridPrimaryText
                          )}
                        >
                          <div />
                        </div>
                        <div
                          className={cx(classes.totalExposureGridTopBorder)}
                        />
                      </div>
                      <div
                        className={cx(
                          classes.totalExposureGridRowLayout,
                          classes.totalExposureGridPrimaryText
                        )}
                      >
                        <div
                          className={cx(
                            classes.totalExposureGridExportText,
                            classes.totalExposureGridCellContentRightCenter
                          )}
                        >
                          P/L
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentRightCenter
                          )}
                        >
                          {localCurrency.symbol}
                          {
                            //
                            // 3b.i
                            //
                            roundScaleAndFormatNumber(
                              response.ordersInvoicesUnrealisedProfitAndLoss
                                .withCostingRate.export,
                              '',
                              true
                            )
                          }
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            response.tradeUnrealisedProfitAndLoss.export
                              ? classes.totalExposureGridCellContentRightCenter
                              : classes.totalExposureGridCellContentCenter
                          )}
                        >
                          {
                            //
                            // 6b
                            //
                            response.tradeUnrealisedProfitAndLoss.export
                              ? roundScaleAndFormatNumber(
                                response.tradeUnrealisedProfitAndLoss.export,
                                localCurrency.symbol,
                                true
                              )
                              : '-'
                          }
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentCenter
                          )}
                        >
                          -
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridTopBorder,
                            classes.totalExposureGridCellContentRightCenter,
                            classes.totalExposureGridPrimaryText
                          )}
                        >
                          {localCurrency.symbol}
                          {
                            //
                            // 9b.i
                            //
                            roundScaleAndFormatNumber(
                              response.netUnrealisedProfitAndLoss
                                .withCostingRate.export,
                              '',
                              true
                            )
                          }
                        </div>
                        <div
                          className={cx(classes.totalExposureGridTopBorder)}
                        />
                      </div>
                    </div>
                    <div className={cx(classes.ACMSection)}>
                      <div className={cx(classes.ACMSubSection)}>
                        <div
                          className={cx(
                            classes.totalExposureGridImportText,
                            classes.totalExposureGridCellContentRight
                          )}
                        >
                          ACM Position:
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridPrimaryText,
                            classes.marginLeft
                          )}
                        >
                          {roundScaleAndFormatNumber(
                            response.acmPosition.import,
                            currency.symbol
                          )}
                        </div>
                      </div>
                      <div />
                      <div className={cx(classes.ACMSubSection)}>
                        <div
                          className={cx(
                            classes.totalExposureGridExportText,
                            classes.totalExposureGridCellContentRight
                          )}
                        >
                          ACM Position:
                        </div>
                        <div
                          className={cx(
                            classes.totalExposureGridCellContentCenter,
                            classes.totalExposureGridPrimaryText,
                            classes.marginLeft
                          )}
                        >
                          {roundScaleAndFormatNumber(
                            response.acmPosition.export,
                            currency.symbol
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
        }
      })()}
      <div className={classes.positionExposureProjectionWrapper}>
        <div className={classes.positionExposureProjectionLayout}>
          <div className={classes.sectionHeadingExposureGraph}>
            Exposure Projection ({currency.isoCode})
          </div>
          <ExposureGraphEntryPoint
            currency={currency}
            disabled={!canViewExposureReport}
          />
        </div>
      </div>
    </Card>
  );
}

PositionCard.propTypes = {
  currency: PropTypes.object.isRequired,
  hedgingPolicy: PropTypes.object,
  localCurrency: PropTypes.object.isRequired,
};

export default PositionCard;
