import React, {useState} from 'react';
import Popper from '@material-ui/core/Popper';
import {makeStyles} from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(theme => ({
  arrow: {
    position: 'fixed',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: arrowGenerator('#1E2036'),
}));


export default function StyledPopper(props) {
  const classes = useStyles()
  const [arrowRef, setArrowRef] = useState(null)


  return (
    props.anchorEl && <Popper
      anchorEl={props.anchorEl}
      className={classes.popper}
      keepMounted
      modifiers={{
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
      onClick={() => {
        props.onClose(null)
      }}
      open
      placement={'bottom-end'}
      transition
    >
      <span
        className={classes.arrow}
        ref={setArrowRef}/>
      <ClickAwayListener
        onClickAway={() => {
          props.onClose(null)
        }}>
        <div>
          {props.children}
        </div>
      </ClickAwayListener>

    </Popper>
  )
}

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
    zIndex: 3,
    top: '5px !important'
  };
}
