import React from 'react'
import PropTypes from 'prop-types'
// Material UI components
import {Card, CardContent, CardHeader, Grid, List, ListItem, makeStyles,} from '@material-ui/core'
// Services
import {RoleRecordkeeper} from 'popcorn-js/security/role'
import {ApiUserRecordkeeper} from 'popcorn-js/legalEntities/apiUser'
import Comparator from 'popcorn-js/apiUser/comparator'
import {HexToRGBA} from 'utils/Utils'

import {createAction, deleteAction, discardAction, saveAction,} from 'components/Actions'
// Custom components
import APIUserDetails from './APIUserDetails'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(4),
    maxHeight: '100%',
  },
  card: {
    border: `1px solid ${theme.palette.primary.light}`,
    height: '100%',
  },
  title: {
    fontWeight: 'bold',
  },
  cardContent: {
    padding: 0,
  },
  cardHeaderRoot: {
    backgroundColor: HexToRGBA(theme.palette.text.primary, .1),
    padding: theme.spacing(1),
  },
  list: {
    height: '28px',
    overflowY: 'scroll',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  listItemRoot: {
    height: '28px',
    '&:hover': {
      background: 'linear-gradient(to right, #229ED9 0%, #229ED9 70%, #3F51B5 100%);',
    },
    '&$listItemSelected': {
      background: `linear-gradient(to right, ${HexToRGBA('#229ED9', .5)} 0%, ${HexToRGBA('#229ED9', .5)} 70%, ${HexToRGBA('#3F51B5', .5)} 100%);`,
    },
  },
  listItemSelected: {},
}))

const ApiUser = (props) => {
  const classes = useStyles()

  const {partyCode} = props

  const [actions, setActions] = React.useState()

  const [apiUsers, setApiUsers] = React.useState([])

  const [roles, setRoles] = React.useState([])

  const [selected, setSelected] = React.useState({})

  const [message, setMessage] = React.useState({})


  React.useEffect(() => {
    RoleRecordkeeper.find([], {sortBy: ['name']}, false).then(response => {
      const roles = response.records || []
      setRoles(roles)
    })

    ApiUserRecordkeeper.find({criteria: [], query: {}}).then(response => {
      const apiUsers = response.records || []
      setApiUsers(apiUsers)

      if (apiUsers.length > 0) {
        setSelected(apiUsers[0])
      } else {
        setSelected({partyCode})
      }
    })
  }, [partyCode])

  React.useEffect(() => {
    const handleCreate = async () => {
      try {
        const createResponse = await ApiUserRecordkeeper.create({apiUser: selected})
        const list = apiUsers.slice()
        list.push(createResponse.apiUser)
        setApiUsers(list)
        setSelected(createResponse.apiUser)

        setMessage({
          info: 'Please make a copy of your api key, you won\'t be able to access this again later: ' + createResponse.apiUser.apiKey,
          options: {
            continueButtonText: 'Copy to clipboard',
            hideCancel: true,
          },
          confirm: () => {
            navigator.clipboard.writeText(createResponse.apiUser.apiKey).then(() => {
              alert('API key copied to clipboard')
            }).catch((e) => {
              alert('Can\'t copy to clipboard: ', e)
            }).finally(() => {
              setMessage({})
            })

          },
        })
      } catch (e) {
        console.error('handleCreate', e)

        setMessage({
          error: e.message || e,
        })
      }
    }
    const handleUpdate = async () => {
      try {
        const updateResponse = await ApiUserRecordkeeper.update({apiUser: selected, identifier: {id: selected.id}})
        const index = apiUsers.findIndex(apiUser => apiUser.description === selected.description)
        const list = apiUsers.slice()
        list.splice(index, 1, updateResponse.apiUser)
        setApiUsers(list)
        setSelected(updateResponse.apiUser)

        setMessage({
          success: 'Saved successfully',
        })
      } catch (e) {
        console.error('handleUpdate', e)

        setMessage({
          error: e.message || e,
        })
      }
    }

    const handleSave = async () => {
      if (selected.id) {
        await handleUpdate()
      } else {
        await handleCreate()
      }
    }
    const handleDelete = async () => {
      try {
        await ApiUserRecordkeeper.deleteForever({identifier: {id: selected.id}})
        const list = apiUsers.slice()
        const index = apiUsers.findIndex(apiUser => apiUser.description === selected.description)
        list.splice(index, 1)
        setApiUsers(list)

        if (list.length > 0) {
          const lastIdx = list.length - 1
          setSelected(list[lastIdx])
        } else {
          setSelected({partyCode})
        }

        setMessage({
          success: 'API User deleted successfully',
        })
      } catch (e) {
        console.error('handleDelete', e)
        setMessage({
          error: e.message || e,
        })
      }
    }
    const handleDiscard = () => {
      if (!selected || !selected.id || selected.id === '') {
        if (Comparator.IsDirty(selected, {})) {
          setMessage({
            warn: 'Discard changes?',
            confirm: () => {
              setSelected(apiUsers[0])
              setMessage({})
            },
          })
        } else {
          if (apiUsers.length > 0) {
            setSelected(apiUsers[0])
          } else {
            setSelected({partyCode})
          }

          setMessage({})
        }
      } else {
        const original = apiUsers.find(apiUser => apiUser.id === selected.id)
        if (!original) {
          console.error('No original found!')
        }
        // const original = apiUsers[index]
        if (Comparator.IsDirty(selected, original)) {
          setMessage({
            warn: 'Discard changes?',
            confirm: () => {
              setSelected(original)
              setMessage({})
            },
          })
        }
      }
    }
    const handleNew = () => {
      setSelected({partyCode})
    }
    const updateActions = () => {
      const original = apiUsers.find(apiUser => apiUser.description === selected.description)

      if (Comparator.IsDirty({...selected, partyCode: ''}, {}) & !original) {
        setActions([discardAction(handleDiscard), saveAction(handleSave)])
      } else if (!Comparator.IsDirty({...selected, partyCode: ''}, {}) & !original) {
        setActions([saveAction(handleSave), discardAction(handleDiscard)])
      } else if (Comparator.IsDirty(selected, original)) {
        setActions([discardAction(handleDiscard), saveAction(handleSave)])
      } else {
        setActions([createAction(handleNew), deleteAction(handleDelete)])
      }
    }
    updateActions()
  }, [selected, apiUsers, partyCode])

  const handleSelect = apiUser => {
    setSelected(apiUser)
  }

  const handleChange = name => value => {
    setSelected({...selected, [name]: value})
  }

  const handleChangeCheckedEvent = name => event => {
    setSelected({...selected, [name]: event.target.checked})
  }

  const handleChangeEvent = name => event => {
    setSelected({...selected, [name]: event.target.value})
  }
  const closeAlert = () => {
    setMessage({})
  }

  const list = () => {
    return apiUsers.map(apiUser => (
      <ListItem
        button
        classes={{
          root: classes.listItemRoot,
          selected: classes.listItemSelected,
        }}
        component="li"
        key={apiUser.id}
        onClick={() => handleSelect(apiUser)}
        selected={apiUser.id === selected.id}
      >
        {apiUser.description}
      </ListItem>
    ))
  }

  const listHeight = apiUsers.length <= 18 ? apiUsers.length * 28 : 18 * 28


  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          action={actions}
          title="API Users"
          titleTypographyProps={{className: classes.title, variant: 'body1'}}
        />
        <CardContent className={classes.cardContent}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Card>
                <CardHeader
                  classes={{
                    root: classes.cardHeaderRoot,
                  }}
                  title="Description"
                  titleTypographyProps={{
                    variant: 'body1',
                  }}
                />
                <List
                  className={classes.list}
                  component="ul"
                  style={{height: `${listHeight}px`}}
                >
                  {list()}
                </List>
              </Card>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <APIUserDetails
                apiUser={selected}
                handleChange={handleChange}
                handleChangeCheckedEvent={handleChangeCheckedEvent}
                handleChangeEvent={handleChangeEvent}
                roles={roles}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <NotificationSweetAlert
        errorMessage={message.error}
        infoMessage={message.info}
        onClose={closeAlert}
        onConfirm={message.confirm}
        options={message.options}
        successMessage={message.success}
        warningMessage={message.warn}
      />
    </div>
  )
}

ApiUser.propTypes = {
  partyCode: PropTypes.string,
}

export default ApiUser
