import { AuditEntry } from 'popcorn-js/audit/entryTypes';
import { Currency } from '../currency/currencyType';

export type OrderType = 'Purchase' | 'Sales';
export type OrderImportExport = 'Import' | 'Export';
export type OrderDirection = 'Payable' | 'Receivable';

export const ORDER_STATUS_PROPOSED = 'PROPOSED';
export const ORDER_STATUS_CONFIRMED = 'CONFIRMED';
export const ORDER_STATUS_RECEIVED = 'RECEIVED';
export const ORDER_STATUS_PAID = 'PAID';
export const ORDER_STATUS_EXPIRED = 'EXPIRED';

export const ORDER_INVALID_REASON_COUNTERPARTY_DOES_NOT_EXIST =
  'counterparty does not exist';

export const ALL_ORDER_STATUSES = [
  ORDER_STATUS_CONFIRMED,
  ORDER_STATUS_PROPOSED,
  ORDER_STATUS_RECEIVED,
  ORDER_STATUS_PAID,
  ORDER_STATUS_EXPIRED,
];

export enum OrderStatus {
  ORDER_STATUS_CONFIRMED = 'ORDER_STATUS_CONFIRMED',
  ORDER_STATUS_PROPOSED = 'ORDER_STATUS_PROPOSED',
  ORDER_STATUS_RECEIVED = 'ORDER_STATUS_RECEIVED',
  ORDER_STATUS_PAID = 'ORDER_STATUS_PAID',
  ORDER_STATUS_EXPIRED = 'ORDER_STATUS_EXPIRED',
}

export const orderTypeOptions = ['Purchase', 'Sales'];
export const orderDirectionOptions = ['Payable', 'Receivable'];
export const orderImportExportOptions = ['Import', 'Export'];

export type orderStatus =
  | 'PROPOSED'
  | 'CONFIRMED'
  | 'RECEIVED'
  | 'PAID'
  | 'EXPIRED';

export interface Order {
  id?: string;
  type?: OrderType;
  importExport?: OrderImportExport;
  direction?: OrderDirection;
  currency?: Currency;
  number?: string;
  counterpartyId?: string;
  amountDue?: number;
  currencyId?: string;
  costCurrencyId?: string;
  costingRate?: number;
  captureRate?: number;
  dueDate?: number;
  shippingDate?: number;
  shipmentReference?: string;
  notes?: string;
  status?: orderStatus;
  partyCode?: string;
  issueDate?: number;
  invoiceId?: string;
  auditEntry?: AuditEntry;
  financialYear?: string;
}
