import React from 'react';
import {Card as MaterialCard, CardHeader, Popover, Theme} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {MdClear} from 'react-icons/md';
import {makeStyles} from '@material-ui/core/styles';
import {HexToRGBA} from '../../utils/Utils';
import Button from '@material-ui/core/Button';
import {OrderType} from '../../popcorn-js/financial/order/orderType';

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    padding: theme.spacing(),
    backgroundColor: theme.palette.primary.dark,
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 17,
    marginRight: '10px',
    marginTop: '0px',
    marginLeft: '10px'
  },
  action: {
    color: 'red',
    marginRight: '0px',
    marginTop: '0px'
  },
  buttonMenu: {
    margin: theme.spacing(),
  },
  buttonWrapper: {
    borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
    '&:last-of-type': {
      borderBottom: 'none',
    }
  },
  buttonContainedPrimaryPurchase: {
    color: theme.palette.import.contrastText,
    fontWeight: 'bold',
    fontSize: '14px',
    backgroundColor: theme.palette.import.main,
    '&:hover': {
      backgroundColor: HexToRGBA(theme.palette.import.main, 0.6),
    }
  },
  buttonContainedPrimarySales: {
    color: theme.palette.export.contrastText,
    fontWeight: 'bold',
    fontSize: '14px',
    backgroundColor: theme.palette.export.main,
    '&:hover': {
      backgroundColor: HexToRGBA(theme.palette.export.main, 0.6),
    }
  },
  button: {
    width: '153px',
    height: '32px',
    margin: theme.spacing()
  },
}
))


interface SalesPurchaseSelectDialogProps {
  onSelect: (option: OrderType) => void,
  onClose: () => void,
  popoverAnchorEl: any
  dialogHeading: string,
}


const SalesPurchaseSelectDialog: React.FC<SalesPurchaseSelectDialogProps> = (props: SalesPurchaseSelectDialogProps) => {
  const {
    onSelect,
    popoverAnchorEl,
    onClose,
    dialogHeading
  } = props
  const classes = useStyles({})

  return (
    <Popover
      anchorEl={popoverAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}
      open
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MaterialCard>
        <CardHeader
          action={(
            <Tooltip
              key={'close'}
              title={'Close'}>
              <IconButton
                onClick={onClose}>
                <MdClear/>
              </IconButton>
            </Tooltip>
          )}
          classes={{
            root: classes.cardHeader,
            action: classes.action,
            title: classes.cardTitle
          }}
          title={dialogHeading}
        />
        <div className={classes.buttonMenu}>
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              classes={{
                containedPrimary: classes.buttonContainedPrimaryPurchase
              }}
              color={'primary'}
              onClick={() => onSelect('Purchase')}
              variant={'contained'}
            >PURCHASE ORDER</Button>
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              classes={{
                containedPrimary: classes.buttonContainedPrimarySales
              }}
              color={'primary'}
              onClick={() => onSelect('Sales')}
              variant={'contained'}
            >SALES ORDER</Button>
          </div>
        </div>
      </MaterialCard>
    </Popover>
  )
}

export default SalesPurchaseSelectDialog
