import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import HistoryLayout from 'views/History/HistoryLayout'
import TradeFields from 'views/History/TradeHistory/TradeFields'
import TradeRecordkeeper from 'popcorn-js/trade/recordkeeper'
import {FullPageLoader as Loader} from 'components/Loader/Loader'
import {withTheme} from '@material-ui/styles'

const mapStateToProps = state => {
  return {
    currencyPairs: state.currencyPair.records ? state.currencyPair.records : [],
  }
}

class TradeHistoryLayoutContainer extends Component {
  constructor(props) {
    super(props)
    this.handleRetrieveHistory = this.handleRetrieveHistory.bind(this)

    this.state = {
      history: [],
      isLoading: false
    }
  }

  componentDidMount() {
    this.handleRetrieveHistory()
  }

  handleRetrieveHistory() {
    this.setState({isLoading: true})
    TradeRecordkeeper.retrieveHistory({
      identifier: {id: this.props.trade.id}
    }
    ).then(result => {
      const history = result.history.map(trade => trade)
      this.setState({history})
    })
      .catch(
        error => this.setState({errorMessage: error.message || error})
      )
      .finally(() => this.setState({isLoading: false}))
  }

  render() {
    const {
      onHide,
      trade,
      currencyPairs,
    } = this.props

    const {
      history,
      isLoading
    } = this.state

    return (
      <span>
        <Loader
          color={this.props.theme.palette.primary.main}
          isLoading={isLoading}
        />
        {!isLoading &&
        <HistoryLayout
          addEntityFieldsProps={{currencyPairs}}
          entity={trade}
          entityFields={TradeFields}
          entityHistory={history}
          entityName={'Trade'}
          onHide={onHide}
          open
        />}
      </span>
    )
  }

}

TradeHistoryLayoutContainer = withTheme(TradeHistoryLayoutContainer)
TradeHistoryLayoutContainer = connect(
  mapStateToProps,
  {},
)(TradeHistoryLayoutContainer)

export default TradeHistoryLayoutContainer

TradeHistoryLayoutContainer.propTypes = {
  /**
   * a bool indicating that the history dialog is open
   */
  onHide: PropTypes.func.isRequired,
  /**
   * function which will be called on closing the history dialog
   */
  open: PropTypes.bool.isRequired,
  /**
   * Trade which will have it's history shown
   */
  trade: PropTypes.object.isRequired,
}


