import EntryComparator from 'popcorn-js/audit/EntryComparator'
import ClientTier from 'popcorn-js/financial/clientTier/ClientTier'
import {isArray} from 'utils/Utils'

class Comparator {

  /**
   * @param {ClientTier} a
   * @param {ClientTier} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareExcluding(a, b, ...args) {
    for (const key in Object.keys(a)) {
      switch (key) {
        case 'auditEntry':
          if (!EntryComparator.CompareExcluding(a.auditEntry, b.auditEntry, ...args.filter(a => a.includes('auditEntry.')).map(a => a.replace('auditEntry.', '')))) {
            return false
          }
          break
        case 'currencyPairSpreads':
          if (!args.includes(key)) {
            if (!this.CompareCurrencyPairSpreads(a.currencyPairSpreads, b.currencyPairSpreads)) {
              return false
            }
          }
          break
        default:
          if (a[key] !== b[key] && !args.includes(key)) {
            return false
          }
      }
    }
    return true
  }

  /**
   * @param {ClientTier} a
   * @param {ClientTier} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareOnly(a, b, ...args) {
    for (const field in args) {
      if (args[field].includes('auditEntry.')) {
        if (!EntryComparator.CompareOnly(a.auditEntry, b.auditEntry,
          args[field].replace('auditEntry.', ''))) {
          return false
        }
      } else if (args[field].includes('currencyPairSpreads')) {
        if (!this.CompareCurrencyPairSpreads(a.currencyPairSpreads, b.currencyPairSpreads)) {
          return false
        }
      } else if (a[args[field]] !== b[args[field]]) {
        return false
      }
    }
    return true
  }

  /**
   * @param {ClientTier} a
   * @param {ClientTier} b
   * @return {boolean}
   */
  static CompareAll(a, b) {
    if (!(a instanceof ClientTier)) {
      console.error('Client tier comparator cannot compare objects: invalid type')
      return false
    }
    if (!(b instanceof ClientTier)) {
      console.error('Client tier comparator cannot compare objects: invalid type')
      return false
    }

    const keys = Object.keys(a)

    for (const i in keys) {
      switch (keys[i]) {
        case 'auditEntry':
          if (!EntryComparator.CompareAll(a.auditEntry, b.auditEntry)) {
            return false
          }
          break
        case 'currencyPairSpreads':
          if (!this.CompareCurrencyPairSpreads(a.currencyPairSpreads, b.currencyPairSpreads)) {
            return false
          }
          break
        default:
          if (a[keys[i]] !== b[keys[i]]) {
            return false
          }
      }
    }
    return true
  }

  /**
   *
   * @param {Array} a
   * @param {Array} b
   * @return {boolean}
   */
  static CompareCurrencyPairSpreads(a, b) {
    if (!isArray(a)) {
      console.error('invalid object passed to \'CompareClientTierSpreads\'.')
      return false
    }
    if (!isArray(b)) {
      console.error('invalid object passed to \'CompareClientTierSpreads\'.')
      return false
    }

    if (a.length !== b.length) {
      console.log('not equal length')
      return false
    }

    return !(a.some(spread1 => !b.find(spread2 => (
      spread1.bidSpreadPoints === spread2.bidSpreadPoints &&
        spread1.askSpreadPoints === spread2.askSpreadPoints &&
        spread1.currencyPair === spread2.currencyPair
    ))) ||
      b.some(spread2 => !a.find(spread1 => (
        spread1.bidSpreadPoints === spread2.bidSpreadPoints &&
        spread1.askSpreadPoints === spread2.askSpreadPoints &&
        spread1.currencyPair === spread2.currencyPair
      ))))
  }
}

export default Comparator
