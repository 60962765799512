import Account from 'popcorn-js/financial/account/Account'
import  AccountEntry from 'popcorn-js/financial/account/AccountEntry'
import  ClientTier from 'popcorn-js/financial/clientTier/ClientTier'
import  Currency from 'popcorn-js/financial/currency/Currency'
import  CurrencyPair from 'popcorn-js/financial/currencyPair/CurrencyPair'

export const financialYears = [
  'CURRENT',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016'
]
export {
  Account,
  AccountEntry,
  ClientTier,
  Currency,
  CurrencyPair
}