import React from 'react'
import {connect} from 'react-redux'
import OrderStation from 'views/OrderStation/OrderStation'

const OrderStationContainer = (props) => {
  return (
    <OrderStation
      currencies={props.currencies}
      partyCode={props.partyCode}
    />
  )
}

const mapStateToProps = state => {
  return {
    currencies: state.currency.records,
    partyCode: state.security.claims.context.partyCode
  }
}

export default connect(
  mapStateToProps,
)(OrderStationContainer)
