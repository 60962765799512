import {
  CREATE_CURRENCY_PAIR_ERROR,
  CREATE_CURRENCY_PAIR_STARTED,
  CREATE_CURRENCY_PAIR_SUCCESS,
  FIND_ALL_CURRENCY_PAIRS_SUCCESS,
  UPDATE_CURRENCY_PAIR_ERROR,
  UPDATE_CURRENCY_PAIR_STARTED,
  UPDATE_CURRENCY_PAIR_SUCCESS,
} from '../actions/actionTypes'

export default function currencyPair(state = {records: [], total: 0, action: {}}, action) {
  switch (action.type) {
    case FIND_ALL_CURRENCY_PAIRS_SUCCESS:
      return {...state, records: action.result.records ? action.result.records : [], total: action.result.total}
    case CREATE_CURRENCY_PAIR_STARTED:
      return {...state, action: {...state.action, created: undefined, error: undefined}}
    case CREATE_CURRENCY_PAIR_SUCCESS: {
      const records = state.records.slice() || []
      records.push(action.result.currencyPair)
      return {
        ...state,
        records,
        total: (state.total + 1),
        action: {...state.action, created: action.result.currencyPair, error: undefined}
      }
    }
    case CREATE_CURRENCY_PAIR_ERROR: {
      return {...state, action: {...state.action, created: undefined, error: action.error}}
    }
    case UPDATE_CURRENCY_PAIR_STARTED:
      return {...state, action: {...state.action, updated: undefined, error: undefined}}
    case UPDATE_CURRENCY_PAIR_SUCCESS: {
      const records = state.records.slice() || []
      const recordToReplaceIndex = records.findIndex(ccyPair => ccyPair.name === action.result.currencyPair.name)
      if (recordToReplaceIndex >= 0) {
        records[recordToReplaceIndex] = action.result.currencyPair
      }
      return {...state, records, action: {...state.action, updated: action.result.currencyPair, error: undefined}}
    }
    case UPDATE_CURRENCY_PAIR_ERROR: {
      return {...state, action: {...state.action, updated: undefined, error: action.error}}
    }

    default:
      return state
  }
}
