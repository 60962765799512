// ##############################
// // // Footer styles
// #############################

import {container, containerFluid, defaultFont,} from 'assets/jss/material-dashboard-pro-react'

const footerStyle = {
  block: {},
  left: {
    float: 'left!important',
    display: 'block'
  },
  right: {
    margin: '0',
    fontSize: 14,
    float: 'right!important',
  },
  footer: {
    bottom: '0',
    padding: '15px 0',
    ...defaultFont,
    zIndex: 4
  },
  container: {
    zIndex: 3,
    ...container,
    position: 'relative'
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: 'relative'
  },
  a: {
    color: '#76999d',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&:hover,&:focus': {
      color: '#00a2b9'
    }
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0'
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0',
    width: 'auto'
  },
  whiteColor: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF'
    }
  }
}
export default footerStyle
