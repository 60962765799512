import React, { useState, useEffect, useCallback, ReactElement } from 'react'
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Backdrop,
  CircularProgress
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {
  TradeParent,
  Trade,
  TradeDirection,
  TradeType
} from 'popcorn-js/trade/tradeTypes'
import { Currency } from 'popcorn-js/financial/currency/currencyType'
import { CurrencyPair } from 'popcorn-js/financial/currencyPair/currencyPairTypes'
import { BuySellStaticFull } from './BuySellStaticFull'
import { CaptureInformation } from './CaptureInformation'
import { ActionButton } from './styledComponents'
import Big from 'big.js'
import { useService } from 'hooks/useService'
import fromUnixTime from 'date-fns/fromUnixTime'
import { ProcessingBank, Company } from 'popcorn-js/legalEntity/party'
import { ConfirmDrawdownExtensionDetails } from './ConfirmDrawdownExtensionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ParentAllocations } from './ParentAllocations'
import { UserManager } from 'popcorn-js/legalEntities/user'
import { getNextTradeDate, getPreviousTradeDate, getMidDay } from './util'
import { isWeekend, parseISO } from 'date-fns'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import RatesHandler from 'popcorn-js/rick/handler';
import { Recordkeeper as TDERecordkeeper } from 'popcorn-js/financial/tradingDayException/recordkeeperT';
import { CriteriaType, CriterionFilterType } from 'popcorn-js/search';
import { TradingDayException } from 'popcorn-js/financial/tradingDayException';

interface DrawdownExtensionTicketProps {
  closeTicket: () => void;
  show: boolean;
  currencies: Currency[];
  currencyPairs: CurrencyPair[];
  banks?: ProcessingBank[];
  userId: string;
  party: Company;
  parentTrades: Trade[];
  tradeType: TradeType.DRAWDOWN|TradeType.EXTENSION;
}

const DrawdownExtensionTicket = (props: DrawdownExtensionTicketProps): ReactElement => {
  const { show, closeTicket, currencies, currencyPairs, party, userId, banks, parentTrades, tradeType } = props;
  
  const theme = useTheme()

  const today = getMidDay(new Date());
  const initialDate: Date = isWeekend(today) ? getNextTradeDate(today) : today;

  let cancellationBank: ProcessingBank|undefined
  let cancellationMaturityDateInitStr: string
  let cancellationMaturityDateInit: Date
  let direction: TradeDirection
  switch (parentTrades[0].tradeType) {
    case TradeType.DRAWDOWN:
    case TradeType.FORWARD:
    case TradeType.SPOT: {
      const relevantLeg = parentTrades && parentTrades[0] && parentTrades[0].legs ? parentTrades[0].legs[0] : undefined
      cancellationMaturityDateInit = parentTrades && parentTrades[0].legs ? getMidDay(fromUnixTime(parentTrades[0].legs[0].maturityDate)) : initialDate
      cancellationMaturityDateInitStr = cancellationMaturityDateInit.toISOString()
      direction = parentTrades && parentTrades[0].legs && relevantLeg ? relevantLeg.direction : TradeDirection.BUY
      cancellationBank = banks?.find((b: ProcessingBank) => b.partyCode === relevantLeg?.bank)
      break
    }
    case TradeType.EXTENSION: {
      const relevantLeg = parentTrades && parentTrades[0] && parentTrades[0].legs ? parentTrades[0].legs[1] : undefined
      cancellationMaturityDateInit = parentTrades && parentTrades[0].legs ? getMidDay(fromUnixTime(parentTrades[0].legs[1].maturityDate)) : initialDate
      cancellationMaturityDateInitStr = cancellationMaturityDateInit.toISOString()
      direction = parentTrades && parentTrades[0].legs && relevantLeg? relevantLeg.direction : TradeDirection.SELL
      cancellationBank = banks?.find((b: ProcessingBank) => b.partyCode === relevantLeg?.bank)
      break
    }
    default:
      throw new Error('parent trade type must be FORWARD, SPOT, DRAWDOWN or EXTENSION')
  }

  const nextTradingDate = getNextTradeDate(cancellationMaturityDateInit).toISOString()
  const previousTradingDate = getPreviousTradeDate(cancellationMaturityDateInit).toISOString()

  // leg-specific trade state
  const [maturityDate, setMaturityDate] = useState<string | null | undefined>(tradeType === TradeType.DRAWDOWN ? initialDate.toISOString() : nextTradingDate)
  const [externalReference, setExternalReference] = useState<string | undefined>()
  const [spotRate, setSpotRate] = useState<Big | undefined>()
  const [allInRate, setAllInRate] = useState<Big | undefined>()
  const [forwardPoints, setForwardPoints] = useState<Big | undefined>()
  const [interbankRate, setInterbankRate] = useState<Big | undefined>()
  const [bankRate, setBankRate] = useState<Big | undefined>()
  const [buyAmount, setBuyAmount] = useState<Big | undefined>()
  const [sellAmount, setSellAmount] = useState<Big | undefined>()
  const [bank, setBank] = useState<ProcessingBank | undefined>()
  const [cancellationMaturityDate, setCancellationMaturityDate] = useState<string | null | undefined>(cancellationMaturityDateInitStr)
  const [cancellationExternalReference, setCancellationExternalReference] = useState<string | undefined>()
  const [cancellationSpotRate, setCancellationSpotRate] = useState<Big | undefined>()
  const [cancellationAllInRate, setCancellationAllInRate] = useState<Big | undefined>()
  const [cancellationForwardPoints, setCancellationForwardPoints] = useState<Big | undefined>()
  const [cancellationInterbankRate, setCancellationInterbankRate] = useState<Big | undefined>()
  const [cancellationBankRate, setCancellationBankRate] = useState<Big | undefined>()
  const [cancellationBuyAmount, setCancellationBuyAmount] = useState<Big | undefined>()
  const [cancellationSellAmount, setCancellationSellAmount] = useState<Big | undefined>()
  // common trade state
  const [tradeDate, setTradeDate] = useState<string | null | undefined>(initialDate.toISOString())
  const [capturedSpotRate, setCapturedSpotRate] = useState<Big | undefined>()
  const [ratesLoading, setRatesLoading] = useState<boolean>(true)
  const [traderOrganisation, setTraderOrganisation] = useState<string | undefined>(party.parentPartyCode)
  const [trader, setTrader] = useState<string | undefined>()
  const [capturedBy, setCapturedBy] = useState<string | undefined>()
  const [notes, setNotes] = useState<string | undefined>()
  const [capturedDate, setCapturedDate] = useState<string | null | undefined>(new Date().toISOString())
  // general state
  const [confirmTradeDetailsOpen, setConfirmTradeDetailsOpen] = useState<boolean>(false)
  const [canRecord, setCanRecord] = useState<boolean>(false)
  const [tradeParents, setTradeParents] = useState<TradeParent[]>([]);
  const [tradeParentsTotalAllocatedAmount, setTradeParentsTotalAllocatedAmount] = useState<Big | undefined>()
  const [nonTradingDays, setNonTradingDays] = useState<Date[]>([]);

  // this effect fires shortly after the component has been mounted so set the maturity date to undefined - this is
  // needed to force the user to choose a maturity date; the MUI KeyboardDatePicker generally sucks (but it's the best out there),
  // so is REALLY bad with invalid values, and has to be instantiated with a valid date
  useEffect(() => {
    const timer = setTimeout(() => {
      setMaturityDate(undefined);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const currencyPair = currencyPairs.find((currencyPair: CurrencyPair) => currencyPair.id === parentTrades[0].currencyPairId)

  const currencyIDs: Set<string> = new Set();
  currencyPairs.forEach((currencyPair: CurrencyPair) => {
    currencyIDs.add(currencyPair.quoteCurrencyId)
    currencyIDs.add(currencyPair.baseCurrencyId)
  })

  const getFindTDEsRequest = useCallback((): any => ({
    criteria: [
      { type: CriteriaType.ExactCriterion, field: 'currencyId', text: currencyPair?.baseCurrencyId },
      { type: CriteriaType.ExactCriterion, field: 'currencyId', text: currencyPair?.quoteCurrencyId },
    ],
    filterType: CriterionFilterType.Or,
  }), [currencyPair]);

  const [{ response: findTDEsResponse, loading: findTDEsLoading }, setFindTDEsRequest] = useService(
    getFindTDEsRequest(),
    TDERecordkeeper.find,
  );

  useEffect(() => {
    if (findTDEsResponse && findTDEsResponse.records) {
      const _nonTradingDays = findTDEsResponse.records.map((tde: TradingDayException) =>
        getMidDay(parseISO(tde.date || '')),
      );
      setNonTradingDays(_nonTradingDays);
    }
  }, [findTDEsResponse]);
  // effect to get new TDEs for currencies
  useEffect(() => setFindTDEsRequest(getFindTDEsRequest()), [currencyPair, getFindTDEsRequest, setFindTDEsRequest]);

  const [
    {
      response: getRateResponse,
      loading: getRateLoading,
    },
    setGetRateRequest
  ] = useService(undefined, RatesHandler.RetrieveRate)

  useEffect(() => {
    setGetRateRequest({
      rateSubscription: {
        currencyPairName: currencyPair?.name,
        date: 0,
      }
    })
  }, [currencyPair, setGetRateRequest])

  useEffect(() => {
    if (getRateResponse && !getRateLoading) {
      setCapturedSpotRate((Big(getRateResponse.priceSubscriptionSucceeded.askSpotPrice).plus(Big(getRateResponse.priceSubscriptionSucceeded.bidSpotPrice))).div(2))
      setRatesLoading(false)
    }
  }, [getRateResponse, getRateLoading])

  const [{
    response: getUserProfileByIdResponse,
    loading: getUserProfileByIdLoading,
  }] = useService({userId}, UserManager.getUserProfileById)

  useEffect(() => {
    if (getUserProfileByIdResponse && getUserProfileByIdResponse.displayName) {
      setTrader(getUserProfileByIdResponse.displayName)
      setCapturedBy(getUserProfileByIdResponse.displayName)
    }
  }, [getUserProfileByIdResponse])

  const determineBuyCurrency = useCallback((): Currency|undefined => {
    if (direction === TradeDirection.BUY) {
      return currencies.find((currency: Currency) => currencyPair?.baseCurrencyId === currency.id)
    }
    return currencies.find((currency: Currency) => currencyPair?.quoteCurrencyId === currency.id)
  }, [direction, currencies, currencyPair])

  const determineSellCurrency = useCallback((): Currency|undefined => {
    if (direction === TradeDirection.BUY) {
      return currencies.find((currency: Currency) => currencyPair?.quoteCurrencyId === currency.id)
    }
    return currencies.find((currency: Currency) => currencyPair?.baseCurrencyId === currency.id)
  }, [direction, currencies, currencyPair])

  useEffect(() => {
    setCanRecord(
      !!externalReference && !!cancellationExternalReference &&
      !!buyAmount && !!cancellationBuyAmount &&
      !!sellAmount && !!cancellationSellAmount &&
      !!tradeDate &&
      !!maturityDate && !!cancellationMaturityDate &&
      !!allInRate && !!cancellationAllInRate &&
      !!bankRate && !!cancellationBankRate &&
      !!trader &&
      !!bank && !!cancellationBank
    )
    // todo add field validation
  }, [
    externalReference,
    cancellationExternalReference,
    buyAmount,
    cancellationBuyAmount,
    sellAmount,
    cancellationSellAmount,
    tradeDate,
    maturityDate,
    cancellationMaturityDate,
    allInRate,
    cancellationAllInRate,
    trader,
    bank,
    cancellationBank,
    bankRate,
    cancellationBankRate,
  ])

  return (
    <Dialog
      fullScreen
      onClose={() => closeTicket()}
      open={show}
      style={{
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <AppBar
        style={{
          position: 'relative',
          backgroundColor: '#0A7280'
        }}>
        <Toolbar>
          <Typography 
            style={{
              marginLeft: theme.spacing(2),
              flex: 1
            }} 
            variant="h5"
          >
            Capture {tradeType === TradeType.DRAWDOWN ? 'Drawdown' : 'Extension'}
          </Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            edge="end"
            onClick={() => {
              // setOpen(false);
              closeTicket();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <PerfectScrollbar>
        <div
          style={{ 
            height: '100%', 
            width: '100%', 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-between',
            // overflowY: 'scroll'
          }}>
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <div>
              <div style={{ width: '100%' }}>
                <div style={{ width: '100%', marginLeft: '32px', marginBottom: '16px', marginTop: '24px' }}>
                  <ParentAllocations
                    parentTrades={parentTrades}
                    totalChange={setTradeParentsTotalAllocatedAmount}
                    tradeParentsChange={setTradeParents}
                  />
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div style={{ float: 'left', marginLeft: '32px', marginRight: '32px'  }}>
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon style={{color: '#FFFFFF'}} />}
                      style={{
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        backgroundColor: (theme.palette as any).primary.main,
                        borderTopRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                      }}
                    >
                      <span style={{fontFamily: 'Roboto', fontSize: '18px', fontWeight: 'bold'}}>
                        {tradeType === TradeType.DRAWDOWN ? 'Drawdown': 'Cancellation'} Leg
                      </span>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0, paddingTop: '16px'}}>
                      {tradeType === TradeType.DRAWDOWN &&
                      <BuySellStaticFull
                        allInRate={allInRate}
                        allInRateChange={setAllInRate}
                        // using the cancellationBank here is by desgin, in order that the two banks follow each other
                        bank={bank}
                        bankChange={setBank}
                        bankEditable
                        bankRateChange={setBankRate}
                        banks={banks}
                        buyAmountChange={setBuyAmount}
                        buyCurrency={determineBuyCurrency()}
                        direction={direction}
                        externalReferenceChange={setExternalReference}
                        foreignCurrencyAmount={tradeParentsTotalAllocatedAmount}
                        forwardPointsChange={setForwardPoints}
                        interbankRateChange={setInterbankRate}
                        maturityDate={maturityDate}
                        maturityDateChange={setMaturityDate}
                        maturityDateEditable
                        maturityDateMax={previousTradingDate}
                        nonTradingDays={nonTradingDays}
                        parentDirection={direction}
                        // using the cancellationSpotRate here is by desgin, in order that the two rates follow each other
                        sellAmountChange={setSellAmount}
                        sellCurrency={determineSellCurrency()}
                        spotRate={cancellationSpotRate}
                        spotRateChange={setSpotRate}
                        tradeDate={tradeDate}
                        tradeDateChange={(date: string | null | undefined) =>
                          setTradeDate(date)
                        }
                      />
                      }
                      {tradeType === TradeType.EXTENSION &&
                      <BuySellStaticFull
                        allInRate={cancellationAllInRate}
                        allInRateChange={setCancellationAllInRate}
                        // using the drawdownBank here is by desgin, in order that the two banks follow each other
                        bank={cancellationBank}
                        bankEditable={false}
                        bankRateChange={setCancellationBankRate}
                        banks={banks}
                        buyAmountChange={setCancellationBuyAmount}
                        buyCurrency={determineSellCurrency()}
                        direction={direction === TradeDirection.BUY ? TradeDirection.SELL : TradeDirection.BUY}
                        externalReferenceChange={setCancellationExternalReference}
                        foreignCurrencyAmount={tradeParentsTotalAllocatedAmount}
                        forwardPointsChange={setCancellationForwardPoints}
                        interbankRateChange={setCancellationInterbankRate}
                        maturityDate={cancellationMaturityDate}
                        maturityDateChange={setCancellationMaturityDate}
                        maturityDateEditable={false}
                        maturityDateMax={previousTradingDate}
                        nonTradingDays={nonTradingDays}
                        parentDirection={direction}
                        // using the drawdownSpotRate here is by desgin, in order that the two rates follow each other
                        sellAmountChange={setCancellationSellAmount}
                        sellCurrency={determineBuyCurrency()}
                        spotRate={spotRate}
                        spotRateChange={setCancellationSpotRate}
                        tradeDate={tradeDate}
                        tradeDateChange={(date: string | null | undefined) =>
                          setTradeDate(date)
                        }
                      />
                      }
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div style={{ float: 'left', marginLeft: '32px', marginRight: '32px'   }}>
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon style={{color: '#FFFFFF'}} />}
                      style={{
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        backgroundColor: (theme.palette as any).primary.main,
                        borderTopRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                      }}
                    >
                      <span style={{fontFamily: 'Roboto', fontSize: '18px', fontWeight: 'bold'}}>
                        {tradeType === TradeType.DRAWDOWN ? 'Cancellation': 'Extension'} Leg
                      </span>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0, paddingTop: '16px'}}>
                      {tradeType === TradeType.DRAWDOWN &&
                      <BuySellStaticFull
                        allInRate={cancellationAllInRate}
                        allInRateChange={setCancellationAllInRate}
                        // using the drawdownBank here is by desgin, in order that the two banks follow each other
                        bank={cancellationBank}
                        bankEditable={false}
                        bankRateChange={setCancellationBankRate}
                        banks={banks}
                        buyAmountChange={setCancellationBuyAmount}
                        buyCurrency={determineSellCurrency()}
                        direction={direction === TradeDirection.BUY ? TradeDirection.SELL : TradeDirection.BUY}
                        externalReferenceChange={setCancellationExternalReference}
                        foreignCurrencyAmount={tradeParentsTotalAllocatedAmount}
                        forwardPointsChange={setCancellationForwardPoints}
                        interbankRateChange={setCancellationInterbankRate}
                        maturityDate={cancellationMaturityDate}
                        maturityDateChange={setCancellationMaturityDate}
                        maturityDateEditable={false}
                        maturityDateMax={previousTradingDate}
                        nonTradingDays={nonTradingDays}
                        parentDirection={direction}
                        // using the drawdownSpotRate here is by desgin, in order that the two rates follow each other
                        sellAmountChange={setCancellationSellAmount}
                        sellCurrency={determineBuyCurrency()}
                        spotRate={spotRate}
                        spotRateChange={setCancellationSpotRate}
                        tradeDate={tradeDate}
                        tradeDateChange={(date: string | null | undefined) =>
                          setTradeDate(date)
                        }
                      />
                      }
                      {tradeType === TradeType.EXTENSION &&
                      <BuySellStaticFull
                        allInRate={allInRate}
                        allInRateChange={setAllInRate}
                        // using the cancellationBank here is by desgin, in order that the two banks follow each other
                        bank={bank}
                        bankChange={setBank}
                        bankEditable
                        bankRateChange={setBankRate}
                        banks={banks}
                        buyAmountChange={setBuyAmount}
                        buyCurrency={determineBuyCurrency()}
                        direction={direction}
                        externalReferenceChange={setExternalReference}
                        foreignCurrencyAmount={tradeParentsTotalAllocatedAmount}
                        forwardPointsChange={setForwardPoints}
                        interbankRateChange={setInterbankRate}
                        maturityDate={maturityDate}
                        maturityDateChange={setMaturityDate}
                        maturityDateEditable
                        maturityDateMin={nextTradingDate}
                        nonTradingDays={nonTradingDays}
                        parentDirection={direction}
                        // using the cancellationSpotRate here is by desgin, in order that the two rates follow each other
                        sellAmountChange={setSellAmount}
                        sellCurrency={determineSellCurrency()}
                        spotRate={cancellationSpotRate}
                        spotRateChange={setSpotRate}
                        tradeDate={tradeDate}
                        tradeDateChange={(date: string | null | undefined) =>
                          setTradeDate(date)
                        }
                      />
                      }
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div style={{ float: 'left', width: '100%' }}>
                  <ActionButton
                    disabled={!canRecord}
                    onClick={() => setConfirmTradeDetailsOpen(true)}
                    style={{marginLeft: '32px', marginTop: '16px', marginBottom: '16px'}}
                  >
                    Record trade information
                  </ActionButton>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ 
              height: '100%', 
            }}>
            <CaptureInformation
              capturedBy={capturedBy}
              capturedDate={capturedDate}
              capturedDateChanged={setCapturedDate}
              capturedSpotRate={capturedSpotRate}
              notesChanged={setNotes}
              party={party}
              traderChanged={setTrader}
              traderOrganisation={traderOrganisation}
              traderOrganisationChanged={setTraderOrganisation}
            />
          </div>
        </div>
      </PerfectScrollbar>

      {confirmTradeDetailsOpen && 
        <ConfirmDrawdownExtensionDetails
          allInRate={allInRate}
          bank={bank}
          bankRate={bankRate}
          buyAmount={buyAmount}
          buyCurrency={determineBuyCurrency()}
          cancellationAllInRate={cancellationAllInRate}
          cancellationBank={cancellationBank}
          cancellationBankRate={cancellationBankRate}
          cancellationBuyAmount={cancellationBuyAmount}
          cancellationExternalReference={cancellationExternalReference}
          cancellationForwardPoints={cancellationForwardPoints}
          cancellationInterbankRate={cancellationInterbankRate}
          cancellationMaturityDate={cancellationMaturityDate}
          cancellationSellAmount={cancellationSellAmount}
          cancellationSpotRate={cancellationSpotRate}
          capturedSpotRate={capturedSpotRate}
          currencyPair={currencyPair}
          direction={direction}
          externalReference={externalReference}
          forwardPoints={forwardPoints}
          interbankRate={interbankRate}
          maturityDate={maturityDate}
          notes={notes}
          onClose={() => {
            setConfirmTradeDetailsOpen(false);
          }}
          onFinished={() => {
            setConfirmTradeDetailsOpen(false);
            closeTicket();
          }}
          open={confirmTradeDetailsOpen}
          parentPartyCode={party.parentPartyCode}
          party={party}
          sellAmount={sellAmount}
          sellCurrency={determineSellCurrency()}
          spotRate={spotRate}
          tradeDate={tradeDate}
          tradeParents={tradeParents}
          tradeType={tradeType}
          trader={trader}
          traderOrganisation={traderOrganisation}
        />
      }
      <Backdrop
        open={ratesLoading || getUserProfileByIdLoading || findTDEsLoading}
        style={{zIndex: 10}}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default DrawdownExtensionTicket;
