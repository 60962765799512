import React from 'react'
import Grid from '@material-ui/core/Grid'
import {roundScaleAndFormatNumber} from 'views/Home/Company/Dashboard/Util'
import {MdInfo as HelpIcon} from 'react-icons/md'
import {makeStyles, Theme} from '@material-ui/core'
import LightTooltip from 'components/LightTooltp/LightTooltip'
import {Currency} from 'popcorn-js/financial/currency/currencyType';
import {GenerateMonthViewCashFlowReportResponse} from 'popcorn-js/report/generatorTS';

const useStyles = makeStyles((theme: Theme) => ({
  helpIconWrapper: {
    width: '14px',
    height: '14px',
    color: theme.palette.customColors.greyText1,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  helpIcon: {
    width: '14px',
    height: '14px',
  },
  root: {
    padding: theme.spacing(),
  },
  fontWeightBold: {
    display: 'grid',
    gridTemplateColumns: ' max-content 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    gridGap: theme.spacing()
  },
  sectionLayout: {
    display: 'grid',
    gridTemplateColumns: '100px 240px',
  },
  sectionStyle: {
    color: theme.palette.text.primary
  },
}))

interface CashFlowTotalsProps {
  currency: Currency,
  report: GenerateMonthViewCashFlowReportResponse,
  width: number,
}

const CashFlowTotals: React.FC<CashFlowTotalsProps> = (props: CashFlowTotalsProps) => {
  const classes = useStyles()
  const totals = (props.report || {}).totals || {}
  const localCurrency = props.currency

  const salesDue = totals.salesDue?.totalAmountInCostCurrency
  const purchaseDue = totals.purchaseDue?.totalAmountInCostCurrency

  const purchaseOverDue = totals.purchaseOverDue?.totalAmountInCostCurrency
  const salesOverDue = totals.salesOverDue?.totalAmountInCostCurrency


  const purchaseHedge = totals.purchaseHedge?.totalAmountInCostCurrency
  const salesHedge = totals.salesHedge?.totalAmountInCostCurrency
  const purchaseSalesHedge = totals.purchaseSalesHedge?.totalAmountInCostCurrency

  const showPurchaseTotals = (purchaseDue !== 0 || purchaseOverDue !== 0 || purchaseHedge !==0 )
  const showSalesTotals = (salesDue !== 0 || salesOverDue !== 0 || salesHedge !==0 )
  const showTradeForecasted = (showPurchaseTotals || showSalesTotals)
  const showTradeForecastedNextToPurchase = showPurchaseTotals

  const TotalItem = (props: {
    title: string
    tooltipTitle: string
    value: number
  }) => {

    return (
      <div
        className={classes.sectionLayout}
      >
        <div>
          {roundScaleAndFormatNumber(props.value, localCurrency.symbol, false, 0)}
        </div>
        <div
          className={classes.fontWeightBold}
        >
          <div>{props.title}</div>
          <div>
            <LightTooltip
              placement={'top-end'}
              title={props.tooltipTitle}
            >
              <span className={classes.helpIconWrapper}>
                <HelpIcon className={classes.helpIcon}/>
              </span>
            </LightTooltip>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        paddingLeft: 5,
      }}
    >
      <Grid
        alignItems={'center'}
        classes={{root: classes.root}}
        container
        direction={'column'}
        justify="center"
        style={{
          width: '1150px'
        }}
      >
        <Grid
          alignItems={'center'}
          classes={{root: classes.root}}
          container
          direction={'row'}
          justify="center"
        >
          {showPurchaseTotals && <Grid
            classes={{root: classes.sectionStyle}}
            container
            direction={'row'}
            sm={4}
            xs={12}
          >
            <TotalItem
              title={'Total Purchase Contracts'}
              tooltipTitle={'Sum of all Purchase Contracts'}
              value={purchaseDue}
            />
          </Grid>
          }

          {showPurchaseTotals && <Grid
            classes={{root: classes.sectionStyle}}
            container
            direction={'row'}
            sm={4}
            xs={12}>
            <TotalItem
              title={'Total Purchase Contracts Overdue'}
              tooltipTitle={'Sum of all Purchase Contracts in arrears'}
              value={purchaseOverDue}
            />
          </Grid>
          }
          {showTradeForecasted && showTradeForecastedNextToPurchase &&
          <Grid
            classes={{root: classes.sectionStyle}}
            container
            direction={'row'}
            sm={4}
            xs={12}>

            <TotalItem
              title={'Total Future Net Trade Cash Flows'}
              tooltipTitle={'Sum of all Future Net Trade Cash Flows'}
              value={purchaseSalesHedge}
            />
          </Grid>}

        </Grid>
        <Grid
          alignItems={'center'}
          classes={{root: classes.root}}
          container
          direction={'row'}
          justify="center"
        >
          {showSalesTotals && <Grid
            classes={{root: classes.sectionStyle}}
            container
            direction={'row'}
            sm={4}
            xs={12}>
            <TotalItem
              title={'Total Sales Contracts'}
              tooltipTitle={'Sum of all Sales Contracts'}
              value={salesDue}
            />
          </Grid>
          }

          {showSalesTotals && <Grid
            classes={{root: classes.sectionStyle}}
            container
            direction={'row'}
            sm={4}
            xs={12}>
            <TotalItem
              title={'Total Sales Contracts Overdue'}
              tooltipTitle={'Sum of all Sales Contracts in arrears'}
              value={salesOverDue}
            />
          </Grid>
          }
          {showTradeForecasted && !showTradeForecastedNextToPurchase &&
          <Grid
            classes={{root: classes.sectionStyle}}
            container
            direction={'row'}
            sm={4}
            xs={12}>

            <TotalItem
              title={'Total Future Net Trade Cash Flows'}
              tooltipTitle={'Sum of all Future Net Trade Cash Flows'}
              value={purchaseSalesHedge}
            />
          </Grid>}
          {showTradeForecasted && showTradeForecastedNextToPurchase && <Grid
            classes={{root: classes.sectionStyle}}
            container
            direction={'row'}
            sm={4}
            xs={12}>
            <div/>
          </Grid>
          }
        </Grid>
      </Grid>
    </div>
  )
}

export default CashFlowTotals
