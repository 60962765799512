import {
  FIND_ALL_PARTY_USERS_SUCCESS,
  FIND_ALL_ROLES_SUCCESS,
  RETRIEVE_MY_PARTY_SUCCESS,
  STATUS_OF_DOCUMENTS_SUCCESS,
  UPDATE_USER_ROLES_SUCCESS,
  UPDATE_USER_SUCCESS,
} from '../actions/actionTypes'

export default function myParty(state = {
  roles: {records: [], total: 0},
  users: {records: [], total: 0},
  currencyPairIds: [],
}, action) {
  switch (action.type) {
    case FIND_ALL_ROLES_SUCCESS:
      return {
        ...state,
        roles: {
          records: action.result.records ? action.result.records : [],
          total: action.result.total,
        },
      }
    case UPDATE_USER_SUCCESS:
    case UPDATE_USER_ROLES_SUCCESS:
      const updatedUserRecords = state.users.records.map((user) => {
        if (user.id === action.result.user.id) return action.result.user
        return user
      })
      return {
        ...state,
        users: {records: updatedUserRecords, total: updatedUserRecords.length},
      }
    case FIND_ALL_PARTY_USERS_SUCCESS:
      return {
        ...state,
        users: {
          records: action.result.records ? action.result.records : [],
          total: action.result.total,
        },
      }
    case RETRIEVE_MY_PARTY_SUCCESS:
      switch (true) {
        case ('system' in action.result):
          return {...state, ...action.result.system}
        case ('processingBank' in action.result):
          return {...state, ...action.result.processingBank}
        case ('processingOrg' in action.result):
          return {...state, ...action.result.processingOrg}
        case ('broker' in action.result):
          return {...state, ...action.result.broker}
        case ('company' in action.result):
          return {...state, ...action.result.company}
        case ('individual' in action.result):
          return {...state, ...action.result.individual}
        default:
          return state
      }
    case STATUS_OF_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.result.documents ? action.result.documents : [],
      }
    default:
      return state
  }
}
