import config from 'react-global-configuration'
import {jsonRpcRequestRaw, httpRequest} from 'actions/helper/utilities'

export default class Authenticator {
  static authenticate(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Authenticator.Authenticate',
      request,
    })
  }

  static refreshAuthentication() {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Authenticator.RefreshAuthentication',
    })
  }

  static logout() {
    return httpRequest({
      url: `${config.get('url')}/logout`,
    })
  }

  static login(loginRequest) {
    return httpRequest({
      url: `${config.get('url')}/login`,
      request: loginRequest,
    })
  }

  static refresh() {
    return httpRequest({
      url: `${config.get('url')}/refresh`,
    })
  }
}
