import {isObject} from '../../../utils/Utils';
import AuditEntry from '../../audit/Entry'

export default class NaturalPerson {
  /**
   * @type {string}
   */
  id = '';
  /**
   * @type {string}
   */
  firstName = '';
  /**
   * @type {string}
   */
  surname = '';
  /**
   * @type {string}
   */
  initials = '';
  /**
   * @type {string}
   */
  auditEntry

  constructor(naturalPerson) {
    if (
      (naturalPerson !== undefined) &&
      (isObject(naturalPerson))
    ) {
      try {
        this.initializeNaturalPerson(naturalPerson)
      } catch (e) {
        console.error(`error constructing user: ${e}`)
      }
    }

  }

  initializeNaturalPerson(naturalPerson) {
    this.id = naturalPerson.id;
    this.firstName = naturalPerson.firstName;
    this.surname = naturalPerson.surname;
    this.initials = naturalPerson.initials;
    this.auditEntry = new AuditEntry(naturalPerson.auditEntry)

  }


}
