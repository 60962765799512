const requiredFields = ['name'];

class Validator {
  static ValidateCounterparty(counterparty) {
    return new Promise((resolve, reject) => {
      const invalidFields = {};

      let valid = true;

      requiredFields.forEach((fieldName) => {
        if (!counterparty[fieldName] || counterparty[fieldName] === '') {
          invalidFields[fieldName] = 'required';
          valid = false;
        }
      });
      if (valid) {
        resolve(undefined);
      } else {
        reject(invalidFields);
      }
    });
  }
}

export default Validator;
