import {INVOICE_DIRECTION_PAYABLE} from 'constants/invoice'

export const commitmentReport = {
  name: 'Commitment Report',
  // These will be looked for in first 20 rows and columns
  // if all are found the configuration is inferred
  identifiers: [
    'FOREIGN COMMITMENT REPORT',
  ],
  headerRowNo: 4,
  headerMap: {
    'COP #': {
      initField: 'number',
    },
    'Name': {
      initField: 'counterparty',
    },
    'Foreign Total': {
      initField: 'amountDue',
      preParser: (val) => {
        return val.replace(',', '')
      },
      // postProcessor: (val) => {
      //   return val
      // },
    },
    'Curr': {
      initField: 'currency',
      preParser: (val) => {
        const curMap = {
          'US': 'USD',
          'R': 'ZAR',
          'EU': 'EUR',
        }
        const retVal = curMap[val]
        return retVal ? retVal : val
      }
    },
    'Rate': {
      initField: 'costingRate',
    },
    'E.T.D': {
      initField: 'shippingDate',
    },
    'Container #': {
      initField: 'shipmentReference',
    },
  },
  consts: {
    'direction': INVOICE_DIRECTION_PAYABLE,
    'costingCurrency': 'ZAR',
  },
  ignoreRowRules: {
    'COP #': '',
    'Name': '',
  },
  rowGroupValues: {
    'DUE DATE': {
      valColHdr: 'Foreign Total',
      initField: 'dueDate',
    },
  },
}

export const mosaicReport = {
  name: 'Mosaic Report',
  // These will be looked for in first 20 rows and columns
  // if all are found the configuration is inferred
  identifiers: [
    'COP #',
    'Name',
    'Terms',
    'Container #',
    'BRANCH',
    'ETD',
    'ETA',
    'QUANITY',
    'PAY METHOD',
    'CURRENCY',
    'FOREIGN TOTAL',
  ],
  headerRowNo: 1,
  headerMap: {
    'COP #': {
      initField: 'number',
    },
    'Name': {
      initField: 'counterparty',
    },
    'CURRENCY': {
      initField: 'amountDue',
      // postProcessor: (val) => {
      //   return -1 * val
      // },
    },
    'FOREIGN TOTAL': {
      initField: 'currency',
      preParser: (val) => {
        const curMap = {
          'US': 'USD',
          'R': 'ZAR',
          'EU': 'EUR',
        }
        const retVal = curMap[val]
        return retVal ? retVal : val
      }
    },
    'EXCHANGE RATE': {
      initField: 'costingRate',
    },
    'ETD': {
      initField: 'shippingDate',
      preParser: (val) => {
        try {
          if (!val.includes('/')) return ''
          const dateParts = val.split('/')
          const days = dateParts[0]
          dateParts[0] = dateParts[1]
          dateParts[1] = days
          return dateParts.join('/')
        } catch (e) {
          console.error(`error pre-parsing shipping date\n${e}`)
        }
      }
    },
    'COD DATE': {
      initField: 'dueDate',
      preParser: (val) => {
        try {
          if (!val.includes('/')) return ''
          const dateParts = val.split('/')
          const days = dateParts[0]
          dateParts[0] = dateParts[1]
          dateParts[1] = days
          return dateParts.join('/')
        } catch (e) {
          console.error(`error pre-parsing due date\n${e}`)
        }
      },
      alternateField: 'ETA',
    },
    'Container #': {
      initField: 'shipmentReference',
    },
  },
  consts: {
    'direction': INVOICE_DIRECTION_PAYABLE,
    'costingCurrency': 'ZAR',
  },
  ignoreRowRules: {
    'COP #': '',
    'Name': '',
  },
}

export const orderCanonical = {
  name: 'Order Canonical',

  identifiers: [
    'Number',
    'Counterparty',
    'Amount Due',
    'Costing Rate',
    'Currency',
    'Cost Currency',
    'Due Date',
    'Shipping Date',
    'Shipment Reference',
    'Notes',
    'Status',
  ],

  headerRowNo: 1,
  headerMap: {
    'Number': {
      initField: 'number',
    },
    'Counterparty': {
      initField: 'counterpartyName',
    },
    'Amount Due': {
      initField: 'amountDue',
    },
    'Costing Rate': {
      initField: 'costingRate',
    },
    'Currency': {
      initField: 'currencyIsoCode',
    },
    'Cost Currency': {
      initField: 'costCurrencyIsoCode',
    },
    'Linked Invoice': {
      initField: 'invoiceNumber',
    },
    'Due Date': {
      initField: 'dueDate',
    },
    'Issue Date': {
      initField: 'issueDate',
    },
    'Shipping Date': {
      initField: 'shippingDate',
    },
    'Shipment Reference': {
      initField: 'shipmentReference',
    },
    'Notes': {
      initField: 'notes',
    },
    'Status': {
      initField: 'status',
    },
    'Import/Export': {
      initField: 'importExport',
    },

  }
}
