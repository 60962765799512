import React from 'react'
// Material UI components
import {Card, CardContent, CardHeader, Dialog, Grid, makeStyles, Theme,} from '@material-ui/core'
// Custom components
import {RoleDetails, RoleList} from './components'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'
import HistoryLayout from 'views/History/HistoryLayout'
import {
  createAction,
  deleteAction,
  deleteForeverAction,
  discardAction,
  restoreAction,
  returnAction,
  saveAction,
  viewDelete,
  viewHistory,
} from './actions'
// Services
import {RoleComparator, RoleRecordkeeper} from 'popcorn-js/security/role'
// Constants
import {ALL_PROTECTED_ROLES} from 'constants/role'
import {RoleFields} from 'views/History/RoleHistory/RoleFields'
import Role from 'popcorn-js/legalEntities/role/Role'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
    height: '100%',
  },
  card: {
    border: `1px solid ${theme.palette.primary.light}`,
    height: '100%',
  },
  cardContent: {
    padding: 0,
  },
  title: {
    fontWeight: 'bold',
  },
  progress: {
    // eslint-disable-next-line
    // @ts-ignore
    color: theme.palette.highActions,
    margin: theme.spacing(2),
  },
  progressSpinnerDialog: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  progressSpinnerDialogBackdrop: {
    backgroundColor: 'transparent',
  },
}))

// services
const findRoles = async (request: any): Promise<any> => {
  const {
    criteria,
    query,
    deleted,
    setMessage,
  } = request

  try {
    const findResponse = await RoleRecordkeeper.find({criteria, query, deleted})
    return (findResponse)
  } catch (e) {
    setMessage({error: e.message || e})
  }
}

const findPermissions = async (request: any): Promise<Array<any> | void> => {
  const {setMessage} = request
  try {
    const findResponse = await RoleRecordkeeper.retrieveAllUserPermissions()
    return findResponse.permissions
  } catch (e) {
    setMessage({error: e.message || e})
  }
}

interface RolesProps {
  partyCode: string
}

const Roles: React.FC<RolesProps> = (props: RolesProps) => {
  const classes = useStyles()
  const {partyCode} = props
  const [actions, setActions] = React.useState<Array<any>>()
  const [roles, setRoles] = React.useState<Array<any>>([])
  const [isLoading, setLoading] = React.useState<boolean>(true)
  const [selected, setSelected] = React.useState<Role>(new Role({partyCode}))
  const [message, setMessage] = React.useState<any>({})
  const [deleted, setDeleted] = React.useState<any>(false)
  const [history, setHistory] = React.useState<any>(false)
  const [permissions, setPermissions] = React.useState<Array<any>>()

  React.useEffect(() => {
    setLoading(true)
    findPermissions({setMessage}).then(permissions => {
      if (permissions) {
        setPermissions(permissions)
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  React.useEffect(() => {
    setLoading(true)
    findRoles({criteria: [], query: {sortBy: ['name']}, deleted, setMessage}).then(response => {
      const roles = response.records.filter((r: any) => !['admin', 'context'].includes(r.name));
      setRoles(roles)

      if (roles.length <= 0) {
        setMessage({info: deleted ? 'No deleted roles found' : 'No roles found'})
        setSelected(new Role({partyCode}))
      } else {
        setSelected(new Role(roles[0]))
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [deleted, partyCode])

  const updateActions = (): void => {
    if (deleted) {
      if (selected && selected.id) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setActions([returnAction(toggleDelete), deleteForeverAction(handleDeleteForever), restoreAction(handleRestore)])
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setActions([returnAction(toggleDelete)])
      }
    } else {
      const original = roles.find(role => role.id === selected.id)
      if (RoleComparator.isDirty(selected, original)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setActions([discardAction(handleDiscard), saveAction(handleSave)])
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setActions([createAction(handleNew), viewHistory(showHistory), deleteAction(handleDelete), viewDelete(toggleDelete)])
      }
    }
  }

  React.useEffect(updateActions, [selected])

  const handleSave = async (): Promise<void> => {
    setLoading(true)
    if (selected.id) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await handleUpdate()
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await handleCreate()
    }
    setLoading(false)
  }

  const handleUpdate = async (): Promise<void> => {
    try {
      let update = RoleRecordkeeper.update
      if (ALL_PROTECTED_ROLES.find(r => r === selected.name)) {
        update = RoleRecordkeeper.updateProtected
      }
      const updateResponse = await update({role: selected, identifier: {id: selected.id}})
      const index = roles.findIndex(role => role.id === selected.id)
      const list = roles.slice()
      list.splice(index, 1, updateResponse.role)
      setRoles(list)
      setSelected(new Role(updateResponse.role))

      setMessage({
        success: 'Saved successfully',
      })
    } catch (e) {
      setMessage({
        error: e.message || e,
      })
    }
  }

  const handleCreate = async (): Promise<void> => {
    try {
      const createResponse = await RoleRecordkeeper.create({role: selected})
      const list = roles.slice()
      list.push(createResponse.role)
      setRoles(list)
      setSelected(new Role(createResponse.role))
      setMessage({
        success: 'Saved successfully',
      })
    } catch (e) {
      setMessage({
        error: e.message || e,
      })
    }
  }

  const handleNew = (): void => {
    setSelected(new Role({partyCode}))
  }

  const handleDelete = async (): Promise<void> => {
    setLoading(true)
    try {
      await RoleRecordkeeper.delete({identifier: {id: selected.id}})
      const list = roles.slice()
      const index = roles.findIndex(role => role.id === selected.id)
      list.splice(index, 1)
      setRoles(list)
      if (list.length > 0) {
        const lastIdx = list.length - 1
        setSelected(new Role(list[lastIdx]))
      } else {
        setSelected(new Role({partyCode}))
      }

      setMessage({
        success: 'Role deleted successfully',
      })
    } catch (e) {
      setMessage({
        error: e.message || e,
      })
    }
    setLoading(false)
  }

  const handleDiscard = (): void => {
    if (!selected || !selected.id || selected.id === '') {
      if (RoleComparator.isDirty(selected, new Role({partyCode}))) {
        setMessage({
          warn: 'Discard changes?',
          confirm: () => {
            setSelected(new Role(roles[0]))
            setMessage({})
          },
        })
      } else {
        if (roles.length > 0) {
          setSelected(new Role(roles[0]))
        } else {
          setSelected(new Role({partyCode}))
        }

        setMessage({})
      }
    } else {
      const original = roles.find(role => role.id === selected.id)
      if (!original) {
        console.error('No original found!')
      }
      if (RoleComparator.isDirty(selected, original)) {
        setMessage({
          warn: 'Discard changes?',
          confirm: () => {
            setSelected(new Role(original))
            setMessage({})
          },
        })
      }
    }
  }

  const toggleDelete = (): void => {
    setDeleted(!deleted)
  }

  const showHistory = async (): Promise<void> => {
    if (history) {
      setHistory(null)
      return
    }

    setLoading(true)
    try {
      const retrieveResponse = await RoleRecordkeeper.retrieveHistory({identifier: {id: selected.id}})
      setHistory(retrieveResponse.history.reverse())
    } catch (e) {
      setMessage({error: e.message || e})
    }
    setLoading(false)
  }

  const handleDeleteForever = async (): Promise<void> => {
    setLoading(true)
    try {
      await RoleRecordkeeper.deleteForever({identifier: {id: selected.id}})
      const list = roles.slice()
      const index = roles.findIndex(role => role.id === selected.id)
      list.splice(index, 1)
      setRoles(list)

      if (list.length > 0) {
        const lastIdx = list.length - 1
        setSelected(new Role(list[lastIdx]))
      } else {
        setSelected(new Role({partyCode}))
      }

      setMessage({
        success: 'Role deleteForeverd successfully',
      })
    } catch (e) {
      setMessage({
        error: e.message || e,
      })
    }
    setLoading(false)
  }

  const handleRestore = async (): Promise<void> => {
    setLoading(true)
    try {
      await RoleRecordkeeper.restore({identifier: {id: selected.id}})
      const list = roles.slice()
      const index = roles.findIndex(role => role.id === selected.id)
      list.splice(index, 1)
      setRoles(list)

      if (list.length > 0) {
        const lastIdx = list.length - 1
        setSelected(new Role(list[lastIdx]))
      } else {
        setSelected(new Role({partyCode}))
      }

      setMessage({
        success: 'Role restored successfully',
      })
    } catch (e) {
      setMessage({
        error: e.message || e,
      })
    }
    setLoading(false)
  }

  const closeAlert = (): void => {
    setMessage({})
  }

  const handleSelect = (role: Role): void => {
    setSelected(new Role(role))
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          action={actions}
          title="Roles"
          titleTypographyProps={{className: classes.title, variant: 'body1'}}
        />
        {isLoading &&
        <Dialog
          BackdropProps={{classes: {root: classes.progressSpinnerDialogBackdrop}}}
          PaperProps={{classes: {root: classes.progressSpinnerDialog}}}
          open={isLoading}
        >
          <CircularProgress
            className={classes.progress}/>
        </Dialog>
        }
        {!isLoading &&
        <CardContent className={classes.cardContent}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <RoleList
                handleSelect={handleSelect}
                roles={roles}
                selected={selected}/>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <RoleDetails
                disabled={deleted}
                onChange={handleSelect}
                permissions={permissions}
                role={selected}
              />
            </Grid>
          </Grid>
        </CardContent>
        }
      </Card>
      {history &&
      <HistoryLayout
        entity={selected}
        entityFields={RoleFields}
        entityHistory={history || []}
        entityName="Role"
        loading={isLoading}
        onHide={showHistory}
        open={Boolean(history)}
      />
      }
      <NotificationSweetAlert
        errorMessage={message.error}
        infoMessage={message.info}
        onClose={closeAlert}
        onConfirm={message.confirm}
        successMessage={message.success}
        warningMessage={message.warn}
      />
    </div>
  )
}


export default Roles
