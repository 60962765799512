import {HexToRGBA} from '../../utils/Utils'

export const MuiIconButton = theme => ({
  root: {
    padding: '9px',
    color: HexToRGBA(theme.palette.text.primary, 0.5),
    '&$disabled': {
      color: theme.palette.primary.light
    },
    '&:hover': {
      color: theme.palette.text.primary
    },
  },
})
