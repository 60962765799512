import React from 'react'
import {connect} from 'react-redux'
import {
  PARTY_TYPE_BROKER,
  PARTY_TYPE_COMPANY,
  PARTY_TYPE_PROCESSING_BANK,
  PARTY_TYPE_PROCESSING_ORG,
  PARTY_TYPE_SYSTEM,
} from 'constants/partyTypes'
import {bindActionCreators} from 'redux'
import SystemHomeContainer from 'views/Home/System/SystemHomeContainer'
import ProcessingOrgHomeContainer from './ProcessingOrg/ProcessingOrgHomeContainer'
import BrokerHome from './Broker/BrokerHome'
import ProcessingBankHome from './ProcessingBank/ProcessingBankHome'
import CompanyHomeDashboard from 'views/Home/Company/Dashboard/Dashboard'

const mapStateToProps = state => {
  return {
    securityClaimsContext: state.security.claims.context,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}


class HomeContainer extends React.Component {
  render() {
    const {securityClaimsContext} = this.props
    switch (securityClaimsContext.partyType) {
      case PARTY_TYPE_SYSTEM:
        return <SystemHomeContainer/>
      case PARTY_TYPE_PROCESSING_ORG:
        return <ProcessingOrgHomeContainer/>
      case PARTY_TYPE_COMPANY:
        return <CompanyHomeDashboard/>
      case PARTY_TYPE_BROKER:
        return <BrokerHome/>
      case PARTY_TYPE_PROCESSING_BANK:
        return <ProcessingBankHome/>
      default:
        return <div>No home screen for party type {securityClaimsContext.partyType}</div>
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
