import { Identifier, marshalIdentifier } from 'popcorn-js/search/identifier/indexT';
import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { DateSearch } from 'popcorn-js/search';
import { TradingDayException } from 'popcorn-js/financial/tradingDayException';

export type GetSpotDateForRequest = {
    currencyPair?: Identifier | string;
    date: number;
};

export type GetSpotDateForResponse = {
    spotDate: number;
};

export type FindBankingHolidaysRequest = {
    currencyPair?: Identifier | string;
    startDate: DateSearch;
    endDate: DateSearch;
};

export type FindBankingHolidaysResponse = {
    tradingDayExceptions: TradingDayException[];
    weekendExceptions: TradingDayException[];
};

export const DateKeeper = {
  ServiceProviderName: 'FxCalendar-DateKeeper',
  GetSpotDateFor(request: GetSpotDateForRequest): Promise<GetSpotDateForResponse> {
    const serializedIdentifier = request.currencyPair
      ? marshalIdentifier(request.currencyPair as Identifier)
      : undefined;
    return jsonRPC<GetSpotDateForRequest, GetSpotDateForResponse>({
      url: config.get('golangUrl'),
      method: `${DateKeeper.ServiceProviderName}.GetSpotDateFor`,
      request: {
        ...request,
        currencyPair: serializedIdentifier,
      },
    });
  },
  FindBankingHolidays(request: FindBankingHolidaysRequest): Promise<FindBankingHolidaysResponse> {
    const serializedIdentifier = request.currencyPair
      ? marshalIdentifier(request.currencyPair as Identifier)
      : undefined;
    return jsonRPC<FindBankingHolidaysRequest, FindBankingHolidaysResponse>({
      url: config.get('golangUrl'),
      method: `${DateKeeper.ServiceProviderName}.FindBankingHolidays`,
      request: {
        ...request,
        currencyPair: serializedIdentifier,
      },
    });
  },
};
