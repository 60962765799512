import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {ID_IDENTIFIER, PARTY_CODE_IDENTIFIER} from 'popcorn-js/search/identifier/types'
import {Marshal} from 'popcorn-js/search/identifier/Marshaller'

/**
 * ProcessingBankRecordkeeper is a convenience client for invoking the backend services
 */
export default class Recordkeeper {
  /**
   * @param {ProcessingBank} processingBank
   */
  static create(processingBank) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ProcessingBank-Recordkeeper.Create',
      request: {processingBank: processingBank},
    })
  }

  /**
   * @param criteria: Filter criteria
   * @param query: Query options
   * @param {boolean} deleted
   */
  static find(criteria, query, deleted) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ProcessingBank-Recordkeeper.Find',
      request: {
        criteria,
        query,
        deleted,
      },
    })
  }

  /**
   * @param {Identifier} id
   */
  static delete(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ProcessingBank-Recordkeeper.Delete',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      }
    })
  }

  /**
   *
   * @param {ProcessingBank} processingBank
   * @param {Identifier} id
   */
  static update(processingBank, id) {

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ProcessingBank-Recordkeeper.Update',
      request: {
        processingBank,
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      },
    })
  }

  /**
   * @param {Identifier} id
   */
  static retrieveHistory(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ProcessingBank-Recordkeeper.RetrieveHistory',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      }
    })
  }

  /**
   * @param {string} partyCode
   */
  static retrieve(partyCode) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ProcessingBank-Recordkeeper.Retrieve',
      request: {
        identifier: Marshal({type: PARTY_CODE_IDENTIFIER, value: {partyCode}}),
      }
    })
  }

  /**
   * @param {Identifier} id
   */
  static restore(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ProcessingBank-Recordkeeper.Restore',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      }
    })
  }

  /**
   * @param {Identifier} id
   */
  static deleteForever(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'ProcessingBank-Recordkeeper.DeleteForever',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}}),
      }
    })
  }
}
