import React, {ChangeEvent, useCallback, useEffect, useState} from 'react'
import {
  AppBar,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  useTheme,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {TradeDirection, TradeParent, TradeType, Trade} from 'popcorn-js/trade/tradeTypes';
import {Currency} from 'popcorn-js/financial/currency/currencyType'
import {CurrencyPair} from 'popcorn-js/financial/currencyPair/currencyPairTypes'
import {BuySell} from './BuySell'
import {RateAndBankInfo} from './RateAndBankInfo'
import {CaptureInformation} from './CaptureInformation';
import {ActionButton, LightTextField} from './styledComponents'
import Big from 'big.js'
import {DateKeeper} from 'popcorn-js/financial/fxCalendar/dateKeeper'
import {useService} from 'hooks/useService'
import getUnixTime from 'date-fns/getUnixTime'
import fromUnixTime from 'date-fns/fromUnixTime'
import {parseISO, isWeekend} from 'date-fns'
import {ProcessingBank, Company} from 'popcorn-js/legalEntity/party'
import {UserManager} from 'popcorn-js/legalEntities/user'
import {ConfirmTradeDetails} from './ConfirmTradeDetails'
import {ParentAllocations} from './ParentAllocations'
import {getMidDay, getNextTradeDate} from './util';
import RatesHandler from 'popcorn-js/rick/handler';
import { Recordkeeper as TDERecordkeeper } from 'popcorn-js/financial/tradingDayException/recordkeeperT';
import { CriteriaType, CriterionFilterType } from 'popcorn-js/search';
import { TradingDayException } from 'popcorn-js/financial/tradingDayException';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.dark,
    },
    appBar: {
      position: 'relative',
      backgroundColor: '#0A7280',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

interface CancellationTicketProps {
  closeTicket: () => void;
  currencyPairToTrade: CurrencyPair;
  direction: TradeDirection;
  show: boolean;
  buyCurrency: Currency;
  sellCurrency: Currency;
  // todo make proper currency type
  currencies: Currency[];
  currencyPairs: CurrencyPair[];
  banks?: ProcessingBank[];
  parentTrades: Trade[];
  userId: string;
  party: Company;
}

/**
 * The main exported ticket component
 * @param props
 * @constructor
 */
const CancellationTicket: React.FC<CancellationTicketProps> = (props: CancellationTicketProps) => {
  const {show, closeTicket, currencies, currencyPairs, currencyPairToTrade, banks, userId, party, parentTrades} = props

  const classes = useStyles(useTheme())

  let bank: ProcessingBank | undefined
  switch (parentTrades[0].tradeType) {
    case TradeType.FORWARD:
    case TradeType.SPOT: {
      const relevantLeg = parentTrades && parentTrades[0] && parentTrades[0].legs ? parentTrades[0].legs[0] : undefined
      if (banks && relevantLeg) {
        bank = banks.find((b: ProcessingBank) => b.partyCode === relevantLeg.bank)
      }
      break
    }
    case TradeType.EXTENSION: {
      const relevantLeg = parentTrades && parentTrades[0] && parentTrades[0].legs ? parentTrades[0].legs[1] : undefined
      if (banks && relevantLeg) {
        bank = banks.find((b: ProcessingBank) => b.partyCode === relevantLeg.bank)
      }
      break
    }
    default:
      throw new Error('trade type must be FORWARD or EXTENSION')
  }

  const today = getMidDay(new Date());
  const initialDate = isWeekend(today) ? getNextTradeDate(today) : today;

  const [tradeType] = useState<TradeType>(TradeType.CANCELLATION)
  const [externalReference, setExternalReference] = useState<string | undefined>()
  const [open, setOpen] = useState<boolean>(show)
  const [currencyPair, setCurrencyPair] = useState<CurrencyPair | undefined>(currencyPairToTrade)
  const [tradeDate, setTradeDate] = useState<string | null | undefined>(initialDate.toISOString())
  const [spotDate, setSpotDate] = useState<string | null | undefined>(initialDate.toISOString())
  const [maturityDate, setMaturityDate] = useState<string | null | undefined>(initialDate.toISOString())
  const [acmChildTrade, setACMTrade] = useState<boolean>(false);
  const [tradeParents, setTradeParents] = useState<TradeParent[]>([]);
  const [tradeParentsTotalAllocatedAmount, setTradeParentsTotalAllocatedAmount] = useState<Big | undefined>()
  const [spotRate, setSpotRate] = useState<Big | undefined>()
  const [capturedBidSpotRate, setCapturedBidSpotRate] = useState<Big | undefined>()
  const [capturedAskSpotRate, setCapturedAskSpotRate] = useState<Big | undefined>()
  const [ratesLoading, setRatesLoading] = useState<boolean>(true)
  const [allInRate, setAllInRate] = useState<Big | undefined>()
  const [forwardPoints, setForwardPoints] = useState<Big | undefined>()
  const [interbankRate, setInterbankRate] = useState<Big | undefined>()
  const [bankRate, setBankRate] = useState<Big | undefined>()
  const [traderOrganisation, setTraderOrganisation] = useState<string | undefined>(party.parentPartyCode)
  const [trader, setTrader] = useState<string | undefined>()
  const [capturedBy, setCapturedBy] = useState<string | undefined>()
  const [notes, setNotes] = useState<string | undefined>()
  const [capturedDate, setCapturedDate] = useState<string | null | undefined>(new Date().toISOString())
  const [direction, setDirection] = useState<TradeDirection | undefined>(props.direction)
  const [buyAmount, setBuyAmount] = useState<Big | undefined>()
  const [sellAmount, setSellAmount] = useState<Big | undefined>()
  const [confirmTradeDetailsOpen, setConfirmTradeDetailsOpen] = useState<boolean>(false)
  const [canRecord, setCanRecord] = useState<boolean>(false)
  const [nonTradingDays, setNonTradingDays] = useState<Date[]>([]);

  const currencyIDs: Set<string> = new Set();
  currencyPairs.forEach((currencyPair: CurrencyPair) => {
    currencyIDs.add(currencyPair.quoteCurrencyId)
    currencyIDs.add(currencyPair.baseCurrencyId)
  })
  const availableCurrencies = currencies.filter((currency: Currency) => currencyIDs.has(currency.id))

  const getFindTDEsRequest = useCallback((): any => ({
    criteria: [
      { type: CriteriaType.ExactCriterion, field: 'currencyId', text: currencyPair?.baseCurrencyId },
      { type: CriteriaType.ExactCriterion, field: 'currencyId', text: currencyPair?.quoteCurrencyId },
    ],
    filterType: CriterionFilterType.Or,
  }), [currencyPair]);

  const [{ response: findTDEsResponse, loading: findTDEsLoading }, setFindTDEsRequest] = useService(
    getFindTDEsRequest(),
    TDERecordkeeper.find,
  );

  useEffect(() => {
    if (findTDEsResponse && findTDEsResponse.records) {
      const _nonTradingDays = findTDEsResponse.records.map((tde: TradingDayException) =>
        getMidDay(parseISO(tde.date || '')),
      );
      setNonTradingDays(_nonTradingDays);
    }
  }, [findTDEsResponse]);
  // effect to get new TDEs for currencies
  useEffect(() => setFindTDEsRequest(getFindTDEsRequest()), [currencyPair, getFindTDEsRequest, setFindTDEsRequest]);

  const [
    {
      response: getRateResponse,
      loading: getRateLoading,
    },
    setGetRateRequest
  ] = useService(undefined, RatesHandler.RetrieveRate)

  useEffect(() => {
    setGetRateRequest({
      rateSubscription: {
        currencyPairName: currencyPair?.name,
        date: 0,
      }
    })
  }, [currencyPair, setGetRateRequest])

  useEffect(() => {
    if (getRateResponse && !getRateLoading) {
      setCapturedBidSpotRate(Big(getRateResponse.priceSubscriptionSucceeded.bidSpotPrice))
      setCapturedAskSpotRate(Big(getRateResponse.priceSubscriptionSucceeded.askSpotPrice))
      setRatesLoading(false)
    }
  }, [getRateResponse, getRateLoading])

  const [{
    response: getUserProfileByIdResponse,
    loading: getUserProfileByIdLoading,
  }] = useService({userId}, UserManager.getUserProfileById)

  useEffect(() => {
    if (getUserProfileByIdResponse && getUserProfileByIdResponse.displayName) {
      setTrader(getUserProfileByIdResponse.displayName)
      setCapturedBy(getUserProfileByIdResponse.displayName)
    }
  }, [getUserProfileByIdResponse])

  const [
    {
      response: getSpotDateForResponse,
      loading: getSpotDateForResponseLoading,
    },
    setGetSpotDateForRequest,
  ] = useService(undefined, DateKeeper.GetSpotDateFor)

  useEffect(() => {
    if (getSpotDateForResponse && getSpotDateForResponse.spotDate) {
      const newSpotDate = fromUnixTime(getSpotDateForResponse.spotDate).toISOString()
      setSpotDate(newSpotDate)
      if (tradeType === TradeType.SPOT) {
        setMaturityDate(newSpotDate)
      }
    }
  }, [getSpotDateForResponse, tradeType, currencyPair])

  useEffect(() => {
    if (parentTrades) {
      if (parentTrades[0].tradeType === TradeType.FORWARD || parentTrades[0].tradeType === TradeType.SPOT) {
        setMaturityDate(parentTrades && parentTrades[0].legs ?
          fromUnixTime(parentTrades[0].legs[0].maturityDate).toISOString() : '')
      } else if (parentTrades[0].tradeType === TradeType.EXTENSION) {
        setMaturityDate(parentTrades && parentTrades[0].legs ?
          fromUnixTime(parentTrades[0].legs[1].maturityDate).toISOString() : '')
      }
    }
  }, [parentTrades])

  useEffect(() => {
    if (tradeDate) {
      const unixTime = getUnixTime(parseISO(tradeDate))
      setGetSpotDateForRequest({currencyPair: {id: currencyPair?.id}, date: unixTime})
    }
  }, [currencyPair, tradeDate, setGetSpotDateForRequest])


  const determineBuyCurrency = useCallback((): Currency | undefined => {
    if (direction === TradeDirection.BUY) {
      return currencies.find((currency: Currency) => currencyPair?.baseCurrencyId === currency.id)
    }
    return currencies.find((currency: Currency) => currencyPair?.quoteCurrencyId === currency.id)
  }, [direction, currencies, currencyPair])

  const determineSellCurrency = useCallback((): Currency | undefined => {
    if (direction === TradeDirection.BUY) {
      return currencies.find((currency: Currency) => currencyPair?.quoteCurrencyId === currency.id)
    }
    return currencies.find((currency: Currency) => currencyPair?.baseCurrencyId === currency.id)
  }, [direction, currencies, currencyPair])

  const handleBuyCurrencyChange = useCallback((buyCurrency: Currency | undefined) => {
    if (direction === TradeDirection.BUY) {
      const sellCurrencyID = currencyPair?.quoteCurrencyId
      const newCurrencyPair = currencyPairs.find((currencyPair: CurrencyPair) =>
        currencyPair.baseCurrencyId === buyCurrency?.id && currencyPair.quoteCurrencyId === sellCurrencyID)
      setCurrencyPair(newCurrencyPair)
    } else {
      const sellCurrencyID = currencyPair?.quoteCurrencyId
      const newCurrencyPair = currencyPairs.find((currencyPair: CurrencyPair) =>
        currencyPair.quoteCurrencyId === buyCurrency?.id && currencyPair.baseCurrencyId === sellCurrencyID)
      setCurrencyPair(newCurrencyPair)
    }
  }, [direction, currencyPairs, currencyPair])

  const handleSellCurrencyChange = useCallback((sellCurrency: Currency | undefined) => {
    if (direction === TradeDirection.BUY) {
      const buyCurrencyID = currencyPair?.baseCurrencyId
      const newCurrencyPair = currencyPairs.find((currencyPair: CurrencyPair) =>
        currencyPair.quoteCurrencyId === sellCurrency?.id && currencyPair.baseCurrencyId === buyCurrencyID)
      setCurrencyPair(newCurrencyPair)
    } else {
      const buyCurrencyID = currencyPair?.quoteCurrencyId
      const newCurrencyPair = currencyPairs.find((currencyPair: CurrencyPair) =>
        currencyPair.baseCurrencyId === sellCurrency?.id && currencyPair.quoteCurrencyId === buyCurrencyID)
      setCurrencyPair(newCurrencyPair)
    }
  }, [direction, currencyPairs, currencyPair])

  useEffect(() => {
    setCanRecord(
      !!externalReference &&
      !!buyAmount &&
      !!sellAmount &&
      !!tradeDate &&
      !!maturityDate &&
      !!allInRate &&
      !!trader &&
      !!bank
    )
    // todo add field validation
  }, [
    externalReference,
    buyAmount,
    sellAmount,
    tradeDate,
    maturityDate,
    allInRate,
    trader,
    bank,
  ])

  return (
    <Dialog
      className={classes.root}
      fullScreen
      onClose={() => closeTicket()}
      open={open}
    >
      <AppBar
        className={classes.appBar}
        color={'secondary'}
      >
        <Toolbar>
          <Typography
            className={classes.title}
            variant="h5">
            Capture Cancellation Trade
          </Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            edge="end"
            onClick={() => {
              setOpen(false)
              closeTicket()
            }}>
            <CloseIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>

      <div
        style={{height: '100%', width: '100%'}}
      >
        <div
          style={{float: 'left', width: '75%', height: '100%', padding: '32px'}}
        >
          <div style={{width: '100%'}}>
            <ParentAllocations
              parentTrades={parentTrades}
              totalChange={setTradeParentsTotalAllocatedAmount}
              tradeParentsChange={setTradeParents}
            />
          </div>
          <div style={{width: '100%'}}>
            <div
              style={{
                margin: '20px',
                height: '50px',
                width: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <LightTextField
                id="externalReference"
                label="External Reference"
                onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setExternalReference(event.target.value)}
                style={{marginRight: '5px'}}
                value={externalReference}
              />
            </div>
          </div>
          <div style={{width: '100%'}}>
            <div
              style={{float: 'left'}}
            >
              <BuySell
                acmTrade={acmChildTrade}
                acmTradeChange={(checked: boolean) => setACMTrade(checked)}
                allInRate={allInRate}
                buyAmount={buyAmount}
                buyAmountChange={setBuyAmount}
                buyCurrency={determineBuyCurrency()}
                buyCurrencyChange={handleBuyCurrencyChange}
                currencies={availableCurrencies}
                currencyPairs={currencyPairs}
                direction={direction}
                directionChange={setDirection}
                maturityDate={maturityDate}
                maturityDateChange={(date: string | null | undefined) => setMaturityDate(date)}
                nonTradingDays={nonTradingDays}
                sellAmount={sellAmount}
                sellAmountChange={setSellAmount}
                sellCurrency={determineSellCurrency()}
                sellCurrencyChange={handleSellCurrencyChange}
                spotDate={spotDate}
                tradeDate={tradeDate}
                tradeDateChange={(date: string | null | undefined) => setTradeDate(date)}
                tradeParentsTotalAllocatedAmount={tradeParentsTotalAllocatedAmount}
                tradeType={tradeType}
              />
            </div>
            <div
              style={{float: 'left'}}
            >
              <RateAndBankInfo
                allInRateChange={setAllInRate}
                bank={bank}
                bankRateChange={setBankRate}
                banks={banks}
                forwardPointsChange={setForwardPoints}
                interbankRateChange={setInterbankRate}
                spotRateChange={setSpotRate}
                tradeType={tradeType}
              />
            </div>
          </div>
          <div
            style={{width: '100%'}}
          >
            <div
              style={{margin: '20px', width: '400px'}}
            >
              <ActionButton
                disabled={!canRecord}
                onClick={() => setConfirmTradeDetailsOpen(true)}
              >
                Record trade information
              </ActionButton>
            </div>
          </div>
        </div>
        <div
          style={{float: 'right', width: '25%', height: '100%'}}
        >
          {/* todo use values from context */}
          <CaptureInformation
            capturedBy={capturedBy}
            capturedDate={capturedDate}
            capturedDateChanged={setCapturedDate}
            capturedSpotRate={direction === TradeDirection.BUY ? capturedAskSpotRate : capturedBidSpotRate}
            notesChanged={setNotes}
            party={party}
            traderChanged={setTrader}
            traderOrganisation={traderOrganisation}
            traderOrganisationChanged={setTraderOrganisation}
          />
        </div>
      </div>
      <ConfirmTradeDetails
        acmChildTrade={acmChildTrade}
        acmParents={[]}
        allInRate={allInRate}
        bank={bank}
        bankRate={bankRate}
        buyAmount={buyAmount}
        buyCurrency={determineBuyCurrency()}
        capturedSpotRate={direction === TradeDirection.BUY ? capturedAskSpotRate : capturedBidSpotRate}
        currencyPair={currencyPair}
        direction={direction}
        externalReference={externalReference}
        forwardPoints={forwardPoints}
        interbankRate={interbankRate}
        maturityDate={maturityDate}
        notes={notes}
        onClose={() => {
          setConfirmTradeDetailsOpen(false)
        }}
        onFinished={() => {
          setConfirmTradeDetailsOpen(false)
          closeTicket()
        }}
        open={confirmTradeDetailsOpen}
        parentPartyCode={party.parentPartyCode}
        parentTradeType={parentTrades[0].tradeType}
        party={party}
        sellAmount={sellAmount}
        sellCurrency={determineSellCurrency()}
        spotRate={spotRate}
        title={'Confirm Cancellation Details'}
        tradeDate={tradeDate}
        tradeParents={tradeParents}
        tradeType={tradeType}
        trader={trader}
        traderOrganisation={traderOrganisation}
      />
      <Backdrop
        open={ratesLoading || getUserProfileByIdLoading || getSpotDateForResponseLoading || findTDEsLoading}
        style={{zIndex: 10}}>
        <CircularProgress color="inherit"/>
      </Backdrop>
    </Dialog>
  )
}

export default CancellationTicket
