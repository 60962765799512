import {IconButton, Tooltip} from '@material-ui/core';
import React from 'react';


import {Delete,} from '@material-ui/icons'


export const viewDelete = onClick => (
  <Tooltip
    key={'viewDelete'}
    title={'View Deletes'}>
    <IconButton onClick={onClick}>
      <Delete/>
    </IconButton>
  </Tooltip>)
