import React from 'react';
import {
  MdAddCircle,
  MdDelete,
  MdFolder,
  MdKeyboardArrowLeft as BackIcon,
  MdRemoveRedEye,
  MdRestore,
  MdUnarchive,
} from 'react-icons/md';
import { withStyles } from '@material-ui/styles';
import {
  DATE_CRITERION,
  ExactCriterion,
  NUMBER_CRITERION,
  TEXT_CRITERION,
} from 'popcorn-js/search/criteria/types';
import { InvoiceRecordkeeper } from 'popcorn-js/financial/invoice';
import { financialYears } from 'popcorn-js/financial';
import InvoiceExporter from 'popcorn-js/financial/invoice/exporter';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert';
import InvoiceDetailDialog from 'components/Detail/invoice/InvoiceDetailDialog';
import InvoiceDetailHistoryContainer from 'views/History/InvoiceHistory/InvoiceDetailHistoryContainer';
import { ComponentLevelError } from 'components/Error/Error';
import {
  HexToRGBA,
  processUnixDateForViewing,
  processUnixDateTimeForViewing,
} from 'utils/Utils';
import InvoiceImporterContainer from 'components/ImportExport/ImportDialogs/invoice/InvoiceImporterContainer';
import PropTypes from 'prop-types';
import saveAs from 'file-saver';
import {
  ALL_INVOICE_STATUSES,
  INVOICE_DIRECTION_PAYABLE,
  INVOICE_DIRECTION_RECEIVABLE,
  ALL_INVOICE_TYPES,
} from '../../../constants/invoice';
import { FormatNumber } from 'utils/TradeUtilities';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import InvoiceFormNew from 'components/InvoiceForm/InvoiceFormNew';
import classNames from 'classnames';
import AndileTable from 'components/AndileMaterialUITable/AndileTable';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { InvoiceTypePopover } from '../InvoiceTypePopover';
import { InvoiceCategoryPopover } from '../InvoiceCategoryPopover';

const styles = (theme) => ({
  menuItem: {
    '&:hover': {
      background: `linear-gradient(90deg, ${theme.palette.highActions} 70%, ${theme.palette.secondary.main} 90%)`,
    },
    padding: '0px',
    paddingBottom: '0px',
    minHeight: 0,
  },
  headerMenuItem: {
    padding: 0,
    minWidth: '100px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.3)}`,
    pointerEvents: 'none',
  },
  menuItemDiv: {
    width: '100%',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    opacity: 0.5,
    borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.5)}`,
  },
  headerMenuItemDiv: {
    fontSize: 17,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  iconActive: {
    color: 'white',
  },
});

const activeStates = {
  viewing: 'ACTIVE_STATE_VIEWING',
  editing: 'ACTIVE_STATE_EDITING',
  creating: 'ACTIVE_STATE_CREATING',
  uploading: 'ACTIVE_STATE_UPLOADING',
  viewDelete: 'ACTIVE_STATE_VIEWING_DELETE',
};

const invoiceStatuses = ALL_INVOICE_STATUSES.map((value) => ({ value }));
const invoiceTypes = ALL_INVOICE_TYPES.map((value) => ({ value }));
const invoiceImportExport = ['IMPORT', 'EXPORT'].map((value) => ({ value }));
const invoiceDirections = [
  {
    value: INVOICE_DIRECTION_PAYABLE,
    label: INVOICE_DIRECTION_PAYABLE,
  },
  {
    value: INVOICE_DIRECTION_RECEIVABLE,
    label: INVOICE_DIRECTION_RECEIVABLE,
  },
];

const safeRender = (accessor, formatter = (value) => value) => (rowData) => {
  try {
    return formatter(rowData[accessor]);
  } catch (e) {
    return '-';
  }
};

const initialPageSize = 17;
const rowsPerPageOptions = [5, 10, 17, 20, 25, 30];
const initialCriteria = {
  financialYear: {
    type: TEXT_CRITERION,
    value: {
      field: 'financialYear',
      text: 'CURRENT',
    },
  },
};

class InvoiceStation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      activeState: activeStates.viewing,
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      showDetail: false,
      showInvoiceHistory: false,
      confirmationMethod: undefined,
      showImportDialog: false,
      invoices: [],
      totalInvoices: 0,
      popoverAnchorEl: undefined,
      invoiceCategoryPopoverOpen: false,
      invoiceCategory: '',
      invoiceCategoryPopoverNextState: '',
      invoiceTypePopoverOpen: false,
      invoiceType: '',
      invoiceTypePopoverNextState: '',
      invoiceTypePopoverTitle: 'Create New',
      invoiceTypePopoverPlural: false,
      query: {
        sortBy: [],
        order: [],
        limit: initialPageSize,
        offset: 0,
      },
      counterparties: [],
      criteria: Object.keys(initialCriteria).map((field) => {
        return initialCriteria[field];
      }),
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleChangeSorting = this.handleChangeSorting.bind(this);
  }

  componentDidMount() {
    this.fetch().finally();
  }

  async fetch() {
    await new Promise((resolve) => setTimeout(resolve, 400));
    await this.findInvoices();
    this.findInvoiceCounterparties();
  }

  async findInvoices() {
    const { query, criteria, activeState } = this.state;
    this.setState({ loading: true });

    const result = await InvoiceRecordkeeper.find({
      criteria: criteria,
      Query: query,
      deleted: activeState === activeStates.viewDelete,
    });
    this.setState({
      invoices: result.records,
      totalInvoices: result.total,
      loading: false,
    });
  }

  async findInvoiceCounterparties() {
    const { invoices } = this.state;
    this.setState({ loading: true });

    const counterpartyFindCrit = invoices
      .filter((b) => b.counterpartyId !== '')
      .map((b) => new ExactCriterion({ field: 'id', text: b.counterpartyId }));

    const counterpartyFindResponse =
      counterpartyFindCrit.length > 0
        ? await CounterpartyRecordkeeper.find({
          criteria: counterpartyFindCrit,
          query: undefined,
          Deleted: false,
        })
        : { records: [] };

    this.setState({
      counterparties: counterpartyFindResponse.records,
      loading: false,
    });
  }

  exportInvoices = async (criteria) => {
    this.setState({ loading: true });
    try {
      const invoiceExportResult = await InvoiceExporter.exportInvoices({
        criteria: criteria,
      });

      // convert base64 to byte array
      let binData = atob(invoiceExportResult.data);
      let bytes = new Array(binData.length);
      for (let i = 0; i < binData.length; i++) {
        bytes[i] = binData.charCodeAt(i);
      }

      let blob = new Blob([new Uint8Array(bytes)], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
      });
      saveAs(blob, 'invoice_export.xlsx');
    } catch (error) {
      this.setState({ errorMessage: error.message || error });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  showDeleteForeverConfirmation = (rowInfo) => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: `You are about to delete invoice '${rowInfo.number}' forever. Do you want to continue?`,
      confirmationMethod: () => {
        this.setState({ loading: true });
        try {
          InvoiceRecordkeeper.deleteForever({
            identifier: { id: rowInfo.id },
          })
            .then(() =>
              this.setState(
                {
                  successMessage: 'Invoice Deleted Forever',
                  warningMessage: undefined,
                  confirmationMethod: undefined,
                  errorMessage: undefined,
                },
                () => this.fetch()
              )
            )
            .catch((e) =>
              this.setState({
                successMessage: undefined,
                warningMessage: undefined,
                confirmationMethod: undefined,
                errorMessage: e.message || e,
              })
            )
            .finally(() => this.setState({ loading: false }));
        } catch (e) {
          this.setState({
            successMessage: undefined,
            warningMessage: undefined,
            confirmationMethod: undefined,
            errorMessage: e.message || e,
            loading: false,
          });
        }
      },
    });
  };
  showDeleteConfirmation = (rowInfo) => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: `You are about to delete invoice '${rowInfo.number}'. Do you want to continue?`,
      confirmationMethod: () => {
        this.setState({ loading: true });
        try {
          InvoiceRecordkeeper.delete({
            identifier: { id: rowInfo.id },
          })
            .then(() =>
              this.setState(
                {
                  successMessage: 'Invoice Deleted',
                  warningMessage: undefined,
                  confirmationMethod: undefined,
                  errorMessage: undefined,
                },
                () => this.fetch()
              )
            )
            .catch((e) =>
              this.setState({
                successMessage: undefined,
                warningMessage: undefined,
                confirmationMethod: undefined,
                errorMessage: e.message || e,
              })
            )
            .finally(() => this.setState({ loading: false }));
        } catch (e) {
          this.setState({
            successMessage: undefined,
            warningMessage: undefined,
            confirmationMethod: undefined,
            errorMessage: e.message || e,
            loading: false,
          });
        }
      },
    });
  };

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    });
  };

  async generateCounterpartyOptions(criterion) {
    const criteria = [criterion];
    try {
      return await CounterpartyRecordkeeper.find({
        criteria: criteria,
        query: { sortBy: ['name'], order: [], limit: 20, offset: 0 },
        Deleted: false,
      });
    } catch (e) {
      throw e.message || e;
    }
  }

  renderDialogs = () => {
    const {
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      showDetail,
      showInvoiceHistory,
      activeState,
      counterparties,
      popoverAnchorEl,
      invoiceTypePopoverOpen,
      invoiceType,
      invoiceTypePopoverNextState,
      invoiceTypePopoverTitle,
      invoiceTypePopoverPlural,
      invoiceCategoryPopoverOpen,
      invoiceCategory,
      invoiceCategoryPopoverNextState,
    } = this.state;

    const { currencies, partyCode, party } = this.props;

    return (
      <span>
        <InvoiceTypePopover
          nextState={invoiceTypePopoverNextState}
          onClose={() =>
            this.setState({
              invoiceTypePopoverOpen: false,
              popoverAnchorEl: undefined,
            })
          }
          onSelectType={(type, nextState) =>
            this.setState({
              invoiceTypePopoverOpen: false,
              popoverAnchorEl: undefined,
              activeState: nextState,
              invoiceType: type,
            })
          }
          open={invoiceTypePopoverOpen}
          plural={invoiceTypePopoverPlural}
          popoverAnchorEl={popoverAnchorEl}
          title={invoiceTypePopoverTitle}
        />
        <InvoiceCategoryPopover
          nextState={invoiceCategoryPopoverNextState}
          onClose={() =>
            this.setState({
              invoiceCategoryPopoverOpen: false,
              popoverAnchorEl: undefined,
            })
          }
          onSelectCatergory={(category, nextState) =>
            this.setState({
              invoiceCategoryPopoverOpen: false,
              popoverAnchorEl: undefined,
              activeState: nextState,
              invoiceCategory: category,
            })
          }
          open={invoiceCategoryPopoverOpen}
          popoverAnchorEl={popoverAnchorEl}
        />
        <InvoiceDetailDialog
          counterparties={counterparties}
          invoice={showDetail || {}}
          onClose={() => this.setState({ showDetail: false })}
          show={!!showDetail}
          updateInvoiceSuccess={() => this.fetch()}
        />
        {activeState === activeStates.creating && (
          <InvoiceFormNew
            createCounterpartyError={(e) =>
              this.setState({
                errorMessage: `Failed to create counterparty ${e}`,
              })
            }
            createCounterpartySuccess={() =>
              this.setState({ successMessage: 'Counterparty Created' })
            }
            createInvoiceError={(e) => {
              this.setState({ errorMessage: `Failed to create invoice ${e}` });
            }}
            createInvoiceSuccess={() => {
              this.setState(
                {
                  successMessage: 'Invoice Created',
                },
                () => this.fetch()
              );
            }}
            currencies={currencies}
            hideInvoiceForm={() => {
              this.setState({ activeState: activeStates.viewing });
            }}
            party={party}
            partyCode={partyCode}
            showForm={activeState === activeStates.creating}
            type={invoiceType}
            validateInvoiceError={(e) =>
              this.setState({ errorMessage: `Failed to validate invoice ${e}` })
            }
          />
        )}
        {activeState === activeStates.uploading && (
          <InvoiceImporterContainer
            category={invoiceCategory}
            enableDownloadTemplate
            onAwayClick={() => {
              this.setState({
                showImportDialog: false,
                activeState: activeStates.viewing,
              });
            }}
            show
            showImportDialog={() =>
              this.setState({
                showImportDialog: true,
                activeState: activeStates.uploading,
              })
            }
            uploadSuccess={() => this.fetch()}
          />
        )}
        {!!showInvoiceHistory && (
          <InvoiceDetailHistoryContainer
            invoice={showInvoiceHistory || {}}
            onHide={() => this.setState({ showInvoiceHistory: undefined })}
            open={!!showInvoiceHistory}
          />
        )}
        <NotificationSweetAlert
          customClass={'configAlert'}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}
          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </span>
    );
  };

  render() {
    const { errorMessage } = this.state;

    if (errorMessage) {
      this.renderError(errorMessage);
    }

    return this.renderMainView();
  }

  renderError = (errorMessage) => {
    return <ComponentLevelError errorMessage={errorMessage} />;
  };

  generateRowActions = (rowInfo) => {
    if (rowInfo.auditEntry.action === 'DELETED') {
      return [
        {
          icon: <MdDelete />,
          tooltip: 'Delete Forever',
          onClick: () => {
            this.showDeleteForeverConfirmation(rowInfo);
          },
        },
        {
          icon: <MdUnarchive />,
          tooltip: 'Restore',
          onClick: () => {
            this.setState({ loading: true });
            try {
              InvoiceRecordkeeper.restore({ identifier: { id: rowInfo.id } })
                .then(() => {
                  this.setState({
                    successMessage: 'Invoice Restored',
                  });
                  this.fetch();
                })
                .catch((e) =>
                  this.setState({
                    errorMessage: e.message || e,
                  })
                )
                .finally(() => this.setState({ loading: false }));
            } catch (e) {
              this.setState({
                errorMessage: e.message || e,
                loading: false,
              });
            }
          },
        },
      ];
    } else {
      return [
        {
          icon: <MdRestore />,
          tooltip: 'History',
          onClick: () => {
            this.setState({
              showInvoiceHistory: rowInfo,
            });
          },
        },
        {
          icon: <MdDelete />,
          tooltip: 'Delete',
          onClick: () => {
            this.showDeleteConfirmation(rowInfo);
          },
        },
        {
          icon: <MdRemoveRedEye />,
          tooltip: 'Details',
          onClick: () => {
            this.setState({
              showDetail: rowInfo,
            });
          },
        },
      ];
    }
  };

  generateMenu() {
    const { classes } = this.props;
    const { criteria } = this.state;

    let actions = [];
    actions.push(
      {
        title: 'Download Template',
        onClick: () => {
          InvoiceImporterContainer.downloadInvoiceTemplate();
        },
      },
      {
        title: 'Upload Invoices',
        onClick: (event) => {
          this.setState({
            popoverAnchorEl: event.currentTarget,
            invoiceCategoryPopoverOpen: true,
            invoiceCategoryPopoverNextState: activeStates.uploading,
          });
        },
      },
      {
        title: 'Download Invoices',
        onClick: () => {
          this.exportInvoices(criteria).finally();
        },
      }
    );
    return (
      <MenuList>
        <MenuItem
          className={classes.headerMenuItem}
          key={'Header'}>
          <div className={classes.headerMenuItemDiv}>More Options</div>
        </MenuItem>
        {actions.map((item, idx) => (
          <MenuItem
            className={classes.menuItem}
            key={idx}
            onClick={item.onClick}
          >
            <div className={classes.menuItemDiv}>{item.title}</div>
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  generateFreeActions() {
    const { classes } = this.props;

    if (this.state.activeState === activeStates.viewDelete) {
      return [
        <Tooltip title="Return">
          <IconButton
            aria-label="return"
            onClick={() => {
              this.setState(
                {
                  activeState: activeStates.viewing,
                },
                () => this.fetch()
              );
            }}
          >
            <BackIcon />
          </IconButton>
        </Tooltip>,
      ];
    }

    return [
      <Tooltip
        key={'new'}
        title="New">
        <IconButton
          aria-label="addNew"
          onClick={(event) =>
            this.setState({
              popoverAnchorEl: event.currentTarget,
              invoiceTypePopoverOpen: true,
              invoiceTypePopoverNextState: activeStates.creating,
              invoiceTypePopoverTitle: 'Create New',
              invoiceTypePopoverPlural: false,
            })
          }
        >
          <MdAddCircle
            className={classNames({
              [classes.iconActive]:
                this.state.activeState === activeStates.creating,
            })}
          />
        </IconButton>
      </Tooltip>,
      <Tooltip
        key={'viewArchive'}
        title="View Deleted Items">
        <IconButton
          aria-label="viewArchive"
          onClick={() => {
            this.setState(
              {
                activeState: activeStates.viewDelete,
              },
              () => this.fetch()
            );
          }}
        >
          <MdFolder />
        </IconButton>
      </Tooltip>,
    ];
  }

  handleChangeSorting(sortBy, order) {
    const { query } = this.state;

    const newQuery = {
      ...query,
      sortBy: [sortBy],
      order: [order],
    };

    this.setState(
      {
        query: newQuery,
      },
      () => this.fetch()
    );
  }

  handleFilterChange(newCrit) {
    const { query } = this.state;
    const newQuery = {
      ...query,
      offset: 0,
    };

    this.setState(
      {
        query: newQuery,
        criteria: newCrit,
      },
      () => this.fetch()
    );
  }

  handleChangeRowsPerPage(event) {
    const rowsPerPage = event.target.value;
    const newQuery = {
      sortBy: [],
      order: [],
      limit: rowsPerPage,
      offset: 0,
    };
    this.setState(
      {
        query: newQuery,
      },
      () => this.fetch()
    );
  }

  handleChangePage(event, newPage) {
    const { query } = this.state;
    const offset = query.limit * newPage;
    const newQuery = {
      ...query,
      offset,
    };
    this.setState(
      {
        query: newQuery,
      },
      () => this.fetch()
    );
  }

  renderMainView() {
    const { currencies } = this.props;
    const {
      activeState,
      invoices,
      totalInvoices,
      loading,
      query,
      counterparties,
    } = this.state;

    return (
      <>
        {this.renderDialogs()}
        <AndileTable
          columns={[
            {
              title: 'Type',
              field: 'type',
              filter: {
                options: invoiceTypes,
                displayAccessor: 'value',
                valueAccessor: 'value',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Number',
              field: 'number',
              filter: { type: TEXT_CRITERION },
            },
            { title: 'Party', field: 'partyCode' },
            {
              title: 'Financial Year',
              field: 'financialYear',
              filter: {
                options: financialYears.map((f) => ({ name: f })),
                displayAccessor: 'name',
                valueAccessor: 'name',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Counterparty',
              disableSort: true,
              filter: {
                asyncOptionsFetcher: this.generateCounterpartyOptions,
                defaultOptions: counterparties ? counterparties : [],
                displayAccessor: 'name',
                valueAccessor: 'id',
                type: TEXT_CRITERION,
              },
              render: (rowData) => {
                if (!rowData.counterpartyId) {
                  return '-';
                }
                const counterPartyName = (
                  counterparties.find((b) => b.id === rowData.counterpartyId) ||
                  {}
                ).name;
                return counterPartyName
                  ? counterPartyName
                  : rowData.counterpartyId;
              },
              field: 'counterpartyId',
            },
            {
              title: 'Amount Due',
              field: 'amountDue',
              filter: { type: NUMBER_CRITERION },
              render: safeRender('amountDue', (amountDue) =>
                FormatNumber(amountDue, true, true, 2, true)
              ),
            },
            {
              title: 'Currency',
              render: safeRender(
                'currencyId',
                (currencyId) =>
                  currencies.find((c) => c.id === currencyId).isoCode
              ),
              field: 'currencyId',
              disableSort: true,
              filter: {
                options: currencies,
                displayAccessor: 'isoCode',
                valueAccessor: 'id',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Cost Currency',
              render: safeRender(
                'costCurrencyId',
                (costCurrencyId) =>
                  currencies.find((c) => c.id === costCurrencyId).isoCode
              ),
              field: 'costCurrencyId',
              disableSort: true,
              filter: {
                options: currencies,
                displayAccessor: 'isoCode',
                valueAccessor: 'id',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Costing Rate',
              field: 'costingRate',
              filter: { type: NUMBER_CRITERION },
              render: safeRender('costingRate', (costingRate) =>
                FormatNumber(costingRate, true, true, 4, true)
              ),
            },
            {
              title: 'Due Date',
              field: 'dueDate',
              render: safeRender('dueDate', processUnixDateForViewing),
              filter: { type: DATE_CRITERION },
            },
            {
              title: 'Shipping Date',
              field: 'shippingDate',
              filter: { type: DATE_CRITERION },
              render: safeRender('shippingDate', (shippingDate) =>
                processUnixDateForViewing(shippingDate)
              ),
            },
            {
              title: 'External Reference',
              field: 'externalReference',
              filter: { type: TEXT_CRITERION },
            },
            {
              title: 'Shipment Reference',
              field: 'shipmentReference',
              filter: { type: TEXT_CRITERION },
            },
            {
              title: 'Notes',
              field: 'notes',
              filter: { type: TEXT_CRITERION },
            },
            {
              title: 'Status',
              field: 'status',
              filter: {
                options: invoiceStatuses,
                displayAccessor: 'value',
                valueAccessor: 'value',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Approved Amount',
              field: 'approvedAmount',
              filter: { type: NUMBER_CRITERION },
              render: safeRender('approvedAmount', (approvedAmount) =>
                FormatNumber(approvedAmount, true, true, 2, true)
              ),
            },
            {
              title: 'Paid Amount',
              field: 'paidAmount',
              filter: { type: NUMBER_CRITERION },
              render: safeRender('paidAmount', (paidAmount) =>
                FormatNumber(paidAmount, true, true, 2, true)
              ),
            },
            {
              title: 'Effective Rate',
              field: 'effectiveRate',
              filter: { type: NUMBER_CRITERION },
              render: safeRender('effectiveRate', (effectiveRate) =>
                FormatNumber(effectiveRate, true, true, 4, true)
              ),
            },
            {
              title: 'Issue Date',
              field: 'issueDate',
              filter: { type: DATE_CRITERION },
              render: safeRender('issueDate', (issueDate) =>
                processUnixDateForViewing(issueDate)
              ),
            },
            {
              title: 'Capture Rate',
              field: 'captureRate',
              filter: { type: NUMBER_CRITERION },
              render: safeRender('captureRate', (captureRate) =>
                FormatNumber(captureRate, true, true, 4, true)
              ),
            },
            {
              title: 'Direction',
              field: 'direction',
              filter: {
                type: TEXT_CRITERION,
                options: invoiceDirections,
                displayAccessor: 'value',
                valueAccessor: 'value',
              },
            },
            {
              title: 'Import/Export',
              field: 'importExport',
              filter: {
                options: invoiceImportExport,
                displayAccessor: 'value',
                valueAccessor: 'value',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Last Modified',
              field: 'auditEntry.time',
              render: safeRender('auditEntry', (auditEntry) =>
                processUnixDateTimeForViewing(auditEntry.time)
              ),
            },
          ]}
          count={totalInvoices}
          data={invoices}
          defaultColConfig={[
            { header: 'Type', visible: true },
            { header: 'Number', visible: true },
            { header: 'Counterparty', visible: true },
            { header: 'Amount Due', visible: true },
            { header: 'Currency', visible: true },
            { header: 'Due Date', visible: true },
            { header: 'Status', visible: true },
            { header: 'Last Modified', visible: true },
            { header: 'Financial Year', visible: false },
            { header: 'Party', visible: false },
            { header: 'Cost Currency', visible: false },
            { header: 'Costing Rate', visible: false },
            { header: 'Shipping Date', visible: false },
            { header: 'External Reference', visible: false },
            { header: 'Shipment Reference', visible: false },
            { header: 'Notes', visible: false },
            { header: 'Approved Amount', visible: false },
            { header: 'Paid Amount', visible: false },
            { header: 'Effective Rate', visible: false },
            { header: 'Issue Date', visible: false },
            { header: 'Capture Rate', visible: false },
            { header: 'Direction', visible: false },
            { header: 'Import/Export', visible: false },
          ]}
          freeActions={this.generateFreeActions()}
          freeActionsMenu={this.generateMenu()}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          initialCriteria={initialCriteria}
          loading={loading}
          maxTableHeight={600}
          onChangeSorting={this.handleChangeSorting}
          onFilterChange={this.handleFilterChange}
          order={query.order.length > 0 ? query.order[0] : undefined}
          page={Math.ceil(query.offset / query.limit)}
          rowActions={this.generateRowActions}
          rowDoubleClickAction={(rowData) =>
            this.setState({
              showDetail: rowData,
            })
          }
          rowsPerPage={query.limit}
          rowsPerPageOptions={rowsPerPageOptions}
          sortBy={query.sortBy.length > 0 ? query.sortBy[0] : undefined}
          tableID={'InvoiceStationTable'}
          title={
            activeState === activeStates.viewDelete
              ? 'Deleted Invoices'
              : 'Invoices'
          }
        />
      </>
    );
  }
}

InvoiceStation.propTypes = {
  currencies: PropTypes.array,
  partyCode: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(InvoiceStation);
