import {marshalIdentifier} from '../../marshallers';
import {MarshalCriteria as marshallCriteria} from 'popcorn-js/search/criteria/Marshaller'
import {jsonRpcRequest} from '../../jsonRpc/jsonRpcReqest';
import {config} from '../../index'


/**
 * ApiUserRecordkeeper is a convenience client for invoking backend services
 */

export const Recordkeeper = {
  retrieve(retrieveRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'APIUser-Recordkeeper.Retrieve',
      request: {
        ...retrieveRequest,
        identifier: serializedIdentifier,
      },
    })
  },

  create(createRequest) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'APIUser-Recordkeeper.Create',
      request: {...createRequest},
    })
  },

  update(updateRequest) {
    const serializedIdentifier = marshalIdentifier(updateRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'APIUser-Recordkeeper.Update',
      request: {
        ...updateRequest,
        identifier: serializedIdentifier
      },
    })
  },

  find(findRequest) {
    const serializedCriteria = marshallCriteria(findRequest.criteria)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'APIUser-Recordkeeper.Find',
      request: {
        ...findRequest,
        criteria: serializedCriteria
      },
    })
  },

  deleteForever(deleteForeverRequest) {
    const serializedIdentifier = marshalIdentifier(deleteForeverRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'APIUser-Recordkeeper.DeleteForever',
      request: {
        ...deleteForeverRequest,
        identifier: serializedIdentifier
      },
    })
  },
}
