import React from 'react'
import {connect} from 'react-redux'
import Workstation from 'views/SettlementInstruction/Workstation/Workstation'

function WorkstationContainer(props) {
  return <Workstation {...props}/>
}

const mapStateToProps = state => {
  const currencies = []
  try {
    // for every currency pair in the system
    for (const ccyPair of state.currencyPair.records) {
      // if the currency pair is assigned to my party
      if (state.myParty.currencyPairIds.includes(ccyPair.id)) {
        // check if the base currency has already been added to list of currencies
        if (!currencies.find(ccy => ccy.id === ccyPair.baseCurrencyId)) {
          // if it has not yet, add it
          currencies.push(state.currency.records.find(
            ccy => ccy.id === ccyPair.baseCurrencyId),
          )
        }
        // check if the quote currency has already been added to list of currencies
        if (!currencies.find(ccy => ccy.id === ccyPair.quoteCurrencyId)) {
          // if it has not yet, add it
          currencies.push(state.currency.records.find(
            ccy => ccy.id === ccyPair.quoteCurrencyId),
          )
        }
      }
    }
  } catch (e) {
    console.error('error getting currency pairs assigned to my party', e)
  }
  return {
    partyCode: state.security.claims.context.partyCode,
    parentPartyCode: state.myParty.parentPartyCode, // todo is this correct
    currencies,
  }
}

const mapDispatchToProps = () => {
  return {}
}

const ConnectedWorkstationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkstationContainer)

export default ConnectedWorkstationContainer
