import {marshalIdentifier} from '../../marshallers'
import {MarshalCriteria as marshallCriteria} from 'popcorn-js/search/criteria/Marshaller'
import {jsonRpcRequest} from '../../jsonRpc/jsonRpcReqest'
import {config} from '../../index'

const recordkeeper = 'Role-Recordkeeper'

export const Recordkeeper = {
  retrieveAllUserPermissions() {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${recordkeeper}.RetrieveAllUserPermissions`,
      request: {},
    })
  },

  retrieve(retrieveRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${recordkeeper}.Retrieve`,
      request: {
        ...retrieveRequest,
        identifier: serializedIdentifier,
      },
    })
  },

  delete(deleteRequest) {
    const serializedIdentifier = marshalIdentifier(deleteRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${recordkeeper}.Delete`,
      request: {
        ...deleteRequest,
        identifier: serializedIdentifier,
      },
    })
  },

  create(createRequest) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${recordkeeper}.Create`,
      request: {...createRequest},
    })
  },

  update(updateRequest) {
    const serializedIdentifier = marshalIdentifier(updateRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${recordkeeper}.Update`,
      request: {
        ...updateRequest,
        identifier: serializedIdentifier,
      },
    })
  },

  updateProtected(updateRequest) {
    const serializedIdentifier = marshalIdentifier(updateRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${recordkeeper}.UpdateProtectedRole`,
      request: {
        ...updateRequest,
        identifier: serializedIdentifier,
      },
    })
  },

  find(findRequest) {
    const serializedCriteria = marshallCriteria(findRequest.criteria)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${recordkeeper}.Find`,
      request: {
        ...findRequest,
        criteria: serializedCriteria,
      },
    })
  },

  restore(restoreRequest) {
    const serializedIdentifier = marshalIdentifier(restoreRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${recordkeeper}.Restore`,
      request: {
        ...restoreRequest,
        identifier: serializedIdentifier,
      },
    })
  },

  deleteForever(deleteForeverRequest) {
    const serializedIdentifier = marshalIdentifier(deleteForeverRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${recordkeeper}.Delete`,
      request: {
        ...deleteForeverRequest,
        identifier: serializedIdentifier,
      },
    })
  },
  retrieveHistory(retrieveHistoryRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveHistoryRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${recordkeeper}.RetrieveHistory`,
      request: {
        ...retrieveHistoryRequest,
        identifier: serializedIdentifier,
      },
    })
  },

}
