import { AuditEntry } from 'popcorn-js/audit/entryTypes';
import { PartyCodeIdentifier } from 'popcorn-js/search/identifier';

export interface PrepaymentTradeLink {
  prepaymentId: string;
  tradeId: string;
  amount: number;
  rate: number;
}

export interface PrepaymentAmountRequested {
  prepaymentId: string;
  amount: number;
}

export interface InvoiceTradeLink {
  invoiceId: string;
  tradeId: string;
  amount: number;
  rate: number;
}

export interface CustomEntryTradeLink {
  tradeId: string;
  amount: number;
  rate: number;
}

export interface CustomEntry {
  counterpartyId: string;
  description?: string;
  amount: number;
  tradeLinks: CustomEntryTradeLink[];
}

export interface InvoiceAmountRequested {
  invoiceId: string;
  amount: number;
}

export enum Status {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Complete = 'Complete',
  Processing = 'Processing',
}

export interface SettlementInstruction {
  id?: string;
  number?: string;
  date?: number;
  currencyId?: string;
  partyCode?: PartyCodeIdentifier;
  status?: Status;
  processingOrg?: PartyCodeIdentifier;
  invoiceAmountsRequested?: InvoiceAmountRequested[];
  invoiceTradeLinks?: InvoiceTradeLink[];
  prepaymentAmountsRequested?: PrepaymentAmountRequested[];
  prepaymentTradeLinks?: PrepaymentTradeLink[];
  customEntries?: CustomEntry[];
  financialYear?: string;
  importExport?: string;
  fxAmount?: number;
  localCurrencyAmount?: number;
  dealRate?: number;
  fxSource?: string;
  avgCostingRate?: number;
  avgEffectiveRate?: number;
  pnl?: number;
  auditEntry?: AuditEntry;
}