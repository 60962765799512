import uuidv1 from 'uuid/v1';

export function jsonRpcRequest({url, method, request, accessToken}) {
  const id = uuidv1()
  const header = new Headers({
    'Content-Type': 'application/json',
  })

  console.log('method ', method)

  if (accessToken) {
    header.append('Authorization', accessToken)
  }

  const body = JSON.stringify({
    jsonrpc: '2.0',
    method: method,
    params: [request],
    id: id,
  })
  console.debug(method + ' jsonRpcRequest.body: ', JSON.parse(body))

  return new Promise((resolve, reject) => {
    fetch(
      url, {
        method: 'POST',
        headers: header,
        mode: 'cors',
        body: body,
        credentials: 'include',
      },
    ).then(responseObject => {
      if (responseObject.ok) {
        return responseObject.json()
      } else {
        reject('Request failed: ' + responseObject.statusText)
      }
    }).then(response => {
      console.debug(method + ' jsonRpcRequest.response: ', response)
      if (response.result) {
        console.log(method + ' - success', response.result)
        resolve(response.result)
      } else {
        reject(response.error)
        console.error(method + ' - error: ', response.error)
      }
    }).catch(error => {
      reject(error)
      console.error(method + ' jsonRpcRequest.error: ', error)
    })
  })
}
