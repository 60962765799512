import { FindRequest, FindResponse } from 'popcorn-js/indexT';
import { Criteria } from 'popcorn-js/search';
import { marshalCriteria } from './search/marshaller';
import { TradingDayException } from '.';
import config from 'react-global-configuration';
import { jsonRPC } from 'utils/jsonrpc';

export const Recordkeeper = {
  ServiceProviderName: 'TradingDayException-Recordkeeper',
  find(request: FindRequest): Promise<FindResponse<TradingDayException>> {
    const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
    return jsonRPC<FindRequest, FindResponse<TradingDayException>>({
      url: config.get('golangUrl'),
      method: `${Recordkeeper.ServiceProviderName}.Find`,
      request: {
        ...request,
        criteria: serializedCriteria,
      },
    });
  },
};
