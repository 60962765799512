import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import XLSX from 'xlsx'
import * as Papa from 'papaparse'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import {MdCheckCircle as SuccessIcon, MdClose as CloseIcon, MdErrorOutline as ErrorIcon} from 'react-icons/md'
import {isNumber, isObject, isString, round} from 'utils/Utils'
import {Table} from 'components/Table'
import Theme from 'tmp/Theme'
import moment from 'moment'
import {SystemDateFormat} from 'constants/formats'
import miniLogo from 'assets/img/stellcap-logo-mini.svg'
import Fab from '@material-ui/core/Fab'

import styles from './importStyles'


const processingStates = {
  fileSelect: 'Processing File',
  fileParse: 'Parsing File',
  objectMapping: 'Mapping Objects',
  processingImportObjectsForDownload: 'Preparing Download',
}

const errorStates = {
  processFileError: 'Error Processing File',
  parseFileError: 'Error Parsing File',
  mappingError: 'Error Mapping Headers',
  createImportObjectsError: 'Error Creating Import Objects',
  errorCreatingPreviewDownload: 'Error Creating Preview Download',
}

const states = {
  'Select File': {
    selectingFile: 0,
    fileSelected: 1,
    processingFile: processingStates.fileSelect,
    processFileError: errorStates.processFileError,
    confirmFileDropped: 4,
    parsingFile: processingStates.fileParse,
    parseFileError: errorStates.parseFileError,
    parseFileSuccess: 7,
  },
  'Import Configuration': {
    setup: 8,
    inProgress: processingStates.objectMapping,
    error: errorStates.mappingError,
    createImportObjectsError: errorStates.createImportObjectsError,
  },
}

const events = {
  init: states['Select File'].selectingFile,
  dropOrSelectFile: states['Select File'].processingFile,
  processFileError: states['Select File'].processFileError,
  processFileSuccess: states['Select File'].parsingFile,
  parseFileError: states['Select File'].parseFileError,

  parseFileSuccess: states['Import Configuration'].setup,

  changeSelectedMappingConf: states['Select File'].parsingFile,
  mappingError: states['Import Configuration'].error,
  startMappingToObjects: states['Import Configuration'].inProgress,
  errorCreatingImportObjects: states['Import Configuration'].createImportObjectsError,
}

const steps = Object.keys(states)

const getStep = (activeState) => {
  for (let stepIdx = 0; stepIdx < steps.length; stepIdx++) {
    const stepStates = states[steps[stepIdx]]
    if (isObject(stepStates)) {
      for (const stepState in stepStates) {
        if (stepStates[stepState] === activeState) {
          return steps[stepIdx]
        }
      }
    } else if (stepStates === activeState) {
      return steps[stepIdx]
    }
  }
}

const stepComplete = (stepLabel, activeStep) => {
  return steps.indexOf(activeStep) > steps.indexOf(stepLabel)
}

const defaultMappingConf = {
  name: 'default',
  headerRowNo: 1,
}

class Import extends Component {
  dropzoneRef = undefined

  constructor(props) {
    super(props)
    this.state = {
      activeState: events.init,
      file: {
        name: undefined,
        data: undefined,
        ext: undefined,
      },
      errors: {},
      mappingConf: defaultMappingConf,
      importConfigs: [
        ...this.props.importConfigs,
        defaultMappingConf,
      ],
      importObjects: [],
      importFieldsObjs: [],
    }
  }

  static cleanHeader(header) {
    if (typeof header !== typeof '_') {
      return header
    }
    header = header.toLowerCase().trim()
    while (header.includes(' ')) {
      header = header.replace(' ', '')
    }
    while (header.includes('/')) {
      header = header.replace('/', '')
    }
    return header
  }

  static parseValueForObj = (value, type, optional) => {
    let retVal
    switch (type) {
      case 'date':
        // possible date formats
        const formats = ['MM/DD/YY', SystemDateFormat]

        if (value === '') { // if a blank string is given
          if (optional) {
            // then if the value is optional, return default for date
            return 0
          } else {
            // otherwise log error and return undefined
            console.error(`unable to parse value '${value}' to ${type}`)
            return undefined
          }
        } else if (isString(value)) { // otherwise if some other string is given
          try {
            // try and parse the date
            return moment.utc(value, formats).startOf('day').unix()
          } catch (e) {
            // if parsing fails then
            if (optional) {
              // if value is optional, return default for date
              return 0
            } else {
              // otherwise log error and return undefined
              console.error(`unable to parse value '${value}' to ${type}`)
              return undefined
            }
          }
        } else if (isNumber(value)) { // otherwise if a number is given
          // return the integer part of the number
          return Math.trunc(value)
        } else {
          // if anything else is given
          if (optional) {
            // if value is optional, return default for date
            return 0
          } else {
            // otherwise log error and return undefined
            console.error(`unable to parse value '${value}' to ${type}`)
            return undefined
          }
        }

      case 'float':

        value = value.replace(',', '')
        retVal = parseFloat(value)
        // if the result is not a number
        if (isNaN(retVal)) {
          if (optional) {
            // then if the value is optional, return default for float
            return 0.0
          } else {
            // otherwise log error and return undefined
            console.error(`unable to parse value '${value}' to ${type}`)
            return undefined
          }
        } else {
          // if the result is a number, return rounded to 4 decimal places
          return round(retVal, 4)
        }

      case 'int':
        // try and parse the value
        value = Import.removeCharacter(value, ' ')
        if (value.includes(',') && value.includes('.')) {
          value = Import.removeCharacter(value, ',')
        }
        retVal = parseInt(value, 10)
        // if the result is not a number
        if (isNaN(retVal)) {
          if (optional) {
            // then if the value is optional, return default for int
            return 0
          } else {
            // otherwise log error and return undefined
            console.error(`unable to parse value '${value}' to ${type}`)
            return undefined
          }
        } else {
          // if the result of parsing is a number, return as is
          return retVal
        }

      case 'string':
      default:
        if (
          (value === '') ||
          (value === undefined)
        ) {
          if (optional) {
            // then if the value is optional, return default for string
            return ''
          } else {
            // otherwise log error and return undefined
            console.error(`unable to parse value '${value}' to ${type}`)
            return undefined
          }
        } else {
          return value.toString()
        }
    }
  }

  static parseValueForPreview = (value, type, optional) => {
    switch (type) {
      case 'date':
        if (!value) {
          return '-'
        } else if (isString(value)) {
          return '-'
        } else {
          try {
            return moment.unix(value).utc().format(SystemDateFormat)
          } catch (e) {
            if (!optional) {
              console.error(`error parsing date value for preview: ${e}`)
            }
            return '-'
          }
        }
      default:
        return value
    }
  }

  static removeCharacter(value, character) {
    if (!isString(value)) {
      return value
    }

    while (value.includes(character)) {
      value = value.replace(character, '')
    }
    return value
  }

  reInit = () => {
    this.setState({
      activeState: events.init,
      file: {
        name: undefined,
        data: undefined,
        ext: undefined,
      },
      errors: {},
      importSheet: {
        rowObjects: [],
      },
      mappingConf: defaultMappingConf,
      importObjects: [],
    })
  }

  closeImportDialog = () => {
    this.reInit()
    this.props.onAwayClick()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {activeState: prevActiveState} = prevState
    const {activeState} = this.state

    // Detect State Change
    if (prevActiveState !== activeState) {
      if (activeState === states['Select File'].parsingFile) {
        try {
          this.parseFile()
        } catch (e) {
          console.error(`Error Parsing File\n${e}`)
          this.setState({
            activeState: events.parseFileError,
            errors: {
              ...this.state.errors,
              [events.parseFileError]: e,
            },
          })
        }
        return
      }

      if (activeState === states['Import Configuration'].inProgress) {
        try {
          this.createImportObjects()
        } catch (e) {
          console.error('Error creating import objects', e)
          this.setState({
            activeState: events.errorCreatingImportObjects,
            errors: {
              ...this.state.errors,
              [events.errorCreatingImportObjects]: e,
            },
          })
        }
        return
      }
    }
  }

  render() {
    const {
      classes,
      entityImportClass,
    } = this.props
    const {
      activeState,
    } = this.state
    const activeStep = getStep(activeState)
    const dialogProps = (() => {
      switch (activeStep) {
        default:
          return {
            fullScreen: true,
          }
      }
    })()

    return (
      <Dialog
        className={classes.root}
        modal={undefined}
        onClose={this.closeImportDialog}
        open={this.props.show}
        scroll="paper"
        {...dialogProps}
      >
        <DialogTitle className={classes.dialogTitleWrapper}>
          <div className={classes.dialogTitle}>
            <div className={classes.TBDLogoWrapper}>
              <img
                alt="logo"
                className={classes.TBDLogoImg}
                src={miniLogo}/>
            </div>
            <div className={classes.dialogTitleText}>Import {entityImportClass.importEntityLabel}</div>
            <div className={classes.dialogTitleCloseBtnWrapper}>
              <Tooltip
                placement="top"
                title="Close">
                <Fab
                  aria-label="Close"
                  className={classes.dialogTitleCloseButton}
                  color="primary"
                  id="importDialogCloseBtn"
                  onClick={this.closeImportDialog}
                >
                  <CloseIcon className={classes.dialogTitleCloseIcon}/>
                </Fab>
              </Tooltip>
            </div>
          </div>
        </DialogTitle>
        <div
          className={classes.dialogContent}
          id="importDialogRoot">
          <div className={classes.stepperWrapper}>
            <Stepper
              activeStep={steps.indexOf(activeStep)}
              className={classes.stepper}
              nonLinear
            >
              {steps.map((stepLabel, stepIdx) => {
                return (
                  <Step key={stepIdx}>
                    <StepButton
                      completed={stepComplete(stepLabel, activeStep)}
                    >
                      {stepLabel}
                    </StepButton>
                  </Step>
                )
              })}
            </Stepper>
          </div>
          <div className={classes.stepContentWrapper}>
            {(() => {
              switch (true) {
                // Render Generic Processing Screen
                case Object.values(processingStates).includes(activeState):
                  return this.renderProcessing()
                // Render Generic Error Screen
                case Object.values(errorStates).includes(activeState):
                  return this.renderError()

                // Check For Steps and Render Them
                case activeStep === 'Select File':
                  return this.renderSelectFileStep()
                case activeStep === 'Import Configuration':
                  return this.renderMapHeadersToFieldsStep()
                default:
                  return <div>Should not see this</div>
              }
            })()}
          </div>
        </div>
      </Dialog>
    )
  }

  renderProcessing = () => {
    const {activeState} = this.state
    const {classes} = this.props
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          alignItems: 'center',
          justifyItems: 'center',
          margin: '15px',
        }}
      >
        <div><b>{activeState}</b></div>
        <div>
          <CircularProgress className={classes.progress}/>
        </div>
      </div>
    )
  }

  renderError = () => {
    const {activeState, errors} = this.state
    const {classes} = this.props
    const error = errors[activeState]

    let errorMsg = 'Unknown Error'
    if (isObject(error)) {
      if (error.message) {
        errorMsg = error.message
      }
    } else if (isString(error)) {
      errorMsg = error
    }

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          alignItems: 'center',
          justifyItems: 'center',
          margin: '15px',
        }}
      >
        <div><b>{activeState}</b></div>
        <div>
          <ErrorIcon
            className={classes.errorIcon}
          />
        </div>
        <div>{errorMsg}</div>
        <div>
          <Button
            className={classes.button}
            onClick={this.reInit}
          >
            Start Over
          </Button>
        </div>
      </div>
    )
  }

  renderSelectFileStep = () => {
    const {
      classes,
    } = this.props
    const {
      activeState,
      file,
    } = this.state
    return (
      <div style={{padding: 10}}>
        <Grid
          alignItems={'center'}
          container
          direction={'column'}
          spacing={1}>
          {(() => {
            switch (activeState) {
              case states['Select File'].selectingFile:
                return (
                  <Grid item>
                    <Dropzone
                      accept=".xls,.xlsx,.csv"
                      multiple={false}
                      // accept='text/plain'
                      onDrop={(selectedFiles) => {
                        this.setState({
                          activeState: events.dropOrSelectFile,
                        })
                        this.handleFileDrop(selectedFiles)
                      }}
                      ref={(node) => {
                        this.dropzoneRef = node
                      }}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section
                          style={{
                            height: '100%',
                            borderWidth: '1px',
                            borderStyle: 'dashed',
                            borderColor: Theme.Secondary,
                          }}
                        >
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div
                              style={{
                                height: '100%',
                                display: 'grid',
                                minWidth: '300px',
                                minHeight: '150px',
                              }}
                            >
                              <span style={{justifySelf: 'center', alignSelf: 'center'}}>
                                Drag and Drop Your File Here
                              </span>
                            </div>
                          </div>

                        </section>
                      )}
                    </Dropzone>
                  </Grid>
                )
              case states['Select File'].confirmFileDropped:
                return (
                  <Grid item>
                    Selected File: {file.name}
                  </Grid>
                )
              default:
            }
          })()}
          <Grid
            container
            direction={'row'}
            justify={'center'}
            spacing={1}>
            <React.Fragment>
              <Grid item>
                <Button
                  className={classes.button}
                  id="selectFileBtn"
                  onClick={() => {
                    if (this.dropzoneRef)
                      this.dropzoneRef.open()
                  }}
                >
                  Select File
                </Button>
              </Grid>
            </React.Fragment>
          </Grid>
        </Grid>
      </div>
    )
  }

  handleFileDrop = (acceptedFiles) => {

    if (!acceptedFiles.length) {
      console.error('No accepted files found')
      return
    }

    // Find File extension
    const extPos = acceptedFiles[0].name.search(/\./)
    if (extPos < 0) {
      console.error('unable to find file extension')
      this.setState({activeState: events.processFileError})
      return
    }
    let fileExt
    try {
      fileExt = acceptedFiles[0].name.slice(extPos + 1)
    } catch (e) {
      console.error('Unable to get file extension', e)
      this.setState({activeState: events.processFileError})
      return
    }

    // Create file reader object
    const reader = new FileReader()

    // Attach event handlers
    reader.onload = (event) => {
      this.setState({
        activeState: events.processFileSuccess,
        file: {
          name: acceptedFiles[0].name,
          data: event.target.result,
          ext: fileExt,
        },
      })
    }

    reader.onerror = (err) => {
      console.error('error loading file: ', err)
      this.setState({activeState: events.processFileError})
    }
    // Start File Read
    reader.readAsBinaryString(acceptedFiles[0])
  }

  parseFile = () => {
    const {
      file,
    } = this.state

    let rowObjects = []
    switch (file.ext) {
      case 'xlsx':
      case 'xls':
        let workbook = undefined
        let worksheet = undefined
        // Try parse data to workbook
        try {
          workbook = XLSX.read(file.data, {type: 'binary'})
        } catch (e) {
          throw new Error(`unable to read file data to workbook\n${e}`)
        }
        // Try get worksheet
        try {
          worksheet = workbook.Sheets[workbook.SheetNames[0]]
        } catch (e) {
          throw new Error(`unable to get first worksheet of given workbook\n${e}`)
        }
        // Try convert contents of worksheet to object array
        try {
          rowObjects = XLSX.utils.sheet_to_json(worksheet, {raw: false})
        } catch (e) {
          throw new Error('unable to convert sheet to row objects')
        }

        break
      case 'csv':
        // Try and parse .csv file
        let parsedFileData
        try {
          parsedFileData = Papa.parse(
            file.data,
            {
              delimiter: ',',
            },
          ).data
        } catch (e) {
          throw new Error(`error parsing csv\n${e}`)
        }
        // Try convert into rowObjects
        try {
          const headers = parsedFileData[0]
          const rowDataWithoutHdr = parsedFileData.slice(1)
          for (let i = 0; i < rowDataWithoutHdr.length; i++) {
            const row = rowDataWithoutHdr[i]
            const rowObj = {}
            for (let j = 0; j < row.length; j++) {
              rowObj[headers[j]] = row[j]
            }
            rowObjects.push(rowObj)
          }
        } catch (e) {
          throw new Error(`error making row objects from csv\n${e}`)
        }
        break
      default:
        throw new Error(`Files With Extension ${file.ext} are not supported`)
    }

    // Check if there are any rows in file provided
    if (!(rowObjects.length > 0)) {
      throw new Error('no rows in workbook supplied')
    }

    this.setState({
      activeState: events.parseFileSuccess,
      importSheet: {
        rowObjects: rowObjects,
      },
    })
  }

  renderMapHeadersToFieldsStep = () => {
    const {
      classes,
      onAwayClick,
      submitRecords,
    } = this.props
    const {
      importSheet,
      mappingConf,
      importConfigs,
    } = this.state

    const importSheetHdrs = Object.keys(importSheet.rowObjects[0])
    const missingHeaders = this.colHeaderCheck()
    let columns
    try {
      columns = importSheetHdrs.map(hdr => {
        return {
          id: hdr,
          accessor: rowObj => rowObj[hdr],
          ...this.buildMappingHeader(hdr),
        }
      })
    } catch (e) {
      this.setState({
        activeState: events.mappingError,
        errors: {
          ...this.state.errors,
          [events.mappingError]: `Error Mapping Column Headers\n${e}`,
        },
      })
    }

    return (
      <div
        id="importDialogImportConfigStepRoot"
        style={{
          padding: 10,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
        }}
      >
        <div style={{padding: '0px 0px 10px 0px'}}>
          <Grid
            alignItems={'flex-start'}
            container
            direction={'column'}
            justify={'flex-start'}
            spacing={1}
          >
            <Grid item>
              <div
                style={{
                  display: 'grid',
                  alignItems: 'center',
                  gridTemplateColumns: 'auto auto',
                }}>
                <div className={classes.confTitle}>
                  Selected Import Configuration:
                </div>
                <div className={classes.confBody}>
                  <Select
                    classes={{
                      root: classes.configSelect,
                      icon: classes.configSelectDisabled,
                      disabled: classes.configSelectDisabled,
                    }}
                    disabled
                    inputProps={{
                      name: 'mappingConf',
                      id: 'mappingConf',
                    }}
                    onChange={(e) => this.handleChangeSelectedMappingConf(e.target.value)}
                    value={mappingConf.name}
                  >
                    {importConfigs.map((config, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          value={config.name}>
                          {config.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div
                style={{
                  display: 'grid',
                  alignItems: 'center',
                  gridTemplateColumns: 'auto auto',
                }}>
                <div className={classes.confTitle}>
                  Header Row Number:
                </div>
                <div className={classes.confBody}>
                  {mappingConf.headerRowNo}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            padding: '0px 0px 10px 0px',
            justifySelf: 'end',
          }}>
          <Grid
            alignItems={'flex-start'}
            container
            direction={'column'}
            justify={'flex-start'}
            spacing={1}
          >
            {missingHeaders.length
              ? <React.Fragment>
                <Grid item>
                  <div
                    style={{
                      display: 'grid',
                      alignItems: 'center',
                      gridTemplateColumns: 'auto auto',
                    }}
                  >
                    <div>Missing Columns In Import Sheet</div>
                    <ErrorIcon/>
                  </div>
                  <div>Resolve To Continue</div>
                  <div>Correct Sheet Or Select Different Configuration</div>
                </Grid>
                <Grid item>
                  <ul>
                    {missingHeaders.map((hdr, idx) => {
                      return <li key={idx}>{hdr}</li>
                    })}
                  </ul>
                </Grid>
              </React.Fragment>
              : <React.Fragment>
                <Grid item>
                  <div
                    style={{
                      display: 'grid',
                      alignItems: 'center',
                      gridTemplateColumns: 'auto auto',
                    }}
                  >
                    <div>All Columns Mapped Successfully</div>
                    <SuccessIcon/>
                  </div>
                </Grid>
              </React.Fragment>
            }
          </Grid>
        </div>
        <div
          style={{
            padding: '0px 0px 10px 0px',
            justifySelf: 'end',
          }}>
          <Grid
            alignItems={'flex-start'}
            container
            direction={'column'}
            justify={'flex-start'}
            spacing={1}
          >
            <Grid item>
              <Button
                className={classes.button}
                id="importDialogValidateBtn"
                onClick={() => {
                  const [importObjects, importFieldsObjs] = this.createImportObjects()
                  onAwayClick()
                  submitRecords(importObjects, importFieldsObjs)
                }}
              >
                Validate
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                id="importDialogImportConfigStepStartOverBtn"
                onClick={this.reInit}
              >
                Start Over
              </Button>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            overflow: 'auto',
            gridColumn: '1/4',
          }}>
          <Table
            columns={columns}
            data={importSheet.rowObjects}
            defaultPageSize={10}
            filterable
            getTheadTrProps={() => {
              return {style: {height: 60}}
            }}
            id="importDialogImportConfigStepPreviewTable"
            noDataText={'No Rows Found In Import File'}
            sortable={false}
          />
        </div>
      </div>
    )
  }

  buildMappingHeader = (hdr) => {
    const {
      entityImportClass,
    } = this.props
    const {
      mappingConf,
    } = this.state

    let importFields
    let initDataFieldObjs
    let width = 80
    try {
      importFields = entityImportClass.importFields().map(field => {
        const len = field.name.length * 10.5
        width = len > width ? len : width
        return field.name
      })
      initDataFieldObjs = entityImportClass.importFields()
    } catch (e) {
      console.error(`invalid entityImportClass\n${e}`)
      throw new Error(`invalid entityImportClass\n${e}`)
    }

    let val = '-'
    try {
      if (mappingConf.headerMap) {
        // Try set val of header select
        for (const importSheetHdr in mappingConf.headerMap) {
          if (importSheetHdr === hdr) {
            val = mappingConf.headerMap[importSheetHdr].initField
            break
          }
        }
      } else if (mappingConf.name === 'default') {
        // Try set val of header select
        try {
          for (let i = 0; i < importFields.length; i++) {
            if (
              (initDataFieldObjs[i].objField &&
                (Import.cleanHeader(initDataFieldObjs[i].objField) === Import.cleanHeader(hdr))
              ) ||
              (Import.cleanHeader(importFields[i]) === Import.cleanHeader(hdr))
            ) {
              val = importFields[i]
              break
            }
          }
        } catch (e) {

        }
      } else {
        console.error('invalid mapping config supplied')
      }
    } catch (e) {
      throw new Error(`error looking for value to set header select to\n${e}`)
    }

    return {
      Header: <div
        style={{
          display: 'grid',
          gridTemplateRows: 'auto auto',
          gridTemplateColums: 'auto',
          justifyItems: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Select
            disabled
            inputProps={{
              name: 'mappingSelect',
              id: 'mappingSelect',
            }}
            style={{
              backgroundColor: '#ffffff',
              width: width,
            }}
            value={val}
          >
            <MenuItem value={'-'}>
              -
            </MenuItem>
            {importFields.map((initField, idx) => {
              return (
                <MenuItem
                  key={idx}
                  value={initField}>
                  {initField}
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <div
          style={{
            padding: 2,
          }}
        >
          {hdr}
        </div>
      </div>,
      width: width + 10,
    }
  }

  handleChangeSelectedMappingConf = (configName) => {
    const {
      importConfigs,
    } = this.state
    for (let i = 0; i < importConfigs.length; i++) {
      if (importConfigs[i].name === configName) {
        this.setState({
          mappingConf: importConfigs[i],
          activeState: events.changeSelectedMappingConf,
        })
        return
      }
    }
    console.error('invalid conf selected!')
  }

  createImportObjects = () => {
    const {
      entityImportClass,
      addNewFromImportProps,
    } = this.props
    const {
      mappingConf,
      importSheet,
    } = this.state

    let objInitFields
    const objInitFieldsTypeMap = {}
    const objInitFieldsOptionalMap = {}
    try {
      objInitFields = entityImportClass.importFields()
      objInitFields.forEach(fieldInit => {
        objInitFieldsTypeMap[fieldInit.name] = fieldInit.type
        objInitFieldsOptionalMap[fieldInit.name] = fieldInit.optional
      })
    } catch (e) {
      throw new Error(`Unable to get objInitFields from given entityImportClass\n${e}`)
    }

    const initObjects = []
    const rowGroupVals = []
    const importObjects = []
    const importFieldsObjs = []

    if (mappingConf.name === 'default') {
      for (let i = 0; i < importSheet.rowObjects.length; i++) {
        const rowObj = importSheet.rowObjects[i]
        const initObj = {}
        objInitFields.forEach(objInit => {
          Object.keys(rowObj).forEach(rowHdr => {
            const objFieldUsed = objInit.objField &&
              (Import.cleanHeader(objInit.objField) === Import.cleanHeader(rowHdr))
            if (
              objFieldUsed ||
              (Import.cleanHeader(objInit.name) === Import.cleanHeader(rowHdr))
            ) {
              initObj[objInit.name] = Import.parseValueForObj(rowObj[rowHdr], objInit.type, objInit.optional)
            }
          })
        })
        // Create Objects
        const newObj = entityImportClass.newFromImportFields(initObj, addNewFromImportProps)
        importObjects.push(newObj)
        // Save init Objects
        importFieldsObjs.push(initObj)
      }
    } else {
      // For Each Row
      for (let i = 0; i < importSheet.rowObjects.length; i++) {
        const rowObj = importSheet.rowObjects[i]
        // Check for row group values to track
        if (mappingConf.rowGroupValues) {
          // For each cell in row
          for (const hdr in rowObj) {
            // A row group value exists with the value of this cell:
            if (mappingConf.rowGroupValues.hasOwnProperty(rowObj[hdr])) {
              if (rowObj.hasOwnProperty(mappingConf.rowGroupValues[rowObj[hdr]].valColHdr)) {
                rowGroupVals.push({
                  importColHdr: rowObj[hdr],
                  initField: mappingConf.rowGroupValues[rowObj[hdr]].initField,
                  val: rowObj[mappingConf.rowGroupValues[rowObj[hdr]].valColHdr],
                  upToIdx: i,
                })
              }
            }
          }
        }

        // Check for ignore row rules
        let ignoreRow = false
        if (mappingConf.ignoreRowRules) {
          Object.keys(mappingConf.ignoreRowRules).forEach(hdr => {
            if (
              (rowObj.hasOwnProperty(hdr)) &&
              (rowObj[hdr] === mappingConf.ignoreRowRules[hdr])
            ) {
              ignoreRow = true
            }
          })
        }

        if (ignoreRow) continue

        const initObj = {}

        // Set Mapped Fields
        for (const hdr in mappingConf.headerMap) {
          let val = rowObj[hdr]
          // Apply preParser if one is specified
          if (mappingConf.headerMap[hdr].preParser) {
            val = mappingConf.headerMap[hdr].preParser(val)
          }
          // Check if Value is blank after parsing and
          // Check if alternate supplied
          if (
            ((val === '') || (val === undefined)) &&
            mappingConf.headerMap[hdr].alternateField
          ) {
            val = rowObj[mappingConf.headerMap[hdr].alternateField]
            // Apply preParser if one is specified
            if (mappingConf.headerMap[hdr].preParser) {
              val = mappingConf.headerMap[hdr].preParser(val)
            }
          }

          // Parse Value
          val = Import.parseValueForObj(
            val,
            objInitFieldsTypeMap[mappingConf.headerMap[hdr].initField],
            objInitFieldsOptionalMap[mappingConf.headerMap[hdr].initField],
          )

          // Post Parse Processing
          if (mappingConf.headerMap[hdr].postProcessor) {
            val = mappingConf.headerMap[hdr].postProcessor(val)
          }

          // Set value in the init object
          initObj[mappingConf.headerMap[hdr].initField] = val
        }

        // Set Const Fields
        for (const hdr in mappingConf.consts) {
          initObj[hdr] = mappingConf.consts[hdr]
        }

        initObjects.push({
          initObj,
          idx: i,
        })
      }

      // Create import objects
      for (let i = 0; i < initObjects.length; i++) {
        const initObj = initObjects[i].initObj
        const initObjIdx = initObjects[i].idx

        // Check for group value to set
        if ((!(mappingConf.name === 'default')) && mappingConf.rowGroupValues) {
          for (let j = 0; j < rowGroupVals.length; j++) {
            if (rowGroupVals[j].upToIdx > initObjIdx) {
              let val = rowGroupVals[j].val
              // Apply preParser if one is specified
              if (mappingConf.rowGroupValues[rowGroupVals[j].importColHdr].preParser) {
                val = mappingConf.rowGroupValues[rowGroupVals[j].importColHdr].preParser(val)
              }
              // Parse Value
              val = Import.parseValueForObj(
                val,
                objInitFieldsTypeMap[rowGroupVals[j].initField],
                objInitFieldsOptionalMap[rowGroupVals[j].initField],
              )
              initObj[rowGroupVals[j].initField] = val
              break
            }
          }
        }
        // Create Objects
        importObjects.push(entityImportClass.newFromImportFields(initObj, addNewFromImportProps))
        // Save init Objects
        importFieldsObjs.push(initObj)
      }
    }

    this.setState({
      // activeState: events.creatingImportObjectsSuccess,
      importObjects,
      importFieldsObjs,
    })

    return [importObjects, importFieldsObjs]
  }

  colHeaderCheck = () => {
    const {
      mappingConf,
      importSheet,
    } = this.state
    const {
      entityImportClass,
    } = this.props
    // Check for missing non-default columns:
    // First get init data fields
    let importFields
    let importSheetHdrs
    try {
      importFields = entityImportClass.importFields()
      importSheetHdrs = Object.keys(importSheet.rowObjects[0])
    } catch (e) {
      console.error(`invalid entityImportClass\n${e}`)
      this.setState({
        activeState: events.mappingError,
        errors: {
          ...this.state.errors,
          [events.mappingError]: `Error Mapping Column Headers\n${e}`,
        },
      })
    }
    const missingCols = []
    if (mappingConf.headerMap) {
      for (let i = 0; i < importFields.length; i++) {
        for (let j = 0; j < importSheetHdrs.length; j++) {
          if (
            mappingConf.headerMap[importSheetHdrs[j]] &&
            (importFields[i].name === mappingConf.headerMap[importSheetHdrs[j]].initField)
          ) {
            break
          }
          if (j === (importSheetHdrs.length - 1)) {
            if (!importFields[i].optional) {
              for (const importShtHdr in mappingConf.headerMap) {
                if (mappingConf.headerMap[importShtHdr].initField === importFields[i].name) {
                  missingCols.push(importShtHdr)
                }
              }
            }
          }
        }
      }
    } else if (mappingConf.name === 'default') {
      for (let i = 0; i < importFields.length; i++) {
        for (let j = 0; j < importSheetHdrs.length; j++) {
          if (
            (importFields[i].objField &&
              (Import.cleanHeader(importFields[i].objField) === Import.cleanHeader(importSheetHdrs[j]))
            ) ||
            (Import.cleanHeader(importFields[i].name) === Import.cleanHeader(importSheetHdrs[j]))
          ) {
            break
          }
          if (j === (importSheetHdrs.length - 1)) {
            if (!importFields[i].optional) {
              missingCols.push(importFields[i].name)
            }
          }
        }
      }
    } else {
      console.error('invalid mapping configuration')
      this.setState({
        activeState: events.mappingError,
        errors: {
          ...this.state.errors,
          [events.mappingError]: 'invalid mapping configuration',
        },
      })
    }
    return missingCols
  }
}

Import.propTypes = {
  /**
   * Determines if the dialog is open
   */
  addNewFromImportProps: PropTypes.object,
  /**
   * Function which will be called when clicking on
   * manual entry button on import dialog
   */
  entityImportClass: PropTypes.any.isRequired,
  /**
   * Import Configurations
   */
  importConfigs: PropTypes.array,
  /**
   * This class will be used to instantiate the objects
   * from the import file. i.e. an object of 'initData' will
   * be passed to the newFromImportFields static function of the class
   */
  onAwayClick: PropTypes.func.isRequired,
  /**
   * Function which will be called and passed an array of
   * objects build from import
   */
  onManualEntryClick: PropTypes.func,
  /**
   * Columns which will be used by the preview
   * table column
   */
  previewTableColumns: PropTypes.array,
  /**
   * Function that will convert an entity class to
   * a POJO and in essence stripping unnecessary fields and
   * converting associations ids to usefull information.
   */
  show: PropTypes.bool.isRequired,
  submitRecords: PropTypes.func.isRequired,
}

Import.defaultProps = {
  show: false,
  importConfigs: [],
}

const StyledImport = withStyles(styles)(Import)

export default StyledImport
