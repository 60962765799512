import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/CustomButtons'
import Table from 'components/Table'
import {
  withStyles, Tabs, Tab, AppBar, TextField,
  Grid, Card, CardHeader, CardContent,
  CardActions, Typography,
} from '@material-ui/core'
import 'react-day-picker/lib/style.css'
import {
  FilterToSortBy, SortByToFilter, SortObjects, SortString,
} from 'utils/Utils'
import Comparator from 'popcorn-js/financial/currency/comparator'
import Validator from 'popcorn-js/financial/currency/validator'
import {FullPageLoader as Loader} from 'components/Loader/Loader'
import HistoryLayout from 'views/History/HistoryLayout'
import {CurrencyFields} from 'views/History/CurrencyHistory/CurrencyFields'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'

//popcorn-js
import {
  CurrencyRecordkeeper
} from 'popcorn-js//financial/currency'
const styles = () => ({
  tabContainer: {
    height: 'calc(100vh - 295px)',
    marginTop: '8px',
    overflowY: 'scroll',
  },
})

const ACTIVE_STATE_VIEWING = 'ACTIVE_STATE_VIEWING'
const ACTIVE_STATE_CREATING = 'ACTIVE_STATE_CREATING'
const ACTIVE_STATE_EDITING = 'ACTIVE_STATE_EDITING'
const ACTIVE_STATE_VIEWING_DELETE = 'ACTIVE_STATE_VIEWING_DELETE'

const CurrencyEntity = {
  camel: 'currency',
  camelP: 'currencies',
  nice: 'currency',
  niceP: 'currencies',
  capital: 'Currency',
  capitalP: 'Currencies',
}

const currencyListLength = 10
const currencyConfigurationListId = `${CurrencyEntity.camel}ConfigurationList`
const newAutoFocusElementId = `${CurrencyEntity.camel}NameField`

class Currency extends React.Component {
  state = {
    activeState: ACTIVE_STATE_VIEWING,

    currencies: [],
    total: 0,
    selectedRowIndex: -1,
    previouslySelectedRowIndex: -1,
    sortBy: ['name'],

    history: [],
    selected: {},
    original: {},
    previouslySelected: {},

    isLoading: true,

    showHistory: false,

    successMessage: undefined,
    errorMessage: undefined,
    warningMessage: undefined,
    confirmationMethod: undefined,

    invalidFields: {},

    tabIndex: 0,
  }

  componentDidMount() {
    this.find()
  }

  handleTabChange = (event, tabIndex) => {
    this.setState({tabIndex: tabIndex})
  }

  handleSelection = (selected, index, activeState) => {
    if (this.state.activeState === ACTIVE_STATE_EDITING && !activeState) {
      this.showDiscardConfirmation(() => {
        this.handleHideAlert()
        this.handleSelection(selected, index, ACTIVE_STATE_VIEWING)
      })
      return
    }

    let t = activeState
    const {
      activeState: currentState,
    } = this.state
    if (!activeState) {
      switch (currentState) {
        case ACTIVE_STATE_CREATING:
        case ACTIVE_STATE_EDITING:
          t = ACTIVE_STATE_VIEWING
          break
        default:
          t = currentState
      }
    }

    this.setState({
      selected: selected,
      original: selected,
      previouslySelected: selected,
      selectedRowIndex: index,
      previouslySelectedRowIndex: index,
      activeState: t,
    })
  }

  find = async (deleted = false) => {
    this.setState({isLoading: true})
    const findRequest = {}
    findRequest.deleted = deleted
    findRequest.query = {}
    findRequest.query.sortBy = this.state.sortBy

    try {
      const findResponse = await CurrencyRecordkeeper.find(findRequest)
      // Map to currency entities
      const currencies = findResponse.records
      currencies.sort((a, b) => SortObjects(a, b, this.state.sortBy))

      // Select the first currency in the list
      const selected = findResponse.total > 0
        ? currencies[0]
        : {}
      const selectedRowIndex = findResponse.total > 0
        ? 0
        : -1

      this.handleSelection(
        selected,
        selectedRowIndex,
        deleted
          ? ACTIVE_STATE_VIEWING_DELETE
          : ACTIVE_STATE_VIEWING,
      )

      this.setState({
        total: findResponse.total,
        currencies: currencies,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleViewDelete = () => {
    this.find(true)
  }

  handleRetrieveHistory = async () => {
    this.setState({isLoading: true})
    const retrieveHistoryRequest = {}
    retrieveHistoryRequest.identifier = {id: this.state.selected.id}
    try {
      const retrieveResponse = await CurrencyRecordkeeper.retrieveHistory(
        retrieveHistoryRequest)
      const history = retrieveResponse.history
      this.setState({history, showHistory: true})
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleReturn = () => {
    this.find()
  }

  handleRestore = async () => {
    this.setState({isLoading: true})
    try {
      const restoreRequest = {}
      restoreRequest.identifier = {id: this.state.selected.id}
      await CurrencyRecordkeeper.restore(restoreRequest)
      const currencies = this.state.currencies.slice()
      const idx = currencies.findIndex(c => c.id === this.state.selected.id)
      currencies.splice(
        idx,
        1,
      )
      if (currencies.length > idx) {
        this.handleSelection(currencies[idx], idx, this.state.activeState)
      } else {
        this.handleSelection(idx === 0
          ? {}
          : currencies[idx - 1],
        idx - 1,
        this.state.activeState,
        )
      }
      this.setState({
        successMessage: `${CurrencyEntity.capital} restored`,
        currencies,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  showDeleteConfirmation = () => {
    this.setState({
      warningMessage: `You are about to permanently delete '${this.state.selected.name}'. Do you want to continue?`,
      confirmationMethod: this.handleDelete,
    })
  }

  showDiscardConfirmation(confirm) {
    this.setState({
      warningMessage: 'You have unsaved changes. Do you want to continue?',
      confirmationMethod: confirm,
    })
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    this.handleHideAlert()

    try {
      await CurrencyRecordkeeper.delete({
        identifier: {
          id: this.state.selected.id,
        },
      })
      const currencies = this.state.currencies.slice()
      const idx = currencies.findIndex(
        c => c.id === this.state.selected.id)
      currencies.splice(idx, 1)

      if (currencies.length > idx) {
        this.handleSelection(currencies[idx], idx, this.state.activeState)
      } else {
        this.handleSelection(
          idx === 0 ? {} : currencies[idx - 1],
          idx - 1, this.state.activeState)
      }

      this.setState({
        successMessage: `${CurrencyEntity.capital} deleted`,
        currencies,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }

    this.setState({isLoading: false})
  }

  handleFieldChange(field, newValue) {
    const {
      selected,
      invalidFields,
    } = this.state
    selected[field] = newValue
    invalidFields[field] = undefined

    this.setState({selected, invalidFields})
    this.handleChanges(selected)
  }

  handleChanges(selected) {
    const {
      activeState: currentState,
    } = this.state
    const {
      selectedRowIndex,
    } = this.state

    let activeState
    switch (currentState) {
      case ACTIVE_STATE_CREATING:
        activeState = ACTIVE_STATE_CREATING
        break

      case ACTIVE_STATE_VIEWING:
        activeState = selectedRowIndex >= 0
          ? !Comparator.CompareAll(this.state.original, selected)
            ? ACTIVE_STATE_EDITING
            : ACTIVE_STATE_VIEWING
          : ACTIVE_STATE_CREATING
        break

      case ACTIVE_STATE_EDITING:
        activeState = !Comparator.CompareAll(this.state.original, selected)
          ? ACTIVE_STATE_EDITING
          : ACTIVE_STATE_VIEWING
        break

      default:
    }
    this.setState({activeState})
  }

  handleNew = () => {
    const focusElement = document.getElementById(
      newAutoFocusElementId)
    if (focusElement) {
      focusElement.focus()
    }

    this.setState({
      selected: {},
      original: {},
      selectedRowIndex: -1,
      activeState: ACTIVE_STATE_CREATING,
    })
  }

  handleUpdate = async () => {
    const isValid = await this.isValid()
    if (isValid) {
      this.setState({isLoading: true})
      try {
        const updateResponse = await CurrencyRecordkeeper.update({
          identifier: {
            id: this.state.original.id,
          },
          currency: this.state.selected,
        })
        const currencies = this.state.currencies.slice() ||
          []
        const currencyToReplaceIndex = currencies.findIndex(
          c => c.id === updateResponse.currency.id
        )
        const selected = updateResponse.currency
        if (currencyToReplaceIndex >= 0) {
          currencies[currencyToReplaceIndex] = selected
        }
        currencies.sort((a, b) => SortObjects(a, b, this.state.sortBy))
        const selectedIndex = currencies.findIndex(
          c => c.id === updateResponse.currency.id)

        this.handleSelection(selected, selectedIndex, ACTIVE_STATE_VIEWING)
        this.setState({
          successMessage: `${CurrencyEntity.capital} updated`,
          currencies,
        })
        Currency.scrollToPos(currencyToReplaceIndex / currencies.length)
      } catch (e) {
        this.setState({errorMessage: e.message || e})
      }
      this.setState({isLoading: false})
    }
  }

  handleCreate = async () => {
    const isValid = await this.isValid()
    if (isValid) {
      this.setState({isLoading: true})
      try {
        const createResponse = await CurrencyRecordkeeper.create({
          currency: this.state.selected,
        })
        const newCurrency = createResponse.currency
        const currencies = this.state.currencies.slice() ||
          []
        currencies.push(newCurrency)
        currencies.sort((a, b) => SortObjects(a, b, this.state.sortBy))

        const selected = newCurrency
        const selectedRowIndex = currencies.findIndex(
          c => c.id === selected.id,
        )

        this.handleSelection(selected, selectedRowIndex, ACTIVE_STATE_VIEWING)

        this.setState({
          successMessage: `${CurrencyEntity.capital} created`,
          currencies,
        })
        Currency.scrollToPos(selectedRowIndex / currencies.length)
      } catch (e) {
        this.setState({errorMessage: e.message || e})
      }
      this.setState({isLoading: false})
    }
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    try {
      await CurrencyRecordkeeper.delete({
        identifier: {
          id: this.state.selected.id,
        },
      })
      const currencies = this.state.currencies.slice() ||
        []
      const idx = currencies.findIndex(
        c => c.id === this.state.selected.id)
      currencies.splice(idx, 1)

      if (currencies.length > idx) {
        this.handleSelection(currencies[idx], idx, this.state.activeState)
      } else {
        this.handleSelection(
          idx === 0 ? {} : currencies[idx - 1],
          idx - 1, this.state.activeState)
      }

      this.setState({
        successMessage: `${CurrencyEntity.capital} deleted`,
        currencies,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleCancelUpdate = () => {
    this.setState({
      invalidFields: {},
      selected: this.state.original,
      activeState: ACTIVE_STATE_VIEWING,
    })
  }

  handleCancelCreate = () => {
    this.setState({
      invalidFields: {},
      selected: this.state.previouslySelected,
      original: this.state.previouslySelected,
      activeState: ACTIVE_STATE_VIEWING,
      selectedRowIndex: this.state.previouslySelectedRowIndex,
    })
    Currency.scrollToPos(
      this.state.previouslySelectedRowIndex / this.state.currencies.length)
  }

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    })
  }

  render() {
    const {
      invalidFields,
      selectedRowIndex,
      selected,
      currencies,
      activeState,
    } = this.state

    const {
      classes,
    } = this.props

    return (
      <div id={`${CurrencyEntity.camel}ConfigurationRoot`}>
        <Card
          style={{
            marginBottom: '10px',
          }}>
          <CardHeader
            action={
              <div>
                <Button disabled>Import</Button>
                <Button disabled>Export</Button>
              </div>
            }
            title={
              <Typography
                gutterBottom
                variant="subtitle1">
                {activeState === ACTIVE_STATE_VIEWING_DELETE ?
                  `${CurrencyEntity.capitalP} - Delete` :
                  `${CurrencyEntity.capitalP}`}
              </Typography>
            }
          />
          <CardContent
            style={{
              padding: '0',
              margin: '0px 24px 24px 24px',
            }}
          >
            <Grid
              container
              direction={'row'}
              spacing={3}>
              <Grid
                item
                lg={4}
                md={6}
                sm={12}>
                <Table
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'name',
                    },
                  ]}
                  data={currencies}
                  defaultPageSize={currencyListLength}
                  defaultSortMethod={(a, b) => SortString(a, b)}
                  defaultSorted={SortByToFilter(this.state.sortBy)}
                  getTbodyProps={() => {
                    return {
                      style: {
                        overflowY: 'scroll',
                        height: '368px',
                        scrollBehavior: 'smooth',
                      },
                      id: currencyConfigurationListId,
                    }
                  }}
                  getTdProps={(state, rowInfo) => {
                    const rowIndex = rowInfo ? rowInfo.index : -1

                    return {
                      onClick: (e, handleOriginal) => {
                        if (rowInfo) {
                          this.handleSelection(
                            rowInfo.original,
                            rowIndex, this.state.activeState)
                        }
                        if (handleOriginal) {
                          handleOriginal()
                        }
                      },
                      style: {
                        background: rowIndex === selectedRowIndex ?
                          this.props.theme.palette.secondary.light :
                          'white',
                        color: rowIndex === selectedRowIndex ?
                          this.props.theme.palette.secondary.contrastText :
                          'black',
                      },
                    }
                  }}
                  id={`${CurrencyEntity.camel}Table`}
                  onSortedChange={(filter) => this.setState(
                    {sortBy: FilterToSortBy(filter)})}
                  pageSize={Math.max(currencyListLength,
                    (currencies || []).length)}
                  showPagination={false}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={6}
                sm={12}>
                <AppBar
                  id="configTabBar"
                  position={'static'}
                >
                  <Tabs
                    onChange={this.handleTabChange}
                    value={this.state.tabIndex}>
                    <Tab
                      id="currencyMainTab"
                      label="Main"
                    />
                  </Tabs>
                </AppBar>
                {this.state.tabIndex === 0 &&
                <Grid
                  className={classes.tabContainer}
                  container
                  direction={'column'}>
                  <TextField
                    InputLabelProps={{
                      shrink: Boolean(selected.name),
                    }}
                    disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                    error={!!invalidFields.name}
                    helperText={invalidFields.name ?
                      `*${invalidFields.name}` :
                      ' '}
                    id="currencyNameField"
                    label="Name"
                    margin="normal"
                    onChange={event => this.handleFieldChange('name',
                      event.target.value)}
                    value={selected.name}
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: Boolean(selected.isoCode),
                    }}
                    disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                    error={!!invalidFields.isoCode}
                    helperText={invalidFields.isoCode ?
                      `*${invalidFields.isoCode}` :
                      ' '}
                    id="currencyISOCodeField"
                    label="ISO Code"
                    margin="normal"
                    onChange={event => this.handleFieldChange('isoCode',
                      event.target.value)}
                    value={selected.isoCode}
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: Boolean(selected.symbol),
                    }}
                    disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                    error={!!invalidFields.symbol}
                    helperText={invalidFields.symbol ?
                      `*${invalidFields.symbol}` :
                      ' '}
                    id="currencySymbolField"
                    label="Symbol"
                    margin="normal"
                    onChange={event => this.handleFieldChange('symbol',
                      event.target.value)}
                    value={selected.symbol}
                  />
                </Grid>
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {this.renderControls()}
        {this.renderDialogs()}
      </div>
    )
  }

  renderControls() {
    const {
      selectedRowIndex,
      activeState,
      isLoading,
    } = this.state
    return (
      <CardActions
        style={{
          display: 'block',
        }}
      >
        {activeState === ACTIVE_STATE_VIEWING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyEntity.camel}NewButton`}
          onClick={this.handleNew}
          size="medium"
        >
          New
        </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING && selectedRowIndex >= 0 &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyEntity.camel}DeleteButton`}
          onClick={this.handleDelete}
          size="medium"
        >
          Delete
        </Button>
        }
        {activeState === ACTIVE_STATE_EDITING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyEntity.camel}SaveButton`}
          onClick={this.handleUpdate}
          size="medium"
        >
          Save Changes
        </Button>
        }
        {activeState === ACTIVE_STATE_EDITING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyEntity.camel}CancelUpdateButton`}
          onClick={this.handleCancelUpdate}
          size="medium"
        >
          Cancel
        </Button>
        }
        {activeState === ACTIVE_STATE_CREATING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyEntity.camel}CreateButton`}
          onClick={this.handleCreate}
          size="medium"
        >
          Save New
        </Button>
        }
        {activeState === ACTIVE_STATE_CREATING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyEntity.camel}CancelCreateButton`}
          onClick={this.handleCancelCreate}
          size="medium"
        >
          Cancel
        </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING_DELETE && selectedRowIndex >=
        0 &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyEntity.camel}RestoreButton`}
          onClick={this.handleRestore}
          size="medium"
        >
          Restore
        </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING_DELETE && selectedRowIndex >=
        0 &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${CurrencyEntity.camel}DeleteButton`}
          onClick={this.showDeleteConfirmation}
          size="medium"
        >
          Delete
        </Button>
        }
        <span
          style={{
            float: 'right',
          }}
        >
          {activeState === ACTIVE_STATE_VIEWING && selectedRowIndex >= 0 &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${CurrencyEntity.camel}RetrieveHistoryButton`}
            onClick={this.handleRetrieveHistory}
            size="medium"
          >
            Show History
          </Button>
          }
          {activeState === ACTIVE_STATE_VIEWING &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${CurrencyEntity.camel}ViewDeleteButton`}
            onClick={this.handleViewDelete}
            size="medium"
          >
            View Delete
          </Button>
          }
          {activeState === ACTIVE_STATE_VIEWING_DELETE &&
          <Button
            color="primary"
            disabled={this.state.isLoading}
            id={`${CurrencyEntity.camel}ReturnFromDeleteButton`}
            onClick={this.handleReturn}
            size="medium"
          >
            Return
          </Button>
          }
        </span>

      </CardActions>
    )
  }

  renderDialogs() {
    const {
      isLoading,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      selected,
      showHistory,
      history,
    } = this.state

    return (
      <React.Fragment>
        <Loader
          color={this.props.theme.palette.primary.main}
          isLoading={isLoading}
        />
        {showHistory &&
        <HistoryLayout
          entity={selected}
          entityFields={CurrencyFields}
          entityHistory={history}
          entityName={CurrencyEntity.capital}
          loading={isLoading}
          onHide={() => this.setState({showHistory: false})}
          open
        />}
        <NotificationSweetAlert
          customClass={`${CurrencyEntity.camel}ConfigAlert`}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}

          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </React.Fragment>
    )
  }

  isValid = async () => {
    const {
      selected,
    } = this.state

    try {
      await Validator.ValidateData(selected)
    } catch (invalidFields) {
      if (invalidFields) {
        this.setState({invalidFields})
        return false
      }
    }
    return true
  }

  static scrollToPos(pos) {
    const objDiv = document.getElementById(currencyConfigurationListId)
    if (objDiv) {
      objDiv.scrollTop = pos * objDiv.scrollHeight
    }
  }
}

Currency.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
}

const StyledCurrency = withStyles(styles, {withTheme: true})(Currency)

export default StyledCurrency
