import {createStyles, makeStyles, Typography, useTheme} from '@material-ui/core'
import React, {useState} from 'react'
import Card from './Card'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {FormatNumber} from '../../utils/TradeUtilities';
import {usePerfectScrollbar} from 'hooks';
import LightTooltip from '../../components/LightTooltp/LightTooltip';
import {MdHelp} from 'react-icons/md';

const useStyles = makeStyles((theme) =>
  createStyles({
    rootCoverageRatio: {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '8px',
      marginTop: theme.spacing(2),
      marginBottom: 0,
    },
    tableCellHead: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
      fontSize: '14px',
      lineHeight: '20px',
      position: 'sticky',
      top: 0,
      fontWeight: 'bold',
      border: '0px',
      whiteSpace: 'nowrap',
      zIndex: 1,
      borderBottom: `1px solid #4C497D`
    },
    tableWrapper: {
      maxHeight: '359px',
      overflow: 'hidden',
    },
    helpIconWrapper: {
      width: '14px',
      height: '14px',
      color: theme.palette.text.body,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.text.primary,
      },
      marginLeft: '4px',
      alignSelf: 'center',
    },
    helpIcon: {
      width: '14px',
      height: '14px',
    },
    hidden: {
      visibility: "hidden"
    }
  }),
)

function Row(props) {
  const {entry, idx, classes} = props
  const [open, setOpen] = useState(false)
  if (entry.exposure === 0 && entry.hedgeAmount === 0) {
    return <div/>
  } else {
    return (
      <React.Fragment>
        <TableRow key={idx}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              disabled={(entry.monthTotals || []).length === 0}
              onClick={() => setOpen(!open)}
              size="small">
              {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
            {entry.currency}
          </TableCell>
          <TableCell>{entry.currencySign} {FormatNumber(entry.exposure, true, true, 2)}</TableCell>
          <TableCell>{entry.currencySign} {FormatNumber(entry.hedgeAmount, true, true, 2)}</TableCell>
          <TableCell>{FormatNumber(entry.hedgePercentage, true, true, 2)} %</TableCell>
          <TableCell>{FormatNumber(entry.avgCostingRate, true, true, 4)}</TableCell>
        </TableRow>
        <TableRow key={idx + 'expand'}>
          <TableCell
            colSpan={5}
            style={{
              padding: 0,
            }}
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit>
              <Table>
                <TableHead style={{visibility: 'collapse'}}>
                  <TableRow>
                    <TableCell
                      classes={{head: classes.tableCellHead}}
                    >
                      Curr
                    </TableCell>
                    <TableCell
                      classes={{head: classes.tableCellHead}}
                    >
                      Exposure
                    </TableCell>
                    <TableCell
                      classes={{head: classes.tableCellHead}}
                    >
                      HedgeAmount
                    </TableCell>
                    <TableCell
                      classes={{head: classes.tableCellHead}}
                    >
                      Hedge%
                    </TableCell>
                    <TableCell
                      classes={{head: classes.tableCellHead}}
                    >
                      A/CostRate
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(entry.monthTotals || []).map(
                    (monthEntry, monthIdx) => {
                      return (
                        <TableRow key={monthIdx}>
                          <TableCell>
                            <IconButton
                              className={classes.hidden}
                              aria-label="expand row"
                              disabled={true}
                              size="small">
                              <KeyboardArrowDownIcon/>
                            </IconButton>
                            {monthEntry.month}
                          </TableCell>
                          <TableCell>
                            {entry.currencySign} {FormatNumber(monthEntry.exposure, true, true, 2)}
                          </TableCell>
                          <TableCell>
                            {entry.currencySign} {FormatNumber(monthEntry.hedgeAmount, true, true, 2)}
                          </TableCell>
                          <TableCell>
                            {FormatNumber(monthEntry.hedgePercentage, true, true, 2)} %
                          </TableCell>
                          <TableCell>
                            {FormatNumber(monthEntry.avgCostingRate, true, true, 4)}
                          </TableCell>
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }
}

const CoverageRatio = (props) => {
  const {
    coverageRatioDataImport,
    coverageRatioDataExport,
    importFlag,
    exportFlag,
  } = props
  const classes = useStyles(useTheme())
  const setScrollBarElementRef = usePerfectScrollbar(false, false)
  return (
    <Card
      additionalActions={[]}
      rootClass={classes.rootCoverageRatio}
      title={<Typography
        variant={'h5'}>Hedging & Exposure</Typography>}
    >
      <div
        className={classes.tableWrapper}
        ref={setScrollBarElementRef}
        style={{
          height: 321
        }}>
        {importFlag && <div
          style={{
            textAlign: 'left',
            font: 'Bold 12px/20px Roboto',
            letterSpacing: '0px',
            color: '#F89533',
            opacity: 1,
            padding: '16px',
            paddingBottom: '0px',
          }}>IMPORTS
        </div>}
        {importFlag && <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell
                classes={{head: classes.tableCellHead}}
              >
                Currency
              </TableCell>
              <TableCell
                classes={{head: classes.tableCellHead}}
              >
                Exposure
              </TableCell>
              <TableCell
                classes={{head: classes.tableCellHead}}
              >
                Hedge Amount
              </TableCell>
              <TableCell
                classes={{head: classes.tableCellHead}}
              >
                Hedge %
              </TableCell>
              <TableCell
                classes={{head: classes.tableCellHead}}
              >
                A/C/Rate
                <LightTooltip
                  placement={'top'}
                  title={'Weighted Average Costing Rate'}
                >
                  <span className={classes.helpIconWrapper}>
                    <MdHelp className={classes.helpIcon}/>
                  </span>
                </LightTooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(coverageRatioDataImport || []).map(
              (entry, idx) => (
                <Row
                  classes={classes}
                  entry={entry}
                  idx={idx}
                  key={idx}/>
              ))}
          </TableBody>
        </Table>}
        {exportFlag && <div
          style={{
            textAlign: 'left',
            font: 'Bold 12px/20px Roboto',
            letterSpacing: '0px',
            color: '#39CBAE',
            opacity: 1,
            padding: '16px',
            paddingBottom: '0px',
          }}>EXPORTS
        </div>}
        {exportFlag && <Table aria-label="exports">
          <TableHead>
            <TableRow>
              <TableCell
                classes={{head: classes.tableCellHead}}
              >
                Currency
              </TableCell>
              <TableCell
                classes={{head: classes.tableCellHead}}
              >
                Exposure
              </TableCell>
              <TableCell
                classes={{head: classes.tableCellHead}}
              >
                Hedge Amount
              </TableCell>
              <TableCell
                classes={{head: classes.tableCellHead}}
              >
                Hedge %
              </TableCell>
              <TableCell
                classes={{head: classes.tableCellHead}}
              >
                A/C/Rate
                <LightTooltip
                  placement={'top'}
                  title={'Weighted Average Costing Rate'}
                >
                  <span className={classes.helpIconWrapper}>
                    <MdHelp className={classes.helpIcon}/>
                  </span>
                </LightTooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(coverageRatioDataExport || []).map(
              (entry, idx) => (
                <Row
                  classes={classes}
                  entry={entry}
                  idx={idx}
                  key={idx}/>
              ))}
          </TableBody>
        </Table>}
      </div>
      <div
        style={{
          textAlign: 'right',
          padding: '14px',
          paddingRight: '32px',
          height: '52px',
          color: '#9FA7DF',
          borderTop: `1px solid #4C497D`
        }}>
        Scroll down to see more within card
      </div>
    </Card>
  )
}

export default CoverageRatio

