import {ContactDetails} from 'popcorn-js/legalEntity/party/index'
import BankingDetails from 'popcorn-js/legalEntity/party/BankingDetails'
import {Entry as AuditEntry} from 'popcorn-js/audit'
import {isObject} from 'utils/Utils'

export default class Company {

  /**
   * @type {string}
   */
  id = ''

  /**
   * @type {string}
   */
  partyCode = ''

  /**
   * @type {string}
   */
  parentPartyCode = ''

  /**
   * @type {boolean}
   */
  enable = false

  /**
   * @type {string}
   */
  name = ''

  /**
   * @type {string}
   */
  adminEmail = ''

  /**
   * @type {number}
   */
  registrationNumber = 0

  /**
   * @type {string}
   */
  natureOfBusiness = ''

  /**
   * @type {string}
   */
  countryOfOrigin = ''

  /**
   * @type {string}
   */
  logo = ''

  /**
   * @type {ContactDetails}
   */
  contactDetails = new ContactDetails()

  /**
   * @type {number}
   */
  incomeTaxNumber = 0

  /**
   * @type {number}
   */
  valueAddedTaxNumber = 0

  /**
   * @type {string}
   */
  customsClientNumber = ''

  /**
   * @type {string}
   */
  sourceOfFunds = ''

  // TODO: Add legal entities for the 3 below and add them
  //DirectorNames             []string `json:"directorNames"`
  //ShareholderNames          []string `json:"shareholderNames"`
  //AuthorisedRepresentatives []string `json:"authorisedRepresentatives"`

  /**
   * @type {string}
   */
  authorisedSignatory = ''

  /**
   * @type {BankingDetails}
   */
  bankingDetails = new BankingDetails()

  /**
   * @type {string}
   */
  clientTierId = ''

  /**
   * @type {string[]}
   */
  currencyPairIds = []

  /**
   * @type {string[]}
   */
  subsidiaries = []

  auditEntry = {}

  /**
   * @type {string}
   */
  defaultCurrencyId = ''

  /**
   * @type {string}
   */
  localCurrencyId  = ''

  /**
   * @type {string}
   */
  financialYearEnd = ''

  /**
   * @type {string}
   */
  hedgingPolicy = ''

  /**
   * @type {boolean}
   */
  import = false

  /**
   * @type {boolean}
   */
  export = false

  static camel = 'company'
  static nice = 'company'
  static niceP = 'Companies'
  static capital = 'Company'
  static capitalP = 'Companies'

  /**
   * @param {object|Company} [company]
   */
  constructor(company) {
    if (company !== undefined &&
        (company instanceof Company || isObject(company))) {
      try {
        this.id = company.id
        this.partyCode = company.partyCode
        this.parentPartyCode = company.parentPartyCode
        this.enable = company.enable
        this.name = company.name
        this.adminEmail = company.adminEmail
        this.registrationNumber = company.registrationNumber
        this.natureOfBusiness = company.natureOfBusiness
        this.countryOfOrigin = company.countryOfOrigin
        this.logo = company.logo
        this.contactDetails = new ContactDetails(company.contactDetails)
        this.incomeTaxNumber = company.incomeTaxNumber
        this.valueAddedTaxNumber = company.valueAddedTaxNumber
        this.customsClientNumber = company.customsClientNumber
        this.sourceOfFunds = company.sourceOfFunds
        this.authorisedSignatory = company.authorisedSignatory
        this.bankingDetails = new BankingDetails(company.bankingDetails)
        this.clientTierId = company.clientTierId
        this.currencyPairIds = company.currencyPairIds
        this.auditEntry = new AuditEntry(company.auditEntry)
        this.defaultCurrencyId = company.defaultCurrencyId
        this.localCurrencyId = company.localCurrencyId
        this.hedgingPolicy = company.hedgingPolicy
        this.financialYearEnd = company.financialYearEnd
        this.import = company.import
        this.export = company.export
      } catch (e) {
        console.error(`error constructing company: ${e}`, company)
        throw e
      }
    }
  }

}
