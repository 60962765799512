import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {DropTarget} from 'react-dnd'
import {withStyles,} from '@material-ui/core'
import {isArray, isFunction} from 'utils/Utils'

const styles = (theme) => ({})

const listItemHolderTarget = {

  canDrop(props) {
    return !props.children
  },

  drop(props, monitor) {
    const {
      onItemDrop,
    } = props

    if (isFunction(onItemDrop)) {
      onItemDrop(monitor.getItem())
    } else {
      console.error('given onItemDrop prop is not a function')
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}


class ListItemHolder extends Component {
  render() {
    const {
      connectDropTarget,
      isOver,
      holderHeight,
    } = this.props
    let {
      children,
    } = this.props

    if (isArray(children)) {
      if (children.length > 1) {
        console.error('ListItemHolder should only ever have 1 child')
        children = [children[0]]
      }
    }

    if (children) {
      return connectDropTarget(
        <div>
          {children}
        </div>
      )
    } else {
      return connectDropTarget(
        <div
          style={{
            padding: holderHeight ? holderHeight : isOver ? 13 : 4
          }}/>
      )
    }
  }
}

ListItemHolder.propTypes = {
  /**
   * props inserted by DnD lib with
   * DropTarget Wrapping
   */
  connectDropTarget: PropTypes.func.isRequired,
  holderHeight: PropTypes.number,
  /**
   * Function will be called and passed the dropped
   * listItem object when item is dropped here
   */
  isOver: PropTypes.bool.isRequired,
  /**
   * Value used to set the height of the holder
   */
  onItemDrop: PropTypes.func.isRequired,
}

const StyledListItemHolder = withStyles(styles)(
  DropTarget(
    'listItem',
    listItemHolderTarget,
    collect,
  )(ListItemHolder))

export default StyledListItemHolder
