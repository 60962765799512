import React, {useEffect, useState} from 'react'
// javascript plugin used to create scrollbars on windows
import {useLocation} from 'react-router-dom'
import cx from 'classnames'
// @material-ui/core components
import {Drawer, withStyles} from '@material-ui/core'
// core components
import styles from './styles.jsx'
import logo from 'assets/img/stellcap-logo.svg'
import miniLogo from 'assets/img/stellcap-logo-mini.svg'
import PerfectScrollbar from 'perfect-scrollbar'
import PropTypes from 'prop-types'
import Links from './Links'

let ps

function Sidebar(props) {
  const {
    classes,
    routes,
    viewPermissions,
    securityClaims,
    handleDrawerToggle,
    bgColor,
  } = props

  const location = useLocation()

  const sidebarWrapperRef = React.useRef()

  const activeRoute = (location, routeName) => {
    return location.pathname === routeName
  }

  const [miniActive, setMiniActive] = useState(false)
  const [linkStates, setLinkStates] = useState({
    openAvatar: false,
    openLegalEntities: activeRoute(location, '/legalEntities'),
    openForms: activeRoute(location, '/forms'),
    openTables: activeRoute(location, '/tables'),
    openMaps: activeRoute(location, '/maps'),
    openPages: activeRoute(location, '-page'),
  })

  useEffect(() => {
    if (sidebarWrapperRef && sidebarWrapperRef.current) {
      if (navigator.platform.indexOf('Win') > -1) {
        ps = new PerfectScrollbar(
          sidebarWrapperRef.current, {
            suppressScrollX: true,
            suppressScrollY: false,
          })
      }
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
      }
    }
  }, [])

  const openCollapse = (collapse) => {
    linkStates[collapse] = !linkStates[collapse]
    setLinkStates(linkStates)
  }

  useEffect(() => {
    setMiniActive(props.miniActive)
  }, [props.miniActive])

  let brand = (
    <div className={classes.logoContainer}>
      <div className={classes.logoImageContainer}>
        {
          miniActive ?
            <img
              alt="logo"
              className={classes.logoMini}
              src={miniLogo}
            /> :
            <img
              alt="logo"
              className={classes.logoNormal}
              src={logo}
            />
        }
      </div>
    </div>
  )

  const drawerPaper = cx(classes.drawerPaper, {
    [classes.drawerPaperMini]: miniActive && miniActive,
  })

  const sidebarWrapper = cx(classes.sidebarWrapper, {
    [classes.drawerPaperMini]: miniActive && miniActive,
    [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
  })

  return (
    <div id="sidebarRoot">
      <Drawer
        anchor={'left'}
        classes={{
          paper: cx(drawerPaper, classes[bgColor + 'Background']),
        }}
        onClose={handleDrawerToggle}
        onMouseOut={() => setMiniActive(props.miniActive)}
        onMouseOver={() => setMiniActive(false)}
        open={props.miniActive}
        variant="permanent"
      >
        {brand}
        <div
          className={sidebarWrapper}
          id="sidebarLinksRoot"
          ref={sidebarWrapperRef}
        >
          <Links
            classes={classes}
            linkStates={linkStates}
            location={location}
            miniActive={miniActive}
            openCollapse={openCollapse}
            routes={routes}
            securityClaims={securityClaims}
            viewPermissions={viewPermissions}
          />
        </div>
      </Drawer>
    </div>
  )
}

Sidebar.propTypes = {
  bgColor: PropTypes.string.isRequired,
  classes: PropTypes.object,
  handleDrawerToggle: PropTypes.func.isRequired,
  miniActive: PropTypes.bool.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  securityClaims: PropTypes.object.isRequired,
  viewPermissions: PropTypes.arrayOf(PropTypes.string),
}

export default withStyles(styles)(Sidebar)
