export default theme => {
  return {
    primaryContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    logoContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    spinnerContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      border: '2px solid #BDAE32',
      borderRadius: '50%',
    },
    logo: {
      width: '30px',
      height: '36px',
    },
    spinner: {
      width: '64px',
      height: '64px',
      margin: '0 auto'
    },
  }
}
