import { isObject } from 'utils/Utils';

export default class Order {
  /**
   * @type {string}
   */
  id = '';
  /**
   * @type {string}
   */
  number = '';

  /**
   * @type {string}
   */
  counterpartyId = '';

  /**
   * @type {number}
   */
  amountDue = 0.0;

  /**
   * @type {string}
   */
  currencyId = '';

  /**
   * @type {string}
   */
  costCurrencyId = '';

  /**
   * @type {number}
   */
  costingRate = 0.0;

  /**
   * @type {number}
   */
  captureRate = 0.0;

  /**
   * @type {number}
   */
  dueDate = 0;

  /**
   * @type {number}
   */
  shippingDate = 0;

  /**
   * @type {string}
   */
  shipmentReference = '';

  /**
   * @type {string}
   */
  notes = '';

  /**
   * @type {string}
   */
  status = '';

  /**
   * @type {string}
   */
  partyCode = '';

  auditEntry = {};

  /**
   * @type {number}
   */
  issueDate = 0;

  /**
   * @type {string}
   */
  invoiceId = '';

  /**
   * @type {string}
   */
  invoiceNumber = '';

  /**
   * @type {string}
   */
  financialYear = '';

  constructor(order) {
    if (order !== undefined && (order instanceof Order || isObject(order))) {
      try {
        this.id = order.id;
        this.number = order.number;
        this.counterpartyId = order.counterpartyId;
        this.amountDue = order.amountDue;
        this.currencyId = order.currencyId;
        this.costCurrencyId = order.costCurrencyId;
        this.costingRate = order.costingRate;
        this.captureRate = order.captureRate;
        this.dueDate = order.dueDate;
        this.shippingDate = order.shippingDate;
        this.shipmentReference = order.shipmentReference;
        this.notes = order.notes;
        this.status = order.status;
        this.partyCode = order.partyCode;
        this.auditEntry = order.auditEntry;
        this.issueDate = order.issueDate;
        this.invoiceId = order.invoiceId;
        this.invoiceNumber = order.invoiceNumber;
        this.financialYear = order.financialYear;
      } catch (e) {
        console.error(`error constructing order: ${e}`);
      }
    }
  }

  static importFields() {
    return [
      {
        name: 'number',
        type: 'string',
      },
      {
        name: 'counterparty',
        type: 'string',
        objField: 'counterparty',
      },
      {
        name: 'invoiceNumber',
        type: 'string',
        objField: 'linkedInvoice',
      },
      {
        name: 'amountDue',
        type: 'float',
      },
      {
        name: 'currencyIsoCode',
        type: 'string',
        objField: 'currency',
      },
      {
        name: 'costCurrencyIsoCode',
        type: 'string',
        objField: 'costCurrency',
      },
      {
        name: 'costingRate',
        type: 'float',
      },
      {
        name: 'captureRate',
        type: 'float',
        optional: true,
      },
      {
        name: 'dueDate',
        type: 'date',
      },
      {
        name: 'shippingDate',
        type: 'date',
      },
      {
        name: 'shipmentReference',
        type: 'string',
        optional: true,
      },
      {
        name: 'notes',
        type: 'string',
        optional: true,
        objField: 'notes',
      },
      {
        name: 'status',
        type: 'string',
        optional: true,
        objField: 'status',
      },
      {
        name: 'partyCode',
        type: 'string',
        optional: true,
      },
      {
        name: 'financialYear',
        type: 'string',
        optional: false,
      },
      {
        name: 'issueDate',
        type: 'date',
      },
    ];
  }

  static newFromImportFields(importFieldsObj, addNewFromImportProps) {
    const newOrder = new Order();

    newOrder.id = importFieldsObj.id;
    newOrder.number = importFieldsObj.number;
    newOrder.counterpartyId =
      (
        (addNewFromImportProps.counterparties || []).find(
          (b) => b.name === importFieldsObj.counterparty
        ) || {}
      ).id || importFieldsObj.counterparty;
    newOrder.counterparty = importFieldsObj.counterparty;
    newOrder.amountDue = importFieldsObj.amountDue;
    newOrder.currencyId = (
      (addNewFromImportProps.currencies || []).find(
        (c) => c.isoCode === importFieldsObj.currencyIsoCode
      ) || {}
    ).id;
    newOrder.costCurrencyId = (
      (addNewFromImportProps.currencies || []).find(
        (c) => c.isoCode === importFieldsObj.costCurrencyIsoCode
      ) || {}
    ).id;
    newOrder.costingRate = importFieldsObj.costingRate;
    newOrder.captureRate = importFieldsObj.captureRate;
    newOrder.dueDate = importFieldsObj.dueDate;
    newOrder.shippingDate = importFieldsObj.shippingDate;
    newOrder.shipmentReference = importFieldsObj.shipmentReference;
    newOrder.notes = importFieldsObj.notes;
    newOrder.status = importFieldsObj.status;
    newOrder.partyCode = importFieldsObj.partyCode;
    newOrder.auditEntry = importFieldsObj.auditEntry;
    newOrder.issueDate = importFieldsObj.issueDate;
    newOrder.financialYear = importFieldsObj.financialYear;
    newOrder.invoiceNumber = importFieldsObj.invoiceNumber;
    newOrder.invoiceId = (
      (addNewFromImportProps.invoices || []).find(
        (b) => b.number === importFieldsObj.invoiceNumber
      ) || {}
    ).id;

    return newOrder;
  }
}
