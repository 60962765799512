import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {marshalIdentifier} from '../../search/identifier/marshalIdentifier';

/**
 * CurrencyPairRecordkeeper is a convenience client for invoking the backend services
 */
export default class Recordkeeper {
  /**
   * @param {CurrencyPair} currencyPair
   */
  static create(currencyPair) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'CurrencyPair-Recordkeeper.Create',
      request: {
        currencyPair,
      },
    })
  }

  /**
   * @param {CurrencyPair} currencyPair
   * @param identifier
   */
  static update(currencyPair, identifier) {
    const serializedIdentifier = marshalIdentifier(identifier)

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'CurrencyPair-Recordkeeper.Update',
      request: {
        currencyPair,
        identifier: serializedIdentifier,
      },
    })
  }

  /**
   * @param criteria: Filter criteria
   * @param query: Query options
   * @param {boolean} [deleted]
   */
  static find(criteria, query, deleted) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'CurrencyPair-Recordkeeper.Find',
      request: {
        criteria,
        query,
        deleted,
      },
    })
  }

  /**
   * @param identifier
   */
  static delete(identifier) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'CurrencyPair-Recordkeeper.Delete',
      request: {
        identifier: identifier.marshall(),
      }
    })
  }

  /**
   * @param identifier
   */
  static restore(identifier) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'CurrencyPair-Recordkeeper.Restore',
      request: {
        identifier: identifier.marshall(),
      }
    })
  }

  /**
   *
   * @param identifier
   */
  static retrieve(identifier) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'CurrencyPair-Recordkeeper.Retrieve',
      request: {
        identifier: identifier.marshall(),
      }
    })
  }

  /**
   * @param identifier
   */
  static deleteForever(identifier) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'CurrencyPair-Recordkeeper.DeleteForever',
      request: {
        identifier: identifier.marshall(),
      }
    })
  }

  /**
   * @param identifier
   */
  static retrieveHistory(identifier) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'CurrencyPair-Recordkeeper.RetrieveHistory',
      request: {
        identifier: identifier.marshall(),
      }
    })
  }
}
