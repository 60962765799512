import {createStyles, makeStyles, Typography, useTheme} from '@material-ui/core';
import React, {useState} from 'react';
import Card from './Card';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import {Bar, BarChart, CartesianGrid, Legend, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts'
import AppBar from '@material-ui/core/AppBar';

const useStyles = makeStyles((theme) =>
  createStyles({
    rootMaturity: {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '8px',
      marginTop: theme.spacing(2),
      marginBottom: 0,
    },

  }),
)
const MaturityLadder = (props) => {
  const {
    maturityLadderData,
  } = props
  const classes = useStyles(useTheme())


  const TickFormatter = (tick) => {
    return (tick / 1000).toFixed(0)
  }

  function populateLegend(graphKeys) {
    const legendPayload = []
    for (const entry of graphKeys) {
      if (!legendPayload.find(({value}) => value === entry.legendName)) {
        legendPayload.push({value: entry.legendName, color: entry.color})
      }
    }
    return legendPayload
  }

  const colorArray = ['#13AEF3', '#F2C713', '#B170F2', '#DD6853']
  const [importFlag, setImportFlag] = useState(false)
  const [exportFlag, setExportFlag] = useState(false)

  function transformLadderViewData(data) {

    const ladderKeys = []
    let i = 0
    for (const entry of data) {
      if (entry.date.length === 10) {
        entry.date = entry.date.substring(5)
      }
      for (const key of Object.keys(entry.currencyTotal || {})) {

        const n = key.indexOf('/')
        const name = key.substring(0, n !== -1 ? n : key.length)
        const ImportExport = key.slice(-1);
        const newKey = {
          key: 'currencyTotal.' + key,
          color: colorArray[i],
          legendName: name,
        }
        if ((ImportExport === 'I') && !importFlag) {
          setImportFlag(true)
        }
        if ((ImportExport === 'E') && !exportFlag) {
          setExportFlag(true)
        }
        if (ladderKeys.length !== 0) {
          let found = false
          let foundName = false
          for (let j = 0; j < ladderKeys.length; j++) {
            if (ladderKeys[j].key === newKey.key) {
              found = true
              break
            }
            if (ladderKeys[j].legendName === newKey.legendName) {
              newKey.color = ladderKeys[j].color
              foundName = true
              break
            }
          }
          if (!found) {
            ladderKeys.push(newKey)
            if (!foundName) {
              i++
            }
          }
        } else {
          ladderKeys.push(newKey)
          i++
        }
      }
    }
    return {ladderKeys}
  }

  let graphData = []
  graphData.ladderKeys = []
  if (maturityLadderData) {
    graphData = transformLadderViewData(maturityLadderData)
  }

  return (
    <Card
      additionalActions={[]}
      rootClass={classes.rootMaturity}
      title={<Typography
        variant={'h5'}>Maturity Ladder</Typography>}
    >
      <ResponsiveContainer
        height={373}
        width="95%">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `auto 1fr`,
          }}
        >
          <div>
            <div style={{
              display: 'grid',
              gridTemplateRows: `${importFlag ? '85px' : ''} 75px 70px  ${exportFlag ? '60px' : ''}`,
              alignItems: 'self-end',
              width: '35px'
            }}>
              {importFlag && <div style={{
                transform: 'rotate(-90deg)',
                fontSize: '12px',
                letterSpacing: '0px',
                color: '#F89533',
                opacity: 1,
              }}>IMPORTS
              </div>}
              <div style={{
                transform: 'rotate(-90deg)',
                fontSize: '12px',
                letterSpacing: '0px',
              }}
              >(Thousands)
              </div>
              <div style={{
                transform: 'rotate(-90deg)',
                fontSize: '12px',
                letterSpacing: '0px',
              }}
              >Amount Due
              </div>
              {exportFlag && <div style={{
                transform: 'rotate(-90deg)',
                fontSize: '12px',
                letterSpacing: '0px',
                color: '#39CBAE',
                opacity: 1,
              }}>EXPORTS
              </div>}
            </div>
          </div>
          <div>
            <BarChart
              data={maturityLadderData}
              height={328}
              margin={{
                top: 32, right: 32, left: 0, bottom: 24,
              }}
              width={335}
              stackOffset={'sign'}
            >
              <XAxis
                orientation="top"
                axisLine={false}
                dataKey="date"
                interval={0}
                tickMargin={10}
                textAnchor="start"
                tick={{
                  angle: -45,
                  fill: '#FFFFFF61'
                }}
              />
              <YAxis
                allowDecimals
                axisLine={false}
                interval={0}
                tickFormatter={TickFormatter}
                tickLine={false}
                tick={{ fill: '#FFFFFF61' }}
              >
              </YAxis>
              <CartesianGrid
                stroke={'#64659A'}
                vertical={false}/>
              <Tooltip
                cursor={{fill: '#1E2036'}}
                content={<div/>}
              />
              {(graphData.ladderKeys || []).map(k => {
                return (
                  <Bar
                    dataKey={k.key}
                    fill={k.color}
                    key={k.key}
                    stackId={k.legendName}
                  />
                )
              })}
              <ReferenceLine
                stroke={'#C6C6C6'}
                y={0}
              />
            </BarChart>
            <div>
              <BarChart
                height={50}
                width={populateLegend(graphData.ladderKeys).length*75}
                margin={{top: 0, right: 8, bottom: 16, left: 0}}
              >
                <Legend
                  //margin={{top: 0, right: 8, bottom: 16, left: 0}}
                  payload={populateLegend(graphData.ladderKeys)}
                  width={populateLegend(graphData.ladderKeys).length*75}
                  wrapperStyle={{
                    background: '#27254D',
                    borderRadius: '3px',
                    padding: '8px',
                  }}/>
              </BarChart>
            </div>
          </div>
        </div>
      </ResponsiveContainer>

      <AppBar
        color="default"
        position="static"/>
    </Card>
  )
}

export default MaturityLadder

