import React from 'react'
import {IconButton, Tooltip} from '@material-ui/core';

import {Add,} from '@material-ui/icons'

export const createAction = onClick => (
  <Tooltip
    key={'new'}
    title={'Create new'}>
    <IconButton onClick={onClick}>
      <Add/>
    </IconButton>
  </Tooltip>
)

