import React from 'react'
// javascript plugin used to create scrollbars on windows
import {NavLink} from 'react-router-dom'
import cx from 'classnames'
// @material-ui/core components
import {Collapse, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core'
// core components
import Icon from '@material-ui/core/Icon/Icon'
import {
  viewHedgeStation,
  viewPaymentStation,
  viewDealingStation,
  viewReportStationCashProjection,
  viewReportStationExposureProjection,
} from 'permissions/permissionTypes'
import PropTypes, {object} from 'prop-types'
import {
  viewOperationsTradesTable,
  viewOperationsTradeStation,
  viewTradesTable,
  viewTradeStation
} from 'permissions/permissionTypes';

const Links = (props) => {
  const {routes, classes, linkStates, openCollapse, viewPermissions, securityClaims, miniActive, location} = props
  const activeRoute = (location, routeName) => {
    return location.pathname === routeName
  }
  return (
    <List
      className={classes.list}
      id="sidebarListRoot">
      {routes.map((route, key) => {
        if (route.permission) {
          if (!viewPermissions.includes(route.permission) ||
          (securityClaims.context.partyCode === 'SYS' &&
            (
              route.permission === viewHedgeStation ||
              route.permission === viewPaymentStation ||
              route.permission === viewDealingStation

            )
          )) {
            return null
          }
        }
        if (route.redirect) {
          return null
        }
        if (route.name === 'Configuration') {
          return null
        }
        if ((route.name === 'Trades') && (viewPermissions.includes(viewTradesTable) && (route.permission === viewTradeStation))){
          return null
        }
        if ((route.name === 'Trades') && (!viewPermissions.includes(viewTradesTable) && (route.permission === viewTradesTable))){
          return null
        }
        if (route.collapse) {
          return (
            <ListItem
              className={classes.item}
              key={key}>
              <NavLink
                className={cx(classes.itemLink, {[classes.collapseActive]: activeRoute(location, route.path)})}
                id={route.linkID}
                onClick={() => openCollapse(route.state)}
                to={'#'}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <Icon><route.icon/></Icon>
                </ListItemIcon>
                <ListItemText
                  className={cx(classes.itemText, {
                    [classes.itemTextMini]: miniActive,
                  })}
                  disableTypography
                  primary={route.name}
                  secondary={
                    <b className={cx(classes.caret, {[classes.caretActive] :linkStates[route.state]})}/>
                  }
                />
              </NavLink>
              <Collapse
                addEndListener={() => {}}
                in={linkStates[route.state]}
                unmountOnExit
              >
                <List className={cx(classes.list, classes.collapseList)}>
                  {route.views.map((view, key) => {
                    if (view.redirect) {
                      return null
                    }
                    if ((route.name === 'Trades') && (viewPermissions.includes(viewOperationsTradesTable) && (route.permission === viewOperationsTradeStation))){
                      return null
                    }
                    if ((route.name === 'Trades') && (!viewPermissions.includes(viewOperationsTradesTable) && (route.permission === viewOperationsTradesTable))){
                      return null
                    }
                    if (view.permission) {
                      if (!viewPermissions.includes(view.permission) ||
                      (securityClaims.context.partyCode === 'SYS' &&
                        (
                          view.permission === viewReportStationExposureProjection
                          || view.permission === viewReportStationCashProjection
                        )
                      )) {
                        return null
                      }
                    }
                    return (
                      <ListItem
                        className={classes.collapseItem}
                        key={key}>
                        <NavLink
                          className={cx(classes.collapseItemLink,
                            {[classes.sideBarMenuItemsColor]: activeRoute(location, view.path)})}
                          id={view.linkID}
                          to={view.path}
                        >
                          <span className={classes.collapseItemMini}>
                            {view.mini}
                          </span>
                          <ListItemText
                            className={cx(classes.collapseItemText, {
                              [classes.collapseItemTextMini]: miniActive,
                            })}
                            disableTypography
                            primary={view.name}
                          />
                        </NavLink>
                      </ListItem>
                    )
                  })}
                </List>
              </Collapse>
            </ListItem>
          )
        }
        return (
          <ListItem
            className={classes.item}
            key={key}>
            <NavLink
              className={cx(classes.itemLink,
                {[classes.sideBarMenuItemsColor]: activeRoute(location, route.path)})}
              id={route.linkID}
              to={route.path}>
              <ListItemIcon className={classes.itemIcon}>
                <Icon><route.icon/></Icon>
              </ListItemIcon>
              <ListItemText
                className={cx(classes.itemText, {[classes.itemTextMini]: miniActive})}
                disableTypography
                primary={route.name}
              />
            </NavLink>
          </ListItem>
        )
      })}
    </List>
  )
}

Links.propTypes = {
  classes: PropTypes.object,
  linkStates: PropTypes.object,
  location: PropTypes.object,
  miniActive: PropTypes.bool,
  openCollapse: PropTypes.func,
  routes: PropTypes.arrayOf(object),
  securityClaims: PropTypes.object,
  viewPermissions: PropTypes.array,
}

export default Links
