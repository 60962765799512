import React from 'react'
import {Header, ItemEntry} from 'views/History/HistoryLayout'
import {Divider} from '@material-ui/core'

const styles = ({
  root: {
    display: 'grid',
    flexGrow: 1,
    rowGap: '2px',
    boxShadow: '',
    // paddingTop: '25px',
    zIndex: 1,
    gridTemplateRows: (() => {
      let space = ''
      for (let i = 0; i < 51; i++) {
        space = space + '32px '
      }
      return space
    })(),
  },
  divider: {
    height: '2px',
    margin: '20px 20px',
  },
  subDivider: {
    height: '1px',
    margin: '16px 40px',
  },
})

export const BrokerFields = (broker, fieldType, changedFields = []) => {
  return (
    <div>
      <main style={styles.root}>
        <Header
          fieldType={fieldType}
          label={'Broker Information'}
        />
        {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
        <ItemEntry
          changed={changedFields.includes('name')}
          fieldType={fieldType}
          id={'name'}
          label={'Name'}
          value={broker.name || ''}
        />
        <ItemEntry
          changed={changedFields.includes('partyCode')}
          fieldType={fieldType}
          id={'partyCode'}
          label={'Party Code'}
          value={broker.partyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('parentPartyCode')}
          fieldType={fieldType}
          id={'parentPartyCode'}
          label={'Parent Party Code'}
          value={broker.parentPartyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('enable')}
          fieldType={fieldType}
          id={'enable'}
          label={'Enable'}
          value={broker['enable'] || ''}
        />
        <ItemEntry
          changed={changedFields.includes('adminEmail')}
          fieldType={fieldType}
          id={'adminEmail'}
          label={'Admin Email'}
          value={broker.adminEmail || ''}
        />
        <ItemEntry
          changed={changedFields.includes('registrationNumber')}
          fieldType={fieldType}
          id={'registrationNumber'}
          label={'Registration Number'}
          value={broker.registrationNumber || ''}
        />
        <ItemEntry
          changed={changedFields.includes('clientTierId')}
          fieldType={fieldType}
          id={'clientTier'}
          label={'Client Tier'}
          value={broker.clientTierId || ''}
        />
        <ItemEntry
          changed={changedFields.includes('defaultCurrencyIsoCode')}
          fieldType={fieldType}
          id={'defaultCurrency'}
          label={'Default Currency'}
          value={broker.defaultCurrencyIsoCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('localCurrencyIsoCode')}
          fieldType={fieldType}
          id={'localCurrencyIsoCode'}
          label={'Local Currency'}
          value={broker.localCurrencyIsoCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('currencyPairIds')}
          fieldType={fieldType}
          id={'currencyPairs'}
          label={'Assigned Currency Pairs'}
          value={broker.currencyPairIds || []}
        />
        <ItemEntry
          changed={changedFields.includes('logo')}
          fieldType={fieldType}
          id={'logo'}
          label={'Logo'}
          value={broker.logo || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Contact Details'}
        />
        <Header
          fieldType={fieldType}
          label={'Physical address'}
          subheader
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.line1')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.line1'}
          label={'Address Line 1'}
          value={((broker.contactDetails || {}).physicalAddress ||
            {}).line1}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.line2')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.line2'}
          label={'Address Line 2'}
          value={((broker.contactDetails || {}).physicalAddress ||
            {}).line2}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.city')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.city'}
          label={'City'}
          value={((broker.contactDetails || {}).physicalAddress ||
            {}).city}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.province')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.province'}
          label={'Province'}
          value={((broker.contactDetails || {}).physicalAddress ||
            {}).province}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.postalCode')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.postalCode'}
          label={'Postal Code'}
          value={((broker.contactDetails || {}).physicalAddress ||
            {}).postalCode}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.country')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.country'}
          label={'Country'}
          value={((broker.contactDetails || {}).physicalAddress ||
            {}).country}
        />
        <Divider style={styles.subDivider}/>
        <Header
          fieldType={fieldType}
          label={'Postal address'}
          subheader
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.line1')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.line1'}
          label={'Address Line 1'}
          value={((broker.contactDetails || {}).postalAddress || {}).line1}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.line2')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.line2'}
          label={'Address Line 2'}
          value={((broker.contactDetails || {}).postalAddress || {}).line2}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.city')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.city'}
          label={'City'}
          value={((broker.contactDetails || {}).postalAddress || {}).city}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.province')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.province'}
          label={'Province'}
          value={((broker.contactDetails || {}).postalAddress ||
            {}).province}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.postalCode')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.postalCode'}
          label={'Postal Code'}
          value={((broker.contactDetails || {}).postalAddress ||
            {}).postalCode}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.country')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.country'}
          label={'Country'}
          value={((broker.contactDetails || {}).postalAddress ||
            {}).country}
        />
        <Divider style={styles.subDivider}/>
        <ItemEntry
          changed={changedFields.includes('contactDetails.telephoneNumber')}
          fieldType={fieldType}
          id={'contactDetails.telephoneNumber'}
          label={'Telephone Number'}
          value={(broker.contactDetails || {}).telephoneNumber || ''}
        />
        <ItemEntry
          changed={changedFields.includes('contactDetails.faxNumber')}
          fieldType={fieldType}
          id={'contactDetails.faxNumber'}
          label={'Fax Number'}
          value={(broker.contactDetails || {}).faxNumber || ''}
        />
        <ItemEntry
          changed={changedFields.includes('contactDetails.emailAddress')}
          fieldType={fieldType}
          id={'contactDetails.emailAddress'}
          label={'Email Address'}
          value={(broker.contactDetails || {}).emailAddress || ''}
        />
        <ItemEntry
          changed={changedFields.includes('contactDetails.website')}
          fieldType={fieldType}
          id={'contactDetails.website'}
          label={'Website'}
          value={(broker.contactDetails || {}).website || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Banking Details'}
        />
        <ItemEntry
          changed={changedFields.includes('bankingDetails.bankName')}
          fieldType={fieldType}
          id={'bankingDetails.bankName'}
          label={'Bank Name'}
          value={(broker.bankingDetails || {}).bankName || ''}
        />
        <ItemEntry
          changed={changedFields.includes('bankingDetails.branchName')}
          fieldType={fieldType}
          id={'bankingDetails.branchName'}
          label={'Branch Name'}
          value={(broker.bankingDetails || {}).branchName || ''}
        />
        <ItemEntry
          changed={changedFields.includes('bankingDetails.branchCode')}
          fieldType={fieldType}
          id={'bankingDetails.branchCode'}
          label={'Branch Code'}
          value={(broker.bankingDetails || {}).branchCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('bankingDetails.bankAccountType')}
          fieldType={fieldType}
          id={'bankingDetails.bankAccountType'}
          label={'Bank Account Type'}
          value={(broker.bankingDetails || {}).bankAccountType || ''}
        />
        <ItemEntry
          changed={changedFields.includes(
            'bankingDetails.bankAccountNumber')}
          fieldType={fieldType}
          id={'bankingDetails.bankAccountNumber'}
          label={'Bank Account Number'}
          value={(broker.bankingDetails || {}).bankAccountNumber || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Governance'}
        />
        <ItemEntry
          changed={changedFields.includes('authorisedSignatory')}
          fieldType={fieldType}
          id={'authorisedSignatory'}
          label={'Authorised Signatory'}
          value={broker.authorisedSignatory || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Audit Entry'}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.username')}
          fieldType={fieldType}
          id={'auditEntry.username'}
          label={'Username'}
          value={(broker.auditEntry || {}).username || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.action')}
          fieldType={fieldType}
          id={'auditEntry.action'}
          label={'Action'}
          value={(broker.auditEntry || {}).action || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.time')}
          fieldType={fieldType}
          id={'auditEntry.time'}
          label={'Time'}
          value={(broker.auditEntry || {}).time || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.version')}
          fieldType={fieldType}
          id={'auditEntry.version'}
          label={'Version'}
          value={(broker.auditEntry || {}).version}
        />
      </main>
    </div>
  )
}
