import React from 'react'
import SettlementInstructionBlotter from 'components/Blotter/SettlementInstruction/Blotter.js'
import {connect} from 'react-redux'

class BlotterContainer extends React.Component {
  render() {
    return <SettlementInstructionBlotter {...this.props} />
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currency.records,
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(BlotterContainer)
