import React, { useState, ReactElement, useContext, useEffect } from 'react'
import { CardHeader, Tooltip, IconButton, Tabs, Tab, Card, Paper, TableBody, TableHead, TableRow, TableCell, useTheme, 
  Table as MUITable, AppBar, Dialog, Toolbar, Typography } from '@material-ui/core'
import { AddIcon, ViewIcon, CloseIcon } from 'components/Icons'
import { INVOICE_STATUS_PAID, INVOICE_TYPE_PURCHASE_CREDIT_NOTE, INVOICE_TYPE_PURCHASE_INVOICE, 
  INVOICE_TYPE_SALES_CREDIT_NOTE, INVOICE_TYPE_SALES_INVOICE } from 'constants/invoice'
import { exactCriterionType, textNeCriterionType, INVOICE_OVERPAID_CRITERION } from 'popcorn-js/search/criteria/types'
import { processUnixDateForViewing } from 'utils/Utils'
import { useStyletron, styled } from 'styletron-react'
import { InvoiceAmountRequested, PrepaymentAmountRequested, SettlementInstruction } from 'popcorn-js/financial/settlementInstruction'
import { AppContext, AppContextType } from 'appContext'
import { Currency } from 'popcorn-js/financial'
import ExposureSelection from './ExposureSelection'
import { Invoice } from 'popcorn-js/financial/invoice/Invoice'
import { Prepayment } from 'popcorn-js/financial/prepayment'  
import { CustomTheme } from 'theme/custom'
import { FormatNumber } from 'utils/TradeUtilities'
import {FiX as DeselectIcon} from 'react-icons/fi'
import TextField, {parseTypes as TextFieldParseTypes} from 'components/FormContols/TextField'
import SettlementInstructionSummary from './SettlementInstructionSummary'
import SettlementInstructionHandler from 'popcorn-js/financial/settlementInstruction/handler';
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert';
import { MdDone as SubmitIcon } from 'react-icons/md';
import InvoiceDetail from 'components/Detail/invoice/InvoiceDetailDialog';
import PrepaymentDetailDialog from 'components/Detail/prepayment/PrepaymentDetailDialogContainer';

type InvoiceEntry = {
  amount: number;
  invoice: Invoice;
}

type PrepaymentEntry = {
  amount: number;
  prepayment: Prepayment;
}

const ReducedPaddingCell = styled(TableCell, {
  padding: '0px 10px 0px 10px',
})

export const EditSettlementInstruction = (props: {
  initialSettlementInstruction: SettlementInstruction;
  onClose: () => void;
  open: boolean;
  onUpdate: (SI: SettlementInstruction) => void;
  onSubmit: (SI: SettlementInstruction) => void;
}): ReactElement => {
  const {onClose, open, initialSettlementInstruction, onUpdate, onSubmit} = props

  const [activeSelectedExposureTab, setActiveSelectedExposureTab] = useState<0|1>(0)
  const [selectedExposureTableBodyHeight, setSelectedExposureTableBodyHeight] = useState<number>(0)
  const [invoiceEntries, setInvoiceEntries] = useState<InvoiceEntry[]>([] as InvoiceEntry[])
  const [prepaymentEntries, setPrepaymentEntries] = useState<PrepaymentEntry[]>([] as PrepaymentEntry[])
  const [selectedInvoiceToView, setSelectedInvoiceToView] = useState<Invoice|undefined>()
  const [showInvoiceDetailDialog, setShowInvoiceDetailDialog] = useState<boolean>(false)
  const [selectedPrepaymentToView, setSelectedPrepaymentToView] = useState<Prepayment|undefined>()
  const [showPrepaymentDetailDialog, setShowPrepaymentDetailDialog] = useState<boolean>(false)
  const [selectedSettlementInstruction, setSelectedSettlementInstruction] = useState<SettlementInstruction>(initialSettlementInstruction)
  const [successMessage, setSuccessMessage] = useState<string|undefined>()
  const [errorMessage, setErrorMessage] = useState<string|undefined>()
  const [warningMessage, setWarningMessage] = useState<string|undefined>()
  const [confirmationMethod, setConfirmationMethod] = useState<(() => void)|undefined>(undefined)

  const [css] = useStyletron()
  const appContext = useContext<AppContextType>(AppContext);
  const theme = useTheme<CustomTheme>()

  useEffect(() => {
    setSelectedSettlementInstruction(initialSettlementInstruction)
  }, [initialSettlementInstruction])

  const getSelectedExposureTableBodyHeight = (element: string | Node | null) => {
    if (element === null) {
      return
    }
    if (typeof(element) === 'string') {
      return
    }
    if (element.parentElement === null) {
      return
    }
    if (selectedExposureTableBodyHeight > 0) {
      // already set
      return
    }
    try {
      setSelectedExposureTableBodyHeight(element.parentElement.clientHeight - 155)
    } catch (e) {
      console.error('error setting table body height', e)
    }
  }
  const getTitle = (): string => {
    const currency = appContext.currencies.find((c: Currency) => selectedSettlementInstruction.currencyId === c.id)
    return `Settlement Instruction (${selectedSettlementInstruction.importExport}) - ${currency?.isoCode} - ${
      processUnixDateForViewing(selectedSettlementInstruction.date)
    }`
  }
  const getInvoiceTotalAllowedAmount = (invoice: Invoice) => {
    let totalAllowedAmount = 0
    try {
      totalAllowedAmount = invoice.amountDue - invoice.paidAmount
      for (const trdLink of invoice.tradeLinks) {
        totalAllowedAmount -= trdLink.amount
      }
    } catch (e) {
      console.error('error getting total allowed amount', e)
    }
    return totalAllowedAmount
  }
  const getPrepaymentTotalAllowedAmount = (prepayment: Prepayment) => {
    let totalAllowedAmount = 0
    try {
      totalAllowedAmount = prepayment.amountDue - prepayment.paidAmount
      for (const trdLink of prepayment.tradeLinks) {
        totalAllowedAmount -= trdLink.amount
      }
    } catch (e) {
      console.error('error getting total allowed amount', e)
    }
    return totalAllowedAmount
  }
  const newInvoiceEntry = (invoice: Invoice) => {
    return {
      invoice: invoice,
      amount: getInvoiceTotalAllowedAmount(invoice),
    }
  }
  const newPrepaymentEntry = (prepayment: Prepayment) => {
    return {
      prepayment: prepayment,
      amount: getPrepaymentTotalAllowedAmount(prepayment),
    }
  }
  const newInvoiceAmountRequested = (invoiceEntry: InvoiceEntry) => {
    return {
      invoiceId: invoiceEntry.invoice.id,
      amount: invoiceEntry.amount,
    }
  }
  const handleSelectInvoice = (invoice: Invoice) => {
    const newInvoiceEntries = [...invoiceEntries]
    newInvoiceEntries.push(newInvoiceEntry(invoice))
    // const idx = invoices.findIndex((i: Invoice) => i.id === invoice.id)
    // if (idx < 0) {
    //   setInvoices([...invoices, invoice])
    // }
    setInvoiceEntries(newInvoiceEntries)
  }
  const handleDeselectInvoice = (invoice: Invoice) => {
    setInvoiceEntries(invoiceEntries.filter((entry: InvoiceEntry) => entry.invoice.id !== invoice.id))
  }
  const handleChangeInvoiceRequestedAmount = (invoiceId: string) => (event: any) => {
    try {
      const newinvoiceEntries = [...invoiceEntries]
      const invoiceRequestedAmountIdx = newinvoiceEntries.findIndex(entry => entry.invoice.id === invoiceId)
      if (invoiceRequestedAmountIdx >= 0) {
        newinvoiceEntries[invoiceRequestedAmountIdx].amount = event.target.value
        setInvoiceEntries(newinvoiceEntries)
      }
    } catch (e) {
      console.error('error changing invoice amount', e)
    }
  }
  const handleChangePrepaymentRequestedAmount = (prepaymentId: string) => (event: any) => {
    try {
      const newPrepaymentEntries = [...prepaymentEntries]
      const prepaymentAmountsRequestedIdx = newPrepaymentEntries.findIndex(
        ppar => ppar.prepayment.id === prepaymentId,
      )
      if (prepaymentAmountsRequestedIdx >= 0) {
        newPrepaymentEntries[prepaymentAmountsRequestedIdx].amount = event.target.value
        setPrepaymentEntries(newPrepaymentEntries)
      }
    } catch (e) {
      console.error('error changing invoice amount', e)
    }
  }
  const handleSelectPrepayment = (prepayment: Prepayment) => {
    const newPrepaymentEntries = [...prepaymentEntries]
    newPrepaymentEntries.push(newPrepaymentEntry(prepayment))
    // const idx = prepayments.findIndex((p: Prepayment) => p.id === prepayment.id)
    // if (idx < 0) {
    //   setPrepayments([...prepayments, prepayment])
    // }
    setPrepaymentEntries(newPrepaymentEntries)
  }
  const handleDeselectPrepayment = (prepayment: Prepayment) => {
    setPrepaymentEntries(prepaymentEntries.filter(
      PrepaymentEntry => PrepaymentEntry.prepayment.id !== prepayment.id
    ))
  }
  const handleInvoiceRemove = (invoiceId: string) => {
    const settlementInstruction = {...selectedSettlementInstruction} || {} as SettlementInstruction

    const entryIndexToSplice = settlementInstruction.invoiceAmountsRequested?.findIndex(
      (entry: InvoiceAmountRequested) => entry.invoiceId === invoiceId
    );
    if (entryIndexToSplice !== undefined && entryIndexToSplice >= 0) {
      settlementInstruction.invoiceAmountsRequested?.splice(
        entryIndexToSplice,
        1
      );
      handleUpdate(settlementInstruction)
      setSelectedSettlementInstruction(settlementInstruction)
    } else {
      console.error(
        'Could not find invoice to deselect. Not suppose to happen'
      );
      setErrorMessage('Unexpected Error Occured. Please Contact Your Administrator')
    }
  };
  const handlePrepaymentRemove = (prepaymentId: string) => {
    const settlementInstruction = {...selectedSettlementInstruction} || {} as SettlementInstruction

    const entryIndexToSplice = settlementInstruction.prepaymentAmountsRequested?.findIndex(
      (ppar: PrepaymentAmountRequested) => ppar.prepaymentId === prepaymentId
    );
    if (entryIndexToSplice !== undefined && entryIndexToSplice >= 0) {
      settlementInstruction.prepaymentAmountsRequested?.splice(entryIndexToSplice, 1);
      setSelectedSettlementInstruction(settlementInstruction)
      handleUpdate(settlementInstruction)
    } else {
      console.error(
        'Could not find prepayment to deselect. Not suppose to happen'
      );
      setErrorMessage('Unexpected Error Occured. Please Contact Your Administrator')
    }
  };

  /* ------------------------------------------------------------------------------------
     update
     ------------------------------------------------------------------------------------ */

  const handleUpdate = async (SI: SettlementInstruction) => {
    try {
      const draftUpdateResponse = await SettlementInstructionHandler.draftUpdate(
        {
          settlementInstruction: SI,
        }
      );
      setSuccessMessage('Updated Settlement Instruction')
      setSelectedSettlementInstruction(draftUpdateResponse.settlementInstruction)
      onUpdate(draftUpdateResponse.settlementInstruction)
    } catch (e) {
      console.error('error updating settlement instruction', e);
      setErrorMessage(e.message || e)
    }
  }

  const handleSubmit = async () => {
    try {
      const submitResponse = await SettlementInstructionHandler.submit({
        id: selectedSettlementInstruction.id,
      });
      setSuccessMessage('Submitted Settlement Instruction')
      onSubmit(submitResponse.settlementInstruction);
      setTimeout(() => onClose(), 2000)
    } catch (e) {
      console.error('error submitting si', e);
      setErrorMessage(e.message || e);
    }
  }
  
  const handleCreateAmountsRequested = () => {
    // update invoice amounts requested
    const newSelectedSettlementInstruction = {...selectedSettlementInstruction}
    if (!newSelectedSettlementInstruction.invoiceAmountsRequested) {
      newSelectedSettlementInstruction.invoiceAmountsRequested = [] as InvoiceAmountRequested[]
    }
    for (const entry of invoiceEntries) {
      if (!newSelectedSettlementInstruction.invoiceAmountsRequested.find((i: InvoiceAmountRequested) => i.invoiceId === entry.invoice.id)) {
        newSelectedSettlementInstruction.invoiceAmountsRequested.push(
          newInvoiceAmountRequested(entry),
        )
      }
    }
    if (!newSelectedSettlementInstruction.prepaymentAmountsRequested) {
      newSelectedSettlementInstruction.prepaymentAmountsRequested = [] as PrepaymentAmountRequested[]
    }
    for (const entry of prepaymentEntries) {
      if (!newSelectedSettlementInstruction.prepaymentAmountsRequested.find((p: PrepaymentAmountRequested) => p.prepaymentId === entry.prepayment.id,)) {
        newSelectedSettlementInstruction.prepaymentAmountsRequested.push({
          prepaymentId: entry.prepayment.id,
          amount: entry.amount,
        })
      }
    }
    handleUpdate(newSelectedSettlementInstruction)
    setSelectedSettlementInstruction(newSelectedSettlementInstruction)
    setInvoiceEntries([] as InvoiceEntry[])
    setPrepaymentEntries([] as PrepaymentEntry[])
  }
  const handleHideAlert = () => {
    setErrorMessage(undefined)
    setSuccessMessage(undefined)
    setWarningMessage(undefined)
    setConfirmationMethod(undefined)
  };

  const renderDialogs = () => {
    return (
      <React.Fragment>
        {showInvoiceDetailDialog && selectedInvoiceToView && (
          <InvoiceDetail
            invoice={selectedInvoiceToView}
            onClose={() => setShowInvoiceDetailDialog(false)}
            readOnly
            show={showInvoiceDetailDialog}
          />
        )}
        {showPrepaymentDetailDialog && selectedPrepaymentToView && (
          <PrepaymentDetailDialog
            closeDialog={() => setShowPrepaymentDetailDialog(false)}
            prepayment={selectedPrepaymentToView}
            readOnly
            show={showPrepaymentDetailDialog}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <Dialog
      fullScreen
      onClose={onClose}
      open={open}
    >
      <AppBar className={css({position: 'relative'})}>
        <Toolbar>
          <IconButton
            aria-label="close"
            color="inherit"
            edge="start"
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography
            className={css({
              marginLeft: theme.spacing(2),
              flex: 1,
            })}
            variant="h6">
            {getTitle()}
          </Typography>
          <Tooltip
            enterDelay={400}
            title="Submit">
            <IconButton onClick={handleSubmit}>
              <SubmitIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <div
        className={css({
          height: 'calc(100vh - 90px)',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'auto 1fr',
          overflow: 'hidden',
        })}
        style={{gridRowGap: 8}}
      >
        <Card
          className={css({
            gridColumn: '1/3',
            gridRow: '1/2',
            padding: 0,
          })} />
        <div
          className={css({
            display: 'grid',
            gridTemplateColumns: '4fr 3fr',
            gridTemplateRows: '1fr 1fr',
            gridRowGap: 8,
            gridColumnGap: 8,
          })}
        >
          <ExposureSelection
            defaultFilter={[
              {
                type: exactCriterionType,
                value: {
                  field: 'currencyId',
                  text: selectedSettlementInstruction.currencyId,
                },
              },
              {
                type: textNeCriterionType,
                value: {field: 'status', text: INVOICE_STATUS_PAID},
              },
              {
                type: INVOICE_OVERPAID_CRITERION,
                value: {overPaid: false},
              },
            ]}
            defaultPrepaymentsFilter={[
              {
                type: exactCriterionType,
                value: {
                  field: 'currencyId',
                  text: selectedSettlementInstruction.currencyId,
                },
              },
              {
                type: textNeCriterionType,
                value: {field: 'status', text: 'Paid'},
              },
            ]}
            onInvoiceDeselect={handleDeselectInvoice}
            onInvoiceSelect={handleSelectInvoice}
            onPrepaymentDeselect={handleDeselectPrepayment}
            onPrepaymentSelect={handleSelectPrepayment}
            partyCode={appContext.party.partyCode}
            selectedInvoices={invoiceEntries.map(entry => entry.invoice)}
            selectedPrepayments={prepaymentEntries.map(entry => entry.prepayment)}
            selectedSI={selectedSettlementInstruction}
          />
          <Card
            className={css({
              gridColumn: '1/2',
              gridRow: '2/3',
              padding: 0,
            })}
          >
            <CardHeader
              action={
                <Tooltip
                  enterDelay={400}
                  title="Add Required Payments">
                  <IconButton
                    onClick={handleCreateAmountsRequested}
                  >
                    <AddIcon/>
                  </IconButton>
                </Tooltip>
              }
              className={css({padding: '6px 10px 5px 10px'})}
              title={
                <Tabs
                  onChange={(muiClass, tab) => setActiveSelectedExposureTab(tab)}
                  value={activeSelectedExposureTab}
                >
                  <Tab
                    label={'Invoices'}
                    value={0}/>
                  <Tab
                    label={'Prepayments'}
                    value={1}/>
                </Tabs>
              }
            />
            {(() => {
              switch (activeSelectedExposureTab) {
                case 0:
                // replace with actual code
                // return this.renderSelectedInvoices()
                  return (

                    <div
                      className={css({
                        display: 'grid',
                        gridTemplateRows: 'auto auto auto',
                        padding: '10px',})}
                      ref={getSelectedExposureTableBodyHeight}
                    >
                      <div
                        className={css({
                          height: '20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          borderBottom: `solid 1px ${theme.palette.grey[300]}`,
                        })}>
                        <div className={css({color: theme.palette.grey[400]})}>
                          {(() => {
                            switch (invoiceEntries.length) {
                              case 0:
                                return 'No Invoices Selected'
                              case 1:
                                return 'Selected 1 Invoice'
                              default:
                                return `Selected ${invoiceEntries.length} Invoice`
                            }
                          })()}
                        </div>
                        <div>
                          {(() => {
                            const total = invoiceEntries.reduce((total, entry) => {
                              switch (entry.invoice.type) {
                                case INVOICE_TYPE_PURCHASE_INVOICE:
                                case INVOICE_TYPE_SALES_INVOICE:
                                  return total + entry.amount
                                case INVOICE_TYPE_PURCHASE_CREDIT_NOTE:
                                case INVOICE_TYPE_SALES_CREDIT_NOTE:
                                  return total - entry.amount
                                default:
                                  return total
                              }
                            }, 0)
                            if (total === 0) {
                              return ''
                            }
                            return `Selected Total: ${FormatNumber(total.toString(), true, true)}`
                          })()}
                        </div>
                      </div>
                      <Paper
                        className={css({
                          display: 'grid',
                          gridTemplateRows: 'auto 1fr',})}>
                        <MUITable>
                          <TableHead>
                            <TableRow>
                              <ReducedPaddingCell/>
                              <ReducedPaddingCell>Number</ReducedPaddingCell>
                              <ReducedPaddingCell>Type</ReducedPaddingCell>
                              <ReducedPaddingCell>Direction</ReducedPaddingCell>
                              <ReducedPaddingCell>Amount</ReducedPaddingCell>
                            </TableRow>
                          </TableHead>
                        </MUITable>
                        <div style={{height: selectedExposureTableBodyHeight, overflow: 'auto'}}>
                          <MUITable>
                            <TableBody>
                              {invoiceEntries.map(
                                (entry, idx) => {
                                  const amountExceeded = Math.abs(entry.amount) > Math.abs(getInvoiceTotalAllowedAmount(entry.invoice))
                                  return (
                                    <TableRow key={idx}>
                                      <ReducedPaddingCell>
                                        <IconButton
                                          className={css({
                                            padding: '0px 2px 0px 2px',
                                            color: 'red',
                                          })}
                                          onClick={() => handleDeselectInvoice(entry.invoice)}
                                        >
                                          <DeselectIcon fontSize={'small'}/>
                                        </IconButton>
                                        <IconButton
                                          className={css({padding: '0'})}
                                          onClick={() => {
                                            setSelectedInvoiceToView(entry.invoice)
                                            setShowInvoiceDetailDialog(true)
                                          }}
                                        >
                                          <ViewIcon fontSize={'small'}/>
                                        </IconButton>
                                      </ReducedPaddingCell>
                                      <ReducedPaddingCell>
                                        {entry.invoice.number}
                                      </ReducedPaddingCell>
                                      <ReducedPaddingCell>
                                        {entry.invoice.type}
                                      </ReducedPaddingCell>
                                      <ReducedPaddingCell>
                                        {entry.invoice.direction}
                                      </ReducedPaddingCell>
                                      <ReducedPaddingCell>
                                        <TextField
                                          error={amountExceeded}
                                          helperText={amountExceeded ? 'Amount Exceeded' : undefined}
                                          onChange={handleChangeInvoiceRequestedAmount(entry.invoice.id)}
                                          parseType={TextFieldParseTypes.float}
                                          value={entry.amount}
                                        />
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                            margin: 'dense',
                                            style: {
                                              fontSize: 14,
                                              color: amountExceeded
                                                ? theme.palette.error.main
                                                : ''
                                            },
                                          }}
                                          error={amountExceeded}
                                          parseType={TextFieldParseTypes.string}
                                          value={`/${entry.invoice.amountDue}`}
                                        />
                                      </ReducedPaddingCell>
                                    </TableRow>
                                  )
                                })}
                            </TableBody>
                          </MUITable>
                        </div>
                      </Paper>
                    </div>
                  )
                case 1:
                  return (
                    <div
                      className={css({
                        display: 'grid',
                        gridTemplateRows: 'auto auto auto',
                        padding: '10px',})}
                      ref={getSelectedExposureTableBodyHeight}
                    >
                      <div
                        className={css({
                          height: '20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          borderBottom: `solid 1px ${theme.palette.grey[300]}`,})}>
                        <div className={css({color: theme.palette.grey[400]})}>
                          {(() => {
                            switch (prepaymentEntries.length) {
                              case 0:
                                return 'No Prepayments Selected'
                              case 1:
                                return 'Selected 1 Prepayment'
                              default:
                                return `Selected ${prepaymentEntries.length} Prepayments`
                            }
                          })()}
                        </div>
                        <div>
                          {(() => {
                            const total = prepaymentEntries.reduce((total, entry) => total + entry.amount, 0)
                            if (total === 0) {
                              return ''
                            }
                            return `Selected Total: ${FormatNumber(total.toString(), true, true)}`
                          })()}
                        </div>
                      </div>
                      <Paper
                        className={css({
                          display: 'grid',
                          gridTemplateRows: 'auto 1fr',})}>
                        <MUITable>
                          <TableHead>
                            <TableRow>
                              <ReducedPaddingCell/>
                              <ReducedPaddingCell>Number</ReducedPaddingCell>
                              <ReducedPaddingCell colSpan={2}>Amount</ReducedPaddingCell>
                            </TableRow>
                          </TableHead>
                        </MUITable>
                        <div style={{height: selectedExposureTableBodyHeight, overflow: 'auto'}}>
                          <MUITable>
                            <TableBody>
                              {prepaymentEntries.map(
                                (ppar, idx) => {
                                  const amountExceeded = ppar.amount > getPrepaymentTotalAllowedAmount(ppar.prepayment)
                                  return (
                                    <TableRow key={idx}>
                                      <ReducedPaddingCell>
                                        <IconButton
                                          className={css({
                                            padding: '0px 2px 0px 2px',
                                            color: 'red',
                                          })}
                                          onClick={() => handleDeselectPrepayment(
                                            ppar.prepayment,
                                          )}
                                        >
                                          <DeselectIcon fontSize={'small'}/>
                                        </IconButton>
                                        <IconButton
                                          className={css({padding: '0'})}
                                          onClick={() => {
                                            setSelectedPrepaymentToView(ppar.prepayment)
                                            setShowPrepaymentDetailDialog(true)
                                          }}
                                        >
                                          <ViewIcon fontSize={'small'}/>
                                        </IconButton>
                                      </ReducedPaddingCell>
                                      <ReducedPaddingCell>
                                        {ppar.prepayment.number}
                                      </ReducedPaddingCell>
                                      <ReducedPaddingCell>
                                        <TextField
                                          error={amountExceeded}
                                          helperText={amountExceeded ? 'Amount Exceeded' : undefined}
                                          onChange={handleChangePrepaymentRequestedAmount(ppar.prepayment.id)}
                                          parseType={TextFieldParseTypes.float}
                                          value={ppar.amount}
                                        />
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                            margin: 'dense',
                                            style: {
                                              fontSize: 14,
                                              color: amountExceeded
                                                ? theme.palette.error.main
                                                : ''
                                            },
                                          }}
                                          error={amountExceeded}
                                          parseType={TextFieldParseTypes.string}
                                          value={`/${ppar.prepayment.amountDue}`}
                                        />
                                      </ReducedPaddingCell>
                                    </TableRow>
                                  )
                                })}
                            </TableBody>
                          </MUITable>
                        </div>
                      </Paper>
                    </div>
                  )
                default:
                  return null
              }
            })()}
          </Card>
          <Card
            className={css({
              gridColumn: '2/3',
              gridRow: '1/3',
              padding: 0,})}>
            <SettlementInstructionSummary
              SI={selectedSettlementInstruction}
              onInvoiceRemove={handleInvoiceRemove}
              onPrepaymentRemove={handlePrepaymentRemove}
            />
          </Card>
        </div>
      </div>
      <NotificationSweetAlert
        customClass={'SettlementInstructionDetails'}
        errorMessage={errorMessage}
        onClose={handleHideAlert}
        onConfirm={confirmationMethod}
        successMessage={successMessage}
        warningMessage={warningMessage}
      />
      {renderDialogs()}
    </Dialog>
    
  )
}