import React, { ChangeEvent, useCallback, useState, useEffect, ReactElement } from 'react'
import { useTheme, Grid, MenuItem, FormControl } from '@material-ui/core'
import { LightDatePicker, LightTextField, LightSelect, LightInputLabel } from './styledComponents'
import { debounce } from 'lodash'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Company, ProcessingOrg } from 'popcorn-js/legalEntity/party'
import { ProcessingOrgRecordkeeper } from 'popcorn-js/legalEntity/party/processingOrg'
import { useService } from 'hooks/useService'
import Big from 'big.js'

interface CaptureInformationProps {
    party: Company;
    capturedBy: string | undefined,
    notesChanged: (value: string | undefined) => void;
    traderChanged: (value: string | undefined) => void;
    traderOrganisation: string | undefined,
    traderOrganisationChanged: (value: string | undefined) => void;
    capturedDate: string | null | undefined;
    capturedDateChanged: (value: string | null | undefined) => void;
    capturedSpotRate?: Big|undefined;
}

export const CaptureInformation = (props: CaptureInformationProps): ReactElement => {
  const {capturedBy, capturedSpotRate, party, notesChanged,
    traderChanged, traderOrganisation, traderOrganisationChanged, capturedDate, capturedDateChanged} = props

  const theme = useTheme()

  const [notes, setNotes] = useState<string|undefined>()
  const [trader, setTrader] = useState<string|undefined>()
  const [parentParty, setParentParty] = useState<ProcessingOrg|undefined>()

  const [{
    response: retrievePartyResponse,
  }] = useService(party.parentPartyCode, ProcessingOrgRecordkeeper.retrieve)

  useEffect(() => {
    if (retrievePartyResponse && retrievePartyResponse.processingOrg) {
      setParentParty(retrievePartyResponse.processingOrg)
      traderOrganisationChanged(retrievePartyResponse.processingOrg.partyCode)
    }
  }, [retrievePartyResponse, traderOrganisationChanged])

  useEffect(() => {
    setTrader(capturedBy)
  }, [capturedBy])
    
  const handleNotesChange = useCallback(debounce((value: string|undefined) => notesChanged(value), 500), [])
  const handleTraderChange = useCallback(debounce((value: string|undefined) => traderChanged(value), 500), [])

  useEffect(() => {
    handleNotesChange(notes)
  }, [notes, handleNotesChange])

  useEffect(() => {
    handleTraderChange(trader)
  }, [trader, handleTraderChange])

  return (
    <div
      style={{
        width: '270px',
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        float: 'right',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '48px',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.paper.default,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span style={{marginLeft: '32px', fontSize: '18px', fontWeight: 'bold'}}>
                        Capture Information
          </span>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          flex: '1 1 auto'
        }}
      >
        <div
          style={{
            paddingTop: '22px', 
            paddingLeft: '33px', 
            paddingRight: '32px', 
            height: '100%', 
            width: '100%', 
            backgroundColor: 
            theme.palette.primary.main,
            borderBottomLeftRadius: '8px',
          }}
        >
          <Grid 
            container 
            spacing={2}
            style={{
              width: '100%',
            }}
          >
            <Grid
              item
              style={{width: '100%'}}
            >
              <LightTextField
                disabled
                id="capturedBy"
                label="Captured By"
                value={capturedBy} />
            </Grid>
            <Grid
              item
              style={{width: '100%'}}
            >
              <LightTextField
                id={'trader'}
                label={'Trader'}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setTrader(event.target.value)}
                value={trader}
              />
            </Grid>
            <Grid
              item
              style={{width: '100%'}}
            >
              <FormControl
                style={{width: '100%'}}
              >
                <LightInputLabel>Trader Organisation</LightInputLabel>
                <LightSelect
                  id="traderOrganisation"
                  onChange={(event: ChangeEvent<{name?: string|undefined, value: unknown}>) =>
                    traderOrganisationChanged(event.target.value as string)}
                  value={traderOrganisation}
                >
                  {parentParty &&
                      <MenuItem
                        key={parentParty.partyCode}
                        value={parentParty.partyCode}
                      >
                        {parentParty.name}
                      </MenuItem>
                  }
                  <MenuItem
                    key={party.partyCode}
                    value={party.partyCode}
                  >
                    {party.name}
                  </MenuItem>
                </LightSelect>
              </FormControl>
            </Grid>
            <Grid
              item
              style={{width: '100%'}}
            >
              <LightTextField
                disabled
                id={'capturedSpotRate'}
                label={'Captured Spot Rate'}
                value={capturedSpotRate?.toFixed(4)}
              />
            </Grid>
            <Grid
              item
              style={{width: '100%'}}
            >
              <LightDatePicker
                disabled
                format={'yyyy-MM-dd'}
                id="tradeDatePicker"
                label="Date Captured"
                onChange={(date: MaterialUiPickersDate, value: string | null | undefined) => capturedDateChanged(value)}
                style={{width: '100%'}}
                value={capturedDate}
              />
            </Grid>
            <Grid
              item
              style={{width: '100%'}}
            >
              <LightTextField
                id={'notes'}
                label={'Notes'}
                multiline
                onChange={(event: ChangeEvent<HTMLInputElement>) => setNotes(event.target.value)}
                value={notes}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
