import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {CurrencyPair} from 'popcorn-js/financial/currencyPair/currencyPairTypes';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {useService} from 'hooks/useService';
import Source from 'popcorn-js/rick/source';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Rate} from 'popcorn-js/rick/pricingTypes';


const mapStateToProps = (state: any) => {
  return {
    currencyPairs: state.currencyPair.records,
  }
}

interface Props {
  currencyPairs: CurrencyPair[],
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'block',
      backgroundColor: theme.palette.primary.main,
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    table: {
      minWidth: 200,
    },
  }),
)
const tenorOptions = ['SPOT', 'ON', 'SW', '1M', '2M', '3M', '6M', '1Y', '2Y', '3Y']
const PricingView: React.FC<Props> = (props: Props) => {
  const {currencyPairs} = props
  const classes = useStyles();
  const [ccyPair, setCcyPair] = React.useState<string | undefined>();
  const [tenor, setTenor] = React.useState<string | undefined>();
  const [rate, setRate] = React.useState<Rate | undefined>();
  const [rates, setRates] = React.useState<Rate[] | undefined>();
  const [open, setOpen] = React.useState(false);
  const [openTenor, setOpenTenor] = React.useState(false);
  const [
    {
      response: findRateResponse,
      loading: findRateLoading,
    },
    setFindRateRequest
  ] = useService(null, Source.RetrieveRate)
  useEffect(() => {
    if (findRateResponse && !findRateLoading) {
      if (findRateResponse.bid !== 0) {
        setRate(findRateResponse)
      } else {
        setRate(undefined)
      }
      setRates(undefined)
    }
  }, [findRateResponse, findRateLoading])

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCcyPair(event.target.value as string);
  };
  const handleCloseTenor = () => {
    setOpenTenor(false);
  };

  const handleOpenTenor = () => {
    setOpenTenor(true);
  };
  const handleChangeTenor = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTenor(event.target.value as string);
  };
  const handleRequest = () => {
    setFindRateRequest({
      currencyPairName: ccyPair,
      tenor: tenor
    })
  };

  const [
    {
      response: findRatesResponse,
      loading: findRatesLoading,
    },
    setFindRatesRequest
  ] = useService(null, Source.RetrieveAllRate)
  useEffect(() => {
    if (findRatesResponse && findRatesResponse.rates && !findRatesLoading) {
      setRates(findRatesResponse.rates)
      setRate(undefined)
    } else if (findRatesResponse && !findRatesLoading) {
      setRates(undefined)
      setRate(undefined)
    }
  }, [findRatesResponse, findRatesLoading])

  const handleRequestAll = () => {
    setFindRatesRequest({
      currencyPairName: ccyPair,
      tenors: tenorOptions,
    })
  };
  return (<div>
    <FormControl className={classes.formControl}>
      <InputLabel id="select-label">Currency Pair</InputLabel>
      <Select
        id="open-select"
        labelId="open-select-label"
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        value={ccyPair}
      >
        {currencyPairs.map(k => {
          return (
            <MenuItem value={k.name}>{k.name}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
    <FormControl className={classes.formControl}>
      <InputLabel id="tenor-label">Tenor</InputLabel>
      <Select
        id="tenor-open-select"
        labelId="tenor-open-select-label"
        onChange={handleChangeTenor}
        onClose={handleCloseTenor}
        onOpen={handleOpenTenor}
        open={openTenor}
        value={tenor}
      >
        {tenorOptions.map(k => {
          return (
            <MenuItem value={k}>{k}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        width: 300,
        paddingBottom: '16px'
      }}
    >
      <div
        style={{display: 'flex'}}
      >
        <Button className={classes.button} onClick={handleRequest}>
          Request
        </Button>
      </div>
      <div
        style={{display: 'flex'}}
      >
        <Button className={classes.button} onClick={handleRequestAll}>
          Request All Rates
        </Button>
      </div>
    </div>
    <TableContainer
      component={Paper}
      style={{width: 300}}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tenor</TableCell>
            <TableCell>Bid</TableCell>
            <TableCell>Ask</TableCell>

          </TableRow>
        </TableHead>
        {rate &&
        <TableBody>
          <TableRow>
            <TableCell>{rate?.tenor}</TableCell>
            <TableCell>{rate?.bid}</TableCell>
            <TableCell>{rate?.ask}</TableCell>
          </TableRow>
        </TableBody>
        }
        {rates &&
        <TableBody>
          {rates.map(k => {
            return (
              <TableRow>
                <TableCell>{k.tenor}</TableCell>
                <TableCell>{k.bid}</TableCell>
                <TableCell>{k.ask}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>}
        {!rate && !rates &&
        <TableRow>No rates found</TableRow>
        }
      </Table>
    </TableContainer>
  </div>)
}

export default connect(mapStateToProps)(PricingView)