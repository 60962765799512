/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-multi-comp */
import React, { Fragment, useEffect, useState, ReactElement } from 'react'
import { Dialog, IconButton, useTheme, Toolbar, Backdrop, CircularProgress, Snackbar, 
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { LightTextField, ActionButton, SecondaryActionButton, SplitSubTitle, 
  SplitRow, SplitLabel, SubTitle, HeaderText, CellText, Alert } from './styledComponents'
import { TradeParent, TradeType, TradeDirection, Trade } from 'popcorn-js/trade/tradeTypes'
import { Currency } from 'popcorn-js/financial/currency/currencyType'
import { CurrencyPair } from 'popcorn-js/financial/currencyPair/currencyPairTypes'
import Big from 'big.js'
import { ProcessingBank, Company } from 'popcorn-js/legalEntity/party'
import { useService } from 'hooks/useService'
import Handler from 'popcorn-js/trade/handler';
import Calculator from 'popcorn-js/trade/calculator';
import { displayAmount, displayDate, createDrawdownTrade, createExtensionTrade } from './util';

interface BuySellTitleProps {
  direction: TradeDirection;
  buyCurrency: Currency|undefined;
  sellCurrency: Currency|undefined;
  tradeType: TradeType;
}
const BuySellTitle = (props: BuySellTitleProps): ReactElement => {
  const {direction, buyCurrency, sellCurrency, tradeType} = props

  const theme = useTheme()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fxColor = direction === TradeDirection.BUY ? (theme as any).palette.import.main : (theme as any).palette.export.main

  if (tradeType === TradeType.DRAWDOWN) {
    return (
      <SplitSubTitle 
        leftComponent={
          <Fragment>
            <span
              style={{
                fontSize: '14px', 
                fontFamily: 'Roboto', 
                fontWeight: 'bold', 
                color: direction === TradeDirection.BUY ? fxColor : (theme as any).palette.local,
              }}>
              {`Buying ${buyCurrency?.isoCode}`}
            </span>
            <span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>
                          &nbsp;/&nbsp;
            </span>
            <span 
              style={{
                fontSize: '14px', 
                fontFamily: 'Roboto', 
                fontWeight: 'bold', 
                color: direction === TradeDirection.SELL ? fxColor : (theme as any).palette.local,
              }}>
              {`Selling ${sellCurrency?.isoCode}`}
            </span>
          </Fragment>
        }
        rightComponent={
          <Fragment>
            <span 
              style={{
                fontSize: '14px', 
                fontFamily: 'Roboto', 
                fontWeight: 'bold', 
                color: direction === TradeDirection.BUY ? (theme as any).palette.local : fxColor,
              }}>                  
              {`Buying ${sellCurrency?.isoCode}`}   
            </span>
            <span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>
                          &nbsp;/&nbsp;
            </span>
            <span
              style={{
                fontSize: '14px', 
                fontFamily: 'Roboto', 
                fontWeight: 'bold', 
                color: direction === TradeDirection.SELL ? (theme as any).palette.local : fxColor,
              }}>
              {`Selling ${buyCurrency?.isoCode}`}
            </span>
          </Fragment>
        }
        variant={'dark'}
      />
    )
  } else {
    return (
      <SplitSubTitle 
        leftComponent={
          <Fragment>
            <span 
              style={{
                fontSize: '14px', 
                fontFamily: 'Roboto', 
                fontWeight: 'bold', 
                color: direction === TradeDirection.BUY ? (theme as any).palette.local : fxColor,
              }}>                  
              {`Buying ${sellCurrency?.isoCode}`}   
            </span>
            <span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>
                          &nbsp;/&nbsp;
            </span>
            <span
              style={{
                fontSize: '14px', 
                fontFamily: 'Roboto', 
                fontWeight: 'bold', 
                color: direction === TradeDirection.SELL ? (theme as any).palette.local : fxColor,
              }}>
              {`Selling ${buyCurrency?.isoCode}`}
            </span>
          </Fragment>
        }
        rightComponent={
          <Fragment>
            <span
              style={{
                fontSize: '14px', 
                fontFamily: 'Roboto', 
                fontWeight: 'bold', 
                color: direction === TradeDirection.BUY ? fxColor : (theme as any).palette.local,
              }}>
              {`Buying ${buyCurrency?.isoCode}`}
            </span>
            <span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>
                          &nbsp;/&nbsp;
            </span>
            <span 
              style={{
                fontSize: '14px', 
                fontFamily: 'Roboto', 
                fontWeight: 'bold', 
                color: direction === TradeDirection.SELL ? fxColor : (theme as any).palette.local,
              }}>
              {`Selling ${sellCurrency?.isoCode}`}
            </span>
          </Fragment>
        }
        variant={'dark'}
      />
    )
  }
}
interface BuyingProps {
  buyCurrency: Currency|undefined;
  sellCurrency: Currency|undefined;
  buyAmount: Big|undefined;
  cancellationBuyAmount: Big|undefined;
  tradeType: TradeType;
  direction: TradeDirection;
}
const Buying = (props: BuyingProps): ReactElement => {
  const {buyCurrency, sellCurrency, buyAmount, cancellationBuyAmount, tradeType, direction} = props

  const theme = useTheme()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fxColor = direction === TradeDirection.BUY ? (theme as any).palette.import.main : (theme as any).palette.export.main

  if (tradeType === TradeType.DRAWDOWN) {
    return (
      <SplitRow 
        leftComponent={<SplitLabel>Buying:</SplitLabel>}
        middleComponent={
          <span 
            style={{
              fontSize: '14px', 
              fontFamily: 'Roboto', 
              fontWeight: 'bold', 
              color: direction === TradeDirection.BUY ? fxColor : (theme as any).palette.local
            }}>
            {buyCurrency?.isoCode} {displayAmount(buyAmount)}
          </span>}
        rightComponent={
          <span 
            style={{
              fontSize: '14px', 
              fontFamily: 'Roboto', 
              fontWeight: 'bold', 
              color: direction === TradeDirection.BUY ? (theme as any).palette.local : fxColor
            }}>
            {sellCurrency?.isoCode} {displayAmount(cancellationBuyAmount)}
          </span>}
      />
    )
  } else {
    return (
      <SplitRow 
        leftComponent={<SplitLabel>Buying:</SplitLabel>}
        middleComponent={
          <span 
            style={{
              fontSize: '14px', 
              fontFamily: 'Roboto', 
              fontWeight: 'bold', 
              color: direction === TradeDirection.BUY ? (theme as any).palette.local : fxColor
            }}>
            {sellCurrency?.isoCode} {displayAmount(cancellationBuyAmount)}
          </span>}
        rightComponent={
          <span 
            style={{
              fontSize: '14px', 
              fontFamily: 'Roboto', 
              fontWeight: 'bold', 
              color: direction === TradeDirection.BUY ? fxColor : (theme as any).palette.local
            }}>
            {buyCurrency?.isoCode} {displayAmount(buyAmount)}
          </span>}
      />
    )
  }
}
interface SellingProps {
  buyCurrency: Currency|undefined;
  sellCurrency: Currency|undefined;
  sellAmount: Big|undefined;
  cancellationSellAmount: Big|undefined;
  tradeType: TradeType;
  direction: TradeDirection;
}
const Selling = (props: SellingProps): ReactElement => {
  const {buyCurrency, sellCurrency, sellAmount, cancellationSellAmount, tradeType, direction} = props

  const theme = useTheme()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fxColor = direction === TradeDirection.BUY ? (theme as any).palette.import.main : (theme as any).palette.export.main

  if (tradeType === TradeType.DRAWDOWN) {
    return (
      <SplitRow 
        leftComponent={<SplitLabel>Selling:</SplitLabel>}
        middleComponent={
          <span 
            style={{
              fontSize: '14px', 
              fontFamily: 'Roboto', 
              fontWeight: 'bold', 
              color: direction === TradeDirection.SELL ? fxColor : (theme as any).palette.local
            }}>
            {sellCurrency?.isoCode} {displayAmount(sellAmount)}
          </span>
        }
        rightComponent={
          <span 
            style={{
              fontSize: '14px', 
              fontFamily: 'Roboto', 
              fontWeight: 'bold', 
              color: direction === TradeDirection.SELL ? (theme as any).palette.local : fxColor
            }}>
            {buyCurrency?.isoCode} {displayAmount(cancellationSellAmount)}
          </span>
        }
      />
    )
  } else {
    return (
      <SplitRow 
        leftComponent={<SplitLabel>Selling:</SplitLabel>}
        middleComponent={
          <span 
            style={{
              fontSize: '14px', 
              fontFamily: 'Roboto', 
              fontWeight: 'bold', 
              color: direction === TradeDirection.SELL ? (theme as any).palette.local : fxColor
            }}>
            {buyCurrency?.isoCode} {displayAmount(cancellationSellAmount)}
          </span>
        }
        rightComponent={
          <span 
            style={{
              fontSize: '14px', 
              fontFamily: 'Roboto', 
              fontWeight: 'bold', 
              color: direction === TradeDirection.SELL ? fxColor : (theme as any).palette.local
            }}>
            {sellCurrency?.isoCode} {displayAmount(sellAmount)}
          </span>
        }
      />
    )
  }
}

interface ExternalReferenceProps {
  tradeType: TradeType;
  externalReference: string|undefined;
  cancellationExternalReference: string|undefined;
}
const ExternalReference = (props: ExternalReferenceProps): ReactElement => {
  const {tradeType, externalReference, cancellationExternalReference} = props

  if (tradeType === TradeType.DRAWDOWN) {
    return (
      <SplitRow 
        leftComponent={<SplitLabel>External reference:</SplitLabel>}
        middleComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{externalReference}</span>}
        rightComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{cancellationExternalReference}</span>}
      />
    )
  } else {
    return (
      <SplitRow 
        leftComponent={<SplitLabel>External reference:</SplitLabel>}
        middleComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{cancellationExternalReference}</span>}
        rightComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{externalReference}</span>}
      />
    )
  }
}

interface MaturityDateProps {
  tradeType: TradeType;
  maturityDate: string|null|undefined;
  cancellationMaturityDate: string|null|undefined;
}
const MaturityDate = (props: MaturityDateProps): ReactElement => {
  const {tradeType, maturityDate, cancellationMaturityDate} = props

  if (tradeType === TradeType.DRAWDOWN) {
    return (
      <SplitRow 
        leftComponent={<SplitLabel>Maturity date:</SplitLabel>}
        middleComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{displayDate(maturityDate)}</span>}
        rightComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{displayDate(cancellationMaturityDate)}</span>}
      />
    )
  } else {
    return (
      <SplitRow 
        leftComponent={<SplitLabel>Maturity date:</SplitLabel>}
        middleComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{displayDate(cancellationMaturityDate)}</span>}
        rightComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{displayDate(maturityDate)}</span>}
      />
    )
  }
}

interface AllInRateProps {
  tradeType: TradeType;
  allInRate: Big|undefined;
  cancellationAllInRate: Big|undefined;
}
const AllInRate = (props: AllInRateProps): ReactElement => {
  const {tradeType, allInRate, cancellationAllInRate} = props

  if (tradeType === TradeType.DRAWDOWN) {
    return (
      <SplitRow 
        leftComponent={<SplitLabel>All-in rate:</SplitLabel>}
        middleComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{allInRate?.toFixed(4)}</span>}
        rightComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{cancellationAllInRate?.toFixed(4)}</span>}
      />
    )
  } else {
    return (
      <SplitRow 
        leftComponent={<SplitLabel>All-in rate:</SplitLabel>}
        middleComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{cancellationAllInRate?.toFixed(4)}</span>}
        rightComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{allInRate?.toFixed(4)}</span>}
      />
    )
  }
}

interface TradeDateProps {
  tradeDate: string|null|undefined;
}
const TradeDate = (props: TradeDateProps): ReactElement => {
  const {tradeDate} = props

  return (
    <SplitRow 
      leftComponent={<SplitLabel>Trade date:</SplitLabel>}
      middleComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{displayDate(tradeDate)}</span>}
      rightComponent={<span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: '#FFFFFF99'}}>{displayDate(tradeDate)}</span>}
    />
  )
}

interface ConfirmDrawdownExtensionDetailsProps {
    open: boolean;
    onClose: () => void;
    onFinished: () => void;
    // trade details (to display)
    buyCurrency: Currency|undefined;
    sellCurrency: Currency|undefined;
    tradeDate: string|null|undefined;
    // leg-specific details
    externalReference: string|undefined;
    buyAmount: Big|undefined;
    sellAmount: Big|undefined;
    maturityDate: string|null|undefined;
    allInRate: Big|undefined;
    spotRate: Big|undefined;
    bank: ProcessingBank|undefined;
    interbankRate: Big|undefined;
    bankRate: Big|undefined;
    forwardPoints: Big|undefined;
    cancellationExternalReference: string|undefined;
    cancellationBuyAmount: Big|undefined;
    cancellationSellAmount: Big|undefined;
    cancellationMaturityDate: string|null|undefined;
    cancellationAllInRate: Big|undefined;
    cancellationSpotRate: Big|undefined;
    cancellationBank: ProcessingBank|undefined;
    cancellationInterbankRate: Big|undefined;
    cancellationBankRate: Big|undefined;
    cancellationForwardPoints: Big|undefined;
    // other trade details required for recording
    currencyPair: CurrencyPair|undefined;
    party: Company;
    parentPartyCode: string;
    capturedSpotRate: Big|undefined;
    trader: string|undefined;
    traderOrganisation: string|undefined;
    notes: string|undefined;
    tradeParents: TradeParent[];
    direction: TradeDirection;
    tradeType: TradeType;
}

// useService to record trade

export const ConfirmDrawdownExtensionDetails = (props: ConfirmDrawdownExtensionDetailsProps): ReactElement => {
  const {
    open, 
    onClose, 
    onFinished, 
    buyCurrency, 
    sellCurrency, 
    tradeDate,
    maturityDate, 
    allInRate, 
    interbankRate, 
    spotRate, 
    forwardPoints, 
    buyAmount, 
    sellAmount, 
    externalReference, 
    bank, 
    cancellationMaturityDate, 
    cancellationAllInRate, 
    cancellationInterbankRate, 
    currencyPair, 
    cancellationSpotRate, 
    cancellationForwardPoints, 
    party, 
    cancellationBank, 
    parentPartyCode, 
    capturedSpotRate, 
    trader, 
    traderOrganisation, 
    notes, 
    tradeParents, 
    cancellationBuyAmount, 
    cancellationSellAmount, 
    cancellationExternalReference, 
    direction,
    tradeType,
    bankRate,
    cancellationBankRate
  } = props

  const theme = useTheme()

  const [trade, setTrade] = useState<Trade|undefined>()
  const [recordStarted, setRecordStarted] = useState<boolean>(false)
  const [calculateBalanceStarted, setCalculateBalanceStarted] = useState<boolean>(false)
  const [calculateEffectiveRateStarted, setCalculateEffectiveRateStarted] = useState<boolean>(false)
  const [activeState, setActiveState] = useState<'initial'|'calculating'|'recording'|'error'|'done'>('initial')
  const [error, setError] = useState<string|undefined>()
  
  const [
    {
      response: calculateBalanceResponse,
      loading: calculateBalanceLoading,
      error: calculateBalanceError,
    },
    setCalculateBalanceRequest,
  ] = useService(null, Calculator.calculateBalance)

  const [
    {
      response: calculateEffectiveRateResponse,
      loading: calculateEffectiveRateLoading,
      error: calculateEffectiveRateError,
    },
    setCalculateEffectiveRateRequest,
  ] = useService(null, Calculator.calculateEffectiveRate)
  
  const [
    {
      response: recordResponse,
      loading: recordLoading,
      error: recordError,
    },
    setRecordRequest,
  ] = useService(null, Handler.Record)

  const handleConfirm = () => {
    if (
      tradeDate && 
      maturityDate && 
      cancellationMaturityDate && 
      currencyPair && 
      buyAmount && 
      cancellationBank && 
      trader && 
      capturedSpotRate &&
      bank
    ) {
      const createTradeProps = {
        tradeDate,
        externalReference,
        buyAmount,
        sellAmount,
        maturityDate,
        allInRate,
        spotRate,
        bank,
        interbankRate,
        forwardPoints,
        cancellationExternalReference,
        cancellationBuyAmount,
        cancellationSellAmount,
        cancellationMaturityDate,
        cancellationAllInRate,
        cancellationSpotRate,
        cancellationBank,
        cancellationInterbankRate,
        cancellationForwardPoints,
        currencyPair,
        party,
        parentPartyCode,
        capturedSpotRate,
        trader,
        traderOrganisation,
        notes,
        tradeParents,
        direction,
        tradeType,
        bankRate,
        cancellationBankRate
      }
      let initialTrade: Trade
      if (tradeType === TradeType.DRAWDOWN) {
        initialTrade = createDrawdownTrade(createTradeProps)
        setTrade(initialTrade)
        setCalculateBalanceRequest({trade: initialTrade})
        setActiveState('calculating')
        setCalculateBalanceStarted(true)
      } else {
        initialTrade = createExtensionTrade(createTradeProps)
        setTrade(initialTrade)
        setCalculateBalanceRequest({trade: initialTrade})
        setCalculateEffectiveRateRequest({trade: initialTrade})
        setActiveState('calculating')
        setCalculateBalanceStarted(true)
        setCalculateEffectiveRateStarted(true)
      }
    }
  }
    
  useEffect(() => {
    if (recordStarted && !recordLoading && !recordError && recordResponse) {
      setActiveState('done')
    } else if (recordStarted && !recordLoading && recordError) {
      setError(recordError)
      setActiveState('error')
    }
  }, [recordStarted, recordLoading, recordError, recordResponse])

  const handleClose = () => {
    setRecordStarted(false)
    if (activeState === 'done') {
      onFinished()
    } else {
      onClose()
    }
  }

  useEffect(() => {
    const finalTrade = trade
    if (
      tradeType === TradeType.DRAWDOWN && 
        calculateBalanceStarted && !calculateBalanceLoading && !calculateBalanceError && 
        calculateBalanceResponse && calculateBalanceResponse.trade && finalTrade
    ) {
      finalTrade.availableBalance = calculateBalanceResponse.trade.availableBalance
      setRecordRequest({trade: finalTrade})
      setRecordStarted(true)
      setActiveState('recording')
    } else if (tradeType === TradeType.DRAWDOWN && calculateBalanceStarted && !calculateBalanceLoading && calculateBalanceError) {
      setError(calculateBalanceError)
      setActiveState('error')
    }
    if (
      tradeType === TradeType.EXTENSION && 
        calculateBalanceStarted && calculateEffectiveRateStarted && 
        !calculateBalanceLoading && !calculateEffectiveRateLoading && 
        !calculateBalanceError && !calculateEffectiveRateError && 
        calculateEffectiveRateResponse && calculateEffectiveRateResponse.trade && finalTrade && finalTrade.legs
    ) {
      finalTrade.legs[0].effectiveRate = calculateBalanceResponse.trade.legs[0].effectiveRate
      finalTrade.legs[1].effectiveRate = calculateBalanceResponse.trade.legs[1].effectiveRate
      setRecordRequest({trade: finalTrade})
      setRecordStarted(true)
      setActiveState('recording')
    } else if (tradeType === TradeType.EXTENSION && calculateBalanceStarted && calculateEffectiveRateStarted && (calculateBalanceError || calculateEffectiveRateError)) {
      setError(calculateBalanceError || calculateEffectiveRateError)
      setActiveState('error')
    }
  }, [trade, setRecordRequest, tradeType, calculateBalanceError, calculateEffectiveRateError,
    calculateBalanceLoading, calculateBalanceResponse, calculateBalanceStarted, calculateEffectiveRateLoading, 
    calculateEffectiveRateResponse, calculateEffectiveRateStarted])

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      id={'ConfirmTradeDetailsDialog'}
      onClose={() => handleClose()}
      open={open}
    >
      <div
        style={{
          width: '578px',
          backgroundColor: '#383964',
        }}
      >
        <Toolbar
          style={{position: 'relative', paddingLeft: '25px', backgroundColor: '#383964'}}
          variant={'dense'}
        >
          <span
            style={{flex: 1, fontSize: '20px', fontFamily: 'Roboto', fontWeight: 'bold'}}>
                Confirm {tradeType === TradeType.DRAWDOWN ? 'Drawdown' : 'Extension'} Details
          </span>
          <IconButton
            aria-label="close"
            color="inherit"
            edge="end"
            onClick={() => handleClose()}>
            <CloseIcon/>
          </IconButton>
        </Toolbar>
        <div
          style={{height: '100%', width: '100%'}}
        >
          <div
            style={{width: '100%', height: '80px', display: 'flex'}}
          >
            <div
              style={{
                height: '100%', 
                float: 'left', 
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '25px'
              }}
            >
              <LightTextField
                disabled
                id={'tradeType'}
                label={'Trade Type'}
                style={{width: '95px'}}
                value={tradeType}
              />
            </div>
          </div>
          <SplitSubTitle 
            leftComponent={
              <span style={{fontSize: '16px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>
                {tradeType === TradeType.DRAWDOWN ? 'Drawdown' : 'Cancellation'} Leg
              </span>
            }
            rightComponent={
              <span style={{fontSize: '16px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>
                {tradeType === TradeType.DRAWDOWN ? 'Cancellation' : 'Extension'} Leg
              </span>
            }
            variant={'light'}
          />
          <BuySellTitle 
            buyCurrency={buyCurrency}
            direction={direction}
            sellCurrency={sellCurrency}
            tradeType={tradeType}
          />
          <ExternalReference
            cancellationExternalReference={cancellationExternalReference} 
            externalReference={externalReference}
            tradeType={tradeType}
          />
          <Buying
            buyAmount={buyAmount}
            buyCurrency={buyCurrency}
            cancellationBuyAmount={cancellationBuyAmount}
            direction={direction}
            sellCurrency={sellCurrency}
            tradeType={tradeType}
          />
          <TradeDate 
            tradeDate={tradeDate}
          />
          <MaturityDate 
            cancellationMaturityDate={cancellationMaturityDate}
            maturityDate={maturityDate}
            tradeType={tradeType}
          />
          <AllInRate
            allInRate={allInRate}
            cancellationAllInRate={cancellationAllInRate}
            tradeType={tradeType}
          />
          <Selling
            buyCurrency={buyCurrency}
            cancellationSellAmount={cancellationSellAmount}
            direction={direction}
            sellAmount={sellAmount}
            sellCurrency={sellCurrency}
            tradeType={tradeType}
          />
          {tradeParents?.length > 0 &&
                <Fragment>
                  <SubTitle>
                    <span style={{fontSize: '16px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>
                            Parent Trades
                    </span>
                  </SubTitle>
                  <div
                    style={{width: '100%', height: 'auto'}}
                  >
                    <TableContainer 
                      component={Paper}
                      style={{
                        backgroundColor: '#383964',
                        borderRadius: 0,
                      }}
                    >
                      <Table size={'small'}>
                        <TableHead>
                          <TableRow
                            style={{
                              width: '100%',
                              backgroundColor: '#414272', 
                            }}>
                            <TableCell style={{paddingLeft: '25px'}}><HeaderText>Number</HeaderText></TableCell>
                            <TableCell><HeaderText>Allocated amount</HeaderText></TableCell>
                            <TableCell><HeaderText>Notional amount</HeaderText></TableCell>
                            <TableCell><HeaderText>Rate</HeaderText></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody 
                          style={{
                            backgroundColor: theme.palette.primary.main
                          }}
                        >
                          {tradeParents.map((parent: TradeParent) => (
                            <TableRow
                              key={parent.parentTradeNumber}
                              style={{ backgroundColor: '#383964' }}>
                              <TableCell
                                align="left"
                                style={{ paddingLeft: '25px' }}><CellText>{parent.parentTradeNumber}</CellText></TableCell>
                              <TableCell align="left"><CellText>{parent.amount?.toFixed(2)}</CellText></TableCell>
                              <TableCell align="left"><CellText>{parent.parentNotionalAmount?.toFixed(2)}</CellText></TableCell>
                              <TableCell align="left"><CellText>{parent.rate?.toFixed(4)}</CellText></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Fragment>
          }
          <div
            style={{width: '100%', height: '75px', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}
          >
            <ActionButton
              disabled={['calculating', 'recording', 'done'].includes(activeState)}
              onClick={() => handleConfirm()}
              style={{width: '220px', height: '23px', fontSize: '10px'}}>Confirm Trade Information</ActionButton>
            <SecondaryActionButton
              disabled={['calculating', 'recording', 'done'].includes(activeState)}
              onClick={() => handleClose()}
              style={{width: '220px', height: '23px', fontSize: '10px'}}>Revise Trade Information</SecondaryActionButton>
          </div>
        </div>
      </div>
      <Backdrop
        open={['calculating', 'recording'].includes(activeState)}
        style={{zIndex: 10}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        autoHideDuration={3000}
        onClose={() => handleClose()}
        open={['done', 'error'].includes(activeState)}>
        <Fragment>
          {activeState === 'done' &&
                <Alert
                  onClose={() => handleClose()}
                  severity="success">
                    Successfully recorded trade!
                </Alert>
          }
          {activeState === 'error' &&
                <Alert
                  onClose={() => handleClose()}
                  severity="error">
                    Failed to record trade: {error}
                </Alert>
          }
        </Fragment>
      </Snackbar>
    </Dialog>
  )
}