import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CircularProgress, Tooltip, withStyles} from '@material-ui/core'
import Table from 'components/Table/ReactTable/Table'
import {isArray, isFunction, isObject, isString, objectCopy} from 'utils/Utils'
import {MdErrorOutline as ErrorIcon, MdViewColumn as ViewColumnIcon,} from 'react-icons/md'
import {
  Date as DateFilter,
  Number as NumberFilter,
  Text as TextFilter,
  TextOptions as TextOptionsFilter,
} from './Filters'
import {DATE_CRITERION, NUMBER_CRITERION, TEXT_CRITERION} from 'popcorn-js/search/criteria/types'
import ColumnConfigDialog from 'components/Table/Common/ColumnConfigDialog'
import Fab from '@material-ui/core/Fab'
import AsyncOptions from 'components/Table/PGTable/Filters/AsyncOptions'

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: 'auto',
  },
  controlsWrapper: {
    padding: 2,
  },
  tableWrapper: {
    overflow: 'auto',
  },
  controlButton: {
    padding: 2,
    minHeight: 25,
    height: 25,
    width: 25,
  },
  controlIcon: {
    fontSize: 20,
  },
  errorIcon: {
    fontSize: 80,
  },
  errorDisplay: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'center',
    margin: '15px',
  },
  progress: {
    margin: 2,
  },
  processingDisplay: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'center',
    margin: '15px',
  },
})

const errorStates = {
  constructingTable: 'Error Constructing Table',
  configuringTable: 'Error Configuring Table',
  retrievingColConfig: 'Error Retrieving Column Config',
  saveColConfig: 'Error Saving Column Config',
}

const processingStates = {
  constructingTable: 'Constructing Table',
  buildingTable: 'Building Table',
  retrievingColConfig: 'Retrieving Column Config',
  savingColConfig: 'Saving Column Config',
}

const states = {
  nop: 0,

  // colConfig states
  retrievingColConfig: processingStates.retrievingColConfig,
  errorRetrievingColConfig: errorStates.retrievingColConfig,
  colConfigOpen: 1,
  savingColConfig: processingStates.savingColConfig,
  errorSavingColConfig: errorStates.saveColConfig,

  // construction states
  constructingTable: processingStates.constructingTable,
  errorConstructingTable: errorStates.constructingTable,
}

const events = {
  init: states.constructingTable,

  // colConfig events
  retrieveColConfig: states.retrievingColConfig,
  retrievingColConfigError: states.retrievingColConfig,
  openColConfig: states.colConfigOpen,
  closeColConfig: states.nop,
  saveColConfig: states.savingColConfig,
  saveColConfigError: states.errorSavingColConfig,

  // construction events
  constructionError: states.errorConstructingTable,
  tableConstructionFinish: states.nop,
}

function retrieveColConfig(tableID) {
  return new Promise((resolve, reject) => {
    try {
      const tableConfigurations = localStorage.getItem('tableConfigurations')
      // if tableConfigurations is null or undefined then this has never been
      // saved in local storage.
      // It must be set now to be available for further use
      if (
        (tableConfigurations === null) ||
        (tableConfigurations === undefined)
      ) {
        localStorage.setItem('tableConfigurations', JSON.stringify({}))
        resolve(undefined)
      }

      // otherwise we try and parse it and return the config for this tableID
      resolve(JSON.parse(tableConfigurations)[tableID])
    } catch (e) {
      console.error(`error retrieving column config: ${e}`)
      reject(e)
    }
  })
}

function saveColConfig(tableID, colConfig) {
  return new Promise((resolve, reject) => {
    try {
      let tableConfigurations = localStorage.getItem('tableConfigurations')
      if (
        (tableConfigurations === null) ||
        (tableConfigurations === undefined)
      ) {
        // if tableConfigurations is null or undefined then this has never been
        // saved in local storage.
        // It must be set now to be available for further use
        tableConfigurations = {}
      } else {
        // otherwise we try and parse it
        tableConfigurations = JSON.parse(tableConfigurations)
      }

      // then update the col config
      tableConfigurations[tableID] = colConfig
      // then save it
      localStorage.setItem('tableConfigurations',
        JSON.stringify(tableConfigurations))
      // and resolve promise
      resolve()
    } catch (e) {
      console.error(`error saving column config: ${e}`)
      reject(e)
    }
  })
}

class PGTable extends Component {

  state = {
    activeState: events.init,
    errors: {},
    page: 0,
  }

  defaultColConfig = []
  colConfig = []
  columns = []
  visibleColumns = []
  criteria = {}
  query = {}

  pageSizeOptions = [5, 10, 20, 25, 50, 100]

  constructor(props) {
    super(props)
    this.renderTable = this.renderTable.bind(this)
    this.renderError = this.renderError.bind(this)
    this.constructTable = this.constructTable.bind(this)
    this.renderProcessing = this.renderProcessing.bind(this)
    this.processInitCriteria = this.processInitCriteria.bind(this)
    this.setDefaultColConfig = this.setDefaultColConfig.bind(this)
    this.setColConfig = this.setColConfig.bind(this)
    this.buildColumns = this.buildColumns.bind(this)
    this.configureColumns = this.configureColumns.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleQuerySortChange = this.handleQuerySortChange.bind(this)
    this.handleQueryLimitChange = this.handleQueryLimitChange.bind(this)
    this.handleQueryOffsetChange = this.handleQueryOffsetChange.bind(this)
    this.handleColConfigChange = this.handleColConfigChange.bind(this)
    this.criteriaToArray = this.criteriaToArray.bind(this)

    this.query.limit = props.defaultPageSize || 5
    if (!this.pageSizeOptions.includes(this.query.limit)) {
      this.pageSizeOptions.push(this.query.limit)
      this.pageSizeOptions.sort((a, b) => {
        return a - b
      })
    }
  }

  static validateColumns(columns) {
    if (!isArray(columns)) {
      throw new TypeError('columns prop passed to PGTable is not an array')
    }

    const headers = []

    for (const column of columns) {
      // every column entry in columns must be an object
      if (!isObject(column)) {
        throw new TypeError(
          `one item in columns array prop passed to PGTable is not an object: ${column}`)
      }

      // if Header is undefined or a blank string (i.e. '') then the column is not considered in column config
      if (
        (column.Header === undefined) ||
        (column.Header === '')
      ) {
        continue
      }

      // column Headers must be strings
      if (!isString(column.Header)) {
        throw new TypeError(
          `one item in columns array prop passed to PGTable has a Header which is not a string: ${column.Header}`)
      }

      // otherwise we must confirm that no 2 headers have the same value
      if (headers.includes(column.Header)) {
        throw new Error(
          `entries in columns prop passed to PGTable with duplicate Header values: ${column.Header}`)
      }
      // add to headers array to check against subsequent
      headers.push(column.Header)

      if (
        // either accessor or id must be a string
        !(
          isString(column.accessor) ||
          isString(column.id)
        )
      ) {
        throw new TypeError(
          'either the \'column.accessor\' or \'column.id\' must be a string')
      }
    }
  }

  static validateColConfig(colConfig, columns) {
    if (!isArray(colConfig)) {
      throw new TypeError('not an array')
    }

    const headers = []
    for (const colConf of colConfig) {
      // each item in colConfig must be an object
      if (!isObject(colConf)) {
        throw new TypeError(
          `an item in the array is not an object ${colConf}`)
      }

      // each item in colConfig must have a `header` and `visible property`
      if (!(colConf.hasOwnProperty('header') && isString(colConf.header))) {
        throw new TypeError(
          `an item in the array does not have a 'header' property which is a string: ${colConf.header}`)
      }

      // each header must only feature once
      if (headers.includes(colConf.header)) {
        throw new Error(`duplicate header in colConfig: ${colConf.header}`)
      }
      headers.push(colConf.header)

      // each header must correspond to one of the column objects
      let found = false
      for (const col of columns) {
        if (col.Header === colConf.header) {
          found = true
          break
        }
      }
      if (!found) {
        throw new Error(
          `no column object with header ${colConf.header} found among columns`)
      }
    }

    // each valid column object Header must be represented in the colConfig
    for (const col of columns) {
      if (
        !(
          (col.Header === undefined) ||
          (col.Header === '') ||
          headers.includes(col.Header)
        )
      ) {
        throw new Error(`column with Header ${col.Header} not in colConfig`)
      }
    }

    return true
  }

  static validateCriteria(criteria) {

  }

  componentDidMount() {
    this.constructTable()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      rebuildToggle: prevRebuildToggle,
      openColumnConfigToggle: prevOpenColumnConfigToggle,
    } = prevProps
    const {
      rebuildToggle,
      openColumnConfigToggle,
    } = this.props

    if (rebuildToggle !== prevRebuildToggle) {
      this.constructTable()
      return
    }

    if (prevOpenColumnConfigToggle !== openColumnConfigToggle) {
      this.setState({activeState: events.openColConfig})
      return
    }
  }

  async constructTable() {
    const {
      errors,
    } = this.state
    const {
      columns,
    } = this.props

    this.setState({activeState: events.init})

    try {
      PGTable.validateColumns(columns)
      this.processInitCriteria()
      this.setDefaultColConfig()
      await this.setColConfig()
      this.buildColumns()
      this.configureColumns()
      this.setState({activeState: events.tableConstructionFinish})
    } catch (e) {
      console.error(`error constructing table: ${e}`)
      this.setState({
        activeState: events.constructionError,
        errors: {
          ...errors,
          [events.constructionError]: e,
        },
      })
    }
  }

  processInitCriteria() {
    const {
      initCriteria,
    } = this.props
    if (initCriteria === undefined) {
      return
    }

    if (!isArray(initCriteria)) {
      throw new TypeError('initCriteria prop passed to PGTable is not an array')
    }

    this.criteria = {}
    for (const criteria of initCriteria) {
      PGTable.validateCriteria(criteria)
      const fieldName = ((criteria || {}).value || {}).field

      if (fieldName) {
        this.criteria[fieldName] = criteria
      } else {
        console.error('could not find field name of criteria')
      }

    }
  }

  setDefaultColConfig() {
    const {
      defaultColConfig,
      columns,
    } = this.props

    try {
      if (
        // if a default column configuration is passed AND
        (defaultColConfig !== undefined) &&
        // it is valid
        PGTable.validateColConfig(defaultColConfig, columns)
      ) {
        // then set defaultColConfig to the prop
        this.defaultColConfig = defaultColConfig
        return
      }
    } catch (e) {
      throw new Error(`invalid defaultColConfig: ${e}`)
    }

    // otherwise it was not passed or is invalid, a default one will be generated
    this.defaultColConfig = []
    for (const col of columns) {

      // if no header given or given header is a blank string then ignore
      // cannot give column configuration
      if (
        !isString(col.Header) ||
        (col.Header === '')
      ) {
        continue
      }

      if (!(col.colConfig && col.colConfig.alwaysVisible)) {
        this.defaultColConfig.push({
          header: col.Header,
          visible: true,
        })
      }
    }
  }

  setColConfig() {
    const {
      activeState: prevActiveState,
      errors,
    } = this.state
    const {
      tableID,
      columns,
    } = this.props

    this.colConfig = undefined
    this.setState({activeState: events.retrieveColConfig})
    return retrieveColConfig(tableID).then(colConfig => {
      if (colConfig === undefined) {
        // if colConfig is undefined, it was never set before
        // so use default
        this.colConfig = objectCopy(this.defaultColConfig)
      } else {
        // otherwise colConfig was retrieved and is not undefined
        try {
          PGTable.validateColConfig(colConfig, columns)
          this.colConfig = colConfig
        } catch (e) {
          this.colConfig = objectCopy(this.defaultColConfig)
        }
      }
    }).catch(e => {
      console.error(`error retrieving retrieving ColConfig: ${e}`)
      this.setState({
        activeState: events.retrievingColConfigError,
        errors: {
          ...errors,
          [events.retrievingColConfigError]: e,
        },
      })
    }).finally(() => {
      const {
        activeState,
      } = this.state
      if (
        // if colConfig still undefined
        (this.colConfig === undefined) &&
        // and the component is not yet in an error state
        !Object.values(errorStates).includes(activeState)
      ) {
        // throw an error
        throw new Error(
          'error setting colConfig, this.colConfig still undefined')
      } else {
        this.setState({activeState: prevActiveState})
      }
    })
  }

  buildColumns() {
    const {
      columns,
    } = this.props

    this.columns = []
    for (const col of columns) {
      // if neither id nor accessor are strings then no filter
      // or filter state will be built
      if (!(
        isString(col.id) || isString(col.accessor)
      )) {
        this.columns.push(col)
        continue
      }

      // check if filtering on this column is disallowed
      if (col.filterable === false) {
        // if so, add and continue
        this.columns.push(col)
        continue
      }

      // the rest of the columns have either an id or an accessor and
      // as such will have a filter added for them

      const columnId = isString(col.id) ? col.id : col.accessor

      if (!col.config || col.config.filter === undefined) {
        // if filterConfig not passed for a column
        // the default filter created is textCriteria
        col.Filter = () => <TextFilter
          fieldID={columnId}
          initValue={this.criteria[columnId]}
          onChange={newFilterVal => this.handleFilterChange(newFilterVal,
            columnId)}
        />
      } else {
        // col.config.filter not undefined
        switch (col.config.filter.type) {
          case TEXT_CRITERION:
            // if no options are provided we assume normal text filter
            if (col.config.filter.options !== undefined) {
              // if options are provided we assume an options filter
              col.Filter = () => <TextOptionsFilter
                fieldID={columnId}
                filterConfig={col.config.filter}
                initValue={this.criteria[columnId]}
                onChange={(newFilterVal) => this.handleFilterChange(
                  newFilterVal, columnId)}
              />
              break
            }
            if (col.config.filter.asyncOptionsFetcher !== undefined) {
              // otherwise if asyncOptionsFetcher are provided we assume an options filter
              col.Filter = () =>
                <AsyncOptions
                  fieldID={columnId}
                  filterConfig={col.config.filter}
                  initValue={this.criteria[columnId]}
                  onChange={(newFilterVal) => this.handleFilterChange(
                    newFilterVal, columnId)}
                />
              break
            }
            col.Filter = () => <TextFilter
              fieldID={columnId}
              filterConfig={col.config.filter}
              initValue={this.criteria[columnId]}
              onChange={(newFilterVal) => this.handleFilterChange(
                newFilterVal, columnId)}
            />
            break

          case NUMBER_CRITERION:
            col.Filter = () => <NumberFilter
              fieldID={columnId}
              filterConfig={col.config.filter}
              initValue={this.criteria[columnId]}
              onChange={(newFilterVal) => this.handleFilterChange(
                newFilterVal, columnId)}
            />
            break

          case DATE_CRITERION:
            col.Filter = () => <DateFilter
              fieldID={columnId}
              filterConfig={col.config.filter}
              initValue={this.criteria[columnId]}
              onChange={(newFilterVal) => this.handleFilterChange(
                newFilterVal, columnId)}
            />
            break

          default:
            throw new TypeError(
              `invalid or unsupported filter criterion type: ${col.config.filter.type}`)
        }
      }
      this.columns.push(col)
    }

  }

  configureColumns() {
    const {
      onCriteriaQueryChange,
    } = this.props

    const colsToAdd = []
    const colsToAddAtStart = []
    const colsToAddAtEnd = []

    // place all columns which have no column config association
    for (const col of this.columns) {
      // if no header given or given header is a blank string then we
      // do not consider the column configuration to determine
      // visibility
      if (
        !isString(col.Header) ||
        (col.Header === '')
      ) {
        if (col.config && col.config.arrangement) {
          // if arrangement configuration is provided, we consider it
          switch (col.config.arrangement.position) {
            case 'start':
              colsToAddAtStart.push(col)
              break
            case 'end':
            default:
              colsToAddAtEnd.push(col)
          }
        } else {
          // if it is not we just add it at end
          colsToAddAtEnd.push(col)
        }
      }
    }

    // flag to record that by reconfiguring which columns are visible the
    // applied criteria has also changed
    let criteriaChanged = false

    // go through column config and place those marked visible
    for (const colConf of this.colConfig) {
      // find the column object associated with this colConf
      let column
      for (const colIdx in this.columns) {
        const col = this.columns[colIdx]
        if (col.Header === colConf.header) {
          column = col
          break
        }
        if (colIdx === (this.columns.length - 1)) {
          throw new Error(
            `unable to locate column with header ${col.Header} in columns`)
        }
      }
      if (colConf.visible) {
        // this column should be visible
        // add
        colsToAdd.push(column)
      } else {
        // this column should not be visible
        // do not add
        // clear any criteria for columns now hidden
        const columnId = isString(column.id) ? column.id : column.accessor
        if (this.criteria[columnId] !== undefined) {
          this.criteria[columnId] = undefined
          criteriaChanged = true
        }
      }
    }

    this.visibleColumns = [
      ...colsToAddAtStart,
      ...colsToAdd,
      ...colsToAddAtEnd,
    ]

    // if the criteria changed call onCriteriaQueryChange
    if (criteriaChanged) {
      const updatedFilters = []
      for (const columnId in this.criteria) {
        if (
          (this.criteria.hasOwnProperty(columnId)) &&
          (this.criteria[columnId] !== undefined)
        ) {
          updatedFilters.push(this.criteria[columnId])
        }
      }
      if (isFunction(onCriteriaQueryChange)) {
        onCriteriaQueryChange(updatedFilters)
      }
    }
  }

  criteriaToArray() {
    const updatedCriteria = []
    for (const field in this.criteria) {
      if (this.criteria[field] !== undefined) {
        updatedCriteria.push(this.criteria[field])
      }
    }
    return updatedCriteria
  }

  handleFilterChange(newFilterValue, columnId) {
    const {
      onCriteriaQueryChange,
    } = this.props
    this.criteria[columnId] = newFilterValue
    this.query.offset = 0
    if (isFunction(onCriteriaQueryChange)) {
      onCriteriaQueryChange(this.criteriaToArray(), this.query)
    }
    this.setState({page: 0})
  }

  handleQuerySortChange(updatedSortObjects) {
    const {
      onCriteriaQueryChange,
    } = this.props
    this.query.sortBy = []
    this.query.order = []

    for (const sortObj of updatedSortObjects) {
      this.query.sortBy.push(sortObj.id)
      this.query.order.push(
        sortObj.desc ? 'desc' : 'asc')
    }

    if (isFunction(onCriteriaQueryChange)) {
      onCriteriaQueryChange(this.criteriaToArray(), this.query)
    }
  }

  handleQueryLimitChange(newPageSize) {
    const {
      onCriteriaQueryChange,
    } = this.props
    this.query.limit = newPageSize
    if (isFunction(onCriteriaQueryChange)) {
      onCriteriaQueryChange(this.criteriaToArray(), this.query)
    }
  }

  handleQueryOffsetChange(newPageIndex) {
    const {
      onCriteriaQueryChange,
    } = this.props
    this.query.offset = newPageIndex * this.query.limit
    if (isFunction(onCriteriaQueryChange)) {
      onCriteriaQueryChange(this.criteriaToArray(), this.query)
    }
    this.setState({page: newPageIndex})
  }

  handleColConfigChange(newColConfig) {
    const {
      columns,
      tableID,
    } = this.props
    const {
      errors,
    } = this.state

    PGTable.validateColConfig(newColConfig, columns)

    this.setState({activeState: events.saveColConfig})
    saveColConfig(tableID, newColConfig).then(() => {
      this.colConfig = newColConfig
      this.configureColumns()
    }).catch(e => {
      console.error(`error saving column config: ${e}`)
      this.setState({
        activeState: events.saveColConfigError,
        errors: {
          ...errors,
          [events.saveColConfigError]: e,
        },
      })
    }).finally(() => this.setState({activeState: events.closeColConfig}))
  }

  render() {
    const {
      classes,
    } = this.props
    const {
      activeState,
      errors,
    } = this.state

    return <div className={classes.root}>
      {(() => {
        switch (true) {
          case Object.values(errorStates).includes(activeState):
            return this.renderError()

          case Object.values(processingStates).includes(activeState):
            return this.renderProcessing()

          case [
            states.nop, states.colConfigOpen,
          ].includes(activeState):
            try {
              return this.renderTable()
            } catch (e) {
              this.setState({
                activeState: events.errorRenderingTable,
                errors: {
                  ...errors,
                  [events.errorRenderingTable]: `${e}`,
                },
              })
            }
            break

          default:
            return this.renderError('Invalid State')
        }
      })()}
    </div>
  }

  renderTable() {
    const {
      activeState,
      page,
    } = this.state
    const {
      classes,
      totalNoRecords,
      openColumnConfigToggle,
      ...rest
    } = this.props

    return <React.Fragment>
      {(activeState === states.colConfigOpen) &&
      <ColumnConfigDialog
        closeDialog={() => this.setState(
          {activeState: events.closeColConfig})}
        columnConfig={this.colConfig}
        defaultColumnConfig={this.defaultColConfig}
        onUpdate={this.handleColConfigChange}
        show={activeState === states.colConfigOpen}
      />}
      {(openColumnConfigToggle === undefined) &&
      <div className={classes.controlsWrapper}>
        <Tooltip
          placement="top"
          title="Adjust Columns">
          <Fab
            aria-label="Adjust Columns"
            className={classes.controlButton}
            color="primary"
            onClick={() => this.setState({activeState: events.openColConfig})}
          >
            <ViewColumnIcon className={classes.controlIcon}/>
          </Fab>
        </Tooltip>
      </div>}
      <div className={classes.tableWrapper}>
        <Table
          {...rest}
          columns={this.visibleColumns}
          defaultPageSize={this.query.limit}
          manual
          onPageChange={this.handleQueryOffsetChange}
          onPageSizeChange={this.handleQueryLimitChange}
          onSortedChange={this.handleQuerySortChange}
          page={page}
          pageSizeOptions={this.pageSizeOptions}
          pages={Math.ceil(totalNoRecords / this.query.limit)}
        />
      </div>
    </React.Fragment>
  }

  renderError(outOfStateError) {
    const {activeState, errors} = this.state
    const {classes} = this.props
    const error = errors[activeState]

    let errorMsg = 'Unknown Error'
    if (outOfStateError) {
      errorMsg = outOfStateError
    } else if (isObject(error)) {
      if (error.message) {
        errorMsg = error.message
      }
    } else if (isString(error)) {
      errorMsg = error
    } else if (error !== undefined) {
      errorMsg = error.toString()
    }

    return <div className={classes.errorDisplay}>
      <div><b>{activeState}</b></div>
      <div>
        <ErrorIcon
          className={classes.errorIcon}
        />
      </div>
      <div>{errorMsg}</div>
    </div>
  }

  renderProcessing() {
    const {activeState} = this.state
    const {classes} = this.props

    return <div className={classes.processingDisplay}>
      <div><b>{activeState}</b></div>
      <div>
        <CircularProgress className={classes.progress}/>
      </div>
    </div>
  }
}

PGTable = withStyles(styles)(PGTable)

PGTable.propTypes = {
  ...Table.propTypes,
  /**
   * Id which will be used when calling the save table config
   * function
   */
  defaultColConfig: PropTypes.array,
  /**
   * function which will be called with an array of new
   * criteria objects and the query object when any of
   * the filters are changed or sorting is changed
   */
  initCriteria: PropTypes.array,
  /**
   * Indicates total number of given records on back-end.
   * Used to calculate total no of pages
   */
  onCriteriaQueryChange: PropTypes.func.isRequired,
  /**
   * Hardcoded Default table config,
   * While editing users can either reset to this.
   * Note: if this is not passed then a default will be created
   * with all columns visible
   */
  openColumnConfigToggle: PropTypes.bool,
  /**
   * Toggle this value to get columns rebuilt
   * The typical use case for this is when the
   * data used in one of the filters is coming from
   * a API Request. E.g. loading currency pairs.
   * When this component constructs the first time that
   * data may not yet have loaded and the filter options
   * will be blank. The parent can toggle this on
   * load of that data to get the columns to rebuild.
   * This is a trade-off to avoid this component doing
   * a very expensive compare of the entire columns
   * prop on each component did update
   */
  rebuildToggle: PropTypes.bool,
  /**
   * Optional argument which will be used to initialise
   * table filter fields
   */
  tableID: PropTypes.string.isRequired,
  /**
   * toggle value. If not undefined the change column config
   * icon will not be shown and a change in this value will
   * result in dialog being opened
   */
  totalNoRecords: PropTypes.number.isRequired,
}

PGTable.defaultProps = {
  openColumnConfigToggle: undefined,
  filterable: true,
}

export default PGTable
