import {isObject} from 'utils/Utils'
import AuditEntry from 'popcorn-js/audit/Entry'

/**
 * Resembles a Client Tier
 */
class ClientTier {
  static camel = 'clientTier'
  static nice = 'client tier'
  static niceP = 'client tiers'
  static capital = 'Client Tier'
  static capitalP = 'Client Tiers'
  /**
   * @type {string}
   */
  id = ''
  /**
   * @type {string}
   */
  name = ''
  /**
   * []CurrencyPairSpread
   * @type {Object[]}
   */
  currencyPairSpreads = []
  /**
   * @type {string}
   */
  partyCode = ''
  /**
   * @type {AuditEntry}
   */
  auditEntry = new AuditEntry()

  constructor(clientTier) {
    if (clientTier !== undefined &&
      (clientTier instanceof ClientTier || isObject(clientTier))) {
      try {
        this.id = clientTier.id
        this.name = clientTier.name
        this.currencyPairSpreads = (clientTier.currencyPairSpreads || []).map(
          cps => new CurrencyPairSpread(cps))
        this.partyCode = clientTier.partyCode
        this.auditEntry = new AuditEntry(clientTier.auditEntry)
      } catch (e) {
        console.error('error constructing clientTier entity')
      }
    }
  }
}

export default ClientTier

class CurrencyPairSpread {
  /**
   * @type {number}
   */
  bidSpreadPoints = 0

  /**
   * @type {number}
   */
  askSpreadPoints = 0

  /**
   * @type {string}
   */
  currencyPairId = ''

  /**
   * @type {string}
   */
  currencyPairName = ''

  constructor(currencyPairSpread) {
    if (currencyPairSpread !== undefined &&
      (currencyPairSpread instanceof CurrencyPairSpread ||
        isObject(currencyPairSpread))) {
      try {
        this.bidSpreadPoints = currencyPairSpread.bidSpreadPoints
        this.askSpreadPoints = currencyPairSpread.askSpreadPoints
        this.currencyPairId = currencyPairSpread.currencyPairId
        this.currencyPairName = currencyPairSpread.currencyPairName
      } catch (e) {
        console.error('error constructing currencyPairSpread entity')
      }
    }
  }
}
