import React from 'react'
import PropTypes from 'prop-types'
import {AppBar, Button, Card, CardActions, CardContent, CardHeader, Grid, Tab, Tabs,} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'
import {FullPageLoader as Loader} from 'components/Loader/Loader'
import {
  PARTY_TYPE_BROKER,
  PARTY_TYPE_COMPANY,
  PARTY_TYPE_PROCESSING_BANK,
  PARTY_TYPE_PROCESSING_ORG,
  PARTY_TYPE_SYSTEM,
} from 'constants/partyTypes'
import HistoryLayout from 'views/History/HistoryLayout'
// cards
import ContactDetailsCard from 'components/Cards/ContactDetails'
import IndividualContactDetails from 'components/Cards/IndividualContactDetails'
import RevenueServiceCard from 'components/Cards/RevenueService'
import BasicInformation from 'components/Cards/BasicInformation'
import BasicInformationIndividual from 'components/Cards/BasicInformationIndividual'
import GovernanceCard from 'components/Cards/Governance'
import AddressCard from 'components/Cards/Address'
import IndividualAddress from 'components/Cards/IndividualAddress'
// entities
import System from 'popcorn-js/legalEntity/party/system/System'
import BrokerEntity from 'popcorn-js/legalEntity/party/broker/Broker'
import ProcessingOrgEntity from 'popcorn-js/legalEntity/party/processingOrg/ProcessingOrg'
import CompanyEntity from 'popcorn-js/legalEntity/party/company/Company'
import IndividualEntity from 'popcorn-js/legalEntity/party/individual/Individual'
import CurrencyEntity from 'popcorn-js/financial/currency/Currency'
import {Country as CountryEntity} from 'popcorn-js/country'
// recordkeepers
import SystemRecordkeeper from 'popcorn-js/legalEntity/party/system/recordkeeper'
import ProcessingOrgRecordkeeper from 'popcorn-js/legalEntity/party/processingOrg/recordkeeper'
import BrokerRecordkeeper from 'popcorn-js/legalEntity/party/broker/recordkeeper'
import CompanyRecordkeeper from 'popcorn-js/legalEntity/party/company/recordkeeper'
import IndividualRecordkeeper from 'popcorn-js/legalEntity/party/individual/recordkeeper'
// comparators
import SystemComparator from 'popcorn-js/legalEntity/party/system/comparator'
import ProcessingOrgComparator from 'popcorn-js/legalEntity/party/processingOrg/comparator'
import BrokerComparator from 'popcorn-js/legalEntity/party/broker/comparator'
import CompanyComparator from 'popcorn-js/legalEntity/party/company/comparator'
import IndividualComparator from 'popcorn-js/legalEntity/party/individual/comparator'
// validators
import SystemValidator from 'popcorn-js/legalEntity/party/system/validator'
import ProcessingOrgValidator from 'popcorn-js/legalEntity/party/processingOrg/validator'
import BrokerValidator from 'popcorn-js/legalEntity/party/broker/validator'
import CompanyValidator from 'popcorn-js/legalEntity/party/company/validator'
import IndividualValidator from 'popcorn-js/legalEntity/party/individual/validator'
// history fields
import {SystemFields} from 'views/History/SystemHistory/SystemFields'
import {ProcessingOrgFields} from 'views/History/ProcessingOrgHistory/ProcessingOrgFields'
import {BrokerFields} from 'views/History/BrokerHistory/BrokerFields'
import CompanyFields from 'views/History/CompanyHistory/CompanyFields'
import IndividualFields from 'views/History/IndividualHistory/IndividualFields'
import {PARTY_TYPE_INDIVIDUAL} from '../../../constants'

const styles = () => ({
  tabContainer: {
    maxHeight: 'calc(100vh - 295px)',
    marginTop: '8px',
    overflowY: 'scroll',
  },
})

const ACTIVE_STATE_VIEWING = 'ACTIVE_STATE_VIEWING'
const ACTIVE_STATE_EDITING = 'ACTIVE_STATE_EDITING'

class Overview extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      activeState: ACTIVE_STATE_VIEWING,
      invalidFields: {},

      party: undefined,
      original: undefined,

      tabIndex: 0,
      history: [],

      isLoading: false,
    }

    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleHideAlert = this.handleHideAlert.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleCancelUpdate = this.handleCancelUpdate.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.handleRetrieveHistory = this.handleRetrieveHistory.bind(this)

  }

  componentDidMount() {
    let {partyType, partyCode} = this.props

    if (partyType && partyCode) {
      this.retrieveMyParty()
    }
  }

  handleFieldChange(field, newValue) {
    let {
      party,
      invalidFields,
    } = this.state
    party[field] = newValue
    invalidFields[field] = undefined

    this.setState({party, invalidFields})
    this.handleChanges(party)
  }

  handleAddressChange(field, newValue, addressType) {
    let {party, invalidFields} = this.state
    // set new value for specified field
    party.contactDetails[addressType][field] = newValue;

    // clear invalid error if it was set
    ((invalidFields.contactDetails || {})[addressType] ||
      {})[field] = undefined

    this.setState({party, invalidFields})
    this.handleChanges(party)
  }

  handleContactDetailsChange(field, newValue) {
    let {party, invalidFields} = this.state
    party.contactDetails[field] = newValue;


    // clear invalid fields
    (invalidFields.contactDetails || {})[field] = undefined
    this.setState({party, invalidFields})
    this.handleChanges(party)
  }

  handleChanges(party) {
    let changesMade = !this.compareAll(this.state.original, party)
    let activeState = changesMade ? ACTIVE_STATE_EDITING : ACTIVE_STATE_VIEWING
    this.setState({activeState})
  }

  handleCancelUpdate() {
    this.setState({
      invalidFields: {},
      party: this.newEntity(this.state.original),
      activeState: ACTIVE_STATE_VIEWING,
    })
  }

  compareAll(original, party) {
    const partyType = this.props.partyType
    switch (partyType) {
      case PARTY_TYPE_BROKER:
        return BrokerComparator.CompareAll(original, party)

      case PARTY_TYPE_PROCESSING_ORG:
        return ProcessingOrgComparator.CompareAll(original, party)

      case PARTY_TYPE_SYSTEM:
        return SystemComparator.CompareAll(original, party)

      case PARTY_TYPE_COMPANY:
        return CompanyComparator.CompareAll(original, party)
      case PARTY_TYPE_INDIVIDUAL:
        return IndividualComparator.CompareAll(original, party)

      case PARTY_TYPE_PROCESSING_BANK:
        console.error('not implemented yet')
        break
      default:
        console.error('invalid party type: ', partyType)
        return false
    }
  }

  newEntity(party) {
    const {partyType} = this.props

    switch (partyType) {
      case PARTY_TYPE_BROKER:
        return new BrokerEntity(party)

      case PARTY_TYPE_PROCESSING_ORG:
        return new ProcessingOrgEntity(party)

      case PARTY_TYPE_SYSTEM:
        return new System(party)

      case PARTY_TYPE_COMPANY:
        return new CompanyEntity(party)

      case PARTY_TYPE_INDIVIDUAL:
        return new IndividualEntity(party)


      default:
        console.error('not implemented yet for ', partyType)
    }

  }

  entityFields() {
    switch (this.props.partyType) {
      case PARTY_TYPE_BROKER:
        return BrokerFields
      case PARTY_TYPE_PROCESSING_ORG:
        return ProcessingOrgFields
      case PARTY_TYPE_SYSTEM:
        return SystemFields
      case PARTY_TYPE_COMPANY:
        return CompanyFields
      case PARTY_TYPE_INDIVIDUAL:
        return IndividualFields

      default:
        console.error('not implemented yet for ')
    }
  }

  handleRetrieveHistory() {
    const {party} = this.state

    let recordkeeper
    switch (this.props.partyType) {
      case PARTY_TYPE_PROCESSING_ORG:
        recordkeeper = ProcessingOrgRecordkeeper
        break
      case PARTY_TYPE_BROKER:
        recordkeeper = BrokerRecordkeeper
        break
      case PARTY_TYPE_SYSTEM:
        recordkeeper = SystemRecordkeeper
        break
      case PARTY_TYPE_COMPANY:
        recordkeeper = CompanyRecordkeeper
        break
      case PARTY_TYPE_INDIVIDUAL:
        recordkeeper = IndividualRecordkeeper
        break

      default:
        console.error('not implemented yet for: ', this.props.partyType)
        return
    }

    this.setState({isLoading: true})
    recordkeeper.retrieveHistory(party.id)
      .then(result => {
        const history = result.history.map(party => this.newEntity(party))
        this.setState({history, showHistory: true})
      })
      .catch(
        error => this.setState({errorMessage: error.message || error}))
      .finally(() => this.setState({isLoading: false}))
  }

  handleHideAlert() {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    })
  }

  handleUpdate() {
    this.isValid(() => new Promise((resolve) => {
      this.setState({isLoading: true})
      const {partyType} = this.props
      switch (partyType) {
        case PARTY_TYPE_SYSTEM:
          SystemRecordkeeper.update(this.state.party)
            .then(result => {
              this.setState({
                party: new System(result.system),
                original: new BrokerEntity(result.broker),
                successMessage: 'Updated',
                activeState: ACTIVE_STATE_VIEWING,
              })
            })
            .catch(error => this.setState(
              {errorMessage: error.message || error}))
            .finally(() => this.setState({isLoading: false},
              () => resolve(undefined)))
          break

        case PARTY_TYPE_BROKER:
          BrokerRecordkeeper.update(this.state.party, this.state.original.id)
            .then(result => {
              this.setState({
                party: new BrokerEntity(result.broker),
                original: new BrokerEntity(result.broker),
                successMessage: 'Updated',
                activeState: ACTIVE_STATE_VIEWING,
              })
            })
            .catch(error => this.setState(
              {errorMessage: error.message || error}))
            .finally(() => this.setState({isLoading: false},
              () => resolve(undefined)))
          break

        case PARTY_TYPE_PROCESSING_ORG:
          ProcessingOrgRecordkeeper.update(this.state.party,
            this.state.original.id)
            .then(result => {
              this.setState({
                party: new ProcessingOrgEntity(result.processingOrg),
                original: new ProcessingOrgEntity(result.processingOrg),
                successMessage: 'Updated',
                activeState: ACTIVE_STATE_VIEWING,
              })
            })
            .catch(error => this.setState(
              {errorMessage: error.message || error}))
            .finally(() => this.setState({isLoading: false},
              () => resolve(undefined)))
          break

        case PARTY_TYPE_COMPANY:
          CompanyRecordkeeper.update(this.state.party, this.state.original.id)
            .then(result => {
              this.setState({
                party: new CompanyEntity(result.company),
                original: new CompanyEntity(result.company),
                successMessage: 'Updated',
                activeState: ACTIVE_STATE_VIEWING,
              })
            })
            .catch(error => this.setState({errorMessage: error.message || error}))
            .finally(() => this.setState({isLoading: false},
              () => resolve(undefined)
            ))
          break
        case PARTY_TYPE_INDIVIDUAL:
          IndividualRecordkeeper.update(this.state.party, this.state.original.id)
            .then(result => {
              this.setState({
                party: new IndividualEntity(result.individual),
                original: new IndividualEntity(result.individual),
                successMessage: 'Updated',
                activeState: ACTIVE_STATE_VIEWING,
              })
            })
            .catch(error => this.setState({errorMessage: error.message || error}))
            .finally(() => this.setState({isLoading: false},
              () => resolve(undefined)
            ))
          break

        default:
          this.setState({isLoading: false})
          console.error('not implemented yet for ', partyType)
      }
    }))
  }

  retrieveMyParty() {
    let {partyType, partyCode} = this.props
    switch (partyType) {
      case PARTY_TYPE_BROKER:
        this.setState({isLoading: true})
        BrokerRecordkeeper.retrieve(partyCode)
          .then(result => {
            this.setState({
              party: new BrokerEntity(result.broker),
              original: new BrokerEntity(result.broker),
            })
          })
          .catch(
            error => this.setState({errorMessage: error.message || error}))
          .finally(() => this.setState({isLoading: false}))
        break

      case PARTY_TYPE_PROCESSING_ORG:
        this.setState({isLoading: true})
        ProcessingOrgRecordkeeper.retrieve(partyCode)
          .then(result => {
            this.setState({
              party: new ProcessingOrgEntity(result.processingOrg),
              original: new ProcessingOrgEntity(result.processingOrg),
            })
          })
          .catch(
            error => this.setState({errorMessage: error.message || error}))
          .finally(() => this.setState({isLoading: false}))
        break

      case PARTY_TYPE_SYSTEM:
        this.setState({isLoading: true})
        SystemRecordkeeper.retrieve()
          .then(result => {
            this.setState({
              party: new System(result.system),
              original: new System(result.system),
            })
          })
          .catch(
            error => this.setState({errorMessage: error.message || error}))
          .finally(() => this.setState({isLoading: false}))
        break

      case PARTY_TYPE_COMPANY:
        this.setState({isLoading: true})
        CompanyRecordkeeper.retrieve(partyCode)
          .then(result => {
            this.setState({
              party: new CompanyEntity(result.company),
              original: new CompanyEntity(result.company),
            })
          })
          .catch(error => this.setState({errorMessage: error.message || error}))
          .finally(() => this.setState({isLoading: false}))
        break
      case PARTY_TYPE_INDIVIDUAL:
        this.setState({isLoading: true})
        IndividualRecordkeeper.retrieve(partyCode)
          .then(result => {
            this.setState({
              party: new IndividualEntity(result.individual),
              original: new IndividualEntity(result.individual),
            })
          })
          .catch(error => this.setState({errorMessage: error.message || error}))
          .finally(() => this.setState({isLoading: false}))
        break
      case PARTY_TYPE_PROCESSING_BANK:
        console.error('\'retrieveMyParty\' not implemented yet for ', partyType)
        break
      default:
        console.error('Invalid party type: ', partyType)
    }
  }

  shouldSetBasicInfo() {
    let partyType = this.props
    return partyType.partyType === PARTY_TYPE_COMPANY;
  }

  handleTabChange(event, tabIndex) {
    this.setState({tabIndex})
  }

  render() {

    let {partyType, classes, countries} = this.props
    let {invalidFields, party} = this.state

    const x = partyType && party ? renderTable[partyType] || [] : []

    return (
      <div>
        {this.renderDialogs()}
        <Card
          style={{
            marginBottom: '10px',
          }}
        >
          <CardHeader title={'My Party'}/>
          <CardContent>
            <Grid
              container
              spacing={3}>
              <Grid
                item
                xs={12}>
                <AppBar position={'static'}>
                  <Tabs
                    onChange={this.handleTabChange}
                    scrollButtons={'auto'}
                    value={this.state.tabIndex}
                    variant={'scrollable'}
                  >
                    {partyType !== PARTY_TYPE_INDIVIDUAL ?
                      <Tab
                        id={'companyInformation'}
                        label={'Company Information'}/> :
                      <Tab
                        id={'individualInformation'}
                        label={'Individual Information'}/>
                    }
                    <Tab
                      id={'contactDetails'}
                      label={'Contact Details'}/>
                  </Tabs>
                </AppBar>
                {this.state.tabIndex === 0 &&
                <Grid
                  className={classes.tabContainer}
                  container
                  spacing={3}>
                  {(x.includes(CARD_BASIC_INFO) ||
                    x.includes(CARD_BASIC_INFO + CARD_DISABLED)) &&
                  <Grid
                    item
                    xs={12}>
                    {partyType !== PARTY_TYPE_INDIVIDUAL ?
                      <BasicInformation
                        currencies={this.props.currencies}
                        disabled={x.includes(CARD_BASIC_INFO + CARD_DISABLED)}
                        invalidFields={invalidFields}
                        onChange={(field, value) => this.handleFieldChange(field, value)}
                        party={party}
                        viewingPartyType={partyType}
                        viewOfPartyType={partyType}
                        importExportFields={this.shouldSetBasicInfo()}
                      /> :
                      <BasicInformationIndividual
                        countries={this.props.countries}
                        currencies={this.props.currencies}
                        disabled={x.includes(CARD_BASIC_INFO + CARD_DISABLED)}
                        invalidFields={invalidFields}
                        onChange={(field, value) => this.handleFieldChange(field, value)}
                        party={party}
                        viewingPartyType={partyType}
                        viewOfPartyType={partyType}
                      />


                    }
                  </Grid>
                  }
                  {(x.includes(CARD_REVENUE_SERVICE) ||
                    x.includes(CARD_REVENUE_SERVICE + CARD_DISABLED)) &&
                  <Grid
                    item
                    xs={12}>
                    <RevenueServiceCard
                      disabled={x.includes(
                        CARD_REVENUE_SERVICE + CARD_DISABLED)}
                      invalidFields={invalidFields}
                      onChange={(field, value) => this.handleFieldChange(
                        field, value)}
                      party={party}
                      partyType={partyType}
                    />
                  </Grid>
                  }
                  {(x.includes(CARD_GOVERNANCE) ||
                    x.includes(CARD_GOVERNANCE + CARD_DISABLED)) &&
                  <Grid
                    item
                    xs={12}>
                    <GovernanceCard
                      disabled={x.includes(CARD_GOVERNANCE + CARD_DISABLED)}
                      invalidFields={invalidFields}
                      onChange={(field, value) => this.handleFieldChange(
                        field, value)}
                      party={party}
                    />
                  </Grid>
                  }
                </Grid>
                }
                {this.state.tabIndex === 1 &&
                <Grid
                  className={classes.tabContainer}
                  container
                  spacing={3}>
                  {(x.includes(CARD_PHYSICAL_ADDRESS) ||
                    x.includes(CARD_PHYSICAL_ADDRESS + CARD_DISABLED)) &&
                  <Grid
                    item
                    xs={12}>
                    {partyType !== PARTY_TYPE_INDIVIDUAL ?
                      <AddressCard
                        address={party.contactDetails.physicalAddress}
                        onChange={(field, value) => this.handleAddressChange(
                          field, value, 'physicalAddress')}
                        title={'Physical Address'}
                      />
                      :
                      <IndividualAddress
                        address={party.contactDetails.address}
                        country={countries}
                        currencies={this.props.currencies}
                        fields={['residentialAddress', 'streetName', 'streetNumber', 'unitNumber', 'city', 'townName', 'suburb', 'complexName', 'complex']}
                        onChange={(field, value) => this.handleAddressChange(
                          field, value, 'address')}
                        title={'Physical Address'}
                      />
                    }
                  </Grid>
                  }
                  {(x.includes(CARD_POSTAL_ADDRESS) ||
                    x.includes(CARD_POSTAL_ADDRESS + CARD_DISABLED)) &&
                  <Grid
                    item
                    xs={12}>
                    {partyType !== PARTY_TYPE_INDIVIDUAL ?
                      <AddressCard
                        address={party.contactDetails.postalAddress}
                        onChange={(field, value) => this.handleAddressChange(
                          field, value, 'postalAddress')}
                        title={'Postal Address'}
                      /> :
                      <IndividualAddress
                        address={party.contactDetails.address}
                        country={countries}
                        fields={['residentialPostalCode', 'country', 'postalAddress', 'POBox', 'postalNumber', 'postalCode']}
                        onChange={(field, value) => this.handleAddressChange(
                          field, value, 'address')}
                        title={'Postal Address'}
                      />
                    }
                  </Grid>
                  }
                  {(x.includes(CARD_CONTACT_DETAILS) ||
                    x.includes(CARD_CONTACT_DETAILS + CARD_DISABLED)) &&
                  <Grid
                    item
                    xs={12}>
                    {partyType === PARTY_TYPE_INDIVIDUAL ?
                      <IndividualContactDetails
                        contactDetails={party.contactDetails}
                        fields={['telephoneNumber', 'cellphoneNumber']}
                        onChange={(field, value) => this.handleContactDetailsChange(
                          field, value)}
                      />
                      : <ContactDetailsCard
                        contactDetails={party.contactDetails}
                        onChange={(
                          field, value) => this.handleContactDetailsChange(
                          field, value)}
                      />
                    }
                  </Grid>
                  }
                </Grid>
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {this.renderControls()}
      </div>
    )
  }

  renderControls() {
    const {activeState, isLoading} = this.state
    return (
      <CardActions
        style={{
          display: 'block',
        }}
      >
        {activeState === ACTIVE_STATE_EDITING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${BrokerEntity.camel}SaveButton`}
          onClick={this.handleUpdate}
          size="medium"
        >
          Save Changes
        </Button>
        }
        {activeState === ACTIVE_STATE_EDITING &&
        <Button
          color="primary"
          disabled={isLoading}
          id={`${BrokerEntity.camel}CancelUpdateButton`}
          onClick={this.handleCancelUpdate}
          size="medium"
        >
          Cancel
        </Button>
        }
        <span
          style={{
            float: 'right',
          }}
        >
          {activeState === ACTIVE_STATE_VIEWING &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${BrokerEntity.camel}RetrieveHistoryButton`}
            onClick={this.handleRetrieveHistory}
            size="medium"
          >
            Show History
          </Button>
          }
        </span>
      </CardActions>
    )
  }

  renderDialogs() {
    const {
      isLoading,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      party,
      showHistory,
      history,
    } = this.state

    const {currencies} = this.props


    return (
      <span>
        <Loader
          color={this.props.theme.palette.primary.main}
          isLoading={isLoading}
        />
        {showHistory &&
        <HistoryLayout
          addEntityFieldsProps={{currencies}}
          entity={party}
          entityFields={this.entityFields()}
          entityHistory={history}
          entityName={''}
          loading={isLoading}
          onHide={() => this.setState({showHistory: false})}
          open
        />
        }
        <NotificationSweetAlert
          customClass={`${BrokerEntity.camel}ConfigAlert`}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}

          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </span>
    )
  }

  isValid(whenValidFunc) {
    const {
      party,
    } = this.state
    const {
      partyType,
    } = this.props

    this.setState({isLoading: true})

    switch (partyType) {
      case PARTY_TYPE_BROKER:
        return BrokerValidator.ValidateData(party)
          .then(() => whenValidFunc())
          .catch((invalidFields => {
              if (invalidFields) {
                this.setState({invalidFields})
                return false
              }
            }
          ))
          .finally(() => this.setState({isLoading: false}))

      case PARTY_TYPE_PROCESSING_ORG:
        return ProcessingOrgValidator.ValidateData(party)
          .then(() => whenValidFunc())
          .catch((invalidFields) => {
            if (invalidFields) {
              this.setState({invalidFields})
              return false
            }
          })
          .finally(() => this.setState({isLoading: false}))

      case PARTY_TYPE_SYSTEM:
        return SystemValidator.ValidateData(party)
          .then(() => whenValidFunc())
          .catch((invalidFields) => {
            if (invalidFields) {
              this.setState({invalidFields})
              return false
            }
          })
          .finally(() => this.setState({isLoading: false}))

      case PARTY_TYPE_COMPANY:
        return CompanyValidator.ValidateData(party)
          .then(() => whenValidFunc())
          .catch((invalidFields) => {
            if (invalidFields) {
              this.setState({invalidFields})
              return false
            }
          })
          .finally(() => this.setState({isLoading: false}))
      case PARTY_TYPE_INDIVIDUAL:
        return IndividualValidator.ValidateData(party)
          .then(() => whenValidFunc())
          .catch((invalidFields) => {
            if (invalidFields) {
              this.setState({invalidFields})
              return false
            }
          })
          .finally(() => this.setState({isLoading: false}))

      default:
        this.setState({isLoading: false})
        console.error('not implemented yet')
    }

  }

}

const CARD_DISABLED = '.DISABLED'

const CARD_BASIC_INFO = 'CARD_BASIC_INFO'
const CARD_REVENUE_SERVICE = 'CARD_REVENUE_SERVICE'
const CARD_GOVERNANCE = 'CARD_GOVERNANCE'
const CARD_PHYSICAL_ADDRESS = 'CARD_PHYSICAL_ADDRESS'
const CARD_POSTAL_ADDRESS = 'CARD_POSTAL_ADDRESS'
const CARD_CONTACT_DETAILS = 'CARD_CONTACT_DETAILS'

const renderTable = {
  [PARTY_TYPE_BROKER]: [
    CARD_BASIC_INFO,
    CARD_CONTACT_DETAILS,
    CARD_POSTAL_ADDRESS,
    CARD_PHYSICAL_ADDRESS,
    CARD_REVENUE_SERVICE,
    CARD_GOVERNANCE,
  ],
  [PARTY_TYPE_PROCESSING_ORG]: [
    CARD_BASIC_INFO,
    CARD_CONTACT_DETAILS,
    CARD_POSTAL_ADDRESS,
    CARD_PHYSICAL_ADDRESS,
    CARD_REVENUE_SERVICE,
    CARD_GOVERNANCE,
  ],
  [PARTY_TYPE_SYSTEM]: [
    CARD_BASIC_INFO,
  ],
  [PARTY_TYPE_COMPANY]: [
    CARD_BASIC_INFO,
    CARD_CONTACT_DETAILS,
    CARD_POSTAL_ADDRESS,
    CARD_PHYSICAL_ADDRESS,
    CARD_REVENUE_SERVICE,
    CARD_GOVERNANCE,
  ],
  [PARTY_TYPE_INDIVIDUAL]: [
    CARD_BASIC_INFO,
    CARD_CONTACT_DETAILS,
    CARD_POSTAL_ADDRESS,
    CARD_PHYSICAL_ADDRESS,
  ]
}

Overview.propTypes = {
  classes: PropTypes.object,
  countries: PropTypes.arrayOf(PropTypes.instanceOf(CountryEntity)),

  currencies: PropTypes.arrayOf(PropTypes.instanceOf(CurrencyEntity)),
  partyCode: PropTypes.string.isRequired,

  partyType: PropTypes.string.isRequired,
  theme: PropTypes.object,
}

export default withStyles(styles, {withTheme: true})(Overview)
