import Base from './Base'
import {NAME_IDENTIFIER} from 'popcorn-js/search/identifier/types'
import {isObject, isString} from 'utils/Utils'

export default class Name extends Base {

  static identifierType = NAME_IDENTIFIER

  /**
   * @type {string}
   * @private
   */
  _type = Name.identifierType

  /**
   * @type {{name: string}}
   * @private
   */
  _value = {
    name: '',
  }

  /**
   * Create new Name instance with given
   * identifier
   * @param {
   * {value:{
   *    name: string,
   *  }
   *  } |
   *  Name |
   *  string |
   * } [identifier]
   */
  constructor(identifier) {
    super()
    switch (true) {
      case isString(identifier):
        this._value = {
          name: identifier,
        }
        break
      case identifier instanceof Name:
      case isObject(identifier):
        this._value = {
          name: identifier.value.name,
        }
        break

      case identifier === undefined:
        break

      default:
        throw new TypeError('invalid value passed to Name constructor')
    }
  }

  get name() {
    return this._value.name
  }

  set name(newVal) {
    this._value.name = newVal
  }
}
