import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/CustomButtons'
import Table from 'components/Table'
import {
  Tabs, Tab, AppBar,
  TextField, Grid, Card,
  CardHeader, CardContent,
  CardActions, Typography,
  withStyles,
} from '@material-ui/core'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'
import Recordkeeper from 'popcorn-js/country/recordkeeper'
import Validator from 'popcorn-js/country/validator'
import {FullPageLoader as Loader}from 'components/Loader/Loader'
import {Country as CountryEntity} from 'popcorn-js/country'
import {
  FilterToSortBy, SortByToFilter,
  SortObjects, SortString,
} from '../../../utils/Utils'
import HistoryLayout from '../../History/HistoryLayout'
import {CountryFields} from '../../History/CountryHistory/CountryFields'
import Comparator from '../../../popcorn-js/country/comparator'

const styles = theme => {
  return {
    close: {
      padding: theme.spacing() / 2,
    },
    spinnerColor: theme.palette.primary.main
  }
}

const STATE_VIEWING = 'STATE_VIEWING'
const STATE_CREATING = 'STATE_CREATING'
const STATE_EDITING = 'STATE_EDITING'
const STATE_VIEWING_DELETE = 'STATE_VIEWING_DELETE'

const countryListLength = 10
const countryConfigurationListId = 'countryConfigurationList'

class Country extends React.Component {
  state = {
    // view state of dashboard
    state: STATE_VIEWING,

    countries: [],
    totalCountries: 0,
    selectedRowIndex: -1,
    previouslySelectedRowIndex: -1,
    sortBy: ['name'],

    history: [],
    selectedCountry: new CountryEntity(),
    originalCountry: new CountryEntity(),
    previouslySelectedCountry: new CountryEntity(),

    isLoading: true,

    showHistory: false,

    successMessage: undefined,
    errorMessage: undefined,
    warningMessage: undefined,
    confirmationMethod: undefined,

    invalidFields: {},

    tabIndex: 0,
  }

  componentDidMount() {
    this.find()
  }

  handleTabChange = (event, tabIndex) => {
    this.setState({tabIndex: tabIndex})
  }

  handleCountrySelection = (selectedCountry, index, state) => {
    if (this.state.state === STATE_EDITING && !state) {
      this.showDiscardConfirmation(()=> {
        this.handleHideAlert()
        this.handleCountrySelection(selectedCountry, index, STATE_VIEWING)
      })
      return
    }

    let t = state
    const {
      state:currentState
    } = this.state
    if (!state) {
      switch (currentState) {
        case STATE_CREATING:
        case STATE_EDITING:
          t = STATE_VIEWING
          break
        default:
          t = currentState
      }
    }

    this.setState({
      selectedCountry: new CountryEntity(selectedCountry),
      originalCountry: new CountryEntity(selectedCountry),
      previouslySelectedCountry: new CountryEntity(selectedCountry),

      selectedRowIndex: index,
      previouslySelectedRowIndex: index,

      state: t,
    })
  }

  find = async () => {
    const {
      sortBy,
    } = this.state

    const query = {
      sortBy,
    }
    this.setState({isLoading: true})
    try {
      const findResult = await Recordkeeper.find(undefined, query)
      // Map to country entities
      const countries = findResult.records.map(country => new CountryEntity(country))

      countries.sort((a, b) => SortObjects(a, b, this.state.sortBy))

      // Select the first country in the list
      const selectedCountry = findResult.total > 0 ? countries[0] : new CountryEntity()
      const selectedRowIndex = findResult.total > 0 ? 0 : -1

      this.handleCountrySelection(selectedCountry, selectedRowIndex, STATE_VIEWING)

      this.setState({
        totalCountries: findResult.total,
        countries: countries,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  findDelete = async () => {
    const {
      sortBy,
    } = this.state
    const query = {
      sortBy,
    }
    this.setState({isLoading:true})
    try {
      const findResponse = await Recordkeeper.find(undefined, query, true)
      // Map delete countries to entities
      const countries = findResponse.records.map(country => new CountryEntity(country))
      countries.sort((a,b) => SortObjects(a, b, this.state.sortBy))

      // Select the first country in the list
      const selectedCountry = findResponse.total > 0 ? countries[0] : new CountryEntity()
      const selectedRowIndex = findResponse.total > 0 ? 0 : -1

      this.handleCountrySelection(selectedCountry, selectedRowIndex, STATE_VIEWING_DELETE)

      this.setState({
        totalCountries: findResponse.total,
        countries: countries,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleViewDelete = () => {
    this.findDelete()
  }

  handleRetrieveHistory = async () => {
    this.setState({isLoading: true})
    try {
      const retrieveHistoryResponse = await Recordkeeper.retrieveHistory(this.state.selectedCountry.id)
      this.setState({
        history: retrieveHistoryResponse.history.map(country => new CountryEntity(country)),
        showHistory: true,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleReturn = () => {
    this.find()
  }

  handleRestore = async () => {
    this.setState({isLoading: true})
    try {
      await Recordkeeper.restore(this.state.selectedCountry.id)
      const countries = this.state.countries.slice()
      const idx = countries.findIndex(c => c.id === this.state.selectedCountry.id)
      countries.splice(idx, 1)

      if (countries.length > idx) {
        this.handleCountrySelection(countries[idx], idx)
      } else {
        this.handleCountrySelection(idx === 0 ? new CountryEntity() : countries[idx-1], idx-1)
      }

      this.setState({
        successMessage:'Successfully restored country',
        countries,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  showDeleteConfirmation = () => {
    this.setState({
      warningMessage:`You are about to permanently delete '${this.state.selectedCountry.name}'. Do you want to continue?`,
      confirmationMethod: this.handleDelete
    })
  }

  showDiscardConfirmation = (confirm) => {
    this.setState({
      warningMessage: 'You have unsaved changes. Do you want to continue?',
      confirmationMethod: confirm
    })
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    this.handleHideAlert()
    try {
      await Recordkeeper.deleteForever(this.state.selectedCountry.id)
      const countries = this.state.countries.slice()
      const idx = countries.findIndex(c => c.id === this.state.selectedCountry.id)
      countries.splice(idx,1)

      if (countries.length > idx) {
        this.handleCountrySelection(countries[idx], idx)
      } else {
        this.handleCountrySelection(idx === 0 ? new CountryEntity() : countries[idx-1], idx-1)
      }

      this.setState({
        successMessage:'Successfully deleted country',
        countries,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleTextChange = name => event => {
    const {
      invalidFields,
      state: currentState,
      selectedRowIndex,
    } = this.state
    const selectedCountry = this.state.selectedCountry
    selectedCountry[name] = event.target.value
    invalidFields[name] = undefined
    let state
    switch (currentState) {
      case STATE_CREATING:
        state = STATE_CREATING
        break
      case STATE_VIEWING:
        state = selectedRowIndex >= 0
          ? !Comparator.CompareAll(this.state.originalCountry, selectedCountry)
            ? STATE_EDITING
            : STATE_VIEWING
          : STATE_CREATING
        break
      case STATE_EDITING:
        state = !Comparator.CompareAll(this.state.originalCountry, selectedCountry)
          ? STATE_EDITING
          : STATE_VIEWING
        break
      default:
    }

    this.setState({selectedCountry, invalidFields, state})
  }

  handleNew = () => {
    document.getElementById('countryNameField').focus()
    this.setState({
      selectedCountry: new CountryEntity(),
      originalCountry: new CountryEntity(),
      selectedRowIndex: -1,
      state: STATE_CREATING
    })
  }

  handleUpdate = async () => {
    const isValid = await this.isValid()
    if (isValid) {
      this.setState({isLoading: true})
      try {
        const updateResponse = await Recordkeeper.update(this.state.selectedCountry, this.state.selectedCountry.id)
        const countries = this.state.countries.slice() || []
        const selectedCountry = new CountryEntity(updateResponse.country)
        const countryToReplaceIndex = countries.findIndex(c => c.id === updateResponse.country.id)
        if (countryToReplaceIndex >= 0) {
          countries[countryToReplaceIndex] = selectedCountry
        }
        countries.sort((a, b) => SortObjects(a, b, this.state.sortBy))

        const selectedRowIndex = countries.findIndex(c => c.id === updateResponse.country.id)

        this.handleCountrySelection(selectedCountry, selectedRowIndex, STATE_VIEWING)
        this.setState({
          successMessage: 'Updated',
          countries,
        })
        Country.scrollToPos(selectedRowIndex / countries.length)
      } catch (e) {
        this.setState({errorMessage: e.message||e})
      }
      this.setState({isLoading: false})
    }
  }

  handleCreate = async () => {
    const isValid = await this.isValid()

    if (isValid) {
      this.setState({isLoading: true})
      try {
        const createResponse = await Recordkeeper.create(this.state.selectedCountry)
        const newCountry = new CountryEntity(createResponse.country)
        const countries = this.state.countries.slice() || []
        countries.push(newCountry)
        countries.sort((a, b) => SortObjects(a, b, this.state.sortBy))

        const selectedCountry = new CountryEntity(newCountry)
        const selectedRowIndex = countries.findIndex(c => c.id === selectedCountry.id)

        this.handleCountrySelection(selectedCountry, selectedRowIndex, STATE_VIEWING)

        this.setState({
          successMessage: 'Country created',
          countries,
        })
        Country.scrollToPos(selectedRowIndex / countries.length)
      } catch (e) {
        this.setState({errorMessage: e.message || e})
      }
      this.setState({isLoading: false})
    }
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    try {
      await Recordkeeper.delete(this.state.selectedCountry.id)
      const countries = this.state.countries.slice() || []
      const idx = countries.findIndex(c => c.id === this.state.selectedCountry.id)
      countries.splice(idx, 1)

      if (countries.length > idx) {
        this.handleCountrySelection(countries[idx], idx)
      } else {
        this.handleCountrySelection(idx===0 ? new CountryEntity() : countries[idx-1], idx-1)
      }

      this.setState({
        successMessage:'Successfully deleted country',
        countries,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleCancelUpdate = () => {
    this.setState({
      invalidFields: {},
      selectedCountry: new CountryEntity(this.state.originalCountry),
      state: STATE_VIEWING,
    })
  }

  handleCancelCreate = () => {
    this.setState({
      invalidFields: {},
      selectedCountry: new CountryEntity(this.state.previouslySelectedCountry),
      originalCountry: new CountryEntity(this.state.previouslySelectedCountry),
      state: STATE_VIEWING,
      selectedRowIndex: this.state.previouslySelectedRowIndex,
    })
    Country.scrollToPos(this.state.previouslySelectedRowIndex/this.state.countries.length)
  }

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    })
  }

  render() {
    const {
      invalidFields,
      selectedRowIndex,
      selectedCountry,
      countries,
      state,
    } = this.state

    return (
      <div id="configurationCountryRoot">
        {this.renderDialogs()}
        <Card
          style={{
            marginBottom: '10px',
          }}>
          <CardHeader
            action={
              <div>
                <Button
                  disabled
                  onClick={() => this.setState({showImportDialog: true})}
                >
                      Import
                </Button>
                <Button
                  disabled
                  onClick={() => this.setState({showExportDialog: true})}
                >
                      Export
                </Button>
              </div>

            }
            title={
              <Typography
                gutterBottom
                variant={'h6'}>
                {state === STATE_VIEWING_DELETE ? 'Countries - Delete' : 'Countries'}
              </Typography>
            }
          />
          <CardContent
            style={{
              padding: '0',
              margin: '0px 24px 24px 24px',
            }}
          >
            <Grid
              container
              spacing={3}>
              <Grid
                item
                lg={4}
                md={6}
                sm={6}
                xs={12}>
                <Table
                  className={'countryTable'}
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'name',
                    },
                    {
                      Header: 'Code',
                      accessor: 'code',
                    },

                  ]}
                  data={countries}
                  defaultPageSize={countryListLength}
                  defaultSortMethod={(a, b) => SortString(a, b)}
                  defaultSorted={SortByToFilter(this.state.sortBy)}
                  getTbodyProps={() => {
                    return {
                      style: {
                        overflowY: 'scroll',
                        height: '368px',
                        scrollBehavior: 'smooth',
                      },
                      id: countryConfigurationListId,
                    }
                  }}
                  getTdProps={(state, rowInfo) => {
                    const rowIndex = rowInfo ? rowInfo.index : undefined

                    return {
                      onClick: (e, handleOriginal) => {
                        if (rowInfo) {
                          this.handleCountrySelection(new CountryEntity(rowInfo.original), rowIndex)
                        }
                        if (handleOriginal) {
                          handleOriginal()
                        }
                      },
                      style: {
                        background: rowIndex === selectedRowIndex ?
                          this.props.theme.palette.secondary.light :
                          'white',
                        color: rowIndex === selectedRowIndex ?
                          this.props.theme.palette.secondary.contrastText :
                          'black',
                      },
                    }
                  }}
                  id="countryTable"
                  onSortedChange={(filter) => this.setState({sortBy: FilterToSortBy(filter)})}
                  pageSize={Math.max(countryListLength, (countries|| []).length)}
                  showPagination={false}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={6}
                sm={6}
                xs={12}>
                <AppBar
                  position={'static'}
                >
                  <Tabs
                    onChange={this.handleTabChange}
                    value={this.state.tabIndex}>
                    <Tab label="Main"/>
                  </Tabs>
                </AppBar>
                {this.state.tabIndex === 0 &&
                  <Grid
                    container
                    direction={'column'}>
                    <TextField
                      disabled={state === STATE_VIEWING_DELETE}
                      error={!!invalidFields.name}
                      helperText={invalidFields.name ? `*${invalidFields.name}`: ' '}
                      id={'countryNameField'}
                      label="Name"
                      margin="normal"
                      onChange={this.handleTextChange('name')}
                      value={selectedCountry.name}
                    />
                    <TextField
                      disabled={state === STATE_VIEWING_DELETE}
                      error={!!invalidFields.code}
                      helperText={invalidFields.code ? `*${invalidFields.code}`: ' '}
                      id={'countryCodeField'}
                      label="Code"
                      margin="normal"
                      onChange={this.handleTextChange('code')}
                      value={selectedCountry.code}
                    />
                  </Grid>
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {this.renderControls()}
      </div>
    )
  }

  renderControls = () => {
    const {
      selectedRowIndex,
      state,
    } = this.state
    return(
      <CardActions
        style={{
          display:'block'
        }}
      >
        {state === STATE_VIEWING &&
          <Button
            color="primary"
            disabled={this.state.isLoading}
            id="newCountryButton"
            onClick={this.handleNew}
            size="medium"
          >
            New
          </Button>
        }
        {state === STATE_VIEWING && selectedRowIndex >= 0 &&
          <Button
            color="primary"
            disabled={this.state.isLoading}
            id="deleteCountryButton"
            onClick={this.handleDelete}
            size="medium"
          >
            Delete
          </Button>
        }
        {state === STATE_EDITING &&
          <Button
            color="primary"
            disabled={this.state.isLoading}
            id="saveCountryButton"
            onClick={this.handleUpdate}
            size="medium"
          >
            Save Changes
          </Button>
        }
        {state === STATE_EDITING &&
          <Button
            color="primary"
            disabled={this.state.isLoading}
            id="cancelUpdateCountryButton"
            onClick={this.handleCancelUpdate}
            size="medium"
          >
            Cancel
          </Button>
        }
        {state === STATE_CREATING &&
          <Button
            color="primary"
            disabled={this.state.isLoading}
            id="createCountryButton"
            onClick={this.handleCreate}
            size="medium"
          >
            Save New
          </Button>
        }
        {state === STATE_CREATING &&
          <Button
            color="primary"
            disabled={this.state.isLoading}
            id="cancelCreateCountryButton"
            onClick={this.handleCancelCreate}
            size="medium"
          >
            Cancel
          </Button>
        }
        {state === STATE_VIEWING_DELETE && selectedRowIndex >= 0 &&
          <Button
            color="primary"
            disabled={this.state.isLoading}
            id="restoreCountryButton"
            onClick={this.handleRestore}
            size="medium"
          >
            Restore
          </Button>
        }
        {state === STATE_VIEWING_DELETE && selectedRowIndex >= 0 &&
          <Button
            color="primary"
            disabled={this.state.isLoading}
            id="deleteCountryButton"
            onClick={this.showDeleteConfirmation}
            size="medium"
          >
            Delete
          </Button>
        }
        <span
          style={{
            float:'right',
          }}
        >
          {state === STATE_VIEWING && selectedRowIndex >= 0 &&
              <Button
                color="primary"
                disabled={this.state.isLoading}
                id="showHistoryCountryButton"
                onClick={this.handleRetrieveHistory}
                size="medium"
              >
                Show History
              </Button>
          }
          {state === STATE_VIEWING &&
            <Button
              color="primary"
              disabled={this.state.isLoading}
              id="viewDeleteCountryButton"
              onClick={this.handleViewDelete}
              size="medium"
            >
              View Delete
            </Button>
          }
          {state === STATE_VIEWING_DELETE &&
            <Button
              color="primary"
              disabled={this.state.isLoading}
              id="returnDeleteCountryButton"
              onClick={this.handleReturn}
              size="medium"
            >
              Return
            </Button>
          }
        </span>

      </CardActions>
    )
  }

  renderDialogs = () => {
    const {
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
    } = this.state

    return (
      <span>
        <Loader
          color={this.props.theme.palette.primary.main}
          isLoading={this.state.isLoading}/>
        {this.state.showHistory &&
          <HistoryLayout
            entity={this.state.selectedCountry}
            entityFields={CountryFields}
            entityHistory={this.state.history}
            entityName={'Country'}
            loading={this.state.isLoading}
            onHide={() => this.setState({showHistory: false})}
            open
          />
        }
        <NotificationSweetAlert
          customClass={'countryConfigAlert'}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}

          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </span>
    )
  }

  isValid = async () => {
    const {
      selectedCountry,
    } = this.state
    try {
      await Validator.ValidateData(selectedCountry)
    } catch (invalidFields) {
      if (invalidFields) {
        this.setState({invalidFields})
        return false
      }
    }
    return true
  }

  static scrollToPos = (pos) => {
    const objDiv = document.getElementById(countryConfigurationListId)
    if (objDiv) {
      objDiv.scrollTop = pos * objDiv.scrollHeight
    }
  }
}

Country.propTypes = {
  classes: PropTypes.object,
  country: PropTypes.object,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  theme: PropTypes.object,
}

const StyledCountry = withStyles(styles, {withTheme: true})(Country)
export default StyledCountry
