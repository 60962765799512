import React from 'react'
import {Card, Link, makeStyles} from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import {IoIosPerson} from 'react-icons/io'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Authenticator from '../../popcorn-js/authenticator/authenticator';
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {HexToRGBA} from '../../utils/Utils';
import {useAuth0} from '../../utils/react-auth0-spa'


const useStyles = makeStyles(theme => ({
  userInfoSections: {
    display: 'grid',
    gridTemplateColumns: '120px 228px',
  },
  avatarContainer: {
    textAlign: 'center',
  },
  userAvatar: {
    width: '96px',
    height: '96px',
    border: '2px solid white',
    margin: '0 auto'
  },
  userPhoto: {
    width: '100%',
    height: '100%',
  },
  editPhotoText: {
    marginTop: '8px',
    fontSize: '12px',
    opacity: 0.5
  },
  userDetailsSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    backgroundColor: theme.palette.secondary.main
  },
  userProfileInfo: {
    display: 'flex',
    flexDirection: 'column',

  },
  border: {
    borderStyle: 'solid',
    height: '0px',
    borderWidth: '0.1em',
    borderColor: theme.palette.text.secondary,
    borderTopColor: theme.palette.background.paper,
    borderLeftColor: theme.palette.background.paper,
    borderRightColor: theme.palette.background.paper,
    marginTop: '15px',
    marginBottom: '15px',
  },
  aboutText: {
    textAlign: 'left',
    fontSize: '12px',
    color: HexToRGBA(theme.palette.text.primary, .5),
  },
  linkStyle: {
    marginLeft: '5px',
    color: HexToRGBA(theme.palette.text.primary, .5),
    '&:hover': {
      color: HexToRGBA(theme.palette.text.primary, 1),

    },
  },
  nameText: {
    color: theme.palette.text.label,
    fontSize: '12px',
    fontWeight: 'bold',
  },
  emailAddressText: {
    color: theme.palette.text.primary,
    fontSize: '12px',
  }
}
))

function UserInfo(props) {
  const classes = useStyles()

  const {logout} = useAuth0();

  function doLogout() {
    Authenticator.logout().then(() => {
      logout({returnTo: `${window.location.origin}/logout`})
      props.history.push('/')
    }).catch(e => {
      console.error('failed to log out: ', e)
      props.history.push('/')
    })
  }

  const userDetail = useSelector(state => state.myUser.userProfile)


  return (
    <Card>
      <CardContent
        className={classes.userInfoSections}
      >
        <div className={classes.avatarContainer}>
          <Avatar
            className={classes.userAvatar}
          >
            {userDetail.avatarURL ?
              (
                <img
                  alt=""
                  className={classes.userPhoto}
                  src={userDetail.avatarURL}
                />
              )
              :
              (
                <IoIosPerson className={classes.userPhoto}/>
              )
            }
          </Avatar>
          <Typography
            className={classes.editPhotoText}
          > edit photo</Typography>
        </div>
        <div className={classes.userDetailsSection}>
          <div className={classes.userProfileInfo}>
            <div>
              <Typography
                className={classes.nameText}
                variant="h5"
              >
                {userDetail.firstName && userDetail.firstName !== '' && userDetail.lastName && userDetail.lastName !== '' ?
                  (
                    userDetail.firstName + ' ' + userDetail.lastName
                  )
                  :
                  (
                    '(name not specified)'
                  )
                }
              </Typography>
            </div>
            <div>
              <Typography
                className={classes.emailAddressText}
                variant="body1"
              >
                {userDetail.emailAddress && userDetail.emailAddress !== '' ?
                  (
                    userDetail.emailAddress
                  )
                  :
                  (
                    '(email address not specified)'
                  )
                }
              </Typography>
            </div>
          </div>
          <div
            style={{paddingTop: '10px'}}
          >
            <Button
              className={classes.button}
              id={'SignOut'}
              onClick={doLogout}
            >
              Sign Out
            </Button>
          </div>
          <div
            className={classes.border}
          />
          <div
          >
            <Typography
              className={classes.aboutText}
            >
              STELLCAP Dealing is designed and built by Andile.
            </Typography>
          </div>
          <div>
            <Typography
              className={classes.aboutText}
            >
              <Link
                className={classes.linkStyle}
                href={'https://www.stellcap.co.za/'}
                target={'_blank'}
                underline={'none'} />
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

UserInfo.propTypes = {
  history: PropTypes.object,
  onLogout: PropTypes.func,
}

export default UserInfo
