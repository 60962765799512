import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {
  Card as MUICard,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  makeStyles, MenuList, Paper,
  Tooltip,
} from '@material-ui/core'
import {MdExpandLess, MdExpandMore,} from 'react-icons/md'
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import StyledPopper from '../../components/Popper/Popper'
import {MdMoreVert} from 'react-icons/md';

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    marginBottom: 0,
  },
  headerRoot: {
    backgroundColor: theme.palette.primary.medium,
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.75),
    height: '52px',
    color: theme.palette.primary.contrastText,
  },
  headerRootSelect: {
    backgroundColor: theme.palette.text.label,
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.75),
    height: '52px',
    color: theme.palette.background.paper,
  },
  cardContentRoot: {
    padding: 0,
    gridTemplateColumns: 'auto auto 1fr auto',
    '&:last-child': {
      padding: 0,
    },
  },
  headerTitleText: {
    color: theme.palette.primary.contrastText,
  },
  iconButton: {
    '& svg': {
      fontSize: 32
    }
  }
}))

//IconButton theme for selected header
const themeSelect = createMuiTheme({
  overrides: {
    // Style sheet name
    MuiIconButton: {
      // Name of the rule
      root: {
        // Some CSS
        color: '#1E2036',
      },
    },
  },
})

function Card(props) {
  const [open, setOpen] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)

  function MoreClick(event) {
    setAnchorEl(event.currentTarget ? event.currentTarget : null)
  }
  const classes = useStyles()
  const {
    title,
    cardProps,
    cardContentProps,
    additionalActions,
    moreActions,
    selectBool,
    rootClass,
  } = props

  let addCardProps = {}
  if (cardProps) {
    addCardProps = {
      ...cardProps,
    }
  }

  let addCardContentProps = {}
  if (cardContentProps) {
    addCardContentProps = {
      ...cardContentProps,
    }
  }

  return (
    <MUICard
      {...addCardProps}
      className={rootClass}
      square={false}
    >
      <CardHeader
        action={
          <React.Fragment>
            {open
              ? (
                <React.Fragment>
                  <ThemeProvider theme={selectBool ? themeSelect : {}}>
                    {additionalActions}
                    {moreActions && <Tooltip title="More Actions">
                      <IconButton
                        aria-label="moreActions"
                        onClick={MoreClick}
                      >
                        <MdMoreVert/>
                      </IconButton>
                    </Tooltip>}
                    <Tooltip title={'Collapse'}>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => setOpen(!open)}
                      >
                        <MdExpandLess/>
                      </IconButton>
                    </Tooltip>
                  </ThemeProvider>
                  <StyledPopper
                    anchorEl={anchorEl}
                    onClose={setAnchorEl}
                  >
                    <Paper>
                      <MenuList
                        id="menuList"
                      >
                        {moreActions}
                      </MenuList>
                    </Paper>
                  </StyledPopper>
                </React.Fragment>
              )
              : (
                <Tooltip title={'Expand'}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => setOpen(!open)}
                  >
                    <MdExpandMore/>
                  </IconButton>
                </Tooltip>
              )
            }
          </React.Fragment>
        }
        classes={{root: selectBool ? classes.headerRootSelect : classes.headerRoot}}
        title={title}
        titleTypographyProps={{
          className: classes.headerTitleText,
          variant: 'h6',
        }}
      />
      <Collapse in={open}>
        <CardContent
          {...addCardContentProps}
          classes={{root: classes.cardContentRoot}}
        >
          {props.children ? props.children : null}
        </CardContent>
      </Collapse>
    </MUICard>
  )
}

Card.propTypes = {
  additionalActions: PropTypes.array,
  moreActions: PropTypes.array,
  cardContentProps: PropTypes.object,
  cardProps: PropTypes.object,
  title: PropTypes.object,
  selectBool: PropTypes.bool,
}

Card.defaultProps = {
  title: {},
  cardProps: {},
  cardContentProps: {},
  additionalActions: [],
  selectBool: false,
}

export default Card
