import { BrokenCriterion, Criteria } from 'popcorn-js/search/index'

export function marshalCriteria(criteria: Criteria): string[]|undefined {
  if (criteria == null) {
    return undefined
  }
  const stringObjects = []
  for (const criterion of criteria) {
    if ((criterion as BrokenCriterion).value) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const c: any = {...criterion}
      stringObjects.push(criterion.type + '::' + JSON.stringify(c.value))
    } else {
      const cType = criterion.Type || criterion.type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const c: any = {...criterion}
      delete c.type
      stringObjects.push(cType + '::' + JSON.stringify(c))
    }
  }
  return stringObjects  
}