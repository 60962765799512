import {marshalCriteria} from 'popcorn-js/search/marshallerV2'
import {jsonRpcRequestRaw} from 'actions/helper/utilities';
import config from 'react-global-configuration';
import {Criteria, Query} from 'popcorn-js/search';


export interface DownloadListRequest {
  criteria: Criteria,
  query: Query,
}
export interface DownloadListResponse {
  data: string
}
export const Downloader = {
  ServiceProviderName: 'Operations-Downloader',
  DownloadList(request: DownloadListRequest): Promise<DownloadListResponse> {
    const serializedCriteria = marshalCriteria(request.criteria)
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: `${Downloader.ServiceProviderName}.DownloadList`,
      request: {
        ...request,
        criteria: serializedCriteria,
      },
      accessToken: ''
    })
  },
}

export default Downloader