class EntryComparator {
  /**
   * @param {Entry} a
   * @param {Entry} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareExcluding(a, b, ...args) {
    return !((a.time !== b.time && !args.includes('time')) ||
      (a.action !== b.action && !args.includes('action')) ||
      (a.userId !== b.userId && !args.includes('userId')) ||
      (a.version !== b.version && !args.includes('version')) ||
      (a.username !== b.username && !args.includes('username')))
  }

  /**
   *
   * @param {Entry} a
   * @param {Entry} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareOnly(a, b, ...args) {
    for (const field in args) {
      if (a[args[field]] !== b[args[field]]) {
        return false
      }
    }
    return true
  }

  /**
   *
   * @param {Entry} a
   * @param {Entry} b
   * @return {boolean}
   */
  static CompareAll(a, b) {
    return !(
      (a.time !== b.time) ||
      (a.action !== b.action) ||
      (a.userId !== b.userId) ||
      (a.version !== b.version) ||
      (a.username !== b.username)
    )
  }
}

export default EntryComparator
