import React from 'react'
import PropTypes from 'prop-types'
// Material UI components
import {Card, CardHeader, ListItem, makeStyles,} from '@material-ui/core'

import List from '@material-ui/core/List'
import {HexToRGBA} from 'utils/Utils'

const useStyles = makeStyles(theme => ({
  root: {},
  cardHeaderRoot: {
    backgroundColor: HexToRGBA(theme.palette.text.primary, .1),
    padding: theme.spacing(1),
  },
  list: {
    height: '100%',
  },
  listItemRoot: {
    '&:hover': {
      background: 'linear-gradient(to right, #229ED9 0%, #229ED9 70%, #3F51B5 100%);',
    },
    '&$listItemSelected': {
      background: `linear-gradient(to right, ${HexToRGBA('#229ED9', .5)} 0%, ${HexToRGBA('#229ED9', .5)} 70%, ${HexToRGBA('#3F51B5', .5)} 100%);`,
    },
  },
  listItemSelected: {},
}))

const RoleList = props => {
  const classes = useStyles()
  const {roles, selected, handleSelect} = props

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{
          root: classes.cardHeaderRoot,
        }}
        title="Name"
        titleTypographyProps={{
          variant: 'body1',
        }}
      />
      <List
        className={classes.list}
        component="ul"
      >
        {roles.map(role => (
          <ListItem
            button
            classes={{
              root: classes.listItemRoot,
              selected: classes.listItemSelected,
            }}
            component="li"
            key={role.id}
            onClick={() => handleSelect(role)}
            selected={role.id === selected.id}
          >
            {role.name}
          </ListItem>
        ))}
      </List>
    </Card>
  )
}

RoleList.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  roles: PropTypes.array,
  selected: PropTypes.object,
}

export default RoleList
