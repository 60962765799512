import {Entry as AuditEntry} from 'popcorn-js/audit'

class Currency {
  static  camel = 'currency'
  static  camelP = 'currencies'
  static  nice = 'currency'
  static  niceP = 'currencies'
  static  capital = 'Currency'
  static  capitalP = 'Currencies'
  /**
   * @type {string}
   */
  id = ''
  /**
   * @type {string}
   */
  isoCode = ''
  /**
   * @type {string}
   */
  name = ''
  /**
   * @type {string}
   */
  symbol = ''
  /**
   * @type {AuditEntry}
   */
  auditEntry = new AuditEntry()

  /**
   * @param {object|Currency} [currency]
   */
  constructor(currency) {
    if (
      (currency !== undefined)
    ) {
      try {
        this.id = currency.id
        this.isoCode = currency.isoCode
        this.name = currency.name
        this.symbol = currency.symbol
        this.auditEntry = new AuditEntry(currency.auditEntry)
      } catch (e) {
        console.error(`error constructing currency: ${e}`)
        throw e
      }
    }
  }

}

export default Currency

