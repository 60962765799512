import React, {useState} from 'react'
import {
  AppBar,
  Avatar,
  Button,
  Divider,
  Icon,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {FiArrowLeft, FiChevronDown, FiMenu,} from 'react-icons/fi'
import {PARTY_TYPE_SYSTEM} from '../../../constants/partyTypes'
import {IoIosPerson} from 'react-icons/io'
import StyledPopper from '../../Popper/Popper'
import UserInfo from '../../UserInfo/UserInfo'
import {useSelector} from 'react-redux'
import {HexToRGBA} from 'utils/Utils'
import {NavLink} from 'react-router-dom';
import {Notifications as NotificationIcon, Settings as ConfigurationIcon} from '@material-ui/icons';
import UpdateTime from 'components/UpdateTime/UpdateTimes';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumb';
import {RefreshIcon} from 'components/Icons';
import {Generator} from 'popcorn-js/exceptionReport/generator';
import saveAs from 'file-saver';

const useStyles = makeStyles(theme => ({
  appBarRoot: {
    paddingLeft: '1px',
    height: '55px',
    color: theme.palette.text.main,
    backgroundColor: theme.palette.secondary.main,
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'auto 1fr 1fr',
    alignItems: 'center',
    zIndex: 10,
  },
  menuButtonWrapper: {
    height: 'inherit',
    width: '61.5px',
    borderRight: `1px solid ${HexToRGBA(theme.palette.secondary.border, 1)}`,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.content,
    '&:hover': {
      color: theme.palette.secondary.buttonHover,
    },
  },
  userPartyDetailsLayout: {
    justifySelf: 'end',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  userActionsLayout: {
    justifySelf: 'start',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  usernamePartyName: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '17px',
    textAlign: 'right',
    paddingTop: '7px',
    marginRight: '5px',
    color: theme.palette.secondary.content,
  },
  avatarLayout: {
    display: 'grid',
    gridTemplateColumns: '40px 40px',
    alignItems: 'center',
    height: 'inherit',
    marginRight: theme.spacing()
  },
  avatarUser: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    fontSize: '20px',
    fontWeight: '850',
    zIndex: 1,
    cursor: 'pointer',
    borderWidth: '0px',
    left: '12px',
    width: '40px !important',
    height: '40px !important',
  },
  avatarLegalEntity: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    fontSize: '20px',
    fontWeight: '850',
    width: '40px !important',
    height: '40px !important',
  },
  menuItems: {
    maxHeight: '25px',
    minHeight: 'auto',
    marginLeft: '15px',
    marginRight: '15px',
    paddingLeft: '0px',
    paddingRight: '0px',
    borderBottom: `solid 1px ${theme.palette.text.primary}`,
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  switchPartyButtonIcon: {
    fontSize: '20px',
    marginRight: '12px',
  },
  switchPartyButtonText: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',
    color: theme.palette.secondary.button,
    '&:hover': {
      color: HexToRGBA(theme.palette.secondary.buttonHover, 1),
    },
  },
  switchPartyButtonRoot: {
    marginTop: '7px',
    marginLeft: '5px',
    color: theme.palette.secondary.button,
    '&:hover': {
      color: HexToRGBA(theme.palette.secondary.buttonHover, 1),
    },
  },
  switchPartyMenuItem: {
    maxHeight: '25px',
    minHeight: 'auto',
    marginLeft: '15px',
    marginRight: '15px',
    paddingLeft: '0px',
    paddingRight: '0px',
    borderBottom: 'solid 1px rgba(255,255,255, 0.5)',
    fontWeight: 600,
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  returnPartyMenuItem: {
    maxHeight: '25px',
    minHeight: 'auto',
    fontWeight: 600,
    justifyContent: 'center',
  },
  returnIcon: {
    fontSize: 'large',
  },
  appBarIcon: {
    color: HexToRGBA(theme.palette.secondary.button, 1),
    '&:hover': {
      color: HexToRGBA(theme.palette.secondary.buttonHover, 1),
    },

  },
  appBarSectionsUpdatedText: {
    height: 'inherit',
    display: 'flex',
    justifySelf: 'flex-start',
    marginRight: theme.spacing(1),
  },
  updatedText: {
    color: theme.palette.text.accent,
    fontWeight: 'bold',
    alignSelf: 'center'
  },
  appBarSectionsBreadcrumbs: {
    height: 'inherit',
    justifySelf: 'flex-start',
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.content,
  },
  appBarSectionsBorder: {
    borderRight: `1px solid ${HexToRGBA(theme.palette.secondary.border, 1)}`,
    height: '32px'
  },
  navLinkColor: {
    color: theme.palette.text.primary,
    '&,&:hover,&:focus': {
      color: theme.palette.text.primary
    }
  },
}))

export default function PrimaryAppBar(props) {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [anchorEl3, setAnchorEl3] = useState(null)
  const [exceptionReportAnchorEl, setExceptionReportAnchorEl] = useState(null)

  let showConfiguration = false
  for (const route of props.viewPermissions) {
    if (route === 'View.Configuration') {
      showConfiguration = true
      break
    }
  }

  let showExceptionReport = false
  for (const route of props.viewPermissions) {
    if (route === 'View.ExceptionReport') {
      showExceptionReport = true
      break
    }
  }

  function ArrowDownClick(event) {
    setAnchorEl(event.currentTarget ? event.currentTarget : null)
  }

  function UserAvatarClick(event) {
    setAnchorEl2(event.currentTarget ? event.currentTarget : null)
  }

  function ConfigurationClick(event) {
    setAnchorEl3(event.currentTarget ? event.currentTarget : null)
  }

  function ExceptionReportClick(event) {
    setExceptionReportAnchorEl(event.currentTarget ? event.currentTarget : null)
  }

  async function exportUnread() {
    try {
      const exportUnreadResult = await Generator.ExportUnread()

      // convert base64 to byte array
      const binData = atob(exportUnreadResult.data)

      const bytes = new Array(binData.length)
      for (let i = 0; i < binData.length; i++) {
        bytes[i] = binData.charCodeAt(i)
      }
      const blob = new Blob([new Uint8Array(bytes)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8'
      })
      saveAs(blob, 'integrationExceptions.xlsx')
    } catch (e) {

    } finally {

    }
  }

  async function exportAll() {
    try {
      const exportAllResult = await Generator.ExportAll()

      // convert base64 to byte array
      const binData = atob(exportAllResult.data)

      const bytes = new Array(binData.length)
      for (let i = 0; i < binData.length; i++) {
        bytes[i] = binData.charCodeAt(i)
      }
      const blob = new Blob([new Uint8Array(bytes)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8'
      })
      saveAs(blob, 'integrationExceptions.xlsx')
    } catch (e) {
      // TODO
    } finally {
      // TODO
    }
  }

  async function exportRecurring() {
    try {
      const exportAllResult = await Generator.ExportRecurring()

      // convert base64 to byte array
      const binData = atob(exportAllResult.data)

      const bytes = new Array(binData.length)
      for (let i = 0; i < binData.length; i++) {
        bytes[i] = binData.charCodeAt(i)
      }
      const blob = new Blob([new Uint8Array(bytes)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8'
      })
      saveAs(blob, 'integrationExceptions.xlsx')
    } catch (e) {
      // TODO
    } finally {
      // TODO
    }
  }

  const userDetail = useSelector(state => state.myUser.userProfile)

  return (
    <AppBar
      classes={{root: classes.appBarRoot}}
      position="static"
    >
      <div className={classes.menuButtonWrapper}>
        <IconButton
          className={classes.appBarIcon}
          onClick={props.sidebarMinimize}
          size={'medium'}
        >
          <FiMenu/>
        </IconButton>
      </div>
      <div className={classes.userActionsLayout}>
        <div className={classes.appBarSectionsBreadcrumbs}>
          <BreadCrumbs
            {...props}
          />
        </div>
        <div className={classes.appBarSectionsBorder}>
          &nbsp;
        </div>
        <Tooltip
          placement={'top'}
          title={'Refresh app'}
        >
          <div>
            <IconButton
              className={classes.appBarIcon}
              onClick={() => window.location.reload()}
            >
              <RefreshIcon/>
            </IconButton>
          </div>
        </Tooltip>
        <div className={classes.appBarSectionsUpdatedText}>
          <UpdateTime
            {...props}
          />
        </div>
      </div>
      <div className={classes.userPartyDetailsLayout}>
        <div className={classes.usernamePartyName}>
          {userDetail.firstName + ' ' + userDetail.lastName}<br/>
          {props.securityClaims.context.name.toUpperCase()}
        </div>
        <div className={classes.avatarLayout}>
          <Avatar
            className={classes.avatarUser}
            component={'button'}
            imgProps={{className: classes.avatarUser}}
            onClick={UserAvatarClick}

          >
            <IoIosPerson/>
          </Avatar>
          <Avatar className={classes.avatarLegalEntity}>
            {props.securityClaims.context.name[0]}
          </Avatar>
          <LogOutMenu
            anchorEl={anchorEl2}
            history={props.history}
            onClose={setAnchorEl2}
            onLogout={props.onLogout}
          />
        </div>
        {showExceptionReport && <Tooltip
          placement={'top'}
          title={'Exception Report'}
        >
          <IconButton
            aria-label="Open drawer"
            className={classes.appBarIcon}
            onClick={ExceptionReportClick}
          >
            <NotificationIcon/>
          </IconButton>

        </Tooltip>
        }
        <ExceptionReportMenuItems
          anchorEl={exceptionReportAnchorEl}
          exportAll={exportAll}
          exportRecurring={exportRecurring}
          exportUnread={exportUnread}
          onClose={setExceptionReportAnchorEl}
        />
        {showConfiguration && <Tooltip
          placement={'top'}
          title={'Configurations'}
        >
          <IconButton
            aria-label="Open drawer"
            className={classes.appBarIcon}
            onClick={ConfigurationClick}
          >
            <ConfigurationIcon/>
          </IconButton>

        </Tooltip>
        }
        <ConfigurationsMenuItems
          anchorEl={anchorEl3}
          onClose={setAnchorEl3}
          routes={props.routes}
          viewPermissions={props.viewPermissions}
        />
        {hasExtendedContext(props.extendedContext) &&
        <React.Fragment>
          <Button
            classes={{
              root: classes.switchPartyButtonRoot,
              label: classes.switchPartyButtonText
            }}
            onClick={ArrowDownClick}
          >
            <FiChevronDown className={classes.switchPartyButtonIcon}/>
            Switch Party
          </Button>
          <ContextSwitcher
            anchorEl={anchorEl}
            extendedContext={props.extendedContext}
            onClose={setAnchorEl}
            onContextSwitch={props.onContextSwitch}
            securityClaims={props.securityClaims}
          />
        </React.Fragment>}
      </div>
    </AppBar>
  )
}

function compare(a, b) {

  let comparison = 0;
  if (a.name > b.name) {
    comparison = 1;
  } else if (a.name < b.name) {
    comparison = -1;
  }
  return comparison;
}

//This temp until design is finalized
function ContextSwitcher(props) {

  const {context, originalContext} = props.securityClaims
  const extendedContext = props.extendedContext
  const classes = useStyles()


  const contextTypes = Object.keys(extendedContext)

  const menuItems = []

  const showReturnButton = originalContext.partyCode !== context.partyCode


  menuItems.push(
    <MenuItem
      className={classes.switchPartyMenuItem}
      component={'div'}
      disableRipple
      key={'SWT*'}
    >
      <div>
        <Typography>
          Switch Party
        </Typography>
      </div>
      <Divider/>
    </MenuItem>
  )


  const allParties = []
  for (const contextType of contextTypes) {
    if (!['companies',  'processingOrgs'].includes(contextType)) {
      // for now, only allow switching to companies
      continue
    }

    // only the system user can switch to processing org
    if (contextType === 'processingOrgs') {
      if (originalContext.partyType !== PARTY_TYPE_SYSTEM) {
        // don't add the option
        continue
      }
    }


    // don't allow switching to yourself (should use switch back instead)
    const filteredExtendedContext = extendedContext[contextType]
      .filter(ctx => ctx.partyCode !== originalContext.partyCode &&
        ctx.partyCode !== context.partyCode)

    for (const ctx of filteredExtendedContext) {
      allParties.push(ctx)
    }
  }

  allParties.sort(compare);
  for (const ctx of allParties) {
    menuItems.push(
      <MenuItem
        className={classes.menuItems}
        component={'div'}
        key={ctx.partyCode}
        onClick={() => props.onContextSwitch(ctx.partyCode)}

      >
        {ctx.name}
      </MenuItem>
    )
  }

  if (showReturnButton) {
    menuItems.push(
      <MenuItem
        className={classes.returnPartyMenuItem}
        key={'RTN*'}
        onClick={() => props.onContextSwitch(originalContext.partyCode)}
      >
        <Tooltip title={'Return'}>
          <Icon
            aria-haspopup="true"
            aria-label="Show more"
            className={classes.returnIcon}
            color="inherit"
            onClick={() => props.onContextSwitch(originalContext.partyCode)}
          >
            <FiArrowLeft/>
          </Icon>
        </Tooltip>
      </MenuItem>
    )
  }


  return (
    <StyledPopper
      anchorEl={props.anchorEl}
      onClose={props.onClose}
    >
      <Paper>
        <MenuList
          id="menuList"
        >
          {menuItems}
        </MenuList>
      </Paper>
    </StyledPopper>
  )

}

function hasExtendedContext(extendedContext) {
  if (extendedContext) {
    const contextTypes = Object.keys(extendedContext)
    if (contextTypes.length > 1) {
      return true
    } else {
      for (const context of contextTypes) {
        if (context === 'companies' && extendedContext[contextTypes].length > 1) {
          return true
        }
        if (context === 'processingOrgs' && extendedContext[contextTypes].length > 1) {
          return true
        }
        if (context === 'processingBanks' && extendedContext[contextTypes].length > 1) {
          return true
        }
        if (context === 'brokers' && extendedContext[contextTypes].length > 1) {
          return true
        }
        if (context === 'individuals' && extendedContext[contextTypes].length > 1) {
          return true
        }

      }
      return false
    }
  }
  return false

}

function ConfigurationsMenuItems(props) {
  let configurationAppRoutes = undefined
  const classes = useStyles()

  for (const route of props.routes) {
    if (route.name === 'Configuration') {
      configurationAppRoutes = route
      break
    }
  }

  //Filter based on permissions
  const filteredConfigView = props.viewPermissions.reduce((newConfig, viewPermission) => {
    for (const configView of configurationAppRoutes.views) {
      if (configView.permission === viewPermission) {
        newConfig.push(configView)
        break
      }
    }
    return newConfig
  }, [])
  const menuItems = []
  let key = 0
  for (const configView of filteredConfigView) {
    menuItems.push(
      <NavLink
        className={classes.navLinkColor}
        id={configView.linkID}
        key={key++}
        to={configView.path}
      >
        <MenuItem
          className={classes.menuItems}
          value={configView.name}
        >
          {configView.name}
        </MenuItem>
      </NavLink>
    )
  }
  return (
    <StyledPopper
      anchorEl={props.anchorEl}
      onClose={props.onClose}
    >
      <Paper>
        <MenuList
          id="confifgurationsOptionsMenu"
        >
          {menuItems}
        </MenuList>
      </Paper>
    </StyledPopper>

  )

}

function ExceptionReportMenuItems(props) {
  const classes = useStyles()
  //Filter based on permissions

  const menuItems = [
    <MenuItem
      className={classes.menuItems}
      key={1}
      onClick={props.exportAll}
      value={'Export All'}
    >
      {'Export All'}
    </MenuItem>,
    <MenuItem
      className={classes.menuItems}
      key={2}
      onClick={props.exportUnread}
      value={'Export Unread'}
    >
      {'Export Unread'}
    </MenuItem>,
    <MenuItem
      className={classes.menuItems}
      key={2}
      onClick={props.exportRecurring}
      value={'Export Recurring'}
    >
      {'Export Recurring'}
    </MenuItem>
  ]

  return (
    <StyledPopper
      anchorEl={props.anchorEl}
      onClose={props.onClose}
    >
      <Paper>
        <MenuList
          id="confifgurationsOptionsMenu"
        >
          {menuItems}
        </MenuList>
      </Paper>
    </StyledPopper>

  )

}

//This temp until design is finalized
function LogOutMenu(props) {
  return (
    <StyledPopper
      anchorEl={props.anchorEl}
      onClose={props.onClose}
    >
      <UserInfo
        history={props.history}
        onLogout={props.onLogout}
      />
    </StyledPopper>

  )
}
