import {createStyles, makeStyles, Typography, useTheme} from '@material-ui/core'
import React from 'react'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Button from '@material-ui/core/Button'
import TablePagination from '@material-ui/core/TablePagination'
import Card from './Card'
import {FormatNumber} from 'utils/TradeUtilities'
import {HexToRGBA} from "../../utils/Utils";

const useStyles = makeStyles((theme) =>
  createStyles({

    rootActions: {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '8px',
      marginTop: theme.spacing(2),
      marginBottom: 0,
    },
    actionEntry: {
      gridTemplateRows: '1fr 1fr 1fr',
      padding: '16px',
      borderBottom: `1px solid #4C497D`
    },
    actionEntrySelect: {
      gridTemplateRows: '1fr 1fr 1fr',
      backgroundColor: '#3F407C',
      padding: '16px'
    },
    actionsGridColumnLayout: {
      display: 'grid',
      gridTemplateColumns: '1fr 1.5fr 3.5fr',
    },
    actionsDueDateColumnLayout: {
      display: 'grid',
      gridTemplateColumns: '1fr 6.5fr',
    },
    actionsStatusColumnLayout: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
    },
    statusText: {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(),
    },
    actionTypeText: {
      fontSize: '14px',
      lineHeight: '14px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(),
    },
    actionDueDateText: {
      fontSize: '12px',
      lineHeight: '14px',
      display: 'flex',
      alignItems: 'center',
      color: '#45B2F7',
    },
    actionDueDateIcon: {
      width: '14px',
      fill: '#45B2F7',
    },
    dueDateTextImport: {
      fontSize: '12px',
      lineHeight: '14px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.import.main,
    },
    dueDateTextExport: {
      fontSize: '12px',
      lineHeight: '14px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.export.main,
    },
    dueDateIconImport: {
      width: '14px',
      fill: theme.palette.import.main,
    },
    dueDateIconExport: {
      width: '14px',
      fill: theme.palette.export.main,
    },
    buttonContainedPrimaryActions: {
      color: '#1A1B36',
      fontWeight: 'bold',
      fontSize: '12px',
      backgroundColor: '#45B2F7',
      '&:hover': {
        backgroundColor: HexToRGBA('#45B2F7', 0.6),
      },
      width: '70px',
      height: '28px',
    },
    actionCurrencyText: {
      margin: 'auto',
      marginLeft: '0',
    },
    amountText: {
      margin: 'auto',
      marginRight: '12px',
    },
    helperText: {
      margin: 'auto',
      marginLeft: '16px',
    },
    footerGridColumnLayout: {
      display: 'flex',
      gridTemplateColumns: '1fr 1fr',
    },
  }),
)

const Actions = (props) => {
  const {
    actionsData,
    actionsPage,
    actionsLimit,
    actionsOffset,
    handleChangePageActions,
    handleResolveClick,
    handleShowResolve,
  } = props

  const classes = useStyles(useTheme())

  function getActionsDataToDisplay() {
    if (actionsData) {
      return actionsData.slice(actionsOffset, actionsOffset + actionsLimit)
    }
  }

  return (
    <Card
      additionalActions={[]}
      rootClass={classes.rootActions}
      title={<Typography
        variant={'h5'}>Actions</Typography>}
    >
      <div
        style={{
          height: 321
        }}>
        {(getActionsDataToDisplay() || []).map(d => {
          let colorClass
          let colorClassButton
          switch (d.importExport) {
            case "Import":
              colorClass = classes.dueDateTextImport
              colorClassButton = classes.dueDateIconImport
              break
            case "Export":
              colorClass = classes.dueDateTextExport
              colorClassButton = classes.dueDateIconExport
              break
            default:
              colorClass = classes.actionDueDateText
              colorClassButton = classes.actionDueDateIcon
          }
          return (
            <div
              className={d.buttonShow ? classes.actionEntrySelect : classes.actionEntry}
              key={d.id}
              onClick={() => handleShowResolve(d)}
            >
              <div className={classes.actionsGridColumnLayout}>
                <div className={classes.actionTypeText}>
                  {d.type}
                </div>
                <div className={classes.actionCurrencyText}>
                  {d.foreignCurrency}
                </div>
                <div className={classes.amountText}>
                   {d.currencySymbol + ' ' + FormatNumber(d.amount, true, true, 2, true)}
                </div>
              </div>
              <div className={classes.actionsDueDateColumnLayout}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '8px',
                  }}>
                  <AccessTimeIcon class={colorClassButton}/>
                </div>
                <div className={colorClass}>
                  Due: {d.date}
                </div>
              </div>
              <div className={classes.actionsStatusColumnLayout}>
                <div className={classes.statusText}>
                  Status: {d.status}
                </div>
                {d.buttonShow && <Button
                  classes={{
                    containedPrimary: classes.buttonContainedPrimaryActions
                  }}
                  color={'primary'}
                  id={d.id}
                  onClick={() => handleResolveClick(d)}
                  variant={'contained'}
                >Resolve</Button>}
              </div>
            </div>
          )
        })}
      </div>
      <div className={classes.footerGridColumnLayout}>
        <div className={classes.helperText}>&nbsp;</div>
        <div>
          <TablePagination
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            colSpan={3}
            count={(actionsData || []).length}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePageActions}
            page={actionsPage}
            rowsPerPage={actionsLimit}
            rowsPerPageOptions={[]}
          />
        </div>
      </div>
    </Card>
  )
}

export default Actions

