export class GeneratePositionReportForCurrencyRequest {
  /**
   * @type {string}
   */
  currencyId = ''
}

export class GeneratePositionReportForCurrencyResponse {
  //
  //	Invoice
  //
  /**
   * @type {{
   *  import: number,
   *  export: number,
   * }}
   */
  invoiceUnpaidTotal = {
    import: 0,
    export: 0,
  }

  /**
   * @type {{
   *  withCostingRate: {import: number, export: number},
   *  withCaptureRate: {import: number, export: number},
   *  withSpotRate: {import: number, export: number},
   * }}
   */
  invoiceUnrealisedProfitAndLoss = {
    withCostingRate: {
      import: 0,
      export: 0,
    },
    withCaptureRate: {
      import: 0,
      export: 0,
    },
    withSpotRate: {
      import: 0,
      export: 0,
    },
  }

  //
  //	Order
  //
  /**
   * @type {{
   *  import: number,
   *  export: number,
   * }}
   */
  orderTotal = {
    import: 0,
    export: 0,
  }

  /**
   * @type {{
   *  withCostingRate: {import: number, export: number},
   *  withCaptureRate: {import: number, export: number},
   * }}
   */
  orderAverageRate = {
    withCostingRate: {
      import: 0,
      export: 0,
    },
    withCaptureRate: {
      import: 0,
      export: 0,
    },
  }

  /**
   * @type {{
   *  withCostingRate: {import: number, export: number},
   *  withCaptureRate: {import: number, export: number},
   *  withSpotRate: {import: number, export: number},
   * }}
   */
  orderUnrealisedProfitAndLoss = {
    withCostingRate: {
      import: 0,
      export: 0,
    },
    withCaptureRate: {
      import: 0,
      export: 0,
    },
    withSpotRate: {
      import: 0,
      export: 0,
    },
  }

  //
  //	Orders/Invoices
  //

  // 1a,b
  /**
   * @type {{
   *  import: number,
   *  export: number,
   * }}
   */
  ordersInvoicesUnpaidTotal = {
    // 1a
    import: 0,
    // 1b
    export: 0,
  }

  // 2a,b
  /**
   * @type {{
   *  withCostingRate: {import: number, export: number},
   *  withCaptureRate: {import: number, export: number},
   *  withSpotRate: {import: number, export: number},
   * }}
   */
  ordersInvoicesAverageRate = {
    // 2a,b.i
    withCostingRate: {
      import: 0,
      export: 0,
    },
    // 2a,b.ii
    withCaptureRate: {
      import: 0,
      export: 0,
    },
    // 2a,b.iii
    withSpotRate: {
      import: 0,
      export: 0,
    },
  }

  // 3a,b
  /**
   * @type {{
   *  withCostingRate: {import: number, export: number},
   *  withCaptureRate: {import: number, export: number},
   *  withSpotRate: {import: number, export: number},
   * }}
   */
  ordersInvoicesUnrealisedProfitAndLoss = {
    // 3a,b.i
    withCostingRate: {
      import: 0,
      export: 0,
    },
    // 3a,b.ii
    withCaptureRate: {
      import: 0,
      export: 0,
    },
    // 3a,b.iii
    withSpotRate: {
      import: 0,
      export: 0,
    },
  }

  //
  //	Trade
  //

  // 4a,b
  /**
   * @type {{
   *  import: number,
   *  export: number,
   * }}
   */
  tradeTotal = {
    // 4a
    import: 0,
    // 4b
    export: 0,
  }

  // 5a,b
  /**
   * @type {{
   *  import: number,
   *  export: number,
   * }}
   */
  tradeAverageRate = {
    // 5a
    import: 0,
    // 5b
    export: 0,
  }

  // 6a,b
  /**
   * @type {{
   *  import: number,
   *  export: number,
   * }}
   */
  tradeUnrealisedProfitAndLoss = {
    // 6a
    import: 0,
    // 6b
    export: 0,
  }

  // 7a,b
  /**
   * @type {{
   *  import: number,
   *  export: number,
   * }}
   */
  netTotal = {
    // 7a
    import: 0,
    // 7b
    export: 0,
  }

  // 8a,b
  /**
   * @type {{
   *  withCostingRate: {import: number, export: number},
   *  withCaptureRate: {import: number, export: number},
   *  withSpotRate: {import: number, export: number},
   * }}
   */
  netAverageRate = {
    // 8a,b.i
    withCostingRate: {
      import: 0,
      export: 0,
    },
    // 8a,b.ii
    withCaptureRate: {
      import: 0,
      export: 0,
    },
    // 8a,b.iii
    withSpotRate: {
      import: 0,
      export: 0,
    },
  }

  // 9a,b
  /**
   * @type {{
   *  withCostingRate: {import: number, export: number},
   *  withCaptureRate: {import: number, export: number},
   *  withSpotRate: {import: number, export: number},
   * }}
   */
  netUnrealisedProfitAndLoss = {
    // 9a,b.i
    withCostingRate: {
      import: 0,
      export: 0,
    },
    // 9a,b.ii
    withCaptureRate: {
      import: 0,
      export: 0,
    },
    // 9a,b.iii
    withSpotRate: {
      import: 0,
      export: 0,
    },
  }

  // 10a,b
  /**
   * @type {{
   *  import: number,
   *  export: number,
   * }}
   */
  netPercentageHedged = {
    // 10a
    import: 0,
    // 10b
    export: 0,
  }

  //
  //	Total Realised PnL
  //

  // 11
  /**
   * @type {{
   *  withCostingRate: number,
   *  withCaptureRate: number,
   * }}
   */
  totalRealisedPnL = {
    // 11.i
    withCostingRate: 0,
    // 11.ii
    withCaptureRate: 0,
  }

  //
  //	Total Unrealised PnL
  //

  // 12
  /**
   * @type {{
   *  withCostingRate: number,
   *  withCaptureRate: number,
   *  withSpotRate: number,
   * }}
   */
  totalUnrealisedPnL = {
    // 12.i
    withCostingRate: 0,
    // 12.ii
    withCaptureRate: 0,
    // 12.iii
    withSpotRate: 0,
  }

  //
  //	Net Position
  //

  // 13
  /**
   * @type {{
   *  withCostingRate: number,
   *  withCaptureRate: number,
   * }}
   */
  netCurrencyPositionAverageRate = {
    // 13.i
    withCostingRate: 0,
    // 13.ii
    withCaptureRate: 0,
  }

  //
  //	%Hedged
  //

  // 14
  /**
   * @type {number}
   */
  percentageHedged = 0

  //
  // Net Currency Position
  //

  // 15
  /**
   * @type {number}
   */
  currencyPosition = 0

  //
  // Spot Rate
  //

  // 16
  /**
   * @type {number}
   */
  spotRate = 0

  // 17
  /**
   * @type {{
   *  withCostingRate: number,
   *  withCaptureRate: number,
   *  withSpotRate: number,
   * }}
   */
  totalPnL = {
    // 17a
    withCostingRate: 0,
    // 17b
    withCaptureRate: 0,
  }

  /**
   * @type {string}
   */
  excelData = undefined

  // ACM
  acmPosition = {
    // 10a
    import: 0,
    // 10b
    export: 0,
  }
}

export class GenerateDollarPortfolioReportRequest {

}
export class GenerateCashFlowReportRequest {

}

export class GenerateCashFlowReportResponse {
  excelData = ''
}

export class GenerateDollarPortfolioReportResponse {
  //
  // % Hedged
  //
  // 1
  /**
   * @type {number}
   */
  percentageHedged = 0

  //
  //	Net Position
  //
  // 2a
  /**
   * @type {number}
   */
  dollarNetPosition = 0

  // 2b
  /**
   * @type {number}
   */
  localCurrencyNetPosition = 0

  //
  // Profit and Loss
  //

  // 3a,b,c
  /**
   * @type {{
   *  withCostingRate: number,
   *  withCaptureRate: number,
   *  withSpotRate: number,
   * }}
   */
  realisedPnL = {
    // 3a
    withCostingRate: 0,
    // 3b
    withCaptureRate: 0,
    // 3c
    withSpotRate: 0,
  }

  // 4a,b,c
  /**
   * @type {{
   *  withCostingRate: number,
   *  withCaptureRate: number,
   *  withSpotRate: number,
   * }}
   */
  unrealisedPnL = {
    // 4a
    withCostingRate: 0,
    // 4b
    withCaptureRate: 0,
    // 4c
    withSpotRate: 0,
  }

  // 5a,b,c
  /**
   * @type {{
   *  withCostingRate: number,
   *  withCaptureRate: number,
   *  withSpotRate: number,
   * }}
   */
  totalPnL = {
    // 5a
    withCostingRate: 0,
    // 5b
    withCaptureRate: 0,
    // 5c
    withSpotRate: 0,
  }

  /**
   * @type {number}
   */
  pnlFromACM = 0
  /**
   * @type {number}
   */
  totalRealisedPnl = 0
  /**
   * @type {number}
   */
  totalUnRealisedPnl = 0
  /**
   * @type {number}
   */
  totalPnlWithACM = 0
  /**
   * @type {number}
   */
  totalImportExposureMtm = 0

  /**
   * @param {GenerateDollarPortfolioReportResponse|Object} [response]
   */
  constructor(response) {
    if (response && typeof response === 'object') {
      this.dollarNetPosition = response.dollarNetPosition
      this.localCurrencyNetPosition = response.localCurrencyNetPosition
      this.percentageHedged = response.percentageHedged
      this.realisedPnL = response.realisedPnL
      this.unrealisedPnL = response.unrealisedPnL
      this.totalPnL = response.totalPnL
      this.pnlFromACM = response.pnlFromACM
      this.totalRealisedPnl = response.totalRealisedPnl
      this.totalUnRealisedPnl = response.totalUnRealisedPnl
      this.totalPnlWithACM = response.totalPnlWithACM
      this.totalImportExposureMtm = response.totalImportExposureMtm
    }
  }

}
