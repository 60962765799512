import React from 'react'
import {IconButton, Tooltip} from '@material-ui/core';

import {Save,} from '@material-ui/icons'

export const saveAction = onClick => (
  <Tooltip
    key={'save'}
    title={'Save Changes'}>
    <IconButton onClick={onClick}>
      <Save/>
    </IconButton>
  </Tooltip>
)

