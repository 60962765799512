const Theme = {
  Primary: '#3e4a57',
  PrimaryRGB: '62, 74, 87',
  Secondary: '#efefef',
  SecondaryRGB: '239, 239, 239',

  Color1: '#2d3e50',
  Color1RGB: '44, 62, 80',
  Color2: '#efefef',
  Color2RGB: '239, 239, 239',
  Color3: '#1d2a36',
  Color3RGB: '29, 42, 54',
  Color4: '#bdc3c7',
  Color4RGB: '189, 189, 189',

  FontColor1: '#ffffff',
  FontColor2: '#2B3E4F',

  Green: '#389239',
  Red: '#bd1212',

  Green2: '#1fb076',
  Red2: '#ff384c',

  Font1: 'Open Sans',
}

export default Theme
