import React from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'


const useStyles = makeStyles(() => ({
  root: {
    height: '32px',
    width: '31px',
    strokeWidth: '2'
  },
})
)


export function TradeStationIcon() {
  const classes = useStyles()
  const theme = useTheme()


  return (
    <SvgIcon
      className={classes.root}
      htmlColor={theme.palette.primary.contrastText}
      viewBox="0 0 32 32"

    >
      <g
        id="Trade_Station">
        <g
          id="Group_18"
          transform="translate(-454.29 -624.086)">
          <g
            id="Group_13">
            <g
              id="Group_12">
              <path
                d="M467.871,633.9a.5.5,0,0,1-.327-.878l4.678-4.03-4.678-4.031a.5.5,0,0,1,.653-.758l5.117,4.41a.5.5,0,0,1,0,.758l-5.117,4.408a.5.5,0,0,1-.326.121Z"
                id="Path_9"/>
            </g>
          </g>
          <g
            id="Group_14">
            <path
              d="M457,639.773a.5.5,0,0,1-.3-.1,6.167,6.167,0,0,1,3.759-11.056h11.666a.5.5,0,0,1,0,1H460.458a5.167,5.167,0,0,0-3.15,9.263.5.5,0,0,1-.305.9Z"
              id="Path_10"/>
          </g>
          <g
            id="Group_16">
            <g
              id="Group_15">
              <path
                d="M465.345,644.758a.5.5,0,0,1-.326-.121l-5.117-4.41a.5.5,0,0,1,0-.758l5.117-4.408a.5.5,0,1,1,.652.758l-4.677,4.029,4.677,4.031a.5.5,0,0,1-.326.879Z"
                id="Path_11"/>
            </g>
          </g>
          <g
            id="Group_17">
            <path
              d="M472.757,640.231H461.091a.5.5,0,1,1,0-1h11.666a5.167,5.167,0,0,0,3.151-9.263.5.5,0,0,1,.609-.793,6.167,6.167,0,0,1-3.76,11.056Z"
              id="Path_12"/>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
