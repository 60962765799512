import React from 'react'
import PropTypes from 'prop-types'
// Material UI components
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
// Custom components
import Permission from './Permission'
import {FixedSizeList} from 'react-window'
// Icons
import {MdSearch as SearchIcon} from 'react-icons/md'
// Styles
import {roleDetailStyles as styles} from './styles'

const useStyles = makeStyles(styles)

const RoleDetails = props => {
  const classes = useStyles()
  const {role, disabled} = props

  const [permissionList, setPermissionList] = React.useState({
    available: props.permissions || [],
    assigned: [],
  })

  const [search, setSearch] = React.useState({
    available: '',
    assigned: '',
  })

  const handleSearch = name => event => {
    const searchFilter = event ? event.target.value : search.available
    if (name === 'available') {
      setSearch({...search, available: searchFilter})
      const available = props.permissions || []
      let filteredPermissions = available.filter(permission => permission.toUpperCase().includes(searchFilter.toUpperCase()))
      filteredPermissions = filteredPermissions.filter(permission => !role.permissions.includes(permission))

      setPermissionList({...permissionList, available: filteredPermissions})
    } else if (name === 'assigned') {
      setSearch({...search, assigned: searchFilter})
      const assigned = role.permissions || []
      const filteredPermissions = assigned.filter(permission => permission.toUpperCase().includes(searchFilter.toUpperCase()))

      setPermissionList({...permissionList, assigned: filteredPermissions})
    } else /* combined */ {
      setSearch({available: searchFilter, assigned: searchFilter})
      const available = props.permissions || []
      let filteredAvailablePermissions = available.filter(permission => permission.toUpperCase().includes(searchFilter.toUpperCase()))
      filteredAvailablePermissions = filteredAvailablePermissions.filter(permission => !role.permissions.includes(permission))

      const assigned = role.permissions || []
      const filteredAssignedPermissions = assigned.filter(permission => permission.toUpperCase().includes(searchFilter.toUpperCase()))

      setPermissionList({available: filteredAvailablePermissions, assigned: filteredAssignedPermissions})
    }
  }

  const handleSearchCallback = React.useCallback(handleSearch, [role])

  React.useEffect(() => {
    if (!role || !role.permissions) {
      return
    }
    handleSearchCallback('combined')()
  }, [role, handleSearchCallback])

  const handleChange = name => event => {
    props.onChange({...role, [name]: event.target.value})
  }

  const revokePermission = (index) => {
    if (disabled) {
      return
    }
    const assigned = permissionList.assigned
    const permission = assigned[index]

    const list = role.permissions
    const idx = list.indexOf(permission)
    list.splice(idx, 1)
    props.onChange({...role, permissions: list})
  }

  const grantPermission = (index) => {
    if (disabled) {
      return
    }
    const available = permissionList.available
    const permission = available[index]

    const list = role.permissions
    list.splice(0, 0, permission)
    props.onChange({...role, permissions: list})
  }

  const InputProps = {
    classes: {
      underline: classes.inputUnderline,
      input: classes.input,
    },
  }

  const InputLabelProps = {
    classes: {
      root: classes.inputLabel,
      focused: classes.inputLabelFocused,
    },
  }

  const {available, assigned} = permissionList

  return (
    <Card
      className={classes.root}
    >
      <CardHeader
        classes={{root: classes.cardHeaderRoot}}
        title="Details"
        titleTypographyProps={{
          variant: 'body1',
        }}
      />
      {role &&
      <CardContent>
        <Grid
          container
          spacing={4}>
          <Grid
            item
            xs={6}>
            <TextField
              InputLabelProps={InputLabelProps}
              InputProps={InputProps}
              className={classes.textField}
              disabled={disabled}
              fullWidth
              label="Role name"
              margin="dense"
              onChange={handleChange('name')}
              value={role.name}
            />
          </Grid>
          <Grid
            item
            xs={12}>
            <Typography>Permissions</Typography>
            <TextField
              InputLabelProps={InputLabelProps}
              InputProps={{
                ...InputProps,
                startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
              }}
              className={classes.textField}
              fullWidth
              label="Search"
              onChange={handleSearch('combined')}
              value={search.left}
            />
          </Grid>
          <Grid
            className={classes.list}
            item
            xs={6}>
            <Typography>Available</Typography>
            <FixedSizeList
              height={400}
              itemCount={available.length}
              itemData={{
                permissions: available,
                selectPermission: grantPermission,
              }}
              itemSize={46}
              width="100%"
            >
              {Permission}
            </FixedSizeList>
          </Grid>
          <Grid
            className={classes.list}
            item
            xs={6}>
            <Typography>Assigned</Typography>
            <FixedSizeList
              height={400}
              itemCount={assigned.length}
              itemData={{
                permissions: assigned,
                selectPermission: revokePermission,
              }}
              itemSize={46}
              width="100%"
            >
              {Permission}
            </FixedSizeList>
          </Grid>
        </Grid>
      </CardContent>
      }
    </Card>
  )
}

RoleDetails.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  permissions: PropTypes.array,
  role: PropTypes.object,
  onChange: PropTypes.func,
}


export default RoleDetails


