import {HexToRGBA} from '../../utils/Utils'

export const MuiTablePagination = theme => ({
  selectIcon: {
    color: HexToRGBA(theme.palette.text.primary, 0.5),
  },
  root: {
    borderBottom: 'none'
  }
})
