import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CurrencyEntity from 'popcorn-js/financial/currency/Currency.js';
import InvoiceImportDialog from './invoiceImportDialog';
import { Invoice as InvoiceEntity } from 'popcorn-js/financial/invoice/Invoice';
import { processUnixDateForViewing } from 'utils/Utils';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import {
  commitmentReport,
  mosaicReport,
} from 'components/ImportExport/importConfigs';
import Import from './Import';

import { ErrorProcessingFileDialog } from 'components/ImportExport/index';

import { INVOICE_INVALID_REASON_COUNTERPARTY_DOES_NOT_EXIST } from 'constants/invoice';
import { InvoiceRecordkeeper } from 'popcorn-js/financial/invoice';

import XLSX from 'xlsx';
import { EXACT_CRITERION } from 'popcorn-js/search/criteria/types';

const width = {
  small: 120,
  medium: 170,
  large: 250,
};

const states = {
  nop: 0,

  // Import
  showingImportDialog: 1,
  showingErrorProcessingFileDialog: 2,

  invoiceUploadInProgress: 3,
  uploadErrorPresent: 4,
};

const events = {
  init: states.importClick,
  // Import
  importClick: states.showingImportDialog,
  closeImport: states.nop,
  errorWhileProcessingFile: states.showingErrorProcessingFileDialog,

  recordImport: states.invoiceUploadInProgress,
  finishInvoiceUpload: states.nop,
};

class InvoiceImporterContainer extends React.Component {
  state = {
    activeState: events.importClick,
    newInvoices: [],
    invoiceCounterparties: [],
    invoicesValidated: {
      validationInProgress: false,
      uploadError: undefined,
      unique: [],
      duplicates: [],
      invalid: [],
      missingCounterparties: [],
    },
  };
  addNewFromImportProps = { currencies: this.props.currencies };
  previewTableColumns = [
    {
      Header: 'Number',
      accessor: 'number',
      width: width.medium,
    },
    {
      Header: 'Counterparty',
      accessor: 'counterparty',
      width: width.large,
    },
    {
      Header: 'Amount Due',
      accessor: 'amountDue',
      width: width.small,
    },
    {
      Header: 'Currency',
      accessor: 'currencyId',
      width: width.medium,
      Cell: (rowCell) => {
        return (
          (
            (this.props.currencies || []).find((c) => c.id === rowCell.value) ||
            {}
          ).isoCode || rowCell.value
        );
      },
    },
    {
      Header: 'Cost Currency',
      accessor: 'costCurrencyId',
      width: width.medium,
      Cell: (rowCell) => {
        return (
          (
            (this.props.currencies || []).find((c) => c.id === rowCell.value) ||
            {}
          ).isoCode || rowCell.value
        );
      },
    },
    {
      Header: 'Costing Rate',
      accessor: 'costingRate',
      width: width.small,
    },
    {
      Header: 'Capture Rate',
      accessor: 'captureRate',
      width: width.small,
    },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
      width: width.medium,
      Cell: (rowCell) => {
        return processUnixDateForViewing(rowCell.value);
      },
    },
    {
      Header: 'Shipping Date',
      accessor: 'shippingDate',
      width: width.medium,
      Cell: (rowCell) => {
        return processUnixDateForViewing(rowCell.value);
      },
    },
    {
      Header: 'Shipment Reference',
      accessor: 'shipmentReference',
      width: width.large,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: width.medium,
    },
    {
      Header: 'Financial Year',
      accessor: 'financialYear',
      width: width.small,
    },
    {
      Header: 'Issue Date',
      accessor: 'issueDate',
      width: width.medium,
      Cell: (rowCell) => {
        return processUnixDateForViewing(rowCell.value);
      },
    },
  ];
  entityImportClass = InvoiceEntity;
  importConfigs = [mosaicReport, commitmentReport];

  constructor(props) {
    super(props);
    this.processInvoiceSubmit = this.processInvoiceSubmit.bind(this);
    this.handleValidateBatchInvoice = this.handleValidateBatchInvoice.bind(
      this
    );
  }

  static downloadInvoiceTemplate() {
    /* create dummy data */
    const data = [
      {
        /* A */ Number: 'ABC123',
        /* B */ ExternalReference: 'ABC123',
        /* C */ Counterparty: 'CreditorABC',
        /* D */ AmountDue: 100000.0,
        /* E */ Currency: 'USD',
        /* F */ CostCurrency: 'ZAR',
        /* G */ CostingRate: 15.15,
        /* H */ DueDate: new Date(),
        /* I */ ShippingDate: new Date(),
        /* J */ ShipmentReference: 'Container number XYZ789',
        /* K */ Status: 'PAID',
        /* L */ PaidAmount: 100000.0,
        /* M */ EffectiveRate: 15.2,
        /* N */ IssueDate: new Date(),
        /* O */ CaptureRate: 14.5,
        /* P */ FinancialYear: 'CURRENT',
        /* Q */ Notes: '',
      },
      {
        /* A */ Number: 'ABC124',
        /* B */ ExternalReference: 'XYZ321',
        /* C */ Counterparty: 'CreditorXYZ',
        /* D */ AmountDue: 200000.0,
        /* E */ Currency: 'EUR',
        /* F */ CostCurrency: 'ZAR',
        /* G */ CostingRate: 16.15,
        /* H */ DueDate: new Date(),
        /* I */ ShippingDate: new Date(),
        /* J */ ShipmentReference: 'Container number XYZ780',
        /* K */ Status: 'CONFIRMED',
        /* L */ PaidAmount: 0,
        /* M */ EffectiveRate: 0,
        /* N */ IssueDate: new Date(),
        /* O */ CaptureRate: 14.5,
        /* P */ FinancialYear: 'CURRENT',
        /* Q */ Notes: '',
      },
    ];

    /* create a new workbook */
    const workbook = XLSX.utils.book_new();

    /* create the worksheet */
    const worksheet = XLSX.utils.json_to_sheet(data);

    /* set some formatting */
    worksheet['A1']['v'] = 'Number';
    worksheet['B1']['v'] = 'External Reference';
    worksheet['C1']['v'] = 'Counterparty';
    worksheet['D1']['v'] = 'Amount Due';
    worksheet['E1']['v'] = 'Currency';
    worksheet['F1']['v'] = 'Cost Currency';
    worksheet['G1']['v'] = 'Costing Rate';
    worksheet['H1']['v'] = 'Due Date';
    worksheet['I1']['v'] = 'Shipping Date';
    worksheet['J1']['v'] = 'Shipment Reference';
    worksheet['K1']['v'] = 'Status';
    worksheet['L1']['v'] = 'Paid Amount';
    worksheet['M1']['v'] = 'Effective Rate';
    worksheet['N1']['v'] = 'Issue Date';
    worksheet['O1']['v'] = 'Capture Rate';
    worksheet['P1']['v'] = 'Financial Year';
    worksheet['Q1']['v'] = 'Notes';

    worksheet['D2']['z'] = '#,##0.00';
    worksheet['D3']['z'] = '#,##0.00';

    /* add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoices');

    /* download the workbook */
    XLSX.writeFile(workbook, 'invoice-template.xlsx');
  }

  processInvoiceSubmit = (newInvoices) => {
    const { partyCode } = this.props;

    this.props.showImportDialog();

    // Set party code on all of the invoices
    newInvoices = newInvoices.map((invoice) => {
      invoice.partyCode = partyCode;
      return invoice;
    });
    this.handleValidateBatchInvoice(newInvoices);
    this.setState({
      activeState: events.recordImport,
      newInvoices,
    });
  };

  async handleValidateBatchInvoice(newInvoices) {
    const invoicesValidated = this.state.invoicesValidated;

    this.setState({
      invoicesValidated: {
        ...invoicesValidated,
        validationInProgress: true,
        uploadError: undefined,
      },
      invoicesCreateBatch: {
        failed: 0,
        succeeded: 0,
        inProgress: false,
        success: false,
        error: undefined,
      },
    });

    const counterpartyFindCriteria = [];
    if (newInvoices && newInvoices.length > 0) {
      const inv = newInvoices[0];
      counterpartyFindCriteria.push({
        type: EXACT_CRITERION,
        value: { text: inv.partyCode, field: 'partyCode' },
      });
    }
    const counterpartyFindResponse = await CounterpartyRecordkeeper.find({
      criteria: counterpartyFindCriteria,
      query: undefined,
      Deleted: false,
    });

    const missingInvoiceCounterparties = [];
    const invoiceCounterparties = [];
    const storedCounterparties = counterpartyFindResponse.records;
    for (const inv of newInvoices) {
      const invoiceCounterparty = storedCounterparties.find(
        (b) => b.name === inv.counterparty
      );
      if (invoiceCounterparty) {
        inv.counterpartyId = invoiceCounterparty.id;
        invoiceCounterparties.push(invoiceCounterparty);
      } else {
        //Check if counterparty already added to list
        if (
          !missingInvoiceCounterparties.find((b) => b.name === inv.counterparty)
        ) {
          missingInvoiceCounterparties.push({ name: inv.counterparty });
        }
      }
    }

    this.setState({ invoiceCounterparties });

    await InvoiceRecordkeeper.validateBatch({ invoices: newInvoices })
      .then((result) => {
        const validation = {};
        validation.unique = result.unique || [];
        validation.deleted = result.deleted
          ? this.processDuplicates(result.deleted)
          : [];
        validation.duplicate = result.duplicate
          ? this.processDuplicates(result.duplicate)
          : [];
        validation.invalid = result.invalid
          ? this.processInvalid(result.invalid)
          : {
            invalid: [],
            missingCounterparties: [],
          };
        validation.missingCounterparties = missingInvoiceCounterparties;

        this.setState({
          invoicesValidated: {
            ...validation,
            validationInProgress: false,
            uploadError: false,
          },
        });
      })
      .catch((error) => {
        this.setState({
          invoicesValidated: {
            ...invoicesValidated,
            validationInProgress: false,
            activeState: states.uploadErrorPresent,
            uploadError: error,
          },
        });
      });
  }

  processDuplicates(duplicates) {
    const dupArray = [];
    duplicates.forEach((dupInvoice) => {
      const changedFields = {};
      dupInvoice.differences.forEach((diff) => {
        changedFields[diff.FieldName] = true;
      });
      dupArray.push({
        ...dupInvoice.newInvoice,
        isNew: true,
        changedFields,
      });
    });
    return dupArray;
  }

  processInvalid(invalids) {
    const invalid = [];

    invalids.forEach((inv) => {
      inv.invoice.reasons = [];
      inv.invoice.invalidFields = [];
      inv.reasons.forEach((reason) => {
        if (
          reason.reason !== INVOICE_INVALID_REASON_COUNTERPARTY_DOES_NOT_EXIST
        ) {
          inv.invoice.reasons.push(reason);
          inv.invoice.invalidFields[reason.field] = true;
        }
      });
      invalid.push(inv.invoice);
    });
    return invalid;
  }

  renderMainView() {
    const {
      activeState,
      fileProcessingError,
      newInvoices,
      invoicesValidated,
      uploadError,
      invoiceCounterparties,
    } = this.state;
    const { category } = this.props;

    const { currencies, enableDownloadTemplate } = this.props;

    switch (activeState) {
      case states.showingImportDialog:
        return (
          <Import
            addNewFromImportProps={this.addNewFromImportProps}
            category={category}
            downloadTemplate={
              enableDownloadTemplate
                ? InvoiceImporterContainer.downloadInvoiceTemplate
                : false
            }
            entityImportClass={this.entityImportClass}
            importConfigs={this.importConfigs}
            onAwayClick={() => {
              this.props.onAwayClick();
            }}
            previewTableColumns={this.previewTableColumns}
            show
            showManualEntryButton={false}
            submitRecords={this.processInvoiceSubmit}
          />
        );
      case states.showingErrorProcessingFileDialog:
        return (
          <ErrorProcessingFileDialog
            errorMessage={fileProcessingError}
            onAwayClick={this.props.onAwayClick}
            show={activeState === states.showingErrorProcessingFileDialog}
          />
        );
      case states.uploadErrorPresent:
        return (
          <ErrorProcessingFileDialog
            errorMessage={uploadError}
            onAwayClick={this.props.onAwayClick}
            show={activeState === states.uploadErrorPresent}
          />
        );
      case states.invoiceUploadInProgress:
        return (
          <InvoiceImportDialog
            category={category}
            currencies={currencies}
            invoiceCounterparties={invoiceCounterparties}
            invoicesValidated={invoicesValidated}
            newInvoices={newInvoices}
            onAwayClick={this.props.onAwayClick}
            show
            uploadSuccess={this.props.uploadSuccess}
            validateBatchInvoice={this.handleValidateBatchInvoice}
          />
        );
      default:
        return <div>A Problem Occurred</div>;
    }
  }

  render() {
    return this.renderMainView();
  }
}

const mapStateToProps = (state) => {
  return {
    currencies: state.currency.records,
    partyCode: state.security.claims.context.partyCode,
  };
};
const mapDispatchToProps = () => {
  return {};
};

InvoiceImporterContainer.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.instanceOf(CurrencyEntity)),
  enableDownloadTemplate: PropTypes.bool,
  onAwayClick: PropTypes.func,
  partyCode: PropTypes.string,
  category: PropTypes.string,
};

const ConnectedInvoiceImporterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceImporterContainer);

export default ConnectedInvoiceImporterContainer;
