import {
  ALL_INVOICE_STATUSES,
  INVOICE_DIRECTION_PAYABLE,
  INVOICE_DIRECTION_RECEIVABLE,
  INVOICE_STATUS_PENDING,
  INVOICE_TYPE_PURCHASE_INVOICE,
  INVOICE_TYPE_SALES_INVOICE,
} from 'constants/invoice';
import { isObject } from 'utils/Utils';

export class Invoice {
  /**
   * unique index of Invoice entity
   * @type {string}
   */
  id = '';
  /**
   * number assigned by party uploading
   * invoice into system.
   * Not unique
   * @type {string}
   */
  number = '';

  /**
   * externalReference assigned by party uploading
   * invoice into system.
   * Not unique
   * @type {string}
   */
  externalReference = '';

  /**
   * counterparty of the invoice.
   * the counterparty must exist
   * @type {string}
   */
  counterpartyId = '';

  /**
   * amount being paid. positive
   * indicates an amount received
   * @type {number}
   */
  amountDue = 0.0;

  /**
   * currency in which amount due
   * is to be paid
   * id.Identifier
   * @type {string}
   */
  currencyId = '';

  /**
   * currency in which amount due
   * is to be considered
   * id.Identifier
   * @type {string}
   */
  costCurrencyId = '';

  /**
   *
   * @type {number}
   */
  costingRate = 0.0;

  /**
   *
   * @type {number}
   */
  dueDate = 0;

  /**
   * @type {number}
   */
  shippingDate = 0;

  /**
   *
   * @type {string}
   */
  shipmentReference = '';

  /**
   *
   * @type {string}
   */
  notes = '';

  /**
   *
   * @type {string}
   */
  status = '';

  /**
   * partyCode.Identifier
   * @type {string}
   */
  partyCode = '';

  auditEntry = {};

  /**
   *
   * @type {number}
   */
  approvedAmount = 0.0;

  /**
   *
   * @type {number}
   */
  paidAmount = 0.0;

  /**
   *
   * @type {number}
   */
  effectiveRate = 0.0;

  /**
   *
   * @type {number}
   */
  allocatedAmount = 0.0;

  /**
   *
   * @type {number}
   */
  issueDate = 0;

  /**
   *
   * @type {number}
   */
  captureRate = 0.0;

  /**
   *
   * @type {string}
   */
  direction = INVOICE_DIRECTION_PAYABLE;

  /**
   *
   * @type {string}
   */
  type = INVOICE_TYPE_PURCHASE_INVOICE;

  /**
   *
   * @type {InvoiceLink[]}
   */
  tradeLinks = [];

  /**
   *
   * @type {InvoiceLink[]}
   */
  accountLinks = [];

  /**
   * @type {string}
   */
  financialYear = '';

  /**
   * @type {string}
   */
  importExport = '';

  constructor(invoice) {
    if (
      invoice !== undefined &&
      (invoice instanceof Invoice || isObject(invoice))
    ) {
      try {
        this.id = invoice.id;
        this.number = invoice.number;
        this.externalReference = invoice.externalReference;
        this.counterparty = invoice.counterparty;
        this.counterpartyId = invoice.counterpartyId;
        this.amountDue = invoice.amountDue;
        this.currencyId = invoice.currencyId;
        this.costCurrencyId = invoice.costCurrencyId;
        this.costingRate = invoice.costingRate;
        this.dueDate = invoice.dueDate;
        this.shippingDate = invoice.shippingDate;
        this.shipmentReference = invoice.shipmentReference;
        this.notes = invoice.notes;
        this.status = invoice.status;
        this.partyCode = invoice.partyCode;
        this.auditEntry = invoice.auditEntry;
        this.approvedAmount = invoice.approvedAmount;
        this.paidAmount = invoice.paidAmount;
        this.effectiveRate = invoice.effectiveRate;
        this.allocatedAmount = invoice.allocatedAmount;
        this.issueDate = invoice.issueDate;
        this.captureRate = invoice.captureRate;
        this.direction = invoice.direction;
        this.type = invoice.type;
        this.financialYear = invoice.financialYear;
        this.importExport = invoice.importExport;
        this.tradeLinks = invoice.tradeLinks || [];
        this.accountLinks = invoice.accountLinks || [];
        this.tradeLinks = invoice.tradeLinks ? invoice.tradeLinks : [];
        this.accountLinks = invoice.accountLinks ? invoice.accountLinks : [];
      } catch (e) {
        console.error(`error constructing invoice: ${e}`);
      }
    }
  }

  static importFields() {
    return [
      {
        name: 'number',
        type: 'string',
      },
      {
        name: 'externalReference',
        type: 'string',
      },
      {
        name: 'counterparty',
        type: 'string',
      },
      {
        name: 'amountDue',
        type: 'float',
      },
      {
        name: 'currency',
        type: 'string',
        objField: 'currencyIsoCode',
      },
      {
        name: 'costCurrency',
        type: 'string',
        optional: true,
        objField: 'costCurrencyIsoCode',
        displayHeader: 'Cost Currency',
      },
      {
        name: 'costingRate',
        type: 'float',
      },
      {
        name: 'captureRate',
        type: 'float',
        optional: true,
      },
      {
        name: 'dueDate',
        type: 'date',
      },
      {
        name: 'shippingDate',
        optional: true,
        type: 'date',
      },
      {
        name: 'shipmentReference',
        type: 'string',
        optional: true,
      },
      {
        name: 'notes',
        type: 'string',
        optional: true,
        objField: 'notes',
      },
      {
        name: 'status',
        type: 'string',
        optional: true,
        objField: 'status',
      },
      {
        name: 'paidAmount',
        type: 'float',
      },
      {
        name: 'effectiveRate',
        type: 'float',
      },
      {
        name: 'partyCode',
        type: 'string',
        optional: true,
      },
      {
        name: 'issueDate',
        type: 'date',
        optional: true,
      },
      {
        name: 'financialYear',
        type: 'string',
        optional: false,
      },
      {
        name: 'notes',
        type: 'string',
      },
      {
        name: 'type',
        type: 'string',
      },
    ];
  }

  static newFromImportFields(importFieldsObj, addNewFromImportProps) {
    const newInvoice = new Invoice();

    newInvoice.id = importFieldsObj.id;
    newInvoice.number = importFieldsObj.number;
    newInvoice.externalReference = importFieldsObj.externalReference;
    newInvoice.counterparty = importFieldsObj.counterparty;
    newInvoice.amountDue = importFieldsObj.amountDue;
    newInvoice.currencyId =
      (
        (addNewFromImportProps.currencies || [])
          .slice()
          .find((c) => c.isoCode === importFieldsObj.currency) || {}
      ).id || '';
    newInvoice.costCurrencyId =
      (
        (addNewFromImportProps.currencies || [])
          .slice()
          .find((c) => c.isoCode === importFieldsObj.costCurrency) || {}
      ).id || '';
    newInvoice.costingRate = importFieldsObj.costingRate;
    newInvoice.captureRate = importFieldsObj.captureRate;
    newInvoice.dueDate = importFieldsObj.dueDate;
    newInvoice.shippingDate = importFieldsObj.shippingDate;
    newInvoice.shipmentReference = importFieldsObj.shipmentReference;
    newInvoice.notes = importFieldsObj.notes;
    newInvoice.partyCode = importFieldsObj.partyCode;
    newInvoice.issueDate = importFieldsObj.issueDate;
    newInvoice.direction = importFieldsObj.direction;
    newInvoice.type = importFieldsObj.type;
    newInvoice.paidAmount = importFieldsObj.paidAmount;
    newInvoice.financialYear = importFieldsObj.financialYear;
    newInvoice.importExport = importFieldsObj.importExport;
    newInvoice.effectiveRate = importFieldsObj.effectiveRate;

    // If the status is something which is valid
    if (ALL_INVOICE_STATUSES.includes(importFieldsObj.status)) {
      // Set the status
      newInvoice.status = importFieldsObj.status;
    } else {
      // Otherwise make the status pending
      newInvoice.status = INVOICE_STATUS_PENDING;
    }

    return newInvoice;
  }
}

export const getSign = (i) => {
  if (i.direction === INVOICE_DIRECTION_RECEIVABLE) {
    return 1
  } else {
    return -1
  }
}

export const getSignBasedOnType = (i) => {
  if ([INVOICE_TYPE_PURCHASE_INVOICE, INVOICE_TYPE_SALES_INVOICE].includes(i.type)) {
    return 1
  } else {
    return -1
  }
}

