import {isObject} from 'utils/Utils'

export default class AccountEntry {
  /**
   * @type {number}
   */
  date = 0
  /**
   * @type {number}
   */
  amount = 0.0
  /**
   * @type {string}
   */
  description = ''

  /**
   * @param {object|AccountEntry} [accountEntry]
   */
  constructor(accountEntry) {
    if (accountEntry !== undefined &&
      (accountEntry instanceof AccountEntry || isObject(accountEntry))) {
      try {
        this.date = accountEntry.date
        this.amount = accountEntry.amount
        this.description = accountEntry.description
      } catch (e) {
        console.error(`error constructing account entry: ${e}`)
        throw e
      }
    }
  }

  /**
   * @param {AccountEntry} a
   * @param {AccountEntry} b
   * @return {boolean} True if a and b are the same
   */
  static CompareAll(a, b) {
    if (!(a instanceof AccountEntry)) {
      console.error('AccountEntry comparator cannot compare objects: invalid type')
      return false
    }
    if (!(b instanceof AccountEntry)) {
      console.error('AccountEntry comparator cannot compare objects: invalid type')
      return false
    }

    const keys = Object.keys(a)

    for (const i in keys) {
      if (a[keys[i]] !== b[keys[i]]) {
        return false
      }
    }
    return true
  }
}
