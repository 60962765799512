import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  Dialog, DialogTitle, Tooltip, withStyles,
  Typography, Grid, FormControl, MenuItem,
  InputLabel, Select, TextField, DialogContent,
  IconButton, Input, Table as MUITable, TableBody,
} from '@material-ui/core'
import {
  MdClose as CloseIcon,
  MdErrorOutline as ErrorIcon,
  MdEdit as EditIcon,
  MdSave as SaveIcon,
  MdClear as CancelIcon,
  MdSubdirectoryArrowRight as ChildRowIcon,
} from 'react-icons/md'
import {Node} from 'popcorn-js/trade/tradeTree'
import Trade, {Leg} from 'popcorn-js/trade/Trade'
import ParsedTextField, {parseTypes as TextFieldParseTypes} from 'components/FormContols/TextField'
import {
  TRADE_RECORD_STATUSES, TRADE_LEG_DIRECTION_BUY, TRADE_LEG_DIRECTION_SELL,
} from 'constants/trade'
import {retrieveFromList} from 'popcorn-js/search/identifier/utilities'
import IDIdentifier from 'popcorn-js/search/identifier/Id'
import {HexToRGBA} from 'utils/Utils'
import {ScaleLoader as Spinner} from 'react-spinners'
import NotificationSweetAlert
  from 'components/SweetAlert/NotificationSweetAlert'
import TradeUpdater from 'popcorn-js/trade/updater'
import TradeHandler from 'popcorn-js/trade/handler'
import ProcessingBankRecordkeeper
  from 'popcorn-js/legalEntity/party/processingBank/recordkeeper'
import ProcessingBank from 'popcorn-js/legalEntity/party/processingBank/ProcessingBank'
import {InvoiceRecordkeeper} from 'popcorn-js/financial/invoice'
import {EntityList} from 'components/Detail/invoice/components'
import InvoiceDetailDialog from 'components/Detail/invoice/InvoiceDetailDialog'
import StellcapLogo from 'assets/img/stellcap-logo.svg'
import {CounterpartyRecordkeeper} from 'popcorn-js/counterparty'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TradeRecordkeeper from 'popcorn-js/trade/recordkeeper'
import {MdRemoveRedEye} from 'react-icons/md'
import PerfectScrollbar from 'perfect-scrollbar'
import Checkbox from '@material-ui/core/Checkbox'
import {TEXT_CRITERION} from 'popcorn-js/search/criteria/types'

const styles = theme => ({
  headerRoot: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
  },
  dialogTitle: {
    height: '102px',
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr',
    alignItems: 'center',
  },
  dialogTitleLogo: {
    height: '50px',
    padding: '0px 41px 0px 41px',
    alignSelf: 'center',
    borderRight: `1px solid ${theme.palette.primary.light}`,
    display: 'grid',
    alignItems: 'center',
  },
  dialogTitleName: {
    padding: '0px 0px 0px 41px',
    lineHeight: '18px',
    fontSize: '18px',
  },
  dialogPaper: {
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
  },
  closeIconWrapper: {
    justifySelf: 'end',
    marginRight: '40px',
  },
  closeIconButton: {
    backgroundColor: `${theme.palette.text.body} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.text.primary} !important`,
    },
  },
  closeIcon: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  loading: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorDisplay: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'center',
    margin: '15px',
  },
  errorIcon: {
    fontSize: 80,
  },
  detailLayout: {
    display: 'grid',
    gridTemplateColumns: '3fr 4fr',
    gridTemplateRows: '1fr',
    paddingTop: '28px',
    gridColumnGap: '38.4px',
    paddingBottom: '28px',
  },
  fecBreakdownDetailLayout: {
    backgroundColor: theme.palette.primary.dark,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.75)',
    display: 'grid',
    gridTemplateRows: '52px 1fr',
  },
  fecDetailTitle: {
    paddingLeft: '13px',
    backgroundColor: theme.palette.primary.main,
    display: 'grid',
    alignItems: 'center',
    fontSize: '17px',
    lineHeight: '17px',
    fontWeight: 'bold',
  },
  fecBreakdownTreeWrapper: {
    padding: `0 ${theme.spacing(1)}px 0 ${theme.spacing(1)}`,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  tradeDetailLayout: {
    backgroundColor: theme.palette.primary.dark,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.75)',
    display: 'grid',
    gridTemplateRows: '52px 1fr',
    overflow: 'hidden',
  },
  tradeDetailTitleLayout: {
    paddingLeft: '13px',
    paddingRight: '13px',
    backgroundColor: theme.palette.primary.main,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr'
  },
  tradeDetailTitle: {
    fontSize: '17px',
    lineHeight: '17px',
    fontWeight: 'bold',
  },
  tradeDetailTitleActions: {
    justifySelf: 'end',
    display: 'flex',
    flexDirection: 'row',
  },
  tradeDetailFormLayout: {
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  tradeDetailFormSection: {
    padding: theme.spacing(2),
  },
  tradeDetailSectionLabel: {
    height: '39px',
    backgroundColor: `${HexToRGBA(theme.palette.text.body, 0.5)}`,
    display: 'grid',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 'regular',
  },
  rootOverride: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr',
    overflow: 'hidden',
  },
  formField: {
    height: '60px',
  },
  progressSpinnerDialog: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  progressSpinnerDialogBackdrop: {
    backgroundColor: 'transparent',
  },
  line: {
    '&:before': {
      borderBottom: `1px solid  ${HexToRGBA(theme.palette.text.primary, '0.13')}`,
    },
    '&:after': {
      borderBottom: `2px solid  ${theme.palette.highActions}`,
    },
  },
  inputLabel: {
    color: HexToRGBA(theme.palette.text.primary, 0.5),
  },
  focusedInputLabel: {
    color: theme.palette.highActions + '!important'
  },

  tradeTreeRowLayout: {
    cursor: 'pointer',
    height: '40px',
    borderBottom: `1px solid  ${HexToRGBA(theme.palette.text.primary, '0.13')}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tradeTreeRowLayoutSelected: {
    backgroundColor: theme.palette.highActions,
  },
  tradeTreeRowInformation: {
    marginLeft: theme.spacing(0.5),
    color: HexToRGBA(theme.palette.text.primary, '0.6'),
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 'regular',
    '&:hover': {
      color: HexToRGBA(theme.palette.text.primary, '0.9'),
    },
  },
  tradeTreeRowInformationSelected: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  expandNodeIcon: {
    fontSize: '20px',
  },
  childNodeIcon: {
    fontSize: '16px',
    alignSelf: 'start',
    marginTop: '9px',
  },
})

const states = {
  nop: 0,
  buildingTradeTree: 1,
  error: 2,
  editingExisting: 3,
  editingExistingLinks: 4,
}

const events = {
  init: states.buildingTradeTree,
  doneBuildingTradeTree: states.nop,
  errorLoading: states.error,
  startEditExisting: states.editingExisting,
  startEditExistingLinks: states.editingExistingLinks,
  cancelEditExisting: states.nop,
}

function findTradeInNode(tradeId, tradeNode) {
  if (tradeNode.trade.id === tradeId) {
    return tradeNode.trade
  }
  for (const childNode of tradeNode.children) {
    const trade = findTradeInNode(tradeId, childNode)
    if (trade) {
      return trade
    }
  }
}

class DetailDialog extends Component {
  tradeDetailsPerfectScrollbar = undefined
  fecBreakdownPerfectScrollbar = undefined

  constructor(props) {
    super(props)
    const newBlankTrade = new Trade()
    newBlankTrade.legs = [new Leg(), new Leg()]
    this.state = {
      isLoading: false,
      detailTicketWrapperHeight: 0,
      activeState: events.init,

      selectedTradeTree: new Node(),
      selectedTrade: newBlankTrade,
      selectedUnEdited: new Trade(),

      previousTradeTree: new Node(),

      successMessage: undefined,
      errorMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
      selectedLinkedInvoice: {},
      showLinkedInvoiceDetail: false,
      onCloseNotification: () => {
      },
      linkedTrades: [],
      linkIdxEditing: -1,
      selectedTradeAcmChildren: [],
    }
    this.reasonsInvalid = {}
    this.linkedInvoices = []
    this.counterparties = []
  }

  findProcessingBanks = async () => {
    this.setState({isLoading: true})
    try {
      const findProcessingBanksResponse = await ProcessingBankRecordkeeper.find(
        undefined,
        undefined,
        false
      )
      this.processingBanks = findProcessingBanksResponse.records.map(
        pb => new ProcessingBank(pb)
      )
    } catch (e) {
      console.error('error finding processing banks', e)
      this.setState({
        isLoading: false,
        activeState: events.errorLoading
      })
    }
    this.setState({isLoading: false})
  }

  findCounterparties = async () => {
    this.setState({isLoading: true})
    const inv = this.state.selectedLinkedInvoice || {}
    const crit = {field: 'id', text: inv.counterpartyId}
    try {
      const findResponse = await CounterpartyRecordkeeper.find(
        {
          criteria: [crit],
          query: {
            sortBy: [],
            order: [],
            limit: 0,
            offset: 0,
          },
          Deleted:  false,
        }
      )
      this.counterparties = findResponse.records
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error finding counterparties', e)
      this.setState({
        isLoading: false,
        activeState: events.errorLoading
      })
    }
    this.setState({isLoading: false})
  }

  findLinkedInvoices = () => {
    this.setState({isLoading: true})
    const {selectedTrade} = this.state

    const criteria = [
      {
        type: 'EXACT_CRITERION',
        value: {
          field: 'tradeLinks.tradeId',
          text: selectedTrade.id,
        },
      }
    ]

    InvoiceRecordkeeper.find({
      criteria,
      query: this.findQuery,
      deleted: false,
    }).then(
      result => {
        this.linkedInvoices = result.records
      }
    ).catch((e) => {
      console.error('error finding processing banks', e)
      this.setState({
        isLoading: false,
        activeState: events.errorLoading
      })
    }).finally(() =>
      this.setState({isLoading: false})
    )
  }

  findLinkedTrades = () => {
    this.setState({isLoading: true})
    const {selectedTrade} = this.state

    const criteria = selectedTrade.tradeParents ? selectedTrade.tradeParents.map(tp => ({
      type: 'EXACT_CRITERION',
      value: {
        field: 'id',
        text: tp.parentTradeId,
      }
    }),) : []

    const criteriaFromChildren = [{
      type: TEXT_CRITERION,
      value: {
        field: 'tradeParents.parentTradeId',
        text: selectedTrade.id,
      },
    }]

    // ACM parents
    const acmParentCriteria = selectedTrade.acmParents ? selectedTrade.acmParents?.map(tp => ({
      type: 'EXACT_CRITERION',
      value: {
        field: 'id',
        text: tp.parentTradeId,
      }
    }),) : []

    // ACM children
    const acmChildrenCriteria = [{
      type: 'EXACT_CRITERION',
      value: {
        field: 'acmParents.parentTradeId',
        text: selectedTrade.id,
      }
    }]
    TradeRecordkeeper.find({
      criteria: acmChildrenCriteria,
      query: {},
      deleted: false,
    }).then(result => {
      this.setState({selectedTradeAcmChildren: result.records})
    })

    criteria.push(...criteriaFromChildren, ...acmParentCriteria)

    TradeRecordkeeper.find({
      criteria,
      filterType: 'OR',
      query: {},
      deleted: false,
    }).then(
      result => {
        this.setState({linkedTrades: result.records})
      }
    ).catch((e) => {
      console.error('error finding processing banks', e)
      this.setState({
        isLoading: false,
        activeState: events.errorLoading
      })
    }).finally(() =>
      this.setState({isLoading: false})
    )
  }

  handleSelectTrade = (trade) => {
    this.setState({
      selectedTrade: new Trade(trade),
      selectedUnEdited: JSON.parse(JSON.stringify(trade)),
      selectedTradeTree: {trade: new Trade(trade)}
    }, () => {
      this.findLinkedInvoices()
      this.findLinkedTrades()
    })
  }

  componentDidMount() {
    const {trade, processingBanks,} = this.props
    this.setState({
      activeState: events.init,
      selectedTrade: new Trade(trade),
      selectedUnEdited: JSON.parse(JSON.stringify(trade)),
    }, () => {
      this.findLinkedInvoices()
      this.findLinkedTrades()
    })
    this.buildTradeTree()
    if (processingBanks.length === 0) {
      this.findProcessingBanks()
    }
  }

  buildTradeTree = async () => {
    const {
      trade,
    } = this.props

    this.setState({isLoading: true})

    // create a new trade tree from the result
    const selectedTradeTree = {trade: new Trade(trade)}

    // find the selected trade in the tree
    const selectedTrade = new Trade(trade)
    // if (selectedTrade === undefined) {
    //   throw new Error('unable to find selected trade in trade tree')
    // }

    // make a backup of the tree for edit cancelling
    const previousTradeTree = {trade: new Trade(trade)}
    //
    // // find the selected trade in the tree
    const previousTrade = new Trade(trade)
    // if (selectedTrade === undefined) {
    //   throw new Error('unable to find previous trade in trade tree')
    // }

    // update state
    this.setState({
      selectedTradeTree,
      selectedTrade,

      previousTradeTree,
      previousTrade,

      activeState: events.doneBuildingTradeTree,
      isLoading: false,
    })
  }

  renderDialogContent = () => {
    const {
      activeState,
    } = this.state

    switch (activeState) {
      case states.nop:
      case states.editingExisting:
      case states.editingExistingLinks:
        return this.renderDetail()
      case states.error:
        return this.renderError()
      case states.buildingTradeTree:
        return <div/>
      default:
        return <div>Error</div>
    }
  }

  renderDetail = () => {
    const {classes, disallowEditing} = this.props
    const {selectedTradeTree, detailTicketWrapperHeight} = this.state

    return (
      <div className={classes.detailLayout}>
        <div className={classes.fecBreakdownDetailLayout}>
          <div className={classes.fecDetailTitle}>
            FEC Break Down
          </div>
          <div
            className={classes.fecBreakdownTreeWrapper}
            ref={this.setFECBreakdownPerfectScrollbar}
            style={{height: detailTicketWrapperHeight}}
          >
            {this.renderTradeNode(selectedTradeTree)}
          </div>
        </div>
        <div className={classes.tradeDetailLayout}>
          <div className={classes.tradeDetailTitleLayout}>
            <div className={classes.tradeDetailTitle}>
              Trade Details
            </div>
            {(!disallowEditing) &&
            <div className={classes.tradeDetailTitleActions}>
              {this.renderControlIcons()}
            </div>}
          </div>
          <div
            className={classes.tradeDetailFormLayout}
            ref={this.setDetailTicketWrapperHeight}
            style={{height: detailTicketWrapperHeight}}
          >
            {this.renderTradeDetails()}
          </div>
        </div>
      </div>
    )

  }

  renderTradeNode = (node, depth = 1) => {
    const {classes} = this.props
    const {selectedTrade} = this.state

    return (
      <React.Fragment key={node.trade.id}>
        <div
          className={cx(
            classes.tradeTreeRowLayout,
            {
              [classes.tradeTreeRowLayoutSelected]:
              selectedTrade.id === node.trade.id,
            },
          )}
        >
          <div style={{width: `${17 * depth}px`}}/>
          <ChildRowIcon className={classes.childNodeIcon}/>
          <div
            className={cx(
              classes.tradeTreeRowInformation,
              {
                [classes.tradeTreeRowInformationSelected]:
                selectedTrade.id === node.trade.id
              },
            )}
          >
            {node.trade.number} - {node.trade.tradeType}
          </div>
        </div>
      </React.Fragment>
    )

  }

  handleTradeFieldChange = async (event) => {
    let {
      selectedTrade,
    } = this.state
    this.setState({isLoading: true})
    try {
      const field = event.target.id ? event.target.id : event.target.name
      selectedTrade =
        (await TradeUpdater.UpdateByField({
          trade: selectedTrade,
          field,
          value: event.target.value,
        })).trade
      this.clearFormFieldReasonsInvalid(field)
    } catch (e) {
      console.error('error changing trade field', e)
      this.setState({
        isLoading: false,
        errorMessage: 'not implemented',
        onCloseNotification: () => this.setState({errorMessage: undefined}),
      })
      return
    }

    this.setState({
      isLoading: false,
      selectedTrade,
    })
  }
  handleACMFieldChange = async () => {
    const {
      selectedTrade,
    } = this.state
    selectedTrade.ACM = !selectedTrade.ACM

    this.setState({
      isLoading: false,
      selectedTrade,
    })
  }


  handleStartEditExisting = () => {
    const {
      selectedTradeTree,
      selectedTrade,
    } = this.state

    this.setState({
      previousTrade: new Trade(selectedTrade),
      previousTradeTree: new Node(selectedTradeTree),
      activeState: events.startEditExisting,
    })
  }

  handleStartEditExistingLink = (idx) => {
    const {
      selectedTradeTree,
      selectedTrade,
    } = this.state

    this.setState({
      previousTrade: new Trade(selectedTrade),
      previousTradeTree: new Node(selectedTradeTree),
      activeState: events.startEditExistingLinks,
      linkIdxEditing: idx
    })
  }

  handleBuySellToggle = async (event) => {
    const {
      activeState,
    } = this.state
    // if state is not editing we return
    if (activeState !== states.editingExisting) {
      return
    }

    let {
      selectedTrade,
    } = this.state

    if (event.target.id === 'legs.0.direction') {
      selectedTrade =
        (await TradeUpdater.UpdateByField({
          trade: selectedTrade,
          field: event.target.id,
          value: selectedTrade.legs[0].direction === TRADE_LEG_DIRECTION_SELL
            ? TRADE_LEG_DIRECTION_BUY
            : TRADE_LEG_DIRECTION_SELL,
        })).trade
    } else {
      // event.target.id === 'legs.1.direction'
      selectedTrade =
        (await TradeUpdater.UpdateByField({
          trade: selectedTrade,
          field: event.target.id,
          value: selectedTrade.legs[1].direction === TRADE_LEG_DIRECTION_SELL
            ? TRADE_LEG_DIRECTION_BUY
            : TRADE_LEG_DIRECTION_SELL,
        })).trade
    }
    this.setState({selectedTrade})
  }

  handleCancelEditExisting = () => {
    const {
      selectedTrade,
      previousTradeTree,
      selectedUnEdited
    } = this.state

    // confirm that user would like to discard changes
    this.setState({
      warningMessage: 'You have unsaved changes. Do you want to continue?',
      // if the user confirms that they would like to discard changes
      confirmationMethod: () => {
        // use the previousTradeTree backup to reset the tree
        const resetSelectedTradeTree = new Node(previousTradeTree)

        // search for the selected trade in this reset tree
        const resetSelectedTrade = findTradeInNode(selectedTrade.id,
          resetSelectedTradeTree)
        if (resetSelectedTrade === undefined) {
          throw new Error('unable to find previous trade in trade tree')
        }

        // clear reasons invalid in trade tree
        this.reasonsInvalid = {}

        this.setState({
          selectedTradeTree: resetSelectedTradeTree,
          selectedTrade: selectedUnEdited,
          activeState: events.cancelEditExisting,
          warningMessage: undefined,
          linkIdxEditing: -1,
        })
      },
      onCloseNotification: () => this.setState({
        warningMessage: undefined,
        confirmationMethod: undefined,
      }),
    })
  }

  handleSaveChanges = async () => {
    const {
      closeDialog,
    } = this.props
    const {
      selectedTrade
    } = this.state

    this.setState({isLoading: true})

    // calculate the effective rate of the trades

    // clear reasons invalid in trade tree
    this.reasonsInvalid = {}

    // otherwise there are no reasons invalid, call amend on all nodes
    let resp
    try {
      resp = await TradeHandler.Amend({
        trade: selectedTrade,
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        errorMessage: `error amending: ${e.message}`,
        onCloseNotification: () => this.setState({errorMessage: undefined}),
      })
      return
    }

    const newSelectedTrade = resp.trade
    // done successfully
    this.setState({
      successMessage: 'Updated',
      isLoading: false,
      selectedUnEdited: JSON.parse(JSON.stringify(newSelectedTrade)),
      selectedTrade: new Trade(newSelectedTrade),
      selectedTradeTree: new Node(newSelectedTrade),
      previousTrade: new Trade(newSelectedTrade),
      previousTradeTree: new Node(newSelectedTrade),
      onCloseNotification: () => {
        this.setState({successMessage: undefined})
        closeDialog()
      },
    })
  }

  handleSaveLinkUpdate = async () => {
    const {
      selectedTrade,
      linkIdxEditing
    } = this.state

    this.setState({isLoading: true})

    this.reasonsInvalid = {}
    const tradeParent = selectedTrade.tradeParents[linkIdxEditing]
    try {
      await TradeHandler.UpdateTradeLink({
        childTradeId: selectedTrade.id,
        tradeParent: tradeParent
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        errorMessage: `error amending: ${e.message}`,
        onCloseNotification: () => this.setState({errorMessage: undefined}),
      })
      return
    }

    // done successfully
    this.setState({
      successMessage: 'Updated',
      isLoading: false,
      activeState: events.cancelEditExisting,
      linkIdxEditing: -1,
      selectedUnEdited: JSON.parse(JSON.stringify(selectedTrade)),
      onCloseNotification: () => this.setState({successMessage: undefined}),
    })
  }

  handleSaveACMLinkUpdate = async () => {
    const {
      selectedTrade,
      linkIdxEditing
    } = this.state

    this.setState({isLoading: true})

    this.reasonsInvalid = {}
    const acmParent = selectedTrade.acmParents[linkIdxEditing]
    let resp
    try {
      resp = await TradeHandler.UpdateACMTradeLink({
        childTradeId: selectedTrade.id,
        acmParent: acmParent
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        errorMessage: `error amending: ${e.message}`,
        onCloseNotification: () => this.setState({errorMessage: undefined}),
      })
      return
    }
    const newSelectedTrade = resp.childTrade


    // done successfully
    this.setState({
      successMessage: 'Updated',
      isLoading: false,
      activeState: events.cancelEditExisting,
      selectedTrade: new Trade(newSelectedTrade),
      linkIdxEditing: -1,
      selectedUnEdited: JSON.parse(JSON.stringify(selectedTrade)),
      onCloseNotification: () => this.setState({successMessage: undefined}),
    })
  }

  renderLinkControlIcon = (idx, acm) => {
    const {
      activeState,
      linkIdxEditing
    } = this.state
    const {readOnly} = this.props
    if (readOnly) {
      return (<div/>)
    }

    if (linkIdxEditing === idx) {
      return (
        <div>
          <Tooltip title="Cancel Edit">
            <IconButton
              disabled={activeState === states.editingExisting || (linkIdxEditing !== -1 && linkIdxEditing !== idx)}
              onClick={this.handleCancelEditExisting}
            >
              <CancelIcon/>
            </IconButton>
          </Tooltip>
          {acm && <Tooltip title="Save Changes">
            <IconButton
              disabled={activeState === states.editingExisting || (linkIdxEditing !== -1 && linkIdxEditing !== idx)}
              onClick={this.handleSaveACMLinkUpdate}
            >
              <SaveIcon/>
            </IconButton>
          </Tooltip>}
          {!acm && <Tooltip title="Save Changes">
            <IconButton
              disabled={activeState === states.editingExisting || (linkIdxEditing !== -1 && linkIdxEditing !== idx)}
              onClick={this.handleSaveLinkUpdate}
            >
              <SaveIcon/>
            </IconButton>
          </Tooltip>}
        </div>
      )
    }

    return (
      <div>
        <Tooltip title="Edit Link Amount">
          <IconButton
            disabled={activeState === states.editingExisting || (linkIdxEditing !== -1 && linkIdxEditing !== idx)}
            onClick={() => this.handleStartEditExistingLink(idx)}
          >
            <EditIcon/>
          </IconButton>
        </Tooltip>
      </div>
    )

  }

  renderControlIcons = () => {
    const {activeState} = this.state
    const {readOnly} = this.props

    switch (activeState) {
      case states.nop:
        if (!readOnly) {
          return (
            <React.Fragment>
              <Tooltip title="Edit">
                <IconButton
                  disabled={activeState === states.editingExistingLinks}
                  onClick={this.handleStartEditExisting}
                >
                  <EditIcon/>
                </IconButton>
              </Tooltip>
            </React.Fragment>)
        }
        return

      case states.editingExisting:
        return <React.Fragment>
          <Tooltip title="Save Changes">
            <IconButton
              onClick={this.handleSaveChanges}
            >
              <SaveIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton
              onClick={this.handleCancelEditExisting}
            >
              <CancelIcon/>
            </IconButton>
          </Tooltip>
        </React.Fragment>

      default:
    }
  }

  setDetailTicketWrapperHeight = (element) => {
    const {detailTicketWrapperHeight} = this.state
    try {
      if (detailTicketWrapperHeight === 0) {
        this.setState(
          {
            detailTicketWrapperHeight: element.parentNode.clientHeight - 55,
          })
      }
    } catch (e) {
      console.error('error setting detail window height', e)
    }
    try {
      if (element) {
        this.tradeDetailsPerfectScrollbar = new PerfectScrollbar(
          element,
          {
            suppressScrollX: true,
            suppressScrollY: false,
          },
        )
      }
    } catch (e) {
      console.error('error setting perfect scroll bar', e)
    }
  }

  setFECBreakdownPerfectScrollbar = element => {
    try {
      this.fecBreakdownPerfectScrollbar = new PerfectScrollbar(
        element,
        {
          suppressScrollX: true,
          suppressScrollY: false,
        },
      )
    } catch (e) {
      console.error('error setting fec breakdown perfect scroll bar', e)
    }

  }

  getFormFieldReasonsInvalid = (fieldName) => {
    const {selectedTrade} = this.state
    if (this.reasonsInvalid[selectedTrade.id] === undefined) {
      return
    }

    if (
      (!this.reasonsInvalid[selectedTrade.id].fieldErrors) ||
      this.reasonsInvalid[selectedTrade.id].fieldErrors[fieldName] === undefined
    ) {
      return
    }

    return this.reasonsInvalid[selectedTrade.id].fieldErrors[fieldName].type
  }

  clearFormFieldReasonsInvalid = (fieldName) => {
    const {selectedTrade} = this.state
    if (this.reasonsInvalid[selectedTrade.id] === undefined) {
      return
    }

    if (!this.reasonsInvalid[selectedTrade.id].fieldErrors) {
      return
    }

    this.reasonsInvalid[selectedTrade.id].fieldErrors[fieldName] = undefined
  }

  renderTradeDetails = () => {
    const {classes} = this.props
    const {
      selectedTrade, activeState, linkedTrades, linkIdxEditing, selectedTradeAcmChildren
    } = this.state

    const processingBanks = (() => {
      if (this.props.processingBanks.length !== 0) {
        return this.props.processingBanks
      }
      if (Array.isArray(this.processingBanks)) {
        return this.processingBanks
      }
      return []
    })()
    const submissionInProgress = false

    const tradeChildren = (linkedTrades || []).filter(trd => {
      const p = trd.tradeParents ? trd.tradeParents.find(tp => tp.parentTradeId === selectedTrade.id) : []
      return p
    })

    return (
      <React.Fragment>
        <div className={classes.tradeDetailFormSection}>
          <Grid
            container
            direction={'row'}
            spacing={7}
          >
            <Grid item>
              <Grid
                container
                direction={'column'}
              >
                <Grid item>
                  <Tooltip
                    enterDelay={500}
                    title={'Cannot change currency pair'}
                  >
                    <TextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      error={!!this.getFormFieldReasonsInvalid('currencyPairId')}
                      helperText={this.getFormFieldReasonsInvalid('currencyPairId')}
                      id={'currencyPairId'}
                      label="Currency Pair"
                      onChange={this.handleTradeFieldChange}
                      value={this.getCurrencyPairName(
                        selectedTrade.currencyPairId)}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip
                    enterDelay={500}
                    title={'Calculated on submission'}
                  >
                    <ParsedTextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      error={!!this.getFormFieldReasonsInvalid('availableBalance')}
                      helperText={this.getFormFieldReasonsInvalid('availableBalance')}
                      id={'availableBalance'}
                      label="Available Balance"
                      parseType={TextFieldParseTypes.float}
                      value={Math.abs(selectedTrade.availableBalance) < 0.001 ? 0.0 : selectedTrade.availableBalance}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip
                    enterDelay={500}
                    title={'Cannot change Import/Export'}
                  >
                    <TextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      error={!!this.getFormFieldReasonsInvalid('importExport')}
                      helperText={this.getFormFieldReasonsInvalid('importExport')}
                      id={'importExport'}
                      label="Import/Export"
                      onChange={this.handleTradeFieldChange}
                      value={selectedTrade.importExport}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  ACM Trade
                  <Checkbox
                    checked={selectedTrade.ACM}
                    inputProps={{disabled: activeState !== states.editingExisting}}
                    onChange={this.handleACMFieldChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
            >
              <Grid item>
                <FormControl
                  aria-describedby="status"
                  // // error={!!this.formFieldErrors['status']}
                  className={classes.formField}
                >
                  <InputLabel
                    classes={{
                      focused: classes.focusedInputLabel,
                      root: classes.inputLabel,
                    }}
                    htmlFor="status"
                  >
                    Status
                  </InputLabel>
                  <Select
                    disableUnderline={activeState !== states.editingExisting}
                    disabled={submissionInProgress}
                    id="status"
                    input={
                      <Input
                        classes={{
                          underline: classes.line,
                        }}
                      />
                    }
                    inputProps={{
                      readOnly: activeState !== states.editingExisting,
                    }}
                    name="status"
                    onChange={this.handleTradeFieldChange}
                    value={selectedTrade.status}
                  >
                    {TRADE_RECORD_STATUSES.map((option, idx) => {
                      return <MenuItem
                        key={idx}
                        value={option}
                      >
                        {option}
                      </MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Tooltip
                  enterDelay={500}
                  title={'Cannot change processing organisation'}
                >
                  <TextField
                    InputLabelProps={
                      {
                        classes: {
                          focused: classes.focusedInputLabel,
                          root: classes.inputLabel,
                        }
                      }
                    }
                    InputProps={{
                      readOnly: true,
                      disableUnderline: activeState !== states.editingExisting,
                      classes: {
                        underline: classes.line,
                      },
                    }}
                    className={classes.formField}
                    disabled={submissionInProgress}
                    error={!!this.getFormFieldReasonsInvalid('processingOrgPartyCode')}
                    helperText={this.getFormFieldReasonsInvalid('processingOrgPartyCode')}
                    id={'processingOrgPartyCode'}
                    label="Processing Org"
                    onChange={this.handleTradeFieldChange}
                    value={selectedTrade.processingOrgPartyCode}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip
                  enterDelay={500}
                  title={'Calculated on submission'}
                >
                  <ParsedTextField
                    InputLabelProps={
                      {
                        classes: {
                          focused: classes.focusedInputLabel,
                          root: classes.inputLabel,
                        }
                      }
                    }
                    InputProps={{
                      readOnly: true,
                      disableUnderline: activeState !== states.editingExisting,
                      classes: {
                        underline: classes.line,
                      },
                    }}
                    className={classes.formField}
                    disabled={submissionInProgress}
                    error={!!this.getFormFieldReasonsInvalid('acmBalance')}
                    helperText={this.getFormFieldReasonsInvalid('acmBalance')}
                    id={'acmBalance'}
                    label="ACM Balance"
                    parseType={TextFieldParseTypes.float}
                    value={Math.abs(selectedTrade.acmBalance) < 0.001 ? 0.0 : selectedTrade.acmBalance}
                  />
                </Tooltip>
              </Grid>
            </Grid>

          </Grid>
        </div>
        <div className={classes.tradeDetailSectionLabel}>
          Near Leg
        </div>
        <div className={classes.tradeDetailFormSection}>
          <Grid
            container
            direction={'row'}
            spacing={7}
          >
            <Grid item>
              <Grid
                container
                direction={'column'}
              >
                <Grid item>
                  <TextField
                    InputLabelProps={
                      {
                        classes: {
                          focused: classes.focusedInputLabel,
                          root: classes.inputLabel,
                        }
                      }
                    }
                    InputProps={{
                      readOnly: activeState !== states.editingExisting,
                      disableUnderline: activeState !== states.editingExisting,
                      classes: {
                        underline: classes.line,
                      },
                    }}
                    className={classes.formField}
                    disabled={submissionInProgress}
                    error={!!this.getFormFieldReasonsInvalid('legs.0.externalReference')}
                    helperText={this.getFormFieldReasonsInvalid(
                      'legs.0.externalReference')}
                    id={'legs.0.externalReference'}
                    label="External Reference"
                    onChange={this.handleTradeFieldChange}
                    value={selectedTrade.legs[0].externalReference}
                  />
                </Grid>

                <Grid item>
                  <FormControl
                    aria-describedby="nearLegBank"
                    className={classes.formField}
                  >
                    <InputLabel
                      classes={{
                        focused: classes.focusedInputLabel,
                        root: classes.inputLabel,
                      }}
                      htmlFor="nearLegBank"
                      style={{width: 150}}
                    >
                      Bank
                    </InputLabel>
                    <Select
                      disableUnderline={activeState !== states.editingExisting}
                      disabled={submissionInProgress}
                      id={'legs.0.bank'}
                      input={
                        <Input
                          classes={{
                            underline: classes.line,
                          }}
                        />
                      }
                      inputProps={{
                        readOnly: activeState !== states.editingExisting,
                      }}
                      name={'legs.0.bank'}
                      onChange={this.handleTradeFieldChange}
                      style={{width: 150}}
                      value={selectedTrade.legs[0].bank}
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      {processingBanks.map((bank, idx) => {
                        return <MenuItem
                          key={idx}
                          value={bank.partyCode}
                        >
                          {bank.name}
                        </MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  <ParsedTextField
                    InputLabelProps={
                      {
                        classes: {
                          focused: classes.focusedInputLabel,
                          root: classes.inputLabel,
                        }
                      }
                    }
                    InputProps={{
                      readOnly: activeState !== states.editingExisting,
                      disableUnderline: activeState !== states.editingExisting,
                      classes: {
                        underline: classes.line,
                      },
                    }}
                    className={classes.formField}
                    disabled={submissionInProgress}
                    error={!!this.getFormFieldReasonsInvalid('legs.0.tradeDate')}
                    helperText={this.getFormFieldReasonsInvalid('legs.0.tradeDate')}
                    id={'legs.0.tradeDate'}
                    label={'Trade Date'}
                    onChange={this.handleTradeFieldChange}
                    parseType={TextFieldParseTypes.date}
                    type={'date'}
                    value={selectedTrade.legs[0].tradeDate}
                  />
                </Grid>
                <Grid item>
                  <ParsedTextField
                    InputLabelProps={
                      {
                        classes: {
                          focused: classes.focusedInputLabel,
                          root: classes.inputLabel,
                        }
                      }
                    }
                    InputProps={{
                      readOnly: activeState !== states.editingExisting,
                      disableUnderline: activeState !== states.editingExisting,
                      classes: {
                        underline: classes.line,
                      },
                    }}
                    className={classes.formField}
                    disabled={submissionInProgress}
                    disallowNegative
                    error={!!this.getFormFieldReasonsInvalid('legs.0.notionalAmount')}
                    helperText={this.getFormFieldReasonsInvalid('legs.0.notionalAmount')}
                    id={'legs.0.notionalAmount'}
                    label="Notional Amount"
                    onChange={this.handleTradeFieldChange}
                    parseType={TextFieldParseTypes.float}
                    value={selectedTrade.legs[0].notionalAmount}
                  />
                </Grid>
                <Grid item>
                  <ParsedTextField
                    InputLabelProps={
                      {
                        classes: {
                          focused: classes.focusedInputLabel,
                          root: classes.inputLabel,
                        }
                      }
                    }
                    InputProps={{
                      readOnly: activeState !== states.editingExisting,
                      disableUnderline: activeState !== states.editingExisting,
                      classes: {
                        underline: classes.line,
                      },
                    }}
                    className={classes.formField}
                    disabled={submissionInProgress}
                    disallowNegative
                    error={!!this.getFormFieldReasonsInvalid('legs.0.spotPrice')}
                    helperText={this.getFormFieldReasonsInvalid('legs.0.spotPrice')}
                    id={'legs.0.spotPrice'}
                    label="Spot Price"
                    onChange={this.handleTradeFieldChange}
                    parseType={TextFieldParseTypes.float}
                    value={selectedTrade.legs[0].spotPrice}
                  />
                </Grid>
                <Grid item>
                  <ParsedTextField
                    InputLabelProps={
                      {
                        classes: {
                          focused: classes.focusedInputLabel,
                          root: classes.inputLabel,
                        }
                      }
                    }
                    InputProps={{
                      readOnly: activeState !== states.editingExisting,
                      disableUnderline: activeState !== states.editingExisting,
                      classes: {
                        underline: classes.line,
                      },
                    }}
                    className={classes.formField}
                    disabled={submissionInProgress}
                    error={!!this.getFormFieldReasonsInvalid('legs.0.allInRate')}
                    helperText={this.getFormFieldReasonsInvalid('legs.0.allInRate')}
                    id={'legs.0.allInRate'}
                    label="All-In Rate"
                    onChange={this.handleTradeFieldChange}
                    parseType={TextFieldParseTypes.float}
                    value={selectedTrade.legs[0].allInRate}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction={'column'}
              >
                <Grid item>
                  <Tooltip
                    enterDelay={500}
                    title={'Cannot change direction'}
                  >
                    <TextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      error={!!this.getFormFieldReasonsInvalid('legs.0.direction')}
                      helperText={this.getFormFieldReasonsInvalid('legs.0.direction')}
                      id={'legs.0.direction'}
                      label="Direction"
                      // onClick={this.handleBuySellToggle}
                      value={selectedTrade.legs[0].direction}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <ParsedTextField
                    InputLabelProps={
                      {
                        classes: {
                          focused: classes.focusedInputLabel,
                          root: classes.inputLabel,
                        }
                      }
                    }
                    InputProps={{
                      readOnly: activeState !== states.editingExisting,
                      disableUnderline: activeState !== states.editingExisting,
                      classes: {
                        underline: classes.line,
                      },
                    }}
                    className={classes.formField}
                    disabled={submissionInProgress}
                    error={!!this.getFormFieldReasonsInvalid('legs.0.maturityDate')}
                    helperText={this.getFormFieldReasonsInvalid('legs.0.maturityDate')}
                    id={'legs.0.maturityDate'}
                    label={'Maturity Date'}
                    onChange={this.handleTradeFieldChange}
                    parseType={TextFieldParseTypes.date}
                    type={'date'}
                    value={selectedTrade.legs[0].maturityDate}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    enterDelay={500}
                    title={'Calculated on submission'}
                  >
                    <ParsedTextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      disallowNegative
                      error={!!this.getFormFieldReasonsInvalid('legs.0.quoteAmount')}
                      helperText={this.getFormFieldReasonsInvalid('legs.0.quoteAmount')}
                      id={'legs.0.quoteAmount'}
                      label="Quote Amount"
                      parseType={TextFieldParseTypes.float}
                      value={selectedTrade.legs[0].quoteAmount}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <ParsedTextField
                    InputLabelProps={
                      {
                        classes: {
                          focused: classes.focusedInputLabel,
                          root: classes.inputLabel,
                        }
                      }
                    }
                    InputProps={{
                      readOnly: activeState !== states.editingExisting,
                      disableUnderline: activeState !== states.editingExisting,
                      classes: {
                        underline: classes.line,
                      },
                    }}
                    className={classes.formField}
                    disabled={submissionInProgress}
                    disallowNegative
                    error={!!this.getFormFieldReasonsInvalid('legs.0.forwardPoints')}
                    helperText={this.getFormFieldReasonsInvalid('legs.0.forwardPoints')}
                    id={'legs.0.forwardPoints'}
                    label="Forward Points"
                    onChange={this.handleTradeFieldChange}
                    parseType={TextFieldParseTypes.float}
                    value={selectedTrade.legs[0].forwardPoints}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    enterDelay={500}
                    title={'Calculated on submission'}
                  >
                    <ParsedTextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      error={!!this.getFormFieldReasonsInvalid('legs.0.effectiveRate')}
                      helperText={this.getFormFieldReasonsInvalid('legs.0.effectiveRate')}
                      id={'legs.0.effectiveRate'}
                      label="Effective Rate"
                      parseType={TextFieldParseTypes.float}
                      value={selectedTrade.legs[0].effectiveRate}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {(selectedTrade.legs.length > 1) && <React.Fragment>
          <div className={classes.tradeDetailSectionLabel}>
            Far Leg
          </div>
          <div className={classes.tradeDetailFormSection}>
            <Grid
              container
              direction={'row'}
              spacing={7}
            >
              <Grid item>
                <Grid
                  container
                  direction={'column'}
                >
                  <Grid item>
                    <TextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: activeState !== states.editingExisting,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      error={!!this.getFormFieldReasonsInvalid('legs.1.externalReference')}
                      helperText={this.getFormFieldReasonsInvalid(
                        'legs.1.externalReference')}
                      id={'legs.1.externalReference'}
                      label="External Reference"
                      onChange={this.handleTradeFieldChange}
                      value={selectedTrade.legs[1].externalReference}
                    />
                  </Grid>

                  <Grid item>
                    <FormControl
                      aria-describedby="farLegBank"
                      className={classes.formField}
                    >
                      <InputLabel
                        classes={{
                          focused: classes.focusedInputLabel,
                          root: classes.inputLabel,
                        }}
                        htmlFor="farLegBank"
                        style={{width: 150}}
                      >
                      Bank
                      </InputLabel>
                      <Select
                        disableUnderline={activeState !== states.editingExisting}
                        disabled={submissionInProgress}
                        id={'legs.1.bank'}
                        input={
                          <Input
                            classes={{
                              underline: classes.line,
                            }}
                          />
                        }
                        inputProps={{
                          readOnly: activeState !== states.editingExisting,
                        }}
                        name={'legs.1.bank'}
                        onChange={this.handleTradeFieldChange}
                        style={{width: 150}}
                        value={selectedTrade.legs[1].bank}
                      >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {processingBanks.map((bank, idx) => {
                          return <MenuItem
                            key={idx}
                            value={bank.partyCode}
                          >
                            {bank.name}
                          </MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <ParsedTextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: activeState !== states.editingExisting,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      error={!!this.getFormFieldReasonsInvalid('legs.1.tradeDate')}
                      helperText={this.getFormFieldReasonsInvalid('legs.1.tradeDate')}
                      id={'legs.1.tradeDate'}
                      label={'Trade Date'}
                      onChange={this.handleTradeFieldChange}
                      parseType={TextFieldParseTypes.date}
                      type={'date'}
                      value={selectedTrade.legs[1].tradeDate}
                    />
                  </Grid>
                  <Grid item>
                    <ParsedTextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: activeState !== states.editingExisting,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      disallowNegative
                      error={!!this.getFormFieldReasonsInvalid('legs.1.notionalAmount')}
                      helperText={this.getFormFieldReasonsInvalid('legs.1.notionalAmount')}
                      id={'legs.1.notionalAmount'}
                      label="Notional Amount"
                      onChange={this.handleTradeFieldChange}
                      parseType={TextFieldParseTypes.float}
                      value={selectedTrade.legs[1].notionalAmount}
                    />
                  </Grid>
                  <Grid item>
                    <ParsedTextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: activeState !== states.editingExisting,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      disallowNegative
                      error={!!this.getFormFieldReasonsInvalid('legs.1.spotPrice')}
                      helperText={this.getFormFieldReasonsInvalid('legs.1.spotPrice')}
                      id={'legs.1.spotPrice'}
                      label="Spot Price"
                      onChange={this.handleTradeFieldChange}
                      parseType={TextFieldParseTypes.float}
                      value={selectedTrade.legs[1].spotPrice}
                    />
                  </Grid>
                  <Grid item>
                    <ParsedTextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: activeState !== states.editingExisting,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      error={!!this.getFormFieldReasonsInvalid('legs.1.allInRate')}
                      helperText={this.getFormFieldReasonsInvalid('legs.1.allInRate')}
                      id={'legs.1.allInRate'}
                      label="All-In Rate"
                      onChange={this.handleTradeFieldChange}
                      parseType={TextFieldParseTypes.float}
                      value={selectedTrade.legs[1].allInRate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction={'column'}
                >
                  <Grid item>
                    <Tooltip
                      enterDelay={500}
                      title={'Cannot change direction'}
                    >
                      <TextField
                        InputLabelProps={
                          {
                            classes: {
                              focused: classes.focusedInputLabel,
                              root: classes.inputLabel,
                            }
                          }
                        }
                        InputProps={{
                          readOnly: true,
                          disableUnderline: activeState !== states.editingExisting,
                          classes: {
                            underline: classes.line,
                          },
                        }}
                        className={classes.formField}
                        disabled={submissionInProgress}
                        error={!!this.getFormFieldReasonsInvalid('legs.1.direction')}
                        helperText={this.getFormFieldReasonsInvalid('legs.1.direction')}
                        id={'legs.1.direction'}
                        label="Direction"
                        // onClick={this.handleBuySellToggle}
                        value={selectedTrade.legs[1].direction}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <ParsedTextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: activeState !== states.editingExisting,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      error={!!this.getFormFieldReasonsInvalid('legs.1.maturityDate')}
                      helperText={this.getFormFieldReasonsInvalid('legs.1.maturityDate')}
                      id={'legs.1.maturityDate'}
                      label={'Maturity Date'}
                      onChange={this.handleTradeFieldChange}
                      parseType={TextFieldParseTypes.date}
                      type={'date'}
                      value={selectedTrade.legs[1].maturityDate}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip
                      enterDelay={500}
                      title={'Calculated on submission'}
                    >
                      <ParsedTextField
                        InputLabelProps={
                          {
                            classes: {
                              focused: classes.focusedInputLabel,
                              root: classes.inputLabel,
                            }
                          }
                        }
                        InputProps={{
                          readOnly: true,
                          disableUnderline: activeState !== states.editingExisting,
                          classes: {
                            underline: classes.line,
                          },
                        }}
                        className={classes.formField}
                        disabled={submissionInProgress}
                        disallowNegative
                        error={!!this.getFormFieldReasonsInvalid('legs.1.quoteAmount')}
                        helperText={this.getFormFieldReasonsInvalid('legs.1.quoteAmount')}
                        id={'legs.1.quoteAmount'}
                        label="Quote Amount"
                        parseType={TextFieldParseTypes.float}
                        value={selectedTrade.legs[1].quoteAmount}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <ParsedTextField
                      InputLabelProps={
                        {
                          classes: {
                            focused: classes.focusedInputLabel,
                            root: classes.inputLabel,
                          }
                        }
                      }
                      InputProps={{
                        readOnly: activeState !== states.editingExisting,
                        disableUnderline: activeState !== states.editingExisting,
                        classes: {
                          underline: classes.line,
                        },
                      }}
                      className={classes.formField}
                      disabled={submissionInProgress}
                      disallowNegative
                      error={!!this.getFormFieldReasonsInvalid('legs.1.forwardPoints')}
                      helperText={this.getFormFieldReasonsInvalid('legs.1.forwardPoints')}
                      id={'legs.1.forwardPoints'}
                      label="Forward Points"
                      onChange={this.handleTradeFieldChange}
                      parseType={TextFieldParseTypes.float}
                      value={selectedTrade.legs[1].forwardPoints}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip
                      enterDelay={500}
                      title={'Calculated on submission'}
                    >
                      <ParsedTextField
                        InputLabelProps={
                          {
                            classes: {
                              focused: classes.focusedInputLabel,
                              root: classes.inputLabel,
                            }
                          }
                        }
                        InputProps={{
                          readOnly: true,
                          disableUnderline: activeState !== states.editingExisting,
                          classes: {
                            underline: classes.line,
                          },
                        }}
                        className={classes.formField}
                        disabled={submissionInProgress}
                        error={!!this.getFormFieldReasonsInvalid('legs.1.effectiveRate')}
                        helperText={this.getFormFieldReasonsInvalid('legs.1.effectiveRate')}
                        id={'legs.1.effectiveRate'}
                        label="Effective Rate"
                        parseType={TextFieldParseTypes.float}
                        value={selectedTrade.legs[1].effectiveRate}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>}
        {(selectedTrade.tradeParents && selectedTrade.tradeParents.length > 0) &&
        <React.Fragment>
          <div className={classes.tradeDetailSectionLabel}>
            Trade Parents
          </div>
          <div className={classes.tradeDetailFormSection}>
            <MUITable>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Number
                  </TableCell>
                  <TableCell>
                    Amount
                  </TableCell>
                  <TableCell>
                    Rate
                  </TableCell>
                  <TableCell/>
                </TableRow>
                {selectedTrade.tradeParents.map(
                  (entry, idx) => {
                    const parent = (linkedTrades || []).find(trd => trd.id === entry.parentTradeId) || {}
                    const acm = false
                    return (
                      <TableRow key={idx}>
                        <TableCell>
                          {parent.number}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center',
                            }}
                          >
                            <ParsedTextField
                              // className={classes.formField}
                              InputLabelProps={
                                {
                                  classes: {
                                    focused: classes.focusedInputLabel,
                                    root: classes.inputLabel,
                                  }
                                }
                              }
                              InputProps={{
                                readOnly: linkIdxEditing !== idx,
                                disableUnderline: linkIdxEditing !== idx,
                                classes: {
                                  underline: classes.line,
                                },
                              }}
                              disabled={submissionInProgress}
                              error={!!this.getFormFieldReasonsInvalid('trade.tradeParents[' + idx + '].amount')}
                              helperText={this.getFormFieldReasonsInvalid('trade.tradeParents[' + idx + '].amount')}
                              id={'trade.tradeParents[' + idx + '].amount'}
                              onChange={this.handleTradeFieldChange}
                              parseType={TextFieldParseTypes.float}
                              value={entry.amount}
                            />
                            {this.renderLinkControlIcon(idx, acm)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            enterDelay={500}
                            title={'Calculated on submission'}
                          >
                            <div>
                              {entry.rate}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={'View Details'}>
                            <IconButton
                              disabled={activeState === states.editingExisting || activeState === states.editingExistingLinks}
                              key={'view'}
                              onClick={() => this.handleSelectTrade(parent)}
                              style={{
                                padding: '0px',
                              }}>
                              <MdRemoveRedEye/>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </MUITable>
          </div>
        </React.Fragment>
        }
        {(tradeChildren.length > 0) &&
        <React.Fragment>
          <div className={classes.tradeDetailSectionLabel}>
            Trade Children
          </div>
          <div className={classes.tradeDetailFormSection}>
            <MUITable>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Number
                  </TableCell>
                  <TableCell/>
                </TableRow>
                {(tradeChildren || []).map(
                  (entry, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell>
                          {entry.number}
                        </TableCell>
                        <TableCell>
                          <Tooltip title={'View Details'}>
                            <IconButton
                              key={'view'}
                              onClick={() => this.handleSelectTrade(entry)}
                              style={{
                                padding: '0px',
                              }}>
                              <MdRemoveRedEye/>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </MUITable>
          </div>
        </React.Fragment>
        }
        {(selectedTrade.acmParents && selectedTrade.acmParents.length > 0) &&
        this.renderACMParentsList()
        }
        {(selectedTradeAcmChildren && selectedTradeAcmChildren.length > 0) &&
        this.renderACMChildList()
        }
        <div className={classes.tradeDetailSectionLabel}>
          Linked Invoices
        </div>
        <div className={classes.tradeDetailFormSection}>
          <EntityList
            columns={[
              {
                Header: 'Number',
                accessor: 'number',
              },
              {
                Header: 'Amount Due',
                accessor: 'amountDue',
                type: 'float'
              }
            ]}
            entities={this.linkedInvoices}
            onClick={(invoice) => this.setState({
              selectedLinkedInvoice: invoice,
            }, () => this.findCounterparties().then(() => this.setState({showLinkedInvoiceDetail: true})))}
          />
        </div>
      </React.Fragment>
    )
  }


  renderACMChildList = () => {
    const {classes} = this.props
    const {
      activeState, selectedTradeAcmChildren, selectedTrade
    } = this.state

    return (
      (selectedTradeAcmChildren && selectedTradeAcmChildren.length > 0) &&
      <React.Fragment>
        <div className={classes.tradeDetailSectionLabel}>
          Trade ACM Children
        </div>
        <div className={classes.tradeDetailFormSection}>
          <MUITable>
            <TableBody>
              <TableRow>
                <TableCell>
                  Number
                </TableCell>
                <TableCell>
                  Amount
                </TableCell>
                <TableCell/>
              </TableRow>
              {selectedTradeAcmChildren.map(
                (entry, idx) => {
                  const link = (entry.acmParents || []).find(p => p.parentTradeId === selectedTrade.id)
                  return (
                    <TableRow key={idx}>
                      <TableCell>
                        {entry.number}
                      </TableCell>
                      <TableCell>
                        {link ? link.amount : '-'}
                      </TableCell>
                      <TableCell>
                        <Tooltip title={'View Details'}>
                          <IconButton
                            disabled={activeState === states.editingExisting || activeState === states.editingExistingLinks}
                            key={'view'}
                            onClick={() => this.handleSelectTrade(entry)}
                            style={{
                              padding: '0px',
                            }}>
                            <MdRemoveRedEye/>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </MUITable>
        </div>
      </React.Fragment>
    )
  }

  renderACMParentsList = () => {
    const {classes} = this.props
    const {
      selectedTrade, activeState, linkedTrades, linkIdxEditing
    } = this.state

    const submissionInProgress = false

    return (
      (selectedTrade.acmParents && selectedTrade.acmParents.length > 0) &&
      <React.Fragment>
        <div className={classes.tradeDetailSectionLabel}>
          Trade ACM Parents
        </div>
        <div className={classes.tradeDetailFormSection}>
          <MUITable>
            <TableBody>
              <TableRow>
                <TableCell>
                  Number
                </TableCell>
                <TableCell>
                  Amount
                </TableCell>
                <TableCell>
                  Rate
                </TableCell>
                <TableCell/>
              </TableRow>
              {selectedTrade.acmParents.map(
                (entry, idx) => {
                  const parent = (linkedTrades || []).find(trd => trd.id === entry.parentTradeId) || {}
                  const acm = true
                  return (
                    <TableRow key={idx}>
                      <TableCell>
                        {parent.number}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                          }}
                        >
                          <ParsedTextField
                            InputLabelProps={
                              {
                                classes: {
                                  focused: classes.focusedInputLabel,
                                  root: classes.inputLabel,
                                }
                              }
                            }
                            InputProps={{
                              readOnly: linkIdxEditing !== idx,
                              disableUnderline: linkIdxEditing !== idx,
                              classes: {
                                underline: classes.line,
                              },
                            }}
                            disabled={submissionInProgress}
                            error={!!this.getFormFieldReasonsInvalid('trade.acmParents[' + idx + '].amount')}
                            helperText={this.getFormFieldReasonsInvalid('trade.acmParents[' + idx + '].amount')}
                            id={'trade.acmParents[' + idx + '].amount'}
                            onChange={this.handleTradeFieldChange}
                            parseType={TextFieldParseTypes.float}
                            value={entry.amount}
                          />
                          {this.renderLinkControlIcon(idx, acm)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          enterDelay={500}
                          title={'Calculated on submission'}
                        >
                          <div>
                            {entry.rate}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={'View Details'}>
                          <IconButton
                            disabled={activeState === states.editingExisting || activeState === states.editingExistingLinks}
                            key={'view'}
                            onClick={() => this.handleSelectTrade(parent)}
                            style={{
                              padding: '0px',
                            }}>
                            <MdRemoveRedEye/>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </MUITable>
        </div>
      </React.Fragment>
    )
  }

  renderError = () => {
    const {classes} = this.props
    return <div className={classes.errorDisplay}>
      <div>
        <Typography color={'error'}>
          <b>Error</b>
        </Typography>
      </div>
      <div>
        <ErrorIcon
          className={classes.errorIcon}
          color={'error'}
        />
      </div>
      <div>
        <Typography color={'error'}>
          Error
        </Typography>
      </div>
    </div>
  }

  render() {
    const {
      open,
      classes,
      closeDialog,
    } = this.props
    const {
      isLoading,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      onCloseNotification, selectedLinkedInvoice,
      showLinkedInvoiceDetail
    } = this.state

    return (
      <Dialog
        classes={{paper: classes.dialogPaper}}
        fullScreen
        modal={undefined}
        open={open}
      >
        {showLinkedInvoiceDetail &&
        <InvoiceDetailDialog
          counterparties={this.counterparties}
          invoice={selectedLinkedInvoice}
          onClose={() => this.setState({showLinkedInvoiceDetail: false})}
          readOnly
          show
        />}
        <DialogTitle classes={{root: classes.headerRoot}}>
          <div className={classes.dialogTitle}>
            <div className={classes.dialogTitleLogo}>
              <img
                alt={'Stellcap'}
                src={StellcapLogo}/>
            </div>
            <div className={classes.dialogTitleName}>
              TRADE SUMMARY
            </div>
            <div className={classes.closeIconWrapper}>
              <Tooltip
                placement={'top-start'}
                title={'Close'}
              >
                <IconButton
                  classes={{root: classes.closeIconButton}}
                  onClick={closeDialog}
                  size={'small'}
                >
                  <CloseIcon className={classes.closeIcon}/>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </DialogTitle>
        <DialogContent classes={{root: classes.rootOverride}}>
          {this.renderDialogContent()}
        </DialogContent>
        <Dialog
          BackdropProps={{classes: {root: classes.progressSpinnerDialogBackdrop}}}
          PaperProps={{classes: {root: classes.progressSpinnerDialog}}}
          className={classes.progressSpinnerDialog}
          open={isLoading}
        >
          <Spinner isLoading/>
        </Dialog>
        <NotificationSweetAlert
          customClass={'TradeDetailDialogAlert'}
          errorMessage={errorMessage}
          onClose={onCloseNotification}
          onConfirm={confirmationMethod}
          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </Dialog>
    )
  }

  getCurrencyPairName = (id) => {
    const {
      currencyPairs,
    } = this.props
    try {
      const ccyPair = retrieveFromList(
        new IDIdentifier(id),
        currencyPairs,
      )
      return ccyPair ? ccyPair.name : '-'
    } catch (e) {
      console.error('error retrieving currency pair from list', e)
    }
  }
}

DetailDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  currencyPairs: PropTypes.array.isRequired,
  disallowEditing: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  /**
   *  array of processing banks
   */
  processingBanks: PropTypes.array,
  /**
   * Currency Pairs From State
   */
  readOnly: PropTypes.bool,
  /**
   * pass true to disable edit button
   */
  theme: PropTypes.object.isRequired,
  trade: PropTypes.object.isRequired,
}
DetailDialog.defaultProps = {
  open: false,
}

const StyledDetailDialog = withStyles(styles, {withTheme: true})(DetailDialog)

export default StyledDetailDialog
