import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
} from '@material-ui/core'
import {DATE_CRITERION} from 'popcorn-js/search/criteria/types'
import {isFunction} from 'utils/Utils'
import {TextField, TextFieldParseTypes} from 'components/FormContols'

const styles = (theme) => ({

})

class Date extends Component {
  static CriteriaType = DATE_CRITERION

  constructor(props) {
    super(props)
    this.state = {
      startDateTouched: false,
      endDateTouched: false,
    }
    if (props.initValue === undefined) {
      this.state.value = {
        type: Date.CriteriaType,
        value: {
          field: props.fieldID,
          startDate: {
            ignore: true,
            date: 0,
            inclusive: true,
          },
          endDate: {
            ignore: true,
            date: 0,
            inclusive: true,
          },
        }
      }
    } else {
      this.state.value = props.initValue
      this.state.startDateTouched = true
      this.state.endDateTouched = true
    }
  }

  onChange = (event, start) => {
    let {
      value,
      startDateTouched,
      endDateTouched,
    } = this.state
    const {
      onChange
    } = this.props

    if (start) {
      if (!startDateTouched) {
        startDateTouched = true
      }
      value.value.startDate.date = event.target.value
      value.value.startDate.ignore = event.target.value === 0
    } else {
      if (!endDateTouched) {
        endDateTouched = true
      }
      value.value.endDate.date = event.target.value
      value.value.endDate.ignore = event.target.value === 0
    }

    if (isFunction(onChange)) {
      if (
        (value.value.startDate.ignore) &&
          (value.value.endDate.ignore)
      ) {
        onChange(undefined)
      } else {
        onChange(value)
      }
    }

    this.setState({
      value,
      startDateTouched,
      endDateTouched,
    })
  }

  render () {
    const {
      filterConfig,
      showBorder,
    } = this.props
    const {
      value,
      startDateTouched,
      endDateTouched,
    } = this.state

    let addInputProps = {}
    if (filterConfig) {
      if (filterConfig.width) {
        addInputProps.style = {
          width: filterConfig.width,
        }
      } else {
        addInputProps.style = {
          width: 162,
        }
      }
    }

    if (showBorder) {
      if (addInputProps.style) {
        addInputProps = {
          ...addInputProps,
          style: {
            ...addInputProps.style,
            backgroundColor: 'white',
            border: '1px solid #bfbfbf',
            borderRadius: '2px',
          },
        }
      } else {
        addInputProps = {
          ...addInputProps,
          style: {
            backgroundColor: 'white',
            border: '1px solid #D6D6D6',
            borderRadius: '2px',
          },
        }
      }
    }

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: 'auto',
          gridTemplateRows: 'auto auto',
        }}>
        <div>
          <TextField
            {...addInputProps}
            InputProps={{disableUnderline:true}}
            id="startDate"
            onChange={(e) => this.onChange(e, true)}
            parseType={TextFieldParseTypes.date}
            type="date"
            value={startDateTouched ? value.value.startDate.date : ''}
          />
        </div>
        <div>
          <TextField
            {...addInputProps}
            InputProps={{disableUnderline:true}}
            id="endDate"
            onChange={(e) => this.onChange(e)}
            parseType={TextFieldParseTypes.date}
            type="date"
            value={endDateTouched ? value.value.endDate.date : ''}
          />
        </div>
      </div>
    )
  }
}

const styledDate= withStyles(styles)(Date)

styledDate.propTypes = {
  // Not required, will be used to set value on construction
  fieldID: PropTypes.string.isRequired,
  filterConfig: PropTypes.object,
  // value: PropTypes.object.isRequired,
  initValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  showBorder: PropTypes.bool,
}

styledDate.defaultProps = {
  showBorder: false,
}

export default styledDate
