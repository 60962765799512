import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import InvoiceStation from 'views/Invoices/InvoiceStation/InvoiceStation'
import CurrencyEntity from 'popcorn-js/financial/currency/Currency.js'

const InvoiceStationContainer = props => {
  return <InvoiceStation {...props}/>
}

const mapStateToProps = state => {
  return {
    currencies: state.currency.records,
    partyCode: state.security.claims.context.partyCode,
    party: state.myParty
  }
}
const mapDispatchToProps = () => {
  return {}
}

InvoiceStationContainer.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.instanceOf(CurrencyEntity)),
}

const ConnectedInvoiceStationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceStationContainer)

export default ConnectedInvoiceStationContainer
