import {isObject} from '../../../utils/Utils'

export default class BankingDetails {
  /**
   * @type string
   */
  bankName = ''
  /**
   * @type number
   */
  branchCode = undefined
  /**
   * @type string
   */
  branchName = ''
  /**
   * @type number
   */
  bankAccountNumber = undefined
  /**
   * @type string
   */
  bankAccountType = ''

  constructor(bankingDetails) {
    if (bankingDetails !== undefined &&
      (bankingDetails instanceof BankingDetails ||
        isObject(bankingDetails))) {
      try {
        this.bankName = bankingDetails.bankName
        this.branchCode = bankingDetails.branchCode
        this.branchName = bankingDetails.branchName
        this.bankAccountNumber = bankingDetails.bankAccountNumber
        this.bankAccountType = bankingDetails.bankAccountType
      } catch (e) {
        console.error(`error constructing banking details: ${e}`)
        throw e
      }
    }
  }
}
