// typical in-memory use: FormatNumber(amount)
// typical display use: FormatNumber(amount, true, true, 2)
// the default decimal places is 4
// set fixed to true, to pad decimal values.

export const FormatNumber = (
  amount = '0',
  outputAsString = false,
  thousandIndicator = false,
  decimalPlaces = 4,
  fixed = false,
) => {
  // Determine if value is negative
  const negative = (() => {
    // If amount is a string
    if (typeof amount === 'string' || amount instanceof String) {
      if (amount.length > 1) {
        if (amount[0] === '-') {
          return true
        }
      }
    }
    // The decimal places can be specified as the last parameter.
    // If amount is a number
    if (typeof amount === 'number' && isFinite(amount)) {
      const factor = Math.pow(10, decimalPlaces)
      amount = Math.round(amount * factor) / factor
      if ((amount * -1) > 0) {
        return true
      }
    }
  })()
  amount = amount == null ? '' : amount
  let stringAmount = '0'
  let decimalString = ''
  const integerPart = amount.toString().split('.')[0]
  let decimalPoint = amount.toString().indexOf('.') === -1 ? '' : '.'
  let decimalPart = amount.toString().split('.')[1]

  if (integerPart) {
    stringAmount = integerPart.replace(/[^0-9.]/g, '')
    stringAmount = stringAmount.replace(/\b0+\B/g, '')
  }
  if (thousandIndicator && outputAsString) {
    const rgx = /(\d+)(\d{3})/
    while (rgx.test(stringAmount)) {
      stringAmount = stringAmount.replace(rgx, '$1,$2')
    }
  } else {
    if (thousandIndicator && !outputAsString) {
      console.warn(
        'Format Number error: Can\'t add \'thousand indicator\' when output format is number')
    }
  }

  if (decimalPart && decimalPlaces && decimalPlaces > 0) {
    decimalPart = decimalPart.replace(/[^0-9.]/g, '')
    decimalString = decimalPart.substr(0, decimalPlaces)
  }

  if (fixed && outputAsString) {
    decimalPoint = '.'
    while (decimalString.length < decimalPlaces) {
      decimalString = decimalString + '0'
    }
  }

  const output = stringAmount + decimalPoint + decimalString
  const returnVal = outputAsString ? output : parseFloat(output)
  return negative ? `-${returnVal}` : returnVal
}

export const GetCurrencyPairObject = () => {
  throw Error('Not implemented')
}

export function canIgnoreErrors(reasons, fieldsToIgnore) {
  // For all of the reasons invalid...
  for (let i = 0; i < reasons.length; i++) {
    if (!fieldsToIgnore.includes(reasons[i].field)) {
      return false
    }
  }
  return true
}

/**
 * @return {string}
 */
export function FormatBoolean(boolean) {
  if (boolean) {
    return 'True'
  } else {
    return 'False'
  }

}

