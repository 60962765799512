import React from 'react'
import {connect} from 'react-redux'
import Company from './Company'

const Container = props => {
  return <Company {...props}/>
}

function mapStateToProps(state) {
  return {
    securityClaims: state.security.claims,
    allCurrencyPairs: state.currencyPair.records || [],
    allCurrencies: state.currency.records || [],
    partyCurrencyPairIds: state.myParty.currencyPairIds || [],
    partyCode: state.security.claims.context.partyCode,
    partyType: state.security.claims.context.partyType,
  }
}


const CompanyContainer = connect(
  mapStateToProps,
)(Container)

export default CompanyContainer
