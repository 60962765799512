import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  Step,
  StepButton,
  Stepper,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { isObject, isString } from 'utils/Utils';
import PropTypes from 'prop-types';
import {
  MdClose as CloseIcon,
  MdErrorOutline as ErrorIcon,
} from 'react-icons/md';
import Table from 'components/Table/index';
import moment from 'moment';
import { SystemDateFormat } from 'constants/formats';
import logoImg from 'assets/img/stellcap-logo-mini.svg';

import { InvoiceRecordkeeper } from 'popcorn-js/financial/invoice';
import InvoiceHandler from 'popcorn-js/financial/invoice/handler';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty';
import Fab from '@material-ui/core/Fab';

import styles from './styles';

const processingStates = {
  invoiceBatchValidation: 'Validating Invoices',
  invoiceRestore: 'Restoring Invoices',
  counterpartyCreation: 'Creating Counterparties',
  uploadNewInvoices: 'Uploading New Invoices',
  uploadUpdatedInvoices: 'Uploading Updated Invoices',
};
const errorStates = {
  processFileError: 'Error processing file',
  batchValidationError: 'Error validating batch of invoices',
  noRecordsToUpload: 'There are no new records to upload',
  counterpartyCreation: 'Error creating counterparties',
  buildingTable: 'Error building table',
  uploadNewError: 'Error creating new invoices',
  uploadUpdatedError: 'Error updating invoices',
};
const states = {
  'Counterparty Creation': {
    invoiceBatchValidation: processingStates.invoiceBatchValidation,
    batchValidationError: errorStates.batchValidationError,
    showMissingCounterparties: 0,
    counterpartyCreationInProgress: processingStates.counterpartyCreation,
    counterpartyCreationError: errorStates.counterpartyCreation,
    counterpartyCreationSuccess: 1,
  },
  'Resolve Invalid': {
    view: 2,
    errorBuildingTable: errorStates.buildingTable,
  },
  'Resolve Deleted': {
    view: 3,
    errorBuildingTable: errorStates.buildingTable,
  },
  'Resolve Duplicates': {
    view: 4,
    errorBuildingTable: errorStates.buildingTable,
  },
  'Confirm Upload': {
    view: 5,
    uploadConfirmed: 6,
    errorBuildingTable: errorStates.buildingTable,
    uploadNew: processingStates.uploadNewInvoices,
    uploadUpdated: processingStates.uploadUpdatedInvoices,
    uploadNewError: errorStates.uploadNewError,
    uploadUpdatedError: errorStates.uploadUpdatedError,
  },
  Finish: {
    view: 7,
    noRecordsToUploadError: errorStates.noRecordsToUpload,
  },
};
const events = {
  // Initialise to batch validation in progress
  init: states['Counterparty Creation'].invoiceBatchValidation,

  // "Counterparty Creation" Step Events
  batchValidationError: states['Counterparty Creation'].batchValidationError,
  batchValidationSuccessWithMissingCounterparties:
    states['Counterparty Creation'].showMissingCounterparties,
  batchValidationSuccessWithInvalid: states['Resolve Invalid'].view,
  batchValidationSuccessWithDuplicates: states['Resolve Duplicates'].view,
  batchValidationSuccessWithDeleted: states['Resolve Deleted'].view,
  batchValidationSuccessNoRecordsToUpload:
    states['Finish'].noRecordsToUploadError,
  batchValidationSuccess: states['Confirm Upload'].view,
  createAllCounterparties:
    states['Counterparty Creation'].counterpartyCreationInProgress,
  createAllCounterpartiesError:
    states['Counterparty Creation'].counterpartyCreationError,
  createAllCounterpartiesSuccess:
    states['Counterparty Creation'].counterpartyCreationSuccess,

  // "Resolve Duplicates" Step Events
  errorBuildingInvalidInvoicesTable:
    states['Resolve Duplicates'].errorBuildingTable,
  noDuplicatesToUpdate: states['Finish'].noRecordsToUploadError,

  // "Resolve Deleted" Step Events
  errorBuildingResolveDeletedInvoicesTable:
    states['Resolve Deleted'].errorBuildingTable,

  // "Invalid Invoices" Step Events
  errorBuildingResolveDuplicateInvoicesTable:
    states['Resolve Invalid'].errorBuildingTable,
  finishDuplicateResolution: states['Confirm Upload'].view,
  finishDeletedResolution: states['Resolve Duplicates'].view,

  // "Confirm Upload" Step Events
  errorBuildingConfirmUploadInvoicesTable:
    states['Confirm Upload'].errorBuildingTable,
  confirmUpload: states['Confirm Upload'].uploadConfirmed,
  uploadNew: states['Confirm Upload'].uploadNew,
  uploadUpdated: states['Confirm Upload'].uploadUpdated,
  errorUploadingNew: states['Confirm Upload'].uploadNewError,
  errorUploadingUpdated: states['Confirm Upload'].uploadUpdatedError,
  uploadingComplete: states['Finish'].view,
};
const steps = Object.keys(states);

// common utility functions
const getStep = (activeState) => {
  for (let stepIdx = 0; stepIdx < steps.length; stepIdx++) {
    const stepStates = states[steps[stepIdx]];
    if (isObject(stepStates)) {
      for (const stepState in stepStates) {
        if (stepStates[stepState] === activeState) {
          return steps[stepIdx];
        }
      }
    } else if (stepStates === activeState) {
      return steps[stepIdx];
    }
  }
};
const stepComplete = (stepLabel, activeStep) => {
  return steps.indexOf(activeStep) > steps.indexOf(stepLabel);
};
const processField = (key = '', rowValue = '', row = {}) => {
  if (((row.original || {}).invalidFields || {})[key]) {
    return <div style={{ color: '#ff111b' }}>{rowValue}</div>;
  }
  if ((row.original || {}).isNew) {
    if (((row.original || {}).changedFields || {})[key]) {
      return (
        <div style={{ color: '#ff111b', fontWeight: 'bold' }}>
          {rowValue}
          <sup>{'*'}</sup>
        </div>
      );
    }
    return <div>{rowValue}</div>;
  }
  return rowValue;
};
const processUnixDateForViewing = (dateUnix) => {
  if (!dateUnix) {
    return '-';
  }
  if (isString(dateUnix)) {
    return '-';
  }
  try {
    return moment.unix(dateUnix).format(SystemDateFormat);
  } catch (e) {
    return '-';
  }
};

/**
 * A stepped dialog that facilitates the uploading of invoices.
 */
function InvoiceImportDialog(props) {
  const {
    classes,
    onAwayClick,
    invoicesValidated,
    uploadSuccess,
    validateBatchInvoice,
    newInvoices,
    invoiceCounterparties,
    currencies,
    show,
    id,
    category,
  } = props;

  const [activeState, setActiveState] = useState(events.init);
  const [errors, setErrors] = useState({});
  const [newToUpload, setNewToUpload] = useState([]);
  const [existingToUpload, setExistingToUpload] = useState([]);
  const [invoicesCreateBatch, setInvoicesCreateBatch] = useState({
    failed: 0,
    succeeded: 0,
    invoiceReasonsFailed: [],
    inProgress: false,
    success: false,
    error: undefined,
  });
  const [invoicesUpdateBatch, setInvoicesUpdateBatch] = useState({
    failed: 0,
    succeeded: 0,
    invoiceReasonsFailed: [],
    inProgress: false,
    success: false,
    error: undefined,
  });
  const [counterpartiesCreateBatch, setCounterpartiesCreateBatch] = useState({
    inProgress: false,
    error: undefined,
    failed: 0,
    succeeded: 0,
    success: false,
  });
  const [invalid, setInvalid] = useState([]);
  const [duplicate, setDuplicate] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [, setUnique] = useState([]);

  const reInit = () => {
    setErrors({});
    setNewToUpload([]);
    setExistingToUpload([]);
    setActiveState(events.init);
  };
  const closeDialog = () => {
    reInit();
    onAwayClick();
  };

  /*
   * Update state according to props
   */
  useEffect(() => {
    setUnique(invoicesValidated.unique);
  }, [invoicesValidated.unique]);
  useEffect(() => {
    setInvalid(invoicesValidated.invalid);
  }, [invoicesValidated.invalid]);
  useEffect(() => {
    setDuplicate(invoicesValidated.duplicate);
  }, [invoicesValidated.duplicate]);
  useEffect(() => {
    setDeleted(invoicesValidated.deleted);
  }, [invoicesValidated.deleted]);
  useEffect(() => {
    if (getStep(activeState) === 'Counterparty Creation') {
      // Check if we are currently waiting for invoice batch validation, or re-validation
      if (
        activeState === states['Counterparty Creation'].invoiceBatchValidation
      ) {
        if (!invoicesValidated.validationInProgress) {
          // Then...
          // If an upload error exists
          if (invoicesValidated.uploadError) {
            setErrors({
              ...errors,
              [events.batchValidationError]: invoicesValidated.uploadError,
            });
            // change the state with batch validation error event
            setActiveState(events.batchValidationError);
          } else {
            // Otherwise the validation was successful
            // Set state appropriately depending on the contents of:
            //  -missingCounterparties
            //  -invalid
            //  -duplicates
            //  -unique
            if (invoicesValidated.missingCounterparties.length) {
              setActiveState(
                events.batchValidationSuccessWithMissingCounterparties
              );
            } else if (invoicesValidated.invalid.length) {
              setActiveState(events.batchValidationSuccessWithInvalid);
            } else if (invoicesValidated.deleted.length) {
              setNewToUpload([...invoicesValidated.unique]);
              setActiveState(events.batchValidationSuccessWithDeleted);
            } else if (invoicesValidated.duplicate.length) {
              setNewToUpload([...invoicesValidated.unique]);
              setActiveState(events.batchValidationSuccessWithDuplicates);
            } else if (invoicesValidated.unique.length) {
              setNewToUpload([...invoicesValidated.unique]);
              setActiveState(events.batchValidationSuccess);
            } else {
              setErrors({
                ...errors,
                [events.batchValidationSuccessNoRecordsToUpload]: '',
              });
              setActiveState(events.batchValidationSuccessNoRecordsToUpload);
            }
          }
        } else {
          // Validation is still in progress
        }
      }
    }
  }, [
    activeState,
    errors,
    invoicesValidated.deleted,
    invoicesValidated.duplicate,
    invoicesValidated.invalid,
    invoicesValidated.unique,
    invoicesValidated.validationInProgress,
    invoicesValidated.missingCounterparties,
    invoicesValidated.uploadError,
  ]);
  // convenience functions for better readability in the ensuing useEffect hook declaration
  const startCreate = () => {
    setInvoicesCreateBatch({
      inProgress: true,
      error: undefined,
      invoiceReasonsFailed: [],
      failed: 0,
      succeeded: 0,
      success: false,
    });
  };
  const createSuccess = (result) => {
    setInvoicesCreateBatch({
      inProgress: false,
      error: undefined,
      invoiceReasonsFailed: result.failed,
      failed: result.failed ? result.failed.length : 0,
      succeeded: result.succeeded ? result.succeeded.length : 0,
      success: true,
    });
  };
  useEffect(() => {
    const createError = (error) => {
      setInvoicesCreateBatch({
        inProgress: false,
        error: error,
        failed: 0,
        invoiceReasonsFailed: [],
        succeeded: 0,
        success: false,
      });
      setErrors({
        ...errors,
        [events.errorUploadingNew]: error,
      });
    };
    const startUpdate = () => {
      setInvoicesUpdateBatch({
        inProgress: true,
        error: undefined,
        failed: 0,
        invoiceReasonsFailed: [],
        succeeded: 0,
        success: false,
      });
    };
    const updateSuccess = (result) => {
      setInvoicesUpdateBatch({
        inProgress: false,
        error: undefined,
        invoiceReasonsFailed: [],
        failed: result.failed ? result.failed.length : 0,
        succeeded: result.succeeded ? result.succeeded.length : 0,
        success: true,
      });
    };
    const updateError = (error) => {
      setInvoicesUpdateBatch({
        inProgress: false,
        error: error,
        failed: 0,
        invoiceReasonsFailed: [],
        succeeded: 0,
        success: false,
      });
      setErrors({
        ...errors,
        [events.errorUploadingUpdated]: error,
      });
    };
    if (getStep(activeState) === 'Confirm Upload') {
      // If We are, check if upload has been confirmed
      if (activeState === states['Confirm Upload'].uploadConfirmed) {
        // Check if there are New Invoices to upload
        const updates = existingToUpload.map((inv) => {
          return {
            identifier: { id: inv.id },
            invoice: inv,
          };
        });
        if (newToUpload.length) {
          startCreate();
          // Upload new invoices
          setActiveState(events.uploadNew);
          InvoiceRecordkeeper.createBatch({ invoices: newToUpload })
            .then((result) => {
              createSuccess(result);
              if (existingToUpload.length) {
                startUpdate();
                InvoiceHandler.updateBatch({ updates: updates })
                  .then((result) => {
                    uploadSuccess();
                    updateSuccess(result);
                    setActiveState(events.uploadingComplete);
                  })
                  .catch((error) => {
                    updateError(error);
                    setActiveState(events.errorUploadingUpdated);
                  });
                setActiveState(events.uploadUpdated);
              } else {
                uploadSuccess();
                // There is nothing else to do, show Finish
                setActiveState(events.uploadingComplete);
              }
            })
            .catch((error) => {
              createError(error);
              setActiveState(events.errorUploadingNew);
            });
          // Or if there are Existing Invoices to upload
        } else if (existingToUpload.length) {
          startUpdate();
          InvoiceHandler.updateBatch({ updates: updates })
            .then((result) => {
              updateSuccess(result);
              uploadSuccess();
              setActiveState(events.uploadingComplete);
            })
            .catch((error) => {
              updateError(error);
              setActiveState(events.errorUploadingUpdated);
            });
          setActiveState(events.uploadUpdated);
        }
      }
    }
  }, [activeState, newToUpload, existingToUpload, errors, uploadSuccess]);
  useEffect(() => {
    if (getStep(activeState) === 'Resolve Duplicates') {
      if (duplicate.length === 0 && existingToUpload.length > 0) {
        setActiveState(events.finishDuplicateResolution);
      } else if (duplicate.length === 0 && existingToUpload.length === 0) {
        setActiveState(events.noDuplicatesToUpdate);
      }
    }
  }, [activeState, duplicate, existingToUpload]);
  useEffect(() => {
    if (getStep(activeState) === 'Resolve Deleted') {
      if (deleted.length === 0) {
        setActiveState(events.finishDeletedResolution);
      }
    }
  }, [activeState, deleted, existingToUpload]);

  // resolution handlers
  const resolveDuplicateExclude = (selectedInvoice) => {
    // Remove it from the list of duplicate invoices
    const duplicateNew = duplicate.filter((invoice) => {
      return !(
        invoice.number === selectedInvoice.number &&
        invoice.partyCode === selectedInvoice.partyCode &&
        invoice.counterparty === selectedInvoice.counterparty
      );
    });
    setDuplicate(duplicateNew);
  };
  const resolveDuplicateUpdate = (selectedInvoice) => {
    // If the selected invoice is the new one add it to
    // the list of existingToUpload (i.e. for update)
    if (selectedInvoice.isNew) {
      existingToUpload.push(selectedInvoice);
    }
    // Remove it from the list of duplicates
    const duplicateNew = duplicate.filter((invoice) => {
      return !(
        invoice.number === selectedInvoice.number &&
        invoice.partyCode === selectedInvoice.partyCode &&
        invoice.counterparty === selectedInvoice.counterparty
      );
    });
    // Update existingToUpload piece of state
    setExistingToUpload(existingToUpload);
    setDuplicate(duplicateNew);
  };
  const updateAllDuplicates = () => {
    // Select all new
    duplicate.forEach((invoice) => {
      if (invoice.isNew) {
        existingToUpload.push(invoice);
      }
    });
    // Update existingToUpload piece of state
    setExistingToUpload(existingToUpload);
    // Clear duplicates
    setDuplicate([]);
  };
  const resolveDeletedRestore = (selectedInvoice) => {
    // restore the invoice and then pass it on to the duplicate list
    const duplicateNew = duplicate.slice();
    InvoiceRecordkeeper.restore({ identifier: { id: selectedInvoice.id } })
      .then(() => {
        duplicateNew.push(selectedInvoice);
        // remove it from the list of deleted invoices
        const deletedNew = deleted.filter((invoice) => {
          return !(
            invoice.number === selectedInvoice.number &&
            invoice.partyCode === selectedInvoice.partyCode &&
            invoice.counterparty === selectedInvoice.counterparty
          );
        });
        setDeleted(deletedNew);
        setDuplicate(duplicateNew);
      })
      .catch((e) => {
        console.error('could not restore invoice:', e);
      });
  };
  const resolveDeletedExclude = (selectedInvoice) => {
    // Remove it from the list of deleted invoices
    const deletedNew = deleted.filter((invoice) => {
      return !(
        invoice.number === selectedInvoice.number &&
        invoice.partyCode === selectedInvoice.partyCode &&
        invoice.counterparty === selectedInvoice.counterparty
      );
    });
    // Update existingToUpload piece of state
    setExistingToUpload(existingToUpload);
    setDeleted(deletedNew);
  };
  const restoreAllDeleted = () => {
    // Select all new
    const duplicateNew = duplicate.slice();
    deleted.forEach((invoice) => {
      InvoiceRecordkeeper.restore({ identifier: { id: invoice.id } })
        .then(() => duplicateNew.push(invoice))
        .catch((e) => {
          console.error('could not restore invoice:', e);
        })
        .finally();
    });
    // Update existingToUpload piece of state
    setExistingToUpload(existingToUpload);
    setDuplicate(duplicateNew);
    // Clear deleted (assuming they were all restored)
    setDeleted([]);
  };

  // render functions
  const renderTable = () => {
    const tableData = (() => {
      switch (activeState) {
        case states['Resolve Invalid'].view:
          return invalid;
        case states['Resolve Deleted'].view:
          return deleted;
        case states['Resolve Duplicates'].view:
          return duplicate;
        case states['Confirm Upload'].view:
          return [...newToUpload, ...existingToUpload];
        default:
          console.error(
            `unable to determine which data to use to build table. State: ${activeState}, Step: ${getStep(
              activeState
            )}`
          );
          throw new Error(
            `unable to determine which data to use to build table. State: ${activeState}, Step: ${getStep(
              activeState
            )}`
          );
      }
    })();
    const width = {
      small: 120,
      medium: 170,
      large: 250,
    };
    const tableColumns = [
      {
        Header: 'Type',
        accessor: 'type',
        width: width.medium,
        Cell: (rowCell) => {
          return processField('type', rowCell.value, rowCell);
        },
      },
      {
        Header: 'Number',
        accessor: 'number',
        width: width.medium,
        Cell: (rowCell) => {
          return processField('number', rowCell.value, rowCell);
        },
      },
      {
        Header: 'Party Code',
        accessor: 'partyCode',
        width: width.small,
        Cell: (rowCell) => {
          return processField('partyCode', rowCell.value, rowCell);
        },
      },
      {
        Header: 'Counterparty',
        accessor: 'counterpartyId',
        width: width.large,
        Cell: (rowCell) => {
          const val =
            (
              (invoiceCounterparties || []).find(
                (c) => c.id === rowCell.value
              ) || {}
            ).name || rowCell.value;
          return processField('counterparty', val, rowCell);
        },
      },
      {
        Header: 'Amount',
        accessor: 'amountDue',
        width: width.small,
        Cell: (rowCell) => {
          return processField('amountDue', rowCell.value, rowCell);
        },
      },
      {
        Header: 'Currency',
        accessor: 'currencyId', //todo
        width: width.medium,
        Cell: (rowCell) => {
          const val =
            ((currencies || []).find((c) => c.id === rowCell.value) || {})
              .isoCode || rowCell.value;
          return processField('currencyId', val, rowCell);
        },
      },
      {
        Header: 'Cost Currency',
        accessor: 'costCurrencyId', // todo
        width: width.medium,
        Cell: (rowCell) => {
          const val =
            ((currencies || []).find((c) => c.id === rowCell.value) || {})
              .isoCode || rowCell.value;
          return processField('costCurrencyId', val, rowCell);
        },
      },
      {
        Header: 'Costing Rate',
        accessor: 'costingRate',
        width: width.small,
        Cell: (rowCell) => {
          return processField('costingRate', rowCell.value, rowCell);
        },
      },
      {
        Header: 'Capture Rate',
        accessor: 'captureRate',
        width: width.small,
        Cell: (rowCell) => {
          return processField('captureRate', rowCell.value, rowCell);
        },
      },
      {
        Header: 'Due date',
        accessor: 'dueDate',
        width: width.medium,
        Cell: (rowCell) => {
          const val = processUnixDateForViewing(rowCell.value);
          return processField('dueDate', val, rowCell);
        },
      },
      {
        Header: 'Financial Year',
        accessor: 'financialYear',
        width: width.medium,
        Cell: (rowCell) => {
          return processField('financialYear', rowCell.value, rowCell);
        },
      },
      {
        Header: 'Shipping Date',
        accessor: 'shippingDate',
        width: width.medium,
        Cell: (rowCell) => {
          const val = processUnixDateForViewing(rowCell.value);
          return processField('shippingDate', val, rowCell);
        },
      },
      {
        Header: 'Shipment Reference',
        accessor: 'shipmentReference',
        width: width.large,
        Cell: (rowCell) => {
          return processField('shipmentReference', rowCell.value, rowCell);
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: width.medium,
        Cell: (rowCell) => {
          return processField('status', rowCell.value, rowCell);
        },
      },
      {
        Header: 'Issue Date',
        accessor: 'issueDate',
        width: width.medium,
        Cell: (rowCell) => {
          const val = processUnixDateForViewing(rowCell.value);
          return processField('issueDate', val, rowCell);
        },
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        width: width.large,
        Cell: (rowCell) => {
          return processField('notes', rowCell.value, rowCell);
        },
      },
    ];
    // Add additional columns
    switch (activeState) {
      case states['Resolve Invalid'].view: {
        // Determine width for reason column
        let reasonLen = 70;
        tableData.forEach((inv) => {
          if (inv.reasons) {
            inv.reasons.forEach((reason) => {
              if (isString(reason.reason)) {
                reasonLen =
                  reasonLen > reason.reason.length * 11
                    ? reasonLen
                    : reason.reason.length * 11;
              }
            });
          }
        });
        reasonLen = reasonLen > 200 ? 200 : reasonLen;

        tableColumns.push({
          Header: 'Issues',
          id: 'invalid',
          width: reasonLen,
          accessor: (invalidInvoice) => {
            return (
              <div
                style={{
                  display: 'grid',
                  alignItems: 'center',
                  overflow: 'auto',
                }}
              >
                <ul>
                  {invalidInvoice.reasons.map((reason, idx) => {
                    return <li key={idx}>{reason.reason}</li>;
                  })}
                </ul>
              </div>
            );
          },
        });
        break;
      }
      case states['Resolve Duplicates'].view:
        tableColumns.unshift({
          Header: 'Action',
          width: 250,
          Cell: (rowCell) => {
            return (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  className={classes.button}
                  onClick={() => resolveDuplicateUpdate(rowCell.original)}
                  style={{
                    minWidth: '90px',
                    width: '90px',
                    height: '28px',
                  }}
                >
                  Update
                </Button>
                <Button
                  className={classes.button}
                  onClick={() => resolveDuplicateExclude(rowCell.original)}
                  style={{
                    minWidth: '90px',
                    width: '90px',
                    height: '28px',
                  }}
                >
                  Exclude
                </Button>
              </div>
            );
          },
        });
        break;
      case states['Resolve Deleted'].view:
        tableColumns.unshift({
          Header: 'Actions',
          width: 250,
          Cell: (rowCell) => {
            return (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  className={classes.button}
                  onClick={() => resolveDeletedRestore(rowCell.original)}
                >
                  Restore
                </Button>
                <Button
                  className={classes.button}
                  onClick={() => resolveDeletedExclude(rowCell.original)}
                >
                  Exclude
                </Button>
              </div>
            );
          },
        });
        break;
      default:
    }
    return (
      <Table
        columns={tableColumns}
        data={tableData}
        filterable
        id={id}
        noDataText={'No invoices to upload'}
      />
    );
  };
  const renderProcessing = () => {
    return (
      <div
        id="invoiceUploadDialogProcessing"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          alignItems: 'center',
          justifyItems: 'center',
          margin: '15px',
        }}
      >
        <div>
          <b>{activeState}</b>
        </div>
        <div>
          <CircularProgress className={classes.progress} />
        </div>
      </div>
    );
  };
  const renderError = (outOfStateError) => {
    const error = errors[activeState];

    let errorMsg = '';
    if (outOfStateError) {
      errorMsg = outOfStateError;
    } else if (isObject(error)) {
      if (error.message) {
        errorMsg = error.message;
      }
    } else if (isString(error)) {
      errorMsg = error;
    }

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          alignItems: 'center',
          justifyItems: 'center',
          margin: '15px',
        }}
      >
        <div>
          <b>{activeState}</b>
        </div>
        <div>
          <ErrorIcon className={classes.errorIcon} />
        </div>
        <div>{errorMsg}</div>
        <div>
          <Button
            className={classes.button}
            onClick={closeDialog}>
            Close
          </Button>
        </div>
      </div>
    );
  };
  const renderCounterpartyCreation = () => {
    return (
      <div
        id="invoiceUploadDialogCounterpartyCreationStepRoot"
        style={{ padding: 10 }}
      >
        <Grid
          alignItems={'center'}
          container
          direction={'column'}
          spacing={8}>
          {(() => {
            switch (activeState) {
              case states['Counterparty Creation'].showMissingCounterparties:
                return (
                  <React.Fragment>
                    <Grid item>
                      Some invoices have counterparties that do not exist.
                    </Grid>
                    <Grid item>
                      <Table
                        className="-highlight"
                        columns={[
                          {
                            Header: 'Counterparty',
                            accessor: 'name',
                            width: 500,
                          },
                        ]}
                        data={invoicesValidated.missingCounterparties}
                        defaultPageSize={
                          invoicesValidated.missingCounterparties.length >= 15
                            ? invoicesValidated.missingCounterparties.length
                            : 15
                        }
                        filterable
                        id="invoiceUploadDialogMissingCounterpartiesTable"
                        noDataText={'No Counterparties'}
                        showPagination={false}
                        style={{
                          height: '500px',
                        }}
                      />
                    </Grid>
                    <Grid item>What would you like to do?</Grid>
                    <Grid item>
                      <Grid
                        container
                        direction={'row'}
                        spacing={8}>
                        <Grid item>
                          <Button
                            className={classes.button}
                            id="invoiceUploadDialogMissingCounterpartiesCloseBtn"
                            onClick={closeDialog}
                          >
                            Close
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            className={classes.button}
                            id="invoiceUploadDialogMissingCounterpartiesCreateAllBtn"
                            onClick={() => {
                              setActiveState(events.createAllCounterparties);
                              CounterpartyRecordkeeper.createBatch({
                                counterparties:
                                  invoicesValidated.missingCounterparties,
                              })
                                .then((result) => {
                                  setCounterpartiesCreateBatch({
                                    inProgress: false,
                                    error: undefined,
                                    failed: result.failed
                                      ? result.failed.length
                                      : 0,
                                    succeeded: result.succeeded
                                      ? result.succeeded.length
                                      : 0,
                                    success: true,
                                  });
                                  setActiveState(
                                    events.createAllCounterpartiesSuccess
                                  );
                                })
                                .catch((error) => {
                                  setErrors({
                                    ...errors,
                                    [events.createAllCounterpartiesError]: error,
                                  });
                                  setActiveState(
                                    events.createAllCounterpartiesError
                                  );
                                });
                            }}
                          >
                            Create All
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              case states['Counterparty Creation'].counterpartyCreationSuccess:
                return (
                  <React.Fragment>
                    <Grid item>
                      <u id="invoiceUploadDialogMissingCounterpartiesCreateCreateComplete">
                        Counterparty Creation Complete
                      </u>
                    </Grid>
                    <Grid
                      id="invoiceUploadDialogMissingCounterpartiesSucceededCount"
                      item
                    >
                      Succeeded: {counterpartiesCreateBatch.succeeded}
                    </Grid>
                    <Grid
                      id="invoiceUploadDialogMissingCounterpartiesFailedCount"
                      item
                    >
                      Failed: {counterpartiesCreateBatch.failed}
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction={'row'}
                        spacing={8}>
                        {counterpartiesCreateBatch.failed !== 0 && (
                          <Grid item>
                            <Button
                              className={classes.button}
                              id="invoiceUploadDialogMissingCounterpartiesCloseBtn"
                              onClick={closeDialog}
                            >
                              Close
                            </Button>
                          </Grid>
                        )}
                        <Grid item>
                          <Button
                            className={classes.button}
                            id="invoiceUploadDialogMissingCounterpartiesNextBtn"
                            onClick={() => {
                              validateBatchInvoice(newInvoices);
                              reInit();
                            }}
                          >
                            {counterpartiesCreateBatch.failed === 0
                              ? 'Next'
                              : 'Try Again'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              default:
            }
          })()}
        </Grid>
      </div>
    );
  };
  const renderInvalidInvoices = () => {
    const RenderTable = (props) => {
      return (() => {
        try {
          return renderTable(props);
        } catch (e) {
          console.error(`error rendering table for invalid invoices: ${e}`);
          setErrors({
            ...errors,
            [events.errorBuildingInvalidInvoicesTable]: e,
          });
          setActiveState(events.errorBuildingInvalidInvoicesTable);
          return <div>Error Building table</div>;
        }
      })();
    };

    return (
      <div
        style={{
          padding: 10,
          display: 'grid',
          justifyItems: 'center',
          gridTemplateColumns: '1fr',
        }}
      >
        <div style={{ padding: '0px 0px 10px 0px' }}>
          <Grid
            alignItems={'center'}
            container
            direction={'column'}
            spacing={8}
          >
            <Grid item>Invalid Invoices Found</Grid>
            <Grid item>Please Resolve Issues and Try Again</Grid>
          </Grid>
        </div>
        <div style={{ display: 'grid' }}>
          <div style={{ overflow: 'auto' }}>
            <RenderTable id="invoiceUploadDialogResolveInvalidStpTable" />
          </div>
        </div>
      </div>
    );
  };
  const renderDeletedInvoices = () => {
    const RenderTable = (props) => {
      return (() => {
        try {
          return renderTable(props);
        } catch (e) {
          console.error(`error rendering table for deleted invoices: ${e}`);
          setErrors({
            ...errors,
            [events.errorBuildingResolveDeletedInvoicesTable]: e,
          });
          setActiveState(events.errorBuildingResolveDeletedInvoicesTable);
          return <div>Error Building table</div>;
        }
      })();
    };

    return (
      <div
        style={{
          padding: 10,
          display: 'grid',
          justifyItems: 'center',
          gridTemplateColumns: '1fr',
        }}
      >
        <div style={{ padding: '0px 0px 10px 0px' }}>
          <Grid
            alignItems={'center'}
            container
            direction={'column'}
            spacing={8}
          >
            <Grid item>
              Some invoices that changed in the upload file have been deleted.
            </Grid>
            <Grid item>
              Please select the invoices you would like to restore before
              updating, or exclude from the updates.
            </Grid>
            <Grid item>
              <Grid
                alignItems={'center'}
                container
                direction={'row'}
                spacing={8}
              >
                <Grid item>
                  <Button
                    className={classes.button}
                    id="invoiceUploadDialogResolveDeletedStpSelectAllNewBtn"
                    onClick={restoreAllDeleted}
                  >
                    Restore all
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'grid' }}>
          <div style={{ overflow: 'auto' }}>
            <RenderTable id="invoiceUploadDialogResolveDeletedStpTable" />
          </div>
        </div>
      </div>
    );
  };
  const renderDuplicateInvoices = () => {
    const RenderTable = (props) => {
      return (() => {
        try {
          return renderTable(props);
        } catch (e) {
          console.error(`error rendering table for duplicate invoices: ${e}`);
          setErrors({
            ...errors,
            [events.errorBuildingResolveDuplicateInvoicesTable]: e,
          });
          setActiveState(events.errorBuildingResolveDuplicateInvoicesTable);
          return <div>Error Building table</div>;
        }
      })();
    };

    return (
      <div
        style={{
          padding: 10,
          display: 'grid',
          justifyItems: 'center',
          gridTemplateColumns: '1fr',
        }}
      >
        <div style={{ padding: '0px 0px 10px 0px' }}>
          <Grid
            alignItems={'center'}
            container
            direction={'column'}
            spacing={8}
          >
            <Grid item>Some invoices will be updated during this upload.</Grid>
            <Grid item>
              Please select the invoices that you would like to update or
              exclude.
            </Grid>
            <Grid item>
              <Grid
                alignItems={'center'}
                container
                direction={'row'}
                spacing={8}
              >
                <Grid item>
                  <Button
                    className={classes.button}
                    id="invoiceUploadDialogResolveDuplicatesStpSelectAllNewBtn"
                    onClick={updateAllDuplicates}
                  >
                    Update all
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'grid' }}>
          <div style={{ overflow: 'auto' }}>
            <RenderTable id="invoiceUploadDialogResolveDuplicatesStpTable" />
          </div>
        </div>
      </div>
    );
  };
  const renderConfirmUpload = () => {
    const RenderTable = (props) => {
      return (() => {
        try {
          return renderTable(props);
        } catch (e) {
          console.error(`error rendering table for upload confirmation: ${e}`);
          setErrors({
            ...errors,
            [events.errorBuildingConfirmUploadInvoicesTable]: e,
          });
          setActiveState(events.errorBuildingConfirmUploadInvoicesTable);
          return <div>Error Building table</div>;
        }
      })();
    };
    return (
      <div
        style={{
          padding: 10,
          display: 'grid',
          justifyItems: 'center',
          gridTemplateColumns: '1fr',
        }}
      >
        <div
          id="invoiceUploadDialogConfirmUploadStpRoot"
          style={{ padding: '0px 0px 10px 0px' }}
        >
          <Grid
            alignItems={'center'}
            container
            direction={'column'}
            spacing={8}
          >
            <Grid item>Please Confirm Upload</Grid>
            <Grid item>
              <Button
                className={classes.button}
                id="invoiceUploadDialogConfirmUploadStpUploadBtn"
                onClick={() => {
                  setActiveState(events.confirmUpload);
                }}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'grid' }}>
          <div style={{ overflow: 'auto' }}>
            {(() => {
              if (activeState === states['Confirm Upload'].view) {
                return (
                  <RenderTable id="invoiceUploadDialogConfirmUploadStpTable" />
                );
              } else if (
                activeState === states['Confirm Upload'].uploadConfirmed
              ) {
                return <div>Upload Confirmed</div>;
              } else {
                return <div>Error</div>;
              }
            })()}
          </div>
        </div>
      </div>
    );
  };
  const renderFinish = () => {
    const showCreateMsg =
      invoicesCreateBatch.succeeded !== 0 || invoicesCreateBatch.failed !== 0;
    const showUpdateMsg =
      invoicesUpdateBatch.succeeded !== 0 || invoicesUpdateBatch.failed !== 0;

    const msg = (action, succeeded, failed) => {
      return (
        <React.Fragment>
          <Grid item>
            <u id="invoiceUploadDialogFinishStpMsg">Invoice {action}:</u>
          </Grid>
          <Grid
            id="invoiceUploadDialogSucceededCount"
            item>
            Succeeded: {succeeded}
          </Grid>
          <Grid
            id="invoiceUploadDialogFailedCount"
            item>
            Failed: {failed}
          </Grid>
          <Grid
            id="invoiceUploadDialogFailedReasons"
            item>
            <FailedInvoiceReasonsList
              failedInvoiceReasons={invoicesCreateBatch.invoiceReasonsFailed}
            />
          </Grid>
        </React.Fragment>
      );
    };

    return (
      <Grid
        alignItems={'center'}
        container
        direction={'column'}
        spacing={8}>
        <Grid item>Upload Complete</Grid>
        {showCreateMsg &&
          msg(
            'Creation',
            invoicesCreateBatch.succeeded,
            invoicesCreateBatch.failed
          )}
        {showUpdateMsg &&
          msg(
            'Updating',
            invoicesUpdateBatch.succeeded,
            invoicesUpdateBatch.failed
          )}
        <Grid item>
          <Grid
            container
            direction={'row'}
            spacing={8}>
            <Grid item>
              <Button
                className={classes.button}
                id="invoiceUploadDialogFinishStpCloseBtn"
                onClick={closeDialog}
              >
                Close
              </Button>
            </Grid>
            {(invoicesUpdateBatch.failed !== 0 ||
              invoicesCreateBatch.failed !== 0) && (
              <Grid item>
                <Button
                  className={classes.button}
                  onClick={() => {
                    validateBatchInvoice(newInvoices);
                    reInit();
                  }}
                >
                  Try Again
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const activeStep = getStep(activeState);
  const dialogProps = (() => {
    switch (activeStep) {
      default:
        return {
          fullScreen: true,
        };
    }
  })();

  return (
    <Dialog
      className={classes.root}
      modal={undefined}
      onClose={closeDialog}
      open={show}
      scroll="paper"
      {...dialogProps}
    >
      <DialogTitle className={classes.dialogTitleWrapper}>
        <div className={classes.dialogTitle}>
          <div className={classes.TBDLogoWrapper}>
            <img
              alt="logo"
              className={classes.TBDLogoImg}
              src={logoImg} />
          </div>
          <div className={classes.dialogTitleText}>
            Importing: {category} INVOICES/CREDIT NOTES
          </div>
          <div className={classes.dialogTitleCloseBtnWrapper}>
            <Tooltip
              placement="top"
              title="Close">
              <Fab
                aria-label="Close"
                className={classes.dialogTitleCloseButton}
                color="primary"
                onClick={closeDialog}
              >
                <CloseIcon className={classes.dialogTitleCloseIcon} />
              </Fab>
            </Tooltip>
          </div>
        </div>
      </DialogTitle>
      <div className={classes.dialogContent}>
        <div className={classes.stepperWrapper}>
          <Stepper
            activeStep={steps.indexOf(activeStep)}
            className={classes.stepper}
            nonLinear
          >
            {steps.map((stepLabel, stepIdx) => {
              return (
                <Step key={stepIdx}>
                  <StepButton
                    completed={stepComplete(stepLabel, activeStep)}
                    id={
                      stepComplete(stepLabel, activeStep)
                        ? `invoiceUploadDialogStepper_Step${stepIdx}_complete`
                        : `invoiceUploadDialogStepper_Step${stepIdx}_incomplete`
                    }
                  >
                    {stepLabel}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className={classes.stepContentWrapper}>
          {(() => {
            switch (true) {
              // Render Generic Processing Screen
              case Object.values(processingStates).includes(activeState):
                return renderProcessing();
              // Render Generic Error Screen
              case Object.values(errorStates).includes(activeState):
                return renderError();
              // Check For Steps and Render Them
              case activeStep === 'Counterparty Creation':
                return renderCounterpartyCreation();
              case activeStep === 'Resolve Invalid':
                return renderInvalidInvoices();
              case activeStep === 'Resolve Deleted':
                return renderDeletedInvoices();
              case activeStep === 'Resolve Duplicates':
                return renderDuplicateInvoices();
              case activeStep === 'Confirm Upload':
                return renderConfirmUpload();
              case activeStep === 'Finish':
                return renderFinish();
              default:
                return renderError('Invalid State');
            }
          })()}
        </div>
      </div>
    </Dialog>
  );
}

InvoiceImportDialog.propTypes = {
  /**
   * Determines if the dialog is open
   */
  classes: PropTypes.object.isRequired,
  /**
   * Function which can be called to close the dialog
   * i.e. set prop.show to False
   */
  currencies: PropTypes.array,
  /**
   * Redux State Object with invoice upload/validation information
   * The init state of this object is:
   *    {
   *     validationInProgress: false,
   *     counterpartyCreationInProgress: false,
   *     uploadError: undefined,
   *     unique: [],
   *     duplicates: [],
   *     invalid: [],
   *     missingCounterparties: [],
   *    }
   */
  id: PropTypes.string,
  invoiceCounterparties: PropTypes.array,
  /**
   * Post Function which can be called to validate invoice batch
   */
  invoicesValidated: PropTypes.object.isRequired,
  /**
   * List of new invoices (used for re-validation)
   */
  newInvoices: PropTypes.array.isRequired,
  onAwayClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  /**
   * Type of invoice
   */
  category: PropTypes.string.isRequired,
  uploadSuccess: PropTypes.func,
  validateBatchInvoice: PropTypes.func.isRequired,
};

InvoiceImportDialog.defaultProps = {
  show: false,
};

const FailedInvoiceReasonsList = (props) => {
  if ((props.failedInvoiceReasons || []).length === 0) {
    return <div />;
  }
  return (
    <React.Fragment>
      <div
        style={{
          margin: '40px 0 10px',
          fontWeight: 'bold',
        }}
      >
        Failed Invoices:
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          textAlign: 'center',
          padding: '5px',
          fontWeight: 'bold',
        }}
      >
        <div>Invoice Number</div>
        <div>Reason(s)</div>
      </div>
      <div
        style={{
          height: '400px',
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          {(() => {
            return props.failedInvoiceReasons.map((invoiceReasons, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    textAlign: 'center',
                    padding: '5px',
                    borderWidth: '1px 0 0 0',
                    borderColor: '#ddd',
                    borderStyle: 'solid',
                  }}
                >
                  <div>{invoiceReasons.invoice.number}</div>
                  <Grid
                    container
                    direction={'column'}
                    spacing={8}>
                    {invoiceReasons.reasons.map((reason, index) => {
                      return (
                        <Grid
                          item
                          key={index}>
                          {reason.reason}
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              );
            });
          })()}
        </div>
      </div>
    </React.Fragment>
  );
};

FailedInvoiceReasonsList.propTypes = {
  failedInvoiceReasons: PropTypes.arrayOf(PropTypes.object),
};

const StyledInvoiceUploadDialog = withStyles(styles)(InvoiceImportDialog);

export default StyledInvoiceUploadDialog;
