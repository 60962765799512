import {
  CREATE_PASSWORD_ERROR,
  RETRIEVE_TABLE_CONFIG_ERROR,
  RETRIEVE_TABLE_CONFIG_STARTED,
  RETRIEVE_TABLE_CONFIG_SUCCESS,
  SAVE_TABLE_CONFIG_ERROR,
  SAVE_TABLE_CONFIG_STARTED,
  SAVE_TABLE_CONFIG_SUCCESS,
  SET_LOGIN_MESSAGE,
} from 'actions/actionTypes'
import {defaultTheme} from 'theme/options'
import {CREATE_PASSWORD_SUCCESS,} from '../actions/actionTypes'


const initState = {
  theme: defaultTheme,
  tableConfig: {
    config: {},
    save: {
      inProgress: false,
      success: false,
      error: undefined,
    },
    retrieve: {
      inProgress: false,
      success: false,
      error: undefined,
    },
  },
  loginMessage: '',
}

export default function ui(state = initState, action) {
  switch (action.type) {
    case SET_LOGIN_MESSAGE:
      return {...state, loginMessage: action.message}
    case CREATE_PASSWORD_SUCCESS:
      return {
        ...state, loginMessage: {
          success: 'Password Created Successful'
        }
      }
    case CREATE_PASSWORD_ERROR:
      return {
        ...state, loginMessage: {
          error: 'Cant create password'
        }
      }

    case SAVE_TABLE_CONFIG_STARTED:
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          save: {
            inProgress: true,
            success: false,
            error: undefined,
          },
        },
      }
    case SAVE_TABLE_CONFIG_SUCCESS:
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          config: {
            ...state.tableConfig.config,
            ...action.config,
          },
          save: {
            inProgress: false,
            success: true,
            error: undefined,
          },
        },
      }
    case SAVE_TABLE_CONFIG_ERROR:
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          save: {
            inProgress: false,
            success: false,
            error: action.error,
          },
        },
      }

    case RETRIEVE_TABLE_CONFIG_STARTED:
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          retrieve: {
            inProgress: true,
            success: false,
            error: undefined,
          },
        },
      }
    case RETRIEVE_TABLE_CONFIG_SUCCESS:
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          config: {
            ...state.tableConfig.config,
            ...action.config,
          },
          retrieve: {
            inProgress: false,
            success: true,
            error: undefined,
          },
        },
      }
    case RETRIEVE_TABLE_CONFIG_ERROR:
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          retrieve: {
            inProgress: false,
            success: false,
            error: action.error,
          },
        },
      }

    default:
      return state
  }
}
