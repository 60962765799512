import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {Marshal} from 'popcorn-js/search/identifier/Marshaller'
import {ID_IDENTIFIER} from '../../search/identifier/types'
import {MarshalCriteria as marshalCriteria} from 'popcorn-js/search/criteria/Marshaller'

export class Recordkeeper {
  /**
   * @param {SettlementInstructionType} settlementInstruction
   */
  static create(settlementInstruction) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Create',
      request: {settlementInstruction},
    })
  }

  /**
   * @param criteria: Filter criteria
   * @param query: Query options
   * @param {boolean} deleted
   */
  static find(criteria, query, deleted) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Find',
      request: {
        criteria: marshalCriteria(criteria),
        query,
        deleted,
      },
    })
  }

  static update(updateRequest) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Update',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id: updateRequest.id}}),
        settlementInstruction: updateRequest.settlementInstruction,
      },
    })
  }
  /**
   * @param {Array<SettlementInstructionType>} requestsForFunds
   */
  static createBatch(requestsForFunds) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.CreateBatch',
      request: {requestsForFunds},
    })
  }

  static retrieve(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Retrieve',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id:id}}),
      },
    })
  }

  static delete(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Delete',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id:id}}),
      },
    })
  }

  static deleteForever(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.DeleteForever',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id:id}}),
      },
    })
  }

  static restore(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Restore',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id:id}}),
      },
    })
  }
}

export default Recordkeeper
