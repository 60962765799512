import React, {useCallback, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import App from './App'
import {loginSuccess, logout} from 'actions/authentication'
import {partySwitchContext} from 'actions/party'
import {MuiThemeProvider} from '@material-ui/core/styles'
import {findAllMyPermissionsSuccess,} from 'actions/permissions'
import {setLoginMessage} from 'actions/authentication'
import {retrieveMyPartySuccess} from 'actions/party'
import {rateSocketConnected, rateSocketConnecting, rateSocketDisconnected, rateSocketStale,} from 'actions/socketStatus'
import Controller from 'popcorn-js/security/permission/controller'
import CurrencyPairRecordkeeper from 'popcorn-js/financial/currencyPair/recordkeeper'
import ProcessingBankRecordkeeper from 'popcorn-js/legalEntity/party/processingBank/recordkeeper'
import Retriever from 'popcorn-js/legalEntity/party/retriever'
import {findAllCurrenciesSuccess} from 'actions/currencies'
import {findAllProcessingBanksSuccess} from 'actions/processingBanks'
import {findAllCurrencyPairsSuccess} from 'actions/currencyPairs'
import {retrieveMyUserProfileSuccess} from 'actions/users'
import {CurrencyRecordkeeper} from 'popcorn-js/financial/currency'
import {UserManager} from 'popcorn-js/legalEntities/user'
import Inspector from 'popcorn-js/party/inspector/inspector'
import LoadingPage from 'views/Pages/LoadingPage/LoadingPage'
import {themeOptions} from 'theme/options'
import {createMuiTheme} from '@material-ui/core'
import {getErrorMessage} from '../utils/Utils'
import PropTypes from 'prop-types'
import { AppContextProvider } from 'appContext'
import { PARTY_TYPE_SYSTEM, PARTY_TYPE_COMPANY, PARTY_TYPE_BROKER, PARTY_TYPE_INDIVIDUAL, 
  PARTY_TYPE_PROCESSING_BANK, PARTY_TYPE_PROCESSING_ORG } from 'constants/partyTypes';

function mapStateToProps(state) {
  return {
    securityClaims: state.security.claims || {},
    viewPermissions: state.security.viewPermissions || [],
    myParty: state.myParty,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(logout()),
    loginSuccess: (authResponse) => dispatch(loginSuccess(authResponse)),
    onContextSwitch: (partyCode) => dispatch(partySwitchContext(partyCode)),
    findAllMyPermissionsSuccess: (result) => dispatch(
      findAllMyPermissionsSuccess(result)),
    setLoginMessage: (message) => dispatch(setLoginMessage(message)),
    retrieveMyPartySuccess: (result) => dispatch(
      retrieveMyPartySuccess(result)),
    findAllCurrenciesSuccess: (result) => dispatch(
      findAllCurrenciesSuccess(result)),
    findAllCurrencyPairsSuccess: (result) => dispatch(
      findAllCurrencyPairsSuccess(result)),
    findAllProcessingBanksSuccess: (result) => dispatch(
      findAllProcessingBanksSuccess(result)),
    retrieveMyUserProfileSuccess: (result) => dispatch(
      retrieveMyUserProfileSuccess(result),),
    doRateSocketConnected: () => dispatch(rateSocketConnected()),
    doRateSocketConnecting: () => dispatch(rateSocketConnecting()),
    doRateSocketDisconnected: () => dispatch(rateSocketDisconnected()),
    doRateSocketStale: () => dispatch(rateSocketStale()),
  }
}

function AppContainer(props) {
  const {
    securityClaims,
    retrieveMyPartySuccess,
    findAllMyPermissionsSuccess,
    findAllCurrenciesSuccess,
    findAllCurrencyPairsSuccess,
    findAllProcessingBanksSuccess,
    retrieveMyUserProfileSuccess,
    viewPermissions,
    myParty,
    setLoginMessage,
    onContextSwitch,
    onError,
    history,
    ...rest
  } = props

  const [loading, setLoading] = useState(true)
  const [extendedContext, setExtendedContext] = useState(undefined)
  const [theme, setTheme] = useState()
  const [partyCode, setPartyCode] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [appContext, setAppContext] = useState({})

  const startAll = useCallback(async (context) => {
    try {
      const {partyCode, partyType} = context

      const retrieveMyPartyResponse = await Retriever.retrieveMyParty({partyType, partyCode})
      retrieveMyPartySuccess(retrieveMyPartyResponse)
      const findAllMyPermissionsResponse = await Controller.FindAllMyPermissions()
      findAllMyPermissionsSuccess(findAllMyPermissionsResponse)
      const findCurrenciesResponse = await CurrencyRecordkeeper.find({})
      findAllCurrenciesSuccess(findCurrenciesResponse)
      const findCurrencyPairsResponse = await CurrencyPairRecordkeeper.find()
      findAllCurrencyPairsSuccess(findCurrencyPairsResponse)
      const findAllProcessingBanksResponse = await ProcessingBankRecordkeeper.find()
      findAllProcessingBanksSuccess(findAllProcessingBanksResponse)
      const extendContextResponse = await Inspector.extendContext({})
      setExtendedContext(extendContextResponse.extendedContext)
      const retrieveUserProfileResponse = await UserManager.getUserProfileById({userId: securityClaims.userId})
      retrieveMyUserProfileSuccess(retrieveUserProfileResponse)

      // update the global app context
      let newAppContext = {}
      newAppContext.securityClaims = securityClaims
      newAppContext.currencies = findCurrenciesResponse.records
      newAppContext.currencyPairs = findCurrencyPairsResponse.records
      newAppContext.partyType = context.partyType
      switch (context.partyType) {
        case PARTY_TYPE_COMPANY:
          newAppContext.party = retrieveMyPartyResponse.company
          break
        case PARTY_TYPE_INDIVIDUAL:
          newAppContext.party = retrieveMyPartyResponse.individual
          break
        case PARTY_TYPE_BROKER:
          newAppContext.party = retrieveMyPartyResponse.broker
          break
        case PARTY_TYPE_PROCESSING_ORG:
          newAppContext.party = retrieveMyPartyResponse.processingOrg
          break
        case PARTY_TYPE_PROCESSING_BANK:
          newAppContext.party = retrieveMyPartyResponse.processingBank
          break
        case PARTY_TYPE_SYSTEM:
          newAppContext.party = retrieveMyPartyResponse.system
          break
        default:
          console.warn('unknown party type: ', context.partyType)
      }
      newAppContext.extendedContext = extendContextResponse.extendedContext
      newAppContext.permissions = findAllMyPermissionsResponse.permissions
      newAppContext.localCurrency = newAppContext.currencies.find((currency) => currency.id === newAppContext.party.localCurrencyId)


      return newAppContext
    } catch (e) {
      const message = getErrorMessage(e)
      console.error('failed to start all: ', message)
      setErrorMessage('Failed to start application' + (message === '' ? '' : `: ${message}`))
    }
  }, [findAllCurrenciesSuccess, findAllCurrencyPairsSuccess, findAllMyPermissionsSuccess,
    findAllProcessingBanksSuccess, retrieveMyPartySuccess, retrieveMyUserProfileSuccess, securityClaims])

  useEffect(() => {
    setLoading(true)
    startAll(securityClaims.context)
      .catch(e=> setErrorMessage(getErrorMessage(e)))
      .then((newAppContext) => {
        setAppContext(newAppContext)
        setLoading(false)
      })
      .finally()
    return () => {
    }
  }, [securityClaims.context, partyCode, startAll])

  useEffect(() => {
    setTheme(createMuiTheme(themeOptions(securityClaims.context, securityClaims.originalContext)))
  }, [securityClaims.context, securityClaims.originalContext, partyCode])

  if (loading) {
    return (
      <MuiThemeProvider theme={theme}>
        <LoadingPage
          errorMessage={errorMessage}
          onDismiss={() => onError()} />
      </MuiThemeProvider>
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
      <AppContextProvider value={appContext}>
        <App
          extendedContext={extendedContext}
          history={history}
          myParty={myParty}
          onContextSwitch={partyCode => {
            onContextSwitch(partyCode)
            setPartyCode(partyCode)
            history.push('/app')
          }}
          onSetLoginMessage={setLoginMessage}
          securityClaims={securityClaims}
          viewPermissions={viewPermissions || []}
          {...rest}
        />
      </AppContextProvider>
    </MuiThemeProvider>
  )
}

AppContainer.propTypes = {
  doRateSocketConnected: PropTypes.func,
  doRateSocketConnecting: PropTypes.func,
  doRateSocketDisconnected: PropTypes.func,
  doRateSocketStale: PropTypes.func,
  findAllCurrenciesSuccess: PropTypes.func,
  findAllCurrencyPairsSuccess: PropTypes.func,
  findAllMyPermissionsSuccess: PropTypes.func,
  findAllProcessingBanksSuccess: PropTypes.func,
  history: PropTypes.object,
  myParty: PropTypes.object,
  onContextSwitch: PropTypes.func,
  onError: PropTypes.func,
  retrieveMyPartySuccess: PropTypes.func,
  retrieveMyUserProfileSuccess: PropTypes.func,
  securityClaims: PropTypes.object,
  setLoginMessage: PropTypes.func,
  viewPermissions: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
