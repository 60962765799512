// ##############################
// // // App styles
// #############################

import {containerFluid, drawerMiniWidth, drawerWidth, transition,} from 'assets/jss/material-dashboard-pro-react'

const appStyle = theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  mainPanel: {
    backgroundColor: theme.palette.background.default,
    // transitionProperty: 'top, bottom, width',
    // transitionDuration: '.2s, .2s, .35s',
    // transitionTimingFunction: 'linear, linear, ease',
    width: `calc(100% - ${drawerWidth}px)`,
    overflow: 'hidden',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    marginTop: theme.spacing(4),
    padding: '0px 15px',
    height: 'calc(100vh - 100px)',
    overflow: 'hidden !important',
  },
  container: {...containerFluid},
  map: {
    marginTop: '70px',
  },
  mainPanelSidebarMini: {
    width: `calc(100% - ${drawerMiniWidth}px)`,
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
})

export default appStyle
