import ProcessingBankEntity from 'popcorn-js/legalEntity/party/processingBank/ProcessingBank'
import {
  FIND_ALL_ProcessingBanks_SUCCESS,
} from '../actions/actionTypes'

export default function processingBank (state = {records: [], total: 0, action: {}}, action) {
  switch (action.type) {
    case FIND_ALL_ProcessingBanks_SUCCESS:
      return {
        ...state,
        records: action.result.records ? action.result.records.map(bank => new ProcessingBankEntity(bank)) : [],
        total: action.result.total,
      }
    default:
      return state
  }
}
