import React, { Component } from 'react';
import {
  withStyles,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  RootRef,
  Grid,
  TextField,
} from '@material-ui/core';
import ParsedTextField, {
  parseTypes as TextFieldParseTypes,
} from 'components/FormContols/TextField';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import { ComponentLevelLoader } from 'components/Loader/Loader';

const styles = (theme) => ({
  dialog: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  root: {
    padding: '0 20px',
    height: 'calc(100vh - 200px)',
    margin: '5px',
    borderWidth: '6px 0 0 0',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  formField: {
    height: '60px',
  },
  loadingLayout: {
    height: '100%',
    display: 'grid',
    alignContent: 'center',
    paddingBottom: '30px',
  },
});

class PrepaymentDetailDialog extends Component {
  static defaultProps = {
    show: false,
    readOnly: false,
  };

  state = {
    cardContentMaxHeight: 0,
    prepaymentCounterparty: {},
    loading: false,
  };

  setMaxCardContentHeight = (elem) => {
    const { cardContentMaxHeight } = this.state;
    if (cardContentMaxHeight > 0) {
      return;
    }
    try {
      this.setState({ cardContentMaxHeight: elem.clientHeight - 60 });
    } catch (e) {
      console.error('error setting max card content height', e);
    }
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { prepayment } = this.props;
    this.setState({ loading: true });
    try {
      const counterpartyRetrieveResponse = await CounterpartyRecordkeeper.retrieve(
        prepayment.counterpartyId
      );
      this.setState({
        prepaymentCounterparty: counterpartyRetrieveResponse.counterparty,
      });
    } catch (e) {
      console.error('error retrieving prepayment counterparty', e);
    }
    this.setState({ loading: false });
  };

  getCurrencyISOCode = (currencyId) => {
    const { currencies } = this.props;
    try {
      const currency = currencies.find((ccy) => ccy.id === currencyId);
      if (currency) {
        return currency.isoCode;
      }
      return '-';
    } catch (e) {
      console.error('error getting currency ISO Code', e);
      return '-';
    }
  };

  render() {
    const { show, closeDialog, classes, readOnly, prepayment } = this.props;
    const {
      cardContentMaxHeight,
      prepaymentCounterparty,
      loading,
    } = this.state;

    if (loading) {
      return (
        <div className={classes.loadingLayout}>
          <ComponentLevelLoader
            color={'black'}
            isLoading />
        </div>
      );
    }

    return (
      <Dialog
        PaperProps={{ classes: { root: classes.dialog } }}
        onClose={closeDialog}
        open={show}
      >
        <RootRef rootRef={this.setMaxCardContentHeight}>
          <Card className={classes.root}>
            <CardHeader title={'Prepayment Detail'} />
            <CardContent
              style={{
                maxHeight: cardContentMaxHeight,
                overflowY: 'scroll',
              }}
            >
              <Grid container>
                <Grid item>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'number'}
                    label="Number"
                    value={prepayment.number}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'externalReference'}
                    label="External Reference"
                    value={prepayment.externalReference}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'counterpartyId'}
                    label="Counterparty"
                    value={prepaymentCounterparty.name}
                  />
                </Grid>
                <Grid item>
                  <ParsedTextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'amount'}
                    label="Amount"
                    parseType={TextFieldParseTypes.float}
                    value={prepayment.amountDue}
                  />
                </Grid>
                <Grid item>
                  <ParsedTextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'paidAmount'}
                    label="Paid Amount"
                    parseType={TextFieldParseTypes.float}
                    value={prepayment.paidAmount}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'currencyId'}
                    label="Currency"
                    value={this.getCurrencyISOCode(prepayment.currencyId)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'costCurrencyId'}
                    label="Cost Currency"
                    value={this.getCurrencyISOCode(prepayment.costCurrencyId)}
                  />
                </Grid>
                <Grid item>
                  <ParsedTextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'costingRate'}
                    label="Costing Rate"
                    parseType={TextFieldParseTypes.float}
                    value={prepayment.costingRate}
                  />
                </Grid>
                <Grid item>
                  <ParsedTextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'dueDate'}
                    label="Due Date"
                    parseType={TextFieldParseTypes.date}
                    value={prepayment.dueDate}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'notes'}
                    label="Notes"
                    value={prepayment.notes}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'status'}
                    label="Status"
                    value={prepayment.status}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'partyCode'}
                    label="Party Code"
                    value={prepayment.partyCode}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'partyCode'}
                    label="Party Code"
                    value={prepayment.partyCode}
                  />
                </Grid>
                <Grid item>
                  <ParsedTextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'issueDate'}
                    label="Issue Date"
                    parseType={TextFieldParseTypes.date}
                    value={prepayment.issueDate}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                      disableUnderline: readOnly,
                    }}
                    className={classes.formField}
                    id={'direction'}
                    label="Direction"
                    value={prepayment.direction}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </RootRef>
      </Dialog>
    );
  }
}

const StyledPrepaymentDetailDialog = withStyles(styles)(PrepaymentDetailDialog);

export default StyledPrepaymentDetailDialog;
