class Comparator {
  static IsDirty(a, b) {
    if (!a || !b) {
      return true
    }
    if (a.description !== b.description) {
      return true
    }

    if (a.roleId !== b.roleId) {
      return true
    }

    if (a.authenticationAllowed !== b.authenticationAllowed) {
      return true
    }

    return false
  }
}

export default Comparator
