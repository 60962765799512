import {Entry as AuditEntry} from 'popcorn-js/audit'

class CurrencyPair {
  static camel = 'currencyPair'
  static nice = 'currency pair'
  static niceP = 'currency pairs'
  static capital = 'Currency Pair'
  static capitalP = 'Currency Pairs'
  /**
   * @type {string}
   */
  id = ''
  /**
   * @type {string}
   */
  name = ''
  /**
   * @type {number}
   */
  pips = undefined
  /**
   * @type {number}
   */
  settlementDays = undefined
  /**
   * @type {string}
   */
  baseCurrencyId = ''
  /**
   * @type {string}
   */
  quoteCurrencyId = ''
  /**
   * @type {AuditEntry}
   */
  auditEntry = new AuditEntry()

  /**
   * @param {object|CurrencyPair} [currencyPair]
   */
  constructor(currencyPair) {
    if (
      (currencyPair !== undefined)
    ) {
      try {
        this.id = currencyPair.id
        this.name = currencyPair.name
        this.pips = currencyPair.pips
        this.settlementDays = currencyPair.settlementDays
        this.baseCurrencyId = currencyPair.baseCurrencyId
        this.quoteCurrencyId = currencyPair.quoteCurrencyId
        this.auditEntry = new AuditEntry(currencyPair.auditEntry)
      } catch (e) {
        console.error(`error constructing currency pair: ${e}`)
        throw e
      }
    }
  }

}

export default CurrencyPair
