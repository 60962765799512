import React from 'react'
import PropTypes from 'prop-types'
// Material UI components
import {Dialog} from '@material-ui/core'
// Custom components
import OrderDetailContainer from 'components/Detail/order/OrderContainer'

const OrderDetailDialog = (props) => {
  const {
    show,
    onClose,
    onSaveSuccess,
    readOnly,
    isNew,
  } = props

  return (
    <Dialog
      maxWidth={'lg'}
      onClose={onClose}
      open={show}
    >
      <div
        style={{overflowY: 'scroll'}}>
        <OrderDetailContainer
          isNew={isNew}
          onClose={onClose}
          onSaveSuccess={onSaveSuccess}
          order={props.order}
          readOnly={readOnly}
        />
      </div>
    </Dialog>
  )
}

export default OrderDetailDialog

OrderDetailDialog.propTypes = {
  isNew: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func,
  order: PropTypes.object,
  readOnly: PropTypes.bool,
  show: PropTypes.bool.isRequired,
}
