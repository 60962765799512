import React from 'react'
import PropTypes from 'prop-types'
import Table from 'components/Table'
import {
  Input, withStyles, Button, Tabs, Tab, AppBar,
  TextField, Grid, Card, CardHeader, CardContent, Typography,
  CardActions,
} from '@material-ui/core'
import {FullPageLoader as Loader} from 'components/Loader/Loader'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'
import Recordkeeper from 'popcorn-js/financial/clientTier/recordkeeper'
import ClientTierEntity from 'popcorn-js/financial/clientTier/ClientTier'
import {
  SortObjects,
  FilterToSortBy, SortNumberString, SortByToFilter,
} from 'utils/Utils'
import ClientTierHistoryContainer from 'views/History/ClientTierHistory/ClientTierHistoryContainer'
import {ClientTierFields} from 'views/History/ClientTierHistory/ClientTierFields'
import Comparator from 'popcorn-js/financial/clientTier/comparator'
import {FormatNumber} from 'utils/TradeUtilities'
import Validator from 'popcorn-js/financial/clientTier/validator'

const styles = () => ({
  tabContainer: {
    height: 'calc(100vh - 295px)',
    marginTop: '8px',
    overflowY: 'scroll',
  },
})

const ACTIVE_STATE_VIEWING = 'ACTIVE_STATE_VIEWING'
const ACTIVE_STATE_CREATING = 'ACTIVE_STATE_CREATING'
const ACTIVE_STATE_EDITING = 'ACTIVE_STATE_EDITING'
const ACTIVE_STATE_VIEWING_DELETE = 'ACTIVE_STATE_VIEWING_DELETE'

const clientTierListLength = 10
const clientTierConfigurationListId = `${ClientTierEntity.camel}ConfigurationList`
const newAutoFocusElementId = `${ClientTierEntity.camel}NameField`

class ClientTier extends React.Component {
  state = {
    activeState: ACTIVE_STATE_VIEWING,

    clientTiers: [],
    total: 0,
    selectedRowIndex: -1,
    previouslySelectedRowIndex: -1,
    sortBy: ['name'],

    history: [],
    selected: new ClientTierEntity(),
    original: new ClientTierEntity(),
    previouslySelected: new ClientTierEntity(),

    isLoading: false,

    showHistory: false,

    successMessage: undefined,
    errorMessage: undefined,
    warningMessage: undefined,
    confirmationMethod: undefined,

    invalidFields: {},

    tabIndex: 0,
  }

  componentDidMount() {
    this.find()
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.currencyPairs || []).length !==
        (this.props.currencyPairs || []).length) {
      this.handleSelection(this.state.selected, this.state.selectedRowIndex,
        this.state.activeState)
    }
  }

  handleTabChange = (event, tabIndex) => {
    this.setState({tabIndex: tabIndex})
  }

  handleSelection = (selected, index, activeState) => {
    if (this.state.activeState === ACTIVE_STATE_EDITING && !activeState) {
      this.showDiscardConfirmation(() => {
        this.handleHideAlert()
        this.handleSelection(selected, index, ACTIVE_STATE_VIEWING)
      })
      return
    }

    let t = activeState
    const {
      activeState: currentState,
    } = this.state
    if (!activeState) {
      switch (currentState) {
        case ACTIVE_STATE_CREATING:
        case ACTIVE_STATE_EDITING:
          t = ACTIVE_STATE_VIEWING
          break
        default:
          t = currentState
      }
    }

    // populate undefined currency pair spreads
    selected.currencyPairSpreads.push(
      ...this.getUndefinedCurrencyPairs(selected).map(c => {
        return {currencyPairId: c, bidSpreadPoints: 0, askSpreadPoints: 0}
      }))

    this.setState({
      selected: new ClientTierEntity(selected),
      original: new ClientTierEntity(selected),
      previouslySelected: new ClientTierEntity(selected),

      selectedRowIndex: index,
      previouslySelectedRowIndex: index,

      activeState: t,
    })
  }

  handleSpreadChange = (currencyPair, bidOrAsk, value) => {
    let numerical = FormatNumber(value)
    // force number to be greater than zero
    if (numerical < 0) {
      numerical = 0
    }
    const selected = this.state.selected
    let defined = false
    selected.currencyPairSpreads.forEach(c => {
      if (c.currencyPairId === currencyPair) {
        c[bidOrAsk] = numerical
        defined = true
      }
    })
    if (!defined) {
      selected.currencyPairSpreads.push({
        currencyPair,
        [bidOrAsk]: value,
      })
    }
    this.setState({selected})
    this.handleChanges(selected)
  }

  handleFieldChange = (field, newValue) => {
    const {
      selected,
      invalidFields,
    } = this.state
    selected[field] = newValue
    invalidFields[field] = undefined

    this.setState({selected, invalidFields})
    this.handleChanges(selected)
  }

  handleChanges = (selected) => {
    const {
      activeState: currentState,
      selectedRowIndex,
    } = this.state

    let activeState
    switch (currentState) {
      case ACTIVE_STATE_CREATING:
        activeState = ACTIVE_STATE_CREATING
        break
      case ACTIVE_STATE_VIEWING:
        activeState = selectedRowIndex >= 0
          ? !Comparator.CompareAll(this.state.original, selected)
            ? ACTIVE_STATE_EDITING
            : ACTIVE_STATE_VIEWING
          : ACTIVE_STATE_CREATING
        break
      case ACTIVE_STATE_EDITING:
        activeState = !Comparator.CompareAll(this.state.original, selected)
          ? ACTIVE_STATE_EDITING
          : ACTIVE_STATE_VIEWING
        break
      default:
    }
    this.setState({activeState})
  }

  handleNew = () => {
    document.getElementById(newAutoFocusElementId).focus()
    this.setState({
      selected: new ClientTierEntity(),
      original: new ClientTierEntity(),
      selectedRowIndex: -1,
      activeState: ACTIVE_STATE_CREATING,
    })
  }

  find = async (deleted = false) => {
    const {
      sortBy,
    } = this.state

    const query = {sortBy}

    this.setState({isLoading: true})
    try {
      const findResponse = await Recordkeeper.find([], query, deleted)
      const clientTiers = findResponse.records.map(c => new ClientTierEntity(c))
      clientTiers.sort((a, b) => SortObjects(a, b, sortBy))

      const total = findResponse.total
      const selected = total > 0 ? clientTiers[0]:new ClientTierEntity()
      const selectedRowIndex = total > 0 ? 0:-1

      this.handleSelection(selected, selectedRowIndex,
        deleted ? ACTIVE_STATE_VIEWING_DELETE:ACTIVE_STATE_VIEWING)
      this.setState({total, clientTiers})
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleViewDelete = () => {
    this.find(true)
  }

  handleRetrieveHistory = async () => {
    this.setState({isLoading: true})
    try {
      const retrieveHistoryResponse = await Recordkeeper.retrieveHistory(
        this.state.selected.id,
      )
      this.setState({
        history: retrieveHistoryResponse.history.map(
          clientTier => new ClientTierEntity(clientTier),
        ),
        showHistory: true,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleReturn = () => {
    this.find()
  }

  handleRestore = async () => {
    this.setState({isLoading: true})
    try {
      await Recordkeeper.restore(this.state.selected.id)
      const clientTiers = this.state.clientTiers.slice()
      const idx = clientTiers.findIndex(
        c => c.id === this.state.selected.id,
      )
      clientTiers.splice(idx, 1)

      if (clientTiers.length > idx) {
        this.handleSelection(clientTiers[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ? new ClientTierEntity():clientTiers[idx - 1],
          idx - 1)
      }

      this.setState({
        successMessage: `${ClientTierEntity.capital} restored`,
        clientTiers,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    })
  }

  handleUpdate = async () => {
    if (await this.isValid()) {
      this.setState({isLoading: true})
      try {
        const updateResponse = await Recordkeeper.update(
          this.state.selected,
          this.state.original.id,
        )
        const clientTiers = this.state.clientTiers.slice() || []
        const clientTierToReplaceIndex = clientTiers.findIndex(
          c => c.id === updateResponse.clientTier.id)
        const selected = new ClientTierEntity(updateResponse.clientTier)
        if (clientTierToReplaceIndex >= 0) {
          clientTiers[clientTierToReplaceIndex] = selected
        }
        clientTiers.sort((a, b) => SortObjects(a, b, this.state.sortBy))
        const selectedIndex = clientTiers.findIndex(c => c.id === selected.id)

        this.handleSelection(selected, selectedIndex,
          ACTIVE_STATE_VIEWING)
        this.setState({
          successMessage: `${ClientTierEntity.capital} updated`,
          clientTiers,
        })
        ClientTier.scrollToPos(clientTierToReplaceIndex / clientTiers.length)
      } catch (e) {
        this.setState({errorMessage: e.message || e})
      }
      this.setState({isLoading: false})
    }
  }

  handleCreate = async () => {
    if (await this.isValid()) {
      this.setState({isLoading: true})
      try {
        const clientTier = this.state.selected
        clientTier.partyCode = this.props.partyCode
        const createResponse = await Recordkeeper.create(clientTier)
        const selected = new ClientTierEntity(createResponse.clientTier)
        const clientTiers = this.state.clientTiers.slice() || []
        clientTiers.push(selected)
        clientTiers.sort((a, b) => SortObjects(a, b, this.state.sortBy))

        const selectedRowIndex = clientTiers.findIndex(
          c => c.id === selected.id
        )

        this.handleSelection(selected, selectedRowIndex,
          ACTIVE_STATE_VIEWING)

        this.setState(
          {
            successMessage: `${ClientTierEntity.capital} created`,
            clientTiers,
          })
      } catch (e) {
        this.setState({errorMessage: e})
      }
      this.setState({isLoading: false})
    }
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    try {
      await Recordkeeper.delete(this.state.selected.id)
      const clientTiers = this.state.clientTiers.slice() || []
      const idx = clientTiers.findIndex(
        c => c.id === this.state.selected.id
      )
      clientTiers.splice(idx, 1)

      if (clientTiers.length > idx) {
        this.handleSelection(clientTiers[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ? new ClientTierEntity():clientTiers[idx - 1],
          idx - 1)
      }

      this.setState({
        successMessage: `${ClientTierEntity.capital} deleted`,
        clientTiers,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    this.handleHideAlert()
    try {
      await Recordkeeper.deleteForever(this.state.selected.id)
      const clientTiers = this.state.clientTiers.slice()
      const idx = clientTiers.findIndex(
        c => c.id === this.state.selected.id
      )
      clientTiers.splice(idx, 1)

      if (clientTiers.length > idx) {
        this.handleSelection(clientTiers[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ? new ClientTierEntity():clientTiers[idx - 1],
          idx - 1)
      }

      this.setState({
        successMessage: `${ClientTierEntity.capital} deleted`,
        clientTiers,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  showDeleteConfirmation = () => {
    this.setState({
      warningMessage: `You are about to permanently delete '${this.state.selected.name}'. Do you want to continue?`,
      confirmationMethod: this.handleDelete,
    })
  }

  showDiscardConfirmation = (confirm) => {
    this.setState({
      warningMessage: 'You have unsaved changes. Do you want to continue?',
      confirmationMethod: confirm,
    })
  }

  getUndefinedCurrencyPairs = (selected) => {
    const currencyPairSpreads = selected.currencyPairSpreads
    return (this.props.partyCurrencyPairIds || []).slice().filter(
      id => !currencyPairSpreads.find(
        spread => spread.currencyPairId === id
      )
    )
  }

  handleCancelUpdate = () => {
    this.setState({
      invalidFields: {},
      selected: new ClientTierEntity(this.state.original),
      activeState: ACTIVE_STATE_VIEWING,
    })
  }

  handleCancelCreate = () => {
    this.setState({
      invalidFields: {},
      selected: new ClientTierEntity(this.state.previouslySelected),
      original: new ClientTierEntity(this.state.previouslySelected),
      activeState: ACTIVE_STATE_VIEWING,
      selectedRowIndex: this.state.previouslySelectedRowIndex,
    })
  }

  render() {
    const {
      invalidFields,
      activeState,
      selected,
      clientTiers,
      selectedRowIndex,
    } = this.state

    const {
      classes
    } = this.props

    return (
      <div id={`${ClientTierEntity.camel}ConfigurationRoot`}>
        <Card
          style={{
            marginBottom: '10px',
          }}>
          {this.renderDialogs()}
          <CardHeader
            action={
              <div>
                <Button
                  disabled
                  onClick={() => this.setState({showImportDialog: true})}
                >
                      Import
                </Button>
                <Button
                  disabled
                  onClick={() => this.setState({showExportDialog: true})}
                >
                      Export
                </Button>
              </div>
            }
            title={
              <Typography
                gutterBottom
                variant="subtitle1">
                {activeState === ACTIVE_STATE_VIEWING_DELETE ?
                  `${ClientTierEntity.capitalP} - Delete`:
                  `${ClientTierEntity.capitalP}`}
              </Typography>
            }
          />
          <CardContent
            style={{
              padding: '0',
              margin: '0px 24px 24px 24px',
            }}
          >
            <Grid
              container
              direction={'row'}
              spacing={3}>
              <Grid
                item
                lg={4}
                md={5}
                sm={12}
                xs={12}
              >
                <Table
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'name',
                    },
                  ]}
                  data={clientTiers}
                  defaultSortMethod={(a, b) => SortNumberString(a, b)}
                  defaultSorted={SortByToFilter(this.state.sortBy)}
                  getTdProps={(state, rowInfo) => {
                    const rowIndex = rowInfo ? rowInfo.index:undefined
                    return {
                      onClick: (e, handleOriginal) => {
                        if (rowInfo) {
                          this.handleSelection(
                            new ClientTierEntity(rowInfo.original),
                            rowIndex,
                          )
                        }
                        if (handleOriginal) {
                          handleOriginal()
                        }
                      },
                      style: {
                        background: rowIndex === selectedRowIndex ?
                          this.props.theme.palette.secondary.light:
                          'white',
                        color: rowIndex === selectedRowIndex ?
                          this.props.theme.palette.secondary.contrastText:
                          'black',
                      },
                    }
                  }}

                  id={`${ClientTierEntity.camel}Table`}
                  onSortedChange={(filter) => this.setState(
                    {sortBy: FilterToSortBy(filter)})}
                  pageSize={Math.max(clientTierListLength,
                    (clientTiers || []).length)}
                  showPagination={false}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={7}
                sm={12}
                xs={12}>
                <AppBar
                  position={'static'}
                >
                  <Tabs
                    onChange={this.handleTabChange}
                    value={this.state.tabIndex}>
                    <Tab label="Main"/>
                  </Tabs>
                </AppBar>
                {this.state.tabIndex === 0 &&
                  <Grid
                    className={classes.tabContainer}
                    container
                    style={{padding: '25px', paddingTop: '10px'}}>
                    <Grid
                      item
                      xs={12}>
                      <TextField
                        disabled={activeState ===
                          ACTIVE_STATE_VIEWING_DELETE}
                        error={!!invalidFields.name}
                        helperText={invalidFields.name ?
                          '*' + invalidFields.name:
                          ''}
                        id="clientTierNameField"
                        label="Name"
                        margin="normal"
                        onChange={(e) => this.handleFieldChange('name',
                          e.target.value)}
                        value={selected.name}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}>
                      <Card>
                        <CardHeader
                          title={
                            <Typography
                              gutterBottom
                              variant="subtitle1">
                              {'Spreads'}
                            </Typography>
                          }
                        />
                        <CardContent>
                          <Grid
                            container
                            direction={'row'}
                            justify={'flex-start'}
                            spacing={3}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}>
                              <Table
                                columns={[
                                  {
                                    Header: 'Currency Pair',
                                    accessor: 'currencyPairId',
                                    Cell: rowInfo => {
                                      const ccyPair = (this.props.currencyPairs ||
                                            []).find(c => c.id === rowInfo.value)
                                      if (ccyPair) {
                                        return ccyPair.name
                                      } else {
                                        return ''
                                      }
                                    },
                                  },
                                  {
                                    Header: 'Bid Points',
                                    accessor: 'bidSpreadPoints',
                                    Cell: rowInfo => <Input
                                      disableUnderline
                                      disabled={activeState ===
                                          ACTIVE_STATE_VIEWING_DELETE}
                                      id="bidSpreadPoints"
                                      onChange={(e) => this.handleSpreadChange(
                                        rowInfo.original.currencyPairId,
                                        'bidSpreadPoints', e.target.value)}
                                      type="number"
                                      value={rowInfo.value}
                                    />,
                                  },
                                  {
                                    Header: 'Ask Points',
                                    accessor: 'askSpreadPoints',
                                    Cell: rowInfo => <Input
                                      disableUnderline
                                      disabled={activeState ===
                                          ACTIVE_STATE_VIEWING_DELETE}
                                      id="askSpreadPoints"
                                      onChange={(e) => this.handleSpreadChange(
                                        rowInfo.original.currencyPairId,
                                        'askSpreadPoints', e.target.value)}
                                      type="number"
                                      value={rowInfo.value}
                                    />,
                                  },
                                ]}
                                data={selected.currencyPairSpreads}
                                defaultPageSize={10}
                                defaultSorted={[
                                  {
                                    id: 'currencyPair',
                                    desc: false,
                                  }]}
                                disabled={activeState ===
                                  ACTIVE_STATE_VIEWING_DELETE}
                                id="currencyPairTable"
                                pageSize={Math.max((selected.currencyPairSpreads||[]).length, 5)}
                                showPagination={false}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                }

              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {this.renderControls()}
      </div>
    )
  }

  renderControls = () => {
    const {
      selectedRowIndex,
      activeState,
      isLoading,
    } = this.state
    return (
      <CardActions
        style={{
          display: 'block',
        }}
      >
        {activeState === ACTIVE_STATE_VIEWING &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ClientTierEntity.camel}NewButton`}
            onClick={this.handleNew}
            size="medium"
          >
            New
          </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING && selectedRowIndex >= 0 &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ClientTierEntity.camel}DeleteButton`}
            onClick={this.handleDelete}
            size="medium"
          >
            Delete
          </Button>
        }
        {activeState === ACTIVE_STATE_EDITING &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ClientTierEntity.camel}SaveButton`}
            onClick={this.handleUpdate}
            size="medium"
          >
            Save Changes
          </Button>
        }
        {activeState === ACTIVE_STATE_EDITING &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ClientTierEntity.camel}CancelUpdateButton`}
            onClick={this.handleCancelUpdate}
            size="medium"
          >
            Cancel
          </Button>
        }
        {activeState === ACTIVE_STATE_CREATING &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ClientTierEntity.camel}CreateButton`}
            onClick={this.handleCreate}
            size="medium"
          >
            Save New
          </Button>
        }
        {activeState === ACTIVE_STATE_CREATING &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ClientTierEntity.camel}CancelCreateButton`}
            onClick={this.handleCancelCreate}
            size="medium"
          >
            Cancel
          </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING_DELETE && selectedRowIndex >=
          0 &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ClientTierEntity.camel}RestoreButton`}
            onClick={this.handleRestore}
            size="medium"
          >
            Restore
          </Button>
        }
        {activeState === ACTIVE_STATE_VIEWING_DELETE && selectedRowIndex >=
          0 &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ClientTierEntity.camel}DeleteButton`}
            onClick={this.showDeleteConfirmation}
            size="medium"
          >
            Delete
          </Button>
        }
        <span
          style={{
            float: 'right',
          }}
        >
          {activeState === ACTIVE_STATE_VIEWING && selectedRowIndex >= 0 &&
          <Button
            color="primary"
            disabled={isLoading}
            id={`${ClientTierEntity.camel}RetrieveHistoryButton`}
            onClick={this.handleRetrieveHistory}
            size="medium"
          >
            Show History
          </Button>
          }
          {activeState === ACTIVE_STATE_VIEWING &&
            <Button
              color="primary"
              disabled={isLoading}
              id={`${ClientTierEntity.camel}ViewDeleteButton`}
              onClick={this.handleViewDelete}
              size="medium"
            >
              View Delete
            </Button>
          }
          {activeState === ACTIVE_STATE_VIEWING_DELETE &&
            <Button
              color="primary"
              disabled={isLoading}
              id={`${ClientTierEntity.camel}ReturnFromDeleteButton`}
              onClick={this.handleReturn}
              size="medium"
            >
              Return
            </Button>
          }
        </span>

      </CardActions>
    )
  }

  renderDialogs = () => {
    const {
      isLoading,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      selected,
      showHistory,
      history,
    } = this.state

    return (
      <span>
        <Loader
          color={this.props.theme.palette.primary.main}
          isLoading={isLoading}
        />
        {showHistory &&
          <ClientTierHistoryContainer
            entity={selected}
            entityFields={ClientTierFields}
            entityHistory={history}
            entityName={ClientTierEntity.capital}
            loading={isLoading}
            onHide={() => this.setState({showHistory: false})}
            open
          />
        }
        <NotificationSweetAlert
          customClass={`${ClientTierEntity.camel}ConfigAlert`}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}

          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </span>
    )
  }

  isValid = async () => {
    const {
      selected,
    } = this.state

    try {
      await Validator.ValidateData(selected)
    } catch (invalidFields) {
      if (invalidFields) {
        this.setState({invalidFields})
        return false
      }
    }
    return true
  }

  static scrollToPos = (pos) => {
    const objDiv = document.getElementById(clientTierConfigurationListId)
    if (objDiv) {
      objDiv.scrollTop = pos * objDiv.scrollHeight
    }
  }

}

ClientTier.propTypes = {
  classes: PropTypes.object,
  currencyPairs: PropTypes.arrayOf(PropTypes.object),
  partyCode: PropTypes.string.isRequired,

  partyCurrencyPairIds: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object,
}

const StyledClientTier = withStyles(styles, {withTheme: true})(ClientTier)

export default StyledClientTier
