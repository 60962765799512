export const INVOICE_STATUS_PENDING = 'PENDING';
export const INVOICE_STATUS_PARTIALLY_PAID = 'PARTIALLY_PAID';
export const INVOICE_STATUS_PAID = 'PAID';
export const PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS';
export const ALL_INVOICE_STATUSES = [
  INVOICE_STATUS_PENDING,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
  PAYMENT_IN_PROGRESS,
];

export const INVOICE_INVALID_REASON_COUNTERPARTY_DOES_NOT_EXIST =
  'counterparty does not exist';

// INVOICE PAYMENT DIRECTION
export const INVOICE_DIRECTION_PAYABLE = 'PAYABLE';
export const INVOICE_DIRECTION_RECEIVABLE = 'RECEIVABLE';
export const ALL_INVOICE_DIRECTIONS = [
  INVOICE_DIRECTION_PAYABLE,
  INVOICE_DIRECTION_RECEIVABLE,
];

// Invoice type
export const INVOICE_TYPE_PURCHASE_INVOICE = 'PURCHASE INVOICE';
export const INVOICE_TYPE_PURCHASE_CREDIT_NOTE = 'PURCHASE CREDIT NOTE';
export const INVOICE_TYPE_SALES_INVOICE = 'SALES INVOICE';
export const INVOICE_TYPE_SALES_CREDIT_NOTE = 'SALES CREDIT NOTE';
export const ALL_INVOICE_TYPES = [
  INVOICE_TYPE_PURCHASE_INVOICE,
  INVOICE_TYPE_PURCHASE_CREDIT_NOTE,
  INVOICE_TYPE_SALES_INVOICE,
  INVOICE_TYPE_SALES_CREDIT_NOTE,
];
