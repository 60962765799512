import React, {Fragment, useCallback, useEffect, useState} from 'react';
import AndileTable from 'components/AndileMaterialUITable/AndileTable';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  createStyles,
  IconButton,
  makeStyles, MenuList, Paper,
  Popover,
  Snackbar, Tab, Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  MdAddCircle,
  MdCancel,
  MdClear,
  MdExpandLess,
  MdExpandMore,
  MdMoreVert,
  MdRemoveRedEye as ViewDetailsIcon,
  MdTrendingDown,
  MdTrendingFlat,
  MdUnarchive,
  MdViewColumn,
} from 'react-icons/all';
import {connect} from 'react-redux';
import {CurrencyPair} from 'popcorn-js/financial/currencyPair/currencyPairTypes';
import {Company} from 'popcorn-js/legalEntity/party'

import {useService} from 'hooks/useService';
import Handler from 'popcorn-js/trade/handler';

import DetailDialog from 'views/TradeStation/DetailDialog'


import {DATE_CRITERION, NUMBER_CRITERION, TEXT_CRITERION} from 'popcorn-js/search/criteria/types';
import {LegAsTrade, OpenFECTrade, PartyType, Trade, TradeDirection, TradeStatus, TradeType} from 'popcorn-js/trade/tradeTypes';
import {HexToRGBA, processUnixDateForViewing} from 'utils/Utils';
import {FormatNumber} from 'utils/TradeUtilities';
import FilterListIcon from '@material-ui/icons/FilterList';
import {BackIcon} from 'components/Icons';
import {debounce} from 'lodash';
import ErrorIcon from 'components/Icons/ErrorIcon/ErrorIcon';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {fromUnixTime, isEqual} from 'date-fns';
import {Alert} from '@material-ui/lab';
import {getMidDay} from '../Tickets/util';
import DrawdownExtensionTicket from '../Tickets/DrawdownExtensionTicket';
import CancellationTicket from '../Tickets/CancellationTicket';
import {ProcessingBank} from '../../popcorn-js/legalEntity/party';
import {Currency} from '../../popcorn-js/financial/currency/currencyType';
import SpotForwardTicket from '../Tickets/SpotForwardTicket';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationSweetAlert from '../../components/SweetAlert/NotificationSweetAlert';
import StyledPopper from '../../components/Popper/Popper';
import moment from 'moment';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) =>
  createStyles({
    rootTable: {
      borderRadius: '8px',
      marginBottom: 0,
    },
    cardContentRoot: {
      padding: 0,
      gridTemplateColumns: 'auto auto 1fr auto',
      '&:last-child': {
        padding: 0,
      },
    },
    headerRoot: {
      backgroundColor: theme.palette.primary.medium,
      padding: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.75),
      height: '52px',
      color: theme.palette.primary.contrastText,
    },
    headerRootSelect: {
      backgroundColor: theme.palette.text.label,
      padding: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.75),
      height: '52px',
      color: theme.palette.background.paper,
    },
    headerTitleText: {
      color: theme.palette.primary.contrastText,
    },
    cardHeader: {
      padding: theme.spacing(),
      backgroundColor: theme.palette.primary.dark,
    },
    cardTitle: {
      fontWeight: 'bold',
      fontSize: 17,
      marginRight: '0px',
      marginTop: '0px',
      marginLeft: '10px'
    },
    action: {
      color: 'red',
      marginRight: '0px',
      marginTop: '0px'
    },
    currencyText: {
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'none',
      color: '#D2B200',
      margin: 'auto',
      marginLeft: '0px',
    },
    buttonContainer: {
      color: HexToRGBA(theme.palette.text.primary, 0.2),
      justifySelf: 'center',
      padding: theme.spacing(),
      paddingRight: '0px',
    },
    currencyEntries: {
      margin: '16px 16px 16px 16px',
    },
    currencyGridColumnLayout: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
      '&:last-of-type': {
        borderBottom: 'none',
      }

    },
    buttonContainedPrimary: {
      color: '#1A1B36',
      fontWeight: 'bold',
      fontSize: '12px',
      backgroundColor: '#45B2F7',
      '&:hover': {
        backgroundColor: HexToRGBA('#45B2F7', 0.6),
      }
    },
    button: {
      width: '50px',
      height: '30px',
    },
    indicator: {
      backgroundColor: '#F1C900',
    },
    iconButton: {
      '& svg': {
        fontSize: 32
      }
    }
  }),
);

const StyledImExValue = (props: {trade: LegAsTrade}) => {
  const [css] = useStyletron();
  const theme = useTheme<CustomTheme>()
  let color = 'inherit'
  if (props.trade.importExport === 'Import') {
    color = theme.palette.import.main
  }
  if (props.trade.importExport === 'Export') {
    color = theme.palette.export.main
  }
  return <span className={css({color})}>
    {props.trade.importExport?.toUpperCase()}
  </span>
}

//IconButton theme for selected header
const themeSelect = createMuiTheme({
  overrides: {
    // Style sheet name
    MuiIconButton: {
      // Name of the rule
      root: {
        // Some CSS
        color: '#1E2036',
      },
    },
  },
})

interface Props {
  myParty: Company,
  currencyPairs: CurrencyPair[],
  processingBanks: ProcessingBank[],
  partyType: PartyType,
  currencies: Currency[],
  userId: string
}

const mapStateToProps = (state: any) => {
  return {
    currencyPairs: state.currencyPair.records,
    currencies: state.currency.records,
  }
}


const tradeTypesForColumn = [
  {value: TradeType.SPOT},
  {value: TradeType.FORWARD},
  {value: TradeType.EXTENSION},
  {value: TradeType.DRAWDOWN},
]


const ticketStates = {
  nop: 0,
  showingTradeNew: 1,
  showingTradeHistory: 2,
  showingTradeExtension: 3,
  showingTradeDrawdown: 4,
  showingTradeCancellation: 5,
}


const FECTable: React.FC<Props> = (props: Props) => {
  const {currencyPairs, myParty, partyType, currencies, userId, processingBanks} = props
  const classes = useStyles(useTheme())

  const [ticketState, setTicketState] = useState<number>(ticketStates.nop)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<any>()
  const [newTradePopoverOpen, setNewTradePopoverOpen] = useState<boolean>(false)
  const [tradesTotal, setTradesTotal] = useState<number>(0)
  const [tradesResponse, setTradesResponse] = useState<OpenFECTrade[] | undefined>()
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState<CurrencyPair | undefined>()
  const [direction, setDirection] = useState<TradeDirection | undefined>()
  const [selectBool, setSelectBool] = useState<boolean>(false)
  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false)
  const [tabValue, setTabValue] = React.useState(0);

  const [loadErrorMessage, setLoadErrorMessage] = useState<string | undefined>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [warningMessage, setWarningMessage] = useState<string | undefined>()
  const [successMessage, setSuccessMessage] = useState<string | undefined>()
  const [confirmationMethod, setConfirmationMethod] = useState<any>()
  const [query, setQuery] = useState<any>({
    sortBy: ['relevantLeg.maturityDate', 'availableBalance', 'currencyPair.name'],
    order: ['asc', 'desc', 'asc'],
    limit: 5,
    offset: 0,
  })
  const [criteria, setCriteria] = useState<any>(
    [
      {
        type: DATE_CRITERION,
        value: {
          field: 'relevantLeg.maturityDate',
          startDate: {
            ignore: false,
            date: moment().local().startOf('day').unix(),
            inclusive: true,
          },
          endDate: {
            ignore: true,
            date: 0,
            inclusive: true,
          },
        }
      },
      {
        type: NUMBER_CRITERION,
        value: {
          field: 'availableBalance',
          lessThan: {
            ignore: true
          },
          moreThan: {
            amount: 0.0004,
            inclusive: false,
          }
        },
      },
      {
        type: TEXT_CRITERION,
        value: {
          field: 'tradeType',
          text: TradeType.FORWARD,
        },
      },
      {
        type: TEXT_CRITERION,
        value: {
          field: 'tradeType',
          text: TradeType.EXTENSION,
        },
      },
      {
        type: TEXT_CRITERION,
        value: {
          field: 'tradeType',
          text: TradeType.SPOT,
        },
      },
      {
        type: TEXT_CRITERION,
        value: {
          field: 'tradeType',
          text: TradeType.DRAWDOWN,
        },
      },
    ]
  )
  const [open, setOpen] = useState<boolean>(true)
  const [anchorEl, setAnchorEl] = useState(null)

  const [showFilterRow, setShowFilterRow] = useState<boolean>(false)
  const [colConfigOpen, setColConfigOpen] = useState<boolean>(false)
  const [deleted, setDeleted] = React.useState<boolean>(false)
  const [selected, setSelected] = React.useState<Trade[]>([])
  const [additionalActions, setAdditionalActions] = React.useState<any>([])

  const [restoreStarted, setRestoreStarted] = useState<boolean>(false)
  const [restoreDone, setRestoreDone] = useState<boolean>(false)


  const generateFindTradesRequest = useCallback(() => {

    return {
      criteria: criteria,
      query: query,
      deleted: deleted,
    }
  }, [query, criteria, deleted])
  const [
    {
      response: findTradesResponse,
      loading: findTradesLoading,
      error: findTradesError,
    },
    setFindTradesRequest
  ] = useService(generateFindTradesRequest(), Handler.FindOpenFECs)


  useEffect(() => {
    setFindTradesRequest(generateFindTradesRequest())
  }, [generateFindTradesRequest, setFindTradesRequest, query, criteria])

  useEffect(() => {
    if (findTradesResponse && findTradesResponse.records && !findTradesLoading) {
      setTradesTotal(findTradesResponse.total)
      setTradesResponse(findTradesResponse.records)
    }
    if (findTradesError) {
      setLoadErrorMessage(findTradesError)
    }
  }, [findTradesResponse, findTradesLoading, findTradesError])


  const handleChangePage = (event: any, newPage: any) => {
    const offset = query.limit * newPage
    const newQuery = {
      ...query,
      offset
    }
    setQuery(newQuery)
  }


  const handleChangeSorting = (sortBy: any, order: any) => {
    const newQuery = {
      ...query,
      sortBy: [sortBy],
      order: [order],
    }
    setQuery(newQuery)
  }

  const handleFilterChange = useCallback(debounce((newCrit: any) => {
    const newQuery = {
      ...query,
      offset: 0
    }
    const tabCrit = newCrit.filter((f: any) => f.value.field === 'relevantLeg.maturityDate')
    if (tabCrit.length === 0) {
      switch (tabValue) {
        case 0:
          newCrit.push(
            {
              type: DATE_CRITERION,
              value: {
                field: 'relevantLeg.maturityDate',
                startDate: {
                  ignore: false,
                  date: moment().local().startOf('day').unix(),
                  inclusive: true,
                },
                endDate: {
                  ignore: true,
                  date: 0,
                  inclusive: true,
                },
              }
            })
          break
        case 1: {
          const now = moment().local().endOf('day')
          newCrit.push(
            {
              type: DATE_CRITERION,
              value: {
                field: 'relevantLeg.maturityDate',
                startDate: {
                  ignore: true,
                  date: 0,
                  inclusive: true,
                },
                endDate: {
                  ignore: false,
                  date: moment(now).subtract(1, 'days').unix(),
                  inclusive: false,
                },
              }
            })
        }
      }
    }
    const tradeTypeCrit = newCrit.filter((f: any) => f.value.field === 'tradeType')
    if (tradeTypeCrit.length === 0) {
      newCrit.push({
        type: TEXT_CRITERION,
        value: {
          field: 'tradeType',
          text: TradeType.FORWARD,
        },
      })
      newCrit.push({
        type: TEXT_CRITERION,
        value: {
          field: 'tradeType',
          text: TradeType.SPOT,
        },
      })
      newCrit.push({
        type: TEXT_CRITERION,
        value: {
          field: 'tradeType',
          text: TradeType.DRAWDOWN,
        },
      })
      newCrit.push({
        type: TEXT_CRITERION,
        value: {
          field: 'tradeType',
          text: TradeType.EXTENSION,
        },
      })
    }
    const tradeTotalCrit = newCrit.filter((f: any) => f.value.field === 'availableBalance')
    if (tradeTotalCrit.length > 0) {
      if (tradeTotalCrit[0].value.moreThan.amount < 0.0004) {
        tradeTotalCrit.forEach((f: any) => f.value.moreThan =
          {
            amount: 0.0004,
            inclusive: false,
            ignore: false,
          })
      }
    } else {
      newCrit.push({
        type: NUMBER_CRITERION,
        value: {
          field: 'availableBalance',
          lessThan: {
            ignore: true
          },
          moreThan: {
            amount: 0.0004,
            inclusive: false,
          }
        },
      })
    }
    setQuery(newQuery)
    setCriteria(newCrit)
  }, 300), [query])
  const handleTabChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setTabValue(newValue)
    switch (newValue) {
      case 0:
        handleFilterChange([
          {
            type: DATE_CRITERION,
            value: {
              field: 'relevantLeg.maturityDate',
              startDate: {
                ignore: false,
                date: moment().local().startOf('day').unix(),
                inclusive: true,
              },
              endDate: {
                ignore: true,
                date: 0,
                inclusive: true,
              },
            }
          },
        ])
        break
      case 1: {
        const now = moment().local().endOf('day')
        handleFilterChange([
          {
            type: DATE_CRITERION,
            value: {
              field: 'relevantLeg.maturityDate',
              startDate: {
                ignore: true,
                date: 0,
                inclusive: true,
              },
              endDate: {
                ignore: false,
                date: moment(now).subtract(1, 'days').unix(),
                inclusive: false,
              },
            }
          },
        ])
      }
    }
  }


  const handleSelectRow = (rowData: OpenFECTrade) => {
    const ind = selected.findIndex((selectedTrd: Trade) => selectedTrd.id === rowData.Trade.id)
    const newSelected = [...selected]
    if (ind === -1) {
      newSelected.push(rowData.Trade)
    } else {
      newSelected.splice(ind, 1)
    }
    let newAdditionalActions = [...additionalActions]
    if (newSelected.length !== 1) {
      newAdditionalActions = []
    }
    let select = false
    if (newSelected.length > 0) {
      select = true
    }
    setSelectBool(select)
    setSelected(newSelected)
    setAdditionalActions(newAdditionalActions)
  }

  const handleSelectAll = () => {
    const newSelected = [...selected]
    if (newSelected.length !== 0) {
      setSelected([])
      setSelectBool(false)
    } else if (tradesResponse) {
      tradesResponse.forEach((trade: OpenFECTrade) => {
        newSelected.push(trade.Trade)
      }
      )
      setSelectBool(true)
      setSelected(newSelected)
    }
  }
  const handleCloseDialog = () => {
    setDetailDialogOpen(false)
    setSelected([])
    setSelectBool(false)
    setFindTradesRequest(generateFindTradesRequest())

  }
  const handleCloseColConfig = () => {
    setColConfigOpen(false)
  }
  const [
    {
      loading: restoreTradeLoading,
      error: restoreTradeError,
    },
    setRestoreTradeRequest
  ] = useService(null, Handler.Restore)

  const handleUnArchive = (Trade: Trade) => {
    if (Trade.id) {
      setRestoreStarted(true)
      setRestoreTradeRequest(Trade.id)
    }
  }

  const handlePriceSelection = (selectedPrice: string, direction: TradeDirection, showTicket: boolean) => {
    const newSelectedCurrencyPair = currencyPairs.find(
      c => c.id === selectedPrice)
    if (showTicket) {
      setSelectedCurrencyPair(newSelectedCurrencyPair)
      setDirection(direction)
      setTicketState(ticketStates.showingTradeNew)
      setNewTradePopoverOpen(false)
    }
  }

  useEffect(() => {
    if (restoreStarted && !restoreTradeLoading) {
      setRestoreDone(true)
    }
  }, [restoreStarted, restoreTradeLoading])

  const handleCloseMessage = () => {
    setRestoreDone(false)
    setRestoreStarted(false)
    setFindTradesRequest(generateFindTradesRequest())
  }
  const handleCancelClick = (trade: Trade) => {
    const newSelectedCurrencyPair = currencyPairs.find(
      c => c.id === trade.currencyPairId)
    let newDirection: TradeDirection = TradeDirection.BUY
    if (trade.legs && (trade.tradeType === TradeType.FORWARD || trade.tradeType === TradeType.SPOT)) {
      if (trade.legs[0].direction === TradeDirection.BUY) {
        newDirection = TradeDirection.SELL
      } else {
        newDirection = TradeDirection.BUY
      }
    } else if (trade.legs && (trade.tradeType === TradeType.EXTENSION)) {
      if (trade.legs[1].direction === TradeDirection.BUY) {
        newDirection = TradeDirection.SELL
      } else {
        newDirection = TradeDirection.BUY
      }
    }
    setSelectedCurrencyPair(newSelectedCurrencyPair)
    setDirection(newDirection)
    setTicketState(ticketStates.showingTradeCancellation)

  }
  const closeTicket = () => {
    setTicketState(ticketStates.nop)
    setFindTradesRequest(generateFindTradesRequest())
  }

  const generateRowActions = () => {
    const ExtendActionErrors: string[] = []
    const DrawDownActionErrors: string[] = []
    const directions: string[] = []
    const maturities: number[] = []
    const importExport: string[] = []
    const banks: string[] = []
    const CancellationErrors: string[] = []

    if (selected) {
      selected.forEach((selectedRow: Trade) => {
        let relevantLeg = 0
        if (selectedRow.availableBalance && (selectedRow.availableBalance <= 0)) {
          ExtendActionErrors.push('No Balance left')
        }
        if (selectedRow.status !== TradeStatus.OPEN) {
          ExtendActionErrors.push(
            'Can only extend trades in open status')
          DrawDownActionErrors.push(
            'Can only drawdown trades in open status')
        }
        if (selectedRow.availableBalance && (selectedRow.availableBalance <= 0)) {
          DrawDownActionErrors.push('No balance left')
        }
        if (selectedRow.tradeType === TradeType.EXTENSION) {
          relevantLeg = 1
        }
        if (selectedRow.tradeType === TradeType.DRAWDOWN) {
          CancellationErrors.push('Cannot perform cancellation on drawdown parents')
        }
        if (selectedRow.status === TradeStatus.MATURED) {
          CancellationErrors.push('Cannot perform cancellation on matured parents')
        }
        if (selectedRow.legs && selectedRow.importExport) {
          directions.push(selectedRow.legs[relevantLeg].direction)
          maturities.push(selectedRow.legs[relevantLeg].maturityDate)
          importExport.push(selectedRow.importExport)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          banks.push(selectedRow.legs[relevantLeg].bank)
        }
      })
      if (!directions.every((val, i, arr) => val === arr[0])) {
        const msg = 'Directions must be the same'
        CancellationErrors.push(msg)
        DrawDownActionErrors.push(msg)
        ExtendActionErrors.push(msg)
      }
      if (!maturities.every((val, i, arr) => isEqual(getMidDay(fromUnixTime(val)), getMidDay(fromUnixTime(arr[0]))))) {
        const msg = 'Maturity dates must be the same'
        CancellationErrors.push(msg)
        DrawDownActionErrors.push(msg)
        ExtendActionErrors.push(msg)
      }
      if (!importExport.every((val, i, arr) => val === arr[0])) {
        const msg = 'Import/export must be the same'
        CancellationErrors.push(msg)
        DrawDownActionErrors.push(msg)
        ExtendActionErrors.push(msg)
      }

      if (!banks.every((val, i, arr) => val === arr[0])) {
        const msg = 'Trade banks must be the same'
        CancellationErrors.push(msg)
        DrawDownActionErrors.push(msg)
        ExtendActionErrors.push(msg)
      }
      const canCancel = CancellationErrors.length === 0
      const canExtend = ExtendActionErrors.length === 0
      const canDrawdown = DrawDownActionErrors.length === 0


      if (deleted && selected.length === 1) {
        const items = []
        if (partyType === PartyType.COMPANY) {
          items.push(
            {
              icon: <MdUnarchive/>,
              tooltip: 'Restore',
              onClick: () => {
                handleUnArchive(selected[0])
              }
            }
          )
        }
        return items
      }

      let items: any[] = []

      if (selected.length === 1) {
        items = [
          {
            icon: <ViewDetailsIcon/>,
            tooltip: 'View Details',
            onClick: () => {
              if (selected.length === 1) {
                setDetailDialogOpen(true)
              }
            }
          },
        ]
      }

      switch (partyType) {
        case PartyType.COMPANY: {
          // No actions to perform on multiple trades when all trade types are not either extension or forward or spot
          if (selected.length > 1) {
            const tradeCurrencyPairId = selected[0].currencyPairId
            for (let i = 0; i < selected.length; i++) {
              if (!(selected[i].tradeType === TradeType.EXTENSION || selected[i].tradeType === TradeType.FORWARD || selected[i].tradeType === TradeType.SPOT)) {
                return items
              }
              if (selected[i].currencyPairId !== tradeCurrencyPairId) {
                return items
              }
              if (deleted) {
                return items
              }
            }
          }

          
          const drawdownTypes: (TradeType|undefined)[] = [TradeType.EXTENSION, TradeType.FORWARD]
          const drawdownActionVisible = selected.every((trade: Trade) => drawdownTypes.includes(trade.tradeType))
          if (drawdownActionVisible) {
            items.push(
              {
                icon: <MdTrendingDown/>,
                disabled: canDrawdown,
                tooltip: canDrawdown ?
                  'Perform Drawdown' :
                  'Cannot Perform Drawdown, ' +
                DrawDownActionErrors.join(),
                onClick: () => {
                  if (canDrawdown) {
                    setTicketState(ticketStates.showingTradeDrawdown)
                  }
                }
              }
            )
          }
          const extensionTypes: (TradeType|undefined)[] = [TradeType.DRAWDOWN, TradeType.EXTENSION, TradeType.FORWARD]
          const extensionActionVisible = selected.every((trade: Trade) => extensionTypes.includes(trade.tradeType))
          if (extensionActionVisible) {
            items.push(
              {
                icon: <MdTrendingFlat/>,
                disabled: canExtend,
                tooltip: canExtend ?
                  'Perform Extension' :
                  'Cannot Perform Extension, ' +
                    ExtendActionErrors.join(),
                onClick: () => {
                  if (canExtend) {
                    setTicketState(ticketStates.showingTradeExtension)
                  }
                }
              }
            )
          }

          const cancellationTypes: (TradeType|undefined)[] = [TradeType.DRAWDOWN, TradeType.EXTENSION, TradeType.FORWARD, TradeType.SPOT]
          const cancellationActionVisible = selected.every((trade: Trade) => cancellationTypes.includes(trade.tradeType))
          if (cancellationActionVisible) {
            items.push(
              {
                icon: <MdCancel/>,
                disabled: canCancel,
                tooltip: canCancel ?
                  'Perform Cancellation' :
                  'Cancellation parents Invalid, ' +
                    CancellationErrors.join(),
                onClick: () => {
                  if (canCancel) {
                    handleCancelClick(selected[0])
                  }
                }
              }
            )
          }
        }
          break
        default:
      }
      return items
    } else {
      return []
    }
  }

  const [
    {
      response: deleteTradeResponse,
      loading: deleteTradeLoading,
      error: deleteTradeError,
    },
    setDeleteTradesRequest
  ] = useService(null, Handler.Delete)


  useEffect(() => {
    if (deleteTradeResponse && !deleteTradeLoading) {
      setSuccessMessage('Trade deleted')
    }
    if (deleteTradeError) {
      setErrorMessage(deleteTradeError)
    }
  }, [deleteTradeResponse, deleteTradeLoading, deleteTradeError])
  const renderTableMoreAction = () => {
    const actions = []
    if (selected.length === 1 && partyType === PartyType.COMPANY) {
      actions.push(
        <MenuItem
          key={'archive'}
          onClick={() => {
            setWarningMessage(`You are about to delete '${selected[0].number}'. Do you want to continue?`)
            setConfirmationMethod(() => {
              setWarningMessage(undefined)
              setConfirmationMethod(undefined)
              setDeleteTradesRequest(selected[0].id)
            })
          }}
        >
          <div>
            Archive
          </div>
        </MenuItem>
      )
    }
    actions.push(
      <MenuItem
        key={'viewArchives'}
        onClick={() => {
          setQuery({
            ...query,
            offset: 0,
          })
          setDeleted(true)
        }}
      >
        <div>
          View Archives
        </div>
      </MenuItem>
    )
    return actions
  }
  const renderTableActionIcons = () => {


    const actions = []
    if (!deleted) {
      actions.push(
        <Tooltip
          key={'newTrade'}
          title="Add New Trade"
        >
          <IconButton
            aria-label="addNewTrade"
            onClick={(event) => {
              setNewTradePopoverOpen(true)
              setPopoverAnchorEl(event.currentTarget)
            }}
          >
            <MdAddCircle/>
          </IconButton>
        </Tooltip>
      )
    }
    if (selected.length > 0) {
      const rowActions = generateRowActions()
      for (let i = 0; i < rowActions.length; i++) {
        actions.push(
          <Tooltip
            key={rowActions[i].tooltip}
            title={rowActions[i].tooltip}
          >
            <IconButton
              aria-label={rowActions[i].tooltip}
              onClick={rowActions[i].onClick}
            >
              {rowActions[i].icon}
            </IconButton>
          </Tooltip>
        )
      }
    }


    if (deleted) {
      actions.push(
        <Tooltip
          key={'exitDeleted'}
          title="Exit Deleted"
        >
          <IconButton
            aria-label="exitDeleted"
            onClick={
              () => {
                setQuery({
                  ...query,
                  offset: 0,
                })
                setDeleted(false)
              }
            }
          >
            <BackIcon/>
          </IconButton>
        </Tooltip>,
      )
    }
    actions.push(
      <Tooltip
        key={'filterList'}
        title="Filter list"
      >
        <IconButton
          aria-label="filter list"
          onClick={() => {
            handleFilterChange(criteria)
            setShowFilterRow(!showFilterRow)
          }}
        >
          <FilterListIcon/>
        </IconButton>
      </Tooltip>)
    return actions
  }


  const getTradeableCurrencyPairs = () => {
    const partyCurrencyPairs = myParty.currencyPairIds
    if (partyCurrencyPairs) {
      const tradeablePairs: CurrencyPair[] = []

      for (const id of partyCurrencyPairs) {
        const found = currencyPairs.find(c => c.id === id)
        if (found) {
          const n = found.name.indexOf('/')
          found.displayName = found.name.substring(0, n !== -1 ? n : found.name.length)
          const foundSymbol = currencies.find(c => c.isoCode === found.displayName)
          if (foundSymbol) {
            found.displayName = found.displayName + ' (' + foundSymbol.symbol + ')'
          }
          tradeablePairs.push(found)
        }
      }
      return tradeablePairs
    } else {
      return []
    }
  }

  const tradableCurrencyPairs = getTradeableCurrencyPairs()

  const renderTickets = () => {
    switch (ticketState) {
      case ticketStates.showingTradeExtension:
        return (
          <Fragment>
            <DrawdownExtensionTicket
              banks={processingBanks}
              closeTicket={closeTicket}
              currencies={currencies}
              currencyPairs={tradableCurrencyPairs}
              parentTrades={selected}
              party={myParty}
              show={ticketState === ticketStates.showingTradeExtension}
              tradeType={TradeType.EXTENSION}
              userId={userId}
            />
          </Fragment>
        )
      case ticketStates.showingTradeCancellation:
        return (
          <CancellationTicket
            banks={processingBanks}
            closeTicket={closeTicket}
            currencies={currencies}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            currencyPairToTrade={selectedCurrencyPair}
            currencyPairs={tradableCurrencyPairs}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            direction={direction}
            parentTrades={selected}
            party={myParty}
            show={ticketState === ticketStates.showingTradeCancellation}
            userId={userId}
          />
        )
      case ticketStates.showingTradeDrawdown:
        return (
          <Fragment>
            <DrawdownExtensionTicket
              banks={processingBanks}
              closeTicket={closeTicket}
              currencies={currencies}
              currencyPairs={tradableCurrencyPairs}
              parentTrades={selected}
              party={myParty}
              show={ticketState === ticketStates.showingTradeDrawdown}
              tradeType={TradeType.DRAWDOWN}
              userId={userId}
            />
          </Fragment>
        )
      case ticketStates.showingTradeNew:
        return (
          <Fragment>
            <SpotForwardTicket
              banks={processingBanks}
              closeTicket={closeTicket}
              currencies={currencies}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              currencyPairToTrade={selectedCurrencyPair}
              currencyPairs={tradableCurrencyPairs}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              direction={direction}
              party={myParty}
              show={ticketState === ticketStates.showingTradeNew}
              userId={userId}
            />
          </Fragment>
        )
      case ticketStates.nop:
      default:
        return (
          <span/>
        )
    }
  }

  const handleHideAlert = () => {
    setErrorMessage(undefined)
    setSuccessMessage(undefined)
    setWarningMessage(undefined)
    setConfirmationMethod(undefined)
  }
  const MoreClick = (event: any) => {
    setAnchorEl(event.currentTarget ? event.currentTarget : null)
  }

  const renderTitle = () => {
    if (deleted) {
      return (
        <Typography
          variant={'h5'}
        >
          FEC Deleted Trades
        </Typography>
      )
    } else {
      return (
        <Typography>
          <Tabs
            aria-label="TableTabs"
            classes={{
              indicator: classes.indicator
            }}
            onChange={handleTabChange}
            value={tabValue}
          >
            <Tab
              label="FECs Available"
              style={{
                fontSize: '1.2rem',
                textTransform: 'none'
              }}
            />
            <Tab
              label="FECs Matured & Unlinked"
              style={{
                fontSize: '1.2rem',
                textTransform: 'none'
              }}/>
          </Tabs>
        </Typography>
      )
    }
  }

  return <div id="tradeStationRoot">
    {detailDialogOpen &&
    <DetailDialog
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      closeDialog={handleCloseDialog}
      currencyPairs={currencyPairs}
      open={detailDialogOpen}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      party={myParty}
      processingBanks={processingBanks}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trade={selected[0]}
    />}
    {renderTickets()}
    <Card
      className={classes.rootTable}
      square={false}
    >
      <CardHeader
        action={
          <React.Fragment>
            {open
              ? (
                <React.Fragment>
                  <ThemeProvider theme={selectBool ? themeSelect : {}}>
                    <React.Fragment>
                      {renderTableActionIcons()}
                      <Tooltip title="Open column configuration">
                        <IconButton
                          aria-label="view column"
                          onClick={() => setColConfigOpen(!colConfigOpen)}
                        >
                          <MdViewColumn/>
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        aria-label="moreActions"
                        onClick={MoreClick}
                      >
                        <MdMoreVert/>
                      </IconButton>
                      <Tooltip title={'Collapse'}>
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => setOpen(!open)}
                        >
                          <MdExpandLess/>
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  </ThemeProvider>
                  <StyledPopper
                    anchorEl={anchorEl}
                    onClose={setAnchorEl}
                  >
                    <Paper>
                      <MenuList
                        id="menuList"
                      >
                        {renderTableMoreAction()}
                      </MenuList>
                    </Paper>
                  </StyledPopper>
                </React.Fragment>
              )
              : (
                <Tooltip title={'Expand'}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => setOpen(!open)}
                  >
                    <MdExpandMore/>
                  </IconButton>
                </Tooltip>
              )
            }
          </React.Fragment>
        }
        classes={{root: selectBool ? classes.headerRootSelect : classes.headerRoot}}
        title={renderTitle()}
        titleTypographyProps={{
          className: classes.headerTitleText,
          variant: 'h6',
        }}
      />
      <Collapse in={open}>
        <CardContent
          classes={{root: classes.cardContentRoot}}
        >
          {((): React.ReactNode => {
            if (loadErrorMessage) {
              return (
                <div>
                  <ErrorIcon/>
                  <div style={{textAlign: 'center'}}>
                    {loadErrorMessage}
                  </div>
                </div>
              )
            } else {
              return (
                <React.Fragment>
                  <AndileTable
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    colConfigCloseFromCard={handleCloseColConfig}
                    colConfigOpenFromCard={colConfigOpen}
                    columns={[
                      {
                        title: 'IM/EX', field: 'importExport',
                        filter: {type: TEXT_CRITERION,},
                        // eslint-disable-next-line react/display-name
                        render: (rowData: any) => <StyledImExValue trade={rowData.Trade} />
                      },
                      {
                        title: 'External Reference', field: 'relevantLeg.externalReference',
                        filter: {type: TEXT_CRITERION,},
                        render: (rowData: any) => {
                          if (rowData.relevantLeg) {
                            return rowData.relevantLeg.externalReference
                          } else {
                            return '-'
                          }
                        }
                      },
                      {
                        title: 'Trade Type', field: 'tradeType',
                        filter:
                          {
                            type: TEXT_CRITERION,
                            options: tradeTypesForColumn,
                            displayAccessor: 'value',
                            valueAccessor: 'value',
                          },
                        render: (rowData: any) => {
                          if (rowData.Trade) {
                            return rowData.Trade.tradeType
                          } else {
                            return '-'
                          }
                        }
                      },
                      {
                        title: 'Currency', field: 'currencyPair.name',
                        filter:
                          {
                            options: currencyPairs,
                            displayAccessor: 'name',
                            valueAccessor: 'name',
                            type: TEXT_CRITERION
                          },
                        render: (rowData: any) => {
                          if (rowData.currencyPair) {
                            return rowData.currencyPair.name
                          } else {
                            return '-'
                          }
                        }
                      },
                      {
                        title: 'Trade Date', field: 'relevantLeg.tradeDate', filter: {type: DATE_CRITERION},
                        render: (rowData: any) => {

                          if (rowData.relevantLeg) {
                            return processUnixDateForViewing(rowData.relevantLeg.tradeDate)
                          } else {
                            return '-'
                          }
                        }
                      },
                      {
                        title: 'Notional Amount', field: 'relevantLeg.notionalAmount', filter: {type: NUMBER_CRITERION},
                        render: (rowData: any) => {
                          if (rowData.relevantLeg) {
                            return FormatNumber(rowData.relevantLeg.notionalAmount, true, true, 2, true)
                          } else {
                            return '-'
                          }
                        }
                      },
                      {
                        title: 'Available Balance', field: 'availableBalance', filter: {type: NUMBER_CRITERION},
                        render: (rowData: any) => {
                          return FormatNumber(rowData.Trade.availableBalance, true, true, 2, true)
                        }
                      },
                      {
                        title: 'Maturity', field: 'relevantLeg.maturityDate', filter: {type: DATE_CRITERION},
                        render: (rowData: any) => {
                          if (rowData.relevantLeg) {
                            return processUnixDateForViewing(rowData.relevantLeg.maturityDate)
                          } else {
                            return '-'
                          }
                        }
                      },
                      {
                        title: 'FWD Rate', field: 'relevantLeg.allInRate', filter: {type: NUMBER_CRITERION},
                        render: (rowData: any) => {
                          if (rowData.relevantLeg) {
                            const decimalPlaces = Math.log10(rowData.currencyPair.pips)
                            const retVal = FormatNumber(rowData.relevantLeg.allInRate, true, true,
                              decimalPlaces, true)
                            return <span style={{float: 'left'}}>{retVal ?
                              retVal :
                              'undefined'}</span>
                          } else {
                            return '-'
                          }
                        }
                      },
                      {
                        title: 'EFF Rate', field: 'relevantLeg.effectiveRate', filter: {type: NUMBER_CRITERION},
                        render: (rowData: any) => {
                          if (rowData.relevantLeg) {
                            const decimalPlaces = Math.log10(rowData.currencyPair.pips)
                            const retVal = FormatNumber(rowData.relevantLeg.effectiveRate, true, true,
                              decimalPlaces, true)
                            return <span style={{float: 'left'}}>{retVal ?
                              retVal :
                              'undefined'}</span>
                          } else {
                            return '-'
                          }
                        }
                      },
                    ]}
                    count={tradesTotal}
                    data={tradesResponse || []}
                    defaultColConfig={[
                      {header: 'IM/EX', visible: true},
                      {header: 'External Reference', visible: true},
                      {header: 'Trade Type', visible: true},
                      {header: 'Currency', visible: true},
                      {header: 'Trade Date', visible: true},
                      {header: 'Notional Amount', visible: true},
                      {header: 'Available Balance', visible: true},
                      {header: 'Maturity', visible: true},
                      {header: 'FWD Rate', visible: true},
                      {header: 'EFF Rate', visible: true},
                    ]}
                    disableHeader
                    handleChangePage={handleChangePage}
                    loading={findTradesLoading}
                    maxTableHeight={240}
                    onChangeSorting={handleChangeSorting}
                    onFilterChange={handleFilterChange}
                    onRowCheck={handleSelectRow}
                    onSelectAll={handleSelectAll}
                    order={query.order.length > 0 ? query.order[0] : undefined}
                    page={Math.ceil(query.offset / query.limit)}
                    rowClickAction={handleSelectRow}
                    rowDoubleClickAction={(rowData: OpenFECTrade) => {
                      setSelected([rowData.Trade])
                      setDetailDialogOpen(true)
                    }}
                    rowsPerPage={5}
                    selected={selected}
                    showCheckboxes
                    showFilterRowFromCard={showFilterRow}
                    sortBy={query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                    tableID={'TradesTable'}
                    title={'Trades'}
                  />
                </React.Fragment>
              )
            }
          })()}
          <NotificationSweetAlert
            customClass={'RecordCancellationTicketDetailDialog'}
            errorMessage={errorMessage}
            onClose={handleHideAlert}
            onConfirm={confirmationMethod}
            successMessage={successMessage}
            warningMessage={warningMessage}
          />
        </CardContent>
      </Collapse>
      <Snackbar
        autoHideDuration={3000}
        onClose={() => handleCloseMessage()}
        open={restoreDone && !restoreTradeLoading}>
        <Fragment>
          {!restoreTradeError &&
          <Alert
            onClose={() => handleCloseMessage()}
            severity="success">
            Successfully restored trade!
          </Alert>
          }
          {!!restoreTradeError &&
          <Alert
            onClose={() => handleCloseMessage()}
            severity="error">
            Failed to restore trade: {restoreTradeError}
          </Alert>
          }
        </Fragment>
      </Snackbar>
      <Popover
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => {
          setNewTradePopoverOpen(false)
          setPopoverAnchorEl(null)
        }}
        open={newTradePopoverOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card>
          <CardHeader
            action={(
              <Tooltip
                key={'close'}
                title={'Close'}>
                <IconButton
                  onClick={() => {
                    setNewTradePopoverOpen(false)
                  }}>
                  <MdClear/>
                </IconButton>
              </Tooltip>
            )}
            classes={{
              root: classes.cardHeader,
              action: classes.action,
              title: classes.cardTitle
            }}
            title={'Capture New Trade'}
          />
          <div>
            <div className={classes.currencyEntries}>
              {(tradableCurrencyPairs || []).map(k => {
                return (
                  <div
                    className={classes.currencyGridColumnLayout}
                    key={k.displayName}>
                    <div className={classes.currencyText}>
                      {k.displayName}
                    </div>
                    <div className={classes.buttonContainer}>
                      <ButtonGroup
                        color="inherit"
                        size="small"
                        variant="contained">
                        <Button
                          className={classes.button}
                          classes={{
                            containedPrimary: classes.buttonContainedPrimary
                          }}
                          color={'primary'}
                          onClick={() => handlePriceSelection(k.id, TradeDirection.BUY, true)}
                          style={{borderRight: `1px solid ${HexToRGBA('#40426C', 0.5)}`}}
                        >Buy</Button>
                        <Button
                          className={classes.button}
                          classes={{
                            containedPrimary: classes.buttonContainedPrimary
                          }}
                          color={'primary'}
                          onClick={() => handlePriceSelection(k.id, TradeDirection.SELL, true)}
                        >Sell</Button>
                      </ButtonGroup>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Card>
      </Popover>
    </Card>
  </div>

}

export default connect(mapStateToProps)(FECTable)