import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {MarshalCriteria} from 'popcorn-js/search/criteria/Marshaller'

export const Recordkeeper = {
  find({criteria, query, deleted}) {
    if (criteria != null) {
      criteria = MarshalCriteria(criteria)
    }
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Prepayment-Recordkeeper.Find',
      request: {criteria, query, deleted},
    })
  },
}

export default Recordkeeper
