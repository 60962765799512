import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from '../../../actions/helper/utilities'

/**
 * user is a convenience client for invoking the backend services
 */
export default class Manager {
  /**
   * @param userId: user id
   */
  static getUserProfileById(userId) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'User-Manager.GetUserProfileById',
      request: {
        userId,
      },
    })
  }

  static sendEmailVerification(emailAddress) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'User-Manager.InviteUser',
      request: {
        emailAddress,
      },
    })
  }
}

