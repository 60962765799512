import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {MarshalCriteria} from '../../search/criteria/Marshaller'

/**
 * Order Exporter
 */
export default class Exporter {
  static export(criteria, deleted) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Order-Exporter.Export',
      request: {
        criteria: MarshalCriteria(criteria),
        deleted: deleted,
      },
    })
  }
}
