import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {DragSource} from 'react-dnd'
import {Card, withStyles,} from '@material-ui/core'

const styles = (theme) => ({
  root: {
    width: 200,
    height: 20,
    cursor: 'grab',
    paddingLeft: 4,
  },
})

const listItemSource = {
  beginDrag(props) {
    return props.listObject
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

class ListItem extends Component {
  render() {
    const {
      connectDragSource,
      isDragging,
      accessor,
      listObject,
      classes,
    } = this.props

    return connectDragSource(
      <div>
        {(() => {
          if (isDragging) {
            return null
          } else {
            return (
              <Card>
                <div className={classes.root}>
                  {listObject[accessor]}
                </div>
              </Card>
            )
          }
        })()}
      </div>
    )
  }
}

ListItem.propTypes = {
  /**
   * props inserted by DnD lib with
   * DragSource Wrapping
   */
  accessor: PropTypes.any.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  /**
   *  The object to be listed
   */
  draggingStarted: PropTypes.func,
  /**
   * String or function which will be used to get value
   * for display and filtering
   */
  draggingStopped: PropTypes.func,
  /**
   * Function which will be called when list item
   * starts moving
   */
  isDragging: PropTypes.bool.isRequired,
  /**
   * Function which will be called when list item
   * stops moving.
   * Will be passed a bool to indicate whether or
   * not it was dropped on a drop target and thus will
   * be handled by that drop target
   */
  listObject: PropTypes.object.isRequired,
}

const StyledListItem = withStyles(styles)(
  DragSource(
    'listItem',
    listItemSource,
    collect
  )(ListItem))

export default StyledListItem
