import React from 'react'
import PropTypes from 'prop-types'

import Dropzone from 'react-dropzone'
import {FiImage} from 'react-icons/fi'
import Typography from '@material-ui/core/Typography/Typography'
import Grid from '@material-ui/core/Grid/Grid'

const maxSizeKb = 30
const maxSizeBytes = maxSizeKb * 1000

export default class LogoUpdater extends React.Component {
  state = {}

  static getDerivedStateFromProps(props, state) {

    if (!props.logoData || props.logoData === '') {
      return {
        file: undefined,
        fileData: undefined,
      }
    }

    if (props.logoData && !state.fileData) {
      const file = {
        preview: props.logoData,
        name: 'logo',
      }

      return {
        file,
        fileData: props.logoData,
      }
    }

    return null
  }

  render() {
    const onDrop = (files, rejected) => {
      if (rejected.length > 0) {
        const file = rejected[0]
        let error = 'File invalid'
        if (file.size > maxSizeBytes) {
          error = 'Max file size ' + maxSizeKb + 'kb'
        }

        this.setState({
          file: undefined,
          fileData: undefined,
          error,
        })
        return
      }
      if (files.length !== 1) {
        this.setState({
          file: undefined,
          fileData: undefined,
          error: 'No files',
        })
        return
      }

      const file = files[0]
      const reader = new FileReader()

      reader.onload = () => {
        const fileData = reader.result
        this.props.handleChange(fileData)
        this.setState({
          fileData: fileData,
        })
      }
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')

      reader.readAsDataURL(file)

      this.setState({
        file,
        error: undefined,
      },)
    }

    const {file, fileData} = this.state
    return (
      <Grid
        alignItems={'center'}
        container
        direction={'column'}>
        <Grid item>
          <Typography variant={'subtitle1'}>Logo</Typography>
        </Grid>
        <Grid item>
          <Dropzone
            accept="image/png, image/jpg, image/jpeg"
            maxSize={maxSizeBytes}
            multiple={false}
            onDrop={onDrop}>
            {({getRootProps, getInputProps}) => (
              <section
                style={
                  {
                    height: '100px',
                    width: '100px',
                    borderWidth: '1px',
                    borderColor: '#959595',
                    backgroundColor: '#e1e1e1',
                    borderStyle: 'solid',
                    borderRadius: '50px',
                  }
                }
              >
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {(file && file.name && fileData) &&
                  <div
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <img
                      alt="Preview"
                      key={file.preview}
                      src={file.preview}
                      style={{
                        maxHeight: '100px',
                        maxWidth: '100px',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                  }
                  {/* User has not selected a file yet */}
                  {(!file || !file.name || !fileData) &&
                  <div
                    style={{
                      height: '100px',
                      display: 'grid',
                      alignItems: 'center',
                      justifyItems: 'center',
                    }}>
                    <FiImage size={60}/>
                  </div>
                  }
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>
        {this.state.error &&
        <Grid item>

          <span style={{color: '#cb1514'}}>{this.state.error}</span>
        </Grid>
        }
      </Grid>
    )
  }
}

LogoUpdater.propTypes = {
  handleChange: PropTypes.func.isRequired,
  logoData: PropTypes.string,
}
