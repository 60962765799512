import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FilteredMultiSelect from 'react-filtered-multiselect'
import {withStyles} from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import {isFunction} from 'utils/Utils'

const styles = theme => ({
  currencySelectCard: {
    marginBottom: '10px',
    marginTop: '20px',
  },
  filteredMultiSelectRoot: {},
  filteredMultiSelectFilter: {
    display: 'block',
    width: '100%',
    height: '34px',
    padding: '6px 12px',
    fontSize: 14,
    lineHeight: '1.42857143',
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    '-webkit-box-shadow': 'inset 0 1px 1px rgba(0, 0, 0, .075)',
    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
    '-webkit-transition': 'border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s',
    '-o-transition': 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
    transition: 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
  },
  filteredMultiSelectButton: {
    ...theme.typography.button,
    color: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '4px',
    marginTop: '4px',
    padding: '6px 12px',
  },
  filteredMultiSelectButtonActive: {
    ...theme.typography.button,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '4px',
    marginTop: '4px',
    padding: '6px 12px',
  },
  filteredMultiSelectSelect: {
    display: 'block',
    width: '100%',
    fontSize: 14,
    lineHeight: '1.42857143',
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    '-webkit-box-shadow': 'inset 0 1px 1px rgba(0, 0, 0, .075)',
    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
    '-webkit-transition': 'border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s',
    '-o-transition': 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
    transition: 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
  },
})

class CurrencyPairs extends React.Component {

  render() {
    const {
      classes,
      theme,
      availableCurrencyPairOptions,
      assignedCurrencyPairOptions,
      onRemove,
      onAdd,
      disabled: disabledProp,
      title,
    } = this.props
    const fmsClassNames = {
      filter: classes.filteredMultiSelectFilter,
      select: classes.filteredMultiSelectSelect,
      button: classes.filteredMultiSelectButton,
      buttonActive: classes.filteredMultiSelectButtonActive,
    }

    const disabled = disabledProp ||
      !(isFunction(onAdd) && isFunction(onRemove))

    return <Card className={classes.currencySelectCard}>
      <CardHeader title={title}/>
      <CardContent>
        <Grid
          container
          direction={'row'}
          spacing={theme.spacing()}
          style={{
            paddingLeft: '40px',
            paddingTop: theme.spacing(),
          }}
        >
          <Grid
            item
            xs={6}>
            <div id="currencyPairSelectAdd">
              <Typography variant={'subtitle1'}>
                Available
              </Typography>
              <FilteredMultiSelect
                buttonText={'Add'}
                className={classes.filteredMultiSelectRoot}
                classNames={fmsClassNames}
                disabled={disabled}
                onChange={onAdd}
                options={availableCurrencyPairOptions || []}
                placeholder={'Search'}
                showFilter
              />
            </div>
          </Grid>
          <Grid
            item
            xs={6}>
            <div id="currencyPairSelectRemove">
              <Typography variant={'subtitle1'}>
                Assigned
              </Typography>
              <FilteredMultiSelect
                buttonText={'Remove'}
                className={classes.filteredMultiSelectRoot}
                classNames={fmsClassNames}
                onChange={onRemove}
                options={assignedCurrencyPairOptions || []}
                placeholder={'Search'}
                showFilter
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  }
}

CurrencyPairs.propTypes = {

  assignedCurrencyPairOptions: PropTypes.arrayOf(PropTypes.object),
  availableCurrencyPairOptions: PropTypes.arrayOf(PropTypes.object),

  disabled: PropTypes.bool,
  margin: PropTypes.oneOf(['dense', 'normal']),

  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  title: PropTypes.string,

}

CurrencyPairs.defaultProps = {
  party: {},
  availableCurrencyPairOptions: [],
  assignedCurrencyPairOptions: [],

  disabled: false,
  title: 'Currency Pairs',
}


export default withStyles(styles, {withTheme: true})(CurrencyPairs)


