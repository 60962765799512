import {Trade} from 'popcorn-js/trade'
import {isObject} from 'utils/Utils'

export default class Node {
  /**
   * @type {Trade}
   */
  trade = new Trade()

  /**
   * @type {Node[]}
   */
  children = []

  /**
   * @param {object|Node} [node]
   */
  constructor(node) {
    if (
      node !== undefined &&
      (node instanceof Node || isObject(node))
    ) {
      this.trade = new Trade(node.trade)
      this.children = node.children && node.children.map(childNode => new Node(childNode))
    }
  }
}
