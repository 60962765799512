export interface Query {
    sortBy?: string[];
    order?: string[];
    limit: number;
    offset: number;
}

export type DateSearch = {
    ignore?: boolean;
    date?: string;
    inclusive?: boolean;
};
export enum CriteriaType {
  IdentifierCriterion = 'IDENTIFIER_CRITERION',
  TextCriterion = 'TEXT_CRITERION',
  ExactCriterion = 'EXACT_CRITERION',
  NumberCriterion = 'NUMBER_CRITERION',
  BoolCriterion = 'BOOL_CRITERION',
  TextNeCriterion = 'TEXT_NE_CRITERION',
  DateCriterion = 'DATE_CRITERION',
  TimeCriterion = 'TIME_CRITERION',
  InvoiceOverpaidCriterion = 'INVOICE_OVERPAID_CRITERION',
}

export type BaseCriterion = {
    Type?: CriteriaType;
    type: CriteriaType;
};

export type ExactCriterionValue = {
    field?: string;
    text?: string;
};
export type ExactCriterion = {
    Type?: CriteriaType.TextCriterion;
    type: CriteriaType.ExactCriterion;
    value?: ExactCriterionValue;
} & ExactCriterionValue;

export type TextNeCriterionValue = {
    field?: string;
    text?: string;
};
export type TextNeCriterion = {
    Type?: CriteriaType.TextCriterion;
    type: CriteriaType.TextNeCriterion;
    value?: TextNeCriterionValue;
} & TextNeCriterionValue;

export type TextCriterionValue = {
    field?: string;
    text?: string;
};
export type TextCriterion = {
    Type?: CriteriaType.TextCriterion;
    type: CriteriaType.TextCriterion;
    value?: TextCriterionValue;
} & TextCriterionValue;

export type DateCriterionValue = {
    field?: string;
    startDate?: {
        ignore?: boolean;
        date: number;
        inclusive?: boolean;
    };
    endDate?: {
        ignore?: boolean;
        date: number;
        inclusive?: boolean;
    };
};
export type DateCriterion = {
    type: CriteriaType.DateCriterion;
    Type?: CriteriaType.TextCriterion;
    value?: DateCriterionValue;
} & DateCriterionValue;

export type NumberCriterionValue = {
    field?: string;
    lessThan?: {
        ignore?: boolean;
        amount?: number;
        inclusive?: boolean;
    };
    moreThan?: {
        ignore?: boolean;
        amount?: number;
        inclusive?: boolean;
    };
};
export type NumberCriterion = {
    type: CriteriaType.NumberCriterion;
    Type?: CriteriaType.TextCriterion;
    value?: NumberCriterionValue;
} & NumberCriterionValue;

// intermexdiate type to deal with 'old regime' of serialized criteria
export type BrokenCriterion = {
    field?: string;
    type: CriteriaType;
    Type?: CriteriaType.TextCriterion;
    value: ExactCriterionValue | TextNeCriterionValue | DateCriterionValue | NumberCriterionValue | TextCriterionValue;
};

export type Criterion = ExactCriterion | TextNeCriterion | DateCriterion | NumberCriterion | TextCriterion | BrokenCriterion;

export type Criteria = Criterion[];

export enum CriterionFilterType {
    Or = 'OR',
    And = 'AND',
}
