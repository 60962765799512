import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'

/**
 * ProcessingOrgRecordkeeper is a convenience client for invoking the backend services
 */
export default class Recordkeeper {
  /**
   * @param {System} system
   */
  static create(system) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'System-Recordkeeper.Create',
      request: {system},
    })
  }

  /**
   *
   * @param {System} system
   */
  static update(system) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'System-Recordkeeper.Update',
      request: {
        system,
      },
    })
  }

  /**
   */
  static retrieve() {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'System-Recordkeeper.Retrieve',
      request: {},
    })
  }

  /**
   */
  static retrieveHistory() {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'System-Recordkeeper.RetrieveHistory',
      request: {},
    })
  }
}
