import React from 'react'
import {Header, ItemEntry} from 'views/History/HistoryLayout'
import {Divider} from '@material-ui/core'
import {processUnixDateForViewing} from '../../../utils/Utils'
import {SystemDateTimeFormat} from '../../../constants/formats'
import {retrieveFromList} from 'popcorn-js/search/identifier/utilities'
import {IDIdentifier} from 'popcorn-js/search/identifier'

const styles = ({
  root: {
    display: 'grid',
    flexGrow: 1,
    rowGap: '2px',
    boxShadow: '',
    // paddingTop: '25px',
    zIndex: 1,
    gridTemplateRows: (() => {
      let space = ''
      for (let i = 0; i < 20; i++) {
        space = space + '32px '
      }
      return space
    })(),
  },
  divider: {
    height: '2px',
    margin: '20px 20px',
  },
  subDivider: {
    height: '1px',
    margin: '16px 40px',
  },
})

export const AccountFields = (account, fieldType, changedFields = [], addEntityFieldsProps) => {
  return (
    <div>
      <main style={styles.root}>
        <Header
          fieldType={fieldType}
          label={'Market Symbol Information'}
        />
        {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
        <ItemEntry
          changed={changedFields.includes('processingBankPartyCode')}
          fieldType={fieldType}
          id={'processingBankPartyCode'}
          label={'Processing Bank'}
          value={account.processingBankPartyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('currencyId')}
          fieldType={fieldType}
          id={'currencyId'}
          label={'Currency'}
          value={retrieveFromList(new IDIdentifier(account.currencyId), addEntityFieldsProps.currencies).name || ''}
        />
        <ItemEntry
          changed={changedFields.includes('default')}
          fieldType={fieldType}
          id={'default'}
          label={'default'}
          value={account.default || ''}
        />
        <ItemEntry
          changed={changedFields.includes('availableBalance')}
          fieldType={fieldType}
          id={'availableBalance'}
          label={'Available Balance'}
          value={account.availableBalance || ''}
        />
        <ItemEntry
          changed={changedFields.includes('authorisedAmount')}
          fieldType={fieldType}
          id={'authorisedAmount'}
          label={'authorisedAmount'}
          value={account.authorisedAmount || ''}
        />
        <ItemEntry
          changed={changedFields.includes('branchCode')}
          fieldType={fieldType}
          id={'branchCode'}
          label={'branchCode'}
          value={account.branchCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('branchName')}
          fieldType={fieldType}
          id={'branchName'}
          label={'branchName'}
          value={account.branchName || ''}
        />
        <ItemEntry
          changed={changedFields.includes('number')}
          fieldType={fieldType}
          id={'number'}
          label={'number'}
          value={account.number || ''}
        />
        <ItemEntry
          changed={changedFields.includes('type')}
          fieldType={fieldType}
          id={'type'}
          label={'type'}
          value={account.type || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Audit Entry'}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.username')}
          fieldType={fieldType}
          id={'auditEntry.username'}
          label={'Username'}
          value={(account.auditEntry || {}).username || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.action')}
          fieldType={fieldType}
          id={'auditEntry.action'}
          label={'Action'}
          value={(account.auditEntry || {}).action || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.time')}
          fieldType={fieldType}
          id={'auditEntry.time'}
          label={'Time'}
          value={(account.auditEntry || {}).time ? processUnixDateForViewing((account.auditEntry || {}).time, SystemDateTimeFormat) : ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.version')}
          fieldType={fieldType}
          id={'auditEntry.version'}
          label={'Version'}
          value={(account.auditEntry || {}).version || 0}
        />
      </main>
    </div>
  )
}
