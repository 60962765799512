import React from 'react'
import {connect} from 'react-redux'
import ClientTier from './ClientTier'

function mapStateToProps(state) {
  return {
    currencyPairs: state.currencyPair.records,
    partyCode: state.security.claims.context.partyCode,
    partyCurrencyPairIds: state.myParty.currencyPairIds || [],
  }
}

const mapDispatchToProps = () => {
  return {}
}

const ClientTierContainer = props => {
  return <ClientTier{...props}/>
}

const ConnectedClientTierContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientTierContainer)

export default ConnectedClientTierContainer
