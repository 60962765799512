import React from 'react'
import {connect} from 'react-redux'

import ProcessingBank from './ProcessingBank'

const mapStateToProps = state => {
  return {
    securityClaims: state.security.claims,
    partyCurrencyPairIds: state.myParty.currencyPairIds || [],
    allCurrencyPairs: state.currencyPair.records || [],
    partyCode: state.security.claims.context.partyCode,
    partyType: state.security.claims.context.partyType,
  }
}

const Container = props => {
  return (
    <div>
      <ProcessingBank {...props} />
    </div>
  )
}

const ProcessingBankContainer = connect(mapStateToProps)(
  Container)
export default ProcessingBankContainer
