import React, { useState, useEffect, ChangeEvent, useContext, useCallback } from 'react'
import {useService} from 'hooks/useService';
import {Recordkeeper as SIRecordkeeper} from 'popcorn-js/financial/settlementInstruction/recordkeeperV2';
import {AddCircle, FilterList, GetApp, MoreVert, Visibility, Delete, Edit, Clear, ArrowBackIos} from '@material-ui/icons'
import { ErrorIcon } from 'components/Icons';
import AndileTable from 'components/AndileMaterialUITable/AndileTable';
import { processUnixDateForViewing } from 'utils/Utils';
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert';
import {Tab, Tabs, Card, CardContent, CardHeader, useTheme } from '@material-ui/core';
import { styled, useStyletron } from 'styletron-react';
import {InvoiceTradeLink, SettlementInstruction, Status} from 'popcorn-js/financial/settlementInstruction';
import { CustomTheme } from 'theme/custom';
import { CriteriaType, Criteria, Criterion } from 'popcorn-js/search';
import { AppContext, AppContextType } from 'appContext'
import { Currency } from 'popcorn-js/financial';
import { ActionsMenu } from './ActionsMenu';
import Handler from 'popcorn-js/financial/settlementInstruction/handler';
import SettlementInstructionSummaryDialog from './SettlementInstructionSummaryDialog';
import {AddNewSI} from './AddNewSI';
import { EditSettlementInstruction } from './EditSettlementInstruction';
import { CurrencyPair } from 'popcorn-js/financial';
import { ActionButton } from './ActionButton'
import { CategoryMenu } from './CategoryMenu';
import { Recordkeeper } from 'popcorn-js/financial/settlementInstruction/recordkeeperV2';
import { RestoreFromTrash } from '@material-ui/icons';
import TradeRecordkeeper from 'popcorn-js/trade/recordkeeper'
import { Trade, getRelevantLeg } from 'popcorn-js/trade/tradeTypes';
import { displayAmount } from 'views/Tickets/util';
import Big from 'big.js'
import Downloader from 'popcorn-js/financial/settlementInstruction/downloader';
import {format} from 'date-fns';

enum TabValue {
  Completed = 0,
  Unlinked = 1,
  Deleted = 2
}

const pageSize = 17
const completedSIsBaseCriteria: Criteria = [
  {type: CriteriaType.ExactCriterion, text: Status.Complete, field: 'status' },
  {type: CriteriaType.ExactCriterion, text: 'CURRENT', field: 'financialYear' },
]
const completedSIsBaseOrder = 'desc'
const completedSIsBaseSortyBy = 'date'
const unlinkedSIsBaseCriteria: Criteria = [
  {type: CriteriaType.TextNeCriterion, text: Status.Complete, field: 'status' },
  {type: CriteriaType.ExactCriterion, text: 'CURRENT', field: 'financialYear' },
]
const unlinkedSIsBaseOrder = 'desc'
const unlinkedSIsBaseSortyBy = 'date'
const deletedSIsBaseCriteria: Criteria = [{type: CriteriaType.ExactCriterion, text: 'CURRENT', field: 'financialYear' }]
const deletedSIsBaseOrder = 'desc'
const deletedSIsBaseSortyBy = 'date'

const StyledTab = styled(Tab, {
  fontSize: '1.2rem',
  textTransform: 'none'
})

const StyledImExValue = (props: {SI: SettlementInstruction}) => {
  const [css] = useStyletron();
  const theme = useTheme<CustomTheme>()
  let color = 'inherit'
  if (props.SI.importExport === 'Import') {
    color = theme.palette.import.main
  }
  if (props.SI.importExport === 'Export') {
    color = theme.palette.export.main
  }
  return <span className={css({color})}>
    {props.SI.importExport?.toUpperCase()}
  </span>
}

type ModifiedSI = SettlementInstruction & {
  linkedTrades?: string;
  linkedTradesInternalReference?: string;
}

const SettlementInstructionsV2 = (): React.ReactNode => {
  const [css] = useStyletron();
  const theme = useTheme<CustomTheme>()
  const appContext = useContext<AppContextType>(AppContext);

  // notifications
  const [errorMessage, setErrorMessage] = useState<string|undefined>()
  const [successMessage, setSuccessMessage] = useState<string|undefined>()
  const [warningMessage, setWarningMessage] = useState<string|undefined>()
  const [confirmCallback, setConfirmCallback] = useState<() => void>(() => () => undefined)
  // completed SIs find state
  const [criteria, setCriteria] = useState<Criteria>(completedSIsBaseCriteria)
  const [filterCriteria, setFilterCriteria] = useState<Criteria|undefined>()
  const [page, setPage] = useState<number>(0)
  const [sIs, setSIs] = useState<ModifiedSI[]|undefined>()
  const [total, setTotal] = useState<number>(0)
  const [order, setOrder] = useState<'asc'|'desc'>('desc')
  const [sortBy, setSortBy] = useState<string>('date')
  // general state
  const [activeTab, setActiveTab] = useState<TabValue>(0)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [colConfigOpen, setColConfigOpen] = useState<boolean>(false)
  const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement|undefined>()
  const [newAnchorEl, setNewAnchorEl] = useState<HTMLElement|undefined>()
  const [showSISummaryDialog, setShowSISummaryDialog] = useState<boolean>(false)
  const [showNewDialog, setShowNewDialog] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  // only do single selection for now
  const [selected, setSelected] = useState<SettlementInstruction|undefined>()
  // used in the creation of a new invoice, as the menu and dialog are separated (possibly combine into single component later...) 
  const [importExport, setImportExport] = useState<'Import'|'Export'>('Import')
  // boolean state to indicate whether a specific service invocation has started
  const [deleteStarted, setDeleteStarted] = useState<boolean>(false)
  const [cancelStarted, setCancelStarted] = useState<boolean>(false)
  const [deleteForeverStarted, setDeleteForeverStarted] = useState<boolean>(false)
  const [restoreStarted, setRestoreStarted] = useState<boolean>(false)
  const [findTradesLoading, setFindTradesLoading] = useState<boolean>(false)

  const cols = [
    {header: 'Number', visible: true},
    {header: 'IM/EX', visible: true},
    {header: 'Value Date', visible: true},
    {header: 'Currency', visible: true},
    {header: 'FX Amount', visible: true},
    {header: `${appContext.localCurrency.isoCode} Amount`, visible: true},
    {header: 'Status', visible: true},
    {header: 'Linked Trades', visible: true},
    // {header: 'P/L', visible: true},
    // hidden
    {header: 'Client', visible: false},
    {header: 'Financial Year', visible: false},
    {header: 'Deal Rate', visible: false},
    {header: 'FX Source', visible: false},
    {header: 'Linked Trades Int Ref', visible: false},
    {header: 'Ave Cost', visible: false},
    {header: 'Ave Effective', visible: false},
  ]

  /* find SIs --------------------------------------------------------------------- */
  const [
    {response: findSIsResponse,loading: findSIsLoading,error: findSIsError},
    setFindSIsRequest,
  ] = useService(null, SIRecordkeeper.find)

  // reset sets criteria and find request to base values based on the selected tab
  const reset = useCallback((tab: TabValue) => {
    setPage(0)
    setFilterCriteria(undefined)
    switch (tab) {
      case TabValue.Completed:
        setCriteria(completedSIsBaseCriteria)
        setSortBy(completedSIsBaseSortyBy)
        setFindSIsRequest({
          criteria: completedSIsBaseCriteria, 
          query: {limit: pageSize, offset: 0, order: [completedSIsBaseOrder], sortBy: [completedSIsBaseSortyBy]}
        })
        break
      case TabValue.Unlinked:
        setCriteria(unlinkedSIsBaseCriteria)
        setSortBy(unlinkedSIsBaseSortyBy)
        setFindSIsRequest({
          criteria: unlinkedSIsBaseCriteria, 
          query: {limit: pageSize, offset: 0, order: [unlinkedSIsBaseOrder], sortBy: [unlinkedSIsBaseSortyBy]}
        })
        break
      case TabValue.Deleted:
        setCriteria(deletedSIsBaseCriteria)
        setSortBy(deletedSIsBaseSortyBy)
        setFindSIsRequest({
          criteria: deletedSIsBaseCriteria, 
          deleted: true,
          query: {limit: pageSize, offset: 0, order: [deletedSIsBaseOrder], sortBy: [deletedSIsBaseSortyBy]}
        })
        break
    }
  }, [setFindSIsRequest])

  // this effect calls reset each time the tab is changed
  useEffect(() => {
    reset(activeTab)
    setSelected(undefined)
    setShowFilter(false)
  }, [activeTab, reset])

  const reduceUniqueTradeIDs = (links: InvoiceTradeLink[]): string[] => {
    const idSet = new Set()
    const ids = [] as string[]
    for (const link of links) {
      if (!idSet.has(link.tradeId)) {
        ids.push(link.tradeId)
        idSet.add(link.tradeId)
      }
    }
    return ids
  }

  const generateTradeCriteria = (records: SettlementInstruction[]): Criterion[] => {
    const tradeCriteria = [] as Criterion[]
    const idSet = new Set()
    for (const SI of records) {
      if (SI && SI.invoiceTradeLinks) {
        for (const link of SI.invoiceTradeLinks) {
          if (!idSet.has(link.tradeId)) {
            tradeCriteria.push(
              {type: CriteriaType.ExactCriterion, field: 'id', text: link.tradeId}
            )
            idSet.add(link.tradeId)
          }
        }
      }
    }
    return tradeCriteria
  }

  useEffect(() => {
    if (findSIsLoading) {
      setFindTradesLoading(true)
    }
  }, [findSIsLoading])

  // handles the service responses and updates the state
  useEffect(() => {
    if (findSIsResponse && !findSIsLoading && !findSIsError) {
      const tradeCriteria = generateTradeCriteria(findSIsResponse.records)
      if (tradeCriteria.length > 0) {
        TradeRecordkeeper.find({criteria: tradeCriteria}).then((findResponse: any) => {
          const trades = findResponse.records as Trade[]
          const newSIs = [] as ModifiedSI[]
          for (const SI of findSIsResponse.records) {
            if (SI.invoiceTradeLinks && SI.invoiceTradeLinks.length > 0) {
              const tradeLink = SI.invoiceTradeLinks[0]
              const trade = trades?.find((t: Trade) => t.id === tradeLink?.tradeId)
              if (trade) {
                const leg = getRelevantLeg(trade)
                if (leg) {
                  const tradeIDs = reduceUniqueTradeIDs(SI.invoiceTradeLinks)
                  const suffix = tradeIDs.length > 1 ? ` +${tradeIDs.length-1}` : ''
                  newSIs.push({...SI, linkedTrades: leg.externalReference + suffix, linkedTradesInternalReference: leg.internalReference + suffix})
                  continue
                }
              }
            }
            newSIs.push({...SI, linkedTrades: '-', linkedTradesInternalReference: '-'})
          }
          setSIs(newSIs)
          setTotal(findSIsResponse.total)
        }).finally(() => setFindTradesLoading(false))
      } else {
        setSIs(findSIsResponse.records)
        setTotal(findSIsResponse.total)
        setFindTradesLoading(false)
      }
    }
  }, [findSIsResponse, findSIsLoading, findSIsError])

  /* general handlers --------------------------------------------------------------------- */

  const handleChangePage = (_event: unknown, page: number) => {
    setPage(page)
    setFindSIsRequest({
      criteria: criteria, 
      deleted: activeTab === 2,
      query: {limit: pageSize, offset: page*pageSize, order: [order], sortBy: [sortBy]}
    })
  }
  const handleChangeSorting = (field: string, order: 'asc'|'desc') => {
    setSortBy(field)
    setOrder(order)
    setFindSIsRequest({
      criteria: criteria,
      deleted: activeTab === 2,
      query: {limit: pageSize, offset: page*pageSize, order: [order], sortBy: [field]}
    })
  }
  // excludeCriteria removes overlapping criteria from the base criteria if it's present in the filter cirteria, based on field
  const excludeCriteria = (filterCriteria: Criteria, baseCriteria: Criteria): Criteria => {
    // determine the overlapping fields, if any
    const baseCriteriaFields = baseCriteria.map((e: Criterion) => e.field || e.value?.field);
    const overlappingFields = filterCriteria
      .filter((e: Criterion) => baseCriteriaFields.includes(e.field) || baseCriteriaFields.includes(e.value?.field))
      .map((e: Criterion) => e.field || e.value?.field)
    if (overlappingFields.length > 0) {
      // exclude the overlapping fields from the base criteria
      const modifiedBaseCriteria = [...baseCriteria].filter((e: Criterion) => !(overlappingFields.includes(e.field) || overlappingFields.includes(e.value?.field)))
      return [...filterCriteria, ...modifiedBaseCriteria]
    }
    return [...filterCriteria, ...baseCriteria]
  }
  const handleFilterChange = (criteria: Criteria) => {
    if (criteria && criteria.length > 0) {
      setFilterCriteria(criteria) 
    } else {
      setFilterCriteria(undefined) 
    }
    let newCriteria: Criteria
    switch (activeTab) {
      case TabValue.Completed: { // Completed SIs
        newCriteria = criteria.length === 0 ? completedSIsBaseCriteria : excludeCriteria(criteria, completedSIsBaseCriteria)
        break;
      }
      case TabValue.Unlinked: { // Unlinked SI's
        newCriteria = criteria.length === 0 ? unlinkedSIsBaseCriteria : excludeCriteria(criteria, unlinkedSIsBaseCriteria)
        break;
      }
      case TabValue.Deleted: { // Deleted SI's
        newCriteria = criteria.length === 0 ? deletedSIsBaseCriteria : excludeCriteria(criteria, deletedSIsBaseCriteria)
        break;
      }
    }
    setPage(0)
    setCriteria(newCriteria)
    setFindSIsRequest({
      criteria: newCriteria, 
      deleted: activeTab === 2,
      query: {limit: pageSize, offset: 0, order: [order], sortBy: [sortBy]}
    })
  }
  const handleSelectRow = (si: SettlementInstruction) => {
    if (selected && selected.id === si.id) {
      setSelected(undefined)
    } else {
      setSelected(si)
    }
  }

  const handleTabChange = (tab: TabValue) => {
    setActiveTab(tab)
  }
  const getLoading = () => {
    return findSIsLoading || deleteSILoading || cancelLoading || restoreLoading || deleteForeverLoading || findTradesLoading
  }

  /* delete SI --------------------------------------------------------------------- */
  const [
    {response: deleteSIResponse,loading: deleteSILoading,error: deleteSIError},
    setDeleteSIRequest,
  ] = useService(null, Handler.delete)
  const handleDeleteSI = () => {
    if (selected) {
      setConfirmCallback(() => () => {
        setDeleteStarted(true)
        setSelected(undefined)
        setDeleteSIRequest({id: selected?.id})
      })
      setWarningMessage(`Are you sure you want to delete this SI (${selected.number})?`)
    }
  }
  // this effect reacts to a successful service response, 
  useEffect(() => {
    if (deleteSIResponse && !deleteSILoading && deleteStarted) {
      setDeleteStarted(false)
      setSuccessMessage('Successfully deleted SI')
      reset(activeTab)
    }
  }, [deleteSIResponse, deleteSILoading, reset, deleteStarted, activeTab])
  useEffect(() => {
    if (deleteSIError && !deleteSILoading && deleteStarted) {
      setDeleteStarted(false)
      setErrorMessage('Failed to delete SI: ' + deleteSIError)
    }
  }, [deleteSIError, deleteSILoading, deleteStarted])

  /* restore --------------------------------------------------------------------- */
  const [
    {response: restoreResponse, loading: restoreLoading, error: restoreError},
    setRestoreRequest,
  ] = useService(null, Recordkeeper.restore)
  const handleRestore = () => {
    if (selected) {
      setConfirmCallback(() => () => {
        setRestoreStarted(true)
        setSelected(undefined)
        setRestoreRequest(selected?.id)
      })
      setWarningMessage(`Are you sure you want to restore this SI (${selected.number})?`)
    }
  }
  // this effect reacts to a successful service response, 
  useEffect(() => {
    if (restoreResponse && !restoreLoading && restoreStarted) {
      setRestoreStarted(false)
      setSuccessMessage('Successfully restored SI')
      reset(activeTab)
    }
  }, [restoreResponse, restoreLoading, reset, restoreStarted, activeTab])
  useEffect(() => {
    if (restoreError && !restoreLoading && restoreStarted) {
      setRestoreStarted(false)
      setErrorMessage('Failed to restore SI: ' + restoreError)
    }
  }, [restoreError, restoreLoading, restoreStarted])

  /* delete forever --------------------------------------------------------------------- */
  const [
    {response: deleteForeverResponse, loading: deleteForeverLoading, error: deleteForeverError},
    setDeleteForeverRequest,
  ] = useService(null, Recordkeeper.deleteForever)
  const handleDeleteForever = () => {
    if (selected) {
      setConfirmCallback(() => () => {
        setDeleteForeverStarted(true)
        setSelected(undefined)
        setDeleteForeverRequest(selected?.id)
      })
      setWarningMessage(`Are you sure you want to delete this SI (${selected.number}) forever?`)
    }
  }
  // this effect reacts to a successful service response, 
  useEffect(() => {
    if (deleteForeverResponse && !deleteForeverLoading && deleteForeverStarted) {
      setDeleteForeverStarted(false)
      setSuccessMessage('Successfully deleted SI forever')
      reset(activeTab)
    }
  }, [deleteForeverResponse, deleteForeverLoading, reset, deleteForeverStarted, activeTab])
  useEffect(() => {
    if (deleteForeverError && !deleteForeverLoading && deleteForeverStarted) {
      setDeleteForeverStarted(false)
      setErrorMessage('Failed to delete SI forever: ' + deleteForeverError)
    }
  }, [deleteForeverError, deleteForeverLoading, deleteForeverStarted])

  /* ---------------------------------------------------------------------
     create new 
     --------------------------------------------------------------------- */
  const [
    {response: createDraftResponse, loading: createDraftLoading, error: createDraftError},
    setCreateDraftRequest,
  ] = useService(null, Handler.createDraft)

  const handleCreateNew = (createDraftInfo: {currencyId: string; date: number, importExport: 'Import'|'Export'}) => {
    setSelected(undefined)
    setImportExport(importExport)
    setShowNewDialog(false)
    setCreateDraftRequest({
      ...createDraftInfo, 
      partyCode: appContext.party.partyCode, 
      processingOrgPartyCode: appContext.party.parentPartyCode,
    })
  }
  // this effect reacts to a successful service response, 
  useEffect(() => {
    if (createDraftResponse && !createDraftLoading) {
      setSuccessMessage('Successfully created draft SI')
      setSelected(createDraftResponse.settlementInstruction)
      setEditOpen(true)
    }
  }, [createDraftResponse, createDraftLoading])
  useEffect(() => {
    if (createDraftError && !createDraftLoading) {
      setErrorMessage('Failed to create draft SI: ' + createDraftError)
    }
  }, [createDraftError, createDraftLoading])

  /* ---------------------------------------------------------------------
     cancel
     --------------------------------------------------------------------- */
  const [
    {response: cancelResponse,loading: cancelLoading,error: cancelError},
    setCancelRequest,
  ] = useService(null, Handler.cancel)
    
  const handleCancel = () => {
    if (selected) {
      setCancelStarted(true)
      setCancelRequest({id: selected.id})
    }
  }
  useEffect(() => {
    if (cancelResponse && !cancelLoading && cancelStarted) {
      setCancelStarted(false)
      setSuccessMessage('Successfully cancelled SI')
      setSelected(cancelResponse.settlementInstruction)
      reset(activeTab)
    }
  }, [cancelResponse, cancelLoading, cancelStarted, activeTab, reset])
  useEffect(() => {
    if (cancelError && !cancelLoading && cancelStarted) {
      setCancelStarted(false)
      setErrorMessage('Failed to cancel SI: ' + cancelError)
    }
  }, [cancelError, cancelLoading, cancelStarted])

  /* ---------------------------------------------------------------------
     download list 
     --------------------------------------------------------------------- */
  const [
    {
      response: downloadListResponse,
      loading: downloadListLoading,
      error: downloadListError,
    },
    setDownloadListRequest,
    resetDownloadListResponse,
  ] = useService(null, Downloader.downloadList)
  const handleDownloadList = () => {
    setDownloadListRequest({
      criteria,
      query : {
        order: [order],
        sortBy: [sortBy],
      },
      deleted: activeTab === TabValue.Deleted,
    })
  }
  useEffect(() => {
    if (downloadListResponse && !downloadListLoading) {
      resetDownloadListResponse()
      try {
        const binData = atob(downloadListResponse.data)
        const bytes = new Array(binData.length)
        for (let i = 0; i < binData.length; i++) {
          bytes[i] = binData.charCodeAt(i)
        }
        const blob = new Blob([new Uint8Array(bytes)], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
        })
        let fileName = ''
        const dateStr = format(new Date(), 'yyyy-MM-dd')
        switch (activeTab) {
          case TabValue.Completed:
          case TabValue.Unlinked:
            if (filterCriteria) {
              fileName = `Filtered SIs ${TabValue[activeTab]} ${dateStr}.xlsx`
            } else {
              fileName = `All SIs ${TabValue[activeTab]} ${dateStr}.xlsx`
            }
            break
          case TabValue.Deleted:
            if (filterCriteria) {
              fileName = `Filtered Deleted SIs ${dateStr}.xlsx`
            } else {
              fileName = `Deleted SIs ${dateStr}.xlsx`
            }
            break
          default:
            // ?
        }
        saveAs(blob, fileName)
      } catch (e) {
        console.error('error downloading SI list', e)
        setErrorMessage(e)
      }
    }
  }, [downloadListResponse, downloadListLoading, activeTab, filterCriteria, resetDownloadListResponse])
  useEffect(() => {
    if (downloadListError && !downloadListLoading) {
      setErrorMessage('Failed to download SI list: ' + downloadListError)
    }
  }, [downloadListError, downloadListLoading])

  // handleUpdate handles updates from editinging an SI, replacing the SI in the list with the updated one
  const handleUpdate = (SI: SettlementInstruction) => {
    const newSIs = sIs ? [...sIs] : [] as SettlementInstruction[]
    const idx = newSIs.findIndex((elem: SettlementInstruction) => elem.id === SI.id)
    newSIs[idx] = SI
    setSIs(newSIs)
  }

  const handleSubmit = (SI: SettlementInstruction) => {
    const newSIs = sIs ? [...sIs] : [] as SettlementInstruction[]
    const idx = newSIs.findIndex((elem: SettlementInstruction) => elem.id === SI.id)
    newSIs[idx] = SI
    setSIs(newSIs)
  }

  const partyCurrencies = appContext.party.currencyPairIds.map((id: string) => {
    const ccyPair = appContext.currencyPairs.find((c: CurrencyPair) => c.id === id)
    const foreignCcyID = ccyPair?.baseCurrencyId
    return appContext.currencies.find((c: Currency) => c.id === foreignCcyID)
  })

  const displayCurrencyAmount = (amount: number|undefined, ccyID: string|undefined): string => {
    const ccy = appContext.currencies.find((c: Currency|undefined) => c && c.id === ccyID)
    if (ccy && amount) {
      let sign = ''
      if (amount < 0) {
        sign = '-'
      }
      return `${sign}${ccy.symbol} ${displayAmount(Big(Math.abs(amount)))}`
    }
    return '-'
  }

  const displayRate = (value: number|undefined): string => {
    if (value) {
      return value.toFixed(4)
    }
    return '-'
  }

  return <>
    <Card
      className={css({
        borderRadius: '8px',
        marginBottom: 0,
      })}
      square={false}
    >
      <CardHeader
        action={
          <>
            {/** Selected SI Actions */}
            {[TabValue.Deleted].includes(activeTab) &&
              <ActionButton 
                disabled={false}
                helpText={'Go Back'}
                icon={<ArrowBackIos />}
                id={'back'}
                onClick={() => setActiveTab(TabValue.Completed)}
              />
            }
            {!!selected &&
              <>
                {selected.status && [Status.Draft, Status.Submitted, Status.Processing, Status.Complete].includes(selected.status) &&
                  <ActionButton 
                    helpText={'View Details'}
                    icon={<Visibility />}
                    id={'view-details'}
                    onClick={() => setShowSISummaryDialog(true)}
                  />
                }
                {[TabValue.Deleted].includes(activeTab) &&
                  <ActionButton 
                    helpText={'Delete Forever'}
                    icon={<Delete />}
                    id={'delete-SI-forever'}
                    onClick={handleDeleteForever}
                  />
                }
                {[TabValue.Deleted].includes(activeTab) &&
                  <ActionButton 
                    helpText={'Restore'}
                    icon={<RestoreFromTrash />}
                    id={'restore'}
                    onClick={handleRestore}
                  />
                }
                {[TabValue.Completed, TabValue.Unlinked].includes(activeTab) &&
                selected.status && [Status.Draft].includes(selected.status) &&
                  <ActionButton 
                    helpText={'Delete'}
                    icon={<Delete />}
                    id={'delete-SI'}
                    onClick={handleDeleteSI}
                  />
                }
                {[TabValue.Completed, TabValue.Unlinked].includes(activeTab) && 
                selected.status && [Status.Draft].includes(selected.status) &&
                  <ActionButton 
                    helpText={'Edit'}
                    icon={<Edit />}
                    id={'edit-SI'}
                    onClick={() => setEditOpen(true)}
                  />
                }
                {[TabValue.Completed, TabValue.Unlinked].includes(activeTab) &&
                selected.status && [Status.Submitted, Status.Processing, Status.Complete].includes(selected.status) &&
                  <ActionButton 
                    helpText={'Cancel'}
                    icon={<Clear />}
                    id={'cancel-SI'}
                    onClick={handleCancel}
                  />
                }
              </>
            }
            {[TabValue.Completed, TabValue.Unlinked].includes(activeTab) &&
              <ActionButton 
                disabled={false}
                helpText={'Add New SI'}
                icon={<AddCircle />}
                id={'add-SI'}
                onClick={(event: any) => 
                  setNewAnchorEl(event.currentTarget ? event.currentTarget : undefined)}
              />
            }
            <ActionButton 
              disabled={false}
              helpText={'Filter Table'}
              icon={<FilterList />}
              id={'toggle-filter'}
              onClick={() => {
                if (showFilter) {
                  setFilterCriteria(undefined) 
                }
                setShowFilter(!showFilter)
                reset(activeTab)
              }}
            />
            {[TabValue.Completed, TabValue.Unlinked, TabValue.Deleted].includes(activeTab) &&
              <ActionButton 
                disabled={false}
                helpText={'Download List'}
                icon={<GetApp />}
                id={'download-list'}
                loading={downloadListLoading}
                onClick={handleDownloadList}
              />
            }
            <ActionButton 
              disabled={false}
              helpText={'More Options'}
              icon={<MoreVert />}
              id={'more-options'}
              onClick={(event: any) => 
                setMoreActionsAnchorEl(event.currentTarget ? event.currentTarget : undefined)}
            />
            <ActionsMenu 
              anchorElement={moreOptionsAnchorEl}
              items={[
                activeTab !== TabValue.Deleted ? { 
                  id: 'view-deleted',
                  text: 'View Deleted SIs',
                  onClick: () => {
                    setActiveTab(TabValue.Deleted)
                  },
                } : undefined,
                { 
                  id: 'column-configuration',
                  text: 'Column Configuration',
                  onClick: () => setColConfigOpen(true),
                }
              ]}
              onClose={() => setMoreActionsAnchorEl(undefined)}
              title={'More Options'}
            />
            <CategoryMenu
              anchorEl={newAnchorEl}
              exportItems={[
                {text: 'EXPORT SETTLEMENT INSTRUCTION'}
              ]}
              importItems={[
                {text: 'IMPORT SETTLEMENT INSTRUCTION'}
              ]}
              onClickItem={(_: string, importExport: 'Import'|'Export') => {
                setImportExport(importExport)
                setSelected(undefined)
                setShowNewDialog(true)
                setNewAnchorEl(undefined)
              }}
              onClose={() => setNewAnchorEl(undefined)}
              title={'Create New'}
            />
          </>
        }
        className={css({
          backgroundColor: theme.palette.paper.default,
          padding: theme.spacing(1.5),
          paddingLeft: 0,
          height: '48px',
          color: theme.palette.primary.contrastText,
        })}
        title={<>
          {activeTab !== TabValue.Deleted && <Tabs
            TabIndicatorProps={{
              className: css({
                backgroundColor: '#F1C900',
              })
            }}
            onChange={(_event: ChangeEvent<unknown>, value: number) => handleTabChange(value as TabValue)}
            value={activeTab}
          >
            <StyledTab label="SI’s Completed"/>
            <StyledTab label="SI’s Unlinked"/>
          </Tabs>
          }
          {activeTab === TabValue.Deleted && 
          <span className={css({fontSize: '1.2rem', textTransform: 'none'})}>Deleted Settlement Instructions</span>}
        </>}
        titleTypographyProps={{
          className: css({
            color: theme.palette.primary.contrastText,
          }),
          variant: 'h6',
        }}
      />
      <CardContent
        className={css({
          padding: 0,
          gridTemplateColumns: 'auto auto 1fr auto',
          ':last-child': {
            padding: 0,
          },
        })}
      >
        {((): React.ReactNode => {
          if (findSIsError) {
            return (
              <div>
                <ErrorIcon/>
                <div style={{textAlign: 'center'}}>
                  {'failed to load'}
                </div>
              </div>
            )
          } else {
            return (
              <>
                <AndileTable
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  colConfigCloseFromCard={() => setColConfigOpen(false)}
                  colConfigOpenFromCard={colConfigOpen}
                  columns={[
                    {
                      title: 'Number', 
                      field: 'number',
                      filter: {type: CriteriaType.TextCriterion},
                      render: (rowData: SettlementInstruction) => rowData.number
                    },
                    {
                      title: 'Value Date', 
                      field: 'date',
                      filter: {type: CriteriaType.DateCriterion},
                      render: (rowData: SettlementInstruction) => processUnixDateForViewing(rowData.date)
                    },
                    {
                      title: 'Currency', 
                      field: 'currencyId',
                      filter: {
                        options: appContext.party.currencyPairIds.map((id: string) => {
                          const ccyPair = appContext.currencyPairs.find((c: CurrencyPair) => c.id === id)
                          const foreignCcyID = ccyPair?.baseCurrencyId
                          return {name: ccyPair?.name, value: foreignCcyID}
                        }),
                        displayAccessor: 'name',
                        valueAccessor: 'value',
                        type: CriteriaType.TextCriterion
                      },
                      render: (rowData: SettlementInstruction) => {
                        const foreignCcy = appContext?.currencies.find((c: Currency) => c.id === rowData.currencyId)
                        if (appContext.localCurrency) {
                          return `${foreignCcy?.isoCode}/${appContext.localCurrency.isoCode}`
                        }
                        return `${foreignCcy?.isoCode}/<UNKNOWN>`
                      }
                    },
                    {
                      title: 'Status', 
                      field: 'status',
                      filter: {
                        options: ((): {v: string}[] => {
                          switch (activeTab) {
                            case TabValue.Completed:
                              return [{v: Status.Complete}]
                            case TabValue.Unlinked: {
                              const values: {v: string}[] = [] as {v: string}[]
                              for (const s in Status) {
                                if (![Status.Complete].includes(s as Status)) {
                                  values.push({v: s})
                                }
                              }
                              return values
                            }
                            case TabValue.Deleted: {
                              const values: {v: string}[] = [] as {v: string}[]
                              for (const s in Status) {
                                values.push({v: s})
                              }
                              return values
                            }
                          }
                        })(),
                        displayAccessor: 'v',
                        valueAccessor: 'v',
                        type: CriteriaType.TextCriterion
                      },
                      render: (rowData: SettlementInstruction) => rowData.status
                    },
                    {
                      title: 'Client', 
                      field: 'partyCode',
                      filter: {type: CriteriaType.TextCriterion},
                      render: (rowData: SettlementInstruction) => rowData.partyCode
                    },
                    {
                      title: 'Financial Year', 
                      field: 'financialYear',
                      filter: {type: CriteriaType.TextCriterion},
                      render: (rowData: SettlementInstruction) => rowData.financialYear
                    },
                    {
                      title: 'IM/EX', 
                      field: 'importExport',
                      filter: {
                        options: [{v: 'Import'}, {v: 'Export'}],
                        displayAccessor: 'v',
                        valueAccessor: 'v',
                        type: CriteriaType.TextCriterion
                      },
                      // eslint-disable-next-line react/display-name
                      render: (rowData: SettlementInstruction) => <StyledImExValue SI={rowData} />
                    },
                    {
                      title: 'FX Amount', 
                      field: 'fxAmount.value',
                      filter: {type: CriteriaType.NumberCriterion},
                      align: 'right',
                      render: (SI: SettlementInstruction) => displayCurrencyAmount(SI.fxAmount, SI.currencyId)
                    },
                    {
                      title: `${appContext.localCurrency.isoCode} Amount`, 
                      field: 'localCurrencyAmount.value',
                      filter: {type: CriteriaType.NumberCriterion},
                      align: 'right',
                      render: (SI: SettlementInstruction) => displayCurrencyAmount(SI.localCurrencyAmount, appContext.localCurrency.id)
                    },
                    {
                      title: 'Deal Rate', 
                      field: 'dealRate.value',
                      filter: {type: CriteriaType.NumberCriterion},
                      align: 'right',
                      render: (SI: SettlementInstruction) => displayRate(SI.dealRate)
                    },
                    {
                      title: 'Ave Cost', 
                      field: 'avgCostingRate.value',
                      filter: {type: CriteriaType.NumberCriterion},
                      align: 'right',
                      render: (SI: SettlementInstruction) => displayRate(SI.avgCostingRate)
                    },
                    {
                      title: 'Ave Effective', 
                      field: 'avgEffectiveRate.value',
                      filter: {type: CriteriaType.NumberCriterion},
                      align: 'right',
                      render: (SI: SettlementInstruction) => displayRate(SI.avgEffectiveRate)
                    },
                    // {
                    //   title: 'P/L',
                    //   field: 'pnl.value',
                    //   filter: {type: CriteriaType.NumberCriterion},
                    //   align: 'right',
                    //   render: (SI: SettlementInstruction) => displayCurrencyAmount(SI.pnl, appContext.localCurrency.id)
                    // },
                    {
                      title: 'Linked Trades', 
                      render: (rowData: ModifiedSI) => rowData.linkedTrades || '-'
                    },
                    {
                      title: 'Linked Trades Int Ref', 
                      render: (rowData: ModifiedSI) => rowData.linkedTradesInternalReference || '-'
                    },
                    {
                      title: 'FX Source', 
                      field: 'fxSource',
                      filter: {
                        options: [{v: 'Stellcap trade'}, {v: 'Client trade'}],
                        displayAccessor: 'v',
                        valueAccessor: 'v',
                        type: CriteriaType.TextCriterion
                      },
                      render: (rowData: SettlementInstruction) => rowData.fxSource
                    },
                  ]}
                  count={total}
                  data={sIs ? sIs : [] as SettlementInstruction[]}
                  defaultColConfig={cols}
                  disableHeader
                  handleChangePage={handleChangePage} 
                  handleChangeRowsPerPage={() => undefined}
                  loading={getLoading()}
                  maxTableHeight={700}
                  onChangeSorting={handleChangeSorting}
                  onFilterChange={handleFilterChange}
                  onRowCheck={handleSelectRow}
                  onSelectAll={() => setSelected(undefined)}
                  order={order}
                  page={page}
                  rowClickAction={handleSelectRow}
                  rowDoubleClickAction={(rowData: SettlementInstruction) => {
                    setSelected(rowData)
                    setShowSISummaryDialog(true)
                  }}
                  rowsPerPage={17}
                  // rowsPerPageOptions={[]}
                  selected={selected ? [selected] : []}
                  showCheckboxes
                  showFilterRowFromCard={showFilter}
                  sortBy={sortBy}
                  tableID={'SI-station'}
                  title={''}
                />
              </>
            )
          }
        })()}
        {showSISummaryDialog && selected && 
        <SettlementInstructionSummaryDialog
          SI={selected}
          onClose={() => setShowSISummaryDialog(false)}
          show={showSISummaryDialog}
        />
        }
        {showNewDialog && 
        <AddNewSI
          closeDialog={() => setShowNewDialog(false)}
          currencies={partyCurrencies}
          importExport={importExport}
          onSubmit={(createDraftInfo: {currencyId: string; date: number}) => handleCreateNew({...createDraftInfo, importExport})}
          show={showNewDialog}
        />
        }
        <NotificationSweetAlert
          errorMessage={errorMessage}
          id={'general-notification'}
          onClose={() => {
            setSuccessMessage(undefined)
            setWarningMessage(undefined)
            setErrorMessage(undefined)
            setConfirmCallback(() => undefined)
          }}
          onConfirm={() => {
            confirmCallback()
            setSuccessMessage(undefined)
            setWarningMessage(undefined)
            setErrorMessage(undefined)
            setConfirmCallback(() => undefined)
          }}
          successMessage={successMessage}
          warningMessage={warningMessage}
        />
        {editOpen && 
          <EditSettlementInstruction
            initialSettlementInstruction={selected || {} as SettlementInstruction}
            onClose={() => {
              setEditOpen(false)
              reset(activeTab)
            }}
            onSubmit={handleSubmit}
            onUpdate={handleUpdate}
            open={editOpen}
          />
        }
      </CardContent>
    </Card>
  </>
}

export default SettlementInstructionsV2