import config from 'react-global-configuration'
import {jsonRpcRequest} from './helper/utilities'
import {
  FIND_ALL_CURRENCY_PAIRS_ERROR,
  FIND_ALL_CURRENCY_PAIRS_STARTED,
  FIND_ALL_CURRENCY_PAIRS_SUCCESS,
} from './actionTypes'

export function findAllCurrencyPairsStarted() {
  return {type: FIND_ALL_CURRENCY_PAIRS_STARTED}
}

export function findAllCurrencyPairsError(error) {
  return {type: FIND_ALL_CURRENCY_PAIRS_ERROR, error}
}

export function findAllCurrencyPairsSuccess(result) {
  return {type: FIND_ALL_CURRENCY_PAIRS_SUCCESS, result}
}

export function findAllCurrencyPairs() {
  return dispatch => {
    jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'CurrencyPair-Recordkeeper.Find',
      request: {
        criteria: [],
        query: {
          offset: 0,
          limit: 100,
          sortBy: ['name'],
          order: ['asc']
        }
      },
      onStart: () => dispatch(findAllCurrencyPairsStarted()),
      onSuccess: result => dispatch(findAllCurrencyPairsSuccess(result)),
      onError: error => dispatch(findAllCurrencyPairsError(error))
    })
  }
}
