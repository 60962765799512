import React from 'react';
import PropTypes from 'prop-types';
// Material UI components
import { Dialog } from '@material-ui/core';
// Custom components
import InvoiceDetailContainer from 'components/Detail/invoice/InvoiceDetailContainer';

const InvoiceDetailDialog = (props) => {
  const {
    show,
    onClose,
    counterparties,
    updateInvoiceError,
    updateInvoiceSuccess,
    readOnly,
    invoice,
  } = props;

  return (
    <Dialog
      maxWidth={'lg'}
      onClose={onClose}
      open={show}>
      <div>
        <InvoiceDetailContainer
          counterparties={counterparties}
          invoice={invoice}
          onClose={onClose}
          readOnly={readOnly}
          updateInvoiceError={updateInvoiceError}
          updateInvoiceSuccess={updateInvoiceSuccess}
        />
      </div>
    </Dialog>
  );
};

export default InvoiceDetailDialog;

InvoiceDetailDialog.propTypes = {
  counterparties: PropTypes.arrayOf(PropTypes.object),
  invoice: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  updateInvoiceError: PropTypes.func,
  updateInvoiceSuccess: PropTypes.func,
};
