import React from 'react'
import {connect} from 'react-redux'
import ProcessingOrgHome from './ProcessingOrgHome'

let ProcessingOrgHomeContainer = props => {
  return <ProcessingOrgHome {...props}/>
}

const mapStateToProps = (state) => {
  return {
    currencyPairs: state.currencyPair ? state.currencyPair.records : [],
    partyCurrencyPairIds: state.myParty.currencyPairIds,
    currencies: state.currency ? state.currency.records : [],
  }
}

ProcessingOrgHomeContainer = connect(
  mapStateToProps,
  {},
)(ProcessingOrgHomeContainer)

export default ProcessingOrgHomeContainer
