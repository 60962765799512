import {FormHelperText, Grid, makeStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import {HexToRGBA} from '../../../../utils/Utils'

const useStyles = makeStyles(theme => ({
  root: {
    color: HexToRGBA(theme.palette.text.primary, 0.5),
  },
}))

export const ItemWrapper = (props) => {
  const classes = useStyles()
  const fieldError = props.invoiceValidationErrors ?
    props.invoiceValidationErrors.find(fe => fe.field === props.field) : undefined
  return (
    <Grid
      className={classes.root}
      item
    >
      <div
        style={{
          height: props.textArea ? '' : '31px',
          display: 'grid',
          gridGap: '20px',
          gridTemplateColumns: '170px 1fr',
          marginLeft: '40px',
          marginRight: '40px',
          alignItems: 'center',
          justifyItems: 'end'
        }}
      >
        <div>
          {props.label}
        </div>
        <div>
          <div
            aria-describedby={props.label}>
            {props.children}
            {fieldError &&
            <FormHelperText
              error
              id="helperText">
              {fieldError.reason}
            </FormHelperText>}
          </div>
        </div>
      </div>
    </Grid>
  )
}

ItemWrapper.propTypes = {
  error: PropTypes.object,
  children: PropTypes.object,
  field: PropTypes.string,
  input: PropTypes.bool,
  invoiceValidationErrors: PropTypes.array,
  label: PropTypes.string,
  textArea: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
