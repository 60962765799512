
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card, CardContent, CardHeader, Dialog, IconButton, Tooltip, Typography, withStyles,} from '@material-ui/core'
import Table from 'components/Table/VirtualizedTable/VirtualizedTable'
import Filter from 'components/Filter/Filter'
import {FiFilter as FilterIcon} from 'react-icons/fi'
import TradeRecordkeeper from 'popcorn-js/trade/recordkeeper'
import {ALL_TRADE_TYPES} from 'constants/trade'
import {DATE_CRITERION, TEXT_CRITERION, TEXT_NE_CRITERION} from 'popcorn-js/search/criteria/types'
import moment from 'moment'
import {SystemDateFormat} from 'constants/formats'
import {FormatNumber} from 'utils/TradeUtilities'

const tradeTypeOptions = ALL_TRADE_TYPES.map(
  type => ({value: type, label: type}))

const styles = theme => ({
  dialogPaper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  dialogCard: {
    margin: '5px',
    borderWidth: '6px 0 0 0',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  filledIcon: {
    fill: theme.palette.grey[600],
    color: theme.palette.grey[600],
  },
  outlinedIcon: {
    fill: 'none',
    color: theme.palette.grey[600],
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
})

const tradeFieldNameMap = {
  'status': 'Status',
  'legs.0.maturityDate': 'Near Leg Maturity Date',
  'legs.1.maturityDate': 'Far Leg Maturity Date',
  'legs.0.tradeDate': 'Near Leg Trade Date',
  'legs.1.tradeDate': 'Far Leg Trade Date',
  'currencyPairId': 'Currency Pair',
}

class TradeSelectionDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showFilter: false,
      sortBy: '',
      sortDirection: 'ASC',
      trades: [],
      totalNoTrades: 1,
      clearCacheToggle: false,
      selectedTrade: {},
      criteria: [],
    }
    this.handleCriteriaChange = this.handleCriteriaChange.bind(this)
  }

  clearCache = () => {
    const {clearCacheToggle} = this.state
    this.setState({
      clearCacheToggle: !clearCacheToggle,
      trades: [],
      totalNoTrades: 1,
    })
  }

  handleSortChange = ({sortBy, sortDirection}) => {
    this.setState({sortBy, sortDirection}, this.clearCache)
  }

  loadMoreRows = async ({startIndex, stopIndex}) => {
    const {compulsoryCriteria} = this.props
    const {criteria, sortBy, sortDirection} = this.state

    const sort = sortBy && sortBy !== ''

    const query = {
      offset: startIndex,
      limit: stopIndex - startIndex + 1,
      sortBy: sort ? [sortBy] : undefined,
      order: sort ? (sortDirection === 'DESC' ? ['desc'] : ['asc']) : undefined,
    }

    try {
      const tradeFindResponse = await TradeRecordkeeper.find({
        criteria: [...criteria, ...compulsoryCriteria],
        query,
      })
      const trades = this.state.trades.slice()

      for (let i = 0; i < (tradeFindResponse.records || []).length; i++) {
        const index = i + startIndex
        trades[index] = tradeFindResponse.records[i]
      }

      this.setState({
        trades,
        totalNoTrades: tradeFindResponse.total,
      })
      return ({startIndex, stopIndex})
    } catch (e) {
      this.setState({errorMessage: e.message || e})
      throw e
    }
  }

  handleCriteriaChange(criteriaFilter = {}) {
    this.setState({
      criteria: criteriaFilter.criteria || [],
    }, () => this.clearCache())
  }

  handleTradeSelect = tradeRow => {
    const {tradeSelect, closeDialog} = this.props
    tradeSelect(tradeRow.rowData)
    closeDialog()
  }

  getCurrencyPairName = ccyPairId => {
    const {currencyPairs} = this.props
    const ccyPair = currencyPairs.find(ccyPair => ccyPair.id === ccyPairId)
    if (ccyPair !== undefined) {
      return ccyPair.name
    }
    return '-'
  }

  compulsoryCriteriaAsString = () => {
    const {compulsoryCriteria} = this.props
    let criteriaString = ''
    for (const criterion of compulsoryCriteria) {
      switch (true) {
        case criterion.type === TEXT_NE_CRITERION:
          criteriaString += `${
            tradeFieldNameMap[criterion.value.field]
          } != ${
            criterion.value.text
          }, `
          break

        case criterion.type === DATE_CRITERION:
          if (criterion.value.startDate.date) {
            criteriaString += `${
              moment.unix(criterion.value.startDate.date).format(SystemDateFormat)
            } ≤ `
          }
          criteriaString += `${tradeFieldNameMap[criterion.value.field]} ≤ ${
            moment.unix(criterion.value.endDate.date).format(SystemDateFormat)
          }, `
          break

        default:
      }
    }
    return criteriaString
  }

  render() {
    const {
      show,
      closeDialog,
      classes,
      selectedTrades,
    } = this.props
    const {
      trades,
      totalNoTrades,
      sortBy,
      sortDirection,
      clearCacheToggle,
      showFilter,
    } = this.state

    return (
      <Dialog
        PaperProps={{classes: {root: classes.dialogPaper}}}
        maxWidth={'xl'}
        onClose={closeDialog}
        open={show}
      >
        <Card className={classes.dialogCard}>
          <CardHeader
            action={
              <IconButton
                onClick={() => this.setState({showFilter: !showFilter})}
              >
                <Tooltip
                  enterDelay={400}
                  title={'Toggle Filter'}>
                  <FilterIcon
                    className={showFilter ?
                      classes.filledIcon :
                      classes.outlinedIcon}
                  />
                </Tooltip>
              </IconButton>
            }
            title={
              <div>
                <Typography variant={'h6'}>
                  Select Trade To Link
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  Looking For Trades with:{' ' +
                this.compulsoryCriteriaAsString()}
                </Typography>
              </div>
            }
          />
          <CardContent>
            <div
              style={{
                height: 500,
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
              }}
            >
              <div style={{padding: '0 40px'}}>
                <Filter
                  filters={[
                    {
                      label: 'Number',
                      field: 'number',
                      filterType: 'text',
                      criterionType: TEXT_CRITERION,
                    },
                    {
                      label: 'Type',
                      field: 'tradeType',
                      filterType: 'select',
                      selectOptions: tradeTypeOptions,
                      criterionType: TEXT_CRITERION,
                    },
                  ]}
                  onChange={this.handleCriteriaChange}
                  show={showFilter}
                />
              </div>
              <div>
                <Table
                  clearCacheToggle={clearCacheToggle}
                  columns={[
                    {
                      width: 130,
                      flexGrow: 1.0,
                      label: 'Number',
                      dataKey: 'number',
                    },
                    {
                      width: 130,
                      flexGrow: 1.0,
                      label: 'Type',
                      dataKey: 'tradeType',
                    },
                    {
                      label: 'Currency Pair',
                      dataKey: 'currencyPairId',
                      flexGrow: 1.0,
                      width: 130,
                      cellContentRenderer: ({cellData}) => {
                        return this.getCurrencyPairName(cellData)
                      },
                    },
                    {
                      label: 'Available Balance',
                      dataKey: 'availableBalance',
                      flexGrow: 1.0,
                      width: 130,
                      cellContentRenderer: ({cellData}) => {
                        return FormatNumber(cellData, true, true, 2, true)
                      },
                      numeric: true,
                    },
                  ]}
                  loadMoreRows={this.loadMoreRows}
                  minimumBatchSize={50}
                  onRowClick={this.handleTradeSelect}
                  rowCount={totalNoTrades}
                  rowGetter={({index}) => {
                    let trade = {}
                    if (trades[index]) {
                      trade = trades[index]
                    }
                    return trade
                  }}
                  selected={selectedTrades}
                  sort={this.handleSortChange}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  threshold={30}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </Dialog>
    )
  }
}

TradeSelectionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  compulsoryCriteria: PropTypes.array.isRequired,
  currencyPairs: PropTypes.array.isRequired,
  selectedTrades: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  tradeSelect: PropTypes.func.isRequired,
}
TradeSelectionDialog.defaultProps = {
  show: false,
}

TradeSelectionDialog = withStyles(styles)(TradeSelectionDialog)
export default TradeSelectionDialog
