import {jsonRpcRequest} from './helper/utilities'
import config from 'react-global-configuration'
import {
  CREATE_PASSWORD_STARTED,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_ERROR,
  FIND_PAGE_ALL_USERS_STARTED,
  FIND_PAGE_ALL_USERS_ERROR,
  FIND_PAGE_ALL_USERS_SUCCESS, RETRIEVE_MY_USER_PROFILE_SUCCESS,
} from './actionTypes'

export function createPasswordStarted() {
  return {type: CREATE_PASSWORD_STARTED}
}

export function createPasswordError(error) {
  return {type: CREATE_PASSWORD_ERROR, error}
}

export function createPasswordSuccess({result}) {
  return {type: CREATE_PASSWORD_SUCCESS, result}
}

export function createPassword(emailAddress, password, accessToken) {
  return dispatch => {
    jsonRpcRequest({
      accessToken,
      url: config.get('golangUrl'),
      method: 'User-Recordkeeper.CreatePassword',
      request: {emailAddress, password},
      onStart: () => {
        dispatch(createPasswordStarted())
      },
      onSuccess: result => {
        dispatch(createPasswordSuccess({result}))
      },
      onError: error => {
        dispatch(createPasswordError(error))
      },
    })
  }
}

export function retrieveMyUserProfileSuccess(result){
  return {type: RETRIEVE_MY_USER_PROFILE_SUCCESS,result}
}


export function findPageAllUsersStarted() {
  return {type: FIND_PAGE_ALL_USERS_STARTED}
}

export function findPageAllUsersError(error) {
  return {type: FIND_PAGE_ALL_USERS_ERROR, error}
}

export function findPageAllUsersSuccess(result) {
  return {type: FIND_PAGE_ALL_USERS_SUCCESS, result}
}

export function findPageAllUsers(criteria, query) {
  return dispatch => {
    jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'User-Recordkeeper.FindAll',
      request: {criteria, query},
      onStart: () => dispatch(findPageAllUsersStarted()),
      onSuccess: result => dispatch(findPageAllUsersSuccess(result)),
      onError: error => dispatch(findPageAllUsersError(error)),

    })
  }
}
