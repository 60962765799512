import { Currency, CurrencyPair } from 'popcorn-js/financial';
import { Broker, Company, Individual, ProcessingOrg } from 'popcorn-js/legalEntity/party';
import { PartyType } from 'popcorn-js/trade/tradeTypes';
import { createContext } from 'react'

export type AppContextType = {
  currencies: Currency[];
  currencyPairs: CurrencyPair[];
  localCurrency: Currency;
  party: Company|ProcessingOrg|Individual|Broker;
  partyType: PartyType;
  extendedContext: string[];
  permissions: string[];
}

export const AppContext = createContext<AppContextType>({} as AppContextType)

export const AppContextProvider = AppContext.Provider
export const AppContextConsumer = AppContext.Consumer