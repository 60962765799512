import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// @material-ui/core components
import {withStyles} from '@material-ui/core/styles'

import footerStyle from 'assets/jss/material-dashboard-pro-react/components/footerStyle'

function Footer({...props}) {
  const {classes, fluid, white} = props
  const container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  })
  const anchor = cx(classes.a)

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          {/*<List className={classes.list}>*/}
          {/*<ListItem className={classes.inlineBlock}>*/}
          {/*<a href="#home" className={block}>*/}
          {/*{rtlActive ? "الصفحة الرئيسية" : "Home"}*/}
          {/*</a>*/}
          {/*</ListItem>*/}
          {/*<ListItem className={classes.inlineBlock}>*/}
          {/*<a href="#company" className={block}>*/}
          {/*{rtlActive ? "شركة" : "Company"}*/}
          {/*</a>*/}
          {/*</ListItem>*/}
          {/*<ListItem className={classes.inlineBlock}>*/}
          {/*<a href="#portfolio" className={block}>*/}
          {/*{rtlActive ? "بعدسة" : "Portfolio"}*/}
          {/*</a>*/}
          {/*</ListItem>*/}
          {/*<ListItem className={classes.inlineBlock}>*/}
          {/*<a href="#blog" className={block}>*/}
          {/*{rtlActive ? "مدونة" : "Blog"}*/}
          {/*</a>*/}
          {/*</ListItem>*/}
          {/*</List>*/}
        </div>
        <p className={classes.right}>
          &copy; {new Date().getFullYear()}{' '}
          <a
            className={anchor}
            href="http://www.andile.net">{'TBSwiss & AI'}</a>
          {/*{"Brought to you by Andile Innovation"}*/}
          {/*{version}*/}
        </p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  rtlActive: PropTypes.bool,
  white: PropTypes.bool,
}

export default withStyles(footerStyle)(Footer)
