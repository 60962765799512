import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import {Address} from '../../popcorn-js/legalEntity/party/ContactDetails'

const AddressCard = (props) => {
  return (
    <div>
      <Card
        style={{
          marginBottom: '10px',
          marginTop: '20px',
        }}>

        <CardHeader title={props.title}/>
        <CardContent>
          <Grid
            container
            direction={'column'}
            style={{paddingLeft: '40px'}}>
            {(!props.fields || props.fields.includes('line1')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.line1}
                fullWidth
                helperText={props.invalidFields.line1 || 'Street Address, P.O. box, etc.'}
                id="line1"
                label="Line 1"
                margin={props.margin}
                onChange={(e) => props.onChange('line1',
                  e.target.value)}
                value={(props.address.line1 || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('line2')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.line2}
                fullWidth
                helperText={props.invalidFields.line2 || 'Apartment, suite, unit, building, floor, etc.'}
                id="line2"
                label="Line 2"
                margin={props.margin}
                onChange={(e) => props.onChange('line2',
                  e.target.value)}
                value={(props.address.line2 || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('city')) &&

            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.city}
                fullWidth
                helperText={props.invalidFields.city || ' '}
                id="city"
                label="City"
                margin={props.margin}
                onChange={(e) => props.onChange('city',
                  e.target.value)}
                value={(props.address.city || '')}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('province')) &&

            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.province}
                fullWidth
                helperText={props.invalidFields.province || ' '}
                id="province"
                label="Province"
                margin={props.margin}
                onChange={(e) => props.onChange('province',
                  e.target.value)}
                value={props.address.province || ''}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('postalCode')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.postalCode}
                fullWidth
                helperText={props.invalidFields.postalCode || ' '}
                id="postalCode"
                label="Postal Code"
                margin={props.margin}
                onChange={(e) => props.onChange('postalCode',
                  e.target.value)}
                value={props.address.postalCode ? props.address.postalCode.toString() : ''}
              />
            </Grid>
            }
            {(!props.fields || props.fields.includes('country')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.country}
                fullWidth
                helperText={props.invalidFields.country || ' '}
                id="country"
                label="Country"
                margin={props.margin}
                onChange={(e) => props.onChange('country',
                  e.target.value)}
                value={props.address.country || ''}
              />
            </Grid>
            }
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

AddressCard.propTypes = {
  address: PropTypes.instanceOf(Address).isRequired,
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.string),
  invalidFields: PropTypes.objectOf(PropTypes.string),
  margin: PropTypes.oneOf(['dense', 'normal']),
  onChange: PropTypes.func,
  title: PropTypes.string,
}

AddressCard.defaultProps = {
  address: {},
  invalidFields: {},
  margin: 'normal',
  disabled: false,
  title: 'Address',
}

export default AddressCard
