import {jsonRpcRequestRaw} from '../../actions/helper/utilities'
import config from 'react-global-configuration'


export const Handler = {
  FindSubsidiaries(request: { holdingCompanyPartyCode: string; subsidiaries: string[] }): any {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Company-Handler.FindSubsidiaries',
      request: {
        holdingCompanyPartyCode: request.holdingCompanyPartyCode,
        subsidiaries: request.subsidiaries,
      },
      accessToken: ''
    })
  },

}


export default Handler