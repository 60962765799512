import {marshalIdentifier} from 'popcorn-js/search/identifier/marshalIdentifier'
import {jsonRpcRequestRaw as jsonRpcRequest} from 'actions/helper/utilities'
import config from 'react-global-configuration'
import {MarshalCriteria as marshallCriteria} from 'popcorn-js/search/criteria/Marshaller'
import {jsonRpcRequestRaw} from 'actions/helper/utilities';

/**
 * TradeRecordkeeper is a convenience client for invoking the backend services
 */

const Recordkeeper = {
  create(createRequest) {
    return new Promise((resolve, reject) => {
      reject({message: 'Not implemented'})
    })
  },

  find(findRequest) {
    const serializedCriteria = marshallCriteria(findRequest.criteria)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Trade-Recordkeeper.Find',
      request: {
        ...findRequest,
        criteria: serializedCriteria

      },
    })
  },

  restore(restoreRequest) {
    return new Promise((resolve, reject) => {
      reject({message: 'Not implemented'})
    })
  },

  delete(deleteRequest) {
    return new Promise((resolve, reject) => {
      reject({message: 'Not implemented'})
    })
  },

  retrieveHistory(retrieveHistoryRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveHistoryRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Trade-Recordkeeper.RetrieveHistory',
      request: {
        ...retrieveHistoryRequest,
        identifier: serializedIdentifier
      }
    }
    )
  },

  retrieve(retrieveRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveRequest.identifier)

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Recordkeeper.Retrieve',
      request: {
        ...retrieveRequest,
        serializedIdentifier: serializedIdentifier
      }
    }
    )
  },

}

export default Recordkeeper
