import { jsonRpcRequestRaw } from '../../actions/helper/utilities'
import config from 'react-global-configuration'
import {Marshal} from '../search/identifier/Marshaller';
import {ID_IDENTIFIER} from '../search/identifier/types';
import {MarshalCriteria} from 'popcorn-js/search/criteria/Marshaller'

const Handler = {
  Record(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Handler.Record',
      request
    })
  },
  Amend(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Handler.Amend',
      request
    })
  },
  UpdateTradeLink(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Handler.UpdateTradeLink',
      request
    })
  },

  UpdateACMTradeLink(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Handler.UpdateACMTradeLink',
      request
    })
  },

  /**
   * @param {Identifier} id
   */
  Delete(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Handler.Delete',
      request: {
        serializedIdentifier: Marshal({ type: ID_IDENTIFIER, value: { id } })
      }
    })
  },

  /**
   * @param {Identifier} id
   */
  Restore(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Handler.Restore',
      request: {
        serializedIdentifier: Marshal({ type: ID_IDENTIFIER, value: { id } })
      }
    })
  },
  FindOpenFECs(findRequest) {
    const serializedCriteria = MarshalCriteria(findRequest.criteria)

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Handler.FindOpenFECs',
      request: {
        ...findRequest,
        criteria: serializedCriteria

      },
    })
  },
  FindAllLegsAsTrades(findRequest) {
    const serializedCriteria = MarshalCriteria(findRequest.criteria)

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Handler.FindAllLegsAsTrades',
      request: {
        ...findRequest,
        criteria: serializedCriteria

      },
    })
  },
}



export default Handler
