import {
  boxShadow,
  defaultFont,
  drawerMiniWidth,
  drawerWidth,
  primaryBoxShadow,
  primaryColor,
} from 'assets/jss/material-dashboard-pro-react'
import {HexToRGBA} from 'utils/Utils';

const styles = theme => ({
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1032',
    overflowX: 'hidden',
    ...boxShadow,
    width: drawerWidth,
    borderRight: 'solid',
    borderRightColor: theme.palette.border,
    borderRightWidth: '1px',
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0'
    }
  },
  drawerPaperMini: {
    width: drawerMiniWidth + 'px!important'
  },
  logoContainer: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '108.1px',
    display: 'flex',
    margin: '0 auto',
    borderBottom: 'solid',
    borderBottomColor: theme.palette.border,
    borderBottomWidth: '1px'
  },
  logoMini: {
    overflow: 'hidden',
    height: '1.8rem'
  },
  logoNormal: {
    overflow: 'hidden',
    height: '1.8rem',
  },
  logoNormalSidebarMini: {
    height: '0rem',
    visibility: 'hidden',
  },
  logoImageContainer: {
    alignSelf: 'center',
    margin: '0 auto'
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  list: {
    marginTop: '15px',
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    marginBottom: '0',
    listStyle: 'none',
    color: 'inherit',
    '&:before,&:after': {
      display: 'table',
      content: '" "'
    },
    '&:after': {
      clear: 'both'
    }
  },
  item: {
    color: 'inherit',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: theme.spacing(0)
  },
  userItem: {
    '&:last-child': {
      paddingBottom: theme.spacing(1.875)
    }
  },
  itemLink: {
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    margin: '10px 15px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: theme.spacing(1.25, 1.875),
    backgroundColor: 'transparent',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none'
    },
    '&,&:hover,&:focus': {
      color: 'inherit'
    }
  },
  itemIcon: {
    color: 'inherit',
    width: '24px',
    height: '24px',
    float: 'left',
    position: 'inherit',
    top: '3px',
    marginLeft: '3px',
    textAlign: 'center',
    verticalAlign: 'middle',
    opacity: '0.8'
  },
  itemText: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    lineHeight: '30px',
    fontSize: 14,
    transform: 'translate3d(0px, 0, 0)',
    opacity: '1',
    verticalAlign: 'center',
    position: 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(7.75),
  },
  userItemText: {
    lineHeight: '22px',
    color: '#fff',
    fontSize: 14
  },
  organisationItemText: {
    lineHeight: '22px',
    color: '#707070',
    fontSize: 12
  },
  itemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0'
  },
  collapseList: {
    marginTop: '0'
  },
  collapseItem: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '10px 0 0 0',
    padding: theme.spacing(0)
  },
  collapseActive: {
    outline: 'none',
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    boxShadow: 'none'
  },
  collapseItemLink: {
    margin: '0 15px',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: theme.spacing(1.25),
    backgroundColor: 'transparent',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none'
    },
    '&,&:hover,&:focus': {
      color: 'inherit'
    }
  },
  collapseItemMini: {
    color: 'inherit',
    ...defaultFont,
    textTransform: 'uppercase',
    width: '30px',
    marginRight: '15px',
    textAlign: 'center',
    letterSpacing: '1px',
    position: 'relative',
    float: 'left',
    display: 'inherit',
    fontSize: 14
  },
  collapseItemText: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    position: 'relative',
    transform: 'translateX(0px)',
    opacity: '1',
    whiteSpace: 'nowrap',
    display: 'block',
    fontSize: 14
  },
  collapseItemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0'
  },
  caret: {
    marginTop: '13px',
    position: 'absolute',
    right: '18px',
    display: 'inline-block',
    width: '0',
    height: '0',
    color: '#fff',
    marginLeft: '2px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent'
  },
  userCaret: {
    marginTop: '10px',
  },
  caretActive: {
    transform: 'rotate(180deg)'
  },
  purple: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: primaryColor,
      ...primaryBoxShadow
    }
  },
  sideBarMenuItemsColor: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.secondary.main,
      boxShadow:
        `0 12px 20px -10px ${HexToRGBA(theme.palette.secondary.main, '.28')}, 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px ${HexToRGBA(theme.palette.secondary.main, '.2')}`
    }
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: '270px',
    zIndex: '4',
    overflowScrolling: 'touch',
    color: 'inherit',
    paddingBottom: theme.spacing(3.75),
    backgroundColor: theme.palette.sidebar,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  sidebarWrapperWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
  user: {
    paddingBottom: theme.spacing(1.25),
    // margin: "20px auto 0",
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '15px',
      height: '1px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)'
    }
  },
  photo: {
    width: '34px',
    height: '34px',
    overflow: 'hidden',
    float: 'left',
    zIndex: '5',
    marginRight: '22px',
    borderRadius: '50%',
    marginLeft: '23px',
    marginTop: '11px',
    ...boxShadow
  },
  avatarImg: {
    maxHeight: '32px',
    maxWidth: '32px',
    objectFit: 'contain',
  },
  userCollapseButton: {
    margin: '0',
    padding: theme.spacing(0.75, 1.875),
    '&:hover': {
      background: 'none'
    }
  },
  // userCollapseLinks: {
  //   marginTop: '-4px',
  //   '&:hover,&:focus': {
  //     color: '#FFFFFF'
  //   }
  // }
})

export default styles
