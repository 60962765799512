export const Comparator = {
  isDirty(a, b) {
    if (!a || !b) {
      return true
    }

    if (a.name !== b.name) {
      return true
    }

    for (const permission of a.permissions) {
      if (!b.permissions.includes(permission)) {
        return true
      }
    }

    for (const permission of b.permissions) {
      if (!a.permissions.includes(permission)) {
        return true
      }
    }

    return false
  },

}
