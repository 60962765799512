const requiredFields = ['name', 'code']

class Validator {

  /**
   * @Param {Country} country
   */
  static ValidateData(country) {
    return new Promise(
      function (resolve, reject) {
        const invalidFields = {}
        let valid = true
        requiredFields.forEach(fieldName => {
          if (!country[fieldName] || country[fieldName] === '') {
            invalidFields[fieldName] = 'required'
            valid = false
          }
        })
        if (valid) {
          resolve(undefined)
        } else {
          reject(invalidFields)
        }
      }
    )
  }
}

// invalidFields {
//    name: 'required'
// }

export default Validator
