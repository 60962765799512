import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {Marshal} from '../../search/identifier/Marshaller'
import {ID_IDENTIFIER} from '../../search/identifier/types'

/**
 * CurrencyRecordkeeper is a convenience client for invoking the backend services
 */
export default class Recordkeeper {
  /**
   * @param {TradingDayException} tradingDayException
   */
  static create(tradingDayException) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'TradingDayException-Recordkeeper.Create',
      request: {tradingDayException},
    })
  }

  /**
   * @param {Currency} tradingDayException
   * @param {string} id
   */
  static update(tradingDayException, id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'TradingDayException-Recordkeeper.Update',
      request: {
        tradingDayException,
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}})
      },
    })
  }

  /**
   * @param criteria: Filter criteria
   * @param query: Query options
   * @param {bool} deleted
   */
  static find(criteria, query, deleted) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'TradingDayException-Recordkeeper.Find',
      request: {
        criteria,
        query,
        deleted,
      },
    })
  }

  /**
   * @param id
   */
  static deleteForever(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'TradingDayException-Recordkeeper.DeleteForever',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}})
      }
    })
  }

  /**
   * @param {string} id
   */
  static delete(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'TradingDayException-Recordkeeper.Delete',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}})
      }
    })
  }

  /**
   * @param {string} id
   */
  static restore(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'TradingDayException-Recordkeeper.Restore',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}})
      }
    })
  }

  /**
   * @param {string} id
   */
  static retrieveHistory(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'TradingDayException-Recordkeeper.RetrieveHistory',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id}})
      }
    })
  }
}
