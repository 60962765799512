import {MuiButton} from 'theme/overrides/MuiButton'
import {MuiIconButton} from 'theme/overrides/MuiIconButton'
import {MuiCardHeader} from 'theme/overrides/MuiCardHeader'
import {MuiListItem} from 'theme/overrides/MuiListItem'
import {MuiTableCell} from 'theme/overrides/MuiTableCell'
import {MuiTooltip} from 'theme/overrides/MuiTooltip'
import {MuiListItemIcon} from 'theme/overrides/MuiListItemIcon'
import {MuiTablePagination} from 'theme/overrides/MuiTablePagination'

const overrides = theme => ({
  MuiButton: MuiButton(theme),
  MuiIconButton: MuiIconButton(theme),
  MuiListItem: MuiListItem(theme),
  MuiCardHeader: MuiCardHeader(theme),
  MuiTableCell: MuiTableCell(theme),
  MuiTooltip: MuiTooltip(theme),
  MuiListItemIcon: MuiListItemIcon(theme),
  MuiTablePagination: MuiTablePagination(theme)
})

export default overrides
