export const MuiListItemIcon = theme => ({
  root: {
    color: theme.palette.text.body,
    '&$disabled': {
      color: theme.palette.primary.light
    },
    '&:hover': {
      color: 'white'
    },
  },
})
