import React from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'


const useStyles = makeStyles(() => ({
  root: {
    height: '32px',
    width: '32px',
    strokeWidth: '2'
  },
})
)


export function OrderIcon() {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <SvgIcon
      className={classes.root}
      htmlColor={theme.palette.primary.contrastText}
      viewBox="0 0 32 32"

    >
      <g
        id="Purchase_Order">
        <g
          id="Group_36"
          transform="translate(-454.435 -564.207)">
          <g
            id="Group_32">
            <g
              id="Group_30">
              <path
                d="M465.267,585.736a.5.5,0,0,1-.5-.5v-6a.5.5,0,0,1,.5-.5h6a.5.5,0,0,1,0,1h-5.5v5.5a.5.5,0,0,1-.5.5Z"
                id="Path_22"/>
            </g>
            <g
              id="Group_31">
              <path
                d="M465.267,585.736H454.935a.5.5,0,0,1-.5-.5V564.707a.5.5,0,0,1,.5-.5h16.332a.5.5,0,0,1,.5.5v4.07a.5.5,0,0,1-1,0v-3.57H455.435v19.529h9.625l5.707-5.707v-4.5a.5.5,0,0,1,1,0v4.709a.5.5,0,0,1-.146.353l-6,6a.5.5,0,0,1-.354.147Z"
                id="Path_23"/>
            </g>
          </g>
          <g
            id="Group_35">
            <g
              data-name="Group 33"
              id="Group_33">
              <path
                d="M474.091,576.287a.5.5,0,0,1-.353-.853l3.836-3.838-3.836-3.837a.5.5,0,1,1,.707-.707l4.189,4.19a.5.5,0,0,1,0,.707l-4.189,4.192a.5.5,0,0,1-.354.146Z"
                id="Path_24"/>
            </g>
            <g
              id="Group_34">
              <path
                d="M465.267,572.1a.5.5,0,0,1,0-1h11.875a.5.5,0,0,1,0,1Z"
                id="Path_25"/>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
