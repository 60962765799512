//Views
import CurrencyComponent from 'views/Configuration/Currency/Currency'
import CurrencyPairContainer from 'views/Configuration/CurrencyPair/CurrencyPairContainer'
import CountryComponent from 'views/Configuration/Country/Country'
import ClientTierContainer from '../views/Configuration/ClientTier/ClientTierContainer'
import OverviewContainer from 'views/Configuration/Overview/OverviewContainer'
import UsersContainer from 'views/Configuration/Users/UsersContainer'
import {
  viewConfigurationAccounts,
  viewConfigurationClientTiers,
  viewConfigurationCountries,
  viewConfigurationCurrencies,
  viewConfigurationCurrencyPairs,
  viewConfigurationOverview,
  viewConfigurationRoles,
  viewConfigurationTradingDayExceptions,
  viewConfigurationUsers,
  viewPartySettingsApiUsers,
} from 'permissions/permissionTypes'
import RoleContainer from 'views/Configuration/Role/RoleContainer'
import AccountsContainer from 'views/Configuration/Accounts/AccountsContainer'
import TradingDayExceptionContainer from 'views/Configuration/TradingDayException/TradingDayExceptionContainer'
import APIUsersContainer from '../views/Configuration/APIUsers/APIUsersContainer';

const configurationRoutes = [
  {
    path: '/app/configuration-overview',
    name: 'Overview',
    mini: 'OV',
    component: OverviewContainer,
    permission: viewConfigurationOverview,
    linkID: 'sidebarConfigurationOverview',
  },
  {
    path: '/app/configuration-accounts',
    name: 'Accounts',
    mini: 'AC',
    component: AccountsContainer,
    permission: viewConfigurationAccounts,
    linkID: 'sidebarConfigurationAccountsLink',
  },
  {
    path: '/app/configuration-client-tiers',
    name: 'Client Tiers',
    mini: 'CT',
    component: ClientTierContainer,
    permission: viewConfigurationClientTiers,
    linkID: 'sidebarConfigurationClientTierLink',
  },
  {
    path: '/app/configuration-countries',
    name: 'Countries',
    mini: 'CO',
    component: CountryComponent,
    permission: viewConfigurationCountries,
    linkID: 'sidebarConfigurationCountriesLink',
  },
  {
    path: '/app/configuration-currencies',
    name: 'Currencies',
    mini: 'CU',
    component: CurrencyComponent,
    permission: viewConfigurationCurrencies,
    linkID: 'sidebarConfigurationCurrenciesLink',
  },
  {
    path: '/app/configuration-currency-pairs',
    name: 'Currency Pairs',
    mini: 'CP',
    component: CurrencyPairContainer,
    permission: viewConfigurationCurrencyPairs,
    linkID: 'sidebarConfigurationCurrencyPairsLink',
  },
  {
    path: '/app/configuration-roles',
    name: 'Roles',
    mini: 'RO',
    component: RoleContainer,
    permission: viewConfigurationRoles,
    linkID: 'sidebarConfigurationRolesLink2'
  },
  {
    path: '/app/configuration-trading-day-exceptions',
    name: 'Trading Day Exceptions',
    mini: 'TE',
    component: TradingDayExceptionContainer,
    permission: viewConfigurationTradingDayExceptions,
    linkID: 'sidebarConfigurationTradingDayExceptionsLink',
  },
  {
    path: '/app/configuration-users',
    name: 'Users',
    mini: 'US',
    component: UsersContainer,
    permission: viewConfigurationUsers,
    linkID: 'sidebarConfigurationUsers',
  },
  {
    path: '/app/configuration-apiUsers',
    name: 'APIUsers',
    mini: 'AU',
    component: APIUsersContainer,
    permission: viewPartySettingsApiUsers,
    linkID: 'configurationAPIUser',

  }
]

export default configurationRoutes
