import {AuditEntry} from 'popcorn-js/audit/entryTypes';

export interface Exception {
  id: string
  entityId: string
  type: Type
  number: string
  status: string
  exceptionStatus: ExceptionStatus
  currency: string
  action: string
  actionExecuted: boolean
  user: string
  time: string
  auditEntry: AuditEntry;
}

export enum Type {
  Invoice = 'Invoice',
  Trade = 'Trade',
  Order = 'Order',
  SI = 'SI',
}
export enum ExceptionStatus {
  Pending = 'PENDING',
  Verified = 'VERIFIED',
  Restored = 'RESTORED',
  TimedOut = 'TIMEDOUT',
  Deleted = 'DELETED',
}