import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {Marshal} from 'popcorn-js/search/identifier/Marshaller'
import {ID_IDENTIFIER} from 'popcorn-js/search/identifier/types'
import {marshalCriteria} from 'popcorn-js/search/marshallerV2'
import {marshalIdentifier} from 'popcorn-js/search/identifier/marshalIdentifier';

export const Recordkeeper = {
  /**
   * @param {SettlementInstructionType} settlementInstruction
   */
  create(request: any): any {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Create',
      request,
      accessToken: ''
    })
  },
  /**
   * @param criteria: Filter criteria
   * @param query: Query options
   * @param {boolean} deleted
   */
  find(request: any): any {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Find',
      request: {
        ...request,
        criteria: marshalCriteria(request.criteria),
      },
      accessToken: ''
    })
  },
  update(request: any): any {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Update',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id: request.id}}),
        settlementInstruction: request.settlementInstruction,
      },
      accessToken: ''
    })
  },
  delete(id: string): any {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Delete',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id:id}}),
      },
      accessToken: ''
    })
  },
  deleteForever(id: string): any {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.DeleteForever',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id:id}}),
      },
      accessToken: ''
    })
  },
  restore(id: string): any {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Restore',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id:id}}),
      },
      accessToken: ''
    })
  },
  retrieve(retrieveRequest: any): any {
    const serializedIdentifier = marshalIdentifier(retrieveRequest.identifier)
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'SettlementInstruction-Recordkeeper.Retrieve',
      request: {
        identifier: serializedIdentifier,
        Deleted: retrieveRequest.Deleted,
      },
      accessToken: ''
    })
  },
}

export default Recordkeeper
