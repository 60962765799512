import {Entry as AuditEntry} from 'popcorn-js/audit'
import {isObject} from 'utils/Utils'

export default class Counterparty {
  /**
   * @type {string}
   */
  id = ''

  /**
   * partyCode.Identifier
   * @type {string}
   */
  partyCode = ''

  /**
   * partyCode.Identifier
   * @type {string}
   * @private
   */
  name = ''

  /**
   * partyCode.Identifier
   * @type {string}
   * @private
   */
  externalReference = ''

  /**
   * @type {AuditEntry}
   * @private
   */
  auditEntry = new AuditEntry()

  /**
   * @param {object|Counterparty} [counterparty]
   */
  constructor(counterparty) {
    if (
      counterparty !== undefined &&
      (counterparty instanceof Counterparty || isObject(counterparty))
    ) {
      try {
        this.id = counterparty.id
        this.name = counterparty.name
        this.externalReference = counterparty.externalReference
        this.partyCode = counterparty.partyCode
        this.auditEntry = new AuditEntry(counterparty.auditEntry)
      } catch (e) {
        console.error(`error constructing counterparty: ${e}`)
      }
    }
  }
}
