import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import config from 'react-global-configuration'
import {marshalCriteria} from 'popcorn-js/search/marshallerV2'
import {Marshal} from 'popcorn-js/search/identifier/Marshaller';
import {Criteria, Query} from 'popcorn-js/search';
import {ID_IDENTIFIER} from 'popcorn-js/search/identifier/types';
import {Exception} from 'views/OperationsManagement/opertation';


export interface FindRequest {
  criteria: Criteria,
  query: Query,
}
export interface FindResponse {
  records: Exception[],
  total: number,
}

export interface ModifyRequest {
  id: string,
  exception: Exception,
}
export interface ModifyResponse {
  exception: Exception,
}

export const Handler = {
  ServiceProviderName: 'Operations-Handler',
  FindExceptions(request: FindRequest): Promise<FindResponse> {
    const serializedCriteria = marshalCriteria(request.criteria)
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: `${Handler.ServiceProviderName}.FindExceptions`,
      request: {
        ...request,
        criteria: serializedCriteria,
      },
      accessToken: ''
    })
  },
  VerifyException(request: ModifyRequest): Promise<ModifyResponse> {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: `${Handler.ServiceProviderName}.VerifyException`,
      request: {
        ...request
      },
      accessToken: ''
    })
  },

  RestoreException(request: ModifyRequest): Promise<ModifyResponse> {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: `${Handler.ServiceProviderName}.RestoreException`,
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id:request.id}}),
        ...request
      },
      accessToken: ''
    })
  },
  DeleteException(request: ModifyRequest): Promise<ModifyResponse> {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: `${Handler.ServiceProviderName}.DeleteException`,
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id:request.id}}),
        ...request
      },
      accessToken: ''
    })
  },

}


export default Handler