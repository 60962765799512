import {jsonRpcRequest} from './helper/utilities'
import config from 'react-global-configuration'
import {
  FIND_ALL_MY_PERMISSIONS_ERROR,
  FIND_ALL_MY_PERMISSIONS_STARTED,
  FIND_ALL_MY_PERMISSIONS_SUCCESS,
} from 'actions/actionTypes'

export function findAllMyPermissionsStarted() {
  return {type: FIND_ALL_MY_PERMISSIONS_STARTED}
}

export function findAllMyPermissionsError(error) {
  return {type: FIND_ALL_MY_PERMISSIONS_ERROR, error}
}

export function findAllMyPermissionsSuccess(result) {
  return {type: FIND_ALL_MY_PERMISSIONS_SUCCESS, result}
}

export function findAllMyPermissions() {
  return dispatch => {
    jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Permission.FindAllMyPermissions',
      request: {},
      onStart: () => dispatch(findAllMyPermissionsStarted()),
      onSuccess: result => dispatch(findAllMyPermissionsSuccess(result)),
      onError: error => dispatch(findAllMyPermissionsError(error))
    })
  }
}
