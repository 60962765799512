import { useEffect, useState } from 'react';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty';

export function useCounterpartyRecordkeeperFind(
  initialCriteria = [],
  initialQuery = {
    sortBy: [],
    order: [],
    limit: 0,
    offset: 0,
  }
) {
  const [request, setRequest] = useState({
    criteria: initialCriteria,
    query: initialQuery,
    Deleted: false,
  });
  const [response, setResponse] = useState({
    records: [],
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setError('');
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 400));
        setResponse(await CounterpartyRecordkeeper.find({ request }));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error finding counterparties', e);
        setError(
          'error finding counterparties: ' +
            (e.message ? e.message : e).toString()
        );
      }
      setLoading(false);
    };
    if (request.query.limit !== -1) {
      fetchData().finally();
    }
  }, [request]);

  return [{ request, response, loading, error }, setRequest];
}
