import React from 'react'
import moment from 'moment'
import Table from 'components/Table/index'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import 'react-day-picker/lib/style.css'
import Typography from '@material-ui/core/Typography'
import {
  Tooltip,
  IconButton,
} from '@material-ui/core'

import {
  withStyles,
} from '@material-ui/styles'
import {
  Publish,
  SystemUpdateAlt,
} from '@material-ui/icons';

import {
  FilterToSortBy,
  SortByToFilter,
  SortNumberString,
  SortObjects,
} from 'utils/Utils'
import Recordkeeper from 'popcorn-js/financial/tradingDayException/recordkeeper'
import Comparator from 'popcorn-js/financial/tradingDayException/comparator'
import {FullPageLoader as Loader} from 'components/Loader/Loader'
import NotificationSweetAlert
  from 'components/SweetAlert/NotificationSweetAlert'
import HistoryLayout from 'views/History/HistoryLayout'
import {TradingDayExceptionFields} from 'views/History/TradingDayExceptionHistory/TradingDayExceptionFields'
import FileSaver from 'file-saver';
import { Uploader } from 'popcorn-js/financial/tradingDayException/uploader';

const styles = theme => ({
  table: {
    minWidth: '700px',
  },
  tableRoot: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  pagination: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 70px auto 1fr',
  },
  paginationText: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  dateCell: {
    width: '115px',
    padding: '4px 16px 4px 14px',
  },
  detailCell: {
    padding: '4px 10px 4px 14px',
  },
  fileInput: {
    visibility: 'hidden'
  }
})

const ACTIVE_STATE_VIEWING = 'ACTIVE_STATE_VIEWING'
const ACTIVE_STATE_CREATING = 'ACTIVE_STATE_CREATING'
const ACTIVE_STATE_EDITING = 'ACTIVE_STATE_EDITING'
const ACTIVE_STATE_VIEWING_DELETE = 'ACTIVE_STATE_VIEWING_DELETE'

const entity = {
  camel: 'tradingDayException',
  camelP: 'tradingDayExceptions',
  nice: 'trading day exception',
  niceP: 'trading day exceptions',
  capital: 'Trading day exception',
  capitalP: 'Trading day exceptions',
}

const tradingDayExceptionListLength = 10
const tradingDayExceptionConfigurationListId = `${entity.camel}ConfigurationList`

class TradingDayException extends React.Component {
  constructor(props) {
    super(props)
    // set default date
    const initialTde = {}
    initialTde.date = moment().unix()

    this.formRef = React.createRef();
    this.fileRef = React.createRef();
  }

  state = {
    activeState: ACTIVE_STATE_VIEWING,
    tradingDayExceptions: [],
    total: 0,
    selectedRowIndex: -1,
    previouslySelectedRowIndex: -1,
    sortBy: ['date'],
    history: [],
    selected: {},
    original: {},
    previouslySelected: {},
    isLoading: true,
    showHistory: false,
    successMessage: undefined,
    errorMessage: undefined,
    warningMessage: undefined,
    confirmationMethod: undefined,
    invalidFields: {},
    tabIndex: 0,
  }

  componentDidMount() {
    this.find()
  }

  handleTabChange = (event, tabIndex) => {
    this.setState({tabIndex: tabIndex})
  }

  handleSelection = (selected, index, activeState) => {
    if (this.state.activeState === ACTIVE_STATE_EDITING && !activeState) {
      this.showDiscardConfirmation(() => {
        this.handleHideAlert()
        this.handleSelection(selected, index, ACTIVE_STATE_VIEWING)
      })
      return
    }

    let t = activeState
    const {
      activeState: currentState,
    } = this.state
    if (!activeState) {
      switch (currentState) {
        case ACTIVE_STATE_CREATING:
        case ACTIVE_STATE_EDITING:
          t = ACTIVE_STATE_VIEWING
          break
        default:
          t = currentState
      }
    }

    this.setState({
      selected: {...selected},
      original: {...selected},
      previouslySelected: {...selected},

      selectedRowIndex: index,
      previouslySelectedRowIndex: index,

      activeState: t,
    })
  }

  find = async (deleted = false) => {
    const {
      sortBy,
    } = this.state

    const query = {
      sortBy,
    }
    this.setState({isLoading: true})

    try {
      const findResponse = await Recordkeeper.find(
        undefined,
        query,
        deleted,
      )
      // Map to tradingDayException entities
      const tradingDayExceptions = findResponse.records;
      tradingDayExceptions.sort((a, b) => SortObjects(a, b, this.state.sortBy))

      // Select the first tradingDayException in the list
      let selected
      let selectedRowIndex
      if (findResponse.total > 0) {
        selected = tradingDayExceptions[0]
        selectedRowIndex = 0
      } else {
        selected = {}
        selectedRowIndex = -1
      }

      this.handleSelection(
        selected,
        selectedRowIndex,
        deleted ? ACTIVE_STATE_VIEWING_DELETE : ACTIVE_STATE_VIEWING
      )

      this.setState({
        total: findResponse.total,
        tradingDayExceptions: tradingDayExceptions,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  showDeleteConfirmation = () => {
    this.setState({
      warningMessage: `You are about to permanently delete '${this.state.selected.name}'. Do you want to continue?`,
      confirmationMethod: this.handleDelete,
    })
  }

  showDiscardConfirmation = (confirm) => {
    this.setState({
      warningMessage: 'You have unsaved changes. Do you want to continue?',
      confirmationMethod: confirm,
    })
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    this.handleHideAlert()

    try {
      await Recordkeeper.deleteForever(this.state.selected.id)
      const tradingDayExceptions = this.state.tradingDayExceptions.slice()
      const idx = tradingDayExceptions.findIndex(
        c => c.id === this.state.selected.id)
      tradingDayExceptions.splice(idx, 1)

      if (tradingDayExceptions.length > idx) {
        this.handleSelection(tradingDayExceptions[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ?
            {} :
            tradingDayExceptions[idx - 1], idx - 1)
      }

      this.setState({
        successMessage: `${entity.capital} deleted`,
        tradingDayExceptions,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleTextChange = name => event => {
    const {
      invalidFields,
      selected,
    } = this.state
    selected[name] = event.target.value
    invalidFields[name] = undefined
    this.setState({invalidFields, selected})
    this.handleChanges(selected)
  }
  isDate =  function(date) {
    return (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date));
  }

  handleDateChange = name => (event) => {
    const {
      invalidFields,
      selected,
    } = this.state

    if (!this.isDate(event.target.value)) {
      this.setState({invalidFields: {...this.state.invalidFields, date: 'invalid date'}})
      return;
    }

    selected[name] = event.target.value
    invalidFields[name] = undefined
    this.setState({selected, invalidFields})
    this.handleChanges(selected)
  }

  handleChanges = (selected) => {
    const {
      activeState: currentState,
      selectedRowIndex,
    } = this.state

    let activeState
    switch (currentState) {
      case ACTIVE_STATE_CREATING:
        activeState = ACTIVE_STATE_CREATING
        break
      case ACTIVE_STATE_VIEWING:
        activeState = selectedRowIndex >= 0
          ? (!Comparator.CompareAll(this.state.original, selected)
            ? ACTIVE_STATE_EDITING
            : ACTIVE_STATE_VIEWING)
          : ACTIVE_STATE_CREATING
        break
      case ACTIVE_STATE_EDITING:
        activeState = !Comparator.CompareAll(this.state.original, selected)
          ? ACTIVE_STATE_EDITING
          : ACTIVE_STATE_VIEWING
        break
      default:
    }
    this.setState({activeState})
  }

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    })
  }

  handleViewDelete = () => {
    this.find(true)
  }

  handleDelete = async () => {
    this.setState({isLoading: true})

    try {
      await Recordkeeper.delete(this.state.selected.id)
      const tradingDayExceptions = this.state.tradingDayExceptions.slice() || []
      const idx = tradingDayExceptions.findIndex(
        c => c.id === this.state.selected.id
      )
      tradingDayExceptions.splice(idx, 1)

      if (tradingDayExceptions.length > idx) {
        this.handleSelection(tradingDayExceptions[idx], idx)
      } else {
        this.handleSelection(
          idx === 0
            ? {}
            : tradingDayExceptions[idx - 1],
          idx - 1,
        )
      }

      this.setState({
        successMessage: `${entity.capital} deleted`,
        tradingDayExceptions,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }

    this.setState({isLoading: false})
  }

  handleReturn = () => {
    this.find()
  }

  handleRestore = async () => {
    this.setState({isLoading: true})
    try {
      await Recordkeeper.restore(this.state.selected.id)
      const tradingDayExceptions = this.state.tradingDayExceptions.slice()
      const idx = tradingDayExceptions.findIndex(
        c => c.id === this.state.selected.id
      )
      tradingDayExceptions.splice(idx, 1)

      if (tradingDayExceptions.length > idx) {
        this.handleSelection(tradingDayExceptions[idx], idx)
      } else {
        this.handleSelection(
          idx === 0
            ? {}            : tradingDayExceptions[idx - 1],
          idx - 1,
        )
      }

      this.setState({
        successMessage: `${entity.capital} restored`,
        tradingDayExceptions,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleRetrieveHistory = async () => {
    this.setState({isLoading: true})
    try {
      const retrieveHistoryResponse = await Recordkeeper.retrieveHistory(
        this.state.selected.id,
      )
      const history = retrieveHistoryResponse.history.map(
        tradingDayException => ({...tradingDayException})
      )
      this.setState({history, showHistory: true})
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

downloadTemplate = async () => {
  try {
    const downloadTemplateResponse = await Uploader.downloadExcelTemplate({});
    // convert base64 to byte array
    console.log(downloadTemplateResponse.data);
    const binData = atob(downloadTemplateResponse.data);
    const bytes = new Array(binData.length);
    for (let i = 0; i < binData.length; i++) {
      bytes[i] = binData.charCodeAt(i);
    }
    const blob = new Blob([new Uint8Array(bytes)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
    });
    FileSaver.saveAs(blob, 'trading-day-exceptions.xlsx');
  } catch (e) {
    this.setState({errorMessage: `Failed to download XLSX template for TDEs: ${e}`});
    return;
  }
};

triggerInputClick = () => this.fileRef?.current?.click();
resetFiles = () => this.formRef?.current?.reset();

uploadTDEs = () => {
  this.resetFiles();
  this.triggerInputClick();
};

handleFileUpload = (acceptedFiles) => {
  if (!acceptedFiles.length) {
    console.error('No accepted files found');
    return;
  }
  const extPos = acceptedFiles[0].name.search(/\./);
  if (extPos < 0) {
    this.setState({errorMessage: 'Failed to determine file extension'});
    return;
  }
  let fileExt;
  try {
    fileExt = acceptedFiles[0].name.slice(extPos + 1);
    if (fileExt !== 'xlsx') {
      this.setState({errorMessage: 'File must be of type ".xlsx"'});
      return;
    }
  } catch (e) {
    this.setState({errorMessage: 'Failed to determine file extension'});
    return;
  }
  const reader = new FileReader();
  reader.onload = async (event) => {
    try {
      // convert to b64, call service
      const fileData = event?.target?.result;
      // eslint-disable-next-line no-undef
      const data = Buffer.from(fileData).toString('base64');
      await Uploader.uploadExcelResponse({ data });
      this.setState({successMessage: 'Successfully uploaded TDEs'});
      this.resetFiles();
      this.find();
    } catch (e) {
      this.setState({errorMessage: `Failed to upload TDEs: ${e.message}`});
      return;
    }
  };
  reader.onerror = (err) => {
    this.setState({errorMessage: `Failed to upload TDEs: ${err}`});
  };
  reader.readAsArrayBuffer(acceptedFiles[0]);
};

render() {
  const {
    selectedRowIndex,
    tradingDayExceptions,
    activeState,
  } = this.state

  const {
    classes,
  } = this.props

  return (
    <div id={`${entity.camel}ConfigurationRoot`}>
      <form ref={this.formRef}>
        <input
          className={classes.fileInput}
          onChange={(event) =>
            this.handleFileUpload((event.target).files)
          }
          ref={this.fileRef}
          type={'file'}
        />
      </form>
      <Card
        style={{
          marginBottom: '10px',
        }}>
        {this.renderDialogs()}
        <CardHeader
          action={
            <div>
              <Tooltip title={'Upload'}>
                <IconButton
                  onClick={this.uploadTDEs}
                >
                  <Publish />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Download template'}>
                <IconButton
                  onClick={this.downloadTemplate}
                >
                  <SystemUpdateAlt />
                </IconButton>
              </Tooltip>
            </div>
          }
          title={
            <Typography
              gutterBottom
              variant={'h6'}>
              {activeState === ACTIVE_STATE_VIEWING_DELETE ?
                `${entity.capitalP} - Delete` :
                `${entity.capitalP}`}
            </Typography>
          }
        />
        <CardContent
          style={{
            padding: '0',
            margin: '0px 24px 24px 24px',
          }}
        >
          <Grid
            container
            direction={'row'}
            spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}>
              <Table
                columns={[
                  {
                    Header: 'Date',
                    accessor: 'date',
                    Cell: cellInfo => cellInfo.value,
                  },
                  {
                    Header: 'Description',
                    accessor: 'description',
                  },
                  {
                    Header: 'Currency',
                    accessor: 'currencyId',
                    Cell: cellInfo => this.props.currencies.find(
                      (currency) => cellInfo.value === currency.id) ?
                      this.props.currencies.find(
                        (currency) => cellInfo.value ===
                            currency.id).isoCode : '',
                  },
                ]}
                data={tradingDayExceptions}
                defaultPageSize={tradingDayExceptionListLength}
                defaultSortMethod={(a, b) => SortNumberString(a, b)}
                defaultSorted={SortByToFilter(this.state.sortBy)}
                getTbodyProps={() => {
                  return {
                    style: {
                      overflowY: 'scroll',
                      height: '368px',
                      scrollBehavior: 'smooth',
                    },
                    id: tradingDayExceptionConfigurationListId,
                  }
                }}
                getTdProps={(state, rowInfo) => {
                  const rowIndex = rowInfo ? rowInfo.index : undefined

                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo) {
                        this.handleSelection(
                          {...rowInfo.original},
                          rowIndex)
                      }
                      if (handleOriginal) {
                        handleOriginal()
                      }
                    },
                    style: {
                      background: rowIndex === selectedRowIndex ?
                        this.props.theme.palette.secondary.light :
                        'white',
                      color: rowIndex === selectedRowIndex ?
                        this.props.theme.palette.secondary.contrastText :
                        'black',
                    },
                  }
                }}
                id={`${entity.camel}Table`}
                onSortedChange={(filter) => this.setState(
                  {sortBy: FilterToSortBy(filter)})}
                pageSize={Math.max(tradingDayExceptionListLength,
                  (tradingDayExceptions || []).length)}
                showPagination={false}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

  renderDialogs = () => {
    const {
      isLoading,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      showHistory,
      selected,
      history,
    } = this.state
    const {
      currencies,
    } = this.props

    return (
      <span>
        <Loader
          color={this.props.theme.palette.primary.main}
          isLoading={isLoading}
        />
        {showHistory &&
        <HistoryLayout
          addEntityFieldsProps={{currencies}}
          entity={selected}
          entityFields={TradingDayExceptionFields}
          entityHistory={history}
          entityName={entity.capital}
          loading={isLoading}
          onHide={() => this.setState({showHistory: false})}
          open
        />
        }
        <NotificationSweetAlert
          customClass={`${entity.camel}ConfigAlert`}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}

          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </span>
    )
  }

  static scrollToPos(pos) {
    const objDiv = document.getElementById(tradingDayExceptionConfigurationListId)
    if (objDiv) {
      objDiv.scrollTop = pos * objDiv.scrollHeight
    }
  }
}

TradingDayException.propTypes = {}

export default withStyles(styles,{withTheme:true})(TradingDayException)

