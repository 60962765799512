import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'

const GovernanceCard = (props) => {
  return (
    <div>
      <Card
        style={{
          marginBottom: '10px',
          marginTop: '20px',
        }}>

        <CardHeader title={props.title}/>
        <CardContent>
          <Grid
            container
            direction={'column'}
            style={{paddingLeft: '40px'}}>
            {(!props.fields || props.fields.includes('authorisedSignatory')) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange}
                error={!!props.invalidFields.authorisedSignatory}
                fullWidth
                helperText={props.invalidFields.authorisedSignatory || ' '}
                id="authorisedSignatory"
                label="Authorised Signatory"
                margin={props.margin}
                onChange={(e) => props.onChange('authorisedSignatory',
                  e.target.value)}
                value={(props.party.authorisedSignatory || '')}
              />
            </Grid>
            }
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

GovernanceCard.propTypes = {
  disabled: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.string),
  invalidFields: PropTypes.objectOf(PropTypes.string),
  margin: PropTypes.oneOf(['dense', 'normal']),
  onChange: PropTypes.func,
  party: PropTypes.object.isRequired,
  title: PropTypes.string,
}

GovernanceCard.defaultProps = {
  party: {},
  invalidFields: {},
  margin: 'normal',
  disabled: false,
  title: 'Governance',
}

export default GovernanceCard
