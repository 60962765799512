import { IconButton, Tooltip, CircularProgress } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { useStyletron } from 'styletron-react'

export const ActionButton = (props: {
  id: string;
  disabled?: boolean;
  loading?: boolean;
  icon: ReactElement;
  helpText?: string;
  onClick: (event: any) => void;
}): ReactElement => {
  const [css] = useStyletron()
  return (
    <>
      {props.disabled &&
      (
        <IconButton
          className={css({padding: 0, marginRight: '16px'})}
          disabled
          id={props.id}
        >
          {props.icon}
        </IconButton>
      )
      }
      {!props.disabled &&
        (
          <Tooltip title={props.helpText || ''}>
            <IconButton
              className={css({padding: 0, marginRight: '16px', height: '100%'})}
              disabled={props.loading}
              id={props.id}
              onClick={props.onClick}
            >
              {props.icon}
              {props.loading && <CircularProgress
                className={css({position: 'fixed'})}
                color={'secondary'}
                size={16} />
              }
            </IconButton>
          </Tooltip>
        )
      }
    </>
  )
}