import CurrencyEntity from 'popcorn-js/financial/currency/Currency'
import {
  CREATE_CURRENCY_ERROR,
  CREATE_CURRENCY_STARTED,
  CREATE_CURRENCY_SUCCESS,
  FIND_ALL_CURRENCIES_SUCCESS,
  UPDATE_CURRENCY_ERROR,
  UPDATE_CURRENCY_STARTED,
  UPDATE_CURRENCY_SUCCESS,
} from '../actions/actionTypes'

export default function currency(state = {records: [], total: 0, action: {}}, action) {
  switch (action.type) {
    case FIND_ALL_CURRENCIES_SUCCESS:
      return {
        ...state,
        records: action.result.records ? action.result.records.map(ccy => new CurrencyEntity(ccy)) : [],
        total: action.result.total,
      }
    case CREATE_CURRENCY_STARTED:
      return {...state, action: {...state.action, created: undefined, error: undefined}}
    case CREATE_CURRENCY_SUCCESS: {
      const records = state.records.slice() || []
      records.push(action.result.currency)
      return {
        ...state,
        records,
        total: (state.total + 1),
        action: {...state.action, created: action.result.currency, error: undefined}
      }
    }
    case CREATE_CURRENCY_ERROR: {
      return {...state, action: {...state.action, created: undefined, error: action.error}}
    }
    case UPDATE_CURRENCY_STARTED:
      return {...state, action: {...state.action, updated: undefined, error: undefined}}
    case UPDATE_CURRENCY_SUCCESS: {
      const records = state.records.slice() || []
      const recordToReplaceIndex = records.findIndex(ccy => ccy.id === action.result.currency.id)
      if (recordToReplaceIndex >= 0) {
        records[recordToReplaceIndex] = action.result.currency
      }
      return {...state, records, action: {...state.action, updated: action.result.currency, error: undefined}}
    }
    case UPDATE_CURRENCY_ERROR: {
      return {...state, action: {...state.action, updated: undefined, error: action.error}}
    }

    default:
      return state
  }
}
