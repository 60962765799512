import {isObject} from '../../../../utils/Utils'


export class Address {
  /**
   * @type {string}
   */
  residentialAddress = ''
  /**
   * @type {string}
   */
  streetName = ''
  /**
   * @type {string}
   */
  streetNumber = ''
  /**
   * @type {string}
   */
  complex = ''
  /**
   * @type {string}
   */
  complexName = ''
  /**
   * @type {string}
   */
  unitNumber = ''
  /**
   * @type {string}
   */
  suburb = ''
  /**
   * @type {string}
   */
  city = ''
  /**
   * @type {string}
   */
  residentialPostalCode = ''
  /**
   * @type {string}
   */
  country = ''
  /**
   * @type {string}
   */
  postalAddress = ''
  /**
   * @type {bool}
   */
  POBox = undefined
  /**
   * @type {bool}
   */
  postalNumber = ''
  /**
   * @type {string}
   */
  townName = ''
  /**
   * @type {string}
   */
  postalCode = ''

  constructor(address) {
    if (address !== undefined &&
      (address instanceof Address || isObject(address))) {
      try {
        this.residentialAddress = address.residentialAddress
        this.streetName = address.streetName
        this.streetNumber = address.streetNumber
        this.complex = address.complex
        this.complexName = address.complexName
        this.unitNumber = address.unitNumber
        this.suburb = address.suburb
        this.residentialPostalCode = address.residentialPostalCode
        this.country = address.country
        this.postalAddress = address.postalAddress
        this.POBox = address.POBox
        this.postalNumber = address.postalNumber
        this.townName = address.townName
        this.postalCode = address.postalCode
      } catch (e) {
        console.error(`error constructing address: ${e}`)
        throw e
      }
    }
  }

}

export class ContactDetailsIndividual {
  /**
   * @type {number}
   */
  cellphoneNumber = undefined
  /**
   * @type {number}
   */
  telephoneNumber = undefined
  /**
   * @type {Address}
   */
  address = new Address()

  constructor(contactDetails) {
    if (contactDetails !== undefined &&
      (contactDetails instanceof ContactDetailsIndividual ||
        isObject(contactDetails))) {
      try {
        this.cellphoneNumber = contactDetails.cellphoneNumber
        this.telephoneNumber = contactDetails.telephoneNumber
        this.address = new Address(contactDetails.address)
      } catch (e) {
        console.error(`error constructing contact details: ${e}`)
        throw e
      }
    }
  }
}
