import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Button, Dialog, DialogTitle, Tooltip, withStyles,} from '@material-ui/core'
import {isArray, isFunction, objectCopy} from 'utils/Utils'
import {MdClose as CloseIcon, MdRestorePage as ResetIcon, MdSave as SaveIcon} from 'react-icons/md'
import DnDListAssignSort from 'components/DnDListAssignSort/index'
import miniLogo from 'assets/img/stellcap-logo-mini.svg'
import Fab from '@material-ui/core/Fab'

const styles = theme => ({
  root: {},
  dialogTitleWrapper: {
    padding: 5,
    backgroundColor: theme.palette.primary.main,
  },
  dialogTitle: {
    padding: 5,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
  },
  TBDLogoWrapper: {
    padding: 4,
  },
  TBDLogoImg: {
    width: '30px',
    verticalAlign: 'middle',
    border: '0'
  },
  dialogTitleText: {
    justifySelf: 'center',
    color: theme.palette.primary.contrastText,
  },
  dialogTitleCloseBtnWrapper: {
    justifySelf: 'end',
    paddingLeft: 4,
  },
  dialogTitleCloseButton: {
    padding: 2,
    minHeight: '20px',
    minWidth: '20px',
    height: '20px',
    width: '20px',
  },
  dialogTitleCloseIcon: {
    fontSize: 15,
  },
  dialogContent: {
    display: 'grid',
    backgroundColor: theme.palette.background.paper,
    gridTemplateRows: 'auto auto',
  },
  buttons: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'auto auto auto',
    marginBottom: 10,
  },
  button: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  buttonWrapper: {
    margin: 4,
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  iconSmall: {
    fontSize: 20,
  },
})

class ColumnConfigDialog extends Component {
  // Component Todos:
  // TODO: implement 'unsaved changes' state and indication
  // TODO: add states for 'save in progress', success, error etc. for back-end saving
  // TODO: fix objCopyFunction... can never copy an array with an array in it

  constructor(props) {
    super(props)

    if (!isArray(props.columnConfig)) {
      console.error('cannot construct ColumnConfigDialog as given columnConfig prop is not an array')
      return
    }

    this.state = {
      resetToggle: false,
    }

    this.originalColumnConfig = props.columnConfig.map(colConfig => objectCopy(colConfig))

    this.buildAssignedAndUnassignedLists(props.columnConfig)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      show: prevShow,
    } = prevProps
    const {
      show,
    } = this.props

    // If the status of show has changed
    if (prevShow !== show) {
      // and if we are now showing the dialog
      if (show) {
        this.buildAssignedAndUnassignedLists(this.originalColumnConfig)
        this.setState({
          resetToggle: !this.state.resetToggle
        })

      }
    }
  }

  closeDialog = () => {
    const {
      closeDialog,
    } = this.props
    if (closeDialog) {
      closeDialog()
    } else {
      console.error('no close function passed to ColumnConfigDialog')
    }
  }

  buildAssignedAndUnassignedLists = (columnConfig) => {

    if (!isArray(columnConfig)) {
      console.error('columnConfig passed to ColumnConfigDialog is not an array')
      return
    }

    columnConfig = columnConfig ? columnConfig.map(colConf => objectCopy(colConf)) : columnConfig

    this.hiddenColumns = []
    this.visibleColumns = []

    columnConfig.forEach(colConf => {
      if (colConf.visible) {
        this.visibleColumns.push(colConf)
      } else {
        this.hiddenColumns.push(colConf)
      }
    })
  }

  saveUpdatedColConfig = () => {
    const {onUpdate} = this.props
    const newColConfig = []

    this.hiddenColumns.forEach(col => {
      col.visible = false
      newColConfig.push(col)
    })
    this.visibleColumns.forEach(col => {
      col.visible = true
      newColConfig.push(col)
    })

    this.originalColumnConfig = newColConfig.map(colConfig => objectCopy(colConfig))

    if (isFunction(onUpdate)) {
      onUpdate(newColConfig)
    } else {
      console.error('onUpdate prop passed to ColumnConfigDialog is not a function')
    }
  }

  undoChanges = () => {
    this.buildAssignedAndUnassignedLists(this.originalColumnConfig)
    this.setState({
      resetToggle: !this.state.resetToggle
    })
  }

  resetToDefault = () => {
    const {
      defaultColumnConfig,
    } = this.props
    this.buildAssignedAndUnassignedLists(defaultColumnConfig)
    this.setState({
      resetToggle: !this.state.resetToggle
    })
  }

  render() {
    const {
      classes,
    } = this.props
    const {
      resetToggle,
    } = this.state

    return (
      <Dialog
        className={classes.root}
        modal={undefined}
        onClick={e => e.stopPropagation()}
        onClose={this.closeDialog}
        open={this.props.show}
        scroll="paper"
      >
        <DialogTitle className={classes.dialogTitleWrapper}>
          <div className={classes.dialogTitle}>
            <div className={classes.TBDLogoWrapper}>
              <img
                alt="logo"
                className={classes.TBDLogoImg}
                src={miniLogo}/>
            </div>
            <div className={classes.dialogTitleText}>Column Configuration</div>
            <div className={classes.dialogTitleCloseBtnWrapper}>
              <Tooltip
                placement="top"
                title="Close">
                <Fab
                  aria-label="Close"
                  className={classes.dialogTitleCloseButton}
                  color="primary"
                  onClick={this.closeDialog}
                >
                  <CloseIcon className={classes.dialogTitleCloseIcon}/>
                </Fab>
              </Tooltip>
            </div>
          </div>
        </DialogTitle>
        <div
          className={classes.dialogContent}
        >
          <DnDListAssignSort
            accessor={'header'}
            assignedItems={this.visibleColumns}
            destListTitle={'Visible Columns'}
            onChange={({unassignedItems, assignedItems}) => {
              this.hiddenColumns = unassignedItems
              this.visibleColumns = assignedItems
            }}
            resetToggle={resetToggle}
            sourceListTitle={'Hidden Columns'}
            unassignedItems={this.hiddenColumns}
          />
          <div className={classes.buttons}>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                onClick={this.resetToDefault}
                size="small"
                variant="contained"
              >
                <ResetIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
                Reset To Default
              </Button>
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                onClick={this.undoChanges}
                size="small"
                variant="contained"
              >
                <ResetIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
                Undo Changes
              </Button>
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                onClick={() => this.saveUpdatedColConfig()}
                size="small"
                variant="contained"
              >
                <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

ColumnConfigDialog.propTypes = {
  show: PropTypes.bool.isRequired,
}

ColumnConfigDialog.defaultProps = {
  /**
   * Determines if the dialog is open
   */
  show: false,
  /**
   * A function which the ColumnConfigDialog can call
   * to close the dialog. i.e. set show == false
   */
  closeDialog: PropTypes.func.isRequired,
  /**
   * Column Configuration that will be changed here.
   * This is what undo changes will reset the configuration
   * to.
   */
  columnConfig: PropTypes.array.isRequired,
  /**
   * Default Column Configuration.
   * This is what reset to default will reset the configuration
   * to.
   */
  defaultColumnConfig: PropTypes.array.isRequired,
  /**
   * Function which will be called on update
   * of columnConfig
   */
  onUpdate: PropTypes.func.isRequired,
}

const StyledColumnConfigDialog = withStyles(styles)(ColumnConfigDialog)

export default StyledColumnConfigDialog
