import React from 'react'
import {processUnixDateForViewing} from 'utils/Utils'
import {Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, ReferenceLine} from 'recharts'
import {
  XAxisTickFormatter,
  roundScaleAndFormatNumber, calculateTooltipOffset
} from 'views/Home/Company/Dashboard/SharedGraphComponents'
import {CustomTooltipSection, CustomTooltipSectionLine, CustomTooltip} from '../RechartsCustomTooltip';
import {useTheme} from '@material-ui/core/styles';
import {Currency} from 'popcorn-js/financial/currency/currencyType';
import moment from 'moment';
import {
  Aggregation,
  GenerateWeekViewExposureReportForCurrencyResponse
} from 'popcorn-js/report/generatorTS';

interface Response {
  data: Array<Record<string, number | string>>,
}

const transformMonthViewData = (cashFlowResponse: GenerateWeekViewExposureReportForCurrencyResponse, dateFormat: string): Response => {

  let data: Array<Record<string, number | string>> = []

  if (!cashFlowResponse.aggregations) {
    return {
      data,
    }
  }

  data = cashFlowResponse.aggregations.map(function (p: Aggregation): Record<string, number | string>  {
    const dateInfo = p.categoryName.split('_')
    const date = moment(`${dateInfo[0]}-${dateInfo[1]}-01`, ).unix()
    return {
      name: processUnixDateForViewing(date, dateFormat),
      date: date,

      // For Tooltip:  Total purchase exposure
      purchaseExposureTotal: (p.invoiceTotalImport?.total || {}).currencyAmount,

      // For Tooltip:  Total sales exposure
      salesExposureTotal: (p.invoiceTotalExport.total || {}).currencyAmount,

      // Note: Currency breakdown no longer on graph neither in tooltip
      // For Graph: Import Invoice Overdue Series
      invoiceOverdueImport: p.invoiceOverdueImport.total.currencyAmount,
      // For Graph: Export Invoice Overdue Series
      invoiceOverdueExport: p.invoiceOverdueExport.total.currencyAmount,
      // For Graph: Import Invoice Due Series
      invoiceUnRealisedImport: p.invoiceUnRealisedImport.total.currencyAmount,
      // For Graph: Export Invoice Due Series
      invoiceUnRealisedExport: p.invoiceUnRealisedExport.total.currencyAmount,

      // For Tooltip and graph: Import trade open balances
      openTradeBalanceImport: p.openTradeBalanceImport,

      // For Tooltip and graph: Export trade open balances
      openTradeBalanceExport: p.openTradeBalanceExport,
    }
  })

  return {
    data,
  }
}

interface ExposureGraphProps {
  currency: Currency,
  data: GenerateWeekViewExposureReportForCurrencyResponse,
  width: number
}

export const ExposureGraph: React.FC<ExposureGraphProps> = (props: ExposureGraphProps) => {
  const dateFormat = 'MMM-YY'
  const theme = useTheme()
  const graphData = transformMonthViewData(props.data || {}, dateFormat)

  const TickFormatter = (tick: number): string => {
    // return (tick / scaleToNumber(scale)) //.toFixed(0)
    return roundScaleAndFormatNumber(tick || 0, currencyCode)
  }

  const currencyCode = props.currency ? props.currency.symbol : ''
  const tooltip: React.ReactElement = (
    <CustomTooltip
      currency={props.currency}
      valueFormatter={(val: number): string => {
        return roundScaleAndFormatNumber(val || 0, currencyCode)
      }}
    >
      <CustomTooltipSection
        heading={'Purchase Exposure'}
        hideIfZero
        style={{color: theme.palette.import.main}}
      >
        <CustomTooltipSectionLine
          dataKey={'purchaseExposureTotal'}
          heading={'Total'}/>
      </CustomTooltipSection>
      <CustomTooltipSection
        heading={'Sales Exposure'}
        hideIfZero
        style={{color: theme.palette.export.main}}
      >
        <CustomTooltipSectionLine
          dataKey={'salesExposureTotal'}
          heading={'Total'}/>
      </CustomTooltipSection>
      <CustomTooltipSection
        heading={'FEC Balances Maturing'}
        hideIfZero
        style={{color: theme.palette.data.graphC}}
      >
        <CustomTooltipSectionLine
          dataKey={'openTradeBalanceImport'}
          heading={'Purchase Hedges'}/>
        <CustomTooltipSectionLine
          dataKey={'openTradeBalanceExport'}
          heading={'Sales Hedges'}/>
      </CustomTooltipSection>
    </CustomTooltip>
  )

  const ImportStack = 'ImportStack'
  const ExportStack = 'ExportStack'

  return (
    <div>
      <BarChart
        barCategoryGap={'15%'}
        data={graphData.data || []}
        height={360}
        margin={{
          top: 5, right: 30, left: 35, bottom: 10,
        }}
        stackOffset={'sign'}
        width={props.width || 1000}
      >
        <Tooltip
          content={tooltip}
          cursor={{fill: '#1E2036'}}
          offset={calculateTooltipOffset(props.width, graphData?.data?.length)}
        />
        <CartesianGrid
          stroke={'#64659A'}
          vertical={false}/>
        <XAxis
          axisLine={false}
          dataKey="name"
          interval={'preserveStartEnd'}
          orientation={'top'}
          tick={
            <XAxisTickFormatter
              dateFormat={dateFormat}
              theme={theme}
            />
          }
          tickLine={false}
        />
        <YAxis
          allowDecimals
          axisLine={false}
          interval={0}
          tick={{fill: theme.palette.customColors.greyText1}}
          tickFormatter={TickFormatter}
          tickLine={false}
        />
        <Bar
          dataKey={'invoiceOverdueImport'}
          fill={theme.palette.data.graphB}
          stackId={ImportStack}
          stroke={theme.palette.data.graphB}
        />
        <Bar
          dataKey={'invoiceOverdueExport'}
          fill={theme.palette.data.graphB}
          stackId={ExportStack}
          stroke={theme.palette.data.graphB}
        />
        <Bar
          dataKey={'invoiceUnRealisedImport'}
          fill={theme.palette.import.main}
          stackId={ImportStack}
          stroke={theme.palette.import.main}
        />
        <Bar
          dataKey={'invoiceUnRealisedExport'}
          fill={theme.palette.export.main}
          stackId={ExportStack}
          stroke={theme.palette.export.main}
        />
        <Bar
          dataKey={'openTradeBalanceImport'}
          fill={theme.palette.data.graphC}
          stackId={ImportStack}
          stroke={theme.palette.data.graphC}
        />
        <Bar
          dataKey={'openTradeBalanceExport'}
          fill={theme.palette.data.graphC}
          stackId={ExportStack}
          stroke={theme.palette.data.graphC}
        />
        <ReferenceLine
          stroke={'#C6C6C6'}
          y={0}
        />
      </BarChart>
    </div>
  )
}
