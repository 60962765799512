import {jsonRpcRequestRaw} from '../../../actions/helper/utilities'
import config from 'react-global-configuration'
import {MarshalCriteria} from '../../search/criteria/Marshaller';
import {jsonRpcRequest} from '../../jsonRpc/jsonRpcReqest';
import {marshalIdentifier} from '../../search/identifier/marshalIdentifier';

const Handler = {
  Record(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Order-Handler.Record',
      request
    })
  },
  Find(findRequest) {
    const serializedCriteria = MarshalCriteria(findRequest.criteria)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Handler.Find',
      request: {
        ...findRequest,
        criteria: serializedCriteria
      },
    })
  },
  Update(updateRequest) {
    const serializedIdentifier = marshalIdentifier(updateRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Handler.Update',
      request: {
        ...updateRequest,
        identifier: serializedIdentifier
      },
    })
  },

}


export default Handler
