import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  withStyles, Button,
} from '@material-ui/core'
import {EXACT_CRITERION, TEXT_CRITERION} from 'popcorn-js/search/criteria/types'
import {isFunction} from 'utils/Utils'
import Select, {components} from 'react-select'
import {HexToRGBA} from '../../../../utils/Utils'

const styles = (theme) => ({
  blankFieldsButton: {
    color: theme.palette.text.primary,
    background: theme.palette.primary.main,
  },
})

const selectStyles = (theme) => ({
  valueContainer: styles => ({
    ...styles,
    border: 'none',
    padding: '0 4px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '6px',
  }),
  option: (styles, {isSelected, isFocused}) => ({
    ...styles,
    border: 'none',
    color: isSelected ? theme.palette.text.primary : theme.palette.primary.contrastText,
    backgroundColor: isSelected ? theme.palette.primary.light : isFocused ? HexToRGBA(theme.palette.primary.light, 0.5) :  theme.palette.primary.main,
  }),
  control: styles => ({
    ...styles,
    minHeight: '1px',
    border: 'none',
    padding: '0',
    borderRadius: '0',
    backgroundColor: 'none',
  }),
  input: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    color: theme.palette.text.primary,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
  }),
  singleValue: (styles, {data}) => {
    return ({
      ...styles,
      color: data.label === 'Blank Fields' || data.label === 'start typing...' ? theme.palette.text.secondary : theme.palette.text.primary,
      fontStyle: data.label === 'Blank Fields' || data.label === 'start typing...' ? 'italic' : ''
    })
  },
})


class TextOptions extends Component {
  constructor(props) {
    super(props)
    if (!props.initValue) {
      this.state = {
        selected: null,
        value: {
          type: TextOptions.CriteriaType,
          value: {
            field: props.fieldID,
            text: '',
          },
        }
      }
    } else {
      this.state = {
        selected: {value: {label: props.initValue.value.text, value: props.initValue.value.text}},
        value: props.initValue
      }
    }
  }

  static CriteriaType = TEXT_CRITERION

  onChange = (newTextVal) => {
    let {
      value,
    } = this.state
    const {
      onChange,
    } = this.props

    value = {
      ...value,
      value: {
        ...value.value,
        text: newTextVal,
      },
    }

    if (isFunction(onChange)) {
      if ((newTextVal === '')) {
        onChange(undefined)
      } else {
        onChange(value)
      }
    }

    this.setState({
      value,
      selected: newTextVal
    })
  }

  render() {
    const {
      filterConfig,
      theme,
      classes,
    } = this.props
    const {
      selected,
    } = this.state

    const MenuList = (props) => {
      return (
        <components.MenuList {...props}>
          <div
            onClick={() => {
              this.setState({
                selected: {value : {label: 'Blank Fields' , value: ''}},
                value:{
                  type: EXACT_CRITERION,
                  value: {
                    field: this.props.fieldID,
                    text: '',
                  },
                }
              })
              if (isFunction(this.props.onChange)) {
                if ((this.props.newTextVal === '')) {
                  this.props.onChange(undefined)
                } else {
                  this.props.onChange({
                    type: EXACT_CRITERION,
                    value: {
                      field: this.props.fieldID,
                      text: '',
                    },
                  })
                }
              }
            }}
            style={{
              cursor: 'pointer',
            }}>
            <Button
              className={classes.blankFieldsButton}
              size="small">
              Search Blank Fields
            </Button>
          </div>
          {props.children}
        </components.MenuList>
      )
    }
    const selectOptions = filterConfig.options.map(o => ({
      value: o[filterConfig.valueAccessor],
      label: o[filterConfig.displayAccessor],
    }))

    return <div
      style={{
        height: '100%',
        display: 'grid',
        justifyItems: 'center',
        gridTemplateRows: '1fr',
      }}>
      <div
        style={{
          alignSelf: 'center',
          width: '100%',
          minWidth: '160px'
        }}
      >
        <Select
          components={{MenuList}}
          isClearable
          menuPosition={'fixed'}
          onChange={selected => this.onChange(selected ? selected.value: '')}
          options={selectOptions}
          styles={selectStyles(theme)}
          value={selected ? selected.value : {value: '', label: 'start typing...'}}
        />
      </div>
    </div>
  }

}

const styledText = withStyles(styles)(TextOptions)

styledText.propTypes = {
  fieldID: PropTypes.string.isRequired,
  filterConfig: PropTypes.object,
  initValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
}

export default withStyles(styles, {withTheme: true})(TextOptions)
