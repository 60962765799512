import React, { useEffect } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  CardHeader,
  Dialog,
  FormControl,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { HexToRGBA } from 'utils/Utils';
import Card from '@material-ui/core/Card';
import { MdClear } from 'react-icons/md';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import {
  TextField as ParsedTextField,
  TextFieldParseTypes,
} from '../FormContols';
// eslint-disable-next-line
// @ts-ignore
import AsyncCreatableSelect from 'react-select/async-creatable/dist/react-select.esm';
import { ALL_INVOICE_STATUSES, ALL_INVOICE_TYPES } from 'constants/invoice';
import Select from 'react-select';
import { Recordkeeper } from 'popcorn-js/financial/invoice/recordkeeper';
import InvoiceHandler from 'popcorn-js/financial/invoice/handler';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  ExactCriterion,
  TextCriterion,
} from 'popcorn-js/search/criteria/types';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty';
import { ComponentLevelLoader } from 'components/Loader/Loader';
import { useService } from 'hooks/useService';
import {
  ActionButton,
  SecondaryActionButton,
} from 'views/Tickets/styledComponents';

const useStyles = makeStyles((theme) => ({
  dialog: {
    // eslint-disable-next-line
    // @ts-ignore
    backgroundColor: HexToRGBA(theme.palette.dialogBackdrop, 0.7),
  },
  root: {},
  stepContent: {
    margin: '10px',
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(),
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 17,
    marginRight: '0px',
    marginTop: '0px',
  },
  action: {
    color: 'red',
    marginRight: '0px',
    marginTop: '0px',
  },
  formActions: {
    marginTop: '20px',
    display: 'flex',
  },
  button: {
    flex: 1,
    margin: theme.spacing(),
  },
  buttonLabel: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  noButton: {
    flex: 1,
    margin: theme.spacing(),
  },

  container: {
    display: 'flex',
  },
  item: {
    flex: 1,
    margin: theme.spacing(),
    height: '56px',
    paddingTop: 0,
  },
  textField: {
    width: '100%',
    height: '100%',
    paddingTop: '6px',
  },
  select: {
    width: '100%',
    height: '100%',
    paddingTop: 0,
  },

  fieldUnderline: {
    '&:before': {
      borderBottom: `1px solid  ${HexToRGBA(theme.palette.common.white, 0.1)}`,
    },
    '&:after': {
      // eslint-disable-next-line
      // @ts-ignore
      borderBottom: `2px solid  ${theme.palette.highActions}`,
    },
  },
  inputLabel: {
    color: HexToRGBA(theme.palette.common.white, 0.5),
  },
  focusedInputLabel: {
    color: HexToRGBA(theme.palette.common.white, 0.5) + '!important',
  },
  selectLabel: {
    fontSize: 12,
    color: HexToRGBA(theme.palette.common.white, 0.5),
  },
  stepButtonRoot: {
    // eslint-disable-next-line
    // @ts-ignore
    color: theme.palette.highActions,
  },
  stepIconRoot: {
    color: HexToRGBA(theme.palette.common.white, 0.2),
    '&$active': {
      // eslint-disable-next-line
      // @ts-ignore
      color: theme.palette.highActions,
    },
    '&$completed': {
      color: '#1cc446',
    },
  },
  active: {},
  completed: {},
  error: {},

  stepIconText: {
    fill: theme.palette.background.paper,
    fontWeight: 'bolder',
  },
  label: {
    fontWeight: 'bolder',
    '&$active': {
      // eslint-disable-next-line
      // @ts-ignore
      color: theme.palette.highActions,
      fontWeight: 'bolder',
    },
    '&$completed': {
      color: '#1cc446',
      fontWeight: 'bolder',
    },
    '&$error': {
      color: '#ed4134',
      fontWeight: 'bolder',
    },
  },
  loadingLayout: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
}));

const selectStyles = (theme: Theme): any => ({
  valueContainer: (styles: any): any => ({
    ...styles,
    border: 'none',
    padding: '0 4px',
  }),
  dropdownIndicator: (styles: any): any => ({
    ...styles,
    padding: '6px',
  }),
  option: (styles: any, { isSelected }: { isSelected: boolean }): any => ({
    ...styles,
    border: 'none',
    backgroundColor: isSelected
      ? theme.palette.primary.light
      : theme.palette.primary.main,
  }),
  control: (styles: any, { menuIsOpen }: { menuIsOpen: boolean }): any => {
    return {
      ...styles,
      minHeight: '1px',
      padding: '0',
      borderRadius: '0',
      border: 'none',
      backgroundColor: 'none',
      // eslint-disable-next-line
      // @ts-ignore
      borderBottom: `1px solid ${
        menuIsOpen ? theme.palette.customText.label : '#4B4D5E'
      }`,
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
        // eslint-disable-next-line
        // @ts-ignore
        borderBottom: `1px solid ${
          menuIsOpen ? theme.palette.customText.label : 'white'
        }`,
      },
    };
  },
  menu: (styles: any): any => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
  }),
  singleValue: (styles: any, { data }: { data: any }): any => ({
    ...styles,
    color:
      data.label === 'start typing...'
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
    fontStyle: data.label === 'start typing...' ? 'italic' : '',
  }),
  input: (styles: any): any => ({
    ...styles,
    padding: '0',
    margin: '0',
    color: theme.palette.text.primary,
  }),
});

const statusOptions = ALL_INVOICE_STATUSES.map((status) => ({
  label: status,
  value: status,
}));

const typeOptions = ALL_INVOICE_TYPES.map((type) => ({
  label: type,
  value: type,
}));

function getSteps(): Array<string> {
  return ['Basic Details', 'Rate Information', 'Shipping Information'];
}

// eslint-disable-next-line no-undef
const fieldsInFormSteps: Record<string, Array<string>> = {
  0: ['number', 'counterpartyId', 'amountDue', 'status'],
  1: ['dueDate', 'currencyId', 'costCurrencyId', 'direction', 'costingRate'],
  2: ['shippingDate', 'issueDate', 'shipmentReference', 'externalReference'],
};

interface InvoiceFormNewProps {
  createInvoiceError: Function;
  createInvoiceSuccess: Function;
  hideInvoiceForm: Function;
  validateInvoiceError: Function;
  createCounterpartyError: Function;
  party: any;
  partyCode: string;
  currencies: Array<any>;
  showForm: boolean;
  type: string;
}

type importExportFlagType = 'Import' | 'Export';

const InvoiceFormNew: React.FC<InvoiceFormNewProps> = (
  props: InvoiceFormNewProps
) => {
  const { currencies } = props;

  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [invoice, setInvoice] = React.useState<any>({
    partyCode: props.partyCode,
    financialYear: 'CURRENT',
    type: props.type,
  });
  const [previouslyVisited, setPreviouslyVisited] = React.useState<any>(
    new Set()
  );
  const [formFieldErrors, setFormFieldErrors] = React.useState<any>({});
  const [stepsWithErrors, setStepsWithErrors] = React.useState<any>(new Set());
  const [stepsCompleted, setStepsCompleted] = React.useState<any>(new Set());
  const [selectedCounterparty, setSelectedCounterparty] = React.useState<any>(
    {}
  );

  const [uploading, setUploading] = React.useState<boolean>(false);

  const [
    {
      response: invoiceValidateResponse,
      loading: invoiceValidatingInProgress,
      error: invoiceValidateValidatingError,
    },
    setValidateInvoiceRequest,
  ] = useService(null, Recordkeeper.validate);

  const [
    {
      response: invoiceUploadResponse,
      loading: invoiceUploadInProgress,
      error: invoiceUploadError,
    },
    setUploadInvoiceRequest,
  ] = useService(null, InvoiceHandler.Record);

  const theme = useTheme();

  if (
    invoiceUploadResponse &&
    invoiceUploadResponse.invoice &&
    invoiceUploadResponse.invoice.id
  ) {
    props.createInvoiceSuccess();
    props.hideInvoiceForm();
  }

  if (invoiceUploadError && invoiceUploadError !== '' && uploading) {
    props.createInvoiceError(invoiceUploadError);
    props.hideInvoiceForm();
  }

  if (invoiceValidateValidatingError && invoiceValidateValidatingError.error) {
    props.validateInvoiceError(invoiceValidateValidatingError);
    props.hideInvoiceForm();
  }

  useEffect(() => {
    if (invoiceValidateResponse && invoiceValidateResponse.reasons) {
      if (invoiceValidateResponse.reasons.length === 0 && uploading) {
        setUploadInvoiceRequest({ invoice });
        setUploading(false);
      }

      const newFormFieldErrors: Map<string, string> = new Map<string, string>();
      const fieldsNotToIgnore: Array<any> = [];
      previouslyVisited.forEach((n: string) => {
        fieldsNotToIgnore.push(...fieldsInFormSteps[n]);
      });
      invoiceValidateResponse.reasons.forEach((reason: any) => {
        if (fieldsNotToIgnore.includes(reason.field)) {
          newFormFieldErrors.set(reason.field, reason.reason);
        }
      });
      setFormFieldErrors(newFormFieldErrors);

      const newStepsWithErrors = new Set();
      const newstepsCompleted = new Set();
      previouslyVisited.forEach((n: any) => {
        let hasError = false;
        fieldsInFormSteps[n].forEach((field) => {
          if (newFormFieldErrors.get(field) !== undefined) {
            hasError = true;
          }
        });
        if (hasError) {
          newStepsWithErrors.add(n);
        } else {
          newstepsCompleted.add(n);
        }
      });
      setStepsWithErrors(newStepsWithErrors);
      setStepsCompleted(newstepsCompleted);
    }
  }, [
    invoiceValidateResponse,
    invoice,
    setUploading,
    uploading,
    previouslyVisited,
    setUploadInvoiceRequest,
  ]);

  async function generateCounterpartyOptions(inputValue: any): Promise<any> {
    const criteria = [
      new TextCriterion({ text: inputValue, field: 'name' }),
      new ExactCriterion({ text: props.partyCode, field: 'partyCode' }),
    ];
    try {
      const findResponse = await CounterpartyRecordkeeper.find({
        criteria: criteria,
        query: undefined,
        Deleted: false,
      });
      return (findResponse.records || []).map((b: any) => ({
        value: b.id,
        label: b.name,
      }));
    } catch (e) {
      throw e.message || e;
    }
  }

  function updateInvoice(field: any, newValue: any): void {
    setInvoice({
      ...invoice,
      [field]: newValue,
    });
  }

  async function handleCounterpartyCreation(inputValue: any): Promise<any> {
    CounterpartyRecordkeeper.create({
      counterparty: {
        name: invoice.counterparty || inputValue,
        partyCode: props.partyCode,
      },
    })
      .then((result) => {
        setSelectedCounterparty({
          value: result.counterparty.id,
          label: result.counterparty.name,
        });
        updateInvoice('counterpartyId', result.counterparty.id);
      })
      .catch((error) => {
        try {
          props.createCounterpartyError(error);
        } catch (e) {
          console.error(
            `error calling createCounterpartyError notification action creator: ${e}`
          );
        }
        console.error(`error calling create counterparty: ${error}`);
      });
  }

  async function handleUpload(): Promise<void> {
    setPreviouslyVisited(() => {
      const previouslyVisitedNew = new Set(previouslyVisited.values());
      previouslyVisitedNew.add(activeStep);
      return previouslyVisitedNew;
    });
    setUploading(true);
    setValidateInvoiceRequest({ invoice });
  }

  function handleNext(): void {
    setPreviouslyVisited(() => {
      const previouslyVisitedNew = new Set(previouslyVisited.values());
      previouslyVisitedNew.add(activeStep);
      return previouslyVisitedNew;
    });
    setActiveStep((activeStep) => activeStep + 1);
    setValidateInvoiceRequest({ invoice });
  }

  function handleBack(): void {
    setPreviouslyVisited(() => {
      const previouslyVisitedNew = new Set(previouslyVisited.values());
      previouslyVisitedNew.add(activeStep);
      return previouslyVisitedNew;
    });
    setUploading(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setValidateInvoiceRequest({ invoice });
  }

  const handleStep = (step: any) => (): void => {
    setPreviouslyVisited(() => {
      const previouslyVisitedNew = new Set(previouslyVisited.values());
      previouslyVisitedNew.add(activeStep);
      return previouslyVisitedNew;
    });
    setUploading(false);
    setActiveStep(step);
    setValidateInvoiceRequest({ invoice });
  };

  const inputProps = {
    classes: {
      underline: classes.fieldUnderline,
    },
    inputProps: {
      style: {
        width: '272px',
      },
    },
  };

  const inputLabelProps = {
    classes: {
      focused: classes.focusedInputLabel,
      root: classes.inputLabel,
    },
    shrink: true,
  };

  return (
    <Dialog
      BackdropProps={{ classes: { root: classes.dialog } }}
      fullWidth
      open={props.showForm}
      scroll="paper"
    >
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'hidden',
          },
        }}
        open={invoiceUploadInProgress}
      >
        <div className={classes.loadingLayout}>
          <ComponentLevelLoader
            color={'white'}
            isLoading={invoiceUploadInProgress}
          />
        </div>
      </Dialog>
      <Card className={classes.root}>
        <CardHeader
          action={
            <Tooltip
              key={'close'}
              title={'Close'}>
              <IconButton onClick={(): void => props.hideInvoiceForm()}>
                <MdClear />
              </IconButton>
            </Tooltip>
          }
          classes={{
            root: classes.cardHeader,
            action: classes.action,
            title: classes.cardTitle,
          }}
          title={'Create a new invoice'}
        />
        <div>
          <Stepper
            activeStep={activeStep}
            nonLinear>
            {steps.map((label, index) => {
              const stepProps: any = {};
              const buttonProps = {};
              const labelProps: any = {};
              stepProps.completed =
                index !== activeStep && stepsCompleted.has(index);
              labelProps.error =
                index !== activeStep && stepsWithErrors.has(index);
              return (
                <Step
                  key={label}
                  {...stepProps}>
                  <StepButton
                    classes={{
                      root: classes.stepButtonRoot,
                    }}
                    onClick={handleStep(index)}
                    {...buttonProps}
                  >
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: classes.active,
                          completed: classes.completed,
                          root: classes.stepIconRoot,
                          text: classes.stepIconText,
                        },
                      }}
                      classes={{
                        active: classes.active,
                        completed: classes.completed,
                        label: classes.label,
                        error: classes.error,
                      }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <div className={classes.stepContent}>
            {activeStep === 0 && (
              <Grid
                container
                direction={'column'}
                spacing={1}>
                <Grid item>
                  <div className={classes.container}>
                    <div className={classes.item}>
                      <FormControl
                        aria-describedby="type"
                        className={classes.select}
                        error={!!formFieldErrors['type']}
                        id="typeFormControlID"
                      >
                        <sup className={classes.selectLabel}>Type</sup>
                        <Select
                          menuPosition={'fixed'}
                          onChange={(selected: any): void => {
                            updateInvoice('type', selected.value);
                          }}
                          options={typeOptions}
                          styles={selectStyles(theme)}
                          value={{
                            label: invoice.type,
                            value: invoice.type,
                          }}
                        />
                        {!!formFieldErrors['type'] && (
                          <FormHelperText id="type">
                            {formFieldErrors['type']}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className={classes.item}>
                      <FormControl
                        aria-describedby="counterpartyId"
                        className={classes.select}
                        error={!!formFieldErrors['counterpartyId']}
                      >
                        <sup className={classes.selectLabel}>Counterparty</sup>
                        <AsyncCreatableSelect
                          id="counterpartyId"
                          loadOptions={generateCounterpartyOptions}
                          menuPosition={'fixed'}
                          onChange={(selected: any): void => {
                            setSelectedCounterparty(selected);
                            updateInvoice('counterpartyId', selected.value);
                          }}
                          onCreateOption={handleCounterpartyCreation}
                          placeholder={'Start Typing...'}
                          styles={selectStyles(theme)}
                          value={{
                            value: invoice.counterpartyId,
                            label: selectedCounterparty
                              ? selectedCounterparty.label
                              : '-',
                          }}
                        />

                        {!!formFieldErrors['counterpartyId'] && (
                          <FormHelperText id="counterpartyId">
                            {formFieldErrors['counterpartyId']}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.container}>
                    <div className={classes.item}>
                      <ParsedTextField
                        InputLabelProps={inputLabelProps}
                        InputProps={inputProps}
                        className={classes.textField}
                        error={!!formFieldErrors['number']}
                        id="number"
                        label="Number"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => updateInvoice('number', e.target.value)}
                        parseType={TextFieldParseTypes.string}
                        value={invoice.number}
                      />
                    </div>
                    <div className={classes.item}>
                      <FormControl
                        aria-describedby="status"
                        className={classes.select}
                        error={!!formFieldErrors['status']}
                        id="statusFormControlID"
                      >
                        <sup className={classes.selectLabel}>Status</sup>
                        <Select
                          menuPosition={'fixed'}
                          onChange={(selected: any): void => {
                            updateInvoice('status', selected.value);
                          }}
                          options={statusOptions}
                          styles={selectStyles(theme)}
                          value={{
                            label: invoice.status,
                            value: invoice.status,
                          }}
                        />
                        {!!formFieldErrors['status'] && (
                          <FormHelperText id="status">
                            {formFieldErrors['status']}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.container}>
                    <div className={classes.item}>
                      <ParsedTextField
                        InputLabelProps={inputLabelProps}
                        InputProps={{
                          ...inputProps,
                        }}
                        className={classes.textField}
                        error={!!formFieldErrors['amountDue']}
                        helperText={formFieldErrors['amountDue']}
                        id="amountDue"
                        label="Amount Due"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => updateInvoice('amountDue', e.target.value)}
                        parseType={TextFieldParseTypes.float}
                        value={invoice.amountDue || 0}
                      />
                    </div>
                    <div className={classes.item} />
                  </div>
                </Grid>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid
                container
                direction={'column'}
                spacing={1}>
                <Grid item>
                  <div className={classes.container}>
                    <div className={classes.item}>
                      <FormControl
                        aria-describedby="currencyId"
                        className={classes.select}
                        error={!!formFieldErrors['currencyId']}
                        id="currencyIdForm"
                      >
                        <sup className={classes.selectLabel}>Currency</sup>
                        <Select
                          menuPosition={'fixed'}
                          onChange={(selected: any): void =>
                            updateInvoice('currencyId', selected.value)
                          }
                          options={currencies.map((c: any) => ({
                            value: c.id,
                            label: c.isoCode,
                          }))}
                          styles={selectStyles(theme)}
                          value={{
                            value: invoice.currencyId,
                            label:
                              (
                                currencies.find(
                                  (c: any) => c.id === invoice.currencyId
                                ) || {}
                              ).isoCode || '',
                          }}
                        />
                        {!!formFieldErrors['currencyId'] && (
                          <FormHelperText id="currencyId">
                            {formFieldErrors['currencyId']}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className={classes.item}>
                      <ParsedTextField
                        InputLabelProps={inputLabelProps}
                        InputProps={{
                          ...inputProps,
                        }}
                        className={classes.textField}
                        error={!!formFieldErrors['costingRate']}
                        helperText={formFieldErrors['costingRate']}
                        id="costingRate"
                        label="Costing Rate"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => updateInvoice('costingRate', e.target.value)}
                        parseType={TextFieldParseTypes.float}
                        value={invoice.costingRate}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.container}>
                    <div className={classes.item}>
                      <FormControl
                        aria-describedby="costCurrencyId"
                        className={classes.select}
                        error={!!formFieldErrors['costCurrencyId']}
                        id="costCurrencyIdForm,"
                      >
                        <sup className={classes.selectLabel}>Cost Currency</sup>
                        <Select
                          menuPosition={'fixed'}
                          onChange={(selected: any): void =>
                            updateInvoice('costCurrencyId', selected.value)
                          }
                          options={currencies.map((c: any) => ({
                            value: c.id,
                            label: c.isoCode,
                          }))}
                          styles={selectStyles(theme)}
                          value={{
                            value: invoice.costCurrencyId,
                            label:
                              (
                                currencies.find(
                                  (c: any) => c.id === invoice.costCurrencyId
                                ) || {}
                              ).isoCode || '',
                          }}
                        />
                        {!!formFieldErrors['costCurrencyId'] && (
                          <FormHelperText id="costCurrencyId">
                            {formFieldErrors['costCurrencyId']}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className={classes.item}>
                      <ParsedTextField
                        InputLabelProps={inputLabelProps}
                        InputProps={{
                          ...inputProps,
                          inputProps: {
                            max: '9999-12-31',
                          },
                        }}
                        className={classes.textField}
                        error={!!formFieldErrors['dueDate']}
                        helperText={formFieldErrors['dueDate']}
                        id="dueDate"
                        label="Due Date"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => updateInvoice('dueDate', e.target.value)}
                        parseType={TextFieldParseTypes.date}
                        type={'date'}
                        value={invoice.dueDate}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {activeStep === 2 && (
              <Grid
                container
                direction={'column'}
                spacing={1}>
                <Grid item>
                  <div className={classes.container}>
                    <div className={classes.item}>
                      <ParsedTextField
                        InputLabelProps={inputLabelProps}
                        InputProps={{
                          ...inputProps,
                          inputProps: {
                            max: '9999-12-31',
                          },
                        }}
                        className={classes.textField}
                        error={!!formFieldErrors['shippingDate']}
                        helperText={formFieldErrors['shippingDate']}
                        id="shippingDate"
                        label="Shipping Date"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          updateInvoice('shippingDate', e.target.value)
                        }
                        parseType={TextFieldParseTypes.date}
                        type={'date'}
                        value={invoice.shippingDate}
                      />
                    </div>
                    <div className={classes.item}>
                      <ParsedTextField
                        InputLabelProps={inputLabelProps}
                        InputProps={inputProps}
                        className={classes.textField}
                        error={!!formFieldErrors['shipmentReference']}
                        id="shipmentReference"
                        label="Shipment Reference"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          updateInvoice('shipmentReference', e.target.value)
                        }
                        parseType={TextFieldParseTypes.string}
                        value={invoice.shipmentReference}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.container}>
                    <div className={classes.item}>
                      <ParsedTextField
                        InputLabelProps={inputLabelProps}
                        InputProps={{
                          ...inputProps,
                          inputProps: {
                            max: '9999-12-31',
                          },
                        }}
                        className={classes.textField}
                        error={!!formFieldErrors['issueDate']}
                        helperText={formFieldErrors['issueDate']}
                        id="issueDate"
                        label="Issue Date"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => updateInvoice('issueDate', e.target.value)}
                        parseType={TextFieldParseTypes.date}
                        type={'date'}
                        value={invoice.issueDate}
                      />
                    </div>
                    <div className={classes.item}>
                      <ParsedTextField
                        InputLabelProps={inputLabelProps}
                        InputProps={inputProps}
                        className={classes.textField}
                        error={!!formFieldErrors['externalReference']}
                        id="externalReference"
                        label="External Reference"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void =>
                          updateInvoice('externalReference', e.target.value)
                        }
                        parseType={TextFieldParseTypes.string}
                        value={invoice.externalReference}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            <div className={classes.formActions}>
              <ActionButton
                classes={{
                  root: classes.button,
                }}
                disabled={
                  (activeStep === steps.length - 1 &&
                    invoiceValidatingInProgress) ||
                  Object.keys(formFieldErrors).length > 0
                }
                onClick={
                  activeStep === steps.length - 1 ? handleUpload : handleNext
                }
              >
                {activeStep === steps.length - 1 ? 'UPLOAD' : 'NEXT STEP'}
              </ActionButton>
              {activeStep !== 0 ? (
                <SecondaryActionButton
                  classes={{
                    root: classes.button,
                  }}
                  onClick={handleBack}
                >
                  GO BACK
                </SecondaryActionButton>
              ) : (
                <div className={classes.noButton} />
              )}
            </div>
          </div>
        </div>
      </Card>
    </Dialog>
  );
};

export default InvoiceFormNew;
