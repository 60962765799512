import React from 'react'
import styles from './warning.module.scss'

export default function WarningIcon() {
  return (
    <div className={styles.icon}>
      <span className={styles.warning_body}>
        <span className={styles.warning_dot}/>
      </span>
    </div>
  )
}
