import {marshalIdentifier} from '../../marshallers';
import {MarshalCriteria as marshallCriteria} from 'popcorn-js/search/criteria/Marshaller'
import {jsonRpcRequest} from '../../jsonRpc/jsonRpcReqest';

import {config} from '../../index'

/**
 * CurrencyRecordkeeper is a convenience client for invoking the backend services
 */


export const Recordkeeper = {
  retrieve(retrieveRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Currency-Recordkeeper.Retrieve',
      request: {
        ...retrieveRequest,
        identifier: serializedIdentifier
      },
    })
  },

  delete(deleteRequest) {
    const serializedIdentifier = marshalIdentifier(deleteRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Currency-Recordkeeper.Delete',
      request: {
        ...deleteRequest,
        identifier: serializedIdentifier
      },
    })
  },

  create(createRequest) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Currency-Recordkeeper.Create',
      request: {...createRequest},
    })
  },

  update(updateRequest) {
    const serializedIdentifier = marshalIdentifier(updateRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Currency-Recordkeeper.Update',
      request: {
        ...updateRequest,
        identifier: serializedIdentifier
      },
    })
  },

  find(findRequest) {
    const serializedCriteria = marshallCriteria(findRequest.criteria)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Currency-Recordkeeper.Find',
      request: {
        ...findRequest,
        criteria: serializedCriteria
      },
    })
  },

  restore(restoreRequest) {
    const serializedIdentifier = marshalIdentifier(restoreRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Currency-Recordkeeper.Restore',
      request: {
        ...restoreRequest,
        identifier: serializedIdentifier
      },
    })
  },

  deleteForever(deleteForeverRequest) {
    const serializedIdentifier = marshalIdentifier(deleteForeverRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Currency-Recordkeeper.DeleteForever',
      request: {
        ...deleteForeverRequest,
        identifier: serializedIdentifier
      },
    })
  },
  retrieveHistory(retrieveHistoryRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveHistoryRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Currency-Recordkeeper.RetrieveHistory',
      request: {
        ...retrieveHistoryRequest,
        identifier: serializedIdentifier
      },
    })
  }

}
