import EntryComparator from 'popcorn-js/audit/EntryComparator';
import { isArray } from 'utils/Utils';

const Comparator = {
  /**
   * @param {SettlementInstructionType} a
   * @param {SettlementInstructionType} b
   * @return {boolean} True if a and b are the same
   */
  CompareAll(a, b) {
    if (a.id !== b.id) {
      return false;
    }
    if (a.date !== b.date) {
      return false;
    }
    if (a.currencyId !== b.currencyId) {
      return false;
    }
    if (a.partyCode !== b.partyCode) {
      return false;
    }
    if (a.status !== b.status) {
      return false;
    }
    if (a.processingOrgPartyCode !== b.processingOrgPartyCode) {
      return false;
    }
    if (a.financialYear !== b.financialYear) {
      return false;
    }

    if (
      !Comparator.CompareInvoiceAmountsRequested(
        a.invoiceAmountsRequested,
        b.invoiceAmountsRequested
      )
    ) {
      return false;
    }
    if (
      !Comparator.CompareInvoiceTradeLinks(
        a.invoiceTradeLinks,
        b.invoiceTradeLinks
      )
    ) {
      return false;
    }
    if (
      !Comparator.ComparePrepaymentAmountsRequested(
        a.prepaymentAmountsRequested,
        b.prepaymentAmountsRequested
      )
    ) {
      return false;
    }
    if (
      !Comparator.ComparePrepaymentTradeLinks(
        a.prepaymentTradeLinks,
        b.prepaymentTradeLinks
      )
    ) {
      return false;
    }
    if (!Comparator.CompareCustomEntries(a.customEntries, b.customEntries)) {
      return false;
    }

    return EntryComparator.CompareAll(a.auditEntry, b.auditEntry);
  },

  CompareInvoiceAmountsRequested(a, b) {
    if (!isArray(a)) {
      throw new TypeError(
        'InvoiceAmountRequestedType array a passed to CompareInvoiceAmountsRequested is not an array'
      );
    }
    if (!isArray(b)) {
      throw new TypeError(
        'InvoiceAmountRequestedType array b passed to CompareInvoiceAmountsRequested is not an array'
      );
    }

    if (a.length !== b.length) {
      return false;
    }

    // look for every amount requested in a in b
    nextAmountRequestedA: for (const invoiceAmountRequestedA of a) {
      // for every invoice amount requested in a
      for (const invoiceAmountRequestedB of b) {
        if (
          invoiceAmountRequestedA.amount === invoiceAmountRequestedB.amount &&
          invoiceAmountRequestedA.invoiceId ===
            invoiceAmountRequestedB.invoiceId
        ) {
          // if it is found in b then go to the next one in a
          continue nextAmountRequestedA;
        }
      }
      // if execution reaches here then invoiceAmountRequestedA was not found in b
      return false;
    }

    // if execution reaches here then the every amount in a was found in b
    return true;
  },

  CompareInvoiceTradeLinks(a, b) {
    if (!isArray(a)) {
      throw new TypeError(
        'InvoiceTradeLinkType array a passed to CompareInvoiceAmountsRequested is not an array'
      );
    }
    if (!isArray(b)) {
      throw new TypeError(
        'InvoiceTradeLinkType array b passed to CompareInvoiceAmountsRequested is not an array'
      );
    }

    if (a.length !== b.length) {
      return false;
    }

    // look for every invoice trade link in a, in b
    nextInvoiceTradeLinkA: for (const invoiceTradeLinkA of a) {
      // for every invoice trade link in a
      for (const invoiceTradeLinkB of b) {
        if (
          invoiceTradeLinkA.invoiceId === invoiceTradeLinkB.invoiceId &&
          invoiceTradeLinkA.tradeId === invoiceTradeLinkB.tradeId &&
          invoiceTradeLinkA.amount === invoiceTradeLinkB.amount
        ) {
          // if it is found in b, then go to the next one in a
          continue nextInvoiceTradeLinkA;
        }
      }
      // if execution reaches here then invoiceTradeLinkA was not found in b
      return false;
    }

    // if execution reaches here then every invoiceTradeLinkA was found in b
    return true;
  },

  ComparePrepaymentAmountsRequested(a, b) {
    if (!isArray(a)) {
      throw new TypeError(
        'PrepaymentAmountRequested array a passed to ComparePrepaymentAmountsRequested is not an array'
      );
    }
    if (!isArray(b)) {
      throw new TypeError(
        'PrepaymentAmountRequested array b passed to ComparePrepaymentAmountsRequested is not an array'
      );
    }

    if (a.length !== b.length) {
      return false;
    }

    // look for every prepayment amount requested in a, in b
    nextPrepaymentAmountRequestedA: for (const prepaymentAmountRequestedA of a) {
      // for every prepayment amount requested in a
      for (const prepaymentAmountRequestedB of b) {
        if (
          prepaymentAmountRequestedA.amount ===
            prepaymentAmountRequestedB.amount &&
          prepaymentAmountRequestedA.prepaymentId ===
            prepaymentAmountRequestedB.prepaymentId
        ) {
          // if it is found in b, then go to the next one in a
          continue nextPrepaymentAmountRequestedA;
        }
      }
      // if execution reaches here then nextPrepaymentAmountRequestedA was not found in b
      return false;
    }
    // if execution reaches here then nextPrepaymentAmountRequestedA was found in b
    return true;
  },

  ComparePrepaymentTradeLinks(a, b) {
    if (!isArray(a)) {
      throw new TypeError(
        'PrepaymentTradeLink array a passed to ComparePrepaymentTradeLinks is not an array'
      );
    }
    if (!isArray(b)) {
      throw new TypeError(
        'PrepaymentTradeLink array b passed to ComparePrepaymentTradeLinks is not an array'
      );
    }

    if (a.length !== b.length) {
      return false;
    }

    // look for every prepaymentTradeLink in a, in b
    nextPrepaymentTradeLinkA: for (const prepaymentTradeLinkA of a) {
      // for every prepaymentTradeLink in a
      for (const prepaymentTradeLinkB of b) {
        if (
          prepaymentTradeLinkA.prepaymentId ===
            prepaymentTradeLinkB.prepaymentId &&
          prepaymentTradeLinkA.tradeId === prepaymentTradeLinkB.tradeId &&
          prepaymentTradeLinkA.amount === prepaymentTradeLinkB.amount
        ) {
          // if it is found in b, then go to the next one in a
          continue nextPrepaymentTradeLinkA;
        }
      }
      // if execution reaches here then prepaymentTradeLinkA was not found in b
      return false;
    }
    return true;
  },

  CompareCustomEntries(a, b) {
    if (!isArray(a)) {
      throw new TypeError(
        'CustomEntryType array a passed to CompareCustomEntries is not an array'
      );
    }
    if (!isArray(b)) {
      throw new TypeError(
        'CustomEntryType array b passed to CompareCustomEntries is not an array'
      );
    }

    if (a.length !== b.length) {
      return false;
    }

    // look for every custom entry in a in b
    nextCustomEntryA: for (const customEntryA of a) {
      // for every custom entry in a
      for (const customEntryB of b) {
        if (
          customEntryA.counterpartyId === customEntryB.counterpartyId &&
          customEntryA.amount === customEntryB.amount &&
          customEntryA.description === customEntryB.description &&
          Comparator.CompareCustomEntryTradeLinks(
            customEntryA.tradeLinks,
            customEntryB.tradeLinks
          )
        ) {
          // if it is found in b then go to the next one in a
          continue nextCustomEntryA;
        }
      }
      // if execution reaches here then customEntryA was not found in B
      return false;
    }

    // if execution reaches here then the every amount in a was found in b
    return true;
  },

  CompareCustomEntryTradeLinks(a, b) {
    if (!isArray(a)) {
      throw new TypeError(
        'CustomEntryTradeLinkType array a passed to CompareCustomEntries is not an array'
      );
    }
    if (!isArray(b)) {
      throw new TypeError(
        'CustomEntryTradeLinkType array b passed to CompareCustomEntries is not an array'
      );
    }

    if (a.length !== b.length) {
      return false;
    }

    // look for every trade link in a in b
    nextTradeLinkA: for (const tradeLinkA of a) {
      // for every trade link in a
      for (const tradeLinkB of b) {
        if (
          tradeLinkA.amount === tradeLinkB.amount &&
          tradeLinkA.tradeId === tradeLinkB.tradeId
        ) {
          // if it is found in b then go to the next one in a
          continue nextTradeLinkA;
        }
      }
      // if execution reaches here then tradeLinkA was not found in b
      return false;
    }

    // if execution reaches here then the every amount in a was found in b
    return true;
  },
};

export default Comparator;
