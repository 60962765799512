import {
  CLEAR_PROTECTED_ROLE,
  RESET_PROTECTED_ROLE_SUCCESS,
  RETRIEVE_PROTECTED_ROLE_SUCCESS,
  UPDATE_PROTECTED_ROLE_SUCCESS,
} from 'actions/actionTypes'

export default function role(state = {}, action) {
  switch (action.type) {
    case RETRIEVE_PROTECTED_ROLE_SUCCESS:
    case RESET_PROTECTED_ROLE_SUCCESS:
    case UPDATE_PROTECTED_ROLE_SUCCESS:
      if (action.result.role.name === 'admin') {
        return {...state, adminRole: action.result.role}
      }
      if (action.result.role.name === 'context') {
        return {...state, contextRole: action.result.role}
      }
      return state
    case CLEAR_PROTECTED_ROLE:
      if (state.role || state.adminRole || state.contextRole) {
        return {...state, adminRole: {}, contextRole: undefined}
      }
      return state
    default:
      return state
  }
}
