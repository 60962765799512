// Components
import HomeContainer from 'views/Home/HomeContainer'
import PricingView from 'views/Pricing/PricingView'
import ProcessingOrgContainer from 'views/LegalEntities/ProcessingOrg/ProcessingOrgContainer'
import ProcessingBankContainer from 'views/LegalEntities/ProcessingBank/ProcessingBankContainer'
import CompanyContainer from 'views/LegalEntities/Company/CompanyContainer'
import CounterpartyStation from 'views/CounterpartyStation/CounterpartyStation'
import OrderStationContainer from 'views/OrderStation/OrderStationContainer'
import LegsAsTradesTable from 'views/TradeStation/LegsAsTradesTable'
import SettlementInstructionWorkstationContainer from 'views/SettlementInstruction/Workstation/WorkstationContainer'
import SettlementInstructionManagementContainer
  from 'views/SettlementInstruction/Management/SettlementInstructionContainer'
// Icons
import {FiSettings,} from 'react-icons/fi'
import DescriptionIcon from '@material-ui/icons/Description';
import RateReviewIcon from '@material-ui/icons/RateReview'
//routes
import configurationRoutes from './configuration'
// permissions
import {
  viewCounterpartyStation,
  viewConfiguration,
  viewLegalEntities,
  viewLegalEntitiesCompany,
  viewLegalEntitiesProcessingBank,
  viewLegalEntitiesProcessingOrg,
  viewSettlementInstruction,
  viewSettlementInstructionManagement,
  viewSettlementInstructionsV2,
  viewTradeStation,
  viewReports
} from 'permissions/permissionTypes'

import InvoiceStationContainer from 'views/Invoices/InvoiceStation/InvoiceStationContainer'
import {DashboardIcon} from 'components/Icons/DashboardIcon'
import {DealingIcon} from 'components/Icons/DealingIcon';
import {CounterpartiesIcon} from 'components/Icons/CounterpartiesIcon';
import {InvoicesIcon} from 'components/Icons/InvoicesIcon';
import {SettlementInstructionIcon} from 'components/Icons/SettlementInstructionIcon';
import {OrderIcon} from 'components/Icons/OrderIcon';
import {LegalEntitiesIcon} from 'components/Icons/LegalEntitiesIcon';
import {TradeStationIcon} from 'components/Icons/TradeStationIcon';
import {
  viewDealingStation,
  viewOperations,
  viewOperationsInvoiceStation,
  viewOperationsOrderStation,
  viewOperationsTradeStation, viewOpsStation,
} from '../permissions/permissionTypes';
import DealingStation from '../views/DealingStation/DealingStation';
import SettlementInstructionsV2 from 'views/SettlementInstruction/SettlementInstructionsV2'
import {Reports} from 'views/Reports'
import OperationsManagement from 'views/OperationsManagement/OperationsManagement';

const appRoutes = [
// TODO temporary
  {
    path: '/app/reports',
    name: 'Reports',
    icon: DealingIcon,
    component: Reports,
    permission: 'Hide',
    linkID: 'Reports',
  },
  {
    path: '/app/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: HomeContainer,
    linkID: 'sidebarCompanyHomeDashboardLink',
  },
  {
    path: '/app/FECDealingStation',
    name: 'FEC Management Station ',
    icon: DealingIcon,
    component: DealingStation,
    permission: viewDealingStation,
    linkID: 'sidebarDealingLink',
  },
  {
    path: '/app/settlementInstruction',
    name: 'Settlement Instructions',
    icon: SettlementInstructionIcon,
    component: SettlementInstructionWorkstationContainer,
    permission: viewSettlementInstruction,
    linkID: 'sidebarSettlementInstructionLink',
  },
  {
    path: '/app/settlementInstructionsV2',
    name: 'Settlement Instructions V2',
    icon: SettlementInstructionIcon,
    component: SettlementInstructionsV2,
    permission: viewSettlementInstructionsV2,
    linkID: 'sidebarSettlementInstructionsV2Link',
  },
  {
    path: '/app/manageSettlementInstruction',
    name: 'Settlement Instructions',
    icon: SettlementInstructionIcon,
    component: SettlementInstructionManagementContainer,
    permission: viewSettlementInstructionManagement,
    linkID: 'sidebarSettlementInstructionManagementLink',
  },

  {
    path: '/app/tradeStation',
    name: 'Trades',
    mini: 'T',
    icon: TradeStationIcon,
    component: LegsAsTradesTable,
    permission: viewTradeStation,
    linkID: 'sidebarTradeStationLink',
  },
  {
    collapse: true,
    name: 'Operations',
    state: 'openOperations',
    icon: LegalEntitiesIcon,
    permission: viewOperations,
    views: [
      {
        path: '/app/counterparties',
        name: 'Counterparties',
        mini: 'C',
        icon: CounterpartiesIcon,
        component: CounterpartyStation,
        permission: viewCounterpartyStation,
        linkID: 'sidebarCounterpartyStationLink',
      },
      {
        path: '/app/orders',
        name: 'Orders',
        mini: 'O',
        icon: OrderIcon,
        component: OrderStationContainer,
        permission: viewOperationsOrderStation,
        linkID: 'sidebarOrderStation',
      },
      {
        path: '/app/invoices',
        name: 'Invoices',
        mini: 'I',
        icon: InvoicesIcon,
        component: InvoiceStationContainer,
        permission: viewOperationsInvoiceStation,
        linkID: 'sidebarInvoiceStationLink',
      },
      {
        path: '/app/tradeStation',
        name: 'Trades',
        mini: 'T',
        icon: TradeStationIcon,
        component: LegsAsTradesTable,
        permission: viewOperationsTradeStation,
        linkID: 'sidebarTradeStationLink',
      },
    ],
    linkID: 'operationsMenuOpen',
  },
  {
    collapse: true,
    path: '/app/configuration',
    name: 'Configuration',
    // state: "openPages",
    icon: FiSettings,
    views: configurationRoutes,
    permission: viewConfiguration,
    linkID: 'sidebarConfigurationMenuOpen',
  },
  {
    collapse: true,
    name: 'Legal Entities',
    state: 'openLegalEntities',
    icon: LegalEntitiesIcon,
    permission: viewLegalEntities,
    views: [
      {
        path: '/app/legalEntities/processingBank',
        name: 'Processing Bank',
        mini: 'PB',
        component: ProcessingBankContainer,
        permission: viewLegalEntitiesProcessingBank,
        linkID: 'sidebarLegalEntitiesProcessingBankLink',
      },
      {
        path: '/app/legalEntities/processingOrg',
        name: 'Processing Organization',
        mini: 'PO',
        component: ProcessingOrgContainer,
        permission: viewLegalEntitiesProcessingOrg,
        linkID: 'sidebarLegalEntitiesProcessingOrgLink',
      },
      {
        path: '/app/legalEntities/company',
        name: 'Company',
        mini: 'C',
        component: CompanyContainer,
        permission: viewLegalEntitiesCompany,
        linkID: 'sidebarLegalEntitiesCompanyLink',
      },
    ],
    linkID: 'legalEntitiesMenuOpen',
  },
  {
    path: '/app/pricing',
    name: 'Pricing',
    icon: DealingIcon,
    component: PricingView,
    permission: viewLegalEntities,
    linkID: 'sidebarPricing',
  },
  {
    path: '/app/reports',
    name: 'Reports',
    icon: DescriptionIcon,
    component: Reports,
    permission: viewReports,
    linkID: 'sidebarReportsLink',
  },
  {
    path: '/app/opsStation',
    name: 'Ops Management',
    icon: RateReviewIcon,
    component: OperationsManagement,
    permission: viewOpsStation,
    linkID: 'sidebarOpsStationLink',
  },
  {
    redirect: true,
    exact: false,
    path: '/app',
    pathTo: '/app/dashboard',
    name: 'Dashboard',
  },
]

export default appRoutes
