import uuidv1 from 'uuid/v1'
import {toast} from 'react-toastify'

export function jsonRpcRequest({url, method, request, onStart, onSuccess, onError, accessToken}) {
  onStart()
  const id = uuidv1()
  const header = new Headers({
    'Content-Type': 'application/json',
  })

  if (accessToken) {
    header.append('Authorization', accessToken)
  }

  const body = JSON.stringify({
    jsonrpc: '2.0',
    method: method,
    params: [request],
    id: id,
  })
  console.debug(method + ' jsonRpcRequest.body: ', JSON.parse(body))
  // toast.info(method)
  return fetch(
    url, {
      method: 'POST',
      headers: header,
      mode: 'cors',
      body: body,
      credentials: 'include',
    },
  ).then(responseObject => {
    return responseObject.json()
  }).then(response => {
    console.debug(method + ' jsonRpcRequest.response: ', response)
    if (response.result) {
      console.log(method + ' - success', response.result)
      onSuccess(response.result)
    } else {
      console.error(method + ' - error: ', response.error)
      if (response.error.message) {
        onError(response.error.message)
        toast.error(response.error.message)
      } else {
        onError(response.error)
      }
    }
  }).catch(error => {
    console.error(method + ' jsonRpcRequest.error: ', error)
    onError('Unknown error for - ' + method)
  })
}

export function jsonRpcRequestRaw({url, method, request, accessToken}) {
  const id = uuidv1()
  const header = new Headers({
    //    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  })

  console.log('method ', method)

  // We don't need this if we use cookies
  //  if (!methodsWithoutAuthorization.includes(method)) {
  //    if (accessToken == null) {
  //      accessToken = localStorage.getItem('access_token')
  //    }
  if (accessToken) {
    header.append('Authorization', accessToken)
  }
  //  else {
  //    console.error('access token not found')
  //  }
  //  }

  const body = JSON.stringify({
    jsonrpc: '2.0',
    method: method,
    params: [request],
    id: id,
  })
  console.debug(method + ' jsonRpcRequest.body: ', JSON.parse(body))

  return new Promise((resolve, reject) => {
    fetch(
      url, {
        method: 'POST',
        headers: header,
        mode: 'cors',
        body: body,
        credentials: 'include',
      },
    ).then(responseObject => {
      if (responseObject.ok) {
        return responseObject.json()
      } else {
        reject('Request failed: ' + responseObject.statusText)
      }
    }).then(response => {
      console.debug(method + ' jsonRpcRequest.response: ', response)
      if (response.result) {
        console.log(method + ' - success', response.result)
        resolve(response.result)
      } else {
        reject(response.error)
        console.error(method + ' - error: ', response.error)
      }
    }).catch(error => {
      reject(error)
      console.error(method + ' jsonRpcRequest.error: ', error)
    })
  })
}

export function httpRequest({url, request}) {
  const header = new Headers({
    'Content-Type': 'application/json',
  })
  let body;
  if (request) {
    body = JSON.stringify(request)
  }
  return new Promise((resolve, reject) => {
    fetch(
      url, {
        method: 'POST',
        headers: header,
        mode: 'cors',
        body: body,
        credentials: 'include',
      },
    ).then(responseObject => {
      if (responseObject.ok) {
        responseObject.json()
          .then(body => resolve(body))
          .catch(e => resolve(e))
      } else {
        reject(responseObject)
      }
    }).catch(e => reject(e))
  })
}
