import React from 'react'
import {connect} from 'react-redux'
import TradingDayException from './TradingDayException'

let TradingDayExceptionContainer = props => {
  return <TradingDayException {...props}/>
}

function mapStateToProps(state) {
  return {
    currencies: state.currency.records,
  }
}

TradingDayExceptionContainer = connect(
  mapStateToProps,
  {},
)(TradingDayExceptionContainer)

export default TradingDayExceptionContainer
