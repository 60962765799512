import {isObject} from 'utils/Utils'

export function MarshalCriteria(criteria) {
  console.log(criteria)
  if (criteria == null) {
    return undefined
  }
  const stringObjects = []
  for (const criterion of criteria) {
    if (criterion.type && criterion.value && isObject(criterion.value)) {
      stringObjects.push(criterion.type + '::' + JSON.stringify(criterion.value))
    } else {
      if (criterion.type) {
        const cType = criterion.type
        delete criterion.type
        stringObjects.push(cType + '::' + JSON.stringify(criterion))
      }
    }

  }
  return stringObjects
}