import {IconButton, makeStyles, Tooltip} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import {HexToRGBA} from '../../../../utils/Utils'
import {MdPageview} from 'react-icons/md'
import {TextField as ParsedTextField, TextFieldParseTypes} from '../../../FormContols'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    padding: theme.spacing(),
    width: '100%',
    height: '33px',
  },
  formField: {
    color: theme.palette.text.body,
  },
  title: {
    color: theme.palette.text.accent,
  },
  column: {
    width: '100px',
    flex: 1,
    color: HexToRGBA(theme.palette.text.primary, 0.5)
  },
  header: {
    flex: 1,
    width: '100px',
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.text.label
  },
  iconButton: {
    padding: '0px',
    paddingTop: '3px',
    flex: '0 0 30px',
  }
}))

export const ListItem = (props) => {
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      onClick={props.onClick ? () => props.onClick(props.entity) : (() => null)}
      style={{
        borderWidth: props.header ? '0 0 1px 0px' : '1px 0 0 0',
        cursor: props.header ? '' : 'pointer',
      }}
    >
      {props.columns.map((c, key) => {
        return (
          <ParsedTextField
            InputProps={{
              className: props.header ? classes.header : c.className ? classNames(c.className, classes.column) : classes.column,
              startAdornment: !props.header && c.currencySymbol,
              disableUnderline: true,
              readOnly: true
            }}
            key={key}
            parseType={(c.type === 'float' && !props.header) ? TextFieldParseTypes.float : TextFieldParseTypes.string}
            value={props.header ? c.Header : props.entity[c.accessor]}
          />
        )
      })}
      <div
        className={classes.iconButton}>
        {
          !props.header &&
          <Tooltip title={'View Details'}>
            <IconButton
              key={'view'}
              onClick={props.onClick ? () => props.onClick(props.entity) : (() => null)}
              style={{
                padding: '0px',
              }}>
              <MdPageview/>
            </IconButton>
          </Tooltip>
        }
      </div>
      <div
        className={classes.iconButton}>
        {
          !props.header && props.additionalActions
        }
      </div>
    </div>
  )
}

ListItem.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  entity: PropTypes.object,
  header: PropTypes.bool,
  onClick: PropTypes.func,
}
