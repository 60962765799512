import {jsonRpcRequest} from '../../jsonRpc/jsonRpcReqest'
import {config} from '../../index'
import {marshalIdentifier} from '../../marshallers'
import {MarshalCriteria as marshallCriteria} from 'popcorn-js/search/criteria/Marshaller'

/**
 * InvoiceRecordkeeper is a convenience client for invoking the backend services
 */

//invoice recordkeeper api
export const Recordkeeper = {


  retrieve(retrieveRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Invoice-Recordkeeper.Retrieve',
      request: {
        ...retrieveRequest,
        identifier: serializedIdentifier
      },
    })
  },

  validate(validateRequest) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Invoice-Recordkeeper.Validate',
      request: {...validateRequest},
    })
  },

  validateBatch(validateBatchRequest) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Invoice-Recordkeeper.ValidateBatch',
      request: {...validateBatchRequest},
    })
  },

  find(findRequest) {
    const serializedCriteria = marshallCriteria(findRequest.criteria)
    return jsonRpcRequest(
      {
        url: config.get('golangUrl'),
        method: 'Invoice-Recordkeeper.Find',
        request: {
          ...findRequest,
          criteria: serializedCriteria
        }
      })
  },

  create(createRequest) {
    return jsonRpcRequest(
      {
        url: config.get('golangUrl'),
        method: 'Invoice-Recordkeeper.Create',
        request: {...createRequest}

      })
  },

  createBatch(createBatchRequest) {
    return jsonRpcRequest(
      {
        url: config.get('golangUrl'),
        method: 'Invoice-Recordkeeper.CreateBatch',
        request: {...createBatchRequest}

      })
  },
  retrieveHistory(retrieveHistoryRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveHistoryRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Invoice-Recordkeeper.RetrieveHistory',
      request: {
        ...retrieveHistoryRequest,
        identifier: serializedIdentifier
      }
    }
    )
  },

  delete(deleteRequest) {
    const serializedIdentifier = marshalIdentifier(deleteRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Invoice-Recordkeeper.Delete',
      request: {
        ...deleteRequest,
        identifier: serializedIdentifier
      }
    }
    )
  },

  deleteForever(deleteForeverRequest) {
    const serializedIdentifier = marshalIdentifier(deleteForeverRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Invoice-Recordkeeper.DeleteForever',
      request: {
        ...deleteForeverRequest,
        identifier: serializedIdentifier
      }
    }
    )
  },

  restore(restoreRequest) {
    const serializedIdentifier = marshalIdentifier(restoreRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Invoice-Recordkeeper.Restore',
      request: {
        ...restoreRequest,
        identifier: serializedIdentifier,
      }
    }
    )
  },


}

export default Recordkeeper

