import React from 'react';
import { Header, ItemEntry } from 'views/History/HistoryLayout';
import { Divider } from '@material-ui/core';
import { processUnixDateForViewing } from 'utils/Utils';
import {
  SystemDateFormat,
  SystemDateTimeFormat,
} from 'constants/formats';

const styles = {
  root: {
    display: 'grid',
    flexGrow: 1,
    rowGap: '2px',
    boxShadow: '',
    // paddingTop: '25px',
    zIndex: 1,
    gridTemplateRows: (() => {
      let space = '';
      for (let i = 0; i < 20; i++) {
        space = space + '32px ';
      }
      return space;
    })(),
  },
  divider: {
    height: '2px',
    margin: '20px 20px',
  },
  subDivider: {
    height: '1px',
    margin: '16px 40px',
  },
};

export const InvoiceFields = (invoice, fieldType, changedFields = []) => {
  return (
    <div>
      <main style={styles.root}>
        <Header
          fieldType={fieldType}
          label={'Invoice Information'} />
        {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
        <ItemEntry
          changed={changedFields.includes('number')}
          fieldType={fieldType}
          id={'number'}
          label={'Number'}
          value={invoice.number || ''}
        />
        <ItemEntry
          changed={changedFields.includes('counterpartyId')}
          fieldType={fieldType}
          id={'counterpartyId'}
          label={'Counterparty'}
          value={invoice.counterpartyName || invoice.counterpartyId || ''}
        />
        <ItemEntry
          changed={changedFields.includes('amountDue')}
          fieldType={fieldType}
          id={'amountDue'}
          label={'Amount Due'}
          value={invoice.amountDue}
        />
        <ItemEntry
          changed={changedFields.includes('currencyId')}
          fieldType={fieldType}
          id={'currencyId'}
          label={'Currency'}
          value={invoice.currencyIsoCode || invoice.currencyId || ''}
        />
        <ItemEntry
          changed={changedFields.includes('costCurrencyId')}
          fieldType={fieldType}
          id={'costCurrencyId'}
          label={'Cost Currency'}
          value={invoice.costCurrencyIsoCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('costingRate')}
          fieldType={fieldType}
          id={'costingRate'}
          label={'Costing Rate'}
          value={invoice.costingRate}
        />
        <ItemEntry
          changed={changedFields.includes('dueDate')}
          fieldType={fieldType}
          id={'dueDate'}
          label={'Due Date'}
          value={processUnixDateForViewing(invoice.dueDate, SystemDateFormat)}
        />
        <ItemEntry
          changed={changedFields.includes('shippingDate')}
          fieldType={fieldType}
          id={'shippingDate'}
          label={'Shipping Date'}
          value={processUnixDateForViewing(
            invoice.shippingDate,
            SystemDateFormat
          )}
        />
        <ItemEntry
          changed={changedFields.includes('shipmentReference')}
          fieldType={fieldType}
          id={'shipmentReference'}
          label={'Shipment Reference'}
          value={invoice.shipmentReference}
        />
        <ItemEntry
          changed={changedFields.includes('notes')}
          fieldType={fieldType}
          id={'notes'}
          label={'Notes'}
          value={invoice.notes || '-'}
        />
        <ItemEntry
          changed={changedFields.includes('status')}
          fieldType={fieldType}
          id={'status'}
          label={'Status'}
          value={invoice.status || ''}
        />
        <ItemEntry
          changed={changedFields.includes('partyCode')}
          fieldType={fieldType}
          id={'partyCode'}
          label={'Party Code'}
          value={invoice.partyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('approvedAmount')}
          fieldType={fieldType}
          id={'approvedAmount'}
          label={'Approved Amount'}
          value={invoice.approvedAmount}
        />
        <ItemEntry
          changed={changedFields.includes('effectiveRate')}
          fieldType={fieldType}
          id={'effectiveRate'}
          label={'Effective Rate'}
          value={invoice.effectiveRate}
        />
        <ItemEntry
          changed={changedFields.includes('issueDate')}
          fieldType={fieldType}
          id={'issueDate'}
          label={'Issue Date'}
          value={processUnixDateForViewing(invoice.issueDate, SystemDateFormat)}
        />
        <ItemEntry
          changed={changedFields.includes('captureRate')}
          fieldType={fieldType}
          id={'captureRate'}
          label={'Capture Rate'}
          value={invoice.captureRate}
        />
        <ItemEntry
          changed={changedFields.includes('direction')}
          fieldType={fieldType}
          id={'direction'}
          label={'Direction'}
          value={invoice.direction || '-'}
        />
        <ItemEntry
          changed={changedFields.includes('paidAmount')}
          fieldType={fieldType}
          id={'paidAmount'}
          label={'Paid Amount'}
          value={invoice.paidAmount || '-'}
        />
        <ItemEntry
          changed={changedFields.includes('unallocatedAmount')}
          fieldType={fieldType}
          id={'unallocatedAmount'}
          label={'Unallocated Amount'}
          value={invoice.unallocatedAmount || '-'}
        />
        <Divider style={styles.divider} />
        <Header
          fieldType={fieldType}
          label={'Audit Entry'} />
        <ItemEntry
          changed={changedFields.includes('auditEntry.username')}
          fieldType={fieldType}
          id={'auditEntry.username'}
          label={'Username'}
          value={(invoice.auditEntry || {}).username || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.action')}
          fieldType={fieldType}
          id={'auditEntry.action'}
          label={'Action'}
          value={(invoice.auditEntry || {}).action || '-'}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.time')}
          fieldType={fieldType}
          id={'auditEntry.time'}
          label={'Time'}
          value={
            (invoice.auditEntry || {}).time
              ? processUnixDateForViewing(
                (invoice.auditEntry || {}).time,
                SystemDateTimeFormat
              )
              : ''
          }
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.version')}
          fieldType={fieldType}
          id={'auditEntry.version'}
          label={'Version'}
          value={(invoice.auditEntry || {}).version || 0}
        />
      </main>
    </div>
  );
};
