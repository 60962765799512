import React, {Component} from 'react'
import {
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Tab,
  Table as MUITable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  withStyles,
} from '@material-ui/core'
import ReportCard from 'components/ReportCard/ReportCard'
import ExposureSelection from 'views/SettlementInstruction/Workstation/ExposureSelection'
import SettlementInstructionRecordkeeper from 'popcorn-js/financial/settlementInstruction/recordkeeper'
import {FormatNumber} from 'utils/TradeUtilities'
import {isArray, processUnixDateForViewing} from 'utils/Utils'
import Table from 'components/Table/VirtualizedTable/VirtualizedTable'
import Tooltip from '@material-ui/core/Tooltip'
import {
  AddIcon,
  BackIcon,
  CancelIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  RestoreIcon,
  ViewDeleteIcon,
  ViewIcon,
} from 'components/Icons'
import {FiX as DeselectIcon} from 'react-icons/fi'
import {StatusComplete, StatusDraft,} from 'constants/settlementInstruction'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'
import SettlementInstructionHandler from 'popcorn-js/financial/settlementInstruction/handler'
import Downloader from 'popcorn-js/financial/settlementInstruction/downloader'
import {FullPageLoader} from 'components/Loader/Loader'
import saveAs from 'file-saver'
import SettlementInstructionSummaryDialog from 'components/Detail/SettlementInstruction/SettlementInstructionSummaryDialog'
import SettlementInstructionDetail from 'components/Detail/SettlementInstruction/SettlementInstructionDetailContainer'
import {EXACT_CRITERION, exactCriterionType, textNeCriterionType} from 'popcorn-js/search/criteria/types'
import {
  INVOICE_STATUS_PAID,
  INVOICE_TYPE_PURCHASE_CREDIT_NOTE,
  INVOICE_TYPE_PURCHASE_INVOICE, INVOICE_TYPE_SALES_CREDIT_NOTE,
  INVOICE_TYPE_SALES_INVOICE
} from 'constants/invoice'
import AddNewSiDialog from 'views/SettlementInstruction/Workstation/AddNewSiDialog'
import TextField, {parseTypes as TextFieldParseTypes} from 'components/FormContols/TextField'
import InvoiceDetail from 'components/Detail/invoice/InvoiceDetailDialog'
import PrepaymentDetailDialog from 'components/Detail/prepayment/PrepaymentDetailDialogContainer'
import {INVOICE_OVERPAID_CRITERION} from '../../../popcorn-js/search/criteria/types';
import {InvoiceRecordkeeper} from '../../../popcorn-js/financial/invoice';

const styles = theme => ({
  root: {
    height: 'calc(100vh - 90px)',
    overflow: 'hidden',
  },
  tableWrapper: {
    height: 'calc(100vh - 250px)',
    overflow: 'hidden',
  },
  editingRecordRootLayout: {
    height: 'calc(100vh - 90px)',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr',
    overflow: 'hidden',
  },
  editingRecordLayout: {
    display: 'grid',
    gridTemplateColumns: '4fr 3fr',
    gridTemplateRows: '1fr 1fr',
  },
  editingHeaderCard: {
    gridColumn: '1/3',
    gridRow: '1/2',
    padding: 0,
  },
  selectedInvoicesCard: {
    gridColumn: '1/2',
    gridRow: '2/3',
    padding: 0,
  },
  settlementInstructionDetailCard: {
    gridColumn: '2/3',
    gridRow: '1/3',
    padding: 0,
  },
  cardHeader: {
    padding: '6px 10px 5px 10px',
  },
  cardRoot: {
    padding: 0,
  },
  selectedExposuresRootLayout: {
    display: 'grid',
    gridTemplateRows: 'auto auto auto',
    padding: '10px',
  },
  selectedExposuresHeading: {
    height: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
  },
  grey: {
    color: theme.palette.grey[400],
  },
  selectedExposuresTableRoot: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  removeIcon: {
    padding: '0px 2px 0px 2px',
    color: theme.palette.common.red,
  },
  viewIcon: {padding: '0'},
})

const ReducedPaddingBodyCell = withStyles(() => ({
  root: {
    padding: '0px 10px 0px 10px',
  },
}))(TableCell)

const ReducedPaddingHeaderCell = withStyles(() => ({
  root: {
    padding: '0px 10px 0px 10px',
  },
}))(TableCell)

function newInvoiceEntry(invoice) {
  return {
    invoice: invoice,
    amount: getInvoiceTotalAllowedAmount(invoice),
  }
}

function newPrepaymentAmountRequested(prepayment) {
  return {
    prepayment: prepayment,
    amount: getPrepaymentTotalAllowedAmount(prepayment),
  }
}

function newInvoiceAmountRequested(invoiceEntry) {
  return {
    invoiceId: invoiceEntry.invoice.id,
    amount: invoiceEntry.amount,
  }
}

function getInvoiceTotalAllowedAmount(invoice) {
  let totalAllowedAmount = 0
  try {
    totalAllowedAmount = invoice.amountDue - invoice.paidAmount
    for (const trdLink of invoice.tradeLinks) {
      totalAllowedAmount -= trdLink.amount
    }
  } catch (e) {
    console.error('error getting total allowed amount', e)
  }
  return totalAllowedAmount
}

function getPrepaymentTotalAllowedAmount(prepayment) {
  let totalAllowedAmount = 0
  try {
    totalAllowedAmount = prepayment.amountDue - prepayment.paidAmount
    for (const trdLink of prepayment.tradeLinks) {
      totalAllowedAmount -= trdLink.amount
    }
  } catch (e) {
    console.error('error getting total allowed amount', e)
  }
  return totalAllowedAmount
}

const states = {
  viewingRecords: 0,
  editingRecord: 2,
}

const selectedExposuresTabs = {
  invoices: 0,
  prepayments: 1,
}

class Workstation extends Component {
  static defaultProps = {
    currencies: [],
  }

  state = {
    activeState: states.viewingRecords,

    showNewDialog: false,

    viewingDeletedRecords: false,
    settlementInstructionRecords: [],
    selectedSettlementInstructionRecord: {},
    settlementInstructionRecordSelected: false,
    totalNoSettlementInstructionRecords: 0,
    loadingRecords: false,

    apiInProgress: false,

    errorMessage: '',
    successMessage: '',

    showSISummaryDialog: false,

    invoiceEntries: [],
    selectedInvoiceToView: {},
    showInvoiceDetailDialog: false,

    prepaymentAmountsRequested: [],
    selectedPrepaymentToView: {},
    showPrepaymentDetailDialog: false,

    activeSelectedExposureTab: selectedExposuresTabs.invoices,
    selectedExposureTableBodyHeight: 0,

    invoiceCache: [],
  }

  componentDidMount() {
    this.findSettlementInstructionRecords().finally()
  }

  findSettlementInstructionRecords = async () => {
    const {viewingDeletedRecords} = this.state
    this.setState({loadingRecords: true})
    try {
      const findResult = await SettlementInstructionRecordkeeper.find(
        null,
        null,
        viewingDeletedRecords,
      )
      this.setState({
        settlementInstructionRecords: findResult.records,
        totalNoSettlementInstructionRecords: findResult.total,
      })
    } catch (e) {
      console.error('error finding settlement instruction records')
    }
    this.setState({loadingRecords: false})
    // retrieve associated invoices
    this.populateInvoiceCache().finally()
  }

  handleRowClick = ({rowData: settlementInstruction}) => {
    this.setState({
      selectedSettlementInstructionRecord: settlementInstruction,
      settlementInstructionRecordSelected: true,
    })
  }

  handleRowDoubleClick = ({rowData: settlementInstruction}) => {
    this.setState({
      selectedSettlementInstructionRecord: settlementInstruction,
      settlementInstructionRecordSelected: true,
      showSISummaryDialog: true,
    })
  }

  handleStartCreateNew = () => {
    this.setState({showNewDialog: true})
  }

  handleToggleViewDelete = () => {
    const {viewingDeletedRecords} = this.state
    this.setState({
      viewingDeletedRecords: !viewingDeletedRecords,
      selectedSettlementInstructionRecord: {},
      settlementInstructionRecordSelected: false,
    },
    this.findSettlementInstructionRecords,
    )
  }

  handleDelete = async () => {
    const {selectedSettlementInstructionRecord} = this.state
    this.setState({apiInProgress: true})
    try {
      await SettlementInstructionRecordkeeper.delete(
        selectedSettlementInstructionRecord.id,
      )
      this.setState({
        successMessage: 'Deleted Settlement Instruction',
      },
      this.findSettlementInstructionRecords,
      )
    } catch (e) {
      console.error('error archiving settlement instruction', e)
      this.setState({errorMessage: e.message || e})
    }
    this.setState({apiInProgress: false})
  }

  handleRestore = async () => {
    const {selectedSettlementInstructionRecord} = this.state
    this.setState({apiInProgress: true})
    try {
      await SettlementInstructionRecordkeeper.restore(
        selectedSettlementInstructionRecord.id,
      )
      this.setState({
        successMessage: 'Restored Settlement Instruction',
      },
      this.findSettlementInstructionRecords,
      )
    } catch (e) {
      console.error('error restoring settlement instruction', e)
      this.setState({errorMessage: e.message || e})
    }
    this.setState({apiInProgress: false})
  }

  handleDeleteForever = async () => {
    const {selectedSettlementInstructionRecord} = this.state
    this.setState({apiInProgress: true})
    try {
      await SettlementInstructionRecordkeeper.deleteForever(
        selectedSettlementInstructionRecord.id,
      )
      this.setState({
        successMessage: 'Deleted Settlement Instruction',
      },
      this.findSettlementInstructionRecords,
      )
    } catch (e) {
      console.error('error deleting settlement instruction', e)
      this.setState({errorMessage: e.message || e})
    }
    this.setState({apiInProgress: false})
  }

  handleCancel = async () => {
    const {selectedSettlementInstructionRecord} = this.state
    const {settlementInstructionRecords} = this.state
    this.setState({apiInProgress: true})
    try {
      const cancelResponse = await SettlementInstructionHandler.cancel({
        id: selectedSettlementInstructionRecord.id,
      })
      const siIdxToReplace = settlementInstructionRecords.findIndex(
        si => si.id === cancelResponse.settlementInstruction.id
      )
      if (siIdxToReplace >= 0) {
        settlementInstructionRecords[siIdxToReplace] =
          cancelResponse.settlementInstruction
      }
      this.setState({
        successMessage: 'Cancelled Settlement Instruction',
        settlementInstructionRecords,
        selectedSettlementInstructionRecord: cancelResponse.settlementInstruction,
      },
      this.findSettlementInstructionRecords,
      )
    } catch (e) {
      console.error('error cancelling settlement instruction', e)
      this.setState({errorMessage: e.message || e})
    }
    this.setState({apiInProgress: false})
  }

  handleUpdate = async () => {
    const {selectedSettlementInstructionRecord} = this.state
    const {settlementInstructionRecords} = this.state
    this.setState({apiInProgress: true})
    try {
      const draftUpdateResponse = await SettlementInstructionHandler.draftUpdate({
        settlementInstruction: selectedSettlementInstructionRecord,
      })
      const siIdxToReplace = settlementInstructionRecords.findIndex(
        si => si.id === draftUpdateResponse.settlementInstruction.id
      )
      if (siIdxToReplace >= 0) {
        settlementInstructionRecords[siIdxToReplace] =
          draftUpdateResponse.settlementInstruction
      }
      this.setState({
        successMessage: 'Updated Settlement Instruction',
        settlementInstructionRecords,
        selectedSettlementInstructionRecord: draftUpdateResponse.settlementInstruction,
        invoiceEntries: [],
        prepaymentAmountsRequested: [],
      },
      this.findSettlementInstructionRecords,
      )
    } catch (e) {
      console.error('error updating settlement instruction', e)
      this.setState({errorMessage: e.message || e})
    }
    this.setState({apiInProgress: false})
  }

  handleCreateNew = async (settlementInstructionCreateInfo) => {
    const {partyCode, parentPartyCode} = this.props
    this.setState({
      apiInProgress: true,
      showNewDialog: false,
    })
    try {
      const createDraftResponse = await SettlementInstructionHandler.createDraft({
        date: settlementInstructionCreateInfo.date,
        currencyId: settlementInstructionCreateInfo.currencyId,
        partyCode,
        processingOrgPartyCode: parentPartyCode,
      })
      this.setState({
        successMessage: 'Created Settlement Instruction',
        selectedSettlementInstructionRecord: createDraftResponse.settlementInstruction,
        activeState: states.editingRecord,
      },
      this.findSettlementInstructionRecords,
      )
    } catch (e) {
      console.error('error creating draft settlement instruction', e)
      this.setState({errorMessage: e.message || e})
    }
    this.setState({apiInProgress: false})
  }

  handleClearAlertMessages = () => {
    this.setState({
      errorMessage: '',
      successMessage: '',
    })
  }

  handleExport = async () => {
    const {selectedSettlementInstructionRecord} = this.state
    this.setState({apiInProgress: true})
    try {
      const settlementInstructionExportResult = await Downloader.downloadConfirmation(
        selectedSettlementInstructionRecord.id,
      )
      // convert base64 to byte array
      const binData = atob(settlementInstructionExportResult.data)
      const bytes = new Array(binData.length)
      for (let i = 0; i < binData.length; i++) {
        bytes[i] = binData.charCodeAt(i)
      }

      const blob = new Blob([new Uint8Array(bytes)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
      })
      saveAs(blob, 'settlementInstruction_export.xlsx')
    } catch (e) {
      console.error('error exporting settlement instruction', e)
      this.setState({errorMessage: e.message || e})
    }
    this.setState({apiInProgress: false})
  }

  handleEdit = () => {
    this.setState({activeState: states.editingRecord})
  }

  handleSelectInvoice = (invoice) => {
    const {invoiceEntries} = this.state
    invoiceEntries.push(newInvoiceEntry(invoice))
    this.setState({invoiceEntries})
  }

  handleDeselectInvoice = (invoice) => {
    const {invoiceEntries} = this.state
    this.setState({
      invoiceEntries: invoiceEntries.filter(
        entry => entry.invoice.id !== invoice.id),
    })
  }

  handleChangeInvoiceRequestedAmount = (invoiceId) => (e) => {
    const {
      invoiceEntries,
    } = this.state
    try {
      const invoiceRequestedAmountIdx = invoiceEntries.findIndex(entry => entry.invoice.id === invoiceId)
      if (invoiceRequestedAmountIdx >= 0) {
        invoiceEntries[invoiceRequestedAmountIdx].amount = e.target.value
        this.setState({invoiceEntries})
      }
    } catch (e) {
      console.error('error changing invoice amount', e)
    }
  }

  handleChangePrepaymentRequestedAmount = (prepaymentId) => (e) => {
    const {
      prepaymentAmountsRequested,
    } = this.state
    try {
      const prepaymentAmountsRequestedIdx = prepaymentAmountsRequested.findIndex(
        ppar => ppar.prepayment.id === prepaymentId,
      )
      if (prepaymentAmountsRequestedIdx >= 0) {
        prepaymentAmountsRequested[prepaymentAmountsRequestedIdx].amount = e.target.value
        this.setState({prepaymentAmountsRequested})
      }
    } catch (e) {
      console.error('error changing invoice amount', e)
    }
  }

  handleSelectPrepayment = (prepayment) => {
    const {prepaymentAmountsRequested} = this.state
    prepaymentAmountsRequested.push(newPrepaymentAmountRequested(prepayment))
    this.setState({prepaymentAmountsRequested})
  }

  handleDeselectPrepayment = (prepayment) => {
    const {prepaymentAmountsRequested} = this.state
    this.setState({
      prepaymentAmountsRequested: prepaymentAmountsRequested.filter(
        prepaymentAmountRequested => prepaymentAmountRequested.prepayment.id !== prepayment.id
      ),
    })
  }

  handleCustomEntryAdd = (customEntry) => {
    const {selectedSettlementInstructionRecord} = this.state
    if (!isArray(selectedSettlementInstructionRecord.customEntries)) {
      selectedSettlementInstructionRecord.customEntries = []
    }
    selectedSettlementInstructionRecord.customEntries.push(customEntry)
    this.setState({selectedSettlementInstructionRecord}, this.handleUpdate)
  }

  handleCreateAmountsRequested = () => {
    const {invoiceEntries, prepaymentAmountsRequested} = this.state
    const {selectedSettlementInstructionRecord} = this.state

    // update invoice amounts requested
    if (!isArray(selectedSettlementInstructionRecord.invoiceAmountsRequested)) {
      selectedSettlementInstructionRecord.invoiceAmountsRequested = []
    }
    for (const entry of invoiceEntries) {
      if (!selectedSettlementInstructionRecord.invoiceAmountsRequested.find(
        invAmntReq =>
          invAmntReq.invoiceId === entry.invoice.id,
      )) {
        selectedSettlementInstructionRecord.invoiceAmountsRequested.push(
          newInvoiceAmountRequested(entry),
        )
      }
    }

    // update prepayment amounts requested
    if (!isArray(selectedSettlementInstructionRecord.prepaymentAmountsRequested)) {
      selectedSettlementInstructionRecord.prepaymentAmountsRequested = []
    }
    for (const prepaymentAmountRequested of prepaymentAmountsRequested) {
      if (!selectedSettlementInstructionRecord.prepaymentAmountsRequested.find(
        ppar =>
          ppar.prepaymentId === prepaymentAmountRequested.prepayment.id,
      )) {
        selectedSettlementInstructionRecord.prepaymentAmountsRequested.push({
          prepaymentId: prepaymentAmountRequested.prepayment.id,
          amount: prepaymentAmountRequested.amount,
        })
      }
    }

    this.setState({
      selectedSettlementInstructionRecord
    }, this.handleUpdate)
  }

  handleOtherComponentUpdateSuccess = (settlementInstruction) => {
    const {settlementInstructionRecords} = this.state
    const siIdxToReplace = settlementInstructionRecords.findIndex(
      si => si.id === settlementInstruction.id
    )
    if (siIdxToReplace >= 0) {
      settlementInstructionRecords[siIdxToReplace] = settlementInstruction
    }
    this.setState({
      successMessage: 'Updated Settlement Instruction',
      settlementInstructionRecords,
      selectedSettlementInstructionRecord: settlementInstruction,
      invoiceEntries: [],
    },
    this.findSettlementInstructionRecords,
    )
  }

  handleOtherComponentSubmitSuccess = (settlementInstruction) => {
    const {settlementInstructionRecords} = this.state
    const siIdxToReplace = settlementInstructionRecords.findIndex(
      si => si.id === settlementInstruction.id
    )
    if (siIdxToReplace >= 0) {
      settlementInstructionRecords[siIdxToReplace] = settlementInstruction
    }
    this.setState({
      successMessage: 'Submitted Settlement Instruction',
      settlementInstructionRecords,
      selectedSettlementInstructionRecord: settlementInstruction,
      invoiceEntries: [],
      activeState: states.viewingRecords,
    },
    this.findSettlementInstructionRecords,
    )
  }

  render() {
    const {
      activeState,
      showNewDialog,
      selectedSettlementInstructionRecord,
      errorMessage,
      successMessage,
      apiInProgress,
      showSISummaryDialog,
      selectedInvoiceToView,
      showInvoiceDetailDialog,
      selectedPrepaymentToView,
      showPrepaymentDetailDialog,
    } = this.state
    const {
      theme,
      currencies,
      classes,
    } = this.props

    return (
      <div className={classes.root}>
        {(() => {
          switch (activeState) {
            case states.viewingRecords:
              return this.renderViewRecords()

            case states.editingRecord:
              return this.renderEditingRecord()

            default:
              return <div>Invalid Active State</div>
          }
        })()}
        <NotificationSweetAlert
          customClass={'configAlert'}
          errorMessage={errorMessage === '' ? undefined : errorMessage}
          onClose={this.handleClearAlertMessages}
          successMessage={successMessage === '' ? undefined : successMessage}
        />
        <FullPageLoader
          color={theme.palette.primary.main}
          isLoading={apiInProgress}
        />
        {showSISummaryDialog &&
        <SettlementInstructionSummaryDialog
          SI={selectedSettlementInstructionRecord}
          onClose={() => this.setState({showSISummaryDialog: false})}
          onSubmitSuccess={() => {
          }}
          onUpdateSuccess={() => {
          }}
          readOnly
          show={showSISummaryDialog}
        />}
        {showNewDialog &&
        <AddNewSiDialog
          closeDialog={() => this.setState({showNewDialog: false})}
          currencies={currencies}
          onSubmit={this.handleCreateNew}
          show={showNewDialog}
        />}
        {showInvoiceDetailDialog &&
        <InvoiceDetail
          invoice={selectedInvoiceToView}
          onClose={() => this.setState({showInvoiceDetailDialog: false})}
          readOnly
          show={showInvoiceDetailDialog}
        />}
        {showPrepaymentDetailDialog &&
        <PrepaymentDetailDialog
          closeDialog={() => this.setState({showPrepaymentDetailDialog: false})}
          prepayment={selectedPrepaymentToView}
          readOnly
          show={showPrepaymentDetailDialog}
        />}
      </div>
    )
  }

  populateInvoiceCache = async () => {
    const {
      settlementInstructionRecords,
    } = this.state

    let invoiceFindCrit = []
    settlementInstructionRecords.forEach(r => {
      r.invoiceAmountsRequested.forEach(a => {
        invoiceFindCrit.push({
          type: EXACT_CRITERION,
          value: {
            field: 'id',
            text: a.invoiceId,
          },
        })
      })
    })
    const findResponse = await InvoiceRecordkeeper.find({ criteria: invoiceFindCrit });
    this.setState({
      invoiceCache: findResponse.records
    })
  }

  renderViewRecords = () => {
    const {
      activeState,
      viewingDeletedRecords,
      selectedSettlementInstructionRecord,
      totalNoSettlementInstructionRecords,
      settlementInstructionRecords,
      invoiceCache
    } = this.state
    const {classes, currencies} = this.props

    let title = ''
    switch (true) {
      case viewingDeletedRecords:
        title = 'Settlement Instruction - Delete'
        break

      case activeState === states.editingRecord:
        title = 'Settlement Instruction - Editing'
        break

      default:
        title = 'Settlement Instruction'
    }

    return (
      <ReportCard>
        <CardHeader
          action={this.buildViewRecordsActions()}
          title={title}
        />
        <CardContent>
          <div className={classes.tableWrapper}>
            <Table
              columns={[
                {
                  label: 'Number',
                  dataKey: 'number',
                  flexGrow: 1.0,
                  width: 150,
                },
                {
                  label: 'Client',
                  dataKey: 'partyCode',
                  flexGrow: 1.0,
                  width: 150,
                },
                {
                  label: 'Requested Amount',
                  dataKey: 'invoiceAmountsRequested',
                  flexGrow: 1.0,
                  width: 150,
                  cellContentRenderer: ({rowData}) => {
                    let canShowReqAmount = true
                    try {
                      let total = rowData.invoiceAmountsRequested.reduce((total, current) => {
                        const inv =  invoiceCache.find(inv => inv.id === current.invoiceId)
                        if (!inv) {
                          canShowReqAmount = false
                          return 0
                        }
                        switch (inv.type) {
                          case INVOICE_TYPE_PURCHASE_INVOICE:
                          case INVOICE_TYPE_SALES_INVOICE:
                            return total + current.amount
                          case INVOICE_TYPE_PURCHASE_CREDIT_NOTE:
                          case INVOICE_TYPE_SALES_CREDIT_NOTE:
                            return total - current.amount
                          default:
                            canShowReqAmount = false
                            return 0
                        }}, 0)
                      total += rowData.prepaymentAmountsRequested.reduce((total, current) => total + current.amount, 0)
                      total += rowData.customEntries.reduce((total, current) => total + current.amount, 0)
                      if (canShowReqAmount) {
                        return FormatNumber(total, true, true, 2, true)
                      }
                      return '-'
                    } catch (e) {
                      console.error('error getting amount requested', e)
                      return 0
                    }
                  },
                  numeric: true,
                },
                {
                  label: 'Currency',
                  dataKey: 'currencyId',
                  flexGrow: 1.0,
                  width: 150,
                  cellContentRenderer: ({cellData}) => (currencies.find(
                    c => c.id === cellData) || {}).isoCode || cellData,
                },
                {
                  label: 'Value Date',
                  dataKey: 'date',
                  flexGrow: 1.0,
                  width: 150,
                  cellContentRenderer: ({cellData}) => processUnixDateForViewing(
                    cellData),
                },
                {
                  label: 'Status',
                  dataKey: 'status',
                  width: 250,
                },
              ]}
              minimumBatchSize={50}
              onRowClick={this.handleRowClick}
              onRowDoubleClick={this.handleRowDoubleClick}
              rowCount={totalNoSettlementInstructionRecords}
              rowGetter={({index}) => {
                if (index > settlementInstructionRecords.length - 1) {
                  return {}
                }
                return settlementInstructionRecords[index]
              }}
              selected={[selectedSettlementInstructionRecord]}
              threshold={30}
            />
          </div>
        </CardContent>
      </ReportCard>
    )
  }

  buildViewRecordsActions = () => {
    const {
      settlementInstructionRecordSelected,
      viewingDeletedRecords,
      selectedSettlementInstructionRecord,
    } = this.state

    const blotterActions = []
    let key = 0
    const getKey = () => {
      key++
      return key
    }

    if (viewingDeletedRecords) {
      if (settlementInstructionRecordSelected) {
        blotterActions.push(
          <Tooltip
            enterDelay={400}
            key={getKey()}
            title="View Detail">
            <IconButton
            >
              <ViewIcon/>
            </IconButton>
          </Tooltip>,
        )
        blotterActions.push(
          <Tooltip
            enterDelay={400}
            key={getKey()}
            title={'Delete Forever'}>
            <IconButton
              onClick={this.handleDeleteForever}
            >
              <DeleteIcon/>
            </IconButton>
          </Tooltip>,
        )
        blotterActions.push(
          <Tooltip
            enterDelay={400}
            key={getKey()}
            title={'Restore'}>
            <IconButton
              onClick={this.handleRestore}
            >

              <RestoreIcon/>
            </IconButton>
          </Tooltip>,
        )
      }
      blotterActions.push(
        <Tooltip
          enterDelay={400}
          key={getKey()}
          title="Back">
          <IconButton
            onClick={this.handleToggleViewDelete}
          >
            <BackIcon/>
          </IconButton>
        </Tooltip>,
      )
    } else {
      if (settlementInstructionRecordSelected) {
        blotterActions.push(
          <Tooltip
            enterDelay={400}
            key={getKey()}
            title="View Detail">
            <IconButton
              onClick={() => this.setState({showSISummaryDialog: true})}
            >
              <ViewIcon/>
            </IconButton>
          </Tooltip>,
        )
        if (selectedSettlementInstructionRecord.status === StatusDraft) {
          blotterActions.push(
            <Tooltip
              enterDelay={400}
              key={getKey()}
              title="Delete"
            >
              <IconButton
                onClick={this.handleDelete}
              >
                <DeleteIcon/>
              </IconButton>
            </Tooltip>,
          )
        }
        blotterActions.push(
          <Tooltip
            enterDelay={400}
            key={getKey()}
            title="Edit">
            <IconButton
              disabled={selectedSettlementInstructionRecord.status !== StatusDraft}
              onClick={this.handleEdit}
            >
              <EditIcon/>
            </IconButton>
          </Tooltip>,
        )

        blotterActions.push(
          <Tooltip
            enterDelay={400}
            key={getKey()}
            title="Cancel">
            <IconButton
              disabled={
                selectedSettlementInstructionRecord.status === StatusDraft
              }
              onClick={this.handleCancel}
            >
              <CancelIcon/>
            </IconButton>
          </Tooltip>,
        )
      }
      blotterActions.push(
        <Tooltip
          enterDelay={400}
          key={getKey()}
          title={'Export'}>
          <IconButton
            component="div"
            disabled={selectedSettlementInstructionRecord.status !== StatusComplete}
            onClick={this.handleExport}
          >
            <DownloadIcon/>
          </IconButton>
        </Tooltip>)

      blotterActions.push(
        <Tooltip
          enterDelay={400}
          key={getKey()}
          title="View Delete">
          <IconButton
            onClick={this.handleToggleViewDelete}
          >
            <ViewDeleteIcon/>
          </IconButton>
        </Tooltip>,
      )

      blotterActions.push(
        <Tooltip
          enterDelay={400}
          key={getKey()}
          title="Add New">
          <IconButton
            onClick={this.handleStartCreateNew}
          >
            <AddIcon/>
          </IconButton>
        </Tooltip>,
      )
    }

    return blotterActions
  }

  getCurrency = (ccyId) => {
    const {currencies} = this.props
    try {
      return currencies.find(ccy => ccy.id === ccyId).isoCode
    } catch (e) {
      console.error('error getting currency')
      return '-'
    }
  }

  renderEditingRecord = () => {
    const {
      selectedSettlementInstructionRecord, invoiceEntries,
      prepaymentAmountsRequested,
      activeSelectedExposureTab,
    } = this.state
    const {classes, partyCode} = this.props

    return (
      <div
        className={classes.editingRecordRootLayout}
        style={{gridRowGap: 8}}
      >
        <ReportCard classes={{root: classes.editingHeaderCard}}>
          <CardHeader
            action={
              <Tooltip
                enterDelay={400}
                title="Back">
                <IconButton
                  onClick={() => this.setState({
                    activeState: states.viewingRecords,
                    invoiceEntries: [],
                    prepaymentAmountsRequested: [],
                  })}
                >
                  <BackIcon/>
                </IconButton>
              </Tooltip>
            }
            classes={{root: classes.cardHeader}}
            title={`Settlement Instruction - ${
              this.getCurrency(selectedSettlementInstructionRecord.currencyId)
            } - ${
              processUnixDateForViewing(selectedSettlementInstructionRecord.date)
            }`}
          />
        </ReportCard>
        <div
          className={classes.editingRecordLayout}
          style={{
            gridRowGap: 8,
            gridColumnGap: 8,
          }}
        >
          <ExposureSelection
            defaultFilter={[
              {
                type: exactCriterionType,
                value: {
                  field: 'currencyId',
                  text: selectedSettlementInstructionRecord.currencyId,
                },
              },
              {
                type: textNeCriterionType,
                value: {field: 'status', text: INVOICE_STATUS_PAID},
              },
              {
                type: INVOICE_OVERPAID_CRITERION,
                value: {overPaid: false},
              },
            ]}
            defaultPrepaymentsFilter={[
              {
                type: exactCriterionType,
                value: {
                  field: 'currencyId',
                  text: selectedSettlementInstructionRecord.currencyId,
                },
              },
              {
                type: textNeCriterionType,
                value: {field: 'status', text: 'Paid'},
              },
            ]}
            onCustomEntryAdd={this.handleCustomEntryAdd}
            onInvoiceDeselect={this.handleDeselectInvoice}
            onInvoiceSelect={this.handleSelectInvoice}

            onPrepaymentDeselect={this.handleDeselectPrepayment}
            onPrepaymentSelect={this.handleSelectPrepayment}
            partyCode={partyCode}

            selectedInvoices={invoiceEntries.map(entry => entry.invoice)}
            selectedPrepayments={prepaymentAmountsRequested.map(entry => entry.prepayment)}
          />
          <ReportCard classes={{root: classes.selectedInvoicesCard}}>
            <CardHeader
              action={
                <Tooltip
                  enterDelay={400}
                  title="Add Required Payments">
                  <IconButton
                    onClick={this.handleCreateAmountsRequested}
                  >
                    <AddIcon/>
                  </IconButton>
                </Tooltip>
              }
              classes={{root: classes.cardHeader}}
              title={
                <Tabs
                  onChange={(muiClass, activeSelectedExposureTab) => this.setState({activeSelectedExposureTab})}
                  value={activeSelectedExposureTab}
                >
                  <Tab
                    label={'Invoices'}
                    value={selectedExposuresTabs.invoice}/>
                  <Tab
                    label={'Prepayments'}
                    value={selectedExposuresTabs.prepayments}/>
                </Tabs>
              }
            />
            {(() => {
              switch (activeSelectedExposureTab) {
                case selectedExposuresTabs.invoices:
                  return this.renderSelectedInvoices()

                case selectedExposuresTabs.prepayments:
                  return this.renderSelectedPrepayments()

                default:
                  return null
              }
            })()}
          </ReportCard>
          <ReportCard classes={{root: classes.settlementInstructionDetailCard}}>
            <SettlementInstructionDetail
              SI={selectedSettlementInstructionRecord}
              onSubmitSuccess={this.handleOtherComponentSubmitSuccess}
              onUpdateSuccess={this.handleOtherComponentUpdateSuccess}
            />
          </ReportCard>
        </div>
      </div>
    )
  }

  getSelectedExposureTableBodyHeight = (element) => {
    const {selectedExposureTableBodyHeight} = this.state
    if (selectedExposureTableBodyHeight > 0) {
      // already set
      return
    }
    try {
      this.setState({
        selectedExposureTableBodyHeight: element.parentElement.clientHeight - 155,
      })
    } catch (e) {
      console.error('error setting table body height', e)
    }
  }

  renderSelectedInvoices = () => {
    const {
      invoiceEntries, selectedExposureTableBodyHeight,
    } = this.state
    const {classes, theme} = this.props

    return (
      <div
        className={classes.selectedExposuresRootLayout}
        ref={this.getSelectedExposureTableBodyHeight}
      >
        <div className={classes.selectedExposuresHeading}>
          <div className={classes.grey}>
            {(() => {
              switch (invoiceEntries.length) {
                case 0:
                  return 'No Invoices Selected'
                case 1:
                  return 'Selected 1 Invoice'
                default:
                  return `Selected ${invoiceEntries.length} Invoice`
              }
            })()}
          </div>
          <div>
            {(() => {
              const total = invoiceEntries.reduce((total, entry) => {
                switch (entry.invoice.type) {
                  case INVOICE_TYPE_PURCHASE_INVOICE:
                  case INVOICE_TYPE_SALES_INVOICE:
                    return total + entry.amount
                  case INVOICE_TYPE_PURCHASE_CREDIT_NOTE:
                  case INVOICE_TYPE_SALES_CREDIT_NOTE:
                    return total - entry.amount
                  default:
                    return total
                }
              }, 0)
              if (total === 0) {
                return ''
              }
              return `Selected Total: ${FormatNumber(total, true, true)}`
            })()}
          </div>
        </div>
        <Paper className={classes.selectedExposuresTableRoot}>
          <MUITable>
            <TableHead>
              <TableRow>
                <ReducedPaddingHeaderCell/>
                <ReducedPaddingHeaderCell>
                  Number
                </ReducedPaddingHeaderCell>
                <ReducedPaddingHeaderCell>
                  Type
                </ReducedPaddingHeaderCell>
                <ReducedPaddingHeaderCell>
                  Direction
                </ReducedPaddingHeaderCell>
                <ReducedPaddingHeaderCell>
                  Amount
                </ReducedPaddingHeaderCell>
              </TableRow>
            </TableHead>
          </MUITable>
          <div style={{height: selectedExposureTableBodyHeight, overflow: 'auto'}}>
            <MUITable>
              <TableBody>
                {invoiceEntries.map(
                  (entry, idx) => {
                    const amountExceeded = Math.abs(entry.amount) > Math.abs(getInvoiceTotalAllowedAmount(entry.invoice))
                    return (
                      <TableRow key={idx}>
                        <ReducedPaddingBodyCell>
                          <IconButton
                            className={classes.removeIcon}
                            onClick={() => this.handleDeselectInvoice(
                              entry.invoice,
                            )}
                          >
                            <DeselectIcon fontSize={'small'}/>
                          </IconButton>
                          <IconButton
                            className={classes.viewIcon}
                            onClick={() => this.setState({
                              selectedInvoiceToView: entry.invoice,
                              showInvoiceDetailDialog: true,
                            })}
                          >
                            <ViewIcon fontSize={'small'}/>
                          </IconButton>
                        </ReducedPaddingBodyCell>
                        <ReducedPaddingBodyCell>
                          {entry.invoice.number}
                        </ReducedPaddingBodyCell>
                        <ReducedPaddingBodyCell>
                          {entry.invoice.type}
                        </ReducedPaddingBodyCell>
                        <ReducedPaddingBodyCell>
                          {entry.invoice.direction}
                        </ReducedPaddingBodyCell>
                        <ReducedPaddingBodyCell>
                          <TextField
                            error={amountExceeded}
                            helperText={amountExceeded ? 'Amount Exceeded' : undefined}
                            onChange={this.handleChangeInvoiceRequestedAmount(
                              entry.invoice.id,
                            )}
                            parseType={TextFieldParseTypes.float}
                            value={entry.amount}
                          />
                          <TextField
                            InputProps={{
                              disableUnderline: true,
                              readOnly: true,
                              margin: 'dense',
                              style: {
                                fontSize: 14,
                                color: amountExceeded
                                  ? theme.palette.error.main
                                  : ''
                              },
                            }}
                            error={amountExceeded}
                            parseType={TextFieldParseTypes.string}
                            value={`/${entry.invoice.amountDue}`}
                          />
                        </ReducedPaddingBodyCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </MUITable>
          </div>
        </Paper>
      </div>
    )
  }

  renderSelectedPrepayments = () => {
    const {
      prepaymentAmountsRequested, selectedExposureTableBodyHeight,
    } = this.state
    const {classes, theme} = this.props

    return (
      <div
        className={classes.selectedExposuresRootLayout}
        ref={this.getSelectedExposureTableBodyHeight}
      >
        <div className={classes.selectedExposuresHeading}>
          <div className={classes.grey}>
            {(() => {
              switch (prepaymentAmountsRequested.length) {
                case 0:
                  return 'No Prepayments Selected'
                case 1:
                  return 'Selected 1 Prepayment'
                default:
                  return `Selected ${prepaymentAmountsRequested.length} Prepayments`
              }
            })()}
          </div>
          <div>
            {(() => {
              const total = prepaymentAmountsRequested.reduce((total, entry) => total + entry.amount, 0)
              if (total === 0) {
                return ''
              }
              return `Selected Total: ${FormatNumber(total, true, true)}`
            })()}
          </div>
        </div>
        <Paper className={classes.selectedExposuresTableRoot}>
          <MUITable>
            <TableHead>
              <TableRow>
                <ReducedPaddingHeaderCell/>
                <ReducedPaddingHeaderCell>
                  Number
                </ReducedPaddingHeaderCell>
                <ReducedPaddingHeaderCell colSpan={2}>
                  Amount
                </ReducedPaddingHeaderCell>
              </TableRow>
            </TableHead>
          </MUITable>
          <div style={{height: selectedExposureTableBodyHeight, overflow: 'auto'}}>
            <MUITable>
              <TableBody>
                {prepaymentAmountsRequested.map(
                  (ppar, idx) => {
                    const amountExceeded = ppar.amount > getPrepaymentTotalAllowedAmount(ppar.prepayment)
                    return (
                      <TableRow key={idx}>
                        <ReducedPaddingBodyCell>
                          <IconButton
                            className={classes.removeIcon}
                            onClick={() => this.handleDeselectPrepayment(
                              ppar.prepayment,
                            )}
                          >
                            <DeselectIcon fontSize={'small'}/>
                          </IconButton>
                          <IconButton
                            className={classes.viewIcon}
                            onClick={() => this.setState({
                              selectedPrepaymentToView: ppar.prepayment,
                              showPrepaymentDetailDialog: true,
                            })}
                          >
                            <ViewIcon fontSize={'small'}/>
                          </IconButton>
                        </ReducedPaddingBodyCell>
                        <ReducedPaddingBodyCell>
                          {ppar.prepayment.number}
                        </ReducedPaddingBodyCell>
                        <ReducedPaddingBodyCell>
                          <TextField
                            error={amountExceeded}
                            helperText={amountExceeded ? 'Amount Exceeded' : undefined}
                            onChange={this.handleChangePrepaymentRequestedAmount(
                              ppar.prepayment.id,
                            )}
                            parseType={TextFieldParseTypes.float}
                            value={ppar.amount}
                          />
                          <TextField
                            InputProps={{
                              disableUnderline: true,
                              readOnly: true,
                              margin: 'dense',
                              style: {
                                fontSize: 14,
                                color: amountExceeded
                                  ? theme.palette.error.main
                                  : ''
                              },
                            }}
                            error={amountExceeded}
                            parseType={TextFieldParseTypes.string}
                            value={`/${ppar.prepayment.amountDue}`}
                          />
                        </ReducedPaddingBodyCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </MUITable>
          </div>
        </Paper>
      </div>
    )
  }
}

const StyledWorkstation = withStyles(styles, {withTheme: true})(Workstation)

export default StyledWorkstation
