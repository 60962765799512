
export const partyCodeCriterion = 'PARTY_CODE_CRITERION'
export const identifierCriterion = 'IDENTIFIER_CRITERION'
export const textCriterion = 'TEXT_CRITERION'
export const exactCriterion = 'EXACT_CRITERION'
export const numberCriterion = 'NUMBER_CRITERION'
export const dateCriterion = 'DATE_CRITERION'
export const boolCriterion = 'BOOL_CRITERION'
export const textNeCriterion = 'TEXT_NE_CRITERION'
export const criterionFilterTypeOR = 'OR'
export const criterionFilterTypeAND = 'AND'
