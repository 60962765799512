import React from 'react'
import PropTypes from 'prop-types'

import {withStyles} from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'

import moment from 'moment'
import {MdLanguage as Language} from 'react-icons/md'
import styles from './Style'
import {BckndPgntdCustColTable} from 'components/Table'
import {processUnixDateForViewing} from 'utils/Utils'
import {FormatNumber} from 'utils/TradeUtilities'
import {DATE_CRITERION, NUMBER_CRITERION, TEXT_CRITERION} from 'popcorn-js/search/criteria/types'
import {exportRatesCache} from 'actions/rick'
import saveAs from 'file-saver'

import Handler from 'popcorn-js/rick/handler'
import RatesCache from './RatesCache';


class SystemHome extends React.Component {
  constructor(props) {
    super(props)
    this.handleHideAlert = this.handleHideAlert.bind(this)
    this.state = {
      tableRebuildColumnsToggle: false,
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    }
  }

  componentDidMount() {
    this.calculateTimeout = this.calculateTimeout.bind(this)
    this.calculateTimeout()
    this.ratesCache().finally()
    setInterval(this.calculateTimeout, 10000)
  }

  exportInstances = async () => {
    this.setState({loading: true})
    try {
      const cacheRatesResult = await exportRatesCache()

      // convert base64 to byte array
      let binData = atob(cacheRatesResult.data)
      let bytes = new Array(binData.length)
      for (let i = 0; i < binData.length; i++) {
        bytes[i] = binData.charCodeAt(i)
      }

      let blob = new Blob([new Uint8Array(bytes)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8'
      })
      saveAs(blob, 'CacheRates_export.xlsx')
      this.setState({
        successMessage: 'Rick Cache Exported'
      })
    } catch (error) {
      this.setState({errorMessage: error.message || error})
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  ratesCache = async () => {
    this.setState({loading: true})
    try {
      const cacheRatesResult = await Handler.GetRateCache()

      this.setState({
        ratesCache: cacheRatesResult
      })
    } catch (error) {
      this.setState({errorMessage: error.message || error})
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  calculateTimeout() {
    let expiry = moment()
    const parsedClaims = localStorage.getItem('claims')
    if (parsedClaims && parsedClaims.exp) {
      expiry = moment.unix(parsedClaims.exp)
    }
    const remaining = moment.duration(expiry.diff(moment())).humanize()

    this.setState({remaining: remaining})
  }

  handleHideAlert() {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    })
  }

  render() {
    const {classes, currencyPairs} = this.props
    const {errorMessage, confirmationMethod, warningMessage, successMessage, ratesCache} = this.state


    return (
      <div id="systemHomeRoot">
        <NotificationSweetAlert
          customClass={'RecordCancellationTicketDetailDialog'}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}
          successMessage={successMessage}
          warningMessage={warningMessage}
        />
        <GridContainer>
          <GridItem xs={12}>
            <RatesCache
              currencyPairs={currencyPairs}
              exportInstances={this.exportInstances}
              instances={ratesCache}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader
                color="success"
                icon>
                <CardIcon color="success">
                  <Language/>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  User Login Details
                </h4>
              </CardHeader>
              <CardBody>
                <BckndPgntdCustColTable
                  columns={[
                    {
                      Header: 'Login Name',
                      accessor: 'loginName',
                      filterConfig: {
                        type: TEXT_CRITERION,
                      }
                    },
                    {
                      Header: 'First Name',
                      accessor: 'firstName',
                      filterConfig: {
                        type: TEXT_CRITERION,
                      }
                    },
                    {
                      Header: 'Last Name',
                      accessor: 'lastName',
                      filterConfig: {
                        type: TEXT_CRITERION,
                      }
                    },
                    {
                      Header: 'Login Count',
                      id: 'loginMetaData.loginCount',
                      accessor: row => row.loginMetaData.loginCount,
                      Cell: row => FormatNumber(row.value, true, true),
                      filterConfig: {
                        type: NUMBER_CRITERION,
                      },
                    },
                    {
                      Header: 'Failed Login Attempts',
                      id: 'loginMetaData.failedLoginAttemptCount',
                      accessor: row => row.loginMetaData.failedLoginAttemptCount,
                      Cell: row => FormatNumber(row.value, true, true),
                      filterConfig: {
                        type: NUMBER_CRITERION,
                      },
                    },
                    {
                      Header: 'Last Change of Password',
                      id: 'loginMetaData.lastChangeOfPassword',
                      accessor: row => row.loginMetaData.lastChangeOfPassword,
                      Cell: row => processUnixDateForViewing(row.value),
                      filterConfig: {
                        type: DATE_CRITERION,
                      },
                    },
                    {
                      Header: 'Last Logged In',
                      id: 'loginMetaData.lastLoginDate',
                      accessor: row => row.loginMetaData.lastLoginDate,
                      Cell: row => processUnixDateForViewing(row.value),
                      filterConfig: {
                        type: DATE_CRITERION,
                      },
                    },
                  ]}
                  data={this.props.user.records}
                  defaultPageSize={5}
                  findPageRequest={this.props.findPageAllUsers}
                  findPageStatuses={this.props.user.findPage}
                  rebuildTableToggle={this.state.tableRebuildColumnsToggle}
                  retrieveTableConfig={this.props.retrieveTableConfig}
                  saveTableConfig={this.props.saveTableConfig}
                  tableConfig={this.props.tableConfig}
                  tableID={'SystemUsersLoginDetailsExposures'}
                  totalNoRecords={this.props.user.total}
                  useDefaultColumnConfig
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}


SystemHome.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(SystemHome)
