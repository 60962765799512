import React from 'react'
import PropTypes from 'prop-types'

import {ListItem, ListItemText, makeStyles,} from '@material-ui/core'
import {HexToRGBA} from 'utils/Utils'

const useStyles = makeStyles(theme => ({
  divider: {
    borderBottomColor: HexToRGBA(theme.palette.text.secondary, .25),
  },
  button: {
    color: theme.palette.text.secondary,
    '&:after': {
      // bo
    },
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.highActions,
    },
  },
}))

const Permission = props => {
  const classes = useStyles()
  const {data, index, style} = props
  const {permissions, selectPermission} = data

  const handleSelect = () => {
    selectPermission(index)
  }

  return (
    <ListItem
      button
      classes={{
        button: classes.button,
        divider: classes.divider,
      }}
      dense
      divider
      key={index}
      onClick={handleSelect}
      style={style}>
      <ListItemText
        // dense
        primary={permissions[index]}
      />
    </ListItem>
  )
}

Permission.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  style: PropTypes.object,
}

export default Permission
