import {marshalIdentifier} from '../../marshallers';
import {jsonRpcRequest} from '../../jsonRpc/jsonRpcReqest';
import {config} from '../../index'

import {MarshalCriteria} from '../../search/criteria/Marshaller'

/**
 * OrderRecordkeeper is a convenience client for invoking the backend services
 */

export const Recordkeeper = {
  delete(deleteRequest) {
    const serializedIdentifier = marshalIdentifier(deleteRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.Delete',
      request: {
        ...deleteRequest,
        identifier: serializedIdentifier
      },
    })
  },

  create(createRequest) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.Create',
      request: {...createRequest},
    })
  },

  createBatch(createBatchRequest) {
    return jsonRpcRequest(
      {
        url: config.get('golangUrl'),
        method: 'Order-Recordkeeper.CreateBatch',
        request: {...createBatchRequest}

      })
  },

  update(updateRequest) {
    const serializedIdentifier = marshalIdentifier(updateRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.Update',
      request: {
        ...updateRequest,
        identifier: serializedIdentifier
      },
    })
  },

  updateBatch(updateBatchRequest) {
    const updates = updateBatchRequest.updates.map((update) => {
      return {
        identifier: marshalIdentifier(update.identifier),
        order: update.order
      }
    })
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.UpdateBatch',
      request: {updates}
    }
    )
  },

  validateBatch(validateBatchRequest) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.ValidateBatch',
      request: {...validateBatchRequest},
    })
  },

  validate(validateRequest) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.Validate',
      request: {...validateRequest},
    })
  },


  find(findRequest) {
    const serializedCriteria = MarshalCriteria(findRequest.criteria)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.Find',
      request: {
        ...findRequest,
        criteria: serializedCriteria

      },
    })
  },

  retrieve(retrieveRequest) {
    const serializedIdentifier = marshalIdentifier(retrieveRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.Retrieve',
      request: {
        ...retrieveRequest,
        identifier: serializedIdentifier
      },
    })
  },

  restore(restoreRequest) {
    const serializedIdentifier = marshalIdentifier(restoreRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.Restore',
      request: {
        ...restoreRequest,
        identifier: serializedIdentifier
      },
    })
  },

  deleteForever(deleteForeverRequest) {
    const serializedIdentifier = marshalIdentifier(deleteForeverRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.DeleteForever',
      request: {
        ...deleteForeverRequest,
        identifier: serializedIdentifier
      },
    })
  },

  retrieveHistory(retrieveHistoryRequest) {
    const serilaizedIdentifier = marshalIdentifier(retrieveHistoryRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Order-Recordkeeper.RetrieveHistory',
      request: {
        ...retrieveHistoryRequest,
        identifier: serilaizedIdentifier
      },
    })
  }

}
