const requiredFields = [
  'branchName',
  'type',
  'processingBankPartyCode',
  'currencyId',
]

class Validator {

  /**
   * @Param {Account} account
   */
  static ValidateData(account) {
    return new Promise((resolve, reject) => {
      const invalidFields = {}
      requiredFields.forEach(fieldName => {
        if (!account[fieldName] || account[fieldName] === '') {
          invalidFields[fieldName] = 'required'
        }
      })
      if (Object.keys(invalidFields).length > 0) {
        reject(invalidFields)
      } else {
        resolve()
      }
    })
  }
}

export default Validator
