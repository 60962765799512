import React, {useState} from 'react'
import {processUnixDateForViewing} from 'utils/Utils'
import {FormatNumber} from 'utils/TradeUtilities'
import {useSelector} from 'react-redux'
import {DATE_CRITERION, NUMBER_CRITERION, TEXT_CRITERION} from "popcorn-js/search/criteria/types"
import {ALL_TRADE_LEG_DIRECTIONS, ALL_TRADE_STATUSES, ALL_TRADE_TYPES} from 'constants/trade'
import DetailDialog from 'views/TradeStation/DetailDialog'
import TradeHistoryLayoutContainer from 'views/History/TradeHistory/TradeHistoryLayoutContainer'
import {MdRemoveRedEye, MdRestore} from 'react-icons/md'
import {useTradeRecordkeeperFind} from 'hooks/financial/fx/trade/recordkeeperHooks'
import {ComponentLevelError} from 'components/Error/Error'
import {makeStyles} from '@material-ui/core'
import AndileTable from 'components/AndileMaterialUITable/AndileTable'

const safeRender = (accessor, formatter = value => (value)) => rowData => {
  try {
    return formatter(rowData[accessor])
  } catch (e) {
    return '-'
  }
}

const initialPageSize = 10
const rowsPerPageOptions = [5, 10, 25]

const useStyles = makeStyles(() => ({
  root: {
    margin: '5px',
  },
}))

function MatureTrades(props) {
  const classes = useStyles()
  const currencyPairs = useSelector(state => state.currencyPair.records)
  const processingBanks = useSelector(state => state.processingBank.records)
  const [{request, response, loading, error}, setRequest] = useTradeRecordkeeperFind(
    [],
    {
      sortBy: [],
      order: [],
      limit: initialPageSize,
      offset: 0,
    }
  )
  const [showHistory, setShowHistory] = useState(undefined)
  const [showDetail, setShowDetail] = useState(undefined)

  const rowActions = [
    {
      icon: <MdRemoveRedEye/>,
      tooltip: 'View Details',
      onClick: rowInfo => {
        setShowDetail(rowInfo)
      }
    },
    {
      icon: <MdRestore/>,
      tooltip: 'View History',
      onClick: rowInfo => {
        setShowHistory(rowInfo)
      }
    },
  ]

  // Table callbacks
  function handleChangeSorting(sortBy, order) {
    const newRequest = {
      ...request,
      query: {
        ...request.query,
        sortBy: [sortBy],
        order: [order],
      }
    }

    setRequest(newRequest)

  }

  function handleChangePage(event, newPage) {
    const offset = request.query.limit * newPage

    const newRequest = {
      ...request,
      query: {
        ...request.query,
        offset: offset,
      }
    }

    setRequest(newRequest)
  }

  function handleChangeRowsPerPage(event) {
    const rowsPerPage = event.target.value

    setRequest({
      ...request,
      query: {
        sortBy: [],
        order: [],
        limit: rowsPerPage,
        offset: 0
      }
    })
  }

  function handleFilterChange(newCrit) {
    setRequest({
      query: {
        ...request.query,
        offset: 0
      },
      criteria: newCrit.map(cr => {
        return {
          type: cr.type,
          ...cr.value
        }
      }),
    })
  }

  if (error) {
    return <ComponentLevelError
      errorMessage={'Failed to find trades'}
    />
  }

  return (
    <div className={classes.root}>
      {showDetail &&
      <DetailDialog
        closeDialog={() => {
          setShowDetail(undefined)
        }}
        currencyPairs={currencyPairs}
        open={!!showDetail}
        processingBanks={processingBanks}
        trade={showDetail}
      />}
      {showHistory && (
        <TradeHistoryLayoutContainer
          onHide={() => {
            setShowHistory(undefined)
          }}
          open
          trade={showHistory}
        />
      )}
      <AndileTable
        columns={[
          {title: 'Number', field: 'number', filter: {type: TEXT_CRITERION}},
          {
            title: 'Number', field: 'number',
            filter: {type: TEXT_CRITERION}
          },
          {
            title: 'Date',
            field: 'date',
            render: safeRender('date', processUnixDateForViewing),
            filter: {type: DATE_CRITERION}
          },
          {
            title: 'Type',
            field: 'tradeType',
            filter:
              {
                type: TEXT_CRITERION,
                options: ALL_TRADE_TYPES.map(value => ({value})),
                displayAccessor: 'value',
                valueAccessor: 'value',
              }
          },
          {
            title: 'Currency Pair',
            field: 'currencyPairId',
            render: safeRender(
              'currencyPairId',
              currencyPairId => (currencyPairs.find(ccyPair => ccyPair.id === currencyPairId)).name,
            ),
            filter: {type: TEXT_CRITERION}
          },
          {
            title: 'Status',
            field: 'status',
            filter:
              {
                options: ALL_TRADE_STATUSES.map(value => ({value})),
                displayAccessor: 'value',
                valueAccessor: 'value',
                type: TEXT_CRITERION
              }
          },
          {
            title: 'Trading Party Code',
            field: 'tradingPartyCode',
            filter: {
              type: TEXT_CRITERION,
              options: props.tradingParties,
              displayAccessor: 'name',
              valueAccessor: 'partyCode',
            },
          },
          {title: 'Processing Org Party Code', field: 'processingOrgPartyCode', filter: {type: TEXT_CRITERION}},
          {
            title: 'Available Balance',
            field: 'availableBalance',
            render: safeRender('availableBalance', balance => FormatNumber(balance, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {title: 'Near Leg External Reference', field: 'legs.0.externalReference', filter: {type: TEXT_CRITERION}},
          {
            title: 'Near Leg Bank',
            field: 'legs.0.bank',
            render: safeRender('legs', legs => legs[0].bank),
            filter: {type: TEXT_CRITERION}
          },
          {
            field: 'legs.0.tradeDate',
            title: 'Near Leg Trade Date',
            render: safeRender('legs', legs => processUnixDateForViewing(legs[0].tradeDate)),
            filter: {type: DATE_CRITERION}
          },
          
          {
            field: 'legs.0.maturityDate',
            title: 'Near Leg Maturity Date',
            render: safeRender('legs', legs => processUnixDateForViewing(legs[0].maturityDate)),
            filter: {type: DATE_CRITERION}
          },
          {
            title: 'Near Leg Notional Amount',
            field: 'legs.0.notionalAmount',
            render: safeRender('legs', legs => FormatNumber(legs[0].notionalAmount, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Near Leg Quote Amount',
            field: 'legs.0.quoteAmount',
            render: safeRender('legs', legs => FormatNumber(legs[0].quoteAmount, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Near Leg Direction',
            field: 'legs.0.direction',
            render: safeRender('legs', legs => legs[0].direction),
            filter:
              {
                type: TEXT_CRITERION,
                options: ALL_TRADE_LEG_DIRECTIONS.map(value => ({value})),
                displayAccessor: 'value',
                valueAccessor: 'value',
              }
          },
          {
            title: 'Near Leg Spot Price',
            field: 'legs.0.spotPrice',
            render: safeRender('legs', legs => FormatNumber(legs[0].spotPrice, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Near Leg Forward Points',
            field: 'legs.0.forwardPoints',
            render: safeRender('legs', legs => FormatNumber(legs[0].forwardPoints, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Near Leg Capture Price',
            field: 'legs.0.capturePrice',
            render: safeRender('legs', legs => FormatNumber(legs[0].capturePrice, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Near Leg Effective Rate',
            field: 'legs.0.effectiveRate',
            render: safeRender('legs', legs => FormatNumber(legs[0].effectiveRate, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Far Leg External Reference',
            field: 'legs.1.externalReference',
            render: safeRender('legs', legs => legs[1].externalReference),
            filter: {type: TEXT_CRITERION}
          },
          {
            title: 'Far Leg Bank',
            field: 'legs.1.bank',
            render: safeRender('legs', legs => legs[1].bank),
            filter: {type: TEXT_CRITERION}
          },
          {
            title: 'Far Leg Trade Date',
            field: 'legs.1.tradeDate',
            render: safeRender('legs', legs => processUnixDateForViewing(legs[1].tradeDate)),
            filter: {type: DATE_CRITERION}
          },
          {
            title: 'Far Leg Maturity Date',
            field: 'legs.1.maturityDate',
            render: safeRender('legs', legs => processUnixDateForViewing(legs[1].maturityDate)),
            filter: {type: DATE_CRITERION}
          },
          {
            title: 'Far Leg Notional Amount',
            field: 'legs.1.notionalAmount',
            render: safeRender('legs', legs => FormatNumber(legs[1].notionalAmount, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Far Leg Quote Amount',
            field: 'legs.1.quoteAmount',
            render: safeRender('legs', legs => FormatNumber(legs[1].quoteAmount, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Far Leg Direction',
            field: 'legs.1.direction',
            render: safeRender('legs', legs => legs[1].direction),
            filter:
              {
                type: TEXT_CRITERION,
                options: ALL_TRADE_LEG_DIRECTIONS.map(value => ({value})),
                displayAccessor: 'value',
                valueAccessor: 'value',
              }
          },
          {
            title: 'Far Leg Spot Price',
            field: 'legs.1.spotPrice',
            render: safeRender('legs', legs => FormatNumber(legs[1].spotPrice, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Far Leg Forward Points',
            field: 'legs.1.forwardPoints',
            render: safeRender('legs', legs => FormatNumber(legs[1].forwardPoints, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Far Leg Capture Price',
            field: 'legs.1.capturePrice',
            render: safeRender('legs', legs => FormatNumber(legs[1].capturePrice, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
          {
            title: 'Far Leg Effective Rate',
            field: 'legs.1.effectiveRate',
            render: safeRender('legs', legs => FormatNumber(legs[1].effectiveRate, true, true, 4, true)),
            filter: {type: NUMBER_CRITERION}
          },
        ]}
        count={response.total}
        data={response.records}
        defaultColConfig={[
          {header: 'Number', visible: true},
          {header: 'Date', visible: true},
          {header: 'Type', visible: true},
          {header: 'Currency Pair', visible: true},
          {header: 'Status', visible: true},
          {header: 'Trading Party Code', visible: false},
          {header: 'Processing Org Party Code', visible: false},
          {header: 'Available Balance', visible: true},
          {header: 'Near Leg External Reference', visible: true},
          {header: 'Near Leg Bank', visible: false},
          {header: 'Near Leg Trade Date', visible: true},
          {header: 'Near Leg Maturity Date', visible: true},
          {header: 'Near Leg Notional Amount', visible: true},
          {header: 'Near Leg Quote Amount', visible: true},
          {header: 'Near Leg Direction', visible: true},
          {header: 'Near Leg Spot Price', visible: true},
          {header: 'Near Leg Forward Points', visible: true},
          {header: 'Near Leg Capture Price', visible: true},
          {header: 'Near Leg Effective Rate', visible: true},
          {header: 'Far Leg External Reference', visible: true},
          {header: 'Far Leg Bank', visible: false},
          {header: 'Far Leg Trade Date', visible: true},
          {header: 'Far Leg Maturity Date', visible: true},
          {header: 'Far Leg Notional Amount', visible: true},
          {header: 'Far Leg Quote Amount', visible: true},
          {header: 'Far Leg Direction', visible: true},
          {header: 'Far Leg Spot Price', visible: true},
          {header: 'Far Leg Forward Points', visible: true},
          {header: 'Far Leg Capture Price', visible: true},
          {header: 'Far Leg Effective Rate', visible: true},
        ]}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
        onChangeSorting={handleChangeSorting}
        onFilterChange={handleFilterChange}
        order={request.query.order.length > 0 ? request.query.order[0] : undefined}
        page={Math.ceil(request.query.offset / request.query.limit)}
        rowActions={rowActions}
        rowDoubleClickAction={(rowInfo) => setShowDetail(rowInfo)}
        rowsPerPage={request.query.limit}
        rowsPerPageOptions={rowsPerPageOptions}
        sortBy={request.query.sortBy.length > 0 ? request.query.sortBy[0] : undefined}
        tableID={'OverdueExposuresProcessingOrgHome'}
        title={'Mature Trade'}
      />
    </div>
  )
}


MatureTrades.propTypes = {}

MatureTrades.defaultProps = {}

export default MatureTrades
