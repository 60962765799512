import React from 'react'
import {makeStyles, useTheme,} from '@material-ui/core'
import {useSelector} from 'react-redux'
import PortfolioCard from './PortfolioCard'
import PositionCard from './PositionCard'
import {usePerfectScrollbar} from 'hooks'

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: 'calc(100vh - 100px)',
    overflow: 'hidden',
    justifyItems: 'center',
  },

  // Portfolio Styles
  portfolioCardWrapper: {
    width: '1090px'
    // maxWidth: '1200px',
    // minWidth: '1090px',
  },

  // Position Styles
  positionCardsWrapper: {
    width: '1090px'
    // maxWidth: '1200px',
    // minWidth: '1090px',
  },
  positionsLayout: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default function Dashboard() {
  const theme = useTheme()
  const classes = useStyles(theme)
  const setScrollBarElementRef = usePerfectScrollbar()
  const setScrollBarElementRef2 = usePerfectScrollbar(false, true)

  const localCurrencyId = useSelector(state => {
    try {
      return state.myParty.localCurrencyId
    } catch (e) {
      console.error('error getting party\'s local currency', e)
    }
  })

  const hedgingPolicy = useSelector(state => {
    try {
      return state.myParty.hedgingPolicy
    } catch (e) {
      console.error('error getting party\'s hedging policy', e)
    }
  })

  const [currencies, localCurrency] = useSelector(state => {
    try {
      const partyCurrencies = []
      let localCurrency = {}
      const partyCurrencyPairsIds = state.myParty.currencyPairIds
      const currencyPairRecords = state.currencyPair.records
      const currencies = state.currency.records

      // find local currency
      try {
        localCurrency = currencies.find(ccy => ccy.id === localCurrencyId)
      } catch (e) {
        console.error('error finding local currency', e)
        localCurrency = {}
      }

      // for every currency pair assigned to this party
      for (const ccyPairId of partyCurrencyPairsIds) {
        // find the currency pair among currency pairs
        const ccyPair = currencyPairRecords.find(
          ccyPair => ccyPair.id === ccyPairId)
        if (!ccyPair) {
          console.error(
            'unable to find assigned currency pair among currency pair records')
          continue
        }

        if (!partyCurrencies.find(ccy => ccy.id === ccyPair.baseCurrencyId)) {
          // if the base currency has not yet been found try and find
          // it among all currency records
          const ccy = currencies.find(ccy => ccy.id === ccyPair.baseCurrencyId)
          if (ccy) {
            if (ccy.id !== localCurrencyId) {
              // add it if it is found
              partyCurrencies.push(ccy)
            }
          } else {
            console.error('unable to find base currency among currency pairs')
          }
        }
        if (!partyCurrencies.find(ccy => ccy.id === ccyPair.quoteCurrencyId)) {
          // if the quote currency has not yet been found try and find
          // it among all currency records
          const ccy = currencies.find(ccy => ccy.id === ccyPair.quoteCurrencyId)
          if (ccy) {
            if (ccy.id !== localCurrencyId) {
              // add it if it is found
              partyCurrencies.push(ccy)
            }
          } else {
            console.error('unable to find quote currency among currency pairs')
          }
        }
      }
      return [partyCurrencies, localCurrency]
    } catch (e) {
      console.error('error getting currencies assigned to user\'s party', e)
      return [[], localCurrency]
    }
  })

  return (
    <div
      className={classes.root}
      ref={setScrollBarElementRef2}>
      <div className={classes.portfolioCardWrapper}>
        <PortfolioCard
          hedgingPolicy={hedgingPolicy}
          localCurrency={localCurrency}
        />
      </div>
      <div
        className={classes.positionCardsWrapper}
        ref={setScrollBarElementRef}
      >
        <div className={classes.positionsLayout}>
          {currencies.map((currency, idx) => {
            return (
              <PositionCard
                currency={currency}
                hedgingPolicy={hedgingPolicy}
                key={idx}
                localCurrency={localCurrency}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
