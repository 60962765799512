import {IconButton, Tooltip} from '@material-ui/core';
import React from 'react';

import {ArrowBack,} from '@material-ui/icons'

export const returnAction = onClick => (
  <Tooltip
    key={'return'}
    title={'Return'}>
    <IconButton onClick={onClick}>
      <ArrowBack/>
    </IconButton>
  </Tooltip>)
