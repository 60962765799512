// Note that columns are directly proportional to props.children
import moment from 'moment'
import {SystemDateFormat, SystemDateTimeFormat,} from 'constants/formats'

export function columnWidth(columns) {
  if (columns === undefined) {
    console.log('Children are undefined')
    return (
      '1fr'
    )
  } else if (columns.length === undefined) {
    console.log('Children length is undefined')
    return (
      '1fr'
    )
  } else {
    let width = Array(columns.length).fill('1fr')
    let widthString = width.join(' ')
    return (
      widthString
    )
  }
}

// CompareObjects compares two nested objects
// TODO that this has not been implemented to compare arrays nested within the object
export const CompareObjects = (o1, o2, recursionDepth = 0) => {

  recursionDepth = recursionDepth + 1

  if (recursionDepth > 30) {
    console.error('max recursion depth exceeded, abandoning object compare')
    return false
  }

  if (!o1 || !o2) {
    return false
  }
  for (let p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (isObject(o1[p])) {
        if (!CompareObjects(o1[p], o2[p], recursionDepth)) {
          return false
        }
      } else if (o1[p] !== o2[p]) {
        return false
      }
    }
  }
  for (let p in o2) {
    if (o2.hasOwnProperty(p)) {
      if (isObject(o2[p])) {
        if (!CompareObjects(o1[p], o2[p], recursionDepth)) {
          return false
        }
      } else if (o1[p] !== o2[p]) {
        return false
      }
    }
  }
  return true
}

export function isString(value) {
  return typeof value === 'string'
}

export function isArray(value) {
  return value && typeof value === 'object' && value.constructor === Array
}

export function isObject(value) {
  return value && typeof value === 'object' && {}.toString.apply(value) ===
    '[object Object]'
}

export function isNumber(value) {
  return typeof value === 'number' && isFinite(value)
}

export function isFunction(value) {
  return typeof value === 'function'
}

export function isBoolean(value) {
  return typeof (value) === typeof (true)
}

export function round(value, decimals) {
  const wasNegative = value < 0
  value = Number(Math.round(Math.abs(value) + 'e' + decimals) + 'e-' + decimals)
  return wasNegative ? -1 * value : value
}

export function objectCopy(input, recursionDepth) {
  // Increment recursion depth to prevent infinite recursion
  // with circular objects
  recursionDepth = recursionDepth ? recursionDepth + 1 : 1

  if (recursionDepth > 30) {
    console.error('max recursion depth exceeded, abandoning object copy')
    return input
  }

  let copy

  switch (true) {

    case (isObject(input)):
      copy = {}
      for (let field in input) {
        copy[field] = objectCopy(input[field], recursionDepth)
      }
      break

    case isArray(input):
      copy = []
      input.forEach(item => {
        copy.push(objectCopy(item, recursionDepth))
      })
      break

    // -bool, null, undefined, string and number are all passed by value
    //  through a = b
    // functions will be passed by reference
    default:
      copy = input
  }
  return copy
}

export function processUnixDateForViewing(dateUnix, format = SystemDateFormat) {
  if (!dateUnix) {
    return '-'
  }
  if (isString(dateUnix)) {
    return '-'
  }
  if (dateUnix === 0) {
    return '-'
  }
  try {
    return moment.unix(dateUnix).format(format)
  } catch (e) {
    return '-'
  }
}

export function processUnixDateTimeForViewing(dateUnix, format = SystemDateTimeFormat) {
  if (!dateUnix) {
    return '-'
  }
  if (isString(dateUnix)) {
    return '-'
  }
  if (dateUnix === 0) {
    return '-'
  }
  try {
    return moment.unix(dateUnix).format(format)
  } catch (e) {
    return '-'
  }
}

/**
 * @return {string}
 */
export function HexToRGBA(hex, opacity) {
  let val = hex
  if (val.startsWith('#')) {
    val = val.substring(1)
  }
  if (val.length > 6) {
    console.log('The hex value is invalid:', hex)
    return 'rgba(255, 255, 255, 1)'
  } else if (val.length < 6) {
    let zero = []
    for (let i = 0; i < 6 - val.length; i++) {
      zero.push('0')
    }
    val = val.concat(val, zero)
  }

  const red = parseInt(val.substr(0, 2), 16)
  const green = parseInt(val.substr(2, 2), 16)
  const blue = parseInt(val.substr(4, 2), 16)
  return 'rgba(' + red + ',' + green + ',' + blue + ',' + opacity + ')'
}

/**
 * SortObject is used to sort objects by a list of sort parameters eg. ["name","-number"]
 * @return {number}
 */
export function SortObjects(item1, item2, sortBy) {
  if (!(isObject(item1) && isObject(item2))) {
    console.error('Invalid items passed to SortObjects.' + item1 + '.' + item2)
    return 0
  }

  if (!isArray(sortBy)) {
    console.error('Invalid sort by array passed to SortObjects.')
    return 0
  }

  if (sortBy.length < 1) {
    console.error('Nothing specified in sortBy')
    return 0
  }

  for (let i in sortBy) {
    let reverse = 1
    if (sortBy.hasOwnProperty(i)) {
      let field = sortBy[i]
      let s = field.charAt(0)
      if (s === '-') {
        reverse = -1
        field = field.substr(1)
      }

      if (!(isNumber(item1[field]) || isString(item1[field]))) {
        console.error('can only sort on string and number values')
        return 0
      }

      if (isNumber(item1[field])) {
        return reverse * SortNumber(item1[field], item2[field])
      }

      if (isString(item1[field])) {
        let result = SortString(item1[field], item2[field])
        if (result !== 0) {
          return result * reverse
        }
      }
    }
  }

  return 0
}

/**
 * @return {number}
 */
export function SortString(item1, item2) {
  if (item1) {
    return item1.localeCompare(item2, 'en', {sensitivity: 'base'})
  }
  return 0
}

/**
 * @return {number}
 */
export function SortNumber(item1, item2) {
  if (item1 > item2) {
    return -1
  }
  if (item1 < item2) {
    return 1
  }
  return 0
}

/**
 * @return {number}
 */
export function SortNumberString(item1, item2) {
  if (isString(item1)) {
    return SortString(item1, item2)
  }
  if (isNumber(item1)) {
    return SortNumber(item1, item2)
  }
}

export function FilterToSortBy(filter = []) {
  return filter.map(f => {
    return `${f.desc ? '-' : ''}${f.id}`
  })
}

export function SortByToFilter(sortBy = []) {
  return sortBy.map(str => {
    let s = str.charAt(0)
    if (s === '-') {
      return {
        id: str.substr(1),
        desc: true,
      }
    }
    return {
      id: str,
      desc: false,
    }
  })
}

export function getErrorMessage(e) {
  if (e.statusText) {
    return e.statusText
  } else if (e.message) {
    return e.message
  } else if (typeof e === 'string') {
    return e
  } else {
    return ''
  }
}

