import { isArray, isFunction, isObject, isString } from 'utils/Utils'
import { UnmarshalCriteria } from 'popcorn-js/search/criteria/Unmarshaller'

export function validateData(columns) {
  if (!isArray(columns)) {
    throw new Error('data prop is not an array')
  }
}

export function validateColumns(columns) {
  if (!isArray(columns)) {
    throw new Error('columns prop is not an array')
  }

  const headers = []

  columns.forEach(column => {
    if (!isObject(column)) {
      throw new Error(`entry in columns array is not an object: ${column}`)
    }

    if (!isString(column.Header)) {
      throw new Error(
        `has Header which is not a string. Problem header: ${column.Header}`
      )
    }

    // check if this header already exists
    if (headers.includes(column.Header)) {
      // If it does check if it has the optional colConfig
      // object passed with 'alwaysVisible' property set to true
      if (
        !isObject(column.colConfig) ||
        (isObject(column.colConfig) && !column.colConfig.alwaysVisible)
      ) {
        throw new Error(`duplicate column Header '${column.Header}' found.
          This can only be done if colConfig object is passed in column
          object with 'alwaysVisible' set to true`)
      }
    }

    // If it doesn't, add it to the headers list for later checks
    headers.push(column.Header)

    if (column.id === undefined) {
      // If column id is not passed
      if (column.accessor === undefined) {
        // and accessor is also not passed,
        // then filterConfig must not be provided
        if (column.filterConfig !== undefined) {
          throw new Error(`filter config cannot be given for a column without an id or accessor.
          problem column Header: ${column.Header}`)
        }
      } else {
        if (!isString(column.accessor)) {
          throw new Error(`when column id is not given but column accessor is, the accessor must be a string.
      problem header: ${column.header}`)
        }
      }
    } else {
      // column.id is passed, confirm that it is a string
      if (!isString(column.id)) {
        throw new Error(`if column id is provided it must be a string.
          problem column header: ${column.Header}`)
      }
      //if id is a valid string, then accessor must be a function
      if (!(isFunction(column.accessor) || isString(column.accessor))) {
        throw new Error(`when column id and accessor are provided, id must be a string
          and accessor must be a function or string. problem header: ${
  column.Header
}`)
      }
    }
  })
}

export function validateColumnConfig(columnConfig, columns) {
  /**
   * validates given configuration. If optional second
   * columns argument is passed then a check will also
   * be done to confirm that the columnConfig is consistent
   * with the columns argument passed
   * Throws an error if the columnConfig is not valid
   */
  // Confirm Type
  if (!isArray(columnConfig)) {
    throw new Error('invalid columnConfig prop: it is not an array')
  }

  // If columns argument passed
  if (columns !== undefined) {
    if (!columnConfigConsistentWithColumns(columnConfig, columns)) {
      throw new Error('column configuration not consistent with columns')
    }
  }
}

export function compareColumnConfigs(conf1, conf2) {
  try {
    validateColumnConfig(conf1)
    validateColumnConfig(conf2)
  } catch (e) {
    return true
  }

  if (conf1.length !== conf2.length) {
    return false
  }

  for (let i = 0; i < conf1.length; i++) {
    if (conf1[i].header === conf2[i].header) {
      if (conf1[i].visible !== conf2[i].visible) {
        return false
      }
    } else {
      return false
    }
  }
  return true
}

export function columnConfigConsistentWithColumns(columnConfig, columns) {
  if (!isArray(columnConfig)) {
    return false
  }
  if (!isArray(columns)) {
    return false
  }

  // Confirm that every column that has a provided header has got an entry in
  // the given column config
  for (let colIdx = 0; colIdx < columns.length; colIdx++) {
    const col = columns[colIdx]
    // If no Header in column object, or if it the expander column,
    // no need to see if column config exists
    if (!col.Header || col.Header === '' || col.expander) {
      // need to make sure that it does not exist in config
      for (let colConfIdx = 0; colConfIdx < columnConfig.length; colConfIdx++) {
        const colConf = columnConfig[colConfIdx]
        if (col.Header === colConf.header) {
          return false
        }
      }
      continue
    }

    // Otherwise there is a Header, we need to check if config exists for it
    // in given column config
    for (let i = 0; i < columnConfig.length; i++) {
      if (columnConfig[i].header === col.Header) {
        break
      }
      if (i === columnConfig.length - 1) {
        // Not found in any of the given column configs
        return false
      }
    }
  }
  // all were found

  // Now consider the problem from the other perspective.
  // There should be no objects in given columnConfig that have a header
  // which is not the same as any Headers in columns
  for (let colConfIdx = 0; colConfIdx < columnConfig.length; colConfIdx++) {
    const colConf = columnConfig[colConfIdx]

    // Otherwise there is a Header, we need to check if config exists for it
    // in given column config
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].Header === colConf.header) {
        break
      }
      if (i === columns.length - 1) {
        // Not found in any of the given column configs
        return false
      }
    }
  }
  // all were found

  return true
}

export function applyColumnConfig(columnConfig, columns) {
  try {
    validateColumnConfig(columnConfig)
    validateColumns(columns)
  } catch (e) {
    console.error(`error applying column config: ${e}`)
    throw new Error(`error applying column config: ${e}`)
  }

  let configuredCols = []

  for (let i = 0; i < columnConfig.length; i++) {
    const colConf = columnConfig[i]

    // Ignore if not set to visible
    if (!colConf.visible) continue

    // Try and find a given column object with col conf header
    for (let j = 0; j < columns.length; j++) {
      const column = columns[j]
      if (column.Header === colConf.header) {
        configuredCols.push(column)
        break
      }
      if (j === columns.length - 1) {
        console.error(
          `unable to find column object with given columnConfig header: ${
            colConf.header
          }`
        )
      }
    }
  }

  // Add Columns Set To Always Visible
  for (let i = 0; i < columns.length; i++) {
    if (columns[i].colConfig && columns[i].colConfig.alwaysVisible) {
      if (columns[i].colConfig.position === 'end') {
        configuredCols = [...configuredCols, columns[i]]
      } else {
        configuredCols = [columns[i], ...configuredCols]
      }
    }
  }

  return configuredCols
}

export function validateInitFindPageCriteria(initFindPageCriteria) {
  const error = err => `invalid initFindPageCriteria: ${err}`
  let unmarshalledCriteria

  // Initial criteria should be an array
  if (!isArray(initFindPageCriteria)) {
    throw error('not an array')
  }

  initFindPageCriteria.forEach((serialisedCrit, idx) => {
    // Each element of the array should be serialised criteria (i.e. strings)
    if (!isString(serialisedCrit)) {
      throw error(
        `expected array of serialised criteria. Array element ${idx}: ${serialisedCrit} is not a string`
      )
    }
  })

  // Unmarshal criteria
  try {
    unmarshalledCriteria = UnmarshalCriteria(initFindPageCriteria)
  } catch (e) {
    throw error(`error when unmarshalling initFindPageCriteria:\n${e}`)
  }

  return unmarshalledCriteria
}
