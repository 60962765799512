import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, withStyles,} from '@material-ui/core'
import {withTheme} from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import {Info as InfoIcon} from '@material-ui/icons'
import {ErrorIcon, SuccessIcon, WarningIcon} from 'components/Icons'
import readingTime from 'reading-time'
import {HexToRGBA} from 'utils/Utils'

const styles = () => ({})

const autoCapitalise = text => {
  if (text) {
    const sentences = text.trim().split('.')
    try {
      const newSentences = []
      for (const sentence of sentences) {
        newSentences.push((sentence.toLowerCase().charAt(0).toUpperCase() + sentence.toLowerCase().substring(1)).trim())
      }
      return newSentences.join('. ').trim()
    } catch {
      return undefined
    }
  }
}

function NotificationSweetAlert(props) {
  const {
    classes,
    errorMessage,
    infoMessage,
    onClose,
    onConfirm,
    options = {},
    successMessage,
    warningMessage,
    errorDismissButtonText,
    hideButton,
    onDismiss,
  } = props

  const [valid, setValid] = useState(true)
  const [showError, setShowError] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  useEffect(() => {
    setValid(validate(successMessage, infoMessage, warningMessage, errorMessage))
    setShowInfo(infoMessage && infoMessage !== '')
    setShowError(errorMessage && errorMessage !== '')
    setShowWarning(warningMessage && warningMessage !== '')
    setShowSuccess(successMessage && successMessage !== '')
  }, [errorMessage, warningMessage, successMessage, infoMessage])

  if (!valid) {
    return (<div/>)
  }

  if (showError) {
    return (
      <Error
        errorDismissButtonText={errorDismissButtonText}
        hideButton={hideButton}
        message={errorMessage}
        onClose={() => {
          onClose()
          onDismiss()
        }}
        open={showError}
      />
    )
  } else if (showWarning) {
    return (
      <Warning
        classes={classes}
        message={warningMessage}
        onClose={onClose}
        onContinue={onConfirm}
        open={showWarning}/>
    )
  } else if (showSuccess) {
    return (
      <Success
        message={successMessage}
        onClose={onClose}
        open={showSuccess}/>
    )
  } else if (showInfo) {
    return (
      <Info
        classes={classes}
        message={infoMessage}
        onClose={onClose}
        onContinue={onConfirm}
        open={showInfo}
        options={options}/>
    )
  } else {
    return <div/>
  }
}

const successDialogStyle = makeStyles(theme => ({
  paper: {
    background: theme.palette.alert.background.success,
    width: 479,
    height: 'auto',
    minHeight: 297,
    textAlign: 'center',
  }
}))
const successStyle = theme => ({
  icon: {
    width: 88,
    height: 88,
  },
  backDrop: {
    background: HexToRGBA(theme.palette.primary.main, 0.7)
  }
})

const Success = withStyles(successStyle)((props) => {
  const {message, open, onClose, classes, theme} = props
  const [timeoutInstance, setTimeoutInstance] = useState(undefined)
  useEffect(() => {
    // create timeout for success only once on component mount
    if (message) {
      const calcTime = readingTime(message).time
      setTimeoutInstance(setTimeout(onClose, message ? (calcTime < 1500 ? 1500 : calcTime) : 0))
    }
  }, [message, onClose])

  return (
    <Dialog
      BackdropProps={{
        classes: {root: classes.backDrop},
      }}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      classes={successDialogStyle(theme)}
      onClose={() => {
        onClose()
        clearTimeout(timeoutInstance)
      }}
      open={open}
    >
      <DialogTitle>
        <SuccessIcon className={classes.icon}/>
        <Typography
          component="p"
          variant={'h4'}>
          Action Confirmed
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant={'body2'}>
          {props.autoFormat ? autoCapitalise(message) : message}
        </Typography>
      </DialogContent>
    </Dialog>
  )
})

const errorDialogStyle = makeStyles(theme => ({
  paper: {
    background: theme.palette.alert.background.error,
    width: 479,
    height: 'auto',
    minHeight: 297,
    textAlign: 'center',
  },
}))
const errorStyle = theme => ({
  icon: {
    width: 88,
    height: 88,
  },
  button: {
    ...theme.palette.alert.error.button,
    width: '50%',
    margin: '0 auto',
    marginBottom: theme.spacing(2)
  },
  backDrop: {
    background: HexToRGBA(theme.palette.primary.main, 0.7)
  }
})

const Error = withStyles(errorStyle)((props) => {
  const {message, open, onClose, classes, theme, errorDismissButtonText, hideButton} = props
  return (
    <Dialog
      BackdropProps={{
        classes: {root: classes.backDrop},
      }}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      classes={errorDialogStyle(theme)}
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        <ErrorIcon className={classes.icon}/>
        <Typography
          component="p"
          variant="h4">
          Action Failed
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant={'body2'}>
          {props.autoFormat ? autoCapitalise(message) : message}
        </Typography>
      </DialogContent>
      <DialogActions>
        {!hideButton &&
          <Button
            className={classes.button}
            color="primary"
            href={''}
            onClick={onClose}>
            {errorDismissButtonText || 'Dismiss'}
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
})

const warningDialogStyle = makeStyles(theme => ({
  paper: {
    background: theme.palette.alert.background.warning,
    width: 479,
    height: 'auto',
    minHeight: 297,
    textAlign: 'center',
  },
}))
const warningStyle = theme => ({
  icon: {
    width: 88,
    height: 88,
  },
  button: {
    ...theme.palette.alert.warning.button,
    width: '50%',
    marginBottom: theme.spacing(2)
  },
  backDrop: {
    background: HexToRGBA(theme.palette.primary.main, 0.7)
  }
})

const Warning = withStyles(warningStyle)((props) => {
  const {message, open, onClose, onContinue, classes, theme} = props
  return (
    <Dialog
      BackdropProps={{
        classes: {root: classes.backDrop},
      }}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      classes={warningDialogStyle(theme)}
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        <WarningIcon className={classes.icon}/>
        <Typography
          component="p"
          variant={'h4'}>
          Warning!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant={'body2'}>
          {props.autoFormat ? autoCapitalise(message) : message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          color="primary"
          href={''}
          onClick={onClose}>
          Cancel
        </Button>
        <Button
          autoFocus
          className={classes.button}
          color="primary"
          href={''}
          onClick={onContinue}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const infoDialogStyle = makeStyles(theme => ({
  paper: {
    background: theme.palette.alert.background.info,
    width: 'auto',
    minWidth: 479,
    height: 'auto',
    minHeight: 297,
    textAlign: 'center',
  },
}))

const infoStyle = theme => ({
  icon: {
    width: 88,
    height: 88,
  },
  button: {
    ...theme.palette.alert.info.button,
    width: '50%',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  backDrop: {
    background: HexToRGBA(theme.palette.primary.main, 0.7)
  }
})

const Info = withStyles(infoStyle)((props) => {
  const {message, open, onClose, onContinue, classes, options, theme} = props
  return (
    <Dialog
      BackdropProps={{
        classes: {root: classes.backDrop},
      }}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      classes={infoDialogStyle(theme)}
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        <InfoIcon className={classes.icon}/>
        <Typography
          component="p"
          variant={'h4'}>
          {options.title || 'Info!'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant={'body2'}>
          {props.autoFormat ? autoCapitalise(message) : message}
        </Typography>
      </DialogContent>
      <DialogActions>
        {!options.hideCancel &&
        <Button
          className={classes.button}
          color="primary"
          href={''}
          onClick={onClose}>
          {options.cancelButtonText || 'Cancel'}
        </Button>
        }
        <Button
          autoFocus
          className={classes.button}
          color="primary"
          href={''}
          onClick={onContinue}>
          {options.continueButtonText || 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const validate = (successMessage, infoMessage, warningMessage, errorMessage) => {
  let total = 0
  if (successMessage && successMessage !== '') {
    total += 1
  }
  if (infoMessage && infoMessage !== '') {
    total += 1
  }
  if (warningMessage && warningMessage !== '') {
    total += 1
  }
  if (errorMessage && errorMessage !== '') {
    total += 1
  }

  if (total > 1) {
    // eslint-disable-next-line no-console
    console.error('Notification SweetAlert has more than one message type set. Only one message can be set at any given instance.')
    // eslint-disable-next-line no-console
    console.error('successMessage: ', successMessage)
    // eslint-disable-next-line no-console
    console.error('infoMessage: ', infoMessage)
    // eslint-disable-next-line no-console
    console.error('warningMessage: ', warningMessage)
    // eslint-disable-next-line no-console
    console.error('errorMessage: ', errorMessage)
    return false
  }

  return true
}

NotificationSweetAlert.propsTypes = {
  classes: PropTypes.object,
  errorMessage: PropTypes.string,
  errorDismissButtonText: PropTypes.string,
  infoMessage: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
  options: PropTypes.object,
  successMessage: PropTypes.string,
  warningMessage: PropTypes.string,
  hideButton: PropTypes.bool,
  autoFormat: PropTypes.bool,
}

NotificationSweetAlert.defaultProps = {
  onClose: () => {},
  onCancel: () => {},
  onDismiss: () => {},
  autoFormat: false,
}

export default withTheme(withStyles(styles)(NotificationSweetAlert))
