import {useEffect, useState} from 'react'
import {InvoiceRecordkeeper} from 'popcorn-js/financial/invoice'
import _ from 'lodash'

export function useInvoiceRecordkeeperFind(
  initialCriteria = [],
  initialQuery = {
    sortBy: [],
    order: [],
    limit: 0,
    offset: 0,
  },
) {
  const [request, setRequest] = useState({
    criteria: initialCriteria,
    query: initialQuery,
  })
  const [response, setResponse] = useState({
    records: [],
    total: 0,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setError('')
      setLoading(true)
      try {
        await new Promise(resolve => setTimeout(resolve, 400))
        const resp = await InvoiceRecordkeeper.find(_.cloneDeep(request))
        setResponse(resp)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error finding invoices', e)
        setError('error finding invoices: ' + (e.message ? e.message : e).toString())
      }
      setLoading(false)
    }
    fetchData().finally()
  }, [request])

  return [{request, response, loading, error}, setRequest]
}
