import {isObject} from 'utils/Utils'
import AuditEntry from '../../audit/Entry'
import NaturalPerson from './NaturalPerson'

class LoginMetaData {
  /**
   * @type {int}
   */
  lastChangeOfPassword = 0
  /**
   * @type {int}
   */
  lastLoginDate = 0
  /**
   * @type {int}
   */
  loginCount = 0
  /**
   * @type {int}
   */
  failedLoginAttemptCount = 0
  /**
   * @type {string}
   */
  previousLoginPassword = ''

  constructor(loginMetaData?: Partial<LoginMetaData>) {
    if (
      (loginMetaData !== undefined) &&
      (isObject(loginMetaData))
    ) {
      try {
        this.initializeLoginMeta(loginMetaData)
      } catch (e) {
        console.error(`error constructing user: ${e}`)
      }
    }
  }

  initializeLoginMeta(loginMetaData?: Partial<LoginMetaData>): void {
    Object.assign(this, loginMetaData);
  }
}

export default class User {
  static camel = 'user'
  static nice = 'user'
  static niceP = 'users'
  static capital = 'User'
  static capitalP = 'Users'
  /**
   * @type {string}
   */
  id = ''
  /**
   * @type {string}
   */
  loginName = ''
  /**
   * @type {string}
   */
  defaultLanguage = ''
  /**
   * @type {string}
   */
  firstName = ''
  /**
   * @type {string}
   */
  lastName = ''
  /**
   * @type {string}
   */
  emailAddress = ''
  /**
   * @type {string}
   */
  primaryTelephoneNumber = ''
  /**
   * @type {string}
   */
  mobilePhone = ''
  /**
   * @type {string}
   */
  roleId = ''
  /**
   * @type {PartyCode}
   */
  partyCode = ''
  /**
   * @type {boolean}
   */
  emailAddressVerified = false
  /**
   * @type {boolean}
   */
  accountLocked = false
  /**
   * @type {boolean}
   */
  loginAllowed = false
  /**
   * @type {string}
   */
  securityAnswer = ''
  /**
   * @type {string}
   */
  securityQuestion = ''
  /**
   * @type {string}
   */
  loginPassword = ''
  /**
   * @type {boolean}
   */
  passwordChangeRequired = false
  /**
   * @type {LoginMetaData}
   */
  loginMetaData: LoginMetaData | {} = {}

  auditEntry = {}
  /**
   * @type {NaturalPerson}
   */
  naturalPerson = {}
  /**
   * @type {string}
   */
  naturalPersonId = ''

  constructor(user?: Partial<User>) {
    if (
      (user !== undefined)
    ) {
      try {
        this.initializeUser(user)
      } catch (e) {
        console.error(`error constructing user: ${e}`)
      }
    }
  }

  initializeUser(user?: Partial<User>): void {
    Object.assign(this, user);

    this.auditEntry = new AuditEntry(user ? user.auditEntry : {})
    this.naturalPerson = new NaturalPerson(user ? user.naturalPerson : {})
    this.loginMetaData = new LoginMetaData(user ? user.loginMetaData : {})
  }
}
