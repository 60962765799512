import React from 'react'
import {Header, ItemEntry} from 'views/History/HistoryLayout'
import {Divider} from '@material-ui/core'
import {retrieveFromList} from 'popcorn-js/search/identifier/utilities'
import IDIdentifier from 'popcorn-js/search/identifier/Id'

const styles = ({
  root: {
    display: 'grid',
    flexGrow: 1,
    rowGap: '2px',
    boxShadow: '',
    // paddingTop: '25px',
    zIndex: 1,
    gridTemplateRows: (() => {
      let space = ''
      for (let i = 0; i < 66; i++) {
        space = space + '32px '
      }
      return space
    })(),
  },
  divider: {
    height: '2px',
    margin: '20px 20px',
  },
  subDivider: {
    height: '1px',
    margin: '16px 40px',
  },
})

const IndividualFields = (individual, fieldType, changedFields = [], addEntityFieldsProps) => {

  return (
    <div>
      <main style={styles.root}>
        <Header
          fieldType={fieldType}
          label={'Individual Information'}
        />
        {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
        <ItemEntry
          changed={changedFields.includes('name')}
          fieldType={fieldType}
          id={'name'}
          label={'Name'}
          value={individual.name || ''}
        />
        <ItemEntry
          changed={changedFields.includes('partyCode')}
          fieldType={fieldType}
          id={'partyCode'}
          label={'Party Code'}
          value={individual.partyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('parentPartyCode')}
          fieldType={fieldType}
          id={'parentPartyCode'}
          label={'Parent Party Code'}
          value={individual.parentPartyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('adminEmail')}
          fieldType={fieldType}
          id={'email'}
          label={'email'}
          value={individual.email || ''}
        />
        <ItemEntry
          changed={changedFields.includes('clientTierId')}
          fieldType={fieldType}
          id={'clientTier'}
          label={'Client Tier'}
          value={individual.clientTierId || ''}
        />
        <ItemEntry
          changed={changedFields.includes('defaultCurrencyId')}
          fieldType={fieldType}
          id={'defaultCurrency'}
          label={'Default Currency'}
          value={(retrieveFromList(new IDIdentifier(individual.defaultCurrencyId), addEntityFieldsProps.currencies || []) || {}).name || ''}
        />
        <ItemEntry
          changed={changedFields.includes('localCurrencyId')}
          fieldType={fieldType}
          id={'localCurrencyId'}
          label={'Local Currency'}
          value={(retrieveFromList(new IDIdentifier(individual.localCurrencyId), addEntityFieldsProps.currencies || []) || {}).name || ''}
        />
        <ItemEntry
          changed={changedFields.includes('currencyPairIds')}
          fieldType={fieldType}
          id={'currencyPairs'}
          label={'Assigned Currency Pairs'}
          value={individual.currencyPairIds || []}
        />
        <ItemEntry
          changed={changedFields.includes('logo')}
          fieldType={fieldType}
          id={'logo'}
          label={'Logo'}
          value={individual.logo || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Revenue Service'}
        />
        <ItemEntry
          changed={changedFields.includes('natureOfBusiness')}
          fieldType={fieldType}
          id={'natureOfBusiness'}
          label={'Nature of Business'}
          value={individual.natureOfBusiness || ''}
        />
        <ItemEntry
          changed={changedFields.includes('incomeTaxNumber')}
          fieldType={fieldType}
          id={'incomeTaxNumber'}
          label={'Income Tax Number'}
          value={individual.incomeTaxNumber || ''}
        />
        <ItemEntry
          changed={changedFields.includes('sourceOfFunds')}
          fieldType={fieldType}
          id={'sourceOfFunds'}
          label={'Source of Funds'}
          value={individual.sourceOfFunds || ''}
        />
        <Divider style={styles.subDivider}/>
        <Header
          fieldType={fieldType}
          label={'Physical address'}
          subheader
        />

        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.residentialAddress')}
          fieldType={fieldType}
          id={'contactDetails.address.residentialAddress'}
          label={'residential Address'}
          value={((individual.contactDetails || {}).address ||
            {}).residentialAddress}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.streetName')}
          fieldType={fieldType}
          id={'contactDetails.address.streetName'}
          label={'Street Name'}
          value={((individual.contactDetails || {}).address ||
            {}).streetName}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.streetNumber')}
          fieldType={fieldType}
          id={'contactDetails.address.streetNumber'}
          label={'Street Number'}
          value={((individual.contactDetails || {}).address ||
            {}).streetNumber}
        />

        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.townName')}
          fieldType={fieldType}
          id={'contactDetails.address.townName'}
          label={'Town Name'}
          value={((individual.contactDetails || {}).address ||
            {}).townName}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.complex')}
          fieldType={fieldType}
          id={'contactDetails.address.complex'}
          label={'Complex'}
          value={((individual.contactDetails || {}).address ||
            {}).complex}
        />

        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.complexName')}
          fieldType={fieldType}
          id={'contactDetails.address.complexName'}
          label={'Complex name'}
          value={((individual.contactDetails || {}).address ||
            {}).complexName}
        />

        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.unitNumber')}
          fieldType={fieldType}
          id={'contactDetails.address.unitNumber'}
          label={'Unit number'}
          value={((individual.contactDetails || {}).address ||
            {}).unitNumber}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.suburb')}
          fieldType={fieldType}
          id={'contactDetails.address.suburb'}
          label={'Suburb'}
          value={((individual.contactDetails || {}).address ||
            {}).suburb}
        />


        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.residentialPostalCode')}
          fieldType={fieldType}
          id={'contactDetails.address.residentialPostalCode'}
          label={'City'}
          value={((individual.contactDetails || {}).address ||
            {}).residentialPostalCode}
        />

        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.country')}
          fieldType={fieldType}
          id={'contactDetails.address.country'}
          label={'Country'}
          value={((individual.contactDetails || {}).address ||
            {}).country}
        />


        <Divider style={styles.subDivider}/>
        <Header
          fieldType={fieldType}
          label={'Postal address'}
          subheader
        />

        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.residentialPostalCode')}
          fieldType={fieldType}
          id={'contactDetails.address.residentialPostalCode'}
          label={'Residential Postal code'}
          value={((individual.contactDetails || {}).address || {}).residentialPostalCode}
        />

        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.postalAddress')}
          fieldType={fieldType}
          id={'contactDetails.address.postalAddress'}
          label={'Postal address'}
          value={((individual.contactDetails || {}).address || {}).postalAddress}
        />


        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.postalNumber')}
          fieldType={fieldType}
          id={'contactDetails.address.postalNumber'}
          label={'Province'}
          value={((individual.contactDetails || {}).address ||
            {}).postalNumber}
        />

        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.address.townName')}
          fieldType={fieldType}
          id={'contactDetails.address.townName'}
          label={'Postal Code'}
          value={((individual.contactDetails || {}).address ||
            {}).townName}
        />


        <Divider style={styles.subDivider}/>
        <Header
          fieldType={fieldType}
          label={'Contact details'}
          subheader
        />
        <ItemEntry
          changed={changedFields.includes('contactDetails.telephoneNumber')}
          fieldType={fieldType}
          id={'contactDetails.telephoneNumber'}
          label={'Telephone Number'}
          value={(individual.contactDetails || {}).telephoneNumber || ''}
        />

        <ItemEntry
          changed={changedFields.includes('contactDetails.cellphoneNumber')}
          fieldType={fieldType}
          id={'contactDetails.cellphoneNumber'}
          label={'Cellphone Number'}
          value={(individual.contactDetails || {}).cellphoneNumber || ''}
        />


        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Payment Policy'}
        />
        <ItemEntry
          changed={changedFields.includes('paymentPolicy.insistence')}
          fieldType={fieldType}
          id={'paymentPolicy.insistence'}
          label={'Insistence'}
          value={(individual.paymentPolicy || {}).insistence || ''}
        />
        <ItemEntry
          changed={changedFields.includes(
            'paymentPolicy.paymentInterval.days')}
          fieldType={fieldType}
          id={'paymentPolicy.paymentInterval'}
          label={'Payment Interval'}
          value={((individual.paymentPolicy || {}).paymentInterval ||
            {}).days || []}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Hedging Policy'}
        />
        <ItemEntry
          changed={changedFields.includes(
            'hedgingPolicy.hedgingTimeBuckets')}
          fieldType={fieldType}
          id="hedgingPolicy"
          label={'Hedging Time Buckets'}
          nestedArray
          value={(individual.hedgingPolicy || []).hedgingTimeBuckets}
        />

        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Audit Entry'}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.username')}
          fieldType={fieldType}
          id={'auditEntry.username'}
          label={'Username'}
          value={(individual.auditEntry || {}).username || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.action')}
          fieldType={fieldType}
          id={'auditEntry.action'}
          label={'Action'}
          value={(individual.auditEntry || {}).action || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.time')}
          fieldType={fieldType}
          id={'auditEntry.time'}
          label={'Time'}
          value={(individual.auditEntry || {}).time || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.version')}
          fieldType={fieldType}
          id={'auditEntry.version'}
          label={'Version'}
          value={(individual.auditEntry || {}).version}
        />
      </main>
    </div>
  )
}

export default IndividualFields
