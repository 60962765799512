import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  withStyles, Button,
} from '@material-ui/core'
import {EXACT_CRITERION, BOOL_CRITERION} from 'popcorn-js/search/criteria/types'
import {HexToRGBA, isFunction} from 'utils/Utils'
import Select, {components} from 'react-select'

const styles = (theme) => ({
  blankFieldsButton: {
    color: theme.palette.text.primary,
    background: theme.palette.primary.main,
  },
})

const selectStyles = (theme) => ({
  valueContainer: styles => ({
    ...styles,
    border: 'none',
    padding: '0 4px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '6px',
  }),
  option: (styles, {isSelected, isFocused}) => ({
    ...styles,
    border: 'none',
    color: isSelected ? theme.palette.text.primary : theme.palette.primary.contrastText,
    backgroundColor: isSelected ? theme.palette.primary.light : isFocused ? HexToRGBA(theme.palette.primary.light, 0.5) :  theme.palette.primary.main,
  }),
  control: styles => ({
    ...styles,
    minHeight: '1px',
    border: 'none',
    padding: '0',
    borderRadius: '0',
    backgroundColor: 'none',
  }),
  input: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    color: theme.palette.text.primary,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
  }),
  singleValue: (styles, {data}) => {
    return ({
      ...styles,
      color: data.label === 'Blank Fields' || data.label === 'start typing...' ? theme.palette.text.secondary : theme.palette.text.primary,
      fontStyle: data.label === 'Blank Fields' || data.label === 'start typing...' ? 'italic' : ''
    })
  },
})

class Bool extends Component {
  constructor(props) {
    super(props)
    if (!props.initValue) {
      this.state = {
        selected: null,
        value: {
          type: Bool.CriteriaType,
          value: {
            field: props.fieldID,
            text: '',
          },
        }
      }
    } else {
      this.state = {
        selected: {value: {label: props.initValue.value.text, value: props.initValue.value.text}},
        value: props.initValue
      }
    }
  }

  static CriteriaType = BOOL_CRITERION

  onChange = (newBoolVal) => {

    const {
      onChange,
      fieldID
    } = this.props

    let {
      value,
    } = this.state

    if (isFunction(onChange)) {
      if ((newBoolVal === '')) {
        onChange(undefined)
      } else {
        if (newBoolVal === 'True') {
          onChange({
            type: Bool.CriteriaType,
            value: {
              field: fieldID,
              value: true,
            },
          })
        }
        if (newBoolVal === 'False') {
          onChange({
            type: Bool.CriteriaType,
            value: {
              field: fieldID,
              value: false,
            },
          })
        }
      }
    }
    this.setState({
      value,
      selected: newBoolVal
    })
  }

  render() {
    const {
      filterConfig,
      theme,
      classes
    } = this.props
    const {
      selected
    } = this.state

    const addInputProps = {}
    if (filterConfig) {
      if (filterConfig.width) {
        addInputProps.style = {
          width: filterConfig.width,
        }
      }
    }
    const MenuList = (props) => {
      return (
        <components.MenuList {...props}>
          <div
            onClick={() => {
              this.setState({
                selected: {value : {label: 'Blank Fields' , value: ''}},
                value:{
                  type: EXACT_CRITERION,
                  value: {
                    field: this.props.fieldID,
                    text: '',
                  },
                }
              })
              if (isFunction(this.props.onChange)) {
                if ((this.props.newBoolVal === '')) {
                  this.props.onChange(undefined)
                } else {
                  if (this.props.newBoolVal === 'True') {
                    this.props.onChange({
                      type: Bool.CriteriaType,
                      value: {
                        field: this.props.fieldID,
                        value: true,
                      },
                    })
                  }
                  if (this.props.newBoolVal === 'False') {
                    this.props.onChange({
                      type: Bool.CriteriaType,
                      value: {
                        field: this.props.fieldID,
                        value: false,
                      },
                    })
                  }
                }
              }
            }}
            style={{
              cursor: 'pointer',
            }}


          >
            <Button
              className={classes.blankFieldsButton}
              size="small">
              Search Blank Fields
            </Button>
          </div>
          {props.children}
        </components.MenuList>
      )
    }

    const selectOptions = filterConfig.options.map(o => ({
      value: o[filterConfig.valueAccessor],
      label: o[filterConfig.displayAccessor],
    }))

    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          justifyItems: 'center',
          gridTemplateRows: '1fr',
        }}>
        <div
          style={{
            alignSelf: 'center',
            width: '100%',
            minWidth: '160px'
          }}
        >
          <Select
            components={{MenuList}}
            isClearable
            menuPosition={'fixed'}
            onChange={selected => this.onChange(selected ? selected.value: '')}
            options={selectOptions}
            styles={selectStyles(theme)}
            value={selected ? selected.value : {value: '', label: 'start typing...'}}
          />
        </div>
      </div>
    )
  }
}

const styledBool = withStyles(styles)(Bool)

styledBool.propTypes = {
  // Not required, will be used to set value on construction
  fieldID: PropTypes.string.isRequired,
  filterConfig: PropTypes.object,
  initValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  showBorder: PropTypes.bool,
  value: PropTypes.object.isRequired,
}

styledBool.defaultProps = {
  showBorder: false,
}

export default withStyles(styles, {withTheme: true})(Bool)
