
import React from 'react'
import PropTypes from 'prop-types'
import {
  CardHeader, IconButton, Tooltip,
  Typography, withStyles, Grid, FormHelperText,
} from '@material-ui/core'
import NotificationSweetAlert
  from 'components/SweetAlert/NotificationSweetAlert'
import ReportCard from 'components/ReportCard/ReportCard'
import {
  TextField as ParsedTextField,
  TextFieldParseTypes,
} from 'components/FormContols'
import {HexToRGBA, objectCopy} from '../../../utils/Utils'
import {CloseIcon, SaveIcon, CancelIcon, EditIcon} from 'components/Icons'
import CountryRecordkeeper from 'popcorn-js/country/recordkeeper'
import {CounterpartyRecordkeeper} from 'popcorn-js/counterparty'
import Select from 'react-select'
import Validator from 'popcorn-js/counterparty/validator'

const styles = theme => ({
  button: {
    marginLeft: '50px',
  },

  primaryAppBar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,

    fontSize: 20,
    fontWeight: '600',
    letterSpacing: '1.1px',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3)',
  },
})

const activeStates = {
  viewing: 'viewing',
  editing: 'editing',
}

class CounterpartyDetail extends React.Component {
  state = {
    activeState: activeStates.viewing,
    loading: false,

    counterparty: {},
    originalCounterparty: {},

    invalidFields: {},

    errorMessage: null,
    successMessage: null,

    countries: [],

    creating: false,
  }

  componentDidMount() {
    const {isNew, partyCode} = this.props

    this.setState({
      counterparty: isNew ? {partyCode} : objectCopy(this.props.counterparty),
      originalCounterparty: isNew ? {partyCode} : objectCopy(this.props.counterparty),
      activeState: isNew ? activeStates.editing : activeStates.viewing,
    })
    this.findCountries()
  }

  findCountries = async () => {
    try {
      const countryFindResponse = await CountryRecordkeeper.find()
      this.setState({countries: countryFindResponse.records})
    } catch (e) {
      console.error('Could not find countries: ', e.message||e)
    }
  }

  handleSave = () => {
    this.validate(()=>{
      const {
        isNew
      } = this.props

      if (isNew) {
        this.handleCreate()
      } else {
        this.handleUpdate()
      }
    })
  }

  handleCreate = async () => {
    const {
      counterparty
    } = this.state

    try {
      const counterpartyCreateResponse = await CounterpartyRecordkeeper.create({counterparty: counterparty})
      this.setState({
        originalCounterparty: objectCopy(counterpartyCreateResponse.counterparty),
        counterparty: objectCopy(counterpartyCreateResponse.counterparty),
        activeState: activeStates.viewing,
        successMessage: 'Counterparty Created'
      },()=>this.props.onSaveSuccess(counterpartyCreateResponse.counterparty))
    } catch (e) {
      this.setState({errorMessage: e.message||e})
    }
  }

  handleUpdate = async () => {
    const {
      counterparty
    } = this.state

    try {
      const counterpartyUpdateResponse = await CounterpartyRecordkeeper.update({
        identifier: {id:counterparty.id},
        counterparty: counterparty,
      })
      this.setState({
        originalCounterparty: objectCopy(counterpartyUpdateResponse.counterparty),
        counterparty: objectCopy(counterpartyUpdateResponse.counterparty),
        activeState: activeStates.viewing,
        successMessage: 'Updated'
      },()=>this.props.onSaveSuccess(counterpartyUpdateResponse.counterparty))
    } catch (e) {
      this.setState({errorMessage: e.message||e})
    }
  }

  handleCancelChanges = () => {
    this.setState({
      counterparty: objectCopy(this.state.counterparty),
      activeState: activeStates.viewing,
    })
  }

  switchToEditMode = () => {
    this.setState({activeState: activeStates.editing})
  }

  handleChanges(field, value) {
    const counterparty = this.state.counterparty
    const invalidFields = this.state.invalidFields

    counterparty[field] = value
    invalidFields[field] = null

    this.setState({counterparty, invalidFields})
  }

  handleNew = () => {
    this.setState({creating: true})
  }

  renderIcons = () => {
    const {activeState} = this.state
    const {readOnly} = this.props

    const items = []
    switch (activeState) {
      case activeStates.viewing:
        if (!readOnly) {
          items.push(
            <IconButton
              key={'edit'}
              onClick={this.switchToEditMode}>
              <Tooltip title={'Edit'}>
                <EditIcon />
              </Tooltip>
            </IconButton>
          )
        }
        items.push(
          <IconButton
            key={'close'}
            onClick={this.props.onClose}>
            <Tooltip title={'Close'}>
              <CloseIcon />
            </Tooltip>
          </IconButton>
        )
        break
      case activeStates.editing:
        items.push(
          <IconButton
            key={'save'}
            onClick={this.handleSave}>
            <Tooltip title={'Save'}>
              <SaveIcon/>
            </Tooltip>
          </IconButton>
        )
        items.push(
          <IconButton
            key={'cancel'}
            onClick={this.handleCancelChanges}>
            <Tooltip title={'Cancel'}>
              <CancelIcon/>
            </Tooltip>
          </IconButton>
        )
        break
      default:
    }
    return items
  }

  handleHideAlert = () => {
    this.setState({
      errorMessage: null,
      successMessage: null,
    })
  }

  render() {
    const {
      errorMessage,
      successMessage,
    } = this.state

    const {
      isNew
    } = this.props

    return (
      <ReportCard>
        <CardHeader
          action={this.renderIcons()}
          title={isNew ? 'Creating Counterparty' : 'Counterparty Detail'}
        />
        {this.renderLayout()}
        <NotificationSweetAlert
          customClass={'configAlert'}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          successMessage={successMessage}
        />
      </ReportCard>

    )
  }

  renderLayout = () => {
    const {
      activeState,
      invalidFields,
      counterparty,
      countries,
    } = this.state

    const {
      currencies,
    } = this.props

    const readOnly = activeState === activeStates.viewing

    const countryOptions = countries.map(c => ({value: c.id, label: c.name}))
    const currencyOptions = currencies.map(c => ({value: c.id, label: c.isoCode}))

    return (
      <div
        style={{
          margin: '30px',
        }}
      >
        <Grid
          container
          direction={'column'}
          spacing={8}>
          <ItemWrapper
            error={invalidFields.name}
            label={'Name'}
          >
            <ParsedTextField
              InputProps={{
                disableUnderline: readOnly,
                readOnly: readOnly,
              }}
              id="externalReference"
              onChange={(e) => this.handleChanges('name', e.target.value)}
              parseType={TextFieldParseTypes.string}
              value={counterparty.name||''}
            />
          </ItemWrapper>
          <ItemWrapper
            error={invalidFields.externalReference}
            label={'External Reference'}
          >
            <ParsedTextField
              InputProps={{
                disableUnderline: readOnly,
                readOnly: readOnly,
              }}
              id="externalReference"
              onChange={(e) => this.handleChanges('externalReference', e.target.value)}
              parseType={TextFieldParseTypes.string}
              value={counterparty.externalReference||''}
            />
          </ItemWrapper>
          <ItemWrapper
            error={invalidFields.countryId}
            label={'Country'}
          > {readOnly &&
            <ParsedTextField
              InputProps={{
                disableUnderline: readOnly,
                readOnly: readOnly,
              }}
              id="countryId"
              parseType={TextFieldParseTypes.string}
              value={(countries.find(c => c.id === counterparty.countryId)||{}).name||counterparty.countryId||''}
            />
            }
            {!readOnly &&
              <Select
                menuPosition={'fixed'}
                onChange={selected => this.handleChanges('countryId', selected.value)}
                options={countryOptions}
                styles={selectStyles(this.props.theme)}
                value={{value: counterparty.countryId, label: (countries.find(c => c.id === counterparty.countryId)||{}).name||counterparty.countryId}}
              />
            }
          </ItemWrapper>
          <ItemWrapper
            error={invalidFields.currencyId}
            label={'Currency'}
          >
            {readOnly &&
            <ParsedTextField
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
              id="currencyId"
              parseType={TextFieldParseTypes.string}
              value={(currencies.find(c=> c.id === counterparty.currencyId)||{}).isoCode||''}
            />
            }
            {!readOnly &&
              <Select
                menuPosition={'fixed'}
                onChange={selected => this.handleChanges('currencyId', selected.value)}
                options={currencyOptions}
                styles={selectStyles(this.props.theme)}
                value={{value: counterparty.currencyId, label: (currencies.find(c => c.id === counterparty.currencyId)||{}).isoCode||counterparty.currencyId}}
              />
            }
          </ItemWrapper>
        </Grid>
      </div>

    )
  }

  validate = async whenValidFunc => {
    const {
      counterparty
    } = this.state

    try {
      await Validator.ValidateCounterparty(counterparty)
      whenValidFunc()
    } catch (invalidFields) {
      if (invalidFields) {
        this.setState({invalidFields})
        return false
      }
    }
  }
}

const ItemWrapper = (props) => {
  return (
    <Grid item>
      <div
        style={{
          height: props.error ? '45px' : '31px',
          display: 'grid',
          gridGap: '20px',
          gridTemplateColumns: '1fr 1fr',
          marginLeft: '40px',
          marginRight: '40px',
        }}
      >
        <Typography variant={'subtitle1'}>
          <div>{props.label}</div>
        </Typography>
        <div>
          {props.children}
          {props.error &&
          <FormHelperText
            error
            id="helperText">
            {props.error}
          </FormHelperText>}
        </div>
      </div>
    </Grid>
  )
}

ItemWrapper.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
}

export default withStyles(styles, {withTheme: true})(CounterpartyDetail)

CounterpartyDetail.defaultProps = {
  counterparties: [],
  currencies: [],
  partyCode: '',
  counterparty: {},
  onClose: () => null,
  onSaveSuccess: () => null,
}

CounterpartyDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  currencies: PropTypes.array,
  isNew: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func,
  partyCode: PropTypes.string.isRequired,
  order: PropTypes.object,
}

const selectStyles = (theme) => ({
  valueContainer: styles => ({
    ...styles,
    border: 'none',
    padding: '0 4px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '6px',
  }),
  option: (styles, {isSelected, isFocused}) => ({
    ...styles,
    border: 'none',
    color: isSelected ? theme.palette.text.primary : theme.palette.primary.contrastText,
    backgroundColor: isSelected ? theme.palette.primary.light : isFocused ? HexToRGBA(theme.palette.primary.light, 0.5) :  theme.palette.primary.main,
  }),
  control: styles => ({
    ...styles,
    minHeight: '1px',
    border: 'none',
    padding: '0',
    borderRadius: '0',
    backgroundColor: 'none',
  }),
  input: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    color: theme.palette.text.primary,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme.palette.primary.main,
  }),
  singleValue: (styles, {data}) => {
    return ({
      ...styles,
      color :data.label === 'start typing...' ? theme.palette.text.secondary : theme.palette.text.primary,
      fontStyle:  data.label === 'start typing...' ? 'italic' : ''
    })
  },
})
