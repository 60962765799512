import {HexToRGBA} from 'utils/Utils'

export const roleDetailStyles = theme => ({
  root: {},
  textField: {},
  inputLabel: {
    '&$inputLabelFocused': {
      color: theme.palette.text.secondary,
    },
  },
  inputLabelFocused: {},

  inputUnderline: {
    '&&:hover:before': {
      borderBottomColor: theme.palette.text.primary,
    },
    '&:before': {
      borderBottomColor: theme.palette.text.secondary,
    },
    '&:after': {
      borderBottomColor: theme.palette.text.primary,
    },
  },

  input: {
    color: HexToRGBA(theme.palette.text.primary, .8),
  },
  inputHover: {},
  list: {},
  cardHeaderRoot: {
    backgroundColor: HexToRGBA(theme.palette.text.primary, .1),
    padding: theme.spacing(1),
  },
})
