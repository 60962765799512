import {jsonRpcRequestRaw} from '../../actions/helper/utilities'
import config from 'react-global-configuration'


const Handler = {
  Find(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Actions-HandlerBasic.Find',
      request
    })
  },

}


export default Handler
