import {Entry as AuditEntry} from 'popcorn-js/audit'
import {isObject} from '../../../utils/Utils'

class TradingDayException {
  /**
   * @type {string}
   */
  id = ''

  /**
   * @type {number}
   */
  date = undefined

  /**
   * @type {string}
   */
  detail = ''

  /**
   * @type {string}
   */
  currencyId = ''

  /**
   * @type {AuditEntry}
   */
  auditEntry = new AuditEntry()

  /**
   * @param {object|TradingDayException} [tradingDayException]
   */
  constructor(tradingDayException) {
    if (tradingDayException !== undefined &&
      (tradingDayException instanceof TradingDayException ||
        isObject(tradingDayException))) {
      try {
        this.id = tradingDayException.id
        this.date = tradingDayException.date
        this.detail = tradingDayException.detail
        this.currencyId = tradingDayException.currencyId
        this.auditEntry = new AuditEntry(tradingDayException.auditEntry)
      } catch (e) {
        console.error(`error constructing trading day exception: ${e}`)
        throw e
      }
    }
  }
}

export default TradingDayException
