import {financialYears} from './financial'

let configuration = {};

const config = {
  get(key) {
    return configuration[key]
  },
  set(conf) {
    configuration = conf
  }
}

export {
  config,
  financialYears
}

