import React from 'react'
import {makeStyles, Theme} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {MdInfo as HelpIcon} from 'react-icons/md'
import {roundScaleAndFormatNumber} from 'views/Home/Company/Dashboard/Util'
import LightTooltip from 'components/LightTooltp/LightTooltip'
import {Currency} from 'popcorn-js/financial/currency/currencyType'
import {GenerateWeekViewExposureReportForCurrencyResponse} from 'popcorn-js/report/generatorTS';

const useStyles = makeStyles((theme: Theme) => ({
  helpIconWrapper: {
    width: '14px',
    height: '14px',
    color: theme.palette.customColors.greyText1,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    marginLeft: theme.spacing(1)
  },
  helpIcon: {
    width: '14px',
    height: '14px',
  },
  root: {
    padding: theme.spacing(),
  },
  sectionLayout: {
    display: 'grid',
    gridTemplateColumns: '90px 240px'
  },
  sectionStyle: {
    color: theme.palette.text.primary
  },
  sectionHeading: {
    display: 'grid',
    gridTemplateColumns: ' max-content 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    gridGap: theme.spacing()
  }
}))

interface ExposureTotalsProps {
  currency: Currency,
  report: GenerateWeekViewExposureReportForCurrencyResponse,
  width: number,
}

const ExposureTotals: React.FC<ExposureTotalsProps> = (props: ExposureTotalsProps) => {
  const classes = useStyles()
  const totals = (props.report || {}).totals || {}
  const currency = props.currency

  const salesDue = totals.salesDue?.totalAmountInCurrency
  const purchaseDue = totals.purchaseDue?.totalAmountInCurrency
  const purchaseOverDue = totals.purchaseOverDue?.totalAmountInCurrency
  const salesOverDue = totals.salesOverDue?.totalAmountInCurrency
  const purchaseTradeOpenBalances = totals.purchaseTradeOpenBalances
  const salesTradeOpenBalances = totals.salesTradeOpenBalances

  const showPurchaseTotals = (purchaseDue !== 0 || purchaseOverDue !== 0 || purchaseTradeOpenBalances !==0)
  const showSalesTotals = (salesDue !== 0 || salesOverDue !== 0 || salesTradeOpenBalances !==0)

  const TotalItem = (props: {
    title: string
    tooltipTitle: string
    value: number
  }) => {
    return (
      <div
        className={classes.sectionLayout}
      >
        <div>
          {roundScaleAndFormatNumber(props.value, currency.symbol, false, 0)}
        </div>
        <div
          className={classes.sectionHeading}
        >
          <div>{props.title}</div>
          <div>
            <LightTooltip
              placement={'top-end'}
              title={props.tooltipTitle}
            >
              <span className={classes.helpIconWrapper}>
                <HelpIcon className={classes.helpIcon}/>
              </span>
            </LightTooltip>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Grid
      alignItems={'center'}
      classes={{root: classes.root}}
      container
      direction={'column'}
      justify="center"
    >
      <Grid
        alignItems={'center'}
        classes={{root: classes.root}}
        container
        direction={'row'}
        justify="center"
        style={{
          width: props.width
        }}
      >
        {showPurchaseTotals && <Grid
          classes={{root: classes.sectionStyle}}
          container
          direction={'row'}
          xs={4}>
          <TotalItem
            title={'Total Purchase Exposure'}
            tooltipTitle={`Sum of all "${currency.isoCode}" Purchase Exposure`}
            value={purchaseDue}
          />
        </Grid>
        }

        {showPurchaseTotals && <Grid
          classes={{root: classes.sectionStyle}}
          container
          direction={'row'}
          xs={4}>
          <TotalItem
            title={'Total Purchase Exposure Overdue'}
            tooltipTitle={`Sum of all "${currency.isoCode}" Purchase Exposure Overdue`}
            value={purchaseOverDue}
          />
        </Grid>
        }
        {showPurchaseTotals &&
        <Grid
          classes={{root: classes.sectionStyle}}
          container
          direction={'row'}
          xs={4}>

          <TotalItem
            title={'Total Purchase Hedge Balances Maturing'}
            tooltipTitle={`Sum of all Available Balances of "${currency.isoCode}" Purchase Hedges`}
            value={purchaseTradeOpenBalances}
          />
        </Grid>}

      </Grid>
      <Grid
        alignItems={'center'}
        classes={{root: classes.root}}
        container
        direction={'row'}
        justify="center"
        style={{
          width: props.width
        }}
      >
        {showSalesTotals && <Grid
          classes={{root: classes.sectionStyle}}
          container
          direction={'row'}
          xs={4}>
          <TotalItem
            title={'Total Sales Exposure'}
            tooltipTitle={`Sum of all "${currency.isoCode}" Sales Exposure`}
            value={salesDue}
          />
        </Grid>
        }

        {showSalesTotals && <Grid
          classes={{root: classes.sectionStyle}}
          container
          direction={'row'}
          xs={4}>
          <TotalItem
            title={'Total Sales Exposure Overdue'}
            tooltipTitle={`Sum of all "${currency.isoCode}" Sales Exposure Overdue`}
            value={salesOverDue}
          />
        </Grid>
        }

        {showSalesTotals && <Grid
          classes={{root: classes.sectionStyle}}
          container
          direction={'row'}
          xs={4}>
          <TotalItem
            title={'Total Sales Hedge Balances Maturing'}
            tooltipTitle={`Sum of all Available Balances of "${currency.isoCode}" Sales Hedges`}
            value={salesTradeOpenBalances}
          />
        </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default ExposureTotals
