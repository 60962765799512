import React, {Component} from 'react'

import {isArray,} from 'utils/Utils'
import {Error as ErrorCard} from 'components/Cards/index'
import {Generator,} from 'popcorn-js/report'
import ActionsHandler from 'popcorn-js/actions/handler'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/styles'

import Actions from './Actions'
import MaturityLadder from './MaturityLadder'
import CoverageRatio from './CoverageRatio'
import PropTypes from 'prop-types';
import FECTable from './FECTable';
import ProcessingBankRecordkeeper from 'popcorn-js/legalEntity/party/processingBank/recordkeeper'
import ProcessingBank from 'popcorn-js/legalEntity/party/processingBank/ProcessingBank';


const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    justifyItems: 'center',
  },

  bottomColumnLayout: {
    display: 'grid',
    gridTemplateColumns: '294px 580px 390px',
  },
})


const mapStateToProps = state => {
  let extendedContext
  try {
    // Try catch to avoid crash if state not populated yet
    extendedContext = state.security.claims.extendedContext
  } catch (e) {
    console.error('state not properly populated', e)
    extendedContext = {}
  }

  let childCompanies = []
  try {
    if (isArray(state.security.claims.extendedContext.companies)) {
      childCompanies = state.security.claims.extendedContext.companies
    }
  } catch (e) {
    console.error('extended context not populated')
  }

  let partyType = ''
  try {
    // Try catch to avoid crash if state not populated yet
    partyType = state.security.claims.context.partyType
  } catch (e) {
    console.error('state not properly populated', e)
  }

  return {
    myParty: state.myParty,
    currencyPairs: state.currencyPair.records,
    currencies: state.currency.records,
    reFetchToggle: state.trade.reFetchToggle,
    extendedContext,
    childCompanies,
    partyType,
    userId: state.security.claims.userId,
    viewPermissions: state.security.viewPermissions || [],
  }
}


class DealingStation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      actionsPage: 0,
      actionsLimit: 3,
      actionsOffset: 0,
      beneficiaries: [],
      coverageRatioDataImportFlag: false,
      coverageRatioDataExportFlag: false,
    }

    this.handleChangePageActions = this.handleChangePageActions.bind(this)
    this.handleResolveClick = this.handleResolveClick.bind(this)
    this.handleShowResolve = this.handleShowResolve.bind(this)
    this.processingBanks = [];
  }

  componentDidMount() {
    this.fetch()
    this.findProcessingBanks().finally();
    setInterval(async () => {
      try {
        const resp = await ActionsHandler.Find()
        this.setState({
          actionsData: resp.records,
          actionsPage: 0,
        })
      } catch (e) {
        console.error('error finding action entries', e)
        this.setState({error: 'error finding action entries'})
      }
      this.setState({tradeStationIsLoading: false})
    }, 300000)
  }

  componentDidUpdate(prevProps) {
    const {currencyPairs: prevCurrencyPairs, reFetchToggle: prevRefetchToggle} = prevProps
    const {currencyPairs, reFetchToggle} = this.props
    if (
      (prevCurrencyPairs && currencyPairs) &&
      (prevCurrencyPairs.length !== currencyPairs.length)
    ) {
      this.fetch()
    }

    if (prevRefetchToggle !== reFetchToggle) {
      this.fetch()
    }
  }

  fetch() {
    this.getMaturityLadderData().finally()
    this.getCoverageRatioData().finally()
    this.getActionsData().finally()
  }

  async findProcessingBanks() {
    this.setState({tradeStationIsLoading: true});
    try {
      const findResponse = await ProcessingBankRecordkeeper.find(
        undefined,
        undefined
      );
      this.processingBanks = findResponse.records.map(
        (pb) => new ProcessingBank(pb)
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error finding processing banks', e);
      this.setState({error: 'error finding processing banks'});
    }
    this.setState({tradeStationIsLoading: false});
  }

  async getMaturityLadderData() {
    try {
      const resp = await Generator.GenerateMaturityLadderReport()
      this.setState({
        maturityLadderData: resp.totals,
        disableColConfig: true,
      })
    } catch (e) {
      console.error('error generating maturity ladder data', e)
      this.setState({maturityError: e.message})
    }
    this.setState({tradeStationIsLoading: false})

  }

  async getCoverageRatioData() {
    try {
      const resp = await Generator.GenerateCoverageRatioReport()
      if (resp.totals.totalsImport) {
        for (let total of resp.totals.totalsImport) {
          if (total.exposure !== 0 || total.hedgeAmount !== 0) {
            console.log('enterIm')
            this.setState({
              coverageRatioDataImportFlag: true
            })
            break
          }
        }
      }
      if (resp.totals.totalsExport) {
        for (let total of resp.totals.totalsExport) {
          if (total.exposure !== 0 || total.hedgeAmount !== 0) {
            this.setState({
              coverageRatioDataExportFlag: true
            })
            break
          }
        }
      }
      this.setState({
        coverageRatioDataImport: resp.totals.totalsImport,
        coverageRatioDataExport: resp.totals.totalsExport,
      })
    } catch (e) {
      console.error('error generating coverage ratio data', e)
      this.setState({coverageError: e.message})
    }
    this.setState({tradeStationIsLoading: false})

  }

  async getActionsData() {
    try {
      const resp = await ActionsHandler.Find()
      this.setState({
        actionsData: resp.records,
        actionsPage: 0,
      })
    } catch (e) {
      console.error('error finding action entries', e)
      this.setState({actionsError: e.message})
    }
    this.setState({tradeStationIsLoading: false})

  }

  handleShowResolve(d) {
    const {actionsData} = this.state
    const found = actionsData.find(a => a.id === d.id)
    if (found) {
      const buttonBool = !found.buttonShow
      let i
      for (i = 0; i < actionsData.length; ++i) {
        actionsData[i].buttonShow = false
      }
      found.buttonShow = buttonBool
      this.setState({
        actionsData: actionsData
      })
    }
  }

  handleResolveClick(d) {
    const {actionsData} = this.state
    const found = actionsData.find(a => a.id === d.id)
    const index = actionsData.indexOf(found);
    if (index > -1) {
      actionsData.splice(index, 1);
    }
    this.setState({
      actionsData: actionsData,
      successMessage: 'Action Resolved',
    })
  }

  handleChangePageActions(event, newPage) {
    const {actionsLimit} = this.state
    this.setState({
      actionsPage: newPage,
      actionsOffset: actionsLimit * newPage,
    })
  }

  render() {
    const {
      currencyPairs,
      classes,
      myParty,
      partyType,
      currencies,
      userId,
    } = this.props
    const {
      error,
      maturityError,
      coverageError,
      actionsError,
      maturityLadderData,
      coverageRatioDataImport,
      coverageRatioDataExport,
      coverageRatioDataImportFlag,
      coverageRatioDataExportFlag,
      actionsData,
      actionsPage,
      actionsLimit,
      actionsOffset,
    } = this.state

    if (error) {
      return <ErrorCard error={error}/>
    }


    return<div
      className={classes.root}
    >
      <div
        id="fecStationRoot"
        style={{
          width: '1263px',
        }}
      >
        <FECTable
          currencyPairs={currencyPairs}
          myParty={myParty}
          partyType={partyType}
          processingBanks={this.processingBanks}
          userId={userId}
        />
        <div
          className={classes.bottomColumnLayout}
        >
          <div
            style={{
              margin: '8px',
              marginLeft: '0px',
            }}>
            {(actionsError) ? <ErrorCard error={actionsError}/>
              : <Actions
                actionsData={actionsData}
                actionsLimit={actionsLimit}
                actionsOffset={actionsOffset}
                actionsPage={actionsPage}
                handleChangePageActions={this.handleChangePageActions}
                handleResolveClick={this.handleResolveClick}
                handleShowResolve={this.handleShowResolve}
              />
            }

          </div>
          <div style={{margin: '8px'}}>
            {(coverageError) ? <ErrorCard error={coverageError}/>
              : <CoverageRatio
                coverageRatioDataExport={coverageRatioDataExport}
                coverageRatioDataImport={coverageRatioDataImport}
                exportFlag={coverageRatioDataExportFlag}
                importFlag={coverageRatioDataImportFlag}
              />}
          </div>
          <div
            style={{
              margin: '8px',
              marginRight: '0px',
            }}>
            {(maturityError) ? <ErrorCard error={maturityError}/>
              : <MaturityLadder
                classes={classes}
                currencies={currencies}
                currencyPairs={currencyPairs}
                maturityLadderData={maturityLadderData}
                myParty={myParty}
              />}
          </div>
        </div>
      </div>
    </div>
  }
}

DealingStation.propTypes = {
  childCompanies: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  currencyPairs: PropTypes.array.isRequired,
  extendedContext: PropTypes.object.isRequired,
  myParty: PropTypes.object.isRequired,
  partyType: PropTypes.string.isRequired,
  reFetchToggle: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  viewPermissions: PropTypes.array.isRequired,
}

export default withStyles(styles, {withTheme: true})(connect(
  mapStateToProps,
  {},
)(DealingStation))




