import React from 'react'
import {Header, ItemEntry} from 'views/History/HistoryLayout'
import {Divider,} from '@material-ui/core'
import moment from 'moment'
import {SystemDateFormat, SystemDateTimeFormat} from 'constants/formats'
import {FormatNumber} from 'utils/TradeUtilities'
import {processUnixDateForViewing} from '../../../utils/Utils'

const styles = (n) => ({
  root: {
    display: 'grid',
    flexGrow: 1,
    rowGap: '2px',
    boxShadow: '',
    // paddingTop: '25px',
    zIndex: 1,
    gridTemplateRows: (() => {
      let space = ''
      for (let i = 0; i < (n || 30); i++) {
        space = space + '32px '
      }
      return space
    })(),
  },
  divider: {
    height: '2px',
    margin: '20px 20px',
  },
  subDivider: {
    height: '1px',
    margin: '16px 40px',
  },
  formField: {
    height: '60px',
    width: '150px',
  },
})

const TradeFields = (
  trade, fieldType, changedFields = [], addEntityFieldsProps) => {

  const propsCurrencyPairs = addEntityFieldsProps.currencyPairs ?
    addEntityFieldsProps.currencyPairs :
    []

  const currencyPair = trade.currencyPairId ? propsCurrencyPairs.find(
    cp => cp.id === trade.currencyPairId) : ''

  const nearLeg = trade.legs && trade.legs.length > 0 ?
    trade.legs[0] :
    undefined
  const farLeg = trade.legs && trade.legs.length > 1 ?
    trade.legs[1] :
    undefined

  const rows = Object.keys(trade).length + 3 +
    (nearLeg ? Object.keys(nearLeg).length + 2 : 0) +
    (farLeg ? Object.keys(farLeg).length + 2 : 0)

  return (
    <div>
      <main style={styles(rows).root}>
        <Header
          fieldType={fieldType}
          label={'Trade Information'}
        />
        {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
        <ItemEntry
          changed={changedFields.includes('number')}
          fieldType={fieldType}
          id={'number'}
          label={'Number'}
          value={trade.number || ''}
        />
        <ItemEntry
          changed={changedFields.includes('date')}
          fieldType={fieldType}
          id={'date'}
          label={'date'}
          value={moment.unix(trade.date).format(SystemDateFormat)}
        />
        <ItemEntry
          changed={changedFields.includes('status')}
          fieldType={fieldType}
          id={'status'}
          label={'Status'}
          value={trade.status || ''}
        />
        <ItemEntry
          changed={changedFields.includes('tradeType')}
          fieldType={fieldType}
          id={'tradeType'}
          label={'Trade Type'}
          value={trade.tradeType || ''}
        />
        <ItemEntry
          changed={changedFields.includes('currencyPairId')}
          fieldType={fieldType}
          id={'currencyPairId'}
          label={'Currency Pair'}
          value={currencyPair ? currencyPair.name : ''}
        />
        <ItemEntry
          changed={changedFields.includes('parentTradeId')}
          fieldType={fieldType}
          id={'parentTradeId'}
          label={'Parent Trade'}
          value={trade.parentTradeId || '-'}
        />
        <ItemEntry
          changed={changedFields.includes('linkedExternalReference')}
          fieldType={fieldType}
          id={'linkedExternalReference'}
          label={'Linked External Reference'}
          value={trade.linkedExternalReference || '-'}
        />

        <ItemEntry
          changed={changedFields.includes('childTradeIds')}
          fieldType={fieldType}
          id={'childTradeCount'}
          label={'Child Trade Count'}
          value={trade.childTradeIds ? trade.childTradeIds.length : 0}
        />
        <ItemEntry
          changed={changedFields.includes('tradingPartyCode')}
          fieldType={fieldType}
          id={'tradingPartyCode'}
          label={'Trading Party'}
          value={trade.tradingPartyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('processingOrgPartyCode')}
          fieldType={fieldType}
          id={'processingOrgPartyCode'}
          label={'Processing Org Party'}
          value={trade.processingOrgPartyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('availableBalance')}
          fieldType={fieldType}
          id={'availableBalance'}
          label={'Available Balance'}
          value={trade.availableBalance || 0}
        />
        {(() => {
          if (nearLeg) {
            return GenerateLeg({
              leg: nearLeg,
              fieldType: fieldType,
              changedFields: changedFields,
              label: 'Near Leg',
            })
          }
        })()}
        {(() => {
          if (farLeg) {
            return GenerateLeg({
              leg: farLeg,
              fieldType: fieldType,
              changedFields: changedFields,
              label: 'Far Leg',
            })
          }
        })()}
        <Divider style={styles().divider}/>
        <Header
          fieldType={fieldType}
          label={'Audit Entry'}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.username')}
          fieldType={fieldType}
          id={'auditEntry.username'}
          label={'User Name'}
          value={(trade.auditEntry || {}).username || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.action')}
          fieldType={fieldType}
          id={'auditEntry.action'}
          label={'Action'}
          value={(trade.auditEntry || {}).action || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.time')}
          fieldType={fieldType}
          id={'auditEntry.time'}
          label={'Time'}
          value={(trade.auditEntry || {}).time ? processUnixDateForViewing((trade.auditEntry || {}).time, SystemDateTimeFormat) : ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.version')}
          fieldType={fieldType}
          id={'auditEntry.version'}
          label={'Version'}
          value={(trade.auditEntry || {}).version}
        />
      </main>
    </div>
  )
}

const GenerateLeg = props => {
  const leg = props.leg
  const fieldType = props.fieldType
  const changedFields = props.changedFields
  const prefix = props.label === 'Near Leg' ? 'leg.0.' : 'leg.1.'
  const list = [
    <Divider
      key={0}
      style={styles().divider}/>,
    <Header
      fieldType={fieldType}
      key={props.label}
      label={props.label}
    />,
    <ItemEntry
      changed={changedFields.includes(`${prefix}bank`)}
      fieldType={fieldType}
      id={`${prefix}Bank`}
      key={`${prefix}Bank`}
      label="Bank"
      value={leg.bank}
    />,
    <ItemEntry
      changed={changedFields.includes(`${prefix}direction`)}
      fieldType={fieldType}
      id={`${prefix}Direction`}
      key={`${prefix}Direction`}
      label="Direction"
      value={leg.direction}
    />,
    <ItemEntry
      changed={changedFields.includes(`${prefix}notionalAmount`)}
      fieldType={fieldType}
      id={`${prefix}NotionalAmount`}
      key={`${prefix}NotionalAmount`}
      label="Notional Amount"
      value={FormatNumber(leg.notionalAmount, true, true)}
    />,
    <ItemEntry
      changed={changedFields.includes(`${prefix}quoteAmount`)}
      fieldType={fieldType}
      id={`${prefix}QuoteAmount`}
      key={`${prefix}QuoteAmount`}
      label="Quote Amount"
      value={FormatNumber(leg.quoteAmount, true, true)}
    />,
    <ItemEntry
      changed={changedFields.includes(`${prefix}spotPrice`)}
      fieldType={fieldType}
      id={`${prefix}SpotPrice`}
      key={`${prefix}SpotPrice`}
      label="Spot Price"
      value={FormatNumber(leg.spotPrice, true, true)}
    />,
    <ItemEntry
      changed={changedFields.includes(`${prefix}forwardPoints`)}
      fieldType={fieldType}
      id={`${prefix}ForwardPoints`}
      key={`${prefix}ForwardPoints`}
      label="Forward Points"
      value={leg.forwardPoints}
    />,
    <ItemEntry
      changed={changedFields.includes(`${prefix}capturePrice`)}
      fieldType={fieldType}
      id={`${prefix}CapturePrice`}
      key={`${prefix}CapturePrice`}
      label="Capture Price"
      value={leg.capturePrice}
    />,
    <ItemEntry
      changed={changedFields.includes(`${prefix}tradeDate`)}
      fieldType={fieldType}
      id={`${prefix}TradeDate`}
      key={`${prefix}TradeDate`}
      label="Trade Date"
      value={moment.unix(leg.tradeDate).format(SystemDateFormat)}
    />,
    <ItemEntry
      changed={changedFields.includes(`${prefix}maturityDate`)}
      fieldType={fieldType}
      id={`${prefix}MaturityDate`}
      key={`${prefix}MaturityDate`}
      label="Maturity Date"
      value={moment.unix(leg.maturityDate).format(SystemDateFormat)}
    />,
  ]
  return list
}

export default TradeFields
