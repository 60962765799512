import React from 'react'
import {connect} from 'react-redux'
import SettlementInstructionStation from 'views/SettlementInstruction/Management/SettlementInstruction'

const SettlementInstructionContainer = props => {
  return (
    <SettlementInstructionStation {...props}/>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

const ConnectedSettlementInstructionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettlementInstructionContainer)

export default ConnectedSettlementInstructionContainer
