import {ContactDetails} from '../ContactDetails'
import BankingDetails from '../BankingDetails'
import Entry from 'popcorn-js/audit/Entry'
import {isObject} from '../../../../utils/Utils'

export default class Broker {
  static camel = 'broker'
  static nice = 'broker'
  static niceP = 'brokers'
  static capital = 'Broker'
  static capitalP = 'Brokers'
  /**
   * @type string
   */
  id = ''
  /**
   * @type string
   */
  name = ''
  /**
   * @type boolean
   */
  enable = false
  /**
   * @type string
   */
  adminEmail = ''
  /**
   * @type string
   */
  customsClientNumber = ''
  /**
   * @type string
   */
  description = ''
  /**
   * @type string
   */
  partyCode = ''
  /**
   * @type string
   */
  sarbAuthNumber = ''
  /**
   * @type string
   */
  logo = ''
  /**
   * @type ContactDetails
   */
  contactDetails = new ContactDetails()
  /**
   * @type BankingDetails
   */
  bankingDetails = new BankingDetails()
  /**
   * @type string
   */
  authorisedSignatory = ''
  /**
   * @type string
   */
  clientTierId = ''
  /**
   * @type [string]
   */
  currencyPairIds = []
  /**
   * @type string
   */
  parentPartyCode = ''
  /**
   * @type Entry
   */
  auditEntry = new Entry()

  /**
   * @type {string[]}
   */
  subsidiaries = []

  constructor(broker) {
    if (broker !== undefined &&
      (broker instanceof Broker ||
        isObject(broker))) {
      try {
        this.id = broker.id
        this.name = broker.name
        this.enable = broker.enable
        this.adminEmail = broker.adminEmail
        this.customsClientNumber = broker.customsClientNumber
        this.description = broker.description
        this.partyCode = broker.partyCode
        this.sarbAuthNumber = broker.sarbAuthNumber
        this.logo = broker.logo
        this.contactDetails = new ContactDetails(broker.contactDetails)
        this.bankingDetails = new BankingDetails(broker.bankingDetails)
        this.authorisedSignatory = broker.authorisedSignatory
        this.clientTierId = broker.clientTierId
        this.currencyPairIds = broker.currencyPairIds
        this.parentPartyCode = broker.parentPartyCode
        this.auditEntry = broker.auditEntry
      } catch (e) {
        console.error(`error constructing broker: ${e}`)
        throw e
      }
    }
  }

}
