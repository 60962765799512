import React from 'react'
import PropTypes from 'prop-types'
import {Button, Dialog, Grid, withStyles,} from '@material-ui/core'

import Theme from 'tmp/Theme'

const styles = theme => ({
  root: {
    fontWeight: '300',
    letterSpacing: '0.4px',
    overflow: 'hidden',
    backgroundColor: 'rgba(29, 42, 54, 0.4)',
  },
  header: {},
  button: {
    backgroundColor: Theme.Color4,
    width: '140px',
  },
  contentArea: {
    backgroundColor: 'rgba(29, 42, 54, 0.4)',
  },
  title: {
    fontSize: 20,
    fontWeight: '600',
    lineHeight: '1.35',
    letterSpacing: '1.1px',
    color: Theme.Primary,
    paddingTop: '8px',
    paddingLeft: '16px',
  }
})

const ErrorProcessingFile = (props) => {
  const {
    classes,
    onAwayClick,
    show,
    errorMessage
  } = props
  return (
    <Dialog
      className={classes.root}
      modal={undefined}
      onClose={() => {
        if (onAwayClick) onAwayClick()
      }}
      open={show}
    >
      <Grid
        container
        direction={'column'}
        justifyItems={'center'}
        spacing={1}
      >
        <Grid
          className={classes.header}
          item>
          <div className={classes.title}>
            Error While Processing File
          </div>
        </Grid>
        <Grid
          className={classes.contentArea}
          item>
          <Grid
            container
            direction={'column'}
            justifyItems={'center'}
            spacing={1}
          >
            <Grid item>
              {errorMessage}
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  if (onAwayClick) onAwayClick()
                }}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

ErrorProcessingFile.propTypes = {
  /**
   * Determines if the dialog is open
   */
  errorMessage: PropTypes.string.isRequired,
  onAwayClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

ErrorProcessingFile.defaultProps = {
  show: false,
}

const StyledErrorProcessingFile = withStyles(styles)(ErrorProcessingFile)

export default StyledErrorProcessingFile
