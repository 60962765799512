import React, {ChangeEvent, useState, useCallback, useEffect, ReactElement} from 'react'
import {
  Grid,
  Card,
  useTheme,
  MenuItem,
  FormControl,
} from '@material-ui/core'
import {LightNumberField, LightSelect, LightInputLabel, LightRateField, LightTextField} from './styledComponents'
import { TradeType } from 'popcorn-js/trade/tradeTypes'
import Big from 'big.js'
import { ProcessingBank } from 'popcorn-js/legalEntity/party'
import { debounce } from 'lodash'


interface RateAndBankInfoProps {
  spotRateChange: (rate: Big | undefined) => void;
  allInRateChange: (rate: Big | undefined) => void;
  forwardPointsChange: (points: Big | undefined) => void;
  bank: ProcessingBank|undefined;
  banks: ProcessingBank[] | undefined;
  bankChange?: (bank: ProcessingBank | undefined) => void;
  interbankRateChange: (interbankRate: Big|undefined) => void;
  bankRateChange: (bankRate: Big|undefined) => void;
  tradeType: TradeType;
}

export const RateAndBankInfo = (props: RateAndBankInfoProps): ReactElement => {
  const {spotRateChange, allInRateChange, forwardPointsChange, bank,
    banks, bankChange, interbankRateChange, bankRateChange, tradeType} = props
  const theme = useTheme()

  const [spotRate, setSpotRate] = useState<Big|undefined>()
  const [allInRate, setAllInRate] = useState<Big|undefined>()
  const [forwardPoints, setForwardPoints] = useState<string|undefined>('')
  const [pointsPrefix, setPointsPrefix] = useState<string|undefined>('')
  const [interbankRate, setInterbankRate] = useState<Big|undefined>()
  const [bankRate, setBankRate] = useState<Big|undefined>()
  
  const allInRateRateUpdateHandler = useCallback(debounce((value: Big|undefined) => allInRateChange(value), 500), []);
  const spotRateUpdateHandler = useCallback(debounce((value: Big|undefined) => spotRateChange(value), 500), []);
  const forwardPointsChangeUpdateHandler = useCallback(debounce((value: Big|undefined) => forwardPointsChange(value), 500), []);
  const interbankRateChangeUpdateHandler = useCallback(debounce((value: Big|undefined) => interbankRateChange(value), 500), []);
  const bankRateChangeUpdateHandler = useCallback(debounce((value: Big|undefined) => bankRateChange(value), 500), []);
  
  
  useEffect(() => {
    allInRateRateUpdateHandler(allInRate)
  }, [allInRate, allInRateRateUpdateHandler])
  useEffect(() => {
    spotRateUpdateHandler(spotRate)
  }, [spotRate, spotRateUpdateHandler])
  useEffect(() => {
    forwardPointsChangeUpdateHandler(forwardPoints ? Big(forwardPoints) : undefined)
  }, [forwardPoints, forwardPointsChangeUpdateHandler])
  useEffect(() => {
    interbankRateChangeUpdateHandler(interbankRate)
  }, [interbankRate, interbankRateChangeUpdateHandler])
  useEffect(() => {
    bankRateChangeUpdateHandler(bankRate)
  }, [bankRate, bankRateChangeUpdateHandler])
  
  
  const handleAllInRateChange = useCallback((value: Big|undefined) => {
    setAllInRate(value)
    if (value && forwardPoints) {
      setSpotRate(value.minus(forwardPoints))
    } else if (value) {
      setSpotRate(value)
    } else {
      setSpotRate(undefined)
    }
  }, [forwardPoints])
  
  const handleSpotRateChange = useCallback((value: Big|undefined) => {
    setSpotRate(value)
    if (value && forwardPoints) {
      setAllInRate(value.plus(forwardPoints))
    } else if (value) {
      setAllInRate(value)
    } else if (forwardPoints) {
      setAllInRate(forwardPoints ? Big(forwardPoints) : undefined)
    } else {
      setAllInRate(undefined)
    }
  }, [forwardPoints])
  
  const parseForwardPoints = useCallback((value: string|undefined): Big|undefined => {
    if (value && value.endsWith('.')) {
      value += '0'
    }
    if (value && !value.startsWith('-')){
      value = pointsPrefix + value
    }
    return value ? Big(value) : undefined
  }, [pointsPrefix])

  const handleKeyPress = (event: any) => {
    if(event.key === '-'){
      if (pointsPrefix === '-'){
        setPointsPrefix('')
      } else {
        setPointsPrefix('-')
      }
    }
    if(event.key === '+'){
      setPointsPrefix('')
    }
  }
  
  const handleForwardPointsChange = useCallback((value: string|undefined) => {

    const newValue = value ? value.trim().replace(/-/g, '') : value
    if (pointsPrefix && newValue && (newValue !== '')) {
      setForwardPoints(pointsPrefix + newValue)
    } else {
      setForwardPoints(newValue)
    }
    const newForwardPoints = parseForwardPoints(newValue)
    if (newForwardPoints && spotRate) {
      setAllInRate(newForwardPoints.plus(spotRate))
    } else if (newForwardPoints) {
      setAllInRate(newForwardPoints)
    } else if (spotRate) {
      setAllInRate(spotRate)
    } else {
      setAllInRate(undefined)
    }
  }, [spotRate, parseForwardPoints, pointsPrefix])
  
  useEffect(() => {
    if (tradeType === TradeType.SPOT) {
      handleForwardPointsChange('')
    }
  }, [tradeType, handleForwardPointsChange])

  return (
    <div
      style={{
        margin: '20px',
        width: '360px',
        height: '250px'
      }}
    >
      {/*
          Spot rate, forward points and all-in rate
        */}
      <div
        style={{
          height: '100%',
          float: 'left',
          width: 'calc(50% - 5px)'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '63%',
          }}
        >
          <Card
            elevation={0}
            style={{
              width: '100%',
              height: '100%',
              padding: '15px',
              backgroundColor: theme.palette.primary.light,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px',
            }}
          >
            <Grid
              container
              spacing={2}>
              <Grid
                item
              >
                <LightNumberField
                  id="spotRate"
                  label="Spot Rate"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => 
                    handleSpotRateChange(event.target.value ? Big(event.target.value) : undefined)}
                  value={spotRate ? spotRate.toFixed(4) : ''}
                />
              </Grid>
              <Grid
                item
              >
                <LightRateField
                  disabled={tradeType === TradeType.SPOT}
                  id="forwardPoints"
                  label="Forward Points"
                  onBlur={() => {
                    setForwardPoints(parseForwardPoints(forwardPoints)?.toFixed(4))
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleForwardPointsChange(event.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder={'-.----'}
                  prefix={pointsPrefix}
                  value={forwardPoints}
                />
              </Grid>
            </Grid>
          </Card>
        </div>
        <div
          style={{
            width: '100%',
            height: '37%'
          }}
        >
          <Card
            elevation={0}
            style={{
              width: '100%',
              height: '100%',
              padding: '15px',
              backgroundColor: theme.palette.primary.main,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: '8px',
              borderBottomLeftRadius: '8px',
            }}
          >
            <Grid container>
              <Grid
                item
              >
                <LightNumberField
                  id="allInRate"
                  label="All-In Rate"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => 
                    handleAllInRateChange(event.target.value ? Big(event.target.value) : undefined)}
                  value={allInRate ? allInRate.toFixed(4) : ''}
                />
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
      {/*
          Bank and interbank rate selection
      */}
      <div
        style={{height: '100%', float: 'right', width: 'calc(50% - 5px)'}}
      >
        <div
          style={{
            width: '100%',
            height: '63%',
            padding: '15px',
          }}
        >
          <Grid
            container
            spacing={2}>
            <Grid
              item
              style={{width: '100%'}}
            >
              {tradeType !== TradeType.CANCELLATION && <FormControl
                style={{width: '100%'}}
              >
                <LightInputLabel>Bank</LightInputLabel>
                <LightSelect
                  id="Bank"
                  onChange={(event: ChangeEvent<{name?: string|undefined, value: unknown}>) =>
                    bankChange ? bankChange(banks?.find((bank: ProcessingBank) => bank.id === event.target.value)) : undefined}
                  style={{width: '100%'}}
                >
                  {banks?.map((bank: ProcessingBank) =>
                    <MenuItem
                      key={bank.partyCode}
                      value={bank.id}>{bank.name}</MenuItem>
                  )}
                </LightSelect>
              </FormControl>}
              {tradeType === TradeType.CANCELLATION && bank &&
              <LightTextField
                disabled
                id={'bank'}
                label={'Bank'}
                style={{width: '100%'}}
                value={bank.name}
              />}
            </Grid>
            <Grid
              item
            >
              <LightNumberField
                id="bankRate"
                label="Bank Rate"
                onChange={(event: ChangeEvent<HTMLInputElement>) => 
                  setBankRate(event.target.value ? Big(event.target.value) : undefined)}
                value={bankRate ? bankRate.toFixed(4) : ''}
              />
            </Grid>
            <Grid
              item
            >
              <LightNumberField
                id="interbankRate"
                label="Interbank Rate"
                onChange={(event: ChangeEvent<HTMLInputElement>) => 
                  setInterbankRate(event.target.value ? Big(event.target.value) : undefined)}
                value={interbankRate ? interbankRate.toFixed(4) : ''}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            width: '100%',
            height: '37%',
          }}
        />
      </div>
    </div>
  )
}
