import {
  RICK_INSTANCE_UPDATE_EVENT,
  RICK_INSTANCES_FIND_SUCCESS,
} from 'actions/actionTypes'

export default function rick(state = {instances: [], total: 0}, action) {
  switch (action.type) {
    case RICK_INSTANCES_FIND_SUCCESS:
      return {
        ...state,
        instances: action.result.records ? action.result.records : [],
        total: action.result.total
      }
    case RICK_INSTANCE_UPDATE_EVENT: {
      let instances = state.instances.slice() || []
      instances = instances.filter(c => {
        return (c.hostname !== action.result.updatedRickInstance.hostname)
      })
      instances.push(action.result.updatedRickInstance)

      return {
        ...state,
        instances,
      }
    }
    default:
      return state
  }
}
