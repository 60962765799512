import React from 'react'
import SIExposureTradeLinkerDialog
  from 'views/SettlementInstruction/Management/SIExposureTradeLinkerDialog/SIExposureTradeLinkerDialog'
import {connect} from 'react-redux'

const SIExposureTradeLinkerDialogContainer = props => {
  return <SIExposureTradeLinkerDialog {...props}/>
}

function mapStateToProps(state) {
  return {currencyPairs: state.currencyPair.records}
}

const ConnectedSiInvoiceTradeLinkerContainer = connect(
  mapStateToProps,
)(SIExposureTradeLinkerDialogContainer)

export default ConnectedSiInvoiceTradeLinkerContainer

