import React from 'react'
import connect from 'react-redux/es/connect/connect'
import CounterpartyDetail from 'components/Detail/counterparty/CounterpartyDetail'

class CounterpartyDetailContainer extends React.Component {
  render() {
    const {
      onClose,
      counterparty,
      currencies,
      onSaveSuccess,
      isNew,
      partyCode,
      readOnly,
    } = this.props

    return (
      <span>
        <CounterpartyDetail
          counterparty={counterparty}
          currencies={currencies}
          isNew={isNew}
          onClose={onClose}
          onSaveSuccess={onSaveSuccess}
          partyCode={partyCode}
          readOnly={readOnly}
        />
      </span>
    )
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currency.records,
    partyCode: state.security.claims.context.partyCode,
  }
}

export default connect(mapStateToProps)(CounterpartyDetailContainer)
