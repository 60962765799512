import {TradeType} from 'popcorn-js/trade/tradeTypes'
import {RadioGroup} from '@material-ui/core'
import React, {ChangeEvent, useState, useCallback, useEffect, ReactElement} from 'react'
import { LightTextField, LightRadio, LightFormControlLabel } from './styledComponents'
import { debounce } from 'lodash'

interface ExternalRefAndTypeProps {
  externalReferenceChange: (externalReference: string) => void;
  tradeType: TradeType;
  tradeTypeChange: (tradeType: TradeType) => void;
}

export const ExternalRefAndType = (props: ExternalRefAndTypeProps): ReactElement => {
  const {externalReferenceChange, tradeTypeChange} = props

  const [externalReference, setExternalReference] = useState<string|undefined>()
  const [tradeType, setTradeType] = useState<TradeType>(props.tradeType)

  const handleExternalReferenceChange = useCallback(debounce((value: string) => externalReferenceChange(value), 500), [])
  const handleTradeTypeChange = useCallback(debounce((value: TradeType) => tradeTypeChange(value), 500), [])

  useEffect(() => {
    handleExternalReferenceChange(externalReference ? externalReference : '')
  }, [externalReference, handleExternalReferenceChange])

  useEffect(() => {
    handleTradeTypeChange(tradeType)
  }, [tradeType, handleTradeTypeChange])

  return (
    <div
      style={{
        margin: '20px',
        height: '50px',
        width: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <LightTextField
        id="externalReference"
        label="External Reference"
        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setExternalReference(event.target.value)}
        style={{marginRight: '5px'}}
        value={externalReference}
      />
      <RadioGroup
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(_: ChangeEvent<HTMLInputElement>, value: string) => setTradeType((TradeType as any)[value])}
        row
        style={{transform: 'translateY(8px)'}}
        value={tradeType}
      >
        <LightFormControlLabel
          control={<LightRadio />}
          label="Spot"
          value="SPOT" />
        <LightFormControlLabel
          control={<LightRadio />}
          label="Forward"
          value="FORWARD" />
      </RadioGroup>
    </div>
  )
}
