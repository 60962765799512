import {AuditEntry} from 'popcorn-js/audit/entryTypes';
import {CurrencyPair} from 'popcorn-js/financial/currencyPair/currencyPairTypes'


export enum TradeType {
  SPOT = 'SPOT',
  FORWARD = 'FORWARD',
  CANCELLATION = 'CANCELLATION',
  DRAWDOWN = 'DRAWDOWN',
  EXTENSION = 'EXTENSION',
}

export enum PartyType {
  SYSTEM = 'SYSTEM',
  PROCESSING_ORG = 'PROCESSING_ORG',
  COMPANY = 'COMPANY',

}

export enum TradeDirection {
  BUY = 'BUY',
  SELL = 'SELL'
}

export enum ImportExport {
  Import = 'Import',
  Export = 'Export'
}

export interface TradeLeg {
  externalReference: string | undefined;
  bank: string | undefined;
  interbankRate: number;
  bankRate: number;
  maturityDate: number;
  quoteAmount: number;
  notionalAmount: number;
  direction: TradeDirection;
  tradeDate: number;
  spotPrice: number;
  forwardPoints: number;
  allInRate: number;
  internalReference?: string | undefined;
  effectiveRate?: number;
}

export interface TradeParent {
  amount: number;
  rate: number;
  parentTradeId: string;
  parentTradeNumber?: string;
  parentNotionalAmount?: number;
  parentACMBalance?: number;
}

export enum TradeStatus {
  OPEN = 'OPEN',
  MATURED = 'MATURED',
  CANCELLED = 'CANCELLED',
}

export interface Trade {
  id?: string;
  auditEntry?: AuditEntry;
  number?: string;
  date?: number;
  status?: TradeStatus;
  legs?: TradeLeg[];
  tradeType?: TradeType;
  currencyPairId?: string;
  tradeParents?: TradeParent[];
  childTradeIds?: string[];
  tradingPartyCode?: string;
  processingOrgPartyCode?: string;
  availableBalance?: number;
  importExport?: string;
  acmBalance?: number;
  ACM?: boolean;
  acmParents?: TradeParent[];
  capturedSpotRate?: number | undefined;
  trader?: string | undefined;
  traderOrganisation?: string | undefined;
  relationshipManager?: string | undefined;
  portfolioManager?: string | undefined;
  notes?: string | undefined;
  financialYear?: string | undefined;
}

export const getRelevantLeg = (t: Trade): TradeLeg|undefined => {
  switch (t.tradeType) {
    case TradeType.SPOT:
    case TradeType.FORWARD:
    case TradeType.DRAWDOWN:
      if (t.legs && t.legs.length > 0) {
        return t.legs[0]
      }
      break
    case TradeType.EXTENSION:
      if (t.legs && t.legs.length > 1) {
        return t.legs[1]
      }
      break
    default:
      return undefined
  }
}

export interface LegAsTrade {
  id: string
  tradeId: string
  number: string
  tradeType: string
  status: TradeStatus
  importExport: string
  currencyPair: CurrencyPair
  externalReference: string
  internalReference: string
  maturityDate: number
  notionalAmount: number
  direction: TradeDirection
  tradeDate: number
  allInRate: number
  effectiveRate: number
  bank: string
  bankRate: number
  interbankRate: number
  quoteAmount: number
  spotPrice: number
  forwardPoints: number
  ACM: boolean
  acmBalance: number
  trader: string
  traderOrganisation: string
  capturedSpotRate: string
  financialYear: string

}

export interface relevantLeg {
  allInRate: number
  bank: string
  bankRate: number
  direction: string
  effectiveRate: number
  externalReference: string
  forwardPoints: number
  interbankRate: number
  internalReference: string
  maturityDate: number
  notionalAmount: number
  quoteAmount: number
  spotPrice: number
  tradeDate: number
}

export interface OpenFECTrade {
  Trade: Trade
  relevantLeg: relevantLeg
  currencyPair: CurrencyPair
}