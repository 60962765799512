
import React from 'react'
import {connect} from 'react-redux'
import PrepaymentDetailDialog from './PrepaymentDetailDialog'

let PrepaymentDetailDialogContainer = props => {
  return <PrepaymentDetailDialog {...props}/>
}

const mapStateToProps = (state) => {
  return {
    currencies: state.currency.records,
  }
}

PrepaymentDetailDialogContainer = connect(
  mapStateToProps,
  {}
)(PrepaymentDetailDialogContainer)

export default PrepaymentDetailDialogContainer
