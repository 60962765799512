import React from 'react'
import {Header, ItemEntry} from 'views/History/HistoryLayout'
import {Divider} from '@material-ui/core'
import {retrieveFromList} from 'popcorn-js/search/identifier/utilities'
import IDIdentifier from 'popcorn-js/search/identifier/Id'

const styles = ({
  root: {
    display: 'grid',
    flexGrow: 1,
    rowGap: '2px',
    boxShadow: '',
    // paddingTop: '25px',
    zIndex: 1,
    gridTemplateRows: (() => {
      let space = ''
      for (let i = 0; i < 66; i++) {
        space = space + '32px '
      }
      return space
    })(),
  },
  divider: {
    height: '2px',
    margin: '20px 20px',
  },
  subDivider: {
    height: '1px',
    margin: '16px 40px',
  },
})

const CompanyFields = (company, fieldType, changedFields = [], addEntityFieldsProps) => {

  return (
    <div>
      <main style={styles.root}>
        <Header
          fieldType={fieldType}
          label={'Company Information'}
        />
        {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
        <ItemEntry
          changed={changedFields.includes('name')}
          fieldType={fieldType}
          id={'name'}
          label={'Name'}
          value={company.name || ''}
        />
        <ItemEntry
          changed={changedFields.includes('partyCode')}
          fieldType={fieldType}
          id={'partyCode'}
          label={'Party Code'}
          value={company.partyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('parentPartyCode')}
          fieldType={fieldType}
          id={'parentPartyCode'}
          label={'Parent Party Code'}
          value={company.parentPartyCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('enable')}
          fieldType={fieldType}
          id={'enable'}
          label={'Enable'}
          value={company['enable'] || ''}
        />
        <ItemEntry
          changed={changedFields.includes('adminEmail')}
          fieldType={fieldType}
          id={'adminEmail'}
          label={'Admin Email'}
          value={company.adminEmail || ''}
        />
        <ItemEntry
          changed={changedFields.includes('registrationNumber')}
          fieldType={fieldType}
          id={'registrationNumber'}
          label={'Registration Number'}
          value={company.registrationNumber || ''}
        />
        <ItemEntry
          changed={changedFields.includes('clientTierId')}
          fieldType={fieldType}
          id={'clientTier'}
          label={'Client Tier'}
          value={company.clientTierId || ''}
        />
        <ItemEntry
          changed={changedFields.includes('defaultCurrencyId')}
          fieldType={fieldType}
          id={'defaultCurrency'}
          label={'Default Currency'}
          value={(retrieveFromList(new IDIdentifier(company.defaultCurrencyId), addEntityFieldsProps ? addEntityFieldsProps.currencies : []) || {}).name || ''}
        />
        <ItemEntry
          changed={changedFields.includes('localCurrencyId')}
          fieldType={fieldType}
          id={'localCurrencyId'}
          label={'Local Currency'}
          value={(retrieveFromList(new IDIdentifier(company.localCurrencyId), addEntityFieldsProps ? addEntityFieldsProps.currencies : []) || {}).name || ''}
        />
        <ItemEntry
          changed={changedFields.includes('currencyPairIds')}
          fieldType={fieldType}
          id={'currencyPairs'}
          label={'Assigned Currency Pairs'}
          value={company.currencyPairIds || []}
        />
        <ItemEntry
          changed={changedFields.includes('logo')}
          fieldType={fieldType}
          id={'logo'}
          label={'Logo'}
          value={company.logo || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Revenue Service'}
        />
        <ItemEntry
          changed={changedFields.includes('natureOfBusiness')}
          fieldType={fieldType}
          id={'natureOfBusiness'}
          label={'Nature of Business'}
          value={company.natureOfBusiness || ''}
        />
        <ItemEntry
          changed={changedFields.includes('incomeTaxNumber')}
          fieldType={fieldType}
          id={'incomeTaxNumber'}
          label={'Income Tax Number'}
          value={company.incomeTaxNumber || ''}
        />
        <ItemEntry
          changed={changedFields.includes('countryOfOrigin')}
          fieldType={fieldType}
          id={'countryOfOrigin'}
          label={'Country of Origin'}
          value={company.countryOfOrigin || ''}
        />
        <ItemEntry
          changed={changedFields.includes('valueAddedTaxNumber')}
          fieldType={fieldType}
          id={'valueAddedTaxNumber'}
          label={'VAT Number'}
          value={company.valueAddedTaxNumber || ''}
        />
        <ItemEntry
          changed={changedFields.includes('customsClientNumber')}
          fieldType={fieldType}
          id={'customsClientNumber'}
          label={'CCN'}
          value={company.customsClientNumber || ''}
        />
        <ItemEntry
          changed={changedFields.includes('sourceOfFunds')}
          fieldType={fieldType}
          id={'sourceOfFunds'}
          label={'Source of Funds'}
          value={company.sourceOfFunds || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Contact Details'}
        />
        <Divider style={styles.subDivider}/>
        <Header
          fieldType={fieldType}
          label={'Physical address'}
          subheader
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.line1')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.line1'}
          label={'Address Line 1'}
          value={((company.contactDetails || {}).physicalAddress ||
            {}).line1}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.line2')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.line2'}
          label={'Address Line 2'}
          value={((company.contactDetails || {}).physicalAddress ||
            {}).line2}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.city')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.city'}
          label={'City'}
          value={((company.contactDetails || {}).physicalAddress ||
            {}).city}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.province')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.province'}
          label={'Province'}
          value={((company.contactDetails || {}).physicalAddress ||
            {}).province}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.postalCode')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.postalCode'}
          label={'Postal Code'}
          value={((company.contactDetails || {}).physicalAddress ||
            {}).postalCode}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.physicalAddress.country')}
          fieldType={fieldType}
          id={'contactDetails.physicalAddress.country'}
          label={'Country'}
          value={((company.contactDetails || {}).physicalAddress ||
            {}).country}
        />
        <Divider style={styles.subDivider}/>
        <Header
          fieldType={fieldType}
          label={'Postal address'}
          subheader
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.line1')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.line1'}
          label={'Address Line 1'}
          value={((company.contactDetails || {}).postalAddress || {}).line1}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.line2')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.line2'}
          label={'Address Line 2'}
          value={((company.contactDetails || {}).postalAddress || {}).line2}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.city')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.city'}
          label={'City'}
          value={((company.contactDetails || {}).postalAddress || {}).city}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.province')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.province'}
          label={'Province'}
          value={((company.contactDetails || {}).postalAddress ||
            {}).province}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.postalCode')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.postalCode'}
          label={'Postal Code'}
          value={((company.contactDetails || {}).postalAddress ||
            {}).postalCode}
        />
        <ItemEntry
          changed={changedFields.includes(
            'contactDetails.postalAddress.country')}
          fieldType={fieldType}
          id={'contactDetails.postalAddress.country'}
          label={'Country'}
          value={((company.contactDetails || {}).postalAddress ||
            {}).country}
        />
        <Divider style={styles.subDivider}/>
        <ItemEntry
          changed={changedFields.includes('contactDetails.telephoneNumber')}
          fieldType={fieldType}
          id={'contactDetails.telephoneNumber'}
          label={'Telephone Number'}
          value={(company.contactDetails || {}).telephoneNumber || ''}
        />
        <ItemEntry
          changed={changedFields.includes('contactDetails.faxNumber')}
          fieldType={fieldType}
          id={'contactDetails.faxNumber'}
          label={'Fax Number'}
          value={(company.contactDetails || {}).faxNumber || ''}
        />
        <ItemEntry
          changed={changedFields.includes('contactDetails.emailAddress')}
          fieldType={fieldType}
          id={'contactDetails.emailAddress'}
          label={'Email Address'}
          value={(company.contactDetails || {}).emailAddress || ''}
        />
        <ItemEntry
          changed={changedFields.includes('contactDetails.website')}
          fieldType={fieldType}
          id={'contactDetails.website'}
          label={'Website'}
          value={(company.contactDetails || {}).website || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Banking Details'}
        />
        <ItemEntry
          changed={changedFields.includes('bankingDetails.bankName')}
          fieldType={fieldType}
          id={'bankingDetails.bankName'}
          label={'Bank Name'}
          value={(company.bankingDetails || {}).bankName || ''}
        />
        <ItemEntry
          changed={changedFields.includes('bankingDetails.branchName')}
          fieldType={fieldType}
          id={'bankingDetails.branchName'}
          label={'Branch Name'}
          value={(company.bankingDetails || {}).branchName || ''}
        />
        <ItemEntry
          changed={changedFields.includes('bankingDetails.branchCode')}
          fieldType={fieldType}
          id={'bankingDetails.branchCode'}
          label={'Branch Code'}
          value={(company.bankingDetails || {}).branchCode || ''}
        />
        <ItemEntry
          changed={changedFields.includes('bankingDetails.bankAccountType')}
          fieldType={fieldType}
          id={'bankingDetails.bankAccountType'}
          label={'Bank Account Type'}
          value={(company.bankingDetails || {}).bankAccountType || ''}
        />
        <ItemEntry
          changed={changedFields.includes(
            'bankingDetails.bankAccountNumber')}
          fieldType={fieldType}
          id={'bankingDetails.bankAccountNumber'}
          label={'Bank Account Number'}
          value={(company.bankingDetails || {}).bankAccountNumber || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Governance'}
        />
        <ItemEntry
          changed={changedFields.includes('authorisedSignatory')}
          fieldType={fieldType}
          id={'authorisedSignatory'}
          label={'Authorised Signatory'}
          value={company.authorisedSignatory || ''}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Payment Policy'}
        />
        <ItemEntry
          changed={changedFields.includes('paymentPolicy.insistence')}
          fieldType={fieldType}
          id={'paymentPolicy.insistence'}
          label={'Insistence'}
          value={(company.paymentPolicy || {}).insistence || ''}
        />
        <ItemEntry
          changed={changedFields.includes(
            'paymentPolicy.paymentInterval.days')}
          fieldType={fieldType}
          id={'paymentPolicy.paymentInterval'}
          label={'Payment Interval'}
          value={((company.paymentPolicy || {}).paymentInterval ||
            {}).days || []}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Hedging Policy'}
        />
        <ItemEntry
          changed={changedFields.includes(
            'hedgingPolicy.hedgingTimeBuckets')}
          fieldType={fieldType}
          id="hedgingPolicy"
          label={'Hedging Time Buckets'}
          nestedArray
          value={(company.hedgingPolicy || []).hedgingTimeBuckets}
        />
        <Divider style={styles.divider}/>
        <Header
          fieldType={fieldType}
          label={'Audit Entry'}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.username')}
          fieldType={fieldType}
          id={'auditEntry.username'}
          label={'Username'}
          value={(company.auditEntry || {}).username || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.action')}
          fieldType={fieldType}
          id={'auditEntry.action'}
          label={'Action'}
          value={(company.auditEntry || {}).action || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.time')}
          fieldType={fieldType}
          id={'auditEntry.time'}
          label={'Time'}
          value={(company.auditEntry || {}).time || ''}
        />
        <ItemEntry
          changed={changedFields.includes('auditEntry.version')}
          fieldType={fieldType}
          id={'auditEntry.version'}
          label={'Version'}
          value={(company.auditEntry || {}).version}
        />
      </main>
    </div>
  )
}

export default CompanyFields
