import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'
import {useLocation} from 'react-router-dom'


const useStyles = makeStyles(() => ({
  breadCrumb: {
    marginLeft: '15px',
    lineHeight: '4',
    fontSize: '0.8rem',
    fontWeight: 'bold',
  }
}))

export default function BreadCrumbs(props) {
  const location = useLocation()
  const classes = useStyles()
  return (
    <Typography
      className={classes.breadCrumb}
      gutterBottom
      variant={'h5'}
    >
      {findRouteName({...props, location})}
    </Typography>
  )
}


function findRouteName(props) {
  let name = ''
  routesLoop:
  for (let route of props.routes) {
    // if route is collapsible
    if (route.collapse) {
      // then consider all of the collapsed routes
      for (let collapsedRoute of route.views) {
        if (collapsedRoute.path === props.location.pathname) {
          name = collapsedRoute.name
          break routesLoop
        }
      }
    }
    if (route.path === props.location.pathname) {
      name = route.name
      break
    }
  }
  return name
}
