import React, { useCallback, useEffect, useState, ReactElement, ReactNode, ChangeEvent } from 'react';
import {
  Card,
  IconButton,
  Tooltip,
  CardHeader,
  useTheme,
  makeStyles,
  createStyles,
  CardContent,
  Collapse,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { lightColor, LightNumberField } from './styledComponents';
import Big from 'big.js';
import { TradeParent, TradeType, Trade, TradeDirection } from 'popcorn-js/trade/tradeTypes';
import { debounce } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      borderRadius: '8px',
      marginBottom: 0,
    },
    cardContentRoot: {
      padding: 0,
      '&:last-child': {
        padding: 0,
      },
    },
    headerTitleText: {
      color: theme.palette.primary.contrastText,
    },
    headerRoot: {
      backgroundColor: theme.palette.paper.default,
    }
  }),
);

interface ParentAllocationsProps {
  parentTrades: Trade[];
  tradeParentsChange: (tradeParents: TradeParent[]) => void;
  totalChange: (total: Big | undefined) => void;
}

export interface parentRow {
  id: string;
  number: string;
  externalReference: string|undefined;
  tradeType: string;
  availableBalance: Big | undefined;
  allocatedAmount: Big | undefined;
  direction: string;
  notionalAmount: Big;
  rate: Big;
}

interface CommonProps {
  children: ReactNode;
}
const HeaderText = (props: CommonProps) =>
  <span
    style={{
      fontWeight: 'bold',
      fontSize: '14px',
      color: lightColor,
      width: 'auto',
    }}
  >
    {props.children}
  </span>

const CellText = (props: CommonProps) =>
  <span
    style={{
      fontSize: '14px',
      fontFamily: 'Roboto',
      color: lightColor,
    }}
  >
    {props.children}
  </span>

const displayAmount = (amount: Big | undefined) => amount ? new Intl.NumberFormat('en-UK', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
}).format(Number(amount)) : ''


export const ParentAllocations = (props: ParentAllocationsProps): ReactElement => {
  const {parentTrades, tradeParentsChange, totalChange} = props

  const [open, setOpen] = useState(true)
  const classes = useStyles(useTheme())
  const theme = useTheme()

  const [parentAllocationsMap, setCancelationParentAllocationsMap] = useState<Map<string, parentRow>>(new Map())

  const handleParentAllocationsChange = useCallback(debounce((value: TradeParent[]) => tradeParentsChange(value), 500), []);

  useEffect(() => {
    handleParentAllocationsChange(Array.from(parentAllocationsMap.values())?.map(
      (parentRow: parentRow) => {
        return {
          amount: Number(parentRow.allocatedAmount),
          rate: Number(parentRow.rate),
          parentTradeId: parentRow.id,
          parentTradeNumber: parentRow.number,
          parentNotionalAmount: Number(parentRow.notionalAmount),
        }
      }))
  }, [parentAllocationsMap, handleParentAllocationsChange])

  const updateCancellationParentAllocatedAmount = useCallback((entry: parentRow, allocatedAmount: Big | undefined) => {
    const newCancellationParentAllocationsMap = new Map(parentAllocationsMap);
    if (allocatedAmount && entry.availableBalance) {
      if (Number(allocatedAmount) > Number(entry.availableBalance)) {
        allocatedAmount = entry.availableBalance
      }
    }
    newCancellationParentAllocationsMap.set(entry.id, {
      ...entry,
      allocatedAmount,
    })
    setCancelationParentAllocationsMap(newCancellationParentAllocationsMap)
  }, [parentAllocationsMap])


  const handleParentTotalAllocatedAmountChange = useCallback(debounce((value: Big|undefined) => totalChange(value), 500), []);

  useEffect(() => {
    handleParentTotalAllocatedAmountChange(Array.from(parentAllocationsMap.values())?.map(
      (parentRow: parentRow) => parentRow.allocatedAmount ? parentRow.allocatedAmount : Big(0)).reduce(
      (total: Big, current: Big) => total.add(current), Big(0)))
  }, [parentAllocationsMap, handleParentTotalAllocatedAmountChange])

  useEffect(() => {
    if (parentTrades) {
      const newCancellationParentAllocationsMap = new Map<string, parentRow>();
      parentTrades.forEach((trade: Trade) => {
        let relevantLeg = 0
        switch (trade.tradeType) {
          case TradeType.FORWARD:
          case TradeType.SPOT:
            relevantLeg = 0
            break
          case TradeType.EXTENSION:
            relevantLeg = 1
            break
        }
        if (trade && trade.id) {
          newCancellationParentAllocationsMap.set(trade.id, {
            allocatedAmount: trade.availableBalance ? Big(trade.availableBalance) : Big(0),
            availableBalance: trade.availableBalance ? Big(trade.availableBalance) : Big(0),
            direction: trade.legs ? trade.legs[relevantLeg].direction : TradeDirection.BUY,
            number: trade.number ? trade.number : '',
            externalReference: trade.legs ? trade.legs[relevantLeg].externalReference : '',
            id: trade.id,
            tradeType: trade.tradeType ? trade.tradeType : TradeType.SPOT,
            notionalAmount: trade.legs ? Big(trade.legs[relevantLeg].notionalAmount) : Big(0),
            rate: trade.legs ? Big(trade.legs[relevantLeg].effectiveRate || 0) : Big(0),
          })
        }
        setCancelationParentAllocationsMap(newCancellationParentAllocationsMap)
      })
    } else {
      setCancelationParentAllocationsMap(new Map<string, parentRow>())
    }

  }, [parentTrades])


  return (
    <div
      style={{
        width: '888px',
      }}
    >
      <Card
        className={classes.root}
        square={false}
      >
        <CardHeader
          action={
            <React.Fragment>
              {open
                ? (
                  <Tooltip title={'Collapse'}>
                    <IconButton
                      onClick={() => setOpen(!open)}
                    >
                      <MdExpandLess/>
                    </IconButton>
                  </Tooltip>
                )
                : (
                  <Tooltip title={'Expand'}>
                    <IconButton
                      onClick={() => setOpen(!open)}
                    >
                      <MdExpandMore/>
                    </IconButton>
                  </Tooltip>
                )
              }
            </React.Fragment>
          }
          classes={{
            root: classes.headerRoot,
          }}
          title={<Typography
            variant={'h5'}>{'Manage Parent Trades'}</Typography>}
          titleTypographyProps={{
            className: classes.headerTitleText,
            variant: 'h6',
          }}
        />
        <Collapse in={open}>
          <CardContent
            classes={{root: classes.cardContentRoot}}
          >
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    width: '100%',
                    backgroundColor: theme.palette.primary.light,
                  }}>
                  <TableCell><HeaderText>External Reference</HeaderText></TableCell>
                  <TableCell><HeaderText>Trade Type</HeaderText></TableCell>
                  <TableCell><HeaderText>Available Balance</HeaderText></TableCell>
                  <TableCell><HeaderText>Buy/Sell</HeaderText></TableCell>
                  <TableCell><HeaderText>Allocated Amount</HeaderText></TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{
                  backgroundColor: theme.palette.primary.main
                }}
              >
                {Array.from(parentAllocationsMap.values())?.map((trade: parentRow) => (
                  <TableRow
                    key={trade.number}
                    style={{backgroundColor: theme.palette.primary.main}}>
                    <TableCell align="left"><CellText>{trade.externalReference}</CellText></TableCell>
                    <TableCell align="left"><CellText>{trade.tradeType}</CellText></TableCell>
                    <TableCell
                      align="left"><CellText>{displayAmount(trade.availableBalance)}</CellText></TableCell>
                    <TableCell align="left"><CellText>{trade.direction}</CellText></TableCell>
                    <TableCell align="left">
                      <LightNumberField
                        id={'allocatedAmount'}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => 
                          updateCancellationParentAllocatedAmount(trade, event.target.value ? Big(event.target.value) : undefined)}
                        style={{width: '150px'}}
                        value={displayAmount(trade.allocatedAmount)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  )
}