import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import {TextField as ParsedTextField, TextFieldParseTypes,} from 'components/FormContols'
import {PARTY_TYPE_BROKER, PARTY_TYPE_COMPANY, PARTY_TYPE_PROCESSING_ORG,} from '../../constants/partyTypes'

const RevenueServiceCard = (props) => {

  const x = renderTable[props.partyType] || []

  return (
    <div>
      <Card
        style={{
          marginBottom: '10px',
          marginTop: '20px',
        }}>

        <CardHeader title={props.title}/>
        <CardContent>
          <Grid
            container
            direction={'column'}
            style={{paddingLeft: '40px'}}>
            {(x.includes(FIELD_NATURE_OF_BUSINESS) ||
              x.includes(FIELD_NATURE_OF_BUSINESS + FIELD_DISABLED)) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange ||
                x.includes(FIELD_NATURE_OF_BUSINESS + FIELD_DISABLED)}
                error={!!props.invalidFields.natureOfBusiness}
                fullWidth
                helperText={props.invalidFields.natureOfBusiness || ' '}
                id="natureOfBusiness"
                label="Nature of Business"
                margin={props.margin}
                onChange={(e) => props.onChange('natureOfBusiness',
                  e.target.value)}
                value={(props.party.natureOfBusiness ?
                  props.party.natureOfBusiness :
                  '')}
              />
            </Grid>
            }
            {(x.includes(FIELD_COUNTRY_OF_ORIGIN) ||
              x.includes(FIELD_COUNTRY_OF_ORIGIN + FIELD_DISABLED)) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange ||
                x.includes(FIELD_COUNTRY_OF_ORIGIN + FIELD_DISABLED)}
                error={!!props.invalidFields.countryOfOrigin}
                fullWidth
                helperText={props.invalidFields.countryOfOrigin || ' '}
                id="countryOfOrigin"
                label="Country of Origin"
                margin={props.margin}
                onChange={(e) => props.onChange('countryOfOrigin',
                  e.target.value)}
                value={(props.party.countryOfOrigin ?
                  props.party.countryOfOrigin :
                  '')}
              />
            </Grid>
            }
            {(x.includes(FIELD_INCOME_TAX_NUMBER) ||
              x.includes(FIELD_INCOME_TAX_NUMBER + FIELD_DISABLED)) &&
            <Grid item>
              <ParsedTextField
                disabled={props.disabled || !props.onChange ||
                x.includes(FIELD_INCOME_TAX_NUMBER + FIELD_DISABLED)}
                disallowNegative
                error={!!props.invalidFields.incomeTaxNumber}
                fullWidth
                helperText={props.invalidFields.incomeTaxNumber || ' '}
                id="incomeTaxNumber"
                label="Income Tax Number"
                margin={props.margin}
                onChange={(e) => props.onChange('incomeTaxNumber',
                  e.target.value)}
                parseType={TextFieldParseTypes.int}
                value={(props.party.incomeTaxNumber ?
                  props.party.incomeTaxNumber.toString() :
                  '')}
              />
            </Grid>
            }
            {(x.includes(FIELD_VALUE_ADDED_TAX_NUMBER) ||
              x.includes(FIELD_VALUE_ADDED_TAX_NUMBER + FIELD_DISABLED)) &&
            <Grid item>
              <ParsedTextField
                disabled={props.disabled || !props.onChange ||
                x.includes(FIELD_VALUE_ADDED_TAX_NUMBER + FIELD_DISABLED)}
                disallowNegative
                error={!!props.invalidFields.valueAddedTaxNumber}
                fullWidth
                helperText={props.invalidFields.valueAddedTaxNumber || ' '}
                id="valueAddedTaxNumber"
                label="Value Added Tax Number"
                margin={props.margin}
                onChange={(e) => props.onChange('valueAddedTaxNumber',
                  e.target.value)}
                parseType={TextFieldParseTypes.int}
                value={props.party.valueAddedTaxNumber ?
                  props.party.valueAddedTaxNumber.toString() :
                  ''}
              />
            </Grid>
            }
            {(x.includes(FIELD_CUSTOMS_CLIENT_NUMBER) ||
              x.includes(FIELD_CUSTOMS_CLIENT_NUMBER + FIELD_DISABLED)) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange ||
                x.includes(FIELD_CUSTOMS_CLIENT_NUMBER + FIELD_DISABLED)}
                error={!!props.invalidFields.customsClientNumber}
                fullWidth
                helperText={props.invalidFields.customsClientNumber || ' '}
                id="customsClientNumber"
                label="Customs Client Number"
                margin={props.margin}
                onChange={(e) => props.onChange('customsClientNumber',
                  e.target.value)}
                value={props.party.customsClientNumber ?
                  props.party.customsClientNumber.toString() :
                  ''}
              />
            </Grid>
            }
            {(x.includes(FIELD_SOURCE_OF_FUNDS) ||
              x.includes(FIELD_SOURCE_OF_FUNDS + FIELD_DISABLED)) &&
            <Grid item>
              <TextField
                disabled={props.disabled || !props.onChange ||
                x.includes(FIELD_SOURCE_OF_FUNDS + FIELD_DISABLED)}
                error={!!props.invalidFields.sourceOfFunds}
                fullWidth
                helperText={props.invalidFields.sourceOfFunds || ' '}
                id="sourceOfFunds"
                label="Source of Funds"
                margin={props.margin}
                onChange={(e) => props.onChange('sourceOfFunds',
                  e.target.value)}
                value={props.party.sourceOfFunds ?
                  props.party.sourceOfFunds :
                  ''}
              />
            </Grid>
            }
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

RevenueServiceCard.propTypes = {
  disabled: PropTypes.bool,
  invalidFields: PropTypes.objectOf(PropTypes.string),
  margin: PropTypes.oneOf(['dense', 'normal']),
  onChange: PropTypes.func,
  party: PropTypes.object,
  partyType: PropTypes.oneOf([PARTY_TYPE_BROKER, PARTY_TYPE_COMPANY, PARTY_TYPE_PROCESSING_ORG]),
  title: PropTypes.string,
}

RevenueServiceCard.defaultProps = {
  party: {},
  invalidFields: {},
  margin: 'normal',
  disabled: false,
  title: 'Revenue Service',
}

export default RevenueServiceCard

const FIELD_NATURE_OF_BUSINESS = 'natureOfBusiness'
const FIELD_COUNTRY_OF_ORIGIN = 'countryOfOrigin'
const FIELD_INCOME_TAX_NUMBER = 'incomeTaxNumber'
const FIELD_VALUE_ADDED_TAX_NUMBER = 'valueAddedTaxNumber'
const FIELD_CUSTOMS_CLIENT_NUMBER = 'customsClientNumber'
const FIELD_SOURCE_OF_FUNDS = 'sourceOfFunds'

const FIELD_DISABLED = '.disabled'

const renderTable = {
  [PARTY_TYPE_BROKER]: [
    FIELD_CUSTOMS_CLIENT_NUMBER,
  ],
  [PARTY_TYPE_PROCESSING_ORG]: [
    FIELD_CUSTOMS_CLIENT_NUMBER,
  ],
  [PARTY_TYPE_COMPANY]: [
    FIELD_INCOME_TAX_NUMBER,
    FIELD_VALUE_ADDED_TAX_NUMBER,
    FIELD_SOURCE_OF_FUNDS,
    FIELD_NATURE_OF_BUSINESS,
    FIELD_COUNTRY_OF_ORIGIN,
    FIELD_CUSTOMS_CLIENT_NUMBER,
  ]
}
