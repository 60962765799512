import {ALL_CRITERION_TYPES} from 'popcorn-js/search/criteria/types'

const error = (msg) => `failed to unmarshal given serialized criteria:\n${msg}`

export function UnmarshalCriteria(serializedCriteria) {
  const criteria = []

  if (!Array.isArray(serializedCriteria)) {
    throw error('expected array of serialized criteria')
  }

  for (let i = 0; i < serializedCriteria.length; i++) {
    const s = serializedCriteria[i]

    // Validate the serialised version
    const err = validateSerialisedCriteria(s)
    if (err) throw error(err)

    // Split it up to get parts
    const criteriaParts = s.split('::')
    const critType = criteriaParts[0]
    const critVal = criteriaParts[1]

    // Check for valid type
    if (!ALL_CRITERION_TYPES.includes(critType))
      throw error(`invalid criteria type: ${critType}`)

    // Try and unmarshal criteria value
    let critValObj = {}
    try {
      critValObj = JSON.parse(critVal)
    } catch (e) {
      throw error(`unable to unmarshal criteria value\n${e}`)
    }

    if (!critValObj.field) {
      console.error('invalid unmarshalled criteria object. No Field:', critValObj)
      throw error(`invalid unmarshalled criteria object. No Field: ${critValObj}`)
    }

    criteria.push({
      type: critType,
      value: critValObj,
    })

  }

  return criteria
}

function validateSerialisedCriteria(s) {
  if (s === '') {
    return 'blank criteria'
  }

  if (s.length < 4) {
    return 'criteria must be at least 4 characters long'
  }

  if (!s.includes('::')) {
    return 'criteria does not include \'::\''
  }

  if (s.split('::').length !== 2) {
    return 'invalid format, more than 1 \'::\''
  }
}
