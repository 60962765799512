import React, { ReactElement, useState, useEffect } from 'react'
import {Dialog, Card, CardContent} from '@material-ui/core'
import { useStyletron } from 'styletron-react'
import SettlementInstructionSummary from './SettlementInstructionSummary'
import { SettlementInstruction } from 'popcorn-js/financial/settlementInstruction'
import Downloader from 'popcorn-js/financial/settlementInstruction/downloader.js';
import { saveAs } from 'file-saver'
import {useService} from 'hooks/useService';
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert';

const SettlementInstructionSummaryDialog = (props: {
  SI: SettlementInstruction;
  show: boolean;
  onClose: () => void;
}): ReactElement => {
  const { onClose, show, SI } = props

  const [css] = useStyletron()

  const [downloadSummaryStarted, setDownloadSummaryStarted] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string|undefined>()

  /* ---------------------------------------------------------------------
     download summary 
     --------------------------------------------------------------------- */
  const [
    {
      response: downloadSIResponse,
      loading: downloadSILoading,
      error: downloadSIError,
    },
    setDownloadSIRequest,
  ] = useService(null, Downloader.downloadConfirmation)
  const handleDownloadSummary = () => {
    if (SI) {
      setDownloadSummaryStarted(true)
      setDownloadSIRequest(SI?.id)
    }
  }
  useEffect(() => {
    if (downloadSIResponse && !downloadSILoading && downloadSummaryStarted) {
      setDownloadSummaryStarted(false)
      try {
        const binData = atob(downloadSIResponse.data)
        const bytes = new Array(binData.length)
        for (let i = 0; i < binData.length; i++) {
          bytes[i] = binData.charCodeAt(i)
        }
        const blob = new Blob([new Uint8Array(bytes)], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
        })
        saveAs(blob, `Settlement Instruction Confirmation ${SI.number}.xlsx`)
      } catch (e) {
        console.error('error downloading SI summary', e)
        setErrorMessage(e)
      }
    }
  }, [downloadSIResponse, downloadSILoading, downloadSummaryStarted, SI.number])
  useEffect(() => {
    if (downloadSIError && !downloadSILoading && downloadSummaryStarted) {
      setDownloadSummaryStarted(false)
      setErrorMessage('Failed to download SI summary: ' + downloadSIError)
    }
  }, [downloadSIError, downloadSILoading, downloadSummaryStarted])

  return (
    <Dialog
      PaperProps={{
        className: css({overflowY: 'hidden'})
      }}
      maxWidth={'lg'}
      onClose={onClose}
      open={show}
    >
      <Card
        className={css({
          padding: '0 20px',
          height: 'calc(100vh - 200px)',
          width: '730px'
        })}
      >
        <CardContent className={css({height: '100%'})}>
          <SettlementInstructionSummary
            SI={SI}
            onDownload={handleDownloadSummary}
            readOnly
          />
        </CardContent>
      </Card>
      <NotificationSweetAlert
        errorMessage={errorMessage}
        id={'general-notification'}
        onClose={() => {
          setErrorMessage(undefined)
        }}
      />
    </Dialog>
  )
}

export default SettlementInstructionSummaryDialog
