import {
  POPCORN_INSTANCE_DELETE_ERROR,
  POPCORN_INSTANCE_DELETE_STARTED,
  POPCORN_INSTANCE_DELETE_SUCCESS,
  POPCORN_INSTANCE_FIND_ERROR,
  POPCORN_INSTANCE_FIND_STARTED,
  POPCORN_INSTANCE_FIND_SUCCESS,
} from '../actions/actionTypes'

export default function popcorn(state = {instances: [], total: 0, action: {}}, action) {
  switch (action.type) {
    case POPCORN_INSTANCE_FIND_SUCCESS:
      return {
        ...state,
        instances: action.result.instances ? action.result.instances : [],
        total: action.result.total
      }
    case POPCORN_INSTANCE_FIND_STARTED:
      return {...state, action: {...state.action, error: undefined}}
    case POPCORN_INSTANCE_FIND_ERROR:
      return {...state, action: {...state.action, error: action.error}}


    case POPCORN_INSTANCE_DELETE_SUCCESS: {
      let instances = state.instances.slice() || []
      instances = instances.filter(c => c.hostname !== action.result.instance.hostname)
      return {...state, instances, action: {...state.action, error: undefined}}
    }
    case POPCORN_INSTANCE_DELETE_STARTED:
      return {...state, action: {...state.action, error: undefined}}
    case POPCORN_INSTANCE_DELETE_ERROR:
      return {...state, action: {...state.action, error: action.error}}

    default:
      return state
  }
}
