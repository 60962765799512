import React from 'react';
import { withStyles } from '@material-ui/core';
import { ComponentLevelError } from 'components/Error/Error';
import OrderDetailDialog from 'components/Detail/order/OrderDialog';
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert';
import OrderHistory from '../History/OrderHistory/OrderContainer';
import OrderImportContainer from 'components/ImportExport/ImportDialogs/order/OrderImporterContainer';
import { Recordkeeper as OrderRecordkeeper } from 'popcorn-js/financial/order/recordkeeper';
import Handler from 'popcorn-js/financial/order/handler';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import { InvoiceRecordkeeper } from 'popcorn-js/financial/invoice';
import { FormatNumber } from 'utils/TradeUtilities';
import XLSX from 'xlsx';
import {
  HexToRGBA,
  processUnixDateForViewing,
  processUnixDateTimeForViewing,
} from 'utils/Utils';
import PropTypes from 'prop-types';
import {
  MdAddCircle,
  MdDelete,
  MdFolder,
  MdKeyboardArrowLeft as BackIcon,
  MdRemoveRedEye,
  MdRestore,
  MdUnarchive,
} from 'react-icons/md';
import OrderExporter from '../../popcorn-js/financial/order/exporter';
import saveAs from 'file-saver';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import AndileTable from '../../components/AndileMaterialUITable/AndileTable';
import { ALL_ORDER_STATUSES } from 'popcorn-js/financial/order/orderType';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { financialYears } from 'popcorn-js/financial';
import {
  DATE_CRITERION,
  NUMBER_CRITERION,
  TEXT_CRITERION,
  EXACT_CRITERION,
} from '../../popcorn-js/search/criteria/types';
import { exactCriterion } from '../../popcorn-js/search/criteria';
import {
  orderDirectionOptions,
  orderImportExportOptions,
  orderTypeOptions,
} from '../../popcorn-js/financial/order/orderType';
import SalesPurchaseSelectDialog from './SalesPurchaseSelectDialog';

const styles = (theme) => ({
  menuItem: {
    '&:hover': {
      background: `linear-gradient(90deg, ${theme.palette.highActions} 70%, ${theme.palette.secondary.main} 90%)`,
    },
    padding: '0px',
    paddingBottom: '0px',
    minHeight: 0,
  },
  headerMenuItem: {
    padding: 0,
    minWidth: '100px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.3)}`,
    pointerEvents: 'none',
  },
  menuItemDiv: {
    width: '100%',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    opacity: 0.5,
    borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.5)}`,
  },
  headerMenuItemDiv: {
    fontSize: 17,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  iconActive: {
    color: 'white',
  },
});

const safeRender = (accessor, formatter = (value) => value) => (rowData) => {
  try {
    return formatter(rowData[accessor]);
  } catch (e) {
    return '-';
  }
};

const initialPageSize = 15;
const rowsPerPageOptions = [5, 10, 15, 20, 25, 30];
const initialCriteria = {
  financialYear: {
    type: TEXT_CRITERION,
    value: {
      field: 'financialYear',
      text: 'CURRENT',
    },
  },
};

class OrderStation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDelete: false,
      loading: false,
      showDetail: undefined,
      showSalesPurchaseSelectDialog: false,
      fileUpload: false,
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
      showHistory: false,
      popoverAnchorEl: undefined,
      showImportDialog: false,
      query: {
        sortBy: [],
        order: [],
        limit: initialPageSize,
        offset: 0,
      },
      orders: [],
      orderCount: 0,
      counterparties: [],
      criteria: Object.keys(initialCriteria).map((field) => {
        return initialCriteria[field];
      }),
      invoices: [],
      componentLevelErrorMessage: undefined,
      orderTypeForFileUpload: undefined,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleChangeSorting = this.handleChangeSorting.bind(this);
  }

  static downloadOrderTemplate() {
    /* create dummy data */
    const data = [
      {
        /* A */ Number: 'ABC123',
        /* B */ Counterparty: 'CreditorABC',
        /* C */ AmountDue: 100000.0,
        /* D */ Currency: 'USD',
        /* E */ CostCurrency: 'ZAR',
        /* F */ LinkedInvoice: '',
        /* G */ CostingRate: 15.15,
        /* H */ FinancialYear: 'CURRENT',
        /* I */ DueDate: new Date(),
        /* J */ IssueDate: new Date(),
        /* K */ Notes: 'Coffee beans',
        /* L */ Status: 'PAID',
        /* M */ ShipmentReference: 'Container number XYZ789',
        /* N */ ShippingDate: new Date(),
      },
      {
        Number: 'ABC124',
        Counterparty: 'CreditorXYZ',
        AmountDue: 200000.0,
        Currency: 'EUR',
        CostCurrency: 'ZAR',
        LinkedInvoice: '',
        CostingRate: 16.15,
        FinancialYear: '2018',
        DueDate: new Date(),
        IssueDate: new Date(),
        Notes: 'Sugar',
        Status: 'PAID',
        ShipmentReference: 'Container number XYZ780',
        ShippingDate: new Date(),
      },
    ];

    /* create a new workbook */
    const workbook = XLSX.utils.book_new();

    /* create the worksheet */
    const worksheet = XLSX.utils.json_to_sheet(data);

    /* set some formatting */
    worksheet['A1']['v'] = 'Number';
    worksheet['B1']['v'] = 'Counterparty';
    worksheet['C1']['v'] = 'Amount Due';
    worksheet['D1']['v'] = 'Currency';
    worksheet['E1']['v'] = 'Cost Currency';
    worksheet['F1']['v'] = 'Linked Invoice';
    worksheet['G1']['v'] = 'Costing Rate';
    worksheet['H1']['v'] = 'Financial Year';
    worksheet['I1']['v'] = 'Due Date';
    worksheet['J1']['v'] = 'Issue Date';
    worksheet['K1']['v'] = 'Notes';
    worksheet['L1']['v'] = 'Status';
    worksheet['M1']['v'] = 'Shipment Reference';
    worksheet['N1']['v'] = 'Shipping Date';

    worksheet['D2']['z'] = '#,##0.00';
    worksheet['D3']['z'] = '#,##0.00';

    /* add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');

    /* download the workbook */
    XLSX.writeFile(workbook, 'orders.xlsx');
  }

  componentDidMount() {
    this.fetch().finally();
  }

  async fetch() {
    await new Promise((resolve) => setTimeout(resolve, 400));
    await this.findOrders();
    this.findInvoices();
    this.findOrderCounterparties();
  }

  async findOrders() {
    const { criteria, query, showDelete } = this.state;
    this.setState({ loading: true });

    const poFindResponse = await Handler.Find({
      criteria : criteria,
      Query: query,
      deleted: showDelete,
    });
    this.setState({
      orders: poFindResponse.records,
      orderCount: poFindResponse.total,
      loading: false,
    });
  }

  async findInvoices() {
    const { orders } = this.state;
    this.setState({ loading: true });

    const invoiceCrit = orders
      .filter((b) => b.invoiceId !== '')
      .map((b) => ({
        type: exactCriterion,
        value: { field: 'id', text: b.invoiceId },
      }));

    const invoiceFindResponse =
      invoiceCrit.length > 0
        ? await InvoiceRecordkeeper.find({ criteria: invoiceCrit })
        : { records: [] };

    this.setState({
      invoices: invoiceFindResponse.records,
      loading: false,
    });
  }

  async findOrderCounterparties() {
    const { orders } = this.state;
    this.setState({ loading: true });

    const counterpartyFindCrit = orders
      .filter((b) => b.counterpartyId !== '')
      .map((b) => ({
        type: EXACT_CRITERION,
        value: { field: 'id', text: b.counterpartyId },
      }));

    const counterpartyFindResponse =
      counterpartyFindCrit.length > 0
        ? await CounterpartyRecordkeeper.find({
          criteria: counterpartyFindCrit,
          query: undefined,
          Deleted: false,
        })
        : { records: [] };

    this.setState({
      counterparties: counterpartyFindResponse.records,
      loading: false,
    });
  }

  generateCounterpartyOptions = async (criterion) => {
    const criteria = [criterion];
    try {
      return await CounterpartyRecordkeeper.find({
        criteria: criteria,
        query: { sortBy: ['name'], order: [], limit: 20, offset: 0 },
        Deleted: false,
      });
    } catch (e) {
      throw e.message || e;
    }
  };

  generateLinkedInvoicesOptions = async (criterion) => {
    const criteria = [criterion];
    try {
      return await InvoiceRecordkeeper.find({
        criteria: criteria,
        query: { sortBy: ['number'], order: [], limit: 20, offset: 0 },
      });
    } catch (e) {
      throw e.message || e;
    }
  };

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    });
  };

  renderDialogs() {
    const {
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      showDetail,
      showHistory,
      showImportDialog,
      showSalesPurchaseSelectDialog,
      popoverAnchorEl,
      orderTypeForFileUpload,
      fileUpload,
    } = this.state;

    const { currencies } = this.props;

    return (
      <span>
        {showDetail && (
          <OrderDetailDialog
            isNew={!showDetail.id}
            onClose={() => this.setState({ showDetail: undefined })}
            onSaveSuccess={() =>
              this.setState({ successMessage: 'Order Saved' }, () => {
                this.fetch().finally();
                this.setState({ showDetail: undefined });
              })
            }
            order={showDetail}
            show
          />
        )}
        {showSalesPurchaseSelectDialog && (
          <SalesPurchaseSelectDialog
            dialogHeading={
              fileUpload ? 'Upload Order Type' : 'Record New Order'
            }
            onClose={() =>
              this.setState({
                popoverAnchorEl: undefined,
                showSalesPurchaseSelectDialog: false,
                fileUpload: false,
              })
            }
            onSelect={(orderType) => {
              fileUpload
                ? this.setState({
                  popoverAnchorEl: undefined,
                  showSalesPurchaseSelectDialog: false,
                  fileUpload: false,
                  orderTypeForFileUpload: orderType,
                  showImportDialog: true,
                })
                : this.setState({
                  popoverAnchorEl: undefined,
                  showSalesPurchaseSelectDialog: false,
                  fileUpload: false,
                  showDetail: { type: orderType },
                });
            }}
            popoverAnchorEl={popoverAnchorEl}
          />
        )}
        <NotificationSweetAlert
          customClass={'configAlert'}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}
          successMessage={successMessage}
          warningMessage={warningMessage}
        />
        {showHistory && (
          <OrderHistory
            onHide={() => this.setState({ showHistory: false })}
            open={showHistory}
            order={showHistory}
          />
        )}
        {showImportDialog && (
          <OrderImportContainer
            currencies={currencies}
            onAwayClick={() => this.setState({ showImportDialog: false })}
            orderType={orderTypeForFileUpload}
            orderTypeForFileUpload={orderTypeForFileUpload}
            show={showImportDialog}
            showImportDialog={() =>
              this.setState({
                showImportDialog: true,
              })
            }
            uploadSuccess={() =>
              this.setState({ successMessage: 'Orders Saved' }, () =>
                this.fetch()
              )
            }
          />
        )}
      </span>
    );
  }

  showDeleteConfirmation = (rowInfo) => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: `You are about to delete order '${rowInfo.number}'. Do you want to continue?`,
      confirmationMethod: () => {
        this.setState({ loading: true });
        try {
          OrderRecordkeeper.delete({ identifier: { id: rowInfo.id } })
            .then(() =>
              this.setState(
                {
                  successMessage: 'Order Deleted',
                  warningMessage: undefined,
                  confirmationMethod: undefined,
                  errorMessage: undefined,
                },
                () => this.fetch().finally()
              )
            )
            .catch((e) =>
              this.setState({
                successMessage: undefined,
                warningMessage: undefined,
                confirmationMethod: undefined,
                errorMessage: e.message || e,
              })
            )
            .finally(() => this.setState({ loading: false }));
        } catch (e) {
          this.setState({
            successMessage: undefined,
            warningMessage: undefined,
            confirmationMethod: undefined,
            errorMessage: e.message || e,
          });
        }
      },
    });
  };

  generateRowActions = (rowInfo) => {
    if (rowInfo.auditEntry.action === 'DELETED') {
      return [
        {
          icon: <MdUnarchive />,
          tooltip: 'Restore',
          onClick: () => {
            this.setState({ loading: true });
            try {
              OrderRecordkeeper.restore({ identifier: { id: rowInfo.id } })
                .then(() => {
                  this.setState({
                    successMessage: 'Order Restored',
                  });
                  this.fetch();
                })
                .catch((e) =>
                  this.setState({
                    errorMessage: e.message || e,
                  })
                )
                .finally(() => this.setState({ loading: false }));
            } catch (e) {
              this.setState({
                errorMessage: e.message || e,
              });
            }
          },
        },
      ];
    } else {
      return [
        {
          icon: <MdRestore />,
          tooltip: 'History',
          onClick: () => {
            this.setState({
              showHistory: rowInfo,
            });
          },
        },
        {
          icon: <MdDelete />,
          tooltip: 'Delete',
          onClick: () => {
            this.showDeleteConfirmation(rowInfo);
          },
        },
        {
          icon: <MdRemoveRedEye />,
          tooltip: 'Details',
          onClick: () => {
            this.setState({
              showDetail: rowInfo,
            });
          },
        },
      ];
    }
  };

  async export() {
    const { criteria, showDelete } = this.state;

    this.setState({ loading: true });
    try {
      const exportResponse = await OrderExporter.export(criteria, showDelete);
      // convert base64 to byte array
      const binData = atob(exportResponse.data);
      const bytes = new Array(binData.length);
      for (let i = 0; i < binData.length; i++) {
        bytes[i] = binData.charCodeAt(i);
      }

      const blob = new Blob([new Uint8Array(bytes)], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
      });

      saveAs(blob, 'purchase_order_export.xlsx');
    } catch (e) {
      this.setState({ errorMessage: e.message || e });
    }
    this.setState({ loading: false });
  }

  generateFreeActions() {
    const { classes } = this.props;
    const { showDelete } = this.state;

    if (showDelete) {
      return [
        <Tooltip title="Return">
          <IconButton
            aria-label="return"
            onClick={() => {
              this.setState(
                {
                  showDelete: false,
                },
                () => this.fetch()
              );
            }}
          >
            <BackIcon />
          </IconButton>
        </Tooltip>,
      ];
    }

    return [
      <Tooltip title="New">
        <IconButton
          aria-label="addNew"
          onClick={(event) =>
            this.setState({
              popoverAnchorEl: event.currentTarget,
              showSalesPurchaseSelectDialog: true,
            })
          }
        >
          <MdAddCircle
            className={classNames({
              [classes.iconActive]: this.state.showDetail,
            })}
          />
        </IconButton>
      </Tooltip>,
      <Tooltip title="View Deleted Items">
        <IconButton
          aria-label="viewArchive"
          onClick={() => {
            this.setState(
              {
                showDelete: true,
              },
              () => this.fetch()
            );
          }}
        >
          <MdFolder />
        </IconButton>
      </Tooltip>,
    ];
  }

  generateMenu() {
    const { classes } = this.props;

    const actions = [];
    actions.push(
      {
        title: 'Download Template',
        onClick: () => {
          OrderStation.downloadOrderTemplate();
        },
      },
      {
        title: 'Import File',
        onClick: (event) => {
          this.setState({
            popoverAnchorEl: event.currentTarget,
            showSalesPurchaseSelectDialog: true,
            fileUpload: true,
          });
        },
      },
      {
        title: 'Export File',
        onClick: () => {
          this.export();
        },
      }
    );
    return (
      <MenuList>
        <MenuItem
          className={classes.headerMenuItem}
          key={'Header'}>
          <div className={classes.headerMenuItemDiv}>Actions</div>
        </MenuItem>
        {actions.map((item, idx) => (
          <MenuItem
            className={classes.menuItem}
            key={idx}
            onClick={item.onClick}
          >
            <div className={classes.menuItemDiv}>{item.title}</div>
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  handleChangeSorting(sortBy, order) {
    const { query } = this.state;

    const newQuery = {
      ...query,
      sortBy: [sortBy],
      order: [order],
    };

    this.setState(
      {
        query: newQuery,
      },
      () => this.fetch()
    );
  }

  handleFilterChange(newCrit) {
    const { query } = this.state;
    const newQuery = {
      ...query,
      offset: 0,
    };

    this.setState(
      {
        query: newQuery,
        criteria: newCrit,
      },
      () => this.fetch().finally()
    );
  }

  handleChangeRowsPerPage(event) {
    const rowsPerPage = event.target.value;
    const newQuery = {
      sortBy: [],
      order: [],
      limit: rowsPerPage,
      offset: 0,
    };
    this.setState(
      {
        query: newQuery,
      },
      () => this.fetch()
    );
  }

  handleChangePage(event, newPage) {
    const { query } = this.state;
    const offset = query.limit * newPage;
    const newQuery = {
      ...query,
      offset,
    };
    this.setState(
      {
        query: newQuery,
      },
      () => this.fetch()
    );
  }

  render() {
    const { componentLevelErrorMessage } = this.state;

    if (componentLevelErrorMessage) {
      return this.renderError(componentLevelErrorMessage);
    }
    try {
      return this.renderMain();
    } catch (e) {
      return this.renderError('Unexpected Error Occurred');
    }
  }

  renderError = (errorMessage) => {
    return <ComponentLevelError errorMessage={errorMessage} />;
  };

  renderMain() {
    const {
      showDelete,
      query,
      loading,
      orders,
      orderCount,
      counterparties,
      invoices,
    } = this.state;

    const { currencies } = this.props;

    return (
      <div>
        {this.renderDialogs()}
        <AndileTable
          columns={[
            {
              title: 'Type',
              field: 'type',
              filter: {
                options: orderTypeOptions.map((f) => ({ name: f })),
                displayAccessor: 'name',
                valueAccessor: 'name',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Import/Export',
              field: 'importExport',
              filter: {
                options: orderImportExportOptions.map((f) => ({ name: f })),
                displayAccessor: 'name',
                valueAccessor: 'name',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Direction',
              field: 'direction',
              filter: {
                options: orderDirectionOptions.map((f) => ({ name: f })),
                displayAccessor: 'name',
                valueAccessor: 'name',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Number',
              field: 'number',
              filter: { type: TEXT_CRITERION },
            },
            {
              title: 'Counterparty',
              field: 'counterpartyId',
              disableSort: true,
              filter: {
                type: TEXT_CRITERION,
                asyncOptionsFetcher: this.generateCounterpartyOptions,
                defaultOptions: counterparties ? counterparties : [],
                displayAccessor: 'name',
                valueAccessor: 'id',
              },
              render: (rowData) => {
                if (!rowData.counterpartyId) {
                  return '-';
                }
                const counterPartyName = (
                  counterparties.find((b) => b.id === rowData.counterpartyId) ||
                  {}
                ).name;
                return counterPartyName
                  ? counterPartyName
                  : rowData.counterpartyId;
              },
            },
            {
              title: 'Financial Year',
              field: 'financialYear',
              filter: {
                options: financialYears.map((f) => ({ name: f })),
                displayAccessor: 'name',
                valueAccessor: 'name',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Amount Due',
              field: 'amountDue',
              filter: { type: NUMBER_CRITERION },
              render: safeRender('amountDue', (amountDue) =>
                FormatNumber(amountDue, true, true, 2, true)
              ),
            },
            {
              title: 'Status',
              field: 'status',
              filter: {
                options: ALL_ORDER_STATUSES.map((value) => ({ value })),
                displayAccessor: 'value',
                valueAccessor: 'value',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Currency',
              field: 'currencyId',
              disableSort: true,
              render: safeRender(
                'currencyId',
                (currencyId) =>
                  currencies.find((c) => c.id === currencyId).isoCode
              ),
              filter: {
                options: currencies,
                displayAccessor: 'isoCode',
                valueAccessor: 'id',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Cost Currency',
              field: 'costCurrencyId',
              disableSort: true,
              render: safeRender(
                'costCurrencyId',
                (costCurrencyId) =>
                  currencies.find((c) => c.id === costCurrencyId).isoCode
              ),
              filter: {
                options: currencies,
                displayAccessor: 'isoCode',
                valueAccessor: 'id',
                type: TEXT_CRITERION,
              },
            },
            {
              title: 'Costing Rate',
              field: 'costingRate',
              filter: { type: NUMBER_CRITERION },
              render: safeRender('costingRate', (costingRate) =>
                FormatNumber(costingRate, true, true, 4, true)
              ),
            },
            {
              title: 'Capture Rate',
              field: 'captureRate',
              filter: { type: NUMBER_CRITERION },
              render: safeRender('captureRate', (captureRate) =>
                FormatNumber(captureRate, true, true, 4, true)
              ),
            },
            {
              title: 'Linked Invoice',
              field: 'invoiceId',
              disableSort: true,
              filter: {
                type: TEXT_CRITERION,
                asyncOptionsFetcher: this.generateLinkedInvoicesOptions,
                defaultOptions: invoices ? invoices : [],
                displayAccessor: 'number',
                valueAccessor: 'id',
              },
              render: (rowData) => {
                if (rowData.invoiceId) {
                  return (
                    (invoices.find((c) => c.id === rowData.invoiceId) || {})
                      .number || rowData.invoiceId
                  );
                } else {
                  return '-';
                }
              },
            },
            {
              title: 'Due Date',
              field: 'dueDate',
              render: safeRender('dueDate', processUnixDateForViewing),
              filter: { type: DATE_CRITERION },
            },
            {
              title: 'Shipping Date',
              field: 'shippingDate',
              filter: { type: DATE_CRITERION },
              render: safeRender('shippingDate', (shippingDate) =>
                processUnixDateForViewing(shippingDate)
              ),
            },
            {
              title: 'Shipment Reference',
              field: 'shipmentReference',
              filter: { type: TEXT_CRITERION },
            },
            {
              title: 'Issue Date',
              field: 'issueDate',
              filter: { type: DATE_CRITERION },
              render: safeRender('issueDate', (issueDate) =>
                processUnixDateForViewing(issueDate)
              ),
            },
            {
              title: 'Last Modified',
              field: 'auditEntry.time',
              render: safeRender('auditEntry', (auditEntry) =>
                processUnixDateTimeForViewing(auditEntry.time)
              ),
            },
          ]}
          count={orderCount}
          data={orders}
          defaultColConfig={[
            { header: 'Type', visible: true },
            { header: 'Import/Export', visible: true },
            { header: 'Direction', visible: true },
            { header: 'Number', visible: true },
            { header: 'Counterparty', visible: true },
            { header: 'Financial Year', visible: false },
            { header: 'Amount Due', visible: true },
            { header: 'Status', visible: true },
            { header: 'Currency', visible: true },
            { header: 'Cost Currency', visible: false },
            { header: 'Costing Rate', visible: false },
            { header: 'Capture Rate', visible: false },
            { header: 'Linked Invoice', visible: true },
            { header: 'Due Date', visible: true },
            { header: 'Shipping Date', visible: false },
            { header: 'Shipment Reference', visible: false },
            { header: 'Issue Date', visible: false },
            { header: 'Last Modified', visible: false },
          ]}
          freeActions={this.generateFreeActions()}
          freeActionsMenu={this.generateMenu()}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          initialCriteria={initialCriteria}
          loading={loading}
          maxTableHeight={600}
          onChangeSorting={this.handleChangeSorting}
          onFilterChange={this.handleFilterChange}
          order={query.order.length > 0 ? query.order[0] : undefined}
          page={Math.ceil(query.offset / query.limit)}
          rowActions={this.generateRowActions}
          rowDoubleClickAction={(rowData) =>
            this.setState({
              showDetail: rowData,
            })
          }
          rowsPerPage={query.limit}
          rowsPerPageOptions={rowsPerPageOptions}
          sortBy={query.sortBy.length > 0 ? query.sortBy[0] : undefined}
          tableID={'OrderStationTable'}
          title={showDelete ? 'Deleted Orders' : 'Orders'}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(OrderStation);

OrderStation.propTypes = {
  currencies: PropTypes.array.isRequired,
};
