import {jsonRpcRequest} from 'jsonRpc/jsonRpcReqest'
import {config} from 'popcorn-js/index'

export interface Amount {
  costCurrencyAmount: number,
  avgCostingRate: number,
  costingRateAvgTotal: number,
  currencyAmount: number,
}

export interface Item {
  perCurrency: Record<string, Amount>,
  total: Amount,
}

export interface Aggregation {
  categoryName: string,
  startDate: number,
  endDate: number,

  invoiceUnRealisedImport: Item,
  invoiceUnRealisedExport: Item,
  invoiceUnRealisedAll: Item,

  invoiceOverdueImport: Item,
  invoiceOverdueExport: Item,
  invoiceOverdueAll: Item,

  invoiceTotalImport: Item,
  invoiceTotalExport: Item,
  invoiceTotalAll: Item,

  tradeRealised: Amount,
  allTradeUnrealised: Amount,

  openTradeBalanceAll: number,
  openTradeBalanceImport: number,
  openTradeBalanceExport: number,

  netCashFlow: number,
}

export interface Total {
  totalAmountInCurrency: number
  totalAmountInCostCurrency: number
}

export interface Totals {
  purchaseDue: Total
  salesDue: Total
  purchaseOverDue: Total
  salesOverDue: Total
  purchaseHedge: Total
  salesHedge: Total
  purchaseSalesHedge: Total
  invoicesDue: Total
  ordersDue: Total
  totalOpenTradeBalances: number
  salesTradeOpenBalances: number
  purchaseTradeOpenBalances: number
}

export interface NetTradeCashFlow {
  month: string,
  realisedTotal: number,
  pendingTotal: number,
  extensions: number,
  cancellations: number,
  drawDowns: number,
  forecasted: number,
}


// GenerateMonthViewCashFlowReport
export interface GenerateMonthViewCashFlowReportResponse {
  excelData: string,
  totals: Totals,
  netTradeCashFlowsThisMonth: NetTradeCashFlow,
  aggregations: Array<Aggregation>
}

export interface GenerateCashFlowReportRequest {
  startDate? : number
  endDate? : number
}

// GenerateWeekViewCashFlowReport
export interface GenerateWeekViewCashFlowReportResponse {
  excelData: string,
  totals: Totals,
  netTradeCashFlowsThisMonth: NetTradeCashFlow,
  aggregations: Array<Aggregation>
}

// GenerateMonthViewExposureReportForCurrency
export interface GenerateMonthViewExposureReportForCurrencyResponse {
  excelData: string,
  totals: Totals,
  aggregations: Array<Aggregation>
}

interface GenerateMonthViewExposureReportForCurrencyRequest {
  currencyId: string
  startDate: number
  endDate: number
}

// GenerateWeekViewExposureReportForCurrency
export interface GenerateWeekViewExposureReportForCurrencyResponse {
  excelData: string,
  totals: Totals,
  aggregations: Array<Aggregation>
}

export interface GenerateWeekViewExposureReportForCurrencyRequest {
  currencyId: string
  startDate: number
  endDate: number
}

export interface SingleRateResult {
  withCostingRate: number;
  withCaptureRate: number;
  withSpotRate: number;
}

export interface ImportExportReportItem {
	import: number;
	export: number;
}

export interface GenerateDollarPortfolioReportResponse {
  percentageHedgedImports:   number;
  percentageHedgedExports:   number;
  grossImportExposureAtSpot: number;
  grossExportExposureAtSpot: number;
  realisedPnL:               SingleRateResult;
  unrealisedPnL:             SingleRateResult;
  totalPnL:                  SingleRateResult;
  pnlFromACM:                number;
  totalRealisedPnl:          number;
  totalUnRealisedPnl:        number;
  totalPnlWithACM:           number;
}

interface GeneratePositionReportForCurrencyRequest {
  currencyId: string
}

export interface ImportExportRateResult {
	withCostingRate: ImportExportReportItem;
	withCaptureRate: ImportExportReportItem;
	withSpotRate: ImportExportReportItem;
}


export interface GeneratePositionReportForCurrencyResponse {
  currencyPosition: number;
  totalPnL: SingleRateResult;
  spotRate: number;
  totalRealisedPnL: SingleRateResult;
  totalUnrealisedPnL: SingleRateResult;
  ordersInvoicesUnpaidTotal: ImportExportReportItem;
  ordersInvoicesAverageRate: ImportExportRateResult;
  ordersInvoicesUnrealisedProfitAndLoss: ImportExportRateResult;
  tradeTotal: ImportExportReportItem;
  tradeAverageRate: ImportExportReportItem;
  tradeUnrealisedProfitAndLoss: ImportExportReportItem;
  netTotal: ImportExportReportItem;
  netAverageRate: ImportExportRateResult;
  netUnrealisedP: ImportExportRateResult;
  netPercentageHedged: ImportExportReportItem;
  acmPosition: ImportExportReportItem;
}


// GenerateWeekViewExposureReportForCurrency
export interface GenerateConsolidatedExposureReportResponse {
  Exposures: any,
}

export interface AsyncGenerateConsolidatedReportResponse {
  jobID: any,
}

export interface GenerateConsolidatedExposureReportRequest {
  subsidiaries: string[]
  holdingCompanyId: string
}

const Generator = {
  ServiceProviderName: 'Report-Generator',

  async GenerateDollarPortfolioReport(request: unknown): Promise<GenerateDollarPortfolioReportResponse> {
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GenerateDollarPortfolioReport`,
      request,
    })
  },

  async GeneratePositionReportForCurrency(request: GeneratePositionReportForCurrencyRequest): Promise<GeneratePositionReportForCurrencyResponse> {
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GeneratePositionReportForCurrency`,
      request,
    })
  },
  
  async GenerateMonthViewCashFlowReport(request: GenerateCashFlowReportRequest): Promise<GenerateMonthViewCashFlowReportResponse> {
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GenerateMonthViewCashFlowReport`,
      request,
    })
  },

  async GenerateWeekViewCashFlowReport(request: GenerateCashFlowReportRequest): Promise<GenerateWeekViewCashFlowReportResponse> {
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GenerateWeekViewCashFlowReport`,
      request,
    })
  },

  async GenerateMonthViewExposureReportForCurrency(request: GenerateMonthViewExposureReportForCurrencyRequest): Promise<GenerateMonthViewExposureReportForCurrencyResponse> {
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GenerateMonthViewExposureReportForCurrency`,
      request,
    })
  },

  async GenerateWeekViewExposureReportForCurrency(request: GenerateWeekViewExposureReportForCurrencyRequest): Promise<GenerateWeekViewExposureReportForCurrencyResponse> {
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GenerateWeekViewExposureReportForCurrency`,
      request,
    })
  },

  async GenerateConsolidatedExposureReport(request: { holdingCompanyPartyCode: string; subsidiaries: string[] }): Promise<GenerateConsolidatedExposureReportResponse> {
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.GenerateConsolidatedExposureReport`,
      request,
    })
  },

  async AsyncGenerateConsolidatedReport(request: { holdingCompanyPartyCode: string; subsidiaries: string[] }): Promise<AsyncGenerateConsolidatedReportResponse> {
    // eslint-disable-next-line
    // @ts-ignore
    return await jsonRpcRequest({
      url: config.get('golangUrl'),
      method: `${Generator.ServiceProviderName}.AsyncGenerateConsolidatedReport`,
      request,
    })
  }

}

export default Generator
