import EntryComparator from 'popcorn-js/audit/EntryComparator'
import CurrencyPair from 'popcorn-js/financial/currencyPair/CurrencyPair'

class Comparator {

  /**
   * @param {CurrencyPair} a
   * @param {CurrencyPair} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareExcluding(a, b, ...args) {
    for (const key in Object.keys(a)) {
      switch (key) {
        case 'auditEntry':
          if (!EntryComparator.CompareExcluding(a.auditEntry, b.auditEntry, ...args.filter(a => a.includes('auditEntry.')).map(a => a.replace('auditEntry.', '')))) {
            return false
          }
          break
        default:
          if (a[key] !== b[key] && !args.includes(key)) {
            return false
          }
      }
    }
    return true
  }

  /**
   * @param {CurrencyPair} a
   * @param {CurrencyPair} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareOnly(a, b, ...args) {
    for (const field in args) {
      if (args[field].includes('auditEntry.')) {
        if (!EntryComparator.CompareOnly(a.auditEntry, b.auditEntry, args[field].replace('auditEntry.', ''))) {
          return false
        }
      } else if (a[args[field]] !== b[args[field]]) {
        return false
      }
    }
    return true
  }

  /**
   * @param {CurrencyPair} a
   * @param {CurrencyPair} b
   * @return {boolean}
   */
  static CompareAll(a, b) {
    if (!(a instanceof CurrencyPair)) {
      console.error('Currency pair comparator cannot compare objects: invalid type')
      return false
    }
    if (!(b instanceof CurrencyPair)) {
      console.error('Currency pair comparator cannot compare objects: invalid type')
      return false
    }

    const keys = Object.keys(a)

    for (const i in keys) {
      switch (keys[i]) {
        case 'auditEntry':
          if (!EntryComparator.CompareAll(a.auditEntry, b.auditEntry)) {
            return false
          }
          break
        default:
          if (a[keys[i]] !== b[keys[i]]) {
            return false
          }
      }
    }
    return true

  }
}

export default Comparator
