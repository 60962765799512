import AccountEntry from 'popcorn-js/financial/account/AccountEntry'
import {isArray, isObject} from 'utils/Utils'

export default class Account {
  /**
   * @type {string}
   */
  id = ''

  /**
   * @type {string}
   */
  partyCode = ''

  /**
   * @type {boolean}
   */
  default = false

  /**
   * @type {string}
   */
  processingBankPartyCode = ''

  /**
   * @type {string}
   */
  currencyId = ''

  /**
   * @type {number}
   */
  balance = 0.0

  /**
   * @type {number}
   */
  authorisedAmount = 0.0

  /**
   * @type {number}
   */
  branchCode = 0

  /**
   * @type {string}
   */
  branchName = ''

  /**
   * @type {string}
   */
  number = ''

  /**
   * @type {string}
   */
  type = ''

  /**
   * @type {AccountEntry[]}
   */
  entries = []

  static camel = 'account'
  static nice = 'account'
  static niceP= 'accounts'
  static capital = 'Account'
  static capitalP = 'Accounts'

  /**
   * @param {object|Account} [account]
   */
  constructor(account) {
    if (account !== undefined &&
        (account instanceof Account || isObject(account))) {
      try {
        this.id = account.id
        this.partyCode = account.partyCode
        this.default = account.default
        this.processingBankPartyCode = account.processingBankPartyCode
        this.currencyId = account.currencyId
        this.balance = account.balance
        this.authorisedAmount = account.authorisedAmount
        this.branchCode = account.branchCode
        this.branchName = account.branchName
        this.number = account.number
        this.type = account.type
        if (isArray(account.entries)) {
          this.entries = account.entries.map(entry => new AccountEntry(entry))
        }
        this.auditEntry = account.auditEntry
      } catch (e) {
        console.error(`error constructing account: ${e}`)
        throw e
      }
    }
  }

  /**
   * @param {Account} account
   * @returns {Account}
   */
  static parse(account){

    return account
  }
}
