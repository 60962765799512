import React, { Fragment, ReactElement } from 'react'
import { Popover, Paper, MenuList, Divider, MenuItem, CircularProgress } from '@material-ui/core'
import { useStyletron, styled } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

type ActionItem = {
  id: string;
  text: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
}

const StyledDivider = styled(() => <Divider variant={'middle'} />, {
  backgroundColor: '#FFFFFF1A'
})

const StyledMenuItem = (props: {
  id: string;
  theme: CustomTheme;
  text: string;
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
}): ReactElement => {
  const [css] = useStyletron()
  const StyledComponent = styled(MenuItem, {
    fontSize: '14px', 
    ':hover': {
      backgroundColor: props.theme.palette.customColors.customBlue,
      color: props.theme.palette.background.default,
    }, 
    ':active': {
      backgroundColor: props.theme.palette.customColors.customBlue,
      color: props.theme.palette.background.default,
    }
  })
  return <StyledComponent
    disabled={props.disabled}
    id={props.id}
    onClick={props.onClick}
  >
    {props.text}<span className={css({width: '5px'})} />{props.loading && <CircularProgress
      color={'secondary'}
      size={10} />}

  </StyledComponent>
}


const HiddenMenuItem = styled(MenuItem, {
  visibility: 'hidden',
})

export const ActionsMenu = (props: {
  title: string;
  anchorElement: HTMLElement|undefined;
  onClose: () => void;
  items: (ActionItem|undefined)[];
}
): ReactElement => {
  const [css] = useStyletron()
  const theme = useTheme<CustomTheme>()

  const {anchorElement, onClose, items, title} = props

  return <Fragment>
    <Popover
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      open={!!anchorElement}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Paper
        className={css({
          borderRadius: '8px'
        })}
      >
        {title !== '' && <div className={css({paddingLeft: '16px', marginTop: '16px'})}>
          <span className={css({fontSize: '14px', fontWeight: 'bold'})}>{title}</span>
        </div>}
        <MenuList
          id="menuList"
        >
          {items?.map((item: ActionItem|undefined, i: number) => (
            item ? <div key={i}>
              <StyledDivider />
              <StyledMenuItem
                theme={theme}
                {...item}
                onClick={() => {
                  item.onClick()
                  onClose()
                }}
              />
            </div> : <div key={i}><HiddenMenuItem /></div>
          ))}
        </MenuList>
      </Paper>
    </Popover>
  </Fragment>
}