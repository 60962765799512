import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {TextField as MUITextField,} from '@material-ui/core'
import {isFunction, isNumber, isString, objectCopy, round} from 'utils/Utils'
import moment from 'moment'
import {SystemDateFormat, SystemDateTimeFormat,} from 'constants/formats'

const parseTypes = {
  string: 'string',
  upperCaseString: 'upperCaseString',
  int: 'int',
  float: 'float',
  date: 'date',
  datetime: 'datetime',
}

class TextField extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.parseAndFormat = this.parseAndFormat.bind(this)
    if (Object.values(parseTypes).includes(props.parseType)) {
      this.parseType = props.parseType
    } else {
      console.error(`invalid parseType prop '${props.parseType}' passed to TextField`)
      this.parseType = parseTypes.string
    }

    // ensure that parseType is consistent with HTML input element type
    switch (props.type) {
      case undefined:
        // do nothing, let props.parseType determine component behaviour
        break
      case 'string':
        this.parseType = parseTypes.string
        break
      case 'number':
        this.parseType = parseTypes.int
        break
      case 'date':
        this.parseType = parseTypes.date
        break
      case 'datetime':
        this.parseType = parseTypes.datetime
        break
      case 'datetime-local':
      default:
        throw new Error('type prop passed to TextField component not yet supported')
    }

    const {
      stateValue,
      newValue,
    } = this.parseAndFormat(props.value)
    this.state = {
      value: stateValue,
      lastReturnedValue: newValue,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      value,
    } = this.props
    const {
      lastReturnedValue,
    } = this.state

    if (this.parseType === parseTypes.string) {
      return
    }

    if (lastReturnedValue !== value) {
      this.setState({
        value: this.parseAndFormat(value).stateValue,
        lastReturnedValue: value,
      })
    }
  }

  parseAndFormat(newValue) {
    const {
      disallowNegative,
      decimalPlaces,
      disableThousandsSeparator,
    } = this.props

    let stateValue
    switch (this.parseType) {
      case parseTypes.datetime:
      case parseTypes.date:
        switch (true) {
          // user is trying to clear fields
          case (newValue === '') || (newValue === 0):
            // value stored in state and displayed in field
            // will be a blank string
            stateValue = ''
            // newValue given back to user will be 0
            newValue = 0
            break
          default:
            if (isString(newValue)) {
              // value stored in state and displayed in field with be
              // the date string
              stateValue = newValue
              // new value given back to user will be the unix date
              newValue = moment.utc(newValue).unix()
            } else if (isNumber(newValue)) {

              let format
              if (this.parseType === parseTypes.date) {
                format = SystemDateFormat
              } else {
                format = SystemDateTimeFormat
              }

              // value stored in state and displayed in field with be
              // the date string
              stateValue = moment.unix(newValue).format(format)
              // new value given back to user will be the unchanged unix date
            }
        }
        break

      case parseTypes.int:
      case parseTypes.float:
        switch (true) {
          // user is trying to clear fields
          case newValue === '':
            // value stored in state and displayed in field
            // will be a blank string
            stateValue = ''
            // newValue given back to user will be 0
            newValue = 0
            break

          // user is starting to type a negative
          case newValue === '-':
            if (!disallowNegative) {
              // value stored in state and displayed in field will
              // be a minus sign
              stateValue = '-'
              // newValue given back to user will be 0
              newValue = 0
            } else {
              // value stored in state and displayed in field will
              // be a zero
              stateValue = 0
              // newValue given back to user will be 0
              newValue = 0
            }
            break

          // user is somewhere else within typing a number
          default:
            // clean string for parsing
            if (isString(newValue)) {
              // remove commas from comma separated thousands
              while (newValue.includes(',')) {
                newValue = newValue.replace(',', '')
              }
              // remove spaces from comma separated thousands
              while (newValue.includes(' ')) {
                newValue = newValue.replace(' ', '')
              }

              // remove illegally inserted minus
              if (
                (disallowNegative) &&
                (newValue[0] === '-')
              ) {
                newValue = newValue.slice(1)
              }
            }
            // perform parsing
            switch (this.parseType) {
              case parseTypes.int:
                newValue = parseInt(newValue, 10)
                if (isNaN(newValue)) { // parsing to int failed
                  // value stored in state and displayed in field will
                  // be a blank string
                  stateValue = ''
                  // newValue given back to user will be 0
                  newValue = 0
                } else { // parsing to int succeeded
                  // newValue given back to user will be the parsed value
                  // i.e.newValue = newValue
                  // value stored in state and displayed in field will
                  // be the newValue
                  stateValue = newValue
                }
                break

              case parseTypes.float:
                // if value parses to 0 the user may be typing 0.0, 0.00 etc.
                if (parseFloat(newValue) === 0) {
                  // value stored in state and displayed in field will
                  // be the users input
                  stateValue = newValue
                  // newValue given back to user will be 0
                  newValue = 0
                  break
                }
                if (isString(newValue)) {
                  // if value ends in . AND second last char is a Number user is typing decimal
                  // OR
                  // if value contains a . AND ends in 0 user is typing something
                  // like 1.0, 12.00 etc.
                  if (
                    (
                      (newValue[newValue.length - 1] === '.') &&
                      !isNaN(newValue[newValue.length - 2])
                    ) ||
                    (
                      newValue.includes('.') &&
                      (newValue[newValue.length - 1] === '0')
                    )
                  ) {
                    // value stored in state and displayed in field will
                    // be the users input
                    stateValue = newValue
                    // newValue given back to user will be the parsed value
                    newValue = parseFloat(newValue)
                    break
                  }
                }

                // no special case, parse value to float and round
                newValue = round(parseFloat(newValue), decimalPlaces)
                if (isNaN(newValue)) { // parsing to float failed
                  // value stored in state and displayed in field will
                  // be a blank string
                  stateValue = ''
                  // newValue given back to user will be 0
                  newValue = 0
                } else { // parsing to float succeeded
                  // newValue given back to user will be the parsed value
                  // i.e.newValue = newValue
                  // value stored in state and displayed in field will
                  // be the newValue
                  stateValue = newValue
                }
                break
              default:
            }
        }
        break

      case parseTypes.string:
        break
      case parseTypes.upperCaseString:
        // newValue given back to user will be a capitalised string
        newValue = newValue.toString().toUpperCase()
        // value stored in state and displayed in field
        // will be a capitalised string
        stateValue = newValue
        break
      default:
    }

    // post parse processing on stateValue
    switch (this.parseType) {
      case parseTypes.int:
      case parseTypes.float:
        const oldStateValue = stateValue
        stateValue = ''
        // remove any illegal chars from value
        if (isString(oldStateValue)) {
          let decimalFound = false
          for (let i = 0; i < oldStateValue.length; i++) {
            const ch = oldStateValue[i]
            if (
              // minus only allowed as first index
              (ch === '-') &&
              (i === 0)
            ) {
              stateValue += ch
            } else if (
              // decimal only allowed once and not at
              // first index
              (ch === '.') &&
              (!decimalFound) &&
              (i !== 0)
            ) {
              decimalFound = true
              stateValue += ch
            } else if (
              // otherwise only numeric chars allowed
              !isNaN(ch)
            ) {
              stateValue += ch
            }
          }
        } else if (!isNaN(oldStateValue)) {
          stateValue = oldStateValue
        }

        if (!disableThousandsSeparator) {
          // change to string and format for display
          stateValue = stateValue.toString()
          const wasNegative = stateValue[0] === '-'
          if (wasNegative) {
            stateValue = stateValue.slice(1)
          }
          const unFormattedIntegerPart = stateValue.split('.')[0]
          const decimalPart = stateValue.split('.')[1]
          let formattedIntegerPart = ''
          for (let i = (unFormattedIntegerPart.length - 1); i >= 0; i--) {
            formattedIntegerPart = unFormattedIntegerPart[i] + formattedIntegerPart
            if (
              !(
                (i === 0) ||
                (i === (unFormattedIntegerPart.length - 1)) ||
                ((unFormattedIntegerPart.length - i) % 3)
              )
            ) {
              formattedIntegerPart = ',' + formattedIntegerPart
            }
          }
          stateValue = `${wasNegative ? '-' : ''}${formattedIntegerPart}${decimalPart === undefined ? '' : '.' + decimalPart}`
        }
        break

      default:
    }

    return {
      stateValue,
      newValue,
    }
  }

  onChange(event) {
    const {
      onChange,
    } = this.props

    const {
      stateValue,
      newValue,
    } = this.parseAndFormat(event.target.value)

    this.setState({
      value: stateValue,
      lastReturnedValue: newValue,
    })
    onChange({
      target: {
        value: newValue,
        id: event.target.id,
      },
      original: event,
    })
  }

  render() {
    if (this.parseType === parseTypes.string) {
      const propsWithoutCustom = objectCopy(this.props)
      customProps.forEach(customProp => {
        delete propsWithoutCustom[customProp]
      })
      return <MUITextField
        {...propsWithoutCustom}
      />
    } else {
      const {
        value,
      } = this.state
      const {
        onChange,
        ...rest
      } = this.props

      const props = {value}

      const restWithoutCustom = objectCopy(rest)
      customProps.forEach(customProp => {
        delete restWithoutCustom[customProp]
      })


      if (!(
        (value === '') ||
        (value === undefined)
      )) {
        if (restWithoutCustom.InputLabelProps) {
          props.InputLabelProps = {
            ...restWithoutCustom.InputLabelProps,
            shrink: true,
          }
        } else {
          props.InputLabelProps = {
            shrink: true,
          }
        }


      }

      if (isFunction(onChange)) {
        props.onChange = this.onChange
      }

      return <MUITextField
        {...restWithoutCustom}
        {...props}
      />
    }
  }
}

const customProps = [
  'parseType',
  'disallowNegative',
  'decimalPlaces',
  'disableThousandsSeparator',
]

TextField.propTypes = {
  ...MUITextField.propTypes,
  /**
   * Determines how the component will parse the value
   * passed in event.target.value to a given onChange function.
   * Should be one of the types defined on parseTypes variable
   * declared in this file.
   */
  decimalPlaces: PropTypes.number,
  /**
   * Determines if negative values are allowed. Only considered
   * if parseType is int or float
   */
  disableThousandsSeparator: PropTypes.bool,
  /**
   * The number of decimal places to round float values to
   */
  disallowNegative: PropTypes.bool,
  /**
   * If true, which is the default value, int and float values
   * will be formatted with a comma thousands separator
   */
  parseType: PropTypes.oneOf(Object.values(parseTypes)),
}

TextField.defaultProps = {
  ...MUITextField.defaultProps,
  parseType: parseTypes.string,
  disallowNegative: false,
  decimalPlaces: 4,
  disableThousandsSeparator: false,
}

export default TextField
export {
  parseTypes,
}

