const requiredFields = ['partyCode', 'name', 'adminEmail', 'customsClientNumber', 'clientTierId', 'sarbAuthNumber']

class Validator {

  /**
   * @Param {Broker} broker
   */
  static ValidateData(broker) {
    return new Promise(
      function (resolve, reject) {
        const invalidFields = {}
        let valid = true
        requiredFields.forEach(fieldName => {
          if (!broker[fieldName] || broker[fieldName] === '') {
            invalidFields[fieldName] = 'required'
            valid = false
          }
        })
        if (valid) {
          resolve(undefined)
        } else {
          reject(invalidFields)
        }
      })
  }
}

///////////////////////////
//  Format of response:  //
//  invalidFields={      //
//     name: 'required'  //
//  }                    //
///////////////////////////
export default Validator
