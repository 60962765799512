import React, { ReactElement, Fragment } from 'react'
import { Popover, Divider, IconButton, Button, Paper } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { styled, useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom'
import {Close} from '@material-ui/icons';

const StyledButton = (color: string) => styled(Button, {
  color: '#1E2036',
  boxShadow: 'none',
  textTransform: 'none',
  outline: 'none',
  fontSize: '14px',
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  backgroundColor: color,
  ':hover': {
    backgroundColor: color,
    opacity: 0.7,
  },
  ':active': {
    backgroundColor: color,
    opacity: 0.85,
  },
  ':focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
})

const VerticalSpacer = styled('div', {
  height: '16px'
})

export type Item = {
  text: string;
  value?: string;
}

export const CategoryMenu = (props: {
  title: string;
  anchorEl: HTMLElement|undefined;
  onClose: () => void;
  onClickItem: (value: string, importExport: 'Import'|'Export') => void;
  importItems: Item[];
  exportItems: Item[];
}): ReactElement => {
  const {title, anchorEl, onClose, onClickItem, importItems, exportItems} = props

  const [css] = useStyletron();
  const theme = useTheme<CustomTheme>()

  const ImportButton = StyledButton(theme.palette.import.main)
  const ExportButton = StyledButton(theme.palette.export.main)

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}
      open={!!anchorEl}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper
        className={css({
          borderRadius: '8px',
          padding: '16px',
          backgroundColor: theme.palette.background.default,
        })}
      >
        <div
          className={css({
            backgroundColor: theme.palette.background.default,
            display: 'flex', 
            flexDirection: 'column'
          })} >
          <div className={css({display: 'table', height: '32px'})}>
            <div className={css({display: 'table-cell', verticalAlign: 'middle', fontSize: '16px', fontWeight: 'bold'})}>{title}</div>
            <IconButton
              className={css({float: 'right'})}
              onClick={onClose}
              size={'small'}
            >
              <Close />
            </IconButton>
          </div>
          <VerticalSpacer />
          {importItems && importItems.length > 0 &&
            importItems.map((item: Item, i: number, arr: Item[]) => <Fragment key={item.text}>
              <ImportButton
                onClick={() => onClickItem(item.value ? item.value : item.text, 'Import')}
              >
                {item.text}
              </ImportButton>
              {i < arr.length-1 && <VerticalSpacer />}
            </Fragment>)
          }
          <VerticalSpacer />
          <Divider
            className={css({backgroundColor: '#4e4e5f'})}
            light />
          <VerticalSpacer />
          {exportItems && exportItems.length > 0 &&
            <Fragment>
              {
                exportItems.map((item: Item, i: number, arr: Item[]) => <Fragment key={item.text}>
                  <ExportButton
                    onClick={() => onClickItem(item.value ? item.value : item.text, 'Export')}
                  >
                    {item.text}
                  </ExportButton>
                  {i < arr.length-1 && <VerticalSpacer />}
                </Fragment>)
              }
            </Fragment>
          }
        </div>
      </Paper>
    </Popover>
  )
}