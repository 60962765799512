import EntryComparator from 'popcorn-js/audit/EntryComparator'
import ProcessingOrg from 'popcorn-js/legalEntity/party/processingOrg/ProcessingOrg'
import {CompareObjects, isArray} from 'utils/Utils'

class Comparator {

  /**
   * @param {ProcessingOrg} a
   * @param {ProcessingOrg} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareExcluding(a, b, ...args) {
    for (const key in Object.keys(a)) {
      switch (key) {
        case 'auditEntry':
          if (!EntryComparator.CompareExcluding(a.auditEntry, b.auditEntry, ...args.filter(a => a.includes('auditEntry.')).map(a => a.replace('auditEntry.', '')))) {
            return false
          }
          break
        case 'currencyPairIds':
          if (!args.includes(key)) {
            if (!this.CompareCurrencyPairSpreads(a.currencyPairIds, b.currencyPairIds)) {
              return false
            }
          }
          break
        default:
          if (a[key] !== b[key] && !args.includes(key)) {
            return false
          }
      }
    }
    return true
  }

  /**
   * @param {ProcessingOrg} a
   * @param {ProcessingOrg} b
   * @param {string} args
   * @return {boolean}
   */
  static CompareOnly(a, b, ...args) {
    for (const field in args) {
      if (args[field].includes('auditEntry.')) {
        if (!EntryComparator.CompareOnly(a.auditEntry, b.auditEntry,
          args[field].replace('auditEntry.', ''))) {
          return false
        }
      } else if (args[field].includes('currencyPairIds')) {
        if (!this.CompareCurrencyPairSpreads(a.currencyPairIds, b.currencyPairIds)) {
          return false
        }
      } else if (a[args[field]] !== b[args[field]]) {
        return false
      }
    }
    return true
  }

  /**
   * @param {ProcessingOrg} a
   * @param {ProcessingOrg} b
   * @return {boolean}
   */
  static CompareAll(a, b) {
    if (!(a instanceof ProcessingOrg)) {
      console.error('ProcessingOrg comparator cannot compare objects: invalid type -', a, b)
      return false
    }
    if (!(b instanceof ProcessingOrg)) {
      console.error('ProcessingOrg comparator cannot compare objects: invalid type -', a, b)
      return false
    }

    const keys = Object.keys(a)

    for (const i in keys) {
      switch (keys[i]) {
        case 'auditEntry':
          if (!EntryComparator.CompareAll(a.auditEntry, b.auditEntry)) {
            return false
          }
          break
        case 'currencyPairIds':
          if (!this.CompareCurrencyPairIds(a.currencyPairIds, b.currencyPairIds)) {
            return false
          }
          break
        case 'contactDetails':
          if (!CompareObjects(a.contactDetails, b.contactDetails)) {
            return false
          }
          break
        default:
          if (a[keys[i]] !== b[keys[i]]) {
            return false
          }
      }
    }
    return true
  }

  /**
   *
   * @param {Array} a
   * @param {Array} b
   * @return {boolean}
   */
  static CompareCurrencyPairIds(a, b) {
    if (!isArray(a)) {
      console.error('invalid object passed to \'CompareCurrencyPairIds\'.')
      return false
    }
    if (!isArray(b)) {
      console.error('invalid object passed to \'CompareCurrencyPairIds\'.')
      return false
    }

    if (a.length !== b.length) {
      return false
    }

    return !(a.some(cpA => !b.find(cpB => (cpA === cpB))) ||
      b.some(cpB => !a.find(cpA => (cpB === cpA))))
  }
}

export default Comparator
