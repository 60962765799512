import React from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {Card, CardContent, CardHeader} from '@material-ui/core'
import moment from 'moment'
import {roundScaleAndFormatNumber} from 'views/Home/Company/Dashboard/Util'
import {MdInfo as HelpIcon} from 'react-icons/md'
import LightTooltip from 'components/LightTooltp/LightTooltip'
import {Currency} from 'popcorn-js/financial/currency/currencyType';

interface NetTradeCashFlowsTicketProps {
  currency: Currency,
  report: any
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: '50px auto',
    gridGap: '8px',
    height: 'min-content',
  },
  helpIconWrapper: {
    width: '14px',
    height: '14px',
    color: theme.palette.customColors.greyText1,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    marginLeft: theme.spacing()
  },
  helpIcon: {
    width: '14px',
    height: '14px',
  },
  heading: {
    fontSize: '16px',
    fontWeight: 'bold',
    alignSelf: 'end',
  },
  item: {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    marginBottom: theme.spacing(),
    gridGap: theme.spacing()
  },
  totalItem: {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(),
    gridGap: theme.spacing()
  },
  itemName: {
    display: 'grid',
    gridTemplateColumns: ' max-content 10px',
    fontSize: '14px',
    color: theme.palette.customColors.customBlue2,
    gridGap: theme.spacing()
  },
  totalItemName: {
    display: 'grid',
    gridTemplateColumns: ' max-content 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    gridGap: theme.spacing()
  },
  cardRoot: {
    background: theme.palette.paper.default
  },
  cardHeading: {
    background: theme.palette.primary.dark
  },
  justifyEnd: {
    justifySelf: 'end',
    color: theme.palette.customText.greyGraphLabel,
  }
}))

const NetTradeCashFlowsTicket: React.FC<NetTradeCashFlowsTicketProps> = (props: NetTradeCashFlowsTicketProps) => {
  const {currency, report} = props
  const netTradeCashFlowsThisMonth = (report || {}).netTradeCashFlowsThisMonth || {}
  const classes = useStyles({})
  const currentMonth = moment().format('MMMM')

  interface itemProps {
    total?: boolean,
    value: number,
    tooltipValue: string,
    heading: string,
    showTooltip?: boolean
  }

  const Item = (props: itemProps): JSX.Element => {
    const {
      total,
      value,
      tooltipValue,
      heading,
      showTooltip,
    } = props

    if (total) {
      return (
        <div className={classes.totalItem}>
          <div className={classes.totalItemName}>
            <div>{heading}</div>
            {showTooltip && <LightTooltip
              placement={'right'}
              title={tooltipValue}
            >
              <span className={classes.helpIconWrapper}>
                <HelpIcon className={classes.helpIcon}/>
              </span>
            </LightTooltip>}
          </div>
          <div
            className={classes.justifyEnd}
          >{roundScaleAndFormatNumber(value, currency.symbol, false, 0)}</div>
        </div>
      )
    }
    return (
      <div className={classes.item}>
        <div className={classes.itemName}>
          <div>{heading}</div>
          {showTooltip && <LightTooltip
            placement={'right'}
            title={tooltipValue}
          >
            <span className={classes.helpIconWrapper}>
              <HelpIcon className={classes.helpIcon}/>
            </span>
          </LightTooltip>}
        </div>
        <div
          className={classes.justifyEnd}
        >{roundScaleAndFormatNumber(value, currency.symbol, false, 0)}</div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        {/*{'Current Month Net Trade Forecasts'}*/}
      </div>
      <Card
        classes={{root: classes.cardRoot}}
      >
        <CardHeader
          classes={{root: classes.cardHeading}}
          title={
            <div className={classes.heading}>
              {`${currentMonth} Net Trade Cash Flows`}
            </div>
          }
        />
        <CardContent>
          <Item
            heading={'Total'}
            showTooltip
            tooltipValue={'The total cash flow contributions from all trades done and expected to be done in the current month'}
            total
            value={netTradeCashFlowsThisMonth.pendingTotal}
          />
          <Item
            heading={'Extensions'}
            tooltipValue={'Total Pending Trade Cash Flows from Extensions'}
            value={netTradeCashFlowsThisMonth.extensions}
          />
          <Item
            heading={'Cancellations'}
            tooltipValue={'Total Pending Trade Cash Flows from Cancellations'}
            value={netTradeCashFlowsThisMonth.cancellations}
          />
          <Item
            heading={'Draw Downs'}
            tooltipValue={'Total Pending Trade Cash Flows from Draw Downs'}
            value={netTradeCashFlowsThisMonth.drawDowns}
          />
          <Item
            heading={'Forecasted'}
            tooltipValue={'Total Pending Forecasted Trade Cash Flows'}
            value={netTradeCashFlowsThisMonth.forecasted}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default NetTradeCashFlowsTicket
