import {
  RATE_SOCKET_CONNECTED,
  RATE_SOCKET_CONNECTING,
  RATE_SOCKET_DISCONNECTED,
  RATE_SOCKET_STALE,
} from './actionTypes'

export function rateSocketConnected() {
  return {type: RATE_SOCKET_CONNECTED}
}

export function rateSocketConnecting() {
  return {type: RATE_SOCKET_CONNECTING}
}

export function rateSocketDisconnected() {
  return {type: RATE_SOCKET_DISCONNECTED}
}

export function rateSocketStale() {
  return {type: RATE_SOCKET_STALE}
}
