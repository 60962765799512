import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
// Custom components
import OrderDetail from 'components/Detail/order/OrderDetail'

const OrderDetailContainer = props => {
  return (
    <OrderDetail {...props} />
  )
}

const mapStateToProps = state => {
  return {
    partyCode: state.security.claims.context.partyCode,
    currencies: state.currency.records,
    myParty: state.myParty,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

OrderDetailContainer.propTypes = {
  currencies: PropTypes.array.isRequired,
  isNew: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func,
  partyCode: PropTypes.string.isRequired,
  order: PropTypes.object,
  readOnly: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderDetailContainer)
