import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'
import dateFormat from 'dateformat';


const useStyles = makeStyles((theme) => ({
  updatedText: {
    color: theme.palette.secondary.updateText,
    fontWeight: 'bold',
    alignSelf: 'center'
  },
}))

export default function UpdateTime(props) {
  const classes = useStyles()
  return (
    <Typography
      className={classes.updatedText}
      variant={'body2'}
    >
      Updated {findCurrentTime(props)}
    </Typography>
  )
}

function findCurrentTime(props) {
  return dateFormat(new Date(), 'mm/dd @ HH:MM')
}


