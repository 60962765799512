import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'

export default class Inspector {
  static extendContext(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Party-Inspector.ExtendContext',
      request,
    })
  }
}
