import React from 'react'
import PropTypes from 'prop-types'

import {ListItem} from './ListItem'
import {makeStyles} from '@material-ui/core'
import {HexToRGBA} from '../../../../utils/Utils'


const useStyles = makeStyles(theme => ({
  formField: {
    color: theme.palette.text.body,
  },
  title: {
    backgroundColor: HexToRGBA('#ffffff', 0.1),
    color: theme.palette.text.primary,
    padding: theme.spacing(),
    border: '1px solid #26283c',
  },
  noRecordsMessage: {
    padding: theme.spacing(),
    flex: 1,
    color: HexToRGBA(theme.palette.text.primary, 0.5),
  },
  itemWrapper: {},
  noRecordsMessageContainer: {
    display: 'flex',
    width: '60%',
    height: '33px',
  },
}))

export const EntityList = (props) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      {props.listHeader && <div
        className={classes.title}
      >
        {props.listHeader}
      </div>}
      <ListItem
        columns={props.columns}
        header
      />
      <div
        className={classes.itemWrapper}>
        {(() => {
          if ((props.entities || []).length > 0) {
            return props.entities.map(
              (entity, index) => {
                return (
                  <ListItem
                    additionalActions={props.additionalActions}
                    columns={props.columns}
                    entity={entity}
                    key={index}
                    onClick={props.onClick}
                  />
                )
              }
            )
          } else {
            return (
              <div className={classes.noRecordsMessageContainer}>
                <div className={classes.noRecordsMessage}>{props.noRecordsMessage}</div>
                <div className={classes.noRecordsMessage}>
                  {props.additionalActions}
                </div>
              </div>
            )
          }
        })()}
      </div>
    </React.Fragment>)
}

EntityList.propTypes = {
  additionalActions: PropTypes.any,
  columns: PropTypes.arrayOf(PropTypes.object),
  entities: PropTypes.arrayOf(PropTypes.object),
  listHeader: PropTypes.string,
  noRecordsMessage: PropTypes.string,
  onClick: PropTypes.func.isRequired
}
