import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  withStyles, Card, CardHeader,
  CardContent, Typography, Grid, Button,
  AppBar, Tabs, Tab, FormControl, InputLabel,
  Select, MenuItem, FormHelperText, Switch,
  FormControlLabel,
} from '@material-ui/core'
import {
  TextField, TextFieldParseTypes,
} from 'components/FormContols'
import Table from 'components/Table'
import Account from 'popcorn-js/financial/account/Account'
import {IDIdentifier, PartyCodeIdentifier} from 'popcorn-js/search/identifier'
import AccountRecordkeeper from 'popcorn-js/financial/account/recordkeeper'
import AccountComparator from 'popcorn-js/financial/account/comparator'
import ProcessingBankRecordkeeper from 'popcorn-js/legalEntity/party/processingBank/recordkeeper'
import {FullPageLoader as Loader} from 'components/Loader/Loader'
import HistoryLayout from 'views/History/HistoryLayout'
import {AccountFields} from 'views/History/AccountHistory/AccountFields'
import NotificationSweetAlert
  from 'components/SweetAlert/NotificationSweetAlert'
import AccountValidator from 'popcorn-js/financial/account/validator'
import {retrieveFromList} from 'popcorn-js/search/identifier/utilities'

const styles = theme => ({
  backgroundCardRoot: {
    marginBottom: '10px',
  },
  backgroundCardContent: {
    padding: '0',
    margin: '0px 24px 24px 24px',
  },
  formField: {
    height: '60px',
    width: '150px',
  },
  controlsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  controlsLeft: {
    justifySelf: 'start',
  },
  controlsRight: {
    justifySelf: 'end',
  },
})

const states = {
  viewing: 0,
  creating: 1,
  editing: 2,
  viewingDelete: 3,
}

const tabs = {
  main: 0,
  other: 1,
}

const accountListLength = 10
const entriesListLength = 5
const accountsListId = `${Account.camel}ConfigurationList`
const accountEntriesListId = `${Account.camel}EntriesList`
const newAutoFocusElementId = `${Account.camel}BranchName`

class Accounts extends Component {
  state = {
    activeState: states.viewing,
    activeTab: tabs.main,
    isLoading: false,
    selectedRowIndex: -1,

    history: [],
    showHistory: false,

    successMessage: undefined,
    errorMessage: undefined,
    warningMessage: undefined,
    confirmationMethod: undefined,

    selected: new Account(),
    original: new Account(),
    previouslySelected: new Account(),

    sortBy: [],

    accounts: [],

    invalidFields: {},
  }
  currencies = []
  processingBanks = []

  componentDidMount() {
    this.load()
  }

  loadProcessingBanks = async () => {
    this.setState({isLoading: true})
    try {
      const findResponse = await ProcessingBankRecordkeeper.find(
        undefined,
        undefined,
      )
      this.processingBanks = findResponse.records
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  load = async () => {
    await this.loadProcessingBanks()
    await this.find()
  }

  find = (deleted = false) => {
    const {
      sortBy,
    } = this.state

    const query = {
      sortBy,
    }

    this.setState({isLoading: true})
    AccountRecordkeeper.find(undefined, query, deleted)
      .then(result => {
        const accounts = result.records.map(account => new Account(account))
        const selected = result.total > 0 ? accounts[0] : new Account()
        const selectedRowIndex = result.total > 0 ? 0 : -1

        this.setState({
          selected,
          original: new Account(selected),
          previouslySelected: new Account(selected),
          selectedRowIndex,
          accounts,
          activeState: deleted ? states.viewingDelete : states.viewing,
        })
      })
      .catch(error => this.setState({errorMessage: error.message || error}))
      .finally(() => this.setState({isLoading: false}))
  }

  handleSelection = (account, rowIndex) => {
    const {
      activeState,
    } = this.state

    switch (activeState) {
      case states.viewing:
      case states.viewingDelete:
        // no confirmation required from user
        // change selected item and selected row index
        this.setState({
          selected: new Account(account),
          original: new Account(account),
          previouslySelected: new Account(account),
          selectedRowIndex: rowIndex,
        })
        break

      case states.creating:
      case states.editing:
        // confirm that user would like to discard changes
        this.setState({
          warningMessage: 'You have unsaved changes. Do you want to continue?',
          // if the user confirms that they would like to discard changes
          // change selected item and selected row index
          confirmationMethod: () =>
            this.setState({
              selected: new Account(account),
              original: new Account(account),
              previouslySelected: new Account(account),
              selectedRowIndex: rowIndex,
              warningMessage: undefined,
              activeState: states.viewing,
            }),
        })
        break
      default:
    }
  }

  handleChange = entityProp => event => {
    const {
      selected,
      invalidFields,
    } = this.state
    const {
      activeState,
      original,
      selectedRowIndex,
    } = this.state

    // update value
    selected[entityProp] = event.target.value

    // clear any field errors
    invalidFields[entityProp] = undefined

    // determine next activeState
    // first assume state is unchanged
    let nextActiveState = activeState

    // compare the original and the selected entities
    const noDifferences = AccountComparator.CompareAll(original, selected)

    switch (activeState) {
      case states.viewing:
        if (!noDifferences) {
          // if original and selected differ
          if (selectedRowIndex < 0) {
            // and if no row is selected, new entity is being created
            nextActiveState = states.creating
          } else {
            // or if a row is selected, that entity has started being edited
            nextActiveState = states.editing
          }
        }
        break

      case states.creating:
      case states.editing:
        if (noDifferences) {
          // while creating and editing if
          // original and selected do not differ
          // state goes back to viewing
          nextActiveState = states.viewing
        }
        break
      default:
    }

    this.setState({selected, activeState: nextActiveState})
  }

  handleNew = () => {
    const focusElement = document.getElementById(newAutoFocusElementId)
    if (focusElement) {
      focusElement.focus()
    }
    this.setState({
      selected: new Account(),
      original: new Account(),
      selectedRowIndex: -1,
      activeState: states.creating,
    })
  }

  handleViewDelete = () => {
    this.find(true)
  }

  handleRetrieveHistory = () => {
    const {
      selected,
    } = this.state
    AccountRecordkeeper.retrieveHistory(new IDIdentifier(selected.id))
      .then(result => {
        const history = result.history.map(
          acc => new Account(acc))
        this.setState({history, showHistory: true})
      })
      .catch(error => this.setState({errorMessage: error.message || error}))
      .finally(() => this.setState({isLoading: false}))
  }

  handleCreate = () => {
    const {
      selected,
    } = this.state
    const {
      accounts,
    } = this.state
    const {
      partyCode,
    } = this.props

    AccountValidator.ValidateData(selected)
      .then(() => {
        this.setState({isLoading: true})
        selected.partyCode = partyCode
        AccountRecordkeeper.create(selected)
          .then(result => {
            const newAccount = new Account(result.account)
            accounts.push(newAccount)

            const newAccountIdx = accounts.findIndex(c => c.id === newAccount.id)

            this.setState({
              successMessage: `${Account.capital} created`,
              selected: newAccount,
              original: new Account(newAccount),
              previouslySelected: newAccount,
              activeState: states.viewing,
              selectedRowIndex: newAccountIdx,
              accounts,
            })
            Accounts.scrollToPos(newAccountIdx / accounts.length)
          })
          .catch(error => this.setState({errorMessage: error.message || error}))
          .finally(() => this.setState({isLoading: false}))
      })
      .catch(invalidFields => this.setState({invalidFields}))
  }

  handleCancelCreate = () => {
    const {
      previouslySelected,
      accounts,
    } = this.state
    const previouslySelectedRowIdx = accounts.findIndex(acc => acc.id === previouslySelected.id)

    // confirm that user would like to discard changes
    this.setState({
      warningMessage: 'You have unsaved changes. Do you want to continue?',
      // if the user confirms that they would like to discard changes
      // change selected item and selected row index
      confirmationMethod: () => {
        this.setState({
          selected: new Account(previouslySelected),
          original: new Account(previouslySelected),
          selectedRowIndex: previouslySelectedRowIdx,
          activeState: states.viewing,
          invalidFields: {},
          warningMessage: undefined,
        })
        Accounts.scrollToPos(previouslySelectedRowIdx / accounts.length)
      },
    })
  }

  handleUpdate = () => {
    const {
      selected,
    } = this.state
    const {
      accounts,
    } = this.state
    const {
      partyCode,
    } = this.props

    AccountValidator.ValidateData(selected)
      .then(() => {
        this.setState({isLoading: true})
        selected.partyCode = partyCode
        AccountRecordkeeper.update(selected, new IDIdentifier(selected.id))
          .then(result => {
            const updatedAccount = new Account(result.account)

            const updatedAccountIdx = accounts.findIndex(c => c.id === updatedAccount.id)
            if (updatedAccountIdx >= 0) {
              accounts[updatedAccountIdx] = updatedAccount
            } else {
              console.error('unable to find updated account entity in accounts')
            }

            // notify user of success
            this.setState({
              successMessage: `${Account.capital} updated`,
              selected: updatedAccount,
              original: new Account(updatedAccount),
              previouslySelected: updatedAccount,
              activeState: states.viewing,
              selectedRowIndex: updatedAccountIdx,
              accounts,
            })
            Accounts.scrollToPos(updatedAccountIdx / accounts.length)
          })
          .catch(error => this.setState({errorMessage: error.message || error}))
          .finally(() => this.setState({isLoading: false}))
      })
      .catch(invalidFields => this.setState({invalidFields}))
  }

  handleCancelUpdate = () => {
    const {
      original,
    } = this.state

    // confirm that user would like to discard changes
    this.setState({
      warningMessage: 'You have unsaved changes. Do you want to continue?',
      // if the user confirms that they would like to discard changes
      // change selected item and selected row index
      confirmationMethod: () =>
        this.setState({
          activeState: states.viewing,
          selected: new Account(original),
          invalidFields: {},
          warningMessage: undefined,
        }),
    })
  }

  handleDelete = () => {
    const {
      selected,
    } = this.state
    const {
      accounts,
    } = this.state
    this.setState({isLoading: true})
    AccountRecordkeeper.delete(new IDIdentifier(selected.id))
      .then(result => {
        const deletedAccountIdx = accounts.findIndex(acc => acc.id === result.account.id)
        if (deletedAccountIdx < 0) {
          console.error('unable to find deleted account in state.accounts')
          return
        }
        accounts.splice(deletedAccountIdx, 1)
        let selectedRowIndex = -1
        if (accounts.length > deletedAccountIdx) {
          selectedRowIndex = deletedAccountIdx
        } else if ((accounts.length - 1) >= (deletedAccountIdx - 1)) {
          selectedRowIndex = deletedAccountIdx - 1
        }
        if (selectedRowIndex === -1) {
          // notify user of success
          this.setState({
            successMessage: `${Account.capital} deleted`,
            selected: new Account(),
            original: new Account(),
            previouslySelected: new Account(),
            selectedRowIndex,
            activeState: states.viewing,
            accounts,
          })
        } else {
          // notify user of success
          this.setState({
            successMessage: `${Account.capital} deleted`,
            selected: new Account(accounts[selectedRowIndex]),
            original: new Account(accounts[selectedRowIndex]),
            previouslySelected: new Account(accounts[selectedRowIndex]),
            selectedRowIndex,
            activeState: states.viewing,
            accounts,
          })
        }
      })
      .catch(error => this.setState({errorMessage: error.message || error}))
      .finally(() => this.setState({isLoading: false}))
  }

  handleRestore = () => {
    const {
      selected,
    } = this.state
    const {
      accounts,
    } = this.state

    this.setState({isLoading: true})

    AccountRecordkeeper.restore(new IDIdentifier(selected.id))
      .then(result => {
        const restoredAccountIdx = accounts.findIndex(acc => acc.id === result.account.id)
        if (restoredAccountIdx < 0) {
          console.error('unable to find restored account in state.accounts')
          return
        }
        accounts.splice(restoredAccountIdx, 1)
        let selectedRowIndex = -1
        if (accounts.length > restoredAccountIdx) {
          selectedRowIndex = restoredAccountIdx
        } else if ((accounts.length - 1) >= (restoredAccountIdx - 1)) {
          selectedRowIndex = restoredAccountIdx - restoredAccountIdx
        }
        if (selectedRowIndex === -1) {
          this.setState({
            selected: new Account(),
            original: new Account(),
            previouslySelected: new Account(),
            selectedRowIndex,
            accounts,
            successMessage: `${Account.capital} restored`,
          })
        } else {
          this.setState({
            selected: new Account(accounts[selectedRowIndex]),
            original: new Account(accounts[selectedRowIndex]),
            previouslySelected: new Account(accounts[selectedRowIndex]),
            selectedRowIndex,
            accounts,
            successMessage: `${Account.capital} restored`,
          })
        }
      })
      .catch(error => this.setState({errorMessage: error.message || error}))
      .finally(() => this.setState({isLoading: false}))
  }

  handleDelete = () => {
    const {
      selected,
    } = this.state
    const {
      accounts,
    } = this.state
    this.setState({isLoading: true})
    AccountRecordkeeper.deleteForever(new IDIdentifier(selected.id))
      .then(result => {
        const deletedAccountIdx = accounts.findIndex(acc => acc.id === result.account.id)
        if (deletedAccountIdx < 0) {
          console.error('unable to find deleted account in state.accounts')
          return
        }
        accounts.splice(deletedAccountIdx, 1)
        let selectedRowIndex = -1
        if (accounts.length > deletedAccountIdx) {
          selectedRowIndex = deletedAccountIdx
        } else if ((accounts.length - 1) >= (deletedAccountIdx - 1)) {
          selectedRowIndex = deletedAccountIdx - 1
        }
        if (selectedRowIndex === -1) {
          this.setState({
            selected: new Account(),
            original: new Account(),
            previouslySelected: new Account(),
            selectedRowIndex,
            accounts,
            successMessage: `${Account.capital} deleted`,
          })
        } else {
          this.setState({
            selected: new Account(accounts[selectedRowIndex]),
            original: new Account(accounts[selectedRowIndex]),
            previouslySelected: new Account(accounts[selectedRowIndex]),
            selectedRowIndex,
            accounts,
            successMessage: `${Account.capital} deleted`,
          })
        }
      })
      .catch(error => this.setState({errorMessage: error.message || error}))
      .finally(() => this.setState({isLoading: false}))
  }

  render() {
    const {
      activeState,
      activeTab,
      accounts,
      selectedRowIndex,
    } = this.state
    const {
      classes,
      theme,
      currencies,
    } = this.props

    return <div id={`${Account.camel}PartySettingsRoot`}>
      {this.renderDialogs()}
      <Card className={classes.backgroundCardRoot}>
        <CardHeader
          action={<div>
            <Button disabled>
              Import
            </Button>
            <Button disabled>
              Export
            </Button>
          </div>}
          title={<Typography
            gutterBottom
            variant="subtitle1">
            {(activeState === states.viewingDelete)
              ? `${Account.capitalP} - Delete`
              : `${Account.capitalP}`
            }
          </Typography>}
        />
        <CardContent className={classes.backgroundCardContent}>
          <Grid
            container
            direction={'row'}
            spacing={3}>
            <Grid
              item
              sm={6}
              xs={12}>
              <Table
                columns={[
                  {
                    width: 135,
                    Header: 'Processing Bank',
                    accessor: 'processingBankPartyCode',
                    Cell: rowCellInfo => {
                      try {
                        return retrieveFromList(new PartyCodeIdentifier(rowCellInfo.value), this.processingBanks).name
                      } catch (e) {
                        console.error('error retrieving processing bank:', e)
                        return '-'
                      }
                    },
                  },
                  {
                    width: 80,
                    Header: 'Currency',
                    accessor: 'currencyId',
                    Cell: rowCellInfo => {
                      try {
                        return retrieveFromList(new IDIdentifier(rowCellInfo.value), currencies).isoCode
                      } catch (e) {
                        console.error('error retrieving currency:', e)
                        return '-'
                      }
                    },
                  },
                  {
                    Header: 'Number',
                    accessor: 'number'
                  },
                ]}
                data={accounts}
                defaultPageSize={accountListLength}
                getTbodyProps={() => {
                  return {
                    style: {
                      overflowY: 'scroll',
                      height: '368px',
                      scrollBehavior: 'smooth',
                    },
                    id: accountsListId,
                  }
                }}
                getTdProps={(state, rowInfo) => {
                  const rowIndex = rowInfo ? rowInfo.index : undefined

                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo) {
                        this.handleSelection(rowInfo.original, rowInfo.index)
                      }
                      if (handleOriginal) {
                        handleOriginal()
                      }
                    },
                    style: {
                      background: rowIndex === selectedRowIndex ?
                        theme.palette.secondary.light :
                        'white',
                      color: rowIndex === selectedRowIndex ?
                        theme.palette.secondary.contrastText :
                        'black',
                    },
                  }
                }}
                id={`${Account.camel}Table`}
                pageSize={Math.max(accountListLength, accounts.length)}
                showPagination={false}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}>
              <Grid
                container
                direction="column"
                spacing={1}>
                <Grid item>
                  <AppBar
                    id="configTabBar"
                    position={'static'}
                  >
                    <Tabs
                      onChange={(e, activeTab) => this.setState({activeTab})}
                      value={activeTab}
                    >
                      <Tab
                        id="configTabBarMainTab"
                        label="Main"
                        value={tabs.main}
                      />
                    </Tabs>
                  </AppBar>
                </Grid>
                <Grid item>
                  {(() => {
                    switch (activeTab) {
                      case tabs.main:
                        return this.renderMainTabContents()
                      default:
                        return <div>invalid tab value</div>
                    }
                  })()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {this.renderControls()}
    </div>
  }

  renderMainTabContents = () => {
    const {
      classes,
      currencies,
    } = this.props
    const {
      isLoading,
      selected,
      invalidFields,
      activeState,
    } = this.state

    const disableFields =
      isLoading ||
      (activeState === states.viewingDelete)

    return <Grid
      container
      direction="column">
      <Grid item>
        <Grid
          container
          spacing={1}>
          <Grid item>
            <Grid
              container
              direction="column">
              <Grid item>
                <FormControl
                  aria-describedby="processingBankPartyCode"
                  className={classes.formField}
                  error={!!invalidFields['processingBankPartyCode']}>
                  <InputLabel htmlFor="processingBankPartyCode">Processing Bank</InputLabel>
                  <Select
                    disabled={disableFields}
                    id="processingBankPartyCode"
                    inputProps={{id: 'processing-bank-select'}}
                    onChange={this.handleChange('processingBankPartyCode')}
                    value={selected.processingBankPartyCode}
                  >
                    {this.processingBanks.map((pb, idx) => {
                      return <MenuItem
                        className="processingBankMenuItemsClassName"
                        key={idx}
                        value={pb.partyCode}
                      >
                        {pb.name}
                      </MenuItem>
                    })}
                  </Select>
                  {(!!invalidFields['processingBankPartyCode']) &&
                  <FormHelperText id="processingBankPartyCode">
                    {invalidFields['processingBankPartyCode']}
                  </FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl
                  aria-describedby="currencyId"
                  className={classes.formField}
                  error={!!invalidFields['currencyId']}>
                  <InputLabel htmlFor="currencyId">Currency</InputLabel>
                  <Select
                    disabled={disableFields}
                    id="currencyId"
                    inputProps={{id: 'currency-select'}}
                    onChange={this.handleChange('currencyId')}
                    value={selected.currencyId}
                  >
                    {currencies.map((ccy, idx) => {
                      return <MenuItem
                        className="currencyMenuItemsClassName"
                        key={idx}
                        value={ccy.id}
                      >
                        {ccy.isoCode}
                      </MenuItem>
                    })}
                  </Select>
                  {(!!invalidFields['currencyId']) &&
                  <FormHelperText id="currencyId">
                    {invalidFields['currencyId']}
                  </FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formField}
                  disabled={disableFields}
                  disallowNegative
                  error={!!invalidFields['availableBalance']}
                  helperText={invalidFields['availableBalance']}
                  id="availableBalance"
                  label="Available Balance"
                  onChange={this.handleChange('availableBalance')}
                  parseType={TextFieldParseTypes.float}
                  value={selected.availableBalance}
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formField}
                  disabled={disableFields}
                  disallowNegative
                  error={!!invalidFields['authorisedAmount']}
                  helperText={invalidFields['authorisedAmount']}
                  id="authorisedAmount"
                  label="Authorised Amount"
                  onChange={this.handleChange('authorisedAmount')}
                  parseType={TextFieldParseTypes.float}
                  value={selected.authorisedAmount}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column">
              <Grid item>
                <TextField
                  className={classes.formField}
                  disabled={disableFields}
                  error={!!invalidFields['branchName']}
                  helperText={invalidFields['branchName']}
                  id={newAutoFocusElementId}
                  label="Branch Name"
                  onChange={this.handleChange('branchName')}
                  value={selected.branchName}
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formField}
                  disableThousandsSeparator
                  disabled={disableFields}
                  disallowNegative
                  error={!!invalidFields['branchCode']}
                  helperText={invalidFields['branchCode']}
                  id="branchCode"
                  label="Branch Code"
                  onChange={this.handleChange('branchCode')}
                  parseType={TextFieldParseTypes.int}
                  value={selected.branchCode}
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formField}
                  disabled={disableFields}
                  error={!!invalidFields['type']}
                  helperText={invalidFields['type']}
                  id="type"
                  label="Account Type"
                  onChange={this.handleChange('type')}
                  value={selected.type}
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formField}
                  disabled={disableFields}
                  error={!!invalidFields['number']}
                  helperText={invalidFields['number']}
                  id="type"
                  label="Account Number"
                  onChange={this.handleChange('number')}
                  value={selected.number}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={selected.default}
              color="primary"
              disabled={disableFields}
              id="accountDefaultSwitch"
              onChange={e => this.handleChange('default')({target: {value: e.target.checked}})}
            />
          }
          label="Default"
          labelPlacement="start"
        />
      </Grid>
      <Grid item>
        <Table
          columns={[
            {
              Header: 'Date',
              accessor: 'date'
            },
            {
              Header: 'Amount',
              accessor: 'amount'
            },
            {
              Header: 'Description',
              accessor: 'description'
            },
          ]}
          data={selected.entries}
          getTbodyProps={() => {
            return {
              style: {
                overflowY: 'scroll',
                height: '105px',
                scrollBehavior: 'smooth',
              },
              id: accountEntriesListId,
            }
          }}
          pageSize={Math.max(entriesListLength, selected.entries.length)}
          showPagination={false}
        />
      </Grid>
    </Grid>
  }

  renderControls = () => {
    const {
      activeState,
      isLoading,
      selectedRowIndex,
    } = this.state
    const {
      classes,
    } = this.props

    const disableFields =
      isLoading ||
      (activeState === states.viewingDelete)

    return <div className={classes.controlsWrapper}>
      {(() => {
        switch (activeState) {
          case states.viewing:
            return <React.Fragment>
              <div className={classes.controlsLeft}>
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${Account.camel}NewButton`}
                  onClick={this.handleNew}
                  size="medium"
                >
                  New
                </Button>
                {(selectedRowIndex >= 0) &&
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${Account.camel}DeleteButton`}
                  onClick={this.handleDelete}
                  size="medium"
                >
                  Delete
                </Button>}
              </div>
              <div className={classes.controlsRight}>
                {(selectedRowIndex >= 0) &&
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${Account.camel}RetrieveHistoryButton`}
                  onClick={this.handleRetrieveHistory}
                  size="medium"
                >
                  Show History
                </Button>}
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${Account.camel}ViewDeleteButton`}
                  onClick={this.handleViewDelete}
                  size="medium"
                >
                  View Delete
                </Button>
              </div>
            </React.Fragment>

          case states.creating:
            return <React.Fragment>
              <div className={classes.controlsLeft}>
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${Account.camel}CreateButton`}
                  onClick={this.handleCreate}
                  size="medium"
                >
                  Save New
                </Button>
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${Account.camel}CancelCreateButton`}
                  onClick={this.handleCancelCreate}
                  size="medium"
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.controlsRight}/>
            </React.Fragment>

          case states.editing:
            return <React.Fragment>
              <div className={classes.controlsLeft}>
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${Account.camel}UpdateButton`}
                  onClick={this.handleUpdate}
                  size="medium"
                >
                  Save Changes
                </Button>
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${Account.camel}CancelUpdateButton`}
                  onClick={this.handleCancelUpdate}
                  size="medium"
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.controlsRight}/>
            </React.Fragment>

          case states.viewingDelete:
            return <React.Fragment>
              <div className={classes.controlsLeft}>
                {(selectedRowIndex >= 0) &&
                <Button
                  color="primary"
                  id={`${Account.camel}RestoreButton`}
                  onClick={this.handleRestore}
                  size="medium"
                >
                  Restore
                </Button>}
                {(selectedRowIndex >= 0) &&
                <Button
                  color="primary"
                  id={`${Account.camel}DeleteButton`}
                  onClick={this.handleDelete}
                  size="medium"
                >
                  Delete
                </Button>}
              </div>
              <div className={classes.controlsRight}>
                <Button
                  color="primary"
                  id={`${Account.camel}ReturnButton`}
                  onClick={() => this.find()}
                  size="medium"
                >
                  Return
                </Button>
              </div>
            </React.Fragment>

          default:
        }
      })()}
    </div>
  }

  renderDialogs = () => {
    const {
      isLoading,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      selected,
      showHistory,
      history,
    } = this.state
    const {
      theme,
      currencies,
    } = this.props

    return (
      <React.Fragment>
        <Loader
          color={theme.palette.primary.main}
          isLoading={isLoading}/>
        {showHistory &&
        <HistoryLayout
          addEntityFieldsProps={{currencies}}
          entity={selected}
          entityFields={AccountFields}
          entityHistory={history}
          entityName={Account.capital}
          loading={isLoading}
          onHide={() => this.setState({showHistory: false})}
          open
        />}
        <NotificationSweetAlert
          customClass={`${Account.camel}ConfigAlert`}
          errorMessage={errorMessage}
          onClose={() =>
            this.setState({
              errorMessage: undefined,
              successMessage: undefined,
              warningMessage: undefined,
              confirmationMethod: undefined,
            })}
          onConfirm={confirmationMethod}

          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </React.Fragment>
    )
  }

  static scrollToPos(pos) {
    const objDiv = document.getElementById(accountsListId)
    if (objDiv) {
      objDiv.scrollTop = pos * objDiv.scrollHeight
    }
  }
}

Accounts.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.object),
  partyCode: PropTypes.string.isRequired,
}

const StyledAccounts = withStyles(styles, {withTheme: true})(Accounts)

export default StyledAccounts
