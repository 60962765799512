import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Checkbox, Typography } from '@material-ui/core';
import {
  AutoSizer,
  Column,
  InfiniteLoader,
  SortDirection,
  Table,
} from 'react-virtualized';
import { HexToRGBA } from '../../../utils/Utils';

const styles = (theme) => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundImage: `linear-gradient(90deg, ${HexToRGBA(
        theme.palette.highActions,
        0.5
      )} 70%, ${HexToRGBA(theme.palette.secondary.main, 0.5)} 90%)`,
    },
    '&:focus': {
      backgroundImage: `linear-gradient(90deg, ${HexToRGBA(
        theme.palette.highActions,
        1
      )} 70%, ${HexToRGBA(theme.palette.secondary.main, 1)} 90%)`,
    },
  },

  selected: {
    backgroundImage: `linear-gradient(90deg, ${theme.palette.highActions} 70%, ${theme.palette.secondary.main} 90%)`,
  },

  tableCell: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noClick: {
    cursor: 'initial',
  },
});

const statuses = {
  requested: 'requested',
  loaded: 'loaded',
};

class MuiVirtualizedTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loadedRowsMap: {},
    };

    this.loadMoreRows = this.loadMoreRows.bind(this);
    this.isRowRequested = this.isRowRequested.bind(this);

    this.infiniteLoaderRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clearCacheToggle !== this.props.clearCacheToggle) {
      this.clearCache();
    }
  }

  getRowClassName = () => {
    const { classes, rowClassName } = this.props;

    return classNames(classes.tableRow, classes.flexContainer, rowClassName);
  };

  cellRenderer = ({ cellData, columnIndex = null, rowIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    const { loadedRowsMap } = this.state;

    const style = {
      height: rowHeight,
    };

    return (
      <TableCell
        align={
          (columnIndex != null &&
            columns[columnIndex] &&
            columns[columnIndex].numeric) ||
          false
            ? 'right'
            : 'left'
        }
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        component="div"
        style={style}
        variant="body"
      >
        <div className={classes.tableCell}>
          {loadedRowsMap[rowIndex] === statuses.loaded
            ? cellData
            : columnIndex === 0
              ? 'loading...'
              : ''}
        </div>
      </TableCell>
    );
  };

  renderCheckbox = ({ rowData }) => {
    const {
      rowHeight,
      selected,
      identifyingField,
      classes,
      onRowClick,
    } = this.props;
    const isChecked =
      !!selected.find(
        (item) => item[identifyingField] === rowData[identifyingField]
      ) || false;

    const style = {
      height: rowHeight,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };

    return (
      <TableCell
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        component="div"
        padding={'checkbox'}
        style={style}
        variant="body"
      >
        <Checkbox
          checked={isChecked}
          // todo figure out how to nest this component within tablecell
        />
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
    const { headerHeight, columns, classes, sort } = this.props;
    const direction = {
      [SortDirection.ASC]: 'asc',
      [SortDirection.DESC]: 'desc',
    };

    const inner =
      !columns[columnIndex].disableSort && sort != null ? (
        <TableSortLabel
          active={dataKey === sortBy}
          direction={direction[sortDirection]}
        >
          {label}
        </TableSortLabel>
      ) : (
        label
      );

    return (
      <TableCell
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
        className={classNames(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        component="div"
        style={{ height: headerHeight }}
        variant="head"
      >
        <div className={classes.tableCell}>{inner}</div>
      </TableCell>
    );
  };

  async loadMoreRows({ startIndex, stopIndex }) {
    const { loadedRowsMap } = this.state;

    for (let i = startIndex; i <= stopIndex; i++) {
      loadedRowsMap[i] = statuses.requested;
    }

    this.setState({ loadedRowsMap });
    const response = await this.props.loadMoreRows({ startIndex, stopIndex });
    if (!response) {
      return;
    }

    for (let i = response.startIndex; i <= response.stopIndex; i++) {
      loadedRowsMap[i] = statuses.loaded;
    }

    this.setState({ loadedRowsMap });
  }

  isRowRequested({ index }) {
    const { loadedRowsMap } = this.state;
    return !!loadedRowsMap[index];
  }

  clearCache() {
    this.setState({ loadedRowsMap: {} }, () => {
      try {
        this.infiniteLoaderRef.current.resetLoadMoreRowsCache(true);
      } catch (e) {
        console.error(e);
      }
    });
  }

  render() {
    const {
      selected,
      selectAll,
      classes,
      columns,
      rowCount,
      threshold,
      minimumBatchSize,
      showCheckboxes,
      headerHeight,
      ...tableProps
    } = this.props;
    const { loadedRowsMap } = this.state;
    if (rowCount === 0) {
      return (
        <div
          style={{
            textAlign: 'center',
            display: 'grid',
            height: '100%',
            alignContent: 'center',
          }}
        >
          <Typography variant={'overline'}>{'no records to show'}</Typography>
        </div>
      );
    }
    return (
      <InfiniteLoader
        autoReload
        isRowLoaded={this.isRowRequested}
        loadMoreRows={this.loadMoreRows}
        minimumBatchSize={minimumBatchSize}
        ref={this.infiniteLoaderRef}
        rowCount={rowCount}
        threshold={threshold}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => (
              <Table
                className={classes.table}
                headerHeight={headerHeight}
                height={height}
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                rowCount={rowCount}
                width={width}
                {...tableProps}
                rowClassName={this.getRowClassName}
              >
                {showCheckboxes && (
                  <Column
                    cellRenderer={this.renderCheckbox}
                    dataKey={'checkbox'}
                    headerRenderer={() => (
                      <TableCell
                        align={'center'}
                        className={classNames(
                          classes.tableCell,
                          classes.flexContainer,
                          classes.noClick
                        )}
                        component="div"
                        padding={'checkbox'}
                        style={{ height: headerHeight }}
                        variant="head"
                      >
                        <Checkbox
                          checked={
                            Object.keys(loadedRowsMap).length ===
                            selected.length
                          }
                          onChange={selectAll}
                        />
                      </TableCell>
                    )}
                  />
                )}
                {columns.map(
                  (
                    {
                      cellContentRenderer = null,
                      className,
                      dataKey,
                      sortDirection,
                      ...other
                    },
                    index
                  ) => {
                    let renderer;
                    if (cellContentRenderer != null) {
                      renderer = (cellRendererProps) =>
                        this.cellRenderer({
                          ...cellRendererProps,
                          cellData: cellContentRenderer(cellRendererProps),
                          columnIndex: index,
                        });
                    } else {
                      renderer = this.cellRenderer;
                    }

                    return (
                      <Column
                        cellRenderer={renderer}
                        className={classNames(classes.flexContainer, className)}
                        dataKey={dataKey}
                        headerRenderer={(headerProps) =>
                          this.headerRenderer({
                            ...headerProps,
                            columnIndex: index,
                          })
                        }
                        key={dataKey}
                        {...other}
                        width={140}
                      />
                    );
                  }
                )}
              </Table>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    );
  }
}

MuiVirtualizedTable.defaultProps = {
  headerHeight: 56,
  rowHeight: 56,
  rowCount: 9999,
  threshold: 50,
  minimumBatchSize: 35,
  showCheckboxes: false,
  selected: [],
  identifyingField: 'id',
  loadMoreRows: ({ startIndex, stopIndex }) =>
    new Promise((resolve) => resolve({ startIndex, stopIndex })),
  dontHighlightSelected: false,
  selectAll: () => {},
};

const StyledMuiVirtualizedTable = withStyles(styles, { withTheme: true })(
  MuiVirtualizedTable
);
export default StyledMuiVirtualizedTable;

// example use
/**
 *
 *
 *
 <Table
 clearCacheToggle={this.state.clearCacheToggle}
 selected={selectedInvoices}
 showCheckboxes
 threshold={30}
 minimumBatchSize={50}
 rowCount={total}
 rowGetter={({ index }) => {
      let inv = {}

      if (invoices[index]) {
        inv = invoices[index]
      }

      return inv
    }}
 sort={this.handleSortChange}
 sortBy={sortBy}
 sortDirection={sortDirection}
 loadMoreRows={this.loadMoreRows}
 onRowClick={row => this.handleSelection(row.rowData)}
 columns={[
      {
        width: 200,
        flexGrow: 1.0,
        label: 'Counterparty',
        dataKey: 'counterpartyId',
        cellContentRenderer: (cell) => {
          return (invoiceCounterparties.find(b => b.id ===cell.cellData)||{}).name || cell.cellData
        },
        disableSort: true,
      },
      {
        width: 140,
        label: 'Number',
        dataKey: 'number',
      },
      {
        width: 120,
        label: 'Currency',
        dataKey: 'currencyId',
        cellContentRenderer: (cell) => {
          return (currencies.find(c => c.id === cell.cellData)||{}).isoCode || cell.cellData
        },
        disableSort: true,
      },
      {
        width: 140,
        label: 'Due date',
        dataKey: 'dueDate',
        cellContentRenderer: (cell) => {
          return processUnixDateForViewing(cell.cellData)
        }
      },
      {
        width: 120,
        label: 'Amount',
        dataKey: 'amountDue',
        numeric: true,
        cellContentRenderer: (cell) => {
          return FormatNumber(cell.cellData,true,true)
        }
      },
    ]}
 />
 */
