import {jsonRpcRequestRaw as jsonRpcRequest} from '../../actions/helper/utilities'
import config from 'react-global-configuration'

export const Generator = {
  ExportAll() {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'ExceptionReport.ExportAll',
      request: {},
    })
  },

  ExportUnread() {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'ExceptionReport.ExportUnread',
      request: {},
    })
  },

  ExportRecurring() {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'ExceptionReport.ExportRecurring',
      request: {},
    })
  },
}
