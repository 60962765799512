import React, {useState, ReactNode} from 'react'
import {
  Card as MUICard,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  makeStyles,
  Tooltip as MUITooltip,
  withStyles,
  CardProps,
  CardContentProps,
  CardHeaderProps,
} from '@material-ui/core'
import {MdExpandLess, MdExpandMore,} from 'react-icons/md'
import {HexToRGBA} from 'utils/Utils'

const useStyles = makeStyles(theme => ({
  headerRoot: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1.75),
    height: '52px',
  },
  headerTitleText: {
    color: theme.palette.primary.contrastText,
  },
  iconCollapsed: {
    color: theme.palette.primary.contrastText,
  },
  icon: {
    color: HexToRGBA(theme.palette.primary.contrastText, 0.5),
    '&:hover': {
      color: theme.palette.primary.contrastText,
    }
  },
}))

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MUITooltip);

interface Props {
  title: ReactNode;
  cardProps: CardProps;
  cardContentProps: CardContentProps;
  additionalActions: ReactNode;
  cardHeaderProps?: CardHeaderProps;
  children: ReactNode;
}
function CardTS(props: Props) {
  const [open, setOpen] = useState(true)
  const classes = useStyles()
  const {
    title,
    cardProps,
    cardContentProps,
    additionalActions,
    cardHeaderProps,
  } = props

  let addCardProps = {}
  if (cardProps) {
    addCardProps = {
      ...cardProps,
    }
  }

  let addCardContentProps = {}
  if (cardContentProps) {
    addCardContentProps = {
      ...cardContentProps,
    }
  }

  let addCardHeaderProps = {}
  if (cardHeaderProps) {
    addCardHeaderProps = {
      ...cardHeaderProps,
    }
  }

  return (
    <MUICard
      {...addCardProps}>
      <CardHeader
        action={
          <React.Fragment>
            {open
              ? (
                <React.Fragment>
                  {additionalActions}
                  <LightTooltip title={'Collapse'}>
                    <IconButton
                      className={classes.icon}
                      onClick={() => setOpen(!open)}
                    >
                      <MdExpandLess/>
                    </IconButton>
                  </LightTooltip>
                </React.Fragment>
              )
              : (
                <LightTooltip title={'Expand'}>
                  <IconButton
                    className={classes.iconCollapsed}
                    onClick={() => setOpen(!open)}
                  >
                    <MdExpandMore className={classes.iconCollapsed}/>
                  </IconButton>
                </LightTooltip>
              )
            }
          </React.Fragment>
        }
        {...addCardHeaderProps}
        classes={{root: classes.headerRoot}}
        title={title}
        titleTypographyProps={{
          className: classes.headerTitleText,
          variant: 'h6',
        }}
      />
      <Collapse in={open}>
        <CardContent {...addCardContentProps}>
          {props.children ? props.children : null}
        </CardContent>
      </Collapse>
    </MUICard>
  )
}

export default CardTS