import {
  LOGIN_SUCCESS,
  PARTY_SWITCH_CONTEXT_SUCCESS,
} from 'actions/actionTypes'
import {FIND_ALL_MY_PERMISSIONS_SUCCESS} from 'actions/actionTypes'

const newToOldClaims = (newClaims) => {
  const oldClaims = {
    userProfile: newClaims?.user,
    type: newClaims?.webTokenPayload?.type,
    exp: newClaims?.webTokenPayload?.exp,
    nbf: newClaims?.webTokenPayload?.nbf,
    iat: newClaims?.webTokenPayload?.iat,
    jti: newClaims?.webTokenPayload?.jti,
    roleId: newClaims?.currentRole?.id,
    username: newClaims?.user?.displayName,
    context: newClaims?.currentContext,
    originalContext: newClaims?.originalContext,
    extendedContext: newClaims?.extendedContext,
    userId: newClaims?.user?.id,
  }

  return oldClaims
}

export default function security(state = {
  viewPermissions: JSON.parse(sessionStorage.getItem('view_permissions')),
  claims: JSON.parse(localStorage.getItem('claims')),
}, action) {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const claims = newToOldClaims(action.claims)
      localStorage.setItem('claims', JSON.stringify(claims))
      return {
        ...state,
        claims: claims,
      }
    }
    case PARTY_SWITCH_CONTEXT_SUCCESS: {
      const claims = newToOldClaims(action.result.claims)
      localStorage.setItem('claims', JSON.stringify(claims))
      return {
        ...state,
        claims: claims,
        permissions: []
      }
    }
    case FIND_ALL_MY_PERMISSIONS_SUCCESS: {
      const viewPermissions = action.result.permissions.filter(
        p => p.toLowerCase().startsWith('view.'))
      sessionStorage.setItem('view_permissions',
        JSON.stringify(viewPermissions))
      return {...state, permissions: action.result.permissions, viewPermissions}
    }
    default:
      return state
  }
}
