import React from 'react'
import PropTypes from 'prop-types'
import {MdErrorOutline as ErrorIcon} from 'react-icons/md'
import Typography from '@material-ui/core/Typography'

// ComponentLevelError is an Error icon than can be implemented nested within components. It does not render over the whole of the viewport.
// 100% width and default height of 200px and font size of 80
const ComponentLevelError = (props) => {
  return (
    <div
      style={{
        height: '200px',
        ...props.style,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr auto',
        alignItems: 'center',
        justifyItems: 'center',
        textAlign: 'center'
      }}
    >
      <div>
        <ErrorIcon
          style={{
            fontSize: 80,
          }}
        />
        <Typography variant={'overline'}>{
          props.errorMessage || 'Whoops.. Something went wrong'
        }</Typography>
      </div>
    </div>
  )
}

ComponentLevelError.propTypes = {
  errorMessage: PropTypes.string,
  style: PropTypes.object,
}

export {ComponentLevelError}
