import {isObject} from '../../../utils/Utils'

export class Address {
  /**
   * @type {string}
   */
  line1 = ''
  /**
   * @type {string}
   */
  line2 = ''
  /**
   * @type {string}
   */
  city = ''
  /**
   * @type {string}
   */
  province = ''
  /**
   * @type {string}
   */
  postalCode = ''
  /**
   * @type {string}
   */
  country = ''

  constructor(address) {
    if (address !== undefined &&
      (address instanceof Address || isObject(address))) {
      try {
        this.line1 = address.line1
        this.line2 = address.line2
        this.city = address.city
        this.province = address.province
        this.postalCode = address.postalCode
        this.country = address.country
      } catch (e) {
        console.error(`error constructing address: ${e}`)
        throw e
      }
    }
  }

}

export class ContactDetails {
  /**
   * @type {Address}
   */
  physicalAddress = new Address()
  /**
   * @type {Address}
   */
  postalAddress = new Address()
  /**
   * @type {number}
   */
  telephoneNumber = undefined
  /**
   * @type {number}
   */
  faxNumber = undefined
  /**
   * @type {string}
   */
  emailAddress = ''
  /**
   * @type {string}
   */
  website = ''

  constructor(contactDetails) {
    if (contactDetails !== undefined &&
      (contactDetails instanceof ContactDetails ||
        isObject(contactDetails))) {
      try {
        this.physicalAddress = new Address(contactDetails.physicalAddress)
        this.postalAddress = new Address(contactDetails.postalAddress)
        this.telephoneNumber = contactDetails.telephoneNumber
        this.faxNumber = contactDetails.faxNumber
        this.emailAddress = contactDetails.emailAddress
        this.website = contactDetails.website
      } catch (e) {
        console.error(`error constructing contact details: ${e}`)
        throw e
      }
    }
  }
}

