import uuidv4 from 'uuid/v4';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  DATE_CRITERION,
  EXACT_CRITERION,
  ExactCriterion,
} from 'popcorn-js/search/criteria/types';
import { InvoiceRecordkeeper } from 'popcorn-js/financial/invoice';
import SettlementInstructionComparator from 'popcorn-js/financial/settlementInstruction/comparator';
import { ScaleLoader as Spinner } from 'react-spinners';
import { ComponentLevelError } from 'components/Error/Error';
import {
  MdClose as CloseIcon,
  MdDone as CompleteIcon,
  MdExpandMore as ExpandMoreIcon,
  MdSave as SaveIcon,
} from 'react-icons/md';
import {
  FiAlertTriangle as ErrorsIcon,
  FiLink as LinkIcon,
  FiPlus as AddTradesIcon,
  FiX as DeselectIcon,
} from 'react-icons/fi';
import miniLogo from 'assets/img/stellcap-logo-mini.svg';
import moment from 'moment';
import TradeSelectionDialog from './TradeSelectionDialog';
import { FormatNumber } from 'utils/TradeUtilities';
import VirtualizedTable from 'components/Table/VirtualizedTable/VirtualizedTable';
import { isArray } from 'utils/Utils';
import TextField, {
  parseTypes as TextFieldParseTypes,
} from 'components/FormContols/TextField';
import SIHandler from 'popcorn-js/financial/settlementInstruction/handler';
import TradeRecordkeeper from 'popcorn-js/trade/recordkeeper';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import { PrepaymentRecordkeeper } from 'popcorn-js/financial/accounting/prepayment';
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert';
import {
  StatusSubmitted,
} from 'constants/settlementInstruction';
import {
  INVOICE_TYPE_PURCHASE_CREDIT_NOTE,
  INVOICE_TYPE_PURCHASE_INVOICE, INVOICE_TYPE_SALES_CREDIT_NOTE,
  INVOICE_TYPE_SALES_INVOICE
} from '../../../../constants/invoice';

const styles = (theme) => ({
  dialog: {},
  dialogRootOverride: {
    backgroundColor: theme.palette.background.paper,
  },
  dialogTitleWrapper: {
    padding: 5,
    backgroundColor: theme.palette.background.default,
  },
  dialogTitle: {
    padding: 5,
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogTitleHeading: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
  },
  dialogTitleControlsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  TBDLogoWrapper: {
    padding: 4,
  },
  TBDLogoImg: {
    width: '30px',
    verticalAlign: 'middle',
    border: '0',
  },
  dialogTitleText: {
    justifySelf: 'center',
    marginLeft: 10,
    color: theme.palette.primary.contrastText,
  },
  dialogTitleCloseBtnWrapper: {
    justifySelf: 'end',
    paddingLeft: 4,
  },
  dialogTitleCloseButton: {
    padding: 2,
    minHeight: '20px',
    minWidth: '20px',
    height: '20px',
    width: '20px',
  },
  dialogTitleCloseIcon: {
    fontSize: 15,
  },
  buttonIcon: {
    fontSize: 20,
  },
  iconBadge: {
    margin: theme.spacing(),
    marginRight: 35,
  },
  card: {
    borderWidth: '6px 0 0 0',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  errorWrapper: {
    alignSelf: 'center',
    justifySelf: 'center',
  },
  contentWrapper: {
    padding: 10,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  rootOverride: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr',
    overflow: 'hidden',
  },
  contentCardTitleLayout: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: '1fr',
    height: 66,
  },
  twoRowLayout: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
  },
  tradeExpansionLayout: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  tradeExpansionPanelDetailsRoot: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    boxShadow: 'inset 0 0 3px #000000',
    backgroundColor: theme.palette.background.default,
  },
  tradeExpansionPanelDetailsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: '10px',
    maxHeight: '350px',
    overflowY: 'scroll',
  },
  tradeExpansionPanelDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tradeRemoveLabel: {
    marginTop: 16,
    cursor: 'pointer',
    color: theme.palette.error.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tradeHeadingCard: {
    padding: '0 5 0 5',
  },
  tradeContentCardWrapper: {
    padding: 5,
  },
  tradeContentCards: {
    height: 'calc(100vh - 320px)',
    padding: 5,
    overflowY: 'scroll',
  },
  tradeExpansionCardSummaryLayout: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridTemplateRows: '1fr',
    alignItems: 'center',
  },
  tradeExpansionCardHeading: {
    fontSize: theme.typography.pxToRem(15),
    width: 250,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    width: 250,
  },
  tradeExpansionCardCheckboxWrapper: {
    width: 50,
  },
  exposureTradeLinksPaper: {
    margin: 5,
  },
  exposureTradeLinksTableRoot: {
    overflowX: 'auto',
  },
  exposureTradeLinksTable: {},
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: '#00dc1f',
  },
  progressSpinnerDialog: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  progressSpinnerDialogBackdrop: {
    backgroundColor: 'transparent',
  },
  tradeExpansionPanelItemHeadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px',
  },
  tradeExpansionPanelItemHeading: {
    fontSize: theme.typography.pxToRem(16),
    weight: 600,
  },
});

function newSi(si) {
  if (si === undefined) {
    return {
      auditEntry: {},
      date: 0,
      id: '',
      partyCode: '',
      status: '',
      processingOrg: '',
      currencyId: '',
      invoiceAmountsRequested: [],
      invoiceTradeLinks: [],
      prepaymentAmountsRequested: [],
      prepaymentTradeLinks: [],
      customEntries: [],
    };
  }

  return {
    auditEntry: si.auditEntry ? si.auditEntry : {},
    date: si.date,
    id: si.id,
    partyCode: si.partyCode,
    status: si.status,
    processingOrg: si.processingOrg,
    currencyId: si.currencyId,
    invoiceAmountsRequested: isArray(si.invoiceAmountsRequested)
      ? si.invoiceAmountsRequested.map((invAmntReq) => ({ ...invAmntReq }))
      : [],
    invoiceTradeLinks: isArray(si.invoiceTradeLinks)
      ? si.invoiceTradeLinks.map((invTrdLnk) => ({ ...invTrdLnk }))
      : [],
    prepaymentAmountsRequested: isArray(si.prepaymentAmountsRequested)
      ? si.prepaymentAmountsRequested.map((ppAmntReq) => ({ ...ppAmntReq }))
      : [],
    prepaymentTradeLinks: isArray(si.prepaymentTradeLinks)
      ? si.prepaymentTradeLinks.map((ppTrdLnk) => ({ ...ppTrdLnk }))
      : [],
    customEntries: isArray(si.customEntries)
      ? si.customEntries.map((entry) => ({
        ...entry,
        tradeLinks: isArray(entry.tradeLinks)
          ? entry.tradeLinks.map((link) => ({ ...link }))
          : [],
      }))
      : [],
    financialYear: si.financialYear,
    importExport: si.importExport,
    fxAmount: si.fxAmount,
    localCurrencyAmount: si.localCurrencyAmount,
    dealRate: si.dealRate,
    fxSource: si.fxSource,
    avgCostingRate: si.avgCostingRate,
    avgEffectiveRate: si.avgEffectiveRate,
    pnl: si.pnl,
  };
}

const exposureTabs = {
  invoice: 0,
  prepayments: 1,
  customEntry: 2,
};

class SIExposureTradeLinkerDialog extends Component {
  state = {
    showTradeSelectionDialog: false,
    loading: false,
    error: undefined,

    invoices: [],
    selectedInvoices: [],

    prepayments: [],
    selectedPrepayments: [],

    trades: [],
    tradeExpansionPanelsState: {},
    selectedTrade: {},
    settlementInstruction: {},
    successMessage: undefined,
    errorMessage: undefined,
    warningMessage: undefined,
    confirmationMethod: undefined,
    onCloseNotification: () => {},
    activeExposureTab: exposureTabs.invoice,
    selectedCustomEntries: [],
    counterparties: [],
  };

  constructor(props) {
    super(props);
    const tradeCurrencyPairCriteria = [];
    for (const ccyPair of props.currencyPairs) {
      if (
        ccyPair.baseCurrencyId === props.settlementInstruction.currencyId ||
        ccyPair.quoteCurrencyId === props.settlementInstruction.currencyId
      ) {
        tradeCurrencyPairCriteria.push({
          type: EXACT_CRITERION,
          value: {
            text: ccyPair.id,
            field: 'currencyPairId',
          },
        });
      }
    }

    this.defaultTradeCriteria = [
      ...tradeCurrencyPairCriteria,
      {
        type: EXACT_CRITERION,
        value: {
          text: props.settlementInstruction.partyCode,
          field: 'tradingPartyCode',
        },
      },
      // todo what should the criteria be here
      {
        type: DATE_CRITERION,
        value: {
          field: 'legs.0.maturityDate',
          startDate: {
            ignore: true,
            date: 0,
            inclusive: true,
          },
          endDate: {
            date: moment
              .unix(props.settlementInstruction.date)
              .endOf('day')
              .unix(),
            inclusive: true,
          },
        },
      },
    ];
    // make a copy of the original si so as not to edit the
    // object passed to this dialog
    this.originalSI = newSi(props.settlementInstruction);
    this.originalSI = {
      ...this.originalSI,
      // index the invoice trade links for easy comparison
      invoiceTradeLinks: this.originalSI.invoiceTradeLinks.map((invTrdLnk) => ({
        id: uuidv4(),
        ...invTrdLnk,
      })),
      // index the prepayment trade links for easy comparison
      prepaymentTradeLinks: this.originalSI.prepaymentTradeLinks.map(
        (ppTrdLnk) => ({
          id: uuidv4(),
          ...ppTrdLnk,
        })
      ),
      customEntries: this.originalSI.customEntries.map((entry) => ({
        // index the custom entries for easy comparison
        id: uuidv4(),
        counterpartyId: entry.counterpartyId,
        description: entry.description,
        amount: entry.amount,
        // index the each custom entry's trade links for easy comparison
        tradeLinks: entry.tradeLinks.map((link) => ({
          id: uuidv4(),
          ...link,
        })),
      })),
    };

    // make a copy of the indexed si so that the original is not
    // changed. will be used for comparison to determine
    // if changes have been made

    this.state.settlementInstruction = newSi(this.originalSI);
  }

  componentDidMount() {
    this.findSIEntities();
  }

  findSIEntities = async () => {
    const { settlementInstruction } = this.state;
    this.setState({ loading: true });

    // find invoices if there are any invoice amounts requested
    try {
      if (settlementInstruction.invoiceAmountsRequested.length > 0) {
        const invoiceFindResponse = await InvoiceRecordkeeper.find({
          criteria: settlementInstruction.invoiceAmountsRequested.map(
            (invAmountRequested) =>
              new ExactCriterion({
                field: 'id',
                text: invAmountRequested.invoiceId,
              })
          ),
        });
        this.setState({ invoices: invoiceFindResponse.records });
      }
    } catch (e) {
      console.error('error getting invoices associated with si', e);
      this.setState({ error: e.toString() });
    }

    // find prepayments if there are any prepayment amounts requested
    try {
      if (settlementInstruction.prepaymentAmountsRequested.length > 0) {
        const prepaymentFindResponse = await PrepaymentRecordkeeper.find({
          criteria: settlementInstruction.prepaymentAmountsRequested.map(
            (ppAmntReq) =>
              new ExactCriterion({
                field: 'id',
                text: ppAmntReq.prepaymentId,
              })
          ),
        });
        this.setState({ prepayments: prepaymentFindResponse.records });
      }
    } catch (e) {
      console.error('error getting prepayments associated with si', e);
      this.setState({ error: e.toString() });
    }

    // find trades if there are any links
    try {
      const tradeIds = [];

      // add trades associated with invoice links
      for (const link of settlementInstruction.invoiceTradeLinks) {
        if (!tradeIds.includes(link.tradeId)) {
          tradeIds.push(link.tradeId);
        }
      }

      // add trades associated with prepayment links
      for (const link of settlementInstruction.prepaymentTradeLinks) {
        if (!tradeIds.includes(link.tradeId)) {
          tradeIds.push(link.tradeId);
        }
      }

      // add trades associated with custom entries
      for (const customEntry of settlementInstruction.customEntries) {
        for (const link of customEntry.tradeLinks) {
          if (!tradeIds.includes(link.tradeId)) {
            tradeIds.push(link.tradeId);
          }
        }
      }

      if (tradeIds.length > 0) {
        const tradeFindResponse = await TradeRecordkeeper.find({
          criteria: tradeIds.map(
            (id) =>
              new ExactCriterion({
                field: 'id',
                text: id,
              })
          ),
        });
        this.setState({ trades: tradeFindResponse.records });
      }
    } catch (e) {
      console.error('error getting trades associated with si', e);
      this.setState({ error: e.toString() });
    }

    // find custom entry counterparties
    try {
      const counterpartyFindResponse = await CounterpartyRecordkeeper.find({
        criteria: settlementInstruction.customEntries.map(
          (customEntry) =>
            new ExactCriterion({
              field: 'id',
              text: customEntry.counterpartyId,
            })
        ),
        query: undefined,
        Deleted: false,
      });
      this.setState({ counterparties: counterpartyFindResponse.records });
    } catch (e) {
      console.error('error finding custom entry counterparties');
    }

    this.setState({ loading: false });
  };

  renderContent = () => {
    const { error } = this.state;
    const { classes } = this.props;

    if (error) {
      return (
        <div className={classes.errorWrapper}>
          <ComponentLevelError errorMessage={error} />
        </div>
      );
    }

    return (
      <div
        className={classes.contentWrapper}
        style={{
          gridColumnGap: 16,
        }}
      >
        {this.renderExposures()}
        {this.renderTrades()}
      </div>
    );
  };

  handleInvoiceRowSelect = ({ rowData }) => {
    if (!(rowData && rowData.id)) {
      return;
    }
    let { selectedInvoices } = this.state;
    if (selectedInvoices.find((inv) => inv.id === rowData.id)) {
      selectedInvoices = selectedInvoices.filter(
        (inv) => inv.id !== rowData.id
      );
    } else {
      selectedInvoices.push(rowData);
    }
    this.setState({ selectedInvoices });
  };

  handleSelectAllInvoices = () => {
    const { invoices, selectedInvoices } = this.state;
    if (invoices.length === selectedInvoices.length) {
      // all are already selected
      this.setState({ selectedInvoices: [] });
    } else {
      // select all
      this.setState({ selectedInvoices: [...invoices] });
    }
  };

  handlePrepaymentRowSelect = ({ rowData }) => {
    if (!(rowData && rowData.id)) {
      return;
    }
    let { selectedPrepayments } = this.state;
    if (selectedPrepayments.find((pp) => pp.id === rowData.id)) {
      selectedPrepayments = selectedPrepayments.filter(
        (pp) => pp.id !== rowData.id
      );
    } else {
      selectedPrepayments.push(rowData);
    }
    this.setState({ selectedPrepayments });
  };

  handleSelectAllPrepayments = () => {
    const { prepayments, selectedPrepayments } = this.state;
    if (prepayments.length === selectedPrepayments.length) {
      // all are already selected
      this.setState({ selectedPrepayments: [] });
    } else {
      // select all
      this.setState({ selectedPrepayments: [...prepayments] });
    }
  };

  handleCustomEntryRowSelect = ({ rowData }) => {
    if (!(rowData && rowData.id)) {
      return;
    }
    let { selectedCustomEntries } = this.state;
    if (selectedCustomEntries.find((entry) => entry.id === rowData.id)) {
      selectedCustomEntries = selectedCustomEntries.filter(
        (entry) => entry.id !== rowData.id
      );
    } else {
      selectedCustomEntries.push(rowData);
    }
    this.setState({ selectedCustomEntries });
  };

  handleSelectAllCustomEntries = () => {
    const { settlementInstruction, selectedCustomEntries } = this.state;
    if (
      settlementInstruction.customEntries.length ===
      selectedCustomEntries.length
    ) {
      // all are already selected
      this.setState({ selectedCustomEntries: [] });
    } else {
      // select all
      this.setState({
        selectedCustomEntries: [...settlementInstruction.customEntries],
      });
    }
  };

  handleExposureTypeTabChange = (muiClass, newTabValue) => {
    this.setState({ activeExposureTab: newTabValue });
  };

  renderExposures = () => {
    const {
      invoices,
      selectedInvoices,
      activeExposureTab,
      selectedCustomEntries,
      settlementInstruction,
      prepayments,
      selectedPrepayments,
    } = this.state;
    const { classes } = this.props;

    return (
      <Card className={classes.card}>
        <CardHeader
          title={
            <div className={classes.twoRowLayout}>
              <Tabs
                onChange={this.handleExposureTypeTabChange}
                value={activeExposureTab}
              >
                <Tab
                  label={'Invoices'}
                  value={exposureTabs.invoice} />
                <Tab
                  label={'Prepayments'}
                  value={exposureTabs.prepayments} />
                <Tab
                  label={'Custom Entry'}
                  value={exposureTabs.customEntry} />
              </Tabs>
              <Typography className={classes.secondaryHeading}>
                {(() => {
                  switch (activeExposureTab) {
                    case exposureTabs.invoice:
                      return 'Select 1 or More Invoices';
                    case exposureTabs.prepayments:
                      return 'Select 1 or More Prepayments';
                    case exposureTabs.customEntry:
                      return 'Select 1 or More Custom Entries';
                    default:
                      return '';
                  }
                })()}
              </Typography>
            </div>
          }
        />
        <CardContent>
          <div style={{ height: 'calc(100vh - 300px)' }}>
            {(() => {
              switch (activeExposureTab) {
                case exposureTabs.invoice:
                  return (
                    <VirtualizedTable
                      columns={[
                        {
                          label: 'Number',
                          dataKey: 'number',
                          flexGrow: 1.0,
                          width: 150,
                        },
                        {
                          width: 60,
                          label: 'Type',
                          dataKey: 'type',
                        },
                        {
                          width: 100,
                          label: 'Direction',
                          dataKey: 'direction',
                        },
                        {
                          label: 'Amount Due',
                          dataKey: 'amountDue',
                          flexGrow: 1.0,
                          width: 150,
                          cellContentRenderer: ({ cellData }) => {
                            return FormatNumber(cellData, true, true, 2, true);
                          },
                          numeric: true,
                        },
                        {
                          label: 'Outstanding Amount',
                          dataKey: 'amountDue',
                          flexGrow: 1.0,
                          width: 150,
                          cellContentRenderer: ({ rowData }) => {
                            return FormatNumber(
                              getInvoiceOutstandingAmount(rowData),
                              true,
                              true,
                              2,
                              true
                            );
                          },
                          numeric: true,
                        },
                        {
                          label: 'Outstanding Requested Amount',
                          dataKey: 'amountDue',
                          flexGrow: 1.0,
                          width: 150,
                          cellContentRenderer: ({ rowData }) => {
                            const outstandingInvoiceAmountRequested = this.getOutstandingInvoiceAmountRequested(
                              rowData
                            );
                            return (
                              <div
                                className={classNames({
                                  [classes.error]:
                                    outstandingInvoiceAmountRequested < 0,
                                  [classes.success]:
                                    outstandingInvoiceAmountRequested === 0,
                                })}
                              >
                                {FormatNumber(
                                  outstandingInvoiceAmountRequested,
                                  true,
                                  true,
                                  2,
                                  true
                                )}
                              </div>
                            );
                          },
                          numeric: true,
                        },
                      ]}
                      dontHighlightSelected
                      minimumBatchSize={50}
                      onRowClick={this.handleInvoiceRowSelect}
                      rowCount={invoices.length}
                      rowGetter={({ index }) => {
                        if (index > invoices.length - 1) {
                          return {};
                        }
                        return invoices[index];
                      }}
                      selectAll={this.handleSelectAllInvoices}
                      selected={selectedInvoices}
                      showCheckboxes
                      threshold={30}
                    />
                  );

                case exposureTabs.prepayments:
                  return (
                    <VirtualizedTable
                      columns={[
                        {
                          label: 'Number',
                          dataKey: 'number',
                          flexGrow: 1.0,
                          width: 150,
                        },
                        {
                          label: 'Amount Due',
                          dataKey: 'amountDue',
                          flexGrow: 1.0,
                          width: 150,
                          cellContentRenderer: ({ cellData }) => {
                            return FormatNumber(cellData, true, true, 2, true);
                          },
                          numeric: true,
                        },
                        {
                          label: 'Outstanding Amount',
                          dataKey: 'amountDue',
                          flexGrow: 1.0,
                          width: 150,
                          cellContentRenderer: ({ rowData }) => {
                            return FormatNumber(
                              getPrepaymentOutstandingAmount(rowData),
                              true,
                              true,
                              2,
                              true
                            );
                          },
                          numeric: true,
                        },
                        {
                          label: 'Outstanding Requested Amount',
                          dataKey: 'amountDue',
                          flexGrow: 1.0,
                          width: 150,
                          cellContentRenderer: ({ rowData }) => {
                            const outstandingPrepaymentAmountRequested = this.getOutstandingPrepaymentAmountRequested(
                              rowData
                            );
                            return (
                              <div
                                className={classNames({
                                  [classes.error]:
                                    outstandingPrepaymentAmountRequested < 0,
                                  [classes.success]:
                                    outstandingPrepaymentAmountRequested === 0,
                                })}
                              >
                                {FormatNumber(
                                  outstandingPrepaymentAmountRequested,
                                  true,
                                  true,
                                  2,
                                  true
                                )}
                              </div>
                            );
                          },
                          numeric: true,
                        },
                      ]}
                      dontHighlightSelected
                      minimumBatchSize={50}
                      onRowClick={this.handlePrepaymentRowSelect}
                      rowCount={prepayments.length}
                      rowGetter={({ index }) => {
                        if (index > prepayments.length - 1) {
                          return {};
                        }
                        return prepayments[index];
                      }}
                      selectAll={this.handleSelectAllPrepayments}
                      selected={selectedPrepayments}
                      showCheckboxes
                      threshold={30}
                    />
                  );

                case exposureTabs.customEntry:
                  return (
                    <VirtualizedTable
                      columns={[
                        {
                          label: 'Counterparty',
                          dataKey: 'counterpartyId',
                          flexGrow: 1.0,
                          width: 150,
                          cellContentRenderer: ({ cellData }) => {
                            return this.getCounterpartyName(cellData);
                          },
                        },
                        {
                          label: 'Description',
                          dataKey: 'description',
                          flexGrow: 1.0,
                          width: 150,
                        },
                        {
                          label: 'Required Amount',
                          dataKey: 'amount',
                          flexGrow: 1.0,
                          width: 150,
                          cellContentRenderer: ({ cellData }) => {
                            return FormatNumber(cellData, true, true, 2, true);
                          },
                          numeric: true,
                        },
                        {
                          label: 'Outstanding Amount',
                          dataKey: 'amount',
                          flexGrow: 1.0,
                          width: 150,
                          cellContentRenderer: ({ rowData }) => {
                            const outstandingCustomEntryAmount = this.getOutstandingCustomEntryAmount(
                              rowData.id
                            );
                            return (
                              <div
                                className={classNames({
                                  [classes.error]:
                                    outstandingCustomEntryAmount < 0,
                                  [classes.success]:
                                    outstandingCustomEntryAmount === 0,
                                })}
                              >
                                {FormatNumber(
                                  outstandingCustomEntryAmount,
                                  true,
                                  true,
                                  2,
                                  true
                                )}
                              </div>
                            );
                          },
                          numeric: true,
                        },
                      ]}
                      dontHighlightSelected
                      minimumBatchSize={50}
                      onRowClick={this.handleCustomEntryRowSelect}
                      rowCount={settlementInstruction.customEntries.length}
                      rowGetter={({ index }) => {
                        if (
                          index >
                          settlementInstruction.customEntries.length - 1
                        ) {
                          return {};
                        }
                        return settlementInstruction.customEntries[index];
                      }}
                      selectAll={this.handleSelectAllCustomEntries}
                      selected={selectedCustomEntries}
                      showCheckboxes
                      threshold={30}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </CardContent>
      </Card>
    );
  };

  handleAddTradeToLink = (trade) => {
    if (trade.id === undefined) {
      return;
    }
    const { tradeExpansionPanelsState, trades } = this.state;
    if (Object.keys(tradeExpansionPanelsState).includes(trade.id)) {
      return;
    }
    trades.push(trade);
    tradeExpansionPanelsState[trade.id] = false;
    this.setState({ tradeExpansionPanelsState, trades });
  };

  handleSelectTrade = (trade) => {
    const { selectedTrade } = this.state;
    if (trade.id === selectedTrade.id) {
      this.setState({
        selectedTrade: {},
      });
    } else {
      this.setState({
        selectedTrade: trade,
      });
    }
  };

  handleRemoveTrade = (trade) => {
    const { settlementInstruction, tradeExpansionPanelsState } = this.state;
    const { trades } = this.state;

    // remove any invoice trade links associated with this trade
    settlementInstruction.invoiceTradeLinks = settlementInstruction.invoiceTradeLinks.filter(
      (invTrdLnk) => invTrdLnk.tradeId !== trade.id
    );

    // remove any prepayment trade links associated with this trade
    settlementInstruction.prepaymentTradeLinks = settlementInstruction.prepaymentTradeLinks.filter(
      (ppTrdLnk) => ppTrdLnk.tradeId !== trade.id
    );

    // remove any custom entry links associated with this trade
    for (const customEntry of settlementInstruction.customEntries) {
      customEntry.tradeLinks = customEntry.tradeLinks.filter(
        (link) => link.tradeId !== trade.id
      );
    }

    // remove expansion panel key associated with this trade
    delete tradeExpansionPanelsState[trade.id];

    this.setState({
      // remove this trade from trades in state
      trades: trades.filter((trd) => trd.id !== trade.id),
      settlementInstruction,
      selectedTrade: {},
      tradeExpansionPanelsState,
    });
  };

  renderTrades = () => {
    const { trades, tradeExpansionPanelsState, selectedTrade } = this.state;
    const { classes } = this.props;

    return (
      <Card className={classes.card}>
        <CardHeader
          title={
            <div className={classes.contentCardTitleLayout}>
              <div className={classes.twoRowLayout}>
                <Typography variant={'h6'}>Trades</Typography>
                <Typography className={classes.secondaryHeading}>
                  Select a Trade to Link The Invoices to
                </Typography>
              </div>
              <Grid
                container
                direction="row"
                justify="flex-end">
                <Grid item>
                  <Tooltip title={'Add Trades'}>
                    <IconButton
                      onClick={() =>
                        this.setState({ showTradeSelectionDialog: true })
                      }
                    >
                      <AddTradesIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          }
        />
        <CardContent>
          {(() => {
            if (trades.length === 0) {
              return (
                <ExpansionPanel expanded={false}>
                  <ExpansionPanelSummary>
                    <div className={classes.tradeExpansionCardSummaryLayout}>
                      <div
                        className={classes.tradeExpansionCardCheckboxWrapper}
                      />
                      <Typography className={classes.tradeExpansionCardHeading}>
                        No Trades Added
                      </Typography>
                      <Typography
                        className={classes.secondaryHeading}
                        style={{ width: 350 }}
                      >
                        Add Trades to See Them Here For Linking
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                </ExpansionPanel>
              );
            } else {
              return (
                <div className={classes.tradeExpansionLayout}>
                  <div className={classes.tradeHeadingCard}>
                    <ExpansionPanel expanded={false}>
                      <ExpansionPanelSummary>
                        <div
                          className={classes.tradeExpansionCardSummaryLayout}
                        >
                          <div
                            className={
                              classes.tradeExpansionCardCheckboxWrapper
                            }
                          />
                          <Typography
                            className={classes.tradeExpansionCardHeading}
                          >
                            Trade Number - Ext. Ref.
                          </Typography>
                          <Typography className={classes.secondaryHeading}>
                            Remaining Balance
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                    </ExpansionPanel>
                  </div>
                  <div className={classes.tradeContentCardWrapper}>
                    <div className={classes.tradeContentCards}>
                      {trades.map((trade, idx) => {
                        const invoiceTradeLinks = this.getInvoiceTradeLinksForTrade(
                          trade
                        );
                        const prepaymentTradeLinks = this.getPrepaymentTradeLinksForTrade(
                          trade
                        );
                        const customEntryTradeLinks = this.getCustomEntryTradeLinksForTrade(
                          trade
                        );
                        const tradeRemainingBalance = this.getTradeRemainingBalance(
                          trade
                        );

                        return (
                          <ExpansionPanel
                            expanded={tradeExpansionPanelsState[trade.id]}
                            key={idx}
                            onChange={() =>
                              this.setState({
                                tradeExpansionPanelsState: {
                                  ...tradeExpansionPanelsState,
                                  [trade.id]: !tradeExpansionPanelsState[
                                    trade.id
                                  ],
                                },
                              })
                            }
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <div
                                className={
                                  classes.tradeExpansionCardSummaryLayout
                                }
                              >
                                <div
                                  className={
                                    classes.tradeExpansionCardCheckboxWrapper
                                  }
                                >
                                  <Checkbox
                                    checked={selectedTrade.id === trade.id}
                                    onChange={() =>
                                      this.handleSelectTrade(trade)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </div>
                                <Typography
                                  className={classes.tradeExpansionCardHeading}
                                >
                                  {`${trade.number}`}
                                </Typography>
                                <Typography
                                  className={classNames(
                                    classes.secondaryHeading,
                                    {
                                      [classes.error]:
                                        tradeRemainingBalance < 0,
                                    }
                                  )}
                                >
                                  {`${FormatNumber(
                                    tradeRemainingBalance,
                                    true,
                                    true,
                                    2,
                                    true
                                  )} / ${FormatNumber(
                                    trade.availableBalance,
                                    true,
                                    true,
                                    2,
                                    true
                                  )}`}
                                </Typography>
                              </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails
                              classes={{
                                root: classes.tradeExpansionPanelDetailsRoot,
                              }}
                            >
                              <div
                                className={
                                  classes.tradeExpansionPanelDetailsWrapper
                                }
                              >
                                <div
                                  className={classes.tradeExpansionPanelDetails}
                                >
                                  {this.renderInvoiceTradeLinks(
                                    invoiceTradeLinks
                                  )}
                                  {this.renderPrepaymentTradeLinks(
                                    prepaymentTradeLinks
                                  )}
                                  {this.renderCustomEntryTradeLinks(
                                    customEntryTradeLinks
                                  )}
                                  <div
                                    className={classes.tradeRemoveLabel}
                                    onClick={() =>
                                      this.handleRemoveTrade(trade)
                                    }
                                  >
                                    <b>x</b> Remove Trade
                                  </div>
                                </div>
                              </div>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            }
          })()}
        </CardContent>
      </Card>
    );
  };

  renderInvoiceTradeLinks = (invoiceTradeLinks) => {
    const { classes } = this.props;

    if (invoiceTradeLinks.length === 0) {
      return (
        <Paper className={classes.exposureTradeLinksTableRoot}>
          <div className={classes.tradeExpansionPanelItemHeadingWrapper}>
            <div className={classes.tradeExpansionPanelItemHeading}>
              No Invoice Links Made
            </div>
          </div>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.exposureTradeLinksPaper}>
          <div className={classes.tradeExpansionPanelItemHeadingWrapper}>
            <div className={classes.tradeExpansionPanelItemHeading}>
              Invoices
            </div>
          </div>
          <Paper className={classes.exposureTradeLinksTableRoot}>
            <Table className={classes.exposureTradeLinksTable}>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Remove Link</TableCell>
                  <TableCell align="right">Invoice Number</TableCell>
                  <TableCell align="left">Linked Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceTradeLinks.map((link, idx) => {
                  const zeroAmount = link.invoiceTradeLink.amount === 0;
                  return (
                    <TableRow key={idx}>
                      <TableCell align="right">
                        <IconButton
                          onClick={this.handleTradeInvoiceLinkRemove(
                            link.invoiceTradeLink.id
                          )}
                          style={{
                            padding: '0',
                            color: 'red',
                          }}
                        >
                          <DeselectIcon fontSize={'small'} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">{link.invoice.number}</TableCell>
                      <TableCell align="right">
                        <TextField
                          error={zeroAmount}
                          helperText={zeroAmount ? 'Cannot be Zero' : undefined}
                          onChange={this.handleInvoiceTradeLinkAmountAdjust(
                            link.invoiceTradeLink.id
                          )}
                          parseType={TextFieldParseTypes.float}
                          value={link.invoiceTradeLink.amount}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </Paper>
      );
    }
  };

  renderPrepaymentTradeLinks = (prepaymentTradeLinks) => {
    const { classes } = this.props;

    if (prepaymentTradeLinks.length === 0) {
      return (
        <Paper className={classes.exposureTradeLinksTableRoot}>
          <div className={classes.tradeExpansionPanelItemHeadingWrapper}>
            <div className={classes.tradeExpansionPanelItemHeading}>
              No Prepayment Links Made
            </div>
          </div>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.exposureTradeLinksPaper}>
          <div className={classes.tradeExpansionPanelItemHeadingWrapper}>
            <div className={classes.tradeExpansionPanelItemHeading}>
              Prepayments
            </div>
          </div>
          <Paper className={classes.exposureTradeLinksTableRoot}>
            <Table className={classes.exposureTradeLinksTable}>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Remove Link</TableCell>
                  <TableCell align="right">Invoice Number</TableCell>
                  <TableCell align="left">Linked Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prepaymentTradeLinks.map((link, idx) => {
                  const zeroAmount = link.prepaymentTradeLink.amount === 0;
                  return (
                    <TableRow key={idx}>
                      <TableCell align="right">
                        <IconButton
                          onClick={this.handleTradePrepaymentLinkRemove(
                            link.prepaymentTradeLink.id
                          )}
                          style={{
                            padding: '0',
                            color: 'red',
                          }}
                        >
                          <DeselectIcon fontSize={'small'} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        {link.prepayment.number}
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          error={zeroAmount}
                          helperText={zeroAmount ? 'Cannot be Zero' : undefined}
                          onChange={this.handlePrepaymentTradeLinkAmountAdjust(
                            link.prepaymentTradeLink.id
                          )}
                          parseType={TextFieldParseTypes.float}
                          value={link.prepaymentTradeLink.amount}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </Paper>
      );
    }
  };

  renderCustomEntryTradeLinks = (customEntryTradeLinks) => {
    const { classes } = this.props;
    if (customEntryTradeLinks.length === 0) {
      return (
        <Paper className={classes.exposureTradeLinksTableRoot}>
          <div className={classes.tradeExpansionPanelItemHeadingWrapper}>
            <div className={classes.tradeExpansionPanelItemHeading}>
              No Custom Entry Links Made
            </div>
          </div>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.exposureTradeLinksPaper}>
          <div className={classes.tradeExpansionPanelItemHeadingWrapper}>
            <div className={classes.tradeExpansionPanelItemHeading}>
              Custom Entries
            </div>
          </div>
          <Paper className={classes.exposureTradeLinksTableRoot}>
            <Table className={classes.exposureTradeLinksTable}>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Remove Link</TableCell>
                  <TableCell align="right">Counterparty</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="left">Linked Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customEntryTradeLinks.map((link, idx) => {
                  const zeroAmount = link.tradeLink.amount === 0;
                  return (
                    <TableRow key={idx}>
                      <TableCell align="right">
                        <IconButton
                          onClick={this.handleTradeCustomEntryLinkRemove(
                            link.tradeLink.id
                          )}
                          style={{
                            padding: '0',
                            color: 'red',
                          }}
                        >
                          <DeselectIcon fontSize={'small'} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        {this.getCounterpartyName(
                          link.customEntry.counterpartyId
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {link.customEntry.description}
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          error={zeroAmount}
                          helperText={zeroAmount ? 'Cannot be Zero' : undefined}
                          onChange={this.handleCustomEntryTradeLinkAmountAdjust(
                            link.tradeLink.id
                          )}
                          parseType={TextFieldParseTypes.float}
                          value={link.tradeLink.amount}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </Paper>
      );
    }
  };

  handleComplete = async () => {
    const { onUpdate } = this.props;
    // if there are changes, update
    if (this.determineIfChangesMade()) {
      await this.handleSaveChanges();
    }
    const { error } = this.state;
    if (error) {
      // if an error occurred during change saving do not continue
      return;
    }

    this.setState({ loading: true });
    try {
      const { settlementInstruction } = this.state;
      const { closeDialog } = this.props;
      // make a copy of the si in state
      let copyToComplete = newSi(settlementInstruction);
      // remove the ids in the links
      copyToComplete = {
        ...copyToComplete,
        invoiceTradeLinks: copyToComplete.invoiceTradeLinks.map((invTrdLnk) => {
          delete invTrdLnk.id;
          return invTrdLnk;
        }),
      };
      await SIHandler.complete(copyToComplete);
      this.setState({
        successMessage: 'Completed Successfully',
        onCloseNotification: () => {
          this.setState({ successMessage: undefined });
          onUpdate(settlementInstruction);
          closeDialog();
        },
      });
    } catch (e) {
      console.error('error completing si', e);
      this.setState({ error: e.toString() });
    }
    this.setState({ loading: false });
  };

  getInvoiceTradeLinksForTrade = (trade) => {
    const { settlementInstruction, invoices } = this.state;
    const invoiceTradeLinks = [];
    for (const invoiceTradeLink of settlementInstruction.invoiceTradeLinks) {
      if (invoiceTradeLink.tradeId === trade.id) {
        const invoice = invoices.find(
          (inv) => inv.id === invoiceTradeLink.invoiceId
        );
        invoiceTradeLinks.push({
          invoiceTradeLink,
          invoice: invoice ? invoice : {},
        });
      }
    }
    return invoiceTradeLinks;
  };

  getPrepaymentTradeLinksForTrade = (trade) => {
    const { settlementInstruction, prepayments } = this.state;
    const prepaymentTradeLinks = [];
    for (const prepaymentTradeLink of settlementInstruction.prepaymentTradeLinks) {
      if (prepaymentTradeLink.tradeId === trade.id) {
        const prepayment = prepayments.find(
          (pp) => pp.id === prepaymentTradeLink.prepaymentId
        );
        prepaymentTradeLinks.push({
          prepaymentTradeLink,
          prepayment: prepayment ? prepayment : {},
        });
      }
    }
    return prepaymentTradeLinks;
  };

  getCustomEntryTradeLinksForTrade = (trade) => {
    const { settlementInstruction } = this.state;

    const customEntryTradeLinks = [];
    for (const customEntry of settlementInstruction.customEntries) {
      for (const tradeLink of customEntry.tradeLinks) {
        if (tradeLink.tradeId === trade.id) {
          customEntryTradeLinks.push({
            tradeLink,
            customEntry,
          });
        }
      }
    }
    return customEntryTradeLinks;
  };

  getInvoiceAmountRequested = (invoice) => {
    const { settlementInstruction } = this.state;
    try {
      return settlementInstruction.invoiceAmountsRequested.find(
        (invAmountRequested) => invAmountRequested.invoiceId === invoice.id
      ).amount;
    } catch (e) {
      console.error('error getting invoice amount requested');
      return 0;
    }
  };

  getOutstandingInvoiceAmountRequested = (invoice) => {
    const { settlementInstruction } = this.state;
    let amountRequested = this.getInvoiceAmountRequested(invoice);
    if (!isArray(settlementInstruction.invoiceTradeLinks)) {
      return amountRequested;
    }
    for (const invoiceTradeLink of settlementInstruction.invoiceTradeLinks) {
      if (invoiceTradeLink.invoiceId === invoice.id) {
        amountRequested -= invoiceTradeLink.amount;
      }
    }
    return amountRequested;
  };

  getPrepaymentAmountRequested = (prepayment) => {
    const { settlementInstruction } = this.state;
    try {
      return settlementInstruction.prepaymentAmountsRequested.find(
        (ppAmountRequested) => ppAmountRequested.prepaymentId === prepayment.id
      ).amount;
    } catch (e) {
      console.error('error getting prepayment amount requested');
      return 0;
    }
  };

  getOutstandingPrepaymentAmountRequested = (prepayment) => {
    const { settlementInstruction } = this.state;
    let amountRequested = this.getPrepaymentAmountRequested(prepayment);
    if (!isArray(settlementInstruction.prepaymentTradeLinks)) {
      return amountRequested;
    }
    for (const prepaymentTradeLink of settlementInstruction.prepaymentTradeLinks) {
      if (prepaymentTradeLink.prepaymentId === prepayment.id) {
        amountRequested -= prepaymentTradeLink.amount;
      }
    }
    return amountRequested;
  };

  getOutstandingCustomEntryAmount = (customEntryId) => {
    const { settlementInstruction } = this.state;
    const customEntry = settlementInstruction.customEntries.find(
      (entry) => entry.id === customEntryId
    );
    if (!customEntry) {
      console.error(
        'unable to find custom entry identified by given customEntryId'
      );
      return 0;
    }
    let outstandingAmount = customEntry.amount;
    for (const tradeLink of customEntry.tradeLinks) {
      outstandingAmount -= tradeLink.amount;
    }
    return outstandingAmount;
  };

  getTradeRemainingBalance = (trade) => {
    let remainingBalance = trade.availableBalance;

    // update balance considering invoice trade links
    remainingBalance -= this.getInvoiceTradeLinksForTrade(trade).reduce(
      (total, link) => {
        switch (link.invoice.type) {
          case INVOICE_TYPE_PURCHASE_CREDIT_NOTE:
          case INVOICE_TYPE_SALES_CREDIT_NOTE:
            return total - link.invoiceTradeLink.amount
          case INVOICE_TYPE_PURCHASE_INVOICE:
          case INVOICE_TYPE_SALES_INVOICE:
            return total + link.invoiceTradeLink.amount
          default:
            return total
        }}, 0
    );

    // update balance considering prepayment trade links
    remainingBalance -= this.getPrepaymentTradeLinksForTrade(trade).reduce(
      (total, link) => total + link.prepaymentTradeLink.amount,
      0
    );

    // update considering custom entries
    remainingBalance -= this.getCustomEntryTradeLinksForTrade(trade).reduce(
      (total, link) => total + link.tradeLink.amount,
      0
    );

    return remainingBalance;
  };

  getCounterpartyName = (id) => {
    const { counterparties } = this.state;
    try {
      const counterparty = counterparties.find((b) => b.id === id);
      if (counterparty) {
        return counterparty.name;
      }
      return 'not found';
    } catch (e) {
      console.error('error finding counterparty name', e);
      return '-';
    }
  };

  handleLinking = () => {
    const {
      selectedCustomEntries,
      selectedInvoices,
      selectedPrepayments,
      selectedTrade,
    } = this.state;

    if (
      // if no trade OR
      !selectedTrade.id ||
      // No invoices
      (selectedInvoices.length === 0 &&
        // no prepayment entries
        selectedPrepayments.length === 0 &&
        // and no custom entries are selected
        selectedCustomEntries.length === 0)
    ) {
      // no links can be made
      return;
    }

    const { settlementInstruction } = this.state;

    // make invoice links
    for (const invoice of selectedInvoices) {
      if (!isArray(settlementInstruction.invoiceTradeLinks)) {
        invoice.invoiceTradeLinks = [];
      }
      // check to see if an invoice trade link between this invoice
      // and this trade already exists
      const existingLinkIdx = settlementInstruction.invoiceTradeLinks.findIndex(
        (invTrdLink) =>
          invTrdLink.tradeId === selectedTrade.id &&
          invTrdLink.invoiceId === invoice.id
      );
      if (existingLinkIdx < 0) {
        // does not yet exist
        let rate;
        if (selectedTrade.tradeType === 'Extension') {
          rate = selectedTrade.legs[1].allInRate;
        } else {
          rate = selectedTrade.legs[0].allInRate;
        }
        settlementInstruction.invoiceTradeLinks.push({
          id: uuidv4(),
          invoiceId: invoice.id,
          tradeId: selectedTrade.id,
          amount: this.getOutstandingInvoiceAmountRequested(invoice),
          rate: rate,
        });
      } else {
        // does exist, update only the amount
        settlementInstruction.invoiceTradeLinks[
          existingLinkIdx
        ].amount = this.getInvoiceAmountRequested(invoice);
      }
    }

    // make prepayment links
    for (const prepayment of selectedPrepayments) {
      if (!isArray(settlementInstruction.prepaymentTradeLinks)) {
        settlementInstruction.prepaymentTradeLinks = [];
      }
      // check to see if a prepayment trade link between this prepayment
      // and this trade already exists
      const existingLinkIdx = settlementInstruction.prepaymentTradeLinks.findIndex(
        (ppTrdLink) =>
          ppTrdLink.tradeId === selectedTrade.id &&
          ppTrdLink.prepaymentId === prepayment.id
      );
      if (existingLinkIdx < 0) {
        // does not yet exist
        let rate;
        if (selectedTrade.tradeType === 'Extension') {
          rate = selectedTrade.legs[1].allInRate;
        } else {
          rate = selectedTrade.legs[0].allInRate;
        }
        settlementInstruction.prepaymentTradeLinks.push({
          id: uuidv4(),
          prepaymentId: prepayment.id,
          tradeId: selectedTrade.id,
          amount: this.getOutstandingPrepaymentAmountRequested(prepayment),
          rate: rate,
        });
      } else {
        // does exist, update only the amount
        settlementInstruction.prepaymentTradeLinks[
          existingLinkIdx
        ].amount = this.getOutstandingPrepaymentAmountRequested(prepayment);
      }
    }

    // make custom entry links
    for (const customEntry of selectedCustomEntries) {
      if (!isArray(customEntry.tradeLinks)) {
        customEntry.tradeLinks = [];
      }
      // check to see if a trade link on this custom entry already
      // exists to this trade
      const existingLinkIdx = customEntry.tradeLinks.findIndex(
        (trdLink) => trdLink.tradeId === selectedTrade.id
      );
      if (existingLinkIdx < 0) {
        let rate;
        if (selectedTrade.tradeType === 'Extension') {
          rate = selectedTrade.legs[1].allInRate;
        } else {
          rate = selectedTrade.legs[0].allInRate;
        }
        // does not yet exist
        customEntry.tradeLinks.push({
          id: uuidv4(),
          tradeId: selectedTrade.id,
          amount: customEntry.amount,
          rate: rate,
        });
      } else {
        // does exist, update the amount of the link
        customEntry.tradeLinks[existingLinkIdx].amount = customEntry.amount;
      }
    }

    this.setState({
      settlementInstruction,
      selectedInvoices: [],
      selectedPrepayments: [],
      selectedCustomEntries: [],
      selectedTrade: {},
    });
  };

  handleTradeInvoiceLinkRemove = (linkId) => () => {
    const { settlementInstruction } = this.state;
    const invoiceTradeLinkIdx = settlementInstruction.invoiceTradeLinks.findIndex(
      (invTrdLnk) => invTrdLnk.id === linkId
    );
    if (invoiceTradeLinkIdx < 0) {
      console.error(
        'cannot find invoiceTradeLink with given linkId among state.settlementInstruction.invoiceTradeLinks'
      );
      return;
    }
    settlementInstruction.invoiceTradeLinks.splice(invoiceTradeLinkIdx, 1);
    this.setState({ settlementInstruction });
  };

  handleTradePrepaymentLinkRemove = (linkId) => () => {
    const { settlementInstruction } = this.state;
    const prepaymentTradeLinkIdx = settlementInstruction.prepaymentTradeLinks.findIndex(
      (ppTrdLnk) => ppTrdLnk.id === linkId
    );
    if (prepaymentTradeLinkIdx < 0) {
      console.error(
        'cannot find prepaymentTradeLinkIdx with given linkId among state.settlementInstruction.prepaymentTradeLinks'
      );
      return;
    }
    settlementInstruction.prepaymentTradeLinks.splice(
      prepaymentTradeLinkIdx,
      1
    );
    this.setState({ settlementInstruction });
  };

  handleTradeCustomEntryLinkRemove = (linkId) => () => {
    const { settlementInstruction } = this.state;
    for (const customEntry of settlementInstruction.customEntries) {
      const customEntryTradeLinkIdx = customEntry.tradeLinks.findIndex(
        (link) => link.id === linkId
      );
      if (customEntryTradeLinkIdx >= 0) {
        customEntry.tradeLinks.splice(customEntryTradeLinkIdx, 1);
        this.setState({ settlementInstruction });
        return;
      }
    }
    console.error(
      'cannot find custom entry trade link with given linkId among state.settlementInstruction.customEntries.tradeLinks'
    );
  };

  handleInvoiceTradeLinkAmountAdjust = (linkId) => (e) => {
    const { settlementInstruction } = this.state;
    const invoiceTradeLinkIdx = settlementInstruction.invoiceTradeLinks.findIndex(
      (invTrdLnk) => invTrdLnk.id === linkId
    );
    if (invoiceTradeLinkIdx < 0) {
      console.error(
        'cannot find invoiceTradeLink with given linkId among state.settlementInstruction.invoiceTradeLinks'
      );
      return;
    }
    settlementInstruction.invoiceTradeLinks[invoiceTradeLinkIdx].amount =
      e.target.value;
    this.setState({ settlementInstruction });
  };

  handlePrepaymentTradeLinkAmountAdjust = (linkId) => (e) => {
    const { settlementInstruction } = this.state;
    const prepaymentTradeLinkIdx = settlementInstruction.prepaymentTradeLinks.findIndex(
      (ppTrdLnk) => ppTrdLnk.id === linkId
    );
    if (prepaymentTradeLinkIdx < 0) {
      console.error(
        'cannot find prepaymentTradeLinkIdx with given linkId among state.settlementInstruction.prepaymentTradeLinks'
      );
      return;
    }
    settlementInstruction.prepaymentTradeLinks[prepaymentTradeLinkIdx].amount =
      e.target.value;
    this.setState({ settlementInstruction });
  };

  handleCustomEntryTradeLinkAmountAdjust = (linkId) => (e) => {
    const { settlementInstruction } = this.state;
    for (const customEntry of settlementInstruction.customEntries) {
      const customEntryTradeLinkIdx = customEntry.tradeLinks.findIndex(
        (link) => link.id === linkId
      );
      if (customEntryTradeLinkIdx >= 0) {
        customEntry.tradeLinks[customEntryTradeLinkIdx].amount = e.target.value;
        this.setState({ settlementInstruction });
        return;
      }
    }
    console.error(
      'cannot find custom entry trade link with given linkId among state.settlementInstruction.customEntries.tradeLinks'
    );
  };

  determineIfChangesMade = () => {
    const { settlementInstruction } = this.state;
    try {
      return !SettlementInstructionComparator.CompareAll(
        settlementInstruction,
        this.originalSI
      );
    } catch (e) {
      console.error('error determining if changes made', e);
      return false;
    }
  };

  handleSaveChanges = async () => {
    const { settlementInstruction } = this.state;
    const { onUpdate } = this.props;

    // make a copy of the si in state
    let copyToSave = newSi(settlementInstruction);
    // remove the ids in the links
    copyToSave = {
      ...copyToSave,
      invoiceTradeLinks: copyToSave.invoiceTradeLinks.map((invTrdLnk) => {
        delete invTrdLnk.id;
        return invTrdLnk;
      }),
      prepaymentTradeLinks: copyToSave.prepaymentTradeLinks.map((ppTrdLnk) => {
        delete ppTrdLnk.id;
        return ppTrdLnk;
      }),
      customEntries: copyToSave.customEntries.map((entry) => {
        delete entry.id;
        return {
          ...entry,
          tradeLinks: entry.tradeLinks.map((link) => {
            delete link.id;
            return link;
          }),
        };
      }),
    };
    copyToSave.status = StatusSubmitted;
    this.setState({ loading: true });
    try {
      const updateResponse = await SIHandler.linkUpdate({
        settlementInstruction: copyToSave,
      });
      // make a copy of the updated si and store as the new original
      this.originalSI = newSi(updateResponse.settlementInstruction);
      // index the invoice trade links for comparison purposes
      // later so that whether or not the links have been updated can
      // be done
      this.originalSI = {
        ...this.originalSI,
        invoiceTradeLinks: this.originalSI.invoiceTradeLinks.map(
          (invTrdLnk) => ({
            id: uuidv4(),
            ...invTrdLnk,
          })
        ),
      };
      // make a copy of the indexed si so that the original is not
      // changed. will be used for comparison to determine
      // if changes have been made
      this.setState({ settlementInstruction: newSi(this.originalSI) });
      onUpdate(updateResponse.settlementInstruction);
    } catch (e) {
      console.error('error updating si', e);
      this.setState({ error: e.message || e.toString() });
    }
    this.setState({ loading: false });
  };

  determineIfErrors = () => {
    const { settlementInstruction, prepayments } = this.state;

    // if any trades have a negative balance there are unresolved errors
    // for (let trade of trades) {
    //   if (this.getTradeRemainingBalance(trade) < 0) {
    //     return true
    //   }
    // }

    // if any invoices have a negative outstanding amount then there
    // are unresolved errors
    // for (let invoice of invoices) {
    //   if (this.getOutstandingInvoiceAmountRequested(invoice) < 0) {
    //     return true
    //   }
    // }

    // if there are any zero invoice trade links then then there are unresolved
    // errors
    for (const invoiceTradeLink of settlementInstruction.invoiceTradeLinks) {
      if (invoiceTradeLink.amount === 0) {
        return true;
      }
    }

    // if any prepayments have a negative outstanding amount then there
    // are unresolved errors
    for (const prepayment of prepayments) {
      if (this.getOutstandingPrepaymentAmountRequested(prepayment) < 0) {
        return true;
      }
    }

    // if there are any zero prepayment trade links then then there are unresolved
    // errors
    for (const prepaymentTradeLink of settlementInstruction.prepaymentTradeLinks) {
      if (prepaymentTradeLink.amount === 0) {
        return true;
      }
    }

    // if any custom entries have a negative outstanding amount OR a zero linked
    // amount then there are unresolved errors
    for (const customEntry of settlementInstruction.customEntries) {
      // if (this.getOutstandingCustomEntryAmount(customEntry.id) < 0) {
      //   return true
      // }
      for (const tradeLink of customEntry.tradeLinks) {
        if (tradeLink.amount === 0) {
          return true;
        }
      }
    }

    return false;
  };

  determineIfCanComplete = () => {
    const { settlementInstruction } = this.state;

    // if there are error you cannot complete
    if (this.determineIfErrors()) {
      return false;
    }

    // to complete the outstanding requested amount for every
    // invoice must be zero
    // TODO can overbook invoices for now
    // for (let invoice of invoices) {
    //   if (this.getOutstandingInvoiceAmountRequested(invoice) !== 0) {
    //     return false
    //   }
    // }

    // to complete the outstanding Custom Entry Amount for every custom entry
    // must be zero
    for (const customEntry of settlementInstruction.customEntries) {
      if (this.getOutstandingCustomEntryAmount(customEntry.id) !== 0) {
        return false;
      }
    }

    return true;
  };

  render() {
    const { classes, closeDialog, showDialog, currencyPairs } = this.props;
    const {
      showTradeSelectionDialog,
      trades,
      selectedInvoices,
      selectedCustomEntries,
      loading,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      onCloseNotification,
      selectedPrepayments,
    } = this.state;

    const errors = this.determineIfErrors();
    const canComplete = this.determineIfCanComplete();

    return (
      <Dialog
        PaperProps={{ classes: { root: classes.dialogRootOverride } }}
        className={classes.dialog}
        fullScreen
        modal={undefined}
        open={showDialog}
      >
        <DialogTitle className={classes.dialogTitleWrapper}>
          <div className={classes.dialogTitle}>
            <div className={classes.dialogTitleHeading}>
              <div className={classes.TBDLogoWrapper}>
                <img
                  alt="logo"
                  className={classes.TBDLogoImg}
                  src={miniLogo} />
              </div>
              <div className={classes.dialogTitleText}>
                Link Invoices To Trades
              </div>
            </div>
            <div className={classes.dialogTitleControlsWrapper}>
              {canComplete && (
                <div className={classes.iconBadge}>
                  <Fab onClick={this.handleComplete}>
                    <Tooltip title="Complete">
                      <CompleteIcon className={classes.buttonIcon} />
                    </Tooltip>
                  </Fab>
                </div>
              )}
              {errors && (
                <div className={classes.iconBadge}>
                  <Tooltip title="Resolve Errors">
                    <IconButton style={{ color: 'red' }}>
                      <ErrorsIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              {// if there are changes
                this.determineIfChangesMade() &&
              // and there are no errors
              !errors && (
                  <div className={classes.iconBadge}>
                    <Fab onClick={this.handleSaveChanges}>
                      <Tooltip title="Save Changes Made">
                        <SaveIcon className={classes.buttonIcon} />
                      </Tooltip>
                    </Fab>
                  </div>
                )}
              <div>
                <Badge
                  badgeContent={
                    selectedInvoices.length +
                    selectedPrepayments.length +
                    selectedCustomEntries.length
                  }
                  className={classes.iconBadge}
                  color={'secondary'}
                >
                  <Fab onClick={this.handleLinking}>
                    <Tooltip title="Link Invoice/s to Trade">
                      <LinkIcon className={classes.buttonIcon} />
                    </Tooltip>
                  </Fab>
                </Badge>
              </div>
              <div className={classes.dialogTitleCloseBtnWrapper}>
                <Fab
                  aria-label="Close"
                  className={classes.dialogTitleCloseButton}
                  color="primary"
                  onClick={closeDialog}
                >
                  <Tooltip
                    placement="top"
                    title="Close">
                    <CloseIcon className={classes.dialogTitleCloseIcon} />
                  </Tooltip>
                </Fab>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: classes.rootOverride }}>
          {this.renderContent()}
        </DialogContent>
        {showTradeSelectionDialog && (
          <TradeSelectionDialog
            closeDialog={() =>
              this.setState({ showTradeSelectionDialog: false })
            }
            compulsoryCriteria={this.defaultTradeCriteria}
            currencyPairs={currencyPairs}
            selectedTrades={trades}
            show={showTradeSelectionDialog}
            tradeSelect={this.handleAddTradeToLink}
          />
        )}
        <Dialog
          BackdropProps={{
            classes: { root: classes.progressSpinnerDialogBackdrop },
          }}
          PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
          className={classes.progressSpinnerDialog}
          open={loading}
        >
          <Spinner isLoading={loading} />
        </Dialog>
        <NotificationSweetAlert
          customClass={'SIInvoiceLinkerDialogAlert'}
          errorMessage={errorMessage}
          onClose={onCloseNotification}
          onConfirm={confirmationMethod}
          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </Dialog>
    );
  }
}

SIExposureTradeLinkerDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  currencyPairs: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  settlementInstruction: PropTypes.object.isRequired,
  showDialog: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};
SIExposureTradeLinkerDialog.defaultProps = {};

const StyledSIExposureTradeLinkerDialog = withStyles(styles, {
  withTheme: true,
})(SIExposureTradeLinkerDialog);

export default StyledSIExposureTradeLinkerDialog;

function getInvoiceOutstandingAmount(invoice) {
  let outstandingAmount = 0;
  try {
    outstandingAmount = invoice.amountDue - invoice.paidAmount;
    if (isArray(invoice.tradeLinks)) {
      outstandingAmount -= invoice.tradeLinks
        .map((link) => link.amount)
        .reduce((a, c) => a + c, 0);
    }
    if (isArray(invoice.accountLinks)) {
      outstandingAmount -= invoice.accountLinks
        .map((link) => link.amount)
        .reduce((a, c) => a + c, 0);
    }
    return outstandingAmount;
  } catch (e) {
    console.error('error getting outstanding invoice amount', e);
    return 0;
  }
}

function getPrepaymentOutstandingAmount(prepayment) {
  let outstandingAmount = 0;
  try {
    outstandingAmount = prepayment.amountDue - prepayment.paidAmount;
    if (isArray(prepayment.tradeLinks)) {
      outstandingAmount -= prepayment.tradeLinks
        .map((link) => link.amount)
        .reduce((a, c) => a + c, 0);
    }
    return outstandingAmount;
  } catch (e) {
    console.error('error getting outstanding prepayment amount', e);
    return 0;
  }
}
