import React, {useEffect, useState} from 'react';
import {useAuth0} from 'utils/react-auth0-spa'
import Authenticator from 'popcorn-js/authenticator/authenticator'
import LoadingPage from './views/Pages/LoadingPage/LoadingPage'
import PropTypes from 'prop-types'
import {getErrorMessage} from './utils/Utils'

interface Props {
    onLoginSuccess: Function;
}

const Authenticate: React.FC<Props> = (props: Props) => {
  const {onLoginSuccess} = props

  const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined)

  const {
    loginWithRedirect,
    isAuthenticated,
    loading,
    getTokenSilently,
    logout
  } = useAuth0();

  useEffect(() => {
    const doAuth = async (): Promise<void> => {
      if (!loading) {
        if (!isAuthenticated) {
          // eslint-disable-next-line
          loginWithRedirect({redirect_uri: `${window.location.origin}/app/dashboard`})
        } else {
          try {
            const t = await getTokenSilently()
            const authResponse = await Authenticator.login({token: t})
            onLoginSuccess(authResponse)
          } catch (e) {
            setErrorMessage('Failed to sign in: ' + getErrorMessage(e))
          }
        }
      }
    }
    doAuth().finally()
  }, [loading, isAuthenticated, getTokenSilently, loginWithRedirect, logout, onLoginSuccess])

  return (
    <LoadingPage
      errorMessage={errorMessage}
      onDismiss={(): Promise<void> => logout({returnTo: `${window.location.origin}/logout`})}
    />
  )
}

Authenticate.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
}

export default Authenticate;
