import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'

/**
 * CurrencyRecordkeeper is a convenience client for invoking the backend services
 */
export default class Recordkeeper {
  /**
   * @param {Account} account
   */
  static create(account) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Account-Recordkeeper.Create',
      request: {account},
    })
  }

  /**
   * @param {Account} account
   * @param {Identifier} id
   */
  static update(account, id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Account-Recordkeeper.Update',
      request: {
        account,
        identifier: id.marshall(),
      },
    })
  }

  /**
   * @param criteria: Filter criteria
   * @param query: Query options
   * @param {boolean} deleted
   */
  static find(criteria, query, deleted) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Account-Recordkeeper.Find',
      request: {
        criteria,
        query,
        deleted,
      },
    })
  }

  /**
   * @param {Identifier} id
   */
  static deleteForever(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Account-Recordkeeper.DeleteForever',
      request: {identifier: id.marshall()}
    })
  }

  /**
   * @param {Identifier} id
   */
  static retrieveHistory(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Account-Recordkeeper.RetrieveHistory',
      request: {identifier: id.marshall()}
    })
  }

  /**
   * @param {Identifier} id
   */
  static delete(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Account-Recordkeeper.Delete',
      request: {identifier: id.marshall()}
    })
  }

  /**
   * @param {Identifier} id
   */
  static restore(id) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Account-Recordkeeper.Restore',
      request: {identifier: id.marshall()}
    })
  }
}
