import React, {useState} from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  IconButton, Theme,
  Typography
} from '@material-ui/core'
import {MdClose as CloseIcon, MdImportExport as ExportIcon,} from 'react-icons/md'
import LightTooltip from 'components/LightTooltp/LightTooltip'
import SplitButton from 'views/Home/Company/Dashboard/MainCashFlowGraph/SplitButton'
import {RefreshIcon} from 'components/Icons'
import {ComponentLevelLoader} from 'components/Loader/Loader'
import ErrorIcon from 'components/Icons/ErrorIcon/ErrorIcon'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {usePerfectScrollbar} from 'hooks'
import {ExposureGraph} from 'views/Home/Company/Dashboard/ExposureGraph/ExposureGraph'
import ExposureTotals from 'views/Home/Company/Dashboard/ExposureGraph/ExposureTotals'
import {exportReport} from 'views/Home/Company/Dashboard/exportReport'
import {useService} from 'hooks/useService'
import Generator, {GenerateWeekViewExposureReportForCurrencyResponse} from 'popcorn-js/report/generatorTS'
import {useSelector} from 'react-redux'
import {
  viewExportMonthExposureReport,
  viewWeekViewExposureReportForCurrency
} from 'permissions/permissionTypes'
import {WeekViewExposureGraph} from './WeekViewExposureGraph';
import {BarChart, IconType, Legend} from 'recharts';
import {Currency} from 'popcorn-js/financial/currency/currencyType';

interface ExposureGraphLayoutProps {
  onClose: () => void,
  currency: Currency,
}

const useStyles = makeStyles((theme: Theme) => ({
  progressWrapper: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    height: '480px'
  },
  cardTitle: {
    width: 'max-content',
    display: 'grid',
    gridTemplateColumns: 'auto 130px',
    gridColumnGap: theme.spacing()
  },
  cardContentRoot: {
    width: 'calc(100%)',
    overflow: 'hidden',
  },
  cardRoot: {
    background: theme.palette.primary.main
  },
  cardHeading: {
    background: theme.palette.paper.default
  },
  paddingTop: {
    paddingTop: '3px'
  }
}))

export const useHasPermission = (permission: string): boolean => {
  return useSelector(state => {
    try {
      // eslint-disable-next-line
      // @ts-ignore
      const permissions = state.security.viewPermissions
      return permissions.includes(permission)
    } catch (e) {
      console.error('error ', e)
    }
  })
}

export const ExposureGraphLayout: React.FC<ExposureGraphLayoutProps> = (props: ExposureGraphLayoutProps) => {
  const request = {
    currencyId: props.currency.id
  }

  const theme = useTheme()

  const canExport = useHasPermission(viewExportMonthExposureReport)
  const canViewWeekViewExposureReport = useHasPermission(viewWeekViewExposureReportForCurrency)

  const paymentOptions = ['MONTH']
  if (canViewWeekViewExposureReport) {
    paymentOptions.push('WEEK')
  }

  const [aggregationOption, setAggregationOption] = useState(paymentOptions[0])

  const service = () => {
    switch (aggregationOption) {
      case 'WEEK':
        return Generator.GenerateWeekViewExposureReportForCurrency
      case 'MONTH':
      default:
        return Generator.GenerateMonthViewExposureReportForCurrency
    }
  }

  const [
    {
      response,
      loading,
      error,
    },
    setMonthViewReportRequest,
  ] = useService(request, service())

  const setScrollBarElementRef = usePerfectScrollbar(false, true)
  const classes = useStyles({})

  const currencyCode = props.currency ? props.currency.isoCode : ''

  const additionalActions = []

  const graphLegend = (width: number, report: GenerateWeekViewExposureReportForCurrencyResponse) => {
    const totals = (report || {}).totals || {}

    const salesDue = totals.salesDue?.totalAmountInCurrency
    const purchaseDue = totals.purchaseDue?.totalAmountInCurrency
    const purchaseOverDue = totals.purchaseOverDue?.totalAmountInCurrency
    const salesOverDue = totals.salesOverDue?.totalAmountInCurrency
    const purchaseHedge = totals.purchaseHedge?.totalAmountInCurrency
    const salesHedge = totals.salesHedge?.totalAmountInCurrency

    const showPurchaseTotals = (purchaseDue !== 0 || purchaseOverDue !== 0 || purchaseHedge !==0)
    const showSalesTotals = (salesDue !== 0 || salesOverDue !== 0 || salesHedge !==0)
    const showOverDue = (showPurchaseTotals || showSalesTotals)
    const showHedges = (showPurchaseTotals || showSalesTotals)

    const t: IconType = 'circle'
    const legendPayload = []
    if (showPurchaseTotals) {
      legendPayload.push({id: '3', value: 'Purchase Exposure', type: t, color: theme.palette.import.main})
    }
    if (showSalesTotals) {
      legendPayload.push({id: '2', value: 'Sales Exposure', type: t, color: theme.palette.export.main})
    }
    if (showOverDue) {
      legendPayload.push({id: '1', value: 'Exposure OverDue', type: t, color: theme.palette.data.graphB})
    }
    if (showHedges) {
      legendPayload.push({id: '4', value: 'Hedge Balances Maturing', type: t, color: theme.palette.data.graphC})
    }

    return (
      <div>
        <BarChart
          height={50}
          width={width}
        >
          <Legend
            margin={{top: 8, right: 8, bottom: 8, left: 8}}
            payload={legendPayload}
            width={width}
            wrapperStyle={{
              background: theme.palette.paper.default,
              borderRadius: '3px',
              padding: theme.spacing(),
              left: '290px',
            }}/>
        </BarChart>
      </div>
    )
  }

  if (canExport) {
    additionalActions.push(
      <LightTooltip
        key={1}
        title={'Export'}
      >
        <span>
          <IconButton
            disabled={loading || error !== ''}
            onClick={(): void => exportReport(response)}
          >
            <ExportIcon/>
          </IconButton>
        </span>
      </LightTooltip>
    )
  }

  additionalActions.push(
    <LightTooltip
      key={3}
      title={'Refresh'}
    >
      <span>
        <IconButton
          disabled={loading}
          onClick={(): void => setMonthViewReportRequest(request)}
        >
          <RefreshIcon/>
        </IconButton>
      </span>
    </LightTooltip>,
    <LightTooltip
      key={2}
      title={'Exit'}
    >
      <IconButton
        onClick={(): void => props.onClose()}
      >
        <CloseIcon/>
      </IconButton>
    </LightTooltip>,
  )

  return (
    <Card
      classes={{root: classes.cardRoot}}
    >
      <CardHeader
        action={
          <React.Fragment>
            {additionalActions}
          </React.Fragment>
        }
        classes={{root: classes.cardHeading}}
        title={
          <div
            className={classes.cardTitle}>
            <Typography
              className={classes.paddingTop}
              variant={'h5'}>{canViewWeekViewExposureReport ? `${currencyCode} Exposure per ` : `${currencyCode} Exposure per Month`}
            </Typography>
            <div>
              {canViewWeekViewExposureReport && <SplitButton
                disableDropdown={!canViewWeekViewExposureReport}
                disabled={!canViewWeekViewExposureReport}
                initialOption={aggregationOption}
                menuOptions={paymentOptions}
                onSelect={(e: string): void => setAggregationOption(e)}
              />}
            </div>
          </div>}
      />
      <CardContent>
        {((): JSX.Element => {
          switch (true) {
            case loading:
              return (
                <div className={classes.progressWrapper}>
                  <ComponentLevelLoader
                    color={'white'}
                    isLoading
                  />
                </div>
              )
            case error !== '':
              return (
                <div>
                  <ErrorIcon/>
                  <div>
                    {error}
                  </div>
                </div>
              )
            default:
              return (
                <div
                  className={classes.cardContentRoot}
                  // eslint-disable-next-line
                  // @ts-ignore
                  ref={setScrollBarElementRef}
                >
                  {(() => {
                    switch (aggregationOption) {
                      case 'WEEK':
                        return (
                          <WeekViewExposureGraph
                            currency={props.currency}
                            data={response}
                            width={1200}
                          />
                        )
                      case 'MONTH':
                      default:
                        return (
                          <ExposureGraph
                            currency={props.currency}
                            data={response}
                            width={1200}
                          />
                        )
                    }
                  })()}
                  <ExposureTotals
                    currency={props.currency}
                    report={response}
                    width={1200}
                  />
                  {graphLegend(670, response)}
                </div>
              )
          }
        })()}
      </CardContent>
    </Card>
  )
}

