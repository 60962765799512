import React from 'react'
import {withStyles} from '@material-ui/core'
import {MdErrorOutline as ErrorIcon,} from 'react-icons/md'
import {isString} from 'utils/Utils'

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'center',
    margin: '15px',
  },
  errorIcon: {
    fontSize: 80,
  },
})

let Error = props => {
  const {
    error,
    classes,
  } = props

  let errorMsg = 'Unknown Error'
  if (isString(error)) {
    errorMsg = error
  } else if (error !== undefined) {
    errorMsg = error.toString()
  }

  return <div className={classes.root}>
    <div><b>Error</b></div>
    <div>
      <ErrorIcon
        className={classes.errorIcon}
      />
    </div>
    <div>{errorMsg}</div>
  </div>
}

Error = withStyles(styles)(Error)

Error.propTypes = {}

Error.defaultProps = {}

export default Error
