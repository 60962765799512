import React, {useEffect, useState} from 'react'
import cx from 'classnames'
import {Redirect, Route, Switch} from 'react-router-dom'
import PropTypes from 'prop-types'
// @material-ui/core components
import {withStyles} from '@material-ui/core/styles'
// core components
import Footer from 'components/Footer/Footer.jsx'
import Sidebar from 'components/Sidebar/Sidebar.jsx'
import logo from 'assets/img/stellcap-logo-mini.svg'
import appRoutes from 'routes/app.jsx'
import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx'
import Toaster from '../components/Toaster/Toaster'
import PrimaryAppBar from 'components/AppBars/PrimaryAppBar/AppBar'
import moment from 'moment'
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import {usePerfectScrollbar} from 'hooks'

const switchRoutes = (
  <Switch>
    {appRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect
          from={prop.path}
          key={key}
          to={prop.pathTo}/>
      if (prop.collapse)
        return prop.views.map((prop, key) => {
          return (
            <Route
              component={prop.component}
              key={key}
              path={prop.path}/>
          )
        })
      return <Route
        component={prop.component}
        key={key}
        path={prop.path}/>
    })}
  </Switch>
)

function App(props) {
  const {
    history,
    classes,
    myParty,
    securityClaims,
    onLogout,
    viewPermissions,
    extendedContext,
    onContextSwitch,
    onRefreshAccessToken,
    onSetLoginMessage,
    ...rest
  } = props

  const [mobileOpen, setMobileOpen] = useState(false)
  const [miniActive, setMiniActive] = useState(true)
  const setScrollbarRef = usePerfectScrollbar()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const [logoutSeconds, setLogoutSeconds] = useState(100)
  const showTimout = logoutSeconds && logoutSeconds < 60

  useEffect(() => {
    const x = setInterval(() => {
      const expiry = moment.unix(securityClaims.exp)
      const remaining = moment.duration(expiry.diff(moment())).as('seconds')
      if (remaining < 61) {
        setLogoutSeconds(remaining)
      }
      if (Math.round(remaining) < 1) {
        onLogout()
      }
    }, 1000)

    return () => {
      clearInterval(x)
    }
  }, [securityClaims.exp, onLogout])

  useEffect(() => {
    if (history.location.pathname !== document.location.pathname) {
      if (mobileOpen) {
        setMobileOpen(false)
      }
    }
  }, [history, mobileOpen])

  const sidebarMinimize = () => {
    setMiniActive(!miniActive)
  }

  const mainPanel =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: miniActive,
    })

  return (
    <div className={classes.wrapper}>
      <Sidebar
        bgColor="black"
        handleDrawerToggle={handleDrawerToggle}
        logoText={securityClaims.context.name}
        logos={[
          {logo, name: 'Original logo'},
        ]}
        miniActive={miniActive}
        myParty={myParty}
        onLogout={onLogout}
        open={mobileOpen}
        routes={appRoutes}
        securityClaims={securityClaims}
        viewPermissions={viewPermissions}
        {...rest}
      />
      <div className={mainPanel}>
        <PrimaryAppBar
          extendedContext={extendedContext}
          history={history}
          onContextSwitch={onContextSwitch}
          onLogout={onLogout}
          onRefreshAccessToken={onRefreshAccessToken}
          onSetLoginMessage={onSetLoginMessage}
          routes={appRoutes}
          securityClaims={securityClaims}
          sidebarMinimize={sidebarMinimize}
          viewPermissions={viewPermissions}
          {...rest}
        />
        <div
          className={classes.content}
          ref={setScrollbarRef}
        >
          <div className={classes.container}>
            {switchRoutes}
          </div>
        </div>
        <Footer fluid/>
        {showTimout &&
        <NotificationSweetAlert
          infoMessage={`You will be logged out in ${Math.round(logoutSeconds)} seconds?`}
          onConfirm={() => {
            setLogoutSeconds()
            onRefreshAccessToken()
          }}
          options={{title: 'Session expiring', continueButtonText: 'Refresh session', hideCancel: true}}
        />
        }
      </div>
      <Toaster/>
    </div>
  )
}

App.propTypes = {
  classes: PropTypes.object,
  extendedContext: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  myParty: PropTypes.object,
  onContextSwitch: PropTypes.func,
  onLogout: PropTypes.func,
  onRefreshAccessToken: PropTypes.func,
  onSetLoginMessage: PropTypes.func,
  securityClaims: PropTypes.object,
  viewPermissions: PropTypes.array,
}

export default withStyles(appStyle)(App)
