// VIEW PERMISSIONS TYPES
export const viewHedgeStation = 'View.HedgeStation';
export const viewDealingStation = 'View.DealingStation';
export const viewPaymentStation = 'View.PaymentStation';
export const viewOperationsInvoiceStation = 'View.Operations/InvoiceStation';
export const viewTradeStation = 'View.TradeStation';
export const viewOpsStation = 'View.OperationStation';
export const viewReports = 'View.Reports';
export const viewSettlementInstruction =
  'View.SettlementInstructionWorkstation';
export const viewSettlementInstructionsV2 =
    'View.SettlementInstructionWorkstationV2';
export const viewSettlementInstructionManagement =
  'View.SettlementInstructionManagement';
export const viewOperationsOrderStation = 'View.Operations/OrderStation';
export const viewCounterpartyStation = 'View.CounterpartyStation';
export const viewOperations = 'View.Operations';
export const viewOperationsTradeStation = 'View.Operations/TradeStation';
export const viewOperationsTradesTable = 'View.Operations/TradesTable';
export const viewTradesTable = 'View.TradesTable';

// Legal Entities

export const viewLegalEntities = 'View.LegalEntities';
export const viewLegalEntitiesProcessingBank =
  'View.LegalEntities/ProcessingBank';
export const viewLegalEntitiesProcessingOrg =
  'View.LegalEntities/ProcessingOrg';
export const viewLegalEntitiesCompany = 'View.LegalEntities/Company';
export const viewLegalEntitiesIndividual = 'View.LegalEntities/Individual';
export const viewLegalEntitiesBroker = 'View.LegalEntities/Broker';

// Report Station

export const viewReportStationExposureProjection =
  'View.ReportStation/ExposureProjection';
export const viewReportStationCashProjection =
  'View.ReportStation/CashProjection';

export const viewConsolidatedReportsPage = 'View.ConsolidatedReportsPage'

// Configuration

export const viewConfiguration = 'View.Configuration';
export const viewConfigurationClientTiers = 'View.Configuration/ClientTiers';
export const viewConfigurationCountries = 'View.Configuration/Countries';
export const viewConfigurationCurrencies = 'View.Configuration/Currencies';
export const viewConfigurationCurrencyPairs =
  'View.Configuration/CurrencyPairs';
export const viewConfigurationRoles = 'View.Configuration/Roles';
export const viewConfigurationTradingDayExceptions =
  'View.Configuration/TradingDayExceptions';
export const viewConfigurationOverview = 'View.Configuration/Overview';
export const viewConfigurationUsers = 'View.Configuration/Users';
export const viewConfigurationAccounts = 'View.Configuration/Accounts';

// Home Dashboard
export const viewCompanyHomeDashboard = 'View.CompanyHomeDashboard';
export const viewViewCashFlowGraph = 'View.ViewCashFlowGraph';
export const viewExposureReport = 'View.ExposureReport';
export const viewExportMonthViewCashFlowReport =
  'View.ExportMonthViewCashFlowReport';
export const viewExportMonthExposureReport = 'View.ExportMonthExposureReport';
export const viewWeekViewCashFlowReport = 'View.WeekViewCashFlowReport';
export const viewWeekViewExposureReportForCurrency =
  'View.WeekViewExposureReportForCurrency';

// SETTINGS
// Party Settings
export const viewPartySettingsApiUsers = 'View.PartySettings/ApiUsers';

// Feature toggles
export const viewCaptureNewTradeTicket = 'View.CaptureNewTradeTicket';
export const viewDrawdownTradeTicket = 'View.DrawdownTradeTicket';
export const viewExtensionTradeTicket = 'View.ExtensionTradeTicket';
export const viewCaptureCancellationTradeTicket =
  'View.CaptureCancellationTradeTicket';