import React from 'react'
import PropTypes from 'prop-types'

import {ScaleLoader as Spinner} from 'react-spinners'
import {Dialog} from '@material-ui/core'

// ComponentLevelLoader is a loader than can be implemented nested within components. It does not render over the whole of the viewport.
// 100% width and default height of 200px
const ComponentLevelLoader = (props) => {
  if (props.isLoading) {
    return (
      <div
        style={{
          height: '200px',
          ...props.style,
          zIndex: '100',
          position: 'relative',
          display: 'grid',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <Spinner
            color={props.color}
            loading={props.isLoading}/>
        </div>
      </div>)
  }
  return null
}

ComponentLevelLoader.propTypes = {
  color: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
}

ComponentLevelLoader.defaultProps = {
  isLoading: false,
  color: '#FFF',
}

const Loader = (props) => {
  if (props.isLoading) {
    return (
      <div
        style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          /* bring your own prefixes */
          transform: 'translate(-50%, -50%)',
          zIndex: '100',
        }}>

        <div>
          <Spinner
            color={props.color}
            loading={props.isLoading}/>
        </div>
      </div>
    )
  }
  return null
}

Loader.propTypes = {
  color: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
}

Loader.defaultProps = {
  isLoading: false,
  color: '#FFF',
}

const LoaderInfo = (props) => {
  if (props.isLoading && props.loading) {
    return (
      <div
        style={{
          position: 'fixed',
          padding: '10px',
          left: '50%',
          top: '95%',
          transform: 'translate(-50%, -50%)',
          color: props.color,
          backgroundColor: props.backgroundColor,
        }}>
        <small style={{alignSelf: 'center'}}>&nbsp;&nbsp; {props.loading.join(
          '... ')}...
        </small>
      </div>
    )
  }
  return null
}

LoaderInfo.defaultProps = {
  backgroundColor: 'rgba(255, 255, 2555}, 0.6)',
  color: '#FFF',
}

LoaderInfo.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  loading: PropTypes.array,
}

// FullPageLoader renders a loader that overlays the whole of the viewport.
// The page will be inactive while loading
const FullPageLoader = (props) => {
  if (props.isLoading) {
    return (
      <div>
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              overflow: 'hidden',
            },
          }}
          open
        >
          <div align="center">
            <Spinner
              color={props.color}
              isLoading
              style={{position: 'relative'}}
            />
          </div>
        </Dialog>
      </div>)
  }
  return (<span/>)
}

FullPageLoader.defaultProps = {
  backgroundColor: 'rgba(255, 255, 2555}, 0.6)',
  color: '#000',
}

FullPageLoader.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  loading: PropTypes.array,
}

export {LoaderInfo, Loader, FullPageLoader, ComponentLevelLoader}
export default Loader
