import React from 'react'
import {connect} from 'react-redux'
import Accounts from './Accounts'

let AccountsContainer = props => {
  return <Accounts {...props}/>
}

function mapStateToProps(state) {
  return {
    partyCode: state.myParty.partyCode ? state.myParty.partyCode : '',
    currencies: state.currency.records,
  }
}

AccountsContainer = connect(
  mapStateToProps,
  {},
)(AccountsContainer)

export default AccountsContainer
