import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  withStyles, Input,
} from '@material-ui/core'
import {TEXT_CRITERION} from 'popcorn-js/search/criteria/types'
import {isFunction} from 'utils/Utils'

const styles = (theme) => ({

})

class Text extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    if (props.initValue === undefined) {
      this.state.value = {
        type: Text.CriteriaType,
        value: {
          field: props.fieldID,
          text: '',
        }
      }
    } else {
      this.state.value = props.initValue
    }
  }

  static CriteriaType = TEXT_CRITERION

  onChange = (newTextVal) => {
    let {
      value,
    } = this.state
    const {
      onChange
    } = this.props

    value = {
      ...value,
      value: {
        ...value.value,
        text: newTextVal
      }
    }

    if (isFunction(onChange)) {
      if ((newTextVal === '')) {
        onChange(undefined)
      } else {
        onChange(value)
      }
    }

    this.setState({
      value
    })
  }

  render () {
    const {
      filterConfig,
      showBorder,
    } = this.props
    const {
      value,
    } = this.state

    let addInputProps = {}
    if (filterConfig) {
      if (filterConfig.width) {
        addInputProps.style = {
          width: filterConfig.width,
        }
      }
    }

    if (showBorder) {
      if (addInputProps.style) {
        addInputProps = {
          ...addInputProps,
          style: {
            ...addInputProps.style,
            backgroundColor: 'white',
            border: '1px solid #bfbfbf',
            borderRadius: '2px',
          },
        }
      } else {
        addInputProps = {
          ...addInputProps,
          style: {
            backgroundColor: 'white',
            border: '1px solid #D6D6D6',
            borderRadius: '2px',
          },
        }
      }
    }

    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          justifyItems: 'center',
          gridTemplateRows: '1fr',
        }}>
        <div
          style={{
            alignSelf: 'center',
          }}
        >
          <Input
            {...addInputProps}
            disableUnderline
            onChange={e => this.onChange(e.target.value)}
            value={value.value.text}
          />
        </div>
      </div>
    )
  }
}

const styledText = withStyles(styles)(Text)

styledText.propTypes = {
  // Not required, will be used to set value on construction
  fieldID: PropTypes.string.isRequired,
  filterConfig: PropTypes.object,
  initValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  showBorder: PropTypes.bool,
  value: PropTypes.object.isRequired,
}

styledText.defaultProps = {
  showBorder: false,
}

export default Text
