import Table from './ReactTable/Table'
import BckndPgntdCustColTable from './BckndPgntdCustColTable/BckndPgntdCustColTable'
import PGTable from './PGTable/PGTable'

export default Table

export {
  Table,
  BckndPgntdCustColTable,
  PGTable,
}
