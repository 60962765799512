import React, { ReactElement, useState } from 'react'
import {
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  withTheme,
  Theme,
} from '@material-ui/core'
import ReportCard from 'components/ReportCard/ReportCard'
import NotificationSweetAlert
  from 'components/SweetAlert/NotificationSweetAlert'
import {CounterpartyRecordkeeper} from 'popcorn-js/counterparty'
import Table from 'components/Table/VirtualizedTable/VirtualizedTable'
import Filter from 'components/Filter/Filter'
import CounterpartyDetailDialog
  from 'components/Detail/counterparty/CounterpartyDetailDialog'
import {TEXT_CRITERION, Criterion} from 'popcorn-js/search/criteria/types'

// icons
import {
  ViewIcon,
  BackIcon,
  DeleteIcon,
  ViewDeleteIcon,
  RestoreIcon,
  FilterIcon,
  NewIcon,
} from 'components/Icons'
import Counterparty from 'popcorn-js/counterparty/Counterparty'
import { useTheme } from '@material-ui/styles'

const CounterpartyStation = (): ReactElement => {

  const theme = useTheme<Theme>()

  const [counterparties, setCounterparties] = useState<Counterparty[]>([])
  const [selected, setSelected] = useState<Counterparty|undefined>()
  const [criteria, setCriteria] = useState<Criterion[]>([])
  const [clearCacheToggle, setClearCacheToggle] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(1)
  const [sortBy, setSortBy] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<string>('ASC')
  const [errorMessage, setErrorMessage] = useState<string|undefined>()
  const [successMessage, setSuccessMessage] = useState<string|undefined>()
  const [viewDelete, setViewDelete] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [showCounterpartyDetail, setShowCounterpartyDetail] = useState<boolean>(false)
  const [creating, setCreating] = useState<boolean>(false)

  const loadMoreRows = async (props: {startIndex: number; stopIndex: number;}) => {
    const {startIndex, stopIndex} = props

    const sort = sortBy && sortBy !== ''

    const query = {
      offset: startIndex,
      limit: stopIndex - startIndex + 1,
      sortBy: sort ? [sortBy] : undefined,
      order: sort ? (sortDirection === 'DESC' ? ['desc'] : ['asc']) : undefined,
    }

    try {
      const counterpartyFindResponse = await CounterpartyRecordkeeper.find({
        criteria: criteria,
        query: query,
        Deleted: viewDelete,
      })
      const newCounterparies = counterparties.slice()

      for (let i = 0; i < counterpartyFindResponse.records.length; i++) {
        const index = i + startIndex
        newCounterparies[index] = counterpartyFindResponse.records[i]
      }

      setCounterparties(newCounterparies)
      setTotal(counterpartyFindResponse.total)
      return {startIndex, stopIndex}
    } catch (e) {
      setErrorMessage(e.message || e)
      throw e
    }
  }

  const handleSelection = (c: Counterparty) => {
    setSelected(c)
  }

  const clearCache = () => {
    setClearCacheToggle(!clearCacheToggle)
    setCounterparties([])
    setTotal(1)
  }

  const handleCriteriaChange = (criteriaFilter: {criteria: Criterion[]}) => {
    setCriteria(criteriaFilter.criteria || [])
    clearCache()
  }

  const handleSortChange = (sortBy: string, sortDirection: string) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
    clearCache()
  }

  const handleToggleFilter = () => {
    setShowFilter(!showFilter)
  }
  
  const handleShowDetail = () => {
    setShowCounterpartyDetail(true)
  }

  const handleHideDetail = () => {
    setShowCounterpartyDetail(false)
    setCreating(false)
  }

  const handleDelete = async () => {
    if (!selected) {
      return
    }
    try {
      const deleteResponse = await CounterpartyRecordkeeper.delete({identifier: {id: selected.id}})
      const newCounterparties = counterparties.slice()
      const indexToRemove = counterparties.findIndex(
        (c: Counterparty) => c.id === deleteResponse.counterparty.id,
      )
      if (indexToRemove >= 0) {
        newCounterparties.splice(indexToRemove, 1)
      }
      setSuccessMessage('Counterparty Deleted')
      setCounterparties(newCounterparties)
      setTotal(total-1)
      setSelected(undefined)
    } catch (e) {
      setErrorMessage(e.message)
    }
  }

  const handleRestore = async () => {
    if (!selected) {
      return
    }
    try {
      const restoreResponse = await CounterpartyRecordkeeper.restore({
        identifier: {id: selected.id},
      })
      const newCounterparties = counterparties.slice()
      const indexToRemove = counterparties.findIndex(
        (b: Counterparty) => b.id === restoreResponse.counterparty.id,
      )
      if (indexToRemove >= 0) {
        newCounterparties.splice(indexToRemove, 1)
      }
      setSuccessMessage('Counterparty Restored')
      setCounterparties(newCounterparties)
      setTotal(total-1)
      setSelected(undefined)
    } catch (e) {
      setErrorMessage(e.message)
    }
  }

  const handleViewDeleted = () => {
    setViewDelete(true)
    clearCache()
  }

  const handleReturn = () => {
    setViewDelete(false)
    clearCache()
  }

  const buildActions = (): ReactElement[] => {
    const blotterActions = []

    if (selected) {
      blotterActions.push(
        <Tooltip
          enterDelay={400}
          key={'detail'}
          title={'View'}>
          <IconButton onClick={handleShowDetail}>
            <ViewIcon/>
          </IconButton>
        </Tooltip>,
      )
      if (viewDelete) {
        blotterActions.push(
          <Tooltip
            enterDelay={400}
            key={'restore'}
            title={'Restore'}>
            <IconButton onClick={handleRestore}>
              <RestoreIcon/>
            </IconButton>
          </Tooltip>,
        )
        blotterActions.push(
          <Tooltip
            enterDelay={400}
            key={'delete'}
            title={'Delete'}>
            <IconButton onClick={handleDelete}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>,
        )
      } else {
        blotterActions.push(
          <Tooltip
            enterDelay={400}
            key={'delete'}
            title={'Delete'}>
            <IconButton onClick={handleDelete}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>,
        )
      }
      blotterActions.push(
        <span
          key={'div'}
          style={{
            borderLeft: `2px solid ${theme.palette.divider}`,
          }}/>,
      )
    }

    blotterActions.push(
      <Tooltip
        enterDelay={400}
        key={'filter'}
        title={'Toggle Filter'}>
        <IconButton onClick={handleToggleFilter}>
          <FilterIcon/>
        </IconButton>
      </Tooltip>,
    )
    if (viewDelete) {
      blotterActions.push(
        <Tooltip
          enterDelay={400}
          key={'return'}
          title={'Back'}>
          <IconButton onClick={handleReturn}>
            <BackIcon/>
          </IconButton>
        </Tooltip>
        ,
      )
    } else {
      blotterActions.push(
        <Tooltip
          enterDelay={400}
          key={'viewDelete'}
          title={'Show Delete'}>
          <IconButton onClick={handleViewDeleted}>
            <ViewDeleteIcon/>
          </IconButton>
        </Tooltip>,
      )
      blotterActions.push(
        <Tooltip
          enterDelay={400}
          key={'add'}
          title={'Create New'}>
          <IconButton onClick={handleNew}>
            <NewIcon/>
          </IconButton>
        </Tooltip>,
      )
    }
    // add icons

    return blotterActions
  }

  const handleNew = () => {
    setCreating(true)
    setSelected(undefined)
    handleShowDetail()
  }

  const handleHideAlert = () => {
    setErrorMessage(undefined)
    setSuccessMessage(undefined)
  }

  const handleDetailDialogSaveSuccess = (counterparty: Counterparty) => {
    if (creating) {
      clearCache()
      setCreating(false)
    } else {
      const newCounterparties = counterparties.slice()

      const indexToReplace = counterparties.findIndex(
        (c: Counterparty) => c.id === counterparty.id)

      if (indexToReplace >= 0) {
        newCounterparties[indexToReplace] = counterparty
      }

      setCounterparties(newCounterparties)
    }

    handleSelection(counterparty)
  }

  const renderDialogs = () => {
    return (
      <React.Fragment>
        <NotificationSweetAlert
          errorMessage={errorMessage}
          onClose={handleHideAlert}
          successMessage={successMessage}
        />
        {showCounterpartyDetail &&
        <CounterpartyDetailDialog
          counterparty={selected}
          isNew={creating}
          onClose={handleHideDetail}
          onSaveSuccess={handleDetailDialogSaveSuccess}
          readOnly={viewDelete}
          show={showCounterpartyDetail}
        />}
      </React.Fragment>
    )
  }

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}>
          <ReportCard>
            <CardHeader
              action={buildActions()}
              title={`Counterparties${viewDelete ? ' - Deleted' : ''}`}
            />
            <div
              style={{
                height: 'calc(100vh - 190px)',
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
              }}
            >
              <div
                style={{
                  padding: '0 40px',
                }}
              >
                <Filter
                  filters={[
                    {
                      label: 'Name',
                      field: 'name',
                      filterType: 'text',
                      criterionType: TEXT_CRITERION,
                    },
                    {
                      label: 'External Reference',
                      field: 'externalReference',
                      filterType: 'text',
                      criterionType: TEXT_CRITERION,
                    },
                  ]}
                  onChange={handleCriteriaChange}
                  show={showFilter}/>
              </div>
              <div>
                <Table
                  clearCacheToggle={clearCacheToggle}
                  columns={[
                    {
                      width: 140,
                      flexGrow: 1.0,
                      label: 'Name',
                      dataKey: 'name',
                    },
                    {
                      width: 220,
                      flexGrow: 1.0,
                      label: 'External Reference',
                      dataKey: 'externalReference',
                    },
                  ]}
                  loadMoreRows={loadMoreRows}
                  minimumBatchSize={50}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onRowClick={(row: any) => handleSelection(row.rowData)}
                  rowCount={total}
                  rowGetter={(props: {index: number}) => {
                    const {index} = props
                    let counterparty = {}
                    if (counterparties[index]) {
                      counterparty = counterparties[index]
                    }
                    return counterparty
                  }}
                  selected={selected ? [selected] : []}
                  sort={handleSortChange}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  threshold={30}
                />
              </div>
            </div>
          </ReportCard>
        </Grid>
      </Grid>
      {renderDialogs()}
    </div>
  )
}

export default withTheme(CounterpartyStation)