import {isObject} from 'utils/Utils'
import {ALL_AUDIT_ENTRY_ACTIONS} from 'constants/auditEntry'

/**
 * Resembles an audit entry
 */
class Entry {
  /**
   *
   * @type {string}
   */
  userId = ''

  /**
   *
   * @type {string}
   */
  username = ''

  /**
   *
   * @type {string}
   */
  action = ''

  /**
   *
   * @type {number}
   */
  time = 0

  /**
   *
   * @type {number}
   */
  version = 0

  /**
   * Creates a audit.Entry
   * @param auditEntry
   */
  constructor(auditEntry) {
    if (
      (auditEntry !== undefined) &&
      isObject(auditEntry)
    ) {
      try {
        this.userId = auditEntry.userId
        this.username = auditEntry.username
        if (ALL_AUDIT_ENTRY_ACTIONS.includes(auditEntry.action)) {
          this.action = auditEntry.action
        }
        this.time = auditEntry.time
        this.version = auditEntry.version
      } catch (e) {
        console.error(`error constructing audit entry: ${e}`)
      }
    }
  }
}

export default Entry
