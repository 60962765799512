import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import UserForm from './UserForm';
import {bindActionCreators} from 'redux';

let UsersContainer = (props) => {
  return <UserForm
    partyCode={props.partyCode}
  />
}

function mapStateToProps(state) {
  return {
    partyCode: state.myParty.partyCode ? state.myParty.partyCode : '',
    partyRoles: state.myParty.roles.records,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

UsersContainer.propTypes = {
  partyCode: PropTypes.string.isRequired,
  partyRoles: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer)
