/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, ChangeEvent, ReactElement, MouseEvent, FunctionComponent, ReactNode, Fragment } from 'react'
import { InputBase, TextField, withStyles, Select, InputLabel, Button, Radio, FormControlLabel, Checkbox, 
  IconButton, TextFieldProps, InputBaseComponentProps, SelectProps, RadioProps, CheckboxProps, FormControlLabelProps, 
  InputLabelProps, ButtonProps, Grid } from '@material-ui/core'
import {KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDatePickerProps} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Cancel } from '@material-ui/icons';
import { AlertProps } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export const darkColor = '#1E2036'
export const lightColor = '#FFFFFFDE'
export const actionColor = '#45B2F7'

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string|undefined;
}
export const NumberFormatCustom = (props: NumberFormatCustomProps): ReactElement => {
  const { inputRef, onChange, value, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowEmptyFormatting={false}
      allowLeadingZeros={false}
      defaultValue={''}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (isNaN(Number(values.value))) {
          onChange({
            target: {
              name: props.name,
              value: '',
            },
          });
        } else {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }
      }}
      thousandSeparator
      value={value}
    />
  );
}
export const NumberFormatRate = (props: NumberFormatCustomProps): ReactElement => {
  const { inputRef, onChange, value, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowEmptyFormatting={false}
      allowLeadingZeros={false}
      allowNegative
      defaultValue={''}
      format={'#.####'}
      getInputRef={inputRef}
      mask={'-'}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator
      value={value}
    />
  );
}

export const DarkTextFieldBase = withStyles({
  root: {
    color: darkColor,
    borderBottomColor: darkColor,
    '& .MuiInput-underline': {
      borderBottomColor: darkColor,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: darkColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: darkColor,
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: darkColor,
    },
    '& .Mui-disabled': {
      color: darkColor,
      opacity: 0.9,
    },
  },
})((TextField));

export const DarkTextField = (props: TextFieldProps): ReactElement => {
  const {disabled, onChange, label, value, InputLabelProps, InputProps, ...rest} = props
    
  const [focused, setFocused] = useState<boolean>(false)
  const [shrunk, setShrunk] = useState<boolean>(false)

  useEffect(() => {
    setShrunk(!!value || focused)
  }, [value, focused])
    
  return <DarkTextFieldBase
    InputLabelProps={{
      ...InputLabelProps,
      style: {
        color: darkColor,
        marginLeft: shrunk ? '0px' : '15px',
      },
      shrink: shrunk
    }}
    InputProps={{
      ...InputProps,
      endAdornment: !disabled ?
        <IconButton
          onClick={(event: MouseEvent<HTMLElement>) => {
            const newEvent = (event as unknown) as ChangeEvent<HTMLInputElement>
            if (onChange) { 
              onChange({...newEvent, target: {...newEvent.target, value: ''}})
            }
          }}
          size={'small'}>
          <Cancel style={{color: darkColor, opacity: 0.2}} />
        </IconButton>
        :
        ''
    }}
    disabled={disabled}
    inputProps={{
      style: {
        color: darkColor,
      },
      onFocus: () => setFocused(true),
      onBlur: () => setFocused(false)
    }}
    label={label}
    onChange={onChange}
    style={{
      color: darkColor,
    }}
    value={value}
    {...rest}
  />
}

export const DarkNumberField = (props: TextFieldProps & NumberFormatProps): ReactElement => {
  const {prefix, label, ...rest} = props

  return <DarkTextField
    InputProps={{
      inputComponent: (NumberFormatCustom as unknown) as FunctionComponent<InputBaseComponentProps>,
      startAdornment: prefix ? <span
        style={{
          color: darkColor,
          fontSize: '16px',
          fontFamily: 'Roboto',
          marginRight: '2px',
        }}>{prefix}</span> : '',
    }}
    label={label}
    {...rest}
  />
}
  
const DarkBootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
    borderBottom: `1px solid ${darkColor}`,
    color: '#1E2036',
  },
  focused: {
    borderBottom: `2px solid ${darkColor}`,
  },
}))(InputBase);
  
export const DarkSelect = (props: SelectProps): ReactElement =>
  <Select 
    input={<DarkBootstrapInput />}
    inputProps={{
      style: {
        color: darkColor,
      }
    }}
    style={{
      color: darkColor,
      borderBottomColor: darkColor,
    }}
    {...props}
  />

export const LightTextFieldBase = withStyles({
  root: {
    color: lightColor,
    borderBottomColor: lightColor,
    '& .MuiInput-underline': {
      borderBottomColor: lightColor,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: lightColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: lightColor,
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: lightColor,
    },
    '& .Mui-disabled': {
      color: lightColor,
      opacity: 0.6,
    },
  },
})((TextField));

export const LightTextField = (props: TextFieldProps): ReactElement =>  {
  const {disabled, onChange, label, value, InputLabelProps, InputProps, ...rest} = props

  const [focused, setFocused] = useState<boolean>(false)

  return <LightTextFieldBase
    InputLabelProps={{
      ...InputLabelProps,
      style: {
        color: lightColor,
      },
      shrink: !!value || focused
    }}
    InputProps={{
      ...InputProps,
      endAdornment: !disabled ?
        <IconButton
          onClick={(event: MouseEvent<HTMLElement>) => {
            const newEvent = (event as unknown) as ChangeEvent<HTMLInputElement>
            if (onChange) { 
              onChange({...newEvent, target: {...newEvent.target, value: ''}})
            }
          }}          
          size={'small'}
        >
          <Cancel style={{color: lightColor, opacity: 0.2}} />
        </IconButton>
        :
        ''
    }}
    disabled={disabled}
    inputProps={{
      style: {
        color: lightColor,
      },
      onFocus: () => setFocused(true),
      onBlur: () => setFocused(false)
    }}
    label={label}
    margin={'none'}
    onChange={onChange}
    style={{
      color: lightColor,
    }}
    value={value}
    {...rest}
  />
}

export const LightNumberField = (props: TextFieldProps & NumberFormatProps): ReactElement => { 
  const {prefix, ...rest} = props

  return <LightTextField
    InputProps={{
      inputComponent: (NumberFormatCustom as unknown) as FunctionComponent<InputBaseComponentProps>,
      startAdornment: prefix ? <span
        style={{
          color: lightColor,
          fontSize: '16px',
          fontFamily: 'Roboto',
          marginRight: '2px',
        }}>{prefix}</span> : ''
    }}
    {...rest}
  />
}

export const LightRateField = (props: TextFieldProps & NumberFormatProps): ReactElement => { 
  const {prefix, ...rest} = props

  return <LightTextField
    InputProps={{
      inputComponent: (NumberFormatRate as unknown) as FunctionComponent<InputBaseComponentProps>,
      startAdornment: prefix ? <span
        style={{
          color: lightColor,
          fontSize: '16px',
          fontFamily: 'Roboto',
          marginRight: '2px',
        }}>{prefix}</span> : ''
    }}
    {...rest}
  />
}
export const LightDatePickerBase = withStyles({
  root: {
    height: '58px',
    '& .Mui-disabled': {
      color: lightColor,
      opacity: 0.75,
    }
  },
  input: {
    borderBlockColor: lightColor,
  }
})(KeyboardDatePicker)

export const LightDatePicker = (props: KeyboardDatePickerProps): ReactElement => 
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <LightDatePickerBase
      InputLabelProps={{
        style: {
          color: lightColor,
        }
      }}
      KeyboardButtonProps={{
        'aria-label': 'change date',
        style: {
          color: lightColor
        }
      }}
      autoOk
      disableToolbar
      format={'MM/dd/yyyy'}
      invalidDateMessage={'invalid date'}
      margin={'none'}
      style={{
        color: lightColor,
        borderBottomColor: lightColor,
      }}
      variant={'inline'}
      {...props}
    />
  </MuiPickersUtilsProvider>

export const LightBootstrapInput = withStyles((theme) => ({
  root: {
    color: lightColor,
    'label + &': {
      marginTop: theme.spacing(2),
    },
    borderBottom: `1px solid ${lightColor}`,
    '& .Mui-disabled': {
      color: lightColor,
      opacity: 0.75,
    },
  },
  focused: {
    borderBottom: `2px solid ${lightColor}`,
  },
}))(InputBase);

const SelectIcon = (props: unknown) => 
  <ExpandMoreIcon
    {...props}
    style={{color: lightColor}}
  />

export const LightSelect = (props: SelectProps): ReactElement =>
  <Select 
    IconComponent={props.disabled ? Fragment : SelectIcon}
    input={<LightBootstrapInput />}
    inputProps={{
      style: {
        color: lightColor,
      }
    }}
    style={{
      color: lightColor,
      borderBottomColor: lightColor,
    }}
    {...props}
  />
  
export const LightInputLabel = (props: InputLabelProps): ReactElement => {
  const {children, ...rest} = props
  return <InputLabel
    style={{color: lightColor}}
    {...rest}>{children}</InputLabel>
}

const ActionButtonBase = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    outline: 'none',
    color: darkColor,
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    backgroundColor: actionColor,
    '&:hover': {
      backgroundColor: actionColor,
      opacity: 0.7,
    },
    '&:active': {
      backgroundColor: actionColor,
      opacity: 0.85,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

export const ActionButton = (props: ButtonProps): ReactElement => {
  const{children, ...rest} = props
  return <ActionButtonBase
    disableElevation 
    variant="contained"
    {...rest}
  >
    {children}
  </ActionButtonBase>
}

const SecondaryActionButtonBase = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    outline: 'none',
    color: lightColor,
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    background: 'none',
    border: `1px solid ${lightColor}`,
    borderRadius: '4px',
    '&:hover': {
      color: darkColor,
      backgroundColor: '#FFFFFF',
      opacity: 0.7,
    },
    '&:active': {
      color: darkColor,
      backgroundColor: 'FFFFFF',
      opacity: 0.85,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    '&:disabled': {
      border: 'none',
    },
  },
})(Button);

export const SecondaryActionButton = (props: ButtonProps): ReactElement => {
  const{children, ...rest} = props
  return <SecondaryActionButtonBase
    disableElevation 
    variant="contained"
    {...rest}
  >
    {children}
  </SecondaryActionButtonBase>
}

const RadioBase = withStyles({
  root: {
    color: lightColor,
    '&$checked': {
      color: actionColor,
    },
  },
  checked: {},
})(Radio);

const CheckboxBase = withStyles({
  root: {
    color: lightColor,
    '&$checked': {
      color: actionColor,
    },
  },
  checked: {},
})(Checkbox);

export const LightRadio = (props: RadioProps): ReactElement => <RadioBase {...props} />

export const LightCheckbox = (props: CheckboxProps): ReactElement => <CheckboxBase {...props} />

export const LightFormControlLabel = (props: FormControlLabelProps): ReactElement => <FormControlLabel
  style={{color: lightColor}}
  {...props} />

  interface FormComponentProps {
    children: ReactElement;
  }
export const FormComponent = (props: FormComponentProps): ReactElement => 
  <Grid
    item
    style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}
    xs={6}
  >
    <div style={{width: '160px', marginTop: '16px'}}>
      {props.children}
    </div>
  </Grid>

export function Alert(props: AlertProps): ReactElement {
  return <MuiAlert
    elevation={6}
    variant="filled"
    {...props} />;
}

interface CommonProps {
  children: ReactNode;
}
export const SubTitle = (props: CommonProps): ReactElement => {
  return (
    <div
      style={{
        width: '100%', 
        height: '40px', 
        paddingLeft: '25px', 
        backgroundColor: '#2F305D', 
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {props.children}
    </div>
  )
}

export const HeaderText = (props: CommonProps): ReactElement => 
  <span
    style={{
      fontWeight: 'bold', 
      fontSize: '14px',
      color: lightColor,
      width: 'auto',
    }}
  >
    {props.children}
  </span>
  
export const CellText = (props: CommonProps): ReactElement => 
  <span
    style={{
      fontSize: '14px',
      fontFamily: 'Roboto',
      color: lightColor,
    }}
  >
    {props.children}
  </span>

export const SplitLabel = (props: CommonProps): ReactElement => {
  return (
    <span style={{fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'bold', color: '#FFFFFF'}}>{props.children}</span>
  )
}

interface SplitSubTitleProps {
  leftComponent: ReactElement;
  rightComponent: ReactElement;
  variant: 'light'|'dark';
}
export const SplitSubTitle = (props: SplitSubTitleProps): ReactElement => {
  const {leftComponent, rightComponent, variant} = props

  return (
    <div
      style={{
        width: '100%', 
        height: '40px', 
        backgroundColor: variant === 'light' ? '#414272' : '#2F305D', 
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '26%', 
          height: '100%', 
          float: 'left', 
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '25px'
        }}
      />
      <div
        style={{
          width: '37%', 
          height: '100%', 
          float: 'right', 
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '25px'
        }}
      >
        {leftComponent}
      </div>
      <div
        style={{
          width: '37%', 
          height: '100%', 
          float: 'right', 
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '25px'
        }}
      >
        {rightComponent}
      </div>
    </div>
  )
}

interface SplitRowProps {
  leftComponent: ReactElement;
  middleComponent: ReactElement;
  rightComponent: ReactElement;
}
export const SplitRow = (props: SplitRowProps): ReactElement => {
  const {leftComponent, middleComponent, rightComponent} = props

  return (
    <div
      style={{
        width: '100%', height: '40px', display: 'flex', borderBottom: '1px solid #4C497D',
      }}
    >
      <div
        style={{
          width: '26%', 
          height: '100%', 
          float: 'left', 
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '25px'
        }}
      >
        {leftComponent}
      </div>
      <div
        style={{
          width: '37%', 
          height: '100%', 
          float: 'right', 
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '25px'
        }}
      >
        {middleComponent}
      </div>
      <div
        style={{
          width: '37%', 
          height: '100%', 
          float: 'right', 
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '25px'
        }}
      >
        {rightComponent}
      </div>
    </div>
  )
}
