import {useLayoutEffect, useState} from 'react'
import PerfectScrollbar from 'perfect-scrollbar'

/**
 * usePerfectScrollbar() is used to add a perfect scroll bar to a component where
 * one is required.
 * Example Use:
 *
 *  function MyComponent(props) {
 *    const setScrollBarElementRef = usePerfectScrollbar()
 *    return (
 *      <div ref={setScrollBarElementRef}>
 *        ...
 *      </div>
 *    )
 *  }
 *
 * @param {bool} [suppressScrollX]
 * @param {bool} [suppressScrollY]
 * @returns {React.Dispatch<React.SetStateAction<Element>>}
 */
export default function usePerfectScrollbar(suppressScrollX = true, suppressScrollY = false) {
  const [scrollBarElementRef, setScrollBarElementRef] = useState(null)
  const [, setPerfectScrollbarInst] = useState(null)

  useLayoutEffect(() => {
    if (scrollBarElementRef) {
      try {
        setPerfectScrollbarInst(
          new PerfectScrollbar(
            scrollBarElementRef,
            {
              suppressScrollX,
              suppressScrollY,
            },
          ),
        )
      } catch (e) {
        console.error('error setting perfect scrollbar', e)
      }
    }
  }, [scrollBarElementRef, suppressScrollX, suppressScrollY])

  return setScrollBarElementRef
}
