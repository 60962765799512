import React from 'react'
import {connect} from 'react-redux'
import SettlementInstruction from 'components/Detail/SettlementInstruction/SettlementInstructionDetail'

const SettlementInstructionDetailContainer = (props) => {
  return <SettlementInstruction {...props}/>
}

function mapStateToProps(state) {
  return {
    currencies: state.currency.records,
    currencyPairs: state.currencyPair.records,
    assignedCurrencyPairIds: state.myParty.currencyPairIds,
    localCurrencyId: state.myParty.localCurrencyId,
  }
}

const ConnectedSettlementInstructionDetailContainer = connect(mapStateToProps)(SettlementInstructionDetailContainer)

export default ConnectedSettlementInstructionDetailContainer
