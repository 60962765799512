import React from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {},
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: '100%',
    maxHeight: props => 56 * props.maxRows || 4,
    minHeight: 56 * 2,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
}))

const SelectList = props => {
  const classes = useStyles(props)
  const {selected, disabled} = props
  const title = props.title || 'Chosen'
  const items = props.options

  const handleToggle = value => () => {
    if (selected === value) {
      props.onSelect()
    } else {
      props.onSelect(value)
    }
  }

  return (
    <Card
      className={classes.root}
    >
      <CardHeader
        className={classes.cardHeader}
        subheader={title}
      />
      <Divider/>
      <List
        className={classes.list}
        component="div"
        dense
        role="list">
        {items.map(value => {
          const labelId = `transfer-list-all-item-${value}-label`

          return (
            <ListItem
              button
              component="li"
              disabled={disabled}
              key={value}
              onClick={handleToggle(value)}
              role="listitem">
              <ListItemIcon>
                <Checkbox
                  checked={selected === value}
                  disableRipple
                  inputProps={{'aria-labelledby': labelId}}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={value}/>
            </ListItem>
          )
        })}
        <ListItem/>
      </List>
    </Card>
  )

}

SelectList.propTypes = {
  disabled: PropTypes.bool,
  maxRows: PropTypes.number,
  onSelect: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.string,
  title: PropTypes.string,
}

export default SelectList
