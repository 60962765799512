import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {MarshalCriteria} from 'popcorn-js/search/criteria/Marshaller'

export class Exporter {
  static exportTrades(request) {
    let serializedCrit = []
    if (request.criteria != null) {
      serializedCrit = MarshalCriteria(request.criteria)}

    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Trade-Exporter.Export',
      request: {
        criteria: serializedCrit,
        Deleted: request.deleted
      },
    })
  }

}

export default Exporter
