import {isObject} from 'utils/Utils'

class Country {
  /**
   * @type {string}
   */
  id = ''

  /**
   * @type {string}
   */
  name = ''

  /**
   * @type {string}
   */
  code = ''

  constructor(country) {
    if (country !== undefined && (country instanceof Country || isObject(country))) {
      try {
        this.id = country.id
        this.name = country.name
        this.code = country.code
        this.auditEntry = country.auditEntry
      } catch (e) {
        console.error(`error constructing country: ${e}`)
        throw e
      }
    }
  }
}

export default Country
