import {jsonRpcRequestRaw} from '../../../actions/helper/utilities'
import config from 'react-global-configuration'
import {marshalIdentifier} from '../../search/identifier/marshalIdentifier';
import {jsonRpcRequest} from '../../jsonRpc/jsonRpcReqest';

const Handler = {
  Record(request) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Invoice-HandlerBasic.Record',
      request
    })
  },

  Validate({invoice}) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Invoice-HandlerBasic.Validate',
      request: {invoice},
    })
  },

  Update(updateRequest) {
    const serializedIdentifier = marshalIdentifier(updateRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Invoice-HandlerBasic.Update',
      request: {
        ...updateRequest,
        identifier: serializedIdentifier
      }
    }
    )
  },

  UpdateBatch(updateBatchRequest) {
    const updates = updateBatchRequest.updates.map((update) => {
      return {
        identifier: marshalIdentifier(update.identifier),
        invoice: update.invoice
      }
    })
    
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Invoice-HandlerBasic.Update',
      request: {updates}
    }
    )
  },

}


export default Handler
