import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'

/**
 * PermissionController is a convenience client for invoking the backend services
 */
export default class Controller {
  static FindAllMyPermissions() {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Permission.FindAllMyPermissions',
      request: {},
    })
  }
}
