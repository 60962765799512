import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Handler as CompanyHandler} from '../popcorn-js/company/handler'
import {Card, makeStyles, Button, Snackbar} from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import AndileTable from '../components/AndileMaterialUITable/AndileTable';
import {CriteriaType} from '../popcorn-js/search';
import {useService} from 'hooks/useService';
import {Alert} from './Tickets/styledComponents';
import Generator from '../popcorn-js/report/generatorTS';
import Store from '../popcorn-js/report/store';
import {processUnixDateForViewing} from '../utils/Utils';
import saveAs from 'file-saver';
import {TEXT_CRITERION} from '../popcorn-js/search/criteria/types';
import { AppContext, AppContextType } from 'appContext'


const useStyles = makeStyles(theme => ({
  root: {
    width: '1160px',
  },
  header: {
    backgroundColor: '#27254D',
  },
  content: {
    padding: 0,
    gridTemplateColumns: 'auto auto 1fr auto',
    '&:last-child': {
      padding: 0,
    },
  },
  buttonGen: {
    Width: '178px',
    color: '#1E2036',
    backgroundColor: '#2BBED9',
    border: '1.5px solid #2BBED9',
    margin: '16px',
  },
  buttonView: {
    Width: '242px',
    color: '#FFFFFF',
    margin: '16px',
    border: '1.5px solid #FFFFFF',
  }
}))

const views = {
  subsidiaries: 0,
  adHocReports: 1,
  monthEndReports: 2,
}

export const Reports: React.FC = (p) => {

  const classes = useStyles()
  const appContext = useContext<AppContextType>(AppContext);
  const [view, setView] = useState<number>(views.subsidiaries)
  const [subsidiaries, setSubsidiaries] = React.useState([])
  const [reports, setReports] = React.useState([])
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [successMessage, setSuccessMessage] = useState<string | undefined>()
  const generateFindSubsidiariesRequest = useCallback(() => {
    return {
      holdingCompanyPartyCode: appContext.party.partyCode,
      subsidiaries: appContext.party.subsidiaries
    }
  }, [appContext])
  const [
    {
      response: findSubsidiariesResponse,
      loading: findSubsidiariesLoading,
      error: findSubsidiariesError,
    },
    setFindSubsidiariesRequest
  ] = useService(generateFindSubsidiariesRequest(), CompanyHandler.FindSubsidiaries)


  useEffect(() => {
    setFindSubsidiariesRequest(generateFindSubsidiariesRequest())
  }, [generateFindSubsidiariesRequest, setFindSubsidiariesRequest])

  useEffect(() => {
    if (findSubsidiariesResponse && findSubsidiariesResponse.subsidiaries && !findSubsidiariesLoading) {
      setSubsidiaries(findSubsidiariesResponse.subsidiaries)

    }
    if (findSubsidiariesError) {
      setErrorMessage(findSubsidiariesError)
    }
  }, [findSubsidiariesResponse, findSubsidiariesLoading, findSubsidiariesError])

  const handleGenerateReport = () => {
    setAsyncGenerateConsolidatedReportRequest(
      {
        holdingCompanyPartyCode: appContext.party.partyCode,
        subsidiaries: appContext.party.subsidiaries
      }
    )
  }
  const handleViewAdHocReports = () => {
    setStoreFindRequest(
      {
        criteria: [
          {
            type: TEXT_CRITERION,
            value: {
              field: 'jobTrigger',
              text: 'AdHoc',
            }
          },
        ]
      }
    )
    setView(views.adHocReports)
  }
  const handleViewMonthEndReports = () => {
    setStoreFindRequest(
      {
        criteria: [
          {
            type: TEXT_CRITERION,
            value: {
              field: 'jobTrigger',
              text: 'MonthEnd',
            }
          },
        ]
      }
    )
    setView(views.monthEndReports)
  }
  const handleBackToReports = () => {
    setView(views.subsidiaries)
  }
  const handleClose = () => {
    setErrorMessage(undefined)
    setSuccessMessage(undefined)
  }

  const [
    {
      response: asyncGenerateConsolidatedReportResponse,
      loading: asyncGenerateConsolidatedReportLoading,
      error: asyncGenerateConsolidatedReportError,
    },
    setAsyncGenerateConsolidatedReportRequest
  ] = useService(null, Generator.AsyncGenerateConsolidatedReport)

  useEffect(() => {
    if (asyncGenerateConsolidatedReportResponse && asyncGenerateConsolidatedReportResponse.jobID && !asyncGenerateConsolidatedReportLoading) {
      setSuccessMessage('Generating report in background')
    }
    if (asyncGenerateConsolidatedReportError) {
      setErrorMessage(asyncGenerateConsolidatedReportError)
    }
  }, [asyncGenerateConsolidatedReportResponse, asyncGenerateConsolidatedReportLoading, asyncGenerateConsolidatedReportError])


  useEffect(() => {
    if (asyncGenerateConsolidatedReportResponse && asyncGenerateConsolidatedReportResponse.jobID && !asyncGenerateConsolidatedReportLoading) {
      setSuccessMessage('Generating report in background')
    }
    if (asyncGenerateConsolidatedReportError) {
      setErrorMessage(asyncGenerateConsolidatedReportError)
    }
  }, [asyncGenerateConsolidatedReportResponse, asyncGenerateConsolidatedReportLoading, asyncGenerateConsolidatedReportError])

  const [
    {
      response: storeFindResponse,
      loading: storeFindLoading,
      error: storeFindError,
    },
    setStoreFindRequest
  ] = useService(null, Store.Find)
  useEffect(() => {
    if (storeFindResponse && storeFindResponse.wrappedReports && !storeFindLoading) {
      setReports(storeFindResponse.wrappedReports)
    }
    if (storeFindError) {
      setErrorMessage(storeFindError)
    }
  }, [storeFindResponse, storeFindLoading, storeFindError])

  const handleDownloadExport = (excelData: string) => {
    const binData = atob(excelData);
    const bytes = new Array(binData.length);
    for (let i = 0; i < binData.length; i++) {
      bytes[i] = binData.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(bytes)], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
    });
    // @ts-ignore
    saveAs(
      blob,
      'FX Exposure Summary.xlsx'
    );
  }

  return (

    <>
      {view === 0 &&
      <>
        <Card
          className={classes.root}
        >
          <CardHeader
            className={classes.header}
            title={'Subsidiaries'}/>
          <CardContent className={classes.content}>
            <AndileTable
              // @ts-ignore
              columns={[
                {
                  title: 'Company Name',
                  field: 'name',
                  filter: {type: CriteriaType.TextCriterion},
                  render: (rowData: any) => rowData.name
                },
              ]}
              data={subsidiaries || []}
              defaultColConfig={[
                {header: 'Company Name', visible: true},
              ]}
              disableFooter
              disableHeader
              maxTableHeight={600}
              tableID={'subsidiaries'}
            />
          </CardContent>
        </Card>
        <Button
          className={classes.buttonGen}
          onClick={() => handleGenerateReport()}
        >
          Generate Report
        </Button>
        <Button
          className={classes.buttonView}
          onClick={() => handleViewAdHocReports()}
        >
          View Ad-Hoc Reports
        </Button>
        <Button
          className={classes.buttonView}
          onClick={() => handleViewMonthEndReports()}
        >
          View Month-End Reports
        </Button>
      </>}
      {view === 1 &&
      <>
        <Card
          className={classes.root}
        >
          <CardHeader
            className={classes.header}
            title={'Ad-Hoc Reports'}/>
          <CardContent className={classes.content}>
            <AndileTable
              // @ts-ignore
              columns={[
                {
                  title: 'Name',
                  field: 'name',
                  filter: {type: CriteriaType.TextCriterion},
                  render: () => 'FX Exposure Summary'
                },
                {
                  title: 'Date',
                  field: 'timestamp',
                  filter: {type: CriteriaType.TextCriterion},
                  render: (rowData: any) => processUnixDateForViewing(rowData.timeStamp)
                },
              ]}
              data={reports || []}
              defaultColConfig={[
                {header: 'Name', visible: true},
                {header: 'Date', visible: true},
              ]}
              disableFooter
              disableHeader
              maxTableHeight={600}
              rowDoubleClickAction={(rowData: any) => {
                handleDownloadExport(rowData.report.excelData)
              }}
              tableID={'reports'}
            />
          </CardContent>
        </Card>
        <Button
          className={classes.buttonView}
          onClick={() => handleBackToReports()}
        >
          Back To Reports
        </Button>
      </>}
      {view === 2 &&
      <>
        <Card
          className={classes.root}
        >
          <CardHeader
            className={classes.header}
            title={'Month-End Reports'}/>
          <CardContent className={classes.content}>
            <AndileTable
              // @ts-ignore
              columns={[
                {
                  title: 'Name',
                  field: 'name',
                  filter: {type: CriteriaType.TextCriterion},
                  render: () => 'FX Exposure Summary'
                },
                {
                  title: 'Date',
                  field: 'timestamp',
                  filter: {type: CriteriaType.TextCriterion},
                  render: (rowData: any) => processUnixDateForViewing(rowData.timeStamp)
                },
              ]}
              data={reports || []}
              defaultColConfig={[
                {header: 'Name', visible: true},
                {header: 'Date', visible: true},
              ]}
              disableFooter
              disableHeader
              maxTableHeight={600}
              rowDoubleClickAction={(rowData: any) => {
                handleDownloadExport(rowData.report.excelData)
              }}
              tableID={'reports'}
            />
          </CardContent>
        </Card>
        <Button
          className={classes.buttonView}
          onClick={() => handleBackToReports()}
        >
          Back To Reports
        </Button>
      </>}
      <Snackbar
        autoHideDuration={3000}
        onClose={() => handleClose()}
        open={successMessage !== undefined}>
        <Alert
          onClose={() => handleClose()}
          severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        onClose={() => handleClose()}
        open={errorMessage !== undefined}>
        <Alert
          onClose={() => handleClose()}
          severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>)
}