class Comparator {
  static IsDirty(a, b) {
    if (!a || !b) {
      return true
    }
    if (a.loginName !== b.loginName) {
      return true
    }

    if (a.defaultLangauge !== b.defaultLangauge) {
      return true
    }

    if (a.firstName !== b.firstName) {
      return true
    }

    if (a.lastName !== b.lastName) {
      return true
    }

    if (a.emailAddress !== b.emailAddress) {
      return true
    }

    if (a.primaryTelephoneNumber !== b.primaryTelephoneNumber) {
      return true
    }

    if (a.mobilePhone !== b.mobilePhone) {
      return true
    }

    if (a.partyCode !== b.partyCode) {
      return true
    }

    if (a.roleId !== b.roleId) {
      return true
    }


    return false
  }
}

export default Comparator

