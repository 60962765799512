import React from 'react'
import styles from './success.module.scss'

export default function SuccessIcon() {
  return (
    <div className={styles.icon}>
      <span className={styles.success_line_long}/>
      <span className={styles.success_line_tip}/>
      <div className={styles.success_ring}/>
    </div>
  )
}
