import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CurrencyEntity from 'popcorn-js/financial/currency/Currency.js';
import OrderImportDialog from './OrderImportDialog';
import Order from 'popcorn-js/financial/Order';
import { processUnixDateForViewing } from 'utils/Utils';
import {
  commitmentReport,
  mosaicReport,
} from 'components/ImportExport/importConfigs';
import { ImportDialog } from '../index';

import { ErrorProcessingFileDialog } from 'components/ImportExport/index';

import { ORDER_INVALID_REASON_COUNTERPARTY_DOES_NOT_EXIST } from 'popcorn-js/financial/order/orderType';
import { InvoiceRecordkeeper } from 'popcorn-js/financial/invoice';
import { Recordkeeper as OrderRecordkeeper } from 'popcorn-js/financial/order/recordkeeper';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty';
import { exactCriterion } from 'popcorn-js/search/criteria';

const width = {
  small: 120,
  medium: 170,
  large: 250,
};

const states = {
  nop: 0,

  // Import
  showingImportDialog: 1,
  showingErrorProcessingFileDialog: 2,

  orderUploadInProgress: 3,
  uploadErrorPresent: 4,
};

const events = {
  init: states.importClick,
  // Import
  importClick: states.showingImportDialog,
  closeImport: states.nop,
  errorWhileProcessingFile: states.showingErrorProcessingFileDialog,

  recordImport: states.orderUploadInProgress,
  finishOrderUpload: states.nop,
};

class OrderImporterContainer extends React.Component {
  state = {
    activeState: events.importClick,
    newOrders: [],
    orderCounterparties: [],
    ordersValidated: {
      validationInProgress: true,
      uploadError: undefined,
      unique: [],
      duplicate: [],
      deleted: [],
      invalid: [],
      missingCounterparties: [],
    },
  };
  addNewFromImportProps = {
    currencies: this.props.currencies,
    invoices: [],
    counterparties: [],
  };
  previewTableColumns = [
    {
      Header: 'Number',
      accessor: 'number',
      width: width.medium,
    },
    {
      Header: 'Counterparty',
      accessor: 'counterparty',
      width: width.large,
    },
    {
      Header: 'Amount Due',
      accessor: 'amountDue',
      width: width.small,
    },
    {
      Header: 'Currency',
      accessor: 'currencyId',
      width: width.medium,
      Cell: (rowCell) => {
        return (
          (
            (this.props.currencies || []).find((c) => c.id === rowCell.value) ||
            {}
          ).isoCode ||
          rowCell.value ||
          ''
        );
      },
    },
    {
      Header: 'Cost Currency',
      accessor: 'costCurrencyId',
      width: width.medium,
      Cell: (rowCell) => {
        return (
          (
            (this.props.currencies || []).find((c) => c.id === rowCell.value) ||
            {}
          ).isoCode ||
          rowCell.value ||
          ''
        );
      },
    },
    {
      Header: 'Linked Invoice',
      accessor: 'invoiceNumber',
      width: width.medium,
    },
    {
      Header: 'Costing Rate',
      accessor: 'costingRate',
      width: width.small,
    },
    {
      Header: 'Due date',
      accessor: 'dueDate',
      width: width.medium,
      Cell: (rowCell) => {
        return processUnixDateForViewing(rowCell.value);
      },
    },
    {
      Header: 'Financial Year',
      accessor: 'financialYear',
      width: width.small,
    },
    {
      Header: 'Shipping Date',
      accessor: 'shippingDate',
      width: width.medium,
      Cell: (rowCell) => {
        return processUnixDateForViewing(rowCell.value);
      },
    },
    {
      Header: 'Shipment Reference',
      accessor: 'shipmentReference',
      width: width.large,
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      width: width.large,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: width.medium,
    },
    {
      Header: 'Issue Date',
      accessor: 'issueDate',
      width: width.medium,
      Cell: (rowCell) => {
        return processUnixDateForViewing(rowCell.value);
      },
    },
    {
      Header: 'Import/Export',
      accessor: 'importExport',
      width: width.small,
    },
  ];
  entityImportClass = Order;
  importConfigs = [mosaicReport, commitmentReport];

  constructor(props) {
    super(props);
    this.processOrderSubmit = this.processOrderSubmit.bind(this);
    this.handleValidateBatchOrders = this.handleValidateBatchOrders.bind(this);
  }

  processOrderSubmit = (newOrders) => {
    const { partyCode, orderTypeForFileUpload } = this.props;

    this.props.showImportDialog();

    // Set party code on all of the orders
    newOrders = newOrders.map((order) => {
      order.partyCode = partyCode;
      order.type = orderTypeForFileUpload;
      return order;
    });
    this.handleValidateBatchOrders(newOrders);
    this.setState({
      activeState: events.recordImport,
      newOrders,
    });
  };

  handleValidateBatchOrders = async (newOrders) => {
    const ordersValidated = this.state.ordersValidated;

    const invoiceNumberCriteria = [];
    // pre-processing - populate counterparty id if not populated yet
    for (const i in newOrders) {
      // check invoice id association
      if (newOrders[i].invoiceNumber) {
        const cachedInvoice = this.addNewFromImportProps.invoices.find(
          (i) => i.number === newOrders[i].invoiceNumber
        );
        if (cachedInvoice) {
          newOrders[i].invoiceNumber = cachedInvoice.number;
        } else {
          const exist = invoiceNumberCriteria.find(
            (i) => i.text === newOrders.invoiceNumber
          );
          if (!exist) {
            invoiceNumberCriteria.push({
              type: exactCriterion,
              text: newOrders[i].invoiceNumber,
              field: 'number',
            });
          }
        }
      }
    }

    this.setState({
      ordersValidated: {
        ...ordersValidated,
        validationInProgress: true,
        uploadError: undefined,
      },
      ordersCreateBatch: {
        failed: 0,
        succeeded: 0,
        inProgress: false,
        success: false,
        error: undefined,
      },
    });

    const counterpartyFindResponse = await CounterpartyRecordkeeper.find({
      criteria: [],
      query: undefined,
      Deleted: false,
    });

    // find counterparty associations
    let newCounterparties = [];
    const missingOrderCounterparties = [];
    const orderCounterparties = [];
    try {
      const storedCounterparties = counterpartyFindResponse.records;
      for (const p of newOrders) {
        const orderCounterparty = storedCounterparties.find(
          (b) => b.name === p.counterparty
        );
        if (orderCounterparty) {
          p.counterpartyId = orderCounterparty.id;
          orderCounterparties.push(orderCounterparty);
        } else {
          //Check if counterparty already added to list
          if (
            !missingOrderCounterparties.find((b) => b.name === p.counterparty)
          ) {
            missingOrderCounterparties.push({ name: p.counterparty });
          }
        }
      }
      newCounterparties = counterpartyFindResponse.records;
      this.addNewFromImportProps.counterparties.push(...orderCounterparties);
    } catch (e) {
      console.error('could not find counterparties: ', e);
    }
    // find invoice associations
    let newInvoices = [];
    if (invoiceNumberCriteria.length > 0) {
      try {
        const findResponse = await InvoiceRecordkeeper.find({
          criteria: invoiceNumberCriteria,
        });
        newInvoices = findResponse.records;
        this.addNewFromImportProps.invoices.push(...newInvoices);
      } catch (e) {
        console.error('could not find invoices: ', e);
      }
    }

    let additionalInvalid = [];
    for (const i in newOrders) {
      // if already populated, skip
      if (
        !(
          newOrders[i].counterpartyId &&
          newOrders[i].counterpartyId !== '' &&
          newOrders[i].counterparty !== newOrders[i].counterpartyId
        )
      ) {
        const newlyAddedCounterparty = newCounterparties.find(
          (b) => b.name === newOrders[i].counterparty
        );
        if (newlyAddedCounterparty) {
          newOrders[i].counterpartyId = newlyAddedCounterparty.id;
        }
      }

      // check missing invoices and set invoice IDs
      if (newOrders[i].invoiceNumber) {
        const newlyAddedInvoice = newInvoices.find(
          (inv) => inv.number === newOrders[i].invoiceNumber
        );
        if (newlyAddedInvoice) {
          newOrders[i].invoiceId = newlyAddedInvoice.id;
        } else {
          // TODO this is a bit of a hack - it should pick up invalid/missing invoices from validation result
          // this should result in an additional invalid
          const p = newOrders.splice(i, 1);
          additionalInvalid = [
            ...additionalInvalid,
            {
              ...p[0],
              reasons: [
                {
                  field: 'invoiceId',
                  reason: 'invoice does not exist',
                },
              ],
            },
          ];
        }
      }
    }

    try {
      const validateResponse = await OrderRecordkeeper.validateBatch({
        orders: newOrders,
      });
      validateResponse.unique = validateResponse.unique || [];
      validateResponse.duplicate = validateResponse.duplicate
        ? this.processDuplicates(validateResponse.duplicate)
        : [];
      validateResponse.deleted = validateResponse.deleted
        ? this.processDuplicates(validateResponse.deleted)
        : [];
      validateResponse.invalid = validateResponse.invalid
        ? this.processInvalid(validateResponse.invalid)
        : [];
      validateResponse.missingCounterparties = missingOrderCounterparties;

      // TODO this is a bit of a hack - it should pick up invalid/missing invoices from validation result
      validateResponse.invalid = [
        ...(validateResponse.invalid || []),
        ...additionalInvalid,
      ];

      this.setState({
        ordersValidated: {
          ...validateResponse,
          validationInProgress: false,
          uploadError: false,
        },
      });
    } catch (e) {
      this.setState({
        ordersValidated: {
          ...ordersValidated,
          validationInProgress: false,
          activeState: states.uploadErrorPresent,
          uploadError: e,
        },
      });
    }
  };

  processDuplicates(duplicates) {
    const dupArray = [];
    duplicates.forEach((dupOrder) => {
      const changedFields = {};
      dupOrder.differences.forEach((diff) => {
        changedFields[diff.FieldName] = true;
      });
      dupArray.push({
        ...dupOrder.newOrder,
        isNew: true,
        changedFields,
      });
    });
    return dupArray;
  }

  processInvalid(invalids) {
    const invalid = [];

    invalids.forEach((inv) => {
      inv.order.reasons = [];
      inv.order.invalidFields = [];
      inv.reasons.forEach((reason) => {
        if (
          reason.reason !== ORDER_INVALID_REASON_COUNTERPARTY_DOES_NOT_EXIST
        ) {
          inv.order.reasons.push(reason);
          inv.order.invalidFields[reason.field] = true;
        }
      });
      invalid.push(inv.order);
    });
    return invalid;
  }

  renderMainView() {
    const {
      activeState,
      fileProcessingError,
      newOrders,
      ordersValidated,
      uploadError,
      orderCounterparties,
    } = this.state;

    const { currencies, orderTypeForFileUpload } = this.props;

    console.log(
      'orderTypeForFileUploadorderTypeForFileUpload',
      orderTypeForFileUpload
    );

    switch (activeState) {
      case states.showingImportDialog:
        return (
          <ImportDialog
            addNewFromImportProps={this.addNewFromImportProps}
            entityImportClass={this.entityImportClass}
            importConfigs={this.importConfigs}
            onAwayClick={() => {
              this.props.onAwayClick();
            }}
            orderCounterparties={orderCounterparties}
            previewTableColumns={this.previewTableColumns}
            show
            showManualEntryButton={false}
            submitRecords={this.processOrderSubmit}
          />
        );
      case states.showingErrorProcessingFileDialog:
        return (
          <ErrorProcessingFileDialog
            errorMessage={fileProcessingError}
            onAwayClick={this.props.onAwayClick}
            show={activeState === states.showingErrorProcessingFileDialog}
          />
        );
      case states.uploadErrorPresent:
        return (
          <ErrorProcessingFileDialog
            errorMessage={uploadError}
            onAwayClick={this.props.onAwayClick}
            show={activeState === states.uploadErrorPresent}
          />
        );
      case states.orderUploadInProgress:
        return (
          <OrderImportDialog
            counterparties={this.addNewFromImportProps.counterparties}
            currencies={currencies}
            invoices={this.addNewFromImportProps.invoices}
            newOrders={newOrders}
            onAwayClick={this.props.onAwayClick}
            ordersValidated={ordersValidated}
            show
            uploadSuccess={this.props.uploadSuccess}
            validateBatchOrder={this.handleValidateBatchOrders}
          />
        );
      default:
        return <div>A Problem Occurred</div>;
    }
  }

  render() {
    return this.renderMainView();
  }
}

const mapStateToProps = (state) => {
  return {
    currencies: state.currency.records,
    partyCode: state.security.claims.context.partyCode,
  };
};
const mapDispatchToProps = () => {
  return {};
};

OrderImporterContainer.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.instanceOf(CurrencyEntity)),
  enableDownloadTemplate: PropTypes.bool,
  onAwayClick: PropTypes.func,
  orderTypeForFileUpload: PropTypes.string,
  partyCode: PropTypes.string,
};

const ConnectedOrderImporterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderImporterContainer);

export default ConnectedOrderImporterContainer;
