import Entry from 'popcorn-js/audit/Entry'
import {isObject} from '../../../../utils/Utils'

export default class System {
  /**
   * @type string
   */
  id = ''
  /**
   * @type string
   */
  name = ''
  /**
   * @type string
   */
  adminEmail = ''
  /**
   * @type string
   */
  description = ''
  /**
   * @type string
   */
  partyCode = ''
  /**
   * @type [string]
   */
  currencyPairIds = []
  /**
   * @type Entry
   */
  auditEntry = new Entry()

  constructor(system) {
    if (system !== undefined &&
      (system instanceof System ||
        isObject(system))) {
      try {
        this.id = system.id
        this.name = system.name
        this.adminEmail = system.adminEmail
        this.description = system.description
        this.partyCode = system.partyCode
        this.currencyPairIds = system.currencyPairIds
        this.auditEntry = system.auditEntry
      } catch (e) {
        console.error(`error constructing system: ${e}`)
        throw e
      }
    }
  }

}
