import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import config from 'react-global-configuration'


const Handler = {
  RetrieveRate(request) {
    return jsonRpcRequestRaw({
      url: config.get('rickUrl'),
      method: 'Rates-Handler.RetrieveRate',
      request
    })
  },
  GetRateCache(request) {
    return jsonRpcRequestRaw({
      url: config.get('rickUrl'),
      method: 'Rates-Handler.GetRateCache',
      request
    })
  },

}


export default Handler
