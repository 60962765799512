import React from 'react';
import PropTypes from 'prop-types';

// Material UI components
import {
  withStyles,
  CardHeader,
  Card,
  IconButton,
  Tooltip,
  Grid,
  Dialog,
} from '@material-ui/core';

// Icons
import {
  MdClear,
  MdHighlightOff,
  MdModeEdit,
  MdRestore,
  MdSave,
} from 'react-icons/md';

// Custom components
import InvoiceDetailHistoryContainer from 'views/History/InvoiceHistory/InvoiceDetailHistoryContainer';
import OrderDetailDialog from 'components/Detail/order/OrderDialog';
import NotificationSweetAlert from 'components/SweetAlert/NotificationSweetAlert';
import DetailDialog from 'views/TradeStation/DetailDialog';
import { ItemWrapper, AuditEntry, EntityList } from './components';
import {
  TextField as ParsedTextField,
  TextFieldParseTypes,
} from 'components/FormContols/index';

// Constants
import Counterparty from 'popcorn-js/counterparty/Counterparty';
import ProcessingBank from 'popcorn-js/legalEntity/party/processingBank/ProcessingBank';
import {
  INVOICE_UPDATE_PERMISSION,
  INVOICE_RETRIEVE_HISTORY_PERMISSION,
} from 'popcorn-js/financial/invoice/permissions.js';
import {
  EXACT_CRITERION,
  TEXT_CRITERION,
} from 'popcorn-js/search/criteria/types';
import {
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
  INVOICE_STATUS_PENDING,
  PAYMENT_IN_PROGRESS,
  INVOICE_TYPE_PURCHASE_CREDIT_NOTE,
  INVOICE_TYPE_PURCHASE_INVOICE,
  INVOICE_TYPE_SALES_INVOICE,
  INVOICE_TYPE_SALES_CREDIT_NOTE,
} from 'constants/invoice';

// Service providers
import ProcessingBankRecordkeeper from 'popcorn-js/legalEntity/party/processingBank/recordkeeper';
import { CounterpartyRecordkeeper } from 'popcorn-js/counterparty/index';
import Manager from 'popcorn-js/legalEntity/user/manager';
import { Recordkeeper as OrderRecordkeeper } from 'popcorn-js/financial/order/recordkeeper';
import InvoiceHandler from 'popcorn-js/financial/invoice/handler';
import TradeRecordKeeper from 'popcorn-js/trade/recordkeeper';
import { financialYears } from 'popcorn-js';

// 3rd Party components
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

// styles
import styles from './styles';
import selectStyles from './selectStyles';

const activeStates = {
  viewing: 'viewing',
  editing: 'editing',
};

class InvoiceDetail extends React.Component {
  state = {
    activeState: activeStates.viewing,
    invoiceValidationErrors: [],
    loading: false,
    successMessage: undefined,
    invoiceChanged: false,
    confirmationMethod: undefined,
    warningMessage: undefined,
    errorMessage: undefined,
    showHistory: false,
    invoiceOrders: [],
    linkedTrades: [],
    selectedInvoiceOrder: {},
    showInvoiceOrderDetail: false,
    selectedLinkedTrade: {},
    showLinkedTradeDetail: false,
    clearCacheToggle: false,
    processingBanks: [],
    invoice: {},
    originalInvoice: {},
  };

  constructor(props) {
    super(props);
    this.state.originalInvoice = this.props.invoice;
    this.state.invoice = this.props.invoice;
  }

  invoiceCounterparty = {};

  componentDidMount() {
    if (!(this.props.counterparties && this.props.counterparties.find(c => c.id === this.props.invoice.counterpartyId))) {
      this.retrieveInvoiceCounterparty();
    }
    this.findProcessingBanks();
    this.processEntityForViewing();
    this.findLinkedOrders();
    this.findLinkedTrades();
  }

  async findProcessingBanks() {
    try {
      const processingBanksFindResponse = await ProcessingBankRecordkeeper.find(
        undefined,
        undefined
      );
      this.setState({
        processingBanks: processingBanksFindResponse.records.map(
          (pb) => new ProcessingBank(pb)
        ),
      });
    } catch (e) {
      console.error('error finding processing banks', e);
      this.setState({ error: 'error finding processing banks' });
    }
  }

  findLinkedOrders = async () => {
    this.setState({ loading: true });
    try {
      const criteria = {
        type: EXACT_CRITERION,
        value: {
          field: 'invoiceId',
          text: this.state.invoice.id,
        },
      };
      const findResponse = await OrderRecordkeeper.find({
        criteria: [criteria],
        Query: undefined,
        deleted: false,
      });
      this.setState({
        invoiceOrders: findResponse.records,
      });
    } catch (e) {
      this.setState(
        {
          errorMessage: 'Failed to retrieve linked orders',
        },
        () => console.error('Failed to retrieve linked orders')
      );
    }
    this.setState({ loading: false });
  };

  findLinkedTrades = async () => {
    if ((this.state.invoice.tradeLinks || []).length === 0) {
      return;
    }
    this.setState({ loading: true });
    const criteria = (this.state.invoice.tradeLinks || []).map((tl) => ({
      type: EXACT_CRITERION,
      value: { field: 'id', text: tl.tradeId },
    }));
    try {
      const findResponse = await TradeRecordKeeper.find({
        criteria,
        query: this.findQuery,
        deleted: false,
      });
      this.setState({
        linkedTrades: findResponse.records,
      });
    } catch (e) {
      this.setState(
        {
          errorMessage: 'Failed to retrieve linked trades',
        },
        () => console.error('Failed to retrieve linked trades')
      );
    }
    this.setState({ loading: false });
  };

  retrieveInvoiceCounterparty = async () => {
    this.setState({ loading: true });
    try {
      const retrieveResponse = await CounterpartyRecordkeeper.retrieve(
        {id: this.props.invoice.counterpartyId}
      );
      this.invoiceCounterparty = new Counterparty(
        retrieveResponse.counterparty
      );
    } catch (e) {
      this.invoiceCounterparty = {};
      console.error('error retrieving invoice counterparty', e);
    }
    this.setState({ loading: false });
  };

  validateInvoice = async () => {
    const invoice = this.state.invoice;
    this.setState({
      invoiceValidationErrors: [],
      loading: true,
    });
    try {
      const validateResponse = await InvoiceHandler.Validate({ invoice });
      if (validateResponse.reasons.length > 0) {
        this.setState({
          invoiceValidationErrors: validateResponse.reasons,
          loading: false,
        });
        return validateResponse.reasons;
      }
    } catch (e) {
      this.setState({
        errorMessage: e.message || e,
      });
    }
    this.setState({ loading: false });
  };

  updateInvoice = async () => {
    const invoice = this.state.invoice;

    this.setState({ loading: true });
    try {
      const updateResponse = await InvoiceHandler.Update({
        identifier: { id: invoice.id },
        invoice: invoice,
      });
      this.processEntityForViewing();
      this.props.updateInvoiceSuccess(updateResponse.invoice);
      this.setState({
        invoice: updateResponse.invoice,
        originalInvoice: updateResponse.invoice,
        activeState: activeStates.viewing,
        successMessage: 'Updated',
        invoiceChanged: false,
      });
    } catch (e) {
      this.setState({
        invoice: this.state.originalInvoice,
        errorMessage: e,
        activeState: activeStates.viewing,
        invoiceChanged: false,
      });
    }
    this.setState({ loading: false });
  };

  onSave = async () => {
    await this.validateInvoice();
    const { invoiceValidationErrors, errorMessage } = this.state;
    if (invoiceValidationErrors.length === 0 && errorMessage === undefined) {
      this.updateInvoice();
    }
  };

  showDiscardChangesConfirmation = (functionAfterDiscard) => {
    this.setState({
      warningMessage: 'Would you like to discard all changes?',
      confirmationMethod: functionAfterDiscard,
    });
  };

  handleDiscardChangesAndView = () => {
    this.setState({
      invoice: this.state.originalInvoice,
      invoiceChanged: false,
      warningMessage: undefined,
      confirmationMethod: undefined,
      invoiceValidationErrors: [],
      activeState: activeStates.viewing,
    });
  };

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    });
  };

  editInvoice = (field, newValue) => {
    this.setState({
      invoiceChanged: true, //TODO, need to check changes using the comparator
    });

    const stateinvoiceValidationErrors = this.state.invoiceValidationErrors.slice();

    const newInvoice = this.state.invoice;
    newInvoice[field] = newValue;
    stateinvoiceValidationErrors.filter((fe) => fe.field === field);
    this.setState({
      invoice: newInvoice,
      invoiceValidationErrors: stateinvoiceValidationErrors,
    });
    this.forceUpdate();
  };

  processEntityForViewing = async () => {
    try {
      const getUsernameResponse = await Manager.getUserProfileById(
        this.state.invoice.auditEntry.userId
      );
      this.setState({
        invoice: {
          ...this.state.invoice,
          auditEntry: {
            ...this.state.invoice.auditEntry,
            username: getUsernameResponse.displayName,
          },
        },
      });
    } catch (e) {
      this.setState({
        invoice: {
          ...this.state.invoice,
          auditEntry: {
            ...this.state.invoice.auditEntry,
            username: 'SYSTEM',
          },
        },
      });
      console.error('could not retrieve user name');
    }
  };

  generateCounterpartyOptions = async (inputValue) => {
    const criteria = [
      {
        type: TEXT_CRITERION,
        value: { text: inputValue, field: 'name' },
      },
      {
        type: EXACT_CRITERION,
        value: { text: this.state.invoice.partyCode, field: 'partyCode' },
      },
    ];
    try {
      const findResponse = await CounterpartyRecordkeeper.find({
        criteria: criteria,
        query: undefined,
        Deleted: false,
      });
      return (findResponse.records || []).map((b) => ({
        value: b.id,
        label: b.name,
      }));
    } catch (e) {
      throw e.message || e;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.root}>
        <CardHeader
          action={this.renderActionIcons()}
          classes={{
            root: classes.cardHeader,
            action: classes.action,
            title: classes.cardTitle,
          }}
          title={'Invoice Detail'}
        />
        <div>{this.renderLayout()}</div>
        {this.renderDialogs()}
      </Card>
    );
  }

  renderDialogs = () => {
    const {
      showHistory,
      selectedInvoiceOrder,
      showInvoiceOrderDetail,
      selectedLinkedTrade,
      showLinkedTradeDetail,
      loading,
    } = this.state;
    const { invoice, classes } = this.props;
    return (
      <span>
        {showHistory && (
          <InvoiceDetailHistoryContainer
            invoice={invoice || {}}
            onHide={() => this.setState({ showHistory: false })}
            open={showHistory}
          />
        )}
        {showInvoiceOrderDetail && (
          <OrderDetailDialog
            onClose={() => this.setState({ showInvoiceOrderDetail: false })}
            onSaveSuccess={() =>
              this.setState({ successMessage: 'Order Saved' }, () =>
                this.props.updateInvoiceSuccess()
              )
            }
            order={selectedInvoiceOrder}
            readOnly
            show={showInvoiceOrderDetail}
          />
        )}
        {showLinkedTradeDetail && (
          <DetailDialog
            closeDialog={() => this.setState({ showLinkedTradeDetail: false })}
            currencyPairs={this.props.currencyPairs}
            open={showLinkedTradeDetail}
            processingBanks={this.state.processingBanks}
            trade={selectedLinkedTrade}
          />
        )}
        {loading && (
          <Dialog
            BackdropProps={{
              classes: { root: classes.progressSpinnerDialogBackdrop },
            }}
            PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
            className={classes.loading}
            open={loading}
          >
            <CircularProgress className={classes.progress} />
          </Dialog>
        )}
      </span>
    );
  };

  renderLayout = () => {
    const { currencies, classes } = this.props;

    const counterparties = (() => {
      if (this.props.counterparties && this.props.counterparties.find(c => c.id === this.props.invoice.counterpartyId)) {
        return this.props.counterparties;
      }
      return [this.invoiceCounterparty];
    })();

    const {
      activeState,
      invoiceValidationErrors,
      confirmationMethod,
      warningMessage,
      successMessage,
      errorMessage,
    } = this.state;
    const invoice = this.state.invoice;
    const mappedDefaultCounterparties = counterparties.map((b) => ({
      label: b.name,
      value: b.id,
    }));

    const statusOptions = [
      {
        value: INVOICE_STATUS_PAID,
        label: INVOICE_STATUS_PAID,
      },
      {
        value: INVOICE_STATUS_PENDING,
        label: INVOICE_STATUS_PENDING,
      },
      {
        value: INVOICE_STATUS_PARTIALLY_PAID,
        label: INVOICE_STATUS_PARTIALLY_PAID,
      },
      {
        value: PAYMENT_IN_PROGRESS,
        label: PAYMENT_IN_PROGRESS,
      },
    ];

    const financialYearOptions = financialYears.map((financialYear) => ({
      value: financialYear,
      label: financialYear,
    }));

    const typeOptions = [
      {
        value: INVOICE_TYPE_PURCHASE_INVOICE,
        label: INVOICE_TYPE_PURCHASE_INVOICE,
      },
      {
        value: INVOICE_TYPE_PURCHASE_CREDIT_NOTE,
        label: INVOICE_TYPE_PURCHASE_CREDIT_NOTE,
      },
      {
        value: INVOICE_TYPE_SALES_INVOICE,
        label: INVOICE_TYPE_SALES_INVOICE,
      },
      {
        value: INVOICE_TYPE_SALES_CREDIT_NOTE,
        label: INVOICE_TYPE_SALES_CREDIT_NOTE,
      },
    ];

    const currencyOptions = [];
    currencies.slice().forEach((c) => {
      currencyOptions.push({
        value: c.id,
        label: c.isoCode,
      });
    });

    const currencySymbol = (
      currencies.find((c) => c.id === invoice.currencyId) || {}
    ).symbol;
    const disableUnderLine = activeState === activeStates.viewing;
    const readOnly = activeState === activeStates.viewing;

    const inputProps = {
      classes: {
        underline: classes.fieldUnderline,
      },
      disableUnderline: disableUnderLine,
      readOnly: readOnly,
    };

    return (
      <div>
        <NotificationSweetAlert
          customClass={'configAlert'}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}
          successMessage={successMessage}
          warningMessage={warningMessage}
        />
        <Grid
          className={classes.layout}
          container
          direction={'row'}
          spacing={2}
        >
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}>
            <div className={classes.title}>Information</div>
            <ItemWrapper
              field={'type'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Type'}
            >
              {(() => {
                switch (activeState) {
                  case activeStates.viewing:
                    return (
                      <ParsedTextField
                        InputProps={inputProps}
                        id="type"
                        onChange={(e) =>
                          this.editInvoice('type', e.target.value)
                        }
                        parseType={TextFieldParseTypes.string}
                        value={invoice.type}
                      />
                    );
                  case activeStates.editing:
                    return (
                      <Select
                        defaultValue={typeOptions.find(
                          (b) => b.value === invoice.type
                        )}
                        disableUnderline={disableUnderLine}
                        isDisabled={readOnly}
                        menuPosition={'fixed'}
                        onChange={(selected) =>
                          this.editInvoice('type', selected.value)
                        }
                        options={typeOptions}
                        styles={selectStyles(this.props.theme)}
                      />
                    );
                  default:
                }
              })()}
            </ItemWrapper>

            <ItemWrapper
              field={'number'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Number'}
            >
              <ParsedTextField
                InputProps={inputProps}
                id="number"
                onChange={(e) => this.editInvoice('number', e.target.value)}
                parseType={TextFieldParseTypes.string}
                value={invoice.number}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'externalReference'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'External Reference'}
            >
              <ParsedTextField
                InputProps={inputProps}
                id="externalReference"
                onChange={(e) =>
                  this.editInvoice('externalReference', e.target.value)
                }
                parseType={TextFieldParseTypes.string}
                value={invoice.externalReference}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'counterparty'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Counterparty'}
            >
              {(() => {
                switch (activeState) {
                  case activeStates.viewing:
                    return (
                      <ParsedTextField
                        InputProps={inputProps}
                        id="counterparty"
                        value={
                          mappedDefaultCounterparties.find(
                            (b) => b.value === invoice.counterpartyId
                          )
                            ? mappedDefaultCounterparties.find(
                              (b) => b.value === invoice.counterpartyId
                            ).label
                            : ''
                        }
                      />
                    );
                  case activeStates.editing:
                    return (
                      <Tooltip
                        placement="top"
                        title="Start typing to search">
                        <AsyncSelect
                          defaultOptions={mappedDefaultCounterparties}
                          disableUnderline={disableUnderLine}
                          id="counterparty"
                          isDisabled={readOnly}
                          loadOptions={this.generateCounterpartyOptions}
                          menuPosition={'fixed'}
                          onChange={(selected) => {
                            this.editInvoice('counterparty', selected.label);
                            this.editInvoice('counterpartyId', selected.value);
                          }}
                          styles={selectStyles(this.props.theme)}
                          value={mappedDefaultCounterparties.find(
                            (b) => b.value === invoice.counterpartyId
                          )}
                        />
                      </Tooltip>
                    );
                  default:
                }
              })()}
            </ItemWrapper>
            <ItemWrapper
              field={'amountDue'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Amount Due'}
            >
              <ParsedTextField
                InputProps={(() => {
                  return {
                    ...inputProps,
                    startAdornment: currencySymbol,
                    className: classNames(
                      classes.formFieldAccent,
                      classes.leftAlign
                    ),
                  };
                })()}
                id="amountDue"
                onChange={(e) => this.editInvoice('amountDue', e.target.value)}
                parseType={TextFieldParseTypes.float}
                value={invoice.amountDue || 0}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'status'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Status'}
            >
              {(() => {
                switch (activeState) {
                  case activeStates.viewing:
                    return (
                      <ParsedTextField
                        InputProps={(() => {
                          return {
                            ...inputProps,
                            className: classNames({
                              [classes.formFieldGreen]:
                                invoice.status === INVOICE_STATUS_PAID,
                              [classes.formFieldAccent]:
                                invoice.status ===
                                INVOICE_STATUS_PARTIALLY_PAID,
                            }),
                          };
                        })()}
                        id="status"
                        value={invoice.status}
                      />
                    );
                  case activeStates.editing:
                    return (
                      <Select
                        defaultValue={statusOptions.find(
                          (b) => b.value === invoice.status
                        )}
                        disableUnderline={disableUnderLine}
                        isDisabled={readOnly}
                        menuPosition={'fixed'}
                        onChange={(selected) =>
                          this.editInvoice('status', selected.value)
                        }
                        options={statusOptions}
                        styles={selectStyles(this.props.theme)}
                      />
                    );
                  default:
                }
              })()}
            </ItemWrapper>
            <ItemWrapper
              field={'financialYear'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Financial Year'}
            >
              {(() => {
                switch (activeState) {
                  case activeStates.viewing:
                    return (
                      <ParsedTextField
                        InputProps={(() => {
                          return {
                            ...inputProps,
                            className: classes.leftAlign,
                          };
                        })()}
                        id="financialYear"
                        value={invoice.financialYear}
                      />
                    );
                  case activeStates.editing:
                    return (
                      <Select
                        defaultValue={financialYearOptions.find(
                          (b) => b.value === invoice.financialYear
                        )}
                        disableUnderline={disableUnderLine}
                        isDisabled={readOnly}
                        menuPosition={'fixed'}
                        onChange={(selected) =>
                          this.editInvoice('financialYear', selected.value)
                        }
                        options={financialYearOptions}
                        styles={selectStyles(this.props.theme)}
                      />
                    );
                  default:
                }
              })()}
            </ItemWrapper>
            <ItemWrapper
              field={'dueDate'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Due Date'}
            >
              <ParsedTextField
                InputProps={(() => {
                  return {
                    ...inputProps,
                    inputProps: {
                      max: '9999-12-31',
                    },
                    className: classes.dateField,
                  };
                })()}
                id={'dueDate'}
                onChange={(event) =>
                  this.editInvoice('dueDate', event.target.value)
                }
                parseType={TextFieldParseTypes.date}
                type={'date'}
                value={invoice.dueDate}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'currency'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Currency'}
            >
              {(() => {
                switch (activeState) {
                  case activeStates.viewing:
                    return (
                      <ParsedTextField
                        InputProps={inputProps}
                        id="currency"
                        value={
                          currencyOptions.find(
                            (b) => b.value === invoice.currencyId
                          ).label
                        }
                      />
                    );
                  case activeStates.editing:
                    return (
                      <Select
                        disableUnderline={disableUnderLine}
                        isDisabled={readOnly}
                        menuPosition={'fixed'}
                        onChange={(selected) =>
                          this.editInvoice('currencyId', selected.value)
                        }
                        options={currencyOptions}
                        styles={selectStyles(this.props.theme)}
                        value={currencyOptions.find(
                          (b) => b.value === invoice.currencyId
                        )}
                      />
                    );
                  default:
                }
              })()}
            </ItemWrapper>
            <ItemWrapper
              field={'costCurrency'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Cost Currency'}
            >
              {(() => {
                switch (activeState) {
                  case activeStates.viewing:
                    return (
                      <ParsedTextField
                        InputProps={inputProps}
                        id="costCurrency"
                        value={
                          currencyOptions.find(
                            (b) => b.value === invoice.costCurrencyId
                          ).label
                        }
                      />
                    );
                  case activeStates.editing:
                    return (
                      <Select
                        disableUnderline={disableUnderLine}
                        isDisabled={readOnly}
                        menuPosition={'fixed'}
                        onChange={(selected) =>
                          this.editInvoice('costCurrencyId', selected.value)
                        }
                        options={currencyOptions}
                        styles={selectStyles(this.props.theme)}
                        value={currencyOptions.find(
                          (b) => b.value === invoice.costCurrencyId
                        )}
                      />
                    );
                  default:
                }
              })()}
            </ItemWrapper>
            <ItemWrapper
              field={'captureRate'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Capture Rate'}
            >
              <ParsedTextField
                InputProps={inputProps}
                id="captureRate"
                onChange={(e) =>
                  this.editInvoice('captureRate', e.target.value)
                }
                parseType={TextFieldParseTypes.float}
                value={invoice.captureRate || 0}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'effectiveRate'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Effective Rate'}
            >
              <ParsedTextField
                InputProps={inputProps}
                id="effectiveRate"
                onChange={(e) =>
                  this.editInvoice('effectiveRate', e.target.value)
                }
                parseType={TextFieldParseTypes.float}
                value={invoice.effectiveRate}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'shippingDate'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Shipping Date'}
            >
              <ParsedTextField
                InputProps={(() => {
                  return {
                    ...inputProps,
                    className: classes.dateField,
                    inputProps: {
                      max: '9999-12-31',
                    },
                  };
                })()}
                id={'shippingDate'}
                onChange={(event) =>
                  this.editInvoice('shippingDate', event.target.value)
                }
                parseType={TextFieldParseTypes.date}
                type={'date'}
                value={invoice.shippingDate}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'issueDate'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Issue Date'}
            >
              <ParsedTextField
                InputProps={(() => {
                  return {
                    ...inputProps,
                    inputProps: {
                      max: '9999-12-31',
                    },
                    className: classes.dateField,
                  };
                })()}
                id={'issueDate'}
                onChange={(event) =>
                  this.editInvoice('issueDate', event.target.value)
                }
                parseType={TextFieldParseTypes.date}
                type={'date'}
                value={invoice.issueDate}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'shipmentReference'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Shipment Reference'}
            >
              <ParsedTextField
                InputProps={inputProps}
                id="Shipment Reference"
                onChange={(e) =>
                  this.editInvoice('shipmentReference', e.target.value)
                }
                parseType={TextFieldParseTypes.text}
                value={invoice.shipmentReference || '#'}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'costingRate'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Costing rate'}
            >
              <ParsedTextField
                InputProps={inputProps}
                id="costingRate"
                onChange={(e) =>
                  this.editInvoice('costingRate', e.target.value)
                }
                parseType={TextFieldParseTypes.float}
                value={invoice.costingRate || 0}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'paidAmount'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Paid amount'}
            >
              <ParsedTextField
                InputProps={(() => {
                  return {
                    ...inputProps,
                    startAdornment: currencySymbol,
                    className: classNames(
                      classes.formFieldAccent,
                      classes.leftAlign
                    ),
                  };
                })()}
                id="paidAmount"
                onChange={(e) => this.editInvoice('paidAmount', e.target.value)}
                parseType={TextFieldParseTypes.float}
                value={invoice.paidAmount || 0}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'unallocatedAmount'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Unallocated Amount'}
            >
              <ParsedTextField
                InputProps={{
                  ...inputProps,
                  startAdornment: currencySymbol,
                  className: classes.leftAlign,
                }}
                id="unallocatedAmount"
                onChange={(e) =>
                  this.editInvoice('unallocatedAmount', e.target.value)
                }
                parseType={TextFieldParseTypes.float}
                value={invoice.unallocatedAmount || 0}
              />
            </ItemWrapper>
            <ItemWrapper
              field={'notes'}
              invoiceValidationErrors={invoiceValidationErrors}
              label={'Notes'}
            >
              <ParsedTextField
                InputProps={inputProps}
                id="Notes"
                onChange={(e) => this.editInvoice('notes', e.target.value)}
                parseType={TextFieldParseTypes.text}
                value={invoice.notes}
              />
            </ItemWrapper>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}>
            <EntityList
              columns={[
                {
                  Header: 'Number',
                  accessor: 'number',
                },
                {
                  Header: 'Amount Due',
                  accessor: 'amountDue',
                  type: 'float',
                  currencySymbol: currencySymbol,
                  className: classes.formFieldAccent,
                },
              ]}
              entities={this.state.invoiceOrders}
              listHeader={'Linked Orders'}
              noRecordsMessage={'No Linked Orders'}
              onClick={(order) => {
                this.setState({
                  selectedInvoiceOrder: order,
                  showInvoiceOrderDetail: true,
                });
              }}
            />
            <EntityList
              columns={[
                {
                  Header: 'Number',
                  accessor: 'number',
                },
              ]}
              entities={this.state.linkedTrades}
              listHeader={'Linked Trades'}
              noRecordsMessage={'No Linked Trades'}
              onClick={(trade) =>
                this.setState({
                  selectedLinkedTrade: trade,
                  showLinkedTradeDetail: true,
                })
              }
            />
            <AuditEntry invoice={invoice} />
          </Grid>
        </Grid>
      </div>
    );
  };

  renderActionIcons = () => {
    const { activeState } = this.state;
    const { readOnly, apiPermissionSet, invoice } = this.props;
    const items = [];
    let key = 0;
    const getKey = () => {
      key++;
      return key;
    };

    let deleted;
    try {
      deleted = (invoice || {}).auditEntry.action === 'DELETED';
    } catch (e) {
      console.error('could not determine audit entry action');
      deleted = true;
    }

    switch (activeState) {
      case activeStates.editing:
        items.push(
          <Tooltip title={'Save Changes'}>
            <IconButton
              disabled={!this.state.invoiceChanged}
              key={getKey()}
              onClick={this.onSave}
            >
              <MdSave />
            </IconButton>
          </Tooltip>
        );
        items.push(
          <Tooltip title={'Cancel'}>
            <IconButton
              key={getKey()}
              onClick={() => {
                if (this.state.invoiceChanged) {
                  this.showDiscardChangesConfirmation(
                    this.handleDiscardChangesAndView
                  );
                } else {
                  this.setState({
                    invoiceValidationErrors: [],
                    activeState: activeStates.viewing,
                  });
                }
              }}
            >
              <MdHighlightOff />
            </IconButton>
          </Tooltip>
        );
        break;
      case activeStates.viewing:
        if (!readOnly) {
          items.push(
            <Tooltip
              disableFocusListener={apiPermissionSet.has(
                INVOICE_RETRIEVE_HISTORY_PERMISSION
              )}
              disableHoverListener={apiPermissionSet.has(
                INVOICE_RETRIEVE_HISTORY_PERMISSION
              )}
              disableTouchListener={apiPermissionSet.has(
                INVOICE_RETRIEVE_HISTORY_PERMISSION
              )}
              key={getKey()}
              title={'Retrieve history permission is required'}
            >
              <span>
                <Tooltip title={'View History'}>
                  <IconButton
                    disabled={
                      !apiPermissionSet.has(INVOICE_RETRIEVE_HISTORY_PERMISSION)
                    }
                    onClick={() => this.setState({ showHistory: true })}
                  >
                    <MdRestore />
                  </IconButton>
                </Tooltip>
              </span>
            </Tooltip>
          );
          items.push(
            <Tooltip
              disableFocusListener={apiPermissionSet.has(
                INVOICE_UPDATE_PERMISSION
              )}
              disableHoverListener={apiPermissionSet.has(
                INVOICE_UPDATE_PERMISSION
              )}
              disableTouchListener={apiPermissionSet.has(
                INVOICE_UPDATE_PERMISSION
              )}
              key={getKey()}
              title={'Update permission is required'}
            >
              <span>
                <Tooltip title={'Edit'}>
                  <IconButton
                    disabled={
                      !apiPermissionSet.has(INVOICE_UPDATE_PERMISSION) ||
                      deleted
                    }
                    onClick={() =>
                      this.setState({ activeState: activeStates.editing })
                    }
                  >
                    <MdModeEdit />
                  </IconButton>
                </Tooltip>
              </span>
            </Tooltip>
          );
          items.push(
            <Tooltip
              key={getKey()}
              title={'Close'}>
              <IconButton
                onClick={() => {
                  this.props.onClose();
                }}
              >
                <MdClear />
              </IconButton>
            </Tooltip>
          );
        }
        break;
      default:
    }
    return items;
  };
}

export default withStyles(styles, { withTheme: true })(InvoiceDetail);

InvoiceDetail.propTypes = {
  apiPermissionSet: PropTypes.object,
  counterparties: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  currencies: PropTypes.array,
  currencyPairs: PropTypes.arrayOf(PropTypes.object),
  invoice: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  theme: PropTypes.object,
  updateInvoiceError: PropTypes.func,
  updateInvoiceSuccess: PropTypes.func,
};
