import {
  LOGIN_SUCCESS,
  LOGOUT,
  SET_LOGIN_MESSAGE,
} from './actionTypes'

export function loginSuccess(claims) {
  return {type: LOGIN_SUCCESS, claims}
}

export function refreshSuccess(claims) {
  return {type: LOGIN_SUCCESS, claims}
}

export function setLoginMessage(message) {
  return {type: SET_LOGIN_MESSAGE, message}
}

export function logout() {
  return {type: LOGOUT}
}
