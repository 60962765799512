import {ContactDetails} from '../ContactDetails'
import Entry from 'popcorn-js/audit/Entry'
import {isObject} from '../../../../utils/Utils'

export default class ProcessingOrg {
  static camel = 'processingOrg'
  static nice = 'processing organisation'
  static niceP = 'processing organisations'
  static capital = 'Processing Organisation'
  static capitalP = 'Processing Organisations'
  /**
   * @type string
   */
  id = ''
  /**
   * @type string
   */
  name = ''
  /**
   * @type string
   */
  adminEmail = ''
  /**
   * @type string
   */
  customsClientNumber = ''
  /**
   * @type string
   */
  description = ''
  /**
   * @type string
   */
  partyCode = ''
  /**
   * @type string
   */
  sarbAuthNumber = ''
  /**
   * @type string
   */
  parentPartyCode = ''
  /**
   * @type boolean
   */
  enable = false
  /**
   * @type string
   */
  logo = ''
  /**
   * @type ContactDetails
   */
  contactDetails = new ContactDetails()
  /**
   * @type string
   */
  authorisedSignatory = ''
  /**
   * @type string
   */
  clientTierId = undefined
  /**
   * @type [string]
   */
  currencyPairIds = []
  /**
   * @type Entry
   */
  auditEntry = new Entry()

  /**
   * @type {string[]}
   */
  subsidiaries = []

  constructor(processingOrg) {
    if (processingOrg !== undefined &&
      (processingOrg instanceof ProcessingOrg ||
        isObject(processingOrg))) {
      try {
        this.id = processingOrg.id
        this.name = processingOrg.name
        this.enable = processingOrg.enable
        this.adminEmail = processingOrg.adminEmail
        this.customsClientNumber = processingOrg.customsClientNumber
        this.description = processingOrg.description
        this.partyCode = processingOrg.partyCode
        this.sarbAuthNumber = processingOrg.sarbAuthNumber
        this.logo = processingOrg.logo
        this.contactDetails = new ContactDetails(processingOrg.contactDetails)
        this.authorisedSignatory = processingOrg.authorisedSignatory
        this.clientTierId = processingOrg.clientTierId
        this.currencyPairIds = processingOrg.currencyPairIds
        this.parentPartyCode = processingOrg.parentPartyCode
        this.auditEntry = processingOrg.auditEntry
      } catch (e) {
        console.error(`error constructing processing org: ${e}`)
        throw e
      }
    }
  }

}
