import Base from './Base'
import {isObject, isString} from 'utils/Utils'
import {ID_IDENTIFIER} from 'popcorn-js/search/identifier/types'
import unmarshal from 'popcorn-js/search/identifier/unmarshal'

export default class Id extends Base {

  static identifierType = ID_IDENTIFIER

  /**
   * @type {string}
   * @private
   */
  _type = Id.identifierType

  /**
   * @type {{id: string}}
   * @private
   */
  _value = {
    id: '',
  }

  /**
   * Create new Id instance with given
   * identifier
   * @param {
   * {value:{
   *    id: string,
   *  }
   *  } |
   *  Id |
   *  string |
   * } [identifier]
   */
  constructor(identifier) {
    super()
    switch (true) {
      case isString(identifier):
        // if a string is passed this could either be a serialised identifier
        // or the actual string id
        if (identifier.indexOf('::') > -1) {
          // if :: exists within string, we return unmarshalled identifier
          return unmarshal(identifier)
        } else {
          // otherwise we assume that the actual string id was passed
          this._value = {
            id: identifier,
          }
        }
        break

      case identifier instanceof Id:
      case isObject(identifier):
        this._value = {
          id: identifier.id,
        }
        break

      case identifier === undefined:
        break

      default:
        throw new TypeError('invalid value passed to Id constructor')
    }
  }

  get id() {
    return this._value.id
  }

  set id(newVal) {
    this._value.id = newVal
  }
}
