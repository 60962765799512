import React from 'react'
import {Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, ReferenceLine} from 'recharts'
import {
  XAxisTickFormatter,
  roundScaleAndFormatNumber, categoryNameToMonthNum, calculateTooltipOffset
} from 'views/Home/Company/Dashboard/SharedGraphComponents'
import {CustomTooltipSection, CustomTooltipSectionLine, CustomTooltip} from '../RechartsCustomTooltip';
import {Theme, useTheme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core';
import moment from 'moment';
import {Currency} from 'popcorn-js/financial/currency/currencyType';
import {
  Aggregation,
  GenerateWeekViewExposureReportForCurrencyResponse
} from 'popcorn-js/report/generatorTS';

interface Response {
  data: Array<WeekViewExposureDataSeries>,
  ticks: Array<string>,
  monthsColumnAmountMap: Record<string, number>
}


export interface viewPermissions {
  canViewMaturityLadder: boolean
}

interface WeekViewExposureDataSeries {
  categoryName?: string
  startDate?: number
  endDate?: number
  purchaseExposureTotal?: number
  salesExposureTotal?: number
  invoiceOverdueImport?: number
  invoiceOverdueExport?: number
  invoiceUnRealisedImport?: number
  invoiceUnRealisedExport?: number
  openTradeBalanceImport?: number
  openTradeBalanceExport?: number
  xAxisLabel?: string
}

const transformMonthViewData = (cashFlowResponse: GenerateWeekViewExposureReportForCurrencyResponse, dateFormat: string): Response => {

  let data: Array<WeekViewExposureDataSeries> = []

  if (!cashFlowResponse.aggregations) {
    return {
      data: [],
      ticks: [],
      monthsColumnAmountMap: {},
    }
  }

  data = cashFlowResponse.aggregations.map(function (p: Aggregation): WeekViewExposureDataSeries {
    return {
      categoryName: p.categoryName,
      startDate: p.startDate,
      endDate: p.endDate,

      // For Tooltip:  Total purchase exposure
      purchaseExposureTotal: (p.invoiceTotalImport?.total || {}).currencyAmount,

      // For Tooltip:  Total sales exposure
      salesExposureTotal: (p.invoiceTotalExport.total || {}).currencyAmount,

      // Note: Currency breakdown no longer on graph neither in tooltip
      // For Graph: Import Invoice Overdue Series
      invoiceOverdueImport: p.invoiceOverdueImport.total.currencyAmount,
      // For Graph: Export Invoice Overdue Series
      invoiceOverdueExport: p.invoiceOverdueExport.total.currencyAmount,
      // For Graph: Import Invoice Due Series
      invoiceUnRealisedImport: p.invoiceUnRealisedImport.total.currencyAmount,
      // For Graph: Export Invoice Due Series
      invoiceUnRealisedExport: p.invoiceUnRealisedExport.total.currencyAmount,

      // For Tooltip and graph: Import trade open balances
      openTradeBalanceImport: p.openTradeBalanceImport,

      // For Tooltip and graph: Export trade open balances
      openTradeBalanceExport: p.openTradeBalanceExport,
    }
  })

  const {dataWithGaps, ticks, monthsColumnAmountMap} = addMonthGaps(data, dateFormat)

  return {
    data: dataWithGaps,
    ticks: ticks,
    monthsColumnAmountMap
  }
}

const addMonthGaps = (data: Array<WeekViewExposureDataSeries>, xAxisDateFormat: string):
  {
    dataWithGaps: Array<WeekViewExposureDataSeries>,
    ticks: Array<string>
    monthsColumnAmountMap: Record<string, number>
  } => {
  const dataWithGaps: Array<WeekViewExposureDataSeries> = []
  const monthsColumnAmountMap: Record<string, number> = {}
  const dataLength = data.length
  if (dataLength > 20) {
    dataWithGaps.push({})
    dataWithGaps.push({})
  }
  const ticks: Array<string> = []
  const labelsAdded: Array<string> = []
  data.forEach((p, i) => {
    dataWithGaps.push(p)
    const monthData = categoryNameToMonthNum(p['categoryName'] || '', xAxisDateFormat)
    const month = monthData.dateString
    const monthWeekNum = monthData.weekOfMonth

    if (!labelsAdded.includes(month)) {
      p.xAxisLabel = month
      labelsAdded.push(month)
      ticks.push(month)
      monthsColumnAmountMap[month] = parseInt(monthWeekNum)
    }

    if (dataLength - 1 > i + 1) {
      const pPlusOne = data[i + 1]
      const monthPlusOne = categoryNameToMonthNum(pPlusOne['categoryName'] || '', xAxisDateFormat).dateString
      if (monthPlusOne !== month) {
        dataWithGaps.push({
          // xAxisLabel: month
        })
      }
    }
    if (dataLength - 1 === i) {
      dataWithGaps.push({
        // xAxisLabel: month
      })
    }
  })
  return {
    dataWithGaps,
    ticks,
    monthsColumnAmountMap
  }
}

interface ExposureGraphProps {
  currency: Currency,
  data: GenerateWeekViewExposureReportForCurrencyResponse,
  width: number
}

const useStyles = makeStyles((theme: Theme) => ({
  tooltipRoot: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr'
  },
  tooltipHeading: {},
  tooltipSubHeading: {
    fontSize: '12px',
    color: theme.palette.customText.greyGraphLabel
  }
}))

export const WeekViewExposureGraph: React.FC<ExposureGraphProps> = (props: ExposureGraphProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const dateFormat = 'MMM-YY'
  const graphData = transformMonthViewData(props.data, dateFormat)

  const TickFormatter = (tick: number): string => {
    // return (tick / scaleToNumber(scale)) //.toFixed(0)
    return roundScaleAndFormatNumber(tick || 0, currencyCode)
  }

  const currencyCode = props.currency ? props.currency.symbol : ''
  const tooltip: React.ReactElement = (
    <CustomTooltip
      currency={props.currency}
      heading={(data: WeekViewExposureDataSeries) => {
        const {dateString, weekOfMonth} = categoryNameToMonthNum(data['categoryName'] || '', 'MMMM')
        const startDate = moment.unix(data['startDate'] || 0).format('DD/MM/YYYY')
        const endDate = moment.unix(data['endDate'] || 0).format('DD/MM/YYYY')
        return (
          <div
            className={classes.tooltipRoot}
          >
            <div
              className={classes.tooltipHeading}
            >
              {`${dateString} Week ${weekOfMonth}`}
            </div>
            <div
              className={classes.tooltipSubHeading}
            >
              {`${startDate} - ${endDate}`}
            </div>
          </div>)
      }}
      valueFormatter={(val: number): string => {
        return roundScaleAndFormatNumber(val || 0, currencyCode)
      }}
    >
      <CustomTooltipSection
        heading={'Purchase Exposure'}
        hideIfZero
        style={{color: theme.palette.import.main}}
      >
        <CustomTooltipSectionLine
          dataKey={'purchaseExposureTotal'}
          heading={'Total'}/>
      </CustomTooltipSection>
      <CustomTooltipSection
        heading={'Sales Exposure'}
        hideIfZero
        style={{color: theme.palette.export.main}}
      >
        <CustomTooltipSectionLine
          dataKey={'salesExposureTotal'}
          heading={'Total'}/>
      </CustomTooltipSection>
      <CustomTooltipSection
        heading={'FEC Balances Maturing'}
        hideIfZero
        style={{color: theme.palette.data.graphC}}
      >
        <CustomTooltipSectionLine
          dataKey={'openTradeBalanceImport'}
          heading={'Purchase Hedges'}/>
        <CustomTooltipSectionLine
          dataKey={'openTradeBalanceExport'}
          heading={'Sales Hedges'}/>
      </CustomTooltipSection>
    </CustomTooltip>
  )

  const ImportStack = 'ImportStack'
  const ExportStack = 'ExportStack'

  return (
    <div>
      <BarChart
        barCategoryGap={'15%'}
        barGap={0}
        data={graphData.data || []}
        height={360}
        margin={{
          top: 5, right: 30, left: 35, bottom: 10,
        }}
        stackOffset={'sign'}
        width={props.width || 1000}
      >
        <XAxis
          axisLine={false}
          dataKey="xAxisLabel"
          interval={0}
          orientation={'top'}
          stroke={'white'}
          tick={
            <XAxisTickFormatter
              dateFormat={dateFormat}
              theme={theme}
              xDiff={(displayMonth) => {
                const numberOfCol = graphData.monthsColumnAmountMap[displayMonth]
                switch (numberOfCol) {
                  case 1:
                    return 18
                  default:
                    return 0
                }
              }}
            />
          }
          tickLine={false}
          ticks={graphData.ticks}
        />
        <YAxis
          allowDecimals
          axisLine={false}
          interval={0}
          tick={{fill: theme.palette.customText.greyGraphLabel}}
          tickFormatter={TickFormatter}
          tickLine={false}
        />
        <Tooltip
          content={tooltip}
          cursor={{fill: '#1E2036'}}
          offset={calculateTooltipOffset(props.width, graphData?.data?.length)}
        />
        <CartesianGrid
          stroke={'#64659A'}
          vertical={false}/>
        <Bar
          dataKey={'invoiceOverdueImport'}
          fill={theme.palette.data.graphB}
          stackId={ImportStack}
          stroke={theme.palette.data.graphB}
        />
        <Bar
          dataKey={'invoiceOverdueExport'}
          fill={theme.palette.data.graphB}
          stackId={ExportStack}
          stroke={theme.palette.data.graphB}
        />
        <Bar
          dataKey={'invoiceUnRealisedImport'}
          fill={theme.palette.import.main}
          stackId={ImportStack}
          stroke={theme.palette.import.main}
        />
        <Bar
          dataKey={'invoiceUnRealisedExport'}
          fill={theme.palette.export.main}
          stackId={ExportStack}
          stroke={theme.palette.export.main}
        />
        <Bar
          dataKey={'openTradeBalanceImport'}
          fill={theme.palette.data.graphC}
          stackId={ImportStack}
          stroke={theme.palette.data.graphC}
        />
        <Bar
          dataKey={'openTradeBalanceExport'}
          fill={theme.palette.data.graphC}
          stackId={ExportStack}
          stroke={theme.palette.data.graphC}
        />
        <ReferenceLine
          stroke={'#C6C6C6'}
          y={0}
        />
      </BarChart>
    </div>
  )
}
