import React from 'react'
import PropTypes from 'prop-types'

import {connect} from 'react-redux'
import Roles from 'views/Configuration/Role/Roles'

function mapStateToProps(state) {
  return {
    partyCode: state.security.claims.context.partyCode,
  }
}

const mapDispatchToProps = () => {
  return {}
}

class RoleContainer extends React.Component {
  render() {
    const {
      partyCode,
    } = this.props

    return (
      <Roles
        partyCode={partyCode}
      />
    )
  }
}

RoleContainer.propTypes = {
  partyCode: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleContainer)
