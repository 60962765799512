import config from 'react-global-configuration'
import {jsonRpcRequestRaw} from 'actions/helper/utilities'
import {Marshal} from '../../search/identifier/Marshaller'
import {ID_IDENTIFIER} from '../../search/identifier/types'

/**
 * OrderLinker is a convenience client for invoking the backend services
 */
export default class Linker {
  /**
   * @param {string} orderId
   * @param {string} invoiceId
   */
  static linkToInvoice(orderId, invoiceId) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Order-Linker.LinkToInvoice',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id: orderId}}),
        invoiceId
      },
    })
  }

  /**
   * @param {string} orderId
   */
  static unlinkFromInvoice(orderId) {
    return jsonRpcRequestRaw({
      url: config.get('golangUrl'),
      method: 'Order-Linker.UnlinkFromInvoice',
      request: {
        identifier: Marshal({type: ID_IDENTIFIER, value: {id: orderId}}),
      },
    })
  }
}
