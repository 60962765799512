import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  AppBar, Button, Card, CardContent,
  CardHeader, Grid, Tab,
  Tabs, Typography, withStyles,
} from '@material-ui/core'
import Table from 'components/Table/index'
import {Company as CompanyEntity} from 'popcorn-js/legalEntity/party'
import {FullPageLoader as Loader} from 'components/Loader/Loader'
import NotificationSweetAlert
  from 'components/SweetAlert/NotificationSweetAlert'
import CompanyRecordkeeper from 'popcorn-js/legalEntity/party/company/recordkeeper'
import HistoryLayout from '../../History/HistoryLayout'
import CompanyFields from '../../History/CompanyHistory/CompanyFields'
import BasicInformation from 'components/Cards/BasicInformation'
import {PARTY_TYPE_COMPANY} from 'constants/partyTypes'
import ClientTierRecordkeeper from 'popcorn-js/financial/clientTier/recordkeeper'
import ClientTierEntity from 'popcorn-js/financial/clientTier/ClientTier'
import CompanyComparator from 'popcorn-js/legalEntity/party/company/comparator'
import CompanyValidator from 'popcorn-js/legalEntity/party/company/validator'
import CurrencyPairs from 'components/Cards/CurrencyPairs'
import {
  FilterToSortBy,
  objectCopy, SortByToFilter,
  SortNumberString,
  SortObjects,
} from '../../../utils/Utils'

const styles = () => ({
  backgroundCardRoot: {
    marginBottom: '10px',
    maxHeight: 'calc(100vh - 152px)',
  },
  backgroundCardContent: {
    padding: '0',
    margin: '0px 24px 24px 24px',
  },
  tabContainer: {
    height: 'calc(100vh - 295px)',
    marginTop: '8px',
    overflowY: 'scroll',
  },
  dashboard: {},
  controlsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  controlsLeft: {
    justifySelf: 'start',
  },
  controlsRight: {
    justifySelf: 'end',
  },
})

const ACTIVE_STATE_VIEWING = 'ACTIVE_STATE_VIEWING'
const ACTIVE_STATE_CREATING = 'ACTIVE_STATE_CREATING'
const ACTIVE_STATE_EDITING = 'ACTIVE_STATE_EDITING'
const ACTIVE_STATE_VIEWING_DELETE = 'ACTIVE_STATE_VIEWING_DELETE'

const tabs = {
  basicInformation: 0,
  currencyPairs: 1,
}

const companyListLength = 10
const companyListId = `${CompanyEntity.camel}ConfigurationList`
const newAutoFocusElementId = 'partyCode'

class Company extends Component {
  state = {
    activeState: ACTIVE_STATE_VIEWING,

    companies: [],
    total: 0,
    selectedRowIndex: -1,
    previouslySelectedRowIndex: -1,
    sortBy: ['name'],

    history: [],
    selected: new CompanyEntity(),
    original: new CompanyEntity(),
    previouslySelected: new CompanyEntity(),

    isLoading: false,
    showHistory: false,

    successMessage: undefined,
    errorMessage: undefined,
    warningMessage: undefined,
    confirmationMethod: undefined,

    invalidFields: {},

    tabIndex: 0,

    assignedCurrencyPairIds: [],
    availableCurrencyPairIds: [],
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.partyCurrencyPairIds.length !==
        this.props.partyCurrencyPairIds.length) {
      this.processCurrencyPairs()
    }
  }

  componentDidMount() {
    this.find()
    this.findClientTiers()
    this.processCurrencyPairs()
  }

  processCurrencyPairs = () => {
    const assignedCurrencyPairIds = this.state.selected.currencyPairIds || []
    const availableCurrencyPairIds = this.props.partyCurrencyPairIds.filter(
      c => !assignedCurrencyPairIds.find(assignedC => assignedC === c))
    this.setState({availableCurrencyPairIds, assignedCurrencyPairIds})
  }

  find = async (deleted = false) => {
    const {
      sortBy,
    } = this.state

    const query = {
      sortBy,
    }

    this.setState({isLoading: true})
    try {
      const findResponse = await CompanyRecordkeeper.find({
        undefined,
        query,
        deleted,
      })
      const companies = findResponse.records.map(
        company => new CompanyEntity(company)
      )
      companies.sort(
        (a, b) => SortObjects(a, b, sortBy)
      )

      const selected = findResponse.total > 0
        ? companies[0]
        : new CompanyEntity()
      const selectedRowIndex = findResponse.total > 0 ? 0 : -1

      this.handleSelection(
        selected,
        selectedRowIndex,
        deleted
          ? ACTIVE_STATE_VIEWING_DELETE
          : ACTIVE_STATE_VIEWING,
      )
      this.setState({companies})
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
    this.setState({tabIndex: 0})
  }

  handleViewDelete = () => {
    this.find(true)
  }

  findClientTiers = async () => {
    this.setState({isLoading: true})
    try {
      const findResponse = await ClientTierRecordkeeper.find([], {}, false)
      this.setState({
        clientTiers: findResponse.records.map(b => new ClientTierEntity(b))
      })
    } catch (e) {
      this.setState({
        errorMessage: 'Could not retrieve client tiers: ' + e.message || e,
      })
    }
    this.setState({isLoading: false})
  }

  handleSelection = (selected, index, activeState) => {
    if (this.state.activeState === ACTIVE_STATE_EDITING && !activeState) {
      this.showDiscardConfirmation(() => {
        this.handleHideAlert()
        this.handleSelection(selected, index, ACTIVE_STATE_VIEWING)
      })
      return
    }

    let t = activeState
    const {
      activeState: currentState,
    } = this.state
    if (!activeState) {
      switch (currentState) {
        case ACTIVE_STATE_CREATING:
        case ACTIVE_STATE_EDITING:
          t = ACTIVE_STATE_VIEWING
          break
        default:
          t = currentState
      }
    }

    this.setState({
      selected: new CompanyEntity(selected),
      original: new CompanyEntity(selected),
      previouslySelected: new CompanyEntity(selected),

      selectedRowIndex: index,
      previouslySelectedRowIndex: index,
      tabIndex: index === -1 ? 0 : this.state.tabIndex,

      activeState: t,
    }, () => this.processCurrencyPairs())

  }

  handleNew = () => {
    this.setState({
      selected: new CompanyEntity(),
      original: new CompanyEntity(),
      selectedRowIndex: -1,
      activeState: ACTIVE_STATE_CREATING,
      tabIndex: 0,
    }, () => document.getElementById(newAutoFocusElementId).focus())
  }

  handleRetrieveHistory = async () => {
    this.setState({isLoading: true})
    try {
      const retrieveHistoryResponse = await CompanyRecordkeeper.retrieveHistory(
        this.state.selected.id,
      )
      this.setState({
        history: retrieveHistoryResponse.history.map(
          company => new CompanyEntity(company)
        ),
        showHistory: true,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleReturn = () => {
    this.find()
  }

  handleRestore = async () => {
    this.setState({isLoading: true})
    try {
      await CompanyRecordkeeper.restore(this.state.selected.id)
      const companies = this.state.companies.slice()
      const idx = companies.findIndex(
        c => c.id === this.state.selected.id)
      companies.splice(idx, 1)

      if (companies.length > idx) {
        this.handleSelection(companies[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ? new CompanyEntity() : companies[idx - 1],
          idx - 1)
      }

      this.setState({
        successMessage: `${CompanyEntity.capital} restored`,
        companies,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleHideAlert = () => {
    this.setState({
      errorMessage: undefined,
      successMessage: undefined,
      warningMessage: undefined,
      confirmationMethod: undefined,
    })
  }

  handleUpdate = async () => {
    this.setState({isLoading: true})
    try {
      const updateResponse = await CompanyRecordkeeper.update(
        this.state.selected,
        this.state.original.id,
      )
      const companies = this.state.companies.slice() || []
      const selected = new CompanyEntity(updateResponse.company)
      const companyToReplaceIndex = companies.findIndex(
        b => b.id === updateResponse.company.id
      )

      if (companyToReplaceIndex >= 0) {
        companies[companyToReplaceIndex] = selected
      }
      companies.sort((a, b) => SortObjects(a, b, this.state.sortBy))
      const selectedIndex = companies.findIndex(b => b.id === selected.id)

      this.handleSelection(selected, selectedIndex, ACTIVE_STATE_VIEWING)
      this.setState({
        successMessage: `${CompanyEntity.capital} updated`,
        companies,
      })
      Company.scrollToPos(companyToReplaceIndex / companies.length)
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleCreate = async () => {
    if (await this.isValid()) {
      this.setState({isLoading: true})
      try {
        const company = this.state.selected
        // populate party code in the client tier
        company.parentPartyCode = this.props.partyCode
        const createResponse = await CompanyRecordkeeper.create(company)
        const selected = new CompanyEntity(createResponse.company)
        const companies = this.state.companies.slice() || []
        companies.push(selected)
        companies.sort(
          (a, b) => SortObjects(a, b, this.state.sortBy)
        )

        const selectedRowIndex = companies.findIndex(
          c => c.id === selected.id
        )

        this.handleSelection(selected, selectedRowIndex, ACTIVE_STATE_VIEWING)

        this.setState(
          {
            successMessage: `${CompanyEntity.capital} created`,
            companies,
          })
      } catch (e) {
        this.setState({errorMessage: e})
      }
      this.setState({isLoading: false})
    }
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    try {
      await CompanyRecordkeeper.delete(this.state.selected.id)
      const companies = this.state.companies.slice() || []
      const idx = companies.findIndex(
        c => c.id === this.state.selected.id
      )
      companies.splice(idx, 1)

      if (companies.length > idx) {
        this.handleSelection(companies[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ? new CompanyEntity() : companies[idx - 1],
          idx - 1)
      }

      this.setState({
        successMessage: `${CompanyEntity.capital} deleted`,
        companies,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  handleDelete = async () => {
    this.setState({isLoading: true})
    this.handleHideAlert()
    try {
      await CompanyRecordkeeper.delete(this.state.selected.id)
      const companies = this.state.companies.slice()
      const idx = companies.findIndex(
        c => c.id === this.state.selected.id
      )
      companies.splice(idx, 1)

      if (companies.length > idx) {
        this.handleSelection(companies[idx], idx)
      } else {
        this.handleSelection(
          idx === 0 ? new CompanyEntity() : companies[idx - 1],
          idx - 1)
      }

      this.setState({
        successMessage: `${CompanyEntity.capital} deleted`,
        companies,
      })
    } catch (e) {
      this.setState({errorMessage: e.message || e})
    }
    this.setState({isLoading: false})
  }

  showDeleteConfirmation = () => {
    this.setState({
      warningMessage: `You are about to permanently delete ${this.state.selected.partyCode} - '${this.state.selected.name}'. Do you want to continue?`,
      confirmationMethod: this.handleDelete,
    })
  }

  showDiscardConfirmation = (confirm) => {
    this.setState({
      warningMessage: 'You have unsaved changes. Do you want to continue?',
      confirmationMethod: confirm,
    })
  }

  handleCancelUpdate = () => {
    this.setState({
      invalidFields: {},
      selected: new CompanyEntity(this.state.original),
      activeState: ACTIVE_STATE_VIEWING,
    }, () => this.processCurrencyPairs())
  }

  handleCancelCreate = () => {
    this.setState({
      invalidFields: {},
      selected: new CompanyEntity(this.state.previouslySelected),
      original: new CompanyEntity(this.state.previouslySelected),
      activeState: ACTIVE_STATE_VIEWING,
      selectedRowIndex: this.state.previouslySelectedRowIndex,
      tabIndex: 0,
    })
  }

  handleFieldChange = (field, newValue) => {
    const {
      selected,
      invalidFields,
    } = this.state
    selected[field] = newValue
    invalidFields[field] = undefined

    this.setState({selected, invalidFields})
    this.handleChanges(selected)
  }

  handleAddCurrencyPairs = (currencyPairs = []) => {
    const selected = new CompanyEntity(objectCopy(this.state.selected))
    for (const ccyPair of currencyPairs) {
      selected.currencyPairIds.push(ccyPair.value)
    }
    this.setState({selected}, () => this.processCurrencyPairs())
    this.handleChanges(selected)
  }

  handleRemoveCurrencyPairs = (currencyPairs) => {
    const selected = new CompanyEntity(objectCopy(this.state.selected))
    selected.currencyPairIds = (selected.currencyPairIds || []).filter(
      c => !currencyPairs.find(cc => cc.value === c))
    this.setState({selected}, () => this.processCurrencyPairs())
    this.handleChanges(selected)
  }

  handleChanges = (selected) => {
    const {
      activeState: currentState,
    } = this.state
    const {
      selectedRowIndex,
    } = this.state

    let activeState
    switch (currentState) {
      case ACTIVE_STATE_CREATING:
        activeState = ACTIVE_STATE_CREATING
        break

      case ACTIVE_STATE_VIEWING:
        activeState = selectedRowIndex >= 0
          ? !CompanyComparator.CompareAll(this.state.original, selected)
            ? ACTIVE_STATE_EDITING
            : ACTIVE_STATE_VIEWING
          : ACTIVE_STATE_CREATING
        break

      case ACTIVE_STATE_EDITING:
        activeState = !CompanyComparator.CompareAll(this.state.original, selected)
          ? ACTIVE_STATE_EDITING
          : ACTIVE_STATE_VIEWING
        break

      default:
    }

    this.setState({activeState})
  }

  render() {
    const {
      activeState,
      tabIndex,
      companies,
      selectedRowIndex,
    } = this.state
    const {
      classes,
      theme,
    } = this.props

    const showCurrencyPairsTab = activeState === ACTIVE_STATE_EDITING ||
        (activeState === ACTIVE_STATE_VIEWING && selectedRowIndex >= 0)

    return <div
      className={classes.dashboard}
      id={`${CompanyEntity.camel}PartySettingsRoot`}>
      {this.renderDialogs()}
      <Card className={classes.backgroundCardRoot}>
        <CardHeader
          action={
            <div>
              <Button disabled>Import</Button>
              <Button disabled>Export</Button>
            </div>
          }
          title={<Typography
            gutterBottom
            variant="subtitle1">
            {(activeState === ACTIVE_STATE_VIEWING_DELETE)
              ? `${CompanyEntity.capitalP} - Delete`
              : `${CompanyEntity.capitalP}`
            }
          </Typography>}
        />
        <CardContent className={classes.backgroundCardContent}>
          <Grid
            container
            direction={'row'}
            spacing={3}>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}>
              <Table
                columns={[
                  {
                    Header: 'Name',
                    accessor: 'name',
                  },
                ]}
                data={companies}
                defaultPageSize={companyListLength}
                defaultSortMethod={(a, b) => SortNumberString(a, b)}
                defaultSorted={SortByToFilter(this.state.sortBy)}

                getTbodyProps={() => {
                  return {
                    style: {
                      overflowY: 'scroll',
                      height: '368px',
                      scrollBehavior: 'smooth',
                    },
                    id: companyListId,
                  }
                }}
                getTdProps={(state, rowInfo) => {
                  const rowIndex = rowInfo ? rowInfo.index : undefined

                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo) {
                        this.handleSelection(
                          new CompanyEntity(rowInfo.original),
                          rowInfo.index)
                      }
                      if (handleOriginal) {
                        handleOriginal()
                      }
                    },
                    style: {
                      background: rowIndex === selectedRowIndex ?
                        theme.palette.secondary.light :
                        'white',
                      color: rowIndex === selectedRowIndex ?
                        theme.palette.secondary.contrastText :
                        'black',
                    },
                  }
                }}
                id={`${CompanyEntity.camel}Table`}
                onSortedChange={(filter) => this.setState(
                  {sortBy: FilterToSortBy(filter)})}
                pageSize={Math.max(10,
                  (companies || []).length)}
                showPagination={false}
              />
            </Grid>
            <Grid
              item
              lg={9}
              md={8}
              sm={6}
              xs={12}>
              <AppBar
                id="configTabBar"
                position={'static'}
              >
                <Tabs
                  onChange={(e, tabIndex) => this.setState({tabIndex})}
                  value={tabIndex}
                >
                  <Tab
                    id="configTabBarBasicInformationTab"
                    label="Basic Info"
                  />
                  {showCurrencyPairsTab &&
                  <Tab
                    id="configTabBarCurrencyPairsTab"
                    label="Currency pairs"
                  />}
                </Tabs>
              </AppBar>
              <Grid
                className={classes.tabContainer}
                item>
                {(() => {
                  switch (tabIndex) {
                    case tabs.basicInformation:
                      return this.renderBasicInformationTabContents()
                    case tabs.currencyPairs:
                      return this.renderCurrencyPairsTabContents()
                    default:
                      return <div>invalid tab value</div>
                  }
                })()}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {this.renderControls()}
    </div>
  }

  renderBasicInformationTabContents = () => {
    const {
      selected,
      activeState,
      invalidFields,
      selectedRowIndex,
    } = this.state
    const {
      partyType,
      allCurrencies,
      partyCode,
    } = this.props

    return <BasicInformation
      clientTiers={this.state.clientTiers}
      currencies={allCurrencies}
      disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
      importExportFields
      invalidFields={invalidFields}
      isNew={activeState === ACTIVE_STATE_CREATING || selectedRowIndex < 0}
      onChange={this.handleFieldChange}
      party={selected}
      partyCode={partyCode}
      viewOfPartyType={PARTY_TYPE_COMPANY}
      viewingPartyType={partyType}
    />
  }

  renderCurrencyPairsTabContents = () => {
    const assignedCurrencyPairOptions = this.state.assignedCurrencyPairIds.map(
      c => ({
        value: c,
        text: (this.props.allCurrencyPairs.find(
          pair => pair.id === c) || {}).name || c,
      }))

    const availableCurrencyPairOptions = this.state.availableCurrencyPairIds.map(
      c => ({
        value: c,
        text: (this.props.allCurrencyPairs.find(
          pair => pair.id === c) || {}).name || c,
      }))

    return <CurrencyPairs
      assignedCurrencyPairOptions={assignedCurrencyPairOptions}
      availableCurrencyPairOptions={availableCurrencyPairOptions}
      onAdd={this.handleAddCurrencyPairs}
      onRemove={this.handleRemoveCurrencyPairs}
    />
  }

  renderDialogs = () => {
    const {
      isLoading,
      errorMessage,
      successMessage,
      warningMessage,
      confirmationMethod,
      selected,
      showHistory,
      history,
      clientTiers,
    } = this.state
    const {
      theme,
      allCurrencies,
    } = this.props

    return (
      <React.Fragment>
        <Loader
          color={theme.palette.primary.main}
          isLoading={isLoading}/>
        {showHistory &&
          <HistoryLayout
            addEntityFieldsProps={{
              currencies: allCurrencies,
              clientTiers: clientTiers,
            }}
            entity={selected}
            entityFields={CompanyFields}
            entityHistory={history}
            entityName={CompanyEntity.capital}
            loading={isLoading}
            onHide={() => this.setState({showHistory: false})}
            open
          />}
        <NotificationSweetAlert
          customClass={`${CompanyEntity.camel}ConfigAlert`}
          errorMessage={errorMessage}
          onClose={this.handleHideAlert}
          onConfirm={confirmationMethod}

          successMessage={successMessage}
          warningMessage={warningMessage}
        />
      </React.Fragment>
    )
  }

  renderControls = () => {
    const {
      activeState,
      isLoading,
      selectedRowIndex,
    } = this.state
    const {
      classes,
    } = this.props

    const disableFields =
        isLoading ||
        (activeState === ACTIVE_STATE_VIEWING_DELETE)

    return <div className={classes.controlsWrapper}>
      {(() => {
        switch (activeState) {
          case ACTIVE_STATE_VIEWING:
            return <React.Fragment>
              <div className={classes.controlsLeft}>
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${CompanyEntity.camel}NewButton`}
                  onClick={this.handleNew}
                  size="medium"
                >
                  New
                </Button>
                {(selectedRowIndex >= 0) &&
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${CompanyEntity.camel}DeleteButton`}
                  onClick={this.handleDelete}
                  size="medium"
                >
                  Delete
                </Button>}
              </div>
              <div className={classes.controlsRight}>
                {(selectedRowIndex >= 0) &&
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${CompanyEntity.camel}RetrieveHistoryButton`}
                  onClick={this.handleRetrieveHistory}
                  size="medium"
                >
                  Show History
                </Button>}
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${CompanyEntity.camel}ViewDeleteButton`}
                  onClick={this.handleViewDelete}
                  size="medium"
                >
                  View Delete
                </Button>
              </div>
            </React.Fragment>

          case ACTIVE_STATE_CREATING:
            return <React.Fragment>
              <div className={classes.controlsLeft}>
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${CompanyEntity.camel}CreateButton`}
                  onClick={this.handleCreate}
                  size="medium"
                >
                  Save New
                </Button>
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${CompanyEntity.camel}CancelCreateButton`}
                  onClick={this.handleCancelCreate}
                  size="medium"
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.controlsRight} />
            </React.Fragment>

          case ACTIVE_STATE_EDITING:
            return <React.Fragment>
              <div className={classes.controlsLeft}>
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${CompanyEntity.camel}UpdateButton`}
                  onClick={this.handleUpdate}
                  size="medium"
                >
                  Save Changes
                </Button>
                <Button
                  color="primary"
                  disabled={disableFields}
                  id={`${CompanyEntity.camel}CancelUpdateButton`}
                  onClick={this.handleCancelUpdate}
                  size="medium"
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.controlsRight} />
            </React.Fragment>

          case ACTIVE_STATE_VIEWING_DELETE:
            return <React.Fragment>
              <div className={classes.controlsLeft}>
                {(selectedRowIndex >= 0) &&
                <Button
                  color="primary"
                  id={`${CompanyEntity.camel}RestoreButton`}
                  onClick={this.handleRestore}
                  size="medium"
                >
                  Restore
                </Button>}
                {(selectedRowIndex >= 0) &&
                <Button
                  color="primary"
                  id={`${CompanyEntity.camel}DeleteButton`}
                  onClick={this.showDeleteConfirmation}
                  size="medium"
                >
                  Delete
                </Button>}
              </div>
              <div className={classes.controlsRight}>
                <Button
                  color="primary"
                  id={`${CompanyEntity.camel}ReturnButton`}
                  onClick={this.handleReturn}
                  size="medium"
                >
                  Return
                </Button>
              </div>
            </React.Fragment>
          default:
        }
      })()}
    </div>
  }

  isValid = async () => {
    const {
      selected,
    } = this.state
    try {
      await CompanyValidator.ValidateData(selected)
    } catch (invalidFields) {
      if (invalidFields) {
        this.setState({invalidFields})
        return false
      }
    }
    return true
  }

  static scrollToPos(pos) {
    const objDiv = document.getElementById(companyListId)
    if (objDiv) {
      objDiv.scrollTop = pos * objDiv.scrollHeight
    }
  }

}

Company.propTypes = {
  allCurrencies: PropTypes.array.isRequired,
  allCurrencyPairs: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  partyCode: PropTypes.string.isRequired,
  partyCurrencyPairIds: PropTypes.array.isRequired,
  partyType: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}

Company.defaultProps = {}

export default withStyles(styles, {withTheme: true})(Company)
