import {jsonRpcRequest} from 'popcorn-js/jsonRpc/jsonRpcReqest'
import {marshalIdentifier} from 'popcorn-js/marshallers'
import {MarshalCriteria} from 'popcorn-js/search/criteria/Marshaller'
import {config} from 'popcorn-js/index'

/**
 * CounterpartyRecordkeeper is a convenience client for invoking the backend services
 */

export const Recordkeeper = {
  delete(deleteRequest) {
    const serializedIdentifier = marshalIdentifier(
      deleteRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Counterparty-Recordkeeper.Delete',
      request: {
        ...deleteRequest,
        identifier: serializedIdentifier,
      },
    })
  },

  create(createRequest) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Counterparty-Recordkeeper.Create',
      request: createRequest,
    })
  },

  createBatch(createBatchRequest) {
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Counterparty-Recordkeeper.CreateBatch',
      request: {...createBatchRequest},
    })
  },

  update(updateRequest) {
    const serializedIdentifier = marshalIdentifier(
      updateRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Counterparty-Recordkeeper.Update',
      request: {
        ...updateRequest,
        identifier: serializedIdentifier,
      },
    })
  },

  find(findRequest) {
    const serializedCriteria = MarshalCriteria(
      findRequest.criteria)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Counterparty-Recordkeeper.Find',
      request: {
        ...findRequest,
        criteria: serializedCriteria,
      },
    })
  },

  restore(restoreRequest) {
    const serializedIdentifier = marshalIdentifier(
      restoreRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Counterparty-Recordkeeper.Restore',
      request: {
        ...restoreRequest,
        identifier: serializedIdentifier,
      },
    })
  },

  deleteForever(deleteForeverRequest) {
    const serializedIdentifier = marshalIdentifier(
      deleteForeverRequest.identifier)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Counterparty-Recordkeeper.DeleteForever',
      request: {
        ...deleteForeverRequest,
        identifier: serializedIdentifier,
      },
    })
  },
  retrieveHistory(retrieveHistoryRequest) {
    const serializedIdentifier = marshalIdentifier(
      retrieveHistoryRequest.identifier)

    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Counterparty-Recordkeeper.RetrieveHistory',
      request: {
        ...retrieveHistoryRequest,
        identifier: serializedIdentifier,
      },
    })
  },
  retrieve(id) {
    // Create an identifier from the id
    console.log('idddd', id)
    const serializedIdentifier = marshalIdentifier(id)
    return jsonRpcRequest({
      url: config.get('golangUrl'),
      method: 'Counterparty-Recordkeeper.Retrieve',
      request: {
        identifier: serializedIdentifier,
      },
    })
  }

}
