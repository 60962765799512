import {makeStyles, useTheme} from '@material-ui/core';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles(() => ({
  root: {
    height: '32px',
    width: '32px',
    strokeWidth: '2'
  },
})
)

export function InvoicesIcon() {
  const classes = useStyles()
  const theme = useTheme()


  return (
    <SvgIcon
      className={classes.root}
      htmlColor={theme.palette.primary.contrastText}
      viewBox="0 0 32 32"
    >
      <g
        className="cls-1"
        id="Invoices">
        <g
          data-name="Group 29"
          id="Group_29"
          transform="translate(-457.942 -445.305)">
          <g
            data-name="Group 23"
            id="Group_23">
            <path
              d="M468.774,466.834a.5.5,0,0,1-.5-.5v-6a.5.5,0,0,1,.5-.5h6a.5.5,0,1,1,0,1h-5.5v5.5a.5.5,0,0,1-.5.5Z"
              id="Path_16"
            />
          </g>
          <g
            data-name="Group 24"
            id="Group_24">
            <path
              d="M468.774,466.834H458.442a.5.5,0,0,1-.5-.5V445.8a.5.5,0,0,1,.5-.5h16.332a.5.5,0,0,1,.5.5v14.529a.5.5,0,0,1-.147.354l-6,6a.5.5,0,0,1-.353.146Zm-9.832-1h9.625l5.707-5.707V446.3H458.942v19.529Z"
              id="Path_17"
            />
          </g>
          <g
            data-name="Group 25"
            id="Group_25">
            <path
              d="M467.692,450.1h-5.875a.5.5,0,0,1,0-1h5.875a.5.5,0,0,1,0,1Z"
              id="Path_18"
            />
          </g>
          <g
            data-name="Group 26"
            id="Group_26">
            <path
              d="M471.567,452.516h-9.75a.5.5,0,0,1,0-1h9.75a.5.5,0,0,1,0,1Z"
              id="Path_19"
            />
          </g>
          <g
            data-name="Group 27"
            id="Group_27">
            <path
              d="M471.567,454.932h-9.75a.5.5,0,0,1,0-1h9.75a.5.5,0,0,1,0,1Z"
              id="Path_20"
            />
          </g>
          <g
            data-name="Group 28"
            id="Group_28">
            <path
              d="M465.9,457.35h-4.082a.5.5,0,0,1,0-1H465.9a.5.5,0,1,1,0,1Z"
              id="Path_21"
            />
          </g>
        </g>
      </g>

    </SvgIcon>
  )
}
